'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { DURATION } from '../constants/property-types';
const SMALL_SCALE_MS_THRESHOLD = 2000;
const checkV1Data = (data, property, metricType) => data.has('dimension') ? data.getIn(['dimension', 'buckets'], List()).every(bucket => checkV1Data(bucket, property, metricType)) : Math.abs(data.getIn(['metrics', property, metricType], 0)) < SMALL_SCALE_MS_THRESHOLD;
export const shouldBeSmallScaleV1 = (dataset, config, properties) => {
  const metrics = config.get('metrics').reduce((acc, metric) => acc.update(metric.get('property'), types => types ? types.concat(metric.get('metricTypes')) : List(metric.get('metricTypes'))), ImmutableMap());
  return metrics.map((types, property) => types.reduce((acc, type) => acc.set(type, properties.getIn([config.get('dataType'), property, 'type']) === DURATION ? checkV1Data(dataset, property, type) : false), ImmutableMap()));
};
export const shouldBeSmallScale = (dataset, metrics) => metrics.reduce((acc, metric) => acc.set(metric, dataset.getIn(['properties', metric, 'type']) === DURATION && dataset.get('data', List()).every(row => Math.abs(row.get(metric)) < SMALL_SCALE_MS_THRESHOLD)), ImmutableMap());