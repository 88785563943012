'use es6';

import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { ENGAGEMENT } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { getObjectsByIds, searchObjects, createGetObjectsByIds, createSearchObjects } from 'reference-resolvers/api/ContactSearchAPI';
export const createEngagementReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.ENGAGEMENTS,
  createFetchByIds: opts => createGetObjectsByIds(opts)(ENGAGEMENT),
  createFetchSearchPage: opts => createSearchObjects(opts)(ENGAGEMENT),
  fetchByIds: getObjectsByIds(ENGAGEMENT),
  fetchSearchPage: searchObjects(ENGAGEMENT)
}, options));
export default createEngagementReferenceResolver();