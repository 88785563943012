import { List } from 'immutable';
// @ts-expect-error need to add types
import getProperties from 'reporting-data/properties';
import { useEffect, useState } from 'react';
import { getDataType } from 'reporting-data/report/reportGetters';
import { isConfigReport } from 'reporting-data/tsTypes/reportTypes';
export const useSingleObjectReportProperties = report => {
  const [properties, setProperties] = useState(List());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const reportDataType = isConfigReport(report) ? getDataType(report) : undefined;
  useEffect(() => {
    setLoading(true);
    getProperties(reportDataType).then(reportProperties => {
      setLoading(false);
      setProperties(reportProperties.get(reportDataType).toList());
    }).catch(propertyFetchError => {
      setError(propertyFetchError);
      setLoading(false);
    });
  }, [reportDataType]);
  return {
    properties,
    loading,
    error
  };
};