import * as OperationTypes from '../../OperationTypes';
import * as OperatorTypes from '../../ObjectSegOperatorTypes';
import invariant from 'react-utils/invariant';
import { convertMomentDateToTimePoint } from '../../timeFilters/timeFiltersUtils';
import { DATE_TIME } from 'customer-data-objects/property/PropertyTypes';
import * as TimeOperationEndpointBehaviorTypes from '../../timeFilters/TimeOperationEndpointBehaviorTypes';
import * as TimePointTimeTypes from '../../timeFilters/TimePointTimeTypes';
import * as IndexedTimePointReferenceTypes from '../../timeFilters/IndexedTimePointReferenceTypes';
import * as DateTimePointReferenceTypes from '../../timeFilters/DateTimePointReferenceTypes';
import * as TimeOperationPropertyParserTypes from '../../timeFilters/TimeOperationPropertyParserTypes';
import { hasRemoveTimezoneForDates } from '../../../../permissions/gates';
import I18n from 'I18n';
export const getEqualToDateOperationWithTimezones = operator => {
  if (!I18n.moment.isMoment(operator.value)) {
    return invariant(false, `${operator.value} is not a valid date value`);
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE,
    propertyType: OperationTypes.RANGED_TIME,
    operator: OperatorTypes.IS_BETWEEN,
    lowerBoundTimePoint: convertMomentDateToTimePoint(operator.value, 'start'),
    upperBoundTimePoint: convertMomentDateToTimePoint(operator.value, 'end'),
    lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
    upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
  };
};
export const getEqualToDateOperationWithoutTimezones = operator => {
  if (!I18n.moment.isMoment(operator.value)) {
    return invariant(false, `${operator.value} is not a valid date value`);
  }
  if (operator.field && operator.field.type === DATE_TIME) {
    return getEqualToDateOperationWithTimezones(operator);
  }
  const hasTimezones = operator && operator.hasTimezones;
  if (hasTimezones) {
    return {
      propertyParser: TimeOperationPropertyParserTypes.VALUE,
      propertyType: OperationTypes.RANGED_TIME,
      operator: OperatorTypes.IS_BETWEEN,
      lowerBoundTimePoint: convertMomentDateToTimePoint(operator.value, 'start'),
      upperBoundTimePoint: convertMomentDateToTimePoint(operator.value, 'end'),
      lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
      upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
    };
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION,
    propertyType: OperationTypes.RANGED_TIME,
    operator: OperatorTypes.IS_BETWEEN,
    lowerBoundTimePoint: convertMomentDateToTimePoint(operator.value, 'start'),
    upperBoundTimePoint: convertMomentDateToTimePoint(operator.value, 'end'),
    lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
    upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
  };
};
export const getEqualToDateOperation = (operator, options) => {
  if (hasRemoveTimezoneForDates(options.isUngated)) {
    return getEqualToDateOperationWithoutTimezones(operator);
  } else {
    return getEqualToDateOperationWithTimezones(operator);
  }
};
export const getAfterDateOperationWithTimezones = operator => {
  if (!I18n.moment.isMoment(operator.value)) {
    return invariant(false, `${operator.value} is not a valid date value`);
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE,
    propertyType: OperationTypes.TIME_POINT,
    operator: OperatorTypes.IS_AFTER,
    timePoint: convertMomentDateToTimePoint(operator.value, 'end'),
    endpointBehavior: TimeOperationEndpointBehaviorTypes.EXCLUSIVE
  };
};
export const getAfterDateOperationWithoutTimezones = operator => {
  if (!I18n.moment.isMoment(operator.value)) {
    return invariant(false, `${operator.value} is not a valid date value`);
  }
  if (operator.field && operator.field.type === DATE_TIME) {
    return getAfterDateOperationWithTimezones(operator);
  }
  const hasTimezones = operator && operator.hasTimezones;
  if (hasTimezones) {
    return {
      propertyParser: TimeOperationPropertyParserTypes.VALUE,
      propertyType: OperationTypes.TIME_POINT,
      operator: OperatorTypes.IS_AFTER,
      timePoint: convertMomentDateToTimePoint(operator.value, 'end'),
      endpointBehavior: TimeOperationEndpointBehaviorTypes.EXCLUSIVE
    };
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION,
    propertyType: OperationTypes.TIME_POINT,
    operator: OperatorTypes.IS_AFTER,
    timePoint: convertMomentDateToTimePoint(operator.value, 'end'),
    endpointBehavior: TimeOperationEndpointBehaviorTypes.EXCLUSIVE
  };
};
export const getAfterDateOperation = (operator, options) => {
  if (hasRemoveTimezoneForDates(options.isUngated)) {
    return getAfterDateOperationWithoutTimezones(operator);
  } else {
    return getAfterDateOperationWithTimezones(operator);
  }
};
export const getBeforeDateOperationWithTimezones = operator => {
  if (!I18n.moment.isMoment(operator.value)) {
    return invariant(false, `${operator.value} is not a valid date value`);
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE,
    propertyType: OperationTypes.TIME_POINT,
    operator: OperatorTypes.IS_BEFORE,
    timePoint: convertMomentDateToTimePoint(operator.value, 'start')
  };
};
export const getBeforeDateOperationWithoutTimezones = operator => {
  if (!I18n.moment.isMoment(operator.value)) {
    return invariant(false, `${operator.value} is not a valid date value`);
  }
  if (operator.field && operator.field.type === DATE_TIME) {
    return getBeforeDateOperationWithTimezones(operator);
  }
  const hasTimezones = operator && operator.hasTimezones;
  if (hasTimezones) {
    return {
      propertyParser: TimeOperationPropertyParserTypes.VALUE,
      propertyType: OperationTypes.TIME_POINT,
      operator: OperatorTypes.IS_BEFORE,
      timePoint: convertMomentDateToTimePoint(operator.value, 'start')
    };
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION,
    propertyType: OperationTypes.TIME_POINT,
    operator: OperatorTypes.IS_BEFORE,
    timePoint: convertMomentDateToTimePoint(operator.value, 'start')
  };
};
export const getBeforeDateOperation = (operator, options) => {
  if (hasRemoveTimezoneForDates(options.isUngated)) {
    return getBeforeDateOperationWithoutTimezones(operator);
  } else {
    return getBeforeDateOperationWithTimezones(operator);
  }
};
export const getBetweenDatesWithTimezonesOperation = operator => {
  if (!I18n.moment.isMoment(operator.value) || !I18n.moment.isMoment(operator.highValue)) {
    return invariant(false, `value ${operator.value} or highValue ${operator.highValue} is not a valid date value`);
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE,
    propertyType: OperationTypes.RANGED_TIME,
    operator: OperatorTypes.IS_BETWEEN,
    lowerBoundTimePoint: convertMomentDateToTimePoint(operator.value, 'start'),
    upperBoundTimePoint: convertMomentDateToTimePoint(operator.highValue, 'end'),
    lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
    upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
  };
};
export const getBetweenDatesWithoutTimezonesOperation = operator => {
  if (!I18n.moment.isMoment(operator.value) || !I18n.moment.isMoment(operator.highValue)) {
    return invariant(false, `value ${operator.value} or highValue ${operator.highValue} is not a valid date value`);
  }
  if (operator.field && operator.field.type === DATE_TIME) {
    return getBetweenDatesWithTimezonesOperation(operator);
  }
  const hasTimezones = operator && operator.hasTimezones;
  if (hasTimezones) {
    return {
      propertyParser: TimeOperationPropertyParserTypes.VALUE,
      propertyType: OperationTypes.RANGED_TIME,
      operator: OperatorTypes.IS_BETWEEN,
      lowerBoundTimePoint: convertMomentDateToTimePoint(operator.value, 'start'),
      upperBoundTimePoint: convertMomentDateToTimePoint(operator.highValue, 'end'),
      lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
      upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
    };
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION,
    propertyType: OperationTypes.RANGED_TIME,
    operator: OperatorTypes.IS_BETWEEN,
    lowerBoundTimePoint: convertMomentDateToTimePoint(operator.value, 'start'),
    upperBoundTimePoint: convertMomentDateToTimePoint(operator.highValue, 'end'),
    lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
    upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
  };
};
export const getBetweenDatesOperation = (operator, options) => {
  if (hasRemoveTimezoneForDates(options.isUngated)) {
    return getBetweenDatesWithoutTimezonesOperation(operator);
  } else {
    return getBetweenDatesWithTimezonesOperation(operator);
  }
};
export const getNotBetweenDatesWithTimezonesOperation = operator => {
  if (!I18n.moment.isMoment(operator.value) || !I18n.moment.isMoment(operator.highValue)) {
    return invariant(false, `value ${operator.value} or highValue ${operator.highValue} is not a valid date value`);
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE,
    propertyType: OperationTypes.RANGED_TIME,
    operator: OperatorTypes.IS_NOT_BETWEEN,
    lowerBoundTimePoint: convertMomentDateToTimePoint(operator.value, 'start'),
    upperBoundTimePoint: convertMomentDateToTimePoint(operator.highValue, 'end'),
    lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
    upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
  };
};
export const getNotBetweenDatesWithoutTimezonesOperation = operator => {
  if (!I18n.moment.isMoment(operator.value) || !I18n.moment.isMoment(operator.highValue)) {
    return invariant(false, `value ${operator.value} or highValue ${operator.highValue} is not a valid date value`);
  }
  if (operator.field && operator.field.type === DATE_TIME) {
    return getNotBetweenDatesWithTimezonesOperation(operator);
  }
  const hasTimezones = operator && operator.hasTimezones;
  if (hasTimezones) {
    return {
      propertyParser: TimeOperationPropertyParserTypes.VALUE,
      propertyType: OperationTypes.RANGED_TIME,
      operator: OperatorTypes.IS_NOT_BETWEEN,
      lowerBoundTimePoint: convertMomentDateToTimePoint(operator.value, 'start'),
      upperBoundTimePoint: convertMomentDateToTimePoint(operator.highValue, 'end'),
      lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
      upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
    };
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION,
    propertyType: OperationTypes.RANGED_TIME,
    operator: OperatorTypes.IS_NOT_BETWEEN,
    lowerBoundTimePoint: convertMomentDateToTimePoint(operator.value, 'start'),
    upperBoundTimePoint: convertMomentDateToTimePoint(operator.highValue, 'end'),
    lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
    upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
  };
};
export const getNotBetweenDatesOperation = (operator, options) => {
  if (hasRemoveTimezoneForDates(options.isUngated)) {
    return getNotBetweenDatesWithoutTimezonesOperation(operator);
  } else {
    return getNotBetweenDatesWithTimezonesOperation(operator);
  }
};
const getMoreThanXDaysAgoWithTimezonesOperation = operator => {
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE,
    propertyType: OperationTypes.TIME_POINT,
    operator: OperatorTypes.IS_BEFORE,
    timePoint: {
      timeType: TimePointTimeTypes.INDEXED,
      indexReference: {
        referenceType: IndexedTimePointReferenceTypes.TODAY
      },
      offset: {
        days: -operator.numberOfDays
      },
      zoneId: operator.zoneId || I18n.moment.portalTz().tz() || 'UTC'
    },
    endpointBehavior: TimeOperationEndpointBehaviorTypes.EXCLUSIVE
  };
};
const getMoreThanXDaysAgoWithoutTimezonesOperation = operator => {
  if (operator.field && operator.field.type === DATE_TIME) {
    return getMoreThanXDaysAgoWithTimezonesOperation(operator);
  }
  const hasTimezones = operator && operator.hasTimezones;
  if (hasTimezones) {
    return {
      propertyParser: TimeOperationPropertyParserTypes.VALUE,
      propertyType: OperationTypes.TIME_POINT,
      operator: OperatorTypes.IS_BEFORE,
      timePoint: {
        timeType: TimePointTimeTypes.INDEXED,
        indexReference: {
          referenceType: IndexedTimePointReferenceTypes.TODAY
        },
        offset: {
          days: -operator.numberOfDays
        },
        zoneId: operator.zoneId || I18n.moment.portalTz().tz() || 'UTC'
      },
      endpointBehavior: TimeOperationEndpointBehaviorTypes.EXCLUSIVE
    };
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION,
    propertyType: OperationTypes.TIME_POINT,
    operator: OperatorTypes.IS_BEFORE,
    timePoint: {
      timeType: TimePointTimeTypes.INDEXED,
      indexReference: {
        referenceType: IndexedTimePointReferenceTypes.TODAY
      },
      offset: {
        days: -operator.numberOfDays
      },
      zoneId: I18n.moment.portalTz().tz() || 'UTC'
    },
    endpointBehavior: TimeOperationEndpointBehaviorTypes.EXCLUSIVE
  };
};
const getMoreThanXDaysAgoOperation = (operator, options) => {
  if (hasRemoveTimezoneForDates(options.isUngated)) {
    return getMoreThanXDaysAgoWithoutTimezonesOperation(operator);
  } else {
    return getMoreThanXDaysAgoWithTimezonesOperation(operator);
  }
};
const getMoreThanXDaysFromNowWithTimezonesOperation = operator => {
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE,
    propertyType: OperationTypes.TIME_POINT,
    operator: OperatorTypes.IS_AFTER,
    timePoint: {
      timeType: TimePointTimeTypes.INDEXED,
      indexReference: {
        referenceType: IndexedTimePointReferenceTypes.TODAY
      },
      offset: {
        days: operator.numberOfDays
      },
      zoneId: operator.zoneId || I18n.moment.portalTz().tz() || 'UTC'
    },
    endpointBehavior: TimeOperationEndpointBehaviorTypes.EXCLUSIVE
  };
};
const getMoreThanXDaysFromNowWithoutTimezonesOperation = operator => {
  if (operator.field && operator.field.type === DATE_TIME) {
    return getMoreThanXDaysFromNowWithTimezonesOperation(operator);
  }
  const hasTimezones = operator && operator.hasTimezones;
  if (hasTimezones) {
    return {
      propertyParser: TimeOperationPropertyParserTypes.VALUE,
      propertyType: OperationTypes.TIME_POINT,
      operator: OperatorTypes.IS_AFTER,
      timePoint: {
        timeType: TimePointTimeTypes.INDEXED,
        indexReference: {
          referenceType: IndexedTimePointReferenceTypes.TODAY
        },
        offset: {
          days: operator.numberOfDays
        },
        zoneId: operator.zoneId || I18n.moment.portalTz().tz() || 'UTC'
      },
      endpointBehavior: TimeOperationEndpointBehaviorTypes.EXCLUSIVE
    };
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION,
    propertyType: OperationTypes.TIME_POINT,
    operator: OperatorTypes.IS_AFTER,
    timePoint: {
      timeType: TimePointTimeTypes.INDEXED,
      indexReference: {
        referenceType: IndexedTimePointReferenceTypes.TODAY
      },
      offset: {
        days: operator.numberOfDays
      },
      zoneId: I18n.moment.portalTz().tz() || 'UTC'
    },
    endpointBehavior: TimeOperationEndpointBehaviorTypes.EXCLUSIVE
  };
};
const getMoreThanXDaysFromNowOperation = (operator, options) => {
  if (hasRemoveTimezoneForDates(options.isUngated)) {
    return getMoreThanXDaysFromNowWithoutTimezonesOperation(operator);
  } else {
    return getMoreThanXDaysFromNowWithTimezonesOperation(operator);
  }
};
export const getMoreThanXDaysOperation = (operator, options) => {
  const numberOfDays = operator.numberOfDays;
  if (typeof numberOfDays !== 'number' || Number.isNaN(numberOfDays)) {
    return invariant(false, `${numberOfDays} is not a valid number of days for ${operator.constructor.name} operator`);
  }
  const direction = operator.direction;
  if (direction === 'backward') {
    return getMoreThanXDaysAgoOperation(operator, options);
  } else if (direction === 'forward') {
    return getMoreThanXDaysFromNowOperation(operator, options);
  }
  return invariant(false, `${direction} is not a valid direction for ${operator.constructor.name} operator`);
};
const getLessThanXDaysAgoWithTimezonesOperation = operator => {
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE,
    propertyType: OperationTypes.RANGED_TIME,
    operator: OperatorTypes.IS_BETWEEN,
    lowerBoundTimePoint: {
      timeType: TimePointTimeTypes.INDEXED,
      indexReference: {
        referenceType: IndexedTimePointReferenceTypes.TODAY
      },
      offset: {
        days: -operator.numberOfDays
      },
      zoneId: operator.zoneId || I18n.moment.portalTz().tz() || 'UTC'
    },
    upperBoundTimePoint: {
      timeType: TimePointTimeTypes.INDEXED,
      indexReference: {
        referenceType: IndexedTimePointReferenceTypes.NOW
      },
      zoneId: operator.zoneId || I18n.moment.portalTz().tz() || 'UTC'
    },
    lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
    upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
  };
};
const getLessThanXDaysAgoWithoutTimezonesOperation = operator => {
  if (operator.field && operator.field.type === DATE_TIME) {
    return getLessThanXDaysAgoWithTimezonesOperation(operator);
  }
  const hasTimezones = operator && operator.hasTimezones;
  if (hasTimezones) {
    return {
      propertyParser: TimeOperationPropertyParserTypes.VALUE,
      propertyType: OperationTypes.RANGED_TIME,
      operator: OperatorTypes.IS_BETWEEN,
      lowerBoundTimePoint: {
        timeType: TimePointTimeTypes.INDEXED,
        indexReference: {
          referenceType: IndexedTimePointReferenceTypes.TODAY
        },
        offset: {
          days: -operator.numberOfDays
        },
        zoneId: operator.zoneId || I18n.moment.portalTz().tz() || 'UTC'
      },
      upperBoundTimePoint: {
        timeType: TimePointTimeTypes.INDEXED,
        indexReference: {
          referenceType: IndexedTimePointReferenceTypes.NOW
        },
        zoneId: operator.zoneId || I18n.moment.portalTz().tz() || 'UTC'
      },
      lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
      upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
    };
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION,
    propertyType: OperationTypes.RANGED_TIME,
    operator: OperatorTypes.IS_BETWEEN,
    lowerBoundTimePoint: {
      timeType: TimePointTimeTypes.INDEXED,
      indexReference: {
        referenceType: IndexedTimePointReferenceTypes.TODAY
      },
      offset: {
        days: -operator.numberOfDays
      },
      zoneId: I18n.moment.portalTz().tz() || 'UTC'
    },
    upperBoundTimePoint: {
      timeType: TimePointTimeTypes.INDEXED,
      indexReference: {
        referenceType: IndexedTimePointReferenceTypes.NOW
      },
      zoneId: I18n.moment.portalTz().tz() || 'UTC'
    },
    lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
    upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
  };
};
const getLessThanXDaysAgoOperation = (operator, options) => {
  if (hasRemoveTimezoneForDates(options.isUngated)) {
    return getLessThanXDaysAgoWithoutTimezonesOperation(operator);
  } else {
    return getLessThanXDaysAgoWithTimezonesOperation(operator);
  }
};
const getLessThanXDaysFromNowWithTimezonesOperation = operator => {
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE,
    propertyType: OperationTypes.RANGED_TIME,
    operator: OperatorTypes.IS_BETWEEN,
    lowerBoundTimePoint: {
      timeType: TimePointTimeTypes.INDEXED,
      indexReference: {
        referenceType: IndexedTimePointReferenceTypes.NOW
      },
      zoneId: operator.zoneId || I18n.moment.portalTz().tz() || 'UTC'
    },
    upperBoundTimePoint: {
      timeType: TimePointTimeTypes.INDEXED,
      indexReference: {
        referenceType: IndexedTimePointReferenceTypes.TODAY
      },
      offset: {
        days: operator.numberOfDays
      },
      zoneId: operator.zoneId || I18n.moment.portalTz().tz() || 'UTC'
    },
    lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
    upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
  };
};
const getLessThanXDaysFromNowWithoutTimezonesOperation = operator => {
  if (operator.field && operator.field.type === DATE_TIME) {
    return getLessThanXDaysFromNowWithTimezonesOperation(operator);
  }
  const hasTimezones = operator && operator.hasTimezones;
  if (hasTimezones) {
    return {
      propertyParser: TimeOperationPropertyParserTypes.VALUE,
      propertyType: OperationTypes.RANGED_TIME,
      operator: OperatorTypes.IS_BETWEEN,
      lowerBoundTimePoint: {
        timeType: TimePointTimeTypes.INDEXED,
        indexReference: {
          referenceType: IndexedTimePointReferenceTypes.NOW
        },
        zoneId: operator.zoneId || I18n.moment.portalTz().tz() || 'UTC'
      },
      upperBoundTimePoint: {
        timeType: TimePointTimeTypes.INDEXED,
        indexReference: {
          referenceType: IndexedTimePointReferenceTypes.TODAY
        },
        offset: {
          days: operator.numberOfDays
        },
        zoneId: operator.zoneId || I18n.moment.portalTz().tz() || 'UTC'
      },
      lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
      upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
    };
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION,
    propertyType: OperationTypes.RANGED_TIME,
    operator: OperatorTypes.IS_BETWEEN,
    lowerBoundTimePoint: {
      timeType: TimePointTimeTypes.INDEXED,
      indexReference: {
        referenceType: IndexedTimePointReferenceTypes.NOW
      },
      zoneId: I18n.moment.portalTz().tz() || 'UTC'
    },
    upperBoundTimePoint: {
      timeType: TimePointTimeTypes.INDEXED,
      indexReference: {
        referenceType: IndexedTimePointReferenceTypes.TODAY
      },
      offset: {
        days: operator.numberOfDays
      },
      zoneId: I18n.moment.portalTz().tz() || 'UTC'
    },
    lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
    upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
  };
};
const getLessThanXDaysFromNowOperation = (operator, options) => {
  if (hasRemoveTimezoneForDates(options.isUngated)) {
    return getLessThanXDaysFromNowWithoutTimezonesOperation(operator);
  } else {
    return getLessThanXDaysFromNowWithTimezonesOperation(operator);
  }
};
export const getLessThanXDaysOperation = (operator, options) => {
  const numberOfDays = operator.numberOfDays;
  if (typeof numberOfDays !== 'number' || Number.isNaN(numberOfDays)) {
    return invariant(false, `${numberOfDays} is not a valid number of days for ${operator.constructor.name} operator`);
  }
  if (operator.direction === 'backward') {
    return getLessThanXDaysAgoOperation(operator, options);
  } else if (operator.direction === 'forward') {
    return getLessThanXDaysFromNowOperation(operator, options);
  }
  return invariant(false, `${operator.direction} is not a valid direction for ${operator.constructor.name} operator`);
};
export const getAfterAnotherPropertyOperation = operator => {
  if (typeof operator.value !== 'string') {
    return invariant(false, `${operator.value} is not a valid property name`);
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE,
    propertyType: OperationTypes.TIME_POINT,
    operator: OperatorTypes.IS_AFTER,
    timePoint: {
      timeType: TimePointTimeTypes.PROPERTY_REFERENCED,
      property: operator.value,
      referenceType: DateTimePointReferenceTypes.VALUE,
      zoneId: I18n.moment.portalTz().tz() || 'UTC'
    },
    endpointBehavior: TimeOperationEndpointBehaviorTypes.EXCLUSIVE
  };
};
export const getBeforeAnotherPropertyOperation = operator => {
  if (typeof operator.value !== 'string') {
    return invariant(false, `${operator.value} is not a valid property name`);
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.VALUE,
    propertyType: OperationTypes.TIME_POINT,
    operator: OperatorTypes.IS_BEFORE,
    timePoint: {
      timeType: TimePointTimeTypes.PROPERTY_REFERENCED,
      property: operator.value,
      referenceType: DateTimePointReferenceTypes.VALUE,
      zoneId: I18n.moment.portalTz().tz() || 'UTC'
    },
    endpointBehavior: TimeOperationEndpointBehaviorTypes.EXCLUSIVE
  };
};
export const getPropertyUpdatedInLastXDaysOperation = operator => {
  const numberOfDays = operator.numberOfDays;
  if (typeof numberOfDays !== 'number' || Number.isNaN(numberOfDays)) {
    return invariant(false, `${numberOfDays} is not a valid number of days for ${operator.name} operator`);
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.UPDATED_AT,
    propertyType: OperationTypes.RANGED_TIME,
    operator: OperatorTypes.IS_BETWEEN,
    lowerBoundTimePoint: {
      timeType: TimePointTimeTypes.INDEXED,
      indexReference: {
        referenceType: IndexedTimePointReferenceTypes.TODAY
      },
      offset: {
        days: -numberOfDays
      },
      zoneId: I18n.moment.portalTz().tz() || 'UTC'
    },
    upperBoundTimePoint: {
      timeType: TimePointTimeTypes.INDEXED,
      indexReference: {
        referenceType: IndexedTimePointReferenceTypes.NOW
      },
      zoneId: I18n.moment.portalTz().tz() || 'UTC'
    },
    lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
    upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
  };
};
export const getPropertyNotUpdatedInLastXDaysOperation = operator => {
  const numberOfDays = operator.numberOfDays;
  if (typeof numberOfDays !== 'number' || Number.isNaN(numberOfDays)) {
    return invariant(false, `${numberOfDays} is not a valid number of days for ${operator.name} operator`);
  }
  return {
    propertyParser: DateTimePointReferenceTypes.UPDATED_AT,
    propertyType: OperationTypes.RANGED_TIME,
    operator: OperatorTypes.IS_NOT_BETWEEN,
    lowerBoundTimePoint: {
      timeType: TimePointTimeTypes.INDEXED,
      indexReference: {
        referenceType: IndexedTimePointReferenceTypes.TODAY
      },
      offset: {
        days: -numberOfDays
      },
      zoneId: I18n.moment.portalTz().tz() || 'UTC'
    },
    upperBoundTimePoint: {
      timeType: TimePointTimeTypes.INDEXED,
      indexReference: {
        referenceType: IndexedTimePointReferenceTypes.NOW
      },
      zoneId: I18n.moment.portalTz().tz() || 'UTC'
    },
    lowerBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE,
    upperBoundEndpointBehavior: TimeOperationEndpointBehaviorTypes.INCLUSIVE
  };
};
export const getUpdatedAfterPropertyOperation = operator => {
  if (typeof operator.value !== 'string') {
    return invariant(false, `${operator.value} is not a valid property name`);
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.UPDATED_AT,
    propertyType: OperationTypes.TIME_POINT,
    operator: OperatorTypes.IS_AFTER,
    timePoint: {
      timeType: TimePointTimeTypes.PROPERTY_REFERENCED,
      property: operator.value,
      referenceType: DateTimePointReferenceTypes.UPDATED_AT,
      zoneId: I18n.moment.portalTz().tz() || 'UTC'
    },
    endpointBehavior: TimeOperationEndpointBehaviorTypes.EXCLUSIVE
  };
};
export const getUpdatedBeforePropertyOperation = operator => {
  if (typeof operator.value !== 'string') {
    return invariant(false, `${operator.value} is not a valid property name`);
  }
  return {
    propertyParser: TimeOperationPropertyParserTypes.UPDATED_AT,
    propertyType: OperationTypes.TIME_POINT,
    operator: OperatorTypes.IS_BEFORE,
    timePoint: {
      timeType: TimePointTimeTypes.PROPERTY_REFERENCED,
      property: operator.value,
      referenceType: DateTimePointReferenceTypes.UPDATED_AT,
      zoneId: I18n.moment.portalTz().tz() || 'UTC'
    },
    endpointBehavior: TimeOperationEndpointBehaviorTypes.EXCLUSIVE
  };
};