'use es6';

import { __ANY_PAGE } from 'customer-data-filters/converters/listSegClassic/ListSegConstants';
import { COALESCING_REFINE_BY, COALESCING_REFINE_BY_SET_TYPE, COALESCING_REFINE_BY_TYPE, PRUNING_REFINE_BY } from 'customer-data-filters/converters/objectSeg/constants/constants';
import fromRefinementFilter from './fromRefinementFilter';
import getIn from 'transmute/getIn';
import FormFilledOut from 'customer-data-filters/filterQueryFormat/operator/FormNotFilledOut';
const isNegationFilterThatShouldBeIgnored = (operator, field, filter) => {
  // Must be an negated operator
  if (operator === FormFilledOut) {
    return false;
  }

  // and must only have a COALESCING_REFINE_BY
  if (filter[PRUNING_REFINE_BY]) {
    return false;
  }
  const {
    type,
    setType,
    maxOccurrences
  } = filter[COALESCING_REFINE_BY] || {};

  // refinement where occurrences is NONE
  const isUsingSetOccurrence = type === COALESCING_REFINE_BY_TYPE.SET_OCCURRENCES;
  const isSetToNone = setType === COALESCING_REFINE_BY_SET_TYPE.NONE || setType === COALESCING_REFINE_BY_SET_TYPE.NONE_EXCLUDE_EMPTY;
  const isNegateUsingSetOccurrence = isUsingSetOccurrence && isSetToNone;

  // refinement where max number is 0
  const isUsingNumOccurrence = type === COALESCING_REFINE_BY_TYPE.NUM_OCCURRENCES;
  const isSetToZero = maxOccurrences === 0;
  const isNegateUsingNumOccurrence = isUsingNumOccurrence && isSetToZero;
  return isNegateUsingSetOccurrence || isNegateUsingNumOccurrence;
};
const tryAddRefinement = (operator, field, filter) => {
  if (isNegationFilterThatShouldBeIgnored(operator, field, filter)) {
    return operator;
  }
  const refinement = fromRefinementFilter(field, filter);
  return refinement ? operator.set('refinement', refinement) : operator;
};
const fromFormSubmissionOperation = (Operator, field, filter) => {
  const pageId = getIn(['pageId'], filter);
  const defaultPageValue = __ANY_PAGE;
  const operator = Operator.of(field, pageId || defaultPageValue);
  return tryAddRefinement(operator, field, filter);
};
export { fromFormSubmissionOperation };