'use es6';

import { useQuery } from 'data-fetching-client';
import { FETCH_PRO_FUNNEL_OPT_IN_AVAILABILITY } from '../requests/optIns';
const useProFunnelOptInAvailability = () => {
  const {
    data,
    error
  } = useQuery(FETCH_PRO_FUNNEL_OPT_IN_AVAILABILITY);
  return {
    data: data ? data.proFunnelOptInAvailability : {},
    error
  };
};
export default useProFunnelOptInAvailability;