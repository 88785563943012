'use es6';

import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import * as PropertyTypes from 'customer-data-objects/property/PropertyTypes';
import { Map as ImmutableMap } from 'immutable';
import DefaultNullValueRecord from 'customer-data-filters/filterQueryFormat/DefaultNullValueRecord';
import MissingField from '../filterQueryFormat/MissingField';
import { getObjectTypeIdSupportedFamily, ObjectTypeIdRegex } from '../filterQueryFormat/ObjectTypeId';
const alwaysSupportedOperators = {
  boolean: [Operators.NotEqual, Operators.NeverEqual],
  datetimePropComparison: [],
  datetime: [Operators.NotInRange],
  enumeration: [Operators.NotIn, Operators.NeverIn, Operators.NotEqualAll, Operators.NotContainAll, Operators.NeverEqualAll, Operators.NeverContainedAll],
  number: [Operators.NotEqual, Operators.Less, Operators.LessOrEqual, Operators.NeverEqual],
  string: [Operators.NotEqualAny, Operators.NotContainAny, Operators.NeverEqualAny, Operators.NeverContained]
};
const ifPrevSetSupportedOperators = {
  boolean: [Operators.Equal, Operators.EverEqual],
  datetimePropComparison: [Operators.After, Operators.Before],
  datetime: [],
  enumeration: [Operators.In, Operators.EverIn],
  number: [Operators.Equal, Operators.Greater, Operators.GreaterOrEqual, Operators.EverEqual],
  string: [Operators.EqualAny, Operators.ContainAny]
};
const getBooleanDefaultNullValue = operator => alwaysSupportedOperators.boolean.includes(operator.constructor) || ifPrevSetSupportedOperators.boolean.includes(operator.constructor) ? DefaultNullValueRecord({
  defaultValue: false
}) : undefined;
const getDateDefaultNullValue = operator => {
  if (alwaysSupportedOperators.datetimePropComparison.includes(operator.constructor) || ifPrevSetSupportedOperators.datetimePropComparison.includes(operator.constructor)) {
    return DefaultNullValueRecord({
      defaultComparisonValue: 0
    });
  }
  if (alwaysSupportedOperators.datetime.includes(operator.constructor) || ifPrevSetSupportedOperators.datetime.includes(operator.constructor)) {
    return DefaultNullValueRecord({
      defaultValue: 0
    });
  }
  return undefined;
};
const getEnumerationDefaultNullValue = operator => alwaysSupportedOperators.enumeration.includes(operator.constructor) || ifPrevSetSupportedOperators.enumeration.includes(operator.constructor) ? DefaultNullValueRecord({
  defaultValue: ''
}) : undefined;
const getNumberDefaultNullValue = operator => alwaysSupportedOperators.number.includes(operator.constructor) || ifPrevSetSupportedOperators.number.includes(operator.constructor) ? DefaultNullValueRecord({
  defaultValue: 0
}) : undefined;
const getStringDefaultNullValue = operator => alwaysSupportedOperators.string.includes(operator.constructor) || ifPrevSetSupportedOperators.string.includes(operator.constructor) ? DefaultNullValueRecord({
  defaultValue: ''
}) : undefined;
export const getDefaultNullValue = (operator, includeObjectsWithNoValueSet = undefined) => {
  let defaultNullValue;
  switch (operator.field.type) {
    case PropertyTypes.BOOLEAN:
      defaultNullValue = getBooleanDefaultNullValue(operator);
      break;
    case PropertyTypes.DATE:
    case PropertyTypes.DATE_TIME:
      defaultNullValue = getDateDefaultNullValue(operator);
      break;
    case PropertyTypes.ENUMERATION:
      defaultNullValue = getEnumerationDefaultNullValue(operator);
      break;
    case PropertyTypes.NUMBER:
      defaultNullValue = getNumberDefaultNullValue(operator);
      break;
    case PropertyTypes.STRING:
      defaultNullValue = getStringDefaultNullValue(operator);
      break;
    default:
      defaultNullValue = undefined;
  }
  if (operator.constructor === Operators.NotUpdatedInLastXDays) {
    // defaultNullValue = undefined;
    defaultNullValue = DefaultNullValueRecord();
  }
  return defaultNullValue ? defaultNullValue.set('includeObjectsWithNoValueSet', includeObjectsWithNoValueSet) : DefaultNullValueRecord();
};
export const operatorDefaultNullValueConditions = ImmutableMap({
  [PropertyTypes.NUMBER]: ImmutableMap({
    [Operators.Equal.toString()]: (defaultValue, value) => defaultValue === value,
    [Operators.NotEqual.toString()]: (defaultValue, value) => defaultValue !== value,
    [Operators.Less.toString()]: (defaultValue, value) => defaultValue < value,
    [Operators.LessOrEqual.toString()]: (defaultValue, value) => defaultValue <= value,
    [Operators.Greater.toString()]: (defaultValue, value) => defaultValue > value,
    [Operators.GreaterOrEqual.toString()]: (defaultValue, value) => defaultValue >= value,
    // will always return false since defaultValue will always be null
    // https://git.hubteam.com/HubSpot/customer-data-filters/pull/3633#discussion_r2737167
    [Operators.EverEqual.toString()]: () => false,
    [Operators.NeverEqual.toString()]: defaultValue => defaultValue !== null
  })
});
export function isDefaultNullValueDefinedOnFilterValue(operator) {
  const defaultValue = operator.getIn(['defaultNullValue', 'defaultValue']);
  const defaultComparisonValue = operator.getIn(['defaultNullValue', 'defaultComparisonValue']);

  // default value of '' will never included objects for some properties, prevent "or is empty" from being shown
  // see: https://issues.hubspotcentral.com/browse/WORKFLOWS-5894
  if (operator.constructor === Operators.In) {
    const prop = operator.getIn(['field', 'name']);
    if (prop === 'pipeline' || prop === 'dealstage') {
      return false;
    }
  }
  return defaultValue !== null && defaultValue !== undefined || defaultComparisonValue !== null && defaultComparisonValue !== undefined;
}
export function isExplicitlyIncludeObjectsWithNoValueSet(operator) {
  const include = operator.getIn(['defaultNullValue', 'includeObjectsWithNoValueSet']);
  return include;
}
export function isOperatorIncludesObjectsWithNoValueSet(operator) {
  // do nothing until field is fetched
  if (MissingField.isMissingField(operator.field)) {
    return false;
  }
  if (isExplicitlyIncludeObjectsWithNoValueSet(operator)) {
    return true;
  }
  const propType = operator.field.type;
  const operatorName = operator.constructor.toString();
  const shouldIncludeObjectsWithNoValueSet = operatorDefaultNullValueConditions.getIn([propType, operatorName]);
  if (shouldIncludeObjectsWithNoValueSet) {
    return isDefaultNullValueDefinedOnFilterValue(operator) && shouldIncludeObjectsWithNoValueSet(parseFloat(operator.defaultNullValue.defaultValue), operator.value);
  }
  return isDefaultNullValueDefinedOnFilterValue(operator);
}
export function shouldOperatorAlwaysShowNoValueCheckbox(filterFamily, operator) {
  const maybeObjectTypeId = getObjectTypeIdSupportedFamily(filterFamily);
  if (!ObjectTypeIdRegex.test(maybeObjectTypeId)) {
    return false;
  }
  switch (operator.field.type) {
    case PropertyTypes.BOOLEAN:
      return alwaysSupportedOperators.boolean.includes(operator.constructor);
    case PropertyTypes.DATE:
    case PropertyTypes.DATE_TIME:
      return alwaysSupportedOperators.datetimePropComparison.includes(operator.constructor) || alwaysSupportedOperators.datetime.includes(operator.constructor);
    case PropertyTypes.ENUMERATION:
      return alwaysSupportedOperators.enumeration.includes(operator.constructor);
    case PropertyTypes.NUMBER:
      return alwaysSupportedOperators.number.includes(operator.constructor);
    case PropertyTypes.STRING:
      return alwaysSupportedOperators.string.includes(operator.constructor);
    default:
      return false;
  }
}