import { useAsyncFetcher } from 'reporting-data/asyncData/useAsyncFetcher';
import { DATASET_INSIGHT_TYPES } from 'reporting-data/tsTypes/datasetInsightTypes';
import { isSucceeded } from 'reporting-data/asyncData/AsyncData';
import { fetchDatasetInsightTypeOptions } from '../lib/datasetInsightUtils';
const useFetchDatasetInsightTypeOptions = (report, hasAdvancedInsightsAccess) => {
  const datasetInsightTypeOptionsFetch = useAsyncFetcher({
    requestArgs: report,
    fetcher: fetchDatasetInsightTypeOptions,
    deferred: !report
  });
  if (isSucceeded(datasetInsightTypeOptionsFetch)) {
    if (!hasAdvancedInsightsAccess) {
      return datasetInsightTypeOptionsFetch.data.map(option => {
        if (option.value === DATASET_INSIGHT_TYPES.ANOMALY_DETECTION || option.value === DATASET_INSIGHT_TYPES.FORECAST_TRENDLINE) {
          return Object.assign({}, option, {
            icon: 'locked'
          });
        }
        return option;
      });
    }
    return datasetInsightTypeOptionsFetch.data;
  }
  return undefined;
};
export default useFetchDatasetInsightTypeOptions;