'use es6';

import { List, Set as ImmutableSet } from 'immutable';
import { DEALS } from '../../constants/dataTypes';
import { CLOSEDWON_STAGES } from '../../constants/magicTypes';
import { get as getPipelines } from '../../retrieve/inboundDb/pipelines';
export default ((config, paths = ImmutableSet()) => getPipelines(DEALS).then(pipelines => {
  const closedWonStages = List(pipelines).flatMap(pipeline => pipeline.stages.filter(stage => parseFloat(stage.metadata.probability) === 1).map(stage => stage.stageId));
  return paths.reduce((memo, path) => {
    const valuesPath = path.slice(0, -1);
    const index = path[path.length - 1];
    return memo.updateIn(['filters', ...valuesPath], values => values.get(index) === CLOSEDWON_STAGES ? values.splice(index, 1, ...closedWonStages) : values);
  }, config);
}));