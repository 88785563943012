'use es6';

import { TICKET_PROPERTIES } from 'reference-resolvers/constants/CacheKeys';
import { getTicketProperties, createGetTicketProperties } from 'reference-resolvers/api/PropertiesAPI';
import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
export const createTicketPropertyReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: TICKET_PROPERTIES,
  createFetchData: createGetTicketProperties,
  fetchData: getTicketProperties
}, options));
export default createTicketPropertyReferenceResolver();