export const CUSTOM_WIDGET_TYPES = {
  ACTIVITY_FEED: 'ACTIVITY_FEED',
  CHANGE_HISTORY_DEAL_LIST: 'CHANGE_HISTORY_DEAL_LIST',
  COMPLETED_ACTIVITIES: 'COMPLETED_ACTIVITIES',
  CONTACTS_OVERVIEW: 'CONTACTS_OVERVIEW',
  DATA_LAYER: 'DATA_LAYER',
  DEAL_FORECAST: 'DEAL_FORECAST',
  DEAL_PUSH_ANALYSIS: 'DEAL_PUSH_ANALYSIS',
  ENGAGEMENT_STREAM: 'ENGAGEMENT_STREAM',
  MARKETING_PERFORMANCE: 'MARKETING_PERFORMANCE',
  PERSONA: 'PERSONA',
  PIPELINE_SNAPSHOTS: 'PIPELINE_SNAPSHOTS',
  SALES_PERFORMANCE: 'SALES_PERFORMANCE',
  SALES_QUOTA: 'SALES_QUOTA',
  TASKS_STREAM: 'TASKS_STREAM',
  WATERFALL: 'WATERFALL'
};