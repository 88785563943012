export default function useUnreadRepliesCount({
  collaborationSidebarState,
  moduleParentComment
}) {
  const {
    unreadRepliesMap
  } = collaborationSidebarState;
  const unreadRepliesCount = moduleParentComment && unreadRepliesMap[moduleParentComment.id] && unreadRepliesMap[moduleParentComment.id].length || 0;
  const isParentCommentRead = moduleParentComment && moduleParentComment ? !!moduleParentComment.read : true;

  // If the parent comment is not read add it to the unread comments count
  const unreadCommentsCount = isParentCommentRead ? unreadRepliesCount : unreadRepliesCount + 1;
  return {
    unreadCommentsCount
  };
}