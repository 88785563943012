export const DEFAULT_START = '2006-01-01';
export let MONTHS;
(function (MONTHS) {
  MONTHS["JANUARY"] = "JANUARY";
  MONTHS["FEBRUARY"] = "FEBRUARY";
  MONTHS["MARCH"] = "MARCH";
  MONTHS["APRIL"] = "APRIL";
  MONTHS["MAY"] = "MAY";
  MONTHS["JUNE"] = "JUNE";
  MONTHS["JULY"] = "JULY";
  MONTHS["AUGUST"] = "AUGUST";
  MONTHS["SEPTEMBER"] = "SEPTEMBER";
  MONTHS["OCTOBER"] = "OCTOBER";
  MONTHS["NOVEMBER"] = "NOVEMBER";
  MONTHS["DECEMBER"] = "DECEMBER";
})(MONTHS || (MONTHS = {}));
export const DEFAULT_FISCAL_YEAR_MONTH_NAME = MONTHS.JANUARY;