'use es6';

import PropTypes from 'prop-types';
const ReferenceResolverLiteType = PropTypes.shape({
  useFetchQuery: PropTypes.func.isRequired,
  useSearchQuery: PropTypes.func.isRequired,
  api: PropTypes.shape({
    byId: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    refreshCache: PropTypes.func.isRequired
  }).isRequired,
  referenceType: PropTypes.string.isRequired
});
export default ReferenceResolverLiteType;