'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["originalGroupName"];
import { createSelector } from 'reselect';
import getIn from 'transmute/getIn';
import { RequestState } from 'dashboard-lib/public/request/request-state';
import { selectDashboardFilters } from './dashboardFilters';
import { getFilterQueryFormatOperator } from '../lib/dashboardFilters/filterConversionHelpers';
import { updateQuickFilterGroups, fetchGeneratedQuickFilterGroups } from '../data/QuickFilterDAO';
import { fetchDashboard } from '../actions/DashboardActions';
import { handleErrorMessage } from '../lib/quickFilters/quickFilterUtils';
import { checkIfQuickFilterGroupIsApplied, doPropertiesInGroupShareDropdownOptions, doPropertiesInGroupShareExternallyReferencedObject, extractQuickFilterGroupFromAppliedFilters, getQuickFilterGroupWithProperty, isEditingQuickFilterGroupNameUnique, isQuickFilterGroupNameUnique, isQuickFilterGroupPropertyTypesSame, isUsingRecordIdPropertyInGroup, findDuplicateProperties } from '../lib/dashboardFilters/groupQuickFilterHelpers';
export const quickFiltersNamespace = 'quickFilters';

// const
export const QUICK_FILTER_LIMIT = 5;
export const KEY_SPLITTER = '/';

// this is for API request actions
export const QUICK_FILTER_REQUEST_ACTION = {
  LOAD: 'load',
  PIN: 'pin',
  UNPIN: 'unpin',
  MODIFY: 'modify',
  REMOVE_FROM_GROUP: 'remove_from_group'
};

// Action types
const SET_DASHBOARD_ID = 'SET_DASHBOARD_ID';
const SET_QUICK_FILTER_GROUPS = 'SET_QUICK_FILTER_GROUPS';
const FETCH_SUCCEEDED = 'FETCH_SUCCEEDED';
const FETCH_FAILED = 'FETCH_FAILED';
const FETCHING = 'FETCHING';
const ADD_QUICK_FILTER_TO_GROUP_DRAFT = 'ADD_QUICK_FILTER_TO_GROUP_DRAFT';
const REMOVE_QUICK_FILTER_FROM_GROUP_DRAFT = 'REMOVE_QUICK_FILTER_FROM_GROUP_DRAFT';
const EDIT_QUICK_FILTER_GROUP = 'EDIT_QUICK_FILTER_GROUP';
const UPDATE_EDIT_QUICK_FILTER_DRAFT_GROUP_NAME_DESC = 'UPDATE_EDIT_QUICK_FILTER_DRAFT_GROUP_NAME_DESC';
const RESET_DRAFT_QUICK_FITLER_GROUP = 'RESET_DRAFT_QUICK_FITLER_GROUP';

// HELPERS
export const getPinnedPropertyActivityMessageOption = entry => {
  const oldValue = entry.get('oldValue');
  const newValue = entry.get('newValue');
  const oldValueSize = oldValue.size;
  const newValueSize = newValue.size;
  if (oldValueSize === newValueSize) {
    return 'reorder';
  }
  return oldValueSize > newValueSize ? 'unpin' : 'pin';
};
export const getQuickFilterUniqueKey = ({
  dataSource,
  name
}) => `${dataSource}${KEY_SPLITTER}${name}`;
const getQuickFilterGroupDataSources = quickFilterGroup => {
  const {
    properties
  } = quickFilterGroup;
  return properties ? [...new Set(properties.map(property => property.dataSourceId))] : [];
};
export const getAppliedFilterOperator = appliedQuickFilterOperators => (dataSource, property, name) => {
  const key = getQuickFilterUniqueKey({
    dataSource,
    name
  });
  if (!appliedQuickFilterOperators.has(key)) {
    return null;
  }
  return getFilterQueryFormatOperator(appliedQuickFilterOperators.get(key), property);
};
const getUngroupedQuickFilters = pinnedPropertyGroups => {
  const ungroupedQuickFilters = pinnedPropertyGroups.filter(quickFilterGroup => {
    // Ungrouped quick filters do not have a grouped filter name
    return !quickFilterGroup.name;
  });
  if (!ungroupedQuickFilters) {
    return [];
  }
  return ungroupedQuickFilters.reduce((acc, ungroupedQuickFilter) => {
    return [...acc, ...ungroupedQuickFilter.properties];
  }, []);
};
const getGroupedQuickFilters = pinnedPropertyGroups => {
  const groupedQuickFilters = pinnedPropertyGroups.filter(quickFilterGroup => {
    // Grouped quick filters have a grouped filter name
    return quickFilterGroup.name;
  });
  return groupedQuickFilters;
};
const isQuickFilterGroupValid = (quickFilterGroup, allQuickFilterGroups, dataSourceProperties) => {
  if (!quickFilterGroup.properties.length || quickFilterGroup.properties.length < 1) {
    return false;
  }
  if (!quickFilterGroup.name || quickFilterGroup.name.length < 3) {
    return false;
  }
  const isEditingQuickFilterGroup = ('originalGroupName' in quickFilterGroup);
  if (isEditingQuickFilterGroup ? !isEditingQuickFilterGroupNameUnique(quickFilterGroup.name, quickFilterGroup, allQuickFilterGroups) : !isQuickFilterGroupNameUnique(quickFilterGroup, allQuickFilterGroups)) {
    return false;
  }
  if (!isQuickFilterGroupPropertyTypesSame(quickFilterGroup, dataSourceProperties)) {
    return false;
  }
  if (isUsingRecordIdPropertyInGroup(quickFilterGroup, dataSourceProperties)) {
    return false;
  }
  if (!doPropertiesInGroupShareDropdownOptions(quickFilterGroup, dataSourceProperties)) {
    return false;
  }
  return doPropertiesInGroupShareExternallyReferencedObject(quickFilterGroup, dataSourceProperties);
};
const prepareQuickFilterGroupsPayload = (quickFilterGroups, quickFilters) => {
  // Format individual quickFilters to the proper shape for the payload.
  const ungroupedQuickFilters = quickFilters.reduce((acc, quickFilter) => {
    return [...acc, {
      properties: [quickFilter]
    }];
  }, []);
  return [...quickFilterGroups, ...ungroupedQuickFilters];
};
const replaceTargetIndexQuickFilterGroup = (quickFilterGroups, targetFilterGroupIndex, filterGroupToUpdate) => {
  return [...quickFilterGroups.slice(0, targetFilterGroupIndex), filterGroupToUpdate, ...quickFilterGroups.slice(targetFilterGroupIndex + 1)];
};
const prepareUpdateTargetIndexQuickFilterGroupPayload = ({
  quickFilterGroups,
  targetGroupName,
  groupDataToUpdate
}) => {
  const groupIndexToUpdate = quickFilterGroups.findIndex(group => group.name === targetGroupName);
  const updatedGroup = Object.assign({}, quickFilterGroups[groupIndexToUpdate], groupDataToUpdate, {
    systemGenerated: false
  });
  return replaceTargetIndexQuickFilterGroup(quickFilterGroups, groupIndexToUpdate, updatedGroup);
};

// Selectors
export const selectQuickFilters = state => {
  return (getIn([quickFiltersNamespace, 'quickFilters'], state) || []).filter(quickFilter => !quickFilter.pinnedProperties);
};
export const selectQuickFilterGroups = state => {
  return getIn([quickFiltersNamespace, 'quickFilterGroups'], state) || [];
};
export const selectGivenPinnedPropertyToProperty = (pinnedProperties, dataSourceProperties) => () => {
  return pinnedProperties.map(({
    dataSourceId,
    name
  }) => {
    return {
      dataSource: dataSourceId,
      name,
      property: getIn([dataSourceId, 'properties', name], dataSourceProperties)
    };
  });
};
export const selectQuickFilterToProperty = dataSourceProperties => createSelector([selectQuickFilters], quickFilters => quickFilters.map(({
  dataSourceId,
  name
}) => {
  return {
    dataSource: dataSourceId,
    name,
    property: getIn([dataSourceId, 'properties'], dataSourceProperties)
  };
}));
export const selectQuickFilterGroupsWithDefinedProperties = dataSourceProperties => createSelector([selectQuickFilterGroups], quickFilterGroups => {
  return quickFilterGroups.map(quickFilterGroup => {
    const {
      properties
    } = quickFilterGroup;
    const definedPropertiesList = properties.map(({
      dataSourceId,
      name
    }) => {
      const dataSourcePropertiesList = dataSourceProperties && getIn([dataSourceId, 'properties'], dataSourceProperties);
      return {
        dataSource: dataSourceId,
        name,
        property: getIn([name], dataSourcePropertiesList)
      };
    });
    return Object.assign({}, quickFilterGroup, {
      properties: definedPropertiesList
    });
  });
});
export const selectQuickFiltersDataSources = createSelector([selectQuickFilters, selectQuickFilterGroups], (quickFilters, quickFilterGroups) => {
  const quickFilterDataSources = quickFilters.map(quickFilter => quickFilter.dataSourceId);
  const quickFilterGroupsDataSources = quickFilterGroups.reduce((memo, quickFilterGroup) => {
    const quickFilterGroupDataSources = getQuickFilterGroupDataSources(quickFilterGroup);
    return [...memo, ...quickFilterGroupDataSources];
  }, []);

  // Use a Set to remove duplicates.
  return [...new Set([...quickFilterDataSources, ...quickFilterGroupsDataSources])];
});
export const selectQuickFilterOperators = dashboardId => createSelector([selectQuickFilters, selectDashboardFilters(dashboardId)], (quickFilters, appliedFilters) => {
  return quickFilters.reduce((memo, quickFilter) => {
    const {
      dataSourceId,
      name
    } = quickFilter;
    const [filterValue] = appliedFilters.filter(appliedFilter => appliedFilter.propertyName === name && appliedFilter.dataSource === dataSourceId);
    if (filterValue) {
      memo.set(getQuickFilterUniqueKey({
        dataSource: dataSourceId,
        name
      }), filterValue);
    }
    return memo;
  }, new Map());
});
export const selectPinnedItemCount = state => {
  const quickFilters = selectQuickFilters(state) || [];
  const quickFilterGroups = selectQuickFilterGroups(state) || [];
  return quickFilters.length + quickFilterGroups.length;
};
export const selectIsPinnedQuickFilterReachLimit = state => {
  return selectPinnedItemCount(state) === QUICK_FILTER_LIMIT;
};
export const selectHasQuickFilterApplied = state => selectPinnedItemCount(state) > 0;
export const selectHasQuickFilterGroupBeenApplied = ({
  quickFilterGroup,
  dashboardId
}) => createSelector([selectDashboardFilters(dashboardId)], appliedFilters => {
  const hasQuickFilterGroupBeenApplied = checkIfQuickFilterGroupIsApplied(quickFilterGroup, appliedFilters);
  return hasQuickFilterGroupBeenApplied;
});
export const selectQuickFilterGroupAppliedOperator = ({
  quickFilterGroup,
  dashboardId,
  dataSourcesProperties
}) => createSelector([selectHasQuickFilterGroupBeenApplied({
  quickFilterGroup,
  dashboardId
}), selectDashboardFilters(dashboardId)], (hasQuickFilterGroupBeenApplied, appliedFilters) => {
  if (hasQuickFilterGroupBeenApplied) {
    const {
      properties
    } = quickFilterGroup;
    if (!properties) {
      return null;
    }
    const firstProperty = properties.at(0);
    const firstPropertyDataSource = firstProperty['dataSource'];
    const firstPropertyName = firstProperty['name'];
    const quickFilterGroupAppliedFilters = extractQuickFilterGroupFromAppliedFilters(quickFilterGroup, appliedFilters);
    const groupAppliedFilter = quickFilterGroupAppliedFilters.find(appliedFilter => {
      const {
        dataSource,
        propertyName
      } = appliedFilter;
      return dataSource === firstPropertyDataSource && propertyName === firstPropertyName;
    });
    return groupAppliedFilter ? getFilterQueryFormatOperator(groupAppliedFilter, getIn([firstPropertyDataSource, 'properties'], dataSourcesProperties)) : null;
  }
  return null;
});
export const selectIsQuickFilterPropertyPinned = ({
  dataSourceId,
  propertyName
}) => state => {
  const quickFilters = selectQuickFilters(state);
  return quickFilters.some(quickFilter => quickFilter.dataSourceId === dataSourceId && quickFilter.name === propertyName);
};
export const selectQuickFilterGroupWithProperty = ({
  dataSourceId,
  propertyName
}) => createSelector(selectQuickFilterGroups, quickFilterGroups => getQuickFilterGroupWithProperty(quickFilterGroups, propertyName, dataSourceId));
export const selectShouldDisableAction = state => {
  const fetchStatus = getIn([quickFiltersNamespace, 'fetchQuickFilterStatus'], state);
  return fetchStatus === RequestState.UNINITIALIZED || fetchStatus === RequestState.PENDING;
};
export const selectCurrentEditingGroup = state => {
  return getIn([quickFiltersNamespace, 'currentEditingGroup'], state);
};
export const selectIsQuickFilterGroupValid = (quickFilterGroup, dataSourceProperties) => createSelector([selectQuickFilterGroups], allQuickFilterGroups => isQuickFilterGroupValid(quickFilterGroup, allQuickFilterGroups, dataSourceProperties));
export const selectIfCurrentEditingGroupIsValid = dataSourceProperties => createSelector([selectCurrentEditingGroup, selectQuickFilterGroups], (currentEditingGroup, allQuickFilterGroups) => isQuickFilterGroupValid(currentEditingGroup, allQuickFilterGroups, dataSourceProperties));
export const selectCurrentEditingGroupDataType = dataSourceProperties => createSelector([selectCurrentEditingGroup], currentEditingGroup => {
  const properties = currentEditingGroup.properties;
  if (!properties.length) {
    return null;
  }
  const [{
    dataSourceId,
    name
  }] = properties;
  return getIn([dataSourceId, 'properties', name], dataSourceProperties);
});
export const selectIfPropertyInCurrentEditingGroup = ({
  dataSourceId,
  name
}) => state => {
  const currentEditingGroup = selectCurrentEditingGroup(state);
  return currentEditingGroup.properties.filter(property => property.dataSourceId === dataSourceId && property.name === name).length > 0;
};
export const setQuickFilterGroups = pinnedPropertyGroups => dispatch => {
  const ungroupedQuickFilters = getUngroupedQuickFilters(pinnedPropertyGroups);
  const quickFilterGroups = getGroupedQuickFilters(pinnedPropertyGroups);
  dispatch({
    type: SET_QUICK_FILTER_GROUPS,
    payload: {
      quickFilters: ungroupedQuickFilters,
      quickFilterGroups
    }
  });
};
export const onFetchCompletes = dispatch => {
  dispatch({
    type: FETCH_SUCCEEDED
  });
};
export const onFetchError = dispatch => {
  dispatch({
    type: FETCH_FAILED
  });
  return action => {
    handleErrorMessage(action);
  };
};
export const onFetching = dispatch => {
  dispatch({
    type: FETCHING
  });
};
export const resetDraftQuickFilterGroup = () => dispatch => {
  dispatch({
    type: RESET_DRAFT_QUICK_FITLER_GROUP
  });
};
export const editQuickFilterGroup = quickFilterGroup => dispatch => {
  dispatch({
    type: EDIT_QUICK_FILTER_GROUP,
    payload: {
      quickFilterGroup
    }
  });
};
export const onUpdateQuickFilterGroup = dispatch => {
  return ({
    dashboardId,
    newQuickFilterGroupData,
    action = QUICK_FILTER_REQUEST_ACTION.MODIFY
  }) => {
    dispatch({
      type: FETCHING
    });
    updateQuickFilterGroups(dashboardId, newQuickFilterGroupData).then(() => {
      setQuickFilterGroups(newQuickFilterGroupData)(dispatch);
      onFetchCompletes(dispatch);
      resetDraftQuickFilterGroup()(dispatch);

      // This call is necessary to update the Dashboard object stored in the Flux store.
      // Otherwise, the dashboard object stored there would be out of date.
      fetchDashboard(dashboardId);
    }).catch(() => onFetchError(dispatch)(action));
  };
};
export const setDashboardId = dashboardId => dispatch => {
  dispatch({
    type: SET_DASHBOARD_ID,
    payload: {
      dashboardId
    }
  });
};
export const addQuickFilterToDraftGroup = quickFilter => (dispatch, getState) => {
  const state = getState();
  if (selectIfPropertyInCurrentEditingGroup(quickFilter)(state)) {
    return;
  }
  dispatch({
    type: ADD_QUICK_FILTER_TO_GROUP_DRAFT,
    payload: quickFilter
  });
};
export const removeQuickFilterFromDraftGroup = quickFilter => dispatch => {
  dispatch({
    type: REMOVE_QUICK_FILTER_FROM_GROUP_DRAFT,
    payload: quickFilter
  });
};
export const updateDraftQuickFilterGroupNameDesc = formData => dispatch => {
  dispatch({
    type: UPDATE_EDIT_QUICK_FILTER_DRAFT_GROUP_NAME_DESC,
    payload: formData
  });
};
export const pinUngroupedQuickFilter = filterToPin => (dispatch, getState) => {
  const state = getState();
  const quickFilterGroups = selectQuickFilterGroups(state);
  const ungroupedQuickFilter = selectQuickFilters(state);
  const dashboardId = getIn([quickFiltersNamespace, 'dashboardId'], state);

  // Add the filter to pin to
  ungroupedQuickFilter.push(Object.assign({}, filterToPin));
  const newQuickFilterGroupData = prepareQuickFilterGroupsPayload(quickFilterGroups, ungroupedQuickFilter);

  // Add validation does not exceed 5 (ungrouped + grouped)
  // add validation to check if property is existing in either ungrouped or grouped

  onUpdateQuickFilterGroup(dispatch)({
    dashboardId,
    newQuickFilterGroupData,
    action: QUICK_FILTER_REQUEST_ACTION.PIN
  });
};
export const unpinUngroupedQuickFilter = filterToUnpin => (dispatch, getState) => {
  const state = getState();
  const quickFilters = getIn([quickFiltersNamespace, 'quickFilters'], state) || [];
  const quickFilterGroup = selectQuickFilterGroups(state) || [];
  const dashboardId = getIn([quickFiltersNamespace, 'dashboardId'], state);
  const {
    dataSourceId,
    name
  } = filterToUnpin;
  const updatedQuickFilters = quickFilters.filter(filter => !(filter.dataSourceId === dataSourceId && filter.name === name));
  const newQuickFilterGroupData = prepareQuickFilterGroupsPayload(quickFilterGroup, updatedQuickFilters);
  onUpdateQuickFilterGroup(dispatch)({
    dashboardId,
    newQuickFilterGroupData,
    action: QUICK_FILTER_REQUEST_ACTION.UNPIN
  });
};
export const pinCurrentEditingGroup = dataSourceProperties => (dispatch, getState) => {
  const state = getState();
  const isValid = selectIfCurrentEditingGroupIsValid(dataSourceProperties)(state);
  if (!isValid) {
    return;
  }
  const dashboardId = getIn([quickFiltersNamespace, 'dashboardId'], state);
  const quickFilterGroups = selectQuickFilterGroups(state);
  const currentEditingGroup = selectCurrentEditingGroup(state);
  const {
      originalGroupName
    } = currentEditingGroup,
    filterGroupToUpdate = _objectWithoutPropertiesLoose(currentEditingGroup, _excluded);
  let updatedQuickFilterGroupWithNewGroup = [];
  if (currentEditingGroup.originalGroupName === '') {
    /* add new group */
    const existingGroupIndexWithSameName = quickFilterGroups.findIndex(quickFilterGroup => quickFilterGroup.name === currentEditingGroup.name);
    if (existingGroupIndexWithSameName !== -1) {
      return;
    }
    updatedQuickFilterGroupWithNewGroup = [...quickFilterGroups, filterGroupToUpdate];
  } else {
    const targetFilterGroupIndex = quickFilterGroups.findIndex(quickFilterGroup => quickFilterGroup.name === originalGroupName);
    if (targetFilterGroupIndex === -1) {
      return; // TODO: add error message;
    }
    const existingGroupIndexWithSameName = quickFilterGroups.findIndex((quickFilterGroup, index) => quickFilterGroup.name === currentEditingGroup.name && index !== targetFilterGroupIndex);
    if (existingGroupIndexWithSameName !== -1) {
      return;
    }
    updatedQuickFilterGroupWithNewGroup = replaceTargetIndexQuickFilterGroup(quickFilterGroups, targetFilterGroupIndex, filterGroupToUpdate);
  }
  const ungroupedQuickFilters = selectQuickFilters(state);
  const newQuickFilterGroupData = prepareQuickFilterGroupsPayload(updatedQuickFilterGroupWithNewGroup, ungroupedQuickFilters);
  onUpdateQuickFilterGroup(dispatch)({
    dashboardId,
    newQuickFilterGroupData
  });
};
export const unpinQuickFilterGroup = quickFilterGroup => (dispatch, getState) => {
  const state = getState();
  const quickFilters = getIn([quickFiltersNamespace, 'quickFilters'], state) || [];
  const quickFilterGroups = selectQuickFilterGroups(state) || [];
  const dashboardId = getIn([quickFiltersNamespace, 'dashboardId'], state);
  const {
    name
  } = quickFilterGroup;
  const updatedQuickFilterGroups = quickFilterGroups.filter(group => group.name !== name);
  const newQuickFilterGroupData = prepareQuickFilterGroupsPayload(updatedQuickFilterGroups, quickFilters);
  onUpdateQuickFilterGroup(dispatch)({
    dashboardId,
    newQuickFilterGroupData,
    action: QUICK_FILTER_REQUEST_ACTION.UNPIN
  });
};
export const unpinAllQuickFilterGroups = () => (dispatch, getState) => {
  const state = getState();
  const dashboardId = getIn([quickFiltersNamespace, 'dashboardId'], state);
  onUpdateQuickFilterGroup(dispatch)({
    dashboardId,
    newQuickFilterGroupData: [],
    action: QUICK_FILTER_REQUEST_ACTION.UNPIN
  });
};
export const removePropertyFromGroup = (quickFilterGroup, propertyToRemove) => (dispatch, getState) => {
  const state = getState();
  const dashboardId = getIn([quickFiltersNamespace, 'dashboardId'], state);
  const quickFilters = getIn([quickFiltersNamespace, 'quickFilters'], state) || [];
  const quickFilterGroups = selectQuickFilterGroups(state) || [];
  const {
    name: groupName,
    properties
  } = quickFilterGroup;
  const updatedProperties = properties.filter(property => {
    return !(property.name === propertyToRemove.name && property.dataSourceId === propertyToRemove.dataSource);
  });

  // If there are no remaining properties in the quick filter group, then remove it
  // from the list of quick filter groups.
  // Otherwise, update the modified quick filter group.
  const updatedQuickFilterGroups = updatedProperties.length === 0 ? quickFilterGroups.filter(group => group.name !== groupName) : prepareUpdateTargetIndexQuickFilterGroupPayload({
    quickFilterGroups,
    targetGroupName: groupName,
    groupDataToUpdate: {
      properties: updatedProperties
    }
  });
  const newQuickFilterGroupData = prepareQuickFilterGroupsPayload(updatedQuickFilterGroups, quickFilters);
  onUpdateQuickFilterGroup(dispatch)({
    dashboardId,
    newQuickFilterGroupData,
    action: QUICK_FILTER_REQUEST_ACTION.REMOVE_FROM_GROUP
  });
};
export const pinGeneratedQuickFilterGroups = generatedQuickFilterGroups => (dispatch, getState) => {
  const state = getState();
  const dashboardId = getIn([quickFiltersNamespace, 'dashboardId'], state);
  const quickFilters = selectQuickFilters(state) || [];
  const quickFilterGroups = selectQuickFilterGroups(state) || [];
  const updatedQuickFilterGroups = [...generatedQuickFilterGroups, ...quickFilterGroups];
  const newQuickFilterGroupData = prepareQuickFilterGroupsPayload(updatedQuickFilterGroups, quickFilters);
  onUpdateQuickFilterGroup(dispatch)({
    dashboardId,
    newQuickFilterGroupData,
    action: QUICK_FILTER_REQUEST_ACTION.PIN
  });
};
export const generateQuickFilterGroups = ({
  dashboardId,
  quickFilterGroupGenerationOptionsList
}) => (dispatch, getState) => {
  const state = getState();
  const quickFilters = selectQuickFilters(state) || [];
  const quickFilterGroups = selectQuickFilterGroups(state) || [];
  return fetchGeneratedQuickFilterGroups(dashboardId, quickFilterGroupGenerationOptionsList).then(generatedQuickFilterGroups => {
    const duplicateProperties = findDuplicateProperties(generatedQuickFilterGroups, quickFilterGroups, quickFilters);
    const duplicatesExists = duplicateProperties.length > 0;
    if (!duplicatesExists) {
      dispatch(pinGeneratedQuickFilterGroups(generatedQuickFilterGroups));
    }
    return {
      duplicatesExists,
      generatedQuickFilterGroups
    };
  }).catch(err => {
    console.error(err);
    return err;
  });
};
export const updateQuickFiltersWithConflictsResolved = ({
  updatedQuickFilterGroups,
  updatedQuickFilters
}) => (dispatch, getState) => {
  const state = getState();
  const dashboardId = getIn([quickFiltersNamespace, 'dashboardId'], state);
  const newQuickFilterGroupData = prepareQuickFilterGroupsPayload(updatedQuickFilterGroups, updatedQuickFilters);
  onUpdateQuickFilterGroup(dispatch)({
    dashboardId,
    newQuickFilterGroupData,
    action: QUICK_FILTER_REQUEST_ACTION.PIN
  });
};

// Reducer
export const quickFilterReducer = (state = {
  quickFilters: [],
  isPanelOpen: false,
  fetchQuickFilterStatus: RequestState.UNINITIALIZED,
  quickFilterGroups: [],
  currentEditingGroup: {
    properties: [],
    name: '',
    description: '',
    originalGroupName: ''
  }
}, action) => {
  switch (action.type) {
    case SET_DASHBOARD_ID:
      {
        // KEEP a dashboard id reference in the store.
        const {
          dashboardId
        } = action.payload;
        return Object.assign({}, state, {
          dashboardId
        });
      }
    case SET_QUICK_FILTER_GROUPS:
      {
        const {
          quickFilters,
          quickFilterGroups
        } = action.payload;
        return Object.assign({}, state, {
          quickFilters,
          quickFilterGroups
        });
      }
    case FETCHING:
      {
        return Object.assign({}, state, {
          fetchQuickFilterStatus: RequestState.PENDING
        });
      }
    case FETCH_SUCCEEDED:
      {
        return Object.assign({}, state, {
          fetchQuickFilterStatus: RequestState.SUCCEEDED
        });
      }
    case FETCH_FAILED:
      {
        return Object.assign({}, state, {
          fetchQuickFilterStatus: RequestState.FAILURE
        });
      }
    case RESET_DRAFT_QUICK_FITLER_GROUP:
      {
        return Object.assign({}, state, {
          currentEditingGroup: {
            properties: [],
            name: '',
            description: '',
            originalGroupName: ''
          }
        });
      }
    case EDIT_QUICK_FILTER_GROUP:
      {
        const {
          quickFilterGroup
        } = action.payload;
        return Object.assign({}, state, {
          currentEditingGroup: Object.assign({}, quickFilterGroup, {
            originalGroupName: quickFilterGroup.name
          })
        });
      }
    case ADD_QUICK_FILTER_TO_GROUP_DRAFT:
      {
        const {
          quickFilter
        } = action.payload;
        return Object.assign({}, state, {
          currentEditingGroup: Object.assign({}, state.currentEditingGroup, {
            properties: [...state.currentEditingGroup.properties, quickFilter],
            systemGenerated: false
          })
        });
      }
    case REMOVE_QUICK_FILTER_FROM_GROUP_DRAFT:
      {
        const {
          quickFilter
        } = action.payload;
        return Object.assign({}, state, {
          currentEditingGroup: Object.assign({}, state.currentEditingGroup, {
            properties: state.currentEditingGroup.properties.filter(property => {
              return !(property.dataSourceId === quickFilter.dataSourceId && property.name === quickFilter.name);
            }),
            systemGenerated: false
          })
        });
      }
    case UPDATE_EDIT_QUICK_FILTER_DRAFT_GROUP_NAME_DESC:
      {
        const formData = action.payload;
        return Object.assign({}, state, {
          currentEditingGroup: Object.assign({}, state.currentEditingGroup, formData, {
            systemGenerated: false
          })
        });
      }
    default:
      return state;
  }
};