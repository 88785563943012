import { useCallback } from 'react';
import { isSucceeded } from 'reporting-data/asyncData/AsyncData';
import { useAsyncFetcher } from 'reporting-data/asyncData/useAsyncFetcher';
import { userInfo as fetchUserInfo } from 'reporting-data/request/user-info';
const useFetchUserInfo = () => {
  const fetcher = useCallback(() => fetchUserInfo(), []);
  const userInfoFetch = useAsyncFetcher({
    fetcher
  });
  if (isSucceeded(userInfoFetch)) {
    return userInfoFetch.data;
  }
};
export default useFetchUserInfo;