import I18n from 'I18n';
import { List, Map as ImmutableMap } from 'immutable';
import { CRM_OBJECT } from '../../constants/dataTypes';
import { GLOBAL_NULL } from '../../constants/defaultNullValues';
import { PERCENTILES } from '../../constants/metricTypes';
import { CURRENCY, DURATION, ENUMERATION, ID, NUMBER, PERCENT } from '../../constants/property-types';
// @ts-expect-error untyped dependency
import { getReferenceLabeler } from '../../crmObjects/references';
import propertyValueFormatter, { formatEnumeration
// @ts-expect-error untyped dependency
} from '../../hydrate/propertyFormatter';
import { filterOutEmptySearchValues } from '../../lib/normalizeEmptiness';
// @ts-expect-error untyped dependency
import { shouldBeSmallScale } from '../../lib/smallScale';
// @ts-expect-error untyped dependency
import { LINKS_FIELD } from '../dataset/constants';
import { defaultDatasetBuildOptions } from './datasetBuildOptions';
import { Reference } from './snowflake/records';
// @ts-expect-error untyped dependency
import { isCustomObjectType } from './../../lib/customObjects';
// @ts-expect-error Untyped dependency
import { CUSTOM_OBJECT } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { NUMERIC } from '../../constants/transforms';
const getBeProvidedUrls = (reportingApiDataset, value, key, rowHsObjectId) => {
  const valuesArray = List.isList(value) ? value : List([value]);
  const urlMap = valuesArray.reduce((accum, val) => {
    const url =
    // Look for URL in "links" first with hs_object_id
    reportingApiDataset.getIn(['links', key, val, rowHsObjectId]) ||
    // Look for URL in value nested due to BE data structure
    reportingApiDataset.getIn(['links', key, val, val]) ||
    // Next look for URL in "links"
    reportingApiDataset.getIn(['links', key, val]) ||
    // Look for URL in identifier references as a fallback for: https://git.hubteam.com/HubSpot/Reporting-as-a-Service/issues/1142
    reportingApiDataset.getIn(['identifiers', key, val, 'references', 'url']);
    return url ? accum.set(String(val), url) : accum;
  }, ImmutableMap({}));
  return urlMap;
};
const getReferenceType = propertyInfo => {
  let referenceType = propertyInfo.getIn(['format', 'referenceMeta', 'referenceType']);
  if (referenceType === CRM_OBJECT) {
    referenceType = propertyInfo.getIn(['format', 'referenceMeta', 'objectTypeId']);
    if (isCustomObjectType(referenceType)) {
      return CUSTOM_OBJECT;
    }
  }
  return referenceType;
};
const formatData = (reportingApiDataset, options) => {
  const typesByProperty = reportingApiDataset.get('header').map(prop => prop.getIn(['format', 'type'])).toMap();
  const typeIsNumeric = reportingApiDataset.get('header').map(prop => {
    const type = prop.getIn(['format', 'type']);
    if (type === NUMBER && options.isSearch) {
      return prop.getIn(['format', 'isFormatted']);
    }
    const transform = prop.getIn(['format', 'transform']);
    if (transform === NUMERIC) {
      return true;
    }
    return [PERCENT, NUMBER, CURRENCY, DURATION].includes(type === ID ? prop.getIn(['format', 'subType']) : type);
  });
  return reportingApiDataset.get('data').map((row, rowNum) => {
    if (options.isSearch && !options.isRelational) {
      row = filterOutEmptySearchValues(row, typesByProperty).toMap();
    }
    const rowHsObjectId = row.get('hs_object_id');
    return row.reduce((obj, value, key) => {
      const urlMap = getBeProvidedUrls(reportingApiDataset, value, key, rowHsObjectId);
      if (!urlMap.isEmpty()) {
        obj = obj.setIn([LINKS_FIELD, key], urlMap);
      }
      if (!typeIsNumeric.get(key)) {
        return obj.set(key, value);
      }
      const excludeFinalConversions = options.removeLastRowPercentiles && rowNum === reportingApiDataset.get('data').count() - 1;
      if (excludeFinalConversions && options.isMeasure(key) && options.getMeasureType(key) === PERCENTILES && value === GLOBAL_NULL) {
        return obj;
      }
      const parsedValue = !isNaN(value) && value !== null && value !== '' ? parseFloat(value) : value;
      const adjustedValue = parsedValue === GLOBAL_NULL && options.isMeasure(key) ? null : parsedValue;
      return obj.set(key, adjustedValue);
    }, ImmutableMap());
  }).toList();
};
const formatPagination = reportingApiDataset => {
  return reportingApiDataset.getIn(['pagination', 'total']);
};
export const shouldFormatByReferenceLabeler = propertyInfo => (propertyInfo.getIn(['format', 'type']) === ID || propertyInfo.hasIn(['format', 'referenceMeta'])) && !propertyInfo.has('aggregationType');
const hydrateValue = (row, value, propertyInfo, identifiers, {
  smallScale = false,
  isRelational = false
} = {}) => {
  if (shouldFormatByReferenceLabeler(propertyInfo)) {
    return getReferenceLabeler(getReferenceType(propertyInfo))(identifiers.getIn([String(value), 'references'], ImmutableMap()), String(value), {
      isRelational
    });
  }
  if (value === GLOBAL_NULL) {
    return I18n.text('reporting-data.missing.value');
  }
  if (propertyInfo.getIn(['format', 'type']) === NUMBER && !propertyInfo.getIn(['format', 'isFormatted'])) {
    return value;
  }
  if (propertyInfo.getIn(['format', 'type']) === ENUMERATION) {
    return propertyInfo.getIn(['format', 'options', String(value), 'label'], formatEnumeration(value));
  }
  propertyInfo = propertyInfo.set('name', propertyInfo.get('field')).set('type', propertyInfo.getIn(['format', 'type']));
  const currencyCodeColumnName = propertyInfo.getIn(['format', 'currencyCodeColumnName']);
  const currencyCode = currencyCodeColumnName && row.get(currencyCodeColumnName);
  return propertyValueFormatter(value, propertyInfo, {
    currencyCode: currencyCode === GLOBAL_NULL ? null : currencyCode,
    frequency: propertyInfo.getIn(['format', 'frequency']),
    smallScale
  });
};

// While this override is not in line with our goal of trusting property definitions, customers have a
// strong preference to see a full timestamp rather than a datetime in search reports: https://issues.hubspotcentral.com/browse/RA-13829
const overrideTimestampProperties = (properties, isSearch) => {
  if (!isSearch) {
    return properties;
  }
  const timestamps = ['engagement.timestamp', 'hs_timestamp'];
  return properties.map(property => {
    if (timestamps.includes(property.get('field'))) {
      return property.setIn(['format', 'type'], 'timestamp');
    }
    return property;
  });
};
const getReferenceHydrator = (response, {
  smallScaleMetrics = ImmutableMap()
} = {}, options) => {
  const header = overrideTimestampProperties(response.get('header'), options.isSearch);
  const identifiers = response.get('identifiers');
  return (obj, refs, refKey, value, currencyCodeColumnName) => {
    if (value === null || value === undefined) {
      return refs;
    }
    const label = hydrateValue(obj, value, header.get(refKey), identifiers && identifiers.get(refKey) ? identifiers.get(refKey) : ImmutableMap(), {
      smallScale: options.isSearch || smallScaleMetrics.get(refKey),
      isRelational: options.isRelational
    });
    const url = identifiers && identifiers.getIn([refKey, value, 'references', 'url']);
    const currencyCode = currencyCodeColumnName ? obj.get(currencyCodeColumnName) : undefined;
    const referenceKey = currencyCode && currencyCode !== GLOBAL_NULL ? `${currencyCode}|${String(value)}` : String(value);
    return refs.set(referenceKey, Reference({
      id: referenceKey,
      label: String(label !== null ? label : value)
    })).update(referenceKey, reference => url ? reference.set('link', url) : reference);
  };
};
const formatProperties = (reportingApiDataset, data, options) => {
  const header = reportingApiDataset.get('header');
  const smallScaleMetrics = shouldBeSmallScale(ImmutableMap({
    data,
    properties: header.reduce((acc, property, propertyName) => {
      return acc.setIn([propertyName, 'type'], property.getIn(['format', 'type']));
    }, ImmutableMap())
  }), List(header.filter(property => property.get('columnType') === 'METRIC').keys()));
  const hydrateReferences = getReferenceHydrator(reportingApiDataset, {
    smallScaleMetrics
  }, options);
  return header.reduce((datasetProperties, value, key) => {
    const format = value.get('format', ImmutableMap());
    const lastRow = data.get(-1, ImmutableMap());
    const currencyCodeColumnName = format.get('currencyCodeColumnName');

    // https://git.hubteam.com/HubSpot/reporting/pull/8071#discussion_r1400868
    const rowCurrency = currencyCodeColumnName && !options.hasCurrencyDimension(currencyCodeColumnName) ? lastRow.get(currencyCodeColumnName, GLOBAL_NULL) : GLOBAL_NULL;
    const optionalFields = {};
    if (smallScaleMetrics.get(key)) {
      optionalFields.shouldBeSmallScale = true;
    }
    if (value.get('scripted', false)) {
      optionalFields.scripted = true;
    }
    if (value.get('propertyMeta')) {
      optionalFields.propertyMeta = value.get('propertyMeta');
    }
    const references = data.reduce((referenceMap, dataObject) => {
      const referenceKey = dataObject.get(key);
      if (List.isList(referenceKey)) {
        return referenceKey.reduce((listReferenceMap, listReferenceKey) => hydrateReferences(dataObject, listReferenceMap, String(key), listReferenceKey, currencyCodeColumnName), referenceMap);
      }
      return hydrateReferences(dataObject, referenceMap, String(key), referenceKey, currencyCodeColumnName);
    }, ImmutableMap());
    const propertyValue = ImmutableMap(Object.assign({
      format: currencyCodeColumnName ? format.set('currencyCode', rowCurrency === GLOBAL_NULL ? null : rowCurrency) : format,
      label: value.get('label'),
      type: format.get('type') === ID && format.get('subType') !== 'number' ? format.get('subType') : format.get('type'),
      name: value.get('field'),
      references
    }, optionalFields));
    return datasetProperties.set(key, propertyValue);
  }, ImmutableMap());
};
export const buildDataset = (reportingApiDataset, options = defaultDatasetBuildOptions) => {
  const formattedData = formatData(reportingApiDataset, options);
  const queryId = reportingApiDataset.get('queryId');
  return Object.assign({
    data: formattedData,
    paginationSize: formatPagination(reportingApiDataset),
    properties: formatProperties(reportingApiDataset, formattedData, options)
  }, queryId && {
    queryId
  }, options.dataAge && {
    dataAge: options.dataAge
  });
};
export const __TESTABLE__ = {
  overrideTimestampProperties,
  formatData,
  getBeProvidedUrls,
  getReferenceType
};