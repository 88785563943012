'use es6';

import { makeOperator } from './Operator';
export default makeOperator({
  name: 'PrivacyConsentNotCompleted',
  values: [{
    name: 'value',
    isValid: value => value != null
  }],
  isRefinable: true
});