import I18n from 'I18n';
export const sortBusinessUnitsByName = (buA, buB) => {
  if (buA.id === 0) return -1;
  if (buB.id === 0) return 1;
  return buA.name.toLocaleLowerCase().localeCompare(buB.name.toLocaleLowerCase());
};
export const getBusinessUnitDisplayName = (name, id) => {
  if (id === 0) {
    return `${name} ${I18n.text('ui-business-units-lib.labels.account')}`;
  }
  return name;
};