'use es6';

import { AssetPermission } from 'dashboard-lib/public/permission/asset-permission';
import compose from 'transmute/compose';
import { defaultTo } from 'dashboard-lib/private/js-util';
import { getPermissionSentence } from 'dashboard-lib/public/dashboard/dashboard-schema';
import { sentenceToPermission } from 'dashboard-lib/public/permission/permission-payload';

/**
 * Requires `permissionSentence` to be hydrated on the subject
 * @param report
 * @returns AssetPermission
 */
export const derivePermission = compose(defaultTo(AssetPermission.ZERO), sentenceToPermission, getPermissionSentence);