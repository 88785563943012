'use es6';

export default ((editorState, regex, trigger) => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const block = contentState.getBlockForKey(selection.getStartKey());
  const blockText = block && block.getText();
  const offset = selection.getStartOffset();
  if (!selection.isCollapsed() || blockText === undefined) {
    return null;
  }
  const matchArray = blockText.slice(0, offset).match(regex);
  if (matchArray === null) {
    return null;
  }
  const beforeCursorText = matchArray[0].slice(trigger.length);
  const pastCursorMatch = blockText.slice(offset).match(/^\w+/);
  const pastCursorText = pastCursorMatch !== null ? pastCursorMatch[0] : '';
  return {
    search: beforeCursorText + pastCursorText,
    offset: offset - beforeCursorText.length - trigger.length,
    length: trigger.length + beforeCursorText.length + pastCursorText.length
  };
});