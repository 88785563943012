export const PAGE_SIZE = 25;
export const mapAsyncResultsToOptions = ({
  callback,
  response
}) => {
  callback(null, {
    options: response.data.listOverview.edges.map(each => ({
      text: each.node.name,
      value: each.node.id
    })),
    pagination: {
      hasMore: response.data.listOverview.pageInfo.hasNextPage,
      after: response.data.listOverview.pageInfo.endCursor,
      pageSize: PAGE_SIZE
    }
  });
};
export const buildObjectListFilterGroup = (listId, objectName) => ({
  id: 1,
  condition: 'OR',
  filters: [{
    id: listId,
    isIgnored: false,
    field: {
      name: 'objectid',
      table: objectName,
      source: 'TABLE',
      type: 'number'
    },
    filter: {
      listId,
      filterType: 'IN_LIST',
      operator: 'IN_LIST'
    }
  }]
});