'use es6';

import { fromJS, List, Map as ImmutableMap } from 'immutable';
import isDevelopment from '../../lib/development';
import State from './records/State';
import ResolveState from './records/ResolveState';
import { ADD_CONFIG, SET_CONFIGURED, SET_PROMISE, SET_STATUS, SET_DATA, DEBUG, baseEnter, baseExit, BUST_ALL_RESOLVES, BUST_ONE_RESOLVE } from './actions';
import { splitConfig, isComparisonConfig } from '../../compare';
import { Config } from '../../config';
const WRITE_DEBUG = isDevelopment();
const isEnterAction = actionType => actionType.startsWith(baseEnter);
const isExitAction = actionType => actionType.startsWith(baseExit);
const normalizeConfig = config => {
  const normalizeDeep = m => m.map(v => ImmutableMap.isMap(v) ? normalizeDeep(v) : v).filter(v => v != null)
  // remove empty lists
  .filterNot(v => List.isList(v) && v.isEmpty())
  // remove empty maps
  .filterNot(v => ImmutableMap.isMap(v) && v.isEmpty());
  return normalizeDeep(fromJS(Config(config).toJS())).update('offset', offset => offset || 0).delete('v2');
};
const findConfigKey = (state, config) => state.get('configs').findKey((_, configKey) => normalizeConfig(configKey).equals(normalizeConfig(config)));
const reindexConfigs = state => {
  const ids = state.get('configs').toList();
  const reindex = id => ids.indexOf(id);
  return state.update('configs', configs => configs.map(reindex)).update('resolving', resolving => resolving.mapKeys(reindex));
};
export default ((state = new State(), action) => {
  const {
    type,
    data = {}
  } = action;
  const {
    config
  } = data;
  if (type === 'SET_RUN_ID') {
    return state.set('runId', data);
  }
  if (type === BUST_ALL_RESOLVES) {
    return new State();
  }
  if (!config) {
    return state;
  }
  if (type === BUST_ONE_RESOLVE) {
    const configKey = findConfigKey(state, config);
    if (!configKey) {
      return state;
    }
    const configId = state.getIn(['configs', configKey], null);
    if (isComparisonConfig(config)) {
      const {
        config: baseConfig,
        compareConfig
      } = splitConfig(config);
      const baseConfigKey = findConfigKey(state, baseConfig);
      const compareConfigKey = findConfigKey(state, compareConfig);
      const baseConfigId = state.getIn(['configs', baseConfigKey], null);
      const compareConfigId = state.getIn(['configs', compareConfigKey], null);
      return reindexConfigs(state.deleteIn(['configs', configKey]).deleteIn(['configs', baseConfigKey]).deleteIn(['configs', compareConfigKey]).deleteIn(['resolving', configId]).deleteIn(['resolving', baseConfigId]).deleteIn(['resolving', compareConfigId]));
    }
    return reindexConfigs(
    // delete the main config
    state.deleteIn(['configs', configKey]).deleteIn(['resolving', configId]));
  }
  const configId = state.getIn(['configs', config], null);
  if (type === ADD_CONFIG) {
    if (!configId) {
      const newConfigId = state.get('configs').count();
      return state.setIn(['configs', config], newConfigId).setIn(['resolving', newConfigId], new ResolveState({
        config
      }));
    }
    return state;
  }
  const update = updater => state.updateIn(['resolving', configId], updater);
  switch (type) {
    case SET_CONFIGURED:
      {
        const {
          configured
        } = data;
        return update(resolveState => resolveState.set('configured', configured));
      }
    case SET_PROMISE:
      {
        const {
          promise
        } = data;
        return update(resolveState => resolveState.set('promise', promise));
      }
    case SET_STATUS:
      {
        const {
          status,
          error = null
        } = data;
        return update(resolveState => resolveState.set('status', status).updateIn(['debug', 'exceptions'], exceptions => error != null ? exceptions.push(error) : exceptions));
      }
    case SET_DATA:
      {
        const {
          data: datasets
        } = data;
        return update(resolveState => resolveState.set('data', datasets));
      }
    case DEBUG:
      {
        const {
          key,
          value
        } = data;
        return update(resolveState => resolveState.updateIn(['debug', key], prevValue => typeof value === 'function' ? value(prevValue) : value));
      }
    default:
  }
  if (isEnterAction(type)) {
    const {
      name,
      input
    } = data;
    return update(resolveState => resolveState.set('currentPhase', name.toUpperCase()).updateIn(['debug', 'phases'], debugState => WRITE_DEBUG ? debugState.setIn([name, 'input'], input) : debugState));
  } else if (isExitAction(type)) {
    const {
      name,
      output
    } = data;
    return update(resolveState => resolveState.set('currentPhase', '').updateIn(['debug', 'phases'], debugState => WRITE_DEBUG ? debugState.setIn([name, 'output'], output) : debugState));
  }
  return state;
});