import * as checked from 'reporting-data/lib/checked';
import { DatasetField } from './dataset-records';
import { Expression, Location } from './expression-records';
import { BooleanType, Type } from './type-records';
export const ViolationTypes = {
  SYNTAX: 'SYNTAX',
  REFERENCE: 'REFERENCE',
  CIRCULAR_DEPENDENCY: 'CIRCULAR_DEPENDENCY',
  TYPE: 'TYPE',
  GENERICS: 'GENERICS',
  CONTEXT: 'CONTEXT',
  DIMENSION_FIELD: 'DIMENSION_FIELD',
  MEASURE_FIELD: 'MEASURE_FIELD',
  CONDITIONAL_FIELD_BOOLEAN: 'CONDITIONAL_FIELD_BOOLEAN',
  PERSISTED_TYPE_DISCREPANCY: 'PERSISTED_TYPE_DISCREPANCY',
  NO_FUNCTION_ACCESS: 'NO_FUNCTION_ACCESS',
  FIELD_LEVEL_PERMISSION: 'FIELD_LEVEL_PERMISSION'
};
export const ViolationType = checked.symbol(ViolationTypes, 'ViolationType');
export const ViolationLevels = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR'
};
export const ViolationLevel = checked.symbol(ViolationLevels, 'ViolationLevel');
export const SyntaxViolation = checked.record({
  violation: ViolationType.always(ViolationTypes.SYNTAX).defaultValue(ViolationTypes.SYNTAX),
  level: ViolationLevel,
  input: checked.string(),
  location: Location.optional(),
  syntaxError: checked.any()
}, 'SyntaxViolation');
export const isSyntaxViolation = violation => violation.violation === ViolationTypes.SYNTAX;
export const ReferenceViolation = checked.record({
  violation: ViolationType.always(ViolationTypes.REFERENCE).defaultValue(ViolationTypes.REFERENCE),
  level: ViolationLevel,
  expression: Expression,
  location: Location,
  field: DatasetField.optional()
}, 'ReferenceViolation');
export const isReferenceViolation = violation => violation.violation === ViolationTypes.REFERENCE;
export const CircularDependencyViolation = checked.record({
  violation: ViolationType.always(ViolationTypes.CIRCULAR_DEPENDENCY).defaultValue(ViolationTypes.CIRCULAR_DEPENDENCY),
  level: ViolationLevel,
  expression: Expression
}, 'CircularDependencyViolation');
export const isCircularDepViolation = violation => violation.violation === ViolationTypes.CIRCULAR_DEPENDENCY;
export const TypeViolation = checked.record({
  violation: ViolationType.always(ViolationTypes.TYPE).defaultValue(ViolationTypes.TYPE),
  level: ViolationLevel,
  expression: Expression,
  parentExpression: Expression,
  argumentIndex: checked.number(),
  location: Location.optional(),
  expected: Type,
  provided: Type
}, 'TypeViolation');
export const isTypeViolation = violation => violation.violation === ViolationTypes.TYPE;
export const GenericsViolation = checked.record({
  violation: ViolationType.always(ViolationTypes.GENERICS).defaultValue(ViolationTypes.GENERICS),
  level: ViolationLevel,
  expression: Expression,
  location: Location.optional(),
  argumentIndices: checked.list(checked.number()),
  genericType: Type,
  providedTypes: checked.list(Type)
}, 'GenericsViolation');
export const isGenericsViolation = violation => violation.violation === ViolationTypes.GENERICS;
export const ContextViolation = checked.record({
  violation: ViolationType.always(ViolationTypes.CONTEXT).defaultValue(ViolationTypes.CONTEXT),
  level: ViolationLevel,
  expression: Expression,
  location: Location.optional()
}, 'ContextViolation');
export const isContextViolation = violation => violation.violation === ViolationTypes.CONTEXT;
export const DimensionFieldViolation = checked.record({
  violation: ViolationType.always(ViolationTypes.DIMENSION_FIELD).defaultValue(ViolationTypes.DIMENSION_FIELD),
  level: ViolationLevel
}, 'DimensionFieldViolation');
export const isDimensionFieldViolation = violation => violation.violation === ViolationTypes.DIMENSION_FIELD;
export const MeasureFieldViolation = checked.record({
  violation: ViolationType.always(ViolationTypes.MEASURE_FIELD).defaultValue(ViolationTypes.MEASURE_FIELD),
  level: ViolationLevel
}, 'MeasureFieldViolation');
export const isMeasureFieldViolation = violation => violation.violation === ViolationTypes.MEASURE_FIELD;
export const ConditionalFieldBooleanViolation = checked.record({
  violation: ViolationType.always(ViolationTypes.CONDITIONAL_FIELD_BOOLEAN).defaultValue(ViolationTypes.CONDITIONAL_FIELD_BOOLEAN),
  expression: Expression,
  expected: BooleanType,
  provided: Type,
  location: Location,
  level: ViolationLevel
}, 'ConditionalFieldBooleanViolation');
export const isConditionalFieldViolation = violation => violation.violation === ViolationTypes.CONDITIONAL_FIELD_BOOLEAN;
export const PersistedTypeDiscrepancyViolation = checked.record({
  violation: ViolationType.always(ViolationTypes.PERSISTED_TYPE_DISCREPANCY).defaultValue(ViolationTypes.PERSISTED_TYPE_DISCREPANCY),
  currentType: Type,
  persistedType: Type.optional(),
  level: ViolationLevel
}, 'PersistedTypeDiscrepancyViolation');
export const isPersistedTypeDiscrepancyViolation = violation => violation.violation === ViolationTypes.PERSISTED_TYPE_DISCREPANCY;
export const NoFunctionAccessViolation = checked.record({
  violation: ViolationType.always(ViolationTypes.NO_FUNCTION_ACCESS).defaultValue(ViolationTypes.NO_FUNCTION_ACCESS),
  expression: Expression,
  location: Location.optional(),
  level: ViolationLevel
}, 'NoFunctionAccessViolation');
export const isNoFunctionAccessViolation = violation => violation.violation === ViolationTypes.NO_FUNCTION_ACCESS;
export const FieldLevelPermissionViolation = checked.record({
  violation: ViolationType.always(ViolationTypes.FIELD_LEVEL_PERMISSION).defaultValue(ViolationTypes.FIELD_LEVEL_PERMISSION),
  expression: Expression,
  location: Location.optional(),
  flpRestrictedFieldLabel: checked.string(),
  level: ViolationLevel
}, 'FieldLevelPermissionViolation');
export const isFieldLevelPermissionViolation = violation => violation.violation === ViolationTypes.FIELD_LEVEL_PERMISSION;
export const Violation = checked.poly('violation', {
  [ViolationTypes.SYNTAX]: SyntaxViolation,
  [ViolationTypes.REFERENCE]: ReferenceViolation,
  [ViolationTypes.CIRCULAR_DEPENDENCY]: CircularDependencyViolation,
  [ViolationTypes.TYPE]: TypeViolation,
  [ViolationTypes.GENERICS]: GenericsViolation,
  [ViolationTypes.CONDITIONAL_FIELD_BOOLEAN]: ConditionalFieldBooleanViolation,
  [ViolationTypes.CONTEXT]: ContextViolation,
  [ViolationTypes.DIMENSION_FIELD]: DimensionFieldViolation,
  [ViolationTypes.MEASURE_FIELD]: MeasureFieldViolation,
  [ViolationTypes.NO_FUNCTION_ACCESS]: NoFunctionAccessViolation,
  [ViolationTypes.FIELD_LEVEL_PERMISSION]: FieldLevelPermissionViolation
}, 'Violation');
export const isInlineViolation = violation => !!violation.location;