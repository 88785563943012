'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { COMPANIES, CONTACTS } from '../../../../constants/dataTypes';
const associationPropertyReferences = ImmutableMap({
  'associations.company': COMPANIES,
  'associations.contact': CONTACTS
});
const fillPropertyReferences = spec => spec.updateIn(['properties', 'references'], ImmutableMap(), referencesOverrides => associationPropertyReferences.set(spec.properties.idProperty, spec.dataType).merge(referencesOverrides));
const associationPropertyPaths = ImmutableMap({
  'associations.company': ['associations', 'associatedCompanyIds'],
  'associations.contact': ['associations', 'associatedVids']
});
const fillPropertyResponsePaths = spec => spec.updateIn(['properties', 'responsePaths'], ImmutableMap(), responsePathOverrides => associationPropertyPaths.merge(responsePathOverrides));
const fillers = List([fillPropertyReferences, fillPropertyResponsePaths]);
export default (spec => fillers.reduce((toFill, filler) => filler(toFill), spec));