import Env from 'enviro';
// @ts-expect-error ts-migrate(2554) FIXME: migrate depedency
import dispatcher from 'dispatcher/dispatcher';
function exists(maybe) {
  return typeof maybe !== 'undefined' && maybe !== null;
}
export default (() => {
  if (Env.debug('dispatcher')) {
    dispatcher.register(payload => {
      const actionType = payload.actionType;
      const data = payload.data;
      let dispatchData;
      if (exists(data) && typeof data.toJS === 'function') {
        dispatchData = data.toJS();
      }
      dispatchData = exists(dispatchData) ? dispatchData : data;
      if (dispatchData) {
        const type = Array.isArray(dispatchData) ? 'array' : typeof dispatchData;
        console.groupCollapsed(`${actionType}: ${type}`);
        console.info(dispatchData);
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        console.groupEnd(actionType);
      } else {
        console.groupCollapsed(actionType);
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        console.groupEnd(actionType);
      }
    });
  }
});