export const VisualTypes = {
  VERTICAL_BAR: 'VERTICAL_BAR',
  HORIZONTAL_BAR: 'HORIZONTAL_BAR',
  LINE: 'LINE',
  AREA: 'AREA',
  SCATTER: 'SCATTER',
  PIE: 'PIE',
  DONUT: 'DONUT',
  TEXT: 'TEXT',
  TABLE: 'TABLE',
  PIVOT_TABLE: 'PIVOT_TABLE',
  COMBO: 'COMBO',
  MAGIC_WAND: 'MAGIC_WAND',
  GAUGE: 'GAUGE'
};
export const TimeSeriesLikeVisualTypes = {
  VERTICAL_BAR: VisualTypes.VERTICAL_BAR,
  HORIZONTAL_BAR: VisualTypes.HORIZONTAL_BAR,
  LINE: VisualTypes.LINE,
  AREA: VisualTypes.AREA,
  SCATTER: VisualTypes.SCATTER
};
export const ChannelNames = {
  COLOR: 'color',
  SIZING: 'sizing',
  DETAIL: 'detail',
  VALUES: 'values',
  SINGLE_VALUE: 'single_value',
  GROUP: 'group',
  ROWS: 'rows',
  COLUMNS: 'columns',
  X: 'x',
  X_MULTI: 'x_multi',
  Y: 'y',
  Y_MULTI: 'y_multi',
  Y_1: 'y1',
  Y_2: 'y2',
  BREAKDOWN: 'breakdown',
  COMPARE: 'compare'
};