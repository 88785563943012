'use es6';

import I18n from 'I18n';
import { has } from '../../../lib/has';
import * as dates from '../params/dates';
import { RANGE_TYPES } from '../../../constants/dateRangeTypes';
import { startOf } from '../../../lib/dateUtils';
const formats = {
  INPUT: 'YYYYMMDD',
  OUTPUT: 'YYYY-MM-DD'
};
const result = (maybe, ...args) => typeof maybe === 'function' ? maybe(...args) : maybe;
export const fill = getter => (spec, config, runtimeOptions) => response => {
  const responseKeys = Object.keys(response).sort((a, b) => {
    return I18n.moment(a, formats.OUTPUT).diff(I18n.moment(b, formats.OUTPUT));
  });
  if (responseKeys.length === 0) {
    return response;
  }
  const {
    dataType,
    frequency,
    filters: {
      dateRange: {
        value: {
          rangeType
        }
      }
    }
  } = config;
  const {
    start,
    end
  } = dates.get(spec, config, runtimeOptions);
  const first = I18n.moment(...(rangeType === RANGE_TYPES.ALL ? [responseKeys[0], formats.OUTPUT] : [start, formats.INPUT]));
  const last = I18n.moment(end, formats.INPUT);
  const filled = {};
  let previous = null;
  for (const current = startOf({
    date: first,
    frequency,
    dataType
  }); current.isSameOrBefore(last, 'day'); current.add(1, frequency)) {
    const date = current.format(formats.OUTPUT);
    const point = result(getter, {
      date,
      previous,
      response
    });
    filled[date] = has(response, date) ? response[date] : point;
    previous = filled[date];
  }
  return filled;
};
export const zero = fill([]);
export const sustain = fill(({
  previous
}) => previous || []);