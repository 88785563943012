'use es6';

import { RequestState } from 'dashboard-lib/public/request/request-state';
import { selectDataSourcesInfo, selectDataSourcesInfoStatus } from '../lib/dashboardFilters/dataSourceInfoLogic';
import { getRecentlyUsedProperties, isDuplicateProperty, addToRecentlyUsedProperties } from '../lib/dashboardFilters/recentlyUsedProperties';
export const dataSourceRecentlyUsedPropertiesNamespace = 'dataSourceRecentlyUsedProperties';

// Action types
const FETCH_DATA_SOURCE_RECENTLY_USED_PROPERTIES_PENDING = 'FETCH_DATA_SOURCE_RECENTLY_USED_PROPERTIES_PENDING';
const FETCH_DATA_SOURCE_RECENTLY_USED_PROPERTIES_SUCCEEDED = 'FETCH_DATA_SOURCE_RECENTLY_USED_PROPERTIES_SUCCEEDED';
const FETCH_DATA_SOURCE_RECENTLY_USED_PROPERTIES_FAILED = 'FETCH_DATA_SOURCE_RECENTLY_USED_PROPERTIES_FAILED';
const ADD_DASHBOARD_FILTER = 'ADD_DASHBOARD_FILTER';

// Actions
export const fetchDataSourcesRecentlyUsedProperties = dataSources => (dispatch, getState) => {
  const state = getState();
  for (const dataSource of dataSources) {
    if (!state[dataSourceRecentlyUsedPropertiesNamespace][dataSource]) {
      dispatch({
        type: FETCH_DATA_SOURCE_RECENTLY_USED_PROPERTIES_PENDING,
        payload: {
          dataSource
        }
      });
      getRecentlyUsedProperties({
        dataSource
      }).then(recentlyUsedProperties => {
        dispatch({
          type: FETCH_DATA_SOURCE_RECENTLY_USED_PROPERTIES_SUCCEEDED,
          payload: {
            dataSource,
            recentlyUsedProperties
          }
        });
      }).catch(() => {
        dispatch({
          type: FETCH_DATA_SOURCE_RECENTLY_USED_PROPERTIES_FAILED,
          payload: {
            dataSource
          }
        });
      });
    }
  }
};

// Reducers
export const dataSourceRecentlyUsedPropertiesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_SOURCE_RECENTLY_USED_PROPERTIES_PENDING:
      {
        const {
          dataSource
        } = action.payload;
        return Object.assign({}, state, {
          [dataSource]: {
            status: RequestState.PENDING
          }
        });
      }
    case FETCH_DATA_SOURCE_RECENTLY_USED_PROPERTIES_SUCCEEDED:
      {
        const {
          dataSource,
          recentlyUsedProperties
        } = action.payload;
        return Object.assign({}, state, {
          [dataSource]: {
            status: RequestState.SUCCEEDED,
            recentlyUsedProperties
          }
        });
      }
    case FETCH_DATA_SOURCE_RECENTLY_USED_PROPERTIES_FAILED:
      {
        const {
          dataSource
        } = action.payload;
        return Object.assign({}, state, {
          [dataSource]: {
            status: RequestState.FAILED
          }
        });
      }
    case ADD_DASHBOARD_FILTER:
      {
        const {
          dataSource,
          property
        } = action.payload;

        // If the portal is not ungated to the recently used properties feature, skip this step.
        if (!dataSource || !property) return state;
        const recentlyUsedProperties = state[dataSource] ? state[dataSource]['recentlyUsedProperties'] || [] : [];
        const propertyName = property.name;
        if (isDuplicateProperty(recentlyUsedProperties, propertyName)) return state;
        return Object.assign({}, state, {
          [dataSource]: {
            recentlyUsedProperties: addToRecentlyUsedProperties(recentlyUsedProperties, propertyName)
          }
        });
      }
    default:
      return state;
  }
};

// Selectors
export const selectDataSourcesRecentlyUsedProperties = dataSources => state => {
  return selectDataSourcesInfo({
    dataNamespace: dataSourceRecentlyUsedPropertiesNamespace,
    dataSources,
    valueName: 'recentlyUsedProperties'
  })(state);
};
export const selectDataSourcesRecentlyUsedPropertiesStatus = dataSources => state => selectDataSourcesInfoStatus({
  dataNamespace: dataSourceRecentlyUsedPropertiesNamespace,
  dataSources
})(state);