import { List } from 'immutable';
import { PERCENTAGE } from 'reporting-data/constants/numberDisplayHints';
import { COLUMN_ROLES } from 'reporting-data/constants/relationalReports';
import { isList } from '../../shared/lib/utility-types';
import { Encoding, EncodingOptions, FieldTypes, defaultEncodingOptions } from '../schema/column-records';
import { CurrencyFormatting, FormatTypes, NegativeNumberTypes, NumberFormatting, PercentFormatting, PercentFormattingType } from '../schema/format-models';
import { ChannelNames, ChannelTypes, TimeSeriesLikeVisualTypes, Visual, VisualTypes, channelMetadata, preferredChannelRoleByVisualType, relationalReportChannels, visualOptionsByType } from '../schema/visual-records';
import { TableEncodings } from '../schema/visuals/table-visual-records';
import { createDefaultDimensionWithTransform, createDefaultMeasure, getColumnAlias, isFieldExpressionBased, isNumberLikeField } from './column-utils';

// encodings API
export const getEncodingColumn = encoding => encoding.column;
export const getEncodingColumnAlias = encoding => encoding.column && encoding.column.alias;
export const getEncodingFormatType = encoding => {
  return encoding && encoding.getIn(['options', 'format', 'type']);
};
export const getChannelEncoding = (channelName, encodings) => {
  if (channelName in encodings) {
    return encodings.get(channelName);
  }
  return undefined;
};
const makeEncodingGetter = channelName => encodings => {
  return getChannelEncoding(channelName, encodings);
};
export const getColorEncoding = makeEncodingGetter(ChannelNames.COLOR);
export const getSizingEncoding = makeEncodingGetter(ChannelNames.SIZING);
export const getDetailEncoding = makeEncodingGetter(ChannelNames.DETAIL);
export const getColumnsEncodings = makeEncodingGetter(ChannelNames.COLUMNS);
export const getGroupEncoding = makeEncodingGetter(ChannelNames.GROUP);
export const getRowsEncodings = makeEncodingGetter(ChannelNames.ROWS);
export const getValuesEncodings = makeEncodingGetter(ChannelNames.VALUES);
export const getXEncoding = makeEncodingGetter(ChannelNames.X);
export const getYEncoding = makeEncodingGetter(ChannelNames.Y);

// channel API
export const getChannel = channelName => channelMetadata.get(channelName);
const isChannelType = channelType => channel => channel.type === channelType;
export const isSingleEncoding = isChannelType(ChannelTypes.SINGLE);
export const isMultipleEncoding = isChannelType(ChannelTypes.MULTIPLE);
export const getEncoding = (encodings, channelName, index) => {
  const encoding = getChannelEncoding(channelName, encodings);
  if (isList(encoding)) {
    if (index == null) {
      return undefined;
    }
    return encoding.get(index, undefined);
  }
  return encoding;
};
export const getEncodingListInChannel = (encodings, channelName) => {
  const encoding = getChannelEncoding(channelName, encodings);
  if (encoding === undefined) {
    return List();
  }
  return isList(encoding) ? encoding : List([encoding]);
};
export const getEncodingList = encodings => {
  const channelNames = encodings.toSeq().map((_, v) => v).toList();
  return channelNames.flatMap(channelName => getEncodingListInChannel(encodings, channelName)).toList();
};
export const decodeChannel = (encodings, channelName, index) => {
  const encoding = getChannelEncoding(channelName, encodings);
  if (isList(encoding)) {
    if (index == null) {
      return encodings;
    }
    const newEncoding = encoding.delete(index);
    return encodings.set(channelName, newEncoding);
  }
  return encodings.set(channelName, undefined);
};
const NumberDisplayHintToFormatType = {
  [PERCENTAGE]: FormatTypes.PERCENTAGE
};
export const getFormatType = snowflakeProperty => {
  const maybeFormatType = NumberDisplayHintToFormatType[snowflakeProperty.numberDisplayHint];
  return maybeFormatType || FormatTypes.AUTO;
};
export const buildEncodingFromColumn = (column, expressionField) => {
  const hasFormatHint = isFieldExpressionBased(column.field) && expressionField && expressionField.formatHint;
  let options = defaultEncodingOptions;
  if (hasFormatHint && expressionField.formatHint === FormatTypes.PERCENTAGE) {
    options = EncodingOptions({
      format: PercentFormatting({
        type: PercentFormattingType
      })
    });
  }
  return Encoding({
    column: getColumnAlias(column),
    options
  });
};
export const replaceEncodingInChannel = ({
  encodings,
  channelName,
  encoding,
  toIndex
}) => {
  if (isSingleEncoding(getChannel(channelName))) {
    return encodings.set(channelName, encoding);
  }
  const previousEncoding = getChannelEncoding(channelName, encodings);
  if (isList(previousEncoding)) {
    const nextEncoding = previousEncoding.splice(toIndex, 1, encoding).toList();
    return encodings.set(channelName, nextEncoding);
  }
  return encodings.set(channelName, List.of(encoding));
};

// toIndex is optional, will append column if not provided
export const placeEncodingIntoChannel = ({
  encodings,
  channelName,
  encoding,
  toIndex
}) => {
  if (isSingleEncoding(getChannel(channelName))) {
    return encodings.set(channelName, encoding);
  }
  const previousEncoding = getChannelEncoding(channelName, encodings);
  if (isList(previousEncoding)) {
    return encodings.set(channelName, toIndex === undefined ? previousEncoding.push(encoding) : previousEncoding.insert(toIndex, encoding));
  }
  return encodings.set(channelName, List.of(encoding));
};
export const encodeChannel = ({
  encodings,
  channelName,
  column,
  expressionField,
  toIndex,
  encodingOptions
}) => {
  const encoding = encodingOptions ? buildEncodingFromColumn(column, expressionField).update('options', options => options.set('format', encodingOptions.get('format'))) : buildEncodingFromColumn(column, expressionField);
  return placeEncodingIntoChannel({
    encodings,
    channelName,
    encoding,
    toIndex
  });
};

// visual API
export const setVisualEncodings = (visual, encodings) => {
  //@ts-expect-error Immutable typescript does not understand that `Iterable` types will work here
  return visual.set('encodings', encodings);
};
export const getVisualChannel = (visual, channelName) => {
  return getChannelEncoding(channelName, visual.encodings);
};
export const getVisualTypeChannels = type => relationalReportChannels.get(type);
export const getVisualOptions = type => visualOptionsByType.get(type);
export const getVisualEncodedColumnIds = visual => getEncodingList(visual.encodings).map(encoding => encoding.column);
export const getEmptyChannelEncoding = channel => isSingleEncoding(channel) ? undefined : List();
export const decodeColumn = (visual, column) => {
  const {
    type,
    encodings
  } = visual;
  const {
    alias
  } = column;
  const channels = getVisualTypeChannels(type);
  return visual.set('encodings',
  //@ts-expect-error to be migrated
  channels.reduce((nextEncodings, channel) => {
    const {
      name: channelName
    } = channel;
    const encoding = getChannelEncoding(channelName, encodings);
    if (!encoding) {
      return nextEncodings;
    }
    if (!isList(encoding)) {
      const {
        column: encodedColumn
      } = encoding;
      return encodedColumn === alias ?
      //@ts-expect-error delete on union of records is ok
      nextEncodings.delete(channelName) : nextEncodings;
    }

    //@ts-expect-error delete on union of records is ok
    return nextEncodings.set(channelName, encoding.filter(e => e.column !== alias));
  }, encodings));
};
export const findEncodedChannel = (visual, encoding) => {
  const {
    encodings,
    type
  } = visual;
  return getVisualTypeChannels(type).find(channel => getEncodingListInChannel(encodings, channel.name).includes(encoding));
};
export const findEncodingInStage = (stagedEncodings, column) => {
  return stagedEncodings.find(encoding => encoding.column === column.alias);
};
export const supportsCompareChannel = type => {
  const channels = getVisualTypeChannels(type);
  return !!channels.get(ChannelNames.COMPARE);
};
export const getBreakdownChannel = type => {
  switch (type) {
    case VisualTypes.GAUGE:
      return ChannelNames.COMPARE;
    case VisualTypes.TEXT:
      return ChannelNames.GROUP;
    default:
      return ChannelNames.COLOR;
  }
};
const visualTypesWithCategoricalVariableOnX = [VisualTypes.VERTICAL_BAR, VisualTypes.LINE, VisualTypes.AREA, VisualTypes.COMBO];
export const categoricalVariableOnX = type => visualTypesWithCategoricalVariableOnX.includes(type);
export const categoricalVariableOnY = type => VisualTypes.HORIZONTAL_BAR === type;
export const noCategoricalVariableOnXY = type => VisualTypes.SCATTER === type;
const xyVisualTypes = [VisualTypes.VERTICAL_BAR, VisualTypes.HORIZONTAL_BAR, VisualTypes.LINE, VisualTypes.AREA, VisualTypes.SCATTER];
export const visualTypeIsXY = visualType => xyVisualTypes.includes(visualType);
const slicedVisualTypes = [VisualTypes.PIE, VisualTypes.DONUT];
export const visualTypeIsSliced = visualType => slicedVisualTypes.includes(visualType);
export const createDefaultVisual = visualType => {
  return Visual({
    type: visualType,
    //@ts-expect-error to be migrated to a more TS friendly factory
    encodings: getVisualTypeChannels(visualType).map(getEmptyChannelEncoding),
    options: getVisualOptions(visualType)({})
  });
};
const customPreferredChannelRoleGettersByVisualType = {
  [VisualTypes.SCATTER]: {
    [ChannelNames.X]: columnField => columnField.get('type') === FieldTypes.DATETIME ? COLUMN_ROLES.DIMENSION : COLUMN_ROLES.MEASURE,
    [ChannelNames.Y]: columnField => columnField.get('type') === FieldTypes.DATETIME ? COLUMN_ROLES.DIMENSION : COLUMN_ROLES.MEASURE
  }
};
export const getPreferredRoleInVisualChannel = (visualType, channelName, columnField) => {
  const preferredRoleGetters = customPreferredChannelRoleGettersByVisualType[visualType];
  const preferredRoleGetter = preferredRoleGetters && preferredRoleGetters[channelName];
  if (preferredRoleGetter && columnField) {
    return preferredRoleGetter(columnField);
  }
  const preferredChannelRoles = preferredChannelRoleByVisualType.get(visualType);
  return preferredChannelRoles && preferredChannelRoles.get(channelName);
};
export const createTableVisualWithCopiedChannels = oldVisual => {
  const prevEncodings = oldVisual.encodings;
  const newTableVisual = createDefaultVisual(VisualTypes.TABLE);
  const tableEncodings = TableEncodings({
    columns: getEncodingList(prevEncodings)
  });
  return setVisualEncodings(newTableVisual, tableEncodings);
};
export const createDefaultColumn = (field, maybeChannelName, visualType, snowflakeProperty) => {
  switch (maybeChannelName) {
    case ChannelNames.X:
      return categoricalVariableOnX(visualType) ? createDefaultDimensionWithTransform(field) : createDefaultMeasure(field, snowflakeProperty);
    case ChannelNames.Y:
      return categoricalVariableOnX(visualType) || noCategoricalVariableOnXY(visualType) ? createDefaultMeasure(field, snowflakeProperty) : createDefaultDimensionWithTransform(field);
    case ChannelNames.Y_1:
      return createDefaultMeasure(field, snowflakeProperty);
    case ChannelNames.Y_2:
      return createDefaultMeasure(field, snowflakeProperty);
    case ChannelNames.COLOR:
    case ChannelNames.GROUP:
    case ChannelNames.ROWS:
      return createDefaultDimensionWithTransform(field);
    case ChannelNames.COLUMNS:
      return createDefaultDimensionWithTransform(field);
    case ChannelNames.VALUES:
      return createDefaultMeasure(field, snowflakeProperty);
    case ChannelNames.SINGLE_VALUE:
      return createDefaultMeasure(field, snowflakeProperty);
    default:
      return isNumberLikeField(field) ? createDefaultMeasure(field, snowflakeProperty) : createDefaultDimensionWithTransform(field);
  }
};
const visualTypesAllowedForDataComparisons = [VisualTypes.VERTICAL_BAR, VisualTypes.HORIZONTAL_BAR, VisualTypes.LINE, VisualTypes.AREA, VisualTypes.TEXT, VisualTypes.GAUGE];
export const dateComparisonAllowedForVisualType = visualType => visualTypesAllowedForDataComparisons.includes(visualType);
const visualTypesWithRestrictedComparisonBreakdowns = [VisualTypes.GAUGE];
export const comparisonBreakdownRestrictedForVisualType = visualType => visualTypesWithRestrictedComparisonBreakdowns.includes(visualType);
export const hasFieldHasValidCurrency = (gates, meta, field) => {
  if (!gates.includes('AdvancedBuilder:Currency')) {
    return true;
  }
  const snowflakeProperty = meta.properties.getIn([field.table, field.name]);
  if (!snowflakeProperty) {
    return false;
  }
  const {
    metaDefinition
  } = snowflakeProperty;
  if (!metaDefinition) {
    return false;
  }
  const currencyProperty = meta.properties.getIn([field.table, metaDefinition.currencyPropertyName]);
  return metaDefinition.showCurrencySymbol && !!currencyProperty;
};
export const isVisualType = str => {
  const objectKeys = Object.keys;
  const values = objectKeys(VisualTypes).map(key => {
    return VisualTypes[key];
  });
  return values.some(type => type === str);
};
export const isTimeSeriesLikeVisualType = str => {
  const objectKeys = Object.keys;
  const values = objectKeys(TimeSeriesLikeVisualTypes).map(key => {
    return TimeSeriesLikeVisualTypes[key];
  });
  return values.some(type => type === str);
};

//property should be PropertyResponseIF but it isn't currently safe, since getProperty() => any in 'reporting-data/v2/dataset/datasetRecords'
export const resolveAutoFormattingType = (formatObject, property) => {
  if (!property || !formatObject || formatObject.type !== FormatTypes.AUTO) {
    return formatObject;
  }
  if (property.getIn(['propertyMeta', 'aggregationType']) === 'DISTINCT_APPROX') {
    return formatObject;
  }
  if (property.get('numberDisplayHint') === 'percentage') {
    return PercentFormatting({
      type: FormatTypes.PERCENTAGE,
      negativeSymbol: NegativeNumberTypes.AUTO,
      customPrecision: 2,
      useThousandsSeparator: true
    });
  }
  const propertyType = property.get('type') || property.get('metaDefinition').type;
  switch (propertyType) {
    case 'number':
      return NumberFormatting({
        type: FormatTypes.NUMBER,
        negativeSymbol: NegativeNumberTypes.AUTO,
        customPrecision: 2,
        useThousandsSeparator: true
      });
    case 'currency_number':
      return CurrencyFormatting({
        type: FormatTypes.CURRENCY,
        negativeSymbol: NegativeNumberTypes.AUTO,
        customPrecision: 2,
        useThousandsSeparator: true,
        currencyCode: property.get('companyCurrencyCode', '')
      });
    default:
      return formatObject;
  }
};
export const getEncodingFormatParameters = (encoding, property) => {
  if (encoding == null || encoding.options == null) {
    return undefined;
  }
  return encoding && resolveAutoFormattingType(encoding.options.format, property);
};