/* hs-eslint ignored failing-rules */
/* eslint-disable prefer-promise-reject-errors */

'use es6';

import http from 'hub-http/clients/apiClient';
import compose from 'transmute/compose';
import fromJS from 'transmute/fromJS';
import get from 'transmute/get';
import indexBy from 'transmute/indexBy';
import { isNil, someIsNil } from '../../private/js-util';
import { Failure, getValue, Success, unwrap } from '../../private/monad/validation';
const unwrapUrl = unwrap(getValue, () => undefined);
const createSingleDashboardSentenceEndpoint = ({
  dashboardId,
  userId
}) => someIsNil([dashboardId, userId]) ? Failure() : Success(`/dashboard/v2/dashboard/${dashboardId}/permission/${userId}`);
export const fetchDashboardSentenceSingle = ({
  dashboardId,
  userId
}) => {
  const maybeUrl = createSingleDashboardSentenceEndpoint({
    dashboardId,
    userId
  });
  const url = unwrapUrl(maybeUrl);
  return isNil(url) ? Promise.reject('Failed to create URL for fetchDashboardSentenceSingle!') : http.get(url).then(fromJS);
};
const createMultiDashboardSentenceEndpoint = ({
  userId
}) => isNil(userId) ? Failure() : Success(`/dashboard/v2/dashboard/permission/${userId}`);
export const fetchDashboardSentenceMulti = ({
  userId
}) => {
  const maybeUrl = createMultiDashboardSentenceEndpoint({
    userId
  });
  const url = unwrapUrl(maybeUrl);
  return isNil(url) ? Promise.reject('Failed to create URL for fetchDashboardSentenceMulti!') : http.get(url).then(compose(indexBy(get('dashboardId')), fromJS, get('permissionSentences')));
};
const createDashboardConfigEndpoint = ({
  dashboardId
}) => isNil(dashboardId) ? Failure() : Success(`/dashboard/v2/dashboard/${dashboardId}/permission`);
export const fetchDashboardConfigSingle = ({
  dashboardId
}) => {
  const maybeUrl = createDashboardConfigEndpoint({
    dashboardId
  });
  const url = unwrapUrl(maybeUrl);
  return isNil(url) ? Promise.reject('Failed to create URL for fetchDashboardConfigSingle!') : http.get(url).then(fromJS);
};
export const putDashboardConfigSingle = ({
  dashboardId,
  data
}) => {
  const maybeUrl = createDashboardConfigEndpoint({
    dashboardId
  });
  const url = unwrapUrl(maybeUrl);
  return isNil(url) ? Promise.reject('Failed to create URL for fetchDashboardConfigSingle!') : http.put(url, {
    data: Object.assign({}, data)
  }).then(fromJS);
};
const createSingleReportSentenceEndpoint = ({
  reportId,
  userId
}) => someIsNil([reportId, userId]) ? Failure() : Success(`/dashboard/v2/reports/${reportId}/permission/${userId}`);
export const fetchReportSentenceSingle = ({
  reportId,
  userId
}) => {
  const maybeUrl = createSingleReportSentenceEndpoint({
    reportId,
    userId
  });
  const url = unwrapUrl(maybeUrl);
  return isNil(url) ? Promise.reject('Failed to create URL for fetchReportSentenceSingle!') : http.get(url).then(fromJS);
};
const createMultiReportSentenceEndpoint = ({
  userId
}) => isNil(userId) ? Failure() : Success(`/dashboard/v2/reports/permission/${userId}`);
export const fetchReportSentenceMulti = ({
  userId
}) => {
  const maybeUrl = createMultiReportSentenceEndpoint({
    userId
  });
  const url = unwrapUrl(maybeUrl);
  return isNil(url) ? Promise.reject('Failed to create URL for fetchReportSentenceMulti!') : http.get(url).then(compose(indexBy(get('reportId')), fromJS, get('permissionSentences')));
};
const createReportConfigEndpoint = ({
  reportId
}) => isNil(reportId) ? Failure() : Success(`/dashboard/v2/reports/${reportId}/permission`);
export const fetchReportConfigSingle = ({
  reportId
}) => {
  const maybeUrl = createReportConfigEndpoint({
    reportId
  });
  const url = unwrapUrl(maybeUrl);
  return isNil(url) ? Promise.reject('Failed to create URL for fetchReportConfigSingle!') : http.get(url).then(fromJS);
};
export const putReportConfigSingle = ({
  reportId,
  data
}) => {
  const maybeUrl = createReportConfigEndpoint({
    reportId
  });
  const url = unwrapUrl(maybeUrl);
  return isNil(url) ? Promise.reject('Failed to create URL for fetchReportConfigSingle!') : http.put(url, {
    data: Object.assign({}, data)
  }).then(fromJS);
};