'use es6';

export const CONTACT_TYPE_ID = '0-1';
export const COMPANY_TYPE_ID = '0-2';
export const DEAL_TYPE_ID = '0-3';
export const ENGAGEMENT_TYPE_ID = '0-4';
export const TICKET_TYPE_ID = '0-5';
export const PRODUCT_TYPE_ID = '0-7';
export const LINE_ITEM_TYPE_ID = '0-8';
export const CONTENT_TYPE_ID = '0-10';
export const CONVERSATION_TYPE_ID = '0-11';
export const QUOTE_TYPE_ID = '0-14';
export const FORM_SUBMISSION_TYPE_ID = '0-15';
export const QUOTA_TYPE_ID = '0-16';
export const COMMUNICATION_TYPE_ID = '0-18';
export const FEEDBACK_SUBMISSION_TYPE_ID = '0-19';
export const ATTRIBUTION_TYPE_ID = '0-20';
export const SALESFORCE_SYNC_ERROR_TYPE_ID = '0-21';
export const RESTORABLE_CRM_OBJECT_TYPE_ID = '0-23';
export const LANDING_PAGE_TYPE_ID = '0-25';
export const PRODUCT_OR_FOLDER_TYPE_ID = '0-26';
export const TASK_TYPE_ID = '0-27';
export const FORM_TYPE_ID = '0-28';
export const MARKETING_EMAIL_TYPE_ID = '0-29';
export const AD_ACCOUNT_TYPE_ID = '0-30';
export const AD_CAMPAIGN_TYPE_ID = '0-31';
export const AD_GROUP_TYPE_ID = '0-32';
export const AD_TYPE_ID = '0-33';
export const KEYWORD_TYPE_ID = '0-34';
export const CAMPAIGN_TYPE_ID = '0-35';
export const SOCIAL_CHANNEL_TYPE_ID = '0-36';
export const SOCIAL_POST_TYPE_ID = '0-37';
export const BLOG_POST_TYPE_ID = '0-39';
export const IMPORT_TYPE_ID = '0-40';
export const CTA_TYPE_ID = '0-42';
export const TASK_TEMPLATE_TYPE_ID = '0-43';
export const AUTOMATION_PLATFORM_FLOW_TYPE_ID = '0-44';
export const OBJECT_LIST_TYPE_ID = '0-45';
export const NOTE_TYPE_ID = '0-46';
export const MEETING_EVENT_TYPE_ID = '0-47';
export const CALL_TYPE_ID = '0-48';
export const EMAIL_TYPE_ID = '0-49';
export const PUBLISHING_TASK_TYPE_ID = '0-50';
export const CONVERSATION_SESSION_TYPE_ID = '0-51';
export const CONTACT_CREATE_ATTRIBUTION_TYPE_ID = '0-52';
export const INVOICE_TYPE_ID = '0-53';
export const MARKETING_EVENT_TYPE_ID = '0-54';
export const CONVERSATION_INBOX_TYPE_ID = '0-55';
export const CHATFLOW_TYPE_ID = '0-56';
export const MEDIA_TYPE_ID = '0-57';
export const SEQUENCE_TYPE_ID = '0-58';
export const SEQUENCE_STEP_TYPE_ID = '0-59';
export const FORECAST_TYPE_ID = '0-60';
export const SNIPPET_TYPE_ID = '0-61';
export const TEMPLATE_TYPE_ID = '0-62';
export const DEAL_CREATE_ATTRIBUTION_TYPE_ID = '0-63';
export const QUOTE_TEMPLATE_TYPE_ID = '0-64';
export const QUOTE_MODULE_TYPE_ID = '0-65';
export const QUOTE_MODULE_FIELD_TYPE_ID = '0-66';
export const QUOTE_FIELD_TYPE_ID = '0-67';
export const SEQUENCE_ENROLLMENT_TYPE_ID = '0-68';
export const SUBSCRIPTION_TYPE_ID = '0-69';
export const ACCEPTANCE_TEST_TYPE_ID = '0-70';
export const SOCIAL_BROADCAST_TYPE_ID = '0-71';
export const DEAL_SPLIT_TYPE_ID = '0-72';
export const DEAL_REGISTRATION_TYPE_ID = '0-73';
export const GOAL_TARGET_TYPE_ID = '0-74';
export const GOAL_TARGET_GROUP_TYPE_ID = '0-75';
export const PORTAL_OBJECT_SYNC_MESSAGE_TYPE_ID = '0-76';
export const FILE_MANAGER_FILE_TYPE_ID = '0-77';
export const FILE_MANAGER_FOLDER_TYPE_ID = '0-78';
export const SEQUENCE_STEP_ENROLLMENT_TYPE_ID = '0-79';
export const APPROVAL_TYPE_ID = '0-80';
export const APPROVAL_STEP_TYPE_ID = '0-81';
export const CTA_VARIANT_TYPE_ID = '0-82';
export const SALES_DOCUMENT_TYPE_ID = '0-83';
export const DISCOUNT_TYPE_ID = '0-84';
export const FEE_TYPE_ID = '0-85';
export const TAX_TYPE_ID = '0-86';
export const MARKETING_CALENDAR_TYPE_ID = '0-87';
export const PERMISSIONS_TESTING_TYPE_ID = '0-88';
export const PRIVACY_SCANNER_COOKIE_TYPE_ID = '0-89';
export const WEB_INTERACTIVE = '0-91';
export const ORDER_TYPE_ID = '0-123';
export const CART_TYPE_ID = '0-142';
export const SERVICE_TYPE_ID = '0-162';
export const COURSE_TYPE_ID = '0-410';
export const LISTING_TYPE_ID = '0-420';
export const APPOINTMENT_TYPE_ID = '0-421';