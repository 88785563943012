'use es6';

import { relationalReportExpandedLimits as expandedConstraints, relationalReportMeta as metaConstraints } from './constraints/relationalReport';
import { InvalidSpecificationTypes } from './validate';

// validate relational reports without importing all of the constraints
export const isValid = (report, userInfo, constraintsToValidate) => {
  const constraintsApplied = constraintsToValidate || expandedConstraints;
  return constraintsApplied.validate(report, userInfo);
};
export const isValidWithMeta = (report, meta, userInfo) => expandedConstraints.validate(report, userInfo) && metaConstraints.validate(report, meta, userInfo);
export const findViolatedConstraint = (report, constraintName, userInfo) => expandedConstraints.listViolated(report, userInfo).find(constraint => constraint.get('name') === constraintName);
export const findViolatedCompletionCriteria = (report, userInfo) => expandedConstraints.listViolated(report, userInfo).find(constraint => constraint.get('producing') === InvalidSpecificationTypes.INCOMPLETE);
export const findViolatedCompletionCriteriaWithMeta = (report, meta, userInfo) => {
  const findViolated = constraint => constraint.get('producing') === InvalidSpecificationTypes.INCOMPLETE;
  return expandedConstraints.listViolated(report, userInfo).find(findViolated) || metaConstraints.listViolated(report, meta, userInfo).find(findViolated);
};
export const listViolatedUnsupportedCriteria = (report, userInfo) => expandedConstraints.listViolated(report, userInfo).filter(constraint => {
  return constraint.get('producing') === InvalidSpecificationTypes.UNSUPPORTED;
});
export const findViolatedUnsupportedCriteria = (report, userInfo) => expandedConstraints.listViolated(report, userInfo).find(constraint => {
  return constraint.get('producing') === InvalidSpecificationTypes.UNSUPPORTED;
});
export const findViolatedUnsupportedCriteriaWithMeta = (report, meta, userInfo) => {
  const findViolated = constraint => constraint.get('producing') === InvalidSpecificationTypes.UNSUPPORTED;
  return expandedConstraints.listViolated(report, userInfo).find(findViolated) || metaConstraints.listViolated(report, meta, userInfo).find(findViolated);
};