'use es6';

import { List, fromJS, Map as ImmutableMap } from 'immutable';
import get from 'transmute/get';
import http from 'hub-http/clients/apiClient';
import * as storage from 'reporting-data/lib/storage';
import { throwPromise } from '../error/error-utils';
const CACHE_TIMEOUT = 60 * 1000;
let cache = ImmutableMap();
let timers = ImmutableMap();
export const clearDashboardCache = id => {
  const key = ['dashboard', id];
  clearTimeout(timers.getIn(key));
  cache = cache.deleteIn(key);
};
export const fetchDashboardSingle = ({
  id
}) => http.get(`dashboard/v2/dashboard/${id}`).then(fromJS);
const fetchDashboards = query => http.get('dashboard/v2/dashboard', {
  query
}).then(fromJS, throwPromise);
export const fetchDashboardCount = () => fetchDashboards({
  limit: 0
}).then(get('allDashboards'));
export const createDashboard = ({
  dashboardName,
  userId,
  dashboardPermissionConfig,
  widgets = [],
  template,
  filterGroups
}) => http.post('dashboard/v2/dashboard', {
  data: {
    name: dashboardName,
    type: 'CUSTOM',
    ownerId: userId,
    dashboardPermissionConfig,
    widgets,
    template,
    filterGroups
  }
}).then(fromJS);
export const getDashboard = id => {
  const key = ['dashboard', id];
  if (!cache.hasIn(key)) {
    cache = cache.setIn(key, fetchDashboardSingle({
      id
    }).then(dashboards => {
      timers = timers.set(key, setTimeout(clearDashboardCache(id), CACHE_TIMEOUT));
      return dashboards;
    }));
  }
  return cache.getIn(key);
};
const RECENT_DASHBOARDS_KEY = 'RA:RecentDashboards';
export const getRecentDashboards = () => {
  if (!storage.has(RECENT_DASHBOARDS_KEY)) {
    return List();
  }
  const stored = storage.get(RECENT_DASHBOARDS_KEY);
  try {
    return List(stored);
  } catch (e) {
    storage.remove(RECENT_DASHBOARDS_KEY);
    return List();
  }
};
export const addReportsToDashboard = (reportIds, dashboardId) => {
  return http.post(`dashboard/v2/dashboard/${dashboardId}/widget/from-reports`, {
    data: reportIds
  }).then(fromJS);
};
export const removeFromDashboard = (reportId, dashboardId) => http.delete(`dashboard/v2/dashboard/${dashboardId}/report/${reportId}`).then(fromJS);
export const moveReport = (oldDashboardId, newDashboardId, reportId) => {
  return removeFromDashboard(reportId, oldDashboardId).then(() => {
    return addReportsToDashboard([reportId], newDashboardId);
  });
};