'use es6';

import { List } from 'immutable';
import toJS from '../../lib/toJS';
import { load } from '../../dataTypeDefinitions';
import * as http from '../../request/http';
import * as params from './params';
import { has } from '../../lib/has';
import { getPeopleType } from './endpoints/people';
import { fiscalYearFetcher } from '../../lib/fiscalYearUtils';
const getCurrentD = (config, spec) => {
  const dProperties = ['d1', 'd2'];
  const dFilters = config.getIn(['filters', 'custom'], List()).filter(filter => dProperties.includes(filter.get('property')));
  const currentD = `d${dFilters.count()}`;
  return spec[currentD] ? currentD : null;
};
const getExportUrl = (spec, config) => {
  const {
    dimensions = []
  } = config;
  const isPeopleReport = dimensions.includes('people');
  const peopleType = getPeopleType(config);
  return isPeopleReport ? `${spec.url}/export-people/${peopleType}` : `${spec.url}/export`;
};
export default ((report, exportConfig) => {
  const reportConfig = toJS(report.get('config'));
  const {
    dataType,
    dimensions
  } = reportConfig;
  const isPeopleReport = dimensions.includes('people');
  return Promise.all([load(dataType), fiscalYearFetcher()]).then(([module, fiscalYearMonthName]) => {
    const spec = module.getUnifiedSpecForConfig({
      config: reportConfig
    });
    const param = params.get(spec, reportConfig, {
      fiscalYearMonthName
    });
    const url = getExportUrl(spec, reportConfig);
    if (has(exportConfig, 'listId')) {
      const peopleType = getPeopleType(reportConfig);
      const listUrl = `${spec.url}/export-list/${peopleType}/${exportConfig.listId}`;
      return http.post(listUrl, {
        query: param
      }).then(toJS);
    }
    let allColumns = report.getIn(['config', 'metrics']).map(metric => metric.get('property')).concat(report.getIn(['displayParams', 'dataColumns'], List())).filter(column => !spec.calculated || !spec.calculated[column]).map(column => {
      if (spec.metadata && has(spec.metadata, column)) {
        return `metadata-${column}`;
      }
      return column;
    }).toOrderedSet().toList();
    const dLevel = getCurrentD(report.get('config'), spec);
    if (!isPeopleReport && has(spec, dLevel) && spec[dLevel].metadata) {
      allColumns = allColumns.unshift(`metadata-${spec[dLevel].metadata}`);
    }
    const data = Object.assign({}, toJS(exportConfig), {
      allColumns: allColumns.size === 0 ? undefined : allColumns,
      exportTitle: exportConfig.title
    });
    return http.post(url, {
      query: param,
      data
    }).then(toJS);
  });
});