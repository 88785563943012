'use es6';

import { defineFactory } from 'general-store';
import { Map as ImmutableMap, Record } from 'immutable';
import { OWNERS_FETCH_SUCCESS } from '../constants/SystemDataActionTypes';
import { __OWNERS_RESET_STATE__ } from '../constants/TestingActionTypes';
const State = Record({
  owners: ImmutableMap(),
  remotes: ImmutableMap(),
  currentUserId: null,
  currentOwnerId: null,
  loaded: false
});
export default defineFactory().defineName('OwnersStore').defineGetInitialState(() => new State()).defineGet((state, key) => {
  return state.has(key) ? state.get(key) : state;
}).defineResponseTo(OWNERS_FETCH_SUCCESS, (state, {
  currentOwnerId,
  owners,
  remotes,
  currentUserId
}) => {
  return new State({
    currentOwnerId,
    owners,
    remotes,
    currentUserId,
    loaded: true
  });
}).defineResponseTo(__OWNERS_RESET_STATE__, () => new State()).register();