'use es6';

import { is } from 'immutable';
import { useRef } from 'react';

/**
 * Holds a cached reference to the given `subject`.
 * If the previous value is different than the new value,
 * then the reference will update with the new value.
 *
 * @param subject {*}
 * @param validator {function} [isEqual]
 *   compares the previous value with the new value:
 *     -> true would mean they consistent
 *     -> false would mean they are inconsistent, and that the cache should be updated.
 * @returns {*} cached value
 */
export const useRefCache = (subject, validator = is) => {
  const ref = useRef(subject);
  if (!validator(ref.current, subject)) {
    ref.current = subject;
  }
  return ref.current;
};