import { makeFrameworkTypesQuickFetchKey, makeFrameworkTypesFetchUrl } from 'framework-data-schema-quick-fetch';
import { withQuickFetch } from 'framework-data-schema-quick-fetch/withQuickFetch';
export const fetchFrameworkTypes = ({
  family,
  httpClient
}) => withQuickFetch({
  requestName: makeFrameworkTypesQuickFetchKey({
    family
  }),
  baseFetch: () => httpClient.get(makeFrameworkTypesFetchUrl({
    family
  }))
});