import I18n from 'I18n';
// @ts-expect-error untyped import
import { EVENT_DATE_PROPERTY_NAME } from '../../event-date-interval-utils';
import { SnowflakeProperty } from '../../../../schema/source-records';
export const getEventDateFilterProperty = () => SnowflakeProperty({
  name: EVENT_DATE_PROPERTY_NAME,
  label: I18n.text('reporting-snowflake.filter-editor.event-date-filter.property-label'),
  type: 'datetime',
  rollupProperty: false,
  rollupPropertyObjectTypeIds: [],
  groupName: '',
  flpRestricted: false
});