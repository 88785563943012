'use es6';

import { isNodeWithinClass } from '../lib/isNodeWithinClass';
import BaseAtomicFocus from './BaseAtomicFocus';
const isNodeWithinRefCreator = parentClasses => ({
  target,
  blockRef
}) => {
  try {
    return blockRef.contains(target) || isNodeWithinClass({
      target,
      parentClasses
    });
  } catch (e) {
    return false;
  }
};
const AtomicFocusWithRef = (parentClasses, removable = true) => {
  return BaseAtomicFocus(isNodeWithinRefCreator(parentClasses), removable);
};
export default AtomicFocusWithRef;