import * as checked from 'reporting-data/lib/checked';
import { Encoding } from '../column-records';
import { showRecordIds } from '../highcharts-settings';
import { VisualTypes } from '../constants/visual-constants';
export const PivotTableEncodings = checked.record({
  rows: checked.list(Encoding).optional(),
  columns: checked.list(Encoding).optional(),
  values: checked.list(Encoding).optional()
}, 'PivotTableEncodings');
export const PivotTableVisualOptions = checked.record({
  showTotals: checked.boolean().defaultValue(true),
  showColumnSubtotals: checked.boolean().defaultValue(false),
  showRowSubtotals: checked.boolean().defaultValue(false),
  showRecordIds
}, 'PivotTableVisualOptions');
export const PivotTableVisual = checked.record({
  type: checked.string().always(VisualTypes.PIVOT_TABLE),
  encodings: PivotTableEncodings,
  options: PivotTableVisualOptions.defaultValue({})
}, 'PivotTableVisual');