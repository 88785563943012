import PropTypes from 'prop-types';
import { contains, listOf, iterable, map } from 'react-immutable-proptypes';
export const DateRangeType = contains({
  property: PropTypes.string,
  value: contains({
    rangeType: PropTypes.string.isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    rollingDays: PropTypes.number,
    entireCurrentUnit: PropTypes.bool
  }).isRequired
});
export const ReportConfigType = contains({
  pipeline: contains({
    id: PropTypes.string,
    stages: listOf(PropTypes.string.isRequired)
  }),
  frequency: PropTypes.string,
  filters: contains({
    dateRange: DateRangeType,
    owner: listOf(PropTypes.number),
    team: listOf(PropTypes.number)
  })
});
export const ReportType = contains({
  chartType: PropTypes.string,
  name: PropTypes.string,
  config: ReportConfigType,
  customConfig: iterable,
  reportDefinition: map
});