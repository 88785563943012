import I18n from 'I18n';
import userInfo from 'hub-http/userInfo';
import { Map as ImmutableMap, List } from 'immutable';
import { useEffect, useState } from 'react';
// @ts-expect-error has no typings yet
import { fetchById, refreshDatasetsCache } from '../../dataset/dataset-meta';
import { toDatasetProperty } from '../../dataset/dataset-property-utils';
import { FieldTypes } from '../schema/column-records';
import { DataSourceTypes, SnowflakeOption, SnowflakeProperty, TableAndPropertyMeta } from '../schema/source-records';
import { JoinTypes } from '../schema/table-records';
import { ASSOCIATION_FIELD_NAME, collectJoinEdges } from '../utils/association-utils';
import { AsyncStatus, isFailed, isLoading, isSucceeded } from '../utils/async-data-utils';
import { getDataSourceId, getDataSourceIdFromTableDescription, getTableList } from '../utils/table-utils';
import { getSnowflakeJoinsMetadata } from './joins-with-meta';
import { getSnowflakeProperties } from './properties';
import { getSnowflakeTables } from './tables';
let dataCache = ImmutableMap();
let requestCache = ImmutableMap();
const createAssociationPropertyOptions = (listOfJoinMetadata, joinDefinitions) => {
  return joinDefinitions.map(joinDefinition => {
    const combinedAssociationId = 'combinedAssociationTypeId' in joinDefinition && joinDefinition.combinedAssociationTypeId || '';
    const foundJoinMetadata = listOfJoinMetadata.find(joinMeta => 'combinedAssociationTypeId' in joinMeta && joinMeta.combinedAssociationTypeId === combinedAssociationId);
    const foundLabel = foundJoinMetadata ? foundJoinMetadata.getIn(['associationDefinition', 'label']) : undefined;
    return SnowflakeOption({
      value: combinedAssociationId,
      label: foundLabel || I18n.text('reporting-snowflake.association.any-option-label'),
      displayOrder: undefined,
      description: undefined
    });
  }).toList();
};
const createAssociationProperties = (joinMeta, tableDescription, hasSelfJoinsAccess) => {
  const getSourceId = tableName => getDataSourceIdFromTableDescription(tableDescription, tableName);
  return collectJoinEdges(tableDescription, hasSelfJoinsAccess).filter(edge => edge.definitions.some(def => def.type === JoinTypes.ASSOCIATION)).toMap().mapKeys((_, edge) => edge ? edge.right : '').map(edge => {
    const {
      definitions = List(),
      left,
      right
    } = edge;
    const leftId = getSourceId(left);
    const rightId = getSourceId(right);
    const listOfJoinMetadata = joinMeta.getIn([leftId, rightId]);
    const options = listOfJoinMetadata ? createAssociationPropertyOptions(listOfJoinMetadata, definitions) : [];
    return SnowflakeProperty({
      name: ASSOCIATION_FIELD_NAME,
      label: I18n.text('reporting-snowflake.association.field-label'),
      type: FieldTypes.ENUMERATION,
      groupName: 'none',
      hidden: false,
      numberDisplayHint: undefined,
      rollupPropertyObjectTypeIds: [],
      rollupProperty: false,
      rollupPropertyErrorLevel: undefined,
      description: undefined,
      options,
      flpRestricted: false
    });
  }).toMap();
};
const fetchAssociationProperties = tableDescription => userInfo().then(user => {
  const {
    gates = []
  } = user || {};
  const hasSelfJoinsAccess = gates.includes('same-type-object-associations');
  return getSnowflakeJoinsMetadata().then(joinMeta => createAssociationProperties(joinMeta, tableDescription, hasSelfJoinsAccess));
});
const toDatasetProperties = snowflakeProperties => snowflakeProperties.map((tableProperties, tableName) => tableProperties.map(snowflakeProperty => toDatasetProperty(tableName, snowflakeProperty)));
const getTableDescriptionMetaFromCache = tableDescription => {
  const id = tableDescription.hashCode().toString();
  return dataCache.get(id);
};

/**
 * Query for the snowflake tables used in a table description,
 * and all properties for those tables
 */
export const getTableDescriptionMeta = tableDescription => {
  const id = tableDescription.hashCode().toString();
  const createRequest = () => {
    if (tableDescription.type === DataSourceTypes.HUBSPOT_DATASET || tableDescription.type === DataSourceTypes.HUBSPOT_GLOBAL_DATASET) {
      const {
        datasetId
      } = tableDescription;
      return fetchById(datasetId, true, tableDescription.type === DataSourceTypes.HUBSPOT_GLOBAL_DATASET).then(datasetObject => datasetObject.get('datasetDefinition')).then(datasetDefinition => getTableDescriptionMeta(datasetDefinition.table).then(({
        tables,
        properties
      }) => {
        const result = TableAndPropertyMeta({
          tables,
          properties,
          datasetProperties: toDatasetProperties(properties),
          datasetDefinition
        });
        dataCache = dataCache.set(id, result);
        return result;
      }));
    } else {
      const tableList = getTableList(tableDescription);
      const dataSourceIds = tableList.map(getDataSourceId).toList();
      const propertiesRequests = Promise.all(dataSourceIds.map(getSnowflakeProperties).toArray());
      return Promise.all([getSnowflakeTables(), propertiesRequests, fetchAssociationProperties(tableDescription)]).then(([mapOfDataSourceIdToSnowflakeTable, propertyResponses, associationProperties]) => {
        const tables = tableList.toMap().mapKeys((_, tableDesc) => tableDesc ? tableDesc.name : '').map(tableDesc => {
          const dataSourceId = getDataSourceId(tableDesc);
          return mapOfDataSourceIdToSnowflakeTable.get(dataSourceId);
        }).filter(snowflakeTable => !!snowflakeTable).toMap();
        const properties = propertyResponses.reduce((mapOfTableNameToPropertyMap, propertyMap, index) => {
          const {
            name
          } = tableList.get(index);
          return mapOfTableNameToPropertyMap.set(name, propertyMap);
        }, ImmutableMap()).map((propertyMap, tableName) => {
          const associationProperty = associationProperties.get(tableName);
          if (!associationProperty) {
            return propertyMap;
          }
          return propertyMap.set(ASSOCIATION_FIELD_NAME, associationProperty);
        }).toMap();
        const result = TableAndPropertyMeta({
          tables,
          properties,
          datasetProperties: toDatasetProperties(properties)
        });
        dataCache = dataCache.set(id, result);
        return result;
      });
    }
  };
  if (!requestCache.has(id)) {
    requestCache = requestCache.set(id, createRequest());
  }
  return requestCache.get(id).catch(error => {
    requestCache = requestCache.delete(id);
    console.error(error);
    throw error;
  });
};

/**
 * A version of the fetcher but returns the cached result immediately if hit.
 * @returns {Promise<TableAndPropertyMeta>|TableAndPropertyMeta}
 */
export const getTableDescriptionMetaWithCache = tableDescription => {
  const cached = getTableDescriptionMetaFromCache(tableDescription);
  if (cached) {
    return cached;
  }
  return getTableDescriptionMeta(tableDescription);
};
export const refreshTableDescriptionMetaCache = tableDescription => {
  dataCache = ImmutableMap();
  requestCache = ImmutableMap();
  refreshDatasetsCache();
  return getTableDescriptionMeta(tableDescription);
};
/**
 * A hook to easily hydrate table description metadata in component state.
 * @param {TableDescription} tableDescription
 * @returns {{loading: boolean, data: TableAndPropertyMeta, error: Error}}
 */
export const useTableDescriptionMeta = tableDescription => {
  const [requestState, setRequestState] = useState(() => {
    const cached = tableDescription && getTableDescriptionMetaFromCache(tableDescription);
    return cached ? {
      status: AsyncStatus.SUCCEEDED,
      data: cached
    } : {
      status: AsyncStatus.UNINITIALIZED
    };
  });
  useEffect(() => {
    if (!tableDescription) return;
    let expired = false;
    // cache is per table description, so check if cache has new needed value
    const cached = getTableDescriptionMetaFromCache(tableDescription);
    if (cached) {
      setRequestState({
        status: AsyncStatus.SUCCEEDED,
        data: cached
      });
    } else {
      // set status to pending, but still allow consumers to access the previous data if they want while it's pending
      setRequestState(state => Object.assign({}, state, {
        status: AsyncStatus.PENDING
      }));
      getTableDescriptionMeta(tableDescription).then(data => {
        if (!expired) {
          setRequestState({
            status: AsyncStatus.SUCCEEDED,
            data
          });
        }
      }).catch(error => {
        if (!expired) {
          setRequestState({
            status: AsyncStatus.FAILED,
            error
          });
        }
      });
    }
    return () => {
      expired = true;
    };
  }, [tableDescription]);
  return {
    loading: isLoading(requestState),
    data: isSucceeded(requestState) ? requestState.data : undefined,
    error: isFailed(requestState) ? requestState.error : undefined
  };
};