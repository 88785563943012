'use es6';

import * as PropertyTypes from 'customer-data-objects/property/PropertyTypes';
import I18n from 'I18n';
const dateString = /^\d{4}-\d{2}-\d{2}$/;
const numberString = /^\d+(\.\d+)?$/;
export function isValidRegex(value) {
  if (!value || typeof value !== 'string' || value.length === 0) {
    return false;
  }
  try {
    RegExp(value);
  } catch (err) {
    return false;
  }
  return true;
}
const MAX_REGEX_LENGTH = 5000;
export function isRegexTooLong(value) {
  return typeof value === 'string' && value.length > MAX_REGEX_LENGTH;
}
export function isRegexGroupCountValid(value) {
  let captureGroupCount = 0;
  try {
    // evaluate the regex OR empty string on an empty string,
    // it should have a result + 1 for each capture group
    captureGroupCount = new RegExp(`${value}|`).exec('').length - 1;
  } catch (err) {
    //
  }
  return captureGroupCount <= 50;
}
export function defaultIsValue(value, field) {
  if (!field) {
    return value !== undefined;
  }
  switch (field.type) {
    case PropertyTypes.BOOLEAN:
      return typeof value === 'boolean';
    case PropertyTypes.DATE:
    case PropertyTypes.DATE_TIME:
      return dateString.test(value);
    case PropertyTypes.ENUMERATION:
      return value !== undefined && value !== null;
    case PropertyTypes.NUMBER:
      return typeof value === 'number' && !isNaN(value) || numberString.test(value);
    case PropertyTypes.STRING:
      return typeof value === 'string' && value !== '';
    default:
      return value !== undefined;
  }
}
export const isValidMomentDate = value => {
  return I18n.moment.isMoment(value) && value.isValid();
};
export const isBoolean = value => {
  return typeof value === 'boolean';
};