// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import * as DSAssetFamilies from 'customer-data-filters/filterQueryFormat/DSAssetFamilies/DSAssetFamilies';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as OperationTypes from './OperationTypes';
import * as OperatorTypes from './ObjectSegOperatorTypes';
import * as PropertyTypes from 'customer-data-objects/property/PropertyTypes';
import { List, fromJS } from 'immutable';
import { __ANY_FORM, __ANY_WEBINAR, __PRIVACY_CONSENT
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
} from 'customer-data-filters/converters/listSegClassic/ListSegConstants';
import { BACKWARD, FORWARD } from '../../filterQueryFormat/rollingDates/RollingDateDirections';
import { fromAdsTimeOperation, getAdsKeyPathName, getOperatorForAds
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './fr... Remove this comment to see the full error message
} from './fromObjectSegFilter/fromAdsFilter';
import { fromCtaValueOperation, isNonPlacementCta
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './fr... Remove this comment to see the full error message
} from './fromObjectSegFilter/fromCtaFilter';
import { fromEmailCampaignOperation, fromSimpleEmailCampaignOperation, getOperatorForEmailCampaign
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './fr... Remove this comment to see the full error message
} from './fromObjectSegFilter/fromEmailEventFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './fr... Remove this comment to see the full error message
import { fromRollingDateRangeOperation } from './fromObjectSegFilter/fromRollingDateRangeOperation';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './fr... Remove this comment to see the full error message
import { fromEventOperation } from './fromObjectSegFilter/fromEventFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './fr... Remove this comment to see the full error message
import { fromFormSubmissionOperation } from './fromObjectSegFilter/fromFormSubmissionFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './fr... Remove this comment to see the full error message
import { fromPageViewOperation } from './fromObjectSegFilter/fromPageViewFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './fr... Remove this comment to see the full error message
import { fromPrivacyConsentOperation } from './fromObjectSegFilter/fromPrivacyConsentFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './Ob... Remove this comment to see the full error message
import { getDSAssetFamilyForType } from './ObjectSegTypeToDSAssetFamily';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './fr... Remove this comment to see the full error message
import { getTransformedListMembershipFilter } from './fromObjectSegFilter/fromListMembershipFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import { makeDynamicFilterFamily } from 'customer-data-filters/filterQueryFormat/DynamicFilterFamilies';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './Ob... Remove this comment to see the full error message
import { operatorTypeToOperator } from './ObjectSegOperators';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../.... Remove this comment to see the full error message
import DefaultNullValueRecord from '../../filterQueryFormat/DefaultNullValueRecord';
import I18n from 'I18n';
import MissingField from '../../filterQueryFormat/MissingField';
import getIn from 'transmute/getIn';
import invariant from 'react-utils/invariant';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../.... Remove this comment to see the full error message
import { getDefaultNullValue } from '../../utilities/defaultNullValue';
import { fromTimeOperation } from './fromObjectSegFilter/fromTimeOperationFilter';
import { hasNewIlsTimeFiltersEnabled, REMOVE_DEFAULT_VALUE_LOGIC } from '../../permissions/gates';
import { isILSFilter } from '../../types/objectSeg/ilsFilter';
// @ts-expect-error untyped
import * as ObjectSegConstants from './constants/constants';
import DeprecatedFilterField from '../listSegClassic/specialFields/DeprecatedFilterField';
import MissingAssociationBranchField from '../listSegClassic/specialFields/MissingAssociationBranchField';
import { getConverterOptionsWithDefaults } from './converterOptions';
const addDefaultNullValue = (operator, operation, isUngated) => {
  const defaultComparisonValue = typeof operation.defaultComparisonValue === 'undefined' ? null : operation.defaultComparisonValue;
  if (isUngated(REMOVE_DEFAULT_VALUE_LOGIC)) {
    return operator.set('defaultNullValue', DefaultNullValueRecord({
      includeObjectsWithNoValueSet: operation.includeObjectsWithNoValueSet,
      defaultComparisonValue
    }));
  } else {
    const defaultValue = typeof operation.defaultValue === 'undefined' ? null : operation.defaultValue;
    if (operation.includeObjectsWithNoValueSet && defaultComparisonValue === null) {
      return operator.set('defaultNullValue', getDefaultNullValue(operator, true));
    }
    return operator.set('defaultNullValue', DefaultNullValueRecord({
      defaultComparisonValue,
      defaultValue
    }));
  }
};
const validateOldDatetimeFiltersAccess = options => {
  const isUngatedForNewTimeFilters = hasNewIlsTimeFiltersEnabled(options.isUngated);
  invariant(!isUngatedForNewTimeFilters || options.isNotUsingTimePoints, 'Ungated for a new time filters but old datetime filter format is found');
};
const getUtcDateString = timestamp => I18n.moment.utc(timestamp).format('YYYY-MM-DD');
const getTzDateString = timestamp => I18n.moment.portalTz(timestamp).format('YYYY-MM-DD');
const fromUtcTimestampOperation = (Operator, field, {
  operation
}, options) => {
  validateOldDatetimeFiltersAccess(options);
  return Operator.of(field, getUtcDateString(operation.timestamp));
};
const fromPortalTimezoneTimestampOperation = (Operator, field, {
  operation
}, options) => {
  validateOldDatetimeFiltersAccess(options);
  return Operator.of(field, getTzDateString(operation.timestamp));
};
const fromValueOperation = (Operator, field, filter, options) => {
  const {
    operation
  } = filter;
  if (operation.propertyType === OperationTypes.DATE_TIME) {
    return fromPortalTimezoneTimestampOperation(Operator, field, filter, options);
  }
  return Operator.of(field, operation.value);
};
const listFromValue = value => {
  return typeof value === 'string' ? List([value]) : List();
};
const fromSurveyQuestionValueListOperation = (Operator, field, {
  valueComparison
}) => {
  return valueComparison.values ? Operator.of(field, List(valueComparison.values)) : Operator.of(field, listFromValue(valueComparison.value));
};
const fromSurveyQuestionValueOperation = (Operator, field, {
  valueComparison
}) => {
  return Operator.of(field, valueComparison.value);
};
const fromSurveyQuestionUtcTimestampOperation = (Operator, field, {
  valueComparison
}, options) => {
  validateOldDatetimeFiltersAccess(options);
  return Operator.of(field, getUtcDateString(valueComparison.timestamp));
};
const fromSurveyQuestionPortalTzTimestampOperation = (Operator, field, {
  valueComparison
}, options) => {
  validateOldDatetimeFiltersAccess(options);
  return Operator.of(field, getTzDateString(valueComparison.timestamp));
};
const fromSurveyQuestionTimeOperation = (Operator, field, {
  valueComparison
}, options) => {
  return fromTimeOperation(Operator, field,
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ operation: any; }' is not assi... Remove this comment to see the full error message
  {
    operation: valueComparison
  }, options);
};
const fromDeprecatedFilter = (Operator, field, filter) => {
  return Operator.of(field, filter.shouldAccept);
};
const fromMissingAssociationBranchFilter = (Operator, field, filter) => {
  return Operator.of(field, filter);
};
const fromValueListOperation = (Operator, field, {
  operation
}) => {
  return operation.values ? Operator.of(field, List(operation.values)) : Operator.of(field, listFromValue(operation.value));
};
const fromRangedOperation = (Operator, field, {
  operation
}) => {
  if (operation.propertyType === OperationTypes.DATE_TIME_RANGED) {
    return Operator.of(field, getUtcDateString(operation.lowerBoundTimestamp), getUtcDateString(operation.upperBoundTimestamp));
  }
  if (operation.propertyType === OperationTypes.NUMBER_RANGED && (Operator === Operators.InRange || Operator === Operators.NotInRange)) {
    // Hack: when date filters are migrated from contact list seg to ils they are
    // converted to 'number-ranged'.
    if (field.type !== OperationTypes.NUMBER) {
      const low = getTzDateString(operation.lowerBound);
      const high = getTzDateString(operation.upperBound);

      // Hack: in addition the the hack above, is equal to was also converted
      // using an in range operator. We'll need to convert it to being equal to the day
      if (low === high) {
        return Operators.Equal.of(field, low);
      }
      return Operator.of(field, low, high);
    }
    return Operator.of(field, operation.lowerBound, operation.upperBound);
  }
  return Operator.of(field, operation.value, operation.highValue);
};
const fromSimpleOperation = (Operator, field) => Operator.of(field);
const fromEmailSubscriptionOperation = (Operator, field, filter) => {
  const subscriptionIds = filter.subscriptionIds;
  return Operator.of(field, List(subscriptionIds).map(id => String(id)));
};
const fromRollingOperation = (Operator, field, {
  operation
}, options) => {
  validateOldDatetimeFiltersAccess(options);
  let direction = '';
  if ([OperatorTypes.IS_LESS_THAN_X_DAYS_AGO, OperatorTypes.IS_MORE_THAN_X_DAYS_AGO].includes(operation.operator)) {
    direction = BACKWARD;
  }
  if ([OperatorTypes.IS_LESS_THAN_X_DAYS_FROM_NOW, OperatorTypes.IS_MORE_THAN_X_DAYS_FROM_NOW].includes(operation.operator)) {
    direction = FORWARD;
  }
  if (operation.numberOfDays < 0) {
    direction = direction === FORWARD ? BACKWARD : FORWARD;
  }
  invariant(direction, `Can't determine direction for the ${operation.operator} operator`);
  return Operator.of(field, Math.abs(operation.numberOfDays), direction);
};
const fromPropertyUpdatedOperation = (Operator, field, {
  operation
}) => Operator.of(field, operation.numberOfDays);
const fromPropertyComparisonOperation = (Operator, field, {
  operation
}) => Operator.of(field, operation.comparisonPropertyName);
const fromSurveyQuestionRangedOperation = (Operator, field, {
  valueComparison
}) => {
  return fromRangedOperation(Operator, field, {
    operation: valueComparison
  });
};
const fromSurveyQuestionRollingOperation = (Operator, field, {
  valueComparison
}, options) => {
  return fromRollingOperation(Operator, field, {
    operation: valueComparison
  }, options);
};
const fromOperationMapping = fromJS({
  DEFAULT: {
    [OperatorTypes.IS_AFTER]: fromPropertyComparisonOperation,
    [OperatorTypes.IS_BEFORE]: fromPropertyComparisonOperation,
    [OperatorTypes.IS_ANY_OF]: fromValueListOperation,
    [OperatorTypes.IS_NONE_OF]: fromValueListOperation,
    [OperatorTypes.HAS_EVER_BEEN_ANY_OF]: fromValueListOperation,
    [OperatorTypes.HAS_NEVER_BEEN_ANY_OF]: fromValueListOperation,
    [OperatorTypes.IS_EQUAL_TO]: fromValueOperation,
    [OperatorTypes.IS_NOT_EQUAL_TO]: fromValueOperation,
    [OperatorTypes.IS_GREATER_THAN]: fromValueOperation,
    [OperatorTypes.IS_LESS_THAN]: fromValueOperation,
    [OperatorTypes.IS_GREATER_THAN_OR_EQUAL_TO]: fromValueOperation,
    [OperatorTypes.IS_LESS_THAN_OR_EQUAL_TO]: fromValueOperation,
    [OperatorTypes.IS_BEFORE_DATE]: fromUtcTimestampOperation,
    [OperatorTypes.IS_AFTER_DATE]: fromUtcTimestampOperation,
    [OperatorTypes.IS_BETWEEN]: fromRangedOperation,
    [OperatorTypes.IS_NOT_BETWEEN]: fromRangedOperation,
    [OperatorTypes.IS_KNOWN]: fromSimpleOperation,
    [OperatorTypes.IS_UNKNOWN]: fromSimpleOperation,
    [OperatorTypes.IN_LAST_TIME_UNIT]: fromRollingDateRangeOperation,
    [OperatorTypes.IN_THIS_TIME_UNIT]: fromRollingDateRangeOperation,
    [OperatorTypes.IN_THIS_TIME_UNIT_SO_FAR]: fromRollingDateRangeOperation,
    [OperatorTypes.IN_NEXT_TIME_UNIT]: fromRollingDateRangeOperation,
    [OperatorTypes.IS_LESS_THAN_X_DAYS_AGO]: fromRollingOperation,
    [OperatorTypes.IS_LESS_THAN_X_DAYS_FROM_NOW]: fromRollingOperation,
    [OperatorTypes.IS_MORE_THAN_X_DAYS_AGO]: fromRollingOperation,
    [OperatorTypes.IS_MORE_THAN_X_DAYS_FROM_NOW]: fromRollingOperation,
    [OperatorTypes.CONTAINS]: fromValueOperation,
    [OperatorTypes.DOES_NOT_CONTAIN]: fromValueOperation,
    [OperatorTypes.STARTS_WITH]: fromValueOperation,
    [OperatorTypes.ENDS_WITH]: fromValueOperation,
    [OperatorTypes.HAS_EVER_BEEN_EQUAL_TO]: fromValueOperation,
    [OperatorTypes.HAS_NEVER_BEEN_EQUAL_TO]: fromValueOperation,
    [OperatorTypes.HAS_EVER_CONTAINED]: fromValueOperation,
    [OperatorTypes.HAS_NEVER_CONTAINED]: fromValueOperation,
    [OperatorTypes.UPDATED_IN_LAST_X_DAYS]: fromPropertyUpdatedOperation,
    [OperatorTypes.NOT_UPDATED_IN_LAST_X_DAYS]: fromPropertyUpdatedOperation,
    [OperatorTypes.IS_EXACTLY]: fromValueListOperation,
    [OperatorTypes.IS_NOT_EXACTLY]: fromValueListOperation,
    [OperatorTypes.CONTAINS_ALL]: fromValueListOperation,
    [OperatorTypes.DOES_NOT_CONTAIN_ALL]: fromValueListOperation,
    [OperatorTypes.HAS_EVER_BEEN_EXACTLY]: fromValueListOperation,
    [OperatorTypes.HAS_NEVER_BEEN_EXACTLY]: fromValueListOperation,
    [OperatorTypes.HAS_EVER_CONTAINED_ALL]: fromValueListOperation,
    [OperatorTypes.HAS_NEVER_CONTAINED_ALL]: fromValueListOperation
  },
  [OperationTypes.STRING]: {
    [OperatorTypes.IS_EQUAL_TO]: fromValueListOperation,
    [OperatorTypes.IS_NOT_EQUAL_TO]: fromValueListOperation,
    [OperatorTypes.CONTAINS]: fromValueListOperation,
    [OperatorTypes.DOES_NOT_CONTAIN]: fromValueListOperation,
    [OperatorTypes.STARTS_WITH]: fromValueListOperation,
    [OperatorTypes.ENDS_WITH]: fromValueListOperation,
    [OperatorTypes.HAS_EVER_BEEN_EQUAL_TO]: fromValueListOperation,
    [OperatorTypes.HAS_NEVER_BEEN_EQUAL_TO]: fromValueListOperation
  },
  [OperationTypes.MULTI_STRING]: {
    [OperatorTypes.IS_EQUAL_TO]: fromValueListOperation,
    [OperatorTypes.IS_NOT_EQUAL_TO]: fromValueListOperation,
    [OperatorTypes.CONTAINS]: fromValueListOperation,
    [OperatorTypes.DOES_NOT_CONTAIN]: fromValueListOperation,
    [OperatorTypes.STARTS_WITH]: fromValueListOperation,
    [OperatorTypes.ENDS_WITH]: fromValueListOperation,
    [OperatorTypes.HAS_EVER_BEEN_EQUAL_TO]: fromValueListOperation,
    [OperatorTypes.HAS_NEVER_BEEN_EQUAL_TO]: fromValueListOperation
  },
  [OperationTypes.DATE_TIME]: {
    [OperatorTypes.IS_AFTER]: fromPortalTimezoneTimestampOperation,
    [OperatorTypes.IS_BEFORE]: fromPortalTimezoneTimestampOperation,
    [OperatorTypes.IS_EQUAL_TO]: fromUtcTimestampOperation
  },
  [OperationTypes.RANGED_TIME]: {
    [OperatorTypes.IS_BETWEEN]: fromTimeOperation,
    [OperatorTypes.IS_NOT_BETWEEN]: fromTimeOperation
  },
  [OperationTypes.TIME_POINT]: {
    [OperatorTypes.IS_AFTER]: fromTimeOperation,
    [OperatorTypes.IS_BEFORE]: fromTimeOperation
  }
});
const propertyOperationToOperator = (filter, field, options) => {
  const {
    operator: operatorType,
    propertyType
  } = filter.operation;
  const fromOperation = fromOperationMapping.getIn([propertyType, operatorType]) || fromOperationMapping.getIn(['DEFAULT', operatorType]);
  const Operator = operatorTypeToOperator(operatorType, propertyType, filter);
  return fromOperation(Operator, field, filter, options);
};
const operationPropertyTypes = {
  [OperationTypes.ALL]: PropertyTypes.STRING,
  [OperationTypes.STRING]: PropertyTypes.STRING,
  [OperationTypes.MULTI_STRING]: PropertyTypes.STRING,
  [OperationTypes.NUMBER]: PropertyTypes.NUMBER,
  [OperationTypes.BOOLEAN]: PropertyTypes.BOOLEAN,
  [OperationTypes.ENUMERATION]: PropertyTypes.ENUMERATION,
  [OperationTypes.DATE_TIME]: PropertyTypes.DATE_TIME,
  [OperationTypes.DATE_TIME_COMPARATIVE]: PropertyTypes.DATE_TIME,
  [OperationTypes.DATE_TIME_RANGED]: PropertyTypes.DATE_TIME,
  [OperationTypes.DATE_TIME_ROLLING]: PropertyTypes.DATE_TIME
};

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'operationType' implicitly has an ... Remove this comment to see the full error message
const getOperationPropertyType = ({
  propertyType: operationType
}) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return operationPropertyTypes[operationType] || PropertyTypes.STRING;
};
const fromFilterFamilyMapping = fromJS({
  [ObjectSegConstants.DEPRECATED_FILTER]: {
    DEFAULT: {
      [OperatorTypes.CONSTANT]: fromDeprecatedFilter
    }
  },
  [ObjectSegConstants.MISSING_ASSOCIATION_BRANCH_FILTER]: {
    DEFAULT: {
      [OperatorTypes.IS_IN_LIST]: fromMissingAssociationBranchFilter
    }
  },
  [DSAssetFamilies.ADS_PROPERTY]: {
    DEFAULT: {
      [OperatorTypes.IS_ANY_OF]: fromValueListOperation,
      [OperatorTypes.IS_KNOWN]: fromSimpleOperation,
      [OperatorTypes.IS_EQUAL_TO]: fromValueListOperation,
      [OperatorTypes.CONTAINS]: fromValueListOperation,
      [OperatorTypes.STARTS_WITH]: fromValueListOperation,
      [OperatorTypes.ENDS_WITH]: fromValueListOperation,
      [OperatorTypes.IS_LESS_THAN]: fromAdsTimeOperation,
      [OperatorTypes.IS_GREATER_THAN]: fromAdsTimeOperation,
      [OperatorTypes.IS_NOT_BETWEEN]: fromAdsTimeOperation,
      [OperatorTypes.IS_BETWEEN]: fromAdsTimeOperation,
      [OperatorTypes.LESS_THAN_X_AGO_OR_FROM_NOW]: fromAdsTimeOperation,
      [OperatorTypes.MORE_THAN_X_AGO_OR_FROM_NOW]: fromAdsTimeOperation
    }
  },
  [DSAssetFamilies.CTA]: {
    DEFAULT: {
      [OperatorTypes.HAS_CLICKED_CTA]: fromCtaValueOperation,
      [OperatorTypes.HAS_NOT_CLICKED_CTA]: fromCtaValueOperation,
      [OperatorTypes.HAS_OPENED_CTA]: fromCtaValueOperation,
      [OperatorTypes.HAS_NOT_OPENED_CTA]: fromCtaValueOperation,
      [OperatorTypes.HAS_CLICKED_CTA_PLACEMENT]: fromCtaValueOperation,
      [OperatorTypes.HAS_NOT_CLICKED_CTA_PLACEMENT]: fromCtaValueOperation,
      [OperatorTypes.HAS_OPENED_CTA_PLACEMENT]: fromCtaValueOperation,
      [OperatorTypes.HAS_NOT_OPENED_CTA_PLACEMENT]: fromCtaValueOperation
    }
  },
  [DSAssetFamilies.EVENT]: {
    DEFAULT: {
      [OperatorTypes.HAS_EVENT]: fromEventOperation,
      [OperatorTypes.NOT_HAS_EVENT]: fromEventOperation
    }
  },
  [DSAssetFamilies.PRIVACY_CONSENT]: {
    DEFAULT: {
      [OperatorTypes.PRIVACY_CONSENT_NOT_GRANTED]: fromPrivacyConsentOperation,
      [OperatorTypes.PRIVACY_CONSENT_GRANTED]: fromPrivacyConsentOperation
    }
  },
  [DSAssetFamilies.IN_LIST]: {
    DEFAULT: {
      [OperatorTypes.IS_IN_LIST]: fromSimpleOperation,
      [OperatorTypes.IS_NOT_IN_LIST]: fromSimpleOperation
    }
  },
  [DSAssetFamilies.IMPORT]: {
    DEFAULT: {
      [OperatorTypes.IS_IN_IMPORT]: fromSimpleOperation,
      [OperatorTypes.IS_NOT_IN_IMPORT]: fromSimpleOperation
    }
  },
  [DSAssetFamilies.INTEGRATION]: {
    DEFAULT: {
      [OperatorTypes.IS_AFTER]: fromPortalTimezoneTimestampOperation,
      [OperatorTypes.IS_BEFORE]: fromPortalTimezoneTimestampOperation,
      [OperatorTypes.IS_ANY_OF]: fromValueListOperation,
      [OperatorTypes.IS_NONE_OF]: fromValueListOperation,
      [OperatorTypes.HAS_EVER_BEEN_ANY_OF]: fromValueListOperation,
      [OperatorTypes.HAS_NEVER_BEEN_ANY_OF]: fromValueListOperation,
      [OperatorTypes.IS_EQUAL_TO]: fromValueOperation,
      [OperatorTypes.IS_NOT_EQUAL_TO]: fromValueOperation,
      [OperatorTypes.IS_GREATER_THAN]: fromValueOperation,
      [OperatorTypes.IS_LESS_THAN]: fromValueOperation,
      [OperatorTypes.IS_GREATER_THAN_OR_EQUAL_TO]: fromValueOperation,
      [OperatorTypes.IS_LESS_THAN_OR_EQUAL_TO]: fromValueOperation,
      [OperatorTypes.IS_BEFORE_DATE]: fromUtcTimestampOperation,
      [OperatorTypes.IS_AFTER_DATE]: fromUtcTimestampOperation,
      [OperatorTypes.IS_BETWEEN]: fromRangedOperation,
      [OperatorTypes.IS_NOT_BETWEEN]: fromRangedOperation,
      [OperatorTypes.IS_KNOWN]: fromSimpleOperation,
      [OperatorTypes.IS_UNKNOWN]: fromSimpleOperation,
      [OperatorTypes.IS_LESS_THAN_X_DAYS_AGO]: fromRollingOperation,
      [OperatorTypes.IS_LESS_THAN_X_DAYS_FROM_NOW]: fromRollingOperation,
      [OperatorTypes.IS_MORE_THAN_X_DAYS_AGO]: fromRollingOperation,
      [OperatorTypes.IS_MORE_THAN_X_DAYS_FROM_NOW]: fromRollingOperation,
      [OperatorTypes.CONTAINS]: fromValueOperation,
      [OperatorTypes.DOES_NOT_CONTAIN]: fromValueOperation,
      [OperatorTypes.STARTS_WITH]: fromValueOperation,
      [OperatorTypes.ENDS_WITH]: fromValueOperation,
      [OperatorTypes.HAS_EVER_BEEN_EQUAL_TO]: fromValueOperation,
      [OperatorTypes.HAS_NEVER_BEEN_EQUAL_TO]: fromValueOperation,
      [OperatorTypes.HAS_EVER_CONTAINED]: fromValueOperation,
      [OperatorTypes.HAS_NEVER_CONTAINED]: fromValueOperation,
      [OperatorTypes.UPDATED_IN_LAST_X_DAYS]: fromPropertyUpdatedOperation,
      [OperatorTypes.NOT_UPDATED_IN_LAST_X_DAYS]: fromPropertyUpdatedOperation
    },
    [OperationTypes.STRING]: {
      [OperatorTypes.IS_EQUAL_TO]: fromValueListOperation,
      [OperatorTypes.IS_NOT_EQUAL_TO]: fromValueListOperation,
      [OperatorTypes.CONTAINS]: fromValueListOperation,
      [OperatorTypes.DOES_NOT_CONTAIN]: fromValueListOperation,
      [OperatorTypes.STARTS_WITH]: fromValueListOperation,
      [OperatorTypes.ENDS_WITH]: fromValueListOperation
    },
    [OperationTypes.MULTI_STRING]: {
      [OperatorTypes.IS_EQUAL_TO]: fromValueListOperation,
      [OperatorTypes.IS_NOT_EQUAL_TO]: fromValueListOperation,
      [OperatorTypes.CONTAINS]: fromValueListOperation,
      [OperatorTypes.DOES_NOT_CONTAIN]: fromValueListOperation,
      [OperatorTypes.STARTS_WITH]: fromValueListOperation,
      [OperatorTypes.ENDS_WITH]: fromValueListOperation
    },
    [OperationTypes.DATE_TIME]: {
      [OperatorTypes.IS_EQUAL_TO]: fromUtcTimestampOperation
    },
    [OperationTypes.RANGED_TIME]: {
      [OperatorTypes.IS_BETWEEN]: fromTimeOperation,
      [OperatorTypes.IS_NOT_BETWEEN]: fromTimeOperation
    },
    [OperationTypes.TIME_POINT]: {
      [OperatorTypes.IS_AFTER]: fromTimeOperation,
      [OperatorTypes.IS_BEFORE]: fromTimeOperation
    }
  },
  [DSAssetFamilies.FORM]: {
    DEFAULT: {
      [OperatorTypes.FORM_SUBMISSION]: fromFormSubmissionOperation,
      [OperatorTypes.FORM_SUBMISSION_ON_PAGE]: fromFormSubmissionOperation
    }
  },
  [DSAssetFamilies.PAGE_VIEW]: {
    DEFAULT: {
      [OperatorTypes.HAS_PAGEVIEW_EQ]: fromPageViewOperation,
      [OperatorTypes.HAS_PAGEVIEW_CONTAINS]: fromPageViewOperation,
      [OperatorTypes.HAS_PAGEVIEW_MATCHES_REGEX]: fromPageViewOperation,
      [OperatorTypes.NOT_HAS_PAGEVIEW_EQ]: fromPageViewOperation,
      [OperatorTypes.NOT_HAS_PAGEVIEW_CONTAINS]: fromPageViewOperation,
      [OperatorTypes.NOT_HAS_PAGEVIEW_MATCHES_REGEX]: fromPageViewOperation
    }
  },
  [DSAssetFamilies.SURVEY_MONKEY_SURVEY]: {
    DEFAULT: {
      [OperatorTypes.HAS_RESPONDED_TO_SURVEY]: fromSimpleOperation,
      [OperatorTypes.HAS_NOT_RESPONDED_TO_SURVEY]: fromSimpleOperation
    }
  },
  [DSAssetFamilies.SURVEY_MONKEY_QUESTION]: {
    DEFAULT: {
      [OperatorTypes.IS_ANY_OF]: fromSurveyQuestionValueListOperation,
      [OperatorTypes.IS_NONE_OF]: fromSurveyQuestionValueListOperation,
      [OperatorTypes.IS_KNOWN]: fromSimpleOperation,
      [OperatorTypes.IS_UNKNOWN]: fromSimpleOperation,
      [OperatorTypes.CONTAINS]: fromSurveyQuestionValueListOperation,
      [OperatorTypes.DOES_NOT_CONTAIN]: fromSurveyQuestionValueListOperation,
      [OperatorTypes.STARTS_WITH]: fromSurveyQuestionValueListOperation,
      [OperatorTypes.ENDS_WITH]: fromSurveyQuestionValueListOperation,
      [OperatorTypes.IS_EQUAL_TO]: fromSurveyQuestionValueOperation,
      [OperatorTypes.IS_NOT_EQUAL_TO]: fromSurveyQuestionValueOperation,
      [OperatorTypes.IS_LESS_THAN]: fromSurveyQuestionValueOperation,
      [OperatorTypes.IS_LESS_THAN_OR_EQUAL_TO]: fromSurveyQuestionValueOperation,
      [OperatorTypes.IS_GREATER_THAN]: fromSurveyQuestionValueOperation,
      [OperatorTypes.IS_GREATER_THAN_OR_EQUAL_TO]: fromSurveyQuestionValueOperation,
      [OperatorTypes.IS_NOT_BETWEEN]: fromSurveyQuestionRangedOperation,
      [OperatorTypes.IS_BETWEEN]: fromSurveyQuestionRangedOperation,
      [OperatorTypes.IS_LESS_THAN_X_DAYS_AGO]: fromSurveyQuestionRollingOperation,
      [OperatorTypes.IS_LESS_THAN_X_DAYS_FROM_NOW]: fromSurveyQuestionRollingOperation,
      [OperatorTypes.IS_MORE_THAN_X_DAYS_AGO]: fromSurveyQuestionRollingOperation,
      [OperatorTypes.IS_MORE_THAN_X_DAYS_FROM_NOW]: fromSurveyQuestionRollingOperation
    },
    [OperationTypes.MULTI_STRING]: {
      [OperatorTypes.IS_EQUAL_TO]: fromSurveyQuestionValueListOperation,
      [OperatorTypes.IS_NOT_EQUAL_TO]: fromSurveyQuestionValueListOperation
    },
    [OperationTypes.DATE_TIME]: {
      [OperatorTypes.IS_EQUAL_TO]: fromSurveyQuestionUtcTimestampOperation,
      [OperatorTypes.IS_BEFORE]: fromSurveyQuestionPortalTzTimestampOperation,
      [OperatorTypes.IS_AFTER]: fromSurveyQuestionPortalTzTimestampOperation
    },
    [OperationTypes.RANGED_TIME]: {
      [OperatorTypes.IS_BETWEEN]: fromSurveyQuestionTimeOperation,
      [OperatorTypes.IS_NOT_BETWEEN]: fromSurveyQuestionTimeOperation
    },
    [OperationTypes.TIME_POINT]: {
      [OperatorTypes.IS_AFTER]: fromSurveyQuestionTimeOperation,
      [OperatorTypes.IS_BEFORE]: fromSurveyQuestionTimeOperation
    }
  },
  [DSAssetFamilies.GOTO_WEBINAR_WEBINAR]: {
    DEFAULT: {
      [OperatorTypes.HAS_WEBINAR_REGISTRATION]: fromSimpleOperation,
      [OperatorTypes.NOT_HAS_WEBINAR_REGISTRATION]: fromSimpleOperation,
      [OperatorTypes.HAS_WEBINAR_ATTENDANCE]: fromSimpleOperation,
      [OperatorTypes.NOT_HAS_WEBINAR_ATTENDANCE]: fromSimpleOperation
    }
  },
  [DSAssetFamilies.EMAIL_CAMPAIGN]: {
    DEFAULT: {
      [OperatorTypes.EMAIL_BOUNCED]: fromSimpleEmailCampaignOperation,
      [OperatorTypes.EMAIL_LINK_CLICKED]: fromEmailCampaignOperation,
      [OperatorTypes.EMAIL_MARKED]: fromSimpleEmailCampaignOperation,
      [OperatorTypes.EMAIL_OPENED]: fromSimpleEmailCampaignOperation,
      [OperatorTypes.EMAIL_OPENED_BUT_LINK_NOT_CLICKED]: fromSimpleEmailCampaignOperation,
      [OperatorTypes.EMAIL_OPENED_BUT_NOT_REPLIED]: fromSimpleEmailCampaignOperation,
      [OperatorTypes.EMAIL_RECEIVED]: fromSimpleEmailCampaignOperation,
      [OperatorTypes.EMAIL_RECEIVED_BUT_NOT_OPENED]: fromSimpleEmailCampaignOperation,
      [OperatorTypes.EMAIL_REPLIED]: fromSimpleEmailCampaignOperation,
      [OperatorTypes.EMAIL_SENT]: fromSimpleEmailCampaignOperation,
      [OperatorTypes.EMAIL_SENT_BUT_LINK_NOT_CLICKED]: fromSimpleEmailCampaignOperation,
      [OperatorTypes.EMAIL_SENT_BUT_NOT_RECEIVED]: fromSimpleEmailCampaignOperation,
      [OperatorTypes.EMAIL_UNSUBSCRIBED]: fromSimpleEmailCampaignOperation
    }
  },
  [DSAssetFamilies.EMAIL_SUBSCRIPTION]: {
    DEFAULT: {
      [OperatorTypes.HAS_OPTED_IN]: fromEmailSubscriptionOperation,
      [OperatorTypes.HAS_OPTED_OUT]: fromEmailSubscriptionOperation,
      [OperatorTypes.HAS_NOT_OPTED]: fromEmailSubscriptionOperation
    }
  },
  [DSAssetFamilies.WORKFLOW]: {
    DEFAULT: {
      [OperatorTypes.WORKFLOW_ACTIVE]: fromSimpleOperation,
      [OperatorTypes.WORKFLOW_NOT_ACTIVE]: fromSimpleOperation,
      [OperatorTypes.WORKFLOW_COMPLETED]: fromSimpleOperation,
      [OperatorTypes.WORKFLOW_NOT_COMPLETED]: fromSimpleOperation,
      [OperatorTypes.WORKFLOW_ENROLLED]: fromSimpleOperation,
      [OperatorTypes.WORKFLOW_NOT_ENROLLED]: fromSimpleOperation,
      [OperatorTypes.WORKFLOW_MET_GOAL]: fromSimpleOperation,
      [OperatorTypes.WORKFLOW_NOT_MET_GOAL]: fromSimpleOperation
    }
  }
});
const fromPropertyFilter = (filter, getField, filterFamily, options) => {
  const {
    operation,
    property: propertyName
  } = filter;
  const field = getField([filterFamily, propertyName]) || getField([propertyName]) || MissingField({
    name: propertyName,
    label: propertyName,
    type: getOperationPropertyType(operation)
  });
  const operator = propertyOperationToOperator(filter, field, options);
  return addDefaultNullValue(operator, operation, options.isUngated);
};
const getOperatorForFilter = (filterFamily, propertyType, operatorType, field, filter, options) => {
  const fromOperator = getIn([filterFamily, propertyType, operatorType], fromFilterFamilyMapping) || getIn([filterFamily, 'DEFAULT', operatorType], fromFilterFamilyMapping);
  const Operator = operatorTypeToOperator(operatorType, propertyType, filter);
  if (filterFamily === DSAssetFamilies.INTEGRATION) {
    const operation = filter.filterLines[0].operation;
    const operator = fromOperator(Operator, field, {
      operation
    }, options);
    return addDefaultNullValue(operator, operation, options.isUngated);
  }
  if (filterFamily === DSAssetFamilies.ADS_PROPERTY && filter.filterType === ObjectSegFilterTypes.ADS_SEARCH) {
    const operation = {
      values: filter.searchTerms
    };
    return fromOperator(Operator, field, {
      operation
    }, options);
  }
  return fromOperator(Operator, field, filter, options);
};
const makeSurveyQuestionId = filter => {
  const {
    surveyQuestion,
    surveyAnswerRowId,
    surveyAnswerColId
  } = filter;
  return `${surveyQuestion}-${surveyAnswerRowId || ''}-${surveyAnswerColId || ''}`;
};
const getPlaceholderFieldKeyPath = filterFamily => {
  return [filterFamily, filterFamily];
};
const filterFamilyRequiresPlaceholderField = filterFamily => filterFamily === DSAssetFamilies.PAGE_VIEW || filterFamily === DSAssetFamilies.EMAIL_SUBSCRIPTION;
const getFieldKeyPath = (filter, filterFamily) => {
  const requiresPlaceholder = filterFamilyRequiresPlaceholderField(filterFamily);
  if (requiresPlaceholder) {
    return getPlaceholderFieldKeyPath(filterFamily);
  }
  const {
    filterType
  } = filter;
  switch (filterType) {
    case ObjectSegFilterTypes.ADS_SEARCH:
    case ObjectSegFilterTypes.ADS_TIME:
      return [filterFamily, getAdsKeyPathName(filter)];
    case ObjectSegFilterTypes.CTA:
      return [isNonPlacementCta(filter.operator) ? DSAssetFamilies.CTA_TO_PLACEMENT : DSAssetFamilies.CTA, filter.ctaName];
    case ObjectSegFilterTypes.EVENT:
      return [filterFamily, filter.eventId];
    case ObjectSegFilterTypes.PRIVACY_CONSENT:
      return [filterFamily, __PRIVACY_CONSENT];
    case ObjectSegFilterTypes.EMAIL_EVENT:
      return [filterFamily, filter.emailId];
    case ObjectSegFilterTypes.FORM_SUBMISSION_ON_PAGE:
    case ObjectSegFilterTypes.FORM_SUBMISSION:
      return [filterFamily, filter.formId || __ANY_FORM];
    case ObjectSegFilterTypes.IN_IMPORT:
      return [filterFamily, filter.importId];
    case ObjectSegFilterTypes.IN_LIST:
      return [filterFamily, filter.listId];
    case ObjectSegFilterTypes.INTEGRATION_EVENT:
      return [makeDynamicFilterFamily(filterFamily, filter.eventTypeId), filter.filterLines[0].property];
    case ObjectSegFilterTypes.PAGE_VIEW:
      return [filterFamily, filter.pageUrl];
    case ObjectSegFilterTypes.SURVEY_MONKEY:
      return [filterFamily, filter.surveyId];
    case ObjectSegFilterTypes.SURVEY_MONKEY_VALUE:
      return [filterFamily, makeSurveyQuestionId(filter)];
    case ObjectSegFilterTypes.WEBINAR:
      return [filterFamily, filter.webinarId || __ANY_WEBINAR];
    case ObjectSegFilterTypes.WORKFLOW:
      return [filterFamily, filter.workflowId];
    default:
      return [filterFamily, filterFamily];
  }
};
const getOperatorName = (filter, filterFamily) => {
  switch (filterFamily) {
    case DSAssetFamilies.ADS_PROPERTY:
      return getOperatorForAds(filter);
    case DSAssetFamilies.EMAIL_CAMPAIGN:
      return getOperatorForEmailCampaign(filter);
    case DSAssetFamilies.EMAIL_SUBSCRIPTION:
      return filter.acceptedStatuses[0];
    case DSAssetFamilies.FORM:
      return filter.filterType;
    case DSAssetFamilies.SURVEY_MONKEY_QUESTION:
      return getIn(['valueComparison', 'operator'], filter);
    case DSAssetFamilies.INTEGRATION:
      return filter.filterLines[0].operation.operator;
    default:
      return filter.operator;
  }
};
const getPropertyType = (filter, filterFamily) => {
  switch (filterFamily) {
    case DSAssetFamilies.ADS_PROPERTY:
      return OperationTypes.MULTI_STRING;
    case DSAssetFamilies.INTEGRATION:
      return filter.filterLines[0].operation.propertyType;
    case DSAssetFamilies.SURVEY_MONKEY_QUESTION:
      return filter.valueComparison.propertyType;
    default:
      return undefined;
  }
};

// If the filterType can represent filters of multiple filterFamilies, the
// filter and filter family used for the conversion must undergo a
// pre-conversion translation to a format the converter supports.
const getTransformedFilter = (filter, filterFamily) => {
  if (filter.filterType === ObjectSegFilterTypes.IN_LIST) {
    return getTransformedListMembershipFilter(filter);
  }
  return [filter, filterFamily];
};
const fromFilter = (filter, getField, filterFamily, options) => {
  [filter, filterFamily] = getTransformedFilter(filter, filterFamily);
  let operatorName = getOperatorName(filter, filterFamily);
  const propertyType = getPropertyType(filter, filterFamily);
  let keyPath = getFieldKeyPath(filter, filterFamily);

  // Non placement cta's need to get the associated placement id from the cta
  if (isNonPlacementCta(filter.operator)) {
    const innerField = getField(keyPath);
    keyPath = [DSAssetFamilies.CTA, getIn(['name'], innerField)];
  }
  let field = getField(keyPath);
  if (filter.filterType === ObjectSegFilterTypes.CONSTANT) {
    field = DeprecatedFilterField();
    operatorName = OperatorTypes.CONSTANT;
  }
  if (filter.filterType === ObjectSegFilterTypes.ASSOCIATION) {
    field = MissingAssociationBranchField();
  }
  invariant(operatorName, `Unable to convert operator "${operatorName}"`);
  invariant(field, `Unable to get a field for operator "${operatorName}"`);
  return getOperatorForFilter(filterFamily, propertyType, operatorName, field, filter, options);
};

/**
 * Convert InboundDbListSeg filter to
 * {@link https://product.hubteam.com/docs/crm-filters/legacy-docs/FilterQueryFormat/Overview.html Filter Query Format}.
 */
export const fromObjectSegFilterStrict = (filter, getField, filterFamily, isUngated, isNotUsingTimePoints = false) => {
  invariant(isILSFilter(filter), `Expected ${filter} to be an ILSFilter`);
  invariant(typeof getField === 'function', `Expected ${getField} to be a function`);
  invariant(typeof filterFamily === 'string', `Expected ${filterFamily} to be a string`);
  invariant(typeof isUngated === 'function', `Expected ${isUngated} to be a function`);
  const converterOptions = getConverterOptionsWithDefaults({
    isUngated,
    isNotUsingTimePoints
  });
  if (filter.filterType === ObjectSegFilterTypes.PROPERTY) {
    return fromPropertyFilter(filter, getField, filterFamily, converterOptions);
  }
  const derivedFilterFamily = getDSAssetFamilyForType(filter.filterType);
  invariant(derivedFilterFamily, `Unable to determine what filter family to use for filter type: "${filter.filterType}"`);
  return fromFilter(filter, getField, derivedFilterFamily, converterOptions);
};