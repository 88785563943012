import { fetchPropertyMetadataEntries } from './frameworkBuilderReadApi';
import { makePropertyMetadataEntriesFetchKey } from 'framework-data-schema-quick-fetch';
import { deepFreeze } from '../cache/deepFreeze';
import { createPersistedAsyncCache } from '../cache/createPersistedAsyncCache';
import { Metrics } from '../metrics';
import { makeLoadFailed } from '../utils/makeLoadFailed';
import { normalizeCustomIdentifier } from '../utils/normalizeCustomIdentifier';
const defaultAsyncPropertyMetadataRequestCache = createPersistedAsyncCache({
  cacheName: 'propertyMetadata',
  segmentKey: key => {
    const match = key.match(makePropertyMetadataEntriesFetchKey({
      portalId: '.*',
      frameworkTypeIdentifier: '(.*)',
      appSettingName: '(.*)'
    }));
    if (!match || !match[1] || !match[2]) {
      return null;
    }
    const [__, identifier, appSettingName] = match;
    return `${normalizeCustomIdentifier(identifier)}-${appSettingName}`;
  }
});
export const makePropertyMetadataClient = ({
  httpClient,
  requestCache = defaultAsyncPropertyMetadataRequestCache,
  toRequestCacheKey = makePropertyMetadataEntriesFetchKey
}) => {
  const client = {
    /**
     * Prints debug info to the console.
     */
    debug: () => {
      requestCache.printDebug();
    },
    /**
     * Clears internal cache state.
     *
     * @returns A promise which resolves when state is clear.
     */
    clearCache: async () => {
      await Promise.resolve(requestCache.clear());
    },
    /**
     * Gets all property metadata entries for the specified app setting under the specified framework type.
     *
     * @param options.appSettingName The app setting to fetch entries for.
     * @param options.frameworkTypeIdentifier The framework type to fetch entries for under the app setting.
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to the property metadata entries, or null if the data could not be found.
     */
    get: ({
      frameworkTypeIdentifier,
      appSettingName,
      __isComposed = false
    }) => {
      if (!__isComposed) {
        Metrics.counter('propertyMetadata.get').increment();
      }
      const cachedValue = requestCache.readThrough({
        cacheKey: toRequestCacheKey({
          frameworkTypeIdentifier,
          appSettingName
        }),
        loadValue: () => fetchPropertyMetadataEntries({
          frameworkTypeIdentifier,
          appSettingName,
          httpClient
        }).then(deepFreeze)
      });
      return cachedValue === null ? makeLoadFailed() : cachedValue;
    }
  };
  return Promise.resolve(client);
};