'use es6';

import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as OperatorTypes from '../ObjectSegOperatorTypes';
import { AdsEntityListTypes, AdsInteraction, AdsSearchTermTypes } from '../../listSegClassic/ListSegConstants';
import { PRUNING_REFINE_BY_TYPE, TIME_COMPARISON, TIME_DIRECTION } from '../constants/constants';
import fromRefinementFilter from './fromRefinementFilter';
import getIn from 'transmute/getIn';
const SearchTermTypeToSuffix = {
  [AdsEntityListTypes.AD_TYPE]: 'type',
  [AdsEntityListTypes.ID]: 'id',
  [AdsEntityListTypes.NAME]: 'name',
  [AdsEntityListTypes.TIMESTAMP]: 'timestamp'
};
const EntityTypeToEntity = {
  [AdsSearchTermTypes.AD]: 'ad',
  [AdsSearchTermTypes.ADGROUP]: 'adgroup',
  [AdsSearchTermTypes.CAMPAIGN]: 'campaign',
  [AdsSearchTermTypes.KEYWORD]: 'keyword'
};
export const getOperatorForAds = filter => {
  if (filter.operator) {
    return filter.operator;
  }

  // This filter can use a refinement as its operator, in that case the operator must be derived
  // https://git.hubteam.com/HubSpot/customer-data-filters/pull/775

  const {
    pruningRefineBy: {
      type: pruningType,
      comparison,
      rangeType
    }
  } = filter;
  if (pruningType === PRUNING_REFINE_BY_TYPE.ABSOLUTE) {
    return comparison === TIME_COMPARISON.BEFORE ? OperatorTypes.IS_LESS_THAN : OperatorTypes.IS_GREATER_THAN;
  }
  if (pruningType === PRUNING_REFINE_BY_TYPE.ABSOLUTE_RANGE) {
    return rangeType === TIME_COMPARISON.NOT_BETWEEN ? OperatorTypes.IS_NOT_BETWEEN : OperatorTypes.IS_BETWEEN;
  }
  if (pruningType === PRUNING_REFINE_BY_TYPE.RELATIVE_RANGE) {
    return OperatorTypes.LESS_THAN_X_AGO_OR_FROM_NOW;
  }
  if (pruningType === PRUNING_REFINE_BY_TYPE.RELATIVE) {
    const direction = getIn(['pruningRefineBy', 'timeOffset', 'offsetDirection'], filter);
    if (
    // less than X days/weeks ago
    comparison === TIME_COMPARISON.AFTER && direction === TIME_DIRECTION.PAST ||
    // less than X days/weeks from now
    comparison === TIME_COMPARISON.BEFORE && direction === TIME_DIRECTION.FUTURE) {
      return OperatorTypes.LESS_THAN_X_AGO_OR_FROM_NOW;
    }
    if (
    // more than X days/weeks ago
    comparison === TIME_COMPARISON.BEFORE && direction === TIME_DIRECTION.PAST ||
    // more than X days/weeks from now
    comparison === TIME_COMPARISON.AFTER && direction === TIME_DIRECTION.FUTURE) {
      return OperatorTypes.MORE_THAN_X_AGO_OR_FROM_NOW;
    }
  }
  return undefined;
};
export const fromAdsTimeOperation = (Operator, field, filter) => {
  // do not use the basic refinement field, use the real field
  return fromRefinementFilter(field, filter).set('field', field);
};
export const getAdsKeyPathName = filter => {
  const {
    adNetwork,
    entityType
  } = filter;
  const searchTermType = filter.filterType === ObjectSegFilterTypes.ADS_TIME ? AdsEntityListTypes.TIMESTAMP : filter.searchTermType;
  const suffix = SearchTermTypeToSuffix[searchTermType];
  let parts;
  if (searchTermType === AdsEntityListTypes.TIMESTAMP) {
    parts = [AdsSearchTermTypes.AD, AdsInteraction, suffix];
  } else if (searchTermType === AdsEntityListTypes.AD_TYPE) {
    parts = [adNetwork, AdsSearchTermTypes.AD, suffix];
  } else {
    const entity = EntityTypeToEntity[entityType];
    parts = [adNetwork, entity, suffix];
  }
  return parts.join('_').toLowerCase();
};