'use es6';

import get from 'transmute/get';
import { stringIfPresent } from '../../private/js-util';
export const getId = get('id');
export const getIdStr = dashboard => stringIfPresent(getId(dashboard));
export const getOwnerId = get('ownerId');
export const getOwnerIdStr = dashboard => stringIfPresent(getOwnerId(dashboard));
export const getDemo = get('demo');
export const getName = get('name');
export const getDescription = get('description');
export const getWidgets = get('widgets');
export const getWidgetCount = get('widgetCount');
export const getType = get('type');
export const getPermissionSentence = get('permissionSentence');
export const getCreatedAt = get('createdAt');
export const getShowDescription = get('showDescription');
export const getFavorite = get('favorite');
export const getBusinessUnit = get('businessUnitId');
export const getUserPermissionLevel = get('userPermissionLevel');
export const getOwnerUser = get('ownerUser');
export const getUpdatedAt = get('updatedAt');
export const getUpdatedByUser = get('updatedByUser');
export const getTemplate = get('template');
export const getPinnedProperties = get('pinnedProperties');