'use es6';

import once from 'transmute/once';
import { isApiAction, isCachedAction } from './create-request-action';
import { selectResource } from './redux-request';
import { RequestActionType } from './request-action';
import { getPromise, getValue, isInitialized, isPending } from './resource-state';

// Middleware that complements "create-request-action"

/**
 * Checks the resource (inferred from the given action), if:
 * "pending":
 *    an on-going request is currently happening; returns the cached promise
 * "succeeded":
 *    the resource has cached data; returns the cached value
 *  Otherwise:
 *    next(action), pass to the next middleware / store
 */
export const cachedRequest = ({
  getState
}) => next => action => {
  if (!isCachedAction(action)) {
    return next(action);
  }
  const {
    prefix
  } = action.payload;
  const resource = selectResource(prefix, getState());
  if (isPending(resource)) {
    return getPromise(resource);
  }
  if (isInitialized(resource)) {
    return Promise.resolve(getValue(resource));
  }
  return next(action);
};
const {
  PEND,
  SUCCEED,
  FAIL,
  CANCEL
} = RequestActionType;

/**
 * Checks the resource (inferred from the given action), if:
 * "pending":
 *    an on-going request is currently happening; returns the cached promise
 * "succeeded":
 *    the resource has cached data; returns the cached value
 *  Otherwise:
 *    next(action), pass to the next middleware / store
 */
export const asyncRequest = ({
  dispatch
}) => next => action => {
  if (!isApiAction(action)) {
    return next(action);
  }
  const {
    requestParams,
    requestFunction
  } = action.payload;
  const {
    asyncActions
  } = action.meta;
  const pend = asyncActions[PEND];
  const succeed = asyncActions[SUCCEED];
  const fail = asyncActions[FAIL];
  const cancel = asyncActions[CANCEL];
  let canceled = false;
  const cancelFunc = once(() => {
    canceled = true;
    dispatch(cancel(null, requestParams));
  });
  const promise = requestFunction(requestParams).then(response => {
    if (!canceled) dispatch(succeed(response, requestParams));
    return response;
  }).catch(e => {
    if (!canceled) dispatch(fail(e, requestParams));
  });
  dispatch(pend(promise, requestParams));
  return {
    promise,
    cancel: cancelFunc
  };
};