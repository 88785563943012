'use es6';

import { fromJS } from 'immutable';
import http from 'hub-http/clients/apiClient';
const BASE_URL = 'dashboard/v2/favorite/dashboard';
export const fetchFavoriteDashboards = () => {
  return http.get(BASE_URL).then(fromJS);
};
export const addFavoriteDashboard = dashboardId => {
  return http.post(BASE_URL, {
    data: {
      objectId: dashboardId
    }
  });
};
export const removeFavoriteDashboard = dashboardId => {
  return http.delete(`${BASE_URL}/${dashboardId}`);
};