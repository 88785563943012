'use es6';

import fromJS from 'transmute/fromJS';
import indexBy from 'transmute/indexBy';
import http from 'hub-http/clients/apiClient';
import { registerQuery } from 'data-fetching-client';
import { getId } from '../schemas/userSchema';
import { getUserLabel } from '../../lib/user-logic';

// Field names for caching
export const CREATE_AND_OWN_USERS_FIELD_NAME = 'createAndOwnUsers';
export const ALL_USERS_FIELD_NAME = 'users';
export const DEACTIVATED_USERS_FIELD_NAME = 'deactivatedUsers';
const CREATE_AND_OWN_USER_URL = `users/v2/app/scopes/reporting-create-and-own/hub-users`;
const USERS_URL = `users/v2/app/hub-users`;
const DEACTIVATED_USERS_URL = `user-deactivation/v1/deactivations`;
const fetchCreateAndOwnUsers = () => http.get(CREATE_AND_OWN_USER_URL);
const fetchAllUsers = () => http.get(USERS_URL);
const fetchAllDeactivatedUsers = () => http.get(DEACTIVATED_USERS_URL);

// @returns {Map(
//   createAndOwnUsers: Map() of users with create and own rights, keyed by their userId
//   createAndOwnUsersLabels: List() of Maps({
//     text: String The user's fullname, followed by their email in paranthesis,
//     value: String The userId
//   })
// )}
export const FETCH_CREATE_AND_OWN_USERS = registerQuery({
  fieldName: CREATE_AND_OWN_USERS_FIELD_NAME,
  fetcher() {
    return fetchCreateAndOwnUsers().then(createAndOwnUsersResponse => {
      const createAndOwnUsers = {};
      const createAndOwnUsersLabels = [];
      createAndOwnUsersResponse.forEach(user => {
        createAndOwnUsers[user.id] = user;
        createAndOwnUsersLabels.push({
          text: getUserLabel({
            user: fromJS(user)
          }),
          value: String(user.id)
        });
      });
      return fromJS({
        createAndOwnUsersLabels,
        createAndOwnUsers
      });
    }, error => console.error(error));
  }
});

// @returns {Map() of
//   Map({
//     id: Number
//     firstName: String
//     lastName: String
//     email: String
//     verified: bool
//   }) keyed by user id
// }

export const FETCH_ALL_USERS = registerQuery({
  fieldName: ALL_USERS_FIELD_NAME,
  fetcher() {
    return fetchAllUsers().then(allUsersResponse => indexBy(getId, fromJS(allUsersResponse)), error => console.error(error));
  }
});

// @returns new SET() of
//   ([
//     userId: Number
//   ]) keyed by userId
//

export const FETCH_DEACTIVATED_USERS = registerQuery({
  fieldName: DEACTIVATED_USERS_FIELD_NAME,
  fetcher() {
    return fetchAllDeactivatedUsers().then(deactivatedUsersResponse => {
      return new Set(deactivatedUsersResponse.map(({
        userId
      }) => {
        return userId;
      }));
    }, error => console.error(error));
  }
});