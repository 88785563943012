import { METRIC_DELIMITER, fromMetricKey, toMetricKey } from 'reporting-data/v2/dataset/datasetMetrics';
import { isValidMetricProperty } from '../components/reportEditors/MetricEditor/metricEditorUtils';
import getMetricLabel from 'reporting-data/public/get-metric-label';
export const getMetricOptions = (metrics, propertiesByName, metricsFromReport, isUnifiedAnalytics = false) => metrics.filter(metric => !(metricsFromReport && metricsFromReport.includes(metric))).reduce((memo, metric) => {
  const metricKey = metric.includes(METRIC_DELIMITER) ? metric : toMetricKey({
    property: metric
  });
  const {
    property,
    type
  } = fromMetricKey(metricKey);
  const propInfo = propertiesByName[property];
  if (propInfo && isValidMetricProperty(propInfo, isUnifiedAnalytics)) {
    const text = getMetricLabel(propInfo.label, type);
    return memo.concat({
      text,
      value: metricKey
    });
  }
  return memo;
}, []);