'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import toJS from '../../lib/toJS';
import * as http from '../../request/http';
export default (() => http.get('/calendarintegration/v1/meeting-outcome').then(toJS).then(({
  meetingOutcomes
}) => List(meetingOutcomes.map(({
  name,
  label,
  displayOrder
}) => ImmutableMap({
  value: name,
  label,
  displayOrder
})))));