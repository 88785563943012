import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestState } from '../filter/requests/request-state';
import { getUnifiedDataTypeMetadata } from '../filter/utils/unifiedDataSourceHelpers';
import { isFrameworkDataSource } from '../filter/utils/dataSourceHelpers';
import { getDataSourceMetadata } from '../filter/data/DataSourceMetadataDAO';
import { getDataSourceMetadataWithUniqueLabel, selectDataSourcesInfo, selectDataSourcesInfoStatus } from '../filter/utils/dataSourceInfoLogic';
export const fetchDataSourceMetadataAsyncThunk = createAsyncThunk('datasourceMetadata/fetch', async dataSource => {
  const metadataPromise = isFrameworkDataSource(dataSource) ? getDataSourceMetadata(dataSource) : Promise.resolve(getUnifiedDataTypeMetadata(dataSource));
  const metadata = await metadataPromise;
  return metadata;
});
export const dataSourceMetadataNamespace = 'dataSourceMetadata';
const dataSourceMetadataSlice = createSlice({
  name: dataSourceMetadataNamespace,
  initialState: {},
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDataSourceMetadataAsyncThunk.pending, (state, action) => {
      const {
        arg: dataSource
      } = action.meta;
      state[dataSource] = {
        status: RequestState.PENDING
      };
    });
    builder.addCase(fetchDataSourceMetadataAsyncThunk.fulfilled, (state, action) => {
      const {
        arg: dataSource
      } = action.meta;
      const metadata = action.payload;
      state[dataSource] = {
        status: RequestState.SUCCEEDED,
        metadata
      };
    });
    builder.addCase(fetchDataSourceMetadataAsyncThunk.rejected, (state, action) => {
      const {
        arg: dataSource
      } = action.meta;
      state[dataSource] = {
        status: RequestState.FAILED
      };
    });
  }
});

// Actions

// Selectors
export const selectFetchMetadataList = dataSources => ({
  dataSourceMetadata: state
}) => {
  return dataSources.filter(dataSource => !state[dataSource]);
};
export const selectDataSourcesMetadataStatus = dataSources => state => selectDataSourcesInfoStatus({
  dataSources
})(state.dataSourceMetadata);
export const selectDataSourcesMetadataWithUniqueLabels = dataSources => state => {
  const dataSourceInfoStatus = selectDataSourcesMetadataStatus(dataSources)(state);
  if (dataSourceInfoStatus !== RequestState.SUCCEEDED) {
    return {};
  }
  const dataSourcesMetadata = selectDataSourcesInfo({
    dataSources,
    valueName: 'metadata'
  })(state.dataSourceMetadata);
  const labelsToDataSource = {};
  const dataSourcesMetadataWithUniqueLabels = Object.assign({}, dataSourcesMetadata);
  for (const [dataSourceKey, dataSourceMetadata] of Object.entries(dataSourcesMetadata)) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const {
      label: dataSourceLabel
    } = dataSourceMetadata;
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const dataSourceWithMatchingLabel = labelsToDataSource[dataSourceLabel];
    if (dataSourceWithMatchingLabel) {
      dataSourcesMetadataWithUniqueLabels[dataSourceKey] = getDataSourceMetadataWithUniqueLabel(dataSourceKey, dataSourceMetadata);
      dataSourcesMetadataWithUniqueLabels[dataSourceWithMatchingLabel] = getDataSourceMetadataWithUniqueLabel(dataSourceWithMatchingLabel, dataSourcesMetadata[dataSourceWithMatchingLabel]);
    } else {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      labelsToDataSource[dataSourceLabel] = dataSourceKey;
    }
  }
  return dataSourcesMetadataWithUniqueLabels;
};
export const fetchDataSourcesMetadata = dataSources =>
// currently there is no proper type for async thunk dispatch.
(dispatch, getState) => {
  selectFetchMetadataList(dataSources)(getState()).forEach(dataSource => {
    dispatch(fetchDataSourceMetadataAsyncThunk(dataSource));
  });
};
export const dataSourceMetadataReducer = dataSourceMetadataSlice.reducer;
export default dataSourceMetadataReducer;