import { matchPath } from 'react-router-dom';
import compose from 'transmute/compose';
import get from 'transmute/get';
import { RouteConfigs } from '../constants/route/DashboardRoute';
/**
 * @param {Object} - match object
 * @param {Object} - path parameters as an object map
 */
export const getPathParams = get('params');
const matchRouterConfig = pathToTest => {
  const isMatch = config => !!matchPath(pathToTest, {
    path: config.path,
    strict: false
  });
  return RouteConfigs.find(isMatch);
};
export const getRouteName = compose(get('name'), matchRouterConfig);