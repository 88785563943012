import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["type"];
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
const createNotificationAction = notificationType => ({
  message,
  sticky = false,
  timeout,
  title,
  id
}) => FloatingAlertStore.addAlert({
  message,
  sticky,
  timeout,
  titleText: title,
  type: notificationType,
  id
});
export const showErrorNotification = createNotificationAction('danger');
export const showWarningNotification = createNotificationAction('warning');
export const showSuccessNotification = createNotificationAction('success');
export const showRemovableNotification = _ref => {
  let {
      type = 'success'
    } = _ref,
    notificationArgs = _objectWithoutPropertiesLoose(_ref, _excluded);
  const id = `alert-${Math.floor(Math.random() * 10000)}`;
  FloatingAlertStore.addAlert(Object.assign({}, notificationArgs, {
    id,
    type,
    title: false
  }));
  return () => FloatingAlertStore.removeAlert(id);
};