'use es6';

import getCrmTier from './getCrmTier';
import getPortalInfo from './getPortalInfo';
import getProductInfo from './getProductInfo';
import getScreenSize from './getScreenSize';
import getSurveyInfo from './getSurveyInfo';
import getUserInfo from './getUserInfo';

/**
 * @param {object} config - contains `EarlyRequester` & `Iris`
 **/
export const combineAll = sources => {
  return Promise.all([getCrmTier(sources), getPortalInfo(sources), getProductInfo(sources), getScreenSize(sources), getUserInfo(sources), getSurveyInfo(sources)]).then(results => {
    return results.reduce((memo, properties) => {
      return Object.assign({}, memo, properties);
    });
  });
};