import { ExpressionTypes } from '../expression/expression';
import { BOOLEAN_TYPE, createAggregateFunctionType, createFunctionType, createGenericType, createStringLiteralType, createUnionType, DATETIME_TYPE, DATE_TYPE, NULLABLE_BOOLEAN_TYPE, NULLABLE_DATETIME_TYPE, NULLABLE_DATE_TYPE, NULLABLE_NUMBER_TYPE, NULLABLE_STRING_TYPE, NULL_TYPE, NUMBER_TYPE, PROPERTY_TYPES, STRING_TYPE } from '../type/type';
export const SCOPE = {
  YEAR: {
    type: createStringLiteralType('YEAR'),
    value: 'YEAR'
  },
  YEAROFWEEK: {
    type: createStringLiteralType('YEAROFWEEK'),
    value: 'YEAROFWEEK'
  },
  YEAROFWEEKISO: {
    type: createStringLiteralType('YEAROFWEEKISO'),
    value: 'YEAROFWEEKISO'
  },
  QUARTER: {
    type: createStringLiteralType('QUARTER'),
    value: 'QUARTER'
  },
  MONTH: {
    type: createStringLiteralType('MONTH'),
    value: 'MONTH'
  },
  WEEK: {
    type: createStringLiteralType('WEEK'),
    value: 'WEEK'
  },
  WEEKISO: {
    type: createStringLiteralType('WEEKISO'),
    value: 'WEEKISO'
  },
  DAY: {
    type: createStringLiteralType('DAY'),
    value: 'DAY'
  },
  DAYOFWEEK: {
    type: createStringLiteralType('DAYOFWEEK'),
    value: 'DAYOFWEEK'
  },
  DAYOFWEEKISO: {
    type: createStringLiteralType('DAYOFWEEKISO'),
    value: 'DAYOFWEEKISO'
  },
  DAYOFYEAR: {
    type: createStringLiteralType('DAYOFYEAR'),
    value: 'DAYOFYEAR'
  },
  HOUR: {
    type: createStringLiteralType('HOUR'),
    value: 'HOUR'
  },
  MINUTE: {
    type: createStringLiteralType('MINUTE'),
    value: 'MINUTE'
  },
  SECOND: {
    type: createStringLiteralType('SECOND'),
    value: 'SECOND'
  }
};
const functionFactory = (...signatures) => name => createFunctionType(name, ...signatures);
export const makeLogicalUnaryFunction = functionFactory({
  arguments: [BOOLEAN_TYPE],
  returns: BOOLEAN_TYPE
}, {
  arguments: [NULL_TYPE],
  returns: NULL_TYPE
}, {
  arguments: [NULLABLE_BOOLEAN_TYPE],
  returns: NULLABLE_BOOLEAN_TYPE
});
export const makeLogicalBinaryFunction = functionFactory({
  arguments: [BOOLEAN_TYPE, BOOLEAN_TYPE],
  returns: BOOLEAN_TYPE
}, {
  arguments: [NULL_TYPE, NULLABLE_BOOLEAN_TYPE],
  returns: NULL_TYPE
}, {
  arguments: [NULLABLE_BOOLEAN_TYPE, NULL_TYPE],
  returns: NULL_TYPE
}, {
  arguments: [NULLABLE_BOOLEAN_TYPE, NULLABLE_BOOLEAN_TYPE],
  returns: NULLABLE_BOOLEAN_TYPE
});
export const makeNumericUnaryFunction = functionFactory({
  arguments: [NUMBER_TYPE],
  returns: NUMBER_TYPE
}, {
  arguments: [NULL_TYPE],
  returns: NULL_TYPE
}, {
  arguments: [NULLABLE_NUMBER_TYPE],
  returns: NULLABLE_NUMBER_TYPE
});
export const makeNumericBinaryFunction = functionFactory({
  arguments: [NUMBER_TYPE, NUMBER_TYPE],
  returns: NUMBER_TYPE
}, {
  arguments: [NULLABLE_NUMBER_TYPE, NULL_TYPE],
  returns: NULL_TYPE
}, {
  arguments: [NULL_TYPE, NULLABLE_NUMBER_TYPE],
  returns: NULL_TYPE
}, {
  arguments: [NULLABLE_NUMBER_TYPE, NULLABLE_NUMBER_TYPE],
  returns: NULLABLE_NUMBER_TYPE
});
export const makeComparisonFunction = functionFactory({
  arguments: [createUnionType(NUMBER_TYPE, DATE_TYPE, DATETIME_TYPE), createUnionType(NUMBER_TYPE, DATE_TYPE, DATETIME_TYPE)],
  returns: BOOLEAN_TYPE
}, {
  arguments: [createUnionType(NUMBER_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE), NULL_TYPE],
  returns: NULL_TYPE
}, {
  arguments: [NULL_TYPE, createUnionType(NUMBER_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE)],
  returns: NULL_TYPE
}, {
  arguments: [createUnionType(NUMBER_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE), createUnionType(NUMBER_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE)],
  returns: NULLABLE_BOOLEAN_TYPE
});
export const makeEqualityFunction = functionFactory({
  arguments: [createGenericType('T'), createGenericType('T')],
  returns: BOOLEAN_TYPE
});
export const OPERATORS = {
  // Logical unary operators
  [ExpressionTypes.NOT]: makeLogicalUnaryFunction('__NOT__'),
  // Logical binary operators
  [ExpressionTypes.AND]: makeLogicalBinaryFunction('__AND__'),
  [ExpressionTypes.OR]: makeLogicalBinaryFunction('__OR__'),
  // Numeric unary operators
  [ExpressionTypes.POSITIVE]: makeNumericUnaryFunction('__POSITIVE__'),
  [ExpressionTypes.NEGATIVE]: makeNumericUnaryFunction('__NEGATIVE__'),
  // Binary numeric operators
  [ExpressionTypes.ADD]: makeNumericBinaryFunction('__ADD__'),
  [ExpressionTypes.SUBTRACT]: makeNumericBinaryFunction('__SUBTRACT__'),
  [ExpressionTypes.MULTIPLY]: makeNumericBinaryFunction('__MULTIPLY__'),
  [ExpressionTypes.DIVIDE]: makeNumericBinaryFunction('__DIVIDE__'),
  [ExpressionTypes.MODULUS]: makeNumericBinaryFunction('__MODULUS__'),
  // Comparison operators
  [ExpressionTypes.GREATER]: makeComparisonFunction('__GREATER__'),
  [ExpressionTypes.GREATER_OR_EQUAL]: makeComparisonFunction('__GREATER_OR_EQUAL__'),
  [ExpressionTypes.LESS]: makeComparisonFunction('__LESS__'),
  [ExpressionTypes.LESS_OR_EQUAL]: makeComparisonFunction('__LESS_OR_EQUAL__'),
  // Equality operators
  [ExpressionTypes.EQUAL]: makeEqualityFunction('__EQUAL__'),
  [ExpressionTypes.NOT_EQUAL]: makeEqualityFunction('__NOT_EQUAL__')
};
export const FUNCTIONS = {
  IF: createFunctionType('IF', {
    arguments: [NULLABLE_BOOLEAN_TYPE, createGenericType('T'), createGenericType('T')],
    returns: createGenericType('T')
  }),
  CONTAINS: createFunctionType('CONTAINS', {
    arguments: [STRING_TYPE, STRING_TYPE],
    returns: BOOLEAN_TYPE
  }, {
    arguments: [NULLABLE_STRING_TYPE, NULLABLE_STRING_TYPE],
    returns: NULLABLE_BOOLEAN_TYPE
  }),
  CONCAT: createFunctionType('CONCAT', {
    arguments: [createUnionType(STRING_TYPE, NUMBER_TYPE), createUnionType(STRING_TYPE, NUMBER_TYPE)],
    returns: STRING_TYPE
  }, {
    arguments: [NULL_TYPE, createUnionType(NULLABLE_STRING_TYPE, NULLABLE_NUMBER_TYPE)],
    returns: NULL_TYPE
  }, {
    arguments: [createUnionType(NULLABLE_STRING_TYPE, NULLABLE_NUMBER_TYPE), NULL_TYPE],
    returns: NULL_TYPE
  }, {
    arguments: [createUnionType(NULLABLE_STRING_TYPE, NULLABLE_NUMBER_TYPE), createUnionType(NULLABLE_STRING_TYPE, NULLABLE_NUMBER_TYPE)],
    returns: NULLABLE_STRING_TYPE
  }),
  LABEL: createFunctionType('LABEL', {
    arguments: [PROPERTY_TYPES.ENUMERATION],
    returns: NULLABLE_STRING_TYPE
  }),
  LENGTH: createFunctionType('LENGTH', {
    arguments: [STRING_TYPE],
    returns: NUMBER_TYPE
  }, {
    arguments: [NULLABLE_STRING_TYPE],
    returns: NULLABLE_NUMBER_TYPE
  }),
  TRIM: createFunctionType('TRIM', {
    arguments: [STRING_TYPE],
    returns: STRING_TYPE
  }, {
    arguments: [NULLABLE_STRING_TYPE],
    returns: NULLABLE_STRING_TYPE
  }),
  ABS: makeNumericUnaryFunction('ABS'),
  CEIL: makeNumericUnaryFunction('CEIL'),
  CONVERT_CURRENCY: createFunctionType('CONVERT_CURRENCY', {
    arguments: [NUMBER_TYPE, STRING_TYPE],
    returns: NUMBER_TYPE
  }, {
    arguments: [NULLABLE_NUMBER_TYPE, STRING_TYPE],
    returns: NULLABLE_NUMBER_TYPE
  }),
  DIV0: makeNumericBinaryFunction('DIV0'),
  EXP: makeNumericUnaryFunction('EXP'),
  FLOOR: makeNumericUnaryFunction('FLOOR'),
  LN: makeNumericUnaryFunction('LN'),
  LOG: makeNumericBinaryFunction('LOG'),
  POWER: makeNumericBinaryFunction('POWER'),
  SQRT: makeNumericUnaryFunction('SQRT'),
  WIDTH_BUCKET: createFunctionType('WIDTH_BUCKET', {
    arguments: [NUMBER_TYPE, NUMBER_TYPE, NUMBER_TYPE, NUMBER_TYPE],
    returns: NUMBER_TYPE
  }, {
    arguments: [NULLABLE_NUMBER_TYPE, NUMBER_TYPE, NUMBER_TYPE, NUMBER_TYPE],
    returns: NULLABLE_NUMBER_TYPE
  }),
  DATE_FROM_PARTS: createFunctionType('DATE_FROM_PARTS', {
    arguments: [NUMBER_TYPE, NUMBER_TYPE, NUMBER_TYPE],
    returns: DATE_TYPE
  }, {
    arguments: [NULLABLE_NUMBER_TYPE, NULLABLE_NUMBER_TYPE, NULLABLE_NUMBER_TYPE],
    returns: NULLABLE_DATE_TYPE
  }),
  DATEDIFF: createFunctionType('DATEDIFF', {
    arguments: [createUnionType(createStringLiteralType('YEAR'), createStringLiteralType('QUARTER'), createStringLiteralType('MONTH'), createStringLiteralType('WEEK'), createStringLiteralType('DAY'), createStringLiteralType('HOUR'), createStringLiteralType('MINUTE'), createStringLiteralType('SECOND')), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE)],
    returns: NUMBER_TYPE
  }, {
    arguments: [createUnionType(createStringLiteralType('YEAR'), createStringLiteralType('QUARTER'), createStringLiteralType('MONTH'), createStringLiteralType('WEEK'), createStringLiteralType('DAY'), createStringLiteralType('HOUR'), createStringLiteralType('MINUTE'), createStringLiteralType('SECOND')), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE)],
    returns: NULLABLE_NUMBER_TYPE
  }),
  DATEPART: createFunctionType('DATEPART', {
    arguments: [createUnionType(createStringLiteralType('YEAR'), createStringLiteralType('YEAROFWEEK'), createStringLiteralType('YEAROFWEEKISO'), createStringLiteralType('QUARTER'), createStringLiteralType('MONTH'), createStringLiteralType('WEEK'), createStringLiteralType('WEEKISO'), createStringLiteralType('DAY'), createStringLiteralType('DAYOFWEEK'), createStringLiteralType('DAYOFWEEKISO'), createStringLiteralType('DAYOFYEAR')), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE)],
    returns: NUMBER_TYPE
  }, {
    arguments: [createUnionType(createStringLiteralType('YEAR'), createStringLiteralType('YEAROFWEEK'), createStringLiteralType('YEAROFWEEKISO'), createStringLiteralType('QUARTER'), createStringLiteralType('MONTH'), createStringLiteralType('WEEK'), createStringLiteralType('WEEKISO'), createStringLiteralType('DAY'), createStringLiteralType('DAYOFWEEK'), createStringLiteralType('DAYOFWEEKISO'), createStringLiteralType('DAYOFYEAR')), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE)],
    returns: NULLABLE_NUMBER_TYPE
  }),
  DATETRUNC: createFunctionType('DATETRUNC', {
    arguments: [createUnionType(createStringLiteralType('YEAR'), createStringLiteralType('QUARTER'), createStringLiteralType('MONTH'), createStringLiteralType('WEEK'), createStringLiteralType('DAY')), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE)],
    returns: DATE_TYPE
  }, {
    arguments: [createUnionType(createStringLiteralType('YEAR'), createStringLiteralType('QUARTER'), createStringLiteralType('MONTH'), createStringLiteralType('WEEK'), createStringLiteralType('DAY')), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE)],
    returns: NULLABLE_DATE_TYPE
  }),
  TIMESTAMP_FROM_PARTS: createFunctionType('TIMESTAMP_FROM_PARTS', {
    arguments: [NUMBER_TYPE,
    // year
    NUMBER_TYPE,
    // month
    NUMBER_TYPE,
    // day
    NUMBER_TYPE,
    // hour
    NUMBER_TYPE,
    // minute
    NUMBER_TYPE // seconds
    ],
    returns: DATETIME_TYPE
  }, {
    arguments: [NULLABLE_NUMBER_TYPE,
    // year
    NULLABLE_NUMBER_TYPE,
    // month
    NULLABLE_NUMBER_TYPE,
    // day
    NULLABLE_NUMBER_TYPE,
    // hour
    NULLABLE_NUMBER_TYPE,
    // minute
    NULLABLE_NUMBER_TYPE // seconds
    ],
    returns: NULLABLE_DATETIME_TYPE
  }),
  WEEKNUM: createFunctionType('WEEKNUM', {
    arguments: [createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE)],
    returns: NUMBER_TYPE
  }, {
    arguments: [createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE)],
    returns: NULLABLE_NUMBER_TYPE
  }),
  NOW: createFunctionType('NOW', {
    arguments: [],
    returns: NUMBER_TYPE
  }),
  WORKINGDAYS: createFunctionType('WORKINGDAYS', {
    arguments: [createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE)],
    returns: NUMBER_TYPE
  }, {
    arguments: [createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE)],
    returns: NULLABLE_NUMBER_TYPE
  }),
  DISTINCT_COUNT: createAggregateFunctionType('DISTINCT_COUNT', {
    arguments: [createGenericType('T')],
    returns: NUMBER_TYPE
  }),
  SUM: createAggregateFunctionType('SUM', {
    arguments: [NULLABLE_NUMBER_TYPE],
    returns: NUMBER_TYPE
  }),
  AVERAGE: createAggregateFunctionType('AVERAGE', {
    arguments: [NULLABLE_NUMBER_TYPE],
    returns: NUMBER_TYPE
  }),
  MIN: createAggregateFunctionType('MIN', {
    arguments: [NULLABLE_NUMBER_TYPE],
    returns: NUMBER_TYPE
  }, {
    arguments: [NULLABLE_STRING_TYPE],
    returns: STRING_TYPE
  }, {
    arguments: [NULLABLE_BOOLEAN_TYPE],
    returns: BOOLEAN_TYPE
  }, {
    arguments: [NULLABLE_DATE_TYPE],
    returns: DATE_TYPE
  }, {
    arguments: [NULLABLE_DATETIME_TYPE],
    returns: DATETIME_TYPE
  }),
  MAX: createAggregateFunctionType('MAX', {
    arguments: [NULLABLE_NUMBER_TYPE],
    returns: NUMBER_TYPE
  }, {
    arguments: [NULLABLE_STRING_TYPE],
    returns: STRING_TYPE
  }, {
    arguments: [NULLABLE_BOOLEAN_TYPE],
    returns: BOOLEAN_TYPE
  }, {
    arguments: [NULLABLE_DATE_TYPE],
    returns: DATE_TYPE
  }, {
    arguments: [NULLABLE_DATETIME_TYPE],
    returns: DATETIME_TYPE
  }),
  ADJUST_FISCAL_YEAR: createFunctionType('ADJUST_FISCAL_YEAR', {
    arguments: [createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, NULL_TYPE)],
    returns: NULLABLE_DATE_TYPE
  }, {
    arguments: [createUnionType(NUMBER_TYPE, STRING_TYPE, DATETIME_TYPE, NULL_TYPE)],
    returns: NULLABLE_DATETIME_TYPE
  }),
  BETWEEN: createFunctionType('BETWEEN', {
    arguments: [createUnionType(NULLABLE_NUMBER_TYPE, NULLABLE_STRING_TYPE, NULLABLE_DATETIME_TYPE), createUnionType(NULLABLE_NUMBER_TYPE, NULLABLE_STRING_TYPE, NULLABLE_DATETIME_TYPE), createUnionType(NULLABLE_NUMBER_TYPE, NULLABLE_STRING_TYPE, NULLABLE_DATETIME_TYPE)],
    returns: BOOLEAN_TYPE
  }),
  LASTDAY: createFunctionType('LASTDAY', {
    arguments: [createUnionType(createStringLiteralType('YEAR'), createStringLiteralType('QUARTER'), createStringLiteralType('MONTH'), createStringLiteralType('WEEK')), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE)],
    returns: DATE_TYPE
  }, {
    arguments: [createUnionType(createStringLiteralType('YEAR'), createStringLiteralType('QUARTER'), createStringLiteralType('MONTH'), createStringLiteralType('WEEK')), createUnionType(NULLABLE_NUMBER_TYPE, NULLABLE_STRING_TYPE, NULLABLE_DATE_TYPE, NULLABLE_DATETIME_TYPE, NULL_TYPE)],
    returns: NULLABLE_DATE_TYPE
  }),
  NOT: createFunctionType('NOT', {
    arguments: [NULLABLE_BOOLEAN_TYPE],
    returns: BOOLEAN_TYPE
  }),
  ROUND: createFunctionType('ROUND', {
    arguments: [NULLABLE_NUMBER_TYPE],
    returns: NULLABLE_NUMBER_TYPE
  }),
  LOWER: createFunctionType('LOWER', {
    arguments: [STRING_TYPE],
    returns: STRING_TYPE
  }, {
    arguments: [NULLABLE_STRING_TYPE],
    returns: NULL_TYPE
  }),
  UPPER: createFunctionType('UPPER', {
    arguments: [STRING_TYPE],
    returns: STRING_TYPE
  }, {
    arguments: [NULLABLE_STRING_TYPE],
    returns: NULL_TYPE
  }),
  STARTSWITH: createFunctionType('STARTSWITH', {
    arguments: [NULLABLE_STRING_TYPE, NULLABLE_STRING_TYPE],
    returns: BOOLEAN_TYPE
  }),
  ENDSWITH: createFunctionType('ENDSWITH', {
    arguments: [NULLABLE_STRING_TYPE, NULLABLE_STRING_TYPE],
    returns: BOOLEAN_TYPE
  }),
  CONTACT_LABEL: createFunctionType('CONTACT_LABEL', {
    arguments: [NULLABLE_STRING_TYPE, NULLABLE_STRING_TYPE, NULLABLE_STRING_TYPE],
    returns: NULLABLE_STRING_TYPE
  }),
  COALESCE: createFunctionType('COALESCE', {
    arguments: [NULLABLE_NUMBER_TYPE, NULLABLE_NUMBER_TYPE],
    returns: NULLABLE_NUMBER_TYPE
  }, {
    arguments: [NULLABLE_STRING_TYPE, NULLABLE_STRING_TYPE],
    returns: NULLABLE_STRING_TYPE
  }),
  MEDIAN: createAggregateFunctionType('MEDIAN', {
    arguments: [NULLABLE_NUMBER_TYPE],
    returns: NULLABLE_NUMBER_TYPE
  }, {
    arguments: [NULLABLE_DATE_TYPE],
    returns: NULLABLE_DATE_TYPE
  }, {
    arguments: [NULLABLE_DATETIME_TYPE],
    returns: NULLABLE_DATETIME_TYPE
  }),
  IN: createAggregateFunctionType('IN', {
    arguments: [NUMBER_TYPE, NULLABLE_NUMBER_TYPE],
    returns: BOOLEAN_TYPE
  }, {
    arguments: [STRING_TYPE, NULLABLE_STRING_TYPE],
    returns: BOOLEAN_TYPE
  }),
  COUNT: createAggregateFunctionType('COUNT', {
    arguments: [createGenericType('T')],
    returns: NUMBER_TYPE
  }),
  ROW_NUMBER: createFunctionType('ROW_NUMBER', {
    arguments: [],
    returns: NUMBER_TYPE
  })
};