'use es6';

import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as OperatorTypes from '../ObjectSegOperatorTypes';
import * as Operators from '../../../filterQueryFormat/operator/Operators';
import getIn from 'transmute/getIn';
import invariant from 'react-utils/invariant';
import toValueComparison from './util/toValueComparison';
const supportedOperators = [Operators.Equal, Operators.NotEqual, Operators.Less, Operators.LessOrEqual, Operators.Greater, Operators.GreaterOrEqual, Operators.Known, Operators.NotKnown, Operators.In, Operators.NotIn, Operators.Known, Operators.NotKnown, Operators.EqualAny, Operators.NotEqualAny, Operators.ContainAny, Operators.NotContainAny, Operators.StartsWithAny, Operators.EndsWithAny, Operators.InRange, Operators.NotInRange, Operators.GreaterRolling, Operators.LessRolling, Operators.BeforeDate, Operators.AfterDate, Operators.EqualTimePoint, Operators.BeforeTimePoint, Operators.AfterTimePoint, Operators.BetweenTimePoints, Operators.NotBetweenTimePoints];
const _toSurveyMonkeyQuestionFilter = (valueComparison, operator) => ({
  filterType: ObjectSegFilterTypes.SURVEY_MONKEY_VALUE,
  surveyId: getIn(['field', 'metadata', 'surveyId'], operator),
  surveyQuestion: getIn(['field', 'metadata', 'questionId'], operator),
  surveyAnswerRowId: getIn(['field', 'metadata', 'rowId'], operator) || null,
  surveyAnswerColId: getIn(['field', 'metadata', 'colId'], operator) || null,
  valueComparison,
  operator: OperatorTypes.HAS_ANSWERED_SURVEY_QUESTION_WITH_VALUE
});
const toSurveyMonkeyQuestionFilter = (operator, _filterFamily, options) => {
  const isSupported = supportedOperators.includes(operator.constructor);
  invariant(isSupported, 'cannot convert Operator "%s" to Survey Monkey Question filter', operator.name);
  const valueComparison = toValueComparison(operator, options);
  return _toSurveyMonkeyQuestionFilter(valueComparison, operator);
};
export default toSurveyMonkeyQuestionFilter;