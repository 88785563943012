import * as DisplayTypes from '../DisplayTypes';
import * as InputTypes from '../InputTypes';
import { Iterable, Record } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../D... Remove this comment to see the full error message
import DefaultNullValueRecord from '../DefaultNullValueRecord';
import devLogger from 'react-utils/devLogger';
import invariant from 'react-utils/invariant';
import isRecord from 'transmute/isRecord';
const OPERATOR_FLAG = '__OPERATOR_FLAG__';
export function isOperatorConstructor(operator) {
  return typeof operator === 'function' && operator[OPERATOR_FLAG] === true;
}
export function isOperator(operator) {
  return isOperatorConstructor(operator.constructor);
}
export function isValidOperator(operator) {
  const validOperator = Boolean(operator.field && operator.constructor.isValid(operator));
  const refinement = operator.refinement;
  const validRefinement = operator.constructor.isRefinable && refinement ? refinement.constructor.isValid(refinement) : true;
  return validOperator && validRefinement;
}
export function isEmptyValueOperator(operator) {
  return operator.constructor.isValueEmpty(operator);
}
function defaultIsValid(value) {
  return value !== undefined;
}
function enforceValue(value, index) {
  invariant(typeof value.name === 'string' && value.name.length > 0, 'expected `value[%s].name` to be a non-empty string but got `%s`', index, value.name);
  invariant(value.isValid === undefined || typeof value.isValid === 'function', 'expected `value[%s].isValid` to be a function but got `%s`', index, value.isValid);
}
export function makeOperator({
  displayType = DisplayTypes.DefaultDisplayType,
  getLabel,
  getOperatorDisplay,
  inputType = InputTypes.DefaultInputType,
  isRefinable = false,
  isRefinableExtra = false,
  name,
  values = []
}) {
  invariant(typeof name === 'string' && name.length > 1, 'expected `name` to be a non-empty string but got `%s`', name);
  values.forEach(enforceValue);
  const defaultProperties = {
    defaultNullValue: DefaultNullValueRecord(),
    field: undefined,
    errors: [],
    name
  };
  if (isRefinable || isRefinableExtra) {
    defaultProperties.refinement = undefined;
  }
  const recordShape = values.reduce((acc, valueField) => {
    acc[valueField.name] = valueField.defaultValue;
    return acc;
  }, defaultProperties);
  const defaultValidators = {
    field: defaultIsValid,
    refinement: v => v === undefined || isOperator(v)
  };
  const validators = values.reduce((acc, valueField) => {
    acc[valueField.name] = valueField.isValid || defaultIsValid;
    return acc;
  }, defaultValidators);
  const Operator = Record(recordShape, name);
  Operator[OPERATOR_FLAG] = true;
  Operator._name = name;
  Operator.toString = () => name;
  if (Object.values(DisplayTypes).includes(displayType)) {
    Operator.displayType = displayType;
  } else {
    const errorMessage = `Attempted to create an operator with invalid display type: ${displayType}`;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ message: string; }' is not ass... Remove this comment to see the full error message
    devLogger.warn({
      message: errorMessage
    });
  }
  if (Object.values(InputTypes).includes(inputType)) {
    Operator.inputType = inputType;
  } else {
    const errorMessage = `Attempted to create an operator with invalid display type: ${inputType}`;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ message: string; }' is not ass... Remove this comment to see the full error message
    devLogger.warn({
      message: errorMessage
    });
  }
  Operator.getLabel = getLabel;
  Operator.getOperatorDisplay = getOperatorDisplay;
  Operator.of = (field, ...args) => {
    return Operator().withMutations(operator => {
      operator.set('field', field);
      values.forEach((valueField, index) => {
        const value = args[index];
        if (validators[valueField.name](value, field)) {
          operator.set(valueField.name, value);
        }
      });
      return operator;
    });
  };
  Operator.ofUnsafe = (field, ...args) => {
    return Operator().withMutations(operator => {
      operator.set('field', field);
      values.forEach((valueField, index) => operator.set(valueField.name, args[index]));
      return operator;
    });
  };
  function isValidField(fieldName, value, field) {
    const validator = validators[fieldName];
    if (!validator) {
      return true;
    }
    return validators[fieldName](value, field);
  }
  function isEmptyField(value) {
    if (Iterable.isIterable(value)) {
      return value.toSeq().size === 0;
    }
    if (value === undefined || value === null || value === '') {
      return true;
    }
    return false;
  }
  Operator.isRefinable = isRefinable;
  Operator.isRefinableExtra = isRefinableExtra;
  Operator.isIterableField = fieldName => {
    const valueField = recordShape[fieldName];
    return Iterable.isIterable(valueField) && !isRecord(valueField);
  };
  Operator.isInexclusive = fieldName => Iterable.isIterable(recordShape[fieldName]) && recordShape.isInexclusive;
  Operator.isValidField = isValidField;
  Operator.isValid = operator => {
    if (!(operator instanceof Operator)) {
      return false;
    }
    if (!operator.field) {
      return false;
    }
    return values.every(({
      name: valueName
    }) => isValidField(valueName, operator.get(valueName), operator.field));
  };
  Operator.isValueEmpty = operator => {
    if (!(operator instanceof Operator)) {
      return undefined;
    }
    return values.some(({
      name: valueName
    }) => isEmptyField(operator.get(valueName)));
  };
  return Operator;
}
export function swapOperator(Operator, currentInstance, initialInstance = null) {
  return Operator().withMutations(operator => {
    if (typeof operator.forEach === 'function') {
      operator.forEach((defaultValue, key) => {
        if (key === 'name') {
          return;
        }
        if (currentInstance.has(key)) {
          const isTheSameInputType = Operator.inputType === currentInstance.constructor.inputType;
          if (key === 'value' && !isTheSameInputType) {
            return;
          }
          const value = currentInstance.get(key);
          if (Operator.isValidField(key, value, currentInstance.field)) {
            operator.set(key, value);
            return;
          }
        }
        if (initialInstance && initialInstance.has(key)) {
          const value = initialInstance.get(key);
          if (Operator.isValidField(key, value, currentInstance.field)) {
            operator.set(key, value);
          }
        }
      });
    }
  });
}