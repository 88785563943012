'use es6';

import { Map as ImmutableMap } from 'immutable';
import { has } from '../../lib/has';
import prefix from '../../lib/prefix';
import toJS from '../../lib/toJS';
import * as http from '../../request/http';
import { channels } from '../../retrieve/unified/options/social';
import { makeOption } from '../Option';
const translate = prefix('reporting-data.properties.unified.options.social-channels');
const mapping = {
  Twitter: 'tw',
  FacebookPage: 'fbp',
  Instagram: 'ig',
  LinkedInStatus: 'lis',
  LinkedInCompanyPage: 'lcp',
  GooglePlusPage: 'gpp',
  YouTube: 'ytc'
};
const convert = key => {
  const [type, id] = key.split(':');
  const namespace = has(mapping, type) ? mapping[type] : type;
  return `${namespace}-${id}`.toLowerCase();
};
const get = () => http.get('broadcast/v2/channels').then(toJS);
const getSocialChannels = ({
  useAnalyticsType = false,
  useChannelId = false
} = {}) => get().then((response = []) => response.reduce((options, {
  channelKey,
  channelId,
  channelSlug,
  name
}) => {
  let value = channelKey;
  if (useAnalyticsType) {
    value = convert(channelKey);
  } else if (useChannelId) {
    value = channelId;
  }
  return options.set(value, makeOption(value, `${name} (${translate(channelSlug)})`));
}, ImmutableMap()));
export default getSocialChannels;
export const socialChannels = args => getSocialChannels(args).then(options => options.reduce((breakdowns, option) => Object.assign({}, breakdowns, {
  [option.get('value')]: option.get('label')
}), {}));
export const socialChannelsOrKeys = breakdownType => {
  return breakdownType === 'channelType' ? Promise.resolve(channels()) : socialChannels();
};
export const analyticsSocialChannels = () => socialChannels({
  useAnalyticsType: true
});