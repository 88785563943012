'use es6';

import { getValidChartTypesForReport } from 'reporting-data/report/report-transformations/chartTypeTransformations';
import { COLUMN } from 'reporting-data/constants/chartTypes';
import { COLLECTION_EXCLUDED_CHART_TYPES } from '../../components/reportCard/controls/ChartTypeControl';
import { getOptionalStagesContextField } from '../stageHelpers';
import { isJourneyReport } from '../salesAnalyticsHelpers';
export function validateChartType(contextChange, context, report, __subAppKey, defaultContext, scopes) {
  const chartType = contextChange.chartType || context.chartType;
  const optionalStagesField = getOptionalStagesContextField(report);
  const optionalStages = contextChange[optionalStagesField] || context[optionalStagesField];
  const reportExcludedChartTypes = !optionalStages.isEmpty() && isJourneyReport(report) ? [COLUMN] : [];
  const allExcludedChartTypes = COLLECTION_EXCLUDED_CHART_TYPES.concat(reportExcludedChartTypes);
  const validChartTypes = getValidChartTypesForReport(report, scopes, allExcludedChartTypes);
  if (validChartTypes.includes(chartType)) {
    return contextChange;
  }
  const defaultChartType = defaultContext.chartType;
  const newChartType = validChartTypes.length > 0 && !validChartTypes.includes(defaultChartType) ? validChartTypes[0] : defaultChartType;
  return Object.assign({}, contextChange, {
    chartType: newChartType
  });
}