'use es6';

import { fromJS } from 'immutable';
import { Promise } from '../../lib/promise';
import prefix from '../../lib/prefix';
import { CONVERSION } from '../../constants/dataTypes';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import getSourceOptions from '../partial/contacts-sources';
import countProperty from '../partial/count-property';
const translate = prefix('reporting-data.properties.conversion');
const translateGroup = prefix('reporting-data.groups.conversion');
const translateProperty = (property, activity) => `${translate(property)} (${translate(activity)})`;
const conversionAttribution = ['firstVisit', 'firstVisitInCreateSession', 'lastVisitInCreateSession', 'firstVisitInLeadSession', 'lastVisitInLeadSession', 'firstVisitInCloseSession', 'lastVisitInCloseSession'];
const attributionRecord = {
  visitTime: 'datetime',
  pageUrl: 'string',
  pageDomain: 'string',
  visitSource: 'string',
  pageContentType: 'string',
  analyticsPageId: 'string',
  pageAuthor: 'string',
  pageBlogId: 'number',
  country: 'string',
  region: 'string',
  city: 'string',
  persona: 'string',
  conversionTime: 'datetime',
  referrerVisitTime: 'datetime',
  referrerUrl: 'string',
  referrerDomain: 'string',
  referrerVisitSource: 'string',
  referrerContentType: 'string',
  referrerAnalyticsPageId: 'string',
  referrerAuthor: 'string',
  referrerBlogAuthorId: 'number',
  referrerBlogId: 'number'
};
const getPageContentTypeOptions = () => [{
  value: 'LANDING_PAGE',
  label: translate('pageContentTypes.landingPage')
}, {
  value: 'STANDARD_PAGE',
  label: translate('pageContentTypes.standardPage')
}, {
  value: 'BLOG_POST',
  label: translate('pageContentTypes.blogPost')
}, {
  value: 'LISTING_PAGE',
  label: translate('pageContentTypes.listingPage')
}];
const options = {
  pageContentType: getPageContentTypeOptions,
  referrerContentType: getPageContentTypeOptions,
  visitSource: getSourceOptions,
  referrerVisitSource: getSourceOptions
};
export const getPropertyGroups = () => Promise.resolve(fromJS([{
  name: 'conversionInfo',
  displayName: translateGroup('conversionInfo'),
  displayOrder: 0,
  hubspotDefined: true,
  properties: conversionAttribution.reduce((merged, activity) => Object.keys(attributionRecord).reduce((memo, property) => [...memo, {
    name: `${activity}.${property}`,
    label: translateProperty(property, activity),
    type: attributionRecord[property],
    options: typeof options[property] === 'function' ? options[property]() : []
  }], merged), [])
}]));
export const getProperties = createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(CONVERSION)));