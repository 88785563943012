import I18n from 'I18n';
import { fromJS, Map as ImmutableMap } from 'immutable';
// @ts-expect-error migrate upstream
import { RANGE_TYPES } from 'reporting-ui-components/components/shared/constants/DateRangeConstants';
const DATE_FORMAT = 'YYYY-MM-DD';
export function isDashboardDateRange(value) {
  return ImmutableMap.isMap(value) && value.has('rangeType');
}
export function isSearchFilterDateRange(value) {
  return ImmutableMap.isMap(value) && value.has('operator') && (value.has('timeUnit') && value.has('timeUnitCount') || value.has('value') && value.has('highValue') || value.get('operator') === 'HAS_PROPERTY');
}
export function convertToSearchFilter(dashboardDateRange) {
  const rangeType = dashboardDateRange.get('rangeType');
  const rollingDays = dashboardDateRange.get('rollingDays');
  const startDate = dashboardDateRange.get('startDate');
  const endDate = dashboardDateRange.get('endDate');
  const entireCurrentUnit = dashboardDateRange.get('entireCurrentUnit');
  switch (rangeType) {
    case RANGE_TYPES.ALL:
      return fromJS({
        operator: 'HAS_PROPERTY',
        property: ''
      });
    case RANGE_TYPES.CUSTOM:
      return fromJS({
        operator: 'BETWEEN',
        property: '',
        dateTimeFormat: 'DATE',
        value: startDate,
        highValue: endDate
      });
    case RANGE_TYPES.ROLLING:
      return fromJS({
        operator: 'TIME_UNIT_TO_DATE',
        inclusive: true,
        property: '',
        timeUnitCount: parseInt(rollingDays || 7, 10),
        timeUnit: 'DAY'
      });
    default:
      break;
  }
  const [timeValue, dateValue] = rangeType.split('_');
  const inclusive = timeValue === 'THIS';
  const operator = inclusive && !entireCurrentUnit ? 'TIME_UNIT_TO_DATE' : 'ROLLING_DATE_RANGE';
  return fromJS({
    operator,
    inclusive,
    property: '',
    timeUnitCount: 1,
    timeUnit: dateValue,
    rollForward: timeValue === 'NEXT'
  });
}
export function convertToDateRange(searchFilter) {
  let rangeType;
  let rollingDays;
  let startDate;
  let endDate;
  let entireCurrentUnit;
  const timeUnit = searchFilter.get('timeUnit');
  switch (searchFilter.get('operator')) {
    case 'HAS_PROPERTY':
      rangeType = RANGE_TYPES.ALL;
      break;
    case 'BETWEEN':
      rangeType = RANGE_TYPES.CUSTOM;
      startDate = I18n.moment.portalTz(searchFilter.get('value')).format(DATE_FORMAT);
      endDate = I18n.moment.portalTz(searchFilter.get('highValue')).format(DATE_FORMAT);
      break;
    case 'TIME_UNIT_TO_DATE':
      {
        const timeUnitCount = searchFilter.get('timeUnitCount');
        if (timeUnit === 'DAY' && timeUnitCount > 1) {
          rangeType = RANGE_TYPES.ROLLING;
          rollingDays = parseInt(timeUnitCount || 7, 10);
        } else {
          rangeType = `THIS_${timeUnit}`;
          entireCurrentUnit = false;
        }
        break;
      }
    case 'ROLLING_DATE_RANGE':
      if (searchFilter.get('inclusive')) {
        rangeType = `THIS_${timeUnit}`;
        entireCurrentUnit = true;
      } else if (!searchFilter.get('rollForward')) {
        rangeType = `LAST_${timeUnit}`;
      } else {
        rangeType = `NEXT_${timeUnit}`;
      }
      break;
    default:
      break;
  }
  return fromJS({
    rangeType,
    rollingDays,
    startDate,
    endDate,
    entireCurrentUnit
  });
}

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}