import { List, Map as ImmutableMap } from 'immutable';
import { ChannelNames, preferredChannelRoleByVisualType, VisualTypes } from '../schema/visual-records';
// @ts-expect-error migrate upstream files
import { channels as channelConstraints } from '../validate/constraints/channels';
// @ts-expect-error migrate upstream files
import { isValid } from '../validate/validation-utils';
import { createDefaultReportWithVisualType, getExpressionFieldFromColumn } from './report-utils';
import { createDefaultVisual, encodeChannel, getChannel, getEmptyChannelEncoding, getEncoding, getEncodingListInChannel, getPreferredRoleInVisualChannel, getVisualTypeChannels, isSingleEncoding, visualTypeIsSliced, visualTypeIsXY } from './visual-utils';
import { mapMagicWandToVisualType, nominalFieldTypes, continuousFieldTypes } from './magic-wand-utils';
import { defaultEncodingOptions } from '../schema/column-records';
import { isList } from '../../shared/lib/utility-types';
import { COLUMN_ROLES } from 'reporting-data/constants/relationalReports';
const {
  X,
  X_MULTI,
  Y,
  Y_MULTI,
  COLOR,
  DETAIL,
  SIZING,
  VALUES,
  GROUP,
  ROWS,
  COLUMNS,
  Y_1,
  Y_2,
  BREAKDOWN
} = ChannelNames;
const pickValidEncodingsForChannel = (channelName, encodingList, columns, visualType) => {
  const preferredRoleForChannel = getPreferredRoleInVisualChannel(visualType, channelName);
  return preferredChannelRoleByVisualType ? encodingList.filter(encoding => columns.get(encoding.column).role === preferredRoleForChannel) : encodingList;
};
const copyMatchingEncodings = (fromEncodings, toEncodings) => toEncodings.toSeq().keySeq().reduce((result, channelName) => result.set(channelName, fromEncodings.get(channelName) || toEncodings.get(channelName)), toEncodings);
const copyFromOneChannelToManyCallbackFactory = (columns, toVisualType) => (tuple, toChannelName) => {
  const [copiedEncodings, remainingEncodings] = tuple;
  const channel = getChannel(toChannelName);
  if (remainingEncodings.size === 0) {
    return [copiedEncodings.set(toChannelName, getEmptyChannelEncoding(channel)), List()];
  }
  if (isSingleEncoding(channel)) {
    const encodingToCopy = pickValidEncodingsForChannel(toChannelName, remainingEncodings, columns, toVisualType).first();
    return encodingToCopy ? [copiedEncodings.set(toChannelName, encodingToCopy), remainingEncodings.filterNot(encoding => encoding.column === encodingToCopy.column).toList()] : [copiedEncodings, remainingEncodings];
  }

  // the target channel is a multiple-channel, copy rest of encodings into it
  return [copiedEncodings.set(toChannelName, pickValidEncodingsForChannel(toChannelName, remainingEncodings, columns, toVisualType).toList()), List()];
};
const copyOneToOne = (from, to, fromEncodings, toEncodings, columns, toVisualType) => {
  const fromChannel = getChannel(from);
  const toChannel = getChannel(to);
  const encoding = fromEncodings.get(from);
  const encodingList = getEncodingListInChannel(fromEncodings, from);
  if (fromChannel.type === toChannel.type) {
    return toEncodings.set(to, encoding);
  }

  // from is single, to is multiple
  if (isSingleEncoding(fromChannel)) {
    return toEncodings.set(to, encodingList);
  }

  // to is single, from is multiple
  return toEncodings.set(to, pickValidEncodingsForChannel(to, encodingList, columns, toVisualType).first());
};

/**
 *
 * helper function to apply to/from transformations to copy over encodings from one visual type to the next
 *
 * We can map encodings 1-1, such as copying X over to Y.
 * We can also map encodings many-1, such as copy X and COLOR both into Y.
 * Finally, we can map encodings 1-many, such as dividing up COLUMNS between X, Y and COLOR.
 *
 * @param {Object[]} swapArray: array of from/to maps defining which encodings should be copied where.
 * If swapArray 'undefined', then we assume we want to all matching encodings over
 * @param {Encodings} fromEncodings: visual encoding record to copy from
 * @param {Encodings} toEncodings: empty visual encoding record to copy to
 * @param {Column} columnsToEncode list of columns in use in report to encode
 * @param {VisualType} toVisualType visual type to transform to
 * @return {Encodings} "toEncodings" with swaps applied
 */
const applyArrayOfSwaps = (swapArray, fromEncodings, toEncodings, columnsToEncode, toVisualType) => {
  if (swapArray === undefined) {
    return copyMatchingEncodings(fromEncodings, toEncodings);
  }
  return swapArray.reduce((encodings, {
    to,
    from
  }) => {
    if (Array.isArray(to)) {
      if (Array.isArray(from)) {
        console.error('Error swapping visuals: "to" and "from" are both arrays.', {
          to,
          from
        });
        return encodings;
      } else {
        const copyFromOneChannelToMany = copyFromOneChannelToManyCallbackFactory(columnsToEncode, toVisualType);
        const [copied] = to.reduce(copyFromOneChannelToMany, [encodings, getEncodingListInChannel(fromEncodings, from)]);
        return copied;
      }
    } else {
      if (Array.isArray(from)) {
        // collect encodings from channels and add all to target
        const encodingList = List(from).flatMap(fromChannelName => getEncodingListInChannel(fromEncodings, fromChannelName)).toList();
        if (isSingleEncoding(getChannel(to))) {
          return encodings.set(to, pickValidEncodingsForChannel(to, encodingList, columnsToEncode, toVisualType).first());
        }
        return encodings.set(to, encodingList);
      } else {
        return copyOneToOne(from, to, fromEncodings, encodings, columnsToEncode, toVisualType);
      }
    }
  }, toEncodings);
};
const swapHorizontalToVerticalXY = [{
  from: [X, X_MULTI],
  to: Y_MULTI
}, {
  from: Y,
  to: X
}, {
  from: COLOR,
  to: COLOR
}];
const swapVerticalToHorizontalXY = [{
  from: [Y, Y_MULTI],
  to: X_MULTI
}, {
  from: X,
  to: Y
}, {
  from: COLOR,
  to: COLOR
}];
const xCategoricalToScatter = [{
  from: X,
  to: DETAIL
}, {
  from: [Y, Y_MULTI],
  to: Y
}, {
  from: COLOR,
  to: COLOR
}];
const yCategoricalToScatter = [{
  from: Y,
  to: DETAIL
}, {
  from: [X, X_MULTI],
  to: X
}, {
  from: COLOR,
  to: COLOR
}];
const slicedToText = [{
  from: COLOR,
  to: GROUP
}, {
  from: VALUES,
  to: VALUES
}];
const textToSliced = [{
  from: GROUP,
  to: COLOR
}, {
  from: VALUES,
  to: VALUES
}];
const xCategoricalToSliced = [{
  from: COLOR,
  to: COLOR
}, {
  from: [Y, Y_MULTI],
  to: VALUES
}];
const yCategoricalToSliced = [{
  from: COLOR,
  to: COLOR
}, {
  from: [X, X_MULTI],
  to: VALUES
}];
const slicedToXCategorical = [{
  from: COLOR,
  to: X
}, {
  from: VALUES,
  to: Y_MULTI
}];
const textToXCategorical = [{
  from: GROUP,
  to: COLOR
}, {
  from: VALUES,
  to: Y_MULTI
}];
const textToYCategorical = [{
  from: GROUP,
  to: COLOR
}, {
  from: VALUES,
  to: X_MULTI
}];
const slicedToYCategorical = [{
  from: COLOR,
  to: Y
}, {
  from: VALUES,
  to: X
}];
const xCategoricalToText = [{
  from: [Y, Y_MULTI],
  to: VALUES
}, {
  from: COLOR,
  to: GROUP
}];
const yCategoricalToText = [{
  from: [X, X_MULTI],
  to: VALUES
}, {
  from: COLOR,
  to: GROUP
}];
const copyAllToColumns = [{
  from: [X, X_MULTI, Y, Y_MULTI, COLOR, DETAIL, SIZING, GROUP, ROWS, COLUMNS, VALUES, Y_1, Y_2, BREAKDOWN],
  to: COLUMNS
}];
const fromVerticalBar = ImmutableMap({
  [VisualTypes.VERTICAL_BAR]: undefined,
  [VisualTypes.HORIZONTAL_BAR]: swapVerticalToHorizontalXY,
  [VisualTypes.LINE]: undefined,
  [VisualTypes.AREA]: undefined,
  [VisualTypes.SCATTER]: xCategoricalToScatter,
  // [VisualTypes.COMBO]: xCategoricalToCombo,
  [VisualTypes.TABLE]: copyAllToColumns,
  [VisualTypes.PIE]: xCategoricalToSliced,
  [VisualTypes.DONUT]: xCategoricalToSliced,
  [VisualTypes.TEXT]: xCategoricalToText
});
const fromHorizontalBar = ImmutableMap({
  [VisualTypes.VERTICAL_BAR]: swapHorizontalToVerticalXY,
  [VisualTypes.HORIZONTAL_BAR]: undefined,
  [VisualTypes.LINE]: swapHorizontalToVerticalXY,
  [VisualTypes.AREA]: swapHorizontalToVerticalXY,
  [VisualTypes.SCATTER]: yCategoricalToScatter,
  // [VisualTypes.COMBO]: yCategoricalToCombo,
  [VisualTypes.TABLE]: copyAllToColumns,
  [VisualTypes.PIE]: yCategoricalToSliced,
  [VisualTypes.DONUT]: yCategoricalToSliced,
  [VisualTypes.TEXT]: yCategoricalToText
});
const fromLine = fromVerticalBar;
const fromArea = fromVerticalBar;
const scatterToCategoricalOnX = [{
  from: Y,
  to: [Y_MULTI, Y]
}, {
  from: DETAIL,
  to: X
}, {
  from: COLOR,
  to: COLOR
}];
const scatterToCategoricalOnY = [{
  from: X,
  to: [X_MULTI, X]
}, {
  from: DETAIL,
  to: Y
}, {
  from: COLOR,
  to: COLOR
}];
const scatterToCombo = [{
  from: Y,
  to: Y_1
}, {
  from: X,
  to: Y_2
}, {
  from: DETAIL,
  to: X
}, {
  from: COLOR,
  to: BREAKDOWN
}];
const fromScatter = ImmutableMap({
  [VisualTypes.VERTICAL_BAR]: scatterToCategoricalOnX,
  [VisualTypes.HORIZONTAL_BAR]: scatterToCategoricalOnY,
  [VisualTypes.LINE]: scatterToCategoricalOnX,
  [VisualTypes.AREA]: scatterToCategoricalOnX,
  [VisualTypes.SCATTER]: undefined,
  [VisualTypes.COMBO]: scatterToCombo,
  [VisualTypes.TABLE]: copyAllToColumns
});
const fromPie = ImmutableMap({
  [VisualTypes.PIE]: undefined,
  [VisualTypes.DONUT]: undefined,
  [VisualTypes.TEXT]: slicedToText,
  [VisualTypes.TABLE]: copyAllToColumns,
  [VisualTypes.VERTICAL_BAR]: slicedToXCategorical,
  [VisualTypes.LINE]: slicedToXCategorical,
  [VisualTypes.AREA]: slicedToXCategorical,
  [VisualTypes.HORIZONTAL_BAR]: slicedToYCategorical
});
const pivotTableToXCategorical = [{
  from: ROWS,
  to: X
}, {
  from: COLUMNS,
  to: COLOR
}, {
  from: VALUES,
  to: Y_MULTI
}];
const pivotTableToYCategorical = [{
  from: ROWS,
  to: Y
}, {
  from: COLUMNS,
  to: COLOR
}, {
  from: VALUES,
  to: X_MULTI
}];
const fromPivotTable = ImmutableMap({
  [VisualTypes.PIE]: undefined,
  [VisualTypes.DONUT]: undefined,
  [VisualTypes.TEXT]: undefined,
  [VisualTypes.TABLE]: copyAllToColumns,
  [VisualTypes.VERTICAL_BAR]: pivotTableToXCategorical,
  [VisualTypes.LINE]: pivotTableToXCategorical,
  [VisualTypes.AREA]: pivotTableToXCategorical,
  [VisualTypes.HORIZONTAL_BAR]: pivotTableToYCategorical
});
const fromDonut = fromPie;
const fromText = ImmutableMap({
  [VisualTypes.PIE]: textToSliced,
  [VisualTypes.DONUT]: textToSliced,
  [VisualTypes.TEXT]: undefined,
  [VisualTypes.TABLE]: copyAllToColumns,
  [VisualTypes.VERTICAL_BAR]: textToXCategorical,
  [VisualTypes.LINE]: textToXCategorical,
  [VisualTypes.HORIZONTAL_BAR]: textToYCategorical
});
const comboToCategoricalOnX = [{
  from: X,
  to: X
}, {
  from: Y_1,
  to: Y_MULTI
}, {
  from: BREAKDOWN,
  to: COLOR
}];
const comboToCategoricalOnY = [{
  from: X,
  to: Y
}, {
  from: Y_1,
  to: X_MULTI
}, {
  from: BREAKDOWN,
  to: COLOR
}];
const comboToPivot = [{
  from: X,
  to: ROWS
}, {
  from: BREAKDOWN,
  to: COLUMNS
}, {
  from: [Y_1, Y_2],
  to: VALUES
}];
const comboToScatter = [{
  from: X,
  to: DETAIL
}, {
  from: Y_1,
  to: Y
}, {
  from: Y_2,
  to: X
}, {
  from: BREAKDOWN,
  to: COLOR
}];
const fromCombo = ImmutableMap({
  [VisualTypes.VERTICAL_BAR]: comboToCategoricalOnX,
  [VisualTypes.HORIZONTAL_BAR]: comboToCategoricalOnY,
  [VisualTypes.LINE]: comboToCategoricalOnX,
  [VisualTypes.AREA]: comboToCategoricalOnX,
  [VisualTypes.SCATTER]: comboToScatter,
  [VisualTypes.COMBO]: undefined,
  [VisualTypes.TABLE]: copyAllToColumns,
  [VisualTypes.PIVOT_TABLE]: comboToPivot
});

// refactored in https://git.hubteam.com/HubSpot/reporting/pull/8040
const visualTypeSwapMatrix = ImmutableMap({
  [VisualTypes.VERTICAL_BAR]: fromVerticalBar,
  [VisualTypes.HORIZONTAL_BAR]: fromHorizontalBar,
  [VisualTypes.LINE]: fromLine,
  [VisualTypes.AREA]: fromArea,
  [VisualTypes.SCATTER]: fromScatter,
  [VisualTypes.PIE]: fromPie,
  [VisualTypes.DONUT]: fromDonut,
  [VisualTypes.TEXT]: fromText,
  [VisualTypes.COMBO]: fromCombo,
  [VisualTypes.PIVOT_TABLE]: fromPivotTable
});
const copyColorOption = (fromVisual, toVisual) => {
  const colorOption = fromVisual.type === VisualTypes.COMBO ? fromVisual.options.get('y1Color') : fromVisual.options.get('color', undefined);
  if (!colorOption) {
    return toVisual;
  }
  switch (toVisual.type) {
    case VisualTypes.COMBO:
      return toVisual.update('options', options => options.set('y1Color', colorOption));
    case VisualTypes.VERTICAL_BAR:
      return toVisual.update('options', options => options.set('color', colorOption));
    case VisualTypes.HORIZONTAL_BAR:
      return toVisual.update('options', options => options.set('color', colorOption));
    case VisualTypes.LINE:
      return toVisual.update('options', options => options.set('color', colorOption));
    case VisualTypes.AREA:
      return toVisual.update('options', options => options.set('color', colorOption));
    case VisualTypes.SCATTER:
      return toVisual.update('options', options => options.set('color', colorOption));
    case VisualTypes.PIE:
    case VisualTypes.DONUT:
      return toVisual.update('options', options => options.set('color', colorOption));
    default:
      return toVisual;
  }
};
const copyAxisLabelOption = (fromVisual, toVisual) => {
  const axisLabel = fromVisual.type === VisualTypes.HORIZONTAL_BAR ? fromVisual.options.get('xAxisLabel') : fromVisual.options.get('yAxisLabel', undefined);
  if (!axisLabel) {
    return toVisual;
  }
  switch (toVisual.type) {
    case VisualTypes.HORIZONTAL_BAR:
      return toVisual.update('options', options => options.set('xAxisLabel', axisLabel));
    case VisualTypes.VERTICAL_BAR:
      return toVisual.update('options', options => options.set('yAxisLabel', axisLabel));
    case VisualTypes.LINE:
      return toVisual.update('options', options => options.set('yAxisLabel', axisLabel));
    case VisualTypes.AREA:
      return toVisual.update('options', options => options.set('yAxisLabel', axisLabel));
    default:
      return toVisual;
  }
};
const canApplyArrayOfSwaps = (fromVisualType, toVisualType) => {
  if (toVisualType === VisualTypes.TABLE) {
    return true;
  }
  if (visualTypeIsXY(fromVisualType) && visualTypeIsXY(toVisualType)) {
    return true;
  }
  if ((visualTypeIsSliced(fromVisualType) || fromVisualType === VisualTypes.TEXT) && (visualTypeIsSliced(toVisualType) || toVisualType === VisualTypes.TEXT)) {
    return true;
  }
  if (fromVisualType === VisualTypes.COMBO && toVisualType === VisualTypes.PIVOT_TABLE) {
    return true;
  }
  if (visualTypeIsXY(fromVisualType) && (visualTypeIsSliced(toVisualType) || toVisualType === VisualTypes.TEXT) || visualTypeIsXY(toVisualType) && (visualTypeIsSliced(fromVisualType) || fromVisualType === VisualTypes.TEXT)) {
    return true;
  }
  return false;
};
const addColumnToChannel = (reportDefinition, {
  column,
  channelName,
  encodingOptions
}) => {
  const expressionField = getExpressionFieldFromColumn(reportDefinition, column);
  return reportDefinition.update('columns', oldColumns => oldColumns.set(column.alias, column)).update('visual', visual => {
    if (visual) {
      return visual.update('encodings', encodings => {
        const newEncodings = encodeChannel({
          encodings,
          channelName,
          column,
          expressionField,
          encodingOptions
        });
        // encodeChannel returns a GenEncoding, but visual.encodings should be an EncodingsUnion
        return newEncodings;
      });
    }
    return visual;
  });
};
const passesConstraints = (reportDefinition, userInfo) => {
  return isValid(reportDefinition, userInfo, channelConstraints);
};
export const encodeSlicedChannelFactory = (fromEncodings, fromChannels, getEncodingForColumn) => (reportDefinition, columnsToEncode, channelName) => {
  const colorCompatibleColumn = columnsToEncode.find(c => c.role === COLUMN_ROLES.DIMENSION);
  const valuesCompatibleColumn = columnsToEncode.find(c => c.role === COLUMN_ROLES.MEASURE);
  const getIndexByChannelName = (name, column) => {
    let encodingsList;
    if (name === ChannelNames.COLUMNS) {
      encodingsList = name in fromEncodings ? fromEncodings.columns : undefined;
    } else if (name === ChannelNames.ROWS) {
      encodingsList = name in fromEncodings ? fromEncodings.rows : undefined;
    } else if (name === ChannelNames.VALUES) {
      encodingsList = name in fromEncodings ? fromEncodings.values : undefined;
    } else {
      return undefined;
    }
    return column && encodingsList && isList(encodingsList) ? encodingsList.map(col => col.column).toList().indexOf(column.alias) : undefined;
  };
  if (channelName === ChannelNames.COLOR && colorCompatibleColumn) {
    const column = colorCompatibleColumn;
    const columnEncoding = fromChannels.map(chnl => getEncodingForColumn(column, chnl, getIndexByChannelName(chnl.name, column))).toList().find(col => !!col);
    const encodingOptions = columnEncoding && columnEncoding.options || defaultEncodingOptions;
    return [addColumnToChannel(reportDefinition, {
      column,
      channelName,
      encodingOptions
    }), columnsToEncode.delete(column.alias)];
  } else if (channelName === ChannelNames.VALUES && valuesCompatibleColumn) {
    const column = valuesCompatibleColumn;
    const valueIndex = getIndexByChannelName(ChannelNames.VALUES, column);
    const columnEncoding = fromChannels.map(chnl => getEncodingForColumn(column, chnl, valueIndex)).toList().find(col => !!col);
    const encodingOptions = columnEncoding && columnEncoding.options || defaultEncodingOptions;
    return [addColumnToChannel(reportDefinition, {
      column,
      channelName,
      encodingOptions
    }), columnsToEncode.delete(column.alias)];
  }
  return [reportDefinition, columnsToEncode];
};

/**
 * Encodes a valid visual given a set of columns. Returned Encoded visual should always pass channel constraints validations
 * @param {Map<String, Columns>} columns Columns to encode in new visual type
 * @param {VisualType} visualType Visual type to encode for
 * @returns {<Encodings>} encodings
 */
const populateVisualTypeEncodingsFromColumns = (columns, visualType, fromVisual, userInfo) => {
  const fromEncodings = fromVisual.encodings;
  const fromVisualType = fromVisual.type;
  const fromChannels = getVisualTypeChannels(fromVisualType);
  if (!fromEncodings || !fromChannels) {
    return undefined;
  }
  const getEncodingForColumn = (column, chnl, index) => {
    const encoding = getEncoding(fromEncodings, chnl.name, index);
    return column && encoding && encoding.column === column.alias ? encoding : undefined;
  };
  const getColumnIndexFromEncodings = (column, encodings) => {
    if (!column) {
      return undefined;
    }
    if (encodings.columns && isList(encodings.columns)) {
      return encodings.columns.map(col => col.column).toList().indexOf(column.alias);
    }
    if (encodings.rows && isList(encodings.rows)) {
      return encodings.rows.map(col => col.column).toList().indexOf(column.alias);
    }
    const values = encodings.get('values');
    if (values && isList(values)) {
      return values.map(col => col.column).toList().indexOf(column.alias);
    }
    return undefined;
  };
  const encodeSingleChannel = (reportDefinition, columnsToEncode, channelName) => {
    const column = columnsToEncode.find(col => {
      return passesConstraints(addColumnToChannel(reportDefinition, {
        column: col,
        channelName,
        encodingOptions: undefined
      }), userInfo);
    });
    const index = getColumnIndexFromEncodings(column, fromEncodings);
    const columnEncoding = fromChannels.map(chnl => getEncodingForColumn(column, chnl, index)).toList().find(col => !!col);
    const encodingOptions = columnEncoding && columnEncoding.options || defaultEncodingOptions;
    if (columnsToEncode.size > 2) {
      if (channelName === ChannelNames.BREAKDOWN) {
        const maybeNominalColumn = columnsToEncode.find(col => col.role === COLUMN_ROLES.DIMENSION && nominalFieldTypes.includes(col.field.type));
        if (maybeNominalColumn) {
          const nextDefinition = addColumnToChannel(reportDefinition, {
            column: maybeNominalColumn,
            channelName,
            encodingOptions
          });
          if (maybeNominalColumn && passesConstraints(nextDefinition, userInfo)) {
            return [nextDefinition, columnsToEncode.delete(maybeNominalColumn.alias)];
          }
        }
      }
      if (channelName === ChannelNames.X && visualType === VisualTypes.VERTICAL_BAR || channelName === ChannelNames.Y && visualType === VisualTypes.HORIZONTAL_BAR || visualType === VisualTypes.LINE || visualType === VisualTypes.AREA) {
        const maybeOrdinalColumn = columnsToEncode.find(col => col.role === COLUMN_ROLES.DIMENSION && continuousFieldTypes.includes(col.field.type));
        if (maybeOrdinalColumn) {
          const nextDefinition = addColumnToChannel(reportDefinition, {
            column: maybeOrdinalColumn,
            channelName,
            encodingOptions
          });
          if (passesConstraints(nextDefinition, userInfo)) {
            return [nextDefinition, columnsToEncode.delete(maybeOrdinalColumn.alias)];
          }
        }
      }
    }
    return column ? [addColumnToChannel(reportDefinition, {
      column,
      channelName,
      encodingOptions
    }), columnsToEncode.delete(column.alias)] : [reportDefinition, columnsToEncode];
  };
  const encodeMultiChannel = (reportDefinition, columnsToEncode, channelName) => {
    return columnsToEncode.reduce(([currentReportDefinition, nextColumnsToEncode], column) => {
      const index = getColumnIndexFromEncodings(column, fromEncodings);
      const columnEncoding = fromChannels.map(chnl => getEncodingForColumn(column, chnl, index)).toList().find(col => !!col);
      const encodingOptions = columnEncoding && columnEncoding.options || defaultEncodingOptions;
      const nextDefinition = addColumnToChannel(currentReportDefinition, {
        column,
        channelName,
        encodingOptions
      });
      return passesConstraints(nextDefinition, userInfo) ? [nextDefinition, nextColumnsToEncode.delete(column.alias)] : [currentReportDefinition, nextColumnsToEncode];
    }, [reportDefinition, columnsToEncode]);
  };

  //sliced visuals won't pass constraint validation until all columns are encoded
  const encodeSlicedChannel = encodeSlicedChannelFactory(fromEncodings, fromChannels, getEncodingForColumn);
  const channelNames = getVisualTypeChannels(visualType).keySeq().filter(name => name !== ChannelNames.COMPARE);
  const [finalReportDefinition] = channelNames.reduce(([nextReportDefinition, columnsToEncode], channelName) => {
    if (visualTypeIsSliced(visualType)) {
      return encodeSlicedChannel(nextReportDefinition, columnsToEncode, channelName);
    }
    if (isSingleEncoding(getChannel(channelName))) {
      return encodeSingleChannel(nextReportDefinition, columnsToEncode, channelName);
    } else {
      return encodeMultiChannel(nextReportDefinition, columnsToEncode, channelName);
    }
  }, [createDefaultReportWithVisualType(visualType), columns]);
  return finalReportDefinition.visual ? finalReportDefinition.visual.encodings : createDefaultVisual(visualType).encodings;
};
export const transformVisualToNewType = (fromVisual, toVisualType, columnsToEncode, userInfo) => {
  const fromVisualType = fromVisual.type;
  if (toVisualType === VisualTypes.MAGIC_WAND) {
    toVisualType = mapMagicWandToVisualType(fromVisualType, columnsToEncode);
  }
  if (!fromVisualType || !toVisualType) {
    return createDefaultVisual(toVisualType);
  }
  if (fromVisualType === toVisualType) {
    return fromVisual;
  }
  let toVisual = createDefaultVisual(toVisualType).update('encodings', encodings => {
    if (canApplyArrayOfSwaps(fromVisualType, toVisualType)) {
      const availableSwaps = visualTypeSwapMatrix.get(fromVisualType);
      const swapArray = availableSwaps ? availableSwaps.get(toVisualType) : undefined;
      const newEncodings = applyArrayOfSwaps(swapArray, fromVisual.encodings, encodings, columnsToEncode, toVisualType);
      return newEncodings;
    }
    return populateVisualTypeEncodingsFromColumns(columnsToEncode, toVisualType, fromVisual, userInfo);
  });
  toVisual = copyColorOption(fromVisual, toVisual);
  toVisual = copyAxisLabelOption(fromVisual, toVisual);
  return toVisual;
};