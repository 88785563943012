'use es6';

import { createFetchImportNamesByObjectType, fetchImportNamesByObjectType } from '../api/ImportsAPI';
import * as CacheKeys from '../constants/CacheKeys';
import createSimpleCachedReferenceResolver from '../lib/createSimpleCachedReferenceResolver';
export const createInboundDbContactImportReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.INBOUND_DB_CONTACT_IMPORT,
  createFetchData: opts => {
    const getFn = createFetchImportNamesByObjectType(opts);
    return () => getFn('contact');
  },
  fetchData: () => fetchImportNamesByObjectType('contact')
}, options));
export default createInboundDbContactImportReferenceResolver();