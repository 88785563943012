'use es6';

import I18n from 'I18n';
import { List } from 'immutable';
import PropertyOptionRecord from 'customer-data-objects/property/PropertyOptionRecord';
import { getMeOption } from 'customer-data-objects/owners/OwnerIdOptions';
import { MY_TEAM_ID, MY_USER_ID } from 'reporting-data/constants/magicTypes';
export const getReportingSpecialOptionsByReferenceType = () => {
  return {
    OWNER: () => List.of(getMeOption()),
    USER: () => List.of(PropertyOptionRecord({
      value: MY_USER_ID,
      label: I18n.text('reporting-ui-components.specialFilterTokens.me'),
      description: I18n.text('reporting-ui-components.specialFilterTokens.meDescription'),
      icon: 'dynamicFilter'
    })),
    TEAM: () => List.of(PropertyOptionRecord({
      value: MY_TEAM_ID,
      label: I18n.text('reporting-ui-components.specialFilterTokens.myTeam')
    }))
  };
};