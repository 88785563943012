import { fromJS } from 'immutable';
import { AREA, LINE } from 'reporting-data/constants/chartTypes';
import { getDisplayParamsIncludeDateLine, isTimeSeriesReport } from 'reporting-data/report/configReportGetters';
import { setDisplayParamsIncludeDateLine } from 'reporting-data/report/configReportSetters';
import { getReportChartType } from 'reporting-data/report/reportGetters';
import { isConfigReport } from 'reporting-data/tsTypes/reportTypes';
export const getIsReportValidForShowTodayCheckbox = ({
  report
}) => {
  return isConfigReport(report) && [LINE, AREA].includes(getReportChartType(report) || '') && isTimeSeriesReport(report);
};
export const getIsShowTodayDateLineSetForConfigReport = report => {
  var _getDisplayParamsIncl;
  return ((_getDisplayParamsIncl = getDisplayParamsIncludeDateLine(report)) === null || _getDisplayParamsIncl === void 0 ? void 0 : _getDisplayParamsIncl.get('date')) === 'today';
};
export const setTodayDateLineForConfigReport = (report, isSet) => {
  return isSet ? setDisplayParamsIncludeDateLine(report, fromJS({
    date: 'today'
  })) : report.removeIn(['displayParams', 'includeDateLine']);
};