import PortalIdParser from 'PortalIdParser';
import { getQueryParam } from './urlUtils';
const sourceReopenGrowthSidePanel = ['growth-plan', 'growth-side-panel'];
export const isOnbordingTourBannerRendered = () => {
  return document.querySelector('.onboarding-tours-banner') !== null;
};
export const isGrowthSidePanelRendered = (rootWindow = window) => {
  return rootWindow.document.getElementById('growth-side-panel') !== null;
};

/**
 * Look for the Growth Side Panel iframe in the document
 *
 * @param targetWindow window where we expect to find the Growth Side Panel iframe
 * @returns iframe content window if found, null otherwise
 */
export const getIframeContentWindow = (targetWindow = window) => {
  var _targetWindow$documen;
  const iframeElement = targetWindow === null || targetWindow === void 0 || (_targetWindow$documen = targetWindow.document) === null || _targetWindow$documen === void 0 ? void 0 : _targetWindow$documen.getElementById('growth-side-panel-help-widget-iframe');
  return (iframeElement === null || iframeElement === void 0 ? void 0 : iframeElement.contentWindow) || null;
};

/**
 * Look for the Growth Side Panel content UI in the document
 *
 * @param targetWindow window where we expect to find the Growth Side Panel content-ui
 * @returns targetWindow if GSP content-ui is present, null otherwise
 */
export const getGrowthSidePanelContentUIWindow = (targetWindow = window) => {
  var _targetWindow$documen2;
  const contentUiElement = targetWindow === null || targetWindow === void 0 || (_targetWindow$documen2 = targetWindow.document) === null || _targetWindow$documen2 === void 0 ? void 0 : _targetWindow$documen2.getElementById('growth-side-panel-content-ui-container');
  return contentUiElement ? targetWindow : null;
};
export const sendTaskUpdateToSidePanel = ({
  actionName,
  targetWindow = window,
  origin = targetWindow.location.origin
}) => {
  const gspWindow = getIframeContentWindow(targetWindow) || getGrowthSidePanelContentUIWindow(targetWindow);
  if (!(gspWindow !== null && gspWindow !== void 0 && gspWindow.postMessage)) {
    return;
  }
  try {
    gspWindow.postMessage({
      type: 'growth-side-panel',
      payload: {
        key: actionName,
        action: 'task-completed'
      }
    }, origin);
  } catch (e) {
    return;
  }
};
/**
 * Post a message when the user completes/exits a task
 * growth-side-panel-messaging has a corrensponding onboardingTask messages constant to listen to this
 * {@link https://git.hubteam.com/HubSpot/growth-side-panel/blob/master/growth-side-panel-messaging/static/js/onboardingTask/constants.ts#L6}
 */
export const sendTaskExitMessage = ({
  actionName,
  targetWindow = window,
  origin = window.location.origin
}) => {
  const gspWindow = getIframeContentWindow(targetWindow) || getGrowthSidePanelContentUIWindow(targetWindow);
  if (!(gspWindow !== null && gspWindow !== void 0 && gspWindow.postMessage)) {
    return;
  }
  try {
    gspWindow.postMessage({
      type: 'growth-side-panel',
      payload: {
        key: actionName,
        action: 'task-exit'
      }
    }, origin);
  } catch (e) {
    return;
  }
};

/**
 * Replicates `toggleHelpWidget` logic from the Nav
 *
 * {@link https://git.hubteam.com/HubSpot/Navigation/blob/01c1c541b46895e63c0d2ca91ea56e6d3a9b0740/unified-navigation-ui/static/js/components/Help/index.tsx#L106}
 **/
export const openGrowthSidePanel = (rootWindow = window) => {
  const anyHubspotWindow = rootWindow.hubspot;
  try {
    var _anyHubspotWindow$zor, _anyHubspotWindow$zor2;
    anyHubspotWindow === null || anyHubspotWindow === void 0 || (_anyHubspotWindow$zor = anyHubspotWindow.zorse) === null || _anyHubspotWindow$zor === void 0 || (_anyHubspotWindow$zor2 = _anyHubspotWindow$zor.openHelpWidget) === null || _anyHubspotWindow$zor2 === void 0 || _anyHubspotWindow$zor2.call(_anyHubspotWindow$zor);
  } catch (_unused) {
    // ignore error
  }
};

/**
 * Auto reopen Growth Side Panel by setting localStorage, this prevents the side panel from opening on the current page
 * It can be used in the tour with page redirection on completion and avoid the side panel slide-in before page refreshing
 * The code is synced from {@link https://git.hubteam.com/HubSpot/Zorse/blob/8d4cb20b123f60df8152731247a5ed965d263231/ZorseButtonUI/static/js/button.js#L101-L110}
 */
export const openGrowthSidePanelOnNextPage = () => {
  try {
    const portalId = PortalIdParser.get();
    const iahAutoOpenKey = `in-app-help-auto-open-widget:${portalId}`;
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    window.localStorage.setItem(iahAutoOpenKey, tomorrow.getTime().toString());
  } catch (_unused2) {
    // ignore error
  }
};

/**
 * Check the presence of URL search parameters that intend to reopen the Growth Side Panel
 */
export const isUsingGrowthSidePanelURLParameters = () => {
  if (isOnbordingTourBannerRendered()) {
    return false;
  }
  const via = getQueryParam('via');
  const isSidePanel = getQueryParam('isSidePanel') === 'true'; // @deprecated prefer `withGrowthSidePanel` instead
  const withGrowthSidePanel = getQueryParam('withGrowthSidePanel') === 'true';
  return via && sourceReopenGrowthSidePanel.includes(via) || isSidePanel || withGrowthSidePanel;
};
const getOngoingTasksStorageKey = () => {
  const portalId = PortalIdParser.get();
  return `growth-side-panel-ongoing-tasks:${portalId}`;
};
export const clearTaskStartedInGrowthSidePanel = taskKey => {
  try {
    // keep track of tasks started in Growth Side Panel
    const ongoingTaskStorageKey = getOngoingTasksStorageKey();
    const ongoingTasksRegisterRaw = window.localStorage.getItem(ongoingTaskStorageKey);
    if (!ongoingTasksRegisterRaw) {
      return;
    }
    const ongoingTasksRegister = JSON.parse(ongoingTasksRegisterRaw);
    if (ongoingTasksRegister[taskKey]) {
      delete ongoingTasksRegister[taskKey];
      window.localStorage.setItem(ongoingTaskStorageKey, JSON.stringify(ongoingTasksRegister));
    }
  } catch (_unused3) {
    // ignore error
  }
};
export const isTaskStartedInGrowthSidePanel = taskKey => {
  try {
    // keep track of tasks started in Growth Side Panel
    const ongoingTaskStorageKey = getOngoingTasksStorageKey();
    const ongoingTasksRegisterRaw = window.localStorage.getItem(ongoingTaskStorageKey);
    if (!ongoingTasksRegisterRaw) {
      return false;
    }
    const ongoingTasksRegister = JSON.parse(ongoingTasksRegisterRaw);
    const {
      time: taskStartTime
    } = ongoingTasksRegister[taskKey];
    const oneHour = 60 * 60 * 1000;
    if (!taskStartTime || Date.now() - taskStartTime > oneHour) {
      clearTaskStartedInGrowthSidePanel(taskKey);
      return false;
    }
    return true;
  } catch (_unused4) {
    // ignore error
    return false;
  }
};
export const reopenGrowthSidePanel = ({
  task = {},
  disableURLParametersRequirement = false,
  disableDirectOpen = false,
  disableOpenOnNextPage = false
} = {}) => {
  const isTaskValid = !task.key || isTaskStartedInGrowthSidePanel(task.key);
  const isUrlParametersValid = disableURLParametersRequirement || isUsingGrowthSidePanelURLParameters();
  if (!isTaskValid || !isUrlParametersValid) {
    return;
  }
  let hasPanelBeingReopened = false;
  if (!disableOpenOnNextPage) {
    hasPanelBeingReopened = true;
    openGrowthSidePanelOnNextPage();
  }
  if (!disableDirectOpen && isGrowthSidePanelRendered()) {
    hasPanelBeingReopened = true;
    openGrowthSidePanel();
  }

  // keep the task in storage unless the panel could be opened:
  // solves an edge case in `onboarding-tours` where beforeExit is called on mount
  // and would clear the storage while still being unable to open the panel (no panel at that time).
  // That would prevent the normal beforeExit call to actually reopen the panel.
  if (hasPanelBeingReopened && task.key && !task.keepInStorage) {
    clearTaskStartedInGrowthSidePanel(task.key);
  }
};