'use es6';

import { VisualTypes } from '../../../schema/visual-records';
import { getReportGroupEncoding, getReportValuesEncodings, getReportVisualType } from '../../../utils/report-utils';
import { logicalXOR } from '../../../utils/validation-utils';
import { combineConstraints, ConstraintTypes, createConstraint, InvalidSpecificationTypes } from '../../validate';
import { groupChannelIsDimension, valueChannelsAreMeasures } from '../channels';
import { checkAllDateDimensionsHaveTransform } from './check-all-date-dimensions-have-transformation';
export const manyValuesXORGroup = createConstraint('manyValuesXORGroup', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, (group, values) => logicalXOR(group !== undefined && values.size === 1, group === undefined && values.size >= 1), [getReportGroupEncoding, getReportValuesEncodings]);
export const textVisualIsComplete = createConstraint('completionCriteria', ConstraintTypes.HARD, InvalidSpecificationTypes.INCOMPLETE, values => values.size > 0, getReportValuesEncodings);
const dateDimensionsHaveTransformation = createConstraint('dateDimensionsHaveTransformation', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, checkAllDateDimensionsHaveTransform, [report => report.columns]);
export const text = combineConstraints('text', [groupChannelIsDimension, valueChannelsAreMeasures, manyValuesXORGroup, textVisualIsComplete, dateDimensionsHaveTransformation], (constraint, report) => getReportVisualType(report) !== VisualTypes.TEXT || constraint.validate(report));