import * as checked from 'reporting-data/lib/checked';
import { Encoding } from '../column-records';
import { VisualTypes } from '../constants/visual-constants';
export const TextEncodings = checked.record({
  group: Encoding.optional(),
  compare: Encoding.optional(),
  values: checked.list(Encoding)
}, 'TextEncodings');
export const TextVisualOptions = checked.record({
  showPercentChangeComparison: checked.boolean().defaultValue(false)
}, 'TextVisualOptions');
export const TextVisual = checked.record({
  type: checked.string().always(VisualTypes.TEXT),
  encodings: TextEncodings,
  options: TextVisualOptions.defaultValue({})
}, 'TextVisual');