/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

import { createSlice } from '@reduxjs/toolkit';
import { RequestState } from '../../filter/requests/request-state';
import { selectDataSourcesInfo, selectDataSourcesInfoStatus } from '../../filter/utils/dataSourceInfoLogic';
import { selectUserScope } from '../userInfoSlice';
import { fetchDataSourcePropertiesAsyncThunk } from './fetchDataSourcePropertiesThunk';
export const dataSourcePropertiesNamespace = 'dataSourceProperties';
const dataSourcePropertiesSlice = createSlice({
  name: dataSourcePropertiesNamespace,
  initialState: {},
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDataSourcePropertiesAsyncThunk.pending, (state, action) => {
      const {
        arg
      } = action.meta;
      const {
        dataSource
      } = arg;
      state[dataSource] = {
        status: RequestState.PENDING
      };
    });
    builder.addCase(fetchDataSourcePropertiesAsyncThunk.fulfilled, (state, action) => {
      const {
        arg
      } = action.meta;
      const properties = action.payload;
      const {
        dataSource
      } = arg;
      state[dataSource] = {
        status: RequestState.SUCCEEDED,
        properties
      };
    });
    builder.addCase(fetchDataSourcePropertiesAsyncThunk.rejected, (state, action) => {
      const {
        arg
      } = action.meta;
      const {
        dataSource
      } = arg;
      state[dataSource] = {
        status: RequestState.FAILED
      };
    });
  }
});

// Selectors
export const selectFetchPropertiesList = dataSources => state => {
  return dataSources.filter(dataSource => {
    return !state.dataSourceProperties[dataSource];
  });
};
export const selectDataSourcesProperties = dataSources => state => {
  return selectDataSourcesInfo({
    dataSources,
    valueName: 'properties'
  })(state.dataSourceProperties);
};
export const selectDataSourceProperties = dataSource => state => selectDataSourcesProperties([dataSource])(state)[dataSource];
export const selectDataSourcesPropertiesStatus = dataSources => state => {
  const status = selectDataSourcesInfoStatus({
    dataSources
  })(state.dataSourceProperties);
  return status;
};
export const selectDashboardDataSourcesProperties = state => {
  return state[dataSourcePropertiesNamespace];
};
export const fetchDataSourcesProperties = (dataSources, hasCustomReportingAccess = false, scopes) => (dispatch, getState) => {
  const globalState = getState();

  // TODO: calculate scopes from within async thunk w/ access to globalState
  const _scopes = scopes || selectUserScope(globalState);
  selectFetchPropertiesList(dataSources)(globalState).forEach(dataSource => dispatch(fetchDataSourcePropertiesAsyncThunk({
    dataSource,
    scopes: _scopes,
    hasCustomReportingAccess
  })));
};
export const dataSourcePropertiesReducer = dataSourcePropertiesSlice.reducer;
export default dataSourcePropertiesReducer;