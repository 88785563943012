import unescapedText from 'I18n/utils/unescapedText';
import { propertyLabelTranslator } from 'property-translator/propertyTranslator';
import PropertyRecord from 'customer-data-objects/property/PropertyRecord';
export const defaultLabelsByName = {
  'associations.contact': 'customerDataPropertyUtils.PropertyDisplay.associatedcontactid',
  '_inbounddbio.importid_': 'customerDataPropertyUtils.PropertyDisplay.inbounddbImport',
  'associations.company': 'customerDataPropertyUtils.PropertyDisplay.associatedcompanyid',
  'engagement.createdBy': 'customerDataPropertyUtils.PropertyDisplay.taskCreatedBy',
  'engagement.ownerId': 'customerDataPropertyUtils.PropertyDisplay.taskOwner',
  'engagement.timestamp': 'customerDataPropertyUtils.PropertyDisplay.taskDueDate',
  'engagement.type': 'customerDataPropertyUtils.PropertyDisplay.engagementType',
  'formSubmissions.formId': 'customerDataPropertyUtils.PropertyDisplay.formSubmissions',
  'listMemberships.listId': 'customerDataPropertyUtils.PropertyDisplay.listMemberships',
  'metadata.body': 'customerDataPropertyUtils.PropertyDisplay.taskBody',
  'metadata.reminders': 'customerDataPropertyUtils.PropertyDisplay.taskReminder',
  'metadata.status': 'customerDataPropertyUtils.PropertyDisplay.taskStatus',
  'metadata.subject': 'customerDataPropertyUtils.PropertyDisplay.taskSubject',
  'metadata.taskType': 'customerDataPropertyUtils.PropertyDisplay.taskType',
  associatedcompanyid: 'customerDataPropertyUtils.PropertyDisplay.associatedcompanyid',
  relatesTo: 'customerDataPropertyUtils.PropertyDisplay.taskRelatesTo',
  pipeline: 'customerDataPropertyUtils.PropertyDisplay.deals.pipeline',
  days_to_close: 'customerDataPropertyUtils.PropertyDisplay.deals.days_to_close',
  amount_in_home_currency: 'customerDataPropertyUtils.PropertyDisplay.deals.amount_in_home_currency',
  hs_projected_amount: 'customerDataPropertyUtils.PropertyDisplay.deals.hs_projected_amount',
  hs_closed_amount: 'customerDataPropertyUtils.PropertyDisplay.deals.hs_closed_amount',
  hs_projected_amount_in_home_currency: 'customerDataPropertyUtils.PropertyDisplay.deals.hs_projected_amount_in_home_currency',
  hs_closed_amount_in_home_currency: 'customerDataPropertyUtils.PropertyDisplay.deals.hs_closed_amount_in_home_currency',
  hs_deal_stage_probability: 'customerDataPropertyUtils.PropertyDisplay.deals.hs_deal_stage_probability'
};
const isPropertyRecord = property => property instanceof PropertyRecord;
export function getPropertyLabel(property, labelsByName = defaultLabelsByName) {
  // Legacy check; no one is expected to pass in an undefined property,
  // but historically this helper handled them gracefully.
  // To avoid breaking legacy assumptions, leave this runtime check here
  // but advertise via types that `property` is required.
  if (!property) {
    return undefined;
  }
  const {
    hubspotDefined,
    label,
    name
  } = property;
  if (hubspotDefined && Object.prototype.hasOwnProperty.call(labelsByName, name)) {
    return unescapedText(labelsByName[name]);
  }
  const isBidenProperty = isPropertyRecord(property) ? property.isBidenProperty : undefined;
  const labelToTranslate = isBidenProperty ? name : label || name;
  const nameSpaceKey = isBidenProperty ? 'bidenProperties' : undefined;
  return hubspotDefined || isBidenProperty ? propertyLabelTranslator(labelToTranslate, nameSpaceKey) || name : labelToTranslate;
}