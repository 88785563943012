'use es6';

import { defineFactory } from 'general-store';
import { List, Record, fromJS, Map as ImmutableMap } from 'immutable';
import { TEAMS_FETCH_SUCCESS } from '../constants/SystemDataActionTypes';
const State = Record({
  loaded: false,
  teamsById: ImmutableMap(),
  teamsByUserId: ImmutableMap(),
  teams: List()
});
export default defineFactory().defineName('TeamsStore').defineGetInitialState(() => new State()).defineGet((state, key, extra = {}) => {
  if (key === 'loaded') {
    return state.get(key);
  }
  if (key === 'teamByUserId') {
    const {
      userId
    } = extra;
    return state.getIn(['teamsByUserId', userId]);
  }
  if (key === 'teams') {
    return state.get('teams');
  }
  if (key) {
    return state.getIn(['teamsById', key]);
  }
  return state;
}).defineResponseTo(TEAMS_FETCH_SUCCESS, (state, teams = []) => {
  let teamsByUserId = ImmutableMap();
  let teamsById = ImmutableMap();
  teams.forEach(team => {
    const userIds = team.get('userIds');
    const id = team.get('id');
    const name = team.get('name');
    teamsById = teamsById.set(id, team);
    userIds.forEach(userId => {
      teamsByUserId = teamsByUserId.set(userId, ImmutableMap({
        id,
        name
      }));
    });
  });
  return new State({
    teamsById,
    teamsByUserId,
    teams: fromJS(teams),
    loaded: true
  });
}).register();