'use es6';

export const get = (spec, config) => {
  const {
    d0,
    d1,
    d2
  } = spec;
  const {
    dimensions = [],
    filters: {
      custom = []
    } = {}
  } = config;
  const {
    length: dimensionality
  } = dimensions;
  const drilldown = custom.some(({
    property
  }) => property === 'd2') ? d2 : custom.some(({
    property
  }) => property === 'd1') ? d1 : d0;
  return {
    drilldown,
    dimensionality
  };
};