import I18n from 'I18n';
import { EDITOR_TYPES } from 'reporting-data/tsTypes/editorTypes';
// const __EXAMPLE = {
//   type: 'PRESETS',
//   options: [
//     {
//       type: 'OVER_TIME_PRESET',
//       configuration: [
//         {
//           type: 'DIMENSION',
//           property: 'hs_createdate',
//         },
//       ],
//     },
//     {
//       type: 'TOTALS_PRESET',
//       configuration: [
//         {
//           type: 'DIMENSION',
//           property: 'hubspot_owner_id',
//         },
//       ],
//     },
//   ],
// };
export const PRESET_TYPES = {
  OVER_TIME_PRESET: 'OVER_TIME_PRESET',
  TOTALS_PRESET: 'TOTALS_PRESET'
};
export const validatePropertyExistsInDimensionSchema = (property, schema) => {
  return schema ? schema.properties.includes(property) : false;
};
export const getPresetLabel = presetType => {
  switch (presetType) {
    case PRESET_TYPES.OVER_TIME_PRESET:
      return I18n.text('reporting-enablement.reportEditors.presetsEditor.options.overTimePreset');
    case PRESET_TYPES.TOTALS_PRESET:
      return I18n.text('reporting-enablement.reportEditors.presetsEditor.options.totalsPreset');
    default:
      // UIViewController expects type "text: string | undefined"
      return undefined;
  }
};
export const validatePresetConfiguration = (preset, editorSchemaMap) => {
  const configuration = preset.configuration;
  return configuration[0].type === EDITOR_TYPES.DIMENSION && validatePropertyExistsInDimensionSchema(configuration[0].property, editorSchemaMap[EDITOR_TYPES.DIMENSION]);
};