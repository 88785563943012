import * as checked from 'reporting-data/lib/checked';
import { Field } from './column-records';
export const Conditions = {
  AND: 'AND',
  OR: 'OR',
  CUSTOM: 'CUSTOM'
};
export const Condition = checked.symbol(Conditions, 'Conditions');
export const Filter = checked.record({
  field: Field,
  filter: checked.any().fromJS(),
  isIgnored: checked.boolean().optional()
}, 'Filter');
export const FilterRefTypes = {
  DATASET_FIELD: 'DATASET_FIELD',
  PROPERTY: 'PROPERTY',
  LIST: 'LIST',
  EXPRESSION_FIELD: 'EXPRESSION_FIELD'
};
export const FilterRefType = checked.symbol(FilterRefTypes, 'FilterRefType');
export const FieldFilterRef = checked.record({
  type: FilterRefType.always(FilterRefTypes.DATASET_FIELD).defaultValue(FilterRefTypes.DATASET_FIELD),
  field: checked.string()
});
export const PropertyFilterRef = checked.record({
  type: FilterRefType.always(FilterRefTypes.PROPERTY).defaultValue(FilterRefTypes.PROPERTY),
  property: checked.string(),
  table: checked.string()
});
export const ListFilterRef = checked.record({
  type: FilterRefType.always(FilterRefTypes.LIST).defaultValue(FilterRefTypes.LIST),
  table: checked.string()
});
export const ExpressionFieldFilterRef = checked.record({
  type: FilterRefType.always(FilterRefTypes.EXPRESSION_FIELD).defaultValue(FilterRefTypes.EXPRESSION_FIELD),
  field: checked.string()
});
const _FilterRef = checked.poly('type', {
  [FilterRefTypes.DATASET_FIELD]: FieldFilterRef,
  [FilterRefTypes.PROPERTY]: PropertyFilterRef,
  [FilterRefTypes.LIST]: ListFilterRef,
  [FilterRefTypes.EXPRESSION_FIELD]: ExpressionFieldFilterRef
}, 'FilterRef');
export const FilterRef = _FilterRef;
export const Group = checked.record({
  id: checked.string(),
  condition: Condition,
  filters: checked.list(Filter)
}, 'Group');
export const Filtering = checked.record({
  condition: Condition,
  groups: checked.list(Group),
  logic: checked.string().optional(),
  stagedFilters: checked.list(FilterRef)
}, 'Filtering');
export const EventDateInterval = checked.record({
  propertyFilterOperation: checked.any().fromJS()
}, 'EventDateInterval');
export const DateComparisonFilter = checked.record({
  filters: checked.list(Filter)
}, 'DateComparisonFilter');