import { List } from 'immutable';
import * as checked from 'reporting-data/lib/checked';
import { SLINKY, CANDY_APPLE, CANDY_APPLE_MEDIUM, MARIGOLD, OZ_MEDIUM, OZ } from 'HubStyleTokens/colors';
const ColorItem = checked.record({
  label: checked.string().optional(),
  key: checked.string(),
  value: checked.string() // hex color string
}, 'ColorItem');
const InterpolateColorItem = checked.record({
  positionFraction: checked.number(),
  // any number in range [0, 1]
  value: checked.string() // hex color string
}, 'InterpolateColorItem');
export const SchemeTypes = {
  DISCRETE: 'DISCRETE',
  INTERPOLATE: 'INTERPOLATE',
  DISCRETE_PATTERN: 'DISCRETE PATTERN',
  BRAND_KIT: 'BRAND KIT'
};
const SchemeType = checked.symbol(SchemeTypes, 'SchemeTypes');
const DiscreteScheme = checked.record({
  type: SchemeType.always(SchemeTypes.DISCRETE).defaultValue(SchemeTypes.DISCRETE),
  colors: checked.list(ColorItem)
});
export const BrandKitScheme = checked.record({
  type: SchemeType.always(SchemeTypes.BRAND_KIT).defaultValue(SchemeTypes.BRAND_KIT),
  colors: checked.list(ColorItem)
});
const InterpolateScheme = checked.record({
  type: SchemeType.always(SchemeTypes.INTERPOLATE).defaultValue(SchemeTypes.INTERPOLATE),
  points: checked.list(InterpolateColorItem)
});
const DiscretePatternScheme = checked.record({
  type: SchemeType.always(SchemeTypes.DISCRETE_PATTERN).defaultValue(SchemeTypes.DISCRETE_PATTERN),
  colors: checked.list(ColorItem),
  patterns: checked.list(checked.string()),
  dashStylePatterns: checked.list(checked.string())
});
export const Scheme = checked.poly('type', {
  [SchemeTypes.DISCRETE]: DiscreteScheme,
  [SchemeTypes.INTERPOLATE]: InterpolateScheme,
  [SchemeTypes.DISCRETE_PATTERN]: DiscretePatternScheme,
  [SchemeTypes.BRAND_KIT]: BrandKitScheme
}, 'Scheme');
export const BRAND_KIT_PALETTE_ID = 'BrandKit';
const PaletteIds = {
  Categorical14: 'Categorical14',
  PatternFill14: 'PatternFill14',
  ShadesOfOrange: 'ShadesOfOrange',
  ShadesOfAqua: 'ShadesOfAqua',
  ShadesOfPurple: 'ShadesOfPurple',
  ShadesOfYellow: 'ShadesOfYellow',
  ShadesOfPink: 'ShadesOfPink',
  ShadesOfBlue: 'ShadesOfBlue',
  ShadesOfGreen: 'ShadesOfGreen',
  TokyoFive: 'TokyoFive',
  BogotaFive: 'BogotaFive',
  DublinFive: 'DublinFive',
  CambridgeFive: 'CambridgeFive',
  SingaporeFive: 'SingaporeFive',
  SydneyFive: 'SydneyFive',
  BerlinFive: 'BerlinFive',
  ParisFive: 'ParisFive',
  SanFranciscoFive: 'SanFranciscoFive',
  PortsmouthFive: 'PortsmouthFive',
  GhentFive: 'GhentFive',
  OceanFive: 'OceanFive',
  SpringFive: 'SpringFive',
  CherryBlossomFive: 'CherryBlossomFive',
  PastelFive: 'PastelFive',
  DefaultY2: 'DefaultY2',
  AlertRedToGreen: 'AlertRedToGreen',
  AlertGreenToRed: 'AlertGreenToRed',
  BrandKit: BRAND_KIT_PALETTE_ID
};
export const checkedPaletteIds = checked.symbol(PaletteIds, 'PaletteIds');
export const Palette = checked.record({
  id: checked.symbol(PaletteIds, 'PaletteIds'),
  label: checked.string(),
  i18nLabel: checked.string().optional(),
  scheme: Scheme
}, 'Palette');
export const DefaultCategorical = Palette({
  id: PaletteIds.Categorical14,
  label: 'reporting-snowflake.colors.palettes.default-14',
  scheme: DiscreteScheme({
    colors: [{
      label: 'Orange',
      key: 'orange',
      value: '#fea58e'
    }, {
      label: 'Aqua',
      key: 'aqua',
      value: '#51d3d9'
    }, {
      label: 'Purple',
      key: 'purple',
      value: '#bda9ea'
    }, {
      label: 'Yellow',
      key: 'yellow',
      value: '#f5c78e'
    }, {
      label: 'Pink',
      key: 'pink',
      value: '#ea90b1'
    }, {
      label: 'Blue',
      key: 'blue',
      value: '#81c1fd'
    }, {
      label: 'Green',
      key: 'green',
      value: '#a4d398'
    }, {
      label: 'DARK_ORANGE',
      key: 'dark-orange',
      value: '#c3705c'
    }, {
      label: 'DARK_AQUA',
      key: 'dark-aqua',
      value: '#009ca2'
    }, {
      label: 'DARK_PURPLE',
      key: 'dark-purple',
      value: '#8775b2'
    }, {
      label: 'DARK_YELLOW',
      key: 'dark-yellow',
      value: '#bb915b'
    }, {
      label: 'DARK_PINK',
      key: 'dark-pink',
      value: '#b05c7d'
    }, {
      label: 'DARK_BLUE',
      key: 'dark-blue',
      value: '#468cc4'
    }, {
      label: 'DARK_GREEN',
      key: 'dark-green',
      value: '#6b9a5b'
    }]
  })
});
export const PatternFill14 = Palette({
  id: PaletteIds.PatternFill14,
  label: 'reporting-snowflake.colors.palettes.pattern-fill-14',
  scheme: DiscretePatternScheme({
    colors: [{
      label: 'Orange',
      key: 'orange',
      value: '#fea58e'
    }, {
      label: 'Aqua',
      key: 'aqua',
      value: '#51d3d9'
    }, {
      label: 'Purple',
      key: 'purple',
      value: '#bda9ea'
    }, {
      label: 'Yellow',
      key: 'yellow',
      value: '#f5c78e'
    }, {
      label: 'Pink',
      key: 'pink',
      value: '#ea90b1'
    }, {
      label: 'Blue',
      key: 'blue',
      value: '#81c1fd'
    }, {
      label: 'Green',
      key: 'green',
      value: '#a4d398'
    }, {
      label: 'DARK_ORANGE',
      key: 'dark-orange',
      value: '#c3705c'
    }, {
      label: 'DARK_AQUA',
      key: 'dark-aqua',
      value: '#009ca2'
    }, {
      label: 'DARK_PURPLE',
      key: 'dark-purple',
      value: '#8775b2'
    }, {
      label: 'DARK_YELLOW',
      key: 'dark-yellow',
      value: '#bb915b'
    }, {
      label: 'DARK_PINK',
      key: 'dark-pink',
      value: '#b05c7d'
    }, {
      label: 'DARK_BLUE',
      key: 'dark-blue',
      value: '#468cc4'
    }, {
      label: 'DARK_GREEN',
      key: 'dark-green',
      value: '#6b9a5b'
    }],
    patterns: [
    //Pulled from Highcharts' default patterns: https://github.com/highcharts/highcharts/blob/c11ce666605334e6ad245a8041f387f6e0fc3f87/ts/Extensions/PatternFill.ts#L99-L121
    'M 0 10 L 10 0 M -1 1 L 1 -1 M 9 11 L 11 9',
    // Line: /
    'M 0 0 L 5 10 L 10 0',
    //ZigZag Horizontal
    'M 3 3 L 8 3 L 8 8 L 3 8 Z',
    // Squares
    'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
    // Line: \
    'M 5 5 m -4 0 a 4 4 0 1 1 8 0 a 4 4 0 1 1 -8 0' // Circles
    ],
    dashStylePatterns: ['Solid', 'ShortDash', 'ShortDot', 'ShortDashDot', 'ShortDashDotDot', 'Dot', 'Dash', 'LongDash', 'DashDot', 'LongDashDot', 'LongDashDotDot']
  })
});
export const ShadesOfAqua = Palette({
  id: PaletteIds.ShadesOfAqua,
  label: 'reporting-snowflake.colors.palettes.shades-of-aqua',
  scheme: InterpolateScheme({
    points: [{
      positionFraction: 0,
      value: '#066469'
    }, {
      positionFraction: 0.5,
      value: '#29b7bd'
    }, {
      positionFraction: 1,
      value: '#a7ffff'
    }]
  })
});
export const ShadesOfOrange = Palette({
  id: PaletteIds.ShadesOfOrange,
  label: 'reporting-snowflake.colors.palettes.shades-of-orange',
  scheme: InterpolateScheme({
    points: [{
      positionFraction: 0,
      value: '#904233'
    }, {
      positionFraction: 0.5,
      value: '#e78f7b'
    }, {
      positionFraction: 1,
      value: '#fffbe6'
    }]
  })
});
export const ShadesOfPurple = Palette({
  id: PaletteIds.ShadesOfPurple,
  label: 'reporting-snowflake.colors.palettes.shades-of-purple',
  scheme: InterpolateScheme({
    points: [{
      positionFraction: 0,
      value: '#5b4b85'
    }, {
      positionFraction: 0.5,
      value: '#af9cdc'
    }, {
      positionFraction: 1,
      value: '#fdf2ff'
    }]
  })
});
export const ShadesOfYellow = Palette({
  id: PaletteIds.ShadesOfYellow,
  label: 'reporting-snowflake.colors.palettes.shades-of-yellow',
  scheme: InterpolateScheme({
    points: [{
      positionFraction: 0,
      value: '#cb8007'
    }, {
      positionFraction: 0.5,
      value: '#fec76f'
    }, {
      positionFraction: 1,
      value: '#fff1da'
    }]
  })
});
export const ShadesOfPink = Palette({
  id: PaletteIds.ShadesOfPink,
  label: 'reporting-snowflake.colors.palettes.shades-of-pink',
  scheme: InterpolateScheme({
    points: [{
      positionFraction: 0,
      value: '#8f3e5f'
    }, {
      positionFraction: 0.5,
      value: '#e48bac'
    }, {
      positionFraction: 1,
      value: '#ffe4ff'
    }]
  })
});
export const ShadesOfBlue = Palette({
  id: PaletteIds.ShadesOfBlue,
  label: 'reporting-snowflake.colors.palettes.shades-of-blue',
  scheme: InterpolateScheme({
    points: [{
      positionFraction: 0,
      value: '#005a8e'
    }, {
      positionFraction: 0.5,
      value: '#6aabe6'
    }, {
      positionFraction: 1,
      value: '#c7ffff'
    }]
  })
});
export const ShadesOfGreen = Palette({
  id: PaletteIds.ShadesOfGreen,
  label: 'reporting-snowflake.colors.palettes.shades-of-green',
  scheme: InterpolateScheme({
    points: [{
      positionFraction: 0,
      value: '#315f24'
    }, {
      positionFraction: 0.5,
      value: '#83b271'
    }, {
      positionFraction: 1,
      value: '#dbffc7'
    }]
  })
});
export const Tokyo = Palette({
  id: PaletteIds.TokyoFive,
  label: 'reporting-snowflake.colors.palettes.tokyo-five',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: '#385315',
      value: '#385315'
    }, {
      label: '',
      key: '#A5B4B3',
      value: '#A5B4B3'
    }, {
      label: '',
      key: '#00627B',
      value: '#00627B'
    }, {
      label: '',
      key: '#AA4735',
      value: '#AA4735'
    }, {
      label: '',
      key: '#E8BAAF',
      value: '#E8BAAF'
    }]
  })
});
export const Bogota = Palette({
  id: PaletteIds.BogotaFive,
  label: 'reporting-snowflake.colors.palettes.bogota-five',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: '#D7C1C2',
      value: '#D7C1C2'
    }, {
      label: '',
      key: '#2F4C5E',
      value: '#2F4C5E'
    }, {
      label: '',
      key: '#86343C',
      value: '#86343C'
    }, {
      label: '',
      key: '#B67D40',
      value: '#B67D40'
    }, {
      label: '',
      key: '#133C82',
      value: '#133C82'
    }]
  })
});
export const Dublin = Palette({
  id: PaletteIds.DublinFive,
  label: 'reporting-snowflake.colors.palettes.dublin-five',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: '#43554F',
      value: '#43554F'
    }, {
      label: '',
      key: '#D6D8D4',
      value: '#D6D8D4'
    }, {
      label: '',
      key: '#A3BFBE',
      value: '#A3BFBE'
    }, {
      label: '',
      key: '#4EB39D',
      value: '#4EB39D'
    }, {
      label: '',
      key: '#C7E9C1',
      value: '#C7E9C1'
    }]
  })
});
export const Cambridge = Palette({
  id: PaletteIds.CambridgeFive,
  label: 'reporting-snowflake.colors.palettes.cambridge-five',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: '#9C7475',
      value: '#9C7475'
    }, {
      label: '',
      key: '#968613',
      value: '#968613'
    }, {
      label: '',
      key: '#29464E',
      value: '#29464E'
    }, {
      label: '',
      key: '#4E1309',
      value: '#4E1309'
    }, {
      label: '',
      key: '#110235',
      value: '#110235'
    }]
  })
});
export const Singapore = Palette({
  id: PaletteIds.SingaporeFive,
  label: 'reporting-snowflake.colors.palettes.singapore-five',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: '#8B9AF8',
      value: '#8B9AF8'
    }, {
      label: '',
      key: '#982B48',
      value: '#982B48'
    }, {
      label: '',
      key: '#CE7AB5',
      value: '#CE7AB5'
    }, {
      label: '',
      key: '#FEA575',
      value: '#FEA575'
    }, {
      label: '',
      key: '#2B5063',
      value: '#2B5063'
    }]
  })
});
export const Sydney = Palette({
  id: PaletteIds.SydneyFive,
  label: 'reporting-snowflake.colors.palettes.sydney-five',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: '#2B8799',
      value: '#2B8799'
    }, {
      label: '',
      key: '#B96AA3',
      value: '#B96AA3'
    }, {
      label: '',
      key: '#62B9D4',
      value: '#62B9D4'
    }, {
      label: '',
      key: '#F5815F',
      value: '#F5815F'
    }, {
      label: '',
      key: '#5A5DA8',
      value: '#5A5DA8'
    }]
  })
});
export const Berlin = Palette({
  id: PaletteIds.BerlinFive,
  label: 'reporting-snowflake.colors.palettes.berlin-five',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: '#4C82B0',
      value: '#4C82B0'
    }, {
      label: '',
      key: '#CA695D',
      value: '#CA695D'
    }, {
      label: '',
      key: '#D3AE84',
      value: '#D3AE84'
    }, {
      label: '',
      key: '#5EAADF',
      value: '#5EAADF'
    }, {
      label: '',
      key: '#4B850E',
      value: '#4B850E'
    }]
  })
});
export const Paris = Palette({
  id: PaletteIds.ParisFive,
  label: 'reporting-snowflake.colors.palettes.paris-five',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: '#578A8C',
      value: '#578A8C'
    }, {
      label: '',
      key: '#79874A',
      value: '#79874A'
    }, {
      label: '',
      key: '#363D52',
      value: '#363D52'
    }, {
      label: '',
      key: '#DED5E6',
      value: '#DED5E6'
    }, {
      label: '',
      key: '#AFC1D9',
      value: '#AFC1D9'
    }]
  })
});
export const SanFrancisco = Palette({
  id: PaletteIds.SanFranciscoFive,
  label: 'reporting-snowflake.colors.palettes.san-francisco-five',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: '#CB9E74',
      value: '#CB9E74'
    }, {
      label: '',
      key: '#5895B0',
      value: '#5895B0'
    }, {
      label: '',
      key: '#013E55',
      value: '#013E55'
    }, {
      label: '',
      key: '#991801',
      value: '#991801'
    }, {
      label: '',
      key: '#A5A5A0',
      value: '#A5A5A0'
    }]
  })
});
export const Portsmouth = Palette({
  id: PaletteIds.PortsmouthFive,
  label: 'reporting-snowflake.colors.palettes.portsmouth-five',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: '#879E97',
      value: '#879E97'
    }, {
      label: '',
      key: '#D47469',
      value: '#D47469'
    }, {
      label: '',
      key: '#FCA170',
      value: '#FCA170'
    }, {
      label: '',
      key: '#217E9F',
      value: '#217E9F'
    }, {
      label: '',
      key: '#304B8D',
      value: '#304B8D'
    }]
  })
});
export const Ghent = Palette({
  id: PaletteIds.GhentFive,
  label: 'reporting-snowflake.colors.palettes.ghent-five',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: '#5885C9',
      value: '#5885C9'
    }, {
      label: '',
      key: '#F2E2D2',
      value: '#F2E2D2'
    }, {
      label: '',
      key: '#C46B85',
      value: '#C46B85'
    }, {
      label: '',
      key: '#FF8B62',
      value: '#FF8B62'
    }, {
      label: '',
      key: '#63836E',
      value: '#63836E'
    }]
  })
});
export const Ocean = Palette({
  id: PaletteIds.OceanFive,
  label: 'reporting-snowflake.colors.palettes.ocean-five',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: '#00155D',
      value: '#00155D'
    }, {
      label: '',
      key: '#005C87',
      value: '#005C87'
    }, {
      label: '',
      key: '#008C9B',
      value: '#008C9B'
    }, {
      label: '',
      key: '#88DFD7',
      value: '#88DFD7'
    }, {
      label: '',
      key: '#CCF2F0',
      value: '#CCF2F0'
    }]
  })
});
export const Spring = Palette({
  id: PaletteIds.SpringFive,
  label: 'reporting-snowflake.colors.palettes.spring-five',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: '#C44170',
      value: '#C44170'
    }, {
      label: '',
      key: '#F7E0E0',
      value: '#F7E0E0'
    }, {
      label: '',
      key: '#6AACD0',
      value: '#6AACD0'
    }, {
      label: '',
      key: '#A0BC8E',
      value: '#A0BC8E'
    }, {
      label: '',
      key: '#B67D40',
      value: '#B67D40'
    }]
  })
});
export const CherryBlossom = Palette({
  id: PaletteIds.CherryBlossomFive,
  label: 'reporting-snowflake.colors.palettes.cherry-blossom-five',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: '#D2B8C3',
      value: '#D2B8C3'
    }, {
      label: '',
      key: '#8D3E37',
      value: '#8D3E37'
    }, {
      label: '',
      key: '#B6674F',
      value: '#B6674F'
    }, {
      label: '',
      key: '#E8BAAF',
      value: '#E8BAAF'
    }, {
      label: '',
      key: '#845C76',
      value: '#845C76'
    }]
  })
});
export const Pastel = Palette({
  id: PaletteIds.PastelFive,
  label: 'reporting-snowflake.colors.palettes.pastel-five',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: '#EFF1DB',
      value: '#EFF1DB'
    }, {
      label: '',
      key: '#FFD4DB',
      value: '#FFD4DB'
    }, {
      label: '',
      key: '#D3B5E5',
      value: '#D3B5E5'
    }, {
      label: '',
      key: '#B4F8C8',
      value: '#B4F8C8'
    }, {
      label: '',
      key: '#FFE4F8',
      value: '#FFE4F8'
    }]
  })
});
export const DefaultY2 = Palette({
  id: PaletteIds.DefaultY2,
  label: 'reporting-snowflake.colors.palettes.default-y2',
  scheme: DiscreteScheme({
    colors: [{
      label: 'Slinky',
      key: 'slinky',
      value: SLINKY
    }]
  })
});
export const AlertRedToGreen = Palette({
  id: PaletteIds.AlertRedToGreen,
  label: 'reporting-snowflake.colors.palettes.alertRedToGreen',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: CANDY_APPLE,
      value: CANDY_APPLE
    }, {
      label: '',
      key: CANDY_APPLE_MEDIUM,
      value: CANDY_APPLE_MEDIUM
    }, {
      label: '',
      key: MARIGOLD,
      value: MARIGOLD
    }, {
      label: '',
      key: OZ_MEDIUM,
      value: OZ_MEDIUM
    }, {
      label: '',
      key: OZ,
      value: OZ
    }]
  })
});
export const AlertGreenToRed = Palette({
  id: PaletteIds.AlertGreenToRed,
  label: 'reporting-snowflake.colors.palettes.alertGreenToRed',
  scheme: DiscreteScheme({
    colors: [{
      label: '',
      key: OZ,
      value: OZ
    }, {
      label: '',
      key: OZ_MEDIUM,
      value: OZ_MEDIUM
    }, {
      label: '',
      key: MARIGOLD,
      value: MARIGOLD
    }, {
      label: '',
      key: CANDY_APPLE_MEDIUM,
      value: CANDY_APPLE_MEDIUM
    }, {
      label: '',
      key: CANDY_APPLE,
      value: CANDY_APPLE
    }]
  })
});
export const DEFAULT_PALETTES = List.of(DefaultCategorical, PatternFill14, ShadesOfOrange, ShadesOfAqua, ShadesOfPurple, ShadesOfYellow, ShadesOfPink, ShadesOfBlue, ShadesOfGreen, Tokyo, Bogota, Dublin, Cambridge, Singapore, Sydney, Berlin, Paris, SanFrancisco, Portsmouth, Ghent, Ocean, Spring, CherryBlossom, Pastel).toOrderedMap().mapKeys((_, v) => v && v.id);
export const GAUGE_PALETTES = List.of(DefaultCategorical, AlertRedToGreen, AlertGreenToRed, ShadesOfOrange, ShadesOfAqua, ShadesOfPurple, ShadesOfYellow, ShadesOfPink, ShadesOfBlue, ShadesOfGreen, Tokyo, Bogota, Dublin, Cambridge, Singapore, Sydney, Berlin, Paris, SanFrancisco, Portsmouth, Ghent, Ocean, Spring, CherryBlossom, Pastel).toOrderedMap().mapKeys((_, v) => v && v.id);