import { MSG_TYPE_CONFIRM_DELETE_TASK, MSG_TYPE_DELETE_TASK_FAILURE, MSG_TYPE_DELETE_TASK_SUCCESS, MSG_TYPE_REQUEST_CLOSE_DELETE_TASK_MODAL, MSG_TYPE_REQUEST_OPEN_DELETE_TASK_MODAL } from '../constants/IFrameMessageTypes';
export const initialState = {
  failed: false,
  hasCampaignsWriteAccess: true,
  hasCampaignsReadAccess: true,
  loading: false,
  show: false,
  task: undefined,
  taskId: undefined
};
export default function taskDelete(state = initialState, action = {}) {
  switch (action.type) {
    case MSG_TYPE_DELETE_TASK_SUCCESS:
      return Object.assign({}, state, {
        failed: false,
        loading: false,
        show: false,
        task: undefined,
        taskId: undefined
      });
    case MSG_TYPE_DELETE_TASK_FAILURE:
      return Object.assign({}, state, {
        failed: true,
        loading: false
      });
    case MSG_TYPE_CONFIRM_DELETE_TASK:
      return Object.assign({}, state, {
        failed: false,
        loading: true
      });
    case MSG_TYPE_REQUEST_OPEN_DELETE_TASK_MODAL:
      return Object.assign({}, state, {
        show: true,
        task: action.task || state.task,
        taskId: action.taskId || state.taskId
      });
    case MSG_TYPE_REQUEST_CLOSE_DELETE_TASK_MODAL:
      return Object.assign({}, state, {
        show: false
      });
    default:
      return state;
  }
}