'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { SUM, MIN, MAX, AVG, COUNT, PERCENTILES } from '../constants/metricTypes';
const COMBINE = ImmutableMap({
  [SUM]: (a, b) => a.get(SUM, 0) + b.get(SUM, 0),
  [MIN]: (a, b) => Math.min(a.get(MIN, 0), b.get(MIN, 0)),
  [MAX]: (a, b) => Math.max(a.get(MAX, 0), b.get(MAX, 0)),
  [AVG]: () => null,
  [COUNT]: (a, b) => a.get(SUM, 0) + b.get(SUM, 0),
  [PERCENTILES]: () => null
});

/* Combines values of sum from both metrics */
export const combineMetrics = (metricA = ImmutableMap(), metricB = ImmutableMap()) => metricA.merge(metricB).filter((_, type) => COMBINE.has(type)).map((_, type) => COMBINE.get(type)(metricA, metricB));

/* Combines all properties of two Metrics objects */
export const merge = (metricsA = ImmutableMap(), metricsB = ImmutableMap()) => metricsA.merge(metricsB).map((_, property) => combineMetrics(metricsA.get(property, ImmutableMap()), metricsB.get(property, ImmutableMap())));
export const summarize = bucket => {
  if (!bucket.has('dimension')) {
    return bucket;
  }
  const updatedBucket = bucket.updateIn(['dimension', 'buckets'], List(), buckets => buckets.map(summarize));
  const childMetrics = updatedBucket.getIn(['dimension', 'buckets'], List()).map(updated => updated.get('metrics', ImmutableMap()));
  return updatedBucket.update('metrics', metrics => metrics && !metrics.isEmpty() ? metrics : childMetrics.reduce(merge, ImmutableMap()));
};