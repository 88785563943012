'use es6';

import * as http from '../../request/http';
import { Map as ImmutableMap, fromJS } from 'immutable';
import { makeOption } from '../Option';
import I18n from 'I18n';
import toJS from '../../lib/toJS';
import { DEFAULT_NULL_VALUES } from '../../constants/defaultNullValues';
import formatName from 'I18n/utils/formatName';
const url = 'users/v2/app/hub-users/bulk';
const getDefaultOptions = () => ImmutableMap({
  0: makeOption(0, I18n.text('reporting-data.missing.unknown.user'))
});
export const generateUserLabel = (user, key) => {
  if (!user || key === DEFAULT_NULL_VALUES.ENUMERATION) {
    return key && key !== DEFAULT_NULL_VALUES.ENUMERATION ? I18n.text('reporting-data.missing.unknown.userWithId', {
      id: key
    }) : I18n.text('reporting-data.missing.unknown.user');
  }
  user = fromJS(user);
  if (!user.has('label') && !user.has('firstName') && !user.has('lastName') && !user.has('email')) {
    return I18n.text('reporting-data.missing.unknown.userWithId', {
      id: key
    });
  }
  if (user.has('label')) {
    return user.get('label');
  } else {
    /* This case gets hit by some FE resolve reports
     *  such as Sales Analytics reports. Once these
     *  reports are moved to backend resolve, this
     *  portion will be unused and can be cleaned up
     *  since the BE resolve provides this labeling.
     */

    return formatName({
      firstName: user.get('firstName'),
      lastName: user.get('lastName'),
      email: user.get('email')
    });
  }
};
export default (ids => {
  const queryIds = ids.map(Number).filter(val => !!val);
  if (queryIds.length === 0) {
    return Promise.resolve(getDefaultOptions());
  }
  return http.put(url, {
    data: queryIds,
    query: {
      limit: queryIds.length
    }
  }).then(toJS).then(({
    userBriefs
  }) => queryIds.reduce((collect, queryId) => {
    const user = userBriefs.find(userBrief => {
      return userBrief.id === queryId;
    });
    const name = generateUserLabel(user);
    return collect.set(queryId, makeOption(queryId, name));
  }, getDefaultOptions()));
});