'use es6';

import { createSelector } from 'reselect';
import { getIsFreeOrStarterPortal } from 'onboarding-scopes';
import { CONTACT_CREATE_ATTRIBUTION, ATTRIBUTION_TOUCH_POINTS as REVENUE_ATTRIBUTION } from 'reporting-data/constants/dataTypes/inboundDb';
import { getDataType } from 'reporting-data/public/get-data-type';
import { selectAllScopes } from './index';
import { selectHasSalesExplorerExpGate } from './gates';
import { BUSINESS_UNITS_ACCESS, CUSTOM_REPORTING_ACCESS, DASHBOARD_DEMO_WRITE_ACCESS, HUB_MARKETING_PRODUCT, IFRAME_WIDGET_ACCESS, MARKETING_FREE_PRODUCT, MARKETING_STARTER_PRODUCT, REPORTS_SALES_PRO_ACCESS, REPORTS_PAID_ACCESS, REPORTING_ACCESS, REPORTING_ADMIN, REPORTING_CREATE_AND_OWN, REPORTING_DATASETS_ACCESS, REPORTING_EDIT, REVENUE_ATTRIBUTION_ACCESS, REVENUE_ATTRIBUTION_LITE, DEAL_CREATE_ATTRIBUTION_ACCESS, CONTACT_CREATE_ATTRIBUTION_ACCESS, SERVICE_ACCESS, SUPER_ADMIN, SUPER_USER, JITA_USER, PAYMENTS_REPORTING_ACCESS } from '../scope/scope-name';
export const selectUserHasScopes = (...scopes) => createSelector(selectAllScopes, scopesList => scopes.every(scope => scopesList.includes(scope)));
export const selectUserHasSomeScopes = (...scopes) => createSelector(selectAllScopes, scopesList => scopes.some(scope => scopesList.includes(scope)));
export const selectIsSuperAdmin = selectUserHasScopes(SUPER_ADMIN);
export const selectIsJitaUser = selectUserHasScopes(JITA_USER);
export const selectIsSuperUser = selectUserHasScopes(SUPER_USER);
export const selectIsUserAdmin = createSelector(selectUserHasScopes(REPORTING_ADMIN), selectIsSuperAdmin, selectIsSuperUser, (isReportingAdmin, isSuperAdmin, isSuperUser) => isReportingAdmin || isSuperAdmin || isSuperUser);
export const selectHasCreateAndOwnScope = selectUserHasScopes(REPORTING_CREATE_AND_OWN);
export const selectHasCustomReportingAcccess = selectUserHasScopes(CUSTOM_REPORTING_ACCESS);
export const selectHasDashboardDemoWriteScope = selectUserHasScopes(DASHBOARD_DEMO_WRITE_ACCESS);
export const selectHasDatasetsAccess = selectUserHasScopes(REPORTING_DATASETS_ACCESS);
export const selectHasIframeWidgetAccess = selectUserHasScopes(IFRAME_WIDGET_ACCESS);
export const selectHasEditScope = selectUserHasScopes(REPORTING_EDIT);
export const selectHasReportingAccess = selectUserHasScopes(REPORTING_ACCESS);
export const selectHasReportsPaidAccessScope = selectUserHasScopes(REPORTS_PAID_ACCESS);
export const selectHasRevenueAttributionReadScope = selectUserHasScopes(REVENUE_ATTRIBUTION_ACCESS);
export const selectHasRevenueLiteAccess = selectUserHasScopes(REVENUE_ATTRIBUTION_LITE);
export const selectHasRevenueAttributionAccess = createSelector(selectHasRevenueAttributionReadScope, selectHasRevenueLiteAccess, (enterprise, pro) => enterprise || pro);
export const selectHasContactCreateAttributionAccess = selectUserHasScopes(CONTACT_CREATE_ATTRIBUTION_ACCESS);
export const selectHasDealCreateAttributionAccess = selectUserHasScopes(DEAL_CREATE_ATTRIBUTION_ACCESS);
export const getAttributionReportScopeReq = report => {
  const DEAL_CREATE_ATTRIBUTION = '0-63';
  const PRO = 'Pro';
  const ENTERPRISE = 'Enterprise';
  const dataType = getDataType(report);
  return {
    [CONTACT_CREATE_ATTRIBUTION]: PRO,
    [DEAL_CREATE_ATTRIBUTION]: ENTERPRISE,
    [REVENUE_ATTRIBUTION]: ENTERPRISE
  }[dataType];
};
export const selectHasSalesProAccess = selectUserHasScopes(REPORTS_SALES_PRO_ACCESS);
export const selectHasServiceAccess = selectUserHasScopes(SERVICE_ACCESS);
export const selectHasSalesAnalyticsAccess = createSelector(selectHasSalesExplorerExpGate, selectHasSalesProAccess, (hasSalesExplorerExpGate, hasSalesProAccess) => hasSalesExplorerExpGate || hasSalesProAccess);
export const selectIsStarterUpgradeEligible = createSelector(selectUserHasScopes(MARKETING_FREE_PRODUCT), selectUserHasSomeScopes(HUB_MARKETING_PRODUCT, MARKETING_STARTER_PRODUCT, REPORTS_PAID_ACCESS), (hasMarketingFree, hasPaidProduct) => hasMarketingFree && !hasPaidProduct);
export const selectIsProUpgradeEligible = createSelector(selectUserHasScopes(MARKETING_STARTER_PRODUCT), selectUserHasSomeScopes(HUB_MARKETING_PRODUCT, REPORTS_PAID_ACCESS), (hasMarketingFree, isIneligible) => hasMarketingFree && !isIneligible);
export const selectHasBusinessUnitsAccess = selectUserHasScopes(BUSINESS_UNITS_ACCESS);
export const selectHasPaymentsReportingAccess = selectUserHasScopes(PAYMENTS_REPORTING_ACCESS);
export const selectIsFreeOrStarterUser = state => {
  const scopes = selectAllScopes(state);
  return getIsFreeOrStarterPortal(scopes);
};