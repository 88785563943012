import { createSlice } from '@reduxjs/toolkit';
import { TEAMS_HIERARCHY_DATA_NAMESPACE } from './types';
import { fetchTeamHierarchyAsyncThunk } from './thunks';
import { RequestState } from 'dashboard-lib/public/request/request-state';
const initialState = {
  value: [],
  status: RequestState.UNINITIALIZED
};
const teamsHierarchyDataSlice = createSlice({
  name: TEAMS_HIERARCHY_DATA_NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTeamHierarchyAsyncThunk.fulfilled, (state, action) => {
      state.value = action.payload;
      state.status = RequestState.SUCCEEDED;
    });
    builder.addCase(fetchTeamHierarchyAsyncThunk.pending, state => {
      state.status = RequestState.PENDING;
    });
    builder.addCase(fetchTeamHierarchyAsyncThunk.rejected, state => {
      state.status = RequestState.FAILED;
    });
  }
});
export const teamsHierarchyDataReducer = teamsHierarchyDataSlice.reducer;
export const __TESTABLE__ = {
  initialState
};