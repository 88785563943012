import { safe } from './safe';
const NAME = '__REPORTING__';

/* global global */
let globalObj;
try {
  globalObj = window;
} catch (e) {
  // @ts-expect-error this occurs due the use inside our data regression cli/testing tool that runs via node.
  // When this custom process is deprecated this should no longer be required.
  globalObj = global.window;
}
const noop = () => {};
export const initialize = () => {
  if (!(globalObj[NAME] && typeof globalObj[NAME] === 'object')) {
    globalObj[NAME] = {};
  }
};
export const get = safe(key => {
  return globalObj[NAME][key];
}, noop);
export const set = safe((key, value) => {
  globalObj[NAME][key] = value;
  return get(key);
}, noop);
export const update = safe((key, updater) => {
  const current = get(key);
  const graced = safe(updater, () => current);
  set(key, graced(current));
  return get(key);
}, noop);
initialize();