import { DATASET_INSIGHT_DATASET_PREFIX } from '../tsTypes/datasetInsightTypes';
export const DATASET_INSIGHT_PARAMS_PROPERTY = 'insightParams';
export const DATASET_INSIGHT_OPTIONS_PATH = [DATASET_INSIGHT_PARAMS_PROPERTY, 'insightOptions'];
export const findDatasetInsights = datasetMap => {
  const insightDatasets = datasetMap.filter((__, key) => {
    return `${key}`.startsWith(DATASET_INSIGHT_DATASET_PREFIX);
  }).toMap();
  return insightDatasets;
};
export const getDatasetInsightsRelatedDatasetName = key => {
  return key.replace(`${DATASET_INSIGHT_DATASET_PREFIX}-`, '').split('-')[0];
};