'use es6';

import { Sort, SortOrders } from '../../../schema/sort-records';
export const checkIfSortIsAccumulateFriendly = (sorts, xId) => {
  if (sorts.isEmpty()) {
    return true;
  }
  if (xId === undefined) {
    return true;
  }
  const accumulationCompatibleSort = Sort({
    column: xId,
    order: SortOrders.ASCENDING
  });
  return sorts.includes(accumulationCompatibleSort);
};