'use es6';

import { has } from '../../../lib/has';
import { pairs } from './metrics';
import { zero } from './fill';
import { chain } from './chain';
import { one } from './downgrade';
import * as frequency from './frequency';
export const parse = (spec, config) => response => {
  const {
    dimensions,
    metrics = []
  } = config;
  const keys = Object.keys(response);
  const remapped = keys.reduce((mapped, key) => Object.assign({}, mapped, {
    [key]: response[key][0]
  }), {});
  const matrix = {
    dimensions,
    metrics: pairs(config),
    keys: [keys],
    data: keys.map(key => metrics.map(({
      property
    }) => has(remapped, key) ? has(remapped[key], property) ? remapped[key][property] : 0 : 0)),
    total: [keys.length] // TODO: determine count vs. total
  };
  return {
    response,
    matrix
  };
};
export const get = (spec, config, runtimeOptions) => ({
  url: `${spec.url}/summarize/${frequency.get(config)}`,
  parse: chain(zero, parse)(spec, config, runtimeOptions),
  downgrade: one
});