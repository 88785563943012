'use es6';

import { createTracker } from 'usage-tracker';
import events from 'dashboard-components/events.yaml';
const Tracker = createTracker({
  events,
  properties: {
    namespace: 'reporting'
  },
  onError: err => {
    console.error(err);
  } // todo this was copied from DashboardUI - is this needed?
});
export const EVENTS = Object.keys(events).reduce((map, event) => Object.assign({}, map, {
  [event]: event
}), {});
export const track = (key, properties = {}) => {
  Tracker.track(key, properties);
};