'use es6';

import identity from 'transmute/identity';
import isEmpty from 'transmute/isEmpty';
import { cond, isBoolean, isFunction } from '../../private/js-util';
import { isAnd, isNot, isOr } from './type-definition';
import { AND, NOT, OR } from './type-name';

// eslint-disable-next-line prefer-const
export let evaluateLogic;
export const or = (...args) => {
  const [first, ...rest] = args;
  if (isEmpty(rest)) {
    return evaluateLogic(first);
  }
  return evaluateLogic(first) || or(...rest);
};
export const and = (...args) => {
  const [first, ...rest] = args;
  if (isEmpty(rest)) {
    return evaluateLogic(first);
  }
  return evaluateLogic(first) && and(...rest);
};
export const not = k => {
  return !evaluateLogic(k);
};
evaluateLogic = cond([[isAnd, ({
  args
}) => and(...args)], [isOr, ({
  args
}) => or(...args)], [isNot, ({
  k
}) => not(k)], [isFunction, accessExp => accessExp()], [isBoolean, identity]]);
export const evaluateToString = cond([[isAnd, ({
  args
}) => `${AND}(${args.map(evaluateToString).join(',')})`], [isOr, ({
  args
}) => `${OR}(${args.map(evaluateToString).join(',')})`], [isNot, ({
  k
}) => `${NOT}(${evaluateToString(k)})`], [isFunction, fn => `${fn}`], [isBoolean, bool => `${bool}`]]);