'use es6';

import toJS from '../lib/toJS';
import * as checked from '../lib/checked';
import { SEARCH, AGGREGATION, TIME_SERIES, PIPELINE, FUNNEL } from '../constants/configTypes';
import * as CompareTypes from '../constants/compareTypes';
import * as DataTypes from '../constants/dataTypes';
import * as FrequencyTypes from '../constants/frequency';
import { Filters } from './filters';
import { Metrics } from './metrics';
import { Pipeline } from './pipeline';
import { Sorts } from './sort';
import { CrossObject } from './cross-object';
import { EventFunnel } from './event-funnel';
const Compare = checked.symbol(CompareTypes, 'Compare');
const DataType = checked.symbol(DataTypes, 'DataType');
const Frequency = checked.symbol(FrequencyTypes, 'Frequency');
const Dimensions = checked.list(checked.string()).defaultValue([]);
const Processors = checked.list(checked.string()).defaultValue([]);
const CommonDefaults = {
  v2: checked.boolean().defaultValue(false),
  compare: Compare.optional(),
  dataType: DataType.optional(),
  dimensions: Dimensions,
  filters: Filters,
  limit: checked.any(),
  metrics: Metrics,
  offset: checked.any(),
  processors: Processors,
  sort: Sorts,
  objectName: checked.any(),
  customized: checked.boolean().defaultValue(false),
  // Needed for Custom objects
  objectTypeId: checked.string().optional(),
  // NOTE: this is ONLY used to allow for account for reporting-data's caching mechanisms around config
  customSeries: checked.any(),
  unifiedEventTypeName: checked.string().optional()
};
const AggregationRecord = checked.record(Object.assign({}, CommonDefaults, {
  configType: checked.string().always(AGGREGATION),
  crossObject: CrossObject.optional(),
  unifiedEvent: checked.map().optional(),
  useFiscalYearInAggregation: checked.boolean().optional(),
  externalDataTypeSource: checked.map().optional()
}), 'Aggregation');
const TimeSeriesRecord = checked.record(Object.assign({}, CommonDefaults, {
  configType: checked.string().always(TIME_SERIES),
  crossObject: CrossObject.optional(),
  frequency: Frequency.optional(),
  useFiscalYearInAggregation: checked.boolean().optional()
}), 'TimeSeries');
const SearchRecord = checked.record({
  v2: checked.boolean().defaultValue(false),
  configType: checked.string().always(SEARCH),
  crossObject: CrossObject.optional(),
  dataType: DataType.optional(),
  dimensions: Dimensions,
  filters: Filters,
  limit: checked.any(),
  metrics: Metrics,
  offset: checked.any(),
  processors: Processors,
  sort: Sorts,
  customized: checked.boolean().defaultValue(false),
  objectTypeId: checked.string().optional(),
  unifiedEvent: checked.map().optional(),
  externalDataTypeSource: checked.map().optional(),
  // TODO: to be removed https://trello.com/c/C7D5Acua
  properties: checked.list().fromJS().defaultValue([]),
  unifiedEventTypeName: checked.string().optional()
}, 'Search');
const PipelineRecord = checked.record(Object.assign({}, CommonDefaults, {
  eventFunnel: EventFunnel.optional(),
  configType: checked.string().always(PIPELINE),
  pipeline: Pipeline
}), 'Pipeline');
const FunnelRecord = checked.record(Object.assign({}, CommonDefaults, {
  eventFunnel: EventFunnel.optional(),
  configType: checked.string().always(FUNNEL),
  pipeline: Pipeline
}), 'Funnel');
export const Config = (obj = {}) => {
  obj = toJS(obj);

  // a lot of tests fail here because of partial config usage.
  //invariant(obj.configType, `Missing required configType field for ${obj}`);
  if (!obj.configType) {
    obj.configType = AGGREGATION;
  }
  const {
    configType
  } = obj;
  switch (configType) {
    case AGGREGATION:
      return AggregationRecord(obj);
    case TIME_SERIES:
      return TimeSeriesRecord(obj);
    case SEARCH:
      return SearchRecord(obj);
    case PIPELINE:
      return PipelineRecord(obj);
    case FUNNEL:
      return FunnelRecord(obj);
    default:
      throw new Error(`No matching config implementation for ${configType}`);
  }
};