'use es6';

import { fromJS } from 'immutable';
import { DEALS, CONTACTS, ENGAGEMENT, COMPANIES, TICKETS, CONVERSATIONS, CRM_OBJECT } from 'reporting-data/constants/dataTypes';
import propertyNamespaces from 'reporting-data/properties/constants/propertyNamespaces';
const PROPERTIES = fromJS({
  SALES_PERFORMANCE: {
    owners: 'hubspot_owner_id',
    teams: 'hubspot_team_id',
    pipeline: 'pipeline'
  },
  [DEALS]: {
    owners: 'hubspot_owner_id',
    teams: 'hubspot_team_id',
    pipeline: 'pipeline'
  },
  [CONTACTS]: {
    owners: 'hubspot_owner_id',
    teams: 'hubspot_team_id'
  },
  [COMPANIES]: {
    owners: 'hubspot_owner_id',
    teams: 'hubspot_team_id'
  },
  [CRM_OBJECT]: {
    owners: 'hubspot_owner_id',
    teams: 'hubspot_team_id'
  },
  [ENGAGEMENT]: {
    owners: 'engagement.ownerId',
    teams: 'engagement.teamId'
  },
  [TICKETS]: {
    owners: 'hubspot_owner_id',
    teams: 'hubspot_team_id'
  },
  [CONVERSATIONS]: {
    owners: 'engagement.ownerId',
    teams: 'engagement.teamId'
  }
});
export const getOverrideProperty = (overrideKey, dataType) => {
  return PROPERTIES.getIn([dataType, overrideKey]);
};
export const getCrossObjectOverrideProperties = (overrideKey, config) => config.getIn(['crossObject', 'dataTypes']).map(dataType => `${propertyNamespaces[dataType]}_${overrideKey}`);