// @ts-expect-error migrate upstream
import { setupCsat } from 'wootric-nps/csat/csat-setup';
// @ts-expect-error migrate upstream
import { setupNps } from 'wootric-nps/nps/nps-setup';
import Raven from 'raven-js';
const DASHBOARD_KEY = 'REPORT_DASHBOARD_VIEW';
export const triggerCSAT = () => {
  try {
    setupCsat(DASHBOARD_KEY);
  } catch (err) {
    Raven.captureException(err);
  }
};
export const triggerWootricNPS = () => {
  try {
    setupNps();
  } catch (err) {
    Raven.captureException(err);
  }
};
export const triggerCSATSurvey = (timeout, hasSelectHasCSATSurvey, triggeredSurvey, setTriggeredSurvey) => {
  if (!hasSelectHasCSATSurvey || timeout.current !== null || triggeredSurvey) {
    return;
  }
  const CSAT_TIMEOUT = 5000;
  timeout = setTimeout(() => {
    triggerCSAT();
    setTriggeredSurvey(true);
  }, CSAT_TIMEOUT);
};