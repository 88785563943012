'use es6';

import { createProblem } from 'dashboard-lib/private/action-problem-solution/common/problem';
import { getAndCheck } from 'dashboard-lib/private/action-problem-solution/common/get-and-check-param';
import { CUSTOM_DASHBOARD_COUNT, CUSTOM_DASHBOARD_LIMIT, DASHBOARD_CREATED_AT_TIMESTAMP, DASHBOARD_IS_CUSTOM } from 'dashboard-lib/private/action-problem-solution/params/all-params';
import { limitExceeded } from 'dashboard-lib/private/action-problem-solution/common/limits';
import { capture } from 'dashboard-lib/public/sentry/report-management-sentry';
import { STANDARD_DASHBOARD_EXEMPTION } from 'dashboard-lib/public/sentry/sentry-event-id';

/**
 * Dashboards created before or at this timestamps:
 * - WILL NOT be subject to lockdown
 * - for example: legacy standard dashboards created before we imposed the new `dashboard` limit).
 *
 * Dashboards created after this timestamp:
 * - WILL be subject to lockdown
 *
 * TODO update this to the actual effective date
 */
const LOCKDOWN_EFFECTIVE_TIMESTAMP = new Date('May 19, 2020 00:00:00').getTime();
const lockdownIsInEffect = createdAt => {
  return createdAt > LOCKDOWN_EFFECTIVE_TIMESTAMP;
};

/**
 * A flavor of CUSTOM_DASHBOARD_LIMIT_EXCEEDED that also checks if this dashboard should be locked down
 */
const checkCustomDashboardLimitExceededAndDashboardShouldLockdown = params => {
  const customDashboardCount = getAndCheck(CUSTOM_DASHBOARD_COUNT, params);
  const customDashboardLimit = getAndCheck(CUSTOM_DASHBOARD_LIMIT, params);
  const createdAt = getAndCheck(DASHBOARD_CREATED_AT_TIMESTAMP, params);
  const isCustomDashboard = getAndCheck(DASHBOARD_IS_CUSTOM, params);
  if (!lockdownIsInEffect(createdAt) && limitExceeded(customDashboardCount, customDashboardLimit) && !isCustomDashboard) {
    capture(STANDARD_DASHBOARD_EXEMPTION);
  }
  return lockdownIsInEffect(createdAt) && isCustomDashboard && limitExceeded(customDashboardCount, customDashboardLimit);
};
export const CUSTOM_DASHBOARD_LIMIT_EXCEEDED_AND_DASHBOARD_SHOULD_LOCKDOWN = createProblem('CUSTOM_DASHBOARD_LIMIT_EXCEEDED_AND_DASHBOARD_SHOULD_LOCKDOWN', checkCustomDashboardLimitExceededAndDashboardShouldLockdown);