'use es6';

import http from 'hub-http/clients/apiClient';
import { formatSavedFilters } from 'reference-resolvers/formatters/formatSavedFilters';
export const createGetAllSavedFilters = ({
  httpClient
}) => collectionType => () => {
  return httpClient.get(`sales/v2/settings/views/${collectionType}`).then(formatSavedFilters);
};
export const getAllSavedFilters = createGetAllSavedFilters({
  httpClient: http
});