// @ts-expect-error migrate upstream
import { convertFromHTML, convertToHTML } from 'draft-convert';
// @ts-expect-error migrate upstream
import { compose } from 'draft-extend';
import { EditorState } from 'draft-js';
// @ts-expect-error migrate upstream
import AdvancedColorPlugin from 'draft-plugins/plugins/AdvancedColorPlugin';
// @ts-expect-error migrate upstream
import BlockAlignment from 'draft-plugins/plugins/BlockAlignment';
// @ts-expect-error migrate upstream
import BlockStylesDropdown from 'draft-plugins/plugins/BlockStylesDropdown';
// @ts-expect-error migrate upstream
import FontStyles from 'draft-plugins/plugins/FontStyles';
// @ts-expect-error migrate upstream
import InlineStyles from 'draft-plugins/plugins/InlineStyles';
// @ts-expect-error migrate upstream
import LinkPlugin from 'draft-plugins/plugins/link';
// @ts-expect-error migrate upstream
import ListStylesDropdown from 'draft-plugins/plugins/ListStylesDropdown';
// @ts-expect-error migrate upstream
import Separator from 'draft-plugins/plugins/Separator';
// @ts-expect-error migrate upstream
import SizeStyles from 'draft-plugins/plugins/SizeStyles';
// @ts-expect-error migrate upstream
import TokenPlugin from 'draft-plugins/plugins/token/TokenPlugin';
// @ts-expect-error migrate upstream
import ColorPicker from 'react-colorpicker/popover/ColorPickerPopoverBody';
// @ts-expect-error migrate upstream
import VideoPlugin from './draftVideoPlugin/VideoPlugin';
import { DashboardUITracker, EVENTS, track
// @ts-expect-error migrate upstream
} from '../../tracking/DashboardUITracker';

//image plugin
// @ts-expect-error migrate upstream
import BaseImagePlugin from 'draft-plugins/plugins/BaseImagePlugin';
// @ts-expect-error migrate upstream
import createImageButton from 'draft-plugins/components/createImageButton';
import FilePickerPanel from 'file-manager-components/picker/FilePickerPanel';
import configureFileManager from 'file-manager-components/configureFileManager';
import { PickerFileAccessOptions } from 'file-manager-components/constants/fileAccess';
const {
  createWithContent
} = EditorState;
const NODE_NAME = 'span';
const DATA_PROP = 'options';
const DATA_TOKEN = 'data-token';
const DATA_OWNER_ID = 'data-owner-id';
const DATA_AT_MENTION = 'data-at-mention';

/**
 * Convert token to HTML string
 *
 * @private
 */
function tokenToHTML({
  value,
  text
}) {
  const attributes = [DATA_TOKEN, DATA_AT_MENTION, `${DATA_OWNER_ID}="${value}"`].join(' ');
  return `<${NODE_NAME} ${attributes}>${text}</${NODE_NAME}>`;
}

/**
 * Convert HTML string to token
 *
 * @private
 */
function htmlToToken(nodeName, node) {
  if (nodeName === NODE_NAME && node.hasAttribute(DATA_AT_MENTION)) {
    return {
      value: parseInt(node.getAttribute(DATA_OWNER_ID), 10),
      text: node.textContent
    };
  }
  return null;
}

/**
 * At-mention entity type
 *
 * @constant
 */
export const AT_MENTION = Symbol('AT_MENTION');

/**
 * Token plugin configuration
 *
 * @constant
 */
export const tokenPluginConfig = {
  entityType: AT_MENTION,
  dataProp: DATA_PROP,
  tokenToHTML,
  htmlToToken
};
const AdvancedTextColorPlugin = AdvancedColorPlugin({
  ColorPicker
});
const AdvancedBackgrounColorPlugin = AdvancedColorPlugin({
  cssProperty: 'background-color',
  ColorPicker,
  defaultColor: '#fff',
  iconName: 'highlight'
});
const FileManager = configureFileManager({
  uploadedFileAccess: PickerFileAccessOptions.VISIBLE_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE,
  usageTracker: DashboardUITracker
});
const trackRichTextFeature = (action, feature) => {
  track(EVENTS.useRichTextFeature, {
    actionType: 'edit',
    action,
    interactionWith: 'modal',
    interactionSource: 'note-editor',
    object: feature,
    assetType: 'dashboard'
  });
};

/**
 * Draft configurations
 *
 * @constant
 */
export const draftConfig = compose(FontStyles(), BlockStylesDropdown({
  headerStyles: ['header-one', 'header-two', 'header-three', 'header-four']
}, {}), SizeStyles(), Separator, InlineStyles(), Separator, AdvancedTextColorPlugin, AdvancedBackgrounColorPlugin, Separator, BlockAlignment({
  tagName: 'div',
  empty: '<br>',
  tooltipPlacement: 'top'
}), ListStylesDropdown(), Separator, LinkPlugin({
  tooltipPlacement: 'top',
  onOpenPopover: () => {
    trackRichTextFeature('open', 'link');
  },
  onInsertLink: () => {
    trackRichTextFeature('insert', 'link');
  }
}), BaseImagePlugin({
  Button: createImageButton({
    tooltipPlacement: 'top',
    FileManager,
    FilePickerPanel,
    onOpenPopover: () => {
      trackRichTextFeature('open', 'image');
    }
    // for insert image tracking see:
    // event: [File Picker] Manage Files, eventKey: fileManagerManageFiles
    // in-app: reporting, action: insert, drawerType: IMAGE
  })
}), VideoPlugin({
  FileManager,
  FilePickerPanel
}), TokenPlugin(tokenPluginConfig));

/**
 * To HTML transformer
 *
 * @params  {EditorState} state Editor state
 * @returns {function}          To HTML transformer with plugins
 */
export const toHTML = draftConfig(convertToHTML);

/**
 * From HTML transformer
 *
 * @params  {string} html HTML string
 * @returns {function}    From HTML transformer with plugins
 */
export const fromHTML = draftConfig(convertFromHTML);

/**
 * Sanitize HTML string
 *
 * @param   {string} html HTML string
 * @returns {string}      Sanitized HTML string
 */
export const sanitizeHtml = (html = '') => {
  return toHTML(fromHTML(html));
};

/**
 * Get editor state from HTML string
 *
 * @param   {string}      html HTML string
 * @returns {EditorState}      Editor state
 */
export const getEditorState = (html = '') => {
  return createWithContent(fromHTML(html));
};

/**
 * Get HTML string from editor state
 *
 * @param   {EditorState} state Editor state
 * @returns {string}            HTML string
 */
export const getContentHtml = state => {
  return toHTML(state.getCurrentContent());
};

/**
 * Public interface for testable values/methods
 *
 * @constant
 * @private
 * @testable
 */
export const __TESTABLE__ = {
  tokenToHTML,
  htmlToToken
};