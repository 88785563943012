import { userInfoSync } from 'hub-http/userInfo';
import { isIsUngated } from '../types/permissions';

/**
 * UserInfoHelper
 * Lazy singleton class to provide utilities that require information to have been loaded by UserInfo
 *
 * Usage 1:
 * UserInfoHelper.instance().isUngated('test gate name')
 *
 * Usage 2: invocable function reference using bind
 * const helper = UserInfoHelper.instance();
 * const getIsUngated = helper.isUngated.bind(helper);
 * getIsUngated('test gate name');
 */

class UserInfoHelper {
  constructor() {
    try {
      this.userInfo = userInfoSync();
    } catch (err) {
      console.warn('Failure reading userinfo sync:', err);
    }
  }
  static instance() {
    if (!this.instanceCache) {
      this.instanceCache = new this();
    }
    return this.instanceCache;
  }
  static _reset() {
    this.instanceCache = undefined;
  }
  getIsUngated(gate) {
    if (!this.userInfo || !this.userInfo.gates || !Array.isArray(this.userInfo.gates)) {
      console.warn('Failure checking getIsUngated, userinfo may not have been fetched');
      return false;
    }
    return this.userInfo.gates.includes(gate);
  }

  /* Room to expand functionality for scopes, limits, etc. */
}
const resolveIsUngated = providedIsUngated => {
  if (isIsUngated(providedIsUngated)) {
    return providedIsUngated;
  }
  const helper = UserInfoHelper.instance();
  return helper.getIsUngated.bind(helper);
};
export { UserInfoHelper, resolveIsUngated };