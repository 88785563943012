import * as chartTypes from '../../constants/chartTypes';
import { DEAL_JOURNEY_ANALYTICS_READ, JOURNEY_ANALYTICS_READ_SCOPE } from '../../constants/scopes';
import { canConvertToTableReport } from '../../lib/convertToTableReport';
import { isConfigReport, isCustomWidgetReport, isJourneyReport, isMultiConfigReport } from '../../tsTypes/reportTypes';
import { isSearchReport } from '../configReportGetters';
import { getJourneyObjectTypeId } from '../journeyReportGetters';
import { getReportChartType, hasVisualization } from '../reportGetters';
import { configReportHasAtLeastOneDimension, configReportValidForNonSeriesTransformation, isReportValidForColumnJourneyTransform } from './reportValidationForTransformations';
import { setChartTypeToTable, transformToNonSeriesChartType, transformToSeriesChartType } from './utils';
export const VALID_JOURNEY_REPORT_CHART_TYPE_TRANSFORMATIONS = [chartTypes.COLUMN, chartTypes.FUNNEL, chartTypes.SANKEY, chartTypes.TABLE];
export const VALID_SO_REPORT_CHART_TYPE_TRANSFORMATIONS = [chartTypes.AREA, chartTypes.BAR, chartTypes.COLUMN, chartTypes.DATA_WELL, chartTypes.DONUT, chartTypes.LINE, chartTypes.PIE, chartTypes.TABLE];
const ALL_VALID_CHART_TYPE_TRANSFORMATIONS = [...VALID_SO_REPORT_CHART_TYPE_TRANSFORMATIONS, ...VALID_JOURNEY_REPORT_CHART_TYPE_TRANSFORMATIONS];
const SANKEY_OBJECT_SCOPES = {
  '0-1': JOURNEY_ANALYTICS_READ_SCOPE,
  '0-3': DEAL_JOURNEY_ANALYTICS_READ
};
export const getIsConfigReportChartTypeValidForChartTypeTransform = report => VALID_SO_REPORT_CHART_TYPE_TRANSFORMATIONS.includes(getReportChartType(report) || '');
export const CHART_TRANSFORMATION_MAP = {
  [chartTypes.AREA]: {
    isReportValidForChartTypeTransformation: configReportHasAtLeastOneDimension,
    transformFunction: transformToSeriesChartType(chartTypes.AREA)
  },
  [chartTypes.BAR]: {
    isReportValidForChartTypeTransformation: configReportHasAtLeastOneDimension,
    transformFunction: transformToSeriesChartType(chartTypes.BAR)
  },
  [chartTypes.COLUMN]: {
    isReportValidForChartTypeTransformation: report => configReportHasAtLeastOneDimension(report) || isReportValidForColumnJourneyTransform(report),
    transformFunction: transformToSeriesChartType(chartTypes.COLUMN)
  },
  [chartTypes.DATA_WELL]: {
    isReportValidForChartTypeTransformation: configReportValidForNonSeriesTransformation,
    transformFunction: transformToNonSeriesChartType(chartTypes.DATA_WELL)
  },
  [chartTypes.DONUT]: {
    isReportValidForChartTypeTransformation: configReportValidForNonSeriesTransformation,
    transformFunction: transformToNonSeriesChartType(chartTypes.DONUT)
  },
  [chartTypes.FUNNEL]: {
    isReportValidForChartTypeTransformation: isJourneyReport,
    transformFunction: transformToNonSeriesChartType(chartTypes.FUNNEL)
  },
  [chartTypes.LINE]: {
    isReportValidForChartTypeTransformation: configReportHasAtLeastOneDimension,
    transformFunction: transformToSeriesChartType(chartTypes.LINE)
  },
  [chartTypes.PIE]: {
    isReportValidForChartTypeTransformation: configReportValidForNonSeriesTransformation,
    transformFunction: transformToNonSeriesChartType(chartTypes.PIE)
  },
  [chartTypes.SANKEY]: {
    isReportValidForChartTypeTransformation: (report, scopes) => {
      if (!isJourneyReport(report)) {
        return false;
      }
      const journeyObjectTypeId = getJourneyObjectTypeId(report);
      const requiredScope = SANKEY_OBJECT_SCOPES[journeyObjectTypeId];
      return !!requiredScope && scopes.includes(requiredScope);
    },
    transformFunction: transformToNonSeriesChartType(chartTypes.SANKEY)
  },
  [chartTypes.TABLE]: {
    isReportValidForChartTypeTransformation: report => configReportHasAtLeastOneDimension(report) && canConvertToTableReport(report) || isJourneyReport(report),
    transformFunction: setChartTypeToTable
  }
};
export const getIsReportValidForChartTypeTransformation = report => {
  if (isCustomWidgetReport(report) || isMultiConfigReport(report) || hasVisualization(report) || isConfigReport(report) && isSearchReport(report)) {
    return false;
  }
  if (isConfigReport(report)) {
    return VALID_SO_REPORT_CHART_TYPE_TRANSFORMATIONS.includes(getReportChartType(report) || '');
  } else if (isJourneyReport(report)) {
    return VALID_JOURNEY_REPORT_CHART_TYPE_TRANSFORMATIONS.includes(getReportChartType(report) || '');
  }
  return false;
};
export const transformReportChartType = (report, chartType) => {
  const transformConfig = CHART_TRANSFORMATION_MAP[chartType];
  if (!transformConfig || !getIsReportValidForChartTypeTransformation(report)) {
    return null;
  }
  return transformConfig.transformFunction(report);
};
const DEFAULT_EXCLUDED_CHART_TYPES = [];
export const getValidChartTypesForReport = (report, scopes, excludedChartTypes = DEFAULT_EXCLUDED_CHART_TYPES) => {
  if (!getIsReportValidForChartTypeTransformation(report)) {
    return [];
  }
  return Object.keys(CHART_TRANSFORMATION_MAP).filter(chartType => !excludedChartTypes.includes(chartType) && CHART_TRANSFORMATION_MAP[chartType].isReportValidForChartTypeTransformation(report, scopes));
};
export const getChartTypesForReportChartTypeTransforms = (report, scopes, excludedChartTypes = DEFAULT_EXCLUDED_CHART_TYPES) => {
  const reportChartType = getReportChartType(report);
  const validChartTypes = getValidChartTypesForReport(report, scopes, excludedChartTypes);
  if (reportChartType && !validChartTypes.includes(reportChartType)) {
    validChartTypes.unshift(reportChartType);
  }
  return validChartTypes;
};
export const getIsReportValidForChartType = (report, transformType, scopes) => {
  if (!getIsReportValidForChartTypeTransformation(report)) {
    return false;
  }
  return CHART_TRANSFORMATION_MAP[transformType].isReportValidForChartTypeTransformation(report, scopes);
};