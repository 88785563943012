import { createSlice } from '@reduxjs/toolkit';
import { refreshAllReportsOnDashboardAsyncThunk } from './thunks';
import { REPORT_DATA_NAMESPACE } from './types';
import { RequestState } from '../../filter/requests/request-state';
import { selectFetchedDashboard } from '../dashboardDataSlice';
function refreshReportWithStatus(stateToUpdate) {
  return (state, action) => {
    const {
      resourceType,
      resourceId,
      reportId
    } = action.payload;
    if (state[resourceType][resourceId] && state[resourceType][resourceId].refreshStatus) {
      state[resourceType][resourceId].refreshStatus[reportId] = stateToUpdate;
    } else {
      state[resourceType][resourceId] = {
        refreshStatus: {
          [reportId]: stateToUpdate
        }
      };
    }
  };
}
const initialState = {
  ['dashboard']: {},
  ['dashboardTemplate']: {}
};
function updateRefreshDashboardStatus(state, resourceType, resourceId, stateToUpdate) {
  const resource = state[resourceType];
  if (!resource[resourceId]) {
    resource[resourceId] = {
      refreshStatus: {}
    };
  }
  resource[resourceId].dashboardRefreshStatus = stateToUpdate;
}
const reportDataSlice = createSlice({
  name: REPORT_DATA_NAMESPACE,
  initialState,
  reducers: {
    refreshReportStart: refreshReportWithStatus(RequestState.PENDING),
    refreshReportSuccess: refreshReportWithStatus(RequestState.SUCCEEDED),
    refreshReportFailure: refreshReportWithStatus(RequestState.FAILED)
  },
  extraReducers: builder => {
    builder.addCase(refreshAllReportsOnDashboardAsyncThunk.pending, (state, action) => {
      const {
        arg: {
          resourceId,
          resourceType
        }
      } = action.meta;
      updateRefreshDashboardStatus(state, resourceType, resourceId, RequestState.PENDING);
    });
    builder.addCase(refreshAllReportsOnDashboardAsyncThunk.fulfilled, (state, action) => {
      const {
        arg: {
          resourceId,
          resourceType
        }
      } = action.meta;
      updateRefreshDashboardStatus(state, resourceType, resourceId, RequestState.SUCCEEDED);
    });
    builder.addCase(refreshAllReportsOnDashboardAsyncThunk.rejected, (state, action) => {
      const {
        arg: {
          resourceId,
          resourceType
        }
      } = action.meta;
      updateRefreshDashboardStatus(state, resourceType, resourceId, RequestState.FAILED);
    });
  }
});
export const refreshAllReportsOnDashboard = (resourceId, resourceType) => async (dispatch, getState) => {
  const state = getState();
  const dashboard = selectFetchedDashboard(resourceId)(state);
  if (!dashboard) {
    return;
  }
  await dispatch(refreshAllReportsOnDashboardAsyncThunk({
    dashboard,
    resourceId,
    resourceType
  }));
};
export const __TESTABLE__ = {
  initialState
};
export const {
  refreshReportStart,
  refreshReportSuccess,
  refreshReportFailure
} = reportDataSlice.actions;
export default reportDataSlice.reducer;