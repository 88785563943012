import { fromJS } from 'immutable';
import PortalIdParser from 'PortalIdParser';
import * as ObjectTypes from '../objectTypes';
export const HS_OBJECT_ID = 'hs_object_id';
const namedObjectTypeUrls = {
  [ObjectTypes.CONTACT]: 'contact',
  [ObjectTypes.COMPANY]: 'company',
  [ObjectTypes.DEAL]: 'deal',
  [ObjectTypes.TICKET]: 'ticket',
  [ObjectTypes.MARKETING_EMAIL]: 'email'
};
const LinkableObjects = {
  [ObjectTypes.CONTACT]: ObjectTypes.CONTACT,
  [ObjectTypes.COMPANY]: ObjectTypes.COMPANY,
  [ObjectTypes.DEAL]: ObjectTypes.DEAL,
  [ObjectTypes.TICKET]: ObjectTypes.TICKET,
  [ObjectTypes.CONVERSATION]: ObjectTypes.CONVERSATION,
  [ObjectTypes.CALL]: ObjectTypes.CALL,
  [ObjectTypes.CALL_MENTIONED_KEYWORD]: ObjectTypes.CALL_MENTIONED_KEYWORD,
  [ObjectTypes.MARKETING_EMAIL]: ObjectTypes.MARKETING_EMAIL,
  [ObjectTypes.V2_CONTACT_REPLIED_SEQUENCE_EMAIL_QA]: ObjectTypes.V2_CONTACT_REPLIED_SEQUENCE_EMAIL_QA,
  [ObjectTypes.V2_CONTACT_REPLIED_SEQUENCE_EMAIL_PROD]: ObjectTypes.V2_CONTACT_REPLIED_SEQUENCE_EMAIL_PROD,
  [ObjectTypes.V2_CONTACT_BOOKED_MEETING_THROUGH_SEQUENCE_QA]: ObjectTypes.V2_CONTACT_BOOKED_MEETING_THROUGH_SEQUENCE_QA,
  [ObjectTypes.V2_CONTACT_BOOKED_MEETING_THROUGH_SEQUENCE_PROD]: ObjectTypes.V2_CONTACT_BOOKED_MEETING_THROUGH_SEQUENCE_PROD,
  [ObjectTypes.V2_CONTACT_UNSUBSCRIBED_SEQUENCE_EMAIL_QA]: ObjectTypes.V2_CONTACT_UNSUBSCRIBED_SEQUENCE_EMAIL_QA,
  [ObjectTypes.V2_CONTACT_UNSUBSCRIBED_SEQUENCE_EMAIL_PROD]: ObjectTypes.V2_CONTACT_UNSUBSCRIBED_SEQUENCE_EMAIL_PROD,
  [ObjectTypes.V2_CONTACT_UNENROLLED_MANUALLY_FROM_SEQUENCE_QA]: ObjectTypes.V2_CONTACT_UNENROLLED_MANUALLY_FROM_SEQUENCE_QA,
  [ObjectTypes.V2_CONTACT_UNENROLLED_MANUALLY_FROM_SEQUENCE_PROD]: ObjectTypes.V2_CONTACT_UNENROLLED_MANUALLY_FROM_SEQUENCE_PROD,
  [ObjectTypes.V2_CONTACT_UNENROLLED_FROM_SEQUENCE_QA]: ObjectTypes.V2_CONTACT_UNENROLLED_FROM_SEQUENCE_QA,
  [ObjectTypes.V2_CONTACT_UNENROLLED_FROM_SEQUENCE_PROD]: ObjectTypes.V2_CONTACT_UNENROLLED_FROM_SEQUENCE_PROD,
  [ObjectTypes.V2_CONTACT_ENROLLED_IN_SEQUENCE_QA]: ObjectTypes.V2_CONTACT_ENROLLED_IN_SEQUENCE_QA,
  [ObjectTypes.V2_CONTACT_ENROLLED_IN_SEQUENCE_PROD]: ObjectTypes.V2_CONTACT_ENROLLED_IN_SEQUENCE_PROD,
  [ObjectTypes.V2_CONTACT_FINISHED_SEQUENCE_QA]: ObjectTypes.V2_CONTACT_FINISHED_SEQUENCE_QA,
  [ObjectTypes.V2_CONTACT_FINISHED_SEQUENCE_PROD]: ObjectTypes.V2_CONTACT_FINISHED_SEQUENCE_PROD,
  [ObjectTypes.V2_SEQUENCE_ERRORED_PROD]: ObjectTypes.V2_SEQUENCE_ERRORED_PROD,
  [ObjectTypes.V2_SEQUENCE_ERRORED_QA]: ObjectTypes.V2_SEQUENCE_ERRORED_QA,
  [ObjectTypes.V2_SEQUENCE_STEP_EXECUTED_QA]: ObjectTypes.V2_SEQUENCE_STEP_EXECUTED_QA,
  [ObjectTypes.V2_SEQUENCE_STEP_EXECUTED_PROD]: ObjectTypes.V2_SEQUENCE_STEP_EXECUTED_PROD,
  [ObjectTypes.V2_SEQUENCE_EMAIL_BOUNCED_PROD]: ObjectTypes.V2_SEQUENCE_EMAIL_BOUNCED_PROD,
  [ObjectTypes.V2_SEQUENCE_EMAIL_BOUNCED_QA]: ObjectTypes.V2_SEQUENCE_EMAIL_BOUNCED_QA
};
export const getRecordLink = (objectTypeId, id) => {
  const objectName = objectTypeId in namedObjectTypeUrls && namedObjectTypeUrls[objectTypeId];
  if (objectName) {
    return `/contacts/${PortalIdParser.get()}/${objectName}/${id}/`;
  }
  return `/contacts/${PortalIdParser.get()}/record/${objectTypeId}/${id}/`;
};
export const getConversationsLink = (objectTypeId, id) => `/live-messages/${PortalIdParser.get()}?cvObjectId=${id}`;
export const getConversationsThreadLink = (objectTypeId, id) => `/live-messages/${PortalIdParser.get()}/inbox/${id}`;
export const getCallLink = (objectTypeId, id) => `/calls/${PortalIdParser.get()}/review/${id}`;
export const getSequenceLink = (objectTypeId, id) => `/sequences/${PortalIdParser.get()}/sequence/${id}`;
export const getTaskLink = (objectTypeId, id) => `/tasks/${PortalIdParser.get()}/view/all/task/${id}`;
export const getMarketingEmailLink = (objectTypeId, id) => `/email/${PortalIdParser.get()}/details/${id}/performance`;
export const LINKS = fromJS({
  [ObjectTypes.CONTACT]: {
    [HS_OBJECT_ID]: getRecordLink
  },
  [ObjectTypes.COMPANY]: {
    [HS_OBJECT_ID]: getRecordLink,
    hs_parent_company_id: getRecordLink
  },
  [ObjectTypes.DEAL]: {
    [HS_OBJECT_ID]: getRecordLink
  },
  [ObjectTypes.TICKET]: {
    hs_ticket_id: getRecordLink,
    [HS_OBJECT_ID]: getRecordLink
  },
  [ObjectTypes.CONVERSATION]: {
    [HS_OBJECT_ID]: getConversationsLink,
    hs_thread_id: getConversationsThreadLink
  },
  [ObjectTypes.TASK]: {
    [HS_OBJECT_ID]: getTaskLink
  },
  [ObjectTypes.CALL]: {
    [HS_OBJECT_ID]: getCallLink
  },
  [ObjectTypes.CALL_MENTIONED_KEYWORD]: {
    __hs_event_native_object_id: getCallLink
  },
  [ObjectTypes.MARKETING_EMAIL]: {
    ['hs_origin_asset_id']: getMarketingEmailLink
  },
  [ObjectTypes.V2_CONTACT_REPLIED_SEQUENCE_EMAIL_QA]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_CONTACT_REPLIED_SEQUENCE_EMAIL_PROD]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_CONTACT_BOOKED_MEETING_THROUGH_SEQUENCE_QA]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_CONTACT_BOOKED_MEETING_THROUGH_SEQUENCE_PROD]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_CONTACT_UNSUBSCRIBED_SEQUENCE_EMAIL_QA]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_CONTACT_UNSUBSCRIBED_SEQUENCE_EMAIL_PROD]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_CONTACT_UNENROLLED_MANUALLY_FROM_SEQUENCE_QA]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_CONTACT_UNENROLLED_MANUALLY_FROM_SEQUENCE_PROD]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_CONTACT_UNENROLLED_FROM_SEQUENCE_QA]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_CONTACT_UNENROLLED_FROM_SEQUENCE_PROD]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_CONTACT_ENROLLED_IN_SEQUENCE_QA]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_CONTACT_ENROLLED_IN_SEQUENCE_PROD]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_CONTACT_FINISHED_SEQUENCE_QA]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_CONTACT_FINISHED_SEQUENCE_PROD]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_SEQUENCE_ERRORED_PROD]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_SEQUENCE_ERRORED_QA]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_SEQUENCE_STEP_EXECUTED_QA]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_SEQUENCE_STEP_EXECUTED_PROD]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_SEQUENCE_EMAIL_BOUNCED_PROD]: {
    hs_sequence_id: getSequenceLink
  },
  [ObjectTypes.V2_SEQUENCE_EMAIL_BOUNCED_QA]: {
    hs_sequence_id: getSequenceLink
  }
});