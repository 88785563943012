'use es6';

const computeCalculatedMetrics = (breakdown = {}) => {
  const {
    visits = 0,
    visitors = 0
  } = breakdown;
  return Object.assign({}, breakdown, {
    returningVisits: visits - visitors // (visitors means new visits)
  });
};
const calculateForBreakdown = response => Object.assign({}, response, {
  totals: computeCalculatedMetrics(response.totals || {}),
  breakdowns: (response.breakdowns || []).map(computeCalculatedMetrics)
});
const calculateForPoints = response => Object.keys(response).reduce((recalculated, date) => Object.assign({}, recalculated, {
  [date]: response[date].map(computeCalculatedMetrics)
}), {});
export const preprocess = (spec, config) => response => {
  const {
    dimensions: [primaryDimension] = []
  } = config;
  return primaryDimension === 'sessionDate' ? calculateForPoints(response) : calculateForBreakdown(response);
};