import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDataSourceTopProperties } from '../filter/data/DataSourcePropertiesDAO';
import { RequestState } from '../filter/requests/request-state';
import { isFrameworkDataSource } from '../filter/utils/dataSourceHelpers';
import { selectDataSourcesInfo, selectDataSourcesInfoStatus } from '../filter/utils/dataSourceInfoLogic';
import { selectIfUserHasCustomReportAccess } from './userInfoSlice';
// Selectors
export const selectDataSourcesTopProperties = dataSources => state => selectDataSourcesInfo({
  dataSources,
  valueName: 'topProperties'
})(state.dataSourceTopProperties);
export const selectDataSourcesTopPropertiesStatus = dataSources => state => selectDataSourcesInfoStatus({
  dataSources
})(state.dataSourceTopProperties);
export const selectFetchTopPropertiesList = dataSources => ({
  dataSourceTopProperties: state
}) => {
  return dataSources.filter(dataSource => !state[dataSource]);
};
export const fetchTopPropertiesAsyncThunk = createAsyncThunk('dataSourceTopProperties/fetch', async ({
  dataSource,
  hasCustomReportingAccess
}) => {
  const topPropertiesPromise = isFrameworkDataSource(dataSource) && hasCustomReportingAccess ? getDataSourceTopProperties(dataSource) : Promise.resolve([]);
  try {
    const topProperties = await topPropertiesPromise;
    return topProperties;
  } catch (error) {
    return error;
  }
});
export const dataSourceTopPropertiesSlice = createSlice({
  name: 'dataSourceTopProperties',
  initialState: {},
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTopPropertiesAsyncThunk.pending, (state, action) => {
      const {
        arg
      } = action.meta;
      const {
        dataSource
      } = arg;
      state[dataSource] = {
        status: RequestState.PENDING
      };
    });
    builder.addCase(fetchTopPropertiesAsyncThunk.fulfilled, (state, action) => {
      const {
        arg
      } = action.meta;
      const topProperties = action.payload;
      const {
        dataSource
      } = arg;
      state[dataSource] = {
        status: RequestState.SUCCEEDED,
        topProperties
      };
    });
    builder.addCase(fetchTopPropertiesAsyncThunk.rejected, (state, action) => {
      const {
        arg
      } = action.meta;
      const {
        dataSource
      } = arg;
      state[dataSource] = {
        status: RequestState.FAILED
      };
    });
  }
});

// Actions
export const fetchDataSourcesTopProperties = dataSources => (dispatch, getState) => {
  const state = getState();
  const hasCustomReportingAccess = selectIfUserHasCustomReportAccess(state);
  selectFetchTopPropertiesList(dataSources)(state).forEach(dataSource => {
    dispatch(fetchTopPropertiesAsyncThunk({
      dataSource,
      hasCustomReportingAccess
    }));
  });
};
export default dataSourceTopPropertiesSlice.reducer;