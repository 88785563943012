import { DEFAULT_MODAL_TRANSITION_TIMING } from 'HubStyleTokens/times';
import { getAttachedElement } from './elementUtils';

/**
 * Returns the delay needed due to the animation of a modal opening - attempting to attach to an animating modal
 * can have unpredictable results. The DEFAULT_MODAL_TRANSITION_TIMING is a string ending in 'ms'.
 */
function getAnimationDelay() {
  const timeString = DEFAULT_MODAL_TRANSITION_TIMING;
  return parseInt(timeString.slice(0, timeString.length - 2), 10) + 100;
}
/**
 * Watches DOM changes for when a specificed elementQuery is matched.
 *
 * @return a promise with the element
 */
export const waitForElementToAppear = ({
  elementQuery,
  elementGetter,
  requiresModalAnimation = false,
  targetDocument = document,
  logger = console.debug
}) => {
  const attachTo = elementQuery || elementGetter;
  const logItem = typeof attachTo === 'function' ? attachTo.toString() : attachTo;
  logger('Waiting for element: ', logItem);
  const maybeElement = getAttachedElement(attachTo, targetDocument);
  if (maybeElement) {
    return Promise.resolve(maybeElement);
  }
  return new Promise(resolve => {
    const observer = new MutationObserver(() => {
      const maybeAppearedElement = getAttachedElement(attachTo, targetDocument);
      if (maybeAppearedElement) {
        logger('Element is visible: ', attachTo);
        if (requiresModalAnimation) {
          setTimeout(() => resolve(maybeAppearedElement), getAnimationDelay());
        } else {
          resolve(maybeAppearedElement);
        }
        observer.disconnect();
      }
    });
    observer.observe(targetDocument, {
      attributes: true,
      childList: true,
      characterData: false,
      subtree: true
    });
  });
};