import { useCallback } from 'react';

// @ts-expect-error Untyped import
import getProperties from '../properties';
import { useAsyncFetcher } from '../asyncData/useAsyncFetcher';
const useFetchPropertyMetaData = properties => {
  const resultFormatter = useCallback(propertyMetaDataFetchList => {
    return properties.reduce((acc, reportProperty) => {
      var _propertyMetaDataFetc;
      const {
        dataType,
        property
      } = reportProperty;
      const metaDataForAllPropertiesInDataType = (_propertyMetaDataFetc = propertyMetaDataFetchList.find(fetch => !!fetch.get(dataType))) === null || _propertyMetaDataFetc === void 0 ? void 0 : _propertyMetaDataFetc.get(dataType);
      const metaDataForProperty = metaDataForAllPropertiesInDataType === null || metaDataForAllPropertiesInDataType === void 0 ? void 0 : metaDataForAllPropertiesInDataType.get(property === 'objectid' ? 'hs_object_id' : property);
      if (!acc[dataType]) {
        acc[dataType] = {};
      }
      if (!metaDataForProperty) {
        return acc;
      }
      acc[dataType][property] = metaDataForProperty.toJS();
      return acc;
    }, {});
  }, [properties]);
  const fetcher = useCallback(() => {
    const allDataTypes = new Set(properties.map(property => property.dataType));
    const dataFetches = [...allDataTypes].map(dataType => getProperties(dataType));
    return Promise.all(dataFetches);
  }, [properties]);
  return useAsyncFetcher({
    fetcher,
    resultFormatter
  });
};
export default useFetchPropertyMetaData;