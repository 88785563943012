'use es6';

import { fromJS } from 'immutable';
import { Promise } from '../../../lib/promise';
import prefix from '../../../lib/prefix';
import { EXPERIMENTAL_REVENUE } from '../../../constants/dataTypes';
import getCountProperty from '../../partial/count-property';
import createPropertiesGetterFromGroups from '../../createPropertiesGetterFromGroups';
const translate = prefix('reporting-data.properties.recurring-revenue');
const translateGroup = prefix('reporting-data.groups.recurring-revenue');
export const getPropertyGroups = () => Promise.resolve(fromJS([{
  name: 'experimentalRevenueInfo',
  displayName: translateGroup('group'),
  displayOrder: 0,
  hubspotDefined: true,
  properties: [{
    name: 'Revenue',
    label: translate('date'),
    type: 'datetime'
  }, {
    name: 'recurring_revenue',
    label: translate('recurringRevenue'),
    type: 'datetime'
  }, {
    name: 'lostRecurringRevenue',
    label: translate('lostRecurringRevenue'),
    type: 'currency'
  }, {
    name: 'newRecurringRevenue',
    label: translate('newRecurringRevenue'),
    type: 'currency'
  }, {
    name: 'existingRecurringRevenue',
    label: translate('existingRecurringRevenue'),
    type: 'currency'
  }]
}]));
export const getProperties = createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(getCountProperty(EXPERIMENTAL_REVENUE)));