import { Record } from 'immutable';
export const UserSetType = {
  SINGLE_USER: 'SINGLE_USER',
  TEAM: 'TEAM',
  API_ACCESS: 'API_ACCESS'
};
export const ReportingAssetObjectType = {
  DASHBOARD: 'DASHBOARD',
  REPORT: 'REPORT'
};

/** from https://git.hubteam.com/HubSpot/Dashboard/blob/master/DashboardBase/src/main/java/com/hubspot/dashboard/permissioning/PermissionConfigCore.java#L9 */

// TODO migrate away from immutablejs. Have dto resemble https://git.hubteam.com/HubSpot/Dashboard/blob/master/DashboardBase/src/main/java/com/hubspot/dashboard/permissioning/request/PartitionTargetIF.java#L11
const ImmutablePermissionGrant = Record({
  userSetType: UserSetType.SINGLE_USER,
  userSetId: -1
}, 'PermissionGrant');
export const createPermissionGrant = (type, id) => ImmutablePermissionGrant({
  userSetType: type,
  userSetId: id
});