import * as checked from 'reporting-data/lib/checked';
export const DataSourceTypes = {
  HUBSPOT_OBJECT: 'HUBSPOT_OBJECT',
  HUBSPOT_DATASET: 'HUBSPOT_DATASET',
  HUBSPOT_GLOBAL_DATASET: 'HUBSPOT_GLOBAL_DATASET',
  HUBSPOT_EVENT: 'HUBSPOT_EVENT',
  EVENTS_DIGEST: 'EVENTS_DIGEST',
  EXTERNAL_DATASOURCE: 'EXTERNAL_DATASOURCE',
  VIRTUAL_DATASOURCE: 'VIRTUAL_DATASOURCE'
};
export const DataSourceType = checked.symbol(DataSourceTypes, 'DataSourceType');