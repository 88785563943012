'use es6';

import { COMPANY_PROPERTIES } from 'reference-resolvers/constants/CacheKeys';
import { getCompanyProperties, createGetCompanyProperties } from 'reference-resolvers/api/PropertiesAPI';
import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
export const createCompanyPropertyReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: COMPANY_PROPERTIES,
  createFetchData: createGetCompanyProperties,
  fetchData: getCompanyProperties
}, options));
export default createCompanyPropertyReferenceResolver();