import PortalIdParser from 'PortalIdParser';
export const Formats = {
  CSV: 'csv',
  XLS: 'xls',
  XLSX: 'xlsx'
};
export const ExportLimits = {
  XLS: 65000,
  XLSX: 1000000
};
export const FormatValues = Object.values(Formats || {});
export const DefaultFieldKeys = {
  EMAIL: 'email',
  FORMAT: 'format'
};
export const EmailValidationStateNames = ['VALID', 'INVALID'];
export const ExportPreferences = {
  SEND: 'SEND',
  DO_NOT_SEND: 'DO_NOT_SEND'
};
export const Urls = {
  allowlistUrl: 'https://knowledge.hubspot.com/articles/KCS_Article/Email/What-steps-can-I-take-to-ensure-HubSpot-emails-get-delivered-to-my-inbox',
  notificationUrl: 'https://knowledge.hubspot.com/articles/kcs_article/messages/why-am-i-not-receiving-push-notifications-for-messages-in-my-browser',
  notificationCenterUrl: `/notification-preferences/${PortalIdParser.get()}`,
  exportAuditUrl: `/sales-products-settings/${PortalIdParser.get()}/importexport`
};