import { DAY, WEEK, MONTH, QUARTER, YEAR } from 'customer-data-filters/filterQueryFormat/rollingDates/TimeUnits';
import { AfterDate, BeforeDate, Equal, InRange, InRollingDateRange
// @ts-expect-error ts-migrate(2307)
} from 'customer-data-filters/filterQueryFormat/operator/Operators';
import { FORWARD } from 'customer-data-filters/filterQueryFormat/rollingDates/RollingDateDirections';
// @ts-expect-error ts-migrate(2307)
import makeDateRangeByType from 'reporting-data/lib/makeDateRangeByType';
import { RANGE_TYPES } from 'reporting-data/constants/dateRangeTypes';
export const GENERIC_DATE_RANGE_PROPERTY_NAME = '__DATE_RANGE__';
export const isGenericDateRangeProperty = propertyName => propertyName === GENERIC_DATE_RANGE_PROPERTY_NAME;
const DateRangeOperatorToSORangeType = {
  [Equal]: RANGE_TYPES.IS_EQUAL_TO,
  [AfterDate]: RANGE_TYPES.IS_AFTER_DATE,
  [BeforeDate]: RANGE_TYPES.IS_BEFORE_DATE,
  [InRange]: RANGE_TYPES.CUSTOM
};
const THIS_UNIT = 'THIS_UNIT';
const NEXT_UNIT = 'NEXT_UNIT';
const LAST_UNIT = 'LAST_UNIT';
const TimeUnitToRangeType = {
  [DAY]: {
    [THIS_UNIT]: RANGE_TYPES.THIS_DAY,
    [NEXT_UNIT]: RANGE_TYPES.NEXT_DAY,
    [LAST_UNIT]: RANGE_TYPES.LAST_DAY
  },
  [WEEK]: {
    [THIS_UNIT]: RANGE_TYPES.THIS_WEEK,
    [NEXT_UNIT]: RANGE_TYPES.NEXT_WEEK,
    [LAST_UNIT]: RANGE_TYPES.LAST_WEEK
  },
  [MONTH]: {
    [THIS_UNIT]: RANGE_TYPES.THIS_MONTH,
    [NEXT_UNIT]: RANGE_TYPES.NEXT_MONTH,
    [LAST_UNIT]: RANGE_TYPES.LAST_MONTH
  },
  [QUARTER]: {
    [THIS_UNIT]: RANGE_TYPES.THIS_QUARTER,
    [NEXT_UNIT]: RANGE_TYPES.NEXT_QUARTER,
    [LAST_UNIT]: RANGE_TYPES.LAST_QUARTER
  },
  [YEAR]: {
    [THIS_UNIT]: RANGE_TYPES.THIS_YEAR,
    [NEXT_UNIT]: RANGE_TYPES.NEXT_YEAR,
    [LAST_UNIT]: RANGE_TYPES.LAST_YEAR
  }
};
function getRangeType(dashboardFilter) {
  const {
    operator: operatorName,
    value: operatorValue
  } = dashboardFilter;
  if (operatorName !== InRollingDateRange.toString()) {
    return DateRangeOperatorToSORangeType[operatorName];
  }
  const {
    direction,
    isInclusive,
    timeUnit,
    value: timeUnitCount
  } = operatorValue;

  // Handles rolling date range.
  if (timeUnit === DAY && timeUnitCount > 1) {
    return RANGE_TYPES.ROLLING;
  } else {
    const rangeTypeKey = direction === FORWARD ? NEXT_UNIT : isInclusive ? THIS_UNIT : LAST_UNIT;
    return TimeUnitToRangeType[timeUnit][rangeTypeKey];
  }
}
function getOtherDateInfo(dashboardFilter) {
  const {
    operator: operatorName,
    value: operatorValue,
    highValue: operatorHighValue
  } = dashboardFilter;
  if (operatorName === InRollingDateRange.toString()) {
    const {
      includeFutureDates,
      timeUnit,
      value: timeUnitCount
    } = operatorValue;

    // Handles rolling date range.
    if (timeUnit === DAY && timeUnitCount > 1) {
      return {
        rollingDays: timeUnitCount
      };
    } else {
      return {
        entireCurrentUnit: includeFutureDates
      };
    }
  }
  if ([RANGE_TYPES.IS_EQUAL_TO, RANGE_TYPES.IS_AFTER_DATE, RANGE_TYPES.IS_BEFORE_DATE].includes(DateRangeOperatorToSORangeType[operatorName])) {
    return {
      date: operatorValue
    };
  }
  return {
    startDate: operatorValue,
    endDate: operatorHighValue
  };
}

// converts operator in Filter Query Format to format used by date range editor
export const getDateRangeFilterOverride = dashboardFilter => {
  const rangeType = getRangeType(dashboardFilter);
  const additionalProps = getOtherDateInfo(dashboardFilter);
  return makeDateRangeByType(Object.assign({
    rangeType
  }, additionalProps));
};