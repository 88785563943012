'use es6';

import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { LINE_ITEM } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { searchObjects, createSearchObjects } from 'reference-resolvers/api/ContactSearchAPI';
import { getObjectsByIds, createGetObjectsByIds } from 'reference-resolvers/api/LineItemsAPI';
export const createLineItemReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.LINE_ITEMS,
  createFetchByIds: opts => createGetObjectsByIds(opts)(LINE_ITEM),
  createFetchSearchPage: opts => createSearchObjects(opts)(LINE_ITEM),
  fetchByIds: getObjectsByIds(LINE_ITEM),
  fetchSearchPage: searchObjects(LINE_ITEM)
}, options));
export default createLineItemReferenceResolver();