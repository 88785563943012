'use es6';

import { fromJS } from 'immutable';
import { ACTIVITY_TYPE, AD_OBJECT_AD_CAMPAIGN, BUSINESS_UNIT, CALL_DISPOSITION, CAMPAIGN, COMPANY, CONTENT, CTA_GUID, CONVERSATION_INBOX, DEAL_PIPELINE, DEAL_PIPELINE_STAGE, EMAIL, FORM, INBOUND_DB_COMPANY_IMPORT, INBOUND_DB_CONTACT_IMPORT, INBOUND_DB_DEAL_IMPORT, MULTI_CURRENCY_CURRENCY_CODE, DEAL, OWNER, PERSONA, PRODUCT, SALESFORCE_CAMPAIGN, SEQUENCE, SEQUENCE_STEP_ORDER, TEAM, TEMPLATE, TICKET_PIPELINE, TICKET_STAGE, USER, TASK_QUEUE, TICKET, LINE_ITEM, MEETING_OUTCOME, CONTACT, BLOG_AUTHOR, BLOG_TAG, MARKETING_EVENT, WEB_INTERACTIVE } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { reportingReferenceResolvers } from 'reporting-data/public/reference-resolvers';
import { createStandardCachedResolver } from 'reference-resolvers-lite/utils/createStandardCachedResolver';
import * as ObjectTypes from '../../objectTypes';
export const TYPES = fromJS({
  [ObjectTypes.CONTACT]: {
    hs_object_id: CONTACT,
    hubspot_owner_id: OWNER,
    hubspot_team_id: TEAM,
    'formSubmissions.formId': FORM,
    hs_persona: PERSONA,
    salesforcecampaignids: SALESFORCE_CAMPAIGN,
    '_inbounddbio.importid_': INBOUND_DB_CONTACT_IMPORT
  },
  [ObjectTypes.COMPANY]: {
    hs_object_id: COMPANY,
    hubspot_owner_id: OWNER,
    hubspot_team_id: TEAM,
    hs_parent_company_id: COMPANY,
    '_inbounddbio.importid_': INBOUND_DB_COMPANY_IMPORT,
    hs_analytics_first_touch_converting_campaign: CAMPAIGN,
    hs_analytics_last_touch_converting_campaign: CAMPAIGN
  },
  [ObjectTypes.DEAL]: {
    hubspot_owner_id: OWNER,
    hs_object_id: DEAL,
    hubspot_team_id: TEAM,
    pipeline: DEAL_PIPELINE,
    dealstage: DEAL_PIPELINE_STAGE,
    deal_currency_code: MULTI_CURRENCY_CURRENCY_CODE,
    '_inbounddbio.importid_': INBOUND_DB_DEAL_IMPORT
  },
  [ObjectTypes.ENGAGEMENT]: {
    hubspot_owner_id: OWNER,
    hubspot_team_id: TEAM,
    hs_created_by: USER,
    hs_modified_by: USER,
    hs_activity_type: ACTIVITY_TYPE,
    hs_call_disposition: CALL_DISPOSITION,
    hs_meeting_outcome: MEETING_OUTCOME,
    hs_queue_membership_ids: TASK_QUEUE
  },
  [ObjectTypes.TASK]: {
    hs_queue_membership_ids: TASK_QUEUE
  },
  [ObjectTypes.BLOG_POST]: {
    hs_blog_author_id: BLOG_AUTHOR,
    hs_tag_ids: BLOG_TAG
  },
  [ObjectTypes.CALL]: {
    hs_created_by: USER,
    hs_call_disposition: CALL_DISPOSITION
  },
  [ObjectTypes.TICKET]: {
    hs_ticket_id: TICKET,
    hs_pipeline: TICKET_PIPELINE,
    hs_pipeline_stage: TICKET_STAGE
  },
  [ObjectTypes.LINE_ITEM]: {
    hs_product_id: PRODUCT,
    hs_object_id: LINE_ITEM,
    hs_line_item_currency_code: MULTI_CURRENCY_CURRENCY_CODE
  },
  [ObjectTypes.CONVERSATION]: {
    hubspot_owner_id: OWNER,
    hs_inbox_object_id: CONVERSATION_INBOX
  },
  [ObjectTypes.MARKETING_EMAIL]: {
    hs_campaign_name: CAMPAIGN
  },
  [ObjectTypes.CAMPAIGN]: {
    hs_object_id: CAMPAIGN,
    hs_owner: OWNER,
    hs_origin_asset_id: CAMPAIGN
  },
  [ObjectTypes.V2_CONTACT_REPLIED_SEQUENCE_EMAIL_QA]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.V2_CONTACT_REPLIED_SEQUENCE_EMAIL_PROD]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.V2_CONTACT_BOOKED_MEETING_THROUGH_SEQUENCE_QA]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.V2_CONTACT_BOOKED_MEETING_THROUGH_SEQUENCE_PROD]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.V2_CONTACT_UNSUBSCRIBED_SEQUENCE_EMAIL_QA]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.V2_CONTACT_UNSUBSCRIBED_SEQUENCE_EMAIL_PROD]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.V2_CONTACT_UNENROLLED_MANUALLY_FROM_SEQUENCE_QA]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER,
    hs_unenrolled_by: USER
  },
  [ObjectTypes.V2_CONTACT_UNENROLLED_MANUALLY_FROM_SEQUENCE_PROD]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER,
    hs_unenrolled_by: USER
  },
  [ObjectTypes.V2_CONTACT_UNENROLLED_FROM_SEQUENCE_QA]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.V2_CONTACT_UNENROLLED_FROM_SEQUENCE_PROD]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.V2_CONTACT_ENROLLED_IN_SEQUENCE_QA]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.V2_CONTACT_ENROLLED_IN_SEQUENCE_PROD]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.V2_CONTACT_FINISHED_SEQUENCE_QA]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.V2_CONTACT_FINISHED_SEQUENCE_PROD]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.V2_SEQUENCE_ERRORED_QA]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE
  },
  [ObjectTypes.V2_SEQUENCE_ERRORED_PROD]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.V2_SEQUENCE_STEP_EXECUTED_QA]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.V2_SEQUENCE_STEP_EXECUTED_PROD]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.V2_SEQUENCE_EMAIL_BOUNCED_QA]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.V2_SEQUENCE_EMAIL_BOUNCED_PROD]: {
    hs_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER
  },
  [ObjectTypes.SENT_TRACKED_INBOX_EMAIL_V8_QA]: {
    hs_sequence_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER,
    hs_template_id: TEMPLATE
  },
  [ObjectTypes.SENT_TRACKED_INBOX_EMAIL_V8_PROD]: {
    hs_sequence_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER,
    hs_template_id: TEMPLATE
  },
  [ObjectTypes.OPENED_TRACKED_INBOX_EMAIL_V8_QA]: {
    hs_sequence_enrolled_by: USER,
    hs_user_id: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER,
    hs_template_id: TEMPLATE
  },
  [ObjectTypes.OPENED_TRACKED_INBOX_EMAIL_V8_PROD]: {
    hs_sequence_enrolled_by: USER,
    hs_user_id: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER,
    hs_template_id: TEMPLATE
  },
  [ObjectTypes.CLICKED_LINK_IN_TRACKED_INBOX_EMAIL_V8_QA]: {
    hs_sequence_enrolled_by: USER,
    hs_user_id: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER,
    hs_template_id: TEMPLATE
  },
  [ObjectTypes.CLICKED_LINK_IN_TRACKED_INBOX_EMAIL_V8_PROD]: {
    hs_sequence_enrolled_by: USER,
    hs_user_id: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER,
    hs_template_id: TEMPLATE
  },
  [ObjectTypes.REPLIED_TO_TRACKED_INBOX_EMAIL_V8_QA]: {
    hs_sequence_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_template_id: TEMPLATE
  },
  [ObjectTypes.REPLIED_TO_TRACKED_INBOX_EMAIL_V8_PROD]: {
    hs_sequence_enrolled_by: USER,
    hs_sequence_id: SEQUENCE,
    hs_sequence_step_order: SEQUENCE_STEP_ORDER,
    hs_template_id: TEMPLATE
  },
  [ObjectTypes.AD_INTERACTION_QA]: {
    hs_ad_campaign_id: AD_OBJECT_AD_CAMPAIGN
  },
  [ObjectTypes.AD_INTERACTION_PROD]: {
    hs_ad_campaign_id: AD_OBJECT_AD_CAMPAIGN
  },
  [ObjectTypes.CLICKED_CTA_QA]: {
    hs_cta_origin_asset_id: CTA_GUID
  },
  [ObjectTypes.CLICKED_CTA_PROD]: {
    hs_cta_origin_asset_id: CTA_GUID
  },
  [ObjectTypes.VIEWED_CTA_QA]: {
    hs_cta_origin_asset_id: CTA_GUID
  },
  [ObjectTypes.VIEWED_CTA_PROD]: {
    hs_cta_origin_asset_id: CTA_GUID
  },
  [ObjectTypes.V4_MARKETING_CAMPAIGN_INFLUENCES_QA]: {
    hs_marketing_campaign_guid: CAMPAIGN
  },
  [ObjectTypes.V4_MARKETING_CAMPAIGN_INFLUENCES_PROD]: {
    hs_marketing_campaign_guid: CAMPAIGN
  }
});
export const RESOLVER_LITE_ALLOWED_PROPERTIES = fromJS({
  [ObjectTypes.CONVERSATION]: {
    hs_conversation_channel: 'hs_conversation_channel'
  },
  [ObjectTypes.DEAL]: {
    hs_tag_ids: 'hs_tag_ids'
  },
  [ObjectTypes.TICKET]: {
    hs_tag_ids: 'hs_tag_ids'
  },
  [ObjectTypes.REPLIED_TO_TRACKED_INBOX_EMAIL_V8_PROD]: {
    hs_user_id: 'hs_user_id'
  },
  [ObjectTypes.REPLIED_TO_TRACKED_INBOX_EMAIL_V8_QA]: {
    hs_user_id: 'hs_user_id'
  }
});

// regardless of object type, if the property name matches, use this type
export const PROPERTY_TYPES = fromJS({
  hs_updated_by_user_id: USER,
  hs_created_by_user_id: USER,
  hubspot_owner_id: OWNER,
  hubspot_team_id: TEAM,
  hs_all_assigned_business_unit_ids: BUSINESS_UNIT,
  // TODO: tempary changes required for JA - will update when actual property metadata updates in puma
  hs_content_id: CONTENT,
  hs_email_meta_content_id: EMAIL,
  hs_form_id: FORM,
  hs_marketing_event: MARKETING_EVENT,
  hs_placement_asset_id: CTA_GUID,
  hs_web_interactive_coordinates: WEB_INTERACTIVE
});
export const RESOLVERS = TYPES.map(typesByProperty => typesByProperty.map(referenceType => reportingReferenceResolvers[referenceType]));
export const PROPERTY_RESOLVERS = PROPERTY_TYPES.map(referenceType => reportingReferenceResolvers[referenceType]);
const getReferenceTypeForResolverLite = (objectTypeId, snowflakeProperty) => {
  const {
    name: propertyName,
    metaDefinition: {
      externalOptionsReferenceType
    } = {}
  } = snowflakeProperty;
  return RESOLVER_LITE_ALLOWED_PROPERTIES.hasIn([objectTypeId, propertyName]) && externalOptionsReferenceType && externalOptionsReferenceType;
};
export const getReferenceType = (snowflakeTable, snowflakeProperty) => {
  const {
    objectTypeId
  } = snowflakeTable;
  const {
    name: propertyName,
    metaDefinition: {
      referencedObjectType,
      externalOptionsReferenceType
    } = {}
  } = snowflakeProperty;

  // TODO: "referencedObjectType" and "externalOptionsReferenceType" are not compatible and refer
  // to resolvers in two different systems. They should not be treated as interchangeable.
  return referencedObjectType || TYPES.getIn([objectTypeId, propertyName]) || PROPERTY_TYPES.get(propertyName) || getReferenceTypeForResolverLite(objectTypeId, snowflakeProperty) || externalOptionsReferenceType;
};
export const getReferenceResolver = (snowflakeTable, snowflakeProperty) => {
  const {
    objectTypeId
  } = snowflakeTable;
  const {
    name: propertyName,
    metaDefinition: {
      referencedObjectType,
      externalOptionsReferenceType
    } = {}
  } = snowflakeProperty;
  return reportingReferenceResolvers[referencedObjectType] || reportingReferenceResolvers[externalOptionsReferenceType] || RESOLVERS.getIn([objectTypeId, propertyName]) || PROPERTY_RESOLVERS.get(propertyName);
};
export const getReferenceResolverLite = (snowflakeTable, snowflakeProperty) => {
  const {
    objectTypeId
  } = snowflakeTable;
  const referenceType = getReferenceTypeForResolverLite(objectTypeId, snowflakeProperty);
  return referenceType && createStandardCachedResolver(referenceType, objectTypeId);
};