'use es6';

import { createSelector } from 'reselect';
import { selectAllGates } from './index';
import { gate } from 'hub-http/gates';
import { userInfoSync } from 'hub-http/userInfo';
export const selectUserHasGates = (...gates) => createSelector(selectAllGates, gatesList => gates.every(g => gatesList.includes(g)));

// v3 gates aren't backed by redux, and are instead retrieved from the user-info package
// v3 gates will become the new norm https://product.hubteam.com/docs/gating/next-gen/overview.html
const selectUserHasV3Gate = gateId => {
  try {
    const {
      gates
    } = userInfoSync();
    return gates.includes(gateId);
  } catch (err) {
    return false;
  }
};
export const selectHasSalesExplorerExpGate = selectUserHasGates('RA:SalesExplorerExp');
export const selectHasSetBusinessUnitMigrationAccess = selectUserHasGates('RM:SetBusinessUnitMigration');
export const selectHasSetBusinessUnitDBUIMigrationAccess = selectUserHasGates('RM:SetBusinessUnitDBUIMigration');
export const selectHasSetDefaultDashboardMigrationAccess = selectUserHasGates('RM:SetDefaultDashboardMigration');
export const selectHasToggleFavoriteAssetsAccess = selectUserHasGates('RM:ToggleFavoriteAssetsAccess');
export const selectHasSavedFiltersAccess = selectUserHasGates('RM:SavedDashboardFilters');
export const selectHasBackendAppliedFiltersAccess = selectUserHasGates('RM:BEFilterApply');
export const selectCanViewMigrateDateRangeAllAlert = selectUserHasGates('RM:MigrateDateRangeAllAlert');
export const selectHasCSATSurvey = selectUserHasGates('RM:CSATSurvey');
export const selectHasMigrateLegacyFilterUrlAccess = selectUserHasGates('RM:FilterUrlBookmarkMigration');
export const selectDBFiltersFinalRollOut = selectUserHasGates('RM:DBFiltersFinalRollOut');
export const getIsUngatedToShareWithComponent = selectUserHasGates('IntegrationComponents:PublicApps:ShareWithComponent');

// v3 gate strings must be created using the `gate` function
export const selectHasAdvancedPropertySearchAccess = () => selectUserHasV3Gate(gate('RM:AdvancedPropertySearch'));
export const selectHasDashboardFiltersInSRV = () => selectUserHasV3Gate(gate('RM:DashboardFiltersInSRV'));