'use es6';

import { Record } from 'immutable';
import always from 'transmute/always';
import compose from 'transmute/compose';
import curry from 'transmute/curry';
import get from 'transmute/get';
import ifElse from 'transmute/ifElse';
import set from 'transmute/set';
import both from 'transmute/both';
import { complement, isNotNil, strictEquals } from '../../private/js-util';
import { RequestState } from './request-state';
import { AvailableState } from './resource-availability-state';
const STATUS = 'status';
const AVAILABILITY = 'availability';
const VALUE = 'value';
const PROMISE = 'promise';
const ERROR = 'error';

/**
 * {Record} ResourceState
 * Represents the state of a resource
 */
export const ResourceState = Record({
  /* represents the status of a request for a resource */
  [STATUS]: RequestState.UNINITIALIZED,
  /* represents the availability of a resource */
  [AVAILABILITY]: AvailableState.UNINITIALIZED,
  /* represents the value of the resource */
  [VALUE]: undefined,
  /* represents any error that occurred */
  [ERROR]: undefined,
  /* represents the current promise used to request a resource */
  [PROMISE]: undefined
});
export const getStatus = get(STATUS);
export const getAvailability = get(AVAILABILITY);
export const isPending = compose(strictEquals(RequestState.PENDING), getStatus);
export const isSucceeded = compose(strictEquals(RequestState.SUCCEEDED), getStatus);
export const isInitialized = compose(strictEquals(AvailableState.INITIALIZED), getAvailability);
export const isUninitialized = compose(strictEquals(AvailableState.UNINITIALIZED), getAvailability);
export const setStatus = set(STATUS);
export const setAvailability = set(AVAILABILITY);
export const getValue = get(VALUE);
export const setValue = set(VALUE);
export const getError = get(ERROR);
export const setError = set(ERROR);
export const getPromise = get(PROMISE);
export const setPromise = set(PROMISE);
export const clearPromise = set(PROMISE, undefined);
const isResourceAvailable = both(isNotNil, complement(isUninitialized));

/**
 * Abstracts away the implementation details knowing whether it is safe to
 * access a resource, so that the user can just provide:
 * @param {any} defaultValue - default fallback value if the resource is unavailable
 * @param {ResourceState} resource
 */
export const toValueWithDefault = curry((defaultValue, resource) => ifElse(isResourceAvailable, getValue, always(defaultValue))(resource));