import { Record } from 'immutable';
export let UserSetType;
(function (UserSetType) {
  UserSetType["SINGLE_USER"] = "SINGLE_USER";
  UserSetType["TEAM"] = "TEAM";
  UserSetType["API_ACCESS"] = "API_ACCESS";
})(UserSetType || (UserSetType = {}));
const defaultPermissionGrantValues = {
  userSetType: undefined,
  userSetId: undefined
};
const PermissionGrant = Record(defaultPermissionGrantValues, 'PermissionGrant');

/**
 * @returns {PermissionGrant}
 */
export const createPermissionGrant = (type, id) => PermissionGrant({
  userSetType: type,
  userSetId: id
});