'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["useFiscalYear", "rangeType"];
import { Map as ImmutableMap } from 'immutable';
import { BASE_QUESTION_CONTEXT_UPDATES, getQuestionContextOverrides } from '../question-insights-data';
import { DEFAULT_DATE_FORMAT } from '../../constants/date-format';
import makeDateRangeByType from 'reporting-data/lib/makeDateRangeByType';
export const applyQuestionInsight = (contextChange, context, report, subAppKey, defaultContext, __scopes) => {
  const question = contextChange.question;
  const savedContext = context.savedContext;
  const questionContext = getQuestionContextOverrides(question);
  if (!questionContext) {
    return contextChange;
  }
  const _ref = questionContext.dateRange || BASE_QUESTION_CONTEXT_UPDATES.dateRange,
    {
      useFiscalYear,
      rangeType
    } = _ref,
    rest = _objectWithoutPropertiesLoose(_ref, _excluded);
  const updatedDateRange = rangeType && ImmutableMap(Object.assign({}, makeDateRangeByType(Object.assign({
    rangeType
  }, rest), DEFAULT_DATE_FORMAT, report.getIn(['config', 'dataType'])), {
    useFiscalYear: !!useFiscalYear
  }));
  const defaultWithSavedContext = Object.assign({}, defaultContext.toObject(), savedContext && {
    savedContext
  });
  return Object.assign({}, defaultWithSavedContext, contextChange, questionContext, updatedDateRange && {
    dateRange: updatedDateRange
  });
};