'use es6';

import { DESC } from '../../../../constants/sortOrder';
import { identity } from './identity';
const types = ['string', 'number'];
export const comparator = (getter = identity, order) => (a, b) => {
  const first = getter(a);
  const second = getter(b);
  const flip = order === DESC ? 1 : -1;
  if (first == null) {
    return 1 * flip;
  }
  if (second == null) {
    return -1 * flip;
  }
  if (!types.includes(typeof first) && !types.includes(typeof second)) {
    return 0;
  }
  switch (typeof first) {
    case 'number':
      return ((second || 0) - (first || 0)) * flip;
    case 'string':
      return (second || '').localeCompare(first || '');
    default:
      return 0;
  }
};