'use es6';

import { PIPELINE_TYPES } from '../pipelineContext';
export const pipelineTypeToQueryParam = {
  [PIPELINE_TYPES.DEAL]: 'pipeline',
  [PIPELINE_TYPES.TICKET]: 'ticketPipeline'
};
export const CONTEXT_QUERY_KEYS = ['rangeType', 'rollingDays', 'startDate', 'endDate', 'entireCurrentUnit', 'owners', pipelineTypeToQueryParam[PIPELINE_TYPES.DEAL], pipelineTypeToQueryParam[PIPELINE_TYPES.TICKET], 'teams', 'frequency'];
export const OWNERS_PATH = ['owner', 'owners'];
export const TEAMS_PATH = ['team', 'teams'];
export const CONTEXT_PATH = ['context'];
export const FREQUENCY_HISTOGRAM_PATH = ['frequency', 'histogram'];
export const HS_ANY = '__hs__ANY';