import { useState, useEffect } from 'react';
import userInfo from 'hub-http/userInfo';
// @ts-expect-error Untyped dependency
import { logError } from './logError';
export const useScopes = () => {
  const [scopes, setScopes] = useState([]);
  useEffect(() => {
    userInfo().then(({
      user: {
        scopes: allScopes
      }
    }) => {
      setScopes(allScopes);
    }).catch(logError);
  }, []);
  return scopes;
};