import keyMirror from 'react-utils/keyMirror';
import { DEAL_PIPELINE, TICKET_PIPELINE
// @ts-expect-error migrate upstream
} from 'reference-resolvers/constants/ReferenceObjectTypes';
import curry from 'transmute/curry';
import setIn from 'transmute/setIn';
export const PIPELINE_TYPES = keyMirror({
  DEAL: null,
  TICKET: null
});
const pipelineTypeToContextPath = {
  [PIPELINE_TYPES.DEAL]: ['pipeline', 'pipelineId'],
  [PIPELINE_TYPES.TICKET]: ['ticket-pipeline']
};
export const pipelineTypeToReferenceObjectType = {
  [PIPELINE_TYPES.DEAL]: DEAL_PIPELINE,
  [PIPELINE_TYPES.TICKET]: TICKET_PIPELINE
};
export const pipelineTypeToDashboardHeaderKey = {
  [PIPELINE_TYPES.DEAL]: 'dealPipelineContext',
  [PIPELINE_TYPES.TICKET]: 'ticketPipelineContext'
};
export const getPipelineFilterFromContext = curry((pipelineType, context) => {
  return context.getIn(pipelineTypeToContextPath[pipelineType], null);
});
export function deletePipelineFilterFromContext(pipelineType, context) {
  return context.deleteIn(pipelineTypeToContextPath[pipelineType]);
}
export const setPipelineFilterOnContext = curry((pipelineType, pipelineId, context) => setIn(pipelineTypeToContextPath[pipelineType], pipelineId, context));