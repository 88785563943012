import * as checked from 'reporting-data/lib/checked';
import { Encoding } from '../column-records';
import { AxisScaleType, ColorOption, Scale, goalFrequency, goalLineValue, legendPosition, legendWidth, showDataLabels, showRecordIds, stacking, stackingType } from '../highcharts-settings';
import { VisualTypes } from '../constants/visual-constants';
export const HorizontalBarEncodings = checked.record({
  x: Encoding.optional(),
  x_multi: checked.list(Encoding),
  y: Encoding.optional(),
  color: Encoding.optional()
}, 'HorizontalBarEncodings');
export const HorizontalBarVisualOptions = checked.record({
  showDataLabels,
  showRecordIds,
  stacking,
  stackingType,
  accumulate: checked.boolean().defaultValue(false),
  xMinMax: Scale.defaultValue({}),
  color: ColorOption.defaultValue({}),
  xAxisType: AxisScaleType,
  xAxisLabel: checked.string().optional(),
  goalLineValue,
  goalFrequency,
  legendPosition,
  legendWidth
});
export const HorizontalBarVisual = checked.record({
  type: checked.string().always(VisualTypes.HORIZONTAL_BAR),
  encodings: HorizontalBarEncodings,
  options: HorizontalBarVisualOptions.defaultValue({})
}, 'HorizontalBarVisual');