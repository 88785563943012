// @ts-expect-error migrate upstream
import { hasSufficientDashboardEditPermission } from 'reporting-action-components/lib/dashboardLogic';
// @ts-expect-error migrate upstream
import { useHasReportingEditAccess } from 'reporting-action-components/access/scopes/hooks/useHasScope';
const useCanEditQuickFilter = ({
  dashboard
}) => {
  const canEditDashboard = hasSufficientDashboardEditPermission(dashboard);
  const canEditReport = useHasReportingEditAccess();
  return canEditDashboard && canEditReport;
};
export default useCanEditQuickFilter;