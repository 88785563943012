'use es6';

import { Map as ImmutableMap } from 'immutable';
import usePollingQuery from './usePollingQuery';
import { ALL_USERS_FIELD_NAME, DEACTIVATED_USERS_FIELD_NAME, FETCH_ALL_USERS, FETCH_DEACTIVATED_USERS } from '../requests/users';
export const useUsers = () => {
  const {
    data,
    error,
    loading
  } = usePollingQuery(FETCH_ALL_USERS);
  return {
    data: data ? data[ALL_USERS_FIELD_NAME] : ImmutableMap(),
    error,
    loading
  };
};
export const useDeactivatedUsers = () => {
  const {
    data,
    error,
    loading
  } = usePollingQuery(FETCH_DEACTIVATED_USERS);
  return {
    data: data ? data[DEACTIVATED_USERS_FIELD_NAME] : new Set(),
    error,
    loading
  };
};