import { Map as ImmutableMap } from 'immutable';
import { useCallback } from 'react';
import { useAsyncFetcher } from 'reporting-data/asyncData/useAsyncFetcher';
// @ts-expect-error Untyped import
import getProperties from 'reporting-data/properties';
import { getMetricOptions } from '../../../lib/metricUtils';
const useFetchOptionsForBaseMetricEditor = (dataType, editorSchema) => {
  const resultFormatter = useCallback(propertyMetaDataList => {
    const propertyMap = dataType ? propertyMetaDataList.get(dataType) : ImmutableMap({});
    return getMetricOptions(editorSchema.metrics, propertyMap.toJS());
  }, [dataType, editorSchema.metrics]);
  const fetcher = useCallback(dataTypeParam => getProperties(dataTypeParam), []);
  const requestArgs = dataType;
  return useAsyncFetcher({
    fetcher,
    requestArgs,
    resultFormatter
  });
};
export default useFetchOptionsForBaseMetricEditor;