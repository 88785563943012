'use es6';

const page = (spec, config) => breakdowns => {
  const {
    offset = 0,
    limit = Infinity
  } = config;
  return breakdowns.slice(offset, offset + limit);
};
export const get = (spec, config) => response => {
  const {
    dimensions: [primary] = [],
    limit,
    offset
  } = config;
  if (!(offset || limit)) {
    return response;
  }
  if (primary === 'sessionDate') {
    const dates = Object.keys(response);
    return dates.reduce((paged, date) => Object.assign({}, paged, {
      [date]: page(spec, config)(response[date])
    }), {});
  }
  const {
    breakdowns
  } = response;
  return Object.assign({}, response, {
    breakdowns: page(spec, config)(breakdowns)
  });
};