'use es6';

import usePollingQuery from './usePollingQuery';
import { GET_SHARE_ASSET_SCHEDULE, SHARE_ASSET_SCHEDULES_FIELD_NAME } from '../requests/emails';
export const useRecurringShareAssets = ({
  objectId,
  objectType,
  deliveryType
}) => {
  const {
    data,
    error,
    loading,
    refetch
  } = usePollingQuery(GET_SHARE_ASSET_SCHEDULE, {
    variables: {
      objectId,
      objectType,
      deliveryType
    },
    fetchPolicy: 'cache-and-network'
  });
  return {
    data: data ? data[SHARE_ASSET_SCHEDULES_FIELD_NAME] : undefined,
    error,
    loading,
    refetch
  };
};