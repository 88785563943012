'use es6';

import * as DSAssetFamilies from 'customer-data-filters/filterQueryFormat/DSAssetFamilies/DSAssetFamilies';
import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as ObjectSegOperatorTypes from 'customer-data-filters/converters/objectSeg/ObjectSegOperatorTypes';
import * as OperatorTypes from '../ObjectSegOperatorTypes';
import { IN_LIST_TYPE } from '../constants/constants';
import getIn from 'transmute/getIn';
const WORKFLOW_IN_LIST_TYPES = [IN_LIST_TYPE.WORKFLOWS_ACTIVE, IN_LIST_TYPE.WORKFLOWS_COMPLETED, IN_LIST_TYPE.WORKFLOWS_ENROLLMENT, IN_LIST_TYPE.WORKFLOWS_GOAL];
export const isWorkflowInListType = inListType => WORKFLOW_IN_LIST_TYPES.includes(inListType);
const WORKFLOW_IN_LIST_TYPE_TO_OPERATOR_TYPE = {
  [IN_LIST_TYPE.WORKFLOWS_ACTIVE]: ObjectSegOperatorTypes.WORKFLOW_ACTIVE,
  [IN_LIST_TYPE.WORKFLOWS_COMPLETED]: ObjectSegOperatorTypes.WORKFLOW_COMPLETED,
  [IN_LIST_TYPE.WORKFLOWS_ENROLLMENT]: ObjectSegOperatorTypes.WORKFLOW_ENROLLED,
  [IN_LIST_TYPE.WORKFLOWS_GOAL]: ObjectSegOperatorTypes.WORKFLOW_MET_GOAL
};
const WORKFLOW_IN_LIST_TYPE_TO_NEGATED_OPERATOR_TYPE = {
  [IN_LIST_TYPE.WORKFLOWS_ACTIVE]: ObjectSegOperatorTypes.WORKFLOW_NOT_ACTIVE,
  [IN_LIST_TYPE.WORKFLOWS_COMPLETED]: ObjectSegOperatorTypes.WORKFLOW_NOT_COMPLETED,
  [IN_LIST_TYPE.WORKFLOWS_ENROLLMENT]: ObjectSegOperatorTypes.WORKFLOW_NOT_ENROLLED,
  [IN_LIST_TYPE.WORKFLOWS_GOAL]: ObjectSegOperatorTypes.WORKFLOW_NOT_MET_GOAL
};
const getWorkflowOperatorType = (inListType, negate) => {
  return negate ? WORKFLOW_IN_LIST_TYPE_TO_NEGATED_OPERATOR_TYPE[inListType] : WORKFLOW_IN_LIST_TYPE_TO_OPERATOR_TYPE[inListType];
};
const transformToImportFilter = filter => {
  const operator = filter.operator === OperatorTypes.IS_IN_LIST ? OperatorTypes.IS_IN_IMPORT : OperatorTypes.IS_NOT_IN_IMPORT;
  return {
    filterType: ObjectSegFilterTypes.IN_IMPORT,
    operator,
    importId: getIn(['metadata', 'id'], filter)
  };
};
const transformToWorkflowFilter = (filter, inListType) => {
  const negate = filter.operator === OperatorTypes.IS_NOT_IN_LIST;
  const operator = getWorkflowOperatorType(inListType, negate);
  return {
    filterType: ObjectSegFilterTypes.WORKFLOW,
    operator,
    workflowId: getIn(['metadata', 'id'], filter)
  };
};
export const getTransformedListMembershipFilter = filter => {
  const inListType = getIn(['metadata', 'inListType'], filter);
  if (inListType === IN_LIST_TYPE.IMPORT) {
    return [transformToImportFilter(filter), DSAssetFamilies.IMPORT];
  }
  if (isWorkflowInListType(inListType)) {
    return [transformToWorkflowFilter(filter, inListType), DSAssetFamilies.WORKFLOW];
  }
  return [filter, DSAssetFamilies.IN_LIST];
};