'use es6';

import { selectResource } from 'dashboard-lib/public/request/redux-request';
import { namespace } from 'dashboard-lib/public/team/team-action';
import { Seq, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import compose from 'transmute/compose';
import set from 'transmute/set';
import get from 'transmute/get';
import valueSeq from 'transmute/valueSeq';
import { getStatus, getValue, toValueWithDefault } from 'dashboard-lib/public/request/resource-state';
import { createSelector } from 'reselect';
import { defaultTo, isNil } from 'dashboard-lib/private/js-util';
import { getChildTeams, getId, getParentTeamId } from 'dashboard-lib/public/team/team-schema';
import identity from 'transmute/identity';
import { selectTeamHierarchyValue } from 'dashboard-lib/public/team/team-hierarchy-selector';
import { traverseStack } from 'dashboard-lib/private/js-util/traverse';
export const selectTeamRoot = selectResource(namespace);
export const selectTeamStatus = compose(getStatus, selectTeamRoot);
export const selectTeamValue = compose(getValue, selectTeamRoot);
export const selectTeam = createSelector([selectTeamRoot, (_, teamId) => teamId], (resource, teamId) => compose(defaultTo(ImmutableMap()), get(teamId), toValueWithDefault(ImmutableMap()))(resource));
export const selectTeamAsSeq = createSelector([selectTeamValue], value => value ? valueSeq(value) : Seq());
export const selectParentTeamIdSet = createSelector([selectTeam, identity], (targetTeam, state) => {
  const loop = (acc, next) => {
    const parentTeamId = getParentTeamId(next);
    if (isNil(parentTeamId)) {
      return acc;
    }
    return loop(acc.add(parentTeamId), selectTeam(state, parentTeamId));
  };
  return loop(ImmutableSet(), targetTeam);
});
const getChildTeamsDefault = defaultValue => compose(defaultTo(defaultValue), getChildTeams);
const traverseHierarchy = (accumulateFn, initial, subject) => traverseStack(accumulateFn, getChildTeamsDefault([]), initial, subject);

/**
 * Selects teams with children derived from team hierarchy
 * team: { children: [team, team] }
 */
const selectTeamsHydratedWithChildren = createSelector([selectTeamHierarchyValue], hierarchy => traverseHierarchy((acc, team) => {
  const teamId = getId(team);
  return set(teamId, team, acc);
}, ImmutableMap(), hierarchy));
const selectTeamByIdHydratedWithChildren = createSelector([(_, teamId) => teamId, selectTeamsHydratedWithChildren], (teamId, teamsWithChildren) => get(teamId, teamsWithChildren));

/**
 * Selects the target team, then traverses down to get all of its children in a
 * Set<teamId>
 * @returns Set of teamId
 */
export const selectChildTeamIdSet = createSelector(selectTeamByIdHydratedWithChildren, targetTeam => traverseHierarchy((acc, team) => acc.add(getId(team)), ImmutableSet(), [targetTeam]));