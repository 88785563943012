'use es6';

import * as DSAssetFamilies from './DSAssetFamilies';
import * as ListSegClassicAssetFamilies from '../../converters/listSegClassic/ListSegClassicAssetFamilies';
import * as ObjectTypes from 'customer-data-objects/constants/ObjectTypes';
import * as ReferenceObjectTypes from 'reference-resolvers/constants/ReferenceObjectTypes';
import { Map as ImmutableMap } from 'immutable';
import { PRIVACY_CONSENT } from '../../converters/listSegClassic/ListSegConstants';
export const ObjectTypeToDSAssetFamily = ImmutableMap({
  [ObjectTypes.COMPANY]: DSAssetFamilies.COMPANY_PROPERTY,
  [ObjectTypes.CONTACT]: DSAssetFamilies.CONTACT_PROPERTY,
  [ObjectTypes.DEAL]: DSAssetFamilies.DEAL_PROPERTY,
  [ObjectTypes.TICKET]: DSAssetFamilies.TICKET_PROPERTY,
  [ObjectTypes.ENGAGEMENT]: DSAssetFamilies.ENGAGEMENT_PROPERTY,
  [ObjectTypes.LINE_ITEM]: DSAssetFamilies.LINE_ITEM_PROPERTY,
  [ObjectTypes.PRODUCT]: DSAssetFamilies.PRODUCT_PROPERTY,
  [ObjectTypes.QUOTE]: DSAssetFamilies.QUOTE_PROPERTY,
  [ObjectTypes.TASK]: DSAssetFamilies.TASK_PROPERTY,
  [ObjectTypes.GOAL_TARGET]: DSAssetFamilies.GOAL_TARGET_PROPERTY
});
export const DSAssetFamilyToObjectType = ObjectTypeToDSAssetFamily.flip();
export const ObjectSegTypeToDSAssetFamily = ImmutableMap({
  [ObjectTypes.COMPANY]: DSAssetFamilies.COMPANY_PROPERTY,
  [ObjectTypes.CONTACT]: DSAssetFamilies.CONTACT_PROPERTY,
  [ObjectTypes.DEAL]: DSAssetFamilies.DEAL_PROPERTY,
  [ObjectTypes.TICKET]: DSAssetFamilies.TICKET_PROPERTY,
  [ObjectTypes.ENGAGEMENT]: DSAssetFamilies.ENGAGEMENT_PROPERTY,
  [ObjectTypes.LINE_ITEM]: DSAssetFamilies.LINE_ITEM_PROPERTY,
  [ObjectTypes.PRODUCT]: DSAssetFamilies.PRODUCT_PROPERTY,
  [ObjectTypes.QUOTE]: DSAssetFamilies.QUOTE_PROPERTY
});
export const DSAssetFamilyToObjectSegType = ObjectSegTypeToDSAssetFamily.flip();
export const ListSegClassicTypeToDSAssetFamily = ImmutableMap({
  [ListSegClassicAssetFamilies.ADS_PROPERTY]: DSAssetFamilies.ADS_PROPERTY,
  [ListSegClassicAssetFamilies.COMPANY_PROPERTY_VALUE]: DSAssetFamilies.COMPANY_PROPERTY,
  [ListSegClassicAssetFamilies.COMPANY_PROPERTY]: DSAssetFamilies.COMPANY_PROPERTY,
  [ListSegClassicAssetFamilies.CTA]: DSAssetFamilies.CTA,
  [ListSegClassicAssetFamilies.DEAL_PROPERTY]: DSAssetFamilies.DEAL_PROPERTY,
  [ListSegClassicAssetFamilies.EMAIL_CAMPAIGN]: DSAssetFamilies.EMAIL_CAMPAIGN,
  [ListSegClassicAssetFamilies.EMAIL_SUBSCRIPTION]: DSAssetFamilies.EMAIL_SUBSCRIPTION,
  [ListSegClassicAssetFamilies.ENGAGEMENT_PROPERTY]: DSAssetFamilies.ENGAGEMENT_PROPERTY,
  [ListSegClassicAssetFamilies.EVENT]: DSAssetFamilies.EVENT,
  [ListSegClassicAssetFamilies.FORM]: DSAssetFamilies.FORM,
  [ListSegClassicAssetFamilies.IMPORT]: DSAssetFamilies.CONTACT_IMPORT,
  [ListSegClassicAssetFamilies.LIST_MEMBERSHIP]: DSAssetFamilies.CONTACT_LIST,
  [ListSegClassicAssetFamilies.LINE_ITEM_PROPERTY]: DSAssetFamilies.LINE_ITEM_PROPERTY,
  [ListSegClassicAssetFamilies.PAGE_VIEW]: DSAssetFamilies.PAGE_VIEW,
  [PRIVACY_CONSENT]: DSAssetFamilies.CONTACT_PROPERTY,
  [ListSegClassicAssetFamilies.PRODUCT_PROPERTY]: DSAssetFamilies.PRODUCT_PROPERTY,
  [ListSegClassicAssetFamilies.PROPERTY_VALUE]: DSAssetFamilies.CONTACT_PROPERTY,
  [ListSegClassicAssetFamilies.SURVEY_MONKEY_QUESTION]: DSAssetFamilies.SURVEY_MONKEY_QUESTION,
  [ListSegClassicAssetFamilies.SURVEY_MONKEY]: DSAssetFamilies.SURVEY_MONKEY_SURVEY,
  [ListSegClassicAssetFamilies.WEBINAR]: DSAssetFamilies.GOTO_WEBINAR_WEBINAR,
  [ListSegClassicAssetFamilies.WORKFLOW]: DSAssetFamilies.WORKFLOW
});
export const DSAssetFamilyToListSegClassicType = ListSegClassicTypeToDSAssetFamily.flip().merge(
// Make sure that families with multiple family names always get the correct name
ImmutableMap({
  [DSAssetFamilies.COMPANY_PROPERTY]: ListSegClassicAssetFamilies.COMPANY_PROPERTY_VALUE,
  [DSAssetFamilies.CONTACT_PROPERTY]: ListSegClassicAssetFamilies.PROPERTY_VALUE
}));
export const referenceTypeToAssetFamily = ImmutableMap({
  [ReferenceObjectTypes.ADS_PROPERTY]: DSAssetFamilies.ADS_PROPERTY,
  [ReferenceObjectTypes.CONTACT_LIST]: DSAssetFamilies.CONTACT_LIST,
  [ReferenceObjectTypes.CTA]: DSAssetFamilies.CTA,
  [ReferenceObjectTypes.CTA_TO_PLACEMENT]: DSAssetFamilies.CTA_TO_PLACEMENT,
  [ReferenceObjectTypes.EMAIL_CAMPAIGN]: DSAssetFamilies.EMAIL_CAMPAIGN,
  [ReferenceObjectTypes.ENGAGEMENT_PROPERTY]: DSAssetFamilies.ENGAGEMENT_PROPERTY,
  [ReferenceObjectTypes.EVENT]: DSAssetFamilies.EVENT,
  [ReferenceObjectTypes.INBOUND_DB_CONTACT_IMPORT]: DSAssetFamilies.CONTACT_IMPORT,
  [ReferenceObjectTypes.INTEGRATION]: DSAssetFamilies.INTEGRATION,
  [ReferenceObjectTypes.LINE_ITEM_PROPERTY]: DSAssetFamilies.LINE_ITEM_PROPERTY,
  [ReferenceObjectTypes.PRODUCT_PROPERTY]: DSAssetFamilies.PRODUCT_PROPERTY,
  [ReferenceObjectTypes.SURVEY]: DSAssetFamilies.SURVEY_MONKEY_SURVEY,
  [ReferenceObjectTypes.SURVEY_QUESTION]: DSAssetFamilies.SURVEY_MONKEY_QUESTION,
  [ReferenceObjectTypes.WEBINAR]: DSAssetFamilies.GOTO_WEBINAR_WEBINAR
});
export const assetFamilyToReferenceType = referenceTypeToAssetFamily.flip();