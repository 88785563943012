import { Metrics } from '../metrics';
export const MATCH_FILE_UPLOAD = 'property-name-match-file-upload';
export const DESCRIPTION_WITHOUT_FIELDTYPE = 'property-description-textarea-without-fieldType';
export const PROBABILITY_WITHOUT_DISPLAYHINT = 'property-probability-without-numberDisplayHint';
export const DURATION_WITHOUT_DISPLAYHINT = 'property-duration-without-numberDisplayHint';
export const USER_WITHOUT_REFERENCE_TYPE = 'property-user-without-reference-type';
export const PIPELINE_WITHOUT_REFERENCE_TYPE = 'property-pipeline-without-reference-type';
export const PIPELINE_STAGE_WITHOUT_REFERENCE_TYPE = 'property-pipeline-stage-without-reference-type';
export const MISSING_LABEL = 'property-missing-label';
export const MISSING_REFERENCE_TYPE = 'property-missing-reference-type';
export const MISSING_XO_BOOLEAN = 'property-missing-externalOptions-boolean';
export const processExtraPropertyMetrics = groups => groups.map(group => group.propertyDefinitions || []).flat(1).reduce((metricsMap, propertyDefinition) => {
  const property = propertyDefinition.property;
  const objectTypeId = propertyDefinition.objectTypeId;
  if (property.name === 'file_upload_') {
    metricsMap['property-name-match-file-upload'] = true;
  }

  // Checks from PropertyIdentifier

  // Properties named "description" without "fieldType: textarea" are always
  // treated as textareas.
  if (property.type === 'string' && property.name === 'description' && property.fieldType !== 'textarea') {
    metricsMap['property-description-textarea-without-fieldType'] = true;
  }

  // These properties are treated as probabilities w/o the corresponding
  // numberDisplayHint (no object type checks).
  if (property.type === 'number' && (property.name === 'hs_forecast_probability' ||
  // DEAL
  property.name === 'hs_deal_stage_probability' ||
  // DEAL and DEAL_SPLIT
  property.name === 'hs_deal_forecast_probability') && property.numberDisplayHint !== 'probability') {
    metricsMap['property-probability-without-numberDisplayHint'] = true;
  }

  // These properties are treated as durations w/o the corresponding
  // numberDisplayHint (no object type checks).
  if (property.hubspotDefined && (property.name.startsWith('hs_time_in_') || property.name === 'time_to_first_agent_reply' || property.name === 'time_to_close') && property.type === 'number' && property.numberDisplayHint !== 'duration') {
    metricsMap['property-duration-without-numberDisplayHint'] = true;
  }

  // These properties are treated as "externalOptionsReferenceType: USER" without
  // the corresponding reference type. I'm unsure we'll be able to clean up this
  // function because it only checks these three properties today.
  if (property.hubspotDefined && (property.name === 'hs_created_by' || property.name === 'hs_created_by_user_id' || property.name === 'hs_updated_by_user_id') && property.externalOptionsReferenceType !== 'USER') {
    metricsMap['property-user-without-reference-type'] = true;
  }

  // We know there are portal-specific overrides for the deal pipeline property that
  // don't include the reference type, so it seems prudent to check for all of them.
  if (property.hubspotDefined && (
  // Deal
  objectTypeId === '0-3' && property.name === 'pipeline' || property.name === 'hs_pipeline') && property.externalOptionsReferenceType !== 'PIPELINE') {
    metricsMap['property-pipeline-without-reference-type'] = true;
  }

  // We know there are portal-specific overrides for the deal stage property that
  // don't include the reference type, so it seems prudent to check for all of them.
  if (property.hubspotDefined && (
  // Contact/company
  (objectTypeId === '0-1' || objectTypeId === '0-2') && property.name === 'lifecyclestage' ||
  // Deal
  objectTypeId === '0-3' && property.name === 'dealstage' || property.name === 'hs_pipeline_stage') && property.externalOptionsReferenceType !== 'PIPELINE_STAGE') {
    metricsMap['property-pipeline-stage-without-reference-type'] = true;
  }

  // Additional checks

  // This should never happen, but it'd be good to have a metric on it
  if (!property.label) {
    metricsMap['property-missing-label'] = true;
  }

  // Only do the general XO checks when the more specific checks have passed
  if (!metricsMap['property-user-without-reference-type'] && !metricsMap['property-pipeline-without-reference-type'] && !metricsMap['property-pipeline-stage-without-reference-type']) {
    // Both or neither of these fields should be set, none of this mixed state.
    if (!property.externalOptions && property.externalOptionsReferenceType) {
      metricsMap['property-missing-externalOptions-boolean'] = true;
    } else if (property.externalOptions && !property.externalOptionsReferenceType) {
      metricsMap['property-missing-reference-type'] = true;
    }
  }
  return metricsMap;
}, {});
export const handleExtraProcessingResult = ({
  segment,
  extraProcessingResult
}) => {
  if (segment && extraProcessingResult) {
    Object.entries(extraProcessingResult).forEach(([metric, value]) => {
      if (value) {
        Metrics.counter(metric, {
          segment
        }).increment();
      }
    });
  }
};