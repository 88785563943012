'use es6';

import * as Operators from '../../../constants/operators';
export const get = (spec, config) => {
  const {
    filters: {
      custom = []
    } = {}
  } = config;
  const {
    metrics = {}
  } = spec;
  const properties = Object.keys(metrics);
  const mapFilterToParams = ({
    operator,
    property,
    value,
    highValue,
    values
  }) => {
    if (!properties.includes(property)) {
      return {};
    }
    const valuesList = value !== null && value !== undefined ? [value] : values;
    switch (operator) {
      case Operators.NEQ:
      case Operators.HAS_PROPERTY:
      case Operators.NOT_HAS_PROPERTY:
        return {};
      case Operators.BETWEEN:
        return {
          [`metric-${property}-min`]: value,
          [`metric-${property}-max`]: highValue
        };
      case Operators.GT:
      case Operators.GTE:
        return {
          [`metric-${property}-min`]: valuesList
        };
      case Operators.LT:
      case Operators.LTE:
        return {
          [`metric-${property}-max`]: valuesList
        };
      default:
        return {
          [`metric-${property}`]: valuesList
        };
    }
  };
  const filters = custom.reduce((acc, filter) => {
    return Object.assign({}, acc, mapFilterToParams(filter));
  }, {});
  return Object.assign({}, filters);
};