'use es6';

import { has } from '../../../../lib/has';
import { comparator } from './comparator';
const breakdown = (spec, config) => response => {
  const {
    metrics = {},
    metadata: properties = {}
  } = spec;
  const {
    sort: [{
      property,
      order
    } = {}] = []
  } = config;
  const {
    breakdowns = []
  } = response;
  const getter = has(metrics, property) ? fields => fields[property] : has(properties, property) ? ({
    metadata: fields = {}
  } = {}) => fields[property] : null;
  return getter ? Object.assign({}, response, {
    breakdowns: breakdowns.sort(comparator(getter, order))
  }) : response;
};
export const get = (spec, config) => response => {
  const {
    dimensions = [],
    sort: [sort] = []
  } = config;
  if (sort == null) {
    return response;
  }
  return dimensions[0] === 'sessionDate' ? response : breakdown(spec, config)(response);
};