'use es6';

import formatProperties from '../formatters/formatProperties';
import get from 'transmute/get';
import http from 'hub-http/clients/apiClient';
const BASE_URL = 'properties/v4/groups/engagement?includeProperties=true';
const getResults = get('results');
export const createGetEngagementProperties = ({
  httpClient
}) => () => httpClient.get(BASE_URL).then(getResults).then(formatProperties);
export const getEngagementProperties = createGetEngagementProperties({
  httpClient: http
});