'use es6';

import isEmpty from 'transmute/isEmpty';
import { Stack } from 'immutable';

/**
 * Base function for recursively traversing a subject
 * @example
 *   traverse(isZero, (result, next) => [...result, next], minusOne, [], 5);
 *   => [5, 4, 3, 2, 1]
 *
 * @param {function} shouldStop - given `next`, should traversal stop or keep going? (next => bool)
 * @param {function} setResult - (result, next) => result'
 * @param {function} setNext - next => next'
 * @param {*} initial - starting / default result
 * @param {*} subject - subject to be traversed
 * @returns {*}
 */
export const traverse = (shouldStop, setResult, setNext, initial, subject) => {
  const loop = (result, next) => {
    if (shouldStop(next)) {
      return result;
    }
    return loop(setResult(result, next), setNext(next));
  };
  return loop(initial, subject);
};

/**
 * Calls `traverse` using a `Stack`.
 * @param {function} setResult - (result, stack) => result'
 * @param {function} getItemsToAddToStack - returns a collection of items to add to the stack
 * @param {*} initial - starting / default result
 * @param {*} subject - subject to be traversed
 * @returns {*}
 */
export const traverseStack = (setResult, getItemsToAddToStack, initial, subject) => {
  const updateStack = stack => {
    const head = stack.first();
    const tail = stack.pop();
    const toBeStacked = getItemsToAddToStack(head, tail, stack);
    return tail.pushAll(toBeStacked);
  };
  const updateResult = (result, stack) => setResult(result, stack.first(), stack.pop());
  return traverse(isEmpty, updateResult, updateStack, initial, Stack(subject));
};