import { makePipelinesFetchKey, makePipelinesFetchUrl } from 'framework-data-schema-quick-fetch';
import { withQuickFetch } from 'framework-data-schema-quick-fetch/withQuickFetch';
export const fetchPipelines = ({
  httpClient,
  frameworkTypeIdentifier
}) => withQuickFetch({
  requestName: makePipelinesFetchKey({
    frameworkTypeIdentifier
  }),
  baseFetch: () => httpClient.get(makePipelinesFetchUrl({
    frameworkTypeIdentifier
  }))
});