'use es6';

export const BOOL = 'bool';
export const CURRENCY_NUMBER = 'currency_number';
export const DATE = 'date';
export const DATE_TIME = 'datetime';
export const ENUMERATION = 'enumeration';
export const NUMBER = 'number';
export const OBJECT_COORDINATES = 'object_coordinates';
export const PHONE_NUMBER = 'phone_number';
export const STRING = 'string';