'use es6';

import get from 'transmute/get';
export const getId = get('id');
export const getName = get('name');
export const getOwnerId = get('ownerId');
export const getDemo = get('demo');
export const getWidgetCount = get('widgetCount');
export const getUserPermissionLevel = get('userPermissionLevel');
export const getCreatedAt = get('createdAt');
export const getWidgets = get('widgets');
export const getPinnedPropertyGroups = get('filterGroups');