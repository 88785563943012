import { trackIfChanged } from '../worker/trackIfChanged';
import { setInIDB, getFromIDB } from '../indexeddb/indexeddb';
import { Metrics } from '../metrics';
import { runWorker } from '../worker/runWorker';
import { createInMemoryCache } from './createInMemoryCache';
import enviro from 'enviro';
const isDeployed = enviro.deployed();

// Default to 1 day of staleness tolerance
export const DEFAULT_STALENESS_CUTOFF = 1000 * 60 * 60 * 24;
export const createPersistedAsyncCache = ({
  cacheName,
  stalenessCutoff = DEFAULT_STALENESS_CUTOFF,
  allowEarlyCachedDataReturn = false,
  segmentKey,
  metrics
}) => {
  const __cache = createInMemoryCache({
    cacheName
  });
  return Object.assign({}, __cache, {
    readThrough: ({
      cacheKey,
      loadValue,
      refresh
    }) => __cache.readThrough({
      cacheKey,
      refresh,
      loadValue: () => {
        // Fetch the data
        const loadPromise = loadValue().then(result => {
          // Overwrite any currently-persisted data with the result (but don't wait on it)
          setInIDB(cacheKey, {
            data: result,
            storedAt: Date.now()
          }).catch(() => {
            // Errors are ignored, because we don't really care if this persists.
            // It's an optimization, the rest of the load will function just fine without it.
          });
          return result;
        });

        // Kick off a read from IndexedDB for the data. Rejects if the data isn't present, or was stale.
        const indexedDBReadPromise = getFromIDB(cacheKey).then(({
          storedAt,
          data
        }) => {
          // Ensures we only return cached data for a certain amount of time, to prevent things
          // from getting permanently stuck
          if (storedAt + stalenessCutoff > Date.now()) {
            // Fire metrics collection code. Swallow all errors and don't await this — it
            // is for metrics only and is fine if it crashes out.
            loadPromise.then(loadResult => runWorker({
              current: loadResult,
              previous: data,
              extraProcessing: metrics && metrics.extraMetricsProcessing
            })).then(({
              isEqual,
              extraProcessingResult
            }) => {
              const segment = segmentKey(cacheKey);

              // If additional processing has been requested, pass the output
              // of the processing to the handler.
              if (metrics) {
                try {
                  metrics.handleExtraProcessingResult({
                    segment,
                    extraProcessingResult
                  });
                } catch (e) {
                  // Do nothing. If the requested additional processing errors out, we'd still like
                  // to track the standardaized metric below.
                }
              }

              // This is the standardized "did this data change since the prior request" metric
              // It relies on indexeddb reads/writes, so it is async.
              return trackIfChanged({
                cacheKey,
                cacheName,
                segment,
                isEqual
              });
            }).catch(err => {
              if (!isDeployed) {
                console.error(err);
              }
            });
            return data;
          }

          // Throwing here makes error handling in the combined promises smoother.
          throw Error('Result was stale');
        });

        // If either promise rejects, we want to fall back to the other. To prevent circular dependency
        // issues, we orchestrate this by "enhancing" each promise with the expected error handling behavior
        // as separate calls from the initial setup.

        // If the load promise rejects, fall back on the indexeddb promise.
        const enhancedLoadPromise = loadPromise.catch(loadError => indexedDBReadPromise.then(result => {
          Metrics.counter('fault-tolerant-data-used', {
            cacheName
          }).increment();
          return result;
        }).catch(() => {
          // If the indexeddb promise rejects, surface the load error instead. This presents
          // an actionable/useful error to the caller, rather than surfacing "random issue with indexeddb".
          // This is a terminal state — both promises have rejected, so we cannot satisfy the request.
          throw loadError;
        }));
        const enhancedIndexedDBReadPromise = indexedDBReadPromise
        // If IndexedDB wins the race, we need to do an additional check to ensure that
        // this application can tolerate having data that might be up to a day out of date
        .then(results => allowEarlyCachedDataReturn ? results : loadPromise).catch(
        // If the indexeddb promise rejects, fall back on the load promise. If the load promise rejects,
        // we have reached a terminal state — both promises have rejected, so we cannot satisfy the request.
        () => loadPromise);

        // Race for either "enhanced" promise to complete. Both have the correct error handling built in, so we
        // don't care which settles first — we simply want to settle when either settles.
        return Promise.race([enhancedLoadPromise, enhancedIndexedDBReadPromise]);
      }
    })
  });
};