import { AsyncQueryStatus as AsyncQueryStatuses } from 'reporting-data/constants/snowflakeQuery';
import * as checked from 'reporting-data/lib/checked';
import { Dataset } from 'reporting-data/v2/dataset/datasetRecords';
import { RelationalReport } from '../schema/report-records';
import { DatasetInsightParams } from 'reporting-data/tsTypes/datasetInsightTypes';
export const ReportOptions = checked.record({
  limit: checked.number().optional(),
  offset: checked.number().optional(),
  fetchFromCache: checked.boolean().optional(),
  updateCache: checked.boolean().optional(),
  reportIdForBustingCache: checked.number().optional(),
  isDrilldownRequest: checked.boolean().optional()
}, 'ReportOptions');
export const ReportWithOptions = checked.record({
  reportId: checked.string().optional(),
  reportDefinition: RelationalReport,
  reportOptions: ReportOptions,
  insightParams: DatasetInsightParams.optional(),
  dashboardId: checked.number().optional(),
  reportDashboardInfo: checked.list().optional(),
  id: checked.number().optional(),
  // TODO: remove the following when reporting v2 api doesn't need querifiedDefinition
  querifiedDefinition: RelationalReport.optional()
}, 'ReportWithOptions');
export const AsyncQueryStatus = AsyncQueryStatuses;
export const AsyncQueryResponse = checked.record({
  status: checked.symbol(AsyncQueryStatus, 'AsyncQueryStatus').optional(),
  dataset: Dataset.optional(),
  id: checked.string().optional()
}, 'AsyncQueryResponse');
export class AsyncQueryError extends Error {}
export class DeprecatedDataSourcesError extends Error {}
export class MaximumColumnsCountExceededError extends Error {}

// error subclasses
export class AsyncQueryCancelled extends AsyncQueryError {}
export class AsyncQueryFailed extends AsyncQueryError {}
export class AsyncQueryTimeout extends AsyncQueryError {}