'use es6';

import { AD_OBJECT_AD_ACCOUNT } from 'reference-resolvers/constants/ReferenceObjectTypes';
import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import { ADS_ACCOUNTS } from 'reference-resolvers/constants/CacheKeys';
import { createFetchAdsObjectSearchPage, createFetchAdsObjectsByIds, fetchAdsObjectsByIds, fetchAdsObjectSearchPage } from 'reference-resolvers/api/AdsEntityApi';
export const createAdsAdAccountsReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: ADS_ACCOUNTS,
  createFetchByIds: opts => createFetchAdsObjectsByIds(opts)(AD_OBJECT_AD_ACCOUNT),
  createFetchSearchPage: opts => createFetchAdsObjectSearchPage(opts)(AD_OBJECT_AD_ACCOUNT),
  fetchByIds: fetchAdsObjectsByIds(AD_OBJECT_AD_ACCOUNT),
  fetchSearchPage: fetchAdsObjectSearchPage(AD_OBJECT_AD_ACCOUNT)
}, options));
export default createAdsAdAccountsReferenceResolver();