// @ts-expect-error Untyped dependency
import { SimpleDate } from 'UIComponents/core/SimpleDate';
export const DATE_FORMAT = 'YYYYMMDD';
export const MIN_DATE_RANGE_DAYS = 31;
export const MAX_DATE_RANGE_DAYS = 1116;
export const DEFAULT_DATE_RANGE = 90;

// 2019 January 1st (0 indexed month)
export const RELIABLE_DATE_MINIMUM = new SimpleDate(2019, 0, 1);
export const DATE_RANGE_TYPES = {
  ROLLING: 'ROLLING',
  STATIC: 'STATIC'
};
export const PROPERTY_FILTER_OPERATION_CONSTANTS = {
  operator: 'IN_LAST_TIME_UNIT',
  timeUnit: 'DAY',
  defaultValue: null,
  operatorName: 'IN_LAST_TIME_UNIT',
  propertyType: 'calendar-date',
  operationType: 'calendar-date',
  useFiscalYear: false,
  fiscalYearStart: null,
  includeObjectsWithNoValueSet: false
};