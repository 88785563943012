'use es6';

import { has } from '../../../lib/has';
import { pairs } from './metrics';
import { one } from './downgrade';
const parse = (spec, config) => response => {
  const {
    dimensions,
    metrics = []
  } = config;
  const {
    total,
    hydratedDeals
  } = response;
  const keys = hydratedDeals.map(({
    dealId
  }) => dealId);
  const remapped = hydratedDeals.reduce((mapped, deal) => Object.assign({}, mapped, {
    [deal.dealId]: deal
  }), {});
  const matrix = {
    dimensions,
    metrics: pairs(config),
    keys: [keys],
    data: keys.map(key => metrics.map(({
      property
    }) => has(remapped, key) ? has(remapped[key], property) ? remapped[key][property] : has(remapped[key].metadata, property) ? remapped[key].metadata[property] : null : null)),
    total: [total]
  };
  return {
    response,
    matrix
  };
};
export const get = (spec, config) => ({
  url: `${spec.url}/deals-influenced`,
  parse: parse(spec, config),
  downgrade: one
});