'use es6';

import { Set as ImmutableSet } from 'immutable';
import * as dataTypes from '../constants/dataTypes';
const BLOCKLIST = {
  [dataTypes.DEALS]: ImmutableSet(['associations.company', 'associations.contact', '_inbounddbio.importid_']),
  [dataTypes.CONTACTS]: ImmutableSet(['_inbounddbio.importid_']),
  [dataTypes.COMPANIES]: ImmutableSet(['_inbounddbio.importid_'])
};
const getBlocklist = dataType => BLOCKLIST[dataType] || ImmutableSet();
export function isBlocklistedForCrossObject(dataType, property) {
  const propertyName = property.get('name');
  return propertyName === 'count' || getBlocklist(dataType).includes(propertyName) || property.get('scripted', false);
}