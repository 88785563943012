import { useCallback, useEffect } from 'react';
export function useEmbeddedCommentClickListener({
  onUpdateCollaborationSidebarState,
  isOpen,
  prevIsOpen
}) {
  const onWindowClick = useCallback(e => {
    onUpdateCollaborationSidebarState({
      commentableAreaClickCoordinates: {
        x: e.pageX,
        y: e.pageY
      }
    });
  }, [onUpdateCollaborationSidebarState]);
  useEffect(() => {
    if (isOpen && !prevIsOpen) {
      window.addEventListener('click', onWindowClick);
    }
  }, [isOpen, prevIsOpen, onWindowClick]);
  useEffect(() => {
    if (!isOpen && prevIsOpen) {
      window.removeEventListener('click', onWindowClick);
    }
  }, [isOpen, prevIsOpen, onWindowClick]);
}