export const TypeNames = {
  UNDEFINED: 'UNDEFINED',
  NULL: 'NULL',
  // Literals
  NULL_LITERAL: 'NULL_LITERAL',
  BOOLEAN_LITERAL: 'BOOLEAN_LITERAL',
  NUMERIC_LITERAL: 'NUMERIC_LITERAL',
  STRING_LITERAL: 'STRING_LITERAL',
  // Primitives
  BOOLEAN: 'BOOLEAN',
  NUMBER: 'NUMBER',
  STRING: 'STRING',
  DATE: 'DATE',
  DATETIME: 'DATETIME',
  // Property
  PROPERTY: 'PROPERTY',
  // Union
  UNION: 'UNION',
  // Function
  GENERIC: 'GENERIC',
  FUNCTION: 'FUNCTION'
};
export const PropertyTypeNames = {
  number: 'number',
  string: 'string',
  bool: 'bool',
  enumeration: 'enumeration',
  datetime: 'datetime',
  date: 'date',
  phone_number: 'phone_number',
  currency_number: 'currency_number',
  json: 'json',
  object_coordinates: 'object_coordinates'
};

/* Undefined and Null  */

export const isUndefinedType = type => type.type === TypeNames.UNDEFINED;
export const isNullType = type => type.type === TypeNames.NULL;

/* Literals */

export const isBooleanLiteralType = type => type.type === TypeNames.BOOLEAN_LITERAL;
export const isNumericLiteralType = type => type.type === TypeNames.NUMERIC_LITERAL;
export const isStringLiteralType = type => type.type === TypeNames.STRING_LITERAL;
export const isLiteralType = type => isBooleanLiteralType(type) || isNumericLiteralType(type) || isStringLiteralType(type);

/* Primitive types */

export const isBooleanType = type => type.type === TypeNames.BOOLEAN;
export const isNumberType = type => type.type === TypeNames.NUMBER;
export const isStringType = type => type.type === TypeNames.STRING;
export const isDateType = type => type.type === TypeNames.DATE;
export const isDatetimeType = type => type.type === TypeNames.DATETIME;
export const isPrimitiveType = type => isBooleanType(type) || isNumberType(type) || isStringType(type) || isDateType(type) || isDatetimeType(type);

/* Property types */

export const isPropertyType = type => type.type === TypeNames.PROPERTY;

/* Union types */

export const isUnionType = type => type.type === TypeNames.UNION;

/* Function types */

export const isGenericType = type => type.type === TypeNames.GENERIC;
export const isFunctionType = type => type.type === TypeNames.FUNCTION;

/* Type */

export const createBooleanLiteralType = value => ({
  type: TypeNames.BOOLEAN_LITERAL,
  value
});
export const createNumericLiteralType = value => ({
  type: TypeNames.NUMERIC_LITERAL,
  value
});
export const createStringLiteralType = value => ({
  type: TypeNames.STRING_LITERAL,
  value
});
export const createPropertyType = propertyType => ({
  type: TypeNames.PROPERTY,
  propertyType
});
export const createUnionType = (...types) => ({
  type: TypeNames.UNION,
  types
});
export const createGenericType = name => ({
  type: TypeNames.GENERIC,
  name
});
export const createFunctionType = (name, ...signatures) => ({
  type: TypeNames.FUNCTION,
  name,
  aggregate: false,
  signatures
});
export const createAggregateFunctionType = (name, ...signatures) => ({
  type: TypeNames.FUNCTION,
  name,
  aggregate: true,
  signatures
});
export const UNDEFINED_TYPE = {
  type: TypeNames.UNDEFINED,
  value: undefined
};
export const NULL_TYPE = {
  type: TypeNames.NULL,
  value: null
};
export const toOptionalType = type => createUnionType(type, UNDEFINED_TYPE);
export const toNullableType = type => createUnionType(type, NULL_TYPE);
export const BOOLEAN_TYPE = {
  type: TypeNames.BOOLEAN
};
export const NUMBER_TYPE = {
  type: TypeNames.NUMBER
};
export const STRING_TYPE = {
  type: TypeNames.STRING
};
export const DATE_TYPE = {
  type: TypeNames.DATE
};
export const DATETIME_TYPE = {
  type: TypeNames.DATETIME
};
export const NULLABLE_BOOLEAN_TYPE = toNullableType(BOOLEAN_TYPE);
export const NULLABLE_NUMBER_TYPE = toNullableType(NUMBER_TYPE);
export const NULLABLE_STRING_TYPE = toNullableType(STRING_TYPE);
export const NULLABLE_DATE_TYPE = toNullableType(DATE_TYPE);
export const NULLABLE_DATETIME_TYPE = toNullableType(DATETIME_TYPE);
export const PROPERTY_TYPES = {
  NUMBER: createPropertyType(PropertyTypeNames.number),
  STRING: createPropertyType(PropertyTypeNames.string),
  BOOL: createPropertyType(PropertyTypeNames.bool),
  ENUMERATION: createPropertyType(PropertyTypeNames.enumeration),
  DATE: createPropertyType(PropertyTypeNames.date),
  DATETIME: createPropertyType(PropertyTypeNames.datetime),
  PHONE_NUMBER: createPropertyType(PropertyTypeNames.phone_number),
  CURRENCY_NUMBER: createPropertyType(PropertyTypeNames.currency_number),
  JSON: createPropertyType(PropertyTypeNames.json),
  OBJECT_COORDINATES: createPropertyType(PropertyTypeNames.object_coordinates)
};