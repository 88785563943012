'use es6';

import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
import { fromJS, List, Map as ImmutableMap } from 'immutable';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import indexBy from 'transmute/indexBy';
import reduce from 'transmute/reduce';
const formatPropertyReference = property => {
  const accessLevel = getIn(['fieldLevelPermission', 'accessLevel'], property);
  if (accessLevel) {
    property.accessLevel = accessLevel;
  }
  return new ReferenceRecord({
    id: property.name,
    label: property.label,
    description: property.type,
    referencedObject: fromJS(property)
  });
};
const formatProperties = propertyGroups => {
  return reduce(ImmutableMap(), (acc, propertyGroup) => acc.merge(indexBy(get('id'), List(propertyGroup.properties).map(formatPropertyReference))), List(propertyGroups));
};
export default formatProperties;