'use es6';

import * as propertyDescriptionTranslator from 'property-description-translator';
import { ObjectTypeIdRegex } from 'customer-data-filters/filterQueryFormat/ObjectTypeId';
import { ObjectTypesToIds } from 'customer-data-objects/constants/ObjectTypeIds';
export const getTranslatedFieldDescription = ({
  fieldDescription,
  fieldName,
  filterFamily
}) => {
  // propertyDescriptionTranslator will throw an error if it gets an
  // objectTypeId that doesn't start with 0 (which means it's hubspot defined)
  const objectTypeId = ObjectTypeIdRegex.test(filterFamily) && filterFamily[0] === '0' ? filterFamily : ObjectTypesToIds[filterFamily];
  return objectTypeId && fieldDescription && fieldName && propertyDescriptionTranslator.propertyDescriptionTranslator({
    name: fieldName,
    description: fieldDescription,
    objectTypeId
  }) || fieldDescription;
};