'use es6';

// Filter types specific to Object Seg
export const ADS_SEARCH = 'ADS_SEARCH';
export const ADS_TIME = 'ADS_TIME';
export const CTA = 'CTA';
export const CONSTANT = 'CONSTANT';
export const EMAIL_EVENT = 'EMAIL_EVENT'; // aka DS EMAIL_CAMPAIGN
export const EMAIL_SUBSCRIPTION = 'EMAIL_SUBSCRIPTION';
export const EVENT = 'EVENT';
export const FORM_SUBMISSION = 'FORM_SUBMISSION';
export const FORM_SUBMISSION_ON_PAGE = 'FORM_SUBMISSION_ON_PAGE';
export const IN_LIST = 'IN_LIST';
export const INTEGRATION_EVENT = 'INTEGRATION_EVENT';
export const IN_IMPORT = 'IN_IMPORT';
export const PAGE_VIEW = 'PAGE_VIEW';
export const PRIVACY_CONSENT = 'PRIVACY';
export const PROPERTY = 'PROPERTY';
export const SURVEY_MONKEY = 'SURVEY_MONKEY';
export const WEBINAR = 'WEBINAR';
export const WORKFLOW = 'WORKFLOW';
export const SURVEY_MONKEY_VALUE = 'SURVEY_MONKEY_VALUE';
export const ASSOCIATION = 'ASSOCIATION';