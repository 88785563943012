import { isConfigReport, isJourneyReport } from '../../tsTypes/reportTypes';
import { getDimensions, getMetrics } from '../configReportGetters';
export const configReportHasAtLeastOneDimension = report => {
  if (report && isConfigReport(report)) {
    const metrics = getMetrics(report);
    const reportDimensions = getDimensions(report);
    if (reportDimensions.size >= 1 && metrics.size > 0) {
      return true;
    }
  }
  return false;
};
export const configReportValidForNonSeriesTransformation = report => {
  if (report && isConfigReport(report)) {
    const metrics = getMetrics(report);
    const reportDimensions = getDimensions(report);
    if ((reportDimensions.size === 0 && metrics.size > 0 || reportDimensions.size === 1 && metrics.size === 1) && reportDimensions.size < 2) {
      return true;
    }
  }
  return false;
};
export const isReportValidForColumnJourneyTransform = report => {
  if (isJourneyReport(report)) {
    const journeyReportStages = report.getIn(['journeyQuery', 'stages'], []);
    const journeyReportHasOptionalStages = !!journeyReportStages.find(stage => stage.get('optional'));

    // am hiding column report transform optionally until this task completed: https://issues.hubspotcentral.com/browse/SR-9
    if (!journeyReportHasOptionalStages) {
      return true;
    }
  }
  return false;
};