'use es6';

import { fromJS, List, Map as ImmutableMap } from 'immutable';
import chunk from '../../lib/async/chunk';
import { Promise } from '../../lib/promise';
import toJS from '../../lib/toJS';
import * as http from '../../request/http';
import { makeOption } from '../Option';
import I18n from 'I18n';
const getTickets = (tickets = List()) => chunk(group => http.post('contacts/search/v1/search/services/tickets?properties=subject', {
  data: {
    count: group.size,
    filterGroups: [{
      filters: [{
        property: 'hs_ticket_id',
        operator: 'IN',
        values: group.toArray()
      }]
    }]
  }
}).then(toJS), responses => responses[0].results, tickets);
export const generateTicketLabel = (ticketInfo, id) => {
  const maybeTicketName = ticketInfo.get('subject') || ticketInfo.getIn(['properties', 'subject', 'value']);
  if (id) {
    return maybeTicketName ? `${maybeTicketName} (${id})` : I18n.text('reporting-data.references.ticket.unknown-with-id', {
      id
    });
  }
  return maybeTicketName || I18n.text('reporting-data.references.ticket.unknown');
};
export default (ids => {
  if (ids.isEmpty()) {
    return Promise.resolve(ImmutableMap());
  }
  const sanitized = ids.reduce((memo, id) => {
    const parsed = Number(id);
    return parsed ? memo.set(String(parsed), id) : memo;
  }, ImmutableMap());
  return getTickets(sanitized.keySeq().toList()).then(tickets => fromJS(tickets).reduce((options, ticketInfo = ImmutableMap()) => {
    const ticketId = ticketInfo.get('objectId');
    return options.set(String(ticketId), makeOption(sanitized.get(ticketId, ticketId), generateTicketLabel(ticketInfo)));
  }, ImmutableMap()));
});