import * as checked from 'reporting-data/lib/checked';
import { Encoding } from '../column-records';
import { ColorOption, showDataLabels, showRecordIds, legendPosition, legendWidth } from '../highcharts-settings';
import { VisualTypes } from '../constants/visual-constants';
export const DonutEncodings = checked.record({
  color: Encoding.optional(),
  values: checked.list(Encoding)
}, 'DonutEncodings');
export const DonutVisualOptions = checked.record({
  showDataLabels,
  showRecordIds,
  color: ColorOption.defaultValue({}),
  showTotals: checked.boolean().defaultValue(true),
  legendPosition,
  legendWidth
});
export const DonutVisual = checked.record({
  type: checked.string().always(VisualTypes.DONUT),
  encodings: DonutEncodings,
  options: DonutVisualOptions.defaultValue({})
}, 'DonutVisual');