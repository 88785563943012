'use es6';

import { has } from '../../../lib/has';
export const create = (name, field) => ({
  get: (spec, config) => {
    const {
      filters: {
        custom = []
      } = {}
    } = config;
    return custom.reduce((memo, filter) => {
      const {
        property,
        value,
        values
      } = filter;
      if (property !== name) {
        return memo;
      }
      const key = typeof field === 'function' ? field(filter) : field || name;
      if (!has(memo, key)) {
        memo[key] = [];
      }
      memo[key] = [...memo[key], ...(value != null ? [value] : values)];
      return memo;
    }, {});
  }
});