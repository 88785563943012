export const USER_ATTRIBUTE_FETCH_PENDING = 'USER_ATTRIBUTE_FETCH_PENDING';
export const USER_ATTRIBUTE_FETCH_SUCCESS = 'USER_ATTRIBUTE_FETCH_SUCCESS';
export const USER_ATTRIBUTE_FETCH_FAILURE = 'USER_ATTRIBUTE_FETCH_FAILURE';
export const USER_ATTRIBUTE_UPDATE_PENDING = 'USER_ATTRIBUTE_UPDATE_PENDING';
export const USER_ATTRIBUTE_UPDATE_SUCCESS = 'USER_ATTRIBUTE_UPDATE_SUCCESS';
export const USER_ATTRIBUTE_UPDATE_FAILURE = 'USER_ATTRIBUTE_UPDATE_FAILURE';
export const USER_ATTRIBUTE_REMOVE_PENDING = 'USER_ATTRIBUTE_REMOVE_PENDING';
export const USER_ATTRIBUTE_REMOVE_SUCCESS = 'USER_ATTRIBUTE_REMOVE_SUCCESS';
export const USER_ATTRIBUTE_REMOVE_FAILURE = 'USER_ATTRIBUTE_REMOVE_FAILURE';
export const HUB_SETTINGS_FETCH_PENDING = 'HUB_SETTINGS_FETCH_PENDING';
export const HUB_SETTINGS_FETCH_SUCCESS = 'HUB_SETTINGS_FETCH_SUCCESS';
export const HUB_SETTINGS_FETCH_FAILURE = 'HUB_SETTINGS_FETCH_FAILURE';
export const HUB_SETTINGS_UPDATE_PENDING = 'HUB_SETTINGS_UPDATE_PENDING';
export const HUB_SETTINGS_UPDATE_SUCCESS = 'HUB_SETTINGS_UPDATE_SUCCESS';
export const HUB_SETTINGS_UPDATE_FAILURE = 'HUB_SETTINGS_UPDATE_FAILURE';

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}