import I18n from 'I18n';
// @ts-expect-error migrate upstream
import { getUserLabel as formatUserLabel } from 'dashboard-lib/public/users/users-logic';
// @ts-expect-error migrate upstream
import OwnersStore from '../stores/OwnersStore';
// @ts-expect-error migrate upstream
import TeamsStore from '../stores/TeamsStore';
let COMMON_LABELS = null;
function getCommonLabels(label) {
  if (COMMON_LABELS == null) {
    COMMON_LABELS = {
      NO_VALUE: I18n.text('objectType.GLOBAL.noValue'),
      USER_DEACTIVATED: I18n.text('objectType.GLOBAL.user.deactivated'),
      OWNER_UNASSIGNED: I18n.text('objectType.GLOBAL.owner.unassigned'),
      TEAM_UNASSIGNED: I18n.text('objectType.GLOBAL.team.unassigned'),
      ME: I18n.text('objectType.GLOBAL.owner.me'),
      MY_TEAM: I18n.text('objectType.GLOBAL.team.myTeam')
    };
  }
  return COMMON_LABELS[label];
}
function formatTeamLabel(value) {
  if (!value) {
    return getCommonLabels('TEAM_UNASSIGNED');
  } else if (value === '__hs__MY_TEAM') {
    return getCommonLabels('MY_TEAM');
  }
  const team = TeamsStore.get(Number(value));
  return team && team.get('name') ? team.get('name') : I18n.text('objectType.GLOBAL.team.unknown', {
    id: value
  });
}
export function getUserLabel(value, {
  showEmail = false
} = {}) {
  const user = OwnersStore.get('remotes').get(value);
  if (!value) {
    return getCommonLabels('OWNER_UNASSIGNED');
  }
  return formatUserLabel(user, showEmail);
}
export function getObjectPluralLabel(objectType) {
  return I18n.text(`reporting-data.dataTypes.${objectType}`, {
    defaultValue: I18n.text('objectType.missing.label.plural')
  });
}
export function getTeamLabel(key) {
  return formatTeamLabel(key);
}