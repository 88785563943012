'use es6';

import { create } from './strings';
const empty = object => Object.keys(object).length === 0;
export const get = (spec, config) => {
  const {
    d1 = {},
    d2 = {}
  } = spec;
  return Object.assign({}, empty(d1) ? {} : create('d1').get(spec, config), empty(d2) ? {} : create('d2').get(spec, config));
};