import isEmpty from 'transmute/isEmpty';
// @ts-expect-error migrate upstream
import { createKnownErrorFingerprint } from '../error/known-error';
/**
 * If this function returns a non-empty array, it will replace the default
 * fingerprinting mechanism in `report-management.capture`.
 *
 * For future extension:
 * this file can to be converted into setter-functions that allow other apps to
 * plug-in their own fingerprinters.
 *
 * Currently:
 * for now, it just points to report-management's fingerprinter,
 * `createKnownErrorFingerprint`
 */
export const createFingerprintFromError = createKnownErrorFingerprint;

/**
 * {{ default }} is used in sentry to represent the default grouping logic
 * For more info, visit: https://docs.sentry.io/data-management/rollups/?platform=browser#custom-grouping
 */
const DEFAULT_FINGERPRINT = '{{ default }}';
export const createFingerprint = (error, appendedFingerprints = []) => {
  const errorFingerprint = createFingerprintFromError(error);
  return isEmpty(errorFingerprint) ? [DEFAULT_FINGERPRINT, ...appendedFingerprints] : [...errorFingerprint, ...appendedFingerprints];
};