'use es6';

import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { getAllFeedbackForms, createGetAllFeedbackForms } from 'reference-resolvers/api/FeedbackFormsAPI';
import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
export const createFeedbackFormReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.FEEDBACK_FORMS,
  createFetchData: createGetAllFeedbackForms,
  fetchData: getAllFeedbackForms
}, options));
export default createFeedbackFormReferenceResolver();