'use es6';

import keyMirror from 'react-utils/keyMirror';
import curry from 'transmute/curry';
import get from 'transmute/get';
import ifElse from 'transmute/ifElse';
import compose from 'transmute/compose';
const ValidationType = keyMirror({
  SUCCESS: null,
  FAILURE: null
});
const createValidation = curry((type, value) => ({
  value,
  type
}));
export const getType = get('type');
export const getValue = get('value');
export const Success = createValidation(ValidationType.SUCCESS);
export const isSuccess = v => getType(v) === ValidationType.SUCCESS;
export const Failure = createValidation(ValidationType.FAILURE);
export const unwrap = ifElse(isSuccess);
export const unwrapValue = curry((onSuccess, onFailure, subject) => ifElse(isSuccess, compose(onSuccess, getValue), compose(onFailure, getValue), subject));