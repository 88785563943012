import { useState } from 'react';
import { buildFailedAsyncData, buildStartedAsyncData, buildSucceededAsyncData, buildUnitializedAsyncData, isSucceeded } from 'reporting-data/asyncData/AsyncData';
import { useBackendFilterPayload } from '../../../ducks/dashboard-reports';
import { createSavedDashboardFilterSet } from '../../../lib/savedDashboardFilters';
export const useCreateEmailOnlyAppliedFilterSet = dashboardId => {
  const [createEmailOnlyAsyncState, setCreateEmailOnlyAsyncState] = useState(buildUnitializedAsyncData());
  const currentAppliedFiltersBackendPayload = useBackendFilterPayload(dashboardId);
  const emailOnlyFilterSetId = isSucceeded(createEmailOnlyAsyncState) ? createEmailOnlyAsyncState.data : undefined;
  const createEmailOnlyFilterSet = () => {
    return createSavedDashboardFilterSet(Object.assign({
      emailOnly: true,
      name: 'EMAIL_ONLY',
      defaultFilter: false
    }, currentAppliedFiltersBackendPayload));
  };
  return {
    asyncState: createEmailOnlyAsyncState,
    emailOnlyFilterSetId,
    createEmailOnlyFilterSet: () => {
      setCreateEmailOnlyAsyncState(buildStartedAsyncData());
      return createEmailOnlyFilterSet().then(response => {
        setCreateEmailOnlyAsyncState(buildSucceededAsyncData(response.id));
        return response.id;
      }).catch(error => {
        console.error(error);
        setCreateEmailOnlyAsyncState(buildFailedAsyncData(error));
        throw error;
      });
    }
  };
};