import * as MetricTypes from '../../constants/metricTypes';
// @ts-expect-error immer intentionally exposes freeze but it's missing from the type definitions https://immerjs.github.io/immer/api/
import { freeze } from 'immer';
export const defaultDatasetBuildOptions = freeze({
  isSearch: false,
  isRelational: false,
  removeLastRowPercentiles: false,
  hasCurrencyDimension: () => false,
  isMeasure: () => false,
  getMeasureType: () => MetricTypes.SUM
});