import http from 'hub-http/clients/apiClient';
import userInfo from 'hub-http/userInfo';
const BASE_PATH = 'share-with-third-party/v1/';
const INTEGRATIONS_PATH = 'integrations';
const RECIPIENTS_PATH = 'recipients';
const SHARE_PATH = 'share';
const QUERY_LIMIT = 10;
export const fetchApps = () => http.get(`${BASE_PATH}${INTEGRATIONS_PATH}`).then(appsResponse => appsResponse.results);
export const fetchRecipients = (appId, name, type, after) => http.get(`${BASE_PATH}${RECIPIENTS_PATH}`, {
  query: {
    limit: QUERY_LIMIT,
    appId,
    type,
    name,
    after
  }
});
export const shareRecord = (recipient, recordToShare, message) => {
  const doPost = userId => {
    return http.post(`${BASE_PATH}${SHARE_PATH}`, {
      data: {
        userId,
        message,
        recipient,
        objectMetadata: recordToShare
      }
    });
  };
  return userInfo().then(({
    user
  }) => doPost(user.user_id));
};