'use es6';

import { MergeTagTypes } from 'draft-plugins/lib/mergeTagConstants';
export const INCLUDE_LINK_PREVIEW = 'include-link-preview';
export const INSERTED_DOCUMENT = 'inserted-document';
export const INSERTED_CONTACT_TOKEN = 'inserted-contact-token';
export const INSERTED_COMPANY_TOKEN = 'inserted-company-token';
export const INSERTED_DEAL_TOKEN = 'inserted-deal-token';
export const INSERTED_TICKET_TOKEN = 'inserted-ticket-token';
export const INSERTED_SENDER_TOKEN = 'inserted-sender-token';
export const INSERTED_CUSTOM_TOKEN = 'inserted-custom-token';
export const INSERTED_SNIPPET = 'inserted-snippet';
export const INSERTED_SNIPPET_FROM_POPOVER = 'inserted-snippet-from-popover';
export const INSERTED_CONTENT_FROM_POPOVER = 'inserted-content-from-popover';

// From usageTracking.ts (rich-text-lib)
export const INSERT_IMAGE = 'insert-image';
export const INSERT_HUBSPOT_VIDEO = 'insert-hubspot-video';
export const APPLY_COLOR_POPOVER = 'change-color';
export const CHANGE_INLINE_STYLE = 'change-inline-style';
export const CHANGE_BLOCK_FORMAT = 'change-block-format';
export const MAKE_ORDERED_LIST = 'make-ordered-list';
export const MAKE_UNORDERED_LIST = 'make-unordered-list';
export const INSERT_LINK = 'insert-link';
export const INSERT_PERSONALIZATION_TOKEN = 'insert-personalization-token';
export const insertedToken = prefix => {
  switch (prefix) {
    case MergeTagTypes.CONTACT:
      return INSERTED_CONTACT_TOKEN;
    case MergeTagTypes.COMPANY:
      return INSERTED_COMPANY_TOKEN;
    case MergeTagTypes.DEAL:
      return INSERTED_DEAL_TOKEN;
    case MergeTagTypes.TICKET:
      return INSERTED_TICKET_TOKEN;
    case MergeTagTypes.SENDER:
      return INSERTED_SENDER_TOKEN;
    case MergeTagTypes.PLACEHOLDER:
      return INSERTED_CUSTOM_TOKEN;
    default:
      return null;
  }
};