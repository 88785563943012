import { Iterable } from 'immutable';
import indexBy from 'transmute/indexBy';
import { getEventId, SentryEvent } from './sentry-event';
import { DASHBOARD_ERROR_BOUNDARY, DASHBOARD_FETCH_FAILURE, DRILLDOWN_FAILED, REPORT_ERROR, UNKNOWN_DASHBOARD_TYPE, UPDATE_53, WIDGET_ERROR_BOUNDARY, CREATE_URL_FAILED, EXPORT_ROUTE_ERROR_BOUNDARY, EMAIL_ROUTE_ERROR_BOUNDARY, ILLEGAL_DASHBOARD_NAME, FAILED_TO_SET_RENDERED, FAILED_TO_SET_OWNER, FAILED_TO_UPDATE_DASHBOARD_DETAILS, FAILED_TO_REMOVE_REPORT_FROM_DASHBOARD, STANDARD_DASHBOARD_EXEMPTION, USER_ATTRIBUTES_MISSING_ATTRIBUTE_PARAMETER, REPORT_DATA_ACCESS_DENIED, VIEW_REPORT_DATA_TYPE_ACCESS_DENIED, SINGLE_REPORT_VIEWER_ERROR_BOUNDARY, PINNED_PROPERTY_ERROR_BOUNDARY, SAVED_DASHBOARD_FILTER_PANEL_ERROR_BOUNDARY, APPLY_DASHBOARD_FILTERS_ERROR_BOUNDARY, DEFAULT_DASHBOARDS_APP_ERROR_BOUNDARY, PROPERTY_LIST_ERROR_BOUNDARY, QUICK_FILTERS_PANEL_ERROR_BOUNDARY, DASHBOARD_HEADER_ERROR_BOUNDARY, WIDGET_GRID_ERROR_BOUNDARY, REPORTING_ACTIONS_LOADER_ERROR_BOUNDARY } from './sentry-event-id';
import { CHRIS, ZOE } from './sentry-owners';
const EVENT_LIST = [
// SentryEvent({
//   id: DASHBOARD_REDIRECT_ERROR,
// }),
// SentryEvent({
//   id: CRM_DASHBOARD,
//   description: "are there dashboards with type = \"CRM\"? yes",
// }),
// SentryEvent({
//   id: DASHBOARD_TYPE_MISMATCH,
//   name: 'Mismatched Dashboard Type',
//   description: 'a dashboard has a route and type mismatch',
// }),
SentryEvent({
  id: UNKNOWN_DASHBOARD_TYPE,
  name: 'Unknown Dashboard Type',
  description: 'watch for dashboards with unknown type'
}), SentryEvent({
  id: DASHBOARD_ERROR_BOUNDARY,
  name: 'Dashboard ErrorBoundary',
  description: 'caught in the dashboard error boundary'
}), SentryEvent({
  id: WIDGET_ERROR_BOUNDARY,
  name: 'Widget ErrorBoundary',
  description: 'caught in the widget error boundary'
}), SentryEvent({
  id: DASHBOARD_FETCH_FAILURE,
  name: 'Failed to fetch dashboard',
  description: 'Trigger and track when the dashboard endpoint fails.'
}), SentryEvent({
  id: UPDATE_53,
  name: 'Updated 53 dashboard',
  description: 'todo',
  owner: ZOE
}), SentryEvent({
  id: REPORT_ERROR,
  name: 'Report error',
  description: 'Triggered when a report produces an error. Audited by guided reporting.',
  owner: ZOE
}), SentryEvent({
  id: DRILLDOWN_FAILED,
  name: 'Failed to drilldown',
  description: 'Triggered when a report fails to drilldown. Audited by guided reporting.',
  owner: CHRIS
}), SentryEvent({
  id: CREATE_URL_FAILED,
  name: 'Failed to `createUrl`',
  description: 'Triggered createUrl fails with an exception, usually means there is a missing path parameter.'
}), SentryEvent({
  id: EXPORT_ROUTE_ERROR_BOUNDARY,
  name: 'Export ErrorBoundary',
  description: 'Error caught in the export dashboard route. This means the export dashboard container has has crashed.'
}), SentryEvent({
  id: EMAIL_ROUTE_ERROR_BOUNDARY,
  name: 'Email ErrorBoundary',
  description: 'Error caught in the email dashboard route. This means the email dashboard container has has crashed.'
}), SentryEvent({
  id: ILLEGAL_DASHBOARD_NAME,
  name: 'Illegal dashboard name',
  description: 'watch for dashboards with illegal name'
}), SentryEvent({
  id: FAILED_TO_SET_RENDERED,
  name: 'failed to set widget as rendered',
  description: 'DataDecorator should always be passed a setIsRendered function'
}), SentryEvent({
  id: FAILED_TO_SET_OWNER,
  name: 'failed to set owner of a report',
  description: 'This might have occurred because the new owner does not have the necessary permissions. The more this happens, the more reason we should look into adding an endpoint that returns a filtered list of owners based on a minimum permission.'
}), SentryEvent({
  id: FAILED_TO_UPDATE_DASHBOARD_DETAILS,
  name: 'failed to update dashboard details',
  description: 'This might have occurred because of the selected owner lacking necessary permissions, a different problem with changing owner, or a problem with updating the dashboard name or description.'
}), SentryEvent({
  id: FAILED_TO_REMOVE_REPORT_FROM_DASHBOARD,
  name: 'failed to remove a report from a dashboard',
  description: 'Triggered when the remove report from dashboard flow errors out.'
}), SentryEvent({
  id: STANDARD_DASHBOARD_EXEMPTION,
  name: 'standard dashboard not locked down due to exemption',
  description: 'https://hubspot.slack.com/archives/C014CR02KJ4/p1596230113047900?thread_ts=1596227715.043400&cid=C014CR02KJ4'
}), SentryEvent({
  id: USER_ATTRIBUTES_MISSING_ATTRIBUTE_PARAMETER,
  name: 'user attributes endpoint is missing attributes parameter',
  description: 'A call to users/v1/app/attributes was made without the attributes parameter. This may potentially fetch hundreds of unneccesary user attributes and can affect app performance. https://hubspot.slack.com/archives/C014CR02KJ4/p1596230113047900?thread_ts=1596227715.043400&cid=C014CR02KJ4'
}), SentryEvent({
  id: REPORT_DATA_ACCESS_DENIED,
  name: 'user denied access to report data type',
  description: 'Triggered when an attempt to access report data results in a 403 error that describes missing scopes. When this occurs, check if the missing scopes should be added to FE data type access check.'
}), SentryEvent({
  id: VIEW_REPORT_DATA_TYPE_ACCESS_DENIED,
  name: 'user prevented from viewing report based on its data type',
  description: 'Triggered when the FE data type access check determines that the user is missing the scopes required to view reports of a certain data type'
}), SentryEvent({
  id: SINGLE_REPORT_VIEWER_ERROR_BOUNDARY,
  name: 'Single Report Viewer error boundary',
  description: 'caught in SingleReportViewerContainer error boundary'
}),
// New Sentry Events

SentryEvent({
  id: DEFAULT_DASHBOARDS_APP_ERROR_BOUNDARY,
  name: 'Default unnamed dashboards app error boundary',
  description: 'caught in an unnamed ErrorBoundary'
}), SentryEvent({
  id: PINNED_PROPERTY_ERROR_BOUNDARY,
  name: 'Pinned Property error boundary',
  description: 'caught in PinnedPropertyErrorBoundary error boundary'
}), SentryEvent({
  id: SAVED_DASHBOARD_FILTER_PANEL_ERROR_BOUNDARY,
  name: 'Saved Dashboard Filters panel error boundary',
  description: 'caught in SavedDashboardFiltersErrorBoundary error boundary'
}), SentryEvent({
  id: APPLY_DASHBOARD_FILTERS_ERROR_BOUNDARY,
  name: 'Apply Dashboard error boundary',
  description: 'caught in SingleReportViewerContainer error boundary'
}), SentryEvent({
  id: PROPERTY_LIST_ERROR_BOUNDARY,
  name: 'Property list error boundary',
  description: 'caught in PropertyList error boundary'
}), SentryEvent({
  id: QUICK_FILTERS_PANEL_ERROR_BOUNDARY,
  name: 'Quick Filters panel error boundary',
  description: 'caught in QuickFiltersPanelErrorBoundary error boundary'
}), SentryEvent({
  id: DASHBOARD_HEADER_ERROR_BOUNDARY,
  name: 'Dashboard header error boundary',
  description: 'Caught when a child of the dashboard header component throws an error'
}), SentryEvent({
  id: WIDGET_GRID_ERROR_BOUNDARY,
  name: 'Dashboard widget grid error boundary',
  description: 'Caught when a child of the widget grid throw an error'
}), SentryEvent({
  id: REPORTING_ACTIONS_LOADER_ERROR_BOUNDARY,
  name: 'Reporting Actions loader error boundary',
  description: 'Caught when an error within a reporting action components dialog throws an error'
})];
export const SentryEventMap = indexBy(getEventId, Iterable.Set(EVENT_LIST));
export const getEventById = eventId => SentryEventMap.get(eventId);
export const hasEventById = eventId => SentryEventMap.has(eventId);