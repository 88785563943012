'use es6';

import StandardErrorCategory from '../constants/StandardErrorCategory';
import StandardErrorSubCategory from '../constants/StandardErrorSubCategory';
function hasProperty(object, propertyName, propertyValue) {
  return object !== undefined && propertyName in object && object[propertyName] === propertyValue;
}
class SlackResponseStatusDeterminer {
  static isErrorResponse(slackResponse) {
    return slackResponse['@result'] === 'ERR';
  }
  static isInvalidAuthError(error) {
    return hasProperty(error, 'category', StandardErrorCategory.INVALID_AUTHENTICATION);
  }
  static isThirdPartyOutageError(error) {
    return hasProperty(error, 'subCategory', StandardErrorSubCategory.THIRD_PARTY_OUTAGE);
  }
}
export default SlackResponseStatusDeterminer;