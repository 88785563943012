'use es6';

import Raven from 'raven-js';
import devLogger from 'react-utils/devLogger';
export const logError = (message, error, key, ravenExtra = {}) => {
  devLogger.warn({
    message: `${message}: ${error}`,
    key
  });
  Raven.captureException(error, {
    level: 'error',
    extra: Object.assign({}, ravenExtra)
  });
};