// currently matching https://git.hubteam.com/HubSpot/IdentityBase/blob/master/src/main/protobuf/contacts.proto#L160
export const FieldTypes = {
  STRING: 'string',
  NUMBER: 'number',
  BOOL: 'bool',
  DATETIME: 'datetime',
  ENUMERATION: 'enumeration',
  DATE: 'date',
  PHONE_NUMBER: 'phone_number',
  CURRENCY_NUMBER: 'currency_number',
  JSON: 'json',
  OBJECT_COORDINATES: 'object_coordinates'
};
export const AggregationTypes = {
  COUNT: 'COUNT',
  DISTINCT_COUNT: 'DISTINCT_COUNT',
  SUM: 'SUM',
  AVERAGE: 'AVERAGE',
  MIN: 'MIN',
  MAX: 'MAX',
  MEDIAN: 'MEDIAN',
  DELEGATE: 'DELEGATE'
};