import { Map as ImmutableMap } from 'immutable';
export const PRIMARY_CONFIG_NAME = 'primary';
export function getConfigs(report) {
  return report.get('reportConfigs') || ImmutableMap();
}
export function getVisualization(report) {
  return report.get('visualization');
}
export function getConfigsIncludingPrimary(report) {
  return getConfigs(report).merge(ImmutableMap({
    [PRIMARY_CONFIG_NAME]: report.get('config')
  }));
}