import { ColumnSort, SortOrders, SortTypes } from '../../schema/sort-records';
import { Directions, Order } from 'reporting-visualizations/field/order';
import { List } from 'immutable';
export const DEFAULT_COLUMN_SORT = ColumnSort({
  priority: 0,
  type: SortTypes.VALUE,
  order: SortOrders.ASCENDING
});
export const SORT_ORDER_TO_DIRECTION = {
  [SortOrders.ASCENDING]: Directions.ascending,
  [SortOrders.DESCENDING]: Directions.descending
};
export const DEFAULT_ORDER = Order({
  direction: Directions.ascending
});
export const reverse = sort => {
  const order = sort.order;
  const nextOrder = order === SortOrders.ASCENDING ? SortOrders.DESCENDING : SortOrders.ASCENDING;
  const isTableSort = ('column' in sort);
  return isTableSort ? sort.set('order', nextOrder) : sort.set('order', nextOrder);
};
const SORT_ALIAS_PREFIX = 'sort_';
export const toSortDependencyAlias = dimensionAlias => `${SORT_ALIAS_PREFIX}${dimensionAlias}`;
export const createMeasureSortDependency = (dimension, measure, fixed = List.of(dimension.alias)) => {
  const alias = toSortDependencyAlias(dimension.alias);
  return measure && measure.set('alias', alias).set('fixedMeasure', true).set('fixed', fixed);
};