'use es6';

import keyMirror from 'react-utils/keyMirror';
export default keyMirror({
  ACTIVITY_FEED: null,
  MARKETING_PERFORMANCE: null,
  SALES_PERFORMANCE: null,
  DEAL_FORECAST: null,
  CONTACTS_OVERVIEW: null,
  PERSONA: null,
  WATERFALL: null,
  CHANGE_HISTORY_DEAL_LIST: null,
  DEAL_PUSH_ANALYSIS: null,
  PIPELINE_SNAPSHOTS: null,
  SALES_QUOTA: null,
  COMPLETED_ACTIVITIES: null,
  TASKS_STREAM: null,
  ENGAGEMENT_STREAM: null
});