'use es6';

import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { createGetAllInboundDbLists, getAllInboundDbLists } from 'reference-resolvers/api/InboundDbListsAPI';
export const createInboundDbCompanyListReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.INBOUND_DB_COMPANY_LIST,
  createFetchData: opts => createGetAllInboundDbLists(opts)('COMPANY'),
  fetchData: getAllInboundDbLists('COMPANY')
}, options));
export default createInboundDbCompanyListReferenceResolver();