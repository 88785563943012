'use es6';

import { fromJS, List } from 'immutable';
import getInboundDbPropertyGroups from '../../retrieve/inboundDb/common/properties';
import { TICKETS } from '../../constants/dataTypes';
import { mergeProperties } from '../mergeProperties';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import getQuotasProperties from '../partial/quotas';
import countProperty from '../partial/count-property';
import ticketsModule from '../../dataTypeDefinitions/inboundDb/tickets';
import overridePropertyTypes from '../../retrieve/inboundDb/common/overridePropertyTypes';
import prefix from '../../lib/prefix';
const translate = prefix('reporting-data.properties.tickets');
export const getPropertyGroups = () => getInboundDbPropertyGroups(TICKETS).then(groups => List([...groups])).then(groups => mergeProperties(groups, 'ticketinformation', {
  hubspot_team_id: {
    referencedObjectType: 'TEAM'
  },
  time_to_close: {
    type: 'duration'
  },
  time_to_first_agent_reply: {
    type: 'duration'
  }
}));
export const getProperties = () => createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(TICKETS)).merge(getQuotasProperties()).merge(fromJS({
  objectId: {
    name: 'objectId',
    label: translate('ticket'),
    type: 'string'
  }
})))().then(overridePropertyTypes(ticketsModule.getInboundSpec()));