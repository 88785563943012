'use es6';

import http from 'hub-http/clients/apiClient';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import get from 'transmute/get';
import { Map as ImmutableMap, fromJS } from 'immutable';
const URL = '/cosemail/v1/emails';
const formatEmail = formatToReferencesList({
  getId: get('id'),
  getLabel: get('name')
});
const formatEmails = formatToReferencesList({
  getId: get('contentId'),
  getLabel: get('name')
});
export const createGetEmailsByIds = ({
  httpClient
}) => ids => {
  const limit = ids && ids.length;
  const query = {
    id__in: ids.join(','),
    property: ['id', 'name'],
    limit
  };
  return httpClient.get(URL, {
    query
  }).then(get('objects')).then(formatEmail);
};
export const getEmailsByIds = createGetEmailsByIds({
  httpClient: http
});
export const createGetEmailsSearchPage = ({
  httpClient
}) => opts => {
  return httpClient.get(`${URL}/search`, {
    query: {
      limit: get('count', opts) || 100,
      offset: get('offset', opts) || 0,
      q: get('query', opts) || ''
    }
  }).then(response => {
    const {
      hasMore,
      results = [],
      offset
    } = response;
    return ImmutableMap({
      count: results.length,
      hasMore,
      offset,
      results: formatEmails(fromJS(response.results))
    });
  });
};
export const getEmailsSearchPage = createGetEmailsSearchPage({
  httpClient: http
});