import I18n from 'I18n';
// @ts-expect-error Untyped dependency
import formatShortMonthYear from 'I18n/utils/formatShortMonthYear';

// @ts-expect-error Untyped dependency
import * as SimpleDate from 'UIComponents/core/SimpleDate';
import { TODAY, YESTERDAY, THIS_WEEK, LAST_WEEK, THIS_MONTH, LAST_MONTH, LAST_THIRTY_DAYS, LAST_THREE_MONTHS, THIS_QUARTER, LAST_QUARTER, THIS_YEAR, LAST_YEAR } from 'UIComponents/dates/dateRangePresets';
import { DATE_FORMAT, DATE_RANGE_TYPES, PROPERTY_FILTER_OPERATION_CONSTANTS, RELIABLE_DATE_MINIMUM } from '../../constants/journeyDateRangeConstants';
import { ROLLING_DATE_TYPES } from 'reporting-data/constants/dateRangeTypes';
export function intervalFromDateInput(dateInfo) {
  if (dateInfo.type === DATE_RANGE_TYPES.STATIC) {
    const {
      startDate,
      endDate
    } = dateInfo.dateInput;
    return {
      dateFrom: startDate ? SimpleDate.toFormattedString(startDate, DATE_FORMAT) : undefined,
      dateTo: endDate ? SimpleDate.toFormattedString(endDate, DATE_FORMAT) : undefined
    };
  }
  return {
    propertyFilterOperation: Object.assign({}, PROPERTY_FILTER_OPERATION_CONSTANTS, {
      timeUnitCount: dateInfo.dateInput
    })
  };
}
export function dateRangeFromInterval({
  dateFrom,
  dateTo
}) {
  return {
    startDate: dateFrom ? SimpleDate.fromMoment(I18n.moment(dateFrom, DATE_FORMAT)) : null,
    endDate: dateTo ? SimpleDate.fromMoment(I18n.moment(dateTo, DATE_FORMAT)) : null
  };
}
export const getDaysFromDate = (date, days) => SimpleDate.fromMoment(SimpleDate.toMoment(date).add(days, 'day'));
export const dateIsOutsideBounds = (maxRange, {
  startDate,
  endDate
}) => {
  if (!startDate || !endDate) {
    return false;
  }
  return SimpleDate.compare(startDate, getDaysFromDate(endDate, -maxRange)) < 0;
};
export const getTodaysDate = () => SimpleDate.now();
export const getDateRangeBounds = (maxRange, {
  startDate,
  endDate
}) => {
  if (!startDate && !endDate) {
    // No dates set yet, only enforce minimum reliable date
    return {
      min: RELIABLE_DATE_MINIMUM
    };
  }
  return startDate ? {
    // start date exists, enforce reliable minimum and max as valid date bound
    min: RELIABLE_DATE_MINIMUM,
    max: getDaysFromDate(startDate, maxRange)
  } : {
    // only end date exists, enforce minimum as reliable minimum or valid
    // date range bound (whichever comes later)
    min: SimpleDate.maxDate([RELIABLE_DATE_MINIMUM, getDaysFromDate(endDate, maxRange * -1)])
  };
};
const alwaysTrueCondition = () => true;
export const STATIC_DATE_PRESETS = [{
  preset: TODAY,
  condition: alwaysTrueCondition
}, {
  preset: YESTERDAY,
  condition: alwaysTrueCondition
}, {
  preset: THIS_WEEK,
  condition: alwaysTrueCondition
}, {
  preset: LAST_WEEK,
  condition: alwaysTrueCondition
}, {
  preset: THIS_MONTH,
  condition: alwaysTrueCondition
}, {
  preset: LAST_MONTH,
  condition: alwaysTrueCondition
}, {
  preset: LAST_THIRTY_DAYS,
  condition: alwaysTrueCondition
}, {
  preset: LAST_THREE_MONTHS,
  condition: maxDays => maxDays >= 93
}, {
  preset: THIS_QUARTER,
  condition: maxDays => !dateIsOutsideBounds(maxDays, {
    startDate: SimpleDate.fromMoment(I18n.moment().startOf('quarter')),
    endDate: getTodaysDate()
  })
}, {
  preset: LAST_QUARTER,
  condition: maxDays => maxDays >= 91
}, {
  preset: THIS_YEAR,
  condition: maxDays => !dateIsOutsideBounds(maxDays, {
    startDate: SimpleDate.fromMoment(I18n.moment().startOf('year')),
    endDate: getTodaysDate()
  })
}, {
  preset: LAST_YEAR,
  condition: maxDays => maxDays >= 365
}];
export const getApplicablePresets = maxDays => Object.values(STATIC_DATE_PRESETS).filter(preset => preset.condition(maxDays)).map(presetObject => presetObject.preset);
export const dateIntervalIsComplete = maybeDateInterval => {
  return !!(maybeDateInterval.dateFrom && maybeDateInterval.dateTo) || !!(maybeDateInterval.propertyFilterOperation && maybeDateInterval.propertyFilterOperation.timeUnitCount);
};
export const getRollingDateRangeOptions = maxDays => Object.values(ROLLING_DATE_TYPES).map(days => ({
  text: I18n.text(`settingLabels.dateRange.journey.rollingOptions.${days.toString()}`),
  value: days,
  disabled: days > maxDays
}));
export const getEventCountDateRange = dateInterval => {
  const dateRange = dateRangeFromInterval(dateInterval);
  const today = getTodaysDate();
  const isStaticDate = !!(dateInterval.dateFrom && dateInterval.dateTo);
  const isRollingDate = !!dateInterval.propertyFilterOperation;
  return {
    startDate: formatShortMonthYear(isStaticDate ? dateRange.startDate : isRollingDate ? getDaysFromDate(today, -dateInterval.propertyFilterOperation.timeUnitCount) : undefined),
    endDate: formatShortMonthYear(isStaticDate ? dateRange.endDate : today)
  };
};
export const closestRollingDateRange = maxDays => {
  return Object.values(ROLLING_DATE_TYPES).reduce((prev, curr) => curr > maxDays ? prev : curr);
};