import { setSuperstoreCachedValue, getSuperstoreCachedValue, getSuperstoreKey } from '../superstore';
const RECENTLY_USED_FILTERS_LIMIT = 4;
export const isDuplicateProperty = (recentlyUsedProperties, propertyName) => {
  return recentlyUsedProperties.find(existingPropertyName => existingPropertyName === propertyName);
};
export const addToRecentlyUsedProperties = (recentlyUsedProperties, propertyName) => {
  return [propertyName, ...recentlyUsedProperties.slice(0, RECENTLY_USED_FILTERS_LIMIT)];
};
export const getRecentlyUsedProperties = ({
  dataSource
}) => {
  return getSuperstoreCachedValue(getSuperstoreKey(dataSource));
};
export const setRecentlyUsedProperties = ({
  dataSource,
  property
}) => {
  const propertyName = property.name;
  return getRecentlyUsedProperties({
    dataSource
  }).then(recentlyUsedProperties => {
    if (!recentlyUsedProperties) {
      return setSuperstoreCachedValue(getSuperstoreKey(dataSource), addToRecentlyUsedProperties([], propertyName));
    }

    // Do not update superstore if the applied property filter was already recently used.
    if (isDuplicateProperty(recentlyUsedProperties, propertyName)) {
      return Promise.resolve();
    }
    return setSuperstoreCachedValue(getSuperstoreKey(dataSource), addToRecentlyUsedProperties(recentlyUsedProperties, propertyName));
  }).catch(() => {
    console.error(`Error: could not set value in cache`);
  });
};