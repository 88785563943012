'use es6';

import { AssetPermission } from '../../../../public/permission/asset-permission';
import { hasSufficientPermission } from '../../../../public/permission/compute-permission';
import { getAndCheck } from '../../common/get-and-check-param';
import { getAllProblemsCurried } from '../../common/get-problems-no-check';
import { createProblem } from '../../common/problem';
import { IS_READ_ONLY_DEMO, USER_DASHBOARD_PERMISSION } from '../../params/all-params';
import { NEED_EDIT_PERMISSION } from '../../problems/permission-problem';
export const PERMISSION_DENIED = createProblem('PERMISSION_DENIED', params => {
  const dashboardPermission = getAndCheck(USER_DASHBOARD_PERMISSION, params);
  return !hasSufficientPermission(AssetPermission.EDIT, dashboardPermission);
});
export const IS_READ_ONLY_DEMO_PROBLEM = createProblem('IS_READ_ONLY_DEMO_PROBLEM', params => getAndCheck(IS_READ_ONLY_DEMO, params));
export const rankedProblems = [NEED_EDIT_PERMISSION, PERMISSION_DENIED, IS_READ_ONLY_DEMO_PROBLEM];
export const getAll = getAllProblemsCurried(rankedProblems);