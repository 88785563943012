/**
 * To be kept in sync with https://git.hubteam.com/HubSpot/ExternalOptions/blob/master/ExternalOptionsCore/src/main/java/com/hubspot/externaloptions/core/ReferenceType.java
 */
export const OWNER = 'OWNER';
export const TEAM = 'TEAM';
export const MULTI_CURRENCY_CURRENCY_CODE = 'MULTI_CURRENCY_CURRENCY_CODE';
export const PERSONA = 'PERSONA';
export const PIPELINE = 'PIPELINE';
export const PIPELINE_STAGE = 'PIPELINE_STAGE';
export const SEQUENCE = 'SEQUENCE';
export const WORKFLOW = 'WORKFLOW';
export const BUSINESS_UNIT = 'BUSINESS_UNIT';
export const ORIGINAL_SOURCE_1 = 'ORIGINAL_SOURCE_1';
export const CAMPAIGN = 'CAMPAIGN';
export const TASK_QUEUE = 'TASK_QUEUE';
export const CRM_OBJECT = 'CRM_OBJECT';
export const USER = 'USER';
export const CHANNEL_INSTANCE = 'CHANNEL_INSTANCE';
export const GENERIC_CHANNEL = 'GENERIC_CHANNEL';
export const TRACKED_TERM = 'TRACKED_TERM';
export const SEQUENCE_WITH_PERMISSIONS = 'SEQUENCE_WITH_PERMISSIONS';
export const PIPELINE_WITH_PERMISSIONS = 'PIPELINE_WITH_PERMISSIONS';
export const PIPELINE_STAGE_WITH_PERMISSIONS = 'PIPELINE_STAGE_WITH_PERMISSIONS';
export const CRM_OBJECT_TAG = 'CRM_OBJECT_TAG';
export const STATIC_INBOUND_DB_LIST = 'STATIC_INBOUND_DB_LIST';
export const OPTIMIZATION_TYPE = 'OPTIMIZATION_TYPE';
export const CALL_DISPOSITION = 'CALL_DISPOSITION';
export const AD_ACCOUNT = 'AD_ACCOUNT';
export const SALES_DOCUMENT = 'SALES_DOCUMENT';
export const MARKETING_SMS_ROOT_MIC = 'MARKETING_SMS_ROOT_MIC';
export const MARKETING_EMAIL = 'MARKETING_EMAIL';
export const MEETING_OUTCOME = 'MEETING_OUTCOME';
export const SYNC_ACCOUNT = 'SYNC_ACCOUNT';
export const PERMISSION_SET = 'PERMISSION_SET';
export const SEAT = 'SEAT';
export const SOURCE_LABEL = 'SOURCE_LABEL';
export const ACTIVITY_TYPE = 'ACTIVITY_TYPE';
export const FORM = 'FORM';
export const CONTENT = 'CONTENT';
export const FILE_ID = 'FILE_ID';
export const FILE_ID_OR_URL = 'FILE_ID_OR_URL';
export const FLOW_ACTION_TYPE = 'FLOW_ACTION_TYPE';
export const COMMERCE_PAYMENT_METHOD = 'COMMERCE_PAYMENT_METHOD';
export const FLOW_ACTION_TYPE_GROUP = 'FLOW_ACTION_TYPE_GROUP';
export const PLAYBOOK = 'PLAYBOOK';
export const FORECAST_TYPE = 'FORECAST_TYPE';
export const USER_MANAGER = 'USER_MANAGER';
export const COMMERCE_PRODUCT_TAX_CATEGORY = 'COMMERCE_PRODUCT_TAX_CATEGORY';
export const CONTACT_EMAIL_ADDRESS = 'CONTACT_EMAIL_ADDRESS';

// TODO: Clean up all existing imports of this type