import http from 'hub-http/clients/apiClient';
import { fromJS } from 'immutable';
import { useState, useMemo, useEffect } from 'react';
import { fetchDataSources } from './tables';
import { withQuickFetch } from './with-quick-fetch';
import { SnowflakeJoinMetadata } from '../schema/source-records';
import { sortAssociationGraphWithMeta } from '../modify/table/sort-association-graph';
import { AsyncStatus, isFailed, isLoading, isSucceeded } from '../utils/async-data-utils';
import { fromJoinMetadata } from '../utils/table-utils';
let joinsCache;
let promiseCache = undefined;
const URL = 'sql-reporting/v1/extended-reportable-options/data-sources/available-joins-with-metadata?withVariants=true';
const _fetchJoinsMetadata = () => http.get(URL);
const fetchJoins = withQuickFetch('reporting-snowflake:joins-with-meta', _fetchJoinsMetadata);
const fetchAndTransformJoins = () => {
  return fetchJoins().then(res => fromJS(res).map(rawJoinMeta => SnowflakeJoinMetadata(rawJoinMeta)).groupBy(joinMeta => joinMeta.source).map(targets => targets.groupBy(joinMeta => joinMeta.target)));
};
export const getSnowflakeJoinsMetadata = () => {
  if (joinsCache) {
    return Promise.resolve(joinsCache);
  }
  const createRequest = () => Promise.all([fetchAndTransformJoins(), fetchDataSources()]).then(([graph, dataSources]) => {
    const sortedGraph = sortAssociationGraphWithMeta(graph);

    // todo: this is temporary until we have a full release/rollout gating
    // solution on the BE. basically, all gating happens at the reportable
    // object level. But, we need to make sure to also remove the joins
    // with gated objects. fetchDataSources will only contain
    // ungated objects, so we can just check if they are in that set of
    // dataSourceIds.
    const allowedIds = dataSources.map(dataSource => dataSource.dataSourceId).toSet();

    // Map<Id, Map<Id, List<DEF>>>
    const filteredGraph = sortedGraph.filter((__, id) => allowedIds.includes(id)).map(targets => targets.filter((__, id) => allowedIds.includes(id))).filterNot(targets => targets.isEmpty());
    return filteredGraph;
  });
  if (!promiseCache) {
    promiseCache = createRequest();
  }
  return promiseCache.then(data => {
    joinsCache = data;
    return data;
  }).catch(error => {
    promiseCache = undefined;
    console.error(error);
    throw error;
  });
};
export const useSnowflakeJoinMetadata = () => {
  const initialState = joinsCache ? {
    status: AsyncStatus.SUCCEEDED,
    data: joinsCache
  } : {
    status: AsyncStatus.UNINITIALIZED
  };
  const [requestState, setRequestState] = useState(initialState);
  useEffect(() => {
    let expired = false;

    // skip effect if already cached
    if (joinsCache) {
      return () => {
        expired = true;
      };
    }
    getSnowflakeJoinsMetadata().then(data => {
      if (!expired) {
        setRequestState({
          status: AsyncStatus.SUCCEEDED,
          data
        });
      }
    }).catch(error => {
      if (!expired) {
        setRequestState({
          status: AsyncStatus.FAILED,
          error
        });
      }
    });
    return () => {
      expired = true;
    };
  }, []);
  return {
    loading: isLoading(requestState),
    data: isSucceeded(requestState) ? requestState.data : undefined,
    error: isFailed(requestState) ? requestState.error : undefined
  };
};
export const useSnowflakeJoinDefinition = () => {
  const requestState = useSnowflakeJoinMetadata();
  const joinsMeta = requestState.data;
  return useMemo(() => {
    return Object.assign({}, requestState, {
      data: joinsMeta && joinsMeta.map(targets => targets.map(listOfJoinMeta => listOfJoinMeta.map(fromJoinMetadata)))
    });
  }, [joinsMeta, requestState]);
};