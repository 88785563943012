'use es6';

import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as OperatorTypes from '../ObjectSegOperatorTypes';
import * as Operators from '../../../filterQueryFormat/operator/Operators';
import get from 'transmute/get';
import invariant from 'react-utils/invariant';
import protocol from 'transmute/protocol';
const _toEmailSubscriptionFilter = (optStatus, operator) => ({
  filterType: ObjectSegFilterTypes.EMAIL_SUBSCRIPTION,
  acceptedStatuses: [optStatus],
  subscriptionIds: get('value', operator).toArray()
});
const invalidOperator = operator => {
  invariant(false, 'cannot convert Operator "%s" to Email Subscription ObjectSeg filter', operator.name);
};
const toEmailSubscriptionFilter = protocol({
  name: 'toEmailSubscriptionFilter',
  args: [protocol.TYPE],
  fallback: invalidOperator
});
toEmailSubscriptionFilter.implement(Operators.EmailSubscriptionOptIn, operator => _toEmailSubscriptionFilter(OperatorTypes.HAS_OPTED_IN, operator));
toEmailSubscriptionFilter.implement(Operators.EmailSubscriptionOptOut, operator => _toEmailSubscriptionFilter(OperatorTypes.HAS_OPTED_OUT, operator));
toEmailSubscriptionFilter.implement(Operators.EmailSubscriptionNotOpted, operator => _toEmailSubscriptionFilter(OperatorTypes.HAS_NOT_OPTED, operator));
export default toEmailSubscriptionFilter;