import I18n from 'I18n';
import { SUB_APPS } from '../constants/sub-apps';
import { DEFAULT_STORY_REPORTS } from 'reporting-enablement/components/pseudo-story-insights/types/storyReportTypes';
import { DIMENSIONS } from './salesAnalyticsHelpers';
const PAGE_TYPE_TO_CODE = {
  STANDARD_PAGE: 0,
  LANDING_PAGES: 1,
  LISTING_PAGE: 2,
  BLOG_POSTS: 3,
  KNOWLEDGE_ARTICLE: 4
};
export const BASE_QUESTION_CONTEXT_UPDATES = {
  dateRange: {
    rangeType: 'THIS_QUARTER',
    entireCurrentUnit: true
  }
};
export const QUESTIONS = {
  adNetworkPerformance: {
    questionId: 'adNetworkPerformance',
    subApp: SUB_APPS.ads,
    path: '/templates/marketing/ads',
    contextOverrides: {
      metric: 'SUM|clicks'
    }
  },
  sourcesSessions: {
    questionId: 'sourcesSessions',
    subApp: SUB_APPS.sources,
    path: '/templates/marketing/sources',
    insightType: DEFAULT_STORY_REPORTS.MAX,
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      metric: 'SUM|visits'
    }
  },
  sourcesCustomers: {
    questionId: 'sourcesCustomers',
    subApp: SUB_APPS.sources,
    path: '',
    insightType: DEFAULT_STORY_REPORTS.MAX,
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      metric: 'SUM|contacts'
    }
  },
  pagesBounceRate: {
    questionId: 'pagesBounceRate',
    subApp: SUB_APPS.pages,
    path: '/templates/marketing/pages',
    insightType: DEFAULT_STORY_REPORTS.MAX,
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      metric: 'PERCENTILES|pageBounceRate'
    }
  },
  blogPostConversion: {
    questionId: 'blogPostConversion',
    subApp: SUB_APPS.pages,
    path: '',
    insightType: DEFAULT_STORY_REPORTS.AVERAGE,
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      pageType: [PAGE_TYPE_TO_CODE.BLOG_POSTS],
      metric: 'PERCENTILES|contactsPerPageview'
    }
  },
  utmCampaignsTraffic: {
    questionId: 'utmCampaignsTraffic',
    subApp: SUB_APPS.utmParameters,
    path: '',
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      metric: 'SUM|visits'
    }
  },
  deviceTypesBounceRate: {
    questionId: 'deviceTypesBounceRate',
    subApp: SUB_APPS.deviceTypes,
    path: '/templates/marketing/deviceTypes',
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      metric: 'PERCENTILES|bounceRate'
    }
  },
  commonDeviceTypes: {
    questionId: 'commonDeviceTypes',
    subApp: SUB_APPS.deviceTypes,
    path: '/templates/marketing/deviceTypes',
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      metric: 'SUM|contacts'
    }
  },
  countriesCustomerConversion: {
    questionId: 'countriesCustomerConversion',
    subApp: SUB_APPS.geolocation,
    path: '/templates/marketing/geolocation',
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      metric: 'percentiles|contactsPerVisit'
    }
  },
  browsersSessionLength: {
    questionId: 'browsersSessionLength',
    subApp: SUB_APPS.browsers,
    path: '/templates/marketing/browsers',
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      metric: 'PERCENTILES|timePerSession'
    }
  },
  formsSubmissions: {
    questionId: 'formsSubmissions',
    subApp: SUB_APPS.leadCapture,
    path: '/templates/marketing/leadCapture',
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      metric: 'SUM|submissions'
    }
  },
  submissionConversion: {
    questionId: 'submissionConversion',
    subApp: SUB_APPS.leadCapture,
    path: '/templates/marketing/pages',
    insightType: DEFAULT_STORY_REPORTS.MAX,
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      metric: 'PERCENTILES|submissionsPerFormView'
    }
  },
  contactsBySource: {
    questionId: 'contactsBySource',
    subApp: SUB_APPS.contactInsights,
    path: '/templates/marketing/contactInsights',
    contextOverrides: {
      grouping: DIMENSIONS.SOURCE,
      metric: 'SUM|contacts'
    }
  },
  contactsLifecycleStages: {
    questionId: 'contactsLifecycleStages',
    subApp: SUB_APPS.contactInsights,
    path: '/templates/marketing/contactInsights',
    contextOverrides: {
      grouping: DIMENSIONS.LIFE_CYCLE
    }
  },
  dealsTimeToClose: {
    questionId: 'dealsTimeToClose',
    subApp: SUB_APPS.dealVelocity,
    path: '/templates/sales/sales-analytics.deal-velocity',
    contextOverrides: {
      grouping: DIMENSIONS.DATE
    }
  },
  forecastClosedWon: {
    questionId: 'forecastClosedWon',
    subApp: SUB_APPS.manualForecastTotal,
    path: '/templates/sales/sales-analytics.forecast-category-using-total-amount',
    contextOverrides: {
      grouping: DIMENSIONS.DATE
    }
  },
  salesRepQuotaAttainment: {
    questionId: 'salesRepQuotaAttainment',
    subApp: SUB_APPS.quotaAttainment,
    path: '/templates/sales/sales-analytics.quota-attainment',
    contextOverrides: {
      grouping: DIMENSIONS.DATE
    }
  },
  callCountAndSuccess: {
    questionId: 'callCountAndSuccess',
    subApp: SUB_APPS.callOutcomes,
    path: '/templates/sales/sales-analytics.call-outcomes',
    contextOverrides: {
      grouping: DIMENSIONS.DATE
    }
  },
  repConversations: {
    questionId: 'repConversations',
    subApp: SUB_APPS.conversations,
    path: '/templates/sales/sales-analytics.chats',
    contextOverrides: {
      grouping: DIMENSIONS.OWNER
    }
  },
  highestActivityCount: {
    questionId: 'highestActivityCount',
    subApp: SUB_APPS.activities,
    path: '/templates/sales/sales-analytics.activities',
    contextOverrides: {
      grouping: DIMENSIONS.OWNER
    }
  },
  dealsCreated: {
    questionId: 'dealsCreated',
    subApp: SUB_APPS.dealsCreatedByRep,
    path: '/templates/sales/sales-analytics.deals-created',
    contextOverrides: {
      grouping: DIMENSIONS.OWNER
    }
  },
  fastestLeadResponseTime: {
    questionId: 'fastestLeadResponseTime',
    subApp: SUB_APPS.leadResponseTime,
    path: '/templates/sales/sales-analytics.lead-response-time',
    contextOverrides: {
      grouping: DIMENSIONS.OWNER
    }
  },
  completedMeetings: {
    questionId: 'completedMeetings',
    subApp: SUB_APPS.meetingOutcomes,
    path: '/templates/sales/sales-analytics.meeting-outcomes',
    contextOverrides: {
      grouping: DIMENSIONS.OWNER
    }
  },
  mostTasksCompleted: {
    questionId: 'mostTasksCompleted',
    subApp: SUB_APPS.taskOutcomes,
    path: '/templates/sales/sales-analytics.tasks-completed',
    contextOverrides: {
      grouping: DIMENSIONS.OWNER
    }
  },
  averageTimeInDealStage: {
    questionId: 'averageTimeInDealStage',
    subApp: SUB_APPS.timeInDealStage,
    path: '',
    contextOverrides: {
      grouping: DIMENSIONS.OWNER
    }
  },
  averageTimeToCloseTicket: {
    questionId: 'averageTimeToCloseTicket',
    subApp: SUB_APPS.resolutionTime,
    path: '/templates/service/service-analytics.resolution-time',
    contextOverrides: {
      grouping: DIMENSIONS.DATE
    }
  },
  timeToAssignChat: {
    questionId: 'timeToAssignChat',
    subApp: SUB_APPS.queueTime,
    path: '/templates/service/service-analytics.chat-wait-time',
    contextOverrides: {
      grouping: DIMENSIONS.DATE
    }
  },
  ticketsByCategory: {
    questionId: 'ticketsByCategory',
    subApp: SUB_APPS.supportVolume,
    path: '/templates/service/service-analytics.support-volume',
    contextOverrides: {}
  },
  customerSatisfaction: {
    questionId: 'customerSatisfaction',
    subApp: SUB_APPS.postSupportCSAT,
    path: '/templates/service/service-analytics.customer-satisfaction-score',
    contextOverrides: {}
  },
  ticketTimeInPipeline: {
    questionId: 'ticketTimeInPipeline',
    subApp: SUB_APPS.ticketTimeInPipeline,
    path: '/templates/service/service-analytics.ticket-time-in-pipeline',
    contextOverrides: {
      grouping: DIMENSIONS.DATE
    }
  },
  highestEmailOpen: {
    questionId: 'highestEmailOpen',
    subApp: SUB_APPS.prospectEngagement,
    path: '/templates/sales/prospectEngagement',
    contextOverrides: {
      tab: 'emails',
      grouping: DIMENSIONS.OWNER
    }
  }
};

// used by questions on reports-list home pages
export const getQuestionCopy = questionId => I18n.text(`sales-analytics-lib.quickAnswers.${questionId}`);
export const getQuestionContextOverrides = questionId => {
  const questionData = QUESTIONS[questionId];
  if (!questionData) {
    return null;
  }
  return questionData.contextOverrides;
};