// @ts-expect-error ts-migrate(2307)
import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import { BOOLEAN, DATE, DATE_TIME, ENUMERATION, NUMBER, STRING } from 'customer-data-objects/property/PropertyTypes';
import { Map as ImmutableMap, OrderedSet } from 'immutable';
export const dateRangePropertyOperators = OrderedSet.of(Operators.InRollingDateRange, Operators.Equal, Operators.AfterDate, Operators.BeforeDate, Operators.InRange);
const dateOperators = OrderedSet.of(Operators.InRollingDateRange, Operators.Equal, Operators.AfterDate, Operators.BeforeDate, Operators.InRange, Operators.Known, Operators.NotKnown);
const PropertyTypeToOperator = ImmutableMap({
  [BOOLEAN]: OrderedSet.of(Operators.Equal, Operators.NotEqual),
  [DATE]: dateOperators,
  [DATE_TIME]: dateOperators,
  [ENUMERATION]: OrderedSet.of(Operators.In, Operators.NotIn, Operators.Known, Operators.NotKnown),
  [NUMBER]: OrderedSet.of(Operators.Equal, Operators.NotEqual, Operators.Greater, Operators.GreaterOrEqual, Operators.Less, Operators.LessOrEqual, Operators.Known, Operators.NotKnown),
  [STRING]: OrderedSet.of(Operators.ContainAny, Operators.NotContainAny, Operators.Known, Operators.NotKnown)
});
export const enumeratedValueOperators = [Operators.In, Operators.NotIn, Operators.ContainAny, Operators.NotContainAny];
export const getDashboardFilterOperatorsForType = type => {
  return PropertyTypeToOperator.get(type);
};