'use es6';

import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { getEvents, createGetEvents } from 'reference-resolvers/api/EventAPI';
export const createEventReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.EVENTS,
  createFetchData: createGetEvents,
  fetchData: getEvents
}, options));
export default createEventReferenceResolver();