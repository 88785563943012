'use es6';

import { Map as ImmutableMap } from 'immutable';
import toJS from '../../lib/toJS';
import * as http from '../../request/http';
import { validNumerical } from '../ids';
import { makeOption } from '../Option';
export const get = () => http.get('app-users/v1/teams').then(toJS);
export const bulkGet = ids => http.put('app-users/v1/teams/bulk-get', {
  data: validNumerical(ids)
}).then(teams => teams.reduce((options, team) => options.set(team.get('id'), makeOption(team.get('id'), team.get('name'))), ImmutableMap()));