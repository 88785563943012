'use es6';

import { CONVERSATION_CHANNEL_TO_VALUE, PUSH_THRESHOLD_MILLS } from '../utils/salesAnalyticsHelpers';
import { getStageOptionValue, STAGE_OBJECT_TYPES, STAGE_VALUE_DELIMITER } from './stageHelpers';
import Context from '../records/Context';
const CHANGE_TYPE_MAP = {
  dealsUnchanged: 'NO_CHANGE',
  dealCreated: 'NEW',
  amountIncreased: 'AMOUNT_INCREASED',
  amountDecreased: 'AMOUNT_DECREASED',
  closedatePushed: 'CLOSE_DATE_PUSHED',
  closedatePulled: 'CLOSE_DATE_PULLED',
  dealstageAdvanced: 'STAGE_MOVED_FORWARD',
  dealstageRegressed: 'STAGE_MOVED_BACKWARD'
};
const contextValueCorrections = {
  activityTypes: values => values.map(value => value === 'CONVERSATION' ? 'CONVERSATION_SESSION' : value),
  changeHistoryTypes: values => values.map(value => CHANGE_TYPE_MAP[value] || value),
  conversationChannels: values => values.map(value => CONVERSATION_CHANNEL_TO_VALUE[value] || value),
  dealStages: values => values.map(value => value.includes(STAGE_VALUE_DELIMITER) ? value : getStageOptionValue(STAGE_OBJECT_TYPES.DEAL, value)),
  pushThreshold: value => PUSH_THRESHOLD_MILLS[value] || value,
  ticketPipelineStatus: value => value ? [value] : []
};
export const contextKeyCorrections = {
  ticketPipelineStatus: 'ticketPipelineStages',
  users: 'ownerIds'
};
export const contextKeysThatShouldNotBeNull = ['dateRange'];
export const fixInitialContext = context => Object.entries(context).reduce((memo, [key, value]) => {
  const getCorrectedContextValue = contextValueCorrections[key];
  const correctedValue = getCorrectedContextValue ? getCorrectedContextValue(value) : value;
  const correctedKey = contextKeyCorrections[key] || key;
  const removeIncorrectNullValues = value === null && contextKeysThatShouldNotBeNull.includes(key);
  if (Context().has(correctedKey) && !removeIncorrectNullValues) {
    memo[correctedKey] = correctedValue;
  }
  return memo;
}, {});