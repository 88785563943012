export const DASHBOARD_ACCESS_DENIED = 'DASHBOARD_ACCESS_DENIED';
const PARTITIONED_DASHBOARD_ACCESS_ERROR = 'PARTITIONED_DASHBOARD_ACCESS_ERROR';
const PRIVATE_DASHBOARD_ACCESS_ERROR = 'PRIVATE_DASHBOARD_ACCESS_ERROR';

// Keys to identify standard friendly error returns
export const FriendlyForbiddenErrorTypes = {
  [DASHBOARD_ACCESS_DENIED]: 'DASHBOARD_ACCESS_DENIED',
  [PARTITIONED_DASHBOARD_ACCESS_ERROR]: 'PARTITIONED_DASHBOARD_ACCESS_ERROR',
  [PRIVATE_DASHBOARD_ACCESS_ERROR]: 'PRIVATE_DASHBOARD_ACCESS_ERROR'
};
export const FORBIDDEN_DASHBOARD = 'FORBIDDEN_DASHBOARD';
export const PARTITIONED_DASHBOARD = 'PARTITIONED_DASHBOARD';
export const PRIVATE_DASHBOARD = 'PRIVATE_DASHBOARD';
export const ForbiddenAssetTypesByErrorType = {
  // Fallback value when user lacks permission to view the dashboard, but it's neither
  // partitioned or private
  [DASHBOARD_ACCESS_DENIED]: FORBIDDEN_DASHBOARD,
  [PARTITIONED_DASHBOARD_ACCESS_ERROR]: PARTITIONED_DASHBOARD,
  [PRIVATE_DASHBOARD_ACCESS_ERROR]: PRIVATE_DASHBOARD
};