'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { Promise } from '../../lib/promise';
let store = ImmutableMap();
const DISABLED = true;
export const PATTERN = /^BUCKET_(?:hs_)?lifecyclestage_([a-z]+)_.+$/;
export const PROPERTY = 'lifecyclestage';
export const PROCESSOR = 'bucket:lifecyclestage';
export const getStage = config => store.get(config);
export const setStage = (config, stage) => {
  store = store.set(config, stage);
};
export const configure = config => {
  const dimensions = config.get('dimensions') || List();
  const index = dimensions.findIndex(PATTERN.test.bind(PATTERN));
  if (DISABLED || index < 0) {
    return Promise.resolve(config);
  }
  const [, stage] = dimensions.get(index).match(PATTERN);
  const updated = config.setIn(['dimensions', index], PROPERTY).update('processors', context => context.push(PROCESSOR));
  setStage(updated, stage);
  return Promise.resolve(updated);
};