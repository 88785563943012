export const INITIALIZATION = 'INITIALIZATION';
export const OWNERS_FETCH_SUCCESS = 'OWNERS_FETCH_SUCCESS';
export const TEAMS_FETCH_SUCCESS = 'TEAMS_FETCH_SUCCESS';
export const PORTAL_SETTINGS_FETCH_SUCCESS = 'PORTAL_SETTINGS_FETCH_SUCCESS';

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}