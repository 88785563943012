'use es6';

import { fromJS, List, Map as ImmutableMap } from 'immutable';
import { isNotNil } from 'dashboard-lib/private/js-util';
import compose from 'transmute/compose';
import identity from 'transmute/identity';
import setIn from 'transmute/setIn';
import { convertToSearchFilter, isDashboardDateRange } from '../DateConversionUtils';
import { PIPELINE_TYPES, setPipelineFilterOnContext } from '../pipelineContext';
import { parseQueryString } from '../queryUtils';
import { CONTEXT_PATH, FREQUENCY_HISTOGRAM_PATH, HS_ANY, OWNERS_PATH, TEAMS_PATH, pipelineTypeToQueryParam } from './context-data';
const isHubSpotAny = subject => subject === HS_ANY;
const createQueryParser = (parser, contextSetter = identity, predicate = isNotNil) => (context, query) => {
  const subject = parser(query);
  if (predicate(subject)) {
    return contextSetter(subject, context);
  }
  return context;
};
const isValidQueryValue = subject => subject && subject !== '';
const parseMultiValueQuery = subject => {
  if (isHubSpotAny(subject)) {
    return List();
  }
  if (isValidQueryValue(subject)) {
    let parsedSubject = subject;
    if (typeof subject === 'string') {
      parsedSubject = subject.split(',');
    }
    return List(parsedSubject);
  }
  return null;
};
const setFrequencyFromQuery = createQueryParser(query => query.frequency, setIn(FREQUENCY_HISTOGRAM_PATH));
const setContextFromQuery = createQueryParser(query => {
  const params = fromJS(query);
  return isDashboardDateRange(params) ? convertToSearchFilter(params) : null;
}, setIn(CONTEXT_PATH));
const setPipelineFromContextParams = pipelineType => createQueryParser(query => query[pipelineTypeToQueryParam[pipelineType]], setPipelineFilterOnContext(pipelineType));
const setOwnersFromQuery = createQueryParser(query => parseMultiValueQuery(query.owners), setIn(OWNERS_PATH));
const setTeamsFromQuery = createQueryParser(query => parseMultiValueQuery(query.teams), setIn(TEAMS_PATH));
const queryParsers = [setFrequencyFromQuery, setContextFromQuery, setOwnersFromQuery, setTeamsFromQuery, setPipelineFromContextParams(PIPELINE_TYPES.DEAL), setPipelineFromContextParams(PIPELINE_TYPES.TICKET)];
const parseContextFromQuery = query => {
  return queryParsers.reduce((context, func) => func(context, query), ImmutableMap({}));
};

/**
 * @param {string} - query string
 * @param {Map} - context
 */
export const parseContext = compose(parseContextFromQuery, parseQueryString);