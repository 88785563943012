import PropTypes from 'prop-types';
export const PERMISSION_SETTINGS_USES = {
  EDIT: 'EDIT',
  CREATE: 'CREATE'
};
export const PermissionSettingsUsesPropType = PropTypes.oneOf(Object.values(PERMISSION_SETTINGS_USES));
export const ASSET_TYPES = {
  DASHBOARD: 'DASHBOARD',
  REPORT: 'REPORT'
};
export const AssetTypePropType = PropTypes.oneOf(Object.values(ASSET_TYPES));
export const ASSIGNMENT_MODES = {
  EVERYONE: 'EVERYONE',
  SPECIFIC: 'SPECIFIC',
  PRIVATE: 'PRIVATE'
};
export const AssignmentModePropType = PropTypes.oneOf(Object.values(ASSIGNMENT_MODES));