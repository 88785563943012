'use es6';

import compose from 'transmute/compose';
import { SALES, CRM } from 'dashboard-lib/public/dashboard/dashboard-type';
const coerceToSales = dashboard => {
  const type = dashboard.get('type');
  if (type === CRM) {
    return dashboard.set('type', SALES);
  }
  return dashboard;
};
const coerceToCRM = dashboard => {
  const type = dashboard.get('type');
  if (type === SALES) {
    return dashboard.set('type', CRM);
  }
  return dashboard;
};
export const toRequest = compose(coerceToCRM);
export const fromResponse = compose(coerceToSales);