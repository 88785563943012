import { useEffect, useState, useMemo, useRef } from 'react';
import { USER_ATTRIBUTES } from 'collaboration-sidebar/constants/userAttributes';
import useUserSettings from 'collaboration-sidebar/hooks/useUserSettings';
import { MODULES_WITH_REPLIES, HIDE_EMBEDDED_COMMENTS } from 'collaboration-sidebar/constants/CollaborationSidebarStateProperties';
import { getAssetKeyFromAsset } from 'collaboration-sidebar/utils/multipleAssets';
import COMMENT_TYPES from '../../../constants/CommentTypes';
import useIsCommentsDataStale from '../../../hooks/useIsCommentsDataStale';
import useCommentingApi from '../../../hooks/useCommentingApi';
export default function useSetUpEmbeddedComments({
  collaborationSidebarState,
  onUpdateCollaborationSidebarState
}) {
  const {
    getUserSetting,
    hasLoaded: userSettingsLoaded
  } = useUserSettings();
  const assetKeyWithFetchedReplies = useRef(null);
  const [hasInitializedHideIndicators, setHasInitializedHideIndicators] = useState(false);
  const {
    isEmbeddedCommentingEnabled,
    objectId,
    objectType,
    isCommentModeActive,
    assetsLastUpdated
  } = collaborationSidebarState;
  const isCommentsDataStale = useIsCommentsDataStale({
    objectType,
    objectId,
    assetsLastUpdated
  });
  const hideIndicators = useMemo(() => userSettingsLoaded ? getUserSetting(USER_ATTRIBUTES.HIDE_COLLABORATION_SIDEBAR_EMBEDDED_COMMENTS.key) === 'true' : true, [getUserSetting, userSettingsLoaded]);
  const commentingApi = useCommentingApi();

  // Fetch all comments to know which modules have comments to show the embedded comment indicators.
  // We don't fetch if the indicators are hidden to begin with, and only fetch when they're on, or if in comment mode.
  // TODO Delay this to not affect SLAs
  useEffect(() => {
    const assetKey = getAssetKeyFromAsset({
      objectId,
      objectType
    });
    if ((!hideIndicators || isCommentModeActive) && isEmbeddedCommentingEnabled && objectId && objectType && (assetKeyWithFetchedReplies.current !== assetKey || isCommentsDataStale)) {
      commentingApi.fetchCommentsCached({
        objectType,
        objectId,
        refetch: isCommentsDataStale
      }).then(response => {
        var _response$results;
        const replyComments = (_response$results = response.results) === null || _response$results === void 0 ? void 0 : _response$results.reduce((repliesAcc, comment) => {
          if (comment.type !== COMMENT_TYPES.DEEP_COMMENT) {
            return repliesAcc;
          }
          return Object.assign({}, repliesAcc, {
            // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
            [comment.parentId]: comment
          });
        }, {});
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null'.
        assetKeyWithFetchedReplies.current = assetKey;
        onUpdateCollaborationSidebarState({
          [MODULES_WITH_REPLIES]: replyComments
        });
      }).catch(__e => {
        // We shouldn't overshow errors here as user likely isn't
        // using commenting. Errors will show when opening sidebar
      });
    }
  }, [isEmbeddedCommentingEnabled, objectId, objectType, hideIndicators, isCommentsDataStale, isCommentModeActive, onUpdateCollaborationSidebarState, commentingApi]);

  // Set initial value for hide indicators when we've fetched the user settings.
  useEffect(() => {
    if (!hasInitializedHideIndicators && userSettingsLoaded) {
      onUpdateCollaborationSidebarState({
        [HIDE_EMBEDDED_COMMENTS]: hideIndicators
      });
      setHasInitializedHideIndicators(true);
    }
  }, [hasInitializedHideIndicators, hideIndicators, userSettingsLoaded, onUpdateCollaborationSidebarState]);
}