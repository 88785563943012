import { Record, Map as ImmutableMap, fromJS } from 'immutable';
const missing = object => () => {
  throw new Error(`missing ${object}`);
};
const RequestMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put'
};
const defaultRequestValues = {
  method: RequestMethods.GET,
  url: '',
  form: ImmutableMap(),
  query: ImmutableMap(),
  data: ImmutableMap(),
  timeout: 30000,
  transformer: missing('transformation')
};
const RequestRecord = Record(defaultRequestValues);
const forMethod = method => data => new RequestRecord(fromJS(data).set('method', method));
const _instance = data => {
  return new RequestRecord(fromJS(data));
};
export default class extends RequestRecord {
  static get(data) {
    return forMethod(RequestMethods.GET)(data);
  }
  static post(data) {
    return forMethod(RequestMethods.POST)(data);
  }
  static put(data) {
    return forMethod(RequestMethods.PUT)(data);
  }
  static instance(data) {
    return _instance(data);
  }
  static sanitize(request) {
    return _instance(JSON.parse(JSON.stringify(request.delete('transformer'))));
  }
}