'use es6';

import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as OperatorTypes from '../ObjectSegOperatorTypes';
import * as Operators from '../../../filterQueryFormat/operator/Operators';
import { __ANY_WEBINAR } from '../../listSegClassic/ListSegConstants';
import getIn from 'transmute/getIn';
import invariant from 'react-utils/invariant';
import omit from 'transmute/omit';
import protocol from 'transmute/protocol';
const getObjectWithOmittedProps = (source, propOmissionMap = {}) => {
  const unwantedProps = Object.keys(source).filter(prop => source[prop] === undefined || source[prop] === propOmissionMap[prop]);
  return omit(unwantedProps, source);
};
const _toWebinarFilter = (operatorName, operator) => getObjectWithOmittedProps({
  filterType: ObjectSegFilterTypes.WEBINAR,
  operator: operatorName,
  webinarId: getIn(['field', 'name'], operator)
}, {
  webinarId: __ANY_WEBINAR
});
const invalidOperator = operator => {
  invariant(false, 'cannot convert Operator "%s" to Webinar ObjectSeg filter', operator.name);
};
const toWebinarFilter = protocol({
  name: 'toWebinarFilter',
  args: [protocol.TYPE],
  fallback: invalidOperator
});
toWebinarFilter.implement(Operators.WebinarHasRegistered, operator => _toWebinarFilter(OperatorTypes.HAS_WEBINAR_REGISTRATION, operator));
toWebinarFilter.implement(Operators.WebinarNotHasRegistered, operator => _toWebinarFilter(OperatorTypes.NOT_HAS_WEBINAR_REGISTRATION, operator));
toWebinarFilter.implement(Operators.WebinarHasAttended, operator => _toWebinarFilter(OperatorTypes.HAS_WEBINAR_ATTENDANCE, operator));
toWebinarFilter.implement(Operators.WebinarNotHasAttended, operator => _toWebinarFilter(OperatorTypes.NOT_HAS_WEBINAR_ATTENDANCE, operator));
export default toWebinarFilter;