'use es6';

import { Set as ImmutableSet, List } from 'immutable';
import { SELECT_TOP } from '../../constants/magicTypes';
import retrieve from '../../retrieve/unified';
import * as unifiedDataTypes from '../../constants/dataTypes/unified';
import { MissingRequiredDataException } from '../../exceptions';
import { has } from '../../lib/has';
import { Metrics } from '../../monitoring/Metrics';
import { SELECT_TOP_STRING_TYPE_VALUES } from '../../constants/trackingMetrics';
export default ((config, paths = ImmutableSet()) => {
  if (!has(unifiedDataTypes, config.get('dataType'))) {
    return Promise.resolve(config);
  }
  const totalsConfig = config.set('dimensions', List(['breakdown'])).update('limit', limit => limit || 10).updateIn(['filters', 'custom'], filters => filters.filter(filter => {
    const values = filter.get('values');
    const valuesIsList = List.isList(values);
    if (!valuesIsList) {
      Metrics.counter(SELECT_TOP_STRING_TYPE_VALUES).increment();
    }
    // support `values: "__select__top__"` and `values: ["__select__top__"]` pattern
    return (valuesIsList ? values.first() : values) !== SELECT_TOP;
  }));
  return retrieve(totalsConfig.toJS(), () => {}).then(response => response.dataset.getIn(['dimension', 'buckets']).map(bucket => bucket.get('key'))).then(breakdowns => {
    if (breakdowns.isEmpty()) {
      throw new MissingRequiredDataException();
    }
    return paths.reduce((acc, path) => {
      // support `values: "__select__top__"` and `values: ["__select__top__"]` pattern
      const pathIsForListValue = String(path[path.length - 1]) === '0';
      const valuesBasePath = pathIsForListValue ? path.slice(0, -1) : path;
      return acc.setIn(['filters', ...valuesBasePath], breakdowns);
    }, config);
  });
});