'use es6';

import http from 'hub-http/clients/apiClient';
import { registerQuery } from 'data-fetching-client';
const OPT_IN_URL = 'optins/v1/availability/New funnel report builder';
export const PRO_FUNNEL_OPT_IN_AVAILABILITY = 'proFunnelOptInAvailability';
function fetchProFunnelOptInAvailability() {
  return http.get(OPT_IN_URL);
}
export const FETCH_PRO_FUNNEL_OPT_IN_AVAILABILITY = registerQuery({
  fieldName: PRO_FUNNEL_OPT_IN_AVAILABILITY,
  fetcher() {
    return fetchProFunnelOptInAvailability();
  }
});