import { useSavedDashboardFiltersPanelContext } from '../context';
import { useSelector } from 'react-redux';
import { selectFilterSetById } from '../../../../../../ducks/savedDashboardFilters';
import usePrevious from 'react-utils/hooks/usePrevious';
export const useInitializeDraftFilters = () => {
  const {
    onDraftFilterSetFiltersChanged,
    onDraftFilterSetNameChanged,
    onDraftFilterSetFrequencyChanged,
    onDraftFilterSetFrequencyUseFiscalYearChanged,
    editingFilterSetId = -1,
    dashboardId
  } = useSavedDashboardFiltersPanelContext();
  const previousEditingFilterSetId = usePrevious(editingFilterSetId);
  const editingFilterSet = useSelector(selectFilterSetById(dashboardId, editingFilterSetId));
  if (editingFilterSet && (!previousEditingFilterSetId || previousEditingFilterSetId !== editingFilterSet.id)) {
    onDraftFilterSetFiltersChanged(editingFilterSet.filters);
    onDraftFilterSetNameChanged(editingFilterSet.name);
    onDraftFilterSetFrequencyChanged(editingFilterSet.frequency);
    onDraftFilterSetFrequencyUseFiscalYearChanged(editingFilterSet.useFiscalYear);
  }
};