export const IS_OPEN = 'isOpen';
export const TAB = 'tab';
export const SELECTED_ASSET = 'SELECTED_ASSET';
export const COMMENT_THREAD = 'commentThread';
export const HIDE_EMBEDDED_COMMENTS = 'hideEmbeddedComments';
export const APP_NAME = 'appName';
export const ENABLED_FEATURES = 'enabledFeatures';
export const OBJECT_ID = 'objectId';
export const OBJECT_TYPE = 'objectType';
export const CTA_URL = 'ctaUrl';
export const MODULES_WITH_REPLIES = 'modulesWithReplies';
export const IS_EMBEDDED_COMMENTS_ENABLED = 'isEmbeddedCommentingEnabled';
export const IS_COMMENT_MODE_ACTIVE = 'isCommentModeActive';
export const BROADCAST_COMMENT_MESSAGE = 'broadcastCommentMessage';
export const EMBEDDED_COMMENT_SIDEBAR_INTERACTIONS = 'embeddedCommentSidebarInteractions';
export const COMMENTS_FILTER = `commentsFilter`;
export const CTA_COMMENT_ID = `ctaCommentId`;
export const ASSETS_LAST_UPDATED = `assetsLastUpdated`;
export const NOTIFICATION_SETTINGS_OPEN = 'notificationSettingsOpen';
export const UNREAD_REPLIES_MAP = 'unreadRepliesMap';
export const HAS_UNRECOVERABLE_ERROR = 'hasUnrecoverableError';
export const IS_EMBEDDED_POPOVER_OPEN = 'isEmbeddedPopoverOpen';
export const EMBEDDED_POPOVER_PLACEMENT = 'embeddedPopoverPlacement';
export const EMBEDDED_COMMENT_COORDINATES = 'embeddedCommentCoordinates';
export const EMBEDDED_MODULE_ID = 'embeddedModuleID';
export const EMBEDDED_META_DESCRIPTION = 'embeddedMetaDescription';
export const IS_EMBEDDED_OPEN_FROM_COMMENT_MODE = 'isEmbeddedOpenFromCommentMode';
export const COMMENTABLE_AREA_CLICK_COORDINATES = 'commentableAreaClickCoordinates';

// Deprecated
export const HIGHLIGHT_COMMENTABLE_AREA = 'highlightCommentableAreaId';
export const SCROLL_TO_COMMENTABLE_AREA = 'scrollToCommentableAreaId';