import { getIsReportValidForChartTypeTransformation } from 'reporting-data/report/report-transformations/chartTypeTransformations';
import BreakdownEditorDropdown from '../../components/breakdown-editor-dropdown/BreakdownEditorDropdown';
import { getIsReportValidForBreakdownEditorDropdown } from '../../components/breakdown-editor-dropdown/utils';
import ChartTypeToggleDropdown from '../../components/chart-type-toggle-dropdown/ChartTypeToggleDropdown';
import ColorThemeDropdown from '../../components/color-theme-dropdown/ColorThemeDropdown';
import { isReportValidForColorThemeDropdown } from '../../components/color-theme-dropdown/utils';
import ReportComparisonDropdown from '../../components/comparison-dropdown/ReportComparisonDropdown';
import { getIsReportValidForComparisonDropdown } from '../../components/comparison-dropdown/utils';
import CumulativeCheckbox from '../../components/cumulative-checkbox/CumulativeCheckbox';
import { getIsReportValidForCumulativeCheckbox } from '../../components/cumulative-checkbox/utils';
import DimensionEditorDropdown from '../../components/dimension-editor-dropdown/DimensionEditorDropdown';
import { getIsReportValidForDimensionEditorDropdown } from '../../components/dimension-editor-dropdown/utils';
import FiscalYearCheckbox from '../../components/fiscal-year-checkbox/FiscalYearCheckbox';
import { getIsReportValidForFiscalYearCheckbox } from '../../components/fiscal-year-checkbox/utils';
import HideDataLabelsCheckbox from '../../components/hide-data-labels-checkbox/HideDataLabelsCheckbox';
import { getIsReportValidForHideDataLabelsCheckbox } from '../../components/hide-data-labels-checkbox/utils';
import HideMarkersCheckbox from '../../components/hide-markers-checkbox/HideMarkersCheckbox';
import { getIsReportValidForHideMarkersCheckbox } from '../../components/hide-markers-checkbox/utils';
import MetricsEditorDropdown from '../../components/metrics-editor-dropdown/MetricsEditorDropdown';
import { getIsReportValidForMetricsEditorDropdown } from '../../components/metrics-editor-dropdown/utils';
import ReportDataLimitDropdown from '../../components/report-data-limit-dropdown/ReportDataLimitDropdown';
import { getIsReportValidForReportDataLimitDropdown } from '../../components/report-data-limit-dropdown/utils';
import ShowTodayCheckbox from '../../components/show-today-checkbox/ShowTodayCheckbox';
import { getIsReportValidForShowTodayCheckbox } from '../../components/show-today-checkbox/utils';
import ShowTotalsCheckbox from '../../components/show-totals-checkbox/ShowTotalsCheckbox';
import { getIsReportValidForTotalsCheckbox } from '../../components/show-totals-checkbox/utils';
import ShowTotalsColumnCheckbox from '../../components/show-totals-column-checkbox/ShowTotalsColumnCheckbox';
import { getIsReportValidForTotalsColumnCheckbox } from '../../components/show-totals-column-checkbox/utils';
import SortSelectorDropdown from '../../components/sort-selector-dropdown/SortSelectorDropdown';
import { getIsReportValidForSortSelectorDropdown } from '../../components/sort-selector-dropdown/utils';
import { TrendLineToggle } from '../../components/trendline-toggle/TrendlineToggle';
import { REPORT_CUSTOMIZATION_COMPONENT_IDS } from './componentTypes';
import { AnomalyDetectionToggle } from '../../components/anomaly-detection-toggle/AnomalyDetectionToggle';
import ReportStackingDropdown from '../../components/report-stacking-dropdown/ReportStackingDropdown';
import { getIsReportValidForReportStackingDropdown } from '../../components/report-stacking-dropdown/utils';
import { getIsReportValidForDataColumnsDropdown } from '../../components/data-columns-dropdown/utils';
import DataColumnsDropdown from '../../components/data-columns-dropdown/DataColumnsDropdown';
export const REPORT_CUSTOMIZATION_COMPONENT_CONFIG = {
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.CHART_TYPE_TOGGLE_DROPDOWN]: {
    component: ChartTypeToggleDropdown,
    isValidForReport: ({
      report
    }) => getIsReportValidForChartTypeTransformation(report)
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.TRENDLINE_TOGGLE_DROPDOWN]: {
    component: TrendLineToggle,
    // Trend line access is fetched from the BE and checked/managed directly in the TrendlineToggle component
    isValidForReport: () => true
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.COLOR_THEME_DROPDOWN]: {
    component: ColorThemeDropdown,
    isValidForReport: ({
      report
    }) => {
      return isReportValidForColorThemeDropdown(report);
    }
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.USE_FISCAL_YEAR_CHECKBOX]: {
    component: FiscalYearCheckbox,
    isValidForReport: ({
      report
    }) => {
      return getIsReportValidForFiscalYearCheckbox({
        report
      });
    }
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.SHOW_TOTALS_CHECKBOX]: {
    component: ShowTotalsCheckbox,
    isValidForReport: getIsReportValidForTotalsCheckbox
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.HIDE_DATA_LABELS_CHECKBOX]: {
    component: HideDataLabelsCheckbox,
    isValidForReport: getIsReportValidForHideDataLabelsCheckbox
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.CUMULATIVE_CHECKBOX]: {
    component: CumulativeCheckbox,
    isValidForReport: getIsReportValidForCumulativeCheckbox
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.SHOW_TOTALS_COLUMN_CHECKBOX]: {
    component: ShowTotalsColumnCheckbox,
    isValidForReport: getIsReportValidForTotalsColumnCheckbox
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.HIDE_MARKERS_CHECKBOX]: {
    component: HideMarkersCheckbox,
    isValidForReport: getIsReportValidForHideMarkersCheckbox
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.SHOW_TODAY_CHECKBOX]: {
    component: ShowTodayCheckbox,
    isValidForReport: getIsReportValidForShowTodayCheckbox
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.REPORT_DATA_LIMIT_DROPDOWN]: {
    component: ReportDataLimitDropdown,
    isValidForReport: getIsReportValidForReportDataLimitDropdown
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.COMPARISON_DROPDOWN]: {
    component: ReportComparisonDropdown,
    isValidForReport: getIsReportValidForComparisonDropdown
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.SORT_SELECTOR_DROPDOWN]: {
    component: SortSelectorDropdown,
    isValidForReport: getIsReportValidForSortSelectorDropdown
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.METRICS_EDITOR_DROPDOWN]: {
    component: MetricsEditorDropdown,
    isValidForReport: getIsReportValidForMetricsEditorDropdown
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.DIMENSION_EDITOR_DROPDOWN]: {
    component: DimensionEditorDropdown,
    isValidForReport: getIsReportValidForDimensionEditorDropdown
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.BREAKDOWN_EDITOR_DROPDOWN]: {
    component: BreakdownEditorDropdown,
    isValidForReport: getIsReportValidForBreakdownEditorDropdown
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.REPORT_STACKING_DROPDOWN]: {
    component: ReportStackingDropdown,
    isValidForReport: getIsReportValidForReportStackingDropdown
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.DATA_COLUMNS_DROPDOWN]: {
    component: DataColumnsDropdown,
    isValidForReport: getIsReportValidForDataColumnsDropdown
  },
  [REPORT_CUSTOMIZATION_COMPONENT_IDS.ANOMALY_DETECTION_TOGGLE]: {
    component: AnomalyDetectionToggle,
    // Anomaly detection access is fetched from the BE and checked/managed directly in the AnomalyDetectionToggle component
    isValidForReport: () => true
  }
};