'use es6';

import { List } from 'immutable';
import getInboundDbPropertyGroups from '../../retrieve/inboundDb/common/properties';
import { ATTRIBUTION_TOUCH_POINTS } from '../../constants/dataTypes';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import countProperty from '../partial/count-property';
import { mergeProperties } from '../mergeProperties';
export const getPropertyGroups = () => getInboundDbPropertyGroups(ATTRIBUTION_TOUCH_POINTS).then(groups => List([...groups])).then(groups => mergeProperties(groups, 'attributionassetinformation', {
  hs_campaign_guid: {
    type: 'enumeration'
  },
  hs_cta_id: {
    type: 'enumeration',
    externalOptions: true,
    referencedObjectType: 'CTA'
  },
  hs_contact_id: {
    type: 'enumeration'
  },
  hs_contact_company: {
    type: 'enumeration',
    externalOptions: true,
    referencedObjectType: 'COMPANY'
  },
  hs_created_by_user_id: {
    type: 'enumeration',
    externalOptions: true,
    referencedObjectType: 'USER'
  },
  hs_updated_by_user_id: {
    type: 'enumeration',
    externalOptions: true,
    referencedObjectType: 'USER'
  }
}));

// TODO: Remove overrides once attribution property types are changed in the backend
export const getProperties = () => createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(ATTRIBUTION_TOUCH_POINTS)))();