'use es6';

import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as Operators from '../../../filterQueryFormat/operator/Operators';
import { COALESCING_REFINE_BY, COALESCING_REFINE_BY_SET_TYPE, COALESCING_REFINE_BY_TYPE } from '../constants/constants';
import { __ANY_FORM, __ANY_PAGE } from 'customer-data-filters/converters/listSegClassic/ListSegConstants';
import getIn from 'transmute/getIn';
import invariant from 'react-utils/invariant';
import omit from 'transmute/omit';
import protocol from 'transmute/protocol';
import toRefinementFilter from './toRefinementFilter';
const getObjectWithOmittedProps = (source, propOmissionMap = {}) => {
  const unwantedProps = Object.keys(source).filter(prop => source[prop] === undefined || source[prop] === propOmissionMap[prop]);
  return omit(unwantedProps, source);
};
const _toFormSubmissionFilter = (operator, isNegated) => {
  const filter = {
    filterType: ObjectSegFilterTypes.FORM_SUBMISSION,
    formId: getIn(['field', 'name'], operator),
    pageId: getIn(['value'], operator)
  };
  if (Object.prototype.hasOwnProperty.call(filter, 'pageId') && filter.pageId !== __ANY_PAGE) {
    filter.filterType = ObjectSegFilterTypes.FORM_SUBMISSION_ON_PAGE;
  }
  let refinementFilter;
  const refinement = operator.get('refinement');
  if (refinement) {
    refinementFilter = toRefinementFilter(refinement);

    // there is a conflict between the negated operator and number of times refinements
    // if there is a refinement using SetOccurrencesRefineBy it can be used to invert the filter
    if (isNegated) {
      refinementFilter[COALESCING_REFINE_BY] = {
        type: COALESCING_REFINE_BY_TYPE.SET_OCCURRENCES,
        setType: COALESCING_REFINE_BY_SET_TYPE.NONE
      };
    }
  } else if (isNegated) {
    // if there are no refinements, the filter can be inverted using SetOccurrencesRefineBy
    refinementFilter = {
      [COALESCING_REFINE_BY]: {
        type: COALESCING_REFINE_BY_TYPE.SET_OCCURRENCES,
        setType: COALESCING_REFINE_BY_SET_TYPE.NONE
      }
    };
  }
  return getObjectWithOmittedProps(Object.assign({}, filter, refinementFilter), {
    formId: __ANY_FORM,
    pageId: __ANY_PAGE
  });
};
const invalidOperator = operator => {
  invariant(false, 'cannot convert Operator "%s" to Form Submission ObjectSeg filter', operator.name);
};
const toFormSubmissionFilter = protocol({
  name: 'toWebinarFilter',
  args: [protocol.TYPE],
  fallback: invalidOperator
});
toFormSubmissionFilter.implement(Operators.FormFilledOut, operator => _toFormSubmissionFilter(operator));
toFormSubmissionFilter.implement(Operators.FormNotFilledOut, operator => _toFormSubmissionFilter(operator, true));
export default toFormSubmissionFilter;