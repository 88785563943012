import { VisualTypes } from '../schema/visual-records';
import { FieldTypes } from '../schema/column-records';
import { COLUMN_ROLES } from 'reporting-data/constants/relationalReports';
export const continuousFieldTypes = [FieldTypes.NUMBER, FieldTypes.DATETIME, FieldTypes.DATE, FieldTypes.CURRENCY_NUMBER];
export const nominalFieldTypes = [FieldTypes.BOOL, FieldTypes.ENUMERATION, FieldTypes.STRING];
const xyContinuousVisuals = [VisualTypes.LINE, VisualTypes.AREA, VisualTypes.VERTICAL_BAR];
const xyDiscreteVisuals = [VisualTypes.HORIZONTAL_BAR];
const slicedVisuals = [VisualTypes.PIE, VisualTypes.DONUT];
export const getRecommendedVisualTypes = columnsToEncode => {
  let recommendedTypes = [VisualTypes.TABLE];
  if (columnsToEncode.size <= 4) {
    const measures = columnsToEncode.filter(x => x !== undefined && x.role === COLUMN_ROLES.MEASURE);
    const dimensions = columnsToEncode.filter(x => x !== undefined && x.role === COLUMN_ROLES.DIMENSION);
    if (measures.size === 1 && (dimensions === undefined || dimensions.size === 0)) {
      recommendedTypes.push(VisualTypes.GAUGE);
    }
    if (measures.size >= 1 && (dimensions === undefined || dimensions.size === 0)) {
      recommendedTypes.push(VisualTypes.TEXT);
    }
    if (measures.size === 1 && (dimensions.size === 1 || dimensions.size === 2) || dimensions.size === 1 && measures.size > 0) {
      const continuousDims = dimensions.filter(dim => continuousFieldTypes.includes(dim.field.type));
      if (continuousDims && continuousDims.size > 0) {
        recommendedTypes = recommendedTypes.concat(xyContinuousVisuals);
      } else {
        if (dimensions.size === 1 && measures.size === 1) {
          recommendedTypes = recommendedTypes.concat(slicedVisuals);
        }
        recommendedTypes = recommendedTypes.concat(xyDiscreteVisuals);
      }
    }
    if (measures.size >= 2 && dimensions.size >= 1) {
      recommendedTypes.push(VisualTypes.SCATTER);
    }
    if (measures.size === 2 && dimensions.size >= 1) {
      recommendedTypes.push(VisualTypes.COMBO);
    }
  }
  return recommendedTypes;
};
export const getValidVisualTypes = columnsToEncode => {
  let validTypes = [];
  validTypes.push(VisualTypes.TABLE, VisualTypes.PIVOT_TABLE);
  const measures = columnsToEncode.filter(x => x !== undefined && x.role === COLUMN_ROLES.MEASURE);
  const dimensions = columnsToEncode.filter(x => x !== undefined && x.role === COLUMN_ROLES.DIMENSION);
  if (measures.size >= 1 && (dimensions === undefined || dimensions.size === 0)) {
    validTypes.push(VisualTypes.GAUGE);
    validTypes.push(VisualTypes.TEXT);
  }
  if (measures.size === 1 && dimensions.size <= 1) {
    validTypes = validTypes.concat(slicedVisuals);
  }
  if (measures.size + dimensions.size === 1) {
    validTypes = validTypes.concat(xyContinuousVisuals);
    validTypes = validTypes.concat(xyDiscreteVisuals);
    return validTypes;
  }
  if (measures.size + dimensions.size > 1) {
    validTypes.push(VisualTypes.SCATTER);
  }
  if (measures.size >= 1 && dimensions.size <= 2) {
    validTypes = validTypes.concat(xyContinuousVisuals);
    validTypes = validTypes.concat(xyDiscreteVisuals);
  }
  if (measures.size === 2 && dimensions.size >= 1) {
    validTypes.push(VisualTypes.COMBO);
  }
  return validTypes;
};
export const mapMagicWandToVisualType = (fromVisualType, columnsToEncode) => {
  const columns = columnsToEncode.toList();
  const validVisuals = getValidVisualTypes(columns);
  if (validVisuals.includes(fromVisualType)) {
    return fromVisualType;
  }
  return validVisuals[0];
};