/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

import { HubsSettingClient } from 'frontend-preferences-client';
import http from 'hub-http/clients/apiClient';
import { List } from 'immutable';
import { assertUnreachable } from '../../shared/lib/utility-types';
import { DataSource, DataSourceTypes, SnowflakeExternalDataSourceTable, SnowflakeTable, SnowflakeVirtualDataSourceTable, TableGroupNames } from '../schema/source-records';
import * as ObjectTypes from './objectTypes';
import { withQuickFetch } from './with-quick-fetch';
let dataSourcesCache;

// Map<DataSourceId, SnowflakeTable>
let tableCache;
const DATA_SOURCES_PATH = 'sql-reporting/v1/extended-reportable-options/data-sources';
const getOverridenReportingFirstLevelGroups = dataSource => {
  const {
    dataSourceId,
    reportingFirstLevelGroups
  } = dataSource;
  if (dataSourceId !== ObjectTypes.MARKETING_EMAIL && dataSourceId !== ObjectTypes.CAMPAIGN && reportingFirstLevelGroups.includes(TableGroupNames.MARKETING_EMAILS)) {
    // take out the events from the marketing email group, and put them into MARKETING_EMAIL_EVENTS
    return List.of(TableGroupNames.MARKETING_EMAIL_EVENTS);
  }
  return reportingFirstLevelGroups;
};
const overrideReportingFirstLevelGroups = dataSource => {
  return dataSource.set('reportingFirstLevelGroups', getOverridenReportingFirstLevelGroups(dataSource));
};
const createIsDataSourceUngatedForUser = objectTypeIdToSetting => {
  return dataSource => {
    const {
      reportingFirstLevelGroups
    } = dataSource;
    if (reportingFirstLevelGroups.includes(TableGroupNames.DEAL_SPLITS)) {
      return objectTypeIdToSetting[ObjectTypes.DEAL_SPLIT].value;
    }
    return true;
  };
};
const toSnowflakeTable = dataSource => {
  const {
    dataSourceType,
    eventsDigestType,
    dataSourceId,
    enabled
  } = dataSource;
  switch (dataSourceType) {
    case DataSourceTypes.HUBSPOT_OBJECT:
    case DataSourceTypes.HUBSPOT_EVENT:
      {
        const {
          inboundDbObjectType: metaDefinition
        } = dataSource;
        const safeMetaDefinition = Object.assign({}, metaDefinition, {
          secondaryDisplayLabelPropertyNames: metaDefinition.secondaryDisplayLabelPropertyNames || []
        });
        const {
          id,
          metaTypeId,
          objectTypeId,
          fullyQualifiedName: name
        } = safeMetaDefinition;

        // @ts-expect-error untyped inboundDbObjectType poly object
        return SnowflakeTable({
          dataSourceType,
          id,
          dataSourceId,
          metaTypeId,
          objectTypeId,
          name,
          metaDefinition: safeMetaDefinition,
          enabled
        });
      }
    case DataSourceTypes.EVENTS_DIGEST:
      {
        // @ts-expect-error untyped inboundDbObjectType poly object
        return SnowflakeTable({
          dataSourceType,
          dataSourceId,
          name: eventsDigestType,
          eventsDigestType,
          rolloutStage: 'new'
        });
      }
    case DataSourceTypes.EXTERNAL_DATASOURCE:
      {
        return SnowflakeExternalDataSourceTable({
          dataSourceType: 'EXTERNAL_DATASOURCE',
          type: DataSourceTypes.EXTERNAL_DATASOURCE,
          dataSourceId,
          name: dataSourceId,
          rolloutStage: dataSource.reportingBeta ? 'beta' : undefined
        });
      }
    case DataSourceTypes.VIRTUAL_DATASOURCE:
      {
        return SnowflakeVirtualDataSourceTable({
          dataSourceType: 'VIRTUAL_DATASOURCE',
          type: 'VIRTUAL_DATASOURCE',
          name: dataSource.dataSourceId,
          dataSourceId: dataSource.dataSourceId,
          requiresEventDateRangeFilter: dataSource.requiresEventDateRangeFilter
        });
      }
    case DataSourceTypes.HUBSPOT_DATASET:
      {
        throw new Error('Dataset data sources cannot be used as snowflake tables.');
      }
    case DataSourceTypes.HUBSPOT_GLOBAL_DATASET:
      {
        throw new Error('Global dataset data sources cannot be used as snowflake tables.');
      }
    default:
      assertUnreachable(dataSourceType, `unsupported DataSourceType ${dataSourceType}`);
  }
};
const parseDataSources = res => {
  const dataSources = res.filter(dataSourceRes => {
    const {
      dataSourceType,
      reportable,
      reportingSection,
      inboundDbObjectType
    } = dataSourceRes;
    if (dataSourceType === DataSourceTypes.EXTERNAL_DATASOURCE) {
      return reportable;
    }
    if (dataSourceType === DataSourceTypes.VIRTUAL_DATASOURCE) {
      return reportable;
    }
    return reportable && !!reportingSection && (!!inboundDbObjectType || dataSourceType === DataSourceTypes.EVENTS_DIGEST);
  }).map(dataSourceRes => {
    const {
      dataSourceId,
      dataSourceType,
      eventsDigestType,
      reportable,
      reportingSection,
      reportingBeta,
      reportingFirstLevelGroups,
      requiresEventDateRangeFilter,
      inboundDbObjectType,
      enabled
    } = dataSourceRes;
    return DataSource({
      dataSourceType,
      dataSourceId,
      eventsDigestType,
      reportable,
      reportingFirstLevelGroups,
      requiresEventDateRangeFilter,
      reportingSection,
      reportingBeta,
      inboundDbObjectType,
      enabled
    });
  });
  return List(dataSources);
};
const configuredHubSettingsClient = HubsSettingClient.forCaller('reporting-snowflake');
const getMapOfObjectTypeIdToSetting = () => {
  // Note: This function was simplified down due to only having one hubSetting that we were fetching
  // if in the future, we need to support multiple, there is a batch endpoint that would
  // allowing for fetching multiple hubSetting keys with a single API call
  return configuredHubSettingsClient.fetch('Forecasting:DealSplits:Enabled', 'false').then(response => {
    return {
      [ObjectTypes.DEAL_SPLIT]: {
        key: 'Forecasting:DealSplits:Enabled',
        value: response === 'true'
      }
    };
  }).catch(__e => {
    return {
      [ObjectTypes.DEAL_SPLIT]: {
        key: 'Forecasting:DealSplits:Enabled',
        value: false
      }
    };
  });
};
let promiseCache;
const _fetchDataSources = () => http.get(DATA_SOURCES_PATH);
const quickFetchDataSources = withQuickFetch('reporting-snowflake:data-sources', _fetchDataSources);
export const fetchDataSources = () => {
  if (dataSourcesCache) {
    return Promise.resolve(dataSourcesCache);
  }
  const fetch = () => Promise.all([quickFetchDataSources().then(parseDataSources), getMapOfObjectTypeIdToSetting()]).then(([dataSources, objectTypeIdToSetting]) => {
    const isUngated = createIsDataSourceUngatedForUser(objectTypeIdToSetting);
    dataSourcesCache = dataSources.filter(isUngated).map(dataSource => overrideReportingFirstLevelGroups(dataSource));
    return dataSourcesCache;
  });
  promiseCache = promiseCache || fetch();
  return promiseCache;
};
const parseSnowflakeTables = dataSources => {
  const snowflakeTables = dataSources.map(dataSource => toSnowflakeTable(dataSource));
  const parsedTables = snowflakeTables.toMap().mapKeys((_, snowflakeTable) => snowflakeTable ? snowflakeTable.dataSourceId : '');
  return parsedTables;
};
export const getSnowflakeTables = () => {
  if (tableCache) {
    return Promise.resolve(tableCache);
  }
  return fetchDataSources().then(parseSnowflakeTables).then(snowflakeTables => {
    tableCache = snowflakeTables;
    return snowflakeTables;
  });
};
export const __TESTABLE__ = {
  parseDataSources,
  parseSnowflakeTables
};