export const applyByIdCache = ({
  byId,
  search
}) => {
  const cache = {};
  const api = {
    byId: id => {
      if (Object.prototype.hasOwnProperty.call(cache, id)) {
        return Promise.resolve(cache[id]);
      }
      return byId(id).then(reference => {
        cache[id] = reference;
        return reference;
      });
    },
    refreshCache: id => {
      delete cache[id];
      return api.byId(id);
    },
    search: searchQuery => {
      return search(searchQuery).then(response => {
        for (const reference of response.references) {
          cache[reference.id] = reference;
        }
        return response;
      });
    }
  };
  return api;
};