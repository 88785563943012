'use es6';

import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
import { createCustomObjectGetter } from 'reference-resolvers/formatters/formatCustomObjects';
import { fromJS, List } from 'immutable';
import indexBy from 'transmute/indexBy';
import get from 'transmute/get';
export const getLabel = createCustomObjectGetter('hs_inbox_name');
export const getArchived = conversationInbox => !!conversationInbox.properties.hs_deleted_at;
const formatConversationInboxesReference = conversationInbox => {
  return new ReferenceRecord({
    id: String(conversationInbox.objectId),
    label: getLabel(conversationInbox),
    referencedObject: fromJS(conversationInbox),
    archived: getArchived(conversationInbox)
  });
};
export const formatConversationInboxes = conversationInbox => {
  return indexBy(get('id'), List(Object.values(conversationInbox)).map(formatConversationInboxesReference));
};
export default formatConversationInboxes;