import { getGroupsWithPropertiesFetchUrl, makeGroupsWithPropertiesQuickFetchKey } from 'framework-data-schema-quick-fetch';
import { withQuickFetch } from 'framework-data-schema-quick-fetch/withQuickFetch';
export const fetchGroupedProperties = ({
  httpClient,
  frameworkTypeIdentifier,
  query
}) => withQuickFetch({
  requestName: makeGroupsWithPropertiesQuickFetchKey({
    frameworkTypeIdentifier,
    query
  }),
  baseFetch: () => httpClient.get(getGroupsWithPropertiesFetchUrl({
    frameworkTypeIdentifier,
    query
  }))
}).then(({
  results
}) => results);