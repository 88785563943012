'use es6';

const getYPosition = (positionString, top, height) => {
  switch (positionString) {
    case 'top':
      return top;
    case 'bottom':
      return top + height;
    default:
      return top + height / 2;
  }
};
const getXPosition = (positionString, left, width) => {
  switch (positionString) {
    case 'left':
      return left;
    case 'right':
      return left + width;
    default:
      return left + width / 2;
  }
};
export default ((position, {
  top,
  left,
  width,
  height
}) => {
  const positionSplit = position.split(' ');
  let positionSplitA = positionSplit[0];
  let positionSplitB = positionSplit[1] || positionSplitA;
  if (positionSplitA === 'left' || positionSplitA === 'right') {
    const positionA = positionSplitA;
    positionSplitA = positionSplitB;
    positionSplitB = positionA;
  }
  return {
    top: getYPosition(positionSplitA, top, height),
    left: getXPosition(positionSplitB, left, width)
  };
});