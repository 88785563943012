import I18n from 'I18n';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'refe... Remove this comment to see the full error message
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
export const defaultEnumOptionFormatter = record => {
  const option = Object.assign({}, ReferenceRecord.toOption(record));
  if (record.archived) {
    // Archived takes precedence over the description
    option.help = I18n.text('customerDataReferenceUiComponents.archived');
  }
  return option;
};