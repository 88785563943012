// @ts-expect-error untyped
import { deref, unwatch, watch } from 'atom';
// @ts-expect-error untyped
import { mapResolve } from 'reference-resolvers/utils';
import emptyFunction from 'react-utils/emptyFunction';
export const atomToPromise = atom => {
  return new Promise((resolve, reject) => {
    const toPromise = mapResolve({
      loading: emptyFunction,
      error(value) {
        reject(value.reason);
        unwatch(atom, toPromise);
      },
      resolved(value) {
        resolve(value);
        unwatch(atom, toPromise);
      }
    });
    watch(atom, toPromise);
    toPromise(deref(atom));
  });
};