'use es6';

import http from 'hub-http/clients/apiClient';
import fromJS from 'transmute/fromJS';
import { registerQuery } from 'data-fetching-client';
const REPORT_COUNTS_URL = `dashboard/v2/reports/report-counts`;
export const REPORT_COUNTS_FIELD_NAME = 'reportCounts';

// @returns {Map(
//   allReports: Number,
//   customCrossObjectReports: Number,
//   customReports: Number,
//   inDashboards: Number,
//   notInDashboards: Number,
//   reportsLimits: Number,
//   standardReports: Number,
//   totalCrossObjectReports: Number
// )}
export const FETCH_REPORT_COUNTS = registerQuery({
  fieldName: REPORT_COUNTS_FIELD_NAME,
  fetcher() {
    return http.get(REPORT_COUNTS_URL).then(response => fromJS(response));
  }
});