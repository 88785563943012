'use es6';

import { fromJS } from 'immutable';
import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import { registerMutation, registerQuery } from 'data-fetching-client';
export const RECENT_DASHBOARDS_FIELD_NAME = 'recentlyViewedDashboards';
export const RECENT_REPORTS_FIELD_NAME = 'recentlyViewedReports';
const RECENT_ASSET_LIMIT = 15;
const getRecentlyViewedAssetUrl = objectType => `dashboard/v2/view/${objectType}?portalId=${PortalIdParser.get()}`;
const getRecordRecentlyViewedAssetUrl = (objectType, objectId) => `dashboard/v2/${objectType}/${objectId}?record=true&portalId=${PortalIdParser.get()}`;
const fetchRecentlyViewedDashboards = () => http.get(getRecentlyViewedAssetUrl('dashboard'), {
  query: {
    limit: RECENT_ASSET_LIMIT
  }
});
const fetchRecentlyViewedReports = () => http.get(getRecentlyViewedAssetUrl('reports'), {
  query: {
    limit: RECENT_ASSET_LIMIT
  }
});
const recordRecentlyViewedDashboard = dashboardId => http.get(getRecordRecentlyViewedAssetUrl('dashboard', dashboardId));
const recordRecentlyViewedReport = reportId => http.get(getRecordRecentlyViewedAssetUrl('reports', reportId));

// @returns {List()} A List of Maps with the shape { favorite, name, objectId, objectType, viewedAt }
export const FETCH_RECENT_DASHBOARDS = registerQuery({
  fieldName: RECENT_DASHBOARDS_FIELD_NAME,
  fetcher() {
    return fetchRecentlyViewedDashboards().then(fromJS);
  }
});

// @returns {List()} A List of Maps with the shape { favorite, name, objectId, objectType, viewedAt }
export const FETCH_RECENT_REPORTS = registerQuery({
  fieldName: RECENT_REPORTS_FIELD_NAME,
  fetcher() {
    return fetchRecentlyViewedReports().then(fromJS);
  }
});

// @dashboardId {Number}
// @returns {Map} of dashboard object
export const UPDATE_RECENT_DASHBOARDS = registerMutation({
  fieldName: RECENT_DASHBOARDS_FIELD_NAME,
  args: ['dashboardId'],
  fetcher({
    dashboardId
  }) {
    return recordRecentlyViewedDashboard(dashboardId);
  }
});

// @reportId {Number}
// @returns {Map} of report object
export const UPDATE_RECENT_REPORTS = registerMutation({
  fieldName: RECENT_REPORTS_FIELD_NAME,
  args: ['reportId'],
  fetcher({
    reportId
  }) {
    return recordRecentlyViewedReport(reportId);
  }
});