'use es6';

import http from 'hub-http/clients/apiClient';
import userInfo from 'hub-http/userInfo';
import { formatOwnersResponse } from '../lib/OwnersDaoHelper';
export const getOwners = () => {
  const query = {
    includeSignature: false
  };
  return userInfo().then(auth => auth.user.user_id).then(userId => http.get('owners/v2/owners', {
    query
  }).then(ownersResponse => formatOwnersResponse(ownersResponse, userId)));
};