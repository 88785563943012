// @ts-expect-error untyped
import { isValidMomentDate, isBoolean } from './OperatorValidators';
import { makeOperator } from './Operator';
import { MomentDate } from '../DisplayTypes';
export default makeOperator({
  displayType: MomentDate,
  name: 'BeforeTimePoint',
  values: [{
    name: 'value',
    isValid: isValidMomentDate
  }, {
    name: 'hasTimezones',
    isValid: isBoolean,
    defaultValue: false
  }]
});