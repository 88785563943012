'use es6';

// @getProblem {func} a function that accepts a report or dashboard and returns an object with problem & disabledMessage values
// @returns {object} The problem and disabledMessage for the first asset with a problem or an object w/ null problem & disabledMessage if the user can act on all assets
export const getBulkActionProblem = ({
  assets,
  getProblem
}) => {
  const assetWithProblem = assets.find(asset => getProblem(asset).problem);
  return assetWithProblem ? getProblem(assetWithProblem) : {
    problem: null,
    disabledMessage: null
  };
};