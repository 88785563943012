/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */
/* eslint-disable prefer-promise-reject-errors */

import PortalIdParser from 'PortalIdParser';
import { UserAttributesClient } from 'frontend-preferences-client';
const userAttributesClient = UserAttributesClient.forCaller('dashboard-ui-components');

// If these values are updated, be sure to also updated earlyRequester.js
export const USER_ATTRIBUTES_KEYS = {
  PREFERRED_HOME: `preferred-home:portal-${PortalIdParser.get()}`,
  DB_FILTERS_SUNSET_ALERT_DISMISSED_TIMESTAMP_KEY: 'Reporting:DashboardFiltersSunsetAlertDismissedTimestamp'
};
const USER_ATTRIBUTES_DEFAULTS = Object.values(USER_ATTRIBUTES_KEYS).reduce((next, attributeKey) => Object.assign({
  [attributeKey]: ''
}, next), {});
export function fetch() {
  // http.get(USER_ATTRIBUTES_URL, { query: { keys: USER_ATTRIBUTES_ARRAY } });

  return userAttributesClient.batchFetch(USER_ATTRIBUTES_DEFAULTS, 'userAttributes');
}
export function save(key, value) {
  return userAttributesClient.write(key, value);
}
export function remove(key) {
  return userAttributesClient.del(key);
}