/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

import Superstore, { IndexedDB } from 'superstore';
import toJS from 'transmute/toJS';
export const defaultNamespace = 'platform-dashboard-ui-cache';
let client;
export const getSuperstoreClient = (namespace = defaultNamespace) => {
  if (!client) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    client = new Superstore({
      backend: IndexedDB,
      namespace
    }).open();
  }
  return client;
};
export const getSuperstoreKey = keyPart => `${defaultNamespace}:${keyPart}`;
export const getSuperstoreCachedValue = key => {
  return getSuperstoreClient().then(store => {
    return store.get(key);
  }).catch(error => {
    console.error(error);
  });
};
export const setSuperstoreCachedValue = (key, data) => {
  return getSuperstoreClient().then(store => {
    return store.set(key, toJS(data)).catch(() => {
      // Some part of the cache write failed. It doesn't really matter what failed,
      // regardless we want to blow away cache so it's clear for next fetch
      return store.delete(key);
    }).catch(error => {
      console.error(error);
    });
  });
};