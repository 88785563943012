import { fromJS } from 'immutable';
export const RANGE_TYPES = {
  ALL: 'ALL',
  THIS_DAY: 'THIS_DAY',
  LAST_DAY: 'LAST_DAY',
  NEXT_DAY: 'NEXT_DAY',
  THIS_WEEK: 'THIS_WEEK',
  LAST_WEEK: 'LAST_WEEK',
  NEXT_WEEK: 'NEXT_WEEK',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  NEXT_MONTH: 'NEXT_MONTH',
  CUSTOM_MONTH: 'CUSTOM_MONTH',
  THIS_QUARTER: 'THIS_QUARTER',
  LAST_QUARTER: 'LAST_QUARTER',
  NEXT_QUARTER: 'NEXT_QUARTER',
  THIS_FISCAL_QUARTER: 'THIS_FISCAL_QUARTER',
  LAST_FISCAL_QUARTER: 'LAST_FISCAL_QUARTER',
  NEXT_FISCAL_QUARTER: 'NEXT_FISCAL_QUARTER',
  CUSTOM_QUARTER: 'CUSTOM_QUARTER',
  THIS_YEAR: 'THIS_YEAR',
  LAST_YEAR: 'LAST_YEAR',
  NEXT_YEAR: 'NEXT_YEAR',
  THIS_FISCAL_YEAR: 'THIS_FISCAL_YEAR',
  LAST_FISCAL_YEAR: 'LAST_FISCAL_YEAR',
  NEXT_FISCAL_YEAR: 'NEXT_FISCAL_YEAR',
  CUSTOM_YEAR: 'CUSTOM_YEAR',
  ROLLING: 'ROLLING',
  CUSTOM: 'CUSTOM',
  IS_EQUAL_TO: 'IS_EQUAL_TO',
  IS_BEFORE_DATE: 'IS_BEFORE_DATE',
  IS_AFTER_DATE: 'IS_AFTER_DATE'
};
export const STANDARD_TO_FISCAL_YEAR_DATE_RANGE = {
  [RANGE_TYPES.THIS_QUARTER]: RANGE_TYPES.THIS_FISCAL_QUARTER,
  [RANGE_TYPES.LAST_QUARTER]: RANGE_TYPES.LAST_FISCAL_QUARTER,
  [RANGE_TYPES.NEXT_QUARTER]: RANGE_TYPES.NEXT_FISCAL_QUARTER,
  [RANGE_TYPES.THIS_YEAR]: RANGE_TYPES.THIS_FISCAL_YEAR,
  [RANGE_TYPES.LAST_YEAR]: RANGE_TYPES.LAST_FISCAL_YEAR,
  [RANGE_TYPES.NEXT_YEAR]: RANGE_TYPES.NEXT_FISCAL_YEAR,
  [RANGE_TYPES.ALL]: RANGE_TYPES.ALL,
  [RANGE_TYPES.ROLLING]: RANGE_TYPES.ROLLING,
  [RANGE_TYPES.IS_BEFORE_DATE]: RANGE_TYPES.IS_BEFORE_DATE,
  [RANGE_TYPES.IS_AFTER_DATE]: RANGE_TYPES.IS_AFTER_DATE,
  [RANGE_TYPES.CUSTOM_QUARTER]: RANGE_TYPES.CUSTOM_QUARTER,
  [RANGE_TYPES.CUSTOM_YEAR]: RANGE_TYPES.CUSTOM_YEAR,
  [RANGE_TYPES.CUSTOM]: RANGE_TYPES.CUSTOM
};
export const FISCAL_YEAR_TO_STANDARD_DATE_RANGE = fromJS(STANDARD_TO_FISCAL_YEAR_DATE_RANGE).flip().toJS();
export const ROLLING_DATE_TYPES = {
  '7': 7,
  '14': 14,
  '30': 30,
  '60': 60,
  '90': 90,
  '180': 180,
  '365': 365
};