'use es6';

export const contentAssistanceNamespace = 'contentAssistance';

// Action Types
const FETCH_CONTENT_ASSISTANCE = 'FETCH_CONTENT_ASSISTANCE';
export const initializeContentAssistance = ({
  isCustomerAnalysisEnabled,
  isContentAssistanceEnabled
}) => dispatch => {
  dispatch({
    type: FETCH_CONTENT_ASSISTANCE,
    payload: {
      isCustomerAnalysisEnabled,
      isContentAssistanceEnabled
    }
  });
};
export const contentAssistanceReducer = (state = {
  isCustomerAnalysisEnabled: false,
  isContentAssistanceEnabled: false
}, action) => {
  switch (action.type) {
    case FETCH_CONTENT_ASSISTANCE:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};
export const selectIsCustomerAnalysisEnabled = state => {
  return state[contentAssistanceNamespace]['isCustomerAnalysisEnabled'];
};
export const selectIsContentAssistanceEnabled = state => {
  return state[contentAssistanceNamespace]['isContentAssistanceEnabled'];
};