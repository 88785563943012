import { getIframeAwareClientRect } from 'UIComponents/utils/Dom';
export const getAttachedElement = (attachTo, targetDocument = document) => {
  if (typeof attachTo === 'string') {
    return targetDocument.querySelector(attachTo);
  } else if (typeof attachTo === 'function') {
    return attachTo();
  }
  // 'attachTo' is already an element
  return attachTo;
};
export const getAttachedElements = attachTo => {
  if (!attachTo) {
    return [];
  }
  const attaches = Array.isArray(attachTo) ? attachTo : [attachTo];
  return attaches.map(attach => getAttachedElement(attach)).filter(element => Boolean(element));
};
export const getAbsoluteRect = element => {
  const rect = getIframeAwareClientRect(element);
  // Make rect enumerable
  return {
    left: rect.left,
    right: rect.right,
    top: rect.top,
    bottom: rect.bottom
  };
};
export const isElementVisible = element => {
  if (!element) {
    return false;
  }
  return Boolean(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
};
export const padRect = (rect, padding) => {
  const {
    left,
    right,
    top,
    bottom
  } = rect;
  return {
    left: left - padding,
    right: right + padding,
    top: top - padding,
    bottom: bottom + padding
  };
};