import { List } from 'immutable';
import { GLOBAL_NULL } from '../constants/defaultNullValues';
import I18n from 'I18n';
export default (({
  labelKeyOrKeys,
  fallbackTranslationPath
}) => {
  return (reference, key) => {
    const fallbackLabel = fallbackTranslationPath ? I18n.text(fallbackTranslationPath, {
      id: key
    }) : key;
    const fallback = key !== GLOBAL_NULL ? fallbackLabel : undefined;
    const labelKeys = List(List.isList(labelKeyOrKeys) ? labelKeyOrKeys : [labelKeyOrKeys]);
    const foundLabel = labelKeys.find(labelKey => {
      return reference.get(labelKey);
    });
    return reference.get(foundLabel) || fallback;
  };
});