import * as checked from 'reporting-data/lib/checked';
import { ReportWithOptions } from '../../resolve/resolve-records';
export const ExportTypes = {
  XLS: 'xls',
  XLSX: 'xlsx',
  CSV: 'csv'
};
export const ExportRequest = checked.record({
  dataReport: ReportWithOptions,
  summaryReport: ReportWithOptions.optional(),
  exportFormat: checked.symbol(ExportTypes, 'ExportTypes'),
  exportName: checked.string().optional()
}, 'ExportRequest');