/* hs-eslint ignored failing-rules */

'use es6';

import Dispatcher from 'dispatcher/dispatcher';
import { INITIALIZATION, OWNERS_FETCH_SUCCESS, TEAMS_FETCH_SUCCESS, PORTAL_SETTINGS_FETCH_SUCCESS } from '../constants/SystemDataActionTypes';
import * as HubSettingsDAO from '../data/HubSettingsDAO';
import { getOwners } from '../data/OwnersDAO';
import { fetchDashboards, getReportCounts } from './DashboardActions';
import { getAllTeam } from 'dashboard-lib/public/team/team-dao';
export function fetchOwners() {
  return getOwners().then(owners => {
    Dispatcher.dispatch({
      actionType: OWNERS_FETCH_SUCCESS,
      data: owners
    });
  });
}
export function fetchTeams() {
  return getAllTeam().then(teams => {
    Dispatcher.dispatch({
      actionType: TEAMS_FETCH_SUCCESS,
      data: teams
    });
  });
}
const SETTINGS_KEYS = ['hsfs:dashboard-banner-dismissed', 'hsfs:dashboard-modal-dismissed'];
function fetchPortalSettings() {
  return HubSettingsDAO.getSettings(SETTINGS_KEYS).then(settings => {
    Dispatcher.dispatch({
      actionType: PORTAL_SETTINGS_FETCH_SUCCESS,
      data: {
        settings
      }
    });
  });
}
export default {
  start() {
    Dispatcher.dispatch({
      actionType: INITIALIZATION
    });
    fetchDashboards();
    getReportCounts();
    Promise.all([fetchPortalSettings(), fetchTeams()]).catch(console.error);
  }
};