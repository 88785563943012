import * as checked from 'reporting-data/lib/checked';
export const FieldRef = checked.record({
  property: checked.string(),
  table: checked.string()
}, 'FieldRef');
export const getProperty = fieldRef => fieldRef.property;
export const getTable = fieldRef => fieldRef.table;

// TODO: confusing, there is a duplicate thing in column-utils
export const getFieldKey = fieldRef => `${fieldRef.table}.${fieldRef.property}`;

/**
 * @param fieldRef {FieldRef}
 * @param snowflakeProperty {SnowflakeProperty?}
 * @returns {String}
 */
export const getFieldRefLabel = (fieldRef, snowflakeProperty) => {
  const rawName = fieldRef.property;
  if (snowflakeProperty == null) {
    return rawName;
  }
  return snowflakeProperty.label || rawName;
};