import { useMemo } from 'react';
// @ts-expect-error migrate upstream dependency
import { getDashboardReports } from 'dashboard-lib/public/dashboard/dashboard-logic';
import { getReportDataSourceUsages } from '../../../../../../lib/dashboardFilters/dataSourceHelpers';
import { useSavedDashboardFiltersPanelContext } from '../context';
export const useReportDataSourceUsages = () => {
  const {
    dashboard
  } = useSavedDashboardFiltersPanelContext();
  const reportDataSourceUsages = useMemo(() => {
    const reports = getDashboardReports(dashboard);
    return getReportDataSourceUsages({
      reports
    });
  }, [dashboard]);
  return reportDataSourceUsages;
};