/**
 * Safe JSON parse
 *
 * @param {string} item Stringified object
 * @returns {object} Parsed object
 */
const parse = item => {
  try {
    return JSON.parse(item);
  } catch (err) {
    return item;
  }
};

/**
 * Check if key exists in localStorage
 *
 * @param   {string}  key Key to check in localStorage
 * @returns {boolean}     Whether key exists
 */
export const has = key => {
  try {
    return {}.hasOwnProperty.call(localStorage, key);
  } catch (err) {
    console.warn(`storage: failed to check existence of '${key}' in localStorage`);
    return false;
  }
};

/**
 * Get key from localStorage
 *
 * @param   {string}  key Key to get from localStorage
 * @returns {?string}     Value and success pair
 */
export const get = key => {
  try {
    const item = localStorage.getItem(key);
    return parse(item);
  } catch (err) {
    console.warn(`storage: failed to get '${key}' from localStorage`);
    return null;
  }
};

/**
 * Set value from localStorage
 *
 * @param   {string} key   Key to set in localStorage
 * @param   {any} value Value to set
 * @returns {void}
 */
export const set = (key, value) => {
  try {
    const item = JSON.stringify(value);
    localStorage.setItem(key, item);
  } catch (err) {
    console.warn(`storage: failed to set '${value}' as '${key}' in localStorage`);
  }
};

/**
 * Remove value from localStorage
 *
 * @param   {string} key Key to remove in localStorage
 * @returns {void}
 */
export const remove = key => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.warn(`storage: failed to remove '${key}' in localStorage`);
  }
};