'use es6';

import getRemoteProperties from '../../retrieve/inboundDb/common/properties';
import { LINE_ITEMS } from '../../constants/dataTypes';
import { mergeProperties } from '../mergeProperties';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import countProperty from '../partial/count-property';
import lineItemsModule from '../../dataTypeDefinitions/inboundDb/line-items';
import overridePropertyTypes from '../../retrieve/inboundDb/common/overridePropertyTypes';
import { PRODUCT } from 'reference-resolvers/constants/ReferenceObjectTypes';
export const getPropertyGroups = () => getRemoteProperties(LINE_ITEMS).then(remoteProps => mergeProperties(remoteProps, 'lineiteminformation', {
  hs_product_id: {
    externalOptions: true,
    referencedObjectType: PRODUCT,
    type: 'enumeration',
    reportingOverwrittenNumericType: true
  }
}));
export const getProperties = () => createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(LINE_ITEMS)))().then(overridePropertyTypes(lineItemsModule.getInboundSpec()));