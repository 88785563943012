import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["quickFilterGroupName", "systemGenerated", "isDateRangeFilter"];
import getIn from 'transmute/getIn';
import { List as ImmutableList } from 'immutable';
import { DATE, DATE_TIME } from 'reporting-data/constants/property-types';
import { areFilterOperatorsAndValuesSame, DashboardFilterOperators } from './types';
import { getType, isRecordIdProperty, getExternalOptionsReferenceType, getOptions
// @ts-expect-error migrate upstream
} from './propertySchema';

// Names of quick filter groups that are generated.
const DATE_RANGE_QUICK_FILTER_TEMPLATE_NAME = 'Date range';
const OWNER_QUICK_FILTER_TEMPLATE_NAME = 'Owners';
const TEAM_QUICK_FILTER_TEMPLATE_NAME = 'Teams';
export const MAX_QUICK_FILTER_TEMPLATES = 3;
export const QUICK_FILTER_TEMPLATES = {
  DATE_RANGE: 'DATE_RANGE',
  OWNER: 'OWNER',
  TEAM: 'TEAM'
};
export const extractQuickFilterGroupFromAppliedFilters = (quickFilterGroup, appliedFilters) => {
  const {
    properties: quickFilterGroupProperties
  } = quickFilterGroup;
  // Filter out properties from applied filters that match a property from the quick filter group.
  const appliedQuickFilterGroupProperties = appliedFilters.filter(appliedFilter => {
    const {
      dataSource: appliedFilterDataSource,
      propertyName: appliedFilterPropertyName
    } = appliedFilter;
    return quickFilterGroupProperties.some(quickFilterGroupProperty => {
      const {
        name: quickFilterGroupPropertyName
      } = quickFilterGroupProperty;
      const quickFilterGroupPropertyDataSource = quickFilterGroupProperty.dataSource || quickFilterGroupProperty.dataSourceId;
      return quickFilterGroupPropertyDataSource === appliedFilterDataSource && quickFilterGroupPropertyName === appliedFilterPropertyName;
    });
  });
  return appliedQuickFilterGroupProperties;
};
export const isDateRangeGeneratedQuickFilterGroup = quickFilterGroup => {
  const {
    name,
    systemGenerated
  } = quickFilterGroup;
  return systemGenerated && name === DATE_RANGE_QUICK_FILTER_TEMPLATE_NAME;
};
export const checkIfQuickFilterGroupIsApplied = (quickFilterGroup, appliedFilters) => {
  const {
    properties: quickFilterGroupProperties
  } = quickFilterGroup;
  const maybeAppliedQuickFilterGroupFilters = extractQuickFilterGroupFromAppliedFilters(quickFilterGroup, appliedFilters);

  // If the applied filters list does contain every property from the quick filter group, then check for operator/value equality.
  if (maybeAppliedQuickFilterGroupFilters.length === quickFilterGroupProperties.length) {
    const firstFilter = maybeAppliedQuickFilterGroupFilters[0];
    const areAllFiltersEqual = maybeAppliedQuickFilterGroupFilters.every(appliedFilter => {
      return areFilterOperatorsAndValuesSame(appliedFilter, firstFilter);
    });
    if (areAllFiltersEqual && isDateRangeGeneratedQuickFilterGroup(quickFilterGroup)) {
      return !maybeAppliedQuickFilterGroupFilters.some(filter => filter.operator === DashboardFilterOperators.KNOWN || filter.operator === DashboardFilterOperators.UNKNOWN);
    }
    return areAllFiltersEqual;
  }
  return false;
};
export const isEditingQuickFilterGroupNameUnique = (name, editingQuickFilterGroup, quickFilterGroups) => {
  const {
    originalGroupName
  } = editingQuickFilterGroup;
  if (name !== originalGroupName) {
    if (quickFilterGroups.some(group => group.name === name)) {
      return false;
    }
  }
  return true;
};
export const isQuickFilterGroupNameUnique = (quickFilterGroupToTest, quickFilterGroups) => {
  const {
    name
  } = quickFilterGroupToTest;
  const count = quickFilterGroups.filter(group => group.name.toLocaleLowerCase() === name.toLocaleLowerCase()).length;
  if (count > 1) {
    return false;
  }
  return true;
};
export const getPropertyRecordsInQuickFilterGroup = (quickFilterGroup, dataSourcesProperties) => {
  return quickFilterGroup.properties.map(property => getIn([property.dataSourceId, property.name], dataSourcesProperties)).filter(maybeProperty => !!maybeProperty);
};
export const isDateOrDatetimeType = type => type === DATE || type === DATE_TIME;
export const isPropertyDateOrDatetimeType = property => {
  const propertyType = getType(property);
  return isDateOrDatetimeType(propertyType);
};
export const isQuickFilterGroupPropertyTypesSame = (editingQuickFilterGroup, dataSourcesProperties) => {
  const propertiesInGroup = getPropertyRecordsInQuickFilterGroup(editingQuickFilterGroup, dataSourcesProperties);
  const types = [...new Set(propertiesInGroup.map(property => getType(property)))];
  if (types.length > 1) {
    // Allow for date and datetime properties in a group
    if (types.every(type => isDateOrDatetimeType(type))) {
      return true;
    }
    return false;
  }
  return true;
};
export const isUsingRecordIdPropertyInGroup = (quickFilterGroup, dataSourcesProperties) => {
  const propertiesInGroup = getPropertyRecordsInQuickFilterGroup(quickFilterGroup, dataSourcesProperties);
  return propertiesInGroup.some(isRecordIdProperty);
};
export const shouldPropertyBeEnabledForHubSpotTeamGroup = (property, quickFilterGroup) => {
  if (quickFilterGroup.properties.length === 0) {
    return true;
  }
  const {
    properties
  } = quickFilterGroup;
  const isHubSpotTeamGroup = properties.every(_property => _property.name === 'hubspot_team_id');
  if (isHubSpotTeamGroup) {
    const propertyName = property.get('name');
    return propertyName === 'hubspot_team_id';
  }
  return false;
};
export const doesPropertyShareExternallyReferencedObjectWithGroup = (property, quickFilterGroup, dataSourcesProperties) => {
  if (quickFilterGroup.properties.length === 0) {
    return true;
  }
  const groupProperty = quickFilterGroup.properties[0];
  const groupPropertyRecord = getIn([groupProperty.dataSourceId, groupProperty.name], dataSourcesProperties);
  const groupPropertyExternalReferenceObject = getExternalOptionsReferenceType(groupPropertyRecord) || null;
  const propertiesExternalReferencedObject = getExternalOptionsReferenceType(property) || null;
  return propertiesExternalReferencedObject === groupPropertyExternalReferenceObject;
};
export const doPropertiesInGroupShareExternallyReferencedObject = (quickFilterGroup, dataSourcesProperties) => {
  const propertiesInGroup = getPropertyRecordsInQuickFilterGroup(quickFilterGroup, dataSourcesProperties);
  return propertiesInGroup.every(property => doesPropertyShareExternallyReferencedObjectWithGroup(property, quickFilterGroup, dataSourcesProperties) === true);
};
export const doesPropertyShareDropdownOptionsWithGroup = (property, quickFilterGroup, dataSourcesProperties) => {
  if (quickFilterGroup.properties.length === 0) {
    return true;
  }
  const groupProperty = quickFilterGroup.properties[0];
  const groupPropertyRecord = getIn([groupProperty.dataSourceId, groupProperty.name], dataSourcesProperties);
  const groupPropertyOptions = getOptions(groupPropertyRecord) || ImmutableList();
  const propertiesOptions = getOptions(property) || ImmutableList();
  if (propertiesOptions.size !== groupPropertyOptions.size) {
    return false;
  }
  return propertiesOptions.equals(groupPropertyOptions);
};
export const doPropertiesInGroupShareDropdownOptions = (quickFilterGroup, dataSourcesProperties) => {
  const propertiesInGroup = getPropertyRecordsInQuickFilterGroup(quickFilterGroup, dataSourcesProperties);
  return propertiesInGroup.every(property => doesPropertyShareDropdownOptionsWithGroup(property, quickFilterGroup, dataSourcesProperties) === true);
};
export const getQuickFilterGroupWithProperty = (quickFilterGroups, propertyName, dataSourceId) => {
  const maybeGroup = quickFilterGroups.find(group => group.properties.some(property => property.name === propertyName && property.dataSourceId === dataSourceId));
  return maybeGroup;
};
export const getExistingGeneratedQuickFilterGroupTemplates = quickFilterGroups => {
  const generatedQuickFilterGroups = quickFilterGroups.filter(quickFilterGroup => quickFilterGroup.systemGenerated);
  const existingGeneratedQuickFilterGroupTemplates = generatedQuickFilterGroups.reduce((acc, generatedQuickFilterGroup) => {
    const {
      name
    } = generatedQuickFilterGroup;
    switch (name) {
      case DATE_RANGE_QUICK_FILTER_TEMPLATE_NAME:
        return [...acc, QUICK_FILTER_TEMPLATES.DATE_RANGE];
      case OWNER_QUICK_FILTER_TEMPLATE_NAME:
        return [...acc, QUICK_FILTER_TEMPLATES.OWNER];
      case TEAM_QUICK_FILTER_TEMPLATE_NAME:
        return [...acc, QUICK_FILTER_TEMPLATES.TEAM];
      default:
        return acc;
    }
  }, []);
  return existingGeneratedQuickFilterGroupTemplates;
};
export const isOwnersGeneratedQuickFilterGroup = quickFilterGroup => {
  const {
    name,
    systemGenerated
  } = quickFilterGroup;
  return systemGenerated && name === OWNER_QUICK_FILTER_TEMPLATE_NAME;
};
export const isTeamsGeneratedQuickFilterGroup = quickFilterGroup => {
  const {
    name,
    systemGenerated
  } = quickFilterGroup;
  return systemGenerated && name === TEAM_QUICK_FILTER_TEMPLATE_NAME;
};
export const getPropertiesInQuickFilterGroups = quickFilterGroups => {
  return quickFilterGroups.reduce((acc, quickFilterGroup) => {
    return [...acc, ...quickFilterGroup.properties];
  }, []);
};
export const findDuplicateProperties = (generatedQuickFilterGroups, quickFilterGroups, quickFilters) => {
  const allGeneratedProperties = getPropertiesInQuickFilterGroups(generatedQuickFilterGroups);
  const allExistingProperties = [...getPropertiesInQuickFilterGroups(quickFilterGroups), ...quickFilters];
  const duplicateProperties = allGeneratedProperties.filter(generatedProperty => allExistingProperties.find(existingProperty => existingProperty.name === generatedProperty.name && existingProperty.dataSourceId === generatedProperty.dataSourceId));
  return duplicateProperties;
};
export const removeDuplicateProperties = (duplicateProperties, existingProperties) => {
  return existingProperties.filter(existingProperty => {
    for (const duplicateProperty of duplicateProperties) {
      if (existingProperty.name === duplicateProperty.name && existingProperty.dataSourceId === duplicateProperty.dataSourceId) {
        return false;
      }
    }
    return true;
  });
};
export const resolveDuplicatePropertiesInQuickFilters = (generatedQuickFilterGroups, quickFilterGroups, quickFilters) => {
  const duplicateProperties = findDuplicateProperties(generatedQuickFilterGroups, quickFilterGroups, quickFilters);
  const updatedIndividualQuickFilters = removeDuplicateProperties(duplicateProperties, quickFilters);
  const updatedQuickFilterGroups = quickFilterGroups.reduce((acc, quickFilterGroup) => {
    const {
      properties: existingProperties
    } = quickFilterGroup;
    const updatedProperties = removeDuplicateProperties(duplicateProperties, existingProperties);
    if (updatedProperties.length === 0) return acc;
    return [...acc, Object.assign({}, quickFilterGroup, {
      properties: updatedProperties
    })];
  }, []);
  return {
    updatedQuickFilterGroups: [...generatedQuickFilterGroups, ...updatedQuickFilterGroups],
    updatedQuickFilters: updatedIndividualQuickFilters
  };
};
export const isAppliedFilterInQuickFilterGroup = (quickFilterGroup, appliedFilter) => {
  const {
    properties
  } = quickFilterGroup;
  return properties.some(property => {
    const propertyDataSource = property.dataSource || property.dataSourceId;
    return appliedFilter.dataSource === propertyDataSource && appliedFilter.propertyName === property.name;
  });
};
export const removeDashboardFilterQuickFilterFlags = filter => {
  const filterClone = _objectWithoutPropertiesLoose(filter, _excluded);
  return filterClone;
};
export const applySystemGeneratedAndQuickFilterGroupFlags = (dashboardFilters, quickFilterGroups) => {
  const appliedQuickFilterGroups = quickFilterGroups.filter(group => {
    const isApplied = checkIfQuickFilterGroupIsApplied(group, dashboardFilters);
    return isApplied;
  });
  return dashboardFilters.map(filter => {
    const belongingQuickFilterGroup = appliedQuickFilterGroups.find(group => {
      return isAppliedFilterInQuickFilterGroup(group, filter);
    });
    if (belongingQuickFilterGroup) {
      const groupFlagsAppliedFilter = Object.assign({}, filter, {
        systemGenerated: belongingQuickFilterGroup.systemGenerated,
        quickFilterGroupName: belongingQuickFilterGroup.name,
        isDateRangeFilter: isDateRangeGeneratedQuickFilterGroup(belongingQuickFilterGroup)
      });
      return groupFlagsAppliedFilter;
    }
    return removeDashboardFilterQuickFilterFlags(filter);
  });
};