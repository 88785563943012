import { makePropertyMetadataEntriesFetchKey, makePropertyMetadataEntriesFetchUrl, makeTypeMetadataEntriesFetchUrl } from 'framework-data-schema-quick-fetch';
import { withQuickFetch } from 'framework-data-schema-quick-fetch/withQuickFetch';
export const fetchTypeMetadataEntries = ({
  appSettingNames,
  httpClient
}) => httpClient.post(makeTypeMetadataEntriesFetchUrl(), {
  data: appSettingNames
});
export const fetchPropertyMetadataEntries = ({
  frameworkTypeIdentifier,
  appSettingName,
  httpClient
}) => withQuickFetch({
  requestName: makePropertyMetadataEntriesFetchKey({
    frameworkTypeIdentifier,
    appSettingName
  }),
  baseFetch: () => httpClient.get(makePropertyMetadataEntriesFetchUrl({
    frameworkTypeIdentifier,
    appSettingName
  }))
});