import { OWNER, USER, CRM_OBJECT } from 'customer-data-objects/externalOptions/ExternalOptionsReferenceTypes';
import Raven from 'raven-js';
const NUMERIC_IDS_ONLY = /^\d+$/;
export const defaultIdIsValid = (referenceType, id) => {
  const isEmptyId = id === '' || id === undefined || id === null;
  if (isEmptyId) {
    return false;
  }
  const isNumberId = typeof id === 'number';
  if (isNumberId) {
    Raven.capturePageEvent('invalid-rrl-id', {
      extra: {
        id,
        referenceType,
        type: 'Numeric Id'
      }
    });
  }
  switch (referenceType) {
    // these ReferenceTypes only accept numeric ids. attempting to send a
    // non-numeric id to ExternalOptions for any of these reference types will
    // cause the API call to fail with a 400 (runtime exception thrown in
    // `parseIntId`/`parseLongId`)
    case OWNER:
    case USER:
    case CRM_OBJECT:
      return NUMERIC_IDS_ONLY.test(id);
    default:
      return true;
  }
};