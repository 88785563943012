'use es6';

import http from 'hub-http/clients/apiClient';
import formatChatflows from 'reference-resolvers/formatters/formatChatflows';
export const createGetChatflowsById = ({
  httpClient
}) => idsToFetch => httpClient.post(`crm-objects/v1/objects/chatflow/batch-read?properties=hs_chatflow_name&properties=hs_deleted_at`, {
  data: {
    ids: idsToFetch
  }
}).then(formatChatflows);
export const getChatflowsById = createGetChatflowsById({
  httpClient: http
});