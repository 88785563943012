'use es6';

import { AssetPermission } from '../../../../public/permission/asset-permission';
import { hasSufficientPermission } from '../../../../public/permission/compute-permission';
import { isString } from '../../../js-util';
import { anyNumberIsLoading } from '../../common/any-number-is-loading';
import { getAndCheck } from '../../common/get-and-check-param';
import { getAllProblemsCurried } from '../../common/get-problems-no-check';
import { limitReachedOrExceeded } from '../../common/limits';
import { createProblem } from '../../common/problem';
import { DASHBOARD_ID, DASHBOARD_IS_LOADING, DASHBOARD_REPORT_LIMIT, IS_READ_ONLY_DEMO, REPORT_NAME, TARGET_DASHBOARD_ID, TARGET_DASHBOARD_REPORT_COUNT, TARGET_IS_READ_ONLY_DEMO, TARGET_USER_DASHBOARD_PERMISSION, USER_DASHBOARD_PERMISSION, RESTRICTED_FIELD_LEVEL_PROPERTIES } from '../../params/all-params';
import { NEED_EDIT_PERMISSION } from '../../problems/permission-problem';
export const LOADING = createProblem('LOADING', params => {
  const dashboardIsLoading = getAndCheck(DASHBOARD_IS_LOADING, params);
  const targetDashboardReportCount = getAndCheck(TARGET_DASHBOARD_REPORT_COUNT, params);
  const dashboardReportLimit = getAndCheck(DASHBOARD_REPORT_LIMIT, params);
  return dashboardIsLoading && anyNumberIsLoading(targetDashboardReportCount, dashboardReportLimit);
});
export const PERMISSION_DENIED = createProblem('PERMISSION_DENIED', params => {
  const dashboardPermission = getAndCheck(USER_DASHBOARD_PERMISSION, params);
  const targetDashboardPermission = getAndCheck(TARGET_USER_DASHBOARD_PERMISSION, params);
  return !hasSufficientPermission(AssetPermission.EDIT, dashboardPermission) || !hasSufficientPermission(AssetPermission.EDIT, targetDashboardPermission);
});
export const IS_READ_ONLY_DEMO_PROBLEM = createProblem('IS_READ_ONLY_DEMO_PROBLEM', params => getAndCheck(IS_READ_ONLY_DEMO, params));
export const TARGET_IS_READ_ONLY_DEMO_PROBLEM = createProblem('TARGET_IS_READ_ONLY_DEMO_PROBLEM', params => getAndCheck(TARGET_IS_READ_ONLY_DEMO, params));
export const TARGET_WILL_BE_OVER_REPORT_LIMIT = createProblem('TARGET_WILL_BE_OVER_REPORT_LIMIT', params => {
  const targetDashboardReportCount = getAndCheck(TARGET_DASHBOARD_REPORT_COUNT, params);
  const dashboardReportLimit = getAndCheck(DASHBOARD_REPORT_LIMIT, params);
  return limitReachedOrExceeded(targetDashboardReportCount, dashboardReportLimit);
});
export const SAME_DASHBOARD = createProblem('SAME_DASHBOARD', params => {
  const dashboardId = getAndCheck(DASHBOARD_ID, params);
  const targetDashboardId = getAndCheck(TARGET_DASHBOARD_ID, params);
  return dashboardId === targetDashboardId;
});
export const BAD_REPORT_NAME = createProblem('BAD_REPORT_NAME', params => {
  const reportName = getAndCheck(REPORT_NAME, params);
  const isValid = isString(reportName) && reportName.trim().length;
  return !isValid;
});
export const LACKS_FIELD_ACCESS = createProblem('LACKS_FIELD_ACCESS', params => {
  const restrictedFieldLevelProperties = getAndCheck(RESTRICTED_FIELD_LEVEL_PROPERTIES, params);
  return restrictedFieldLevelProperties && restrictedFieldLevelProperties.length > 0;
});
export const rankedProblems = [LOADING, NEED_EDIT_PERMISSION, PERMISSION_DENIED, IS_READ_ONLY_DEMO_PROBLEM, LACKS_FIELD_ACCESS, TARGET_IS_READ_ONLY_DEMO_PROBLEM, TARGET_WILL_BE_OVER_REPORT_LIMIT, SAME_DASHBOARD, BAD_REPORT_NAME];
export const getAll = getAllProblemsCurried(rankedProblems);