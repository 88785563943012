'use es6';

import { fromJS } from 'immutable';
import { getUseFiscalYear } from '../../../lib/fiscalYearUtils';
import makeDateRangeByType from '../../../lib/makeDateRangeByType';
const format = 'YYYYMMDD';
export const get = (spec, config, runtimeOptions = {}) => {
  const {
    dataType,
    filters: {
      dateRange: {
        value
      } = {}
    } = {}
  } = config;
  const useFiscalYear = getUseFiscalYear(fromJS(config));
  const {
    fiscalYearMonthName
  } = runtimeOptions;
  const {
    startDate,
    endDate
  } = makeDateRangeByType(value, format, dataType, useFiscalYear, fiscalYearMonthName);
  return {
    start: startDate,
    end: endDate
  };
};