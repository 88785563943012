'use es6';

import { AssetPermissionTypes } from '../../../public/permission/asset-permission';
import * as ParamType from '../../../public/validation/param-type';
import { createParam } from '../common/param';
import { SaveReportUseTypeList } from 'dashboard-lib/public/action/save-report/save-report-use';
const {
  nullable
} = ParamType;

/**
 * When adding parameters, consider:
 *   1. Primitives: use primitives; avoid deep objects
 *   2. Reuse-able: params should be easily shared between problems across different actions
 *   3. Minimal: fewest number of params, while maintaining 1 and 2
 */

// Permissions
export const MAX_USER_ASSET_PERMISSION = createParam('MAX_USER_ASSET_PERMISSION', nullable(ParamType.enumeration(AssetPermissionTypes)));
export const USER_REPORT_PERMISSION = createParam('USER_REPORT_PERMISSION', nullable(ParamType.enumeration(AssetPermissionTypes)));
export const USER_DASHBOARD_PERMISSION = createParam('USER_DASHBOARD_PERMISSION', nullable(ParamType.enumeration(AssetPermissionTypes)));
export const TARGET_USER_DASHBOARD_PERMISSION = createParam('TARGET_USER_DASHBOARD_PERMISSION', nullable(ParamType.enumeration(AssetPermissionTypes)));
export const ASSIGNMENT_MODE = createParam('ASSIGNMENT_MODE', nullable(ParamType.boolean));
export const SELECTED_TEAMS = createParam('SELECTED_TEAMS', nullable(ParamType.set));
export const SELECTED_USERS = createParam('SELECTED_USERS', nullable(ParamType.set));

// scopes
export const HAS_ATTRIBUTION_ACCESS = createParam('HAS_ATTRIBUTION_ACCESS', nullable(ParamType.boolean));
export const HAS_CUSTOM_REPORTING_ACCESS = createParam('HAS_CUSTOM_REPORTING_ACCESS', nullable(ParamType.boolean));
export const HAS_DATASETS_ACCESS = createParam('HAS_DATASETS_ACCESS', nullable(ParamType.boolean));
export const HAS_SALES_ANALYTICS_ACCESS = createParam('HAS_SALES_ANALYTICS_ACCESS', nullable(ParamType.boolean));
export const HAS_REPORTING_ADD_ON = createParam('HAS_REPORTING_ADD_ON', nullable(ParamType.boolean));
export const HAS_DATA_TYPE_ACCESS = createParam('HAS_DATA_TYPE_ACCESS', nullable(ParamType.boolean));
export const MARKETING_HUB_UPGRADE_REQUIRED = createParam('MARKETING_HUB_UPGRADE_REQUIRED', nullable(ParamType.enumeration(['Pro', 'Enterprise'])));
export const HAS_PAYMENTS_REPORTING_ACCESS = createParam('HAS_PAYMENTS_REPORTING_ACCESS', nullable(ParamType.boolean));

// gates
export const HAS_ONE_TO_MANY_ACCESS = createParam('HAS_ONE_TO_MANY_ACCESS', nullable(ParamType.boolean));

// limits
export const CUSTOM_REPORT_COUNT = createParam('CUSTOM_REPORT_COUNT', nullable(ParamType.integer));
export const CUSTOM_REPORT_LIMIT = createParam('CUSTOM_REPORT_LIMIT', nullable(ParamType.integer));
export const CUSTOM_DASHBOARD_COUNT = createParam('CUSTOM_DASHBOARD_COUNT', nullable(ParamType.integer));
export const CUSTOM_DASHBOARD_LIMIT = createParam('CUSTOM_DASHBOARD_LIMIT', nullable(ParamType.integer));
export const DASHBOARD_REPORT_LIMIT = createParam('DASHBOARD_REPORT_LIMIT', nullable(ParamType.integer));
export const CUSTOM_REPORT_COUNT_IN_DASHBOARD = createParam('CUSTOM_REPORT_COUNT_IN_DASHBOARD', nullable(ParamType.integer));
export const FAVORITE_REPORT_COUNT = createParam('FAVORITE_REPORT_COUNT', nullable(ParamType.integer));
export const FAVORITE_DASHBOARD_COUNT = createParam('FAVORITE_DASHBOARD_COUNT', nullable(ParamType.integer));
export const STANDARD_REPORT_COUNT = createParam('STANDARD_REPORT_COUNT', nullable(ParamType.integer));
export const STANDARD_REPORT_LIMIT = createParam('STANDARD_REPORT_LIMIT', nullable(ParamType.integer));
export const REPORT_NAME = createParam('REPORT_NAME', nullable(ParamType.string));
export const REPORT_IS_DASHBOARD_COMPATIBLE = createParam('REPORT_IS_DASHBOARD_COMPATIBLE', nullable(ParamType.boolean));
export const REPORT_IS_ATTRIBUTION = createParam('REPORT_IS_ATTRIBUTION', nullable(ParamType.boolean));
export const REPORT_IS_CUSTOM = createParam('REPORT_IS_CUSTOM', nullable(ParamType.boolean));
export const REPORT_IS_SNOWFLAKE = createParam('REPORT_IS_SNOWFLAKE', nullable(ParamType.boolean));
export const REPORT_IS_SALES_ANALYTICS = createParam('REPORT_IS_SALES_ANALYTICS', nullable(ParamType.boolean));
export const REPORT_USES_DATASET = createParam('REPORT_USES_DATASET', nullable(ParamType.boolean));
export const TARGET_IS_READ_ONLY_DEMO = createParam('TARGET_IS_READ_ONLY_DEMO', nullable(ParamType.boolean));
export const TARGET_DASHBOARD_REPORT_COUNT = createParam('TARGET_DASHBOARD_REPORT_COUNT', nullable(ParamType.integer));
export const TARGET_DASHBOARD_ID = createParam('TARGET_DASHBOARD_ID', nullable(ParamType.integer));
export const IS_READ_ONLY_DEMO = createParam('IS_READ_ONLY_DEMO', nullable(ParamType.boolean));
export const DASHBOARD_IS_LOADING = createParam('DASHBOARD_IS_LOADING', nullable(ParamType.boolean));
export const DASHBOARD_FETCH_FAILED = createParam('DASHBOARD_FETCH_FAILED', nullable(ParamType.boolean));
export const DASHBOARD_ID = createParam('DASHBOARD_ID', nullable(ParamType.integer));
export const NEW_DASHBOARD_NAME = createParam('NEW_DASHBOARD_NAME', nullable(ParamType.string));
export const DASHBOARD_IS_CUSTOM = createParam('DASHBOARD_IS_CUSTOM', nullable(ParamType.boolean));
export const DASHBOARD_CREATED_AT_TIMESTAMP = createParam('DASHBOARD_CREATED_AT_TIMESTAMP', nullable(ParamType.integer));
export const PARENT_DASHBOARD_IDS = createParam('PARENT_DASHBOARD_IDS', nullable(ParamType.set));
export const USER_IS_SUPER_ADMIN = createParam('USER_IS_SUPER_ADMIN', nullable(ParamType.boolean));
export const USER_ID = createParam('USER_ID', nullable(ParamType.integer));
export const SAVE_REPORT_USE_TYPE = createParam('SAVE_REPORT_USE_TYPE', nullable(ParamType.enumeration(SaveReportUseTypeList)));
export const LAST_REFRESH_REPORT_TIME = createParam('LAST_REFRESH_REPORT_TIME', nullable(ParamType.integer));
export const RESTRICTED_FIELD_LEVEL_PROPERTIES = createParam('RESTRICTED_FIELD_LEVEL_PROPERTIES', nullable(ParamType.enumeration));
export const BULK_SELECTED_REPORTS = createParam('BULK_SELECTED_REPORTS', nullable(ParamType.enumeration));

// Snowflake reports are currently not available on free/starter portals due
// to the perceived increases in cost to Snowflake. However, certain Snowflake
// report templates (standard reports) are excempt from this general rule.
export const REPORT_IS_SNOWFLAKE_TEMPLATE_EXCEPTION = createParam('REPORT_IS_SNOWFLAKE_TEMPLATE_EXCEPTION', nullable(ParamType.boolean));
export const REPORT_IS_PAYMENTS = createParam('REPORT_IS_PAYMENTS', nullable(ParamType.boolean));