'use es6';

import { Record, Map as ImmutableMap } from 'immutable';
export const StatusTypes = {
  PENDING: 'PENDING',
  SUCCEEDED: 'SUCCEEDED',
  ERRORED: 'ERRORED'
};
export const HttpRequestState = Record({
  request: null,
  status: StatusTypes.PENDING,
  promise: null,
  response: null,
  error: null,
  duration: null,
  ttl: null,
  timeout: null,
  isRetriable: true
}, 'HttpRequestState');
export const types = {
  ADD_REQUEST: '@@reporting/ADD_REQUEST',
  REMOVE_REQUEST: '@@reporting/REMOVE_REQUEST',
  SET_REQUEST_STATUS: '@@reporting/SET_REQUEST_STATUS'
};
export const initialState = ImmutableMap();
export const reducer = (state = initialState, action) => {
  const {
    type,
    data = {}
  } = action;
  switch (type) {
    case types.ADD_REQUEST:
      {
        const {
          request,
          promise,
          ttl = null,
          timeout
        } = data;
        if (!state.has(request)) {
          return state.set(request, new HttpRequestState({
            request,
            promise,
            ttl,
            timeout
          }));
        }
        return state;
      }
    case types.REMOVE_REQUEST:
      {
        const {
          request
        } = data;
        const requestState = state.get(request);
        if (requestState && requestState.timeout) {
          clearTimeout(requestState.timeout);
        }
        return state.remove(request);
      }
    case types.SET_REQUEST_STATUS:
      {
        const {
          request,
          response,
          status,
          duration,
          error,
          timeout,
          isRetriable
        } = data;
        if (!state.has(request)) {
          // the request must have already been busted
          return state;
        }
        return state.update(request, requestState => requestState.set('response', response).set('status', status).set('duration', duration).set('error', error).set('timeout', timeout).set('isRetriable', isRetriable));
      }
    default:
      {
        return state;
      }
  }
};
export const actions = {
  addRequest: ({
    request,
    promise,
    ttl,
    timeout
  }) => ({
    type: types.ADD_REQUEST,
    data: {
      request,
      promise,
      ttl,
      timeout
    }
  }),
  removeRequest: ({
    request
  }) => ({
    type: types.REMOVE_REQUEST,
    data: {
      request
    }
  }),
  setRequestStatus: ({
    request,
    response,
    status,
    duration,
    error,
    timeout,
    isRetriable
  }) => ({
    type: types.SET_REQUEST_STATUS,
    data: {
      request,
      response,
      status,
      duration,
      error,
      timeout,
      isRetriable
    }
  })
};
export const getState = state => {
  return state.request;
};
export const getRequestState = (state, request) => {
  return state.request.get(request);
};
export const getRequestStatus = (state, request) => {
  return state.request.getIn([request, 'status']);
};
export const getRequestPromise = (state, request) => {
  return state.request.getIn([request, 'promise']);
};