import { useCallback } from 'react';
import { isSucceeded } from 'reporting-data/asyncData/AsyncData';
import useFetchPropertyGroupsForEditors from '../../../hooks/useFetchPropertyGroupsForEditors';
import { getIsValidConfigMetricProperty } from '../../../lib/reportPropertyUtils';
const useFetchPropertyOptionsForMetricsEditor = report => {
  const resultFormatter = useCallback(response => {
    return response.map(group => {
      const propertyOptions = group.get('properties').filter(prop => {
        return getIsValidConfigMetricProperty(prop, report);
      }).map(prop => {
        return {
          text: prop.get('label'),
          value: prop.get('name')
        };
      });
      return {
        text: group.get('displayName'),
        options: propertyOptions.toList().toJS()
      };
    }).filter(group => {
      return group.options.length;
    }).toList().toJS();
  }, [report]);
  const fetch = useFetchPropertyGroupsForEditors(report, resultFormatter);
  if (isSucceeded(fetch)) {
    return fetch.data;
  }
  return [];
};
export default useFetchPropertyOptionsForMetricsEditor;