'use es6';

import { getDefaultDealPipelineId } from '../../data/pipelinesDao';
import { getPreferredPipeline } from '../context-storage-sync';
import { isQuotaReport, isSinglePipelineReport, NO_PIPELINE } from '../salesAnalyticsHelpers';
export function validatePipeline(contextChange, _, report, subAppKey) {
  const defaultPipelineId = getPreferredPipeline() || getDefaultDealPipelineId();
  if (!isQuotaReport(report) && contextChange.pipeline === NO_PIPELINE) {
    const newPipelineValue = isSinglePipelineReport(subAppKey, report) ? defaultPipelineId : null;
    return Object.assign({}, contextChange, {
      pipeline: newPipelineValue
    });
  }
  return contextChange;
}