export const POLL_RATE = 2000;
export const JOURNEY_POLL_RATE = 2000;
export const AsyncQueryStatus = {
  UNKNOWN: 'UNKNOWN',
  RUNNING: 'RUNNING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
  TIMEOUT: 'TIMEOUT'
};
export const REPORT_SOURCE = {
  CRM_SEARCH: 'CRM_SEARCH',
  SNOWFLAKE: 'SNOWFLAKE'
};