'use es6';

import { List } from 'immutable';
import { AggregationTypes, FieldTypes } from '../../schema/column-records';
import { VisualTypes } from '../../schema/visual-records';
import { getColumn, getColumnField, getColumnRole, isDateLikeField } from '../../utils/column-utils';
import { getReportBreakdownEncoding, getReportColorEncoding, getReportColumnsEncodings, getReportGroupEncoding, getReportRowsEncodings, getReportSizingEncoding, getReportValuesEncodings, getReportSingleValueEncoding, getReportVisualType, getReportXEncoding, getReportXMultiEncoding, getReportY1Encoding, getReportY2Encoding, getReportYEncoding, getReportYMultiEncoding } from '../../utils/report-utils';
import { getEncodingColumn } from '../../utils/visual-utils';
import { logicalXOR } from '../../utils/validation-utils';
import { combineConstraints, ConstraintTypes, createConstraint, InvalidSpecificationTypes } from '../validate';
import { COLUMN_ROLES } from 'reporting-data/constants/relationalReports';
const hasRole = role => encoding => encoding === undefined || getColumnRole(getEncodingColumn(encoding)) === role;
const everyEncodingHasRole = role => encodings => encodings === undefined || encodings.every(hasRole(role));
const encodingColumnFieldHasType = type => encoding => {
  return encoding === undefined || getColumnField(getEncodingColumn(encoding)).type === type;
};
const isMeasureOrDate = encoding => {
  return hasRole(COLUMN_ROLES.MEASURE)(encoding) || encodingColumnFieldHasType(FieldTypes.DATETIME)(encoding) || encodingColumnFieldHasType(FieldTypes.DATE)(encoding);
};
export const xMultiChannelsLength = createConstraint('x-multi-channel-length', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (multiXAxis = List()) => multiXAxis.size <= 12, getReportXMultiEncoding);
export const yMultiChannelsLength = createConstraint('y-multi-channel-length', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (multiYAxis = List()) => multiYAxis.size <= 12, getReportYMultiEncoding);
export const eitherYAxisOrYMultiAxis = createConstraint('single-y-axis-encoding', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (singleYAxis, multiYAxis) => singleYAxis ? !multiYAxis || multiYAxis.length === 0 : true, [getReportYEncoding, getReportYMultiEncoding]);
export const eitherXAxisOrXMultiAxis = createConstraint('single-x-axis-encoding', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (singleXAxis, multiXAxis) => singleXAxis ? !multiXAxis || multiXAxis.length === 0 : true, [getReportXEncoding, getReportXMultiEncoding]);
export const breakdownChannelIsDimension = createConstraint('breakdown-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, hasRole(COLUMN_ROLES.DIMENSION), report => getReportBreakdownEncoding(report));
export const colorChannelIsDimension = createConstraint('color-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, hasRole(COLUMN_ROLES.DIMENSION), report => getReportColorEncoding(report));
export const sizingChannelIsMeasure = createConstraint('sizing-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, hasRole(COLUMN_ROLES.MEASURE), report => getReportSizingEncoding(report));
export const columnChannelsAreDimensions = createConstraint('columns-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, everyEncodingHasRole(COLUMN_ROLES.DIMENSION), report => getReportColumnsEncodings(report));
export const columnChannelsLength = createConstraint('columns-channel-length', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, columns => columns.size <= 4, report => getReportColumnsEncodings(report));
export const columnChannelsExpandedLength = createConstraint('columns-channel-length', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, columns => columns.size <= 6, report => getReportColumnsEncodings(report));
export const groupChannelIsDimension = createConstraint('group-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, hasRole(COLUMN_ROLES.DIMENSION), report => getReportGroupEncoding(report));
export const detailChannelIsDimension = createConstraint('detail-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, hasRole(COLUMN_ROLES.DIMENSION), report => getReportGroupEncoding(report));
export const rowChannelsAreDimensions = createConstraint('rows-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, everyEncodingHasRole(COLUMN_ROLES.DIMENSION), report => getReportRowsEncodings(report));
export const rowChannelsLength = createConstraint('rows-channel-length', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, rows => rows.size <= 4, report => getReportRowsEncodings(report));
export const rowChannelsExpandedLength = createConstraint('rows-channel-length', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, rows => rows.size <= 6, report => getReportRowsEncodings(report));
export const valueChannelsAreMeasures = createConstraint('values-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, everyEncodingHasRole(COLUMN_ROLES.MEASURE), report => getReportValuesEncodings(report));
export const singleValueChannelIsMeasure = createConstraint('single-value-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, hasRole(COLUMN_ROLES.MEASURE), report => getReportSingleValueEncoding(report));
export const xChannelIsDimension = createConstraint('x-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, hasRole(COLUMN_ROLES.DIMENSION), report => getReportXEncoding(report));
export const xChannelIsMeasure = createConstraint('x-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, hasRole(COLUMN_ROLES.MEASURE), report => getReportXEncoding(report));
export const xMultiChannelIsMeasure = createConstraint('x-multi-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, everyEncodingHasRole(COLUMN_ROLES.MEASURE), getReportXMultiEncoding);
export const manyXAxisValuesXORCategory = createConstraint('many-measures-xor-category', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (color, xValues = List()) => {
  return logicalXOR(color !== undefined && xValues.size <= 1, color === undefined && xValues.size >= 0);
}, [getReportColorEncoding, getReportXMultiEncoding]);
export const manyYAxisValuesXORCategory = createConstraint('many-measures-xor-category', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (color, yValues = List()) => {
  return logicalXOR(color !== undefined && yValues.size <= 1, color === undefined && yValues.size >= 0);
}, [getReportColorEncoding, getReportYMultiEncoding]);
export const xChannelIsMeasureOrDate = createConstraint('x-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, isMeasureOrDate, report => getReportXEncoding(report));
export const yMultiChannelIsMeasure = createConstraint('y-multi-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, everyEncodingHasRole(COLUMN_ROLES.MEASURE), getReportYMultiEncoding);
export const yChannelIsDimension = createConstraint('y-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, hasRole(COLUMN_ROLES.DIMENSION), report => getReportYEncoding(report));
export const yChannelIsMeasure = createConstraint('y-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, hasRole(COLUMN_ROLES.MEASURE), report => getReportYEncoding(report));
export const yChannelIsMeasureOrDate = createConstraint('y-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, isMeasureOrDate, report => getReportYEncoding(report));
export const y1ChannelIsMeasure = createConstraint('y1-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, hasRole(COLUMN_ROLES.MEASURE), report => getReportY1Encoding(report));
export const y2ChannelIsMeasure = createConstraint('y2-channel', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, hasRole(COLUMN_ROLES.MEASURE), report => getReportY2Encoding(report));
export const createXYAreNotBothDimensionConstraint = channelEncodingName => createConstraint(`${channelEncodingName}-channel`, ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, (xEncoding, yEncoding) => {
  return xEncoding === undefined || yEncoding === undefined || !everyEncodingHasRole(COLUMN_ROLES.DIMENSION)([xEncoding, yEncoding]);
}, [report => getReportXEncoding(report), report => getReportYEncoding(report)]);
const createChannelAggregationIsAllowedConstraint = channelEncodingName => createConstraint(`aggregations.${channelEncodingName}-channel`, ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, report => {
  if (report.visual.type === VisualTypes.TABLE) {
    return true;
  }
  const channelColumn = getColumn(report.columns, report.visual.encodings[channelEncodingName] ? report.visual.encodings[channelEncodingName].column : null);
  if (channelColumn) {
    const field = channelColumn.field;
    const isDateLike = isDateLikeField(field);
    return !isDateLike || !channelColumn.aggregation || isDateLike && AggregationTypes.DISTINCT_COUNT === channelColumn.aggregation;
  } else {
    return true;
  }
}, report => report);
const channelAggregationIsAllowedConstraintList = [createChannelAggregationIsAllowedConstraint('x'), createChannelAggregationIsAllowedConstraint('x_multi'), createChannelAggregationIsAllowedConstraint('values'), createChannelAggregationIsAllowedConstraint('y'), createChannelAggregationIsAllowedConstraint('y_multi'), createChannelAggregationIsAllowedConstraint('y1'), createChannelAggregationIsAllowedConstraint('y2')];
const verticalBarXYChannels = combineConstraints(VisualTypes.VERTICAL_BAR, [xChannelIsDimension, yChannelIsMeasure, yMultiChannelIsMeasure, yMultiChannelsLength, manyYAxisValuesXORCategory, colorChannelIsDimension, eitherYAxisOrYMultiAxis], (constraint, report) => getReportVisualType(report) !== VisualTypes.VERTICAL_BAR || constraint.validate(report));
const horizontalBarXYChannels = combineConstraints(VisualTypes.HORIZONTAL_BAR, [xChannelIsMeasure, xMultiChannelIsMeasure, xMultiChannelsLength, manyXAxisValuesXORCategory, yChannelIsDimension, colorChannelIsDimension, eitherXAxisOrXMultiAxis], (constraint, report) => getReportVisualType(report) !== VisualTypes.HORIZONTAL_BAR || constraint.validate(report));
const lineXYChannels = combineConstraints(VisualTypes.LINE, [xChannelIsDimension, yChannelIsMeasure, yMultiChannelIsMeasure, yMultiChannelsLength, manyYAxisValuesXORCategory, colorChannelIsDimension, eitherYAxisOrYMultiAxis], (constraint, report) => getReportVisualType(report) !== VisualTypes.LINE || constraint.validate(report));
const areaXYChannels = combineConstraints(VisualTypes.AREA, [xChannelIsDimension, yChannelIsMeasure, yMultiChannelIsMeasure, yMultiChannelsLength, manyYAxisValuesXORCategory, colorChannelIsDimension, eitherYAxisOrYMultiAxis], (constraint, report) => getReportVisualType(report) !== VisualTypes.AREA || constraint.validate(report));
const scatterXYChannels = combineConstraints(VisualTypes.SCATTER, [xChannelIsMeasureOrDate, yChannelIsMeasureOrDate, createXYAreNotBothDimensionConstraint('x'), createXYAreNotBothDimensionConstraint('y'), colorChannelIsDimension, sizingChannelIsMeasure, detailChannelIsDimension], (constraint, report) => getReportVisualType(report) !== VisualTypes.SCATTER || constraint.validate(report));
const comboXYChannels = combineConstraints(VisualTypes.COMBO, [xChannelIsDimension, y1ChannelIsMeasure, y2ChannelIsMeasure, breakdownChannelIsDimension], (constraint, report) => getReportVisualType(report) !== VisualTypes.COMBO || constraint.validate(report));
const pivotChannels = combineConstraints(VisualTypes.PIVOT_TABLE, [rowChannelsAreDimensions, columnChannelsAreDimensions, rowChannelsLength, columnChannelsLength, valueChannelsAreMeasures], (constraint, report) => getReportVisualType(report) !== VisualTypes.PIVOT_TABLE || constraint.validate(report));
const pivotChannelsExpanded = combineConstraints(VisualTypes.PIVOT_TABLE, [rowChannelsAreDimensions, columnChannelsAreDimensions, rowChannelsExpandedLength, columnChannelsExpandedLength, valueChannelsAreMeasures], (constraint, report) => getReportVisualType(report) !== VisualTypes.PIVOT_TABLE || constraint.validate(report));
const manyValuesXORCategory = (category, values) => logicalXOR(category === undefined, category !== undefined && values.size <= 1);
const manyValuesXORGroupConstraint = createConstraint('many-values-xor-category', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, report => manyValuesXORCategory(getReportGroupEncoding(report), getReportValuesEncodings(report)));
const manyValuesXORColorConstraint = createConstraint('many-values-xor-category', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, report => manyValuesXORCategory(getReportColorEncoding(report), getReportValuesEncodings(report)));
const pieChannels = combineConstraints(VisualTypes.PIE, [colorChannelIsDimension, manyValuesXORColorConstraint, valueChannelsAreMeasures], (constraint, report) => getReportVisualType(report) !== VisualTypes.PIE || constraint.validate(report));
const donutChannels = combineConstraints(VisualTypes.DONUT, [colorChannelIsDimension, manyValuesXORColorConstraint, valueChannelsAreMeasures], (constraint, report) => getReportVisualType(report) !== VisualTypes.DONUT || constraint.validate(report));
const textChannels = combineConstraints(VisualTypes.TEXT, [groupChannelIsDimension, manyValuesXORGroupConstraint, valueChannelsAreMeasures], (constraint, report) => getReportVisualType(report) !== VisualTypes.TEXT || constraint.validate(report));
export const channels = combineConstraints('channels', [verticalBarXYChannels, horizontalBarXYChannels, lineXYChannels, areaXYChannels, scatterXYChannels, comboXYChannels, pivotChannels, ...channelAggregationIsAllowedConstraintList, pieChannels, donutChannels, textChannels], (constraint, report) => constraint.validate(report));
export const expandedChannels = combineConstraints('channels', [verticalBarXYChannels, horizontalBarXYChannels, lineXYChannels, areaXYChannels, scatterXYChannels, comboXYChannels, pivotChannelsExpanded, ...channelAggregationIsAllowedConstraintList, pieChannels, donutChannels, textChannels], (constraint, report) => constraint.validate(report));