'use es6';

import { compose, createPlugin } from 'draft-extend';
const baseAccumulator = {
  __isAccumulator: true
};
const createPluginStackWithAdapters = (...adapters) => (...plugins) => {
  let allSets = [];
  let currentSet = [];
  plugins.forEach(plugin => {
    const accumulatorResult = plugin(baseAccumulator);
    const canBeCombined = accumulatorResult !== baseAccumulator;
    if (canBeCombined) {
      if (Array.isArray(accumulatorResult)) {
        if (currentSet.length > 0) {
          allSets = [...allSets, currentSet];
          currentSet = [];
        }
        allSets = [...allSets, ...accumulatorResult.map(stack => [stack])];
        return;
      }
      currentSet = [...currentSet, plugin];
      return;
    }
    if (currentSet.length > 0) {
      allSets = [...allSets, currentSet];
      currentSet = [];
    }
    allSets = [...allSets, [plugin]];
  });
  if (currentSet.length > 0) {
    allSets = [...allSets, currentSet];
  }
  const combinedPluginStacks = allSets.map(pluginSet => {
    const reversedPluginSet = [...pluginSet].reverse();
    const pluginProps = compose(...reversedPluginSet)(baseAccumulator);
    if (pluginProps === baseAccumulator) {
      if (pluginSet.length === 1) {
        return pluginSet[0];
      }
      return compose(...pluginSet);
    }
    const numCombinedPlugins = pluginSet.length;
    if (numCombinedPlugins === 1 && !pluginProps.__pluginStack) {
      return pluginSet[0];
    }
    const displayName = pluginProps.__pluginStack || `${numCombinedPlugins}(PluginStack)`;
    return EditorComponent => {
      if (EditorComponent && EditorComponent.__isAccumulator) {
        return pluginProps;
      }
      if (EditorComponent.prototype && EditorComponent.prototype.isReactComponent) {
        return createPlugin(Object.assign({}, pluginProps, {
          displayName
        }))(EditorComponent);
      }
      return compose(...pluginSet)(EditorComponent);
    };
  });
  return arg => {
    if (arg && arg.__isAccumulator) {
      return combinedPluginStacks;
    }
    return compose(...adapters)(...combinedPluginStacks)(arg);
  };
};
const createPluginStack = createPluginStackWithAdapters(compose);
export { createPluginStackWithAdapters, createPluginStack };