'use es6';

import { defineFactory } from 'general-store';
import { Map as ImmutableMap, Record, fromJS } from 'immutable';
import { getId as getReportId, getReportDashboardInfo } from 'dashboard-lib/public/report/report-schema';
import { isCustom as isCustomReport } from 'dashboard-lib/public/report/report-logic';
import * as DashboardActionTypes from '../constants/DashboardActionTypes';
import { __DASHBOARDS_RESET_STATE__ } from '../constants/TestingActionTypes';
import { getId } from 'dashboard-lib/public/dashboard/dashboard-schema';
const State = Record({
  fetched: false,
  refreshingAll: false,
  dashboards: ImmutableMap(),
  dashboardFetched: ImmutableMap(),
  dashboardFetching: ImmutableMap(),
  dashboardFetchFailed: ImmutableMap(),
  customReportCount: undefined,
  standardReportCount: undefined,
  allDashboardsCount: undefined,
  errorType: undefined,
  isForbidden: undefined
});
const getIndexOfReport = (widgets, reportId) => widgets.findIndex(widget => widget.getIn(['report', 'id']) === reportId);
export default defineFactory().defineName('DashboardStore').defineGetInitialState(() => new State()).defineGet((state, key) => {
  if (!key) {
    return state;
  }
  return Array.isArray(key) ? state.getIn(key) : state.get(key);
}).defineResponseTo([DashboardActionTypes.REPORT_UPDATE_SUCCESS], (state, {
  report
}) => {
  const reportId = getReportId(report);
  const parentDashboards = getReportDashboardInfo(report);
  let updatedDashboards = state.get('dashboards');
  parentDashboards.forEach(parentDashboardInfo => {
    updatedDashboards = updatedDashboards.updateIn([getId(parentDashboardInfo), 'widgets'], widgets => {
      const index = getIndexOfReport(widgets, reportId);
      return index !== -1 ? widgets.setIn([index, 'report'], report) : widgets;
    });
  });
  return state.set('dashboards', updatedDashboards);
}).defineResponseTo([DashboardActionTypes.REPORT_DELETE_SUCCESS], (state, {
  report
}) => {
  const isCustom = isCustomReport(report);
  const reportId = getReportId(report);
  const parentDashboards = getReportDashboardInfo(report);
  let updatedDashboards = state.get('dashboards');
  parentDashboards.forEach(parentDashboardInfo => {
    const parentId = getId(parentDashboardInfo);
    const updatedDashboard = updatedDashboards.get(parentId).update('widgets', widgets => {
      const index = getIndexOfReport(widgets, reportId);
      return index !== -1 ? widgets.delete(index) : widgets;
    }).update('widgetCount', count => --count);
    updatedDashboards = updatedDashboards.set(parentId, updatedDashboard);
  });
  return state.set('dashboards', updatedDashboards).update(isCustom ? 'customReportCount' : 'standardReportCount', count => --count);
}).defineResponseTo([DashboardActionTypes.REPORT_REMOVE_SUCCESS], (state, {
  dashboardId,
  reportId
}) => {
  return state.updateIn(['dashboards', dashboardId, 'widgets'], widgets => {
    const index = getIndexOfReport(widgets, reportId);
    return index !== -1 ? widgets.delete(index) : widgets;
  }).updateIn(['dashboards', dashboardId, 'widgetCount'], count => --count);
}).defineResponseTo([DashboardActionTypes.DASHBOARD_CREATE_SUCCESS], (state, {
  dashboard
}) => {
  const id = dashboard.get('id');
  const key = Number(id) || id;
  return state.setIn(['dashboards', key], dashboard).setIn(['dashboardFetched', key], true).setIn(['dashboardFetching', key], false).setIn(['dashboardFetchFailed', key], false);
}).defineResponseTo([DashboardActionTypes.DASHBOARD_FETCH_SUCCESS], (state, {
  dashboard
}) => {
  const id = dashboard.get('id');
  const key = Number(id) || id;
  return state.setIn(['dashboards', key], dashboard).setIn(['dashboardFetched', key], true).setIn(['dashboardFetching', key], false).set('errorType', undefined).set('isForbidden', false).set('refreshingAll', false);
}).defineResponseTo(DashboardActionTypes.DASHBOARD_DELETE_SUCCESS, (state, {
  dashboardId
}) => {
  return state.deleteIn(['dashboards', dashboardId]).deleteIn(['dashboardFetched', dashboardId]).deleteIn(['dashboardFetching', dashboardId]).deleteIn(['dashboardFetchFailed', dashboardId]);
}).defineResponseTo(DashboardActionTypes.DASHBOARD_DELETE_MULTIPLE_SUCCESS, (state, {
  dashboardIds
}) => {
  return state.update('dashboards', dashboards => dashboards.filterNot((value, dbId) => dashboardIds.includes(dbId))).update('dashboardFetched', dashboardFetched => dashboardFetched.filterNot((value, dbId) => dashboardIds.includes(dbId))).update('dashboardFetching', dashboardFetching => dashboardFetching.filterNot((value, dbId) => dashboardIds.includes(dbId))).update('dashboardFetchFailed', dashboardFetchFailed => dashboardFetchFailed.filterNot((value, dbId) => {
    dashboardIds.includes(dbId);
  }));
}).defineResponseTo(DashboardActionTypes.DASHBOARD_FETCH_FAILURE, (state, {
  id,
  shouldDelete,
  dashboardId,
  errorType,
  isForbidden
}) => {
  const key = id !== 'default' ? Number(id) : id;
  const dashboardKey = dashboardId !== 'default' ? Number(dashboardId) : dashboardId;
  const nextState = state.setIn(['dashboardFetched', dashboardKey], true).setIn(['dashboardFetching', dashboardKey], false).setIn(['dashboardFetchFailed', dashboardKey], true).set('errorType', errorType).set('isForbidden', isForbidden).set('refreshingAll', false);
  if (shouldDelete) {
    return nextState.deleteIn(['dashboards', key]);
  }
  return nextState;
}).defineResponseTo(DashboardActionTypes.DASHBOARD_FETCH, (state, {
  id
}) => {
  const key = id !== 'default' ? Number(id) : id;
  return state.set('refreshingAll', true).setIn(['dashboardFetching', key], true);
}).defineResponseTo(DashboardActionTypes.DASHBOARDS_FETCH_SUCCESS, (state, {
  dashboards,
  allDashboardsCount
}) => {
  const currentDashboards = state.get('dashboards');
  return state.merge({
    fetched: true,
    allDashboardsCount,
    dashboards: dashboards.reduce((allDashboards, dashboard) => {
      const id = dashboard.get('id');
      const key = Number(id) || id;
      if (currentDashboards.has(key)) {
        return allDashboards;
      }
      return allDashboards.set(key, dashboard);
    }, currentDashboards)
  });
}).defineResponseTo(DashboardActionTypes.DASHBOARDS_FETCH_FAILURE, state => {
  return state.set('fetched', false);
}).defineResponseTo(DashboardActionTypes.DASHBOARD_UPDATE_SUCCESS, (state, {
  dashboard
}) => {
  const id = dashboard.get('id');
  const key = Number(id) || id;
  return state.setIn(['dashboards', key], fromJS(dashboard));
}).defineResponseTo(
// When a non-loaded dashboard is updated (i.e. without widgets)
DashboardActionTypes.DASHBOARD_META_UPDATE_SUCCESS, (state, {
  dashboard
}) => {
  const id = dashboard.get('id');
  const key = Number(id) || id;
  return state.updateIn(['dashboards', key], existingDashboard => {
    return fromJS(dashboard).update('widgets', widgets => existingDashboard && existingDashboard.get('widgets') || widgets).update('widgetCount', widgetCount => existingDashboard && existingDashboard.get('widgetCount') || widgetCount);
  });
}).defineResponseTo(DashboardActionTypes.DASHBOARD_LAYOUT_UPDATE_SUCCESS, (state, {
  dashboardId,
  widgetLayouts
}) => {
  const layoutsById = widgetLayouts.reduce((layouts, widget) => layouts.set(widget.get('reportId'), widget.get('layout')), ImmutableMap());
  return state.updateIn(['dashboards', dashboardId, 'widgets'], widgets => {
    return widgets.map(widget => {
      return widget.set('layout', layoutsById.get(widget.get('reportId')));
    });
  });
}).defineResponseTo(DashboardActionTypes.REPORT_COUNT_FETCH_SUCCESS, (state, reportCounts) => {
  return state.set('customReportCount', reportCounts.get('customReports')).set('standardReportCount', reportCounts.get('standardReports'));
}).defineResponseTo(DashboardActionTypes.DASHBOARD_COUNT_FETCH_SUCCESS, (state, {
  allDashboardsCount
}) => {
  return state.set('allDashboardsCount', allDashboardsCount);
}).defineResponseTo([DashboardActionTypes.REFRESH_ALL], (state, {
  dashboardId
}) => {
  return state.set('refreshingAll', true).updateIn(['dashboards', dashboardId, 'widgets'], widgets => widgets.map(widget => widget.set('refreshing', true)));
}).defineResponseTo([DashboardActionTypes.REFRESH_REPORT], (state, {
  dashboardId,
  reportId
}) => {
  return state.updateIn(['dashboards', dashboardId, 'widgets'], widgets => {
    const index = widgets.findIndex(widget => widget.getIn(['report', 'id']) === reportId);
    if (index === -1) {
      return widgets;
    }
    return widgets.setIn([index, 'refreshing'], true);
  });
}).defineResponseTo([DashboardActionTypes.REFRESH_ALL_SUCCESS], (state, {
  dashboardId
}) => {
  return state.set('refreshingAll', false).updateIn(['dashboards', dashboardId, 'widgets'], widgets => widgets.map(widget => widget.update('reportRefreshCount', reportRefreshCount => (reportRefreshCount || 0) + 1).set('refreshing', false)));
}).defineResponseTo([DashboardActionTypes.REFRESH_REPORT_SUCCESS], (state, {
  dashboardId,
  reportId
}) => {
  return state.updateIn(['dashboards', dashboardId, 'widgets'], widgets => {
    const index = widgets.findIndex(widget => widget.getIn(['report', 'id']) === reportId);
    if (index === -1) {
      return widgets;
    }
    return widgets.updateIn([index, 'reportRefreshCount'], reportRefreshCount => (reportRefreshCount || 0) + 1).setIn([index, 'refreshing'], false);
  });
}).defineResponseTo([DashboardActionTypes.REFRESH_ALL_FAILURE], (state, {
  dashboardId
}) => {
  return state.set('refreshingAll', false).updateIn(['dashboards', dashboardId, 'widgets'], widgets => widgets.map(widget => widget.set('refreshing', false)));
}).defineResponseTo([DashboardActionTypes.REFRESH_REPORT_FAILURE], (state, {
  dashboardId,
  reportId,
  refreshError
}) => {
  return state.updateIn(['dashboards', dashboardId, 'widgets'], widgets => {
    const index = widgets.findIndex(widget => widget.getIn(['report', 'id']) === reportId);
    if (index === -1) {
      return widgets;
    }
    return widgets.setIn([index, 'refreshing'], false).setIn([index, 'refreshError'], refreshError);
  });
})
// Reset for testing
.defineResponseTo(__DASHBOARDS_RESET_STATE__, () => new State()).register();