'use es6';

export default (Comp => {
  if (typeof Comp === 'string') {
    return Comp;
  }
  if (!Comp) {
    return undefined;
  }
  return Comp.displayName || Comp.name || 'Component';
});