'use es6';

import { Record } from 'immutable';
const FieldDefinitionRecord = Record({
  getDescriptionString: undefined,
  getLabelString: undefined,
  InputComponent: undefined,
  inputType: undefined,
  operators: undefined,
  referencedObjectType: undefined,
  ValueComponent: undefined
}, 'FieldDefinitionRecord');
FieldDefinitionRecord.isFieldDefinitionRecord = field => field instanceof FieldDefinitionRecord;
export default FieldDefinitionRecord;