'use es6';

/*
 * TODO: need to determine how to better handle count vs. total
 */
export const zero = ({
  metrics,
  data,
  total
}) => ({
  metrics: data.reduce((nested, value, d0) => Object.assign({}, nested, {
    [metrics[d0][0]]: {
      [metrics[d0][1]]: value
    }
  }), {}),
  total: total[0]
});
export const one = ({
  dimensions,
  metrics,
  keys,
  data,
  total,
  totals,
  labels,
  preCovertedCurrencyProperties = []
}) => Object.assign({
  dimension: Object.assign({
    property: dimensions[0],
    buckets: data.map((series, d0) => Object.assign({
      key: keys[0][d0],
      metrics: series.reduce((bucket, value, d1) => Object.assign({}, bucket, {
        [metrics[d1][0]]: {
          [metrics[d1][1]]: value
        }
      }), {})
    }, labels && labels.keys && labels.keys.length > 0 ? {
      keyLabel: labels.keys[0][d0]
    } : {}))
  }, labels && labels.dimensions ? {
    propertyLabel: labels.dimensions[0]
  } : {})
}, totals ? {
  metrics: metrics.reduce((memo, metric, index) => Object.assign({}, memo, {
    [metric[0]]: {
      [metric[1]]: totals[index]
    }
  }), {})
} : {}, {
  total: total[0] || keys[0].length,
  preCovertedCurrencyProperties
});
export const two = ({
  dimensions,
  metrics,
  keys,
  data,
  total,
  labels
}) => ({
  dimension: {
    property: dimensions[0],
    buckets: data.map((outer, d0) => ({
      key: keys[0][d0],
      dimension: {
        property: dimensions[1],
        buckets: outer.map((inner, d1) => Object.assign({
          key: keys[1][d1],
          metrics: inner.reduce((bucket, value, d2) => Object.assign({}, bucket, {
            [metrics[d2][0]]: {
              [metrics[d2][1]]: value
            }
          }), {})
        }, labels && labels.keys && labels.keys.length > 1 ? {
          keyLabel: labels.keys[1][d1]
        } : {}))
      }
    }))
  },
  total: total[0] || keys[0].length
});