'use es6';

import { fromJS, List, Map as ImmutableMap } from 'immutable';
import chunk from '../../lib/async/chunk';
import { Promise } from '../../lib/promise';
import toJS from '../../lib/toJS';
import * as http from '../../request/http';
import { makeOption } from '../Option';
import { hydrateFn, hydrateInputs, unknownContactLabel } from './hydrate';
const extractInputs = contactInfo => {
  const extracted = {};
  hydrateInputs.forEach(property => {
    extracted[property] = contactInfo.getIn(['properties', property, 'value']) || contactInfo.get(property);
  });
  return extracted;
};
const getDisplayName = (contactInfo, fallbackId) => hydrateFn(extractInputs(contactInfo), fallbackId);
const getContacts = (contacts = List()) => chunk(group => http.get('contacts/v3/contacts/batch', {
  query: {
    id: group.toArray(),
    properties: hydrateInputs
  }
}).then(toJS), responses => responses.reduce((memo, response) => Object.assign({}, memo, response), {}), contacts);
export const generateContactLabel = (contactInfo, key) => contactInfo.get('label') || unknownContactLabel(contactInfo.get('vid') || key);
export default ((ids, transform) => {
  if (ids.isEmpty()) {
    return Promise.resolve(ImmutableMap());
  }
  const sanitized = ids.reduce((memo, id) => {
    const parsed = Number(id);
    return parsed ? memo.set(String(parsed), id) : memo;
  }, ImmutableMap());
  return getContacts(sanitized.keySeq().toList()).then(contacts => {
    return fromJS(contacts).reduce((options, contactInfo = ImmutableMap(), contactId) => {
      const option = makeOption(sanitized.get(contactId, contactId), getDisplayName(contactInfo, contactId));
      return options.set(String(contactId), transform ? transform(option, contactInfo) : option);
    }, ImmutableMap());
  });
});