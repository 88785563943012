export const mergePropertiesAndMetadata = ({
  propertyDefinitions,
  appSettingNames,
  appSettingsResponses
}) => propertyDefinitions.map(property => Object.assign({}, property, {
  // HACK: This makes TS happy, it doesn't seem to infer the initial value correctly
  // when it is typed with "{} as ...", which is what I'd typically do here.
  propertyMetadata: appSettingsResponses.reduce((metadataForProperty, metadata, index) => {
    const entries = metadata && metadata[property.property.name];
    if (entries) {
      metadataForProperty = {};
      metadataForProperty[appSettingNames[index]] = entries;
    }
    return metadataForProperty;
  }, null)
}));