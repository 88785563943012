let _ = t => t,
  _t;
import { createGlobalStyle } from 'styled-components';
const SLINKY_RGB = '81, 111, 144';
const MONOLITH_RGB = '0, 0, 0';
export const DISABLE_MODAL_BLUR_CLASS = 'disableModalBlur';
export const RemoveModalFullscreenBlur = createGlobalStyle(_t || (_t = _`
  /* The div selectors here are to avoid using !important */
  body.uiFullScreenBackground.${0}:not(.space-sword--editor) {
    > div.page,
    > div.app,
    div#hs-nav-before-container #trial-banner-block,
    div#hs-nav-sidebar,
    div#hs-nav-v4  {
      /* Remove the background blur of a UIModal */
      filter: none;
    }

    .uiOverlay-backdrop {
      background-color: initial;

      .uiDialog-root {
        box-shadow: 0 0 0 1px rgba(${0}, 0.3), 0 4px 48px 0 rgba(${0}, 0.4);
      }
    }
  }
`), DISABLE_MODAL_BLUR_CLASS, SLINKY_RGB, MONOLITH_RGB);