/* hs-eslint ignored failing-rules */

/*eslint no-restricted-imports: 1 */

// @ts-expect-error migrate upstream file
import { redirectSuspendedUsers } from 'hub-http/middlewares/user';
import createStandardI18nProvider from 'I18n/init/providers/createStandardI18nProvider';
import 'I18n/init/data/allTimezones';
import 'I18n/init/data/baseLocales';
import 'I18n/init/data/currency';
import 'I18n/init/data/publicLocales';
// @ts-expect-error migrate upstream file
import { initializeEmailConfirmBar } from 'dashboard-ui-components/components/shared/EmailConfirmBar';
import { initialize, startMonitoring } from './utils';
import renderParentComponent from './render';
const THREE_WEEKS = 21 * 86400000;

/**
 * Force reload of app after three weeks for customers displaying the app as a TV display in their offices
 * Where three weeks is around the max amount of time that a setTimeout can use due to JS Number.MAX_VALUE
 * https://stackoverflow.com/questions/12351521/can-settimeout-be-too-long
 * https://git.hubteam.com/hubspot/report-management-issues/issues/2275
 */
setTimeout(() => {
  window.location.reload();
}, THREE_WEEKS);
function start() {
  const StandardI18nProvider = createStandardI18nProvider();
  initialize(StandardI18nProvider).then(() => {
    initializeEmailConfirmBar();
    startMonitoring();
    renderParentComponent();
  }).catch(console.error);
}
export default (() => {
  redirectSuspendedUsers.setEnabled(false);
  start();
});
export const __TESTABLE__ = {
  start
};