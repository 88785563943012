import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchInstalledApps } from '../requests/fetchInstalledApps';
const INSTALLED_APPS_FIELD_NAME = 'installedApps';
export const GET_INSTALLED_APPS = registerQuery({
  fieldName: INSTALLED_APPS_FIELD_NAME,
  args: [],
  fetcher: fetchInstalledApps
});
export const useGetInstalledApps = () => {
  var _data$installedApps;
  const _useQuery = useQuery(GET_INSTALLED_APPS, {
      variables: {},
      fetchPolicy: 'cache-and-network'
    }),
    {
      data
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  return Object.assign({}, rest, {
    data: (data === null || data === void 0 || (_data$installedApps = data.installedApps) === null || _data$installedApps === void 0 ? void 0 : _data$installedApps.listings) || []
  });
};