import { Map as ImmutableMap } from 'immutable';
import compose from 'transmute/compose';
import curry from 'transmute/curry';
import identity from 'transmute/identity';
import ifThen from 'transmute/ifThen';
import isEmpty from 'transmute/isEmpty';
import isUndefined from 'transmute/isUndefined';
import set from 'transmute/set';
import { PIPELINE_TYPES, getPipelineFilterFromContext } from './pipelineContext';
import { convertToDateRange } from './DateConversionUtils';
import ReportContextHelper from './ReportContextHelper';
const CLEAR_FILTER_VALUE = null;
const setOverride = curry((key, shouldClearFilter, value) => {
  if (value) {
    if (shouldClearFilter(value)) {
      return set(key, CLEAR_FILTER_VALUE);
    } else {
      return set(key, value);
    }
  }
  return identity;
});
const setDateRangeFilter = compose(setOverride('dateRange', isUndefined), ifThen(ImmutableMap.isMap, convertToDateRange), ReportContextHelper.getDateRange);
const setOwnersFilter = compose(setOverride('owners', isEmpty), ReportContextHelper.getOwners);
const setTeamsFilter = compose(setOverride('teams', isEmpty), ReportContextHelper.getTeams);
const setDealPipelineFilter = compose(setOverride('dealPipeline', pipeline => pipeline === 'all'), getPipelineFilterFromContext(PIPELINE_TYPES.DEAL));
const setTicketPipelineFilter = compose(setOverride('ticketPipeline', pipeline => pipeline === 'all'), getPipelineFilterFromContext(PIPELINE_TYPES.TICKET));
export const getContextOverrideFilters = (context = ImmutableMap()) => compose(setTicketPipelineFilter(context), setDealPipelineFilter(context), setOwnersFilter(context), setTeamsFilter(context), setDateRangeFilter(context))(ImmutableMap());
export const getOverrideFrequency = (dashboardContext = ImmutableMap()) => {
  return ReportContextHelper.getFrequency(dashboardContext);
};
export const normalizeReport = (...args) => {
  return import(
  // @ts-expect-error migrate upstream
  /* webpackChunkName: "report-migration" */
  'reporting-reports/normalizeReport').then(module => {
    const func = module.default;
    return func(...args);
  });
};