'use es6';

import { getRangeType } from '../range';
import { getAllowedFrequenciesForRangeType, getDefaultFrequencyForRangeType } from '../dateRangeHelpers';
export function validateFrequency(contextChange, context, report) {
  const dateRange = contextChange.dateRange || context.dateRange;
  const rangeType = getRangeType(dateRange.toJS());
  const frequencyToTest = contextChange.frequency || context.frequency;
  const intraperiod = contextChange.intraperiod != null ? contextChange.intraperiod : context.intraperiod;
  const allowedFrequencies = getAllowedFrequenciesForRangeType(rangeType, report, intraperiod);
  if (!allowedFrequencies.includes(frequencyToTest)) {
    return Object.assign({}, contextChange, {
      frequency: getDefaultFrequencyForRangeType(dateRange, report, intraperiod)
    });
  } else {
    return contextChange;
  }
}