'use es6';

import I18n from 'I18n';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
const getErrorAlert = () => {
  if (Object.keys(FloatingAlertStore.getAlerts()).length === 0) FloatingAlertStore.addAlert({
    message: I18n.text('sales-analytics-lib.errorMessage.message'),
    titleText: I18n.text('sales-analytics-lib.errorMessage.titleText'),
    timeout: 3000,
    type: 'warning'
  });
};
export default getErrorAlert;