import { createSelector } from '@reduxjs/toolkit';
import { appliedDashboardFiltersNamespace } from './slice';
import { groupFiltersByDataSource } from './utils';
import { applySystemGeneratedAndQuickFilterGroupFlags } from '../../filter/utils/groupQuickFilterHelpers';
import { selectQuickFilterGroups } from '../quickFilterSlice';
function withState(selector) {
  return state => selector(state[appliedDashboardFiltersNamespace]);
}
const _selectDashboardFilters = dashboardId => withState(state => {
  return state.filters[dashboardId] || [];
});
export const selectDashboardFilters = dashboardId => createSelector([_selectDashboardFilters(dashboardId), selectQuickFilterGroups(String(dashboardId))],
// TODO: we need add selectQuickFilterGroups when start migration
applySystemGeneratedAndQuickFilterGroupFlags);
export const selectIsExistingProperty = (property, dashboardId) => createSelector(selectDashboardFilters(dashboardId), filters => {
  return filters.some(filter => filter.propertyName === property.name && filter.dataSource === property.dataSource);
});
export const selectIsDashboardFilterPanelOpen = withState(state => state.isPanelOpen);
export const selectDashboardFiltersGroupedByDataSource = dashboardId => createSelector(selectDashboardFilters(dashboardId), appliedDashboardFilters => {
  return groupFiltersByDataSource(appliedDashboardFilters);
});
export const selectDashboardFrequency = dashboardId => withState(state => dashboardId in state.frequency ? state.frequency[dashboardId].frequency : undefined);
export const selectDashboardFrequencyUseFiscalYear = dashboardId => withState(state => dashboardId in state.frequency ? state.frequency[dashboardId].useFiscalYear || false : false);