'use es6';

import PortalIdParser from 'PortalIdParser';
import { getFullUrl } from 'hubspot-url-utils';
const baseHubSpotUrl = getFullUrl('api');
export const getSignedSrc = fileManagerId => {
  const portalId = PortalIdParser.get();
  return `${baseHubSpotUrl}/filemanager/api/v2/files/${fileManagerId}/signed-url-redirect?portalId=${portalId}`;
};
export const isPrivateFile = fileObject => {
  return fileObject.meta && fileObject.meta.allows_anonymous_access === false;
};