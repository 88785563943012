'use es6';

import { AD_OBJECT_KEYWORD } from 'reference-resolvers/constants/ReferenceObjectTypes';
import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import { ADS_KEYWORDS } from 'reference-resolvers/constants/CacheKeys';
import { createFetchAdsObjectSearchPage, createFetchAdsObjectsByIds, fetchAdsObjectsByIds, fetchAdsObjectSearchPage } from 'reference-resolvers/api/AdsEntityApi';
export const createAdsKeywordsReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: ADS_KEYWORDS,
  createFetchByIds: opts => createFetchAdsObjectsByIds(opts)(AD_OBJECT_KEYWORD),
  createFetchSearchPage: opts => createFetchAdsObjectSearchPage(opts)(AD_OBJECT_KEYWORD),
  fetchByIds: fetchAdsObjectsByIds(AD_OBJECT_KEYWORD),
  fetchSearchPage: fetchAdsObjectSearchPage(AD_OBJECT_KEYWORD)
}, options));
export default createAdsKeywordsReferenceResolver();