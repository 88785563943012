import * as checked from 'reporting-data/lib/checked';
import { List, Map as ImmutableMap } from 'immutable';
import { DATASET_TABLE } from 'reporting-snowflake/relational/schema/table-records';
import { JoinDefinition, Join } from '../schema/table-records';
import { ASSOCIATION_DIVIDER, ASSOCIATION_ID_DIVIDER, getSelfAssociationLabelFromId, getSelfJoinMetadataForObjectId } from './self-association-utils';
export const ASSOCIATION_FIELD_NAME = 'hs_combined_association_type_id';
export const JoinEdge = checked.record({
  left: checked.string(),
  right: checked.string(),
  definitions: checked.list(JoinDefinition).defaultValue(List())
}, 'JoinEdge');
export const createEdgeKey = ({
  left,
  right
}) => {
  if (right.includes(ASSOCIATION_DIVIDER)) {
    return `${left}-${right.split(ASSOCIATION_DIVIDER)[0]}`;
  }
  return `${left}-${right}`;
};
const getSelfAssociationJoinsFromEdge = (tableDescription, newEdge, allJoinMetadata) => {
  const {
    definitions
  } = newEdge;
  if (!tableDescription.objectTypeId || definitions.isEmpty()) {
    return tableDescription;
  }
  const joinMetadata = getSelfJoinMetadataForObjectId(allJoinMetadata, tableDescription.objectTypeId);
  const associationTypeId = newEdge.definitions.first() && newEdge.definitions.first().get('combinedAssociationTypeId', '');
  const associationLabel = getSelfAssociationLabelFromId(joinMetadata, associationTypeId);
  const existingJoins = tableDescription.join || List();
  return tableDescription.set('join', existingJoins.push(Join({
    target: tableDescription.set('join', List()).set('name', `${tableDescription.name}${ASSOCIATION_DIVIDER}${associationLabel}${ASSOCIATION_ID_DIVIDER}${associationTypeId.replace('-', '_')}`),
    definitions
  })));
};
const updateTableDescriptionWithEdge = (tableDescription, newEdge, allJoinMetadata) => {
  const {
    left,
    right,
    definitions
  } = newEdge;
  if (tableDescription.name === left) {
    const {
      join: joins
    } = tableDescription;
    if (!joins || joins.isEmpty()) {
      if (left === right && newEdge.definitions && !newEdge.definitions.isEmpty()) {
        return getSelfAssociationJoinsFromEdge(tableDescription, newEdge, allJoinMetadata);
      }
      return tableDescription;
    }
    const indexOfRightTable = joins.findIndex(join => join && join.target && join.target.name === right);
    if (indexOfRightTable === -1 && left === right) {
      return getSelfAssociationJoinsFromEdge(tableDescription, newEdge, allJoinMetadata);
    }
    return tableDescription.setIn(['join', String(indexOfRightTable), 'definitions'], definitions);
  }
  return tableDescription.update('join', (joins = List()) => joins.map(join => {
    return join.update('target', target => updateTableDescriptionWithEdge(target, newEdge, allJoinMetadata));
  }).toList());
};
const createSelfJoinEdgeMap = (tableDescription, hasSelfJoinsAccess) => {
  const joinEdge = JoinEdge({
    left: tableDescription.name,
    right: tableDescription.name,
    definitions: List()
  });
  if (!hasSelfJoinsAccess) {
    return ImmutableMap();
  }
  return ImmutableMap({
    [createEdgeKey(joinEdge)]: joinEdge
  });
};
/**
 * @param {TableDescription} tableDescription
 * @returns {ImmutableMap<EdgeKey, JoinEdge>}
 */
export const collectJoinEdges = (tableDescription, hasSelfJoinsAccess) => {
  const joins = tableDescription.join;
  if (joins === undefined || joins.isEmpty()) {
    return createSelfJoinEdgeMap(tableDescription, hasSelfJoinsAccess);
  }
  const leftTable = tableDescription;
  const createJoinEdge = join => JoinEdge({
    left: leftTable.name,
    right: join.target.name,
    definitions: join.definitions
  });
  const edges = joins.filterNot(join => join.target.name === DATASET_TABLE).map(join => createJoinEdge(join)).toMap().mapKeys((__, edge) => edge ? createEdgeKey(edge) : '').toMap();
  const rest = joins.filterNot(join => join.target.name === DATASET_TABLE).map(join => join.target).flatMap(target => collectJoinEdges(target, hasSelfJoinsAccess));
  return createSelfJoinEdgeMap(tableDescription, hasSelfJoinsAccess).merge(edges).merge(rest).toMap();
};

/**
 * new edges => add to state
 * conflict edges => keep the old
 * old edges => remove from state
 * @param {ImmutableMap<JoinEdge>} oldJoinEdges
 * @param {ImmutableMap<JoinEdge>} newJoinEdges
 * @return {ImmutableMap<JoinEdge>}
 */
export const mergeEdges = (oldJoinEdges, newJoinEdges) => {
  const removedStaleEdges = oldJoinEdges.filter((_, edgeKey) => newJoinEdges.has(edgeKey));
  return newJoinEdges.merge(removedStaleEdges);
};

/**
 * @param {ImmutableMap<EdgeKey, JoinEdge>} edges
 * @param tableDescription
 * @returns {*}
 */
export const applyEdgesToTableDescription = (edges, tableDescription, allJoinMetadata) => {
  return edges.reduce((acc, edge) => {
    return updateTableDescriptionWithEdge(acc, edge, allJoinMetadata);
  }, tableDescription);
};