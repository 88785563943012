'use es6';

import I18n from 'I18n';
const HALF_PERCENT = 0.5;
const THRESHOLD = 200;
const parseBreakdown = ({
  breakdown
}) => Number(breakdown) + 1;
const bySegment = (a, b) => parseBreakdown(a) - parseBreakdown(b);
const labelDuration = breakdown => {
  const padding = 2;
  const duration = parseBreakdown(breakdown);
  const seconds = `00${duration % 60}`.slice(-padding);
  const minutes = `00${Math.floor(duration / 60)}`.slice(-padding);
  return `${minutes}:${seconds}`;
};
const labelPercent = breakdown => {
  const slice = parseBreakdown(breakdown);
  return I18n.formatPercentage(slice * HALF_PERCENT, {
    precision: 2
  });
};
export const preprocess = () => response => {
  const {
    breakdowns
  } = response;
  if (breakdowns) {
    const label = breakdowns.length < THRESHOLD ? labelDuration : labelPercent;
    const sorted = breakdowns.sort(bySegment);
    const labeled = sorted.map(point => Object.assign({}, point, {
      breakdown: label(point)
    }));
    const firstBreakdown = sorted[0];
    const realFirstBreakdown = Object.assign({}, firstBreakdown, {
      breakdown: label({
        breakdown: -1
      })
    });
    return Object.assign({}, response, {
      breakdowns: [realFirstBreakdown, ...labeled]
    });
  }
  return response;
};