import { useEffect, useState } from 'react';
import { getTourStatus, updateTourStatus } from '../api/OnboardingTours';
import Raven from 'raven-js';
import { TourCompletionStatus } from '../constants/TourCompletionStatus';
import { isOnboardingTourSeleniumRunning } from '../utils/env';
export default function useTourStatus({
  tourId
}) {
  const [loading, setLoading] = useState(true);
  const [tourStatus, setTourStatus] = useState({
    tourKey: tourId,
    status: TourCompletionStatus.INCOMPLETE
  });
  const isRunningOnboardingTourTest = isOnboardingTourSeleniumRunning();
  useEffect(() => {
    if (isRunningOnboardingTourTest) {
      // In the onboarding tour ATs we should only rely on the query parameters
      setTourStatus({
        tourKey: tourId,
        status: TourCompletionStatus.COMPLETE
      });
      setLoading(false);
      return;
    }
    getTourStatus(tourId).then(status => {
      setTourStatus(status);
    }).catch(error => {
      Raven.captureException(error, {
        fingerprint: ['error fetching the tour status']
      });
    }).finally(() => {
      setLoading(false);
    });
  }, [tourId, isRunningOnboardingTourTest]);
  useEffect(() => {
    if (isRunningOnboardingTourTest) {
      return;
    }

    // Mark the tour as complete if tour is showing
    if (!loading && tourStatus.status !== TourCompletionStatus.COMPLETE) {
      updateTourStatus(tourId, TourCompletionStatus.COMPLETE).catch(error => {
        Raven.captureException(error, {
          fingerprint: ['error updating the tour status']
        });
      });
    }
  }, [loading, tourStatus, tourId, isRunningOnboardingTourTest]);
  return {
    tourStatus,
    loading
  };
}