'use es6';

import { DASHBOARD_IS_READ_ONLY_DEMO, PERMISSION_DENIED } from 'dashboard-lib/private/action-problem-solution/actions/set-dashboard-permission/problem';
import { indexBy } from 'dashboard-lib/private/js-util';
import { DASHBOARD_SET_PERMISSION } from 'dashboard-lib/public/action/reporting-action';
import { NeedCreateAndOwnPermissionMessage } from '../common/common-messages';
import { createDisabledMessage } from '../common/disabled-message';
import { getProblemLang } from '../common/get-problem-lang';
import { createMessage, getId } from '../common/message';
import { CUSTOM_DASHBOARD_LIMIT_EXCEEDED_AND_DASHBOARD_SHOULD_LOCKDOWN } from 'dashboard-lib/private/action-problem-solution/problems/lockdown-problem';
const MESSAGE_ROOT = getProblemLang(DASHBOARD_SET_PERMISSION);
const createProblemMessage = createMessage(MESSAGE_ROOT);
const ProblemMessages = [NeedCreateAndOwnPermissionMessage(DASHBOARD_SET_PERMISSION), createProblemMessage(PERMISSION_DENIED), createProblemMessage(CUSTOM_DASHBOARD_LIMIT_EXCEEDED_AND_DASHBOARD_SHOULD_LOCKDOWN), createProblemMessage(DASHBOARD_IS_READ_ONLY_DEMO)];
const _problemToMessage = indexBy(getId, ProblemMessages);
export function getDisabledProblemMessage(params, problem) {
  return createDisabledMessage(params, problem, _problemToMessage);
}