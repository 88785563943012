import { useEffect } from 'react';
import { usePlatformDashboardSelector } from 'platform-dashboard-ui/store';
import { selectAllDataSourcesByPropertyRecords } from 'platform-dashboard-ui/ducks/dataSourcePropertiesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { platformDataSourceMetadataActions, selectDataSourcePropertiesView } from '../ducks/platform-data-source-metadata';

/**
 * Temporary hack to get all the property metadata from the platform dashboard ui's redux store into our local dbui redux store
 * This is needed to perform property validation on quick filters and dashboard filters local to dbui.
 * Once quick filters and dashboard filters are powered through the platform dashboard ui redux store this bridge can be toppled like the Francis Scott Key
 */
export const usePlatformDashboardBridge = () => {
  const allPlatformProperties = usePlatformDashboardSelector(selectAllDataSourcesByPropertyRecords);
  const dbuiProperties = useSelector(selectDataSourcePropertiesView);
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(dbuiProperties).length !== Object.keys(allPlatformProperties).length) {
      dispatch(platformDataSourceMetadataActions.updateProperties({
        properties: allPlatformProperties
      }));
    }
  }, [allPlatformProperties, dbuiProperties, dispatch]);
};