'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["properties"];
import { List, Record } from 'immutable';
import PropertyRecord from './PropertyRecord';
const PropertyGroupRecord = Record({
  displayName: '',
  displayOrder: 0,
  name: '',
  portalId: null,
  properties: List(),
  hubspotDefined: false
}, 'PropertyGroupRecord');
PropertyGroupRecord.fromJS = (json, propertyRecordTransformer = PropertyRecord.fromJS) => {
  if (!json || typeof json !== 'object') {
    return json;
  }
  const {
      properties
    } = json,
    rest = _objectWithoutPropertiesLoose(json, _excluded);
  return PropertyGroupRecord(Object.assign({}, rest, {
    properties: Array.isArray(properties) ? List(properties.map(propertyRecordTransformer)) : List()
  }));
};
export default PropertyGroupRecord;