'use es6';

import { Map as ImmutableMap, OrderedMap } from 'immutable';
import prefix from '../../lib/prefix';
const translateProperty = prefix('reporting-data.properties.tasks');
const mapToLabels = () => OrderedMap([['COMPLETED', translateProperty('taskStatus.options.COMPLETED')], ['NOT_STARTED', translateProperty('taskStatus.options.NOT_STARTED')], ['IN_PROGRESS', translateProperty('taskStatus.options.IN_PROGRESS')], ['WAITING', translateProperty('taskStatus.options.WAITING')], ['DEFERRED', translateProperty('taskStatus.options.DEFERRED')]]);
export const STATUS = () => mapToLabels().keySeq().toSet().toMap().toJS();
export const getOptions = () => mapToLabels().map((label, value) => ImmutableMap({
  label,
  value,
  displayOrder: -1
})).valueSeq().toList();