'use es6';

import FeedbackLoader from 'feedback-loader';
import getLang from 'I18n/utils/getLang';
export const OPT_SURVEY_TYPE = 'CSAT';
export const getFeedbackLoader = () => FeedbackLoader;
export const isFeedbackSurveyAvailable = (feedbackClient, feedbackSurveyId) => new Promise(resolve => {
  feedbackClient.onConfigLoaded = () => {
    resolve(feedbackClient.checkIsLanguageValid(getLang()));
  };
  feedbackClient.loadConfigOnly(OPT_SURVEY_TYPE, feedbackSurveyId);
});
export const showFeedbackSurvey = (feedbackClient, feedbackSurveyId) => {
  feedbackClient.loadSurvey(OPT_SURVEY_TYPE, feedbackSurveyId);
};