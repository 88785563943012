import { Map as ImmutableMap, List, fromJS } from 'immutable';
import I18n from 'I18n';
// @ts-expect-error Untyped dependency
import { getCurrencyInformation } from 'reporting-data/public/currency';
// @ts-expect-error Untyped dependency
import { formatDate } from 'reporting-data/hydrate/propertyFormatter';
import { DEAL_CHANGE_TYPES } from '../lib/salesAnalytics/constants';
import memoize from 'transmute/memoize';
// @ts-expect-error Untyped dependency
import Widgets from '../constants/widgets';
const DEFAULT_CURRENCY_CODE = 'USD';
const deletedDealStageLabel = () => I18n.text('reporting-ui-components.customWidgets.salesQuota.properties.deletedDealStageLabel');
const missingCategoryLabel = () => I18n.text('reporting-ui-components.customWidgets.salesQuota.properties.missingCategoryLabel');
export const dealActivityLabel = () => I18n.text('reporting-ui-components.customWidgets.pipelineSnapshots.properties.deal_activity');
export const SALES_ANALYTICS_WIDGETS = [Widgets.WATERFALL, Widgets.CHANGE_HISTORY_DEAL_LIST, Widgets.DEAL_PUSH_ANALYSIS, Widgets.PIPELINE_SNAPSHOTS, Widgets.SALES_QUOTA, Widgets.COMPLETED_ACTIVITIES];
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMATS = {
  DAY: 'l',
  WEEK: 'l',
  MONTH: 'short-m-y',
  QUARTER: 'short-q-y',
  YEAR: 'YYYY'
};
const START = 'START';
const CREATED = 'CREATED';
const PULLED = 'PULLED';
const INCREASED = 'INCREASED';
const DECREASED = 'DECREASED';
const PUSHED = 'PUSHED';
const LOST = 'LOST';
const WON = 'WON';
const END = 'END';
export const BREAKDOWNS = {
  START,
  CREATED,
  PULLED,
  INCREASED,
  DECREASED,
  PUSHED,
  LOST,
  WON,
  END
};
export const isSalesAnalyticsWidget = report => {
  const customWidgetType = report.getIn(['displayParams', 'customWidget', 'type']);
  // Being a bit defensive here checking if customWidgetType is truthy because
  // if somehow an undefined value got into the SALES_ANALYTICS_WIDGETS list,
  // we'd return true for all non-customWidget reports.
  return customWidgetType && SALES_ANALYTICS_WIDGETS.includes(customWidgetType);
};
export const getDealStageReferences = property => property.get('options').filter(option => option.get('value') !== 'create').toJS().reduce((collect, {
  value,
  label,
  displayOrder
}) => Object.assign({}, collect, {
  [value]: {
    label,
    displayOrder
  }
}), {
  '@@DELETED_DEALSTAGE@@': {
    label: deletedDealStageLabel(),
    displayOrder: -1
  },
  '@@MISSING@@': ImmutableMap({
    label: missingCategoryLabel()
  }),
  deal_activity: {
    label: dealActivityLabel()
  }
});
export const getPipelineReferences = pipelines => pipelines.reduce((collect, {
  pipelineId,
  label
}) => Object.assign({}, collect, {
  [pipelineId]: {
    label
  }
}), {});
export const getPropertyReferences = property => property.get('options', List()).reduce((collect, option) => collect.set(option.get('value'), ImmutableMap({
  label: option.get('label'),
  displayOrder: option.get('displayOrder')
})), ImmutableMap()).merge({
  '@@MISSING@@': ImmutableMap({
    label: missingCategoryLabel()
  }),
  deal_activity: ImmutableMap({
    label: dealActivityLabel()
  })
});
export const getCurrencyCode = memoize(() => getCurrencyInformation().then(({
  homeCurrency: {
    currencyCode = DEFAULT_CURRENCY_CODE
  }
}) => currencyCode));
export const getProgressReferences = () => ({
  ADVANCE: {
    label: I18n.text('reporting-ui-components.customWidgets.changeHistoryDealList.propertyChange.advance')
  },
  REGRESS: {
    label: I18n.text('reporting-ui-components.customWidgets.changeHistoryDealList.propertyChange.regress')
  },
  PUSH: {
    label: I18n.text('reporting-ui-components.customWidgets.changeHistoryDealList.propertyChange.push')
  },
  PULL: {
    label: I18n.text('reporting-ui-components.customWidgets.changeHistoryDealList.propertyChange.pull')
  },
  REMOVED: {
    label: I18n.text('reporting-ui-components.customWidgets.changeHistoryDealList.propertyChange.removed')
  },
  NEW: {
    label: I18n.text('reporting-ui-components.customWidgets.changeHistoryDealList.propertyChange.new')
  }
});
export const getPushStatusReferences = () => fromJS({
  NOT_PUSHED: {
    value: 'NOT_PUSHED',
    label: I18n.text('reporting-ui-components.customWidgets.dealPushAnalysis.options.label.dealsNotPushed')
  },
  PUSHED: {
    value: 'PUSHED',
    label: I18n.text('reporting-ui-components.customWidgets.dealPushAnalysis.options.label.dealsPushed')
  }
});
export const getSnapshotDateReferences = response => response.get('breakdowns', List()).reduce((collect, breakdown) => {
  const date = breakdown.get('snapshotDate');
  return collect.has(date) ? collect : collect.set(date, ImmutableMap({
    label: I18n.moment(breakdown.get('effectiveSnapshotDate'), DEFAULT_DATE_FORMAT).format(DATE_FORMATS.DAY)
  }));
}, ImmutableMap());
export const getDealbreakdownReferences = report => {
  const startDate = report.getIn(['config', 'filters', 'dateRange', 'value', 'startDate']);
  const endDate = report.getIn(['config', 'filters', 'dateRange', 'value', 'endDate']);
  return Object.keys(BREAKDOWNS).reduce((collect, breakdown, index) => Object.assign({}, collect, {
    [breakdown]: {
      name: breakdown,
      label: I18n.text(`reporting-ui-components.customWidgets.waterfall.dealBreakdown.${breakdown}`, breakdown === BREAKDOWNS.START ? {
        date: formatDate(startDate, 'l')
      } : breakdown === BREAKDOWNS.END ? {
        date: formatDate(endDate, 'l')
      } : {}),
      displayOrder: index
    }
  }), {});
};
export const getDealChangeTypeReferences = () => DEAL_CHANGE_TYPES.reduce((collect, changeType, index) => collect.set(changeType, ImmutableMap({
  name: changeType,
  label: I18n.text(`reporting-ui-components.customWidgets.changeHistoryDealList.deal_change_type.${changeType}`),
  displayOrder: index
})), ImmutableMap());