'use es6';

import { singleReportViewerSupportsSubApp } from './salesAnalyticsHelpers';
import { SUBSCRIPTION_ANALYTICS_GATE, LEAD_OBJECT_GATE, EMAIL_REPORT_GATE, MARKETING_ANALYTICS_PRIVATE_BETA_GATE, DEAL_PUSH_RATE_RM_GATE, PIPELINE_SNAPSHOT_JIT_GATE, PIPELINE_SNAPSHOT_RM_GATE, ALL_SUBDOMAIN_PROCESSING, V2_DEAL_STAGE_PROEPRTIES_GATE, DEAL_STAGE_PROPERTIES_BANNER_GATE, UPGRADE_ENGAGEMENTS_GATE, REMOVE_SRV_EDITOR_OVERRIDE_GATE, DEAL_CHANGE_HISTORY_RM_GATE, AI_INSIGHTS_COLLECTIONS } from '../constants/gates';

// Scopes
export const PROSPECTING_WORKSPACE_ACCESS = 'prospecting-workspace-access';
export const CONTACT_JOURNEY_READ = 'journey-analytics-read';
export const DEAL_JOURNEY_READ = 'deal-journey-analytics-read';
export const V2_DEAL_STAGE_PROEPRTIES_SCOPE = 'crm-scp-deals-access';
export const ACTIVE_CONTROL_GATES = [LEAD_OBJECT_GATE, EMAIL_REPORT_GATE, MARKETING_ANALYTICS_PRIVATE_BETA_GATE, DEAL_PUSH_RATE_RM_GATE, DEAL_CHANGE_HISTORY_RM_GATE, PIPELINE_SNAPSHOT_RM_GATE, PIPELINE_SNAPSHOT_JIT_GATE, UPGRADE_ENGAGEMENTS_GATE, AI_INSIGHTS_COLLECTIONS];

// tell the user to ask the portal admin to upgrade the portal to sales professional
// https://hubspot.slack.com/archives/C013LKGNUQ2/p1591824937027500
export function checkUserRoleSales(scopes) {
  return scopes.includes('reports-sales-pro-access');
}
export function checkUserRoleService(scopes) {
  return scopes.includes('service-team-analytics');
}
export function checkUserRoleSubscription(scopes) {
  return scopes.includes('subscriptions-access') && scopes.includes('subscription-team-analytics');
}
export function checkAccessSales(scopes) {
  return checkUserRoleSales(scopes);
}
export function checkAccessService(scopes) {
  return checkUserRoleService(scopes);
}
export function checkAccessSubscription(scopes, gates) {
  return gates.includes(SUBSCRIPTION_ANALYTICS_GATE) && checkUserRoleSubscription(scopes);
}
export function checkAccessMarketing(gates) {
  return gates.includes(MARKETING_ANALYTICS_PRIVATE_BETA_GATE);
}
export const checkHasAllSubdomainProcessing = gates => gates.includes(ALL_SUBDOMAIN_PROCESSING);
export const checkHasSingleReportViewerLayout = (gates, subAppKey) => {
  return gates.includes(MARKETING_ANALYTICS_PRIVATE_BETA_GATE) && singleReportViewerSupportsSubApp(subAppKey);
};
export const checkHasV2DealStageProperties = (gates, scopes) => gates.includes(V2_DEAL_STAGE_PROEPRTIES_GATE) && scopes.includes(V2_DEAL_STAGE_PROEPRTIES_SCOPE);
export const checkShouldShowDealStagePropertiesBanner = (gates, scopes) => scopes.includes(V2_DEAL_STAGE_PROEPRTIES_SCOPE) && gates.includes(DEAL_STAGE_PROPERTIES_BANNER_GATE) && !gates.includes(V2_DEAL_STAGE_PROEPRTIES_GATE);
export const checkHasUpgradeEngagements = gates => gates.includes(UPGRADE_ENGAGEMENTS_GATE);
export const checkHasSrvEditorOverrideRemoval = gates => gates.includes(REMOVE_SRV_EDITOR_OVERRIDE_GATE);
export const checkHasAIInsightsAccess = gates => gates.includes(AI_INSIGHTS_COLLECTIONS);