'use es6';

import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import * as PropertyTypes from 'customer-data-objects/property/PropertyTypes';
import { fromJS } from 'immutable';
const DEFAULT = '__DEFAULT__';
const REFINEMENT = '__REFINEMENT__';
const i18nPrefix = 'customerDataFilters.FilterOperatorLabelTranslations';
export const OperatorLabelTranslations = fromJS({
  [DEFAULT]: {
    [Operators.After]: `${i18nPrefix}.DEFAULT.After`,
    [Operators.AfterDate]: `${i18nPrefix}.DEFAULT.AfterDate`,
    [Operators.Any]: `${i18nPrefix}.DEFAULT.Any`,
    [Operators.AssociatedTo]: `${i18nPrefix}.DEFAULT.AssociatedTo`,
    [Operators.Before]: `${i18nPrefix}.DEFAULT.Before`,
    [Operators.BeforeDate]: `${i18nPrefix}.DEFAULT.BeforeDate`,
    [Operators.BeforeNow]: `${i18nPrefix}.DEFAULT.BeforeNow`,
    [Operators.Contain]: `${i18nPrefix}.DEFAULT.Contain`,
    [Operators.ContainAll]: `${i18nPrefix}.DEFAULT.ContainAll`,
    [Operators.ContainAllTeams]: `${i18nPrefix}.DEFAULT.ContainAllTeams`,
    [Operators.ContainAny]: `${i18nPrefix}.DEFAULT.ContainAny`,
    [Operators.CtaHasClicked]: `${i18nPrefix}.DEFAULT.CtaHasClicked`,
    [Operators.CtaHasNotClicked]: `${i18nPrefix}.DEFAULT.CtaHasNotClicked`,
    [Operators.CtaHasNotSeen]: `${i18nPrefix}.DEFAULT.CtaHasNotSeen`,
    [Operators.CtaHasSeen]: `${i18nPrefix}.DEFAULT.CtaHasSeen`,
    [Operators.DeprecatedFilter]: `${i18nPrefix}.DEFAULT.DeprecatedFilter`,
    [Operators.MissingAssociationBranchFilter]: `${i18nPrefix}.DEFAULT.MissingAssociationBranchFilter`,
    [Operators.EmailBounced]: `${i18nPrefix}.DEFAULT.EmailBounced`,
    [Operators.EmailLinkClicked]: `${i18nPrefix}.DEFAULT.EmailLinkClicked`,
    [Operators.EmailMarked]: `${i18nPrefix}.DEFAULT.EmailMarked`,
    [Operators.EmailOpened]: `${i18nPrefix}.DEFAULT.EmailOpened`,
    [Operators.EmailOpenedButLinkNotClicked]: `${i18nPrefix}.DEFAULT.EmailOpenedButLinkNotClicked`,
    [Operators.EmailOpenedButNotReplied]: `${i18nPrefix}.DEFAULT.EmailOpenedButNotReplied`,
    [Operators.EmailReceived]: `${i18nPrefix}.DEFAULT.EmailReceived`,
    [Operators.EmailReceivedButNotOpened]: `${i18nPrefix}.DEFAULT.EmailReceivedButNotOpened`,
    [Operators.EmailReplied]: `${i18nPrefix}.DEFAULT.EmailReplied`,
    [Operators.EmailSent]: `${i18nPrefix}.DEFAULT.EmailSent`,
    [Operators.EmailSentButLinkNotClicked]: `${i18nPrefix}.DEFAULT.EmailSentButLinkNotClicked`,
    [Operators.EmailSentButNotReceived]: `${i18nPrefix}.DEFAULT.EmailSentButNotReceived`,
    [Operators.EmailUnsubscribed]: `${i18nPrefix}.DEFAULT.EmailUnsubscribed`,
    [Operators.EmailSubscriptionNotOpted]: `${i18nPrefix}.DEFAULT.EmailSubscriptionNotOpted`,
    [Operators.EmailSubscriptionOptIn]: `${i18nPrefix}.DEFAULT.EmailSubscriptionOptIn`,
    [Operators.EmailSubscriptionOptOut]: `${i18nPrefix}.DEFAULT.EmailSubscriptionOptOut`,
    [Operators.EndsWith]: `${i18nPrefix}.DEFAULT.EndsWith`,
    [Operators.EndsWithAny]: `${i18nPrefix}.DEFAULT.EndsWithAny`,
    [Operators.Equal]: `${i18nPrefix}.DEFAULT.Equal`,
    [Operators.EqualAll]: `${i18nPrefix}.DEFAULT.EqualAll`,
    [Operators.EqualAny]: `${i18nPrefix}.DEFAULT.EqualAny`,
    [Operators.EventCompleted]: `${i18nPrefix}.DEFAULT.EventCompleted`,
    [Operators.EventNotCompleted]: `${i18nPrefix}.DEFAULT.EventNotCompleted`,
    [Operators.EverContained]: `${i18nPrefix}.DEFAULT.EverContained`,
    [Operators.EverContainedAll]: `${i18nPrefix}.DEFAULT.EverContainedAll`,
    [Operators.EverContainedAny]: `${i18nPrefix}.DEFAULT.EverContainedAny`,
    [Operators.EverEqual]: `${i18nPrefix}.DEFAULT.EverEqual`,
    [Operators.EverEqualAny]: `${i18nPrefix}.DEFAULT.EverEqualAny`,
    [Operators.EverEqualAll]: `${i18nPrefix}.DEFAULT.EverEqualAll`,
    [Operators.EverIn]: `${i18nPrefix}.DEFAULT.EverIn`,
    [Operators.FormFilledOut]: `${i18nPrefix}.DEFAULT.FormFilledOut`,
    [Operators.FormNotFilledOut]: `${i18nPrefix}.DEFAULT.FormNotFilledOut`,
    [Operators.Greater]: `${i18nPrefix}.DEFAULT.Greater`,
    [Operators.GreaterOrEqual]: `${i18nPrefix}.DEFAULT.GreaterOrEqual`,
    [Operators.GreaterRolling]: `${i18nPrefix}.DEFAULT.GreaterRolling`,
    [Operators.GreaterThanRolling]: `${i18nPrefix}.DEFAULT.GreaterThanRolling`,
    [Operators.In]: `${i18nPrefix}.DEFAULT.In`,
    [Operators.InImport]: `${i18nPrefix}.DEFAULT.InImport`,
    [Operators.InRange]: `${i18nPrefix}.DEFAULT.InRange`,
    [Operators.InRollingDateRange]: `${i18nPrefix}.DEFAULT.InRollingDateRange`,
    [Operators.Known]: `${i18nPrefix}.DEFAULT.Known`,
    [Operators.Less]: `${i18nPrefix}.DEFAULT.Less`,
    [Operators.LessOrEqual]: `${i18nPrefix}.DEFAULT.LessOrEqual`,
    [Operators.LessRolling]: `${i18nPrefix}.DEFAULT.LessRolling`,
    [Operators.LessThanRolling]: `${i18nPrefix}.DEFAULT.LessThanRolling`,
    [Operators.ListIsMember]: `${i18nPrefix}.DEFAULT.ListIsMember`,
    [Operators.ListIsNotMember]: `${i18nPrefix}.DEFAULT.ListIsNotMember`,
    [Operators.Near]: `${i18nPrefix}.DEFAULT.Near`,
    [Operators.NeverContained]: `${i18nPrefix}.DEFAULT.NeverContained`,
    [Operators.NeverEqual]: `${i18nPrefix}.DEFAULT.NeverEqual`,
    [Operators.NeverEqualAny]: `${i18nPrefix}.DEFAULT.NeverEqualAny`,
    [Operators.NeverEqualAll]: `${i18nPrefix}.DEFAULT.NeverEqualAll`,
    [Operators.NeverIn]: `${i18nPrefix}.DEFAULT.NeverIn`,
    [Operators.NotContain]: `${i18nPrefix}.DEFAULT.NotContain`,
    [Operators.NotContainAll]: `${i18nPrefix}.DEFAULT.NotContainAll`,
    [Operators.NotContainAny]: `${i18nPrefix}.DEFAULT.NotContainAny`,
    [Operators.NotEqual]: `${i18nPrefix}.DEFAULT.NotEqual`,
    [Operators.NotEqualAll]: `${i18nPrefix}.DEFAULT.NotEqualAll`,
    [Operators.NotEqualAny]: `${i18nPrefix}.DEFAULT.NotEqualAny`,
    [Operators.NeverContainedAll]: `${i18nPrefix}.DEFAULT.NeverContainedAll`,
    [Operators.SurveyMonkeyHasAnswered]: `${i18nPrefix}.DEFAULT.SurveyMonkeyHasAnswered`,
    [Operators.SurveyMonkeyHasResponded]: `${i18nPrefix}.DEFAULT.SurveyMonkeyHasResponded`,
    [Operators.SurveyMonkeyNotHasResponded]: `${i18nPrefix}.DEFAULT.SurveyMonkeyNotHasResponded`,
    [Operators.NotIn]: `${i18nPrefix}.DEFAULT.NotIn`,
    [Operators.NotInImport]: `${i18nPrefix}.DEFAULT.NotInImport`,
    [Operators.NotInRange]: `${i18nPrefix}.DEFAULT.NotInRange`,
    [Operators.NotKnown]: `${i18nPrefix}.DEFAULT.NotKnown`,
    [Operators.NotWildCardEqual]: `${i18nPrefix}.DEFAULT.NotWildCardEqual`,
    [Operators.NotUpdatedInLastXDays]: `${i18nPrefix}.DEFAULT.NotUpdatedInLastXDays`,
    [Operators.PageViewContain]: `${i18nPrefix}.DEFAULT.PageViewContain`,
    [Operators.PageViewEqual]: `${i18nPrefix}.DEFAULT.PageViewEqual`,
    [Operators.PageViewMatchRegex]: `${i18nPrefix}.DEFAULT.PageViewMatchRegex`,
    [Operators.PageViewNotContain]: `${i18nPrefix}.DEFAULT.PageViewNotContain`,
    [Operators.PageViewNotEqual]: `${i18nPrefix}.DEFAULT.PageViewNotEqual`,
    [Operators.PageViewNotMatchRegex]: `${i18nPrefix}.DEFAULT.PageViewNotMatchRegex`,
    [Operators.PrivacyConsentCompleted]: `${i18nPrefix}.DEFAULT.PrivacyConsentCompleted`,
    [Operators.PrivacyConsentNotCompleted]: `${i18nPrefix}.DEFAULT.PrivacyConsentNotCompleted`,
    [Operators.StartsWith]: `${i18nPrefix}.DEFAULT.StartsWith`,
    [Operators.StartsWithAny]: `${i18nPrefix}.DEFAULT.StartsWithAny`,
    [Operators.UpdatedAfter]: `${i18nPrefix}.DEFAULT.UpdatedAfter`,
    [Operators.UpdatedBefore]: `${i18nPrefix}.DEFAULT.UpdatedBefore`,
    [Operators.UpdatedInLastXDays]: `${i18nPrefix}.DEFAULT.UpdatedInLastXDays`,
    [Operators.WebinarHasAttended]: `${i18nPrefix}.DEFAULT.WebinarHasAttended`,
    [Operators.WebinarNotHasAttended]: `${i18nPrefix}.DEFAULT.WebinarNotHasAttended`,
    [Operators.WebinarNotHasRegistered]: `${i18nPrefix}.DEFAULT.WebinarNotHasRegistered`,
    [Operators.WebinarHasRegistered]: `${i18nPrefix}.DEFAULT.WebinarHasRegistered`,
    [Operators.WildCardEqual]: `${i18nPrefix}.DEFAULT.WildCardEqual`,
    [Operators.WorkflowActive]: `${i18nPrefix}.DEFAULT.WorkflowActive`,
    [Operators.WorkflowNotActive]: `${i18nPrefix}.DEFAULT.WorkflowNotActive`,
    [Operators.WorkflowCompleted]: `${i18nPrefix}.DEFAULT.WorkflowCompleted`,
    [Operators.WorkflowNotCompleted]: `${i18nPrefix}.DEFAULT.WorkflowNotCompleted`,
    [Operators.WorkflowEnrolled]: `${i18nPrefix}.DEFAULT.WorkflowEnrolled`,
    [Operators.WorkflowNotEnrolled]: `${i18nPrefix}.DEFAULT.WorkflowNotEnrolled`,
    [Operators.WorkflowMetGoal]: `${i18nPrefix}.DEFAULT.WorkflowMetGoal`,
    [Operators.WorkflowNotMetGoal]: `${i18nPrefix}.DEFAULT.WorkflowNotMetGoal`,
    [Operators.EqualTimePoint]: `${i18nPrefix}.DEFAULT.Equal`,
    [Operators.BeforeTimePoint]: `${i18nPrefix}.DEFAULT.BeforeDate`,
    [Operators.AfterTimePoint]: `${i18nPrefix}.DEFAULT.AfterDate`,
    [Operators.BetweenTimePoints]: `${i18nPrefix}.DEFAULT.InRange`,
    [Operators.NotBetweenTimePoints]: `${i18nPrefix}.DEFAULT.NotInRange`
  },
  [PropertyTypes.DATE]: {
    [Operators.Greater]: `${i18nPrefix}.DATE.Greater`,
    [Operators.GreaterOrEqual]: `${i18nPrefix}.DATE.GreaterOrEqual`,
    [Operators.Less]: `${i18nPrefix}.DATE.Less`,
    [Operators.LessOrEqual]: `${i18nPrefix}.DATE.LessOrEqual`
  },
  [PropertyTypes.DATE_TIME]: {
    [Operators.Greater]: `${i18nPrefix}.DATE.Greater`,
    [Operators.Less]: `${i18nPrefix}.DATE.Less`
  },
  [REFINEMENT]: {
    [Operators.Equal]: `${i18nPrefix}.REFINEMENT.Equal`,
    [Operators.Greater]: `${i18nPrefix}.REFINEMENT.Greater`,
    [Operators.Less]: `${i18nPrefix}.REFINEMENT.Less`
  },
  [PropertyTypes.STRING]: {
    [Operators.NotWildCardEqual]: `${i18nPrefix}.STRING.NotWildCardEqual`,
    [Operators.WildCardEqual]: `${i18nPrefix}.STRING.WildCardEqual`
  }
});
export function getOperatorLabelTranslationKey(Operator, field) {
  const {
    refinement,
    type
  } = field || {};
  const defaultTranslationKey = Operator.toString();
  const translationKey = refinement ? OperatorLabelTranslations.getIn([REFINEMENT, Operator.toString()]) || OperatorLabelTranslations.getIn([type, Operator.toString()]) || OperatorLabelTranslations.getIn([DEFAULT, Operator.toString()]) : OperatorLabelTranslations.getIn([type, Operator.toString()]) || OperatorLabelTranslations.getIn([DEFAULT, Operator.toString()]);
  if (!translationKey) {
    console.error(`translation key not found in FilterOperatorLabelTranslations for Operator '${Operator}'`);
  }
  return translationKey || defaultTranslationKey;
}