'use es6';

import fromJS from 'transmute/fromJS';
import indexBy from 'transmute/indexBy';
import http from 'hub-http/clients/apiClient';
import { registerQuery } from 'data-fetching-client';
import { getId } from '../schemas/teamSchema';
export const TEAMS_FIELD_NAME = 'teams';
export const TEAM_HIERARCHY_FIELD_NAME = 'teamHierarchy';
const TEAMS_URL = 'app-users/v1/teams';
const TEAM_HIERARCHY_URL = `${TEAMS_URL}/hierarchy`;
const fetchTeams = () => http.get(TEAMS_URL);
const fetchTeamHierarchy = () => http.get(TEAM_HIERARCHY_URL);

// @returns {Map() of
//   Map({
//     name: String name of the team
//     userIds: List ids of the users in the team
//     parentTeamId: Number id of the parent team if has one
//     secondaryUserIds: List
//     id: Number id of the team
//     childTeams: List of child teams
//   }) keyed by team id
// }
export const FETCH_TEAMS = registerQuery({
  fieldName: TEAMS_FIELD_NAME,
  fetcher() {
    return fetchTeams().then(teamsResponse => indexBy(getId, fromJS(teamsResponse)), error => console.error(error));
  }
});

// @returns {List() of
//   Maps({
//     name: String name of the team
//     userIds: List ids of the users in the team
//     parentTeamId: Number id of the parent team if has one
//     secondaryUserIds: List
//     id: Number id of the team
//     childTeams: List of child teams
//   })
// }
export const FETCH_TEAM_HIERARCHY = registerQuery({
  fieldName: TEAM_HIERARCHY_FIELD_NAME,
  fetcher() {
    return fetchTeamHierarchy().then(fromJS, error => console.error(error));
  }
});