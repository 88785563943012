'use es6';

import * as OperatorTypes from '../ObjectSegOperatorTypes';
import { __ANY_LINK } from 'customer-data-filters/converters/listSegClassic/ListSegConstants';
import fromRefinementFilter from './fromRefinementFilter';
import getIn from 'transmute/getIn';
import MissingField from '../../../filterQueryFormat/MissingField';
const EVENT_TYPE_TO_OPERATOR_TYPE = {
  SENT: OperatorTypes.EMAIL_SENT,
  DELIVERED: OperatorTypes.EMAIL_RECEIVED,
  BOUNCE: OperatorTypes.EMAIL_BOUNCED,
  OPEN: OperatorTypes.EMAIL_OPENED,
  CLICK: OperatorTypes.EMAIL_LINK_CLICKED,
  SPAMREPORT: OperatorTypes.EMAIL_MARKED,
  UNSUBSCRIBED: OperatorTypes.EMAIL_UNSUBSCRIBED,
  REPLIED: OperatorTypes.EMAIL_REPLIED
};
const tryAddRefinement = (operator, field, filter) => {
  const filterWithRefinement = getIn(['emailFilterLines', 0], filter);
  const refinement = fromRefinementFilter(field, filterWithRefinement);
  return refinement ? operator.set('refinement', refinement) : operator;
};
const hydrateIfMissingField = (field, filter) => {
  if (!(field instanceof MissingField)) {
    return field;
  }

  // If the field is not found, we are not able to derive the appId or level
  // when converting to object seg. If we have the information in the filter
  // it should be saved in the missing field.
  return field.set('metadata', {
    appId: filter.appId,
    level: filter.level
  });
};

// Simple email campaign operations do not have a value
export const fromSimpleEmailCampaignOperation = (Operator, field, filter) => {
  const hydratedField = hydrateIfMissingField(field, filter);
  const operator = Operator.of(hydratedField);
  return tryAddRefinement(operator, hydratedField, filter);
};

// Standard email campaign operations has a value
export const fromEmailCampaignOperation = (Operator, field, filter) => {
  let value;
  const filterLines = filter.emailFilterLines;
  if (filterLines && filterLines.length && filterLines[0].clickUrlSubstring) {
    value = filterLines[0].clickUrlSubstring;
  }
  const hydratedField = hydrateIfMissingField(field, filter);
  const operator = Operator.of(hydratedField, value || __ANY_LINK);
  return tryAddRefinement(operator, hydratedField, filter);
};
export const getOperatorForEmailCampaign = filter => {
  if (!filter || !filter.emailFilterLines || !filter.emailFilterLines.length) {
    return undefined;
  }
  const {
    emailFilterLines
  } = filter;
  const event1 = EVENT_TYPE_TO_OPERATOR_TYPE[emailFilterLines[0].eventType];
  if (emailFilterLines.length === 1) {
    return event1;
  }
  const event2 = EVENT_TYPE_TO_OPERATOR_TYPE[emailFilterLines[1].eventType];
  const {
    negated
  } = emailFilterLines[1];
  if (event1 === OperatorTypes.EMAIL_OPENED && event2 === OperatorTypes.EMAIL_LINK_CLICKED && negated) {
    return OperatorTypes.EMAIL_OPENED_BUT_LINK_NOT_CLICKED;
  }
  if (event1 === OperatorTypes.EMAIL_OPENED && event2 === OperatorTypes.EMAIL_REPLIED && negated) {
    return OperatorTypes.EMAIL_OPENED_BUT_NOT_REPLIED;
  }
  if (event1 === OperatorTypes.EMAIL_RECEIVED && event2 === OperatorTypes.EMAIL_OPENED && negated) {
    return OperatorTypes.EMAIL_RECEIVED_BUT_NOT_OPENED;
  }
  if (event1 === OperatorTypes.EMAIL_SENT && event2 === OperatorTypes.EMAIL_LINK_CLICKED && negated) {
    return OperatorTypes.EMAIL_SENT_BUT_LINK_NOT_CLICKED;
  }
  if (event1 === OperatorTypes.EMAIL_SENT && event2 === OperatorTypes.EMAIL_RECEIVED && negated) {
    return OperatorTypes.EMAIL_SENT_BUT_NOT_RECEIVED;
  }
  return undefined;
};