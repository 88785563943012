'use es6';

import usePollingQuery from './usePollingQuery';
import { FETCH_REPORT_COUNTS, REPORT_COUNTS_FIELD_NAME } from '../requests/reportCounts';
export const useReportCounts = () => {
  const {
    data,
    error,
    loading
  } = usePollingQuery(FETCH_REPORT_COUNTS);
  return {
    data: data ? data[REPORT_COUNTS_FIELD_NAME] : undefined,
    error,
    loading
  };
};
export const useCustomReportCount = () => {
  const {
    data
  } = useReportCounts();
  return data && data.get('customReports');
};
export const useStandardReportCount = () => {
  const {
    data
  } = useReportCounts();
  return data && data.get('standardReports');
};