import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// import { updateQuickFilter as updateFilter } from '../filter/data/QuickFilterDAO';
import { RequestState } from '../../filter/requests/request-state';
import { getFilterQueryFormatOperator } from '../../filter/utils/filterConversionHelpers';
import { handleErrorMessage } from '../../lib/quickFilters/quickFilterUtils';
import { QUICK_FILTER_LIMIT, QUICK_FILTER_MAP_DELIMITER, quickFiltersNamespace, getQuickFilterUniqueKey } from './constants';
import { selectIsQuickFilterPropertyPinned } from './selectors';
import { createQuickFilterGroupAsyncThunk, removeAllQuickFilterGroupsAsyncThunk, removeQuickFilterGroupAsyncThunk, updateQuickFilterGroupAsyncThunk } from './actions';
export let QuickFilterPanelViews;

// Helpers
(function (QuickFilterPanelViews) {
  QuickFilterPanelViews[QuickFilterPanelViews["ADD"] = 0] = "ADD";
  QuickFilterPanelViews[QuickFilterPanelViews["EDIT"] = 1] = "EDIT";
})(QuickFilterPanelViews || (QuickFilterPanelViews = {}));
const checkIfFilterExists = filters => {
  return (dataSourceId, name) => {
    return filters.filter(item => dataSourceId === item.dataSourceId && item.name === name).length > 0;
  };
};
export const getAppliedFilterOperator = appliedQuickFilterOperators => (dataSource, property, name) => {
  const key = getQuickFilterUniqueKey({
    dataSource,
    name
  });
  if (!appliedQuickFilterOperators.has(key)) {
    return null;
  }
  return getFilterQueryFormatOperator(appliedQuickFilterOperators.get(key), property);
};
export const QUICK_FILTER_REQUEST_ACTION = {
  LOAD: 'load',
  PIN: 'pin',
  UNPIN: 'unpin',
  MODIFY: 'modify'
};
const initialState = {
  quickFilterSet: {},
  quickFilterGroupSet: {},
  isPanelOpen: false,
  panelView: QuickFilterPanelViews.ADD,
  fetchQuickFiltersStatus: RequestState.UNINITIALIZED,
  dashboardId: ''
};
export const onUpdateQuickFilter = createAsyncThunk('quickFilters/update', async ({
  dashboardId,
  updatedQuickFilters,
  requestAction = QUICK_FILTER_REQUEST_ACTION.MODIFY
}) => {
  await Promise.resolve(dashboardId); // TODO: remove this when we need to persist quick filter on the go.
  // await updateFilter(dashboardId, [...updatedQuickFilters]);
  return {
    dashboardId,
    updatedQuickFilters,
    requestAction
  };
});
const quickFilterSlice = createSlice({
  name: quickFiltersNamespace,
  initialState,
  reducers: {
    openQuickFiltersPanel(state, action) {
      const {
        panelView
      } = action.payload;
      state.isPanelOpen = true;
      state.panelView = panelView;
    },
    closeQuickFiltersPanel(state) {
      state.isPanelOpen = false;
    },
    setDashboardId(state, action) {
      const {
        dashboardId
      } = action.payload;
      state.dashboardId = dashboardId;
    },
    onFetching(state) {
      state.fetchQuickFiltersStatus = RequestState.PENDING;
    },
    onFetchCompletes(state) {
      state.fetchQuickFiltersStatus = RequestState.SUCCEEDED;
    },
    onFetchError(state, action) {
      const {
        requestAction
      } = action.payload;
      state.fetchQuickFiltersStatus = RequestState.FAILED;
      return handleErrorMessage(requestAction);
    },
    setQuickFilters(state, action) {
      const {
        dashboardId,
        quickFilters
      } = action.payload;
      state.quickFilterSet[dashboardId] = quickFilters;
    },
    unpinAllQuickFilters(state, action) {
      const {
        dashboardId
      } = action.payload;
      state.quickFilterSet[dashboardId] = [];
    },
    setQuickFilterGroups(state, action) {
      const {
        dashboardId,
        quickFilterGroups
      } = action.payload;
      state.quickFilterGroupSet[dashboardId] = quickFilterGroups;
    }
  },
  extraReducers: builder => {
    builder.addCase(onUpdateQuickFilter.fulfilled, (state, action) => {
      const {
        dashboardId,
        updatedQuickFilters
      } = action.payload;
      state.quickFilterSet[dashboardId] = updatedQuickFilters;
      state.fetchQuickFiltersStatus = RequestState.SUCCEEDED;
    });
    builder.addCase(onUpdateQuickFilter.rejected, (state, action) => {
      const {
        requestAction
      } = action.meta.arg;
      state.fetchQuickFiltersStatus = RequestState.FAILED;
      // TODO: add a middleware or handleErrorMessage would be considered as side effect
      return handleErrorMessage(requestAction);
    });
    builder.addCase(createQuickFilterGroupAsyncThunk.fulfilled, (state, action) => {
      const {
        meta: {
          arg: {
            dashboardId,
            quickFilterGroup
          }
        }
      } = action;
      const currentQuickFilterGroups = state.quickFilterGroupSet[dashboardId] || [];
      state.quickFilterGroupSet[dashboardId] = [...currentQuickFilterGroups, quickFilterGroup];
    });
    builder.addCase(updateQuickFilterGroupAsyncThunk.fulfilled, (state, action) => {
      const {
        meta: {
          arg: {
            dashboardId,
            quickFilterGroup,
            quickFilterGroupIdx
          }
        }
      } = action;
      const currentQuickFilterGroups = [...(state.quickFilterGroupSet[dashboardId] || [])];
      if (currentQuickFilterGroups.length > quickFilterGroupIdx) {
        currentQuickFilterGroups[quickFilterGroupIdx] = quickFilterGroup;
        state.quickFilterGroupSet[dashboardId] = [...currentQuickFilterGroups];
      }
    });
    builder.addCase(removeQuickFilterGroupAsyncThunk.fulfilled, (state, action) => {
      const {
        meta: {
          arg: {
            dashboardId,
            quickFilterGroupIdx
          }
        }
      } = action;
      const currentQuickFilterGroups = state.quickFilterGroupSet[dashboardId] || [];
      state.quickFilterGroupSet[dashboardId] = [...currentQuickFilterGroups].filter((__, idx) => {
        return idx !== quickFilterGroupIdx;
      });
    });
    builder.addCase(removeAllQuickFilterGroupsAsyncThunk.fulfilled, (state, action) => {
      const {
        meta: {
          arg: {
            dashboardId
          }
        }
      } = action;
      state.quickFilterGroupSet[dashboardId] = [];
    });
  }
});

// actions
export const pinQuickFilter = ({
  dashboardId,
  dataSourceId,
  name
}) => (dispatch, getState) => {
  const state = getState().quickFilter;
  const quickFilterSet = state.quickFilterSet[dashboardId] || [];
  const isReachLimit = quickFilterSet.length === QUICK_FILTER_LIMIT;
  const checkIfExistFn = checkIfFilterExists(quickFilterSet);
  if (isReachLimit || checkIfExistFn(dataSourceId, name)) {
    return;
  }
  const updatedQuickFilters = [...quickFilterSet, {
    dataSourceId,
    name
  }];
  dispatch(onUpdateQuickFilter({
    dashboardId,
    updatedQuickFilters,
    requestAction: QUICK_FILTER_REQUEST_ACTION.PIN
  }));
};
export const unpinQuickFilter = filterToUnpin => (dispatch, getState) => {
  const state = getState().quickFilter;
  const {
    dashboardId,
    dataSourceId,
    name
  } = filterToUnpin;
  const updatedQuickFilters = state.quickFilterSet[dashboardId].filter(filter => !(filter.dataSourceId === dataSourceId && filter.name === name));
  dispatch(onUpdateQuickFilter({
    dashboardId,
    updatedQuickFilters,
    requestAction: QUICK_FILTER_REQUEST_ACTION.UNPIN
  }));
};
export const onClickPropertyItem = ({
  dashboardId,
  dataSourceId,
  name
}) => (dispatch, getState) => {
  const state = getState();
  const isQuickFilterPropertyPinned = selectIsQuickFilterPropertyPinned({
    dashboardId,
    dataSourceId,
    propertyName: name
  })(state);
  if (isQuickFilterPropertyPinned) {
    unpinQuickFilter({
      dashboardId,
      dataSourceId,
      name
    })(dispatch, getState);
  } else {
    pinQuickFilter({
      dashboardId,
      dataSourceId,
      name
    })(dispatch, getState);
  }
};
export const updateQuickFilterOrder = ({
  dashboardId,
  newOrder
}) => (dispatch, getState) => {
  const state = getState().quickFilter;
  const quickFilters = state.quickFilterSet[dashboardId];
  const orderChanged = quickFilters.some(({
    dataSourceId,
    name
  }, index) => newOrder[index] !== getQuickFilterUniqueKey({
    dataSource: dataSourceId,
    name
  }));
  if (!orderChanged) {
    return;
  }
  dispatch(onUpdateQuickFilter({
    dashboardId,
    updatedQuickFilters: newOrder.map(item => {
      const [dataSourceId, name] = item.split(QUICK_FILTER_MAP_DELIMITER);
      return {
        dataSourceId,
        name
      };
    })
  }));
};
export const {
  openQuickFiltersPanel,
  setDashboardId,
  onFetching,
  onFetchCompletes,
  onFetchError,
  closeQuickFiltersPanel,
  setQuickFilters,
  unpinAllQuickFilters,
  setQuickFilterGroups
} = quickFilterSlice.actions;
export const quickFilterSliceReducer = quickFilterSlice.reducer;
export default quickFilterSliceReducer;