import * as checked from 'reporting-data/lib/checked';
import { DataSourceType, DataSourceTypes } from './data-source';
import { DatasetProperty, RelationalDataset } from '../../dataset/dataset-records';
import { PropertyTypeNames } from '../../dataset/type-records';

// not exhaustive, just those needed for programmatic use
export const MetaTypes = {
  PORTAL_SPECIFIC: 'PORTAL_SPECIFIC'
};
export const RolloutStage = checked.symbol({
  BETA: 'beta',
  NEW: 'new'
}, 'RolloutStage');
export { DataSourceTypes } from './data-source';
export const DataSource = checked.record({
  dataSourceType: DataSourceType,
  dataSourceId: checked.string(),
  eventsDigestType: checked.string(),
  reportable: checked.boolean(),
  reportingFirstLevelGroups: checked.list(checked.string()),
  reportingSection: checked.string(),
  // todo: change to RolloutStage
  reportingBeta: checked.boolean().optional(),
  inboundDbObjectType: checked.any(),
  requiresEventDateRangeFilter: checked.boolean().defaultValue(false),
  enabled: checked.boolean().defaultValue(true)
}, 'DataSource');
export const SnowflakeHubspotObjectTable = checked.record({
  id: checked.number(),
  dataSourceId: checked.string(),
  dataSourceType: checked.string().always(DataSourceTypes.HUBSPOT_OBJECT),
  type: checked.string().always(DataSourceTypes.HUBSPOT_OBJECT),
  metaTypeId: checked.number(),
  objectTypeId: checked.string(),
  name: checked.string(),
  metaDefinition: checked.any(),
  rolloutStage: RolloutStage.optional(),
  enabled: checked.boolean().defaultValue(true),
  requiresEventDateRangeFilter: checked.boolean().defaultValue(false)
});
export const SnowflakeHubspotEventTable = checked.record({
  id: checked.number(),
  dataSourceId: checked.string(),
  dataSourceType: checked.string().always(DataSourceTypes.HUBSPOT_EVENT),
  type: checked.string().always(DataSourceTypes.HUBSPOT_EVENT),
  metaTypeId: checked.number(),
  objectTypeId: checked.string(),
  name: checked.string(),
  metaDefinition: checked.any(),
  rolloutStage: RolloutStage.optional(),
  enabled: checked.boolean().defaultValue(true),
  requiresEventDateRangeFilter: checked.boolean().defaultValue(false)
});
export const SnowflakeEventsDigestTable = checked.record({
  dataSourceType: checked.string().always(DataSourceTypes.EVENTS_DIGEST),
  type: checked.string().always(DataSourceTypes.EVENTS_DIGEST),
  dataSourceId: checked.string(),
  eventsDigestType: checked.string(),
  name: checked.string(),
  rolloutStage: RolloutStage.optional(),
  enabled: checked.boolean().defaultValue(true),
  requiresEventDateRangeFilter: checked.boolean().defaultValue(false)
});
export const SnowflakeExternalDataSourceTable = checked.record({
  dataSourceType: checked.string().always(DataSourceTypes.EXTERNAL_DATASOURCE),
  type: checked.string().always(DataSourceTypes.EXTERNAL_DATASOURCE),
  dataSourceId: checked.string(),
  name: checked.string(),
  rolloutStage: RolloutStage.optional(),
  enabled: checked.boolean().defaultValue(true),
  requiresEventDateRangeFilter: checked.boolean().defaultValue(false)
});
export const SnowflakeVirtualDataSourceTable = checked.record({
  dataSourceType: checked.string().always(DataSourceTypes.VIRTUAL_DATASOURCE),
  type: checked.string().always(DataSourceTypes.VIRTUAL_DATASOURCE),
  dataSourceId: checked.string(),
  name: checked.string(),
  rolloutStage: RolloutStage.optional(),
  enabled: checked.boolean().defaultValue(true),
  requiresEventDateRangeFilter: checked.boolean().defaultValue(false)
});
const _SnowflakeTable = checked.poly('dataSourceType', {
  [DataSourceTypes.HUBSPOT_OBJECT]: SnowflakeHubspotObjectTable,
  [DataSourceTypes.HUBSPOT_EVENT]: SnowflakeHubspotEventTable,
  [DataSourceTypes.EVENTS_DIGEST]: SnowflakeEventsDigestTable,
  [DataSourceTypes.EXTERNAL_DATASOURCE]: SnowflakeExternalDataSourceTable
}, 'SnowflakeTable');
export const SnowflakeTable = _SnowflakeTable;
export const SnowflakeTableGroup = checked.record({
  name: checked.string(),
  section: checked.string(),
  root: checked.list(checked.string()),
  related: checked.list(checked.string()),
  makeRelatedEventRootIfPrimary: checked.boolean(),
  rolloutStage: RolloutStage.optional()
}, 'SnowflakeTableGroup');
export const SnowflakeOption = checked.record({
  value: checked.string(),
  label: checked.string().optional(),
  displayOrder: checked.number().optional(),
  description: checked.any()
}, 'SnowflakeOption');
export const RollupPropertyErrorLevels = {
  ERROR: 'ERROR',
  WARNING: 'WARNING'
};
export const SnowflakeProperty = checked.record({
  name: checked.string(),
  label: checked.string(),
  type: checked.symbol(PropertyTypeNames),
  groupName: checked.string(),
  hidden: checked.boolean().optional(),
  // this is not strongly typed because we don't want to exclude any new `numberDisplayHints`
  numberDisplayHint: checked.string().defaultValue('formatted'),
  dateDisplayHint: checked.string().optional(),
  rollupPropertyObjectTypeIds: checked.list().defaultValue([]),
  rollupProperty: checked.boolean().defaultValue(false),
  rollupPropertyErrorLevel: checked.symbol(RollupPropertyErrorLevels, 'RollupPropertyErrorLevel').optional(),
  // required if `rollupProperty` is true
  canBeResolvedWithLabelExpression: checked.boolean().defaultValue(false),
  description: checked.string().optional(),
  options: checked.list(SnowflakeOption).optional(),
  metaDefinition: checked.any(),
  // todo using `any()` here seems to make this node a POJO
  flpRestricted: checked.boolean()
}, 'SnowflakeProperty');
export const SnowflakeJoinTypes = {
  OBJECT_TO_OBJECT_ASSOCIATION: 'OBJECT_TO_OBJECT_ASSOCIATION',
  OBJECT_TO_LIST: 'OBJECT_TO_LIST',
  OBJECT_TO_OBJECT_UNIQUE_PROPERTY: 'OBJECT_TO_OBJECT_UNIQUE_PROPERTY',
  EVENT_AND_OBJECT_PRIMARY: 'EVENT_AND_OBJECT_PRIMARY',
  EVENT_AND_OBJECT_UNIQUE_PROPERTY: 'EVENT_AND_OBJECT_UNIQUE_PROPERTY',
  EVENT_AND_OBJECT_COORDINATE: 'EVENT_AND_OBJECT_COORDINATE',
  OBJECT_COORDINATE: 'OBJECT_COORDINATE'
};
const AssociationDefinition = checked.record({
  name: checked.string(),
  label: checked.string().optional(),
  hasAllAssociatedObjects: checked.boolean()
}, 'AssociationDefinition');
const ObjectToObjectAssociationJoin = checked.record({
  joinType: checked.string().always(SnowflakeJoinTypes.OBJECT_TO_OBJECT_ASSOCIATION),
  usedImplicitly: checked.boolean().defaultValue(false),
  source: checked.string(),
  target: checked.string(),
  combinedAssociationTypeId: checked.string().optional(),
  associationDefinition: AssociationDefinition
}, 'ObjectToObjectAssociationJoin');
const ObjectToListJoin = checked.record({
  joinType: checked.string().always(SnowflakeJoinTypes.OBJECT_TO_LIST),
  source: checked.string(),
  target: checked.string(),
  sourceProperty: checked.string(),
  targetProperty: checked.string()
}, 'ObjectToListJoin');
const ObjectToObjectUniquePropertyJoin = checked.record({
  joinType: checked.string().always(SnowflakeJoinTypes.OBJECT_TO_OBJECT_UNIQUE_PROPERTY),
  source: checked.string(),
  target: checked.string(),
  sourceProperty: checked.string(),
  targetProperty: checked.string()
}, 'ObjectToObjectUniquePropertyJoin');
const EventAndObjectPrimaryJoin = checked.record({
  joinType: checked.string().always(SnowflakeJoinTypes.EVENT_AND_OBJECT_PRIMARY),
  source: checked.string(),
  target: checked.string()
}, 'EventAndObjectPrimaryJoin');
const EventAndObjectUniquePropertyJoin = checked.record({
  joinType: checked.string().always(SnowflakeJoinTypes.EVENT_AND_OBJECT_UNIQUE_PROPERTY),
  source: checked.string(),
  target: checked.string(),
  sourceProperty: checked.string(),
  targetProperty: checked.string()
}, 'EventAndObjectUniquePropertyJoin');
const EventAndObjectCoordinateJoin = checked.record({
  joinType: checked.string().always(SnowflakeJoinTypes.EVENT_AND_OBJECT_COORDINATE),
  source: checked.string(),
  target: checked.string(),
  // (either one of sourceProperty or targetProperty must be present)
  sourceProperty: checked.string().optional(),
  targetProperty: checked.string().optional()
}, 'EventAndObjectCoordinateJoin');
const ObjectCoordinateJoin = checked.record({
  joinType: checked.string().always(SnowflakeJoinTypes.OBJECT_COORDINATE),
  joinMetadataSource: checked.string(),
  source: checked.string(),
  sourceProperty: checked.string().optional(),
  target: checked.string(),
  targetProperty: checked.string().optional()
}, 'ObjectCoordinateJoin');
const _SnowflakeJoinMetadata = checked.poly('joinType', {
  [SnowflakeJoinTypes.OBJECT_TO_OBJECT_ASSOCIATION]: ObjectToObjectAssociationJoin,
  [SnowflakeJoinTypes.OBJECT_TO_LIST]: ObjectToListJoin,
  [SnowflakeJoinTypes.OBJECT_TO_OBJECT_UNIQUE_PROPERTY]: ObjectToObjectUniquePropertyJoin,
  [SnowflakeJoinTypes.EVENT_AND_OBJECT_PRIMARY]: EventAndObjectPrimaryJoin,
  [SnowflakeJoinTypes.EVENT_AND_OBJECT_UNIQUE_PROPERTY]: EventAndObjectUniquePropertyJoin,
  [SnowflakeJoinTypes.EVENT_AND_OBJECT_COORDINATE]: EventAndObjectCoordinateJoin,
  [SnowflakeJoinTypes.OBJECT_COORDINATE]: ObjectCoordinateJoin
}, 'SnowflakeJoinMetadata');
export const SnowflakeJoinMetadata = _SnowflakeJoinMetadata;
export const SnowflakeSourceData = checked.record({
  // Map<tableName, SnowflakeTable>
  snowflakeTables: checked.map(SnowflakeTable),
  // Map<tableGroupName, SnowflakeTableGroup>
  snowflakeTableGroups: checked.map(SnowflakeTableGroup),
  // Map<tableName, List<associatedTableNames>>
  snowflakeJoins: checked.map(checked.list(checked.string()))
}, 'SnowflakeSourceData');
export const TableAndPropertyMeta = checked.record({
  // Map<tableName, SnowflakeTable>
  tables: checked.map(SnowflakeTable),
  // Map<tableName, Map<propertyName, SnowflakeProperty>>
  properties: checked.map(checked.map(SnowflakeProperty)),
  // Map<tableName, Map<propertyName, DatasetProperty>>
  datasetProperties: checked.map(checked.map(DatasetProperty)),
  datasetDefinition: RelationalDataset.optional()
}, 'TableAndPropertyMeta');
export const TableGroupSections = {
  CRM: 'crm',
  MARKETING: 'marketing',
  SALES: 'sales',
  SERVICE: 'service',
  CUSTOM_OBJECTS: 'custom-objects',
  OTHER: 'other',
  FORMS: 'forms',
  SELF_ASSOCIATION: 'self-association',
  APP_OBJECTS: 'app-object'
};
export const CustomObjectsGroup = 'custom-objects';

// keep in alphabetical order
export const TableGroupNames = {
  AD_INTERACTIONS_BY_CONTACT: 'ad-interactions-by-contact',
  AD_PERFORMANCE_METRICS: 'ad-performance-metrics',
  ADS: 'ads',
  APPOINTMENTS: 'appointments',
  BEHAVIORAL_EVENTS: 'behavioural-events',
  BLOG: 'blog',
  CAMPAIGN: 'campaign',
  CAMPAIGNS: 'campaigns',
  COMPANIES: 'companies',
  CONTACTS: 'contacts',
  CONVERSATIONS: 'conversations',
  COURSES: 'courses',
  CTA: 'ctas',
  CTA_ACTIVITIES: 'cta-event-digest',
  CTA_VIEW: 'cta-view',
  CALL: 'call',
  COMMERCE_PAYMENTS: 'Payments',
  DEAL_SPLITS: 'deal-splits',
  DEALS: 'deals',
  EMAIL_EVENTS_DIGEST: 'email-events-digest',
  FEEDBACK: 'feedback-submissions',
  FORM_ANALYTICS: 'form-analytics',
  FORM_FIELD_DATA: 'form-field-data',
  FORM_SUBMISSIONS: 'form-submissions',
  V_FORM_SUBMISSIONS: 'v-form-submissions',
  FORMS: 'forms',
  INVOICES: 'Invoices',
  LANDING_PAGES: 'landing-pages',
  LEADS: 'leads',
  LINE_ITEMS: 'line-items',
  LISTINGS: 'listings',
  LISTS: 'Lists',
  MARKETING_CAMPAIGN_INFLUENCES: 'marketing-campaign-influences',
  MARKETING_CAMPAIGNS: 'marketing_campaigns',
  MARKETING_EMAIL_EVENTS: 'marketing-email-events',
  MARKETING_EMAILS: 'marketing-email',
  MARKETING_EVENT_ATTENDANCE: 'marketing-event-attendance',
  MARKETING_EVENT_UE_DIGEST: 'marketing-event-ue-digest',
  MARKETING_EVENTS: 'marketing-events',
  MEDIA: 'media',
  MEETINGS: 'meetings',
  QUOTES: 'Quotes',
  SALES_ACTIVITIES: 'sales-activities',
  SALES_EMAIL: 'Email',
  // todo: change to "sales-email"
  SEQUENCE: 'sequence',
  SERVICES: 'Services',
  SOCIAL_CHANNELS: 'social-channels',
  SOCIAL_POSTS: 'social-posts',
  SUBSCRIPTION: 'Subscriptions',
  TICKETS: 'tickets',
  USERS: 'users',
  VIDEO: 'video',
  VIDMILE_VIDEOS: 'vidmile-videos',
  WEB_ACTIVITIES: 'web-activities',
  WEBSITE_PAGES: 'website-pages',
  WORKFLOWS: 'Workflow'
};
export const TABLES = {
  CONTACT: 'CONTACT',
  FORM: 'FORM',
  FORM_SUBMISSION: 'FORM_SUBMISSION',
  LANDING_PAGE: 'LANDING_PAGE',
  MARKETING_EVENT: 'MARKETING_EVENT',
  PAGE_VIEW: 'e_visited_page',
  MARKETING_EVENT_ATTENDANCE: 'MARKETING_EVENT_ATTENDANCE'
};

/**
 * todo remove this when we no longer have "empty snowflake tables"
 * see https://git.hubteam.com/HubSpot/report-builder-issues/issues/348
 */
export const isEmptySnowflakeTable = snowflakeTable => {
  return snowflakeTable === undefined || snowflakeTable.name === undefined;
};