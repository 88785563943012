import I18n from 'I18n';
import once from 'transmute/once';
import { BOOLEAN } from 'customer-data-objects/property/PropertyTypes';
// @ts-expect-error untyped
import * as ObjectSegConstants from '../../objectSeg/constants/constants';
import MissingField from '../../../filterQueryFormat/MissingField';
export default once(() => MissingField({
  type: BOOLEAN,
  name: ObjectSegConstants.DEPRECATED_FILTER,
  label: I18n.text('customerDataFilters.FilterOperatorLabelTranslations.DEFAULT.DeprecatedFilter')
}));