export const compareDataSourceIds = (a, b) => {
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }
  const [aMetaType, aObjectType] = a.split('-');
  const [bMetaType, bObjectType] = b.split('-');
  return +aMetaType === +bMetaType ? +aObjectType - +bObjectType : +aMetaType - +bMetaType;
};