'use es6';

import { createSelector } from 'reselect';
import { selectIsStarterUpgradeEligible, selectIsProUpgradeEligible } from 'dashboard-lib/public/userInfo/scopes';
export const UPGRADE_POINT_LOCATIONS = {
  DASHBOARD: 'DASHBOARD',
  DASHBOARD_VIEW: 'DASHBOARD_VIEW',
  DASHBOARD_EDIT: 'DASHBOARD_EDIT',
  DASHBOARD_CREATE: 'DASHBOARD_CREATE',
  DASHBOARD_LIST: 'DASHBOARD_LIST',
  DASHBOARD_LIBRARY: 'DASHBOARD_LIBRARY',
  REPORT_LIST_VIEW: 'REPORT_LIST_VIEW',
  REPORT_LIST_EDIT: 'REPORT_LIST_EDIT',
  REPORT_LIST_CREATE: 'REPORT_LIST_CREATE',
  REPORT_LIBRARY_CREATE: 'REPORT_LIBRARY_CREATE',
  DISCOVER: 'DISCOVER'
};
export const REPORT_BUILDER_UPGRADE_KEYS = {
  [UPGRADE_POINT_LOCATIONS.DASHBOARD_VIEW]: 'report-builder-reports-dashboard-view-report',
  [UPGRADE_POINT_LOCATIONS.DASHBOARD_EDIT]: 'report-builder-reports-dashboard-edit-report',
  [UPGRADE_POINT_LOCATIONS.DASHBOARD_CREATE]: 'report-builder-reports-dashboard-create-report',
  [UPGRADE_POINT_LOCATIONS.REPORT_LIST_VIEW]: 'report-builder-report-list-view-report',
  [UPGRADE_POINT_LOCATIONS.REPORT_LIST_EDIT]: 'report-builder-report-list-edit-report',
  [UPGRADE_POINT_LOCATIONS.REPORT_LIST_CREATE]: 'report-builder-report-list-create-report',
  [UPGRADE_POINT_LOCATIONS.REPORT_LIBRARY_CREATE]: 'report-builder-report-library-create-report'
};
export const REPORT_BUILDER_UPGRADE_LOCATIONS = Object.keys(REPORT_BUILDER_UPGRADE_KEYS);
export const REPORT_LIMIT_UPGRADE_KEYS = {
  [UPGRADE_POINT_LOCATIONS.DASHBOARD_CREATE]: 'dashboard-custom-reports-reports-dashboard-create-report',
  [UPGRADE_POINT_LOCATIONS.REPORT_LIST_CREATE]: 'dashboard-custom-reports-report-list-create-report',
  [UPGRADE_POINT_LOCATIONS.REPORT_LIBRARY_CREATE]: 'dashboard-custom-reports-report-library-create-report'
};
export const REPORT_LIMIT_UPGRADE_LOCATIONS = Object.keys(REPORT_LIMIT_UPGRADE_KEYS);
const DASHBOARD_LIMIT_UPGRADE_KEYS = {
  [UPGRADE_POINT_LOCATIONS.DASHBOARD]: {
    starterKey: 'dashboard-suite-starter-reports-dashboard-create-dashboard',
    proKey: 'dashboard-pro-reports-dashboard-create-dashboard',
    limitIncreaseKey: 'dashboard-custom-reports-reports-dashboard-create-dashboard'
  },
  [UPGRADE_POINT_LOCATIONS.DASHBOARD_LIST]: {
    starterKey: 'dashboard-suite-starter-dashboard-list-create-dashboard',
    proKey: 'dashboard-pro-dashboard-list-create-dashboard',
    limitIncreaseKey: 'dashboard-custom-reports-dashboard-list-create-dashboard'
  },
  [UPGRADE_POINT_LOCATIONS.DASHBOARD_LIBRARY]: {
    starterKey: 'dashboard-suite-starter-dashboard-library-create-dashboard',
    proKey: 'dashboard-pro-dashboard-library-create-dashboard',
    limitIncreaseKey: 'dashboard-custom-reports-dashboard-library-create-dashboard'
  },
  [UPGRADE_POINT_LOCATIONS.DISCOVER]: {
    starterKey: 'dashboard-suite-starter-discover-create-dashboard',
    proKey: 'dashboard-pro-discover-create-dashboard',
    limitIncreaseKey: 'dashboard-custom-reports-discover-create-dashboard'
  }
};
export const DASHBOARD_LIMIT_UPGRADE_LOCATIONS = Object.keys(DASHBOARD_LIMIT_UPGRADE_KEYS);
export const selectDashboardLimitUpgradeKey = upgradeLocation => createSelector([selectIsStarterUpgradeEligible, selectIsProUpgradeEligible], (isStarterUpgradeEligible, isProUpgradeEligible) => {
  const {
    starterKey,
    proKey,
    limitIncreaseKey
  } = DASHBOARD_LIMIT_UPGRADE_KEYS[upgradeLocation];
  return isStarterUpgradeEligible ? starterKey : isProUpgradeEligible ? proKey : limitIncreaseKey;
});
export const selectDashboardLimitUpgradeProduct = createSelector([selectIsStarterUpgradeEligible, selectIsProUpgradeEligible], (isStarterUpgradeEligible, isProUpgradeEligible) => isStarterUpgradeEligible ? 'suite-starter' : isProUpgradeEligible ? 'marketing-pro' : 'reporting');