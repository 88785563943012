'use es6';

import getIn from 'transmute/getIn';
import { getId, getReportDashboardInfo, getTemplateKey } from '../data/schemas/reportSchema';
import { getOrigin, getReportDataTypesForTracking, getReportVisualType, isDatasetsReport } from '../lib/report-logic';
export const getDefaultReportActionEventProperties = report => {
  return {
    assetType: 'report',
    chartType: getReportVisualType(report),
    dataTypes: getReportDataTypesForTracking(report),
    isDatasetsReport: isDatasetsReport(report),
    template: getTemplateKey(report),
    reportId: getId(report),
    dashboardId: getIn(['dashboardId'], report),
    dashboardReportIds: getReportDashboardInfo(report).map(info => info.get('id')).toJS()
  };
};

/** Only use the following when the report has already been saved (not for create flows) */
export const getDefaultSavedReportActionEventProperties = report => Object.assign({
  dashboardCount: getReportDashboardInfo(report).length,
  origin: getOrigin(report)
}, getDefaultReportActionEventProperties(report));