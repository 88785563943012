import { Map as ImmutableMap } from 'immutable';
import { COLOR, colors } from './colors';
export const ColorSets = {
  SOURCES: 'sources',
  FEEDBACK: 'feedback',
  COMPANY_SOURCES: 'company-sources'
};
export const colorAssignmentSets = ImmutableMap({
  [ColorSets.SOURCES]: ImmutableMap({
    organic: colors.get(COLOR.GREEN),
    referrals: colors.get(COLOR.YELLOW),
    social: colors.get(COLOR.AQUA),
    email: colors.get(COLOR.ORANGE),
    paid: colors.get(COLOR.PINK),
    'paid-social': colors.get(COLOR.DARK_PINK),
    direct: colors.get(COLOR.BLUE),
    other: colors.get(COLOR.DARK_ORANGE),
    offline: colors.get(COLOR.PURPLE)
  }),
  [ColorSets.FEEDBACK]: ImmutableMap({
    promoters: colors.get(COLOR.GREEN),
    passives: colors.get(COLOR.ORANGE),
    detractors: '#ea90b1',
    PROMOTER: colors.get(COLOR.GREEN),
    PASSIVE: colors.get(COLOR.ORANGE),
    DETRACTOR: '#ea90b1',
    promotersCount: colors.get(COLOR.GREEN),
    passivesCount: colors.get(COLOR.ORANGE),
    detractorsCount: '#ea90b1'
  }),
  [ColorSets.COMPANY_SOURCES]: ImmutableMap({
    ORGANIC_SEARCH: colors.get(COLOR.GREEN),
    PAID_SEARCH: colors.get(COLOR.PINK),
    EMAIL_MARKETING: colors.get(COLOR.ORANGE),
    SOCIAL_MEDIA: colors.get(COLOR.AQUA),
    REFERRALS: colors.get(COLOR.YELLOW),
    OTHER_CAMPAIGNS: colors.get(COLOR.DARK_ORANGE),
    DIRECT_TRAFFIC: colors.get(COLOR.BLUE),
    PAID_SOCIAL: colors.get(COLOR.DARK_PINK),
    OFFLINE: colors.get(COLOR.PURPLE)
  })
});