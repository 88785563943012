'use es6';

import { AssetPermission } from '../../../../public/permission/asset-permission';
import { hasSufficientPermission } from '../../../../public/permission/compute-permission';
import { getAndCheck } from '../../common/get-and-check-param';
import { getAllProblems } from '../../common/get-problems-no-check';
import { createProblem } from '../../common/problem';
import { DASHBOARD_IS_LOADING, MAX_USER_ASSET_PERMISSION, IS_READ_ONLY_DEMO, USER_DASHBOARD_PERMISSION } from '../../params/all-params';
export const LOADING = createProblem('LOADING', params => getAndCheck(DASHBOARD_IS_LOADING, params));
export const DASHBOARD_PERMISSION_DENIED = createProblem('DASHBOARD_PERMISSION_DENIED', params => {
  const dashboardPermission = getAndCheck(USER_DASHBOARD_PERMISSION, params);
  return !hasSufficientPermission(AssetPermission.EDIT, dashboardPermission);
});
export const IS_READ_ONLY_DEMO_PROBLEM = createProblem('IS_READ_ONLY_DEMO_PROBLEM', params => getAndCheck(IS_READ_ONLY_DEMO, params));
export const USER_SETTINGS_LACK_EDIT = createProblem('USER_SETTINGS_LACK_EDIT', params => {
  const maximumPermission = getAndCheck(MAX_USER_ASSET_PERMISSION, params);
  return !hasSufficientPermission(AssetPermission.EDIT, maximumPermission);
});
export const rankedProblems = [LOADING, USER_SETTINGS_LACK_EDIT, DASHBOARD_PERMISSION_DENIED, IS_READ_ONLY_DEMO_PROBLEM];
export const getAll = params => getAllProblems(params, rankedProblems);