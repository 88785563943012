'use es6';

import I18n from 'I18n';
import formatName from 'I18n/utils/formatName';
export const idProperty = 'vid';
export const hydrateInputs = [idProperty, 'firstname', 'lastname', 'email'];
export const unknownContactLabel = id => I18n.text('reporting-data.references.contact.unknown', {
  id
});
export const hydrateFn = (extractedContactInfo, fallbackId) => extractedContactInfo && formatName({
  firstName: extractedContactInfo.firstname,
  lastName: extractedContactInfo.lastname,
  email: extractedContactInfo.email
}) || unknownContactLabel(extractedContactInfo[idProperty] || fallbackId);