'use es6';

import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { createSelector } from 'reselect';
import compose from 'transmute/compose';
import get from 'transmute/get';
import reduce from 'transmute/reduce';
import valueSeq from 'transmute/valueSeq';
import { selectResource } from 'dashboard-lib/public/request/redux-request';
import { namespace } from 'dashboard-lib/public/users/users-action';
import { toValueWithDefault } from 'dashboard-lib/public/request/resource-state';
import { selectTeamValue } from 'dashboard-lib/public/team/team-selector';
import { getSecondaryUserIds, getUserIds } from 'dashboard-lib/public/team/team-schema';
export const selectUsersRoot = selectResource(namespace);
export const selectUsersValue = compose(toValueWithDefault(ImmutableMap()), selectUsersRoot);
export const selectUser = userId => compose(get(userId), selectUsersValue);
export const selectUserSeq = createSelector([selectUsersValue], valueSeq);
export const makeUserSetInTeamIdListSelector = () => createSelector(selectTeamValue, (_, teamIdSet) => teamIdSet, (teams, teamIdSet) => reduce(ImmutableSet(), (userIdSet, teamId) => {
  const team = get(teamId, teams);
  const memberUserIdList = getUserIds(team);
  const secondaryMemberUserIdList = getSecondaryUserIds(team);
  return userIdSet.concat(memberUserIdList, secondaryMemberUserIdList);
}, teamIdSet));