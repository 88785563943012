'use es6';

import I18n from 'I18n';
import { fromJS, List, Map as ImmutableMap, OrderedMap } from 'immutable';
import { DEALS } from '../../constants/dataTypes';
import { DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES } from '../../constants/objectCoordinates';
import chunk from '../../lib/async/chunk';
import { Promise } from '../../lib/promise';
import * as http from '../../request/http';
import { makeOption } from '../Option';
const getDeals = (deals = List()) => chunk(group => http.post('inbounddb-objects/v1/preview', {
  data: {
    [DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(DEALS)]: group.toArray()
  }
}).then(fromJS), responses => responses.reduce((references, response) => {
  const chunkReferences = response.getIn([DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(DEALS)]);
  return references.concat(chunkReferences);
}, OrderedMap({})), deals);
export const generateDealLabel = (dealInfo, key) => {
  const name = dealInfo.get('dealname') || dealInfo.getIn(['properties', 'dealname', 'value'], null);
  return name || I18n.text('reporting-data.references.deal.unknown', {
    id: key
  });
};
export default (ids => {
  if (ids.isEmpty()) {
    return Promise.resolve(ImmutableMap());
  }
  const sanitized = ids.reduce((memo, id) => {
    const parsed = Number(id);
    return parsed ? memo.set(String(parsed), id) : memo;
  }, ImmutableMap());
  return getDeals(sanitized.keySeq().toList()).then(deals => {
    return fromJS(deals).reduce((options, dealInfo = ImmutableMap(), dealId) => {
      return options.set(String(dealId), makeOption(sanitized.get(dealId, dealId), generateDealLabel(dealInfo, dealId)));
    }, ImmutableMap());
  });
});