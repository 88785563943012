'use es6';

import { fromJS } from 'immutable';
import { addFavoriteDashboard, fetchFavoriteDashboards, removeFavoriteDashboard } from '../data/FavoritesApi';
import RequestStatusType from '../constants/RequestStatusType';
export const favoritesNamespace = 'favorites';
const {
  UNINITIALIZED,
  PENDING,
  SUCCEEDED,
  FAILED
} = RequestStatusType;

// Action types
const FETCH_FAVORITES_PENDING = 'FETCH_FAVORITES_PENDING';
const FETCH_FAVORITES_SUCCEEDED = 'FETCH_FAVORITES_SUCCEEDED';
const FETCH_FAVORITES_FAILED = 'FETCH_FAVORITES_FAILED';
const ADD_FAVORITE_PENDING = 'ADD_FAVORITE_PENDING';
const ADD_FAVORITE_SUCCEEDED = 'ADD_FAVORITE_SUCCEEDED';
const ADD_FAVORITE_FAILED = 'ADD_FAVORITE_FAILED';
const REMOVE_FAVORITE_PENDING = 'REMOVE_FAVORITE_PENDING';
const REMOVE_FAVORITE_SUCCEEDED = 'REMOVE_FAVORITE_SUCCEEDED';
const REMOVE_FAVORITE_FAILED = 'REMOVE_FAVORITE_FAILED';

// Actions
export const fetchFavorites = () => dispatch => {
  dispatch({
    type: FETCH_FAVORITES_PENDING
  });
  return fetchFavoriteDashboards().then(response => {
    dispatch({
      type: FETCH_FAVORITES_SUCCEEDED,
      payload: response
    });
  }, error => {
    console.error(error);
    dispatch({
      type: FETCH_FAVORITES_FAILED,
      payload: error
    });
  });
};
export const addFavorite = dashboardId => dispatch => {
  dispatch({
    type: ADD_FAVORITE_PENDING
  });
  return addFavoriteDashboard(dashboardId).then(() => {
    dispatch({
      type: ADD_FAVORITE_SUCCEEDED,
      payload: dashboardId
    });
  }, error => {
    console.error(error);
    dispatch({
      type: ADD_FAVORITE_FAILED,
      payload: error
    });
    throw error;
  });
};
export const removeFavorite = dashboardId => dispatch => {
  dispatch({
    type: REMOVE_FAVORITE_PENDING
  });
  return removeFavoriteDashboard(dashboardId).then(() => {
    dispatch({
      type: REMOVE_FAVORITE_SUCCEEDED,
      payload: dashboardId
    });
  }, error => {
    console.error(error);
    dispatch({
      type: REMOVE_FAVORITE_FAILED,
      payload: error
    });
    throw error;
  });
};

// Reducers
const initialState = fromJS({
  status: {
    fetchFavorites: UNINITIALIZED,
    addFavorite: UNINITIALIZED,
    removeFavorite: UNINITIALIZED
  },
  favoriteDashboards: undefined
});
export const favoritesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FAVORITES_PENDING:
      return state.setIn(['status', 'fetchFavorites'], PENDING);
    case FETCH_FAVORITES_SUCCEEDED:
      return state.setIn(['status', 'fetchFavorites'], SUCCEEDED).set('favoriteDashboards', action.payload.map(favorite => favorite.get('objectId')));
    case FETCH_FAVORITES_FAILED:
      return state.setIn(['status', 'fetchFavorites'], FAILED);
    case ADD_FAVORITE_PENDING:
      return state.setIn(['status', 'addFavorite'], PENDING);
    case ADD_FAVORITE_SUCCEEDED:
      return state.setIn(['status', 'addFavorite'], SUCCEEDED).update('favoriteDashboards', favorites => favorites.push(action.payload));
    case ADD_FAVORITE_FAILED:
      return state.setIn(['status', 'addFavorite'], FAILED);
    case REMOVE_FAVORITE_PENDING:
      return state.setIn(['status', 'removeFavorite'], PENDING);
    case REMOVE_FAVORITE_SUCCEEDED:
      return state.setIn(['status', 'removeFavorite'], SUCCEEDED).update('favoriteDashboards', favorites => favorites.delete(favorites.indexOf(action.payload)));
    case REMOVE_FAVORITE_FAILED:
      return state.setIn(['status', 'removeFavorite'], FAILED);
    default:
      return state;
  }
};

// Selectors
export const selectFavoriteDashboards = state => state[favoritesNamespace].get('favoriteDashboards');
export const selectFavoriteDashboardsStatus = state => state[favoritesNamespace].getIn(['status', 'fetchFavorites']);