'use es6';

import get from 'transmute/get';
import { handleActions } from 'redux-actions';
import { createAction } from 'redux-actions';
export const FORCE_RENDER_COUNT_ROOT = 'forceRenderCount';
export const INCREMENT_FORCE_RENDER_COUNT = createAction('INCREMENT_FORCE_RENDER_COUNT');
export const forceRenderCountReducer = handleActions({
  [INCREMENT_FORCE_RENDER_COUNT](state) {
    return ++state;
  }
}, 0);
export const selectForceRenderCount = get(FORCE_RENDER_COUNT_ROOT);