module.exports = {
  "/": {},
  "/view": {},
  "/sales": {},
  "/marketing": {},
  "/service": {},
  "/web-analytics": {},
  "/view/:dashboardId": {
    "success": {
      "View dashboard page loaded": [
        "HEADER_LOADED",
        "SUB_HEADER_LOADED",
        "WIDGET_GRID_WRAPPER_LOADED"
      ],
      "View dashboard page empty state loaded": [
        "DASHBOARD_EMPTY_STATE"
      ],
      "View dashboard page not found state loaded": [
        "DASHBOARD_NOT_FOUND"
      ],
      "Forbidden access dashboard state loaded": [
        "FORBIDDEN_ACCESS_DASHBOARD"
      ]
    },
    "error": [
      "DASHBOARD_LOAD_FAILED"
    ]
  },
  "/view/:dashboardId/:reportId": {
    "success": {
      "Single Report Viewer component loaded": [
        "SRV_LOADED"
      ]
    },
    "error": [
      "DASHBOARD_LOAD_FAILED"
    ]
  },
  "/export/dashboard/:dashboardId": {
    "success": {
      "Export page loaded": [
        "EXPORT_PAGE_LOADED"
      ]
    },
    "error": [
      "EXPORT_PAGE_LOAD_FAILED"
    ]
  },
  "/permission-required": {
    "success": {
      "Permission required page loaded": [
        "PERMISSION_REQUIRED_PAGE_LOADED"
      ]
    }
  },
  "/email-unsubscribe": {
    "name": "emailUnsubscribe",
    "success": {
      "Email unsubscribe page loaded": [
        "EMAIL_UNSUBSCRIBE_PAGE_LOADED"
      ]
    }
  }
};