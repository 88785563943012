'use es6';

import { Iterable, List, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { reduce } from '../../lib/async';
import { MY_USER_ID, MY_OWNER_ID, MY_TEAM_ID, CURRENT_TIME, CLOSEDWON_STAGES, SELECT_TOP } from '../../constants/magicTypes';
import userId from './userId';
import ownerId from './ownerId';
import teamId from './teamId';
import closedWonStageIds from './closedWonStageIds';
import currentTime from './currentTime';
import selectTopFilters from './selectTopFilters';
const mutators = {
  [MY_USER_ID]: userId,
  [MY_OWNER_ID]: ownerId,
  [MY_TEAM_ID]: teamId,
  [CLOSEDWON_STAGES]: closedWonStageIds,
  [CURRENT_TIME]: currentTime,
  [SELECT_TOP]: selectTopFilters
};
const getPaths = (filters, path = [], found = ImmutableMap()) => filters.reduce((memo, filter, key) => Iterable.isIterable(filter) ? getPaths(filter, [...path, key], memo) : mutators.hasOwnProperty(filter) ? memo.update(filter, (paths = ImmutableSet()) => paths.add([...path, key])) : memo, found);
export default (config => {
  const filters = config.get('filters') || new List();
  const paths = getPaths(filters);
  return paths.isEmpty() ? config : reduce(paths, (mutated, value, key) => mutators[key](mutated, value), config);
});