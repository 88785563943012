const START = 'START';
const CREATED = 'CREATED';
const PULLED = 'PULLED';
const INCREASED = 'INCREASED';
const DECREASED = 'DECREASED';
const PUSHED = 'PUSHED';
const LOST = 'LOST';
const WON = 'WON';
const END = 'END';
const BREAKDOWNS = {
  START,
  CREATED,
  PULLED,
  INCREASED,
  DECREASED,
  PUSHED,
  LOST,
  WON,
  END
};
export const DEFAULT_X_FIELD = 'dealBreakdown';
export const DEFAULT_Y_FIELD = 'SUM|amount_in_home_currency';
export const AGGREGATION_PROPS = [DEFAULT_X_FIELD, DEFAULT_Y_FIELD, 'count', 'netPipelineImpact'];
export const WATERFALL_DRILLDOWN_FIELDS = {
  [BREAKDOWNS.PULLED]: ['initialCloseDate', 'finalCloseDate', 'createdate'],
  [BREAKDOWNS.INCREASED]: ['initialAmountInHomeCurrency', 'finalAmountInHomeCurrency', 'amountInHomeCurrencyChange', 'createdate'],
  [BREAKDOWNS.DECREASED]: ['initialAmountInHomeCurrency', 'finalAmountInHomeCurrency', 'amountInHomeCurrencyChange', 'createdate'],
  [BREAKDOWNS.PUSHED]: ['initialCloseDate', 'finalCloseDate', 'createdate'],
  [BREAKDOWNS.LOST]: ['finalPipelineStage', 'closedLostReason', 'createdate'],
  [BREAKDOWNS.WON]: ['finalPipelineStage', 'closedWonReason', 'createdate']
};