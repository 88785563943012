'use es6';

import { strictEquals } from 'dashboard-lib/private/js-util';
export const AssignmentMode = {
  EVERYONE: 'EVERYONE',
  SPECIFIC: 'SPECIFIC',
  PRIVATE: 'PRIVATE'
};
export const isEveryone = strictEquals(AssignmentMode.EVERYONE);
export const isSpecific = strictEquals(AssignmentMode.SPECIFIC);
export const isPrivate = strictEquals(AssignmentMode.PRIVATE);
export const AssignmentModeKeys = Object.keys(AssignmentMode);