'use es6';

import I18n from 'I18n';
import { Map as ImmutableMap } from 'immutable';
import { bulkGet } from './teams';
import { makeOption } from '../Option';
import { DEFAULT_NULL_VALUES } from '../../constants/defaultNullValues';
export const generateTeamLabel = (teamInfo, id, {
  isRelational
} = {}) => {
  if (id === DEFAULT_NULL_VALUES.ENUMERATION || Number(id) === 0) {
    return isRelational ? I18n.text('reporting-data.missing.value') : I18n.text('reporting-data.missing.unassigned');
  } else if (teamInfo.has('label')) {
    return teamInfo.get('label');
  } else if (teamInfo.has('name')) {
    return teamInfo.get('name');
  }
  return I18n.text('reporting-data.missing.unknown.team', {
    id
  });
};
export default (ids => bulkGet(ids).then(remote => ids.reduce((options, key) => {
  const numberKey = Number(key);
  return options.set(numberKey, makeOption(key, generateTeamLabel(ImmutableMap(remote.has(numberKey) ? {
    name: remote.getIn([numberKey, 'label'])
  } : {}), key)));
}, ImmutableMap())));