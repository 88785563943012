'use es6';

import fromRefinementFilter from './fromRefinementFilter';
const tryAddRefinement = (operator, field, filter) => {
  const refinement = fromRefinementFilter(field, filter);
  return refinement ? operator.set('refinement', refinement) : operator;
};
const fromEventOperation = (Operator, field, filter) => {
  const operator = Operator.of(field);
  return tryAddRefinement(operator, field, filter);
};
export { fromEventOperation };