'use es6';

import { List } from 'immutable';
import usePollingQuery from './usePollingQuery';
import { TEAM_HIERARCHY_FIELD_NAME, FETCH_TEAM_HIERARCHY } from '../requests/teams';
export const useTeamHierarchy = () => {
  const {
    data,
    error,
    loading
  } = usePollingQuery(FETCH_TEAM_HIERARCHY);
  return {
    data: data ? data[TEAM_HIERARCHY_FIELD_NAME] : List(),
    error,
    loading
  };
};