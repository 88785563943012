'use es6';

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { selectDashboardPermission } from 'dashboard-lib/public/permission/permission-selector';
import { getId } from 'dashboard-lib/public/dashboard/dashboard-schema';

/**
 * Returns a decorated component which renders the original component
 * with an additional prop: `dashboardPermission`.
 */
export const withDashboardPermission = Subject => {
  const mapStateToProps = (state, {
    dashboardId,
    dashboard
  }) => {
    const id = dashboardId || getId(dashboard);
    return {
      dashboardPermission: selectDashboardPermission(id)(state)
    };
  };
  const WithPermission = connect(mapStateToProps)(Subject);
  WithPermission.propTypes = {
    dashboardId: PropTypes.number,
    dashboard: ImmutablePropTypes.map
  };
  return WithPermission;
};