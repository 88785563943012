'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { getTotalMeasureAlias, isTotalAlias } from 'reporting-visualizations/visualizations/crossTab/dataHelpers/totals';
export const isTotalMeasure = measure => isTotalAlias(measure.alias);
const toFixedTotalMeasure = (measure, fixedToDimensions = List()) => {
  const fixed = fixedToDimensions.map(column => column.alias);
  return measure.set('alias', getTotalMeasureAlias(measure.alias, fixed)).set('fixedMeasure', true).set('fixed', fixed);
};
const toGrandTotalMeasure = measure => {
  return toFixedTotalMeasure(measure);
};
export const getYTotalColumn = report => {
  const {
    columns: reportColumns,
    visual: {
      encodings: {
        x: xEncoding,
        y,
        y_multi: yMultiEncoding,
        color: colorEncoding
      }
    }
  } = report;
  const usesMultipleMeasures = yMultiEncoding && yMultiEncoding.size > 1;
  if (usesMultipleMeasures) {
    return undefined;
  }

  /**
   * The `y` case handles older report that still use `y`.
   */
  const yEncodingToFixate = yMultiEncoding && yMultiEncoding.first() || y;
  if (!yEncodingToFixate) {
    return undefined;
  }
  const yColumn = reportColumns.get(yEncodingToFixate.column);
  if (!xEncoding && colorEncoding) {
    return toGrandTotalMeasure(yColumn);
  } else if (xEncoding && colorEncoding) {
    const xColumn = reportColumns.get(xEncoding.column);
    return toFixedTotalMeasure(yColumn, List.of(xColumn));
  } else {
    return undefined;
  }
};
export const applyYTotals = report => {
  const totalColumn = getYTotalColumn(report);
  if (!totalColumn) {
    return report;
  }
  const newColumnsMap = ImmutableMap({
    [totalColumn.alias]: totalColumn
  });
  const nextColumns = report.columns.merge(newColumnsMap);
  return report.set('columns', nextColumns);
};
export const getXTotalColumn = report => {
  const {
    columns: reportColumns,
    visual: {
      encodings: {
        y: yEncoding,
        x,
        x_multi: xMultiEncoding,
        color: colorEncoding
      }
    }
  } = report;
  const usesMultipleMeasures = xMultiEncoding && xMultiEncoding.size > 1;
  if (usesMultipleMeasures) {
    return undefined;
  }

  /**
   * The `x` case handles older report that still use `x`.
   */
  const xEncodingToFixate = xMultiEncoding && xMultiEncoding.first() || x;
  if (!xEncodingToFixate) {
    return undefined;
  }
  const xColumn = reportColumns.get(xEncodingToFixate.column);
  if (!yEncoding && colorEncoding) {
    return toGrandTotalMeasure(xColumn);
  } else if (yEncoding && colorEncoding) {
    const yColumn = reportColumns.get(yEncoding.column);
    return toFixedTotalMeasure(xColumn, List.of(yColumn));
  } else {
    return undefined;
  }
};
export const applyXTotals = report => {
  const totalColumn = getXTotalColumn(report);
  if (!totalColumn) {
    return report;
  }
  const newColumnsMap = ImmutableMap({
    [totalColumn.alias]: totalColumn
  });
  const nextColumns = report.columns.merge(newColumnsMap);
  return report.set('columns', nextColumns);
};