'use es6';

import http from 'hub-http/clients/apiClient';
import { registerMutation, registerQuery } from 'data-fetching-client';
import fromJS from 'transmute/fromJS';
const SEND_ASSET_BASE_URL = 'dashboard/v1/email/dashboard';
const SCHEDULE_ASSET_BASE_URL = 'dashboard/v1/report-emails';

// Mutation/Query field names (for caching)
const SEND_ASSET_NOW_FIELD_NAME = 'sendAssetNow';
const CREATE_ASSET_SCHEDULE_FIELD_NAME = 'createAssetSchedule';
const EDIT_ASSET_SCHEDULE_FIELD_NAME = 'editAssetSchedule';
const DELETE_ASSET_SCHEDULE_FIELD_NAME = 'deleteAssetSchedule';
export const SHARE_ASSET_SCHEDULES_FIELD_NAME = 'emailSchedules';
export const CONNECTED_EMAIL_SENDING_DOMAINS_FIELD_NAME = 'connectedEmailSendingDomains';
export const REPORT_OBJECT_TYPE = 'REPORT';
export const DASHBOARD_OBJECT_TYPE = 'DASHBOARD';
const sendAssetNow = payload => {
  return http.post(SEND_ASSET_BASE_URL, {
    data: Object.assign({}, payload)
  });
};
const createAssetSchedule = ({
  deliveryType,
  objectType,
  objectId,
  subject,
  message,
  nickname,
  ownerId,
  exportType,
  queryString,
  reportsPerPage,
  recipientUserIds,
  scheduleFrequency,
  scheduledOnDay,
  scheduledOnDays,
  scheduledAtHour,
  isUtc,
  recipientDestination,
  filterId
}) => {
  return http.post(`${SCHEDULE_ASSET_BASE_URL}/schedule`, {
    data: {
      deliveryType,
      objectType,
      objectId,
      subject,
      message,
      nickname,
      ownerId,
      exportType,
      queryString,
      reportsPerPage,
      recipientUserIds,
      scheduleFrequency,
      scheduledOnDay,
      scheduledOnDays,
      scheduledAtHour,
      isUtc,
      recipientDestination,
      filterId
    }
  });
};
const editAssetSchedule = ({
  deliveryType,
  scheduledEmailId,
  objectType,
  objectId,
  subject,
  message,
  nickname,
  ownerId,
  exportType,
  queryString,
  reportsPerPage,
  recipientUserIds,
  scheduleFrequency,
  scheduledOnDay,
  scheduledOnDays,
  scheduledAtHour,
  isUtc,
  recipientDestination,
  filterId
}) => {
  return http.put(`${SCHEDULE_ASSET_BASE_URL}/${scheduledEmailId}`, {
    data: {
      deliveryType,
      objectType,
      objectId,
      subject,
      message,
      nickname,
      ownerId,
      exportType,
      queryString,
      reportsPerPage,
      recipientUserIds,
      scheduleFrequency,
      scheduledOnDay,
      scheduledOnDays,
      scheduledAtHour,
      isUtc,
      recipientDestination,
      filterId
    }
  });
};
const deleteAssetSchedule = ({
  scheduleId
}) => {
  return http.delete(`${SCHEDULE_ASSET_BASE_URL}/${scheduleId}`);
};
const getShareAssetSchedules = payload => {
  return http.get(`dashboard/v1/report-emails`, {
    query: Object.assign({}, payload)
  });
};
const getConnectedEmailSendingDomains = () => {
  return http.get(`email-mta-sending/v1/auth-domains/connected-email-sending-domains`);
};

// @objectType {String} Object type to email/export, can be either "REPORT" or "DASHBOARD".
// @objectId {Number} Id of the asset to email/export.
// @subject {String} Subject of email.
// @message {String} Message/body of email.
// @exportType {String} File type of export, can be either "PNG", "CSV", "XLS", or "XLSX".
// @reportsPerPage {Number} Number of reports to show per page
// @recipientUserIds {Array} List of user IDs that the email will be sent to.
export const SEND_ASSET_NOW = registerMutation({
  fieldName: SEND_ASSET_NOW_FIELD_NAME,
  args: ['deliveryType', 'objectType', 'objectId', 'subject', 'message', 'queryString', 'exportType', 'reportsPerPage', 'recipientUserIds', 'externalRecipientEmails', 'recipientDestination', 'filterId'],
  fetcher(payload) {
    return sendAssetNow(payload);
  }
});

// @objectType {String} Object type to email/export, can be either "REPORT" or "DASHBOARD".
// @objectId {Number} Id of the asset to email/export.
// @subject {String} Subject of email.
// @message {String} Message/body of email.
// @nickname {String} Nickname of the scheduled email.
// @ownerId {String} Id of the owner of the asset to email.
// @exportType {String} File type of export, can be either "PNG", "CSV", "XLS", or "XLSX".
// @queryString {String} Query string that contains ad hoc filters that will be applied to the asset.
// @reportsPerPage {Number} Number of reports to show per page
// @recipientUserIds {Array} List of user IDs that the email will be sent to.
// @scheduleFrequency {String} The frequency in which an email should be sent.
// @scheduledOnDay {Number} The day in which an email should be sent.
// @scheduledAtHour {Number} The hour of the day in which an email should be sent.
// @isUtc {Boolean} If the email should be sent using UTC time zone.
export const CREATE_ASSET_SCHEDULE = registerMutation({
  fieldName: CREATE_ASSET_SCHEDULE_FIELD_NAME,
  args: ['deliveryType', 'objectType', 'objectId', 'subject', 'message', 'nickname', 'ownerId', 'exportType', 'queryString', 'reportsPerPage', 'recipientUserIds', 'scheduleFrequency', 'scheduledOnDay', 'scheduledOnDays', 'scheduledAtHour', 'isUtc', 'recipientDestination', 'filterId'],
  // TODO: please modify the destructured obj to fetcher(payload) - Look at an example in fn above
  fetcher({
    deliveryType,
    objectType,
    objectId,
    subject,
    message,
    nickname,
    ownerId,
    exportType,
    queryString,
    reportsPerPage,
    recipientUserIds,
    scheduleFrequency,
    scheduledOnDay,
    scheduledOnDays,
    scheduledAtHour,
    isUtc,
    recipientDestination,
    filterId
  }) {
    return createAssetSchedule({
      deliveryType,
      objectType,
      objectId,
      subject,
      message,
      nickname,
      ownerId,
      exportType,
      queryString,
      reportsPerPage,
      recipientUserIds,
      scheduleFrequency,
      scheduledOnDay,
      scheduledOnDays,
      scheduledAtHour,
      isUtc,
      recipientDestination,
      filterId
    });
  }
});

// @scheduledEmailId {number} The ID of the scheduled email that is being edited.
// @objectType {String} Object type to email/export, can be either "REPORT" or "DASHBOARD".
// @objectId {Number} Id of the asset to email/export.
// @subject {String} Subject of email.
// @message {String} Message/body of email.
// @nickname {String} Nickname of the scheduled email.
// @ownerId {String} Id of the owner of the asset to email.
// @exportType {String} File type of export, can be either "PNG", "CSV", "XLS", or "XLSX".
// @queryString {String} Query string that contains ad hoc filters that will be applied to the asset.
// @reportsPerPage {Number} Number of reports to show per page
// @recipientUserIds {Array} List of user IDs that the email will be sent to.
// @scheduleFrequency {String} The frequency in which an email should be sent.
// @scheduledOnDay {Number} The day in which an email should be sent.
// @scheduledAtHour {Number} The hour of the day in which an email should be sent.
// @isUtc {Boolean} If the email should be sent using UTC time zone.
export const EDIT_ASSET_SCHEDULE = registerMutation({
  fieldName: EDIT_ASSET_SCHEDULE_FIELD_NAME,
  args: ['deliveryType', 'scheduledEmailId', 'objectType', 'objectId', 'subject', 'message', 'nickname', 'ownerId', 'exportType', 'queryString', 'reportsPerPage', 'recipientUserIds', 'scheduleFrequency', 'scheduledOnDay', 'scheduledOnDays', 'scheduledAtHour', 'isUtc', 'recipientDestination', 'filterId'],
  fetcher({
    deliveryType,
    scheduledEmailId,
    objectType,
    objectId,
    subject,
    message,
    nickname,
    ownerId,
    exportType,
    queryString,
    reportsPerPage,
    recipientUserIds,
    scheduleFrequency,
    scheduledOnDay,
    scheduledOnDays,
    scheduledAtHour,
    isUtc,
    recipientDestination,
    filterId
  }) {
    return editAssetSchedule({
      deliveryType,
      scheduledEmailId,
      objectType,
      objectId,
      subject,
      message,
      nickname,
      ownerId,
      exportType,
      queryString,
      reportsPerPage,
      recipientUserIds,
      scheduleFrequency,
      scheduledOnDay,
      scheduledOnDays,
      scheduledAtHour,
      isUtc,
      recipientDestination,
      filterId
    });
  }
});

// @scheduleId {Number} ID of the scheduled email to delete.
export const DELETE_ASSET_SCHEDULE = registerMutation({
  fieldName: DELETE_ASSET_SCHEDULE_FIELD_NAME,
  args: ['scheduleId'],
  fetcher({
    scheduleId
  }) {
    return deleteAssetSchedule({
      scheduleId
    });
  }
});

// @objectType {String} Object type to fetch recurring emails for, can be either "REPORT" or "DASHBOARD".
// @objectId {Number} Id of the asset to fetch recurring emails for.
// @returns {List()} of scheduled email objects for a given asset.
export const GET_SHARE_ASSET_SCHEDULE = registerQuery({
  fieldName: SHARE_ASSET_SCHEDULES_FIELD_NAME,
  args: ['objectId', 'objectType', 'deliveryType'],
  fetcher(payload) {
    return getShareAssetSchedules(payload).then(shareAssetSchedulesResponse => fromJS(shareAssetSchedulesResponse), error => console.error(error));
  }
});
export const GET_CONNECTED_EMAIL_SENDING_DOMAINS = registerQuery({
  fieldName: CONNECTED_EMAIL_SENDING_DOMAINS_FIELD_NAME,
  args: [],
  fetcher() {
    return getConnectedEmailSendingDomains().then(connectedEmailsResponse => fromJS(connectedEmailsResponse), error => console.error(error));
  }
});