'use es6';

import * as http from '../../../request/http';
import toJS from '../../../lib/toJS';
import * as params from '../params';
const clean = (param = {}) => Object.keys(param).reduce((query, key) => /^metadata-/i.test(key) ? query : Object.assign({}, query, {
  [key]: param[key]
}), {});
export const get = (spec, config) => {
  const {
    url
  } = spec;
  const param = params.get(spec, config);
  return http.get(`${url}/metadata`, {
    query: clean(param)
  }).then(toJS).then(({
    metadata
  }) => metadata);
};