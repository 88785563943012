import { useDispatch, useSelector } from 'react-redux';
import { track, EVENTS
// @ts-expect-error migrate upstream
} from 'dashboard-ui-components/tracking/DashboardUITracker';

// @ts-expect-error migrate upstream
import { useUserRoleForDashboard } from 'reporting-action-components/utils/trackingUtils';
import { removeCurrentDashboardFilters, selectDashboardFilters } from '../../../../../../ducks/applied-dashboard-filters';
import { useSavedDashboardFiltersPanelContext } from '../context';
export const useClearAllAppliedFilters = () => {
  const {
    dashboardId,
    dashboard
  } = useSavedDashboardFiltersPanelContext();
  const appliedFilters = useSelector(selectDashboardFilters(dashboardId));
  const dispatch = useDispatch();
  const userRole = useUserRoleForDashboard(dashboard);
  const canClearAll = appliedFilters.length > 0;
  const clearAllFilters = () => {
    dispatch(removeCurrentDashboardFilters({
      dashboardId
    }));
    track(EVENTS.clearDashboardFilters, {
      actionType: 'explore',
      action: 'clear-filters',
      assetType: 'dashboard',
      role: userRole,
      interactionSource: 'filters-panel'
    });
  };
  return {
    canClearAll,
    clearAllFilters
  };
};