'use es6';

import I18n from 'I18n';
import { Map as ImmutableMap, fromJS, List } from 'immutable';
import makeDateRangeByType from 'reporting-data/lib/makeDateRangeByType';
import * as colors from 'HubStyleTokens/colors';
import { TIME_SERIES, SEARCH, AGGREGATION } from 'reporting-data/constants/configTypes';
import { GT, LT } from 'reporting-data/constants/operators';
import { TABLE } from 'reporting-data/constants/chartTypes';
import { DEALS } from 'reporting-data/constants/dataTypes';
import { SUM } from 'reporting-data/constants/metricTypes';
import { configureReport } from 'reporting-data/configure';
import { configureDateLine } from '../../../../reports/decorators/ConfigOptionsDecorator/dateLine';
export const isReportAccumulative = report => report.getIn(['displayParams', 'customWidget', 'options', 'intraperiod']);
export const isTimeSeries = report => report.getIn(['config', 'configType']) === TIME_SERIES;
export const isSearch = report => report.getIn(['config', 'configType']) === SEARCH;
export const isTable = report => report.get('chartType') === TABLE;
export const getMetricField = report => report.getIn(['config', 'metrics']).map(metric => metric.get('property')).find(property => property !== 'count');

//returns a report dimension to group the data
//eg. hubspot_owner_id, hubspot_team_id, closedate
export const getBucketField = report => report.getIn(['config', 'dimensions', 0]);

//returns a report dimension to categorize breakdowns
//eg. dealstage, hs_manual_forecast_category, hs_analytics_source
export const getBreakdownField = report => report.getIn(['config', 'dimensions', 1]);
export const getCustomWidgetType = report => report.getIn(['displayParams', 'customWidget', 'type']);
export const isWeightedPipelineReport = report => getCustomWidgetType(report) === 'SALES_QUOTA' && getBreakdownField(report) === 'dealstage';
export const hasMetricTypeCount = report => {
  const widgetType = getCustomWidgetType(report);
  return widgetType === 'DEAL_PUSH_ANALYSIS' || widgetType === 'CHANGE_HISTORY_DEAL_LIST';
};
export const hasSecondaryDateRange = report => {
  const widgetType = getCustomWidgetType(report);
  return widgetType === 'CHANGE_HISTORY_DEAL_LIST' || widgetType === 'WATERFALL';
};
export const makeAccumulative = (dataPoints, applicableProps = [], initialValue = 0) => {
  const accumulative = applicableProps.reduce((collect, prop) => Object.assign({}, collect, {
    [prop]: initialValue
  }), {});
  return dataPoints.map(point => point.map((value, prop) => applicableProps.includes(prop) ? accumulative[prop] += value : value));
};
export const DATE_LINE = ImmutableMap({
  key: 'today',
  dashStyle: 'line',
  width: 2
});
const getDateCategories = (dataPoints, dateField) => dataPoints.map(point => point.get(dateField)).toOrderedSet().toJS().map(date => ImmutableMap({
  key: date
}));
export const getDateLineInfo = (dataPoints, dateField) => ({
  x: {
    bottom: {
      rules: {
        plotLines: [configureDateLine(DATE_LINE, getDateCategories(dataPoints, dateField))]
      }
    }
  }
});
export const getReportStartDate = report => report.getIn(['config', 'filters', 'dateRange', 'value', 'startDate']);
export const translateFilters = report => configureReport(report);
export const deleteMetricTypes = report => report.updateIn(['config', 'metrics'], metrics => metrics ? metrics.map(metric => metric.delete('metricTypes')) : metrics);
export const getDateProperty = report => report.getIn(['config', 'filters', 'dateRange', 'property']);

/**
 * This function could be used for general purposes (i.e. pipeline snapshots in future refactor).
 * It returns a Map with count & amount associated with every row in table.
 * @param {*} ownersForecastList : List [{ count, amount, category }]
 * returns: Map({ category_count, category_amount })
 */
export const getCombinedValues = (ownersForecastList, breakdownField, amountField, countField) => ownersForecastList.reduce((acc, point) => {
  const breakdown = point.get(breakdownField);
  return acc.set(breakdown, point.get(countField)).set(`${breakdown}_SUM`, point.get(amountField));
}, ImmutableMap());
export const getCountAndAmountLabel = options => I18n.text(`reporting-ui-components.customWidgets.salesAnalytics.countAndAmount`, options);
export const COLOR_BY_DEAL_CHANGE_TYPE = {
  NEW: colors.CHART_GREEN,
  AMOUNT_INCREASED: colors.CHART_GREEN,
  CLOSE_DATE_PULLED: colors.CHART_GREEN,
  STAGE_MOVED_FORWARD: colors.CHART_GREEN,
  FORECAST_MOVED_FORWARD: colors.CHART_GREEN,
  AMOUNT_DECREASED: colors.CHART_RED,
  CLOSE_DATE_PUSHED: colors.CHART_RED,
  STAGE_MOVED_BACKWARD: colors.CHART_RED,
  FORECAST_MOVED_BACKWARD: colors.CHART_RED,
  NO_CHANGE: colors.BATTLESHIP
};
export const DRILLED_WATERFALL_REPORT = fromJS({
  chartType: 'WATERFALL',
  config: {
    configType: AGGREGATION,
    dataType: DEALS,
    dimensions: ['dealBreakdown'],
    metrics: [{
      property: 'amount_in_home_currency',
      metricTypes: [SUM]
    }],
    filters: {
      dateRange: {
        property: 'closedate',
        value: {}
      }
    }
  },
  displayParams: {
    customWidget: {
      type: 'WATERFALL',
      options: {
        x: 'dealBreakdown',
        y: 'SUM|amount_in_home_currency'
      },
      isDrilledReport: true
    }
  }
});
export const isSingleDimensionTimeSeries = report => isTimeSeries(report) && report.getIn(['config', 'dimensions']).size === 1;

// use builder state or primary date-range to update GT/LT filters to match their intended rangeType
// since we lose track of what rangeType (e.g. THIS_MONTH) those filters were intended to represent
const updateSecondaryDateRangeFilter = report => {
  const dateProperty = report.getIn(['config', 'filters', 'dateRange', 'property']);
  const hasSecondaryDatefilters = !report.getIn(['config', 'filters', 'custom'], List()).filter(map => map.get('property') === dateProperty).isEmpty();
  const secondaryDateRangeContext = report.getIn(['displayParams', 'salesAnalytics', 'context', 'secondaryDateRange']);
  const secondaryDateRange = secondaryDateRangeContext && makeDateRangeByType(secondaryDateRangeContext.toJS());
  const primaryDateRange = report.getIn(['config', 'filters', 'dateRange', 'value']).toJS();
  const {
    startDate,
    endDate
  } = secondaryDateRange || primaryDateRange;
  if (hasSecondaryDatefilters && !secondaryDateRange) {
    return report;
  }
  return report.updateIn(['config', 'filters', 'custom'], (filters = List()) => {
    return filters.filter(filter => filter.get('property') !== dateProperty).concat(fromJS([{
      property: dateProperty,
      operator: GT,
      value: startDate,
      dateTimeFormat: 'DATE'
    }, {
      property: dateProperty,
      operator: LT,
      value: endDate,
      dateTimeFormat: 'DATE'
    }]));
  });
};
export const fillDateRangeToReport = report => {
  const updatedReport = report.updateIn(['config', 'filters', 'dateRange', 'value'], (dateRange = ImmutableMap({
    rangeType: 'LAST_MONTH'
  })) => {
    return ImmutableMap(makeDateRangeByType(dateRange.toJS()));
  });
  if (hasSecondaryDateRange(report)) {
    return updateSecondaryDateRangeFilter(updatedReport);
  }
  return updatedReport;
};
export const getQuery = report => ({
  reportId: report.get('id'),
  href: !report.get('id') && window.location.href
});