import Raven from 'raven-js';
function setLocalStorageItem(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch (__e) {
    // not actionable
  }
}
function getLocalStorageItem(key) {
  let itemValue = null;
  try {
    itemValue = localStorage.getItem(key);
  } catch (__e) {
    // not actionable
  }
  return itemValue;
}
function hasLocalStorageItem(key) {
  let itemValue = null;
  try {
    itemValue = localStorage.getItem(key);
  } catch (__e) {
    // not actionable
  }
  return itemValue !== null;
}
function removeLocalStorageItem(key) {
  try {
    localStorage.removeItem(key);
  } catch (__e) {
    // not actionable
  }
}
export function isRecord(value) {
  return value !== null && typeof value === 'object' && Object.entries(value).length > 0;
}
function getLocalStorageKeyValueMap(key) {
  const lastSavedJSONMap = getLocalStorageItem(key);
  let parsedMap = null;
  if (!lastSavedJSONMap) {
    return parsedMap;
  }
  try {
    const parsedValue = JSON.parse(lastSavedJSONMap);
    if (isRecord(parsedValue)) {
      parsedMap = parsedValue;
    }
  } catch (error) {
    Raven.captureException(new Error(`Failed to parse ${key}`), {
      extra: {
        error
      },
      fingerprint: ['failed to parse localStorage value']
    });
  }
  return parsedMap;
}
function updateLocalStorageKeyValueMap({
  updatedKey,
  updatedValue,
  localStorageKey
}) {
  const map = getLocalStorageKeyValueMap(localStorageKey);
  const updatedMap = Object.assign({}, map, {
    [updatedKey]: updatedValue
  });
  try {
    const JSONMap = JSON.stringify(updatedMap);
    setLocalStorageItem(localStorageKey, JSONMap);
  } catch (error) {
    Raven.captureException(new Error(`Failed to stringify ${updatedKey} for ${localStorageKey}`), {
      extra: {
        error
      },
      fingerprint: ['failed to stringify localStorage value']
    });
  }
}
export { setLocalStorageItem, hasLocalStorageItem, getLocalStorageItem, removeLocalStorageItem, getLocalStorageKeyValueMap, updateLocalStorageKeyValueMap };