export const UNKNOWN_DASHBOARD_TYPE = 'UNKNOWN_DASHBOARD_TYPE';
export const REPORTING_ACTIONS_LOADER_ERROR_BOUNDARY = 'REPORTING_ACTIONS_LOADER_ERROR_BOUNDARY';
export const DASHBOARD_ERROR_BOUNDARY = 'DASHBOARD_ERROR_BOUNDARY';
export const EXPORT_ROUTE_ERROR_BOUNDARY = 'EXPORT_ROUTE_ERROR_BOUNDARY';
export const EMAIL_ROUTE_ERROR_BOUNDARY = 'EMAIL_ROUTE_ERROR_BOUNDARY';
export const DASHBOARD_FETCH_FAILURE = 'DASHBOARD_FETCH_FAILURE';
export const UPDATE_53 = 'UPDATE_53';
export const REPORT_ERROR = 'REPORT_ERROR';
export const DRILLDOWN_FAILED = 'DRILLDOWN_FAILED';
export const WIDGET_ERROR_BOUNDARY = 'WIDGET_ERROR_BOUNDARY';
export const CREATE_URL_FAILED = 'CREATE_URL_FAILED';
export const ILLEGAL_DASHBOARD_NAME = 'ILLEGAL_DASHBOARD_NAME';
export const FAILED_TO_SET_RENDERED = 'FAILED_TO_SET_RENDERED';
export const FAILED_TO_SET_OWNER = 'FAILED_TO_SET_OWNER';
export const FAILED_TO_UPDATE_DASHBOARD_DETAILS = 'FAILED_TO_UPDATE_DASHBOARD_DETAILS';
export const FAILED_TO_REMOVE_REPORT_FROM_DASHBOARD = 'FAILED_TO_REMOVE_REPORT_FROM_DASHBOARD';
export const STANDARD_DASHBOARD_EXEMPTION = 'STANDARD_DASHBOARD_EXEMPTION';
export const USER_ATTRIBUTES_MISSING_ATTRIBUTE_PARAMETER = 'USER_ATTRIBUTES_MISSING_ATTRIBUTE_PARAMETER';
export const REPORT_DATA_ACCESS_DENIED = 'REPORT_DATA_ACCESS_DENIED';
export const VIEW_REPORT_DATA_TYPE_ACCESS_DENIED = 'VIEW_REPORT_DATA_TYPE_ACCESS_DENIED';
export const SINGLE_REPORT_VIEWER_ERROR_BOUNDARY = 'SINGLE_REPORT_VIEWER_ERROR_BOUNDARY';
export const PINNED_PROPERTY_ERROR_BOUNDARY = 'PINNED_PROPERTY_ERROR_BOUNDARY';
export const DEFAULT_DASHBOARDS_APP_ERROR_BOUNDARY = 'DEFAULT_DASHBOARDS_APP_ERROR_BOUNDARY';
export const SAVED_DASHBOARD_FILTER_PANEL_ERROR_BOUNDARY = 'SAVED_DASHBOARD_FILTER_PANEL_ERROR_BOUNDARY';
export const APPLY_DASHBOARD_FILTERS_ERROR_BOUNDARY = 'APPLY_DASHBOARD_FILTERS_ERROR_BOUNDARY';
export const PROPERTY_LIST_ERROR_BOUNDARY = 'PROPERTY_LIST_ERROR_BOUNDARY';
export const QUICK_FILTERS_PANEL_ERROR_BOUNDARY = 'QUICK_FILTERS_PANEL_ERROR_BOUNDARY';
export const DASHBOARD_HEADER_ERROR_BOUNDARY = 'DASHBOARD_HEADER_ERROR_BOUNDARY';
export const WIDGET_GRID_ERROR_BOUNDARY = 'WIDGET_GRID_ERROR_BOUNDARY';