'use es6';

import { has } from '../../../lib/has';
import { pairs } from './metrics';
import { one } from './downgrade';
const parse = (spec, config) => response => {
  const {
    dimensions,
    metrics = []
  } = config;
  const {
    total,
    totals,
    breakdowns = []
  } = response;
  let preCovertedCurrencyProperties = (totals === null || totals === void 0 ? void 0 : totals.propertiesUsingAdSpendCurrencyChoice) || [];
  const keys = breakdowns.map(({
    breakdown
  }) => breakdown);
  const remapped = breakdowns.reduce((mapped, breakdown) => Object.assign({}, mapped, {
    [breakdown.breakdown]: breakdown
  }), {});
  const matrix = Object.assign({
    dimensions,
    metrics: pairs(config),
    keys: [keys],
    data: keys.map(key => {
      var _remapped$key;
      const currentBucketConvertedCurrencyProperties = ((_remapped$key = remapped[key]) === null || _remapped$key === void 0 ? void 0 : _remapped$key.propertiesUsingAdSpendCurrencyChoice) || [];
      if (preCovertedCurrencyProperties.length === 0 && currentBucketConvertedCurrencyProperties.length !== 0) {
        preCovertedCurrencyProperties = currentBucketConvertedCurrencyProperties;
      }
      return metrics.map(({
        property
      }) => has(remapped, key) ? has(remapped[key], property) ? remapped[key][property] : has(remapped[key].metadata, property) ? remapped[key].metadata[property] : null : null);
    }),
    // TODO: determine count vs. total
    total: [total]
  }, totals ? {
    totals: metrics.map(({
      property
    }) => has(totals, property) ? totals[property] : 0)
  } : {}, {
    preCovertedCurrencyProperties
  });
  return {
    response,
    matrix
  };
};
export const get = (spec, config) => ({
  url: `${spec.url}/total`,
  parse: parse(spec, config),
  downgrade: one
});