'use es6';

import http from 'hub-http/clients/apiClient';
import formatAnalyticsViews from '../formatters/formatAnalyticsViews';
const BASE_URL = 'activities/v2/filters';
export const createGetAllAnalyticsViews = ({
  httpClient
}) => () => httpClient.get(BASE_URL).then(formatAnalyticsViews);
export const getAllAnalyticsViews = createGetAllAnalyticsViews({
  httpClient: http
});