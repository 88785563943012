/**
 * Object type ids that don't have a corresponding object type in the CRM.
 * Reach out to #collaboration-sidebar-hq if you need to update this map.
 */
const CommentObjectType = {
  COMMENTING_SANDBOX: 'COMMENTING_SANDBOX',
  LEAD_FLOW: 'LEAD_FLOW',
  REPORTING_DASHBOARD: 'REPORTING_DASHBOARD',
  UNKNOWN: 'UNKNOWN',
  USER_AUDIT_LOG: 'USER_AUDIT_LOG',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  AD_CAMPAIGN: 'AD_CAMPAIGN',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  BLOG_LISTING_PAGE: 'BLOG_LISTING_PAGE',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  BLOG_POST: 'BLOG_POST',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  CAMPAIGN: 'CAMPAIGN',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  CTA: 'CTA',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  DEAL: 'DEAL',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  ENGAGEMENT: 'ENGAGEMENT',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  FORM: 'FORM',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  KNOWLEDGE_ARTICLE: 'KNOWLEDGE_ARTICLE',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  LANDING_PAGE: 'LANDING_PAGE',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  MARKETING_EMAIL: 'MARKETING_EMAIL',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  MARKETING_EVENT: 'MARKETING_EVENT',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  MARKETING_SMS: 'MARKETING_SMS',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  OBJECT_LIST: 'OBJECT_LIST',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  SOCIAL_BROADCAST: 'SOCIAL_BROADCAST',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  WEB_INTERACTIVE: 'WEB_INTERACTIVE',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  WEBSITE_PAGE: 'WEBSITE_PAGE',
  /**
   * @deprecated This value has been migrated to Framework App Settings: https://tools.hubteam.com/puma/framework-app-settings/collaborationSidebar/entries
   */
  WORKFLOW: 'WORKFLOW'
};
export default CommentObjectType;

/**
 * Comment object types that have not been migrated to commenting FAS
 */
export const LEGACY_COMMENT_OBJECT_TYPES = [CommentObjectType.COMMENTING_SANDBOX, CommentObjectType.LEAD_FLOW, CommentObjectType.REPORTING_DASHBOARD, CommentObjectType.UNKNOWN, CommentObjectType.USER_AUDIT_LOG];