'use es6';

import { ANALYTICS_BLOG_POSTS, ANALYTICS_CAMPAIGNS, ANALYTICS_CONTENT_SEARCH, ANALYTICS_EVENT_COMPLETIONS, ANALYTICS_KNOWLEDGE_ARTICLES, ANALYTICS_LANDING_PAGES, ANALYTICS_PAGES, ANALYTICS_PATHS, ANALYTICS_PEOPLE, ANALYTICS_SOURCES, ANALYTICS_STANDARD_PAGES, ANALYTICS_TOPICS, ANALYTICS_UTM_CAMPAIGNS, ANALYTICS_UTM_CONTENTS, ANALYTICS_UTM_MEDIUMS, ANALYTICS_UTM_SOURCES, ANALYTICS_UTM_TERMS, ATTRIBUTION_TOUCH_POINTS, BLOG, BLOG_POSTS, CONVERSATIONS, FEEDBACK, LEGACY_CAMPAIGNS, KNOWLEDGE_ARTICLES, PRODUCTS, SEQUENCES, SOCIAL_POSTS, TICKETS, UNIFIED_FEEDBACK_TRENDS, WEB_ANALYTICS } from 'reporting-data/constants/dataTypes';
import { CAMPAIGNS_ACCESS, CMS_REPORTS_ACCESS, CONTENT_REPORTS_ACCESS, MARKETING_PRODUCT_ACCESS, PAGES_ANALYTICS, REVENUE_ATTRIBUTION_ACCESS, SEQUENCES_REPORTS_ACCESS, SERVICE_ACCESS, SERVICE_FEEDBACK_ACCESS, SERVICE_KNOWLEDGE_ACCESS, SOCIAL_ACCESS, TOPIC_CLUSTERS_REPORTS_ACCESS, TRAFFIC_ANALYTICS_API_ACCESS, WEB_ANALYTICS_API_ACCESS } from './scope-name';

//groups data types that require the same set of scopes
const DATA_TYPE_GROUPS = {
  CMS_PAGES: [ANALYTICS_BLOG_POSTS, ANALYTICS_LANDING_PAGES],
  CMS_PAGES_CONTENT: [ANALYTICS_CONTENT_SEARCH, ANALYTICS_PAGES, ANALYTICS_STANDARD_PAGES],
  FEEDBACK: [FEEDBACK, UNIFIED_FEEDBACK_TRENDS],
  KNOWLEDGE_ARTICLES: [KNOWLEDGE_ARTICLES, ANALYTICS_KNOWLEDGE_ARTICLES],
  MARKETING: [ANALYTICS_EVENT_COMPLETIONS, BLOG, BLOG_POSTS],
  MARKETING_CONTENT: [ANALYTICS_PATHS, ANALYTICS_PEOPLE],
  SERVICE: [CONVERSATIONS, PRODUCTS, TICKETS],
  UTM_ANALYTICS: [ANALYTICS_UTM_CAMPAIGNS, ANALYTICS_UTM_CONTENTS, ANALYTICS_UTM_MEDIUMS, ANALYTICS_UTM_SOURCES, ANALYTICS_UTM_TERMS]
};
const getDataTypeGroup = dataType => {
  for (const group in DATA_TYPE_GROUPS) {
    if (DATA_TYPE_GROUPS[group].includes(dataType)) {
      return group;
    }
  }
  return dataType;
};
const DATA_TYPE_SCOPE_CHECKERS = {
  // access to the following data types depends solely on portal parcels
  [ANALYTICS_SOURCES]: checkScope => checkScope(WEB_ANALYTICS_API_ACCESS) || checkScope(TRAFFIC_ANALYTICS_API_ACCESS),
  [ATTRIBUTION_TOUCH_POINTS]: checkScope => checkScope(REVENUE_ATTRIBUTION_ACCESS),
  [SEQUENCES]: checkScope => checkScope(SEQUENCES_REPORTS_ACCESS),
  [WEB_ANALYTICS]: checkScope => checkScope(WEB_ANALYTICS_API_ACCESS),
  // access to the following data types depends solely on user settings
  SERVICE: checkScope => checkScope(SERVICE_ACCESS),
  // access to the following data types depends both on portal parcels & user settings
  [ANALYTICS_CAMPAIGNS]: checkScope => checkScope(PAGES_ANALYTICS) && (checkScope(CAMPAIGNS_ACCESS) || checkScope(CONTENT_REPORTS_ACCESS)),
  [ANALYTICS_TOPICS]: checkScope => checkScope(TOPIC_CLUSTERS_REPORTS_ACCESS) && checkScope(CONTENT_REPORTS_ACCESS),
  CMS_PAGES: checkScope => checkScope(CMS_REPORTS_ACCESS) || checkScope(PAGES_ANALYTICS),
  CMS_PAGES_CONTENT: checkScope => (checkScope(CMS_REPORTS_ACCESS) || checkScope(PAGES_ANALYTICS)) && checkScope(CONTENT_REPORTS_ACCESS),
  FEEDBACK: checkScope => checkScope(SERVICE_FEEDBACK_ACCESS),
  KNOWLEDGE_ARTICLES: checkScope => checkScope(SERVICE_KNOWLEDGE_ACCESS),
  [LEGACY_CAMPAIGNS]: checkScope => checkScope(MARKETING_PRODUCT_ACCESS) && (checkScope(CAMPAIGNS_ACCESS) || checkScope(CONTENT_REPORTS_ACCESS)),
  MARKETING: checkScope => checkScope(MARKETING_PRODUCT_ACCESS),
  MARKETING_CONTENT: checkScope => checkScope(MARKETING_PRODUCT_ACCESS) && checkScope(CONTENT_REPORTS_ACCESS),
  [SOCIAL_POSTS]: checkScope => checkScope(MARKETING_PRODUCT_ACCESS) && checkScope(SOCIAL_ACCESS),
  UTM_ANALYTICS: checkScope => checkScope(TRAFFIC_ANALYTICS_API_ACCESS) && checkScope(CONTENT_REPORTS_ACCESS)
};
const checkDataTypeAccess = (dataType, checkScope) => {
  const dataTypeGroup = getDataTypeGroup(dataType);
  const hasAccess = DATA_TYPE_SCOPE_CHECKERS[dataTypeGroup];
  return !hasAccess || hasAccess(checkScope);
};
export default checkDataTypeAccess;