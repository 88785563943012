import { getTableList, getTableLabel } from './table-utils';
import { DataSourceTypes } from '../schema/data-source';
const dataSourceTypeHasObjectTypeId = dataSourceType => {
  const objectTypeIdDataSourceTypes = [DataSourceTypes.HUBSPOT_OBJECT, DataSourceTypes.HUBSPOT_EVENT];
  return objectTypeIdDataSourceTypes.includes(dataSourceType);
};
export const hasObjectTypeId = snowflakeTable => {
  return 'objectTypeId' in snowflakeTable;
};
export const maybeGetObjectTypeId = snowflakeTable => snowflakeTable && hasObjectTypeId(snowflakeTable) ? snowflakeTable.objectTypeId : null;
export const getMapOfFieldKeyToProperty = tableDescriptionMeta => {
  const {
    properties
  } = tableDescriptionMeta;
  return properties.map((propertyMap, tableName) => propertyMap.mapKeys(propertyName => `${tableName}.${propertyName}`)).flatten(1);
};
const filterObjectIdsFromTableDescription = (tableDescription, objectTypeIds) => {
  const match = id => objectTypeIds.includes(id);
  return getTableList(tableDescription).filter(table => dataSourceTypeHasObjectTypeId(table.type) && typeof table.objectTypeId === 'string' && match(table.objectTypeId)).map(table => table.objectTypeId);
};
const containsObjectTypeIds = (tableDescription, objectTypeIds) => !!filterObjectIdsFromTableDescription(tableDescription, objectTypeIds).size;
export const isDangerousRollupProperty = (tableDescription, snowflakeProperty) => {
  return snowflakeProperty && snowflakeProperty.rollupProperty && containsObjectTypeIds(tableDescription, snowflakeProperty.rollupPropertyObjectTypeIds);
};
export const shouldHideHiddenProperty = snowflakeProperty => snowflakeProperty && snowflakeProperty.hidden;
export const shouldHideProperty = snowflakeProperty => shouldHideHiddenProperty(snowflakeProperty);
export const getDangerousRollupPropertyTableLabel = (tableDescription, snowflakeTables, snowflakeProperty) => {
  const rollupObjectIdInTableDescription = filterObjectIdsFromTableDescription(tableDescription, snowflakeProperty.rollupPropertyObjectTypeIds).first();
  const rollupSnowflakeTableOfProperty = snowflakeTables.find(table => hasObjectTypeId(table) && 'objectTypeId' in table && table.objectTypeId === rollupObjectIdInTableDescription);
  return getTableLabel(tableDescription.name, rollupSnowflakeTableOfProperty);
};
export const pruneNonRootJoins = (snowflakeJoins, snowflakeTableGroups) => {
  const mapOfGroupNameToRoot = snowflakeTableGroups.map(({
    root
  }) => root);
  const isTableGroupRoot = tableName => {
    return mapOfGroupNameToRoot.toList().some(root => {
      return root.includes(tableName);
    });
  };
  return snowflakeJoins.filter((__, tableName) => {
    return isTableGroupRoot(tableName);
  }).map(associations => {
    return associations.filter(isTableGroupRoot);
  });
};