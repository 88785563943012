module.exports = {
  "clickedTableLink": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "dataType": "string"
    },
    "namespace": "reporting",
    "version": "1"
  },
  "toggleLegendItem": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "clickReportSpecificAction": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string",
        "isOptional": true
      },
      "data_type": {
        "type": "string",
        "isOptional": true
      },
      "actionType": [
        "create-task",
        "add-persona",
        "set-quotas",
        "show-goals",
        "show-offline-sources",
        "customize-sales-performance",
        "set-goals"
      ]
    },
    "namespace": "reporting",
    "version": "1"
  },
  "setMarketingPerformanceGoals": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "initializeMarketingPerformanceGoals": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "type": [
        "LAST_MONTHS_VALUES",
        "DEFAULT_VALUES"
      ]
    },
    "namespace": "reporting",
    "version": "1"
  },
  "setSrvGoal": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "add-goal",
          "remove-goal",
          "reset-goal"
        ],
        "isOptional": false
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickGoToLink": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "openReportFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "selectQuickFilter": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "dataType": {
        "type": "string"
      },
      "property": {
        "type": "string"
      },
      "actionType": {
        "type": [
          "edit"
        ]
      },
      "action": {
        "type": [
          "filter"
        ]
      },
      "assetType": {
        "type": [
          "report",
          "srv-checkbox-table"
        ],
        "isOptional": false
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "toggleReportChartType": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "dataType": {
        "type": "string"
      },
      "chartType": {
        "type": "string"
      },
      "actionType": {
        "type": [
          "edit"
        ]
      },
      "action": {
        "type": [
          "change chart type"
        ]
      },
      "assetType": {
        "type": [
          "report"
        ],
        "isOptional": false
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "addSrvSavedFilter": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "changeSrvReportConfigFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "condition-added",
          "condition-deleted"
        ],
        "isOptional": false
      },
      "filterType": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "changeSrvObjectSpecificFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "filterType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "changeSrvDateFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "date-range",
          "date-property",
          "compared-to",
          "frequency",
          "secondary-date-range",
          "currency",
          "query",
          "metric",
          "group-by",
          "breakdown-by"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      },
      "isNewFilterExperience": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "filtersBarView": {
    "name": "SRV_VIEW",
    "class": "view",
    "namespace": "reporting",
    "version": "1"
  },
  "changeSrvSnowflakeFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "resetSrvFilters": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "namespace": "reporting",
    "version": "1"
  },
  "toggleSrvTabs": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "srv-filters-tab",
          "srv-about-tab"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "filtersOnboardingTourInteraction": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "step": {
        "type": "string",
        "isOptional": true
      },
      "onboardingAction": {
        "type": [
          "cancel",
          "back",
          "next",
          "finish"
        ],
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "drillInto": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "drill-into"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": "string",
        "isOptional": false
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "hoverOver": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "hover-over"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": "string",
        "isOptional": false
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "dataTypes": {
        "type": "array",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": false
      },
      "templateKey": {
        "type": "string",
        "isOptional": true
      },
      "origin": {
        "type": "string",
        "isOptional": true
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "isNonPlatformReport": {
        "type": "boolean",
        "isOptional": true
      },
      "reportIdentifier": {
        "type": "string",
        "isOptional": true
      },
      "isDrilldownReport": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "toggleSummaryInsight": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "show-summary",
          "hide-summary"
        ]
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "changeChartType": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "change-chart-type"
        ]
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "reportType": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "toggleDatasetInsights": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "enable",
          "disable"
        ]
      },
      "value": {
        "type": "string"
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "selectDimension": {
    "name": "REPORT_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "change-report-dimension"
        ]
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "applicationName": {
        "type": "string",
        "isOptional": true
      },
      "whereScreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickTableCellLink": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "click-table-cell-link"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "report"
        ],
        "isOptional": false
      },
      "reportId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "string",
        "isOptional": true
      },
      "dashboardReportIds": {
        "type": "array",
        "isOptional": true
      },
      "objectId": {
        "type": "string",
        "isOptional": false
      },
      "linkClicked": {
        "type": "string",
        "isOptional": false
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "viewReportingInsights": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "interactionSource": {
        "type": [
          "single-object-report-viewer"
        ],
        "isOptional": false
      },
      "insightReportMetric": {
        "type": [
          "MAX",
          "AVG",
          "MIN",
          "SUM"
        ],
        "isOptional": false
      },
      "insightReportChartType": {
        "type": [
          "DATA_WELL",
          "PIE",
          "TABLE"
        ]
      },
      "originalReportMetric": {
        "type": "string",
        "isOptional": false
      },
      "originalReportChartType": {
        "type": "string",
        "isOptional": false
      },
      "insightReportIndex": {
        "type": "number"
      }
    },
    "namespace": "reporting",
    "version": "1"
  },
  "clickReportingInsightsUserFeedback": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "interactionSource": {
        "type": [
          "single-object-report-viewer"
        ],
        "isOptional": false
      },
      "insightReportMetric": {
        "type": [
          "MAX",
          "AVG",
          "MIN",
          "SUM"
        ],
        "isOptional": false
      },
      "insightReportChartType": {
        "type": [
          "DATA_WELL",
          "PIE",
          "TABLE"
        ]
      },
      "originalReportMetric": {
        "type": "string",
        "isOptional": false
      },
      "originalReportChartType": {
        "type": "string",
        "isOptional": false
      },
      "userFeedback": {
        "type": [
          "positive",
          "negative"
        ]
      }
    },
    "namespace": "reporting",
    "version": "1"
  }
};