'use es6';

import getInboundDbPropertyGroups from '../../retrieve/inboundDb/common/properties';
import { PRODUCTS } from '../../constants/dataTypes';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import countProperty from '../partial/count-property';
import productsModule from '../../dataTypeDefinitions/inboundDb/products';
import overridePropertyTypes from '../../retrieve/inboundDb/common/overridePropertyTypes';
export const getPropertyGroups = () => {
  return getInboundDbPropertyGroups(PRODUCTS);
};
export const getProperties = () => createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(PRODUCTS)))().then(overridePropertyTypes(productsModule.getInboundSpec()));