import { FUNCTION_DEFINITIONS } from '../../dataset/expression-meta';
import { Equal, FunctionCall, NullLiteral, NumericLiteral } from '../../dataset/expression-records';
import { expressionUpdater, isAggregationContextExpression, isFieldExpression, isPropertyExpression } from '../../dataset/expression-utils';
import { PropertyTypeNames } from '../../dataset/type-records';
import { toPropertyBasicType } from '../../dataset/type-utils';
import { DATASET_TABLE } from '../schema/table-records';
import { expressionFieldsListToMap } from './expression-field-utils';
const getTypeFromPropertyOrFieldExpression = (expression, reportDefinition, meta) => {
  if (isPropertyExpression(expression)) {
    const {
      table,
      name
    } = expression;
    const isDatasetProperty = table === DATASET_TABLE && meta.datasetDefinition;
    if (isDatasetProperty) {
      const fields = meta.datasetDefinition.fields;
      const field = fields.get(name);
      if (!field) {
        throw new Error(`Could not find dataset field with name: ${name}`);
      }
      return toPropertyBasicType(field.type);
    }
    const properties = meta.datasetProperties.get(table);
    if (!properties) {
      throw new Error(`Could not find properties for ${table}`);
    }
    const property = properties.get(name);
    if (!property) {
      throw new Error(`Could not find property for ${table}.${name}`);
    }
    return property.type;
  } else {
    const expressionFieldsMap = expressionFieldsListToMap(reportDefinition.expressions);
    const reportLevelField = expressionFieldsMap.get(expression.name);
    if (!reportLevelField) {
      throw new Error(`Could not expression field with name: ${expression.name}`);
    }
    return toPropertyBasicType(reportLevelField.type);
  }
};
const buildQueryifyNullsUpdater = (meta, reportDefinition) => expression => {
  if (isPropertyExpression(expression) || isFieldExpression(expression)) {
    let type = undefined;
    try {
      type = getTypeFromPropertyOrFieldExpression(expression, reportDefinition, meta);
    } catch (e) {
      console.error(e);
      return expression;
    }
    if (type === PropertyTypeNames.number || type === PropertyTypeNames.currency_number) {
      const conditionArgument = Equal({
        left: expression,
        right: NullLiteral({})
      });
      const nullHandledExpression = FunctionCall({
        name: FUNCTION_DEFINITIONS.IF.name,
        arguments: [conditionArgument, NumericLiteral({
          value: 0
        }), expression]
      });
      return nullHandledExpression;
    } else {
      return expression;
    }
  }
  return expression;
};
const querifyNulls = (expressionField, reportDefinition, reportMeta) => {
  const queryifyNullsUpdater = buildQueryifyNullsUpdater(reportMeta, reportDefinition);
  if (isAggregationContextExpression(expressionField.expression)) {
    return expressionField.updateIn(['expression', 'expression'], nextAggregateExpression => expressionUpdater(nextAggregateExpression, queryifyNullsUpdater));
  } else {
    const nextExpression = expressionUpdater(expressionField.expression, queryifyNullsUpdater);
    return expressionField.set('expression', nextExpression);
  }
};
export const querifyNullHandledExpressionFields = (reportDefinition, reportMeta) => {
  const currentExpressionFields = reportDefinition.expressions;
  const nextExpressionFields = currentExpressionFields.map(expressionField => expressionField.getIn(['editor', 'editorState', 'treatNullsAsZero']) ? querifyNulls(expressionField, reportDefinition, reportMeta) : expressionField).toList();
  return reportDefinition.set('expressions', nextExpressionFields);
};