'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { GT } from '../../constants/operators';
import prefix from '../../lib/prefix';
import { BUCKETS } from '../../constants/property-types';
import unescapedText from 'I18n/utils/unescapedText';
import { RAAS_FE_ONLY_CREATE_STAGE } from './contacts-lifecyclestages';
const unescapeTranslate = stageLabel => unescapedText(`reporting-data.properties.contacts.hasEnteredStage`, {
  stageLabel
});
const translateCommon = prefix('reporting-data.properties.common');
const booleanOptions = () => [{
  value: 'YES',
  label: translateCommon('buckets.included')
}, {
  value: 'NO',
  label: translateCommon('buckets.excluded')
}];
const createLifecycleDateProperty = lifecyclestage => `hs_date_entered_${lifecyclestage}`;
const createEnteredCountProperty = lifecyclestage => `BUCKET_${createLifecycleDateProperty(lifecyclestage)}_count`;
export const getEnteredLifecycleStagePropertyOptions = lifecyclestages => lifecyclestages.reduce((enteredProperties, stage) => {
  const value = stage.get('value');
  const label = stage.get('label');
  return value === RAAS_FE_ONLY_CREATE_STAGE ? enteredProperties : enteredProperties.push(ImmutableMap({
    label: unescapeTranslate(label),
    name: createEnteredCountProperty(value),
    property: createLifecycleDateProperty(value),
    groupName: 'contactscripted',
    scripted: true,
    blocklistedForFiltering: true,
    options: booleanOptions(),
    type: BUCKETS,
    buckets: [{
      name: 'YES',
      operator: GT,
      value: 0
    }, {
      name: 'NO'
    }]
  }));
}, List([]));
export default getEnteredLifecycleStagePropertyOptions;