'use es6';

import { createSelector } from 'reselect';
import every from 'transmute/every';
import get from 'transmute/get';
import { CONTACT_CREATE_ATTRIBUTION, ATTRIBUTION_TOUCH_POINTS as REVENUE_ATTRIBUTION } from 'reporting-data/constants/dataTypes';
import { getDataType } from 'reporting-data/public/get-data-type';
import { derivePermission as deriveDashboardPermission } from '../../dashboard/dashboard-permission';
import checkDataTypeAccess from '../../scope/checkDataTypeAccess';
import { getMoveReportToExistingDashboardProblem } from 'dashboard-lib/public/enable-action/move-report-to-existing-dashboard';
import { getSetReportBusinessUnitProblem } from '../../enable-action/set-report-business-unit';
import { getViewReportDataProblem } from '../../enable-action/view-report-data';
import { getViewReportProblem } from '../../enable-action/view-report';
import { getUserReportPermissionLevel } from '../../report/report-schema';
import { CUSTOM_REPORT_COUNT, CUSTOM_REPORT_LIMIT, DASHBOARD_ID, DASHBOARD_IS_LOADING, DASHBOARD_REPORT_LIMIT, HAS_ATTRIBUTION_ACCESS, HAS_CUSTOM_REPORTING_ACCESS, HAS_DATA_TYPE_ACCESS, HAS_DATASETS_ACCESS, HAS_SALES_ANALYTICS_ACCESS, IS_READ_ONLY_DEMO, MAX_USER_ASSET_PERMISSION, REPORT_IS_ATTRIBUTION, REPORT_IS_CUSTOM, REPORT_IS_SALES_ANALYTICS, REPORT_IS_SNOWFLAKE, REPORT_NAME, REPORT_USES_DATASET, TARGET_DASHBOARD_ID, TARGET_DASHBOARD_REPORT_COUNT, TARGET_IS_READ_ONLY_DEMO, TARGET_USER_DASHBOARD_PERMISSION, USER_DASHBOARD_PERMISSION, USER_REPORT_PERMISSION, RESTRICTED_FIELD_LEVEL_PROPERTIES, REPORT_IS_SNOWFLAKE_TEMPLATE_EXCEPTION, HAS_PAYMENTS_REPORTING_ACCESS, REPORT_IS_PAYMENTS } from '../../../private/action-problem-solution/params/all-params';
import { getAllDataTypes, isCustom, isCustomDatasetReport as _isCustomDatasetReport, isV2, isAttributionLike } from '../../report/report-logic';
import { getRestrictedFieldLevelProperties } from '../../report/report-schema';
import { getDemo, getIdStr } from '../../dashboard/dashboard-schema';
import { selectUserMaxAssetPermission } from './index';
import { selectCustomReportsLimit, selectDashboardMaxWidgetsLimit } from '../limits';
import { selectHasCustomReportingAcccess, selectHasDashboardDemoWriteScope, selectHasDatasetsAccess, selectHasPaymentsReportingAccess, selectHasSalesAnalyticsAccess } from '../scopes';
import { selectCustomReportsCount } from '../../reports/index';
import { selectAllScopes } from '../index';
import { CONTACT_CREATE_ATTRIBUTION_ACCESS, DEAL_CREATE_ATTRIBUTION_ACCESS, REVENUE_ATTRIBUTION_ACCESS } from '../../scope/scope-name';
import { isSnowflakeReportTemplateException } from 'reporting-action-components/lib/report-logic';
import { isCommercePaymentsReport as _isCommercePaymentsReport } from '../../report/report-logic';
const SALES_ANALYTICS_WIDGETS = ['WATERFALL', 'CHANGE_HISTORY_DEAL_LIST', 'DEAL_PUSH_ANALYSIS', 'PIPELINE_SNAPSHOTS', 'QUOTA_ATTAINMENT', 'SALES_QUOTA', 'COMPLETED_ACTIVITIES'];
const _isSalesAnalyticsWidget = report => SALES_ANALYTICS_WIDGETS.includes(report.getIn(['displayParams', 'customWidget', 'type']));

// A general check to see if the user has access to
// view the type of data type represented in the report
export const selectHasAccessToDataInReport = report => createSelector(selectAllScopes, scopesMap => {
  const dataTypes = getAllDataTypes(report);
  const checkAccessToDataTypes = every(dataType => checkDataTypeAccess(dataType, scopeName => scopesMap.includes(scopeName)));
  return checkAccessToDataTypes(dataTypes);
});
const DEAL_CREATE_ATTRIBUTION = '0-63';
export const selectHasAttributionReportAccess = report => createSelector(selectAllScopes, scopesMap => {
  const dataType = getDataType(report);
  const typeScope = {
    [CONTACT_CREATE_ATTRIBUTION]: CONTACT_CREATE_ATTRIBUTION_ACCESS,
    [DEAL_CREATE_ATTRIBUTION]: DEAL_CREATE_ATTRIBUTION_ACCESS,
    [REVENUE_ATTRIBUTION]: REVENUE_ATTRIBUTION_ACCESS
  }[dataType];
  return scopesMap.includes(typeScope);
});

// This file contains selectors that return the value of whether a user is able
// to perform an action on a report and requires that the reports state
// (defined at dashboard-lib/public/reports) to be defined in the redux store

// action "problems" determine whether a user has access to the action, based
// on a ranked list of parameters. If any of the conditions are truthy, the problem
// selector will return a value. If no conditions are met, the selector will return null;

// These values are consumed by ProblemMessage components defined in dashboard-component

/**
 * Can move a report from a given originDashboard to a given targetDashboard
 */
export const selectMoveReportToTargetDashboardProblem = (originDashboard, targetDashboard) => {
  const isOriginDashboardDemo = !!getDemo(originDashboard);
  const isTargetDashboardDemo = !!getDemo(targetDashboard);
  const originDashboardId = getIdStr(originDashboard);
  const targetDashboardWidgetCount = get('widgetCount', targetDashboard);
  const targetDashboardId = getIdStr(targetDashboard);
  const userOriginDashboardPermission = deriveDashboardPermission(originDashboard);
  const userTargetDashboardPermission = deriveDashboardPermission(targetDashboard);
  return createSelector(selectDashboardMaxWidgetsLimit, selectHasDashboardDemoWriteScope, selectUserMaxAssetPermission, (dashboardMaxWidgetsLimit, hasDashboardDemoWriteScope, userMaxAssetPermission) => getMoveReportToExistingDashboardProblem({
    // This is always set to 'a_valid_name' because the move report in reports-list does not let you rename reports.
    [REPORT_NAME]: 'a_valid_name',
    [DASHBOARD_IS_LOADING]: !originDashboard,
    [MAX_USER_ASSET_PERMISSION]: userMaxAssetPermission,
    [USER_DASHBOARD_PERMISSION]: userOriginDashboardPermission,
    [IS_READ_ONLY_DEMO]: isOriginDashboardDemo && !hasDashboardDemoWriteScope,
    [TARGET_IS_READ_ONLY_DEMO]: isTargetDashboardDemo && !hasDashboardDemoWriteScope,
    [TARGET_USER_DASHBOARD_PERMISSION]: userTargetDashboardPermission,
    [TARGET_DASHBOARD_REPORT_COUNT]: targetDashboardWidgetCount,
    [DASHBOARD_REPORT_LIMIT]: dashboardMaxWidgetsLimit,
    [DASHBOARD_ID]: originDashboardId,
    [TARGET_DASHBOARD_ID]: targetDashboardId
  }));
};
export const selectSetReportBusinessUnitProblem = report => {
  const isCustomReport = isCustom(report);
  const userReportPermission = getUserReportPermissionLevel(report);
  return createSelector(selectCustomReportsCount, selectCustomReportsLimit, (customReportsCount, customReportsLimit) => getSetReportBusinessUnitProblem({
    [USER_REPORT_PERMISSION]: userReportPermission,
    [CUSTOM_REPORT_COUNT]: customReportsCount,
    [CUSTOM_REPORT_LIMIT]: customReportsLimit,
    [REPORT_IS_CUSTOM]: isCustomReport
  }));
};
export const selectViewReportProblem = report => {
  const isCustomReport = isCustom(report);
  const isCustomDatasetReport = _isCustomDatasetReport(report);
  const isSalesAnalyticsWidget = _isSalesAnalyticsWidget(report);
  const userReportPermission = getUserReportPermissionLevel(report);
  const isAttributionReport = isAttributionLike(report);
  const restrictedFieldLevelProperties = getRestrictedFieldLevelProperties(report);
  const isSnowflakeReport = isV2(report);
  const isCommercePaymentsReport = _isCommercePaymentsReport({
    report
  });
  const isSnowflakeTemplateException = isSnowflakeReportTemplateException(report);
  return createSelector(selectCustomReportsCount, selectCustomReportsLimit, selectHasCustomReportingAcccess, selectHasDatasetsAccess, selectHasSalesAnalyticsAccess, selectHasAttributionReportAccess(report), selectHasPaymentsReportingAccess, (customReportsCount, customReportsLimit, hasCustomReportingAccess, hasDatasetsAccess, hasSalesAnalyticsAccess, hasAttributionReportAccess, hasPaymentsReportingAccess) => getViewReportProblem({
    [CUSTOM_REPORT_COUNT]: customReportsCount,
    [CUSTOM_REPORT_LIMIT]: customReportsLimit,
    [HAS_CUSTOM_REPORTING_ACCESS]: hasCustomReportingAccess,
    [HAS_DATASETS_ACCESS]: hasDatasetsAccess,
    [HAS_SALES_ANALYTICS_ACCESS]: hasSalesAnalyticsAccess,
    [REPORT_IS_CUSTOM]: isCustomReport,
    [REPORT_IS_SALES_ANALYTICS]: isSalesAnalyticsWidget,
    [REPORT_USES_DATASET]: isCustomDatasetReport,
    [USER_REPORT_PERMISSION]: userReportPermission,
    [REPORT_IS_ATTRIBUTION]: isAttributionReport,
    [HAS_ATTRIBUTION_ACCESS]: hasAttributionReportAccess,
    [RESTRICTED_FIELD_LEVEL_PROPERTIES]: restrictedFieldLevelProperties,
    [REPORT_IS_SNOWFLAKE]: isSnowflakeReport,
    [REPORT_IS_SNOWFLAKE_TEMPLATE_EXCEPTION]: isSnowflakeTemplateException,
    [HAS_PAYMENTS_REPORTING_ACCESS]: hasPaymentsReportingAccess,
    [REPORT_IS_PAYMENTS]: isCommercePaymentsReport
  }));
};
export const selectViewReportDataProblem = report => {
  const isCustomReport = isCustom(report);
  const isSnowflakeReport = isV2(report);
  const userReportPermission = getUserReportPermissionLevel(report);
  const isAttributionReport = isAttributionLike(report);
  const restrictedFieldLevelProperties = getRestrictedFieldLevelProperties(report);
  const isSnowflakeTemplateException = isSnowflakeReportTemplateException(report);
  const isCommercePaymentsReport = _isCommercePaymentsReport({
    report
  });
  return createSelector(selectCustomReportsCount, selectCustomReportsLimit, selectHasAccessToDataInReport(report), selectHasAttributionReportAccess(report), selectHasPaymentsReportingAccess, selectHasCustomReportingAcccess, (customReportsCount, customReportsLimit, hasAccessToDataInReport, hasAttributionReportAccess, hasPaymentsReportingAccess, hasCustomReportingAccess) => getViewReportDataProblem({
    [CUSTOM_REPORT_COUNT]: customReportsCount,
    [CUSTOM_REPORT_LIMIT]: customReportsLimit,
    [HAS_DATA_TYPE_ACCESS]: hasAccessToDataInReport,
    [REPORT_IS_CUSTOM]: isCustomReport,
    [USER_REPORT_PERMISSION]: userReportPermission,
    [REPORT_IS_SNOWFLAKE]: isSnowflakeReport,
    [REPORT_IS_ATTRIBUTION]: isAttributionReport,
    [HAS_ATTRIBUTION_ACCESS]: hasAttributionReportAccess,
    [RESTRICTED_FIELD_LEVEL_PROPERTIES]: restrictedFieldLevelProperties,
    [REPORT_IS_SNOWFLAKE_TEMPLATE_EXCEPTION]: isSnowflakeTemplateException,
    [HAS_PAYMENTS_REPORTING_ACCESS]: hasPaymentsReportingAccess,
    [REPORT_IS_PAYMENTS]: isCommercePaymentsReport,
    [HAS_CUSTOM_REPORTING_ACCESS]: hasCustomReportingAccess
  }));
};