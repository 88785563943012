export const DASHBOARD_CONTEXT_UPDATE = 'DASHBOARD_CONTEXT_UPDATE';
export const DASHBOARD_CREATE_SUCCESS = 'DASHBOARD_CREATE_SUCCESS';
export const DASHBOARD_CLONE_FAILURE = 'DASHBOARD_CLONE_FAILURE';
export const DASHBOARD_DELETE_FAILURE = 'DASHBOARD_DELETE_FAILURE';
export const DASHBOARD_DELETE_MULTIPLE_SUCCESS = 'DASHBOARD_DELETE_MULTIPLE_SUCCESS';
export const DASHBOARD_DELETE_SUCCESS = 'DASHBOARD_DELETE_SUCCESS';
export const DASHBOARD_FETCH = 'DASHBOARD_FETCH';
export const DASHBOARD_FETCH_FAILURE = 'DASHBOARD_FETCH_FAILURE';
export const DASHBOARD_FETCH_SUCCESS = 'DASHBOARD_FETCH_SUCCESS';
export const DASHBOARD_MOUNTED = 'DASHBOARD_MOUNTED';
export const REPORT_UPDATE_SUCCESS = 'REPORT_UPDATE_SUCCESS';
export const REPORT_UPDATE_FAILURE = 'REPORT_UPDATE_FAILURE';
export const REPORT_REMOVE_SUCCESS = 'REPORT_REMOVE_SUCCESS';
export const REPORT_DELETE_SUCCESS = 'REPORT_DELETE_SUCCESS';
export const DASHBOARD_UPDATE_SUCCESS = 'DASHBOARD_UPDATE_SUCCESS';
export const DASHBOARD_UPDATE_FAILURE = 'DASHBOARD_UPDATE_FAILURE';
export const DASHBOARD_META_UPDATE_SUCCESS = 'DASHBOARD_META_UPDATE_SUCCESS';
export const DASHBOARDS_FETCH = 'DASHBOARDS_FETCH';
export const DASHBOARDS_FETCH_FAILURE = 'DASHBOARDS_FETCH_FAILURE';
export const DASHBOARDS_FETCH_SUCCESS = 'DASHBOARDS_FETCH_SUCCESS';
export const DASHBOARD_LAYOUT_UPDATE_SUCCESS = 'DASHBOARD_LAYOUT_UPDATE_SUCCESS';
export const DELETE_REPORT = 'DELETE_REPORT';
export const REPORT_COUNT_FETCH_SUCCESS = 'REPORT_COUNT_FETCH_SUCCESS';
export const REPORT_COUNT_FETCH_FAILURE = 'REPORT_COUNT_FETCH_FAILURE';
export const DASHBOARD_COUNT_FETCH_SUCCESS = 'DASHBOARD_COUNT_FETCH_SUCCESS';
export const DASHBOARD_COUNT_FETCH_FAILURE = 'DASHBOARD_COUNT_FETCH_FAILURE';
export const REFRESH_ALL = 'REFRESH_ALL';
export const REFRESH_ALL_SUCCESS = 'REFRESH_ALL_SUCCESS';
export const REFRESH_ALL_FAILURE = 'REFRESH_ALL_FAILURE';
export const REFRESH_REPORT = 'REFRESH_REPORT';
export const REFRESH_REPORT_SUCCESS = 'REFRESH_REPORT_SUCCESS';
export const REFRESH_REPORT_FAILURE = 'REFRESH_REPORT_FAILURE';
export const REPORT_SAVE_SUCCESS = 'REPORT_SAVE_SUCCESS';
export const REPORT_SAVE_FAILURE = 'REPORT_SAVE_FAILURE';

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}