'use es6';

import I18n from 'I18n';
import { getTableList, isTableEventLike } from '../../../utils/table-utils';
import { ReportOptions, ReportWithOptions } from '../../../resolve/resolve-records';
import { Field, FieldTypes } from '../../../schema/column-records';
import { addColumnToChannel } from '../../../updaters/report';
import { asTable, createDimensionTableReport, tempRemoveEmptySnowflakeProperties } from '../../../utils/report-utils';
import { createDefaultDimension } from '../../../utils/column-utils';
import { findViolatedCompletionCriteria } from '../../../validate/validation-utils';
import { ExportRequest } from '../export-records';
import { DataSourceTypes } from '../../../schema/source-records';
import { DATASET_TABLE } from '../../../schema/table-records';
import { ChannelNames } from '../../../schema/visual-records';
import { OBJECT_ID, ROW_ID } from '../../../utils/field-utils';
import { applyEnableUndefinedDateBuckets } from '../../../updaters/report/modifiers';
import { querifyNullHandledExpressionFields } from '../../../utils/expression-field-null-transform-utils';
const encodeIdDimensions = (reportDefinition, deps) => {
  const {
    table: tableDescription
  } = reportDefinition;
  const objectIdDimensions = getTableList(tableDescription).filter(table => table.type !== DataSourceTypes.VIRTUAL_DATASOURCE).map(table => {
    const {
      name: tableName
    } = table;
    const fieldName = isTableEventLike(table) ? ROW_ID : OBJECT_ID;
    return Field({
      name: fieldName,
      type: FieldTypes.NUMBER,
      table: tableName
    });
  }).map(createDefaultDimension);
  return objectIdDimensions.reduce((acc, idDimension) => addColumnToChannel(acc, {
    column: idDimension,
    channelName: ChannelNames.COLUMNS,
    deps
  }), reportDefinition);
};
const setUndefinedBucketLabel = columns => columns.map(c => c.set('undefinedBucket', I18n.text('reporting-snowflake.export.undefined-bucket-label')));
const clearDateTransforms = columns => columns.map(c => c.delete('transform'));

/**
 * @param reportDefinition
 * @param deps
 * @returns {RelationalReport}
 */
const transformToDataReport = (reportDefinition, deps) => {
  let next = createDimensionTableReport(reportDefinition, deps.meta);
  if (reportDefinition.table.name !== DATASET_TABLE) {
    next = encodeIdDimensions(next, deps);
  }
  next = applyEnableUndefinedDateBuckets(next);
  next = next.update('columns', setUndefinedBucketLabel);
  next = next.update('columns', clearDateTransforms);
  return next;
};

/**
 * @param reportDefinition
 * @returns {RelationalReport?} - optional
 */
const transformToSummaryReport = (reportDefinition, userInfo, reportMeta) => {
  if (findViolatedCompletionCriteria(reportDefinition, userInfo)) {
    return undefined;
  }
  let next = asTable(reportDefinition);
  next = next.update('columns', setUndefinedBucketLabel);
  next = querifyNullHandledExpressionFields(next, reportMeta);
  return next;
};

/**
 * @param reportDefinition {RelationalReport}
 * @param deps {{ meta: TableAndPropertyMeta, userInfo: UserInfo }}
 * @param exportOptions {object} - options passed by r-ui-c's ExportButton
 * @returns {ExportRequest}
 */
export const exportRelationalReport = (reportDefinition, deps, exportOptions) => {
  const {
    format: exportFormat,
    title: exportName
  } = exportOptions;
  const reportResolveOptions = ReportOptions({
    fetchFromCache: false,
    updateCache: true
  });
  const {
    meta: reportMeta,
    userInfo
  } = deps;
  const reportDefinitionWithoutEmptyProperties = tempRemoveEmptySnowflakeProperties(reportDefinition, reportMeta);
  const dataReportDefinition = transformToDataReport(reportDefinitionWithoutEmptyProperties, deps);
  const maybeSummaryReportDefinition = transformToSummaryReport(reportDefinitionWithoutEmptyProperties, userInfo, reportMeta);
  const dataReport = ReportWithOptions({
    reportDefinition: dataReportDefinition,
    reportOptions: reportResolveOptions
  });
  const summaryReport = maybeSummaryReportDefinition && ReportWithOptions({
    reportDefinition: maybeSummaryReportDefinition,
    reportOptions: reportResolveOptions
  });
  return ExportRequest({
    dataReport,
    summaryReport,
    exportFormat,
    exportName
  });
};