import quickFetch from 'quick-fetch';
import { setDataToSmuggle, readSmuggledData } from './smuggleData';
export { readSmuggledData } from './smuggleData';
export const makeGroupsWithPropertiesQuickFetchKey = ({
  portalId = quickFetch.getPortalId(),
  frameworkTypeIdentifier,
  query
}) => `frameworkDataSchemaResolvers-${portalId}-properties-${frameworkTypeIdentifier}-${query === null || query === void 0 ? void 0 : query.showHighlySensitiveProperties}`;
export const getGroupsWithPropertiesFetchUrl = ({
  frameworkTypeIdentifier,
  query
}) => `/properties/v4/groups/${encodeURIComponent(frameworkTypeIdentifier)}/properties?includeFieldLevelPermission=true${query !== null && query !== void 0 && query.showHighlySensitiveProperties ? '&showHighlySensitiveProperties=true' : ''}`;
const quickFetchGroupsWithPropertiesForType = ({
  frameworkTypeIdentifier,
  query
}) => {
  const requestName = makeGroupsWithPropertiesQuickFetchKey({
    frameworkTypeIdentifier,
    query
  });
  if (quickFetch.getRequestStateByName(requestName)) {
    return;
  }
  quickFetch.makeEarlyRequest(requestName, {
    url: quickFetch.getApiUrl(getGroupsWithPropertiesFetchUrl({
      frameworkTypeIdentifier,
      query
    }), true),
    dataType: 'json',
    contentType: 'application/json',
    type: 'GET'
  });
};
export const makeFrameworkTypesQuickFetchKey = ({
  portalId = quickFetch.getPortalId(),
  family
}) => `frameworkDataSchemaResolvers-${portalId}-frameworkTypes-${family}`;
export const makeFrameworkTypesFetchUrl = ({
  family
}) => `/inbounddb-meta/v1/object-types/${family === 'events' ? 'events/' : ''}for-portal`;
const makeQuickFetchFrameworkTypesForFamily = ({
  family
}) => () => {
  const requestName = makeFrameworkTypesQuickFetchKey({
    family
  });
  if (quickFetch.getRequestStateByName(requestName)) {
    return;
  }
  quickFetch.makeEarlyRequest(requestName, {
    url: quickFetch.getApiUrl(makeFrameworkTypesFetchUrl({
      family
    }), true),
    dataType: 'json',
    contentType: 'application/json',
    type: 'GET'
  });
};
export const makeTypeMetadataEntriesFetchKey = ({
  portalId = quickFetch.getPortalId(),
  appSettingNames
}) => `frameworkDataSchemaResolvers-${portalId}-typeMetadataEntries-${appSettingNames.slice().sort().join(',')}`;
export const makeTypeMetadataEntriesFetchUrl = () => '/framework-builder/v1/read/metadata/type/all/batch';
export const typeMetadataEntriesQuickFetchSmuggleKey = 'FDG:FDSR:TypeMetadataSmuggledKeys';
const quickFetchTypeMetadataEntries = ({
  appSettingNames
}) => {
  const key = makeTypeMetadataEntriesFetchKey({
    appSettingNames
  });

  // Stores a map of quick-fetch key to the app settings that particular request fetched.
  const smuggledKeys = readSmuggledData(typeMetadataEntriesQuickFetchSmuggleKey) || {};
  smuggledKeys[key] = appSettingNames;
  const didSmuggle = setDataToSmuggle(typeMetadataEntriesQuickFetchSmuggleKey, smuggledKeys);

  // If we weren't able to store the data, there is no point in continuing with the
  // quick-fetch because we have no way of handling it in client code.
  if (didSmuggle) {
    quickFetch.makeEarlyRequest(makeTypeMetadataEntriesFetchKey({
      appSettingNames
    }), {
      url: quickFetch.getApiUrl(makeTypeMetadataEntriesFetchUrl(), true),
      dataType: 'json',
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify(appSettingNames)
    });
  }
};
export const makePropertyMetadataEntriesFetchKey = ({
  frameworkTypeIdentifier,
  appSettingName,
  portalId = quickFetch.getPortalId()
}) => `frameworkDataSchemaResolvers-${portalId}-propertyMetadataEntries-${frameworkTypeIdentifier}-${appSettingName}`;
export const makePropertyMetadataEntriesFetchUrl = ({
  frameworkTypeIdentifier,
  appSettingName
}) => `/framework-builder/v1/read/metadata/property/${encodeURIComponent(frameworkTypeIdentifier)}/${encodeURIComponent(appSettingName)}`;
const quickFetchPropertyMetadataEntries = ({
  frameworkTypeIdentifier,
  appSettingName
}) => {
  const requestName = makePropertyMetadataEntriesFetchKey({
    frameworkTypeIdentifier,
    appSettingName
  });
  if (quickFetch.getRequestStateByName(requestName)) {
    return;
  }
  quickFetch.makeEarlyRequest(requestName, {
    url: quickFetch.getApiUrl(makePropertyMetadataEntriesFetchUrl({
      frameworkTypeIdentifier,
      appSettingName
    }), true),
    dataType: 'json',
    contentType: 'application/json',
    type: 'GET'
  });
};
export const makePipelinesFetchKey = ({
  frameworkTypeIdentifier,
  portalId = quickFetch.getPortalId()
}) => `frameworkDataSchemaResolvers-${portalId}-pipelines-${frameworkTypeIdentifier}`;
export const makePipelinesFetchUrl = ({
  frameworkTypeIdentifier
}) => `/pipelines/v2/pipelines/${encodeURIComponent(frameworkTypeIdentifier)}?includeApprovalStageStatus=true&includePermissions=true`;
const quickFetchPipelines = ({
  frameworkTypeIdentifier
}) => {
  const requestName = makePipelinesFetchKey({
    frameworkTypeIdentifier
  });
  if (quickFetch.getRequestStateByName(requestName)) {
    return;
  }
  quickFetch.makeEarlyRequest(requestName, {
    url: quickFetch.getApiUrl(makePipelinesFetchUrl({
      frameworkTypeIdentifier
    }), true),
    dataType: 'json',
    contentType: 'application/json',
    type: 'GET'
  });
};

/**
 *
 * @param options.typeMetadataDebounceInterval Determines the debounce interval used to batch type metadata requests together. Each
 * instance has its own typeMetadata debounce and request queue.
 * @returns A promise that resolves to a FDSR quick-fetch client instance.
 */
export const buildQuickFetchClientInstance = ({
  typeMetadataDebounceInterval = 50
} = {}) => {
  const queuedTypeMetadataRequests = new Set();
  let typeMetadataRequestTimeout;
  const client = {
    properties: {
      get: quickFetchGroupsWithPropertiesForType,
      getGroups: quickFetchGroupsWithPropertiesForType,
      // Preserves the `getProperty` interface just in case we ever
      // switch this to actually fetch individual properties (very unlikely)
      getProperty: ({
        frameworkTypeIdentifier,
        propertyName: __propertyName
      }) => quickFetchGroupsWithPropertiesForType({
        frameworkTypeIdentifier
      })
    },
    pipelines: {
      get: quickFetchPipelines,
      getPipeline: ({
        frameworkTypeIdentifier
      }) => quickFetchPipelines({
        frameworkTypeIdentifier
      })
    },
    frameworkTypes: {
      getEvents: makeQuickFetchFrameworkTypesForFamily({
        family: 'events'
      }),
      getObjects: makeQuickFetchFrameworkTypesForFamily({
        family: 'objects'
      })
    },
    typeMetadata: {
      get: ({
        appSettingNames
      }) => {
        if (typeMetadataRequestTimeout) {
          clearTimeout(typeMetadataRequestTimeout);
        }
        appSettingNames.forEach(name => queuedTypeMetadataRequests.add(name));

        // Straightforward debouncing approach. Collects all requests on a 50ms debounce,
        // then fires the request. This should generally catch the case where multiple QF clients
        // are constructed and used independently in a QF file
        typeMetadataRequestTimeout = setTimeout(() => {
          quickFetchTypeMetadataEntries({
            appSettingNames: [...queuedTypeMetadataRequests]
          });

          // Clear request queue
          queuedTypeMetadataRequests.clear();
        }, typeMetadataDebounceInterval);
      }
    },
    propertyMetadata: {
      get: quickFetchPropertyMetadataEntries
    },
    graph: {
      frameworkTypes: {
        getObjects: ({
          appSettingNames
        } = {}) => {
          client.frameworkTypes.getObjects();
          if (appSettingNames && appSettingNames.length) {
            client.typeMetadata.get({
              appSettingNames
            });
          }
        },
        getEvents: ({
          appSettingNames
        } = {}) => {
          client.frameworkTypes.getEvents();
          if (appSettingNames && appSettingNames.length) {
            client.typeMetadata.get({
              appSettingNames
            });
          }
        }
      },
      properties: {
        getGroups: ({
          frameworkTypeIdentifier,
          appSettingNames
        }) => {
          client.properties.getGroups({
            frameworkTypeIdentifier
          });
          appSettingNames.forEach(appSettingName => client.propertyMetadata.get({
            frameworkTypeIdentifier,
            appSettingName
          }));
        },
        getProperty: ({
          frameworkTypeIdentifier,
          propertyName: __propertyName,
          appSettingNames
        }) => {
          client.graph.properties.getGroups({
            frameworkTypeIdentifier,
            appSettingNames
          });
        },
        get: ({
          frameworkTypeIdentifier,
          appSettingNames
        }) => {
          client.graph.properties.getGroups({
            frameworkTypeIdentifier,
            appSettingNames
          });
        }
      }
    }
  };
  return Promise.resolve(client);
};