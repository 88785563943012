'use es6';

import { EditorState, Modifier, SelectionState } from 'draft-js';
export default (({
  editorState,
  block,
  updatedBlockData,
  preserveSelection = false
}) => {
  const blockSelection = SelectionState.createEmpty(block.getKey());
  let contentWithModifiedBlockData = Modifier.mergeBlockData(editorState.getCurrentContent(), blockSelection, updatedBlockData);
  if (preserveSelection) {
    // we preserve selection when doing something "under the hood", i.e. a user
    // didn't manually update block data, so we shouldn't select the block or
    // add the changes to the undo stack (hence EditorState.set instead of .push)
    const originalContent = editorState.getCurrentContent();
    contentWithModifiedBlockData = contentWithModifiedBlockData.merge({
      selectionAfter: originalContent.getSelectionAfter(),
      selectionBefore: originalContent.getSelectionBefore()
    });
    return EditorState.set(editorState, {
      currentContent: contentWithModifiedBlockData
    });
  }
  return EditorState.push(editorState, contentWithModifiedBlockData, 'change-block-data');
});