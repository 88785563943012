'use es6';

import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import { CONTENTS } from 'reference-resolvers/constants/CacheKeys';
import { CONTENT } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { createFetchCrmObjectsSearchPage, fetchCrmObjectsSearchPage, createFetchCrmObjectsByIds, fetchCrmObjectsByIds } from 'reference-resolvers/api/CrmSearchByUniqueIdApi';
const CONTENT_ID_PROPERTY = 'hs_content_id';
const CONTENT_NAME_PROPERTY = 'hs_name';
export const createContentReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CONTENTS,
  createFetchByIds: opts => createFetchCrmObjectsByIds(opts)(CONTENT, CONTENT_ID_PROPERTY, CONTENT_NAME_PROPERTY),
  createFetchSearchPage: opts => createFetchCrmObjectsSearchPage(opts)(CONTENT, CONTENT_ID_PROPERTY, CONTENT_NAME_PROPERTY),
  fetchByIds: fetchCrmObjectsByIds(CONTENT, CONTENT_ID_PROPERTY, CONTENT_NAME_PROPERTY),
  fetchSearchPage: fetchCrmObjectsSearchPage(CONTENT, CONTENT_ID_PROPERTY, CONTENT_NAME_PROPERTY)
}, options));
export default createContentReferenceResolver();