import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data", "loading"];
import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchThirdPartyApps } from '../requests/fetchThirdPartyApps';
const THIRD_PARTY_APPS = 'thirdParthApps';
export const GET_THIRD_PARTY_APPS = registerQuery({
  fieldName: THIRD_PARTY_APPS,
  args: [],
  fetcher: fetchThirdPartyApps
});
export const useGetThirdPartyApps = () => {
  var _data$thirdParthApps;
  const _useQuery = useQuery(GET_THIRD_PARTY_APPS, {
      fetchPolicy: 'cache-and-network'
    }),
    {
      data,
      loading
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  return Object.assign({}, rest, {
    loading,
    data: (data === null || data === void 0 || (_data$thirdParthApps = data.thirdParthApps) === null || _data$thirdParthApps === void 0 ? void 0 : _data$thirdParthApps.results) || []
  });
};