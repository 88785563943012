'use es6';

import { List, Map as ImmutableMap, fromJS } from 'immutable';
import prefix from '../../lib/prefix';
import { CALLS } from '../../constants/dataTypes';
import getDispositionOptions from '../partial/call-disposition-options';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import getCommonPropertyGroups from '../partial/engagement-common';
import getQuotasProperties from '../partial/quotas';
import countProperty from '../partial/count-property';
import engagementModule from '../../dataTypeDefinitions/inboundDb/engagement';
import overridePropertyTypes from '../../retrieve/inboundDb/common/overridePropertyTypes';
const translate = prefix('reporting-data.properties.call');
const translateStatus = prefix('reporting-data.properties.call.callStatus');
const translateGroup = prefix('reporting-data.groups.engagement');
export const getCallPropertyGroups = () => getDispositionOptions().then(dispositionOptions => List([ImmutableMap({
  name: 'callInfo',
  displayName: translateGroup('callInfo'),
  displayOrder: 0,
  hubspotDefined: true,
  properties: List([ImmutableMap({
    name: 'call.status',
    label: translate('status'),
    type: 'enumeration',
    hidden: true,
    options: fromJS([{
      value: 'COMPLETED',
      label: translateStatus('completed')
    }, {
      value: 'NO_ANSWER',
      label: translateStatus('noAnswer')
    }, {
      value: 'QUEUED',
      label: translateStatus('queued')
    }, {
      value: 'FAILED',
      label: translateStatus('failed')
    }, {
      value: 'CANCELED',
      label: translateStatus('canceled')
    }, {
      value: 'BUSY',
      label: translateStatus('busy')
    }, {
      value: 'CONNECTING',
      label: translateStatus('connecting')
    }, {
      value: 'CALLING_CRM_USER',
      label: translateStatus('callingCrmUser')
    }, {
      value: 'RINGING',
      label: translateStatus('ringing')
    }, {
      value: 'IN_PROGRESS',
      label: translateStatus('inProgress')
    }])
  }), ImmutableMap({
    name: 'call.durationMilliseconds',
    label: translate('duration'),
    type: 'duration'
  }), ImmutableMap({
    name: 'call.disposition',
    label: translate('disposition'),
    type: 'enumeration',
    options: dispositionOptions
  }), fromJS({
    name: 'call.body',
    label: translate('body'),
    type: 'string',
    format: {
      type: 'string',
      isHtml: true
    }
  })])
})]));
export const getPropertyGroups = () => Promise.all([getCommonPropertyGroups(), getCallPropertyGroups()]).then(([commonPropertyGroups, callPropertyGroups]) => List([...commonPropertyGroups, ...callPropertyGroups]));
export const getProperties = () => createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(CALLS)).merge(getQuotasProperties()))().then(overridePropertyTypes(engagementModule.getInboundSpec()));