'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import I18n from 'I18n';
import { CONTACTS } from 'reporting-data/constants/dataTypes';
import { getDealCreateStage } from 'reporting-data/properties/data-type/deals';
import { getCachedPipelines } from '../data/pipelinesDao';
import { getDataTypeName, isJourneyReport } from './salesAnalyticsHelpers';
export const JOURNEY_STAGE_LIMIT = 15;
export const FUNNEL_CREATE_STAGE_ID = 'create';
export const STAGE_OBJECT_TYPES = {
  CONTACT: 'CONTACT',
  DEAL: 'DEAL',
  LEAD: 'LEAD'
};
export const getStageContextField = report => getDataTypeName(report) === CONTACTS ? 'leadStages' : 'dealStages';
export const getOptionalStagesContextField = report => getDataTypeName(report) === CONTACTS ? 'optionalLeadStages' : 'optionalDealStages';
export const STAGE_VALUE_DELIMITER = '|';
const getDataFromStageValue = optionValue => {
  const splitValue = optionValue.split(STAGE_VALUE_DELIMITER);
  return {
    objectType: splitValue[0],
    stageId: splitValue[1]
  };
};
export const getObjectTypeFromStageValue = optionValue => getDataFromStageValue(optionValue).objectType;
export const getStageIdFromStageValue = optionValue => getDataFromStageValue(optionValue).stageId;
export const getStageOptionValue = (objectType, stageId) => `${objectType}${STAGE_VALUE_DELIMITER}${stageId}`;
const getFunnelCreateStage = objectType => {
  const funnelCreateStage = ImmutableMap(getDealCreateStage());
  return funnelCreateStage.set('stageId', funnelCreateStage.get('value')).update('label', label => objectType === STAGE_OBJECT_TYPES.DEAL ? label : I18n.text('reporting-data.lifecyclestage.create'));
};
const getFunnelCreateStageForReport = report => {
  const stageObjectType = getDataTypeName(report) === CONTACTS ? STAGE_OBJECT_TYPES.CONTACT : STAGE_OBJECT_TYPES.DEAL;
  return getFunnelCreateStage(stageObjectType);
};
export const getObjectPipelines = objectType => {
  const cachedPipelines = getCachedPipelines();
  return cachedPipelines.filter(pipeline => pipeline.get('objectType') === objectType);
};
export const getPipelineStages = pipeline => {
  const pipelineStages = pipeline ? pipeline.get('stages') : List();
  return pipelineStages;
};
const getCachedStagesForObjectPipeline = (objectType, dealPipelineId) => {
  const objectPipelines = getObjectPipelines(objectType);
  const objectPipelineId = objectType === STAGE_OBJECT_TYPES.DEAL ? dealPipelineId : null;
  const pipeline = objectPipelineId ? objectPipelines.find(objPipeline => objPipeline.get('pipelineId') === objectPipelineId) : objectPipelines.get(0);
  return getPipelineStages(pipeline);
};
export const getCachedDataForStage = (stage, report, dealPipeline) => {
  const stageObjectType = getObjectTypeFromStageValue(stage);
  const stageId = getStageIdFromStageValue(stage);
  const stagesToSearch = dealPipeline ? getCachedStagesForObjectPipeline(stageObjectType, dealPipeline, report) : getObjectPipelines(stageObjectType).reduce((memo, pipeline) => memo.concat(getPipelineStages(pipeline)), List());
  if (stageId === FUNNEL_CREATE_STAGE_ID) {
    return getFunnelCreateStageForReport(report);
  }
  return stagesToSearch.find(pipelineStage => pipelineStage.get('stageId') === stageId);
};
const CONTEXT_FIELD_TO_DEFAULT_STAGE_OBJECT_TYPE = {
  dealStages: STAGE_OBJECT_TYPES.DEAL,
  leadStages: STAGE_OBJECT_TYPES.CONTACT
};
export const getDefaultStagesForContextField = (contextField, dealPipelineId, report) => {
  const defaultObjectType = CONTEXT_FIELD_TO_DEFAULT_STAGE_OBJECT_TYPE[contextField];
  const pipelineStages = getCachedStagesForObjectPipeline(defaultObjectType, dealPipelineId, report);
  const defaultStages = isJourneyReport(report) ? pipelineStages.unshift(getFunnelCreateStageForReport(report)) : pipelineStages;
  return defaultStages.map(stage => getStageOptionValue(defaultObjectType, stage.get('stageId')));
};
export const canBeOptional = (stage, selectedStages) => stage !== selectedStages.first() && stage !== selectedStages.last();