import { useEffect, useState } from 'react';
import isEqual from 'hs-lodash/isEqual';
function createStableReference(object, keys) {
  return keys.reduce((acc, key) => {
    acc[key] = object[key];
    return acc;
  }, {});
}

/**
 * Ref: https://git.hubteam.com/HubSpot/Critsit/issues/4039
 *
 * Ensures stable references for all `keys` defined in `object`.
 * Useful for props that are sent by collaboration-sidebar consumers that could have unstable references,
 * causing collaboration-sidebar to re-render unnecessarily (Example: https://git.hubteam.com/HubSpotProtected/email-editor-ui/pull/514).
 */
export default function useStableReferences({
  object,
  keys
}) {
  const [stableReferences, setStableReferences] = useState(createStableReference(object, keys));
  useEffect(() => {
    const newValues = createStableReference(object, keys);
    setStableReferences(prev => {
      return isEqual(prev, newValues) ? prev : newValues;
    });
  }, [object, keys]);
  return Object.assign({}, object, stableReferences);
}