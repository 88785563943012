'use es6';

import ResolverError from 'reference-resolvers/schema/ResolverError';
import ResolverLoading from 'reference-resolvers/schema/ResolverLoading';
import curry from 'transmute/curry';
import isInstanceOf from 'transmute/isInstanceOf';
export const isLoading = isInstanceOf(ResolverLoading);
export const isError = isInstanceOf(ResolverError);
export const isResolved = value => value != null && !isLoading(value) && !isError(value);

/**
 * Maps a resolving value to with the correct mapper givin its state
 *
 * @param {object} mappers
 * @return {any}
 */
export const mapResolve = curry(({
  loading,
  error,
  resolved
}, value) => {
  if (isLoading(value)) {
    return loading(value);
  }
  if (isError(value)) {
    return error(value);
  }
  return resolved(value);
});