'use es6';

import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import curry from 'transmute/curry';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import { Map as ImmutableMap } from 'immutable';
import { LINE_ITEM } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
const BASE_URL = `line-items/v1/line-items/batch`;
const mapIdsWithProperties = curry((properties, ids) => ids.map(id => ({
  portalId: PortalIdParser.get(),
  objectId: id,
  requestOptions: {
    properties
  }
})));
export const OBJECT_SEARCH_CONFIGS = {
  [LINE_ITEM]: {
    url: BASE_URL,
    adapter: {
      mapIds: mapIdsWithProperties(['name']),
      mapResponse: formatToReferencesList({
        getId: get('objectId'),
        getLabel: getIn(['properties', 'name', 'value'])
      })
    }
  }
};
export const createGetObjectsByIds = ({
  httpClient
}) => curry((objectType, ids) => {
  const {
    url,
    adapter
  } = OBJECT_SEARCH_CONFIGS[objectType];
  return httpClient.get(url, {
    query: {
      id: ids,
      includeAllValues: true
    }
  }).then(res => ImmutableMap(res).valueSeq().toArray()).then(adapter.mapResponse);
});
export const getObjectsByIds = createGetObjectsByIds({
  httpClient: http
});