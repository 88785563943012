'use es6';

import { createTracker } from 'usage-tracker';
import events from '../../events.yaml';
const tracker = createTracker({
  events,
  properties: {
    screen: 'sales-analytics'
  }
});
export default tracker;