import { GLOBAL_NULL } from '../constants/defaultNullValues';
import { ENUMERATION, STRING, BOOLEAN, DATE, DATE_TIME, DURATION, NUMBER, ID } from '../constants/property-types';
const shouldTreatAsEmpty = (value, type) => value === GLOBAL_NULL || value === '' && [ENUMERATION, STRING, DATE_TIME, DURATION, DATE, BOOLEAN, NUMBER, ID, undefined].includes(type);
export const filterOutEmptySearchValues = (row, typesByProperty) => row.filter((value, property) => !shouldTreatAsEmpty(value, typesByProperty.get(property)));
export const normalizeDatasetEmptiness = dataset => {
  const typesByProperty = dataset.get('properties').map(property => property.get('type'));
  dataset = dataset.update('data', rows => rows.map(row => filterOutEmptySearchValues(row, typesByProperty)));
  dataset = dataset.update('properties', properties => properties.map((property, propertyName) => {
    const type = typesByProperty.get(propertyName);
    return property.update('references', references => references.filter((_, key) => !shouldTreatAsEmpty(key, type)));
  }));
  return dataset;
};