'use es6';

import { OrderedMap } from 'immutable';
import * as DataTypes from '../../constants/dataTypes';
import * as empty from '../empty';
import * as analyticsFunnel from './analytics-funnel';
import * as analyticsSources from './analytics-sources';
import * as attribution from './attribution';
import * as attributionTouchPoints from './attribution-touch-points';
import * as calls from './calls';
import * as companies from './companies';
import * as contacts from './contacts';
import * as contactCreateAttribution from './contact-create-attribution';
import * as conversations from './conversations';
import * as conversion from './conversion';
import * as dealCreateAttribution from './deal-create-attribution';
import * as deals from './deals';
import * as emails from './email';
import * as engagement from './engagement';
import * as engagementEmails from './engagement-emails';
import * as engagements from './engagements';
import * as feedbackSubmissions from './feedback-submissions';
import * as knowledgeArticles from './knowledge-articles';
import * as lineItem from './line-items';
import * as meeting from './meetings';
import * as notes from './notes';
import * as products from './products';
import * as quotas from './quotas';
import * as socialPosts from './social-posts';
import * as tasks from './tasks';
import * as tickets from './tickets';
import { createUnifiedProperties } from './unified';
import * as experimentalRevenue from './__experimental__/revenue';
import * as unifiedDataTypes from '../../constants/dataTypes/unified';
const unifiedPropertyGroups = Object.keys(unifiedDataTypes).reduce((groups, dataType) => Object.assign({}, groups, {
  [dataType]: createUnifiedProperties(dataType)
}), {});
export default OrderedMap(Object.assign({
  [DataTypes.ANALYTICS_FUNNEL]: analyticsFunnel,
  [DataTypes.ATTRIBUTION]: attribution,
  [DataTypes.ATTRIBUTION_TOUCH_POINTS]: attributionTouchPoints,
  [DataTypes.CALLS]: calls,
  [DataTypes.COMPANIES]: companies,
  [DataTypes.CONTACTS]: contacts,
  [DataTypes.CONTACT_CREATE_ATTRIBUTION]: contactCreateAttribution,
  [DataTypes.CONVERSATIONS]: conversations,
  [DataTypes.CONVERSION]: conversion,
  [DataTypes.CROSS_OBJECT]: empty,
  [DataTypes.CRM_OBJECT]: empty,
  [DataTypes.DEAL_CREATE_ATTRIBUTION]: dealCreateAttribution,
  [DataTypes.DEALS]: deals,
  [DataTypes.EMAIL]: emails,
  [DataTypes.ENGAGEMENT]: engagement,
  [DataTypes.ENGAGEMENTS]: engagements,
  [DataTypes.ENGAGEMENT_EMAILS]: engagementEmails,
  [DataTypes.FEEDBACK_SUBMISSIONS]: feedbackSubmissions,
  [DataTypes.KNOWLEDGE_ARTICLES]: knowledgeArticles,
  [DataTypes.LINE_ITEMS]: lineItem,
  [DataTypes.MEETINGS]: meeting,
  [DataTypes.NOTES]: notes,
  [DataTypes.PRODUCTS]: products,
  [DataTypes.QUOTAS]: quotas,
  [DataTypes.SOCIAL_POSTS]: socialPosts,
  [DataTypes.TASKS]: tasks,
  [DataTypes.TICKETS]: tickets
}, unifiedPropertyGroups, {
  [DataTypes.ANALYTICS_SOURCES]: analyticsSources,
  // experimental
  [DataTypes.EXPERIMENTAL_REVENUE]: experimentalRevenue
}));