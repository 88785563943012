'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import * as checked from 'reporting-data/lib/checked';
export const HARD = 'HARD';
export const SOFT = 'SOFT';
export const COMBINED = 'COMBINED';
export const ILL_FORMED = 'ILL_FORMED';
export const INCOMPLETE = 'INCOMPLETE';
export const UNSUPPORTED = 'UNSUPPORTED';
export const ConstraintTypes = {
  HARD,
  SOFT,
  COMBINED
};
export const InvalidSpecificationTypes = {
  ILL_FORMED,
  INCOMPLETE,
  UNSUPPORTED
};
export const ConstraintType = checked.symbol(ConstraintTypes, 'ConstraintType');
export const InvalidSpecificationType = checked.symbol(InvalidSpecificationTypes, 'InvalidSpecificationType');

// eslint-disable-next-line no-var
export var Constraint = checked.record({
  name: checked.string(),
  type: ConstraintType,
  producing: InvalidSpecificationType.optional(),
  cost: checked.number().optional(),
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  children: checked.list(Constraint).optional(),
  validate: checked.func(),
  listAll: checked.func(),
  listSatisfied: checked.func(),
  listViolated: checked.func()
}, 'Constraint');
const get = (getters, ...values) => Array.isArray(getters) ? getters.map(getter => getter(...values)) : [getters(...values)];
export const createConstraint = (name, type, producing, condition = () => true, getters, maybeCost = 0) => {
  const validate = (...values) => {
    try {
      const selectedArgs = getters ? get(getters, ...values) : values;
      return condition(...selectedArgs);
    } catch (e) {
      console.error(e);
      return false;
    }
  };
  const cost = type === ConstraintTypes.HARD ? Infinity : maybeCost;
  const data = ImmutableMap({
    name,
    type,
    producing,
    cost
  });
  const result = List([data]);
  return Constraint({
    name,
    type,
    producing,
    cost,
    validate,
    listAll: () => result,
    listSatisfied: (...values) => !validate(...values) ? List() : result,
    listViolated: (...values) => validate(...values) ? List() : result
  });
};
export const combineConstraints = (name, constraintArray = [], condition = (constraint, ...values) => constraint.validate(...values), getters) => {
  const constraints = List(constraintArray);
  const prefix = constraint => constraint.update('name', childName => {
    return name ? `${name}.${childName}` : childName;
  });
  const validateConstraint = (constraint, ...values) => {
    try {
      return condition(constraint, ...values);
    } catch (e) {
      console.error(e);
      return false;
    }
  };
  const validate = (...values) => {
    const selectedArguments = getters ? get(getters, ...values) : values;
    return List(constraints).every(constraint => validateConstraint(constraint, ...selectedArguments));
  };
  return Constraint({
    name,
    type: ConstraintTypes.COMBINED,
    validate,
    children: constraints,
    listAll: () => constraints.flatMap(constraint => constraint.listAll()).map(prefix),
    listSatisfied: (...values) => constraints.filter(constraint => validateConstraint(constraint, ...values)).flatMap(constraint => constraint.listSatisfied(...values)).map(prefix),
    listViolated: (...values) => constraints.filter(constraint => !validateConstraint(constraint, ...values)).flatMap(constraint => constraint.listViolated(...values)).map(prefix)
  });
};