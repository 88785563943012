import * as checked from 'reporting-data/lib/checked';
import { Encoding } from '../column-records';
import { AxisScaleType, ColorOption, Scale, goalFrequency, goalLineValue, legendPosition, legendWidth, showDataLabels, showRecordIds, stacking, stackingType } from '../highcharts-settings';
import { VisualTypes } from '../constants/visual-constants';
export const VerticalBarEncodings = checked.record({
  x: Encoding.optional(),
  y: Encoding.optional(),
  y_multi: checked.list(Encoding),
  color: Encoding.optional()
}, 'VerticalBarEncodings');
export const VerticalBarVisualOptions = checked.record({
  showDataLabels,
  showRecordIds,
  stacking,
  stackingType,
  accumulate: checked.boolean().defaultValue(false),
  yMinMax: Scale.defaultValue({}),
  color: ColorOption.defaultValue({}),
  yAxisType: AxisScaleType,
  yAxisLabel: checked.string().optional(),
  goalLineValue,
  goalFrequency,
  legendPosition,
  legendWidth
});
export const VerticalBarVisual = checked.record({
  type: checked.string().always(VisualTypes.VERTICAL_BAR),
  encodings: VerticalBarEncodings,
  options: VerticalBarVisualOptions.defaultValue({})
}, 'VerticalBarVisual');