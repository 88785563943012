'use es6';

import { PRODUCT_PROPERTIES } from 'reference-resolvers/constants/CacheKeys';
import { getProductProperties, createGetProductProperties } from 'reference-resolvers/api/PropertiesAPI';
import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
export const createProductPropertyReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: PRODUCT_PROPERTIES,
  createFetchData: createGetProductProperties,
  fetchData: getProductProperties
}, options));
export default createProductPropertyReferenceResolver();