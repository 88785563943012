import { List, OrderedMap, fromJS } from 'immutable';
import { isMultiConfigReport } from '../tsTypes/reportTypes';
import { TABLE } from '../constants/chartTypes';
import { ACCUMULATE } from '../constants/processorTypes';
import { isCustomWidgetReport } from '../tsTypes/reportTypes';
import { toMetricKey } from './../v2/dataset/datasetMetrics';
import { SUM } from './../constants/metricTypes';
import { getConfigs, getVisualization } from '../report/multiConfigReportGetters';
import { hasVisualization } from '../report/reportGetters';
export const canConvertToTableReport = report => {
  const chartType = report.get('chartType');
  const dimensions = report.getIn(['config', 'dimensions'], List());
  const metrics = report.getIn(['config', 'metrics'], List());
  const isNonMultiConfigReportWithVisualizationOverrides = hasVisualization(report) && !isMultiConfigReport(report);
  return chartType !== TABLE && dimensions.size !== 0 && metrics.size !== 0 && !isCustomWidgetReport(report) && !isNonMultiConfigReportWithVisualizationOverrides;
};
function updateConfigLimitAndProcessors(config, limit, processors) {
  return config.setIn(['limit'], limit).set('processors', processors);
}
function generateTableVisualization(report) {
  /* Visualization property is used to stitch data from Multi-config report
   * into a single Table visualization.  It stats with the columns from
   * primary config and add columns from inner configs
   */

  const reportConfigs = getConfigs(report);
  const extractColumns = (config, datasetKey) => {
    return config.get('dimensions').map(dimension => {
      return {
        dataset: datasetKey,
        field: dimension
      };
    }).concat(config.get('metrics').map(metric => {
      const metricTypes = metric.get('metricTypes') || List([SUM]);
      return metricTypes.map(metricType => {
        const field = toMetricKey({
          property: metric.get('property'),
          type: metricType
        });
        return {
          dataset: datasetKey,
          field
        };
      }).toList();
    })).flatten();
  };

  // Update config for visualization
  const columns = OrderedMap({
    primary: report.get('config')
  }).merge(reportConfigs).map((config, key) => extractColumns(config, key)).toList().flatten();
  return fromJS({
    type: TABLE,
    columns
  });
}
function chartToTableMultiConfigReport(report) {
  const limit = report.getIn(['config', 'limit']);

  // Update Inner configs for data fetching
  const configs = getConfigs(report).map(config => config.withMutations(mutableConfig => updateConfigLimitAndProcessors(mutableConfig, limit, config.get('processors', List()).filter(processor => processor !== ACCUMULATE)))).toMap();
  return report.set('reportConfigs', configs).set('visualization', generateTableVisualization(report));
}
export const chartToTableReport = (report, additionalMetrics) => {
  if (!canConvertToTableReport(report)) {
    return report;
  }
  const limit = report.getIn(['config', 'limit']);
  const config = report.get('config');
  const updatedConfig = report.withMutations(mutableReport => mutableReport.set('chartType', TABLE).set('config', updateConfigLimitAndProcessors(config, limit, config.get('processors', List()).filter(processor => processor !== ACCUMULATE))).updateIn(['config', 'metrics'], metrics => additionalMetrics && !additionalMetrics.isEmpty() ? metrics.concat(additionalMetrics).toSet().toList() : metrics));
  if (isMultiConfigReport(updatedConfig)) {
    return chartToTableMultiConfigReport(updatedConfig);
  }
  return updatedConfig;
};
function revertTableToChartMultiConfigReport(report, originalReport) {
  const reportConfigs = getConfigs(report).map((reportConfig, key) => {
    const limit = originalReport.getIn(['reportConfigs', key, 'limit']);
    const processors = originalReport.getIn(['reportConfigs', key, 'processors']);
    return reportConfig.withMutations(mutableConfig => updateConfigLimitAndProcessors(mutableConfig, limit, processors));
  }).toMap();
  const updatedReport = report.set('visualization', getVisualization(originalReport)).set('reportConfigs', reportConfigs);
  return updatedReport;
}
export const revertTableToChartReport = (report, originalReport) => {
  const updatedReport = report.withMutations(mutableReport => mutableReport.set('chartType', originalReport.get('chartType')).set('config', updateConfigLimitAndProcessors(report.get('config'), originalReport.getIn(['config', 'limit']), originalReport.getIn(['config', 'processors'], List()))));
  if (isMultiConfigReport(updatedReport) && isMultiConfigReport(originalReport)) {
    return revertTableToChartMultiConfigReport(updatedReport, originalReport);
  }
  return updatedReport;
};