'use es6';

import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as OperatorTypes from '../ObjectSegOperatorTypes';
import * as Operators from '../../../filterQueryFormat/operator/Operators';
import { __ANY_CTA } from 'customer-data-filters/converters/listSegClassic/ListSegConstants';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import invariant from 'react-utils/invariant';
import protocol from 'transmute/protocol';
import toRefinementFilter from './toRefinementFilter';
const CtaToCtaPlacement = {
  [OperatorTypes.HAS_CLICKED_CTA]: OperatorTypes.HAS_CLICKED_CTA_PLACEMENT,
  [OperatorTypes.HAS_NOT_CLICKED_CTA]: OperatorTypes.HAS_NOT_CLICKED_CTA_PLACEMENT,
  [OperatorTypes.HAS_OPENED_CTA]: OperatorTypes.HAS_OPENED_CTA_PLACEMENT,
  [OperatorTypes.HAS_NOT_OPENED_CTA]: OperatorTypes.HAS_NOT_OPENED_CTA_PLACEMENT
};
const _toCtaFilter = (operatorName, operator) => {
  const operatorValue = get('value', operator);
  const filter = {
    filterType: ObjectSegFilterTypes.CTA,
    operator: operatorName,
    ctaName: operatorValue
  };
  if (operatorValue === __ANY_CTA) {
    filter.operator = CtaToCtaPlacement[operatorName];
    filter.ctaName = getIn(['field', 'name'], operator);
  }
  const refinement = operator.get('refinement');
  if (refinement) {
    return Object.assign({}, filter, toRefinementFilter(refinement));
  }
  return filter;
};
const invalidOperator = operator => {
  invariant(false, 'cannot convert Operator "%s" to Cta filter', operator.name);
};
const toCtaFilter = protocol({
  name: 'toCtaFilter',
  args: [protocol.TYPE],
  fallback: invalidOperator
});
toCtaFilter.implement(Operators.CtaHasClicked, operator => _toCtaFilter(OperatorTypes.HAS_CLICKED_CTA, operator));
toCtaFilter.implement(Operators.CtaHasNotClicked, operator => _toCtaFilter(OperatorTypes.HAS_NOT_CLICKED_CTA, operator));
toCtaFilter.implement(Operators.CtaHasSeen, operator => _toCtaFilter(OperatorTypes.HAS_OPENED_CTA, operator));
toCtaFilter.implement(Operators.CtaHasNotSeen, operator => _toCtaFilter(OperatorTypes.HAS_NOT_OPENED_CTA, operator));
export default toCtaFilter;