'use es6';

import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import get from 'transmute/get';
import reduce from 'transmute/reduce';
import usePollingQuery from './usePollingQuery';
import { TEAMS_FIELD_NAME, FETCH_TEAMS } from '../requests/teams';
import { getSecondaryUserIds, getUserIds } from '../schemas/teamSchema';
export const useTeams = () => {
  const {
    data,
    error,
    loading
  } = usePollingQuery(FETCH_TEAMS);
  return {
    data: data ? data[TEAMS_FIELD_NAME] : ImmutableMap(),
    error,
    loading
  };
};
export const useUserSetInTeamIdSet = teamIdSet => {
  const {
    data: teams
  } = useTeams();
  return reduce(ImmutableSet(), (userIdSet, teamId) => {
    const team = get(teamId, teams);
    const memberUserIdList = getUserIds(team);
    const secondaryMemberUserIdList = getSecondaryUserIds(team);
    return userIdSet.concat(memberUserIdList, secondaryMemberUserIdList);
  }, teamIdSet);
};