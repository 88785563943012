'use es6';

import { DashboardUITracker, EVENTS } from './DashboardUITracker';

// Following the convention of other DashboardUI events by setting screen
// to the value 'dashboard'
const SCREEN = 'dashboard';
const DASHBOARD = 'dashboard';
const FORBIDDEN_DASHBOARD_PAGE = 'ForbiddenDashboardPage';
const DASHBOARD_CONTAINER = 'DashboardContainer';
const VIEW = 'view';
const SHARE = 'share';
export const trackViewForbiddenDashboard = dashboardType => {
  const event = EVENTS.viewForbiddenDashboard;
  DashboardUITracker.track(event, {
    assetType: DASHBOARD,
    actionType: VIEW,
    dashboardType,
    interactionWith: FORBIDDEN_DASHBOARD_PAGE,
    interactionSource: DASHBOARD_CONTAINER,
    screen: SCREEN
  });
};
export const trackRequestDashboardAccess = dashboardType => {
  const event = EVENTS.clickRequestDashboardAccess;
  DashboardUITracker.track(event, {
    actionType: SHARE,
    assetType: DASHBOARD,
    dashboardType,
    interactionWith: FORBIDDEN_DASHBOARD_PAGE,
    interactionSource: DASHBOARD_CONTAINER,
    screen: SCREEN
  });
};
export const trackViewDashboardAccessRequestResponse = (dashboardType, isActionSuccess) => {
  const event = EVENTS.viewDashboardAccessRequestAlert;
  DashboardUITracker.track(event, {
    actionType: VIEW,
    assetType: DASHBOARD,
    dashboardType,
    interactionWith: 'FloatingAlertStore',
    interactionSource: DASHBOARD_CONTAINER,
    isActionSuccess,
    screen: SCREEN
  });
};