import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
const getMetadata = async (objectTypeId, propertyName, httpClient) => {
  const client = await getFrameworkDataSchemasClient({
    httpClient
  });
  const property = await client.properties.getProperty({
    frameworkTypeIdentifier: objectTypeId,
    propertyName
  });
  if (property && property.externalOptionsMetaData) {
    return property.externalOptionsMetaData;
  }
  return undefined;
};
export const maybeGetExternalOptionsMetaData = async (objectTypeId, propertyName, httpClient) => {
  if (objectTypeId && propertyName) {
    try {
      const metadata = await getMetadata(objectTypeId, propertyName, httpClient);
      return metadata;
    } catch (e) {
      // Do nothing
    }
  }
  return undefined;
};