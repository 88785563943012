import { useFetchApiResolver, useSearchApiResolver } from './adapters/ApiHooks';
import { verifyReferenceType } from './internals/Invariants';
import { createPendingResolutionCache, resolveBatchFetchedReferences } from './internals/searchResolver/resolveBatchFetchedReferences';
import { resolveSearchedReferences } from './internals/searchResolver/resolveSearchedReferences';
import { applyByIdCache } from './internals/searchResolver/cache';
import { defaultIdIsValid } from './internals/defaultIdIsValid';
export const createSearchReferenceResolver = ({
  debouncePeriod = 500,
  enableCaching = true,
  fetchByIds,
  formatByIdResponse,
  formatSearchResponse,
  maxBatchSize = 500,
  referenceType,
  isIdValid = id => defaultIdIsValid(referenceType, id),
  fetchBySearch
}) => {
  verifyReferenceType(referenceType);
  const fetchByIdsWithFormatting = ids => fetchByIds(ids).then(formatByIdResponse);
  const fetchSearchWithFormatting = searchQuery => fetchBySearch(searchQuery).then(formatSearchResponse);
  const resolutionCache = createPendingResolutionCache();
  const resolveById = resolveBatchFetchedReferences({
    debouncePeriod,
    fetchByIds: fetchByIdsWithFormatting,
    isIdValid,
    maxBatchSize,
    resolutionCache
  });
  const resolveBySearch = resolveSearchedReferences(fetchSearchWithFormatting);
  const apiWithFormatting = {
    byId: id => resolveById(id),
    search: searchQuery => resolveBySearch(searchQuery)
  };
  const apiWithCaching = enableCaching ? applyByIdCache(apiWithFormatting) : Object.assign({}, apiWithFormatting, {
    // This is just to match the ResolverApi type, it's simpler for
    // consumers if this always exists and this will have the same
    // behavior as actually refreshing the cache
    refreshCache: apiWithFormatting.byId
  });
  return {
    api: apiWithCaching,
    referenceType,
    useFetchQuery: useFetchApiResolver,
    useSearchQuery: useSearchApiResolver
  };
};