import * as checked from 'reporting-data/lib/checked';
import { Encoding } from '../column-records';
import { AxisScaleType, ColorOption, dataLabelFormat, legendPosition, legendWidth, opacity, Scale, showBubbleLegend, showDataLabels, showRecordIds } from '../highcharts-settings';
// @ts-expect-error migrate upstream file
import { LegendWidthTypes } from 'reporting-visualizations/visualizations/commonRecords';
import { VisualTypes } from '../constants/visual-constants';
export const ScatterEncodings = checked.record({
  x: Encoding.optional(),
  y: Encoding.optional(),
  color: Encoding.optional(),
  detail: Encoding.optional(),
  sizing: Encoding.optional()
}, 'ScatterEncodings');
export const ScatterVisualOptions = checked.record({
  showBubbleLegend,
  showDataLabels: showDataLabels.defaultValue(false),
  showRecordIds,
  dataLabelFormat: dataLabelFormat.defaultValue('Y'),
  legendPosition,
  legendWidth: legendWidth.defaultValue({
    value: 0,
    type: LegendWidthTypes.AUTO
  }),
  color: ColorOption.defaultValue({}),
  xMinMax: Scale.defaultValue({}),
  yMinMax: Scale.defaultValue({}),
  yAxisType: AxisScaleType,
  xAxisType: AxisScaleType,
  opacity: opacity.defaultValue(75)
});
export const ScatterVisual = checked.record({
  type: checked.string().always(VisualTypes.SCATTER),
  encodings: ScatterEncodings,
  options: ScatterVisualOptions.defaultValue({})
}, 'ScatterVisual');