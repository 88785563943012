'use es6';

import I18n from 'I18n';
export const SCHEDULE_FREQUENCY = {
  NOW: 'NOW',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY'
};
export const DAYS = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7
};
export const MONTH_DAYS = Array.from(new Array(28).keys()).slice(1);
export const LAST_DAY_OF_MONTH = 28;
export const DEFAULT_SCHEDULED_HOUR = 8;
export const getFormattedDayLabels = ({
  scheduleFrequency,
  scheduleOnDays,
  isUtc
}) => {
  const date = isUtc ? I18n.moment.utc() : I18n.moment.portalTz();
  date.startOf('day');

  // Format days for weekly or monthly frequency (i.e. Monday, Tuesday, ... vs 1st, 13th, ...).
  const formattedDays = scheduleOnDays.map(day => {
    let dayToFormat = '';
    if (scheduleFrequency === SCHEDULE_FREQUENCY.WEEKLY) {
      date.day(day);
      dayToFormat = date.format('dddd');
    } else if (scheduleFrequency === SCHEDULE_FREQUENCY.MONTHLY) {
      if (day === LAST_DAY_OF_MONTH) {
        return I18n.text('reporting-action-components.actions.emailReport.dialog.form.emailFormSection.monthDays.lastDayOfMonthLowercase');
      }
      date.date(day);
      dayToFormat = date.format('Do');
    }
    return dayToFormat;
  });
  date.portalTz(); // Always returns moment in portalTz
  return formattedDays;
};
export const getFormattedTimeLabels = ({
  scheduleAtHour,
  isUtc
}) => {
  const date = isUtc ? I18n.moment.utc() : I18n.moment.portalTz();
  date.startOf('day');
  date.hour(scheduleAtHour);
  date.portalTz(); // Always returns moment in portalTz

  return {
    timezone: I18n.moment.portalTz().format('z'),
    time: date.format('LT')
  };
};