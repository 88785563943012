import { useCallback } from 'react';
import { isSucceeded } from 'reporting-data/asyncData/AsyncData';
import useFetchPropertyGroupsForEditors from '../../../hooks/useFetchPropertyGroupsForEditors';
import { getIsValidDimensionProperty } from '../../../lib/reportPropertyUtils';
const useFetchPropertyOptionsForDimensionEditorDropdown = report => {
  const resultFormatter = useCallback(response => {
    return response.map(group => {
      const propertyOptions = group.get('properties').filter(prop => {
        return getIsValidDimensionProperty(prop);
      }).map(prop => {
        return {
          text: prop.get('label'),
          value: prop.get('name')
        };
      }).sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
      return {
        text: group.get('displayName'),
        options: propertyOptions.toList().toJS()
      };
    }).filter(group => {
      return group.options.length;
    }).toList().toJS();
  }, []);
  const fetch = useFetchPropertyGroupsForEditors(report, resultFormatter);
  if (isSucceeded(fetch)) {
    return fetch.data;
  }
  return [];
};
export default useFetchPropertyOptionsForDimensionEditorDropdown;