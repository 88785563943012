import createChainablePropType from './createChainablePropType';
export const isValidColorHex = color => /^#?[0-9A-Fa-f]{0,6}$/.test(color);
const color = (props, propName, componentName) => {
  if (props[propName]) {
    const value = props[propName];
    if (!isValidColorHex(value)) {
      return new Error(`${componentName}: Invalid color value. Expected format RRGGBB, got "${value}".`);
    }
  }
  return null;
};
export const propType = createChainablePropType(color, 'color');