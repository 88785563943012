export const BETWEEN = 'BETWEEN';
export const GT = 'GT';
export const GTE = 'GTE';
export const LT = 'LT';
export const LTE = 'LTE';
export const EQ = 'EQ';
export const NEQ = 'NEQ';
export const IN = 'IN';
export const NOT_IN = 'NOT_IN';
export const HAS_PROPERTY = 'HAS_PROPERTY';
export const NOT_HAS_PROPERTY = 'NOT_HAS_PROPERTY';