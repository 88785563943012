'use es6';

import * as DisplayTypes from 'customer-data-filters/filterQueryFormat/DisplayTypes';
import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as Operators from '../../../filterQueryFormat/operator/Operators';
import { COALESCING_REFINE_BY, COALESCING_REFINE_BY_TYPE, PRUNING_REFINE_BY, PRUNING_REFINE_BY_TYPE, TIME_COMPARISON, TIME_DIRECTION, TIME_UNIT } from '../constants/constants';
import { NONE, PORTAL_TZ_ALIGNED } from '../../../filterQueryFormat/timestamps/TimestampTypes';
import { convertTimestampToSimpleDate } from '../../../filterQueryFormat/timestamps/TimestampConverters';
import DSFieldRecord from 'customer-data-filters/filterQueryFormat/DSFieldRecord/DSFieldRecord';
import getIn from 'transmute/getIn';
import invariant from 'react-utils/invariant';
import isNumber from 'transmute/isNumber';
import { BACKWARD, FORWARD } from '../../../filterQueryFormat/rollingDates/RollingDateDirections';
const makeDateRefinementField = field => {
  return DSFieldRecord({
    refinement: true,
    type: 'date',
    timestampType: field.get('timestampType')
  });
};
const makeNumberRefinementField = () => {
  return DSFieldRecord({
    displayType: DisplayTypes.CountDisplayType,
    refinement: true,
    type: 'number'
  });
};
const convertTimestamp = (field, timestamp) => {
  const timestampType = field.get('timestampType');
  if (timestampType === NONE) {
    return convertTimestampToSimpleDate(timestamp, PORTAL_TZ_ALIGNED);
  }
  return convertTimestampToSimpleDate(timestamp, timestampType);
};
const TIME_UNIT_MAPPING = {
  [TIME_UNIT.DAYS]: 'days',
  [TIME_UNIT.WEEKS]: 'weeks'
};
const fromObjectSegTimeUnit = timeUnit => TIME_UNIT_MAPPING[timeUnit];
const fromAbsoluteComparativeTimestampRefineByPruning = (field, filter) => {
  // Ads filters are the only ones that simple comparisons without or equals
  const useSimpleCompare = filter.filterType === ObjectSegFilterTypes.ADS_TIME;
  const comparison = getIn([PRUNING_REFINE_BY, 'comparison'], filter);
  let Operator;
  if (comparison === TIME_COMPARISON.BEFORE) {
    Operator = useSimpleCompare ? Operators.Less : Operators.LessOrEqual;
  } else if (comparison === TIME_COMPARISON.AFTER) {
    Operator = useSimpleCompare ? Operators.Greater : Operators.GreaterOrEqual;
  }
  invariant(Operator, `Unable to parse time refinement for absolute time comparison "${comparison}"`);
  return Operator.of(makeDateRefinementField(field), convertTimestamp(field, getIn([PRUNING_REFINE_BY, 'timestamp'], filter)));
};
const fromAbsoluteRangedTimestampRefineByPruning = (field, filter) => {
  const rangeType = getIn([PRUNING_REFINE_BY, 'rangeType'], filter);
  let Operator;
  if (rangeType === TIME_COMPARISON.BETWEEN) {
    Operator = Operators.InRange;
  } else if (rangeType === TIME_COMPARISON.NOT_BETWEEN) {
    Operator = Operators.NotInRange;
  }
  invariant(Operator, `Unable to parse time refinement for absolute time range of type "${rangeType}"`);
  return Operator.of(makeDateRefinementField(field), convertTimestamp(field, getIn([PRUNING_REFINE_BY, 'lowerTimestamp'], filter)), convertTimestamp(field, getIn([PRUNING_REFINE_BY, 'upperTimestamp'], filter)));
};
const fromRelativeComparativeTimestampRefineByPruning = (field, filter) => {
  const comparison = getIn([PRUNING_REFINE_BY, 'comparison'], filter);
  const timeOffset = getIn([PRUNING_REFINE_BY, 'timeOffset'], filter);
  const direction = timeOffset.offsetDirection;
  let Operator;
  let operatorDirection;
  if (comparison === TIME_COMPARISON.AFTER && direction === TIME_DIRECTION.PAST) {
    Operator = Operators.LessThanRolling;
    operatorDirection = 'backward';
  }
  if (comparison === TIME_COMPARISON.BEFORE && direction === TIME_DIRECTION.FUTURE) {
    Operator = Operators.LessThanRolling;
    operatorDirection = 'forward';
  }
  if (comparison === TIME_COMPARISON.BEFORE && direction === TIME_DIRECTION.PAST) {
    Operator = Operators.GreaterThanRolling;
    operatorDirection = 'backward';
  }
  if (comparison === TIME_COMPARISON.AFTER && direction === TIME_DIRECTION.FUTURE) {
    Operator = Operators.GreaterThanRolling;
    operatorDirection = 'forward';
  }
  invariant(Operator, `Unable to parse time refinement for relative time comparison "${comparison}"`);
  return Operator.of(makeDateRefinementField(field), timeOffset.amount, operatorDirection, fromObjectSegTimeUnit(timeOffset.timeUnit));
};
const fromRelativeRangedTimestampRefineByPruning = (field, filter) => {
  // the operators is LessThanRolling because it is the only operator
  // converted to RelativeRangedTimestampRefineBy
  const Operator = Operators.LessThanRolling;
  const offsetDirection = getIn(['pruningRefineBy', 'lowerBoundOffset', 'offsetDirection'], filter);
  const timeUnit = getIn(['pruningRefineBy', 'lowerBoundOffset', 'timeUnit'], filter);
  let amount;
  let direction;
  if (offsetDirection === TIME_DIRECTION.PAST) {
    direction = BACKWARD;
    amount = getIn(['pruningRefineBy', 'lowerBoundOffset', 'amount'], filter);
  } else {
    direction = FORWARD;
    amount = getIn(['pruningRefineBy', 'upperBoundOffset', 'amount'], filter);
  }
  return Operator.of(makeDateRefinementField(field), amount, direction, fromObjectSegTimeUnit(timeUnit));
};
const fromNumCoalescing = (__, filter) => {
  const refinementField = makeNumberRefinementField();
  const minOccurrences = parseInt(getIn([COALESCING_REFINE_BY, 'minOccurrences'], filter), 10);
  const maxOccurrences = parseInt(getIn([COALESCING_REFINE_BY, 'maxOccurrences'], filter), 10);
  const hasMin = isNumber(minOccurrences);
  const hasMax = isNumber(maxOccurrences);
  invariant(hasMin || hasMax, `Unable to parse occurrence refinement with min: "${minOccurrences}" and max: "${maxOccurrences}"`);
  if (minOccurrences === maxOccurrences) {
    return Operators.Equal.of(refinementField, minOccurrences);
  }
  return hasMin ? Operators.Greater.of(refinementField, minOccurrences) : Operators.Less.of(refinementField, maxOccurrences);
};
const FROM_PRUNE_FOR_TYPE = {
  [PRUNING_REFINE_BY_TYPE.ABSOLUTE]: fromAbsoluteComparativeTimestampRefineByPruning,
  [PRUNING_REFINE_BY_TYPE.ABSOLUTE_RANGE]: fromAbsoluteRangedTimestampRefineByPruning,
  [PRUNING_REFINE_BY_TYPE.RELATIVE]: fromRelativeComparativeTimestampRefineByPruning,
  [PRUNING_REFINE_BY_TYPE.RELATIVE_RANGE]: fromRelativeRangedTimestampRefineByPruning
};
const FROM_COALESCE_FOR_TYPE = {
  [COALESCING_REFINE_BY_TYPE.NUM_OCCURRENCES]: fromNumCoalescing
};
const fromRefinementFilter = (field, filter) => {
  const pruneType = getIn([PRUNING_REFINE_BY, 'type'], filter);
  if (Object.prototype.hasOwnProperty.call(FROM_PRUNE_FOR_TYPE, pruneType)) {
    return FROM_PRUNE_FOR_TYPE[pruneType](field, filter);
  }
  const coalesceType = getIn([COALESCING_REFINE_BY, 'type'], filter);
  if (Object.prototype.hasOwnProperty.call(FROM_COALESCE_FOR_TYPE, coalesceType)) {
    return FROM_COALESCE_FOR_TYPE[coalesceType](field, filter);
  }
  return undefined;
};
export default fromRefinementFilter;