'use es6';

import { fromJS, Set as ImmutableSet } from 'immutable';
const updateExistingProperties = (existingGroups, propertiesToUpdateOrAdd) => {
  let propertiesToUpdateOrAddKeys = ImmutableSet(Object.keys(propertiesToUpdateOrAdd));
  const mergedGroups = existingGroups.map(existingGroup => {
    return existingGroup.update('properties', properties => {
      return properties.map(property => {
        if (propertiesToUpdateOrAdd.hasOwnProperty(property.get('name'))) {
          propertiesToUpdateOrAddKeys = propertiesToUpdateOrAddKeys.remove(property.get('name'));
          return property.merge(propertiesToUpdateOrAdd[property.get('name')]);
        }
        return property;
      });
    });
  });
  return {
    mergedGroups,
    propertiesToAdd: propertiesToUpdateOrAddKeys.map(key => propertiesToUpdateOrAdd[key])
  };
};

//Add any remaining to-be-merged properties to the fallback group.
//This code assumes that the fallbackgroup already exists
const addNewPropertiesToFallbackGroup = (mergedGroups, fallbackGroupName, propertiesToAdd) => {
  const fallbackMergedGroupIndex = mergedGroups.findIndex(g => g.get('name') === fallbackGroupName);
  const fallbackMergedGroup = mergedGroups.get(fallbackMergedGroupIndex);
  if (fallbackMergedGroup) {
    return mergedGroups.update(fallbackMergedGroupIndex, g => g.update('properties', existingProps => existingProps.concat(propertiesToAdd.map(prop => fromJS(prop)))));
  }
  return mergedGroups;
};

/**
 * This updates properties within all property group.
 * For properties that already exist within some group, their value will be updated.
 * For properties that do _not_ already exist in _any_ group:
 *    if fallbackGroupName is an existing group, the brand-new properties will
 *       be added to it
 *    if it is not an existing group, the brand-new properties will not be added
 *       anywhere
 *
 * @param {*} existingGroups the original property groups we're updating
 * @param {*} fallbackGroupName the group that we will put any _new_ properties
 * into if they do not already exist in another group
 * @param {*} propertiesToUpdateOrAdd the new property values we are merging
 * into the existing property groups
 */
export const mergeProperties = (existingGroups, fallbackGroupName, propertiesToUpdateOrAdd) => {
  const {
    mergedGroups,
    propertiesToAdd
  } = updateExistingProperties(existingGroups, propertiesToUpdateOrAdd);
  return addNewPropertiesToFallbackGroup(mergedGroups, fallbackGroupName, propertiesToAdd);
};