import * as RollingDateOptionValues from './RollingDateOptionValues';
import { BACKWARD, FORWARD } from './RollingDateDirections';
import { DAY, MONTH, QUARTER, WEEK, YEAR } from './TimeUnits';
import { Map as ImmutableMap, Record, is } from 'immutable';
import isNumber from 'transmute/isNumber';
const isValidDirection = direction => {
  return direction === FORWARD || direction === BACKWARD;
};
const isValidTimeUnit = timeUnit => {
  return [DAY, MONTH, QUARTER, WEEK, YEAR].includes(timeUnit);
};
const isValidBoolean = maybeBool => {
  return maybeBool === true || maybeBool === false;
};
const RollingDateConfigDefaultValues = {
  direction: BACKWARD,
  // Determines whether or not to include days after today in the date range
  includeFutureDates: true,
  // Determines whether the final day in the range is included or not
  isInclusive: true,
  timeUnit: DAY,
  useFiscalYear: false,
  value: 1
};
const RollingDateConfig = Record(RollingDateConfigDefaultValues, 'RollingDateConfig');
export const validateRollingDateConfig = (opts = {}) => {
  const {
    direction,
    includeFutureDates,
    isInclusive,
    timeUnit,
    value
  } = opts;
  if (!isValidDirection(direction)) {
    return false;
  }
  if (!isValidTimeUnit(timeUnit)) {
    return false;
  }
  if (!isValidBoolean(isInclusive)) {
    return false;
  }
  if (!isValidBoolean(includeFutureDates)) {
    return false;
  }
  if (!isNumber(value) || value < 0) {
    return false;
  }
  return true;
};
const OptionValueToRollingDateConfig = {
  [RollingDateOptionValues.TODAY]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: true,
    timeUnit: DAY,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.YESTERDAY]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: false,
    timeUnit: DAY,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.TOMORROW]: RollingDateConfig({
    direction: FORWARD,
    isInclusive: false,
    timeUnit: DAY,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.THIS_WEEK]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: true,
    timeUnit: WEEK,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.THIS_WEEK_SO_FAR]: RollingDateConfig({
    direction: BACKWARD,
    includeFutureDates: false,
    isInclusive: true,
    timeUnit: WEEK,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.LAST_WEEK]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: false,
    timeUnit: WEEK,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.NEXT_WEEK]: RollingDateConfig({
    direction: FORWARD,
    isInclusive: false,
    timeUnit: WEEK,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.THIS_MONTH]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: true,
    timeUnit: MONTH,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.THIS_MONTH_SO_FAR]: RollingDateConfig({
    direction: BACKWARD,
    includeFutureDates: false,
    isInclusive: true,
    timeUnit: MONTH,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.LAST_MONTH]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: false,
    timeUnit: MONTH,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.NEXT_MONTH]: RollingDateConfig({
    direction: FORWARD,
    isInclusive: false,
    timeUnit: MONTH,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.THIS_QUARTER]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: true,
    timeUnit: QUARTER,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.THIS_FISCAL_QUARTER]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: true,
    timeUnit: QUARTER,
    useFiscalYear: true,
    value: 1
  }),
  [RollingDateOptionValues.THIS_QUARTER_SO_FAR]: RollingDateConfig({
    direction: BACKWARD,
    includeFutureDates: false,
    isInclusive: true,
    timeUnit: QUARTER,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.THIS_FISCAL_QUARTER_SO_FAR]: RollingDateConfig({
    direction: BACKWARD,
    includeFutureDates: false,
    isInclusive: true,
    timeUnit: QUARTER,
    useFiscalYear: true,
    value: 1
  }),
  [RollingDateOptionValues.LAST_QUARTER]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: false,
    timeUnit: QUARTER,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.LAST_FISCAL_QUARTER]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: false,
    timeUnit: QUARTER,
    useFiscalYear: true,
    value: 1
  }),
  [RollingDateOptionValues.NEXT_QUARTER]: RollingDateConfig({
    direction: FORWARD,
    isInclusive: false,
    timeUnit: QUARTER,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.NEXT_FISCAL_QUARTER]: RollingDateConfig({
    direction: FORWARD,
    isInclusive: false,
    timeUnit: QUARTER,
    useFiscalYear: true,
    value: 1
  }),
  [RollingDateOptionValues.THIS_YEAR]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: true,
    timeUnit: YEAR,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.THIS_FISCAL_YEAR]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: true,
    timeUnit: YEAR,
    useFiscalYear: true,
    value: 1
  }),
  [RollingDateOptionValues.THIS_YEAR_SO_FAR]: RollingDateConfig({
    direction: BACKWARD,
    includeFutureDates: false,
    isInclusive: true,
    timeUnit: YEAR,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.THIS_FISCAL_YEAR_SO_FAR]: RollingDateConfig({
    direction: BACKWARD,
    includeFutureDates: false,
    isInclusive: true,
    timeUnit: YEAR,
    useFiscalYear: true,
    value: 1
  }),
  [RollingDateOptionValues.LAST_YEAR]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: false,
    timeUnit: YEAR,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.LAST_FISCAL_YEAR]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: false,
    timeUnit: YEAR,
    useFiscalYear: true,
    value: 1
  }),
  [RollingDateOptionValues.NEXT_YEAR]: RollingDateConfig({
    direction: FORWARD,
    isInclusive: false,
    timeUnit: YEAR,
    useFiscalYear: false,
    value: 1
  }),
  [RollingDateOptionValues.NEXT_FISCAL_YEAR]: RollingDateConfig({
    direction: FORWARD,
    isInclusive: false,
    timeUnit: YEAR,
    useFiscalYear: true,
    value: 1
  }),
  [RollingDateOptionValues.LAST_7_DAYS]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: false,
    timeUnit: DAY,
    useFiscalYear: false,
    value: 7
  }),
  [RollingDateOptionValues.LAST_14_DAYS]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: false,
    timeUnit: DAY,
    useFiscalYear: false,
    value: 14
  }),
  [RollingDateOptionValues.LAST_30_DAYS]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: false,
    timeUnit: DAY,
    useFiscalYear: false,
    value: 30
  }),
  [RollingDateOptionValues.LAST_60_DAYS]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: false,
    timeUnit: DAY,
    useFiscalYear: false,
    value: 60
  }),
  [RollingDateOptionValues.LAST_90_DAYS]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: false,
    timeUnit: DAY,
    useFiscalYear: false,
    value: 90
  }),
  [RollingDateOptionValues.LAST_180_DAYS]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: false,
    timeUnit: DAY,
    useFiscalYear: false,
    value: 180
  }),
  [RollingDateOptionValues.LAST_365_DAYS]: RollingDateConfig({
    direction: BACKWARD,
    isInclusive: false,
    timeUnit: DAY,
    useFiscalYear: false,
    value: 365
  })
};
const OptionValueToRollingDateConfigMap = ImmutableMap(OptionValueToRollingDateConfig);
RollingDateConfig.fromRollingDateOptionValue = optionValue => OptionValueToRollingDateConfig[optionValue];
RollingDateConfig.toRollingDateOptionValue = rollingDateConfig => {
  const rollingDateEntry = OptionValueToRollingDateConfigMap.findEntry(knownConfig => is(knownConfig, rollingDateConfig));
  if (Array.isArray(rollingDateEntry)) {
    const [optionValue] = rollingDateEntry;
    return optionValue;
  }
  return undefined;
};
export default RollingDateConfig;