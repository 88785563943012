// @ts-expect-error migrate upstream
import { fromPairs, isNotNil, toPairs } from 'dashboard-lib/private/js-util';
import { parse, stringify } from 'hub-http/helpers/params';
import compose from 'transmute/compose';
import filter from 'transmute/filter';
import omit from 'transmute/omit';
const prepareQueryForParsing = queryString => queryString.replace(/^\?/, '');
const removeNilsFromQuery = query => {
  const removeNils = filter(([, value]) => isNotNil(value));
  return compose(fromPairs, removeNils, toPairs)(query);
};

/**
 * @param {string} - query string
 * @returns {object} - query object
 */
export const parseQueryString = compose(removeNilsFromQuery, parse, prepareQueryForParsing);
export const removeKeyFromQueryString = (history, key) => {
  const queryObj = parseQueryString(window.location.search);
  history.replace({
    search: stringify(omit([key], queryObj))
  });
};