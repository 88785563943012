/* eslint hubspot-dev/hubspot-is-special: 0 */

'use es6';

import { createSelector, createStructuredSelector } from '../lib/ImmutableReselect';
import DashboardStore from '../stores/DashboardStore';
import { defaultTo } from 'dashboard-lib/private/js-util';

//------------------------------------------------------------------------------
// Selectors
//------------------------------------------------------------------------------

const fetchedSelector = () => DashboardStore.get('fetched');
const dashboardsSelector = () => DashboardStore.get('dashboards');
const dashboardFetchedSelector = () => DashboardStore.get('dashboardFetched');
const dashboardFetchingSelector = () => DashboardStore.get('dashboardFetching');
const dashboardFetchFailedSelector = () => DashboardStore.get('dashboardFetchFailed');
const refreshingAllSelector = () => DashboardStore.get('refreshingAll');
/**
 * Returns the total count of all dashboards.
 */
const dashboardsCountSelector = () => {
  return DashboardStore.get('allDashboardsCount');
};
const visibleDashboardsSelector = createSelector(dashboardsSelector, dashboards => {
  return dashboards;
});
export default {
  stores: [DashboardStore],
  deref: createStructuredSelector({
    fetched: fetchedSelector,
    dashboardFetched: dashboardFetchedSelector,
    dashboardFetching: dashboardFetchingSelector,
    dashboardFetchFailed: dashboardFetchFailedSelector,
    dashboards: visibleDashboardsSelector,
    dashboardsCount: dashboardsCountSelector
  })
};
export const RefreshingAllDependency = {
  stores: [DashboardStore],
  deref: () => {
    return defaultTo(false, refreshingAllSelector());
  }
};
export const DashboardCountDependency = {
  stores: [DashboardStore],
  deref: () => {
    return defaultTo(null, dashboardsCountSelector());
  }
};
export const CustomReportCountDependency = {
  stores: [DashboardStore],
  deref: () => {
    return defaultTo(null, DashboardStore.get('customReportCount'));
  }
};