import { OrderedSet } from 'immutable';

// @ts-expect-error untyped import
import FilterOperatorEnumInput from 'customer-data-filters/components/operator/FilterOperatorEnumInput';
// @ts-expect-error untyped import
import { IN_LIST } from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import { IS_IN_LIST, IS_NOT_IN_LIST } from 'customer-data-filters/converters/objectSeg/ObjectSegOperatorTypes';
// @ts-expect-error untyped import
import * as DSAssetFamilies from 'customer-data-filters/filterQueryFormat/DSAssetFamilies/DSAssetFamilies';
import DSFieldRecord from 'customer-data-filters/filterQueryFormat/DSFieldRecord/DSFieldRecord';
// @ts-expect-error untyped import
import Any from 'customer-data-filters/filterQueryFormat/operator/Any';
import { ListIsMember, ListIsNotMember
// @ts-expect-error untyped import
} from 'customer-data-filters/filterQueryFormat/operator/Operators';
import { COMPANY, CONTACT, DEAL, TICKET, OBJECT_LIST } from 'customer-data-objects/constants/ObjectTypes';
import { INBOUND_DB_LIST } from 'customer-data-objects/property/ExternalOptionTypes';
import { INBOUND_DB_COMPANY_LIST, INBOUND_DB_CONTACT_LIST, INBOUND_DB_DEAL_LIST, INBOUND_DB_TICKET_LIST, INBOUND_DB_LISTS
// @ts-expect-error untyped import
} from 'reference-resolvers/constants/ReferenceObjectTypes';
const ObjectTypeToInboundDbListType = {
  [COMPANY]: INBOUND_DB_COMPANY_LIST,
  [CONTACT]: INBOUND_DB_CONTACT_LIST,
  [DEAL]: INBOUND_DB_DEAL_LIST,
  [TICKET]: INBOUND_DB_TICKET_LIST,
  [OBJECT_LIST]: INBOUND_DB_LISTS
};
export const getOperatorsListMembership = () => OrderedSet.of(ListIsMember, ListIsNotMember);
export const listMembershipFromObjectSegFilterStrict = filter => {
  const {
    operator,
    listId
  } = filter;
  const field = DSFieldRecord({
    name: listId,
    type: DSAssetFamilies.IN_LIST
  });
  if (operator === IS_IN_LIST) {
    return ListIsMember.of(field);
  }
  if (operator === IS_NOT_IN_LIST) {
    return ListIsNotMember.of(field);
  }
  return Any.of(field);
};
export const listMembershipToObjectSegFilterStrict = operation => {
  const listId = operation.getIn(['field', 'name']);
  if (operation instanceof ListIsMember) {
    return {
      filterType: IN_LIST,
      listId,
      operator: IS_IN_LIST
    };
  }
  if (operation instanceof ListIsNotMember) {
    return {
      filterType: IN_LIST,
      listId,
      operator: IS_NOT_IN_LIST
    };
  }

  // TODO -- what is this edge case?
  return {};
};
export const getReferencedObjectTypeListMembership = () => INBOUND_DB_LIST;
export const getInboundDbListType = filterFamily => {
  return ObjectTypeToInboundDbListType[filterFamily];
};
export const getInputComponentListMembership = () => FilterOperatorEnumInput;