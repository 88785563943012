'use es6';

import { Record, Set as ImmutableSet, List } from 'immutable';
const Team = Record({
  id: null,
  name: '',
  userIds: ImmutableSet(),
  parentTeamId: null,
  childTeams: List(),
  secondaryUserIds: List()
});
Team.from = json => new Team(Object.assign({}, json, {
  userIds: json.userIds && typeof json.userIds[Symbol.iterator] === 'function' ? ImmutableSet(json.userIds) : ImmutableSet(),
  teamIds: json.teamIds && typeof json.teamIds[Symbol.iterator] === 'function' ? ImmutableSet(json.teamIds) : ImmutableSet(),
  childTeams: json.childTeams && typeof json.childTeams[Symbol.iterator] === 'function' ? List(json.childTeams).map(Team.from) : List(),
  secondaryUserIds: json.secondaryUserIds && typeof json.secondaryUserIds[Symbol.iterator] === 'function' ? List(json.secondaryUserIds) : List()
}));
Team.fromMap = map => new Team(map).set('childTeams', map.get('childTeams').map(Team.fromMap));
Team.fromArray = arr => {
  return List.of(...arr).map(team => Team.from(team));
};
Team.fromReference = ref => {
  return Team.fromMap(ref.get('referencedObject'));
};
export default Team;