'use es6';

import { getAndCheck } from '../../common/get-and-check-param';
import { getAllProblems } from '../../common/get-problems-no-check';
import { limitExceeded } from '../../common/limits';
import { createProblem } from '../../common/problem';
import { CUSTOM_REPORT_COUNT, CUSTOM_REPORT_LIMIT, HAS_DATA_TYPE_ACCESS, REPORT_IS_CUSTOM, REPORT_IS_SNOWFLAKE, REPORT_IS_ATTRIBUTION, HAS_ATTRIBUTION_ACCESS, RESTRICTED_FIELD_LEVEL_PROPERTIES } from '../../params/all-params';
import { PERMISSION_DENIED } from '../view-report/problem';
import { LACKS_SNOWFLAKE_TEMPLATE_ACCESS } from '../../problems/snowflake-template-problem';
import { LACKS_PAYMENTS_REPORT_ACCESS } from '../../problems/payment-reporting-problem';
export const REPORT_INCOMPATIBLE_WITH_EXPORT = createProblem('REPORT_INCOMPATIBLE_WITH_EXPORT', params => getAndCheck(REPORT_IS_SNOWFLAKE, params));
const _customReportLimitExceeded = params => {
  const getter = key => getAndCheck(key, params);
  const reportIsCustom = getter(REPORT_IS_CUSTOM);
  const customReportCount = getter(CUSTOM_REPORT_COUNT);
  const customReportLimit = getter(CUSTOM_REPORT_LIMIT);
  return reportIsCustom && limitExceeded(customReportCount, customReportLimit);
};
export const CUSTOM_REPORT_LIMIT_EXCEEDED = createProblem('CUSTOM_REPORT_LIMIT_EXCEEDED', params => _customReportLimitExceeded(params));
export const LACKS_DATA_TYPE_ACCESS = createProblem('LACKS_DATA_TYPE_ACCESS', params => !getAndCheck(HAS_DATA_TYPE_ACCESS, params));
export const LACKS_ATTRIBUTION_REPORT_ACCESS = createProblem('LACKS_ATTRIBUTION_REPORT_ACCESS', params => getAndCheck(REPORT_IS_ATTRIBUTION, params) && !getAndCheck(HAS_ATTRIBUTION_ACCESS, params));
export const LACKS_FIELD_ACCESS = createProblem('LACKS_FIELD_ACCESS', params => {
  const restrictedFieldLevelProperties = getAndCheck(RESTRICTED_FIELD_LEVEL_PROPERTIES, params);
  return restrictedFieldLevelProperties && restrictedFieldLevelProperties.length > 0;
});
export const rankedProblems = [PERMISSION_DENIED, LACKS_ATTRIBUTION_REPORT_ACCESS, LACKS_DATA_TYPE_ACCESS, LACKS_FIELD_ACCESS, CUSTOM_REPORT_LIMIT_EXCEEDED, LACKS_SNOWFLAKE_TEMPLATE_ACCESS, LACKS_PAYMENTS_REPORT_ACCESS];
export const getAll = params => getAllProblems(params, rankedProblems);