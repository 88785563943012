import { categoricalVariableOnX, categoricalVariableOnY, visualTypeIsXY } from 'reporting-snowflake/relational/utils/visual-utils';
import { Transform, TransformTypes } from 'reporting-snowflake/relational/schema/column-records';
import { getDateTruncTransformValue, getDimensions, hasDateTrunc } from 'reporting-snowflake/relational/utils/column-utils';
import { getReportColorEncoding, getReportXEncoding, getReportYEncoding } from 'reporting-snowflake/relational/utils/report-visual-utils';
import { isList } from 'reporting-snowflake/shared/lib/utility-types';
import { getReportVisualType } from 'reporting-snowflake/relational/utils/report-utils';
const getCategoricalColumn = reportDefinition => {
  const visualType = getReportVisualType(reportDefinition);
  if (!visualType) {
    return undefined;
  }
  if (visualTypeIsXY(visualType)) {
    if (categoricalVariableOnX(visualType)) {
      const xEncoding = getReportXEncoding(reportDefinition);
      if (xEncoding && !isList(xEncoding)) {
        return xEncoding.column;
      }
    }
    if (categoricalVariableOnY(visualType)) {
      const yEncoding = getReportYEncoding(reportDefinition);
      if (yEncoding && !isList(yEncoding)) {
        return yEncoding.column;
      }
    }
  }
  return undefined;
};
const getBreakdownColumn = reportDefinition => {
  const visualType = getReportVisualType(reportDefinition);
  if (!visualType) {
    return undefined;
  }
  if (visualTypeIsXY(visualType)) {
    const breakdownEncoding = getReportColorEncoding(reportDefinition);
    if (breakdownEncoding && !isList(breakdownEncoding)) {
      return breakdownEncoding.column;
    }
  }
  return undefined;
};
const getLesserFrequencyTimeUnit = frequency => {
  if (frequency === 'YEAR') {
    return 'QUARTER';
  }
  if (frequency === 'QUARTER') {
    return 'MONTH';
  }
  if (frequency === 'MONTH') {
    return 'WEEK';
  }
  return 'DAY';
};
const overrideColumnsFrequency = (column, frequency, dashboardFrequencyUseFiscalYear) => {
  if (hasDateTrunc(column)) {
    return column.set('transform', Transform({
      type: TransformTypes.DATE_TRUNC,
      arguments: [frequency]
    })).set('useFiscalYear', dashboardFrequencyUseFiscalYear);
  }
  return column;
};
const updateReportsColumn = (reportDefinition, column) => reportDefinition.setIn(['columns', column.alias], column);
const overrideXYVisualFrequency = (reportDefinition, frequency, dashboardFrequencyUseFiscalYear) => {
  const visualType = getReportVisualType(reportDefinition);
  if (!visualType) {
    return reportDefinition;
  }
  const breakdownColumn = getBreakdownColumn(reportDefinition);
  const categoricalColumn = getCategoricalColumn(reportDefinition);
  if (categoricalColumn) {
    const categoricalOverriddenReportDefinition = updateReportsColumn(reportDefinition, overrideColumnsFrequency(categoricalColumn, frequency, dashboardFrequencyUseFiscalYear));
    if (breakdownColumn) {
      return updateReportsColumn(categoricalOverriddenReportDefinition, overrideColumnsFrequency(breakdownColumn, getLesserFrequencyTimeUnit(frequency), dashboardFrequencyUseFiscalYear));
    }
  }
  if (breakdownColumn) {
    return updateReportsColumn(reportDefinition, overrideColumnsFrequency(breakdownColumn, frequency, dashboardFrequencyUseFiscalYear));
  }
  return reportDefinition;
};
const overrideSingleDimensionFrequency = (reportDefinition, frequency, dashboardFrequencyUseFiscalYear) => {
  const dateTruncDimensions = getDimensions(reportDefinition.columns).toList().filter(hasDateTrunc);
  if (dateTruncDimensions && dateTruncDimensions.size === 1) {
    const columnToOverride = dateTruncDimensions.get(0);
    return updateReportsColumn(reportDefinition, overrideColumnsFrequency(columnToOverride, frequency, dashboardFrequencyUseFiscalYear));
  }
  return reportDefinition;
};
export const canOverrideReportDefinitionsFrequency = (reportDefinition, dashboardFrequency, dashboardFrequencyUseFiscalYear = false) => {
  if (!dashboardFrequency) {
    return false;
  }
  const visualType = getReportVisualType(reportDefinition);
  if (!visualType) {
    return false;
  }
  const dimensionsWithFrequency = getDimensions(reportDefinition.columns).toList().filter(hasDateTrunc);

  /** dashboard frequency is already set */
  const frequencyDimensionsContainDashboardFrequency = dimensionsWithFrequency.some(dimension => getDateTruncTransformValue(dimension) === dashboardFrequency && dimension.get('useFiscalYear') === dashboardFrequencyUseFiscalYear);
  if (frequencyDimensionsContainDashboardFrequency) {
    return false;
  }
  if (dimensionsWithFrequency.size === 1) {
    return true;
  }
  if (visualTypeIsXY(visualType) && dimensionsWithFrequency.size === 2) {
    return true;
  }
  return false;
};
export const overrideReportDefinitionsFrequency = (reportDefinition, dashboardFrequency, dashboardFrequencyUseFiscalYear = false) => {
  const visualType = getReportVisualType(reportDefinition);
  if (!visualType) {
    return reportDefinition;
  }
  if (!canOverrideReportDefinitionsFrequency(reportDefinition, dashboardFrequency, dashboardFrequencyUseFiscalYear)) {
    return reportDefinition;
  }
  const dimensionsWithFrequency = getDimensions(reportDefinition.columns).toList().filter(hasDateTrunc);

  /** dashboard frequency is already set */
  const frequencyDimensionsContainDashboardFrequency = dimensionsWithFrequency.some(dimension => getDateTruncTransformValue(dimension) === dashboardFrequency && dimension.get('useFiscalYear') === dashboardFrequencyUseFiscalYear);
  if (frequencyDimensionsContainDashboardFrequency) {
    return reportDefinition;
  }
  if (dimensionsWithFrequency.size === 1) {
    return overrideSingleDimensionFrequency(reportDefinition, dashboardFrequency, dashboardFrequencyUseFiscalYear);
  }
  if (visualTypeIsXY(visualType)) {
    return overrideXYVisualFrequency(reportDefinition, dashboardFrequency, dashboardFrequencyUseFiscalYear);
  }
  return reportDefinition;
};
export const __TESTABLE__ = {
  overrideSingleDimensionFrequency,
  overrideXYVisualFrequency
};