import { isConfigReport } from 'reporting-data/tsTypes/reportTypes';
import { getDimensions } from 'reporting-data/report/configReportGetters';
import { getIsReportStacked, getReportChartType, getReportStackingType } from 'reporting-data/report/reportGetters';
import { AREA, BAR, COLUMN, LINE } from 'reporting-data/constants/chartTypes';
export const getIsReportValidForReportStackingDropdown = ({
  report
}) => {
  var _getDimensions;
  return isConfigReport(report) && ((_getDimensions = getDimensions(report)) === null || _getDimensions === void 0 ? void 0 : _getDimensions.size) > 1 && [LINE, AREA, BAR, COLUMN].includes(getReportChartType(report) || '');
};
export const getStackedValueFromConfigReportForDropdown = report => {
  const isReportStacked = getIsReportStacked(report);
  const reportStackingType = getReportStackingType(report);
  if (isReportStacked && reportStackingType === 'percent') {
    return 'percent';
  } else {
    return !!isReportStacked;
  }
};