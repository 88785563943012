import cached from './cached';
import development from './development';
const colors = ['#ea90b1', '#fea58e', '#f5c78e', '#a2d28f', '#51d3d9', '#81c1fd', '#bda9ea', '#9784c2'];
const next = ((index = 0) => () => {
  return colors[index++ % colors.length];
})();
export const debug = cached('debug', namespace => {
  const color = next();
  const logger = (...args) => {
    if (development()) {
      const styles = `color: ${color}; font-weight: bold;`;
      // eslint-disable-next-line no-console
      console.log(`%c${namespace}`, styles, ...args);
    }
  };
  logger.once = cached(logger);
  return logger;
});

// @ts-expect-error TODO remove usages
debug.log = (namespace, ...args) => debug(namespace)(...args);

// @ts-expect-error TODO remove usages
debug.once = (namespace, ...args) => debug(namespace).once(...args);