'use es6';

import * as ObjectTypeIds from 'customer-data-objects/constants/ObjectTypeIds';
import * as DSAssetFamilies from 'customer-data-filters/filterQueryFormat/DSAssetFamilies/DSAssetFamilies';
import { CustomObjectTypeIdRegex, isCustomBehavioralEventObjectTypeId, isUnifiedEventObjectTypeId, ObjectTypeIdRegex } from '../../filterQueryFormat/ObjectTypeId';
import { HUBSPOT_DEFINED, USER_DEFINED } from '../../filterQueryFormat/associations/AssociationCategory';
import { getObjectName, getIntegrationName, getUnifiedEventCategoryGroupName, getUnifiedEventName, getCustomBehavioralEventName } from './filterFamilyEntityNameGetters';
import { testDynamicFilterFamily } from '../../filterQueryFormat/DynamicFilterFamilies';
import I18n from 'I18n';
import getIn from 'transmute/getIn';
import { objectHasCustomLabels } from '../../utilities/filterFamilyTranslationConfig';
import { FilterFamilyObjectTypeIdTranslator, LegacyFilterFamilyObjectNameTranslator } from '../FilterFamilyTranslator';
import { Map as ImmutableMap } from 'immutable';
import DSFieldRecord from '../../filterQueryFormat/DSFieldRecord/DSFieldRecord';
import { AppObjectTypeIdRegex } from '../../filterQueryFormat/ObjectTypeId';

/**
 * Extract association id and category from filter branch
 */
export const getAssociationSpecFromFilterBranch = filterBranch => {
  const {
    associationTypeId,
    associationCategory
  } = filterBranch;
  return {
    associationTypeId,
    associationCategory
  };
};

/**
 * Get an internal primary association between two objects.
 *
 * Association is considered primary if it has "ONE_TO_ONE" cardinality
 * or the lowest `id`
 * (see https://hubspot.slack.com/archives/C01B5SP6Y76/p1603380387131400)
 */
export const getInternalPrimaryAssociation = associationsBetweenObjects => {
  let primaryAssociation;
  for (const association of associationsBetweenObjects.values()) {
    if (association.cardinality === 'ONE_TO_ONE') {
      primaryAssociation = association;
      break;
    }
    if (!primaryAssociation || association.id < primaryAssociation.id) {
      primaryAssociation = association;
    }
  }
  return primaryAssociation;
};

/**
 * Get a wildcard association between two object types.
 *
 * Wildcard is an association that always exists between two
 * records of two different object types
 */
export const getWildcardAssociation = associationsBetweenObjects => {
  return associationsBetweenObjects.find(assn => assn.hasAllAssociatedObjects);
};
export const isWildcardAssociation = association => {
  return association.hasAllAssociatedObjects === true;
};

/**
 * Check whether given association is a public "Primary" association
 */
export const isPublicPrimaryAssociation = association => association.isPrimary;
export const isInversePrimaryAssociation = association => association.isInversePrimary;

/**
 * Get a default association between two object types
 *
 * If provided object types have more then one association they should have
 * a wildcard association, if not use internal primary association
 */
export const getDefaultAssociation = (fromObjectTypeId, toObjectTypeId, associationDefinitions = ImmutableMap()) => {
  const associationsBetweenObjects = associationDefinitions.getIn([fromObjectTypeId, toObjectTypeId]);
  if (fromObjectTypeId === toObjectTypeId || !associationsBetweenObjects) {
    return undefined;
  }
  return getWildcardAssociation(associationsBetweenObjects) || getInternalPrimaryAssociation(associationsBetweenObjects);
};
export const makeAssociationOption = (id, category) => {
  return `${id}--${category}`;
};
export const parseAssociationOption = assnOption => {
  const [id, category] = assnOption.split('--');
  return {
    id: parseInt(id, 10),
    category
  };
};
const enabledFlexibleAssociations = Object.freeze({
  [ObjectTypeIds.CONTACT_TYPE_ID]: Object.freeze({
    [ObjectTypeIds.CONTACT_TYPE_ID]: true,
    [ObjectTypeIds.COMPANY_TYPE_ID]: true,
    [ObjectTypeIds.DEAL_TYPE_ID]: true,
    [ObjectTypeIds.TICKET_TYPE_ID]: true
  }),
  [ObjectTypeIds.COMPANY_TYPE_ID]: Object.freeze({
    [ObjectTypeIds.CONTACT_TYPE_ID]: true,
    [ObjectTypeIds.COMPANY_TYPE_ID]: true,
    [ObjectTypeIds.DEAL_TYPE_ID]: true,
    [ObjectTypeIds.TICKET_TYPE_ID]: true
  }),
  [ObjectTypeIds.DEAL_TYPE_ID]: Object.freeze({
    [ObjectTypeIds.COMPANY_TYPE_ID]: true,
    [ObjectTypeIds.CONTACT_TYPE_ID]: true,
    [ObjectTypeIds.DEAL_TYPE_ID]: true,
    [ObjectTypeIds.TICKET_TYPE_ID]: true
  }),
  [ObjectTypeIds.TICKET_TYPE_ID]: Object.freeze({
    [ObjectTypeIds.COMPANY_TYPE_ID]: true,
    [ObjectTypeIds.CONTACT_TYPE_ID]: true,
    [ObjectTypeIds.DEAL_TYPE_ID]: true,
    [ObjectTypeIds.TICKET_TYPE_ID]: true
  })
});
export const isUngatedForFlexibleAssociationsSelect = (fromObjectTypeId, toObjectTypeId) => {
  const isStandardObjectsEnabled = getIn([fromObjectTypeId, toObjectTypeId], enabledFlexibleAssociations);
  if (isStandardObjectsEnabled) {
    return true;
  }
  const isCustomObject = CustomObjectTypeIdRegex.test(fromObjectTypeId) || CustomObjectTypeIdRegex.test(toObjectTypeId);
  if (isCustomObject) {
    return true;
  }
  return false;
};
export const isAssociationBranch = group => {
  return typeof group.get('associationTypeId') === 'number' && [HUBSPOT_DEFINED, USER_DEFINED].includes(group.get('associationCategory'));
};
export const getAssociationValueFromFilterBranch = group => {
  if (!isAssociationBranch(group)) {
    return undefined;
  }
  return makeAssociationOption(group.get('associationTypeId'), group.get('associationCategory'));
};
export const convertAssnDefinitionToOption = (assn, objectName) => {
  let text;
  let isPublicPrimary = false;
  let isWildcard = false;
  if (isPublicPrimaryAssociation(assn)) {
    text = I18n.text('customerDataFilters.FilterFamilyGroupHeadingTranslator.CRM_OBJECT_PRIMARY', {
      entityName: objectName
    });
    isPublicPrimary = true;
  } else if (isWildcardAssociation(assn)) {
    text = I18n.text('customerDataFilters.FilterFamilyGroupHeadingTranslator.CRM_OBJECT_ANY', {
      entityName: objectName
    });
    isWildcard = true;
  } else {
    text = assn.label;
  }
  return {
    value: makeAssociationOption(assn.id, assn.category),
    text,
    isPublicPrimary,
    isWildcard,
    associatedObjectName: objectName
  };
};
export const makeGetFilterFamilyEntityName = ({
  data
}) => {
  return (filterFamily, isPlural) => {
    if (isUnifiedEventObjectTypeId(filterFamily)) {
      return getUnifiedEventName(filterFamily, data.get(DSAssetFamilies.UNIFIED_EVENT));
    }
    if (isCustomBehavioralEventObjectTypeId(filterFamily)) {
      return getCustomBehavioralEventName(filterFamily, data.get(DSAssetFamilies.CUSTOM_BEHAVIORAL_EVENT));
    }
    if (ObjectTypeIdRegex.test(filterFamily)) {
      if (objectHasCustomLabels(filterFamily)) {
        return FilterFamilyObjectTypeIdTranslator(filterFamily);
      }
      const isCustomObject = CustomObjectTypeIdRegex.test(filterFamily);
      const isAppObject = AppObjectTypeIdRegex.test(filterFamily);
      const objectDefinitions = isCustomObject ? data.get(DSAssetFamilies.CUSTOM_OBJECT_DEFINITION) : isAppObject ? data.get(DSAssetFamilies.APP_OBJECT_DEFINITION) : data.get(DSAssetFamilies.STANDARD_OBJECT_DEFINITION);
      return getObjectName({
        objectTypeId: filterFamily,
        objectDefinitions,
        isPlural
      });
    }
    if (testDynamicFilterFamily(DSAssetFamilies.INTEGRATION, filterFamily)) {
      return getIntegrationName(filterFamily, data.get(DSAssetFamilies.INTEGRATION));
    }
    if (testDynamicFilterFamily(DSAssetFamilies.UNIFIED_EVENT, filterFamily)) {
      return getUnifiedEventCategoryGroupName(filterFamily, data.get(DSAssetFamilies.UNIFIED_EVENT));
    }
    return LegacyFilterFamilyObjectNameTranslator(filterFamily);
  };
};
export const getObjectAssociationDefinitions = (baseFilterFamily, assetFamily, data) => {
  const associations = getIn([DSAssetFamilies.ASSOCIATION_DEFINITION, baseFilterFamily, assetFamily], data);
  if (associations) {
    return associations.valueSeq().toArray();
  }
  return undefined;
};
export const getUnifiedEventsCategoryGroupFields = (categoryGroupName, props) => {
  const {
    data,
    getIsUngated,
    hasScope
  } = props;
  const unifiedEvents = data.get(DSAssetFamilies.UNIFIED_EVENT);
  return unifiedEvents.filter(ue => {
    const hasAccess = ue.get('scopes').every(scope => hasScope(scope)) && ue.get('gates').every(gate => getIsUngated(gate));
    return ue.categoryGroupName === categoryGroupName && hasAccess;
  }).map(ue => DSFieldRecord({
    name: ue.objectTypeId,
    label: ue.label
  }));
};
export const getCustomBehavioralEventGroupFields = (customBehavioralEvents = ImmutableMap()) => {
  return customBehavioralEvents.map(ue => DSFieldRecord({
    name: ue.objectTypeId,
    label: ue.label
  }));
};