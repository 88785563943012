const generateHash = input => Math.abs(input.split('').map(char => char.charCodeAt(0)).reduce( /* eslint-disable no-bitwise */
(hash, char) => (hash << 5) - hash + char | 0, 0));
const getUniqueFilterFields = ({
  filter: {
    property,
    operation
  }
}) => ({
  property,
  operator: operation.operator,
  value: operation.value,
  values: operation.values
});
export const generateJourneyFilterId = (filter, {
  stageIndex = -1,
  stepIndex = -1,
  listIndex = 0
} = {}) => generateHash(`${JSON.stringify(getUniqueFilterFields(filter))}_${stageIndex}_${stepIndex}${listIndex !== 0 ? `_${listIndex}` : ''}`);
export const generateJourneyFilterGroupId = filters => filters.length === 1 ? filters[0].id : generateHash(filters.map(({
  id
}) => id.toString()).join('_'));
const generateBreakdownHash = breakdown => `_b${generateHash(JSON.stringify({
  numberOfStepsRequested: breakdown.numberOfStepsRequested,
  includeNullBreakdownStep: breakdown.includeNullBreakdownStep,
  field: {
    name: breakdown.field.name,
    table: breakdown.field.table,
    source: breakdown.field.source,
    type: breakdown.field.type
  }
}))}`;
const generatePathfinderHash = ({
  info,
  field
}) => `_p${generateHash(JSON.stringify({
  numberOfStepsRequested: info.numberOfStepsRequested,
  includeNullValueStep: info.includeNullValueStep,
  pathfinderType: info.pathfinderType,
  field: {
    name: field.name,
    table: field.table,
    source: field.source,
    type: field.type
  }
}))}`;
export const createJourneyStepAlias = (stageIndex, stepIndex, query, {
  breakdown,
  pathfinder
} = {}) => {
  return `${query.table.name}_${stageIndex}_${stepIndex}${query.filtering ? `_${query.filtering.groups[0].id}` : ''}${breakdown ? generateBreakdownHash(breakdown) : ''}${pathfinder ? generatePathfinderHash(pathfinder) : ''}`;
};