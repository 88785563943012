'use es6';

import { List, fromJS, Map as ImmutableMap } from 'immutable';
import { TABLE, COLUMN, BAR, DATA_WELL } from 'reporting-data/constants/chartTypes';
import { TIME_SERIES, AGGREGATION } from 'reporting-data/constants/configTypes';
import { AVG, COUNT, SUM } from 'reporting-data/constants/metricTypes';
import { IN } from 'reporting-data/constants/operators';
import { getCustomWidgetType } from 'reporting-data/report/reportGetters';
import CustomWidgets from 'reporting-ui-components/components/customWidgets/constants/widgets';
import { transformReportChartType } from 'reporting-data/report/report-transformations/chartTypeTransformations';
import { mapPropertyToSearchProperty } from 'reporting-data/retrieve/inboundDb/common/mapToSearchProperties';
import { getTimestampProperty, TEAM_GROUPING_TYPE, DIMENSIONS, getDimensionProperty, ACCESSIBLE_TEAM_PROPERTY, canGroupByAllAccessibleTeams, getStaticDimension, canDisplayStaticGoal, getDataTypeName, isJourneyReport, getDrilldownPropertiesForGrouping, hasAverageMetric, isUnifiedAnalyticsReport, getBreakdownProperty, getDataType } from '../../utils/salesAnalyticsHelpers';
import { slug } from 'reporting-data/lib/slug';
import { getCachedPipelines } from '../../data/pipelinesDao';
import { getMetricFieldsFromKey, getMetricKey, getMetricTooltip } from '../../constants/dataTypeMetricOptions';
import { deriveGroupingFromReport } from '../../constants/sub-app/contextDefaults';
import { getStageIdFromStageValue } from '../../utils/stageHelpers';
import { getFilteredBreakdownValuesFromKeys } from '../../utils/filteredBreakdownsHelpers';
import { checkHasUpgradeEngagements } from '../../utils/access';
import MetricControl from '../../components/reportCard/controls/MetricControl';
import MultiMetricControl from '../../components/controls/MultiMetricControl';
import SecondDimensionControl from '../../components/controls/SecondDimensionControl';
import { isCustomWidgetReport } from 'reporting-data/tsTypes/reportTypes';
import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
const CHART_LIMIT = 15;
const MULTI_DIMENSIONAL_CHART_LIMIT = 10;
export const QuotasMutator = ({
  report,
  context: {
    grouping,
    intraperiod
  }
}) => {
  const isOverTime = grouping === DIMENSIONS.DATE;
  return report.setIn(['displayParams', 'customWidget'], fromJS({
    type: 'SALES_QUOTA',
    options: {
      intraperiod: isOverTime && intraperiod
    }
  })).setIn(['config', 'intraPeriod'], isOverTime && intraperiod);
};
export const DealCountTableMutator = ({
  report
}) => !report.get('id') && report.get('chartType') === TABLE && report.getIn(['config', 'dimensions'], List()).count() < 2 ? report.updateIn(['config', 'metrics'], (metrics = List()) => metrics.concat(fromJS([{
  property: 'count',
  metricTypes: [COUNT]
}]))) : report;
export const ChartTypeMutator = ({
  report,
  context: {
    chartType
  }
}) => transformReportChartType(report, chartType);
export const WaterfallChartTypeMutator = ({
  report
}) => report.get('chartType') !== TABLE ? report.set('chartType', 'WATERFALL') : report;
const getGroupingDimensions = (report, subAppKey, grouping, teamGroupingType, gates) => {
  if (!grouping) {
    return [];
  } else if (grouping === DIMENSIONS.TEAM && teamGroupingType === TEAM_GROUPING_TYPE.ACCESSIBLE_TEAM && canGroupByAllAccessibleTeams(report)) {
    return [ACCESSIBLE_TEAM_PROPERTY];
  }
  return [getDimensionProperty(report, subAppKey, grouping, gates)];
};
const applyDimensionSideEffects = (report, dimensions, isOverTime) => {
  if (getCustomWidgetType(report) === CustomWidgets.PIPELINE_SNAPSHOTS) {
    return report;
  }
  if (report.get('chartType') === TABLE) {
    return report.setIn(['displayParams', 'showTotalsColumn'], isOverTime && !hasAverageMetric(report));
  }
  if (dimensions.isEmpty()) {
    return report.set('chartType', DATA_WELL);
  }
  const multiDimensional = dimensions.size > 1;
  const stacked = !hasAverageMetric(report) && multiDimensional;
  const chartLimit = multiDimensional && !stacked ? MULTI_DIMENSIONAL_CHART_LIMIT : CHART_LIMIT;
  return report.set('chartType', isOverTime ? COLUMN : BAR).setIn(['config', 'limit'], isOverTime ? null : chartLimit).setIn(['displayParams', 'stacked'], stacked);
};
export const DimensionsMutator = ({
  report,
  subAppKey,
  context: {
    breakdown,
    grouping,
    teamGroupingType
  },
  filteredGates
}) => {
  if (report.get('chartType') === DATA_WELL) {
    return report;
  }

  // we don't want to mutate UA table dimensions but we need to make a non function mutation to trigger a re-render
  if (isUnifiedAnalyticsReport(report) && report.get('chartType') === TABLE) {
    const displayParamContextPath = ['displayParams', 'salesAnalytics', 'context'];
    return report.setIn([...displayParamContextPath, 'grouping'], grouping).setIn([...displayParamContextPath, 'breakdown'], breakdown);
  }
  const timestampProperty = getTimestampProperty(report, filteredGates);
  const groupingDimensions = getGroupingDimensions(report, subAppKey, grouping, teamGroupingType, filteredGates);
  const isOverTime = grouping === DIMENSIONS.DATE;
  const dimensions = isOverTime ? [timestampProperty] : groupingDimensions;
  const prevReportGrouping = deriveGroupingFromReport(report, subAppKey, filteredGates);
  const staticDimension = getStaticDimension(report, subAppKey, prevReportGrouping);
  const hasUpgradeEngagements = checkHasUpgradeEngagements(filteredGates);
  const updatedStaticDimension = hasUpgradeEngagements ? mapPropertyToSearchProperty(staticDimension, getDataType(report)) : staticDimension;
  if (SecondDimensionControl.isUsableForReport({
    subAppKey,
    filteredGates
  }) && breakdown) {
    dimensions.push(getBreakdownProperty(subAppKey, breakdown));
  } else if (updatedStaticDimension) {
    dimensions.push(updatedStaticDimension);
  }
  const updatedReport = report.setIn(['config', 'dimensions'], List(dimensions)).setIn(['config', 'configType'], isOverTime ? TIME_SERIES : AGGREGATION);
  return applyDimensionSideEffects(updatedReport, List(dimensions), isOverTime);
};
export const FrequencyMutator = ({
  report,
  context: {
    frequency
  }
}) => report.setIn(['config', 'frequency'], frequency);
export const CompareMutator = ({
  report,
  context: {
    compare
  }
}) => report.setIn(['config', 'compare'], report.get('chartType') !== TABLE ? compare : null);
export const ColorThemeMutator = ({
  report,
  context: {
    colorTheme
  }
}) => report.setIn(['displayParams', 'colors', 'theme'], colorTheme);
export const StaticGoalMutator = ({
  report,
  subAppKey,
  context: {
    staticGoal
  }
}) => {
  if (!canDisplayStaticGoal(report, subAppKey) || !staticGoal) {
    return report;
  }
  return report.setIn(['displayParams', 'customSeries'], fromJS([{
    type: 'datetime.goal',
    timeUnit: 'MONTH',
    goal: staticGoal
  }]));
};
export const ChartMetricMutator = ({
  report,
  context: {
    metric
  }
}) => {
  if (!metric || !MetricControl.isUsableForReport({
    report
  })) {
    return report;
  }
  const {
    property,
    metricTypes
  } = getMetricFieldsFromKey(metric);
  return report.setIn(['config', 'metrics'], fromJS([{
    property,
    metricTypes
  }]));
};
export const ChartMetricsMutator = ({
  report,
  context: {
    chartMetrics
  }
}) => {
  if (!MultiMetricControl.isUsableForReport({
    report
  })) {
    return report;
  }
  return report.setIn(['config', 'metrics'], chartMetrics.map(metricKey => fromJS(getMetricFieldsFromKey(metricKey))));
};
export const TooltipMutator = ({
  report
}) => {
  if (![DATA_WELL, TABLE].includes(report.get('chartType')) || isJourneyReport(report)) {
    return report;
  }
  const metrics = report.getIn(['config', 'metrics']);
  return report.updateIn(['displayParams', 'columns'], ImmutableMap(), columns => {
    return metrics.reduce((acc, metric) => {
      const metricKey = getMetricKey(metric.get('property'), metric.get('metricTypes').first());
      return acc.update(metricKey, ImmutableMap(), column => {
        const tooltip = getMetricTooltip(getDataTypeName(report), metric.toJS());
        return tooltip ? column.set('tooltipI18nKey', tooltip) : column;
      });
    }, columns);
  });
};
export const LeadResponseMetricsMutator = ({
  report
}) => !report.get('id') && report.get('chartType') === TABLE ? report.updateIn(['config', 'metrics'], (metrics = List()) => metrics.concat(fromJS([{
  property: 'count',
  metricTypes: [COUNT]
}, {
  property: 'hs_count_is_worked',
  metricTypes: [SUM]
}, {
  property: 'hs_count_is_unworked',
  metricTypes: [SUM]
}]))) : report;
export const FunnelPipelineDealStageMutator = ({
  report,
  context: {
    pipeline,
    dealStages
  }
}) => {
  const stageIds = dealStages.map(stageValue => getStageIdFromStageValue(stageValue));
  return report.setIn(['config', 'pipeline'], ImmutableMap({
    id: pipeline,
    stages: stageIds
  }));
};
export const timeInDealStageMetricsMutator = ({
  report,
  context: {
    dealPipelines
  },
  options: {
    hasV2DealStageProperties
  }
}) => {
  const includedStages = getCachedPipelines().toJS().filter(({
    pipelineId
  }) => dealPipelines.isEmpty() || dealPipelines.includes(pipelineId)).reduce((collect, {
    stages
  }) => [...collect, ...stages.filter(({
    metadata: {
      probability
    }
  }) => probability > 0 && probability < 1)], []);
  const stagePropertyNames = includedStages.map(({
    metadata: {
      timeInStagePropertyName
    }
  }) => timeInStagePropertyName.replace('hs_time_in_', 'hs_v2_latest_time_in_'));
  const properties = hasV2DealStageProperties ? stagePropertyNames : includedStages.map(({
    stageId
  }) => `dealstages.${slug(stageId)}_duration`);
  return report.setIn(['config', 'metrics'], fromJS(properties.map(property => ({
    property,
    metricTypes: [AVG]
  }))));
};
export const TicketTimeInPipelineMutator = ({
  report,
  context
}) => {
  const {
    ticketPipeline
  } = context;
  const allPipelines = getCachedPipelines();
  if (!ticketPipeline || !allPipelines) {
    return report;
  }
  const pipelineStagesProperties = ImmutableMap(allPipelines.map(p => [p.get('pipelineId'), p])).getIn([ticketPipeline, 'stages']);
  if (!pipelineStagesProperties) {
    return report;
  }
  const newPipelineStagesProperties = pipelineStagesProperties.map(stages => stages.getIn(['metadata', 'timeInStagePropertyName']));
  const metrics = newPipelineStagesProperties.map(stage => fromJS({
    property: stage,
    metricTypes: ['AVG'],
    percentiles: []
  }));
  return report.setIn(['config', 'metrics'], metrics);
};
export const DrilldownMutator = ({
  report,
  context: {
    drilldowns,
    grouping
  }
}) => {
  const drilldownProperties = getDrilldownPropertiesForGrouping(report, grouping);
  if (drilldowns.isEmpty() || report.get('chartType') !== TABLE || drilldownProperties.length === 0) {
    return report;
  }
  const drilldownFilters = drilldowns.map(drilldown => ({
    property: drilldown.get('property'),
    operator: IN,
    values: [drilldown.get('value')]
  }));
  const existingFilters = report.getIn(['config', 'filters', 'custom']);
  const newDimension = drilldownProperties[drilldowns.count()];
  return report.setIn(['config', 'dimensions'], List([newDimension])).setIn(['config', 'filters', 'custom'], existingFilters.concat(drilldownFilters));
};
export const FilteredBreakdownsMutator = ({
  report,
  context: {
    filteredBreakdowns,
    grouping,
    breakdown
  }
}) => {
  const filteredBreakdownsAreRequired = isUnifiedAnalyticsReport(report) && grouping === DIMENSIONS.DATE && breakdown === DIMENSIONS.ID;
  if (!isUnifiedAnalyticsReport(report) || filteredBreakdowns.isEmpty() && !filteredBreakdownsAreRequired) {
    return report;
  }
  const filteredBreakdownValues = getFilteredBreakdownValuesFromKeys(filteredBreakdowns);
  if (report.get('chartType') === TABLE) {
    return report.setIn(['displayParams', 'checked'], filteredBreakdownValues);
  }
  const updatedBreakdownFilter = fromJS({
    property: 'filters',
    operator: 'IN',
    values: filteredBreakdownValues
  });
  return report.updateIn(['config', 'filters', 'custom'], originalCustomFilters => {
    const breakdownFilterIndex = originalCustomFilters.findIndex(filter => filter.get('property') === 'filters');
    return breakdownFilterIndex === -1 ? originalCustomFilters.push(updatedBreakdownFilter) : originalCustomFilters.set(breakdownFilterIndex, updatedBreakdownFilter);
  });
};
export const PipelineSnapshotsDimensionsMutator = ({
  report
}) => {
  if (isCustomWidgetReport(report)) {
    return report;
  }
  return report.updateIn(['config', 'dimensions'], dimensions => dimensions.map(dimension => dimension === 'dealstage' ? `${dimension}>latest_value` : dimension));
};
export const EventSearchSelectorMutator = ({
  report,
  context: {
    event
  }
}) => {
  return event ? report.setIn(['config', 'unifiedEventTypeName'], event) : report;
};
export const SubAppKeyMutator = ({
  report,
  subAppKey
}) => report.setIn(['displayParams', 'salesAnalytics', 'subAppKey'], subAppKey);
export const HideTotalsMutator = ({
  report,
  subAppKey
}) => {
  if (subAppKey === SUB_APPS.changeHistoryDealList) {
    return report.setIn(['displayParams', 'showTotals'], false);
  }
  return report;
};