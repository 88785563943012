import { DefaultFieldKeys } from '../Constants';
// @ts-expect-error untyped dep
import EmailAddressPattern from 'PatternValidationJS/patterns/EmailAddress';
export function getFormFieldDefaults(formFields) {
  return formFields.filter(formField => formField !== null && formField !== undefined).reduce((defaultValues, field) => {
    const fieldFunction = field;
    if (fieldFunction.key) {
      defaultValues[fieldFunction.key] = fieldFunction.defaultValue;
    }
    return defaultValues;
  }, {});
}
export function getEmailValidationState(formState) {
  const emailValue = formState[DefaultFieldKeys.EMAIL];
  const email = typeof emailValue === 'string' ? emailValue.trim() : emailValue;
  if (!EmailAddressPattern.test(email)) {
    return 'INVALID';
  }
  return 'VALID';
}
export function getExportReason(xhr) {
  if (xhr.status === 0) {
    return 'NO_NETWORK';
  }
  if (xhr.response) {
    try {
      if (JSON.parse(xhr.response).errorType === 'FREE_PORTAL_EXPORTS_LIMIT') {
        return 'FREE_PORTAL_EXPORTS_LIMIT';
      }
      if (JSON.parse(xhr.response).errorType === 'PAID_PORTAL_EXPORTS_LIMIT') {
        return 'PAID_PORTAL_EXPORTS_LIMIT';
      }
      if (JSON.parse(xhr.response).message === 'Portal is blocked from performing exports') {
        return 'BLOCKED_PORTAL';
      }
    } catch (e) {
      // ignore
    }
  }
  return 'UNKNOWN';
}