import { uniqueArrayFromToString } from '../../utils';
import { hasLocationProperty, isAggregationContextExpression, isBinaryExpression, isBooleanLiteralExpression, isExpressionsExpression, isFieldExpression, isFunctionCallExpression, isIdentifierExpression, isLiteralExpression, isMissingExpression, isNullLiteralExpression, isNumericLiteralExpression, isParsedFunctionCallExpression, isPropertyExpression, isReferenceExpression, isStringLiteralExpression, isSymbolExpression, isUnaryExpression, isUnrecognizedExpression } from './expression';
// @ts-expect-error generated code
import { __parseWithRecovery } from './expression-parser';
export const parse = input => __parseWithRecovery(input);
export const expressionToString = expression => {
  if (isExpressionsExpression(expression)) {
    return expression.expressions.map(expressionToString).join(' ');
  }
  if (isMissingExpression(expression)) {
    return '';
  }
  if (isSymbolExpression(expression)) {
    return expression.text;
  }
  if (isUnrecognizedExpression(expression)) {
    return expression.text;
  }
  if (isNullLiteralExpression(expression)) {
    return 'NULL';
  }
  if (isStringLiteralExpression(expression)) {
    return `"${expression.value}"`;
  }
  if (isNumericLiteralExpression(expression)) {
    return expression.value.toString();
  }
  if (isBooleanLiteralExpression(expression)) {
    return expression.value.toString();
  }
  if (isIdentifierExpression(expression)) {
    return expression.name;
  }
  if (isPropertyExpression(expression)) {
    return `[${expression.table}.${expression.name}]`;
  }
  if (isFieldExpression(expression)) {
    return `[${expression.name}]`;
  }
  if (isFunctionCallExpression(expression)) {
    return `${expression.name}(${expression.arguments.map(expressionToString).join(', ')})`;
  }
  if (isAggregationContextExpression(expression)) {
    const strExp = expressionToString(expression.expression);
    if (expression.dimensions.length === 0) {
      return `{ ${strExp} }`;
    }
    const strCtx = expression.dimensions.map(expressionToString).join(', ');
    return `{ ${strCtx}: ${strExp} }`;
  }
  if (isUnaryExpression(expression)) {
    return `(${expression.operator}${expressionToString(expression.argument)})`;
  }
  if (isBinaryExpression(expression)) {
    return `(${expressionToString(expression.left)} ${expression.operator} ${expressionToString(expression.right)})`;
  }
  return '';
};
export function expressionToArray(exp) {
  if (isMissingExpression(exp) || isSymbolExpression(exp) || isUnrecognizedExpression(exp) || isLiteralExpression(exp) || isReferenceExpression(exp)) {
    return [exp];
  }
  if (isExpressionsExpression(exp)) {
    return [exp, ...exp.expressions.flatMap(expressionToArray)];
  }
  if (isFunctionCallExpression(exp)) {
    return [exp, ...exp.arguments.flatMap(expressionToArray)];
  }
  if (isAggregationContextExpression(exp)) {
    return [exp, ...exp.dimensions.flatMap(expressionToArray), ...expressionToArray(exp.expression)];
  }
  if (isUnaryExpression(exp)) {
    return [exp, ...expressionToArray(exp.argument)];
  }
  if (isBinaryExpression(exp)) {
    return [exp, ...expressionToArray(exp.left), ...expressionToArray(exp.right)];
  }
  throw new Error('Unhandled expression type in expressionToList');
}
export const expressionUpdater = (expression, updater) => {
  expression = structuredClone(expression);
  const applyUpdater = exp => {
    exp = updater(exp);
    if (isExpressionsExpression(exp)) {
      exp.expressions = exp.expressions.map(applyUpdater);
      return exp;
    }
    if (isFunctionCallExpression(exp)) {
      exp.arguments = exp.arguments.map(applyUpdater);
      return exp;
    }
    if (isAggregationContextExpression(exp)) {
      exp.dimensions = exp.dimensions.map(applyUpdater);
      exp.expression = applyUpdater(exp.expression);
      return exp;
    }
    if (isUnaryExpression(exp)) {
      exp.argument = applyUpdater(exp.argument);
      return exp;
    }
    if (isBinaryExpression(exp)) {
      exp.left = applyUpdater(exp.left);
      exp.right = applyUpdater(exp.right);
      return exp;
    }
    return exp;
  };
  return applyUpdater(expression);
};
export const getExpressionProperties = (expression, environment) => uniqueArrayFromToString(property => `${property.table}-${property.name}`, expressionToArray(expression).filter(isPropertyExpression).map(exp => environment.properties[`${exp.table}.${exp.name}`]).filter(property => property));
export const getExpressionIdentifiers = expression => uniqueArrayFromToString(field => `${field.name}`, expressionToArray(expression).filter(isFieldExpression));
export const getExpressionFields = expression => uniqueArrayFromToString(field => `${field.name}`, expressionToArray(expression).filter(isFieldExpression));
export const getExpressionFunctions = (expression, meta) => uniqueArrayFromToString(functionCall => `${functionCall.name}`, expressionToArray(expression).filter(isFunctionCallExpression)).map(exp => meta.find(functionMeta => functionMeta.function === exp.name));
export const mapExpressionFieldNames = (expression, mapper) => expressionUpdater(expression, exp => isFieldExpression(exp) ? Object.assign({}, exp, {
  name: mapper(exp.name)
}) : exp);
export const getPositionFromInputCoordinates = (input, coordinates) => {
  // The cursor can be after the very last character in the input
  // that is why we allow a maximum offset of one longer than the input length
  const maximumOffset = input.length + 1;
  let currentX = 0;
  let currentY = 0;
  for (let offset = 0; offset < maximumOffset; offset++) {
    if (currentX === coordinates.x && currentY === coordinates.y) {
      return {
        offset,
        line: currentY,
        column: currentX
      };
    }
    currentX++;
    if (input[offset] === '\n') {
      currentY++;
      currentX = 0;
    }
  }
  throw new Error('Cursor coordinates not found in provided input');
};
export const locationLength = location => location.end.offset - location.start.offset;
export const offsetInLocation = (index, location) => index >= location.start.offset && index <= location.end.offset;
export const positionInLocation = (position, location) => offsetInLocation(position.offset, location);
export const offsetInLocationExclusive = (index, location) => index > location.start.offset && index < location.end.offset;
export const positionInLocationExclusive = (position, location) => offsetInLocationExclusive(position.offset, location);
export const getShortestExpression = expressions => {
  let shortest;
  let shortestLength;
  for (const expression of expressions) {
    if (hasLocationProperty(expression)) {
      const length = locationLength(expression.location);
      if (shortestLength == null || length < shortestLength) {
        shortest = expression;
        shortestLength = length;
      }
    }
  }
  return shortest;
};
export const getExpressionAtPosition = (expression, position) => getShortestExpression(expressionToArray(expression).filter(hasLocationProperty).filter(exp => positionInLocation(position, exp.location)));
export const getFunctionWithIdentifierAtPosition = (expression, position) => expressionToArray(expression).filter(isParsedFunctionCallExpression).find(exp => positionInLocation(position, exp.identifierLocation));
export const getFunctionArgumentAtPosition = (expression, position) => {
  const functionCallExpression = getShortestExpression(expressionToArray(expression).filter(isParsedFunctionCallExpression).filter(exp => positionInLocation(position, exp.invocationLocation)));
  if (!functionCallExpression) {
    return undefined;
  }
  return {
    expression: functionCallExpression,
    argumentIndex: functionCallExpression.argumentLocations.findIndex(location => positionInLocation(position, location))
  };
};