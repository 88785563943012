import { List, fromJS } from 'immutable';
import { toObjectSegFilterStrict } from 'customer-data-filters/converters/objectSeg/toObjectSegFilter';
// @ts-expect-error migrate upstream types
import { IN_LIST } from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import { Conditions } from 'reporting-snowflake/relational/schema/filter-records';
// @ts-expect-error migrate upstream types
import { getType } from './propertySchema';
import { getFilterQueryFormatOperator } from './filterConversionHelpers';
import { RelationalReport } from 'reporting-snowflake/relational/schema/report-records';
import { overrideReportDefinitionsFrequency } from './snowflake-frequency-override-utils';
import { isListMembershipProperty, listMembershipsToObjectSegFilterGroup } from 'platform-dashboard-ui/filter/utils/listMembershipFilterUtils';
const getSnowflakeFilterLogicExpression = (filterDefinition, newFilterGroups) => {
  const reportConditionType = filterDefinition.get('condition');
  const existingFilterGroups = filterDefinition.get('groups');
  const expressionParts = newFilterGroups.map(group => group.get('id'));
  if (existingFilterGroups.length > 0) {
    const existingFilterGroupIds = existingFilterGroups.map(group => group.get('id'));
    const existingFiltersExpression = reportConditionType === Conditions.AND ? existingFilterGroupIds.join(' AND ') : reportConditionType === Conditions.OR ? existingFilterGroupIds.join(' OR ') : filterDefinition.get('logic');
    const isEmptyCustomLogic = reportConditionType === Conditions.CUSTOM && existingFiltersExpression === '';
    if (!isEmptyCustomLogic) expressionParts.unshift(`(${existingFiltersExpression})`);
  }
  return expressionParts.join(' AND ');
};
const overrideSnowflakeReportFrequency = (report, frequency, frequencyUseFiscalYear) => {
  if (!frequency) {
    return report;
  }
  try {
    const reportDefinition = RelationalReport(report.getIn(['reportDefinition']));
    return report.setIn(['reportDefinition'], overrideReportDefinitionsFrequency(reportDefinition, frequency, frequencyUseFiscalYear));
  } catch (__error) {
    return report;
  }
};
const createNewQuickFilterGroup = (operator, gates = [], idCounter, propertyName, dataSourceName) => {
  const snowflakeOperator = toObjectSegFilterStrict(operator, 'SNOWFLAKE', gate => gates.includes(gate));
  const property = operator.get('field');
  const newFilterGroup = fromJS({
    id: idCounter,
    condition: Conditions.AND,
    filters: [{
      id: idCounter,
      field: {
        name: propertyName,
        table: dataSourceName,
        type: getType(property)
      },
      filter: snowflakeOperator
    }]
  });
  return newFilterGroup;
};
const shouldDashboardFilterOverrideReportFilter = ({
  reportSingleFilter,
  dashboardFilter
}) => {
  const reportFilterDataSourceName = reportSingleFilter.getIn(['field', 'table']);
  const reportFilterPropertyName = reportSingleFilter.getIn(['field', 'name']);
  const reportFilterType = reportSingleFilter.getIn(['filter', 'filterType']);
  const {
    dataSourceName: dashboardFilterDataSourceName,
    propertyName: dashboardFilterPropertyName
  } = dashboardFilter;
  if (isListMembershipProperty(dashboardFilterPropertyName)) {
    return reportFilterDataSourceName === dashboardFilterDataSourceName && reportFilterType === IN_LIST;
  }
  return reportFilterDataSourceName === dashboardFilterDataSourceName && reportFilterPropertyName === dashboardFilterPropertyName;
};
export const getOverriddenSnowflakeReportAndFilters = ({
  dashboardFilters: applicableDashboardFilters,
  dataSourcesProperties,
  report,
  gates,
  frequency,
  frequencyUseFiscalYear
}) => {
  const overriddenFilters = new Set();
  const filterDefinition = report.getIn(['reportDefinition', 'filtering']);
  const existingFilterGroups = filterDefinition.get('groups') || [];
  const savedFilteringCondition = filterDefinition.get('condition');
  const existingFilterGroupCount = existingFilterGroups.length;
  let idCounter = existingFilterGroupCount + 1;
  let nonOverriddenSavedFilterGroups = existingFilterGroups;
  const newFilterGroups = [];
  for (const dashboardFilter of applicableDashboardFilters) {
    const {
      dataSource,
      dataSourceName,
      propertyName
    } = dashboardFilter;
    const operator = getFilterQueryFormatOperator(dashboardFilter, dataSourcesProperties[dataSource]);
    // skip applying filter if invalid
    if (!operator) {
      continue;
    }
    const newFilterGroup = isListMembershipProperty(propertyName) ? listMembershipsToObjectSegFilterGroup(dataSourceName, operator, idCounter) : createNewQuickFilterGroup(operator, gates, idCounter, propertyName, dataSourceName);
    idCounter++;
    newFilterGroups.push(newFilterGroup);

    // override saved report filter groups with dashboard filters only if they're all AND-ed together
    if (savedFilteringCondition === Conditions.AND) {
      const newNonOverriddenSavedFilterGroups = [];
      for (const savedFilterGroup of nonOverriddenSavedFilterGroups) {
        const savedGroupFilters = savedFilterGroup.get('filters');
        if (savedGroupFilters.length === 1) {
          const singleFilter = savedGroupFilters.first();
          const shouldOverrideFilter = shouldDashboardFilterOverrideReportFilter({
            reportSingleFilter: singleFilter,
            dashboardFilter
          });
          if (shouldOverrideFilter) {
            overriddenFilters.add({
              isDateRange: false,
              dataSource,
              filter: singleFilter
            });
            continue;
          }
        }
        newNonOverriddenSavedFilterGroups.push(savedFilterGroup);
      }
      nonOverriddenSavedFilterGroups = newNonOverriddenSavedFilterGroups;
    }
  }
  if (newFilterGroups.length === 0) {
    return {
      overriddenFilters,
      overriddenReport: overrideSnowflakeReportFrequency(report, frequency, frequencyUseFiscalYear)
    };
  }
  const newLogicExpression = getSnowflakeFilterLogicExpression(filterDefinition, newFilterGroups);
  const updatedFilterGroups = List(nonOverriddenSavedFilterGroups.concat(newFilterGroups).map((group, i) => {
    return savedFilteringCondition === Conditions.AND ? group.set('id', i + 1) : group;
  }));
  const updatedFiltering = savedFilteringCondition === Conditions.AND ? filterDefinition.set('groups', updatedFilterGroups) : filterDefinition.set('groups', updatedFilterGroups).set('condition', Conditions.CUSTOM).set('logic', newLogicExpression);
  const overriddenReport = report.setIn(['reportDefinition', 'filtering'], updatedFiltering);
  return {
    overriddenFilters,
    overriddenReport: overrideSnowflakeReportFrequency(overriddenReport, frequency, frequencyUseFiscalYear)
  };
};
export const __TESTABLE__ = {
  overrideSnowflakeReportFrequency,
  getSnowflakeFilterLogicExpression
};