import { CREATE_AND_OWN, EDIT, NONE, VIEW } from 'reporting-data/constants/userAccessLevel';
export const AssetPermission = {
  /**  Signifies that user does not have access to the asset */
  NONE,
  /**  Often confused with NONE, ZERO signifies that user does not have reporting access at all */
  ZERO: 'ZERO',
  VIEW,
  EDIT,
  CREATE_AND_OWN,
  COMPLETE: 'COMPLETE'
};
export const AssetPermissionTypes = Object.keys(AssetPermission);
// Order: most constrained to least constrained
export const AssetPermissionRank = [AssetPermission.NONE,
// Signifies that user does not have access to the asset
AssetPermission.ZERO,
// Often confused with NONE, ZERO signifies that user does not have reporting access at all
AssetPermission.VIEW, AssetPermission.EDIT, AssetPermission.CREATE_AND_OWN, AssetPermission.COMPLETE // Not related to an asset, but signifies that user in an admin and can do and see everything
];
export const asNumber = assetPermission => AssetPermissionRank.indexOf(assetPermission);