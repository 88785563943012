'use es6';

import { TooMuchDataException } from '../../../exceptions';
const limit = 500;
export const get = () => response => {
  const {
    breakdowns
  } = response;
  if (breakdowns != null && breakdowns.length > limit) {
    throw new TooMuchDataException();
  }
  const dates = Object.keys(response);
  if (dates.length > limit) {
    throw new TooMuchDataException();
  }
  const points = dates.reduce((memo, date) => (response[date] || []).length + memo, 0);
  if (points > limit) {
    throw new TooMuchDataException();
  }
};