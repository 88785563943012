'use es6';

export const FETCH = 'FETCH';
export const PUT = 'PUT';
export const POST = 'POST';

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}