import * as checked from 'reporting-data/lib/checked';
import { EventDateInterval, Filtering } from '../relational/schema/filter-records';
import { TableDescription } from '../relational/schema/table-records';
import { Context, Contexts } from './context-records';
import { DefaultEditor, Editor } from './editor-records';
import { Expression } from './expression-records';
import { PropertyTypeNames, Type } from './type-records';
export const DatasetProperty = checked.record({
  name: checked.string(),
  table: checked.string(),
  type: checked.symbol(PropertyTypeNames)
}, 'DatasetProperty');
export const DatasetField = checked.record({
  name: checked.string(),
  label: checked.string(),
  derived: checked.boolean(),
  hidden: checked.boolean(),
  valid: checked.boolean(),
  input: checked.string().defaultValue(''),
  expression: Expression,
  persistedType: Type.optional(),
  hasPropertyMapping: checked.boolean().defaultValue(false),
  type: Type,
  // change defaultValue to Contexts.UNDEFINED when we release aggregations feature
  context: Context.defaultValue(Contexts.ROW_LEVEL),
  editor: Editor.defaultValue(DefaultEditor({
    editorState: {}
  })),
  format: checked.any()
}, 'DatasetField');
export const RelationalDataset = checked.record({
  table: TableDescription,
  // Map<tableName, Map<propertyName, DatasetProperty>>
  properties: checked.map(checked.map(DatasetProperty)),
  // Map<fieldName, DatasetField>
  fields: checked.map(DatasetField),
  fieldOrder: checked.list(checked.string()),
  derivedFieldOrder: checked.list(checked.string()),
  unifiedFieldOrder: checked.list(checked.string()).optional(),
  filtering: Filtering.optional(),
  eventDateInterval: EventDateInterval.optional()
}, 'RelationalDataset');