export const ExpressionTypes = {
  // Recovery
  EXPRESSIONS: 'EXPRESSIONS',
  MISSING: 'MISSING',
  SYMBOL: 'SYMBOL',
  UNRECOGNIZED: 'UNRECOGNIZED',
  // Literal
  NULL_LITERAL: 'NULL_LITERAL',
  BOOLEAN_LITERAL: 'BOOLEAN_LITERAL',
  NUMERIC_LITERAL: 'NUMERIC_LITERAL',
  STRING_LITERAL: 'STRING_LITERAL',
  // Reference
  IDENTIFIER: 'IDENTIFIER',
  PROPERTY: 'PROPERTY',
  FIELD: 'FIELD',
  // Language
  FUNCTION_CALL: 'FUNCTION_CALL',
  AGGREGATION_CONTEXT: 'AGGREGATION_CONTEXT',
  // Unary Operators
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
  NOT: 'NOT',
  // Binary Operators
  ADD: 'ADD',
  SUBTRACT: 'SUBTRACT',
  MULTIPLY: 'MULTIPLY',
  DIVIDE: 'DIVIDE',
  MODULUS: 'MODULUS',
  GREATER: 'GREATER',
  GREATER_OR_EQUAL: 'GREATER_OR_EQUAL',
  LESS: 'LESS',
  LESS_OR_EQUAL: 'LESS_OR_EQUAL',
  EQUAL: 'EQUAL',
  NOT_EQUAL: 'NOT_EQUAL',
  AND: 'AND',
  OR: 'OR'
};
export const SymbolNames = {
  COMMA: 'COMMA',
  LEFT_BRACE: 'LEFT_BRACE',
  RIGHT_BRACE: 'RIGHT_BRACE',
  LEFT_PARENTHESIS: 'LEFT_PARENTHESIS',
  RIGHT_PARENTHESIS: 'RIGHT_PARENTHESIS',
  LEFT_BRACKET: 'LEFT_BRACKET',
  RIGHT_BRACKET: 'RIGHT_BRACKET',
  SINGLE_QUOTE: 'SINGLE_QUOTE',
  DOUBLE_QUOTE: 'DOUBLE_QUOTE'
};
export const hasRecoveredProperty = expression => expression && Object.prototype.hasOwnProperty.call(expression, 'recovered');
export const hasLocationProperty = expression => expression && Object.prototype.hasOwnProperty.call(expression, 'location');

/* Recovery */

/**
 * Expressions is a node that contains a list of other expression nodes. This
 * is only used when the parser is unable to parse a single expression.
 *
 * ex: `(2 + 2) (3 - 1)`
 */
export const isExpressionsExpression = expression => expression.type === ExpressionTypes.EXPRESSIONS;
export const isParsedExpressionsExpression = expression => isExpressionsExpression(expression) && hasRecoveredProperty(expression) && hasLocationProperty(expression);

/**
 * The Missing node is present when recovery parsing results in a dangling
 * operator. This is the only node with a truly optional location as a result
 * from the parser.
 *
 * ex: `[DEAL.amount] *`
 */

export const isMissingExpression = expression => expression.type === ExpressionTypes.MISSING;
export const isParsedMissingExpression = expression => isMissingExpression(expression) && hasRecoveredProperty(expression) && hasLocationProperty(expression);
export const MISSING_EXPRESSION = {
  type: ExpressionTypes.MISSING
};

/**
 * The Symbol node represents a single recognized symbol, such as a comma, that
 * was unable to be parsed into an expression. Typically this is only seen
 * when unrecognized characters are encountered and break what would otherwise
 * be a valid expression into multiple expressions (with symbols).
 */

export const isSymbolExpression = expression => expression.type === ExpressionTypes.SYMBOL;
export const isParsedSymbolExpression = expression => isSymbolExpression(expression) && hasRecoveredProperty(expression) && hasLocationProperty(expression);

/**
 * The Unrecognized node is any text that is entirely unrecognizable to the
 * parser.
 */

export const isUnrecognizedExpression = expression => expression.type === ExpressionTypes.UNRECOGNIZED;
export const isParsedUnrecognizedExpression = expression => isUnrecognizedExpression(expression) && hasRecoveredProperty(expression) && hasLocationProperty(expression);

/**
 * Recovery expressions are parsed when recovering from syntax errors present
 * in the users expression.
 */

export const isRecoveryExpression = expression => isExpressionsExpression(expression) || isMissingExpression(expression) || isSymbolExpression(expression) || isUnrecognizedExpression(expression);
export const isParsedRecoveryExpression = expression => isParsedExpressionsExpression(expression) || isParsedMissingExpression(expression) || isParsedSymbolExpression(expression) || isParsedUnrecognizedExpression(expression);

/* Literal */

/**
 * User inputted null literal.
 * ex: `NULL` or `null`
 */
export const isNullLiteralExpression = expression => expression.type === ExpressionTypes.NULL_LITERAL;
export const isParsedNullLiteralExpression = expression => isNullLiteralExpression(expression) && hasRecoveredProperty(expression) && hasLocationProperty(expression);

/**
 * A boolean value
 * ex: `true` or `false`
 * note: maybe expand this to TRUE and FALSE as well
 */

export const isBooleanLiteralExpression = expression => expression.type === ExpressionTypes.BOOLEAN_LITERAL;
export const isParsedBooleanLiteralExpression = expression => isBooleanLiteralExpression(expression) && hasRecoveredProperty(expression) && hasLocationProperty(expression);

/**
 * Numeric literal expression
 * ex: `1` or `1.0`
 */

export const isNumericLiteralExpression = expression => expression.type === ExpressionTypes.NUMERIC_LITERAL;
export const isParsedNumericLiteralExpression = expression => isNumericLiteralExpression(expression) && hasRecoveredProperty(expression) && hasLocationProperty(expression);

/**
 * String literal expression
 * ex: `'hello'` or `"hello"`
 */

export const isStringLiteralExpression = expression => expression.type === ExpressionTypes.STRING_LITERAL;
export const isParsedStringLiteralExpression = expression => isStringLiteralExpression(expression) && hasRecoveredProperty(expression) && hasLocationProperty(expression);
export const isLiteralExpression = expression => isNullLiteralExpression(expression) || isBooleanLiteralExpression(expression) || isNumericLiteralExpression(expression) || isStringLiteralExpression(expression);
export const isParsedLiteralExpression = expression => isParsedNullLiteralExpression(expression) || isParsedBooleanLiteralExpression(expression) || isParsedNumericLiteralExpression(expression) || isParsedStringLiteralExpression(expression);

/* Reference */

/**
 * Identifier expression, a valid identifier name was parsed.
 * ex: `deal` or `foo` or `foo123`, but not `1bar`
 */
export const isIdentifierExpression = expression => expression.type === ExpressionTypes.IDENTIFIER;
export const isParsedIdentifierExpression = expression => isIdentifierExpression(expression) && hasRecoveredProperty(expression) && hasLocationProperty(expression);

/**
 * Property expressions are used to access property values from the tables
 * available to an expression. This is really just a namespace separating the
 * framework, internal id based namespace from user inputted field names.
 *
 * ex: `[TABLE.internal_property_name]`
 */

export const isPropertyExpression = expression => expression.type === ExpressionTypes.PROPERTY;
export const isParsedPropertyExpression = expression => isPropertyExpression(expression) && hasRecoveredProperty(expression) && hasLocationProperty(expression);

/**
 * Similar to Property expressions, Field is used to reference the of another
 * field.
 *
 * ex: `[My Field]` or `[Deal Amount]
 */

export const isFieldExpression = expression => expression.type === ExpressionTypes.FIELD;
export const isParsedFieldExpression = expression => isFieldExpression(expression) && hasRecoveredProperty(expression) && hasLocationProperty(expression);
export const isReferenceExpression = expression => isIdentifierExpression(expression) || isPropertyExpression(expression) || isFieldExpression(expression);
export const isParsedReferenceExpression = expression => isParsedIdentifierExpression(expression) || isParsedPropertyExpression(expression) || isParsedFieldExpression(expression);

/* Language */

/**
 * FunctionCall is an invocation of a predefined function in the language.
 * ex: `DATEDIFF("MONTH", [Date 1], [Date 2])` or `NOW()`
 */
export const isFunctionCallExpression = expression => expression.type === ExpressionTypes.FUNCTION_CALL;
export const isParsedFunctionCallExpression = expression => isFunctionCallExpression(expression) && hasRecoveredProperty(expression) && hasLocationProperty(expression) && Object.prototype.hasOwnProperty.call(expression, 'identifierLocation') && Object.prototype.hasOwnProperty.call(expression, 'invocationLocation') && Object.prototype.hasOwnProperty.call(expression, 'invocationLocationWithParens') && Object.prototype.hasOwnProperty.call(expression, 'argumentLocations');

/**
 * Sets an explicit detail for an aggregate expression.
 * ex: `{ [Deal Stage]: SUM([Amount]) }
 * computes the sum of deal amount for each deal stage.
 */

export const isAggregationContextExpression = expression => expression.type === ExpressionTypes.AGGREGATION_CONTEXT;
export const isParsedAggregationContextExpression = expression => isAggregationContextExpression(expression) && hasRecoveredProperty(expression) && hasLocationProperty(expression);
export const isLanguageExpression = expression => isFunctionCallExpression(expression) || isAggregationContextExpression(expression);
export const isParsedLanguageExpression = expression => isParsedFunctionCallExpression(expression) || isParsedAggregationContextExpression(expression);

/* Unary */

/**
 * A Unary expression is a unary operator applied to a single expression.
 * ex: `-[Amount]` or `![Active]`
 */
export const isUnaryExpression = expression => expression.type === ExpressionTypes.POSITIVE || expression.type === ExpressionTypes.NEGATIVE || expression.type === ExpressionTypes.NOT;
export const isParsedUnaryExpression = expression => isUnaryExpression(expression) && hasRecoveredProperty(expression) && hasLocationProperty(expression);

/* Binary */

/**
 * A Binary expression is a binary operator applied to two expressions.
 * ex: `[Amount] + [Deal Stage]` or `[Date 1] < [Date 2]`
 */
export const isBinaryExpression = expression => expression.type === ExpressionTypes.ADD || expression.type === ExpressionTypes.SUBTRACT || expression.type === ExpressionTypes.MULTIPLY || expression.type === ExpressionTypes.DIVIDE || expression.type === ExpressionTypes.MODULUS || expression.type === ExpressionTypes.GREATER || expression.type === ExpressionTypes.GREATER_OR_EQUAL || expression.type === ExpressionTypes.LESS || expression.type === ExpressionTypes.LESS_OR_EQUAL || expression.type === ExpressionTypes.EQUAL || expression.type === ExpressionTypes.NOT_EQUAL || expression.type === ExpressionTypes.AND || expression.type === ExpressionTypes.OR;
export const isParsedBinaryExpression = expression => isBinaryExpression(expression) && hasRecoveredProperty(expression) && hasLocationProperty(expression);

/* Expression */

/* Additional union types for utility, not related to type structure */

export const isEqualityExpression = expression => expression.type === ExpressionTypes.EQUAL || expression.type === ExpressionTypes.NOT_EQUAL;
export const isParsedEqualityExpression = expression => isEqualityExpression(expression) && isParsedBinaryExpression(expression);
export const isRecoveredExpression = expression => hasRecoveredProperty(expression) && expression.recovered;