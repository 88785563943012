export const AssociationInputType = 'ASSOCIATION';
export const CtaInputType = 'CTA';
export const DefaultInputType = 'DEFAULT';
export const EmailLinkInputType = 'EMAIL_LINK';
export const EmailSubscriptionInputType = 'EMAIL_SUBSCRIPTION';
export const RangeInputType = 'RANGE';
export const PropertyInputType = 'PROPERTY';
export const RollingDateInputType = 'ROLLING_DATE';
export const RollingInequalityInputType = 'ROLLING_INEQUALITY';
export const RollingPropertyUpdatedInputType = 'ROLLING_PROPERTY_UPDATED';
export const RollingWithUnitInputType = 'ROLLING_WITH_UNIT';
export const NoInputType = 'NONE';
export const UrlInputType = 'URL';