'use es6';

import { APP_NAMES } from '../constants/apps';
export default {
  [APP_NAMES.SUBSCRIPTION_ANALYTICS]: {
    translationKey: 'subscription-analytics',
    defaultRoute: '/newMonthlyRecurringRevenue'
  },
  [APP_NAMES.SERVICE_ANALYTICS]: {
    translationKey: 'service-analytics',
    defaultRoute: '/supportVolume'
  },
  [APP_NAMES.SALES_ANALYTICS]: {
    translationKey: 'sales-analytics',
    defaultRoute: '/activities'
  },
  [APP_NAMES.MARKETING_ANALYTICS]: {
    translationKey: 'marketing-analytics',
    defaultRoute: '/sources'
  }
};
export const templateCollectionToAppName = {
  sales: APP_NAMES.SALES_ANALYTICS,
  subscription: APP_NAMES.SUBSCRIPTION_ANALYTICS,
  service: APP_NAMES.SERVICE_ANALYTICS,
  marketing: APP_NAMES.MARKETING_ANALYTICS
};