/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import { ACTION_CHOICE_RESET, ACTION_INIT_OPT, ACTION_STATE_UPDATED } from 'ui-addon-opt/state/OptActions';
import { OPT_CHOICE_TO_STATE_VALUE, OPT_CHOICE_TO_TARGET_TYPE, TARGET_TYPE_HUB, isHubWideChoice, isUserOptin } from 'ui-addon-opt/state/OptState';
import { cloneTracker, emptyTracker, trackOptAction } from 'ui-addon-opt/lib/Tracking';
import { fetchUserOptSettings, updateOpt } from 'ui-addon-opt/api/OptinsAPI';
import { isOptMinimized, setOptMinimized } from 'ui-addon-opt/lib/LocalSettings';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isFeedbackSurveyAvailable } from 'ui-addon-opt/lib/Feedback';
export const updateValues = (ref, values) => {
  ref.current = Object.assign({}, values);
};
export const changed = (ref, values) => Object.keys(values).some(key => ref.current[key] !== values[key]);
const toStateType = availability => availability.hubLevelOverride ? TARGET_TYPE_HUB : availability.targetType;
export const useFetchUserOptSettingsEffect = ({
  dispatch,
  optinName,
  optinView,
  onFetchOptError,
  opt: {
    state
  },
  setOptSettings
}) => {
  const fetchRef = useRef({});
  useEffect(() => {
    const values = {
      optinName,
      optinView,
      state: state || fetchRef.current.state // Avoid fetch when reset to null
    };
    if (changed(fetchRef, values)) {
      fetchUserOptSettings(optinName).then(settings => {
        dispatch({
          type: ACTION_INIT_OPT,
          settings
        });
        setOptSettings(settings);
      }, onFetchOptError);
    }
    updateValues(fetchRef, values);
  }, [dispatch, optinName, optinView, onFetchOptError, state, setOptSettings]);
};
export const useUpdateUserOptSettingsEffect = ({
  ConfirmationModal,
  dispatch,
  optinName,
  onOptStateChangeCancelled,
  onUpdateOptError,
  opt: {
    choice
  },
  optSettings
}) => {
  const updateRef = useRef({});
  useEffect(() => {
    const values = {
      choice
    };
    if (choice && changed(updateRef, values)) {
      const updateOptinWithChoice = () => {
        updateOpt({
          optinName,
          newOptinState: OPT_CHOICE_TO_STATE_VALUE[choice],
          hubOverride: isUserOptin(optSettings.availability.targetType) && isHubWideChoice(choice),
          availability: optSettings.availability
        }).then(() => dispatch({
          type: ACTION_STATE_UPDATED
        }), onUpdateOptError);
      };
      if (ConfirmationModal) {
        ConfirmationModal({
          optState: optSettings.availability.state
        }).then(() => {
          updateOptinWithChoice();
        }, () => {
          onOptStateChangeCancelled(optSettings.availability.state, choice);
          dispatch({
            type: ACTION_CHOICE_RESET
          });
        });
        return;
      }
      updateOptinWithChoice();
    }
    updateValues(updateRef, values);
  }, [ConfirmationModal, choice, dispatch, optinName, onOptStateChangeCancelled, onUpdateOptError, optSettings.availability, optSettings.availability.state]);
};
export const useInitialOptStateFetchedEffect = ({
  onInitialOptStateFetched,
  optSettings: {
    availability
  }
}) => {
  const invokedRef = useRef(false);
  useEffect(() => {
    if (availability.state && !invokedRef.current) {
      onInitialOptStateFetched({
        value: availability.state,
        type: toStateType(availability)
      });
      invokedRef.current = true;
    }
  }, [onInitialOptStateFetched, availability]);
};
export const useOptStateChangedEffect = ({
  opt: {
    state
  },
  onOptStateChanged,
  optinName,
  usageTracker
}) => {
  const changeRef = useRef({});
  useEffect(() => {
    const values = {
      state
    };
    if (state && changed(changeRef, values)) {
      onOptStateChanged({
        value: OPT_CHOICE_TO_STATE_VALUE[state],
        type: OPT_CHOICE_TO_TARGET_TYPE[state]
      });
      trackOptAction(usageTracker, optinName, state);
    }
    updateValues(changeRef, values);
  }, [onOptStateChanged, optinName, state, usageTracker]);
};
export const useTargetOptinViewChangedEffect = ({
  onTargetOptinViewChanged,
  opt: {
    optinView: targetOptinView,
    state
  },
  optSettings,
  optinView,
  notifyOnUserViewChangesOnly
}) => {
  const changedRef = useRef({});
  const targetOptinViewRef = useRef(null);
  useEffect(() => {
    const values = {
      targetOptinView,
      availabilityState: optSettings.availability.state
    };
    if (targetOptinView && optSettings.availability.state && changed(changedRef, values)) {
      if (targetOptinViewRef.current !== targetOptinView && (!notifyOnUserViewChangesOnly || optinView !== targetOptinView)) {
        const optState = {
          value: OPT_CHOICE_TO_STATE_VALUE[state] || optSettings.availability.state,
          type: OPT_CHOICE_TO_TARGET_TYPE[state] || toStateType(optSettings.availability)
        };
        onTargetOptinViewChanged(targetOptinView, optState);
        targetOptinViewRef.current = targetOptinView;
      }
    }
    updateValues(changedRef, values);
  }, [targetOptinView, onTargetOptinViewChanged, optSettings.availability.state, optSettings.availability.targetType, state, optinView, optSettings.availability, notifyOnUserViewChangesOnly]);
};
export const useUsageTracker = (tracker = emptyTracker) => {
  const [usageTracker, setUsageTracker] = useState(emptyTracker);
  useEffect(() => {
    setUsageTracker(cloneTracker(tracker));
  }, [tracker]);
  return usageTracker;
};
export const useOptMinimizedEffect = (optinName, autoMinimizeTimeout) => {
  const [minimized, setMinimized] = useState(isOptMinimized(optinName));
  const [userClickedOpt, setUserClickedOpt] = useState(false);
  const userClickedOptRef = useRef(userClickedOpt);
  userClickedOptRef.current = userClickedOpt;
  useEffect(() => {
    setOptMinimized(optinName, minimized);
  }, [minimized, optinName]);
  useEffect(() => {
    if (!autoMinimizeTimeout) {
      return undefined;
    }
    const autoMinimizeTimer = setTimeout(() => {
      if (!userClickedOptRef.current) {
        setMinimized(true);
      }
    }, autoMinimizeTimeout);
    return () => {
      clearTimeout(autoMinimizeTimer);
    };
  }, [autoMinimizeTimeout]);
  const setUserClickedOptCb = useCallback(() => setUserClickedOpt(true), []);
  return [minimized, setMinimized, setUserClickedOptCb];
};
export const useFeedbackSurveyAvailabilityEffect = (feedbackClient, feedbackSurveyId) => {
  const [feedbackSurveyAvailable, setFeedbackSurveyAvailable] = useState(false);
  useEffect(() => {
    if (!feedbackClient) {
      return;
    }
    isFeedbackSurveyAvailable(feedbackClient, feedbackSurveyId).then(setFeedbackSurveyAvailable);
  }, [feedbackSurveyId, feedbackClient]);
  return feedbackSurveyAvailable;
};