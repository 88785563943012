import { createSelector } from 'reselect';
import { getSavedFilterStateForDashboard } from './helpers';
import { savedDashboardFiltersNamespace } from './slice';
import { getFilterSetById } from '../../filter/utils/savedDashboardFilters';
// selectors

function withState(selector) {
  return state => selector(state[savedDashboardFiltersNamespace]);
}
function withDashboardId(selector, notFoundReturn) {
  return dashboardId => withState(state => {
    const maybeSavedFilterState = getSavedFilterStateForDashboard(state, dashboardId);
    if (maybeSavedFilterState) {
      return selector(maybeSavedFilterState);
    }
    return notFoundReturn;
  });
}
export const selectSavedFilterSetState = withDashboardId(filterState => filterState, undefined);
export const selectSavedFilterSets = withDashboardId(filterState => filterState.filterSets, []);

/** Returns all non-email only filter sets to be used for display */
export const selectDisplaySavedFilterSets = dashboardId => createSelector(selectSavedFilterSets(dashboardId), filterSets => {
  return filterSets.filter(filterSet => filterSet.emailOnly !== true);
});

/** returns non-email only filter set by id */
export const selectDisplayFilterSetById = (dashboardId, filterSetId) => createSelector(selectDisplaySavedFilterSets(dashboardId), filterSets => getFilterSetById(filterSets, filterSetId));
export const selectHasAppliedSavedFilterSet = withDashboardId(filterState => !!filterState.appliedFilterSetId, false);
export const selectFilterSetById = (dashboardId, filterSetId) => createSelector(selectSavedFilterSets(dashboardId), filterSets => getFilterSetById(filterSets, filterSetId));
export const selectAppliedSavedFilterSet = withDashboardId(filterState => {
  if (filterState.appliedFilterSetId !== undefined) {
    const maybeAppliedFilterSet = getFilterSetById(filterState.filterSets, filterState.appliedFilterSetId);
    return maybeAppliedFilterSet;
  }
  return undefined;
}, undefined);
export const selectAppliedSavedFilters = withDashboardId(filterState => {
  if (filterState.appliedFilterSetId !== undefined) {
    const maybeAppliedFilterSet = getFilterSetById(filterState.filterSets, filterState.appliedFilterSetId);
    if (maybeAppliedFilterSet) {
      return maybeAppliedFilterSet.filters;
    }
  }
  return [];
}, []);