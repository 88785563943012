import { OrderedSet, Set as ImmutableSet, List, Map as ImmutableMap } from 'immutable';
import { isEmptySnowflakeProperty } from '../metadata/report-meta';
import { getFilterFieldRefs, getFilterGroupsForTable, removeFiltersOfTable, removeFiltersWithPredicate } from '../modify/filters/filter-utils';
import { applySorting } from '../modify/sorts/report-sort-utils';
import { Conditions, Filtering, FilterRefTypes } from '../schema/filter-records';
import { RELATIONAL, RelationalReport } from '../schema/report-records';
import { DATASET_TABLE, TableDescription } from '../schema/table-records';
import { ChannelNames, VisualTypes } from '../schema/visual-records';
import { clearColumnAggregation, createDefaultDrilldownDimensionWithTransform, createMagicMeasure, duplicateColumn, getColumnsFromTable, getDateResolutionForColumn, getMeasures, isColumnFromTable, isFieldExpressionBased, isMagicMeasure, removeColumnById, getExpressionFieldRefs, getStagedColumnsFromTable, isDateLikeField, hasDateTrunc } from './column-utils';
import { findAndReplaceTable } from './table-utils';
import { createDefaultVisual, createTableVisualWithCopiedChannels, decodeColumn, encodeChannel, getEncoding, getEncodingList, getEncodingListInChannel, getVisualTypeChannels, isTimeSeriesLikeVisualType, isVisualType } from './visual-utils';
import { DataSourceTypes } from '../schema/source-records';
import { AggregationTypes } from '../schema/column-records';
import { expressionToList } from '../../dataset/expression-utils';
import { ExpressionTypes } from '../../dataset/expression-records';
import { removeUnencodedColumns } from './report-visual-utils';
import { convertFieldToFieldRef } from './field-utils';
export { removeUnencodedColumns } from './report-visual-utils';
export const getExpressionFieldFromColumn = (report, column) => {
  return report.expressions.toMap().mapKeys((__, field) => field && field.alias).get(column.field.name);
};
export const expressionFieldReferencesTable = (expressionFieldToTest, tableName) => expressionToList(expressionFieldToTest.expression).find(expression => expression.type === ExpressionTypes.PROPERTY && expression.table === tableName);
export const MAXIMUM_REPORT_COLUMNS_COUNT = 20;
export const EXPANDED_REPORT_COLUMNS_COUNT = 30;
export const getReportVisualType = report => {
  if (!report.visual || !isVisualType(report.visual.type)) {
    console.error('Invalid report visual');
    return undefined;
  }
  return report.visual.type;
};
export const getReportVisualEncodings = report => report.visual && report.visual.encodings;
export const getReportOptions = report => {
  var _report$visual;
  return report === null || report === void 0 || (_report$visual = report.visual) === null || _report$visual === void 0 ? void 0 : _report$visual.options;
};
import { getReportXEncoding } from './report-visual-utils';
export { getReportXEncoding };
export { getReportColumnsEncodings, getReportBreakdownEncoding, getReportColorEncoding, getReportSizingEncoding, getReportDetailEncoding, getReportGroupEncoding, getReportRowsEncodings, getReportValuesEncodings, getReportSingleValueEncoding, getReportYEncoding, getReportY1Encoding, getReportY2Encoding, getReportXMultiEncoding, getReportYMultiEncoding, getReportCompareEncoding } from './report-visual-utils';
const removeUnencodedColumnsForDrilldown = report => {
  const columns = report.columns;
  const encodings = getReportVisualEncodings(report);
  const encodedColumns = encodings ? getEncodingList(encodings).map(encoding => encoding.column) : List();
  const result = report.set('columns', columns.filter(column => encodedColumns.includes(column.get('alias'))).toMap());
  return result;
};
export const applyResetFixedMeasures = report => {
  const encodings = getReportVisualEncodings(report);
  const resetColumn = column => column.set('fixedMeasure', false).delete('fixed');
  const encodedColumns = encodings ? getEncodingList(encodings) : List();
  return encodedColumns.reduce((nextReport, encoding) => {
    const nextColumns = nextReport.get('columns').update(encoding.column, resetColumn);
    return nextReport.set('columns', nextColumns);
  }, report);
};
export const getReportEncodedColumn = (report, channelName, index) => {
  const columns = report.columns;
  const encodings = getReportVisualEncodings(report);
  const encoding = encodings && getEncoding(encodings, channelName, index);
  return columns.get(encoding ? encoding.column : '');
};
export const createDefaultReport = () => RelationalReport({
  type: RELATIONAL,
  table: TableDescription({
    name: 'CONTACT',
    objectTypeId: '0-1',
    type: DataSourceTypes.HUBSPOT_OBJECT
  }),
  filtering: Filtering({
    condition: Conditions.AND,
    groups: [],
    logic: undefined,
    stagedFilters: []
  }),
  stagedColumns: [],
  stagedEncodings: [],
  columns: {},
  sorts: [],
  flags: [],
  visual: createDefaultVisual(VisualTypes.VERTICAL_BAR)
});
export const createDefaultReportWithVisualType = visualType => createDefaultReport().set('visual', createDefaultVisual(visualType));
export const asTable = reportDefinition => reportDefinition.update('visual', reportVisual => reportVisual == null ? createDefaultVisual(VisualTypes.TABLE) : createTableVisualWithCopiedChannels(reportVisual));
export const getReportFieldRefs = report => {
  const {
    columns,
    filtering,
    stagedColumns,
    expressions
  } = report;
  const columnFieldRefs = columns.toList().map(column => column.field).filter(field => !isFieldExpressionBased(field)).map(convertFieldToFieldRef).toSet();
  const stagedColumnFieldRefs = stagedColumns.map(stagedColumn => stagedColumn.field).filter(field => !isFieldExpressionBased(field)).map(convertFieldToFieldRef).toSet();
  const filterFieldRefs = filtering == null ? ImmutableSet() : getFilterFieldRefs(filtering).toSet();
  const expressionFieldRefs = getExpressionFieldRefs(expressions).toSet();
  return List(OrderedSet().union(columnFieldRefs).union(stagedColumnFieldRefs).union(expressionFieldRefs).union(filterFieldRefs));
};
const removeColumnFromStagedColumns = (report, column) => {
  return report.update('stagedColumns', stagedColumns => stagedColumns.filterNot(stagedColumn => stagedColumn.equals(column)).toList());
};
const removeEncodingWithColumnFromStagedEncodings = (report, alias) => {
  return report.update('stagedEncodings', stagedEncodings => stagedEncodings.filterNot(encoding => encoding.column === alias).toList());
};
export const removeColumnFromColumns = (report, column) => {
  if (report.columns.has(column.alias)) {
    return applySorting(report.update('columns', columns => columns.delete(column.alias)).update('visual', visual => visual && decodeColumn(visual, column)), undefined);
  }
  return report;
};
const stripMagicMeasures = report => {
  const magicMeasuresFromColumns = report.columns.filter(isMagicMeasure);
  const next = magicMeasuresFromColumns.reduce((reportResult, column) => removeColumnFromColumns(reportResult, column), report);
  const magicMeasuresFromStage = report.stagedColumns.filter(isMagicMeasure);
  return magicMeasuresFromStage.reduce((reportResult, column) => {
    return removeEncodingWithColumnFromStagedEncodings(removeColumnFromStagedColumns(reportResult, column), column.alias);
  }, next);
};
export const getChannelDisplayOrderComparator = report => {
  const encodings = getReportVisualEncodings(report);
  const type = getReportVisualType(report);
  const channels = type && getVisualTypeChannels(type);
  const findChannel = encodedColumn => channels && channels.find(channel => !!encodings && getEncodingListInChannel(encodings, channel.name).map(encoding => encoding.column).includes(encodedColumn.alias));
  return (a, b) => {
    const channelA = findChannel(a);
    const channelB = findChannel(b);
    if (!channelA || !channelB) {
      return 0;
    }
    if (channelA.displayOrder == null || channelB.displayOrder == null) {
      return 0;
    }
    if (channelA.equals(channelB)) {
      const orderedIds = encodings ? getEncodingListInChannel(encodings, channelA.name).map(encoding => encoding.column).toList() : List();
      return orderedIds.indexOf(a.alias) - orderedIds.indexOf(b.alias);
    }
    return channelA.displayOrder - channelB.displayOrder;
  };
};
export const createDimensionTableReport = (report, meta) => {
  const {
    stagedColumns,
    columns,
    table,
    filtering,
    eventDateInterval,
    expressions
  } = removeUnencodedColumnsForDrilldown(stripMagicMeasures(report));
  const displayOrderComparator = getChannelDisplayOrderComparator(report);
  const columnsToExport = columns.sort(displayOrderComparator).toOrderedSet().union(stagedColumns.toOrderedSet()).filter(column => column.aggregation !== AggregationTypes.DELEGATE);
  const dimensions = columnsToExport.map(
  // column is duplicated to assign a new alias
  c => clearColumnAggregation(duplicateColumn(c)));
  const defaultReport = createDefaultReportWithVisualType(VisualTypes.TABLE).set('table', table).set('columns', dimensions.toMap().mapKeys((_, dimension) => dimension ? dimension.alias : '').toMap()).set('eventDateInterval', eventDateInterval).set('filtering', filtering).set('expressions', expressions);
  const defaultVisual = defaultReport.get('visual') || createDefaultVisual(VisualTypes.TABLE);
  const updatedVisual = defaultVisual.update('encodings', nextEncodings => dimensions.reduce((acc, dimension) => {
    const expressionField = getExpressionFieldFromColumn(report, dimension);
    return encodeChannel({
      encodings: acc,
      channelName: ChannelNames.COLUMNS,
      column: dimension,
      expressionField
    });
  }, nextEncodings));
  return applySorting(applyResetFixedMeasures(defaultReport.set('visual', updatedVisual)), meta);
};
export const createDimensionTableReportForDrilldown = (report, reportMeta) => {
  const {
    stagedColumns,
    columns,
    table,
    filtering,
    eventDateInterval,
    expressions
  } = removeUnencodedColumnsForDrilldown(stripMagicMeasures(report));
  const displayOrderComparator = getChannelDisplayOrderComparator(report);
  const columnFields = columns.sort(displayOrderComparator).toOrderedSet().union(stagedColumns.toOrderedSet()).filter(column => column.aggregation !== AggregationTypes.DELEGATE).map(column => column.field);
  const dimensions = columnFields.map(createDefaultDrilldownDimensionWithTransform).map(dimension => dimension.remove('sort'));
  const defaultReport = createDefaultReportWithVisualType(VisualTypes.TABLE).set('expressions', expressions).set('table', table).set('columns', ImmutableMap(Object.fromEntries(dimensions.toArray().filter(dimension => !!dimension).map(dimension => [dimension.alias, dimension])))).set('eventDateInterval', eventDateInterval).set('filtering', filtering);
  const defaultVisual = defaultReport.get('visual') || createDefaultVisual(VisualTypes.TABLE);
  const updatedVisual = defaultVisual.update('encodings', nextEncodings => dimensions.reduce((acc, dimension) => {
    const expressionField = getExpressionFieldFromColumn(report, dimension);
    return encodeChannel({
      encodings: acc,
      channelName: ChannelNames.COLUMNS,
      column: dimension,
      expressionField
    });
  }, nextEncodings));
  return applySorting(defaultReport.set('visual', updatedVisual), reportMeta);
};
export const getDateResolution = (dateColumnId, report) => {
  const dateColumn = report.columns.get(dateColumnId);
  if (!dateColumn) {
    return undefined;
  }
  return getDateResolutionForColumn(dateColumn);
};
export const reportHasReferenceToTable = (reportDefinition, table) => getColumnsFromTable(reportDefinition.columns, table).size > 0 || reportDefinition.filtering && getFilterGroupsForTable(reportDefinition.filtering, table).size > 0 || getStagedColumnsFromTable(reportDefinition.stagedColumns, table).size > 0;

// todo remove eventually
export const tempRemoveEmptySnowflakeProperties = (report, reportMeta) => {
  const columnAliasesToKeep = report.stagedColumns.filter(stagedColumn => {
    return isMagicMeasure(stagedColumn) || stagedColumn.field.table === DATASET_TABLE || isFieldExpressionBased(stagedColumn.field) || !isEmptySnowflakeProperty(reportMeta.properties.getIn([stagedColumn.field.table, stagedColumn.field.name]));
  }).map(stagedColumn => stagedColumn.alias).toSet();
  return report.update('stagedColumns', stagedColumns => stagedColumns.filter(stagedColumn => columnAliasesToKeep.has(stagedColumn.alias)).toList()).update('stagedEncodings', stagedEncodings => stagedEncodings.filter(stagedEncoding => columnAliasesToKeep.has(stagedEncoding.column)).toList());
};
export const removeTable = (report, table) => {
  const expressionAliasesReferencingTable = report.expressions.filter(expressionField => !!expressionFieldReferencesTable(expressionField, table.name)).map(expressionField => expressionField.alias);
  const expressionColumnsReferencingTable = report.columns.toList().filter(column => isFieldExpressionBased(column.field) && expressionAliasesReferencingTable.includes(column.field.name));
  const next = getColumnsFromTable(report.columns, table).concat(expressionColumnsReferencingTable).reduce((resultReport, column) => {
    return resultReport.update('columns', resultColumns => removeColumnById(resultColumns, column.alias)).update('visual', resultVisual => resultVisual && decodeColumn(resultVisual, column));
  }, report).update('filtering', filtering => removeFiltersOfTable(filtering, table)).update('filtering', filtering => filtering && removeFiltersWithPredicate(filtering, stagedFilter => {
    if (stagedFilter && stagedFilter.type === FilterRefTypes.EXPRESSION_FIELD) {
      return expressionAliasesReferencingTable.includes(stagedFilter.field);
    }
    return false;
  }, filter => {
    if (isFieldExpressionBased(filter.field)) {
      return expressionAliasesReferencingTable.includes(filter.field.name);
    }
    return false;
  })).update('stagedColumns', stagedColumns => stagedColumns.filterNot(column => isColumnFromTable(column, table) || isFieldExpressionBased(column.field) && expressionAliasesReferencingTable.includes(column.field.name)).toList()).update('expressions', expressionFields => {
    return expressionFields.filter(expressionField => !expressionAliasesReferencingTable.includes(expressionField.alias)).toList();
  });
  return next.update('stagedEncodings', stagedEncodings => stagedEncodings.filter(encoding => !!next.stagedColumns.find(column => encoding.column === column.alias)).toList());
};
export const replaceMagicMeasure = (report, fromTable, toTable) => {
  if (!fromTable || !toTable) {
    return report;
  }
  const maybeReplaceColumn = column => {
    const magicMeasure = isMagicMeasure(column) && createMagicMeasure(toTable);
    return magicMeasure && column.field.table === fromTable.name ? column.set('field', magicMeasure.field).delete('name') : column;
  };
  return report.update('table', tableDescription => findAndReplaceTable(tableDescription, fromTable, toTable)).update('columns', columns => columns.map(maybeReplaceColumn).toMap()).update('stagedColumns', stagedColumns => stagedColumns.map(maybeReplaceColumn).toList());
};
export const doesColumnCountExceedExpandedMaximum = reportDefinition => {
  return removeUnencodedColumns(reportDefinition).columns.size > EXPANDED_REPORT_COLUMNS_COUNT;
};
export const isSearchTableReport = reportDefinition => getMeasures(reportDefinition.columns).isEmpty();
const getReportChannelColumnIds = (reportDefinition, channelName) => {
  const encodings = getReportVisualEncodings(reportDefinition);
  const encodingList = encodings && getEncodingListInChannel(encodings, channelName);
  return encodingList ? encodingList.map(encoding => encoding.column).toList() : undefined;
};
const VISUAL_TYPES_TO_DATE_CHANNEL = {
  [VisualTypes.LINE]: [ChannelNames.X],
  [VisualTypes.AREA]: [ChannelNames.X],
  [VisualTypes.VERTICAL_BAR]: [ChannelNames.X],
  [VisualTypes.HORIZONTAL_BAR]: [ChannelNames.Y],
  [VisualTypes.SCATTER]: [ChannelNames.X, ChannelNames.Y]
};
const isTimeSeriesCompatibleVisualType = (visualType, allowedVisualTypes) => {
  const allVisualTypesToDateChannelKeys = Object.keys(VISUAL_TYPES_TO_DATE_CHANNEL);
  const filteredVisualTypes = allowedVisualTypes ? allVisualTypesToDateChannelKeys.filter(key => isTimeSeriesLikeVisualType(key) && allowedVisualTypes.includes(key)) : allVisualTypesToDateChannelKeys;
  return filteredVisualTypes.includes(visualType);
};
export const getTimeSeriesLikeReportDateColumnId = (reportDefinition, allowedVisualTypes) => {
  const reportVisualType = getReportVisualType(reportDefinition);

  // invalid visual type or not provided
  if (!reportVisualType) {
    return undefined;
  }

  // visual type is not compatible (not mapped in VISUAL_TYPES_TO_DATE_CHANNEL or excluded)
  if (!isTimeSeriesCompatibleVisualType(reportVisualType, allowedVisualTypes)) {
    return undefined;
  }

  // all possible channels to locate date column
  const dateChannelNames = VISUAL_TYPES_TO_DATE_CHANNEL[reportVisualType];
  const columnsToCheck = dateChannelNames && dateChannelNames.reduce((dateColumns, dateChannelName) => {
    const dateChannelColumnIds = getReportChannelColumnIds(reportDefinition, dateChannelName);
    if (!dateChannelColumnIds || dateChannelColumnIds.isEmpty()) {
      return dateColumns;
    }

    // first column in channel should be "date like" to be considered "time series like"
    const firstColumnId = dateChannelColumnIds.first();
    const reportColumns = reportDefinition.get('columns');
    const dateColumn = reportColumns.get(firstColumnId) || undefined;
    return dateColumns.push(dateColumn);
  }, List());
  const dateColumn = columnsToCheck && columnsToCheck.find(column => {
    return column && isDateLikeField(column.get('field')) && hasDateTrunc(column);
  });
  return dateColumn ? dateColumn.alias : undefined;
};
export const isTimeSeriesLikeReport = (reportDefinition, allowedVisualTypes) => {
  const dateColumnId = getTimeSeriesLikeReportDateColumnId(reportDefinition, allowedVisualTypes);
  return dateColumnId !== undefined;
};