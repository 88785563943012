'use es6';

import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { createGetBlogsById, createGetSearchBlogOptions, getBlogsById, getSearchBlogOptions } from 'reference-resolvers/api/BlogPostAPI';
import createBatchedReferenceResolver from '../lib/createBatchedReferenceResolver';
export const createBlogOptionsReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.BLOGS,
  createFetchSearchPage: createGetSearchBlogOptions,
  fetchSearchPage: getSearchBlogOptions,
  createFetchByIds: createGetBlogsById,
  fetchByIds: getBlogsById
}, options));
export default createBlogOptionsReferenceResolver();