'use es6';

import { AssetPermission } from '../../../../public/permission/asset-permission';
import { hasSufficientPermission } from '../../../../public/permission/compute-permission';
import { getAndCheck } from '../../common/get-and-check-param';
import { getAllProblems } from '../../common/get-problems-no-check';
import { limitExceeded } from '../../common/limits';
import { createProblem } from '../../common/problem';
import { CUSTOM_REPORT_COUNT, CUSTOM_REPORT_LIMIT, HAS_ATTRIBUTION_ACCESS, HAS_CUSTOM_REPORTING_ACCESS, HAS_DATASETS_ACCESS, HAS_SALES_ANALYTICS_ACCESS, REPORT_IS_ATTRIBUTION, REPORT_IS_CUSTOM, REPORT_IS_SALES_ANALYTICS, REPORT_USES_DATASET, USER_REPORT_PERMISSION, RESTRICTED_FIELD_LEVEL_PROPERTIES } from '../../params/all-params';
import { CUSTOM_DASHBOARD_LIMIT_EXCEEDED_AND_DASHBOARD_SHOULD_LOCKDOWN } from 'dashboard-lib/private/action-problem-solution/problems/lockdown-problem';
import { LACKS_SNOWFLAKE_TEMPLATE_ACCESS } from '../../problems/snowflake-template-problem';
import { LACKS_PAYMENTS_REPORT_ACCESS } from '../../problems/payment-reporting-problem';
export const PERMISSION_DENIED = createProblem('PERMISSION_DENIED', params => {
  const reportPermission = getAndCheck(USER_REPORT_PERMISSION, params);
  return !hasSufficientPermission(AssetPermission.VIEW, reportPermission);
});
export const CUSTOM_REPORT_LIMIT_EXCEEDED = createProblem('CUSTOM_REPORT_LIMIT_EXCEEDED', params => {
  const reportIsCustom = getAndCheck(REPORT_IS_CUSTOM, params);
  const customReportCount = getAndCheck(CUSTOM_REPORT_COUNT, params);
  const customReportLimit = getAndCheck(CUSTOM_REPORT_LIMIT, params);
  return reportIsCustom && limitExceeded(customReportCount, customReportLimit);
});
export const LACKS_BUILDER = createProblem('LACKS_BUILDER', params => getAndCheck(REPORT_IS_CUSTOM, params) && !getAndCheck(HAS_CUSTOM_REPORTING_ACCESS, params));
export const LACKS_DATASETS_ACCESS = createProblem('LACKS_DATASETS_ACCESS', params => !getAndCheck(HAS_DATASETS_ACCESS, params) && getAndCheck(REPORT_USES_DATASET, params));
export const LACKS_SALES_ANALYTICS_ACCESS = createProblem('LACKS_SALES_ANALYTICS_ACCESS', params => getAndCheck(REPORT_IS_SALES_ANALYTICS, params) && !getAndCheck(HAS_SALES_ANALYTICS_ACCESS, params));
export const LACKS_ATTRIBUTION_REPORT_ACCESS = createProblem('LACKS_ATTRIBUTION_REPORT_ACCESS', params => getAndCheck(REPORT_IS_ATTRIBUTION, params) && !getAndCheck(HAS_ATTRIBUTION_ACCESS, params));
export const LACKS_FIELD_ACCESS = createProblem('LACKS_FIELD_ACCESS', params => {
  const restrictedFieldLevelProperties = getAndCheck(RESTRICTED_FIELD_LEVEL_PROPERTIES, params);
  return restrictedFieldLevelProperties && restrictedFieldLevelProperties.length > 0;
});
export const rankedProblems = [PERMISSION_DENIED, LACKS_DATASETS_ACCESS, LACKS_SALES_ANALYTICS_ACCESS, LACKS_ATTRIBUTION_REPORT_ACCESS, LACKS_BUILDER, LACKS_SNOWFLAKE_TEMPLATE_ACCESS, LACKS_PAYMENTS_REPORT_ACCESS, LACKS_FIELD_ACCESS, CUSTOM_DASHBOARD_LIMIT_EXCEEDED_AND_DASHBOARD_SHOULD_LOCKDOWN, CUSTOM_REPORT_LIMIT_EXCEEDED];
export const getAll = params => getAllProblems(params, rankedProblems);