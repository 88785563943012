import * as checked from 'reporting-data/lib/checked';
import { userInfo as resolveUserInfo } from 'reporting-data/request/user-info';
import { TableAndPropertyMeta } from '../schema/source-records';
import { resolveReportMeta } from './report-meta';
export const ReportDeps = checked.record({
  meta: TableAndPropertyMeta,
  userInfo: checked.any()
}, 'ReportDeps');
export const resolveDeps = reportDefinition => {
  return Promise.all([resolveReportMeta(reportDefinition), resolveUserInfo()]).then(([meta, userInfo]) => {
    return ReportDeps({
      meta,
      userInfo
    });
  });
};