'use es6';

import { define } from 'general-store';
import { Map as ImmutableMap, fromJS } from 'immutable';
import { RequestState } from 'dashboard-lib/public/request/request-state';
import compose from 'transmute/compose';
import isEmpty from 'transmute/isEmpty';
import { DEHYDRATED, HYDRATED } from '../constants/ResourceState';
import { USER_ATTRIBUTE_FETCH_PENDING, USER_ATTRIBUTE_FETCH_SUCCESS, USER_ATTRIBUTE_FETCH_FAILURE, USER_ATTRIBUTE_UPDATE_PENDING, USER_ATTRIBUTE_UPDATE_SUCCESS, USER_ATTRIBUTE_UPDATE_FAILURE, USER_ATTRIBUTE_REMOVE_PENDING, USER_ATTRIBUTE_REMOVE_SUCCESS, USER_ATTRIBUTE_REMOVE_FAILURE, HUB_SETTINGS_FETCH_PENDING, HUB_SETTINGS_FETCH_SUCCESS, HUB_SETTINGS_FETCH_FAILURE, HUB_SETTINGS_UPDATE_PENDING, HUB_SETTINGS_UPDATE_SUCCESS, HUB_SETTINGS_UPDATE_FAILURE } from '../constants/SettingsActionTypes';
const parseMaybeJSON = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return str;
  }
  return JSON.parse(str);
};
const parse = settings => fromJS(settings.reduce((memo, {
  key,
  value
}) => Object.assign({}, memo, {
  [key]: parseMaybeJSON(value)
}), {}));
let userAttributes = ImmutableMap();
let userAttributesResourceState = DEHYDRATED;
let userAttributesStatus = RequestState.UNINITIALIZED;
let userAttributesUpdateStatus = RequestState.UNINITIALIZED;
let hubSettings = ImmutableMap();
let hubSettingsResourceState = DEHYDRATED;
let hubSettingsStatus = RequestState.UNINITIALIZED;
let hubSettingsUpdateStatus = RequestState.UNINITIALIZED;

/**
 * A resource is considered loaded once it is hydrated with data
 * @param resourceState
 * @returns {boolean}
 */
const isLoaded = resourceState => resourceState === HYDRATED;
const settingsStore = define().defineName('SettingsStore').defineGet(key => {
  switch (key) {
    case 'user-attributes-request-status':
      return userAttributesStatus;
    case 'user-attributes-loaded':
      return isLoaded(userAttributesResourceState);
    case 'hub-settings-request-status':
      return hubSettingsStatus;
    case 'hub-settings-loaded':
      return isLoaded(hubSettingsResourceState);
    case 'user-attributes-update-status':
      return userAttributesUpdateStatus;
    case 'hub-settings-update-status':
      return hubSettingsUpdateStatus;
    case 'user-attributes':
      return userAttributes;
    case 'hub-settings':
      return hubSettings;
    default:
      return {};
  }
});
const defineUserAttribute = store => store.defineResponseTo(USER_ATTRIBUTE_FETCH_PENDING, () => {
  userAttributesStatus = RequestState.PENDING;
}).defineResponseTo(USER_ATTRIBUTE_FETCH_FAILURE, () => {
  userAttributes = ImmutableMap();
  userAttributesStatus = RequestState.FAILURE;
  userAttributesResourceState = HYDRATED;
}).defineResponseTo(USER_ATTRIBUTE_FETCH_SUCCESS, ({
  settings
}) => {
  userAttributes = fromJS(Object.keys(settings).reduce((attributes, key) => {
    const value = settings[key];
    attributes[key] = parseMaybeJSON(value);
    return attributes;
  }, {}));
  userAttributesStatus = RequestState.SUCCESS;
  userAttributesResourceState = HYDRATED;
}).defineResponseTo(USER_ATTRIBUTE_UPDATE_PENDING, () => {
  userAttributesUpdateStatus = RequestState.PENDING;
}).defineResponseTo(USER_ATTRIBUTE_UPDATE_FAILURE, () => {
  userAttributesUpdateStatus = RequestState.FAILURE;
}).defineResponseTo(USER_ATTRIBUTE_UPDATE_SUCCESS, ({
  key,
  value
}) => {
  userAttributes = userAttributes.set(key, value);
  userAttributesUpdateStatus = RequestState.SUCCESS;
}).defineResponseTo(USER_ATTRIBUTE_REMOVE_PENDING, () => {
  userAttributesUpdateStatus = RequestState.PENDING;
}).defineResponseTo(USER_ATTRIBUTE_REMOVE_FAILURE, () => {
  userAttributesUpdateStatus = RequestState.FAILURE;
}).defineResponseTo(USER_ATTRIBUTE_REMOVE_SUCCESS, key => {
  userAttributes = userAttributes.remove(key);
  userAttributesUpdateStatus = RequestState.SUCCESS;
});
const defineHubSettings = store => store.defineResponseTo(HUB_SETTINGS_FETCH_PENDING, () => {
  hubSettingsStatus = RequestState.PENDING;
}).defineResponseTo(HUB_SETTINGS_FETCH_FAILURE, () => {
  if (isEmpty(userAttributes)) {
    hubSettingsStatus = RequestState.UNINITIALIZED;
  } else {
    hubSettingsStatus = RequestState.FAILURE;
  }
}).defineResponseTo(HUB_SETTINGS_FETCH_SUCCESS, ({
  settings
}) => {
  hubSettings = hubSettings.merge(parse(settings));
  hubSettingsStatus = RequestState.SUCCESS;
  hubSettingsResourceState = HYDRATED;
}).defineResponseTo(HUB_SETTINGS_UPDATE_PENDING, () => {
  hubSettingsUpdateStatus = RequestState.PENDING;
}).defineResponseTo(HUB_SETTINGS_UPDATE_FAILURE, () => {
  hubSettingsUpdateStatus = RequestState.FAILURE;
}).defineResponseTo(HUB_SETTINGS_UPDATE_SUCCESS, ({
  key,
  value
}) => {
  hubSettings = hubSettings.set(key, value);
  hubSettingsUpdateStatus = RequestState.SUCCESS;
});
const register = store => store.register();
export default compose(register, defineHubSettings, defineUserAttribute)(settingsStore);