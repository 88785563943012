'use es6';

import I18n from 'I18n';
import { fromJS, List, Map as ImmutableMap } from 'immutable';
import * as referencedObjectTypes from '../../constants/referencedObjectTypes';
import isBetPortal from '../../lib/isBetPortal';
import prefix from '../../lib/prefix';
import offlineSources from '../../retrieve/unified/options/offlineSources';
import { getBetEngagementPropertyGroups } from './bet-engagement';
import { getEngagementTypeOptions } from './engagement-type-options';
import { userInfo } from '../../request/user-info';
import { ENGAGEMENT_SOURCE_MIGRATION } from '../../constants/gates';
const translate = prefix('reporting-data.properties.engagement');
const translateGroup = prefix('reporting-data.groups.engagement');
const translateCommon = prefix('reporting-data.properties.common');
const migratedEngagementProperties = engagementTypeOptions => [{
  // engagement.type
  name: 'hs_engagement_type',
  label: translate('type'),
  type: 'enumeration',
  options: engagementTypeOptions
}, {
  // engagement.createdAt
  name: 'hs_createdate',
  label: translateCommon('created'),
  type: 'datetime'
}, {
  // engagement.timestamp
  name: 'hs_timestamp',
  label: translate('timestamp'),
  type: 'datetime'
}];
export default (() => Promise.all([getEngagementTypeOptions(), userInfo()]).then(([engagementTypeOptions, {
  gates
}]) => {
  const isUngatedForMigrateEngagementProperties = gates && gates.includes('RaaS:EngagementReportsLegacyPropertiesMigration');
  const isUngatedForEngagementSourceMigration = gates === null || gates === void 0 ? void 0 : gates.includes(ENGAGEMENT_SOURCE_MIGRATION);
  return fromJS([{
    name: 'engagementInfo',
    displayName: translateGroup('engagementInfo'),
    displayOrder: 0,
    hubspotDefined: true,
    properties: [{
      name: 'associations.contact',
      label: translateCommon('associatedContacts'),
      type: 'enumeration',
      hidden: false,
      blocklistedForFiltering: true
    }, {
      name: 'associations.company',
      label: translateCommon('associatedCompanies'),
      type: 'enumeration',
      hidden: false,
      blocklistedForFiltering: true
    }, {
      name: 'associations.deal',
      label: translateCommon('associatedDeals'),
      type: 'enumeration',
      hidden: false,
      blocklistedForFiltering: true
    }, {
      name: 'associations.ticket',
      label: translateCommon('associatedTickets'),
      type: 'enumeration',
      hidden: false,
      blocklistedForFiltering: true
    }, {
      name: 'engagement.id',
      label: translate('instance'),
      type: 'string',
      hidden: true
    }, {
      name: 'engagement.details',
      label: translate('details'),
      type: 'string',
      format: {
        type: 'string',
        isHtml: true
      },
      blocklistedForFiltering: true
    }, Object.assign({
      name: 'engagement.timestamp',
      label: translate('timestamp'),
      type: 'datetime'
    }, isUngatedForMigrateEngagementProperties ? {
      hidden: true
    } : {}), Object.assign({
      name: 'engagement.createdAt',
      label: translateCommon('created'),
      type: 'datetime'
    }, isUngatedForMigrateEngagementProperties ? {
      hidden: true
    } : {}), Object.assign({
      name: 'engagement.ownerId',
      label: translate('owner'),
      type: 'enumeration',
      externalOptions: true,
      referencedObjectType: referencedObjectTypes.OWNER
    }, isUngatedForMigrateEngagementProperties ? {
      hidden: true
    } : {}), {
      // engagement.ownerId
      name: 'hubspot_owner_id',
      label: translate('owner'),
      type: 'enumeration',
      externalOptions: true,
      hidden: true,
      referencedObjectType: referencedObjectTypes.OWNER
    }, {
      name: 'engagement.createdBy',
      label: translate('createdBy'),
      type: 'enumeration',
      reportingOverwrittenNumericType: true,
      reportingOverwrittenNumericNullLabel: I18n.text('reporting-data.missing.unknown.user'),
      externalOptions: true,
      referencedObjectType: referencedObjectTypes.USER
    }, {
      name: 'engagement.html',
      label: translate('html'),
      type: 'string',
      format: {
        type: 'string',
        isHtml: true
      },
      hidden: true
    }, {
      name: 'engagement.lastUpdated',
      label: translateCommon('updated'),
      type: 'datetime',
      hidden: true
    }, {
      name: 'engagement.modifiedBy',
      label: translate('modifiedBy'),
      type: 'enumeration',
      externalOptions: true,
      referencedObjectType: referencedObjectTypes.USER,
      hidden: true
    }, Object.assign({
      name: 'engagement.type',
      label: translate('type'),
      type: 'enumeration',
      options: engagementTypeOptions
    }, isUngatedForMigrateEngagementProperties ? {
      hidden: true
    } : {}), {
      name: 'engagement.teamId',
      label: translate('team'),
      type: 'enumeration',
      externalOptions: true,
      referencedObjectType: referencedObjectTypes.TEAM
    }, {
      // engagement.teamId
      name: 'hubspot_team_id',
      label: translate('team'),
      type: 'enumeration',
      hidden: true,
      externalOptions: true,
      referencedObjectType: referencedObjectTypes.TEAM
    }, {
      name: 'hs_all_accessible_team_ids',
      label: translate('allTeams'),
      type: 'enumeration',
      externalOptions: true,
      referencedObjectType: referencedObjectTypes.TEAM
    }, ...(isUngatedForEngagementSourceMigration ? [{
      // engagement.source replacement
      name: 'hs_object_source_label',
      label: translate('recordSource'),
      type: 'enumeration',
      externalOptions: true,
      externalOptionsReferenceType: 'SOURCE_LABEL'
    }, {
      name: 'engagement.source',
      label: translate('source'),
      type: 'enumeration',
      options: fromJS(offlineSources()).reduce((acc, val, key) => acc.push(ImmutableMap({
        label: val,
        value: key
      })), List()),
      hidden: true
    }] : [{
      // engagement.source replacement
      name: 'hs_object_source_label',
      label: translate('recordSource'),
      type: 'enumeration',
      externalOptions: true,
      hidden: true,
      externalOptionsReferenceType: 'SOURCE_LABEL'
    }, {
      name: 'engagement.source',
      label: translate('source'),
      type: 'enumeration',
      options: fromJS(offlineSources()).reduce((acc, val, key) => acc.push(ImmutableMap({
        label: val,
        value: key
      })), List())
    }]), ...(isBetPortal() ? [] : [{
      name: 'engagement.activityType',
      label: translate('activityType'),
      type: 'enumeration',
      externalOptions: true
    }]), ...(isUngatedForMigrateEngagementProperties ? migratedEngagementProperties(engagementTypeOptions) : [])]
  }]).concat(getBetEngagementPropertyGroups());
}));