import * as checked from 'reporting-data/lib/checked';
import { DataSourceType, DataSourceTypes } from './data-source';
export const DATASET_TABLE = '__DATASET__';

// todo temporarily keep the export to avoid breaking downstream builds
export const SourceTypes = DataSourceTypes;
export const JoinTypes = {
  ASSOCIATION: 'ASSOCIATION',
  FIELD: 'FIELD',
  COORDINATE: 'COORDINATE',
  OBJECT_TO_LIST: 'OBJECT_TO_LIST'
};
export const AssociationJoin = checked.record({
  type: checked.string().always(JoinTypes.ASSOCIATION),
  // required if object-object, not required if event-object
  combinedAssociationTypeId: checked.string().optional()
}, 'AssociationJoin');
export const FieldJoin = checked.record({
  type: checked.string().always(JoinTypes.FIELD),
  leftField: checked.string(),
  rightField: checked.string()
}, 'FieldJoin');
export const CoordinateJoin = checked.record({
  type: checked.string().always(JoinTypes.COORDINATE),
  // one of `leftField` or `rightField` must be present
  leftField: checked.string().optional(),
  rightField: checked.string().optional()
}, 'CoordinateJoin');
export const ObjectToListJoin = checked.record({
  type: checked.string().always(JoinTypes.OBJECT_TO_LIST),
  leftField: checked.string(),
  rightField: checked.string()
}, 'ObjectToListJoin');
const _JoinDefinition = checked.poly('type', {
  [JoinTypes.ASSOCIATION]: AssociationJoin,
  [JoinTypes.FIELD]: FieldJoin,
  [JoinTypes.COORDINATE]: CoordinateJoin,
  [JoinTypes.OBJECT_TO_LIST]: ObjectToListJoin
}, 'JoinDefinition');
export const JoinDefinition = _JoinDefinition;
const createJoinRecord = tableDescription => checked.record({
  target: tableDescription,
  definitions: checked.list(JoinDefinition).defaultValue([])
}, 'Join');
const _TableDescription = checked.recursive(__TableDescription => {
  const __Join = createJoinRecord(__TableDescription);
  return checked.record({
    name: checked.string(),
    type: DataSourceType,
    datasetId: checked.string().optional(),
    // todo future looking: consider removing `objectTypeId` and `eventsDigestType` and just use `dataSourceId`
    objectTypeId: checked.string().optional(),
    eventsDigestType: checked.string().optional(),
    externalDatasourceId: checked.string().optional(),
    datasourceId: checked.string().optional(),
    join: checked.list(__Join).optional().defaultValue([])
  }, 'TableDescription');
});
export const TableDescription = _TableDescription;
export const Join = createJoinRecord(_TableDescription);
const _JoinPath = checked.recursive(__JoinPath => checked.record({
  name: checked.string(),
  type: DataSourceType,
  objectTypeId: checked.string().optional(),
  join: __JoinPath
}, 'JoinPath'));

// similar to TableDescription, but describes a single join path

export const JoinPath = _JoinPath;