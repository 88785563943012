import { useSelector } from 'react-redux';
// @ts-expect-error migrate upstream
import { selectHasCustomReportingAcccess } from 'dashboard-lib/public/userInfo/scopes';
// @ts-expect-error migrate upstream
import { selectAllScopes } from 'dashboard-lib/public/userInfo';
import { usePlatformDashboardSelector, usePlatformDashboardDispatch } from 'platform-dashboard-ui/store';
import { RequestState } from 'platform-dashboard-ui/filter/requests/request-state';
import { selectDataSourcesMetadataWithUniqueLabels, selectDataSourcesMetadataStatus, fetchDataSourcesMetadata } from 'platform-dashboard-ui/ducks/dataSourceMetadataSlice';
import { useReportDataSourceUsages } from './use-report-data-source-usages';
import { useEffect } from 'react';
import { useSavedDashboardFiltersPanelContext } from '../context';
import { getDataSourceKeysForFilters } from '../../../../../../ducks/applied-dashboard-filters/utils';
import { selectDataSourcesProperties, selectDataSourcesPropertiesStatus, fetchDataSourcesProperties } from 'platform-dashboard-ui/ducks/dataSourcePropertiesSlice';
import { selectSavedFilterSets } from '../../../../../../ducks/savedDashboardFilters';
const useDataSources = dataSourceKeys => {
  const hasCustomReportingAccess = useSelector(selectHasCustomReportingAcccess);
  const scopes = useSelector(selectAllScopes);
  const platformDispatch = usePlatformDashboardDispatch();
  const dataSourcesMetadata = usePlatformDashboardSelector(selectDataSourcesMetadataWithUniqueLabels(dataSourceKeys));
  const dataSourcesProperties = usePlatformDashboardSelector(selectDataSourcesProperties(dataSourceKeys));
  const dataSourcesMetadataStatus = usePlatformDashboardSelector(selectDataSourcesMetadataStatus(dataSourceKeys));
  const dataSourcesPropertiesStatus = usePlatformDashboardSelector(selectDataSourcesPropertiesStatus(dataSourceKeys));
  const areDataSourcesPropertiesLoaded = dataSourcesPropertiesStatus === RequestState.SUCCEEDED;
  const areDataSourcesMetadataLoaded = dataSourcesMetadataStatus === RequestState.SUCCEEDED;
  useEffect(() => {
    platformDispatch(fetchDataSourcesProperties(dataSourceKeys, hasCustomReportingAccess, scopes));
  }, [dataSourceKeys, platformDispatch, hasCustomReportingAccess, scopes]);
  useEffect(() => {
    platformDispatch(fetchDataSourcesMetadata(dataSourceKeys));
  }, [dataSourceKeys, platformDispatch]);
  const isLoading = !areDataSourcesMetadataLoaded || !areDataSourcesPropertiesLoaded;
  return {
    dataSourceKeys,
    dataSourcesMetadata: dataSourcesMetadata || {},
    dataSourcesProperties,
    isLoading
  };
};
export const useReportDataSources = () => {
  const {
    dashboardId
  } = useSavedDashboardFiltersPanelContext();
  const reportDataSourceUsages = useReportDataSourceUsages();
  const savedFilterSets = useSelector(selectSavedFilterSets(dashboardId));
  const dataSourceKeys = savedFilterSets.reduce((dataSourceKeySet, savedFilterSet) => savedFilterSet.filters.reduce((nextDataSourceKeySet, filter) => nextDataSourceKeySet.add(filter.dataSource), dataSourceKeySet), new Set(Object.keys(reportDataSourceUsages)));
  return useDataSources(Array.from(dataSourceKeys));
};
export const useFiltersDataSources = filters => {
  return useDataSources(getDataSourceKeysForFilters(filters));
};
export const useDraftFiltersDataSources = () => {
  const {
    draftFilterSetFilters
  } = useSavedDashboardFiltersPanelContext();
  return useFiltersDataSources(draftFilterSetFilters);
};