'use es6';

import isEmpty from 'transmute/isEmpty';
import { getDimensionPropertiesForReport, isGroupingRequired, hasFirstDimensionControlSupport, getStaticDimension, isSnowflakeReport } from '../salesAnalyticsHelpers';
const getValidDimensions = (report, subAppKey, gates) => {
  if (!hasFirstDimensionControlSupport(report)) {
    return [];
  }
  return Object.keys(getDimensionPropertiesForReport(subAppKey, report, gates));
};
export function validateGrouping(contextChange, context, report, subAppKey, defaultContext, __scopes, gates) {
  //relational + journey reports don't support the grouping context yet
  if (isSnowflakeReport(report)) {
    return contextChange;
  }
  const grouping = contextChange.grouping != null ? contextChange.grouping : context.grouping;
  const validDimensions = getValidDimensions(report, subAppKey, gates);
  const isEmptyGroupingValid = !isGroupingRequired(report) && getStaticDimension(report, subAppKey, context.grouping);
  if (validDimensions.includes(grouping) || isEmpty(grouping) && isEmptyGroupingValid) {
    return contextChange;
  }
  return Object.assign({}, contextChange, {
    grouping: defaultContext.grouping
  });
}