'use es6';

import http from 'hub-http/clients/apiClient';
const SLACK_INTEGRATION_API_PATH = 'slack-integration/v1';
const SLACK_USER_QUERY_LIMIT = 10;
class SlackAPI {
  static shareToSlack(recordToShare, channelId, message) {
    return http.post(`${SLACK_INTEGRATION_API_PATH}/share`, {
      data: {
        objectId: recordToShare.id,
        objectType: recordToShare.type,
        channelId,
        message
      }
    });
  }
  static searchChannels(channelName, nextCursor) {
    return http.get(`${SLACK_INTEGRATION_API_PATH}/slack/channels/page`, {
      query: {
        limit: SLACK_USER_QUERY_LIMIT,
        channelName,
        nextCursor
      }
    });
  }
  static getIntegrationConnectionStatus() {
    return http.get(`${SLACK_INTEGRATION_API_PATH}/connection-status`);
  }
}
export default SlackAPI;