// @ts-expect-error migrate upstream dependency
import { connect } from 'general-store';
import PropTypes from 'prop-types';
import compose from 'transmute/compose';
// @ts-expect-error migrate upstream dependency
import DashboardStore from '../../../stores/DashboardStore';
// @ts-expect-error migrate upstream dependency
import toClass from '../../../decorators/toClass';
// @ts-expect-error migrate upstream dependency
import { CustomReportCountDependency } from '../../../dependencies/DashboardsDependency';
// @ts-expect-error migrate upstream dependency
import AddReportActionDropdown from '../../shared/AddReportActionDropdown';
const ConnectedAddReport = compose(connect({
  dashboard: {
    stores: [DashboardStore],
    deref({
      currentDashboardId
    }) {
      return DashboardStore.get(['dashboards', currentDashboardId]);
    }
  },
  customReportCount: CustomReportCountDependency
}), toClass)(AddReportActionDropdown);
ConnectedAddReport.propTypes = {
  currentDashboardId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};
export default ConnectedAddReport;