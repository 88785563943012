import { useDispatch, useSelector } from 'react-redux';
import { useUpdateDashboardFilterSet } from '../../../../../../lib/savedDashboardFilters/hooks';
import { track, EVENTS
// @ts-expect-error migrate upstream
} from '../../../../../../../tracking/DashboardUITracker';
import { savedDashboardFiltersActions, selectAppliedSavedFilterSet, selectFilterSetById } from '../../../../../../ducks/savedDashboardFilters';
import { useSavedDashboardFiltersPanelContext } from '../context';
import { useReportDataSources } from './use-data-sources';
import { areFiltersSame } from '../../../../../../lib/dashboardFilters/types';
export const useSaveDraftFilterSet = () => {
  const {
    dataSourcesProperties,
    dataSourcesMetadata
  } = useReportDataSources();
  const {
    isLoading,
    onUpdate,
    hasError,
    error
  } = useUpdateDashboardFilterSet(dataSourcesProperties, dataSourcesMetadata);
  const {
    dashboardId,
    draftFilterSetFilters,
    draftFilterSetName,
    editingFilterSetId = -1,
    draftFilterSetFrequency,
    draftFilterSetFrequencyUseFiscalYear
  } = useSavedDashboardFiltersPanelContext();
  const dispatch = useDispatch();
  const appliedFilterSet = useSelector(selectAppliedSavedFilterSet(dashboardId));
  const currentFilterSet = useSelector(selectFilterSetById(dashboardId, editingFilterSetId));
  const handleSaveDraftFilterSet = () => {
    if (!isLoading && currentFilterSet) {
      const payload = {
        id: currentFilterSet.id,
        defaultFilter: currentFilterSet.defaultFilter,
        name: draftFilterSetName,
        dashboardId,
        filters: draftFilterSetFilters,
        frequency: draftFilterSetFrequency,
        useFiscalYear: draftFilterSetFrequencyUseFiscalYear
      };
      onUpdate(payload).then(nextFilterSet => {
        if (appliedFilterSet && nextFilterSet.id === appliedFilterSet.id) {
          dispatch(savedDashboardFiltersActions.loadSavedDashboardFilterSet({
            dashboardId,
            nextAppliedFilterSet: nextFilterSet
          }));
        }
        track(EVENTS.saveDashboardFilterSet, {
          interactionSource: 'edit-filter-set',
          actionType: 'explore',
          assetType: 'dashboard'
        });
      }).catch(() => {
        console.error('Failed to save filter set');
      });
    }
  };
  const hasUnsavedFilters = (() => {
    if (!currentFilterSet) {
      return false;
    }
    if (currentFilterSet.filters.length !== draftFilterSetFilters.length) {
      return true;
    }
    if (currentFilterSet.filters.some(filter => !draftFilterSetFilters.some(filterSetFilter => areFiltersSame(filterSetFilter, filter)))) {
      return true;
    }
    if (currentFilterSet.frequency !== draftFilterSetFrequency) {
      return true;
    }
    if (currentFilterSet.useFiscalYear !== draftFilterSetFrequencyUseFiscalYear) {
      return true;
    }
    if (currentFilterSet.name !== draftFilterSetName) {
      return true;
    }
    return false;
  })();
  return {
    isLoading,
    onSaveDraftFilterSet: handleSaveDraftFilterSet,
    hasError,
    error,
    hasUnsavedFilters
  };
};