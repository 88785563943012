'use es6';

import { Record } from 'immutable';
import keyMirror from 'react-utils/keyMirror';
import always from 'transmute/always';
import get from 'transmute/get';
import { overSome } from '../../private/js-util';
import { createError, matchErrorExact, matchErrorMessageExact } from './error-utils';
import { matchErrorMessageRegex } from './error-utils';
const KNOWN_ERROR_TYPE = keyMirror({
  WEAK_MAP_NOT_DEFINED: null,
  OUT_OF_MEMORY: null,
  // https://hubspot.slack.com/archives/CC04HSNNQ/p1558555156046200
  POST_MESSAGE_PROPERTY: null,
  PICKADATE: null
});
const KnownError = Record({
  id: null,
  matcher: always(false),
  fingerprinter: always([])
}, 'KnownError');
const KNOWN_ERROR_DATA = [KnownError({
  id: KNOWN_ERROR_TYPE.WEAK_MAP_NOT_DEFINED,
  matcher: matchErrorExact(createError('ReferenceError', 'WeakMap is not defined')),
  fingerprinter: always('ReferenceError: WeakMap is not defined')
}), KnownError({
  id: KNOWN_ERROR_TYPE.OUT_OF_MEMORY,
  matcher: overSome(matchErrorMessageExact('Out of memory'), matchErrorMessageExact('Mémoire insuffisante')),
  fingerprinter: always('Error: Out of memory')
}), KnownError({
  id: KNOWN_ERROR_TYPE.POST_MESSAGE_PROPERTY,
  matcher: matchErrorExact(createError('TypeError', "Cannot read property 'postMessage' of null")),
  fingerprinter: always("TypeError: Cannot read property 'postMessage' of null")
}), KnownError({
  id: KNOWN_ERROR_TYPE.PICKADATE,
  matcher: matchErrorMessageRegex(/pickadate is not a function/),
  fingerprinter: always('pickadate is not a function')
})];
const getFingerprinter = get('fingerprinter');
const getMatcher = get('matcher');
export const matchKnownError = error => {
  for (const knownError of KNOWN_ERROR_DATA) {
    const matcher = getMatcher(knownError);
    if (matcher(error)) {
      return knownError;
    }
  }
  return null;
};
export const createKnownErrorFingerprint = error => {
  const maybeKnownError = matchKnownError(error);
  return maybeKnownError ? [getFingerprinter(maybeKnownError)(error)] : [];
};