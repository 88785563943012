import { PROPERTY_BLOCKLIST } from 'reporting-snowflake/funnel/constants/properties';
const isHiddenProperty = ({
  hidden
}) => !!hidden;
export const isBlocklisted = ({
  name
}) => PROPERTY_BLOCKLIST.includes(name);
export const excludeProperties = (properties, conditions) => {
  const propertyKeys = Object.keys(properties);
  return propertyKeys.reduce((accum, propertyKey) => {
    const sqlProperty = properties[propertyKey];
    const {
      propertyDefinition: {
        property
      }
    } = sqlProperty;
    const shouldExcludeProperty = conditions.some(condition => condition(property));
    return shouldExcludeProperty ? accum : Object.assign({}, accum, {
      [propertyKey]: sqlProperty
    });
  }, {});
};
export const sanitizeProperties = properties => excludeProperties(properties, [isHiddenProperty, isBlocklisted]);
export const __TESTABLE__ = {
  sanitizeProperties
};