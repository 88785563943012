'use es6';

import { Map as ImmutableMap } from 'immutable';
import { Promise } from '../lib/promise';
import { makeOption } from './Option';
const caches = [];
const requestBase = (ids, getter, cacheHas, cachePut) => {
  const misses = ids.filter(id => !cacheHas(id));
  if (misses.isEmpty()) {
    return Promise.resolve(ImmutableMap());
  }
  return getter(misses).then(cachePut);
};
const request = (ids, getter, cacheHas, cacheGet, cachePut) => requestBase(ids, getter, cacheHas, cachePut).then(() => ids.toSet().toMap().map(cacheGet));
const requestOptions = (ids, getter, cacheHas, cacheGet, cachePut) => requestBase(ids, getter, cacheHas, cachePut).then(() => ids.toMap().map(id => makeOption(id, cacheGet(id))));
const requestFromCache = (ids, cacheGet) => Promise.resolve(ids.reduce((options, id) => options.set(id, makeOption(id, cacheGet(id))), ImmutableMap()));
export function ReferenceStore(getter) {
  let cache = ImmutableMap();
  const cacheGet = id => cache.get(id);
  const cacheHas = id => cache.has(id);
  const cachePut = idNameMap => {
    cache = cache.merge(idNameMap);
    return idNameMap;
  };
  const store = {
    bustCache: () => {
      cache = ImmutableMap();
    },
    store: cachePut,
    request: ids => request(ids, getter, cacheHas, cacheGet, cachePut),
    requestOptions: ids => requestOptions(ids, getter, cacheHas, cacheGet, cachePut),
    requestFromCache: ids => requestFromCache(ids, cacheGet)
  };
  caches.push(store);
  return store;
}
export const bustAllReferenceStores = () => {
  caches.forEach(cache => cache.bustCache());
};