'use es6';

import { List } from 'immutable';
import indexBy from 'transmute/indexBy';
import get from 'transmute/get';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
const formatSequenceStepOrderReference = step => ReferenceRecord({
  id: String(step.id),
  label: String(step.stepOrder)
});
const formatSequenceStepOrders = steps => indexBy(get('id'), List(steps).map(formatSequenceStepOrderReference));
export default formatSequenceStepOrders;