import { NoInputType } from '../InputTypes';
import { makeOperator } from './Operator';
// @ts-expect-error untyped
import { defaultIsValue } from './OperatorValidators';
export default makeOperator({
  inputType: NoInputType,
  name: 'DeprecatedFilter',
  values: [{
    name: 'value',
    isValid: defaultIsValue
  }]
});