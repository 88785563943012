import { Map as ImmutableMap } from 'immutable';
export const COLOR = {
  ORANGE: 'orange',
  YELLOW: 'yellow',
  GREEN: 'green',
  AQUA: 'aqua',
  BLUE: 'blue',
  PURPLE: 'purple',
  PINK: 'pink',
  DARK_ORANGE: 'dark orange',
  DARK_AQUA: 'dark aqua',
  DARK_PURPLE: 'dark purple',
  DARK_YELLOW: 'dark yellow',
  DARK_PINK: 'dark pink',
  DARK_BLUE: 'dark blue',
  DARK_GREEN: 'dark green'
};
export const colors = ImmutableMap({
  [COLOR.ORANGE]: '#fea58e',
  [COLOR.YELLOW]: '#f5c78e',
  [COLOR.GREEN]: '#a4d398',
  [COLOR.AQUA]: '#51d3d9',
  [COLOR.BLUE]: '#81c1fd',
  [COLOR.PURPLE]: '#bda9ea',
  [COLOR.PINK]: '#ea90b1',
  [COLOR.DARK_ORANGE]: '#c3705c',
  [COLOR.DARK_AQUA]: '#009ca2',
  [COLOR.DARK_PURPLE]: '#8775b2',
  [COLOR.DARK_YELLOW]: '#bb915b',
  [COLOR.DARK_PINK]: '#b05c7d',
  [COLOR.DARK_BLUE]: '#468cc4',
  [COLOR.DARK_GREEN]: '#6b9a5b'
});