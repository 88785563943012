'use es6';

import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { searchBlogTagOptions, createSearchBlogTagOptions, getBlogTagsById, createGetBlogTagsById } from 'reference-resolvers/api/BlogPostAPI';
export const createBlogTagOptionsReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.BLOG_TAG,
  createFetchSearchPage: createSearchBlogTagOptions,
  fetchSearchPage: searchBlogTagOptions,
  createFetchByIds: createGetBlogTagsById,
  fetchByIds: getBlogTagsById
}, options));
export default createBlogTagOptionsReferenceResolver();