'use es6';

import cached from './lib/cached';
import connectActions from './redux/connectActions';
import { bustResolves as bustResolvesAction, bustOneResolve as bustOneResolveAction } from './redux/resolve/actions';
import store from './redux/store';
import { bustAllReferenceStores } from './references/OldReferenceStore';
import { bustAll as bustRequests } from './request/http';
const [bustResolves, bustOneResolve] = connectActions(store, [bustResolvesAction, bustOneResolveAction]);
export const bustOne = config => {
  bustOneResolve({
    config
  });
};
export default (() => {
  bustResolves();
  bustRequests();
  cached.resetAll();
  bustAllReferenceStores();
});