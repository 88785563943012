'use es6';

import keyMirror from 'react-utils/keyMirror';
export const FISCAL_YEAR_ENABLED_DEFAULT_RANGE_TYPES = keyMirror({
  ALL: null,
  THIS_DAY: null,
  LAST_DAY: null,
  NEXT_DAY: null,
  THIS_WEEK: null,
  LAST_WEEK: null,
  NEXT_WEEK: null,
  THIS_MONTH: null,
  LAST_MONTH: null,
  NEXT_MONTH: null,
  THIS_QUARTER: null,
  THIS_FISCAL_QUARTER: null,
  LAST_QUARTER: null,
  LAST_FISCAL_QUARTER: null,
  NEXT_QUARTER: null,
  NEXT_FISCAL_QUARTER: null,
  THIS_YEAR: null,
  THIS_FISCAL_YEAR: null,
  LAST_YEAR: null,
  LAST_FISCAL_YEAR: null,
  NEXT_YEAR: null,
  NEXT_FISCAL_YEAR: null,
  ROLLING: null,
  CUSTOM: null
});
export const DEFAULT_RANGE_TYPES = keyMirror({
  ALL: null,
  THIS_DAY: null,
  LAST_DAY: null,
  NEXT_DAY: null,
  THIS_WEEK: null,
  LAST_WEEK: null,
  NEXT_WEEK: null,
  THIS_MONTH: null,
  LAST_MONTH: null,
  NEXT_MONTH: null,
  THIS_QUARTER: null,
  LAST_QUARTER: null,
  NEXT_QUARTER: null,
  THIS_YEAR: null,
  LAST_YEAR: null,
  NEXT_YEAR: null,
  ROLLING: null,
  CUSTOM: null
});
export const RANGE_TYPES = keyMirror({
  ALL: null,
  THIS_DAY: null,
  LAST_DAY: null,
  NEXT_DAY: null,
  THIS_WEEK: null,
  LAST_WEEK: null,
  NEXT_WEEK: null,
  THIS_MONTH: null,
  LAST_MONTH: null,
  NEXT_MONTH: null,
  CUSTOM_MONTH: null,
  THIS_QUARTER: null,
  LAST_QUARTER: null,
  NEXT_QUARTER: null,
  CUSTOM_QUARTER: null,
  THIS_YEAR: null,
  LAST_YEAR: null,
  NEXT_YEAR: null,
  CUSTOM_YEAR: null,
  ROLLING: null,
  CUSTOM: null,
  IS_EQUAL_TO: null,
  IS_BEFORE_DATE: null,
  IS_AFTER_DATE: null
});
export const HISTORICAL_RANGE_TYPES = keyMirror({
  ALL: null,
  THIS_DAY: null,
  LAST_DAY: null,
  THIS_WEEK: null,
  LAST_WEEK: null,
  THIS_MONTH: null,
  LAST_MONTH: null,
  THIS_QUARTER: null,
  LAST_QUARTER: null,
  THIS_YEAR: null,
  LAST_YEAR: null,
  ROLLING: null,
  CUSTOM: null
});
export const RELATIVE_RANGE_TYPES = [RANGE_TYPES.IS_EQUAL_TO, RANGE_TYPES.IS_BEFORE_DATE, RANGE_TYPES.IS_AFTER_DATE];
export const CUSTOM_UNIT_RANGE_TYPES = [RANGE_TYPES.CUSTOM_MONTH, RANGE_TYPES.CUSTOM_QUARTER, RANGE_TYPES.CUSTOM_YEAR];
export const ROLLING_DATE_TYPES = {
  7: 7,
  14: 14,
  30: 30,
  60: 60,
  90: 90,
  180: 180,
  365: 365
};

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = module.exports;
}