import { fromJS } from 'immutable';
import omit from 'transmute/omit';
export function formatOwnersResponse(ownersList, userId) {
  const owners = {};
  const remotes = {};
  ownersList.forEach(owner => {
    const {
      ownerId,
      remoteList
    } = owner;
    owners[String(ownerId)] = owner;
    remoteList.forEach(remote => {
      const remoteId = String(remote.remoteId);
      remotes[remoteId] = omit(['remoteList'], owner);
      remotes[remoteId].remoteType = remote.remoteType || 'NONE';
    });
  });

  // NOTE: from crm app.coffee
  let currentOwnerId;
  if (remotes[String(userId)] != null) {
    ({
      ownerId: currentOwnerId
    } = remotes[String(userId)]);
  } else {
    currentOwnerId = -1;
  }
  return {
    currentOwnerId,
    owners: fromJS(owners),
    remotes: fromJS(remotes),
    currentUserId: userId
  };
}

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}