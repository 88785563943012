'use es6';

import { fromJS, List } from 'immutable';
import { ENGAGEMENT_EMAILS } from '../../constants/dataTypes';
import engagementModule from '../../dataTypeDefinitions/inboundDb/engagement';
import prefix from '../../lib/prefix';
import { Promise } from '../../lib/promise';
import overridePropertyTypes from '../../retrieve/inboundDb/common/overridePropertyTypes';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import countProperty from '../partial/count-property';
import getCommonPropertyGroups from '../partial/engagement-common';
const translate = prefix('reporting-data.properties.engagementEmail');
const translateGroup = prefix('reporting-data.groups.engagement');
export const getEngagementEmailPropertyGroups = () => Promise.resolve(List.of(fromJS({
  name: 'engagementEmailInfo',
  displayName: translateGroup('engagementEmailInfo'),
  displayOrder: 0,
  hubspotDefined: true,
  properties: [{
    name: 'email.subject',
    label: translate('subject'),
    type: 'string'
  }, {
    name: 'email.text',
    label: translate('text'),
    type: 'string',
    hidden: true
  }, {
    name: 'email.html',
    label: translate('html'),
    type: 'string',
    format: {
      type: 'string',
      isHtml: true
    },
    hidden: true
  }, {
    name: 'email.trackerKey',
    label: translate('trackerKey'),
    type: 'string',
    hidden: true
  }, {
    name: 'email.messageId',
    label: translate('messageId'),
    type: 'string',
    hidden: true
  }, {
    name: 'email.threadId',
    label: translate('threadId'),
    type: 'string',
    hidden: true
  }, {
    name: 'email.status',
    label: translate('status'),
    type: 'enumeration',
    options: [{
      displayOrder: -1,
      label: translate('statusOptions.bounced'),
      value: 'BOUNCED'
    }, {
      displayOrder: -1,
      label: translate('statusOptions.failed'),
      value: 'FAILED'
    }, {
      displayOrder: -1,
      label: translate('statusOptions.scheduled'),
      value: 'SCHEDULED'
    }, {
      displayOrder: -1,
      label: translate('statusOptions.sending'),
      value: 'SENDING'
    }, {
      displayOrder: -1,
      label: translate('statusOptions.sent'),
      value: 'SENT'
    }]
  }, {
    name: 'email.sentVia',
    label: translate('sentVia'),
    type: 'enumeration',
    options: [{
      displayOrder: -1,
      label: translate('sentViaOptions.gmail'),
      value: 'GMAIL'
    }, {
      displayOrder: -1,
      label: translate('sentViaOptions.office365'),
      value: 'OFFICE365'
    }, {
      displayOrder: -1,
      label: translate('sentViaOptions.sendgrid'),
      value: 'SENDGRID'
    }]
  }, {
    name: 'email.attachedVideoId',
    label: translate('attachedVideoId'),
    type: 'string'
  }, {
    name: 'email.attachedVideoOpened',
    label: translate('attachedVideoOpened'),
    type: 'bool'
  }, {
    name: 'email.attachedVideoWatched',
    label: translate('attachedVideoWatched'),
    type: 'bool'
  }]
})));
export const getPropertyGroups = () => Promise.all([getCommonPropertyGroups(), getEngagementEmailPropertyGroups()]).then(([commonPropertyGroups, engagementEmailPropertyGroups]) => List([...commonPropertyGroups, ...engagementEmailPropertyGroups]));
export const getProperties = () => createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(ENGAGEMENT_EMAILS)))().then(overridePropertyTypes(engagementModule.getInboundSpec()));