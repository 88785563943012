export const exportJourneyReport = (report, exportOptions) => {
  const {
    format: exportFormat,
    title: exportName
  } = exportOptions;
  const reportResolveOptions = {
    fetchFromCache: false,
    updateCache: true
  };

  // remove 'measure' from query
  const journeyQuery = Object.assign({}, report.journeyQuery);
  delete journeyQuery.measure;
  const journeyReportWithOptions = {
    journeyQuery,
    reportOptions: reportResolveOptions
  };
  return {
    exportFormat,
    exportName,
    journeyReportWithOptions,
    reportId: report.id
  };
};