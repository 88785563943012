'use es6';

import createSimpleCachedReferenceResolver from '../lib/createSimpleCachedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { getAllAnalyticsViews, createGetAllAnalyticsViews } from '../api/AnalyticsViewApi';
export const createAnalyticsViewsReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.ANALYTICS_VIEWS,
  createFetchData: createGetAllAnalyticsViews,
  fetchData: getAllAnalyticsViews
}, options));
export default createAnalyticsViewsReferenceResolver();