export const setDataToSmuggle = (requestName, data) => {
  try {
    window.sessionStorage.setItem(requestName, JSON.stringify(data));
    return true;
  } catch (e) {
    // Do nothing
  }
  try {
    // @ts-expect-error Smuggling data via window
    window[key] = data;
    return true;
  } catch (e) {
    // Do nothing
  }
  return false;
};
export const readSmuggledData = requestName => {
  try {
    const rawData = window.sessionStorage.getItem(requestName);
    window.sessionStorage.removeItem(requestName);
    return rawData && JSON.parse(rawData);
  } catch (e) {
    console.log({
      e
    });
    // Do nothing
  }
  try {
    // @ts-expect-error Trying to read smuggled data out of window
    const data = window[requestName];
    // @ts-expect-error Trying to clear smuggled data out of window
    delete window[requestName];
    return data;
  } catch (e) {
    // Do nothing
  }
  return null;
};