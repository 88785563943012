'use es6';

import * as PropertyTypes from 'customer-data-objects/property/PropertyTypes';
import invariant from 'react-utils/invariant';
import protocol from 'transmute/protocol';
import * as Operators from '../../../../filterQueryFormat/operator/Operators';
import { convertSimpleDateToTimestamp, convertTimestampToUtc } from '../../../../filterQueryFormat/timestamps/TimestampConverters';
import { PORTAL_TZ_ALIGNED, UTC_TZ_ALIGNED } from '../../../../filterQueryFormat/timestamps/TimestampTypes';
import { hasNewIlsTimeFiltersEnabled } from '../../../../permissions/gates';
import { RANGE_OPERATOR_TYPES } from '../../constants/rollingDateRange';
import * as OperatorTypes from '../../ObjectSegOperatorTypes';
import * as OperationTypes from '../../OperationTypes';
import { getAfterAnotherPropertyOperation, getAfterDateOperation, getBeforeAnotherPropertyOperation, getBeforeDateOperation, getBetweenDatesOperation, getEqualToDateOperation, getLessThanXDaysOperation, getMoreThanXDaysOperation, getNotBetweenDatesOperation, getPropertyNotUpdatedInLastXDaysOperation, getPropertyUpdatedInLastXDaysOperation, getUpdatedAfterPropertyOperation, getUpdatedBeforePropertyOperation } from './toTimeOperation';
const propertyTypeOperationTypes = {
  [PropertyTypes.BOOLEAN]: OperationTypes.BOOLEAN,
  [PropertyTypes.DATE]: OperationTypes.DATE_TIME,
  [PropertyTypes.DATE_TIME]: OperationTypes.DATE_TIME,
  [PropertyTypes.ENUMERATION]: OperationTypes.ENUMERATION,
  [PropertyTypes.NUMBER]: OperationTypes.NUMBER,
  [PropertyTypes.STRING]: OperationTypes.STRING
};
const getOperationType = field => propertyTypeOperationTypes[field.get('type')] || OperationTypes.STRING;
export const getDateValueOperation = (operatorType, operator) => {
  const alignToTheEOD = operatorType === OperatorTypes.IS_AFTER;
  const usePortalTZ = operatorType === OperatorTypes.IS_AFTER || operatorType === OperatorTypes.IS_BEFORE;
  const timestamp = convertSimpleDateToTimestamp(operator.value, usePortalTZ ? PORTAL_TZ_ALIGNED : UTC_TZ_ALIGNED, alignToTheEOD);
  return {
    propertyType: OperationTypes.DATE_TIME,
    operator: operatorType,
    timestamp,
    requiresTimeZoneConversion: false
  };
};
const getRangedOperation = (operatorType, operator) => {
  const {
    value,
    highValue
  } = operator.value < operator.highValue ? operator : {
    value: operator.highValue,
    highValue: operator.value
  };
  return getOperationType(operator.field) === OperationTypes.DATE_TIME ? {
    propertyType: OperationTypes.DATE_TIME_RANGED,
    operator: operatorType,
    lowerBoundTimestamp: convertTimestampToUtc(value),
    upperBoundTimestamp: convertTimestampToUtc(highValue)
  } : {
    propertyType: OperationTypes.NUMBER_RANGED,
    operator: operatorType,
    lowerBound: value,
    upperBound: highValue
  };
};
const getGreaterRollingOperation = operator => {
  const operatorType = operator.direction === 'forward' ? OperatorTypes.IS_MORE_THAN_X_DAYS_FROM_NOW : OperatorTypes.IS_MORE_THAN_X_DAYS_AGO;
  return {
    propertyType: OperationTypes.DATE_TIME_ROLLING,
    operator: operatorType,
    numberOfDays: operator.numberOfDays
  };
};
const getRollingDateRangeOperation = operator => {
  const {
    direction,
    includeFutureDates,
    useFiscalYear,
    isInclusive,
    timeUnit,
    value
  } = operator.value;
  const operatorName = Object.keys(RANGE_OPERATOR_TYPES).find(name => {
    const config = RANGE_OPERATOR_TYPES[name];
    return direction === config.direction && includeFutureDates === config.includeFutureDates && isInclusive === config.isInclusive;
  });
  invariant(operatorName, `Cannot create filter for rolling date, no object seg operator was not able to be determined.`);
  const filter = {
    propertyType: OperationTypes.CALENDAR_DATE,
    operationType: OperationTypes.CALENDAR_DATE,
    operator: operatorName,
    timeUnit,
    timeUnitCount: value
  };
  if (useFiscalYear) {
    filter.useFiscalYear = true;
  }
  return filter;
};
const getLessRollingOperation = operator => {
  const operatorType = operator.direction === 'forward' ? OperatorTypes.IS_LESS_THAN_X_DAYS_FROM_NOW : OperatorTypes.IS_LESS_THAN_X_DAYS_AGO;
  return {
    propertyType: OperationTypes.DATE_TIME_ROLLING,
    operator: operatorType,
    numberOfDays: operator.numberOfDays
  };
};
const getPropertyUpdatedOperation = (operatorType, operator) => {
  return {
    propertyType: OperationTypes.ROLLING_PROPERTY_UPDATED,
    operator: operatorType,
    numberOfDays: operator.numberOfDays
  };
};
const getPropertyUpdatedCompareOperation = (operatorType, operator) => ({
  defaultComparisonValue: operator.defaultNullValue.defaultComparisonValue,
  defaultValue: operator.defaultNullValue.defaultValue,
  propertyType: OperationTypes.PROPERTY_UPDATED_COMPARATIVE,
  operator: operatorType,
  comparisonPropertyName: operator.value,
  operationType: OperationTypes.PROPERTY_UPDATED_COMPARATIVE,
  operatorName: operatorType
});
const getCompareOperation = (operatorType, operator) => ({
  defaultComparisonValue: operator.defaultNullValue.defaultComparisonValue,
  defaultValue: operator.defaultNullValue.defaultValue,
  propertyType: OperationTypes.DATE_TIME_COMPARATIVE,
  operator: operatorType,
  comparisonPropertyName: operator.value
});
const getValueOperation = (operatorType, operator) => {
  const objectSegOperationType = getOperationType(operator.field);
  if (objectSegOperationType === OperationTypes.DATE_TIME) {
    return getDateValueOperation(operatorType, operator);
  }
  return {
    propertyType: objectSegOperationType,
    operator: operatorType,
    value: operator.value
  };
};
const getValuesArray = value => {
  if (value && value.toArray) {
    return value.toArray().map(String);
  }
  return [];
};
const getValuesOperation = (operatorType, propertyType, operator) => {
  return {
    propertyType,
    operator: operatorType,
    values: getValuesArray(operator.value)
  };
};
const getAllTypesOperation = operatorType => {
  return {
    propertyType: OperationTypes.ALL,
    operator: operatorType
  };
};
const shouldUseNewTimeFiltersFormat = (operator, options, isDateTimeSpecific = true) => {
  const objectSegOperationType = getOperationType(operator.field);
  if ((objectSegOperationType === OperationTypes.DATE_TIME || !isDateTimeSpecific) && options && options.isUngated && hasNewIlsTimeFiltersEnabled(options.isUngated) && !options.isNotUsingTimePoints) {
    return true;
  }
  return false;
};
const validateDateTimeOperator = (operator, options) => {
  if (shouldUseNewTimeFiltersFormat(operator, options)) {
    console.error(`${operator.constructor} operator is not supported with timepoint filters. See https://git.hubteam.com/HubSpot/crm-filters/issues/1528 for more details`);
  }
};
const invalidOperator = operator => {
  invariant(false, 'cannot convert value operation of operator "%s"', operator.name);
};
const _toValueComparison = protocol({
  name: '_toValueComparison',
  args: [protocol.TYPE],
  fallback: invalidOperator
});
_toValueComparison.implement(Operators.After, (operator, options) => {
  if (shouldUseNewTimeFiltersFormat(operator, options)) {
    return getAfterAnotherPropertyOperation(operator);
  }
  return getCompareOperation(OperatorTypes.IS_AFTER, operator);
});
_toValueComparison.implement(Operators.Before, (operator, options) => {
  if (shouldUseNewTimeFiltersFormat(operator, options)) {
    return getBeforeAnotherPropertyOperation(operator);
  }
  return getCompareOperation(OperatorTypes.IS_BEFORE, operator);
});
_toValueComparison.implement(Operators.AfterDate, (operator, options) => {
  validateDateTimeOperator(operator, options);
  return getValueOperation(OperatorTypes.IS_AFTER, operator);
});
_toValueComparison.implement(Operators.BeforeDate, (operator, options) => {
  validateDateTimeOperator(operator, options);
  return getValueOperation(OperatorTypes.IS_BEFORE, operator);
});
_toValueComparison.implement(Operators.AfterTimePoint, (operator, options) => getAfterDateOperation(operator, options));
_toValueComparison.implement(Operators.BeforeTimePoint, (operator, options) => getBeforeDateOperation(operator, options));
_toValueComparison.implement(Operators.Contain, operator => getValueOperation(OperatorTypes.CONTAINS, operator));
_toValueComparison.implement(Operators.NotContain, operator => getValueOperation(OperatorTypes.DOES_NOT_CONTAIN, operator));
_toValueComparison.implement(Operators.StartsWith, operator => getValueOperation(OperatorTypes.STARTS_WITH, operator));
_toValueComparison.implement(Operators.EndsWith, operator => getValueOperation(OperatorTypes.ENDS_WITH, operator));
_toValueComparison.implement(Operators.In, operator => getValuesOperation(OperatorTypes.IS_ANY_OF, OperationTypes.ENUMERATION, operator));
_toValueComparison.implement(Operators.NotIn, operator => getValuesOperation(OperatorTypes.IS_NONE_OF, OperationTypes.ENUMERATION, operator));
_toValueComparison.implement(Operators.EverIn, operator => getValuesOperation(OperatorTypes.HAS_EVER_BEEN_ANY_OF, OperationTypes.ENUMERATION, operator));
_toValueComparison.implement(Operators.NeverIn, operator => getValuesOperation(OperatorTypes.HAS_NEVER_BEEN_ANY_OF, OperationTypes.ENUMERATION, operator));
_toValueComparison.implement(Operators.EqualAll, operator => getValuesOperation(OperatorTypes.IS_EXACTLY, OperationTypes.ENUMERATION, operator));
_toValueComparison.implement(Operators.NotEqualAll, operator => getValuesOperation(OperatorTypes.IS_NOT_EXACTLY, OperationTypes.ENUMERATION, operator));
_toValueComparison.implement(Operators.ContainAll, operator => getValuesOperation(OperatorTypes.CONTAINS_ALL, OperationTypes.ENUMERATION, operator));
_toValueComparison.implement(Operators.NotContainAll, operator => getValuesOperation(OperatorTypes.DOES_NOT_CONTAIN_ALL, OperationTypes.ENUMERATION, operator));
_toValueComparison.implement(Operators.EverEqualAll, operator => getValuesOperation(OperatorTypes.HAS_EVER_BEEN_EXACTLY, OperationTypes.ENUMERATION, operator));
_toValueComparison.implement(Operators.NeverEqualAll, operator => getValuesOperation(OperatorTypes.HAS_NEVER_BEEN_EXACTLY, OperationTypes.ENUMERATION, operator));
_toValueComparison.implement(Operators.EverContainedAll, operator => getValuesOperation(OperatorTypes.HAS_EVER_CONTAINED_ALL, OperationTypes.ENUMERATION, operator));
_toValueComparison.implement(Operators.NeverContainedAll, operator => getValuesOperation(OperatorTypes.HAS_NEVER_CONTAINED_ALL, OperationTypes.ENUMERATION, operator));
_toValueComparison.implement(Operators.EverContained, operator => getValueOperation(OperatorTypes.HAS_EVER_CONTAINED, operator));
_toValueComparison.implement(Operators.NeverContained, operator => getValueOperation(OperatorTypes.HAS_NEVER_CONTAINED, operator));
_toValueComparison.implement(Operators.Equal, (operator, options) => {
  validateDateTimeOperator(operator, options);
  return getValueOperation(OperatorTypes.IS_EQUAL_TO, operator);
});
_toValueComparison.implement(Operators.EqualTimePoint, (operator, options) => {
  return getEqualToDateOperation(operator, options);
});
_toValueComparison.implement(Operators.NotEqual, operator => getValueOperation(OperatorTypes.IS_NOT_EQUAL_TO, operator));
_toValueComparison.implement(Operators.EverEqual, operator => getValueOperation(OperatorTypes.HAS_EVER_BEEN_EQUAL_TO, operator));
_toValueComparison.implement(Operators.NeverEqual, operator => getValueOperation(OperatorTypes.HAS_NEVER_BEEN_EQUAL_TO, operator));
_toValueComparison.implement(Operators.Greater, operator => getValueOperation(OperatorTypes.IS_GREATER_THAN, operator));
_toValueComparison.implement(Operators.GreaterOrEqual, operator => getValueOperation(OperatorTypes.IS_GREATER_THAN_OR_EQUAL_TO, operator));
_toValueComparison.implement(Operators.Less, operator => getValueOperation(OperatorTypes.IS_LESS_THAN, operator));
_toValueComparison.implement(Operators.LessOrEqual, operator => getValueOperation(OperatorTypes.IS_LESS_THAN_OR_EQUAL_TO, operator));
_toValueComparison.implement(Operators.InRange, (operator, options) => {
  if (getOperationType(operator.field) === OperationTypes.DATE_TIME) {
    validateDateTimeOperator(operator, options);
  }
  return getRangedOperation(OperatorTypes.IS_BETWEEN, operator);
});
_toValueComparison.implement(Operators.NotInRange, (operator, options) => {
  validateDateTimeOperator(operator, options);
  return getRangedOperation(OperatorTypes.IS_NOT_BETWEEN, operator);
});
_toValueComparison.implement(Operators.BetweenTimePoints, (operator, options) => getBetweenDatesOperation(operator, options));
_toValueComparison.implement(Operators.NotBetweenTimePoints, (operator, options) => getNotBetweenDatesOperation(operator, options));
_toValueComparison.implement(Operators.InRollingDateRange, operator => getRollingDateRangeOperation(operator));
_toValueComparison.implement(Operators.GreaterRolling, (operator, options) => {
  if (shouldUseNewTimeFiltersFormat(operator, options)) {
    return getMoreThanXDaysOperation(operator, options);
  }
  return getGreaterRollingOperation(operator);
});
_toValueComparison.implement(Operators.LessRolling, (operator, options) => {
  if (shouldUseNewTimeFiltersFormat(operator, options)) {
    return getLessThanXDaysOperation(operator, options);
  }
  return getLessRollingOperation(operator);
});
_toValueComparison.implement(Operators.GreaterThanRolling, operator => getGreaterRollingOperation(operator));
_toValueComparison.implement(Operators.LessThanRolling, operator => getLessRollingOperation(operator));
_toValueComparison.implement(Operators.Known, operator => getAllTypesOperation(OperatorTypes.IS_KNOWN, operator));
_toValueComparison.implement(Operators.NotKnown, operator => getAllTypesOperation(OperatorTypes.IS_UNKNOWN, operator));
_toValueComparison.implement(Operators.UpdatedInLastXDays, (operator, options) => {
  if (shouldUseNewTimeFiltersFormat(operator, options, false)) {
    return getPropertyUpdatedInLastXDaysOperation(operator);
  }
  return getPropertyUpdatedOperation(OperatorTypes.UPDATED_IN_LAST_X_DAYS, operator);
});
_toValueComparison.implement(Operators.NotUpdatedInLastXDays, (operator, options) => {
  if (shouldUseNewTimeFiltersFormat(operator, options, false)) {
    return getPropertyNotUpdatedInLastXDaysOperation(operator);
  }
  return getPropertyUpdatedOperation(OperatorTypes.NOT_UPDATED_IN_LAST_X_DAYS, operator);
});
_toValueComparison.implement(Operators.UpdatedAfter, (operator, options) => {
  if (shouldUseNewTimeFiltersFormat(operator, options, false)) {
    return getUpdatedAfterPropertyOperation(operator);
  }
  return getPropertyUpdatedCompareOperation(OperatorTypes.IS_AFTER, operator);
});
_toValueComparison.implement(Operators.UpdatedBefore, (operator, options) => {
  if (shouldUseNewTimeFiltersFormat(operator, options, false)) {
    return getUpdatedBeforePropertyOperation(operator);
  }
  return getPropertyUpdatedCompareOperation(OperatorTypes.IS_BEFORE, operator);
});
_toValueComparison.implement(Operators.EqualAny, operator => getValuesOperation(OperatorTypes.IS_EQUAL_TO, OperationTypes.MULTI_STRING, operator));
_toValueComparison.implement(Operators.NotEqualAny, operator => getValuesOperation(OperatorTypes.IS_NOT_EQUAL_TO, OperationTypes.MULTI_STRING, operator));
_toValueComparison.implement(Operators.ContainAny, operator => getValuesOperation(OperatorTypes.CONTAINS, OperationTypes.MULTI_STRING, operator));
_toValueComparison.implement(Operators.NotContainAny, operator => getValuesOperation(OperatorTypes.DOES_NOT_CONTAIN, OperationTypes.MULTI_STRING, operator));
_toValueComparison.implement(Operators.StartsWithAny, operator => getValuesOperation(OperatorTypes.STARTS_WITH, OperationTypes.MULTI_STRING, operator));
_toValueComparison.implement(Operators.EndsWithAny, operator => getValuesOperation(OperatorTypes.ENDS_WITH, OperationTypes.MULTI_STRING, operator));
_toValueComparison.implement(Operators.EverEqualAny, operator => getValuesOperation(OperatorTypes.IS_EQUAL_TO, OperationTypes.MULTI_STRING, operator));
_toValueComparison.implement(Operators.NeverEqualAny, operator => getValuesOperation(OperatorTypes.IS_NOT_EQUAL_TO, OperationTypes.MULTI_STRING, operator));
const getIncludeObjectsWithNoValueSetForNumberPropertyType = (operator, operation) => {
  const defaultValue = parseFloat(operator.defaultNullValue.defaultValue);
  const {
    value
  } = operator;
  if (typeof value !== 'number') {
    return false;
  }
  switch (operation.operator) {
    case OperatorTypes.IS_EQUAL_TO:
      return defaultValue === value;
    case OperatorTypes.IS_NOT_EQUAL_TO:
      return defaultValue !== value;
    case OperatorTypes.IS_LESS_THAN:
      return defaultValue < value;
    case OperatorTypes.IS_LESS_THAN_OR_EQUAL_TO:
      return defaultValue <= value;
    case OperatorTypes.IS_GREATER_THAN:
      return defaultValue > value;
    case OperatorTypes.IS_GREATER_THAN_OR_EQUAL_TO:
      return defaultValue >= value;
    case OperatorTypes.HAS_EVER_BEEN_EQUAL_TO:
      // will always return false since defaultValue will always be null
      // https://git.hubteam.com/HubSpot/customer-data-filters/pull/3633#discussion_r2737167
      return false;
    case OperatorTypes.HAS_NEVER_BEEN_EQUAL_TO:
      return defaultValue !== null;
    default:
      return false;
  }
};
const getIncludeObjectsWithNoValueSet = (operator, operation) => {
  if (!operator.defaultNullValue) {
    return false;
  }
  const {
    defaultValue,
    includeObjectsWithNoValueSet
  } = operator.defaultNullValue;
  if (includeObjectsWithNoValueSet || defaultValue === null || defaultValue === undefined) {
    return includeObjectsWithNoValueSet;
  }
  if (operation.propertyType === PropertyTypes.NUMBER) {
    return getIncludeObjectsWithNoValueSetForNumberPropertyType(operator, operation);
  }
  return true;
};
const toValueComparison = (operator, options) => {
  const operation = _toValueComparison(operator, options);
  if (!('defaultValue' in operation) && !('defaultComparisonValue' in operation)) {
    const includeObjectsWithNoValueSet = getIncludeObjectsWithNoValueSet(operator, operation);
    if (includeObjectsWithNoValueSet) {
      return Object.assign({}, operation, {
        includeObjectsWithNoValueSet
      });
    }
  }
  return operation;
};
export default toValueComparison;