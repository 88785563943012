import { fromJS } from 'immutable';
import identity from 'transmute/identity';
import { DATE, DATE_TIME, STRING } from 'customer-data-objects/property/PropertyTypes';
import { AfterDate, BeforeDate, ContainAny, Known, NotContainAny, NotKnown
// @ts-expect-error migrate upstream types
} from 'customer-data-filters/filterQueryFormat/operator/Operators';
// @ts-expect-error migrate upstream types
import { convertToContactSearch } from 'customer-data-filters/converters/contactSearch/FilterContactSearch';
// @ts-expect-error migrate upstream types
import { RESERVE_WORDS } from 'customer-data-filters/converters/contactSearch/constants';
import { EQUAL, GREATER, LESS, NOT_EQUAL } from 'customer-data-filters/converters/contactSearch/FilterContactSearchOperatorTypes';
import { getDateRangeFilterOverride, isGenericDateRangeProperty, getDateRangeFiscalYearOverride } from './dateRangeHelpers';
import { getFilterQueryFormatOperator } from './filterConversionHelpers';
import { isDateRangeGeneratedQuickFilterGroup } from './groupQuickFilterHelpers';
// @ts-expect-error migrate upstream types
import { mapPropertyToSearchProperty } from 'reporting-data/retrieve/inboundDb/common/mapToSearchProperties';
import { getCustomWidgetType } from 'reporting-data/report/reportGetters';
import { CUSTOM_WIDGET_TYPES } from 'reporting-data/tsTypes/customWidgetTypes';
import { getReportDataType, isSalesAnalyticsPipelineSnapshotWidget, isPipeline, isFunnel
// @ts-expect-error migrate upstream types
} from 'reporting-action-components/data/schemas/reportSchema';
import { maybeUpdateLegacyListMembershipOperatorName } from 'platform-dashboard-ui/filter/utils/listMembershipFilterUtils';
import { isDynamicDateRangeFilterType } from './types';
// Object seg is the BE data format that powers snowflake reports
// Contact search is the BE data format that powers config (aka single object) reports
// The two types of reports share many operator components, but this map helps resolve the disparities that exist
const ObjectSegOperatorToContactSearchOperatorName = {
  [AfterDate]: GREATER,
  [BeforeDate]: LESS,
  [ContainAny]: EQUAL,
  [NotContainAny]: NOT_EQUAL
};
export const convertObjectSegOperatorToContactSearch = operator => {
  const fieldType = operator.field.type;
  const result = {
    operator: ObjectSegOperatorToContactSearchOperatorName[operator.constructor],
    property: operator.field.name
  };
  if (operator.has('value')) {
    result.value = operator.value;
  }
  if (fieldType === STRING && [ContainAny, NotContainAny].includes(operator.constructor)) {
    result.value = operator.value.filter(identity).map((str = '') => {
      let safeValue = str.trim();
      if (RESERVE_WORDS.includes(safeValue)) {
        safeValue = `"${safeValue}"`;
      }
      return safeValue;
    }).join(' OR ');
  }
  if ([DATE, DATE_TIME].includes(fieldType)) {
    result.dateTimeFormat = 'DATE';
  }
  return result;
};
export const shouldSkipDynamicDateRangeProperty = ({
  dashboardFilter,
  report
}) => {
  const currentDateRangeFilter = report.getIn(['config', 'filters', 'dateRange']);

  // Handles edge case scenario in which some config based reports do not have a dateRange field.
  if (!currentDateRangeFilter) {
    return false;
  }
  const {
    propertyName,
    systemGenerated,
    quickFilterGroupName
  } = dashboardFilter;
  const reportDataType = getReportDataType(report);
  const currentDateRangeFilterProperty = currentDateRangeFilter.get('property');
  // Convert report's date range filter property name to its search naming equivalent
  // to properly match with dashboard filter property name.
  const convertedDateRangePropertyToSearch = mapPropertyToSearchProperty(currentDateRangeFilterProperty, reportDataType);

  // Check if the filter being applied belongs to the dynamic date range quick filter group,
  // or is a dynamic date range filter
  if (isDateRangeGeneratedQuickFilterGroup({
    systemGenerated,
    name: quickFilterGroupName
  }) || isDynamicDateRangeFilterType(dashboardFilter)) {
    // If the filter being applied is the generic "Date range" property for non-framework data sources, then
    // do not skip applying it.
    if (isGenericDateRangeProperty(propertyName)) {
      return false;
    }
    if (isFunnel(report) || isPipeline(report)) {
      return true;
    }

    // Skip applying the dynamic date range quick filter property if the property does not match the SO report's date property.
    // (to mimic the legacy experience)
    return propertyName !== convertedDateRangePropertyToSearch;
  }
  return false;
};
export const getOverriddenConfigReportAndFilters = ({
  dashboardFilters: applicableDashboardFilters,
  dataSourcesProperties,
  report,
  frequency,
  frequencyUseFiscalYear
}) => {
  const overriddenFilters = new Set();
  const currentReportCustomFilters = report.getIn(['config', 'filters', 'custom']).toJS();
  const reportDataType = getReportDataType(report);
  const isCompletedActivities = getCustomWidgetType(report) === CUSTOM_WIDGET_TYPES.COMPLETED_ACTIVITIES;
  const currentDateRangeFilter = report.getIn(['config', 'filters', 'dateRange']);
  const currentDateRangeFilterProperty = currentDateRangeFilter.get('property');

  // Convert report's date range filter property name to its search naming equivalent
  // to properly match with dashboard filter property name.
  const convertedDateRangePropertyToSearch = mapPropertyToSearchProperty(currentDateRangeFilterProperty, reportDataType);
  let nonOverriddenSavedReportFilters = currentReportCustomFilters;
  let newFilters = [];
  let overriddenReport = report;
  for (const filter of applicableDashboardFilters) {
    const {
      dataSource,
      operator: operatorName,
      propertyName
    } = filter;

    // For "Date range" dynamic quick filter group properties, only override
    // the report's date property if the properties match. Otherwise, skip
    // applying the property to the report (to mimic the legacy experience)
    if (shouldSkipDynamicDateRangeProperty({
      dashboardFilter: filter,
      report
    })) {
      continue;
    }

    // If the current filter is the generic date range property filter, or
    // if the filter overrides the report's date range filter,
    // convert the filter to format used by date range editor.
    // Omit this step if the user selects the Known or Unknown operators.
    // Also omit this step if the report is a pipeline report and the property selected is createdate.
    const shouldOverrideDateRangeFilter = (isGenericDateRangeProperty(propertyName) || propertyName === convertedDateRangePropertyToSearch) && !(operatorName === Known.toString() || operatorName === NotKnown.toString()) && !((isFunnel(report) || isPipeline(report)) && propertyName === 'createdate');
    if (shouldOverrideDateRangeFilter) {
      const dateRangeFilterValueOverride = getDateRangeFilterOverride(filter);
      overriddenReport = overriddenReport.setIn(['config', 'filters', 'dateRange', 'value'], fromJS(dateRangeFilterValueOverride));
      const nextDateRangeFiscalYearSetting = getDateRangeFiscalYearOverride(filter);
      if (nextDateRangeFiscalYearSetting !== null) {
        overriddenReport = overriddenReport.setIn(['config', 'filters', 'dateRange', 'useFiscalYear'], nextDateRangeFiscalYearSetting);
      }
    } else {
      const operator = getFilterQueryFormatOperator(filter, dataSourcesProperties[dataSource], isCompletedActivities);
      // skip applying filter if invalid
      if (!operator) {
        continue;
      }
      // convert dashboard filter to contact search format to apply to report
      const contactSearchOperator = Object.keys(ObjectSegOperatorToContactSearchOperatorName).includes(operatorName) ? convertObjectSegOperatorToContactSearch(operator) : convertToContactSearch(operator);

      // Specifically for the "List membership" property, update instances of the legacy name of the property
      // to its ILS counterpart to enable support for both SO and Snowfake reports.
      const maybeUpdatedLegacyListMembershipOperator = maybeUpdateLegacyListMembershipOperatorName(contactSearchOperator);
      newFilters = newFilters.concat(maybeUpdatedLegacyListMembershipOperator);
    }

    // handle overriding saved report filters with dashboard filters
    const newNonOverriddenSavedFilters = [];
    for (const aFilter of nonOverriddenSavedReportFilters) {
      // Convert report property name to search equivalent for correct comparison to dashboard
      // level filter property name.
      const convertedReportPropertyNameToSearch = mapPropertyToSearchProperty(aFilter.property, reportDataType);
      let shouldOverrideFilter = convertedReportPropertyNameToSearch === propertyName;

      // Temporary fix: because the SA Pipeline Snapshot report is a custom widget,
      // there is validation that occurs upon resolving this report in which our
      // logic to override the 'pipeline' property is invalid. Therefore, do not
      // apply the same override logic for the `pipeline` property for this specific
      // custom widget. The SA team is planning to move this report onto the platform
      // in the near future, so this is a bandaid solution to get DB filters compatible with
      // this custom widget.
      // TODO: remove this logic once SA custom widgets are on platform.
      if (isSalesAnalyticsPipelineSnapshotWidget(report) && propertyName === 'pipeline') {
        shouldOverrideFilter = false;
      }

      // Use search property name for correct property name translation.
      const overriddenFilter = Object.assign({}, aFilter, {
        property: convertedReportPropertyNameToSearch
      });
      if (shouldOverrideFilter) {
        overriddenFilters.add({
          isDateRange: false,
          dataSource,
          filter: overriddenFilter
        });
      } else {
        newNonOverriddenSavedFilters.push(aFilter);
      }
    }
    nonOverriddenSavedReportFilters = newNonOverriddenSavedFilters;

    // check if date range filter was overridden by dashboard filter
    if (shouldOverrideDateRangeFilter) {
      overriddenFilters.add({
        isDateRange: true,
        dataSource,
        filter: currentDateRangeFilter
      });
    }
  }
  const updatedFilters = fromJS(nonOverriddenSavedReportFilters.concat(newFilters));
  overriddenReport = overriddenReport.setIn(['config', 'filters', 'custom'], updatedFilters);
  if (frequency && overriddenReport.getIn(['config', 'frequency']) != null) {
    overriddenReport = overriddenReport.setIn(['config', 'frequency'], frequency);

    // update frequency's use fiscal year setting only if there is a dashboard level frequency override
    if (overriddenReport.getIn(['config', 'useFiscalYearInAggregation']) != null) {
      overriddenReport = overriddenReport.setIn(['config', 'useFiscalYearInAggregation'], frequencyUseFiscalYear || false);
    }
  }
  return {
    overriddenFilters,
    overriddenReport
  };
};