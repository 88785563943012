import { isCustomWidgetReport } from 'reporting-data/tsTypes/reportTypes';
// @ts-expect-error Untyped dependency
import CustomWidgets from 'reporting-ui-components/components/customWidgets/constants/widgets';
import { getCustomWidgetType } from 'reporting-data/report/reportGetters';
export const SALES_ANALYTICS_FISCAL_YEAR_GATE = 'EnableSalesAnalyticsFiscalYearFilters';
const FISCAL_YEAR_UNSUPPORTED_SALES_ANALYTICS_CUSTOM_WIDGETS = [CustomWidgets.SALES_QUOTA, CustomWidgets.PIPELINE_SNAPSHOTS, CustomWidgets.WATERFALL, CustomWidgets.CHANGE_HISTORY_DEAL_LIST, CustomWidgets.DEAL_PUSH_ANALYSIS, CustomWidgets.MARKETING_PERFORMANCE];
export const isFiscalYearEnabled = (report, gates) => {
  if (!isCustomWidgetReport(report)) {
    return true;
  }
  const customWidgetType = getCustomWidgetType(report);
  const isSupportedCustomWidget = !FISCAL_YEAR_UNSUPPORTED_SALES_ANALYTICS_CUSTOM_WIDGETS.includes(customWidgetType);
  return isSupportedCustomWidget || gates.includes(SALES_ANALYTICS_FISCAL_YEAR_GATE);
};