'use es6';

import { AggregationTypes, DomainTypes } from '../../schema/column-records';
import { RELATIONAL } from '../../schema/report-records';
import { getColumnAlias, getDimensions, getMeasures, isFieldExpressionBased } from '../../utils/column-utils';
import { doesColumnCountExceedExpandedMaximum, getReportVisualEncodings } from '../../utils/report-utils';
import { findEventLikeTable } from '../../utils/table-utils';
import { getEncodingColumn, getEncodingList } from '../../utils/visual-utils';
import { withinTableLimit as checkWithinTableLimit, withinEventLimit as checkWithinEventLimit } from '../../metadata/limits';
import { combineConstraints, ConstraintTypes, createConstraint, InvalidSpecificationTypes } from '../validate';
import { aggregations } from './aggregations';
import { channels } from './channels';
import { enums } from './enums';
import { area } from './visuals/area';
import { donut } from './visuals/donut';
import { horizontalBar } from './visuals/horizontal-bar';
import { line } from './visuals/line';
import { pie } from './visuals/pie';
import { pivotTable } from './visuals/pivot-table';
import { scatter } from './visuals/scatter';
import { table } from './visuals/table';
import { text } from './visuals/text';
import { verticalBar } from './visuals/vertical-bar';
import { combo } from './visuals/combo';
import { gauge } from './visuals/gauge';
import { expressionFieldsListToMap } from '../../utils/expression-field-utils';
import { Contexts } from '../../../dataset/context-records';
import { filters } from './filters';
import { columnExpressionsAreValid, filterAndColumnExpressionsAreValid, filterExpressionsAreValid, noExpressionFunctionsAccess, datasetPropertiesAreValid } from './expressions';
const getFromReportColumns = getter => report => getter(report.columns);
export const everyAggregateExpressionColumnIsDelegate = createConstraint('everyAggregateExpressionColumnIsDelegate', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (columns, expressionsMap) => columns.every(column => {
  const {
    field
  } = column;
  if (isFieldExpressionBased(field)) {
    const expression = expressionsMap.get(field.name);
    if (expression.context === Contexts.AGGREGATE) {
      return column.aggregation === AggregationTypes.DELEGATE;
    }
  }
  return true;
}), [report => report.columns, report => expressionFieldsListToMap(report.expressions)]);
export const everyDimensionIsDiscrete = createConstraint('everyDimensionIsDiscrete', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, dimensions => dimensions.every(dimension => dimension.get('domain') === DomainTypes.DISCRETE), getFromReportColumns(getDimensions));
export const everyMeasureIsContinuous = createConstraint('everyMeasureIsContinuous', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, measures => measures.every(measure => measure.get('domain') === DomainTypes.CONTINUOUS), getFromReportColumns(getMeasures));
export const noDimensionHasAnAggregation = createConstraint('noDimensionHasAnAggregation', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, dimensions => dimensions.every(dimension => dimension.get('aggregation') === undefined), getFromReportColumns(getDimensions));
export const everyMeasureHasAnAggregation = createConstraint('everyMeasureHasAnAggregation', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, measures => measures.every(measure => measure.get('aggregation') !== undefined), getFromReportColumns(getMeasures));
export const encodingsAreColumns = createConstraint('encodingsAreColumns', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, report => {
  const columns = report.columns.toList();
  const encodings = getReportVisualEncodings(report);
  const columnAliases = columns.map(getColumnAlias);
  const encodingsList = getEncodingList(encodings);
  return encodingsList.map(getEncodingColumn).every(alias => columnAliases.includes(alias));
});
export const sortsAreColumns = createConstraint('sortsAreColumns', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, report => {
  const columns = report.columns;
  const sorts = report.sorts;
  const columnAliases = columns.map(column => column.alias);
  const sortColumnAliases = sorts.map(sort => sort.column);
  return sortColumnAliases.every(alias => columnAliases.includes(alias));
});
export const eventReportsHaveEventDateInterval = createConstraint('eventReportsHaveEventDateInterval', ConstraintTypes.HARD, InvalidSpecificationTypes.ILL_FORMED, (report, meta) => {
  const eventLikeTable = findEventLikeTable(report.table, meta.tables);
  if (!eventLikeTable) {
    return true;
  }
  return !!report.eventDateInterval;
});
export const withinTableLimit = createConstraint('withinTableLimit', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, checkWithinTableLimit, report => report.table);
export const withinEventLimit = createConstraint('withinEventLimit', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, checkWithinEventLimit, report => report.table);
export const withinExpandedColumnLimit = createConstraint('withinExpandedColumnLimit', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, report => !doesColumnCountExceedExpandedMaximum(report), report => report);
export const relationalReportExpandedLimits = combineConstraints('relationalReport', [everyAggregateExpressionColumnIsDelegate, everyDimensionIsDiscrete, everyMeasureIsContinuous, noDimensionHasAnAggregation, everyMeasureHasAnAggregation, encodingsAreColumns, sortsAreColumns, withinTableLimit, withinEventLimit, withinExpandedColumnLimit, channels, enums, aggregations, verticalBar, horizontalBar, line, area, scatter, pie, donut, text, table, pivotTable, combo, gauge, filters], (constraint, report, userInfo) => report.get('type') !== RELATIONAL || constraint.validate(report, userInfo));
export const relationalReport = relationalReportExpandedLimits;
export const relationalReportMeta = combineConstraints('relationalReportMeta', [filterAndColumnExpressionsAreValid, columnExpressionsAreValid, filterExpressionsAreValid, noExpressionFunctionsAccess, datasetPropertiesAreValid, eventReportsHaveEventDateInterval], (constraint, report, meta) => report.get('type') !== RELATIONAL || constraint.validate(report, meta));