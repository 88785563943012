'use es6';

import * as OperatorTypes from 'customer-data-filters/converters/objectSeg/ObjectSegOperatorTypes';
import { BACKWARD, FORWARD } from 'customer-data-filters/filterQueryFormat/rollingDates/RollingDateDirections';
const RANGE_OPERATOR_TYPES = {
  [OperatorTypes.IN_NEXT_TIME_UNIT]: {
    direction: FORWARD,
    isInclusive: false,
    includeFutureDates: true
  },
  [OperatorTypes.IN_THIS_TIME_UNIT_SO_FAR]: {
    direction: BACKWARD,
    isInclusive: true,
    includeFutureDates: false
  },
  [OperatorTypes.IN_LAST_TIME_UNIT]: {
    direction: BACKWARD,
    isInclusive: false,
    includeFutureDates: true
  },
  [OperatorTypes.IN_THIS_TIME_UNIT]: {
    direction: BACKWARD,
    isInclusive: true,
    includeFutureDates: true
  }
};
export { RANGE_OPERATOR_TYPES };