'use es6';

import { Map as ImmutableMap } from 'immutable';

/**
 * Creates a immutable map from a iterable which is indexed by the indexer
 *
 * @param {(item: T) => I} indexer returns the index for the value
 * @return {Immutable.Map<I, T>}
 */
export default ((indexer, collection) => {
  return ImmutableMap().withMutations(result => {
    for (const item of collection) {
      result.set(indexer(item), item);
    }
    return result;
  });
});