'use es6';

import get from 'transmute/get';
import filter from 'transmute/filter';
import map from 'transmute/map';
import compose from 'transmute/compose';
import { Record, List, OrderedSet } from 'immutable';
import { AssignmentMode } from 'dashboard-lib/public/permission/assignment-mode';
import { AssetPermission } from './asset-permission';
import { createPermissionGrant, UserSetType } from 'dashboard-lib/public/permission/permission-grant';
import { invert } from 'dashboard-lib/private/js-util';
import { PermissionLevel } from './permission-level';
const {
  EDIT
} = AssetPermission;

/**
 * ACCESS_CLASS is a derived value sent from GET endpoints for convenience;
 * It is not required for PUT / POST
 */
const ACCESS_CLASS = 'accessClassification';
const PERM_LEVEL = 'permissionLevel';
const PERM_GRANTS = 'permissionGrants';
export const getAccessClassification = get(ACCESS_CLASS);
export const getPermissionLevel = get(PERM_LEVEL);
export const getPermissionGrants = get(PERM_GRANTS);
export const PermissionConfigPayload = Record({
  [ACCESS_CLASS]: AssignmentMode.EVERYONE,
  [PERM_LEVEL]: EDIT,
  [PERM_GRANTS]: List([createPermissionGrant(UserSetType.API_ACCESS)])
});
export const AssetPermissionToPermissionLevel = {
  [AssetPermission.ZERO]: PermissionLevel.NONE,
  [AssetPermission.VIEW]: PermissionLevel.VIEW,
  [AssetPermission.EDIT]: PermissionLevel.EDIT,
  [AssetPermission.CREATE_AND_OWN]: PermissionLevel.CREATE_AND_OWN
};
export const PermissionLevelToAssetPermission = invert(AssetPermissionToPermissionLevel);

/**
 * @param UI state
 * @returns {PermissionConfigPayload}
 */
export const fromState = ({
  selectedUserSet,
  selectedTeamSet,
  assignmentMode,
  permissionValue
}) => {
  const createPermissionGrants = () => {
    if (assignmentMode === AssignmentMode.PRIVATE) {
      return List();
    }
    if (assignmentMode === AssignmentMode.EVERYONE) {
      /*
       Note: the API grant is always the same as permissionValue.
       For example, everyone who is an editor can edit.
       For example, everyone who is a viewer can view.
      */
      const apiGrant = createPermissionGrant(UserSetType.API_ACCESS);
      return List([apiGrant]);
    }
    const userPermissionGrants = List(selectedUserSet).map(userId => createPermissionGrant(UserSetType.SINGLE_USER, userId));
    const teamPermissionGrants = List(selectedTeamSet).map(teamId => createPermissionGrant(UserSetType.TEAM, teamId));
    return userPermissionGrants.concat(teamPermissionGrants);
  };
  const computePermissionLevel = () => {
    const value = assignmentMode === AssignmentMode.PRIVATE ? AssetPermission.ZERO : permissionValue;
    return AssetPermissionToPermissionLevel[value];
  };
  return PermissionConfigPayload({
    [ACCESS_CLASS]: assignmentMode,
    [PERM_LEVEL]: computePermissionLevel(),
    [PERM_GRANTS]: createPermissionGrants()
  });
};
const toEntitySet = type => compose(OrderedSet, map(get('userSetId')), filter(grant => get('userSetType', grant) === type));

/**
 * Payload -> UI state
 * @param {PermissionConfigPayload} payload
 * @returns {object} state
 */
export const toState = payload => {
  const assignmentMode = getAccessClassification(payload);
  const permissionLevel = getPermissionLevel(payload);
  const permissionGrants = getPermissionGrants(payload);
  return {
    assignmentMode,
    permissionValue: PermissionLevelToAssetPermission[permissionLevel],
    selectedUserSet: toEntitySet(UserSetType.SINGLE_USER)(permissionGrants),
    selectedTeamSet: toEntitySet(UserSetType.TEAM)(permissionGrants)
  };
};
export const PermissionSentence = Record({
  dashboardId: undefined,
  permissionLevel: undefined,
  userSetId: undefined,
  userSetType: undefined
});

/**
 * @param {PermissionSentence}
 * @returns {AssetPermission}
 */
export const sentenceToPermission = compose(permissionLevel => PermissionLevelToAssetPermission[permissionLevel], getPermissionLevel);