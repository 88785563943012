'use es6';

import http from 'hub-http/clients/apiClient';
import { List, fromJS } from 'immutable';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
export const createGetProductsByIds = ({
  httpClient
}) => ids => {
  const url = 'inbounddbproducts/v1/products/batch';
  return httpClient.get(url, {
    query: {
      id: ids,
      allPropertiesFetchMode: 'latest_version'
    }
  }).then(response => {
    const referenceRecords = ids.map(id => new ReferenceRecord({
      id: String(id),
      label: response[id] && response[id].properties.name.value || String(id),
      referencedObject: fromJS(response[id])
    }));
    return List(referenceRecords);
  });
};
export const getProductsByIds = createGetProductsByIds({
  httpClient: http
});