import PortalIdParser from 'PortalIdParser';
import { useCallback, useState } from 'react';
import { ERROR, FINISHED, NOT_STARTED, STARTED } from './constants';
import { useHttpClient } from './useHttpClient';
export const CONTENT_ASSISTANCE_SETTINGS_URL = 'content-assistance/v1/settings';
export const useContentAssistantSettings = ({
  onSuccess,
  onError
}) => {
  const [isContentAssistanceEnabled, setIsContentAssistanceEnabled] = useState(false);
  const [isCustomerAnalysisEnabled, setIsCustomerAnalysisEnabled] = useState(false);
  const [error, setError] = useState(null);
  const [getRequest, setGetRequest] = useState(NOT_STARTED);
  const [contentAssistantPostRequest, setContentAssistantPostRequest] = useState(NOT_STARTED);
  const [customerAnalysisPostRequest, setCustomerAnalysisPostRequest] = useState(NOT_STARTED);
  const http = useHttpClient();
  const fetchContentAssistanceSettings = useCallback(() => {
    setGetRequest(STARTED);
    http.get(CONTENT_ASSISTANCE_SETTINGS_URL).then(response => {
      const isContentAssistanceOn = Boolean(response.contentAssistanceEnabled);
      const isCustomerAnalysisOn = Boolean(response.customerAnalysisEnabled);
      setIsContentAssistanceEnabled(isContentAssistanceOn);
      setIsCustomerAnalysisEnabled(isCustomerAnalysisOn);
      if (onSuccess) {
        onSuccess({
          isContentAssistanceEnabled: isContentAssistanceOn,
          isCustomerAnalysisEnabled: isCustomerAnalysisOn
        });
      }
      setGetRequest(FINISHED);
    }).catch(e => {
      setGetRequest(ERROR);
      setError(e);
      if (onError) {
        onError(e);
      }
    });
  }, [http, onSuccess, onError]);
  const postContentAssistantSettings = useCallback(contentAssistanceEnabled => {
    setContentAssistantPostRequest(STARTED);
    return http.post(CONTENT_ASSISTANCE_SETTINGS_URL, {
      data: {
        contentAssistanceEnabled,
        portalId: PortalIdParser.get()
      }
    }).then(() => {
      setIsContentAssistanceEnabled(contentAssistanceEnabled);
      setContentAssistantPostRequest(FINISHED);
    }).catch(e => {
      setContentAssistantPostRequest(ERROR);
      setError(e);
    });
  }, [http]);
  const postCustomerAnalysisSettings = useCallback(customerAnalysisEnabled => {
    setCustomerAnalysisPostRequest(STARTED);
    return http.post(CONTENT_ASSISTANCE_SETTINGS_URL, {
      data: {
        customerAnalysisEnabled,
        portalId: PortalIdParser.get()
      }
    }).then(() => {
      setIsCustomerAnalysisEnabled(customerAnalysisEnabled);
      setCustomerAnalysisPostRequest(FINISHED);
    }).catch(e => {
      setCustomerAnalysisPostRequest(ERROR);
      setError(e);
    });
  }, [http]);
  return {
    getRequest,
    contentAssistantPostRequest,
    customerAnalysisPostRequest,
    error,
    isContentAssistanceEnabled,
    isCustomerAnalysisEnabled,
    fetch: fetchContentAssistanceSettings,
    toggleContentAssistantSettings: postContentAssistantSettings,
    toggleCustomerAnalysisSettings: postCustomerAnalysisSettings
  };
};