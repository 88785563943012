/* hs-eslint ignored failing-rules */

'use es6';

import getLang from 'I18n/utils/getLang';
import PropTypes from 'prop-types';
import { Component } from 'react';
import enviro from 'enviro';
import PortalIdParser from 'PortalIdParser';
import userInfo from 'hub-http/userInfo';
import injectScript from './utils/injectScript';
const ENV = enviro.getShort();
const qaSuffix = ENV === 'qa' ? 'qa' : '';
const getLocalStorageItem = key => {
  try {
    return window.localStorage && window.localStorage.getItem(key);
  } catch (err) {
    console.error(err);
    return null;
  }
};
const loadLocalPopupInjector = getLocalStorageItem('LOCAL_FEEDBACK_UI') === 'true';
const PORTAL_ID_ATTR = 'data-hubspot-feedback-portal-id';
const CUSTOMER_PORTAL_ID_ATTR = 'data-hubspot-feedback-customer-portal-id';
const ENV_ATTR = 'data-hubspot-feedback-env';
const HUBSPOT_APP_ATTR = 'data-hubspot-feedback-hubspot-app';
const HUBSPOT_EMAIL_ATTR = 'data-hubspot-feedback-hubspot-email';
const HUBSPOT_USER_LANG = 'data-hubspot-feedback-user-lang';
const HUBSPOT_CLIENT_LOAD_ONLY = 'data-hubspot-feedback-client-load-only';
const SELENIUM_DISABLE_FEEDBACK_KEY = 'selenium.disable.feedback-surveys';
const hsq = window._hsq = window._hsq || [];
hsq.push(['setCookiePolicy', [{
  enabled: true,
  privacyPolicy: 2,
  privacyDefault: true
}]]);
const addOnClientReadyCallback = onReady => {
  window.onHsFeedbackReady = window.onHsFeedbackReady || [];
  window.onHsFeedbackReady.push(onReady);
};
const inject = (portalId, customerPortalId, userInfoClient, onClientLoad, clientLoadOnly = true, loadAnalyticsScript = true) => {
  const ANALYTICS_SRC = `//js.hubspot${qaSuffix}.com/analytics/0/common.js`;
  const FEEDBACK_INJECTOR_SRC = loadLocalPopupInjector ? `//local.hsappstatic.net/feedback-web-renderer-ui/static/bundles/popupInjector.js` : `//js.hubspotfeedback${qaSuffix}.com/feedbackweb-new.js`;
  if (loadAnalyticsScript) {
    injectScript(ANALYTICS_SRC);
  }
  userInfoClient().then(({
    user,
    hubspotter
  }) => {
    const {
      email
    } = user || hubspotter;
    hsq.push(['identify', {
      email
    }]);
    injectScript(FEEDBACK_INJECTOR_SRC, {
      [PORTAL_ID_ATTR]: portalId,
      [CUSTOMER_PORTAL_ID_ATTR]: customerPortalId,
      [ENV_ATTR]: ENV,
      [HUBSPOT_APP_ATTR]: true,
      [HUBSPOT_EMAIL_ATTR]: email,
      [HUBSPOT_USER_LANG]: getLang(),
      [HUBSPOT_CLIENT_LOAD_ONLY]: clientLoadOnly
    });
    if (onClientLoad) {
      addOnClientReadyCallback(onClientLoad);
    }
  }).catch(console.error);
};
export default class FeedbackLoader extends Component {
  constructor(props) {
    super(props);
    const {
      customerPortalId,
      delayMs,
      onClientLoad,
      portalId,
      userInfoClient,
      clientLoadOnly,
      loadAnalyticsScript
    } = props;
    if (window.hsFeedbackLoaded || window.disabledHsPopups && window.disabledHsPopups.indexOf('FEEDBACK') > -1 || getLocalStorageItem(SELENIUM_DISABLE_FEEDBACK_KEY) === 'true') {
      return;
    }
    hsq.push(['setPortalId', portalId]);
    setTimeout(() => {
      inject(portalId, customerPortalId, userInfoClient, onClientLoad, clientLoadOnly, loadAnalyticsScript);
    }, delayMs);
  }
  shouldComponentUpdate() {
    return false;
  }
  render() {
    return null;
  }
}
FeedbackLoader.propTypes = {
  customerPortalId: PropTypes.number,
  delayMs: PropTypes.number,
  onClientLoad: PropTypes.func,
  portalId: PropTypes.number,
  userInfoClient: PropTypes.func,
  clientLoadOnly: PropTypes.bool,
  loadAnalyticsScript: PropTypes.bool
};
FeedbackLoader.defaultProps = {
  customerPortalId: PortalIdParser.get(),
  delayMs: 0,
  portalId: ENV === 'prod' ? 53 : 100765651,
  userInfoClient: userInfo,
  clientLoadOnly: true,
  loadAnalyticsScript: true
};