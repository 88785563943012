'use es6';

import { DEAL_STAGE, OWNER, TICKET_STAGE } from 'customer-data-objects/property/ExternalOptionTypes';
import { EMAIL_CAMPAIGN_URL, SITE_CONTENT } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { List } from 'immutable';
import { __ANY_LINK, __ANY_PAGE } from 'customer-data-filters/converters/listSegClassic/ListSegConstants';
import { getAllClosedOption } from 'customer-data-objects/ticket/TicketStageIdOptions';
import { getAllClosedWonOption } from 'customer-data-objects/deal/DealStageIdOptions';
import { getMeOption } from 'customer-data-objects/owners/OwnerIdOptions';
import PropertyOptionRecord from 'customer-data-objects/property/PropertyOptionRecord';
import once from 'transmute/once';
import unescapedText from 'I18n/utils/unescapedText';
const getSpecialOptionsByReferenceType = once(() => ({
  [OWNER]: () => List.of(getMeOption()),
  [DEAL_STAGE]: () => List.of(getAllClosedWonOption()),
  [TICKET_STAGE]: () => List.of(getAllClosedOption()),
  [EMAIL_CAMPAIGN_URL]: () => List.of(PropertyOptionRecord({
    label: unescapedText('customerDataFilters.FilterEditor.specialOptionValues.anyLink'),
    value: __ANY_LINK
  })),
  [SITE_CONTENT]: () => List.of(PropertyOptionRecord({
    label: unescapedText('customerDataFilters.FilterEditor.specialOptionValues.anyPage'),
    value: __ANY_PAGE
  }))
}));
export default getSpecialOptionsByReferenceType;