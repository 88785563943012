import * as checked from 'reporting-data/lib/checked';
import { Encoding } from '../column-records';
import { VisualTypes } from '../constants/visual-constants';
export const MagicWandEncodings = checked.record({
  columns: checked.list(Encoding)
}, 'MagicWandEncodings');
export const MagicWandVisualOptions = checked.record({});
export const MagicWandVisual = checked.record({
  type: checked.string().always(VisualTypes.MAGIC_WAND),
  encodings: MagicWandEncodings,
  options: MagicWandVisualOptions.defaultValue({})
}, 'MagicWandVisual');