import * as checked from 'reporting-data/lib/checked';
import { Encoding } from '../column-records';
import { ColorOption, goalFrequency, goalLineValue, Scale, showDataLabels, showRecordIds, showMarkers, showToday, AxisScaleType, legendPosition, legendWidth } from '../highcharts-settings';
import { VisualTypes } from '../constants/visual-constants';
export const LineEncodings = checked.record({
  x: Encoding.optional(),
  y: Encoding.optional(),
  y_multi: checked.list(Encoding),
  color: Encoding.optional()
}, 'LineEncodings');
export const LineVisualOptions = checked.record({
  showDataLabels,
  showRecordIds,
  showMarkers,
  accumulate: checked.boolean().defaultValue(false),
  showToday,
  goalLineValue,
  goalFrequency,
  yMinMax: Scale.defaultValue({}),
  color: ColorOption.defaultValue({}),
  yAxisType: AxisScaleType,
  yAxisLabel: checked.string().optional(),
  legendPosition,
  legendWidth
});
export const LineVisual = checked.record({
  type: checked.string().always(VisualTypes.LINE),
  encodings: LineEncodings,
  options: LineVisualOptions.defaultValue({})
}, 'LineVisual');