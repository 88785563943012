module.exports = {
  "draftFormatting": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "method": {
        "type": "string",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  },
  "draftLink": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "noFollow": "boolean",
      "externalLink": "boolean",
      "isImage": "boolean",
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  },
  "draftImage": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "method": "string",
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  },
  "draftToken": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "method": "string",
      "tokenCategory": "string",
      "tokenName": "string",
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  },
  "draftVideo": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "method": "string",
      "watchPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "videoTitle": {
        "type": "string",
        "isOptional": true
      },
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  },
  "draftChangeColor": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "format": [
        "forecolor",
        "hilitecolor"
      ],
      "method": {
        "type": "string",
        "isOptional": true
      },
      "textLibrary": "string"
    },
    "namespace": "rich-text"
  }
};