import debounce from 'react-utils/debounce';
import { toFetchFailedError, toInvalidIdError, toObjectNotFoundError } from '../../Errors';
import chunk from '../../lib/chunk';
import flatten from '../../lib/flatten';
import indexBy from '../../lib/indexBy';
const indexIds = indexBy(String);
const makeCacheEntry = () => {
  const entry = {};
  entry.promise = new Promise((resolve, reject) => {
    entry.resolve = resolve;
    entry.reject = reject;
  });
  return entry;
};
export const createPendingResolutionCache = () => ({
  idQueue: [],
  pending: {}
});
export const resolveBatchFetchedReferences = ({
  debouncePeriod,
  fetchByIds,
  isIdValid,
  maxBatchSize,
  resolutionCache
}) => {
  const makeBatchedIdRequest = debounce(() => {
    const ids = resolutionCache.idQueue;
    resolutionCache.idQueue = [];
    const idChunks = chunk(ids, maxBatchSize);
    Promise.all(idChunks.map(idChunk => fetchByIds(idChunk))).then(flatten).then(results => {
      const idMap = indexIds(ids);
      results.forEach(reference => {
        const id = String(reference.id);
        // If `fetchByIds` returns an result whose `id` is not in the list we requested,
        // ignore the result rather than throwing an error.
        //
        // For example: the ExternalOptions teams resolver will sometimes return extra
        // nested teams whose ids aren't in the list we fetched. These extra results
        // should probably be filtered out by the API, but when they are not, we should
        // ignore them on the FE rather than erroring.
        if (Object.prototype.hasOwnProperty.call(resolutionCache.pending, id)) {
          resolutionCache.pending[id].resolve(reference);
        }
        delete resolutionCache.pending[id];
        delete idMap[id];
      });
      const missing = Object.keys(idMap);
      missing.forEach(id => {
        resolutionCache.pending[id].reject(toObjectNotFoundError(id));
        delete resolutionCache.pending[id];
      });
    }).catch(error => {
      ids.forEach(id => {
        const idStr = String(id);
        resolutionCache.pending[idStr].reject(toFetchFailedError(error));
        delete resolutionCache.pending[idStr];
      });
    });
  }, debouncePeriod);
  return id => {
    if (!isIdValid(id)) {
      return Promise.reject(toInvalidIdError(id));
    }
    const idStr = String(id);
    if (Object.prototype.hasOwnProperty.call(resolutionCache.pending, id)) {
      return resolutionCache.pending[idStr].promise;
    }
    const entry = makeCacheEntry();
    resolutionCache.pending[idStr] = entry;
    resolutionCache.idQueue.push(id);
    makeBatchedIdRequest();

    /*
     * HACK: See https://issues.hubspotcentral.com/browse/CRM-47860
     *
     * This line was previously:
     * return resolutionCache.pending[idStr].promise;
     *
     * It seems that it is possible for the pending cache entry to be wiped out
     * before we return it. Based on my understanding of the event loop it should not
     * be possible (the delete calls are behind a debounce *and* a promise), but it
     * is the only explanation I can see for the user's issue (assuming the sentries
     * here are from this user: https://sentry.hubteam.com/sentry/crm-records-ui/?query=is%3Aunresolved+portalId%3A21084925
     *
     * To get around this issue, we now store the cache entry in a variable and return
     * the pending promise from that (rather than looking the entry up from the cache
     * in the return). This is technically a behavior change, but should be equivalent
     * in every case except the one where the user has issues.
     *
     * One potential "more correct" fix is to refactor the cache implementation to store
     * each request as a promise, and never wipe out the cache. You can resolve
     * promises multiple times, so each query for a given id would just return
     * the cached promise if found and create it if not.
     *
     * That will be a large refactor though, so I'm taking this as a first step.
     */
    return entry.promise;
  };
};