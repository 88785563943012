'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["breakdowns", "totals"];
import { fromJS } from 'immutable';
import { getFilterByProperty } from '../../../config/filters/functions';
const replyRateScore = breakdown => {
  if (breakdown.repliesPerCompletionsAndUnenrolls > 0.13) {
    return 3;
  } else if (breakdown.repliesPerCompletionsAndUnenrolls > 0.07) {
    return 2;
  } else {
    // Also catches when the metric is not present
    return 1;
  }
};
const bounceRateScore = breakdown => {
  if (breakdown.bouncesPerCompletionsAndUnenrolls > 0.07) {
    return 1;
  } else if (breakdown.bouncesPerCompletionsAndUnenrolls > 0.03) {
    return 2;
  } else {
    // Also catches when the metric is not present
    return 3;
  }
};
export const getSenderScoreEnum = (breakdown, isDrilldown) => {
  const score = replyRateScore(breakdown) + bounceRateScore(breakdown);
  // 100 enrollments are not required for scoring when drilled into sequence breakdowns
  if (breakdown.completionsAndUnenrolls > 100 || isDrilldown) {
    return {
      [2]: 'LOW',
      [3]: 'LOW',
      [4]: 'FAIR',
      [5]: 'GOOD',
      [6]: 'EXCELLENT'
    }[score] || 'UNKNOWN';
  }
  return 'UNKNOWN';
};
const addSenderScore = (breakdown, {
  isDrilldown
}) => Object.assign({}, breakdown, {
  senderScore: getSenderScoreEnum(breakdown, isDrilldown)
});
export const preprocess = (__spec, config) => _ref => {
  let {
      breakdowns = [],
      totals = {}
    } = _ref,
    rest = _objectWithoutPropertiesLoose(_ref, _excluded);
  const isDrilldown = !!getFilterByProperty(fromJS(config), 'd1');
  return Object.assign({}, rest, {
    breakdowns: breakdowns.map(breakdown => addSenderScore(breakdown, {
      isDrilldown
    })),
    totals: addSenderScore(totals || {}, {
      isDrilldown
    })
  });
};