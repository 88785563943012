import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["pickerType", "disableUpload"];
import { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Raven from 'raven-js';
import { DrawerTypes, FileExtensionFilterTypes, PickerPanels, PickerTypes } from '../constants';
import FileManagerContext from '../internal/FileManagerContext';
import { logNewRelicError } from '../internal/utils';
const FilePickerPanel = _ref => {
  let {
      pickerType = PickerTypes.PANEL,
      disableUpload = false
    } = _ref,
    props = _objectWithoutPropertiesLoose(_ref, _excluded);
  const {
    openPicker,
    closePicker
  } = useContext(FileManagerContext);
  useEffect(() => {
    if (!props.type) {
      const err = new Error('[file-manager] `type` prop is required');
      logNewRelicError(err);
      return;
    } else if (!Object.values(DrawerTypes).includes(props.type)) {
      const err = new Error('[file-manager] `type` prop is not valid');
      logNewRelicError(err);
      return;
    } else if (!openPicker) {
      const err = new Error('[file-manager] FilePickerPanel must be wrapped in `file-manager-components/configureFileManager` HOC');
      logNewRelicError(err);
      Raven.captureException(err);
      if (props.onClose) {
        props.onClose();
      }
      return;
    }
    openPicker(Object.assign({}, props, {
      pickerType,
      disableUpload
    }));

    // eslint-disable-next-line consistent-return
    return () => {
      if (closePicker) {
        closePicker();
      }
    };

    // intentionally only support changing props below while mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, pickerType, props.filterType, props.filteredExtensions]);
  return null;
};
FilePickerPanel.displayName = 'FilePickerPanel';

// unsupported props from FileManagerLib version intentionally omitted
FilePickerPanel.propTypes = {
  type: PropTypes.oneOf(Object.keys(DrawerTypes)).isRequired,
  pickerType: PropTypes.oneOf(Object.keys(PickerTypes)).isRequired,
  disableUpload: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  filterType: PropTypes.oneOf(Object.keys(FileExtensionFilterTypes)),
  filteredExtensions: PropTypes.arrayOf(PropTypes.string),
  filteredReasons: PropTypes.object,
  renderContext: PropTypes.string,
  initialPanel: PropTypes.oneOf(Object.keys(PickerPanels))
};

// not defaulting `type` to `IMAGE` (like FileManagerLib) to push consumers to be explicit
FilePickerPanel.defaultProps = {
  pickerType: PickerTypes.PANEL,
  disableUpload: false
};
export default FilePickerPanel;