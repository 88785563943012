'use es6';

import * as Operators from '../../../filterQueryFormat/operator/Operators';
import { COALESCING_REFINE_BY, COALESCING_REFINE_BY_NUM_TYPE, COALESCING_REFINE_BY_SET_TYPE, COALESCING_REFINE_BY_TYPE, PRUNING_REFINE_BY, PRUNING_REFINE_BY_TYPE, TIME_COMPARISON, TIME_DIRECTION, TIME_UNIT } from '../constants/constants';
import { NONE, PORTAL_TZ_ALIGNED } from '../../../filterQueryFormat/timestamps/TimestampTypes';
import { convertSimpleDateToTimestamp } from '../../../filterQueryFormat/timestamps/TimestampConverters';
import getIn from 'transmute/getIn';
import protocol from 'transmute/protocol';
import { FORWARD } from '../../../filterQueryFormat/rollingDates/RollingDateDirections';
const TIME_UNIT_MAPPING = {
  days: TIME_UNIT.DAYS,
  weeks: TIME_UNIT.WEEKS
};
const convertSimpleDate = (date, timestampType, isEndOfDayAligned) => {
  if (timestampType === NONE) {
    return convertSimpleDateToTimestamp(date, PORTAL_TZ_ALIGNED, isEndOfDayAligned);
  }
  return convertSimpleDateToTimestamp(date, timestampType, isEndOfDayAligned);
};
const toObjectSegTimeUnit = timeUnit => TIME_UNIT_MAPPING[timeUnit];
const _toAbsoluteComparativeTimestampRefineBy = (comparison, refinementOperator, isEndOfDayAligned) => {
  const timestampType = refinementOperator.getIn(['field', 'timestampType']);
  const timestamp = convertSimpleDate(refinementOperator.get('value'), timestampType, isEndOfDayAligned);
  return {
    [PRUNING_REFINE_BY]: {
      comparison,
      timestamp,
      type: PRUNING_REFINE_BY_TYPE.ABSOLUTE
    },
    [COALESCING_REFINE_BY]: {
      type: COALESCING_REFINE_BY_TYPE.SET_OCCURRENCES,
      setType: COALESCING_REFINE_BY_SET_TYPE.ANY
    }
  };
};
const _toAbsoluteRangedTimestampRefineBy = (rangeType, refinementOperator) => {
  const timestampType = refinementOperator.getIn(['field', 'timestampType']);
  const lowerTimestamp = convertSimpleDate(refinementOperator.get('value'), timestampType, false);
  const upperTimestamp = convertSimpleDate(refinementOperator.get('highValue'), timestampType, true);
  return {
    [PRUNING_REFINE_BY]: {
      rangeType,
      lowerTimestamp,
      upperTimestamp,
      type: PRUNING_REFINE_BY_TYPE.ABSOLUTE_RANGE
    },
    [COALESCING_REFINE_BY]: {
      type: COALESCING_REFINE_BY_TYPE.SET_OCCURRENCES,
      setType: COALESCING_REFINE_BY_SET_TYPE.ANY
    }
  };
};
const _toTimeOffset = (refinementOperator, direction) => {
  let timeUnit = refinementOperator.get('timeUnit');
  let value = refinementOperator.get('value');
  if (refinementOperator.has('numberOfDays')) {
    timeUnit = 'days';
    value = refinementOperator.get('numberOfDays');
  }
  return {
    offsetDirection: direction,
    timeUnit: toObjectSegTimeUnit(timeUnit),
    amount: value
  };
};
const _toRelativeComparativeTimestampRefineBy = refinementOperator => {
  const operatorDirection = refinementOperator.get('direction');
  let comparison = '';
  let direction = '';
  if (operatorDirection === 'backward') {
    comparison = TIME_COMPARISON.BEFORE;
    direction = TIME_DIRECTION.PAST;
  }
  if (operatorDirection === 'forward') {
    comparison = TIME_COMPARISON.AFTER;
    direction = TIME_DIRECTION.FUTURE;
  }
  return {
    [PRUNING_REFINE_BY]: {
      comparison,
      timeOffset: _toTimeOffset(refinementOperator, direction),
      type: PRUNING_REFINE_BY_TYPE.RELATIVE
    },
    [COALESCING_REFINE_BY]: {
      type: COALESCING_REFINE_BY_TYPE.SET_OCCURRENCES,
      setType: COALESCING_REFINE_BY_SET_TYPE.ANY
    }
  };
};
const _toRelativeRangedTimestampRefineBy = refinementOperator => {
  const direction = refinementOperator.get('direction');
  const amount = refinementOperator.get('value');
  const timeUnit = toObjectSegTimeUnit(refinementOperator.get('timeUnit'));
  const refineBys = {
    [PRUNING_REFINE_BY]: {
      rangeType: TIME_COMPARISON.BETWEEN,
      type: PRUNING_REFINE_BY_TYPE.RELATIVE_RANGE
    },
    [COALESCING_REFINE_BY]: {
      type: COALESCING_REFINE_BY_TYPE.SET_OCCURRENCES,
      setType: COALESCING_REFINE_BY_SET_TYPE.ANY
    }
  };
  if (direction === FORWARD) {
    refineBys[PRUNING_REFINE_BY].lowerBoundOffset = {
      offsetDirection: TIME_DIRECTION.FUTURE,
      timeUnit,
      amount: 0
    };
    refineBys[PRUNING_REFINE_BY].upperBoundOffset = {
      offsetDirection: TIME_DIRECTION.FUTURE,
      timeUnit,
      amount
    };
  } else {
    refineBys[PRUNING_REFINE_BY].lowerBoundOffset = {
      offsetDirection: TIME_DIRECTION.PAST,
      timeUnit,
      amount
    };
    refineBys[PRUNING_REFINE_BY].upperBoundOffset = {
      offsetDirection: TIME_DIRECTION.PAST,
      timeUnit,
      amount: 0
    };
  }
  return refineBys;
};
const _toNumCoalescing = (type, refinementOperator) => {
  const value = refinementOperator.get('value');
  const values = {};
  if (type === COALESCING_REFINE_BY_NUM_TYPE.MORE || type === COALESCING_REFINE_BY_NUM_TYPE.EQUAL) {
    values.minOccurrences = value;
  }
  if (type === COALESCING_REFINE_BY_NUM_TYPE.LESS || type === COALESCING_REFINE_BY_NUM_TYPE.EQUAL) {
    values.maxOccurrences = value;
  }
  return {
    [COALESCING_REFINE_BY]: Object.assign({
      type: COALESCING_REFINE_BY_TYPE.NUM_OCCURRENCES
    }, values)
  };
};
const toDateRefinementFilter = protocol({
  name: 'toDateRefinementFilter',
  args: [protocol.TYPE],
  fallback: () => ({})
});
toDateRefinementFilter.implement(Operators.Less, refinementOperator => _toAbsoluteComparativeTimestampRefineBy(TIME_COMPARISON.BEFORE, refinementOperator));
toDateRefinementFilter.implement(Operators.LessOrEqual, refinementOperator => _toAbsoluteComparativeTimestampRefineBy(TIME_COMPARISON.BEFORE, refinementOperator, true));
toDateRefinementFilter.implement(Operators.Greater, refinementOperator => _toAbsoluteComparativeTimestampRefineBy(TIME_COMPARISON.AFTER, refinementOperator, true));
toDateRefinementFilter.implement(Operators.GreaterOrEqual, refinementOperator => _toAbsoluteComparativeTimestampRefineBy(TIME_COMPARISON.AFTER, refinementOperator));
toDateRefinementFilter.implement(Operators.InRange, refinementOperator => _toAbsoluteRangedTimestampRefineBy(TIME_COMPARISON.BETWEEN, refinementOperator));
toDateRefinementFilter.implement(Operators.NotInRange, refinementOperator => _toAbsoluteRangedTimestampRefineBy(TIME_COMPARISON.NOT_BETWEEN, refinementOperator));
toDateRefinementFilter.implement(Operators.LessThanRolling, refinementOperator => _toRelativeRangedTimestampRefineBy(refinementOperator));
toDateRefinementFilter.implement(Operators.GreaterThanRolling, refinementOperator => _toRelativeComparativeTimestampRefineBy(refinementOperator));
toDateRefinementFilter.implement(Operators.LessRolling, refinementOperator => _toRelativeComparativeTimestampRefineBy(refinementOperator));
toDateRefinementFilter.implement(Operators.GreaterRolling, refinementOperator => _toRelativeComparativeTimestampRefineBy(refinementOperator));
const toNumberRefinementFilter = protocol({
  name: 'toNumberRefinementFilter',
  args: [protocol.TYPE],
  fallback: () => ({})
});
toNumberRefinementFilter.implement(Operators.Less, refinementOperator => _toNumCoalescing(COALESCING_REFINE_BY_NUM_TYPE.LESS, refinementOperator));
toNumberRefinementFilter.implement(Operators.Equal, refinementOperator => _toNumCoalescing(COALESCING_REFINE_BY_NUM_TYPE.EQUAL, refinementOperator));
toNumberRefinementFilter.implement(Operators.Greater, refinementOperator => _toNumCoalescing(COALESCING_REFINE_BY_NUM_TYPE.MORE, refinementOperator));
const toRefinementFilter = operator => {
  const type = getIn(['field', 'type'], operator);
  if (type === 'date') {
    return toDateRefinementFilter(operator);
  }
  if (type === 'number') {
    return toNumberRefinementFilter(operator);
  }
  return {};
};
export default toRefinementFilter;