import { List, Map as ImmutableMap } from 'immutable';
import { RANGE_TYPES as REPORTING_RANGE_TYPES, STANDARD_TO_FISCAL_YEAR_DATE_RANGE } from 'reporting-data/constants/dateRangeTypes';

// The following range types are allowed in reporting but not Sales Analytics:
// IS_EQUAL_TO
// IS_BEFORE_DATE
// IS_AFTER_DATE
export const RANGE_TYPE = {
  [REPORTING_RANGE_TYPES.ALL]: REPORTING_RANGE_TYPES.ALL,
  [REPORTING_RANGE_TYPES.THIS_DAY]: REPORTING_RANGE_TYPES.THIS_DAY,
  [REPORTING_RANGE_TYPES.LAST_DAY]: REPORTING_RANGE_TYPES.LAST_DAY,
  [REPORTING_RANGE_TYPES.THIS_WEEK]: REPORTING_RANGE_TYPES.THIS_WEEK,
  THIS_ENTIRE_WEEK: 'THIS_ENTIRE_WEEK',
  [REPORTING_RANGE_TYPES.LAST_WEEK]: REPORTING_RANGE_TYPES.LAST_WEEK,
  [REPORTING_RANGE_TYPES.NEXT_WEEK]: REPORTING_RANGE_TYPES.NEXT_WEEK,
  [REPORTING_RANGE_TYPES.THIS_MONTH]: REPORTING_RANGE_TYPES.THIS_MONTH,
  THIS_ENTIRE_MONTH: 'THIS_ENTIRE_MONTH',
  [REPORTING_RANGE_TYPES.LAST_MONTH]: REPORTING_RANGE_TYPES.LAST_MONTH,
  [REPORTING_RANGE_TYPES.NEXT_MONTH]: REPORTING_RANGE_TYPES.NEXT_MONTH,
  [REPORTING_RANGE_TYPES.CUSTOM_MONTH]: REPORTING_RANGE_TYPES.CUSTOM_MONTH,
  [REPORTING_RANGE_TYPES.THIS_QUARTER]: REPORTING_RANGE_TYPES.THIS_QUARTER,
  THIS_ENTIRE_QUARTER: 'THIS_ENTIRE_QUARTER',
  [REPORTING_RANGE_TYPES.LAST_QUARTER]: REPORTING_RANGE_TYPES.LAST_QUARTER,
  [REPORTING_RANGE_TYPES.NEXT_QUARTER]: REPORTING_RANGE_TYPES.NEXT_QUARTER,
  [REPORTING_RANGE_TYPES.CUSTOM_QUARTER]: REPORTING_RANGE_TYPES.CUSTOM_QUARTER,
  [REPORTING_RANGE_TYPES.THIS_FISCAL_QUARTER]: REPORTING_RANGE_TYPES.THIS_FISCAL_QUARTER,
  THIS_ENTIRE_FISCAL_QUARTER: 'THIS_ENTIRE_FISCAL_QUARTER',
  [REPORTING_RANGE_TYPES.LAST_FISCAL_QUARTER]: REPORTING_RANGE_TYPES.LAST_FISCAL_QUARTER,
  [REPORTING_RANGE_TYPES.NEXT_FISCAL_QUARTER]: REPORTING_RANGE_TYPES.NEXT_FISCAL_QUARTER,
  [REPORTING_RANGE_TYPES.THIS_YEAR]: REPORTING_RANGE_TYPES.THIS_YEAR,
  THIS_ENTIRE_YEAR: 'THIS_ENTIRE_YEAR',
  [REPORTING_RANGE_TYPES.LAST_YEAR]: REPORTING_RANGE_TYPES.LAST_YEAR,
  [REPORTING_RANGE_TYPES.NEXT_YEAR]: REPORTING_RANGE_TYPES.NEXT_YEAR,
  [REPORTING_RANGE_TYPES.CUSTOM_YEAR]: REPORTING_RANGE_TYPES.CUSTOM_YEAR,
  [REPORTING_RANGE_TYPES.THIS_FISCAL_YEAR]: REPORTING_RANGE_TYPES.THIS_FISCAL_YEAR,
  THIS_ENTIRE_FISCAL_YEAR: 'THIS_ENTIRE_FISCAL_YEAR',
  [REPORTING_RANGE_TYPES.LAST_FISCAL_YEAR]: REPORTING_RANGE_TYPES.LAST_FISCAL_YEAR,
  [REPORTING_RANGE_TYPES.NEXT_FISCAL_YEAR]: REPORTING_RANGE_TYPES.NEXT_FISCAL_YEAR,
  [REPORTING_RANGE_TYPES.ROLLING]: REPORTING_RANGE_TYPES.ROLLING,
  [REPORTING_RANGE_TYPES.CUSTOM]: REPORTING_RANGE_TYPES.CUSTOM
};
export const PARTIAL_TO_ENTIRE = ImmutableMap({
  [RANGE_TYPE.THIS_WEEK]: RANGE_TYPE.THIS_ENTIRE_WEEK,
  [RANGE_TYPE.THIS_MONTH]: RANGE_TYPE.THIS_ENTIRE_MONTH,
  [RANGE_TYPE.THIS_QUARTER]: RANGE_TYPE.THIS_ENTIRE_QUARTER,
  [RANGE_TYPE.THIS_FISCAL_QUARTER]: RANGE_TYPE.THIS_ENTIRE_FISCAL_QUARTER,
  [RANGE_TYPE.THIS_YEAR]: RANGE_TYPE.THIS_ENTIRE_YEAR,
  [RANGE_TYPE.THIS_FISCAL_YEAR]: RANGE_TYPE.THIS_ENTIRE_FISCAL_YEAR
});
export const ENTIRE_TO_PARTIAL = PARTIAL_TO_ENTIRE.flip();
export const RANGE_TYPE_LIST = List(Object.keys(RANGE_TYPE));
// convert (reporting-data) DateRange to RANGE_TYPE
export const getRangeType = ({
  rangeType,
  entireCurrentUnit,
  useFiscalYear
}) => {
  const maybeFiscalRange = useFiscalYear ? STANDARD_TO_FISCAL_YEAR_DATE_RANGE[rangeType] : rangeType;
  if (entireCurrentUnit && PARTIAL_TO_ENTIRE.has(maybeFiscalRange)) {
    return PARTIAL_TO_ENTIRE.get(maybeFiscalRange);
  } else {
    return maybeFiscalRange;
  }
};