'use es6';

import { List } from 'immutable';
import getInboundDbPropertyGroups from '../../retrieve/inboundDb/common/properties';
import { FEEDBACK_SUBMISSIONS } from '../../constants/dataTypes';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import countProperty from '../partial/count-property';
import { mergeProperties } from '../mergeProperties';
import prefix from '../../lib/prefix';
import { ENUMERATION } from '../../constants/property-types';
const translateCommon = prefix('reporting-data.properties.common');
export const getPropertyGroups = () => getInboundDbPropertyGroups(FEEDBACK_SUBMISSIONS).then(groups => List([...groups])).then(groups => mergeProperties(groups, 'feedbacksubmissioninformation', {
  hs_object_id: {
    name: 'hs_object_id',
    type: ENUMERATION,
    label: translateCommon('hs_object_id'),
    hidden: true
  }
}));
export const getProperties = () => createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(FEEDBACK_SUBMISSIONS)))();