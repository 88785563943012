'use es6';

import * as Frequency from '../../constants/frequency';
const build = (period, interval = period) => ({
  period,
  interval
});
export const get = (unit, isoweek) => {
  switch (unit) {
    case Frequency.DAY:
      return build('day');
    case Frequency.WEEK:
      return isoweek ? build('isoWeek', 'week') : build('week');
    case Frequency.MONTH:
      return build('month');
    case Frequency.QUARTER:
      return build('quarter');
    case Frequency.YEAR:
      return build('year');
    default:
      return build(null);
  }
};