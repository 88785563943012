import http from 'hub-http/clients/apiClient';
import { fromJS, Seq } from 'immutable';
import { useEffect, useState } from 'react';
import * as checked from 'reporting-data/lib/checked';
import { ExpressionType, ExpressionTypes } from './expression-records';
import { BOOLEAN_TYPE, createAggregationFunctionType, createFunctionType, createGenericType, createStringType, createUnionType, DATETIME_TYPE, DATE_TYPE, FunctionType, NULLABLE_BOOLEAN_TYPE, NULLABLE_DATETIME_TYPE, NULLABLE_DATE_TYPE, NULLABLE_NUMBER_TYPE, NULLABLE_STRING_TYPE, NULL_TYPE, NUMBER_TYPE, PROPERTY_TYPES, Scope, STRING_TYPE, Value } from './type-records';
export const SupportedType = checked.record({
  expressionType: ExpressionType,
  validInputs: checked.list(checked.string())
}, 'SupportedType');
export const ArgumentMeta = checked.record({
  type: checked.string(),
  // TODO basictype
  supportedTypes: checked.list(SupportedType),
  validInputs: checked.list(checked.any())
}, 'FunctionArgument');
export const FunctionMeta = checked.record({
  function: ExpressionType,
  returnType: checked.string(),
  // TODO basicType
  arguments: checked.list(ArgumentMeta)
}, 'FunctionMeta');
export const InlineFunctionDocumentation = checked.record({
  arguments: checked.list(ArgumentMeta),
  description: checked.string(),
  functionName: checked.string(),
  returnType: checked.string()
}, 'InlineFunctionDocumentation');

// @ts-expect-error add type
let functionMetaCache;

// @ts-expect-error need type for param
const supportedTypeToString = supportedType => {
  if (supportedType.expressionType === ExpressionTypes.IDENTIFIER) {
    return supportedType.validInputs.join('|');
  }
  return supportedType.expressionType;
};

// @ts-expect-error need type for param
export const argumentsToString = functionMeta => functionMeta.arguments
// @ts-expect-error need type for param
.map(argument => {
  if (argument.type === 'enumeration') {
    return argument.supportedTypes.map(supportedTypeToString).join('|');
  }
  return argument.type;
}).join(', ');
export const fetchFunctions = () => {
  // @ts-expect-error add type above
  if (functionMetaCache) {
    return Promise.resolve(functionMetaCache);
  }
  return http.get('/sql-reporting/v1/datasets/functions').then(fromJS).then(functionMeta => {
    // @ts-expect-error add type for param
    const result = functionMeta.map(f => FunctionMeta(f));
    functionMetaCache = result;
    return result;
  }).catch(e => {
    throw e;
  });
};
export const useFunctionMeta = () => {
  const [requestState, setRequestState] = useState({
    loading: true
  });
  // @ts-expect-error add type when initializing
  const {
    loading,
    data,
    error
  } = requestState;

  // @ts-expect-error update arg
  useEffect(() => {
    let expired = false;
    if (loading) {
      fetchFunctions().then(functionMeta => {
        if (!expired) {
          // @ts-expect-error add type when initializing
          setRequestState({
            loading: false,
            data: functionMeta
          });
        }
      }).catch(e => {
        if (!expired) {
          // @ts-expect-error add type when initializing
          setRequestState({
            loading: false,
            error: e
          });
        }
      });
    }
    return () => expired = true;
  }, [loading]);
  return {
    loading,
    data,
    error
  };
};
export const ArgumentDefinition = checked.record({
  name: checked.string(),
  descriptionKey: checked.string(),
  examples: checked.list(checked.any()) // types are string or number
}, 'ArgumentDefinition');
export const FunctionDefinition = checked.record({
  name: checked.string(),
  type: FunctionType,
  signatureIndexForDocs: checked.number(),
  showBetaTag: checked.boolean().defaultValue(false),
  descriptionKey: checked.string(),
  examples: checked.list(checked.string()),
  arguments: checked.list(ArgumentDefinition)
}, 'FunctionDefinition');
export const LOGICAL_UNARY_OPERATION = createFunctionType({
  arguments: [BOOLEAN_TYPE],
  returns: BOOLEAN_TYPE
}, {
  arguments: [NULL_TYPE],
  returns: NULL_TYPE
}, {
  arguments: [NULLABLE_BOOLEAN_TYPE],
  returns: NULLABLE_BOOLEAN_TYPE
});
export const LOGICAL_BINARY_OPERATION = createFunctionType({
  arguments: [BOOLEAN_TYPE, BOOLEAN_TYPE],
  returns: BOOLEAN_TYPE
}, {
  arguments: [NULL_TYPE, NULLABLE_BOOLEAN_TYPE],
  returns: NULL_TYPE
}, {
  arguments: [NULLABLE_BOOLEAN_TYPE, NULL_TYPE],
  returns: NULL_TYPE
}, {
  arguments: [NULLABLE_BOOLEAN_TYPE, NULLABLE_BOOLEAN_TYPE],
  returns: NULLABLE_BOOLEAN_TYPE
});
export const NUMERIC_UNARY_OPERATION = createFunctionType({
  arguments: [NUMBER_TYPE],
  returns: NUMBER_TYPE
}, {
  arguments: [NULL_TYPE],
  returns: NULL_TYPE
}, {
  arguments: [NULLABLE_NUMBER_TYPE],
  returns: NULLABLE_NUMBER_TYPE
});
export const NUMERIC_BINARY_OPERATION = createFunctionType({
  arguments: [NUMBER_TYPE, NUMBER_TYPE],
  returns: NUMBER_TYPE
}, {
  arguments: [NULLABLE_NUMBER_TYPE, NULL_TYPE],
  returns: NULL_TYPE
}, {
  arguments: [NULL_TYPE, NULLABLE_NUMBER_TYPE],
  returns: NULL_TYPE
}, {
  arguments: [NULLABLE_NUMBER_TYPE, NULLABLE_NUMBER_TYPE],
  returns: NULLABLE_NUMBER_TYPE
});
export const COMPARISON_OPERATION = createFunctionType({
  arguments: [createUnionType(NUMBER_TYPE, DATE_TYPE, DATETIME_TYPE), createUnionType(NUMBER_TYPE, DATE_TYPE, DATETIME_TYPE)],
  returns: BOOLEAN_TYPE
}, {
  arguments: [createUnionType(NUMBER_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE), NULL_TYPE],
  returns: NULL_TYPE
}, {
  arguments: [NULL_TYPE, createUnionType(NUMBER_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE)],
  returns: NULL_TYPE
}, {
  arguments: [createUnionType(NUMBER_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE), createUnionType(NUMBER_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE)],
  returns: NULLABLE_BOOLEAN_TYPE
});
export const EQUALITY_OPERATION = createFunctionType({
  arguments: [createGenericType('T'), createGenericType('T')],
  returns: BOOLEAN_TYPE
});
export const OPERATOR_TYPES = {
  // Logical unary operators
  [ExpressionTypes.NOT]: LOGICAL_UNARY_OPERATION,
  // Logical binary operators
  [ExpressionTypes.AND]: LOGICAL_BINARY_OPERATION,
  [ExpressionTypes.OR]: LOGICAL_BINARY_OPERATION,
  // Numeric unary operators
  [ExpressionTypes.POSITIVE]: NUMERIC_UNARY_OPERATION,
  [ExpressionTypes.NEGATIVE]: NUMERIC_UNARY_OPERATION,
  // Binary numeric operators
  [ExpressionTypes.ADD]: NUMERIC_BINARY_OPERATION,
  [ExpressionTypes.SUBTRACT]: NUMERIC_BINARY_OPERATION,
  [ExpressionTypes.MULTIPLY]: NUMERIC_BINARY_OPERATION,
  [ExpressionTypes.DIVIDE]: NUMERIC_BINARY_OPERATION,
  [ExpressionTypes.MODULUS]: NUMERIC_BINARY_OPERATION,
  // Comparison operators
  [ExpressionTypes.GREATER]: COMPARISON_OPERATION,
  [ExpressionTypes.GREATER_OR_EQUAL]: COMPARISON_OPERATION,
  [ExpressionTypes.LESS]: COMPARISON_OPERATION,
  [ExpressionTypes.LESS_OR_EQUAL]: COMPARISON_OPERATION,
  // Equality operators
  [ExpressionTypes.EQUAL]: EQUALITY_OPERATION,
  [ExpressionTypes.NOT_EQUAL]: EQUALITY_OPERATION
};
export const FUNCTION_DEFINITIONS = {
  IF: FunctionDefinition({
    name: 'IF',
    type: createFunctionType({
      arguments: [NULLABLE_BOOLEAN_TYPE, createGenericType('T'), createGenericType('T')],
      returns: createGenericType('T')
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.IF.description',
    examples: ['IF (DATEDIFF("DAY", NOW(), [DEAL.notes_next_activity_date]) < 0, 0, DATEDIFF("DAY", NOW(), [DEAL.notes_next_activity_date]))', 'IF ([DEAL.amount] <= 2000, "Low profit", IF([DEAL.amount] > 2000 AND [DEAL.amount] < 5000, "Medium profit", "High profit"))'],
    arguments: [{
      name: 'condition',
      descriptionKey: 'reporting-snowflake.function-definitions.IF.arg1.description',
      examples: ['DATEDIFF("DAY", NOW(), [DEAL.notes_next_activity_date]) < 0', '[DEAL.amount] <= 2000']
    }, {
      name: 'value1',
      descriptionKey: 'reporting-snowflake.function-definitions.IF.arg2.description',
      examples: [0, '"Low profit"']
    }, {
      name: 'value2',
      descriptionKey: 'reporting-snowflake.function-definitions.IF.arg3.description',
      examples: ['DATEDIFF("DAY", NOW(), [DEAL.notes_next_activity_date])', 'IF([DEAL.amount] > 2000 AND [DEAL.amount] < 5000, "Medium profit", "High profit")']
    }]
  }),
  CONTAINS: FunctionDefinition({
    name: 'CONTAINS',
    type: createFunctionType({
      arguments: [STRING_TYPE, STRING_TYPE],
      returns: BOOLEAN_TYPE
    }, {
      arguments: [NULLABLE_STRING_TYPE, NULLABLE_STRING_TYPE],
      returns: NULLABLE_BOOLEAN_TYPE
    }),
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.CONTAINS.description',
    examples: ['CONTAINS("HubSpot", "Hub") = true', 'CONTAINS("foo", "bar") = false', 'CONTAINS([CONTACT.firstname], "Mike")'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.CONTAINS.arg1.description',
      examples: ['"HubSpot"', 'string property such as [CONTACT.firstname]', 'string field']
    }, {
      name: 'substring',
      descriptionKey: 'reporting-snowflake.function-definitions.CONTAINS.arg2.description',
      examples: ['"Hub"', 'string property', 'string field']
    }]
  }),
  CONCAT: FunctionDefinition({
    name: 'CONCAT',
    type: createFunctionType({
      arguments: [createUnionType(STRING_TYPE, NUMBER_TYPE), createUnionType(STRING_TYPE, NUMBER_TYPE)],
      returns: STRING_TYPE
    }, {
      arguments: [NULL_TYPE, createUnionType(NULLABLE_STRING_TYPE, NULLABLE_NUMBER_TYPE)],
      returns: NULL_TYPE
    }, {
      arguments: [createUnionType(NULLABLE_STRING_TYPE, NULLABLE_NUMBER_TYPE), NULL_TYPE],
      returns: NULL_TYPE
    }, {
      arguments: [createUnionType(NULLABLE_STRING_TYPE, NULLABLE_NUMBER_TYPE), createUnionType(NULLABLE_STRING_TYPE, NULLABLE_NUMBER_TYPE)],
      returns: NULLABLE_STRING_TYPE
    }),
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.CONCAT.description',
    examples: ['CONCAT("Hub", "Spot") = "HubSpot"', 'CONCAT([CONTACT.firstname], CONCAT(" ", [CONTACT.lastname]))'],
    arguments: [{
      name: 'string1',
      descriptionKey: 'reporting-snowflake.function-definitions.CONCAT.arg1.description',
      examples: ['"Hub"', 'string property such as [CONTACT.firstname]', 'string field']
    }, {
      name: 'string2',
      descriptionKey: 'reporting-snowflake.function-definitions.CONCAT.arg2.description',
      examples: ['"Spot"', 'string property such as [CONTACT.lastname]', 'string field']
    }]
  }),
  LABEL: FunctionDefinition({
    name: 'LABEL',
    type: createFunctionType({
      arguments: [PROPERTY_TYPES.ENUMERATION],
      returns: NULLABLE_STRING_TYPE
    }, {
      arguments: [PROPERTY_TYPES.NUMBER],
      returns: NULLABLE_STRING_TYPE
    }, {
      arguments: [PROPERTY_TYPES.STRING],
      returns: NULLABLE_STRING_TYPE
    }),
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.LABEL.description',
    examples: ['LABEL([CONTACT.lifecyclestage])', 'LABEL([DEAL.dealstage])'],
    arguments: [{
      name: 'field',
      descriptionKey: 'reporting-snowflake.function-definitions.LABEL.arg1.description',
      examples: ['Enumeration properties such as [CONTACT.lifecyclestage]']
    }]
  }),
  LENGTH: FunctionDefinition({
    name: 'LENGTH',
    type: createFunctionType({
      arguments: [STRING_TYPE],
      returns: NUMBER_TYPE
    }, {
      arguments: [NULLABLE_STRING_TYPE],
      returns: NULLABLE_NUMBER_TYPE
    }),
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.LENGTH.description',
    examples: ['LENGTH("HubSpot") = 7', 'LENGTH([FEEDBACK_SUBMISSION.hs_content])'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.LENGTH.arg1.description',
      examples: ['"HubSpot"', 'CONCAT("Hub", "Spot")', 'string property such as [DEAL.dealname]', 'string field']
    }]
  }),
  TRIM: FunctionDefinition({
    name: 'TRIM',
    type: createFunctionType({
      arguments: [STRING_TYPE],
      returns: STRING_TYPE
    }, {
      arguments: [NULLABLE_STRING_TYPE],
      returns: NULLABLE_STRING_TYPE
    }),
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.TRIM.description',
    examples: ['TRIM("    HubSpot is great    ") = "HubSpot is great"'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.TRIM.arg1.description',
      examples: ['"    HubSpot    "', 'string property', 'string field']
    }]
  }),
  ABS: FunctionDefinition({
    name: 'ABS',
    type: NUMERIC_UNARY_OPERATION,
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.ABS.description',
    examples: ['ABS(-10) = 10', 'ABS(10) = 10'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.ABS.arg1.description',
      examples: [-10, 10, 'EXP(1)', '-EXP(1)', 'number property', 'number field']
    }]
  }),
  CEIL: FunctionDefinition({
    name: 'CEIL',
    type: NUMERIC_UNARY_OPERATION,
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.CEIL.description',
    examples: ['CEIL(3.14) = 4', 'CEIL(EXP(1)) = 3', 'CEIL(LN([DEAL.amount]))'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.CEIL.arg1.description',
      examples: [-10, 10, 'EXP(1)', '-EXP(1)', 'number property', 'number field']
    }]
  }),
  CONVERT_CURRENCY: FunctionDefinition({
    name: 'CONVERT_CURRENCY',
    type: createFunctionType({
      arguments: [NUMBER_TYPE, STRING_TYPE],
      returns: NUMBER_TYPE
    }, {
      arguments: [NULLABLE_NUMBER_TYPE, STRING_TYPE],
      returns: NULLABLE_NUMBER_TYPE
    }),
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.CONVERT_CURRENCY.description',
    examples: ['CONVERT_CURRENCY([DEAL.amount_in_company_currency], USD)'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.CONVERT_CURRENCY.arg1.description',
      examples: ['20']
    }, {
      name: 'currency',
      descriptionKey: 'reporting-snowflake.function-definitions.CONVERT_CURRENCY.arg2.description',
      examples: ['USD']
    }]
  }),
  DIV0: FunctionDefinition({
    name: 'DIV0',
    type: NUMERIC_BINARY_OPERATION,
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.DIV0.description',
    examples: ['DIV0(5, 2) = 2.5', 'DIV0(5, 0) = 0', 'DIV0([DEAL.amount], DATEDIFF("DAY", [DEAL.createdate], [DEAL.closedate]))'],
    arguments: [{
      name: 'dividend',
      descriptionKey: 'reporting-snowflake.function-definitions.DIV0.arg1.description',
      examples: [10, '([DEAL.amount] - [LINE_ITEM.amount])', 'number property', 'number field']
    }, {
      name: 'divisor',
      descriptionKey: 'reporting-snowflake.function-definitions.DIV0.arg2.description',
      examples: [0, 10, 'DATEDIFF("DAY", [DEAL.createdate], [DEAL.closedate])', 'number property', 'number field']
    }]
  }),
  EXP: FunctionDefinition({
    name: 'EXP',
    type: NUMERIC_UNARY_OPERATION,
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.EXP.description',
    examples: ['EXP(1) = 2.718281828459045', 'EXP(0) = 1'],
    arguments: [{
      name: 'exponent',
      descriptionKey: 'reporting-snowflake.function-definitions.EXP.arg1.description',
      examples: [0, 1, 'number property', 'number field']
    }]
  }),
  FLOOR: FunctionDefinition({
    name: 'FLOOR',
    type: NUMERIC_UNARY_OPERATION,
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.FLOOR.description',
    examples: ['FLOOR(3.14) = 3', 'CEIL(EXP(1)) = 2', 'FLOOR(LN([DEAL.amount]))'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.FLOOR.arg1.description',
      examples: [-10, 10, 'EXP(1)', '-EXP(1)', 'number property', 'number field']
    }]
  }),
  LN: FunctionDefinition({
    name: 'LN',
    type: NUMERIC_UNARY_OPERATION,
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.LN.description',
    examples: ['LN(1) = 0', 'LN(EXP(1)) = 1', 'LN([DEAL.amount])'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.LN.arg1.description',
      examples: [-10, 10, 'EXP(1)', '-EXP(1)', 'number property', 'number field']
    }]
  }),
  LOG: FunctionDefinition({
    name: 'LOG',
    type: NUMERIC_BINARY_OPERATION,
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.LOG.description',
    examples: ['LOG(10, 1) = 0', 'LOG(10, 10) = 1', 'LOG(10, [DEAL.amount])'],
    arguments: [{
      name: 'base',
      descriptionKey: 'reporting-snowflake.function-definitions.LOG.arg1.description',
      examples: [10, 'EXP(1)', 'number property', 'number field']
    }, {
      name: 'exponent',
      descriptionKey: 'reporting-snowflake.function-definitions.LOG.arg2.description',
      examples: [10, 100, 'number property', 'number field']
    }]
  }),
  POWER: FunctionDefinition({
    name: 'POWER',
    type: NUMERIC_BINARY_OPERATION,
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.POWER.description',
    examples: ['POWER(2, 10) = 1024', 'POWER(100, 0.5) = 10', 'POWER([DEAL.hs_arr], 2)'],
    arguments: [{
      name: 'base',
      descriptionKey: 'reporting-snowflake.function-definitions.POWER.arg1.description',
      examples: [5, 100, 'LN([DEAL.amount])', 'number property', 'number field']
    }, {
      name: 'exponent',
      descriptionKey: 'reporting-snowflake.function-definitions.POWER.arg2.description',
      examples: [2, 10, 'EXP(1)', 'number property', 'number field']
    }]
  }),
  SQRT: FunctionDefinition({
    name: 'SQRT',
    type: NUMERIC_UNARY_OPERATION,
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.SQRT.description',
    examples: ['SQRT(100) = 10', 'SQRT([DEAL.hs_arr])'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.SQRT.arg1.description',
      examples: [100, 144, '[DEAL.amount]']
    }]
  }),
  WIDTH_BUCKET: FunctionDefinition({
    name: 'WIDTH_BUCKET',
    type: createFunctionType({
      arguments: [NUMBER_TYPE, NUMBER_TYPE, NUMBER_TYPE, NUMBER_TYPE],
      returns: NUMBER_TYPE
    }, {
      arguments: [NULLABLE_NUMBER_TYPE, NUMBER_TYPE, NUMBER_TYPE, NUMBER_TYPE],
      returns: NULLABLE_NUMBER_TYPE
    }),
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.WIDTH_BUCKET.description',
    examples: ['WIDTH_BUCKET(25, 0, 100, 10) = 3', 'WIDTH_BUCKET(95, 0, 100, 10) = 10', 'WIDTH_BUCKET(-1000, 0, 100, 10) = 0', 'WIDTH_BUCKET(9999, 0, 100, 10) = 11', 'WIDTH_BUCKET([DEAL.amount], 0, 10000, 1000)'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.WIDTH_BUCKET.arg1.description',
      examples: [25, 'FLOOR(DIV0([DEAL.amount], DATEDIFF("DAY", [DEAL.createdate], [DEAL.closedate])))']
    }, {
      name: 'minValue',
      descriptionKey: 'reporting-snowflake.function-definitions.WIDTH_BUCKET.arg2.description',
      examples: []
    }, {
      name: 'maxValue',
      descriptionKey: 'reporting-snowflake.function-definitions.WIDTH_BUCKET.arg3.description',
      examples: []
    }, {
      name: 'bucketCount',
      descriptionKey: 'reporting-snowflake.function-definitions.WIDTH_BUCKET.arg4.description',
      examples: []
    }]
  }),
  DATE_FROM_PARTS: FunctionDefinition({
    name: 'DATE_FROM_PARTS',
    type: createFunctionType({
      arguments: [NUMBER_TYPE, NUMBER_TYPE, NUMBER_TYPE],
      returns: DATE_TYPE
    }, {
      arguments: [NULLABLE_NUMBER_TYPE, NULLABLE_NUMBER_TYPE, NULLABLE_NUMBER_TYPE],
      returns: NULLABLE_DATE_TYPE
    }),
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.DATE_FROM_PARTS.description',
    examples: ['DATE_FROM_PARTS(2021, 1, 1) = 2021-01-01'],
    arguments: [{
      name: 'year',
      descriptionKey: 'reporting-snowflake.function-definitions.DATE_FROM_PARTS.arg1.description',
      examples: [2021, 1995]
    }, {
      name: 'month',
      descriptionKey: 'reporting-snowflake.function-definitions.DATE_FROM_PARTS.arg2.description',
      examples: [1, 2, 3, 12]
    }, {
      name: 'day',
      descriptionKey: 'reporting-snowflake.function-definitions.DATE_FROM_PARTS.arg3.description',
      examples: [1, 2, 3, 31]
    }]
  }),
  DATEDIFF: FunctionDefinition({
    name: 'DATEDIFF',
    type: createFunctionType({
      arguments: [createUnionType(createStringType('YEAR'), createStringType('QUARTER'), createStringType('MONTH'), createStringType('WEEK'), createStringType('DAY'), createStringType('HOUR'), createStringType('MINUTE'), createStringType('SECOND')), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE)],
      returns: NUMBER_TYPE
    }, {
      arguments: [createUnionType(createStringType('YEAR'), createStringType('QUARTER'), createStringType('MONTH'), createStringType('WEEK'), createStringType('DAY'), createStringType('HOUR'), createStringType('MINUTE'), createStringType('SECOND')), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE)],
      returns: NULLABLE_NUMBER_TYPE
    }),
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.DATEDIFF.description',
    examples: ['DATEDIFF("DAY", "2021-01-01", DATE_FROM_PARTS(2021, 2, 1)) = 31', 'DATEDIFF("MONTH", "2021-01-01", DATE_FROM_PARTS(2021, 2, 1)) = 1', 'DATEDIFF("QUARTER", [DEAL.createdate], [DEAL.closedate])'],
    arguments: [{
      name: 'datePart',
      descriptionKey: 'reporting-snowflake.function-definitions.DATEDIFF.arg1.description',
      examples: ['"YEAR"', '"QUARTER"', '"MONTH"', '"WEEK"', '"DAY"', '"HOUR"', '"MINUTE"', '"SECOND"']
    }, {
      name: 'date1',
      descriptionKey: 'reporting-snowflake.function-definitions.DATEDIFF.arg2.description',
      examples: ['"2021-01-01"', 1609459200, '[DEAL.createdate]']
    }, {
      name: 'date2',
      descriptionKey: 'reporting-snowflake.function-definitions.DATEDIFF.arg3.description',
      examples: ['DATE_FROM_PARTS(2021, 3, 15)', 'DATETRUNC("MONTH", [CONTACT.createdate])']
    }]
  }),
  ADJUST_FISCAL_YEAR: FunctionDefinition({
    name: 'ADJUST_FISCAL_YEAR',
    type: createFunctionType({
      arguments: [createUnionType(DATETIME_TYPE, NULL_TYPE)],
      returns: NULLABLE_DATETIME_TYPE
    }, {
      arguments: [createUnionType(DATE_TYPE, NULL_TYPE)],
      returns: NULLABLE_DATE_TYPE
    }),
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.ADJUST_FISCAL_YEAR.description',
    examples: ['ADJUST_FISCAL_YEAR([CONTACT.createdate])'],
    arguments: [{
      name: 'date',
      descriptionKey: 'reporting-snowflake.function-definitions.ADJUST_FISCAL_YEAR.arg1.description',
      examples: ['[CONTACT.createdate]', 'date property', 'datetime property']
    }]
  }),
  DATEPART: FunctionDefinition({
    name: 'DATEPART',
    type: createFunctionType({
      arguments: [createUnionType(createStringType('YEAR'), createStringType('YEAROFWEEK'), createStringType('YEAROFWEEKISO'), createStringType('QUARTER'), createStringType('MONTH'), createStringType('WEEK'), createStringType('WEEKISO'), createStringType('DAY'), createStringType('HOUR'), createStringType('DAYOFWEEK'), createStringType('DAYOFWEEKISO'), createStringType('DAYOFYEAR')), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE)],
      returns: NUMBER_TYPE
    }, {
      arguments: [createUnionType(createStringType('YEAR'), createStringType('YEAROFWEEK'), createStringType('YEAROFWEEKISO'), createStringType('QUARTER'), createStringType('MONTH'), createStringType('WEEK'), createStringType('WEEKISO'), createStringType('DAY'), createStringType('HOUR'), createStringType('DAYOFWEEK'), createStringType('DAYOFWEEKISO'), createStringType('DAYOFYEAR')), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE)],
      returns: NULLABLE_NUMBER_TYPE
    }),
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.DATEPART.description',
    examples: ['DATEPART("DAY", "2021-03-15") = 15', 'DATEPART("MONTH", DATE_FROM_PARTS(2021, 3, 15)) = 3', 'DATEPART("YEAR", [DEAL.createdate])'],
    arguments: [{
      name: 'datePart',
      descriptionKey: 'reporting-snowflake.function-definitions.DATEPART.arg1.description',
      examples: ['"YEAR"', '"YEAROFWEEK"', '"YEAROFWEEKISO"', '"QUARTER"', '"MONTH"', '"WEEK"', '"WEEKISO', '"DAY"', '"DAYOFWEEK"', '"DAYOFWEEKISO"', '"DAYOFYEAR"']
    }, {
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.DATEPART.arg2.description',
      examples: ['"2021-01-01"', 1609459200, '[DEAL.createdate]']
    }]
  }),
  DATETRUNC: FunctionDefinition({
    name: 'DATETRUNC',
    type: createFunctionType({
      arguments: [createUnionType(createStringType('YEAR'), createStringType('QUARTER'), createStringType('MONTH'), createStringType('WEEK'), createStringType('DAY')), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE)],
      returns: DATE_TYPE
    }, {
      arguments: [createUnionType(createStringType('YEAR'), createStringType('QUARTER'), createStringType('MONTH'), createStringType('WEEK'), createStringType('DAY')), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE)],
      returns: NULLABLE_DATE_TYPE
    }),
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.DATETRUNC.description',
    examples: ['DATETRUNC("YEAR", DATE_FROM_PARTS(2021, 3, 15)) = 2021-01-01', 'DATETRUNC("MONTH", "2021-03-15") = 2021-03-01', 'DATETRUNC("DAY", NOW()) = 2022-11-29', 'DATETRUNC("DAY", [e_visited_page.__hs_event_native_timestamp])'],
    arguments: [{
      name: 'datePart',
      descriptionKey: 'reporting-snowflake.function-definitions.DATETRUNC.arg1.description',
      examples: ['"YEAR"', '"QUARTER"', '"MONTH"', '"WEEK"', '"DAY"']
    }, {
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.DATETRUNC.arg2.description',
      examples: ['"2021-01-01"', 1609459200, '[DEAL.createdate]']
    }]
  }),
  TIMESTAMP_FROM_PARTS: FunctionDefinition({
    name: 'TIMESTAMP_FROM_PARTS',
    type: createFunctionType({
      arguments: [NUMBER_TYPE,
      // year
      NUMBER_TYPE,
      // month
      NUMBER_TYPE,
      // day
      NUMBER_TYPE,
      // hour
      NUMBER_TYPE,
      // minute
      NUMBER_TYPE // seconds
      ],
      returns: DATETIME_TYPE
    }, {
      arguments: [NULLABLE_NUMBER_TYPE,
      // year
      NULLABLE_NUMBER_TYPE,
      // month
      NULLABLE_NUMBER_TYPE,
      // day
      NULLABLE_NUMBER_TYPE,
      // hour
      NULLABLE_NUMBER_TYPE,
      // minute
      NULLABLE_NUMBER_TYPE // seconds
      ],
      returns: NULLABLE_DATETIME_TYPE
    }),
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.TIMESTAMP_FROM_PARTS.description',
    examples: ['TIMESTAMP_FROM_PARTS(2021, 1, 1, 0, 0, 0) = 1609459200000'],
    arguments: [{
      name: 'year',
      descriptionKey: 'reporting-snowflake.function-definitions.TIMESTAMP_FROM_PARTS.arg1.description',
      examples: [2021, 1995]
    }, {
      name: 'month',
      descriptionKey: 'reporting-snowflake.function-definitions.TIMESTAMP_FROM_PARTS.arg2.description',
      examples: [1, 2, 3, 12]
    }, {
      name: 'day',
      descriptionKey: 'reporting-snowflake.function-definitions.TIMESTAMP_FROM_PARTS.arg3.description',
      examples: [1, 2, 3, 31]
    }, {
      name: 'hour',
      descriptionKey: 'reporting-snowflake.function-definitions.TIMESTAMP_FROM_PARTS.arg4.description',
      examples: [0, 1, 2, 3, 23]
    }, {
      name: 'minute',
      descriptionKey: 'reporting-snowflake.function-definitions.TIMESTAMP_FROM_PARTS.arg5.description',
      examples: [0, 1, 2, 3, 59]
    }, {
      name: 'second',
      descriptionKey: 'reporting-snowflake.function-definitions.TIMESTAMP_FROM_PARTS.arg6.description',
      examples: [0, 1, 2, 3, 59]
    }]
  }),
  WEEKNUM: FunctionDefinition({
    name: 'WEEKNUM',
    type: createFunctionType({
      arguments: [createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE)],
      returns: NUMBER_TYPE
    }, {
      arguments: [createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE)],
      returns: NULLABLE_NUMBER_TYPE
    }),
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.WEEKNUM.description',
    examples: ['WEEKNUM("2021-03-15") = 11'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.WEEKNUM.arg1.description',
      examples: ['"2021-01-01"', 1609459200, '[DEAL.createdate]']
    }]
  }),
  NOW: FunctionDefinition({
    name: 'NOW',
    type: createFunctionType({
      arguments: [],
      returns: DATETIME_TYPE
    }),
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.NOW.description',
    examples: ['NOW() = 1633611966314'],
    arguments: []
  }),
  WORKINGDAYS: FunctionDefinition({
    name: 'WORKINGDAYS',
    type: createFunctionType({
      arguments: [createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE)],
      returns: NUMBER_TYPE
    }, {
      arguments: [createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE, NULL_TYPE)],
      returns: NULLABLE_NUMBER_TYPE
    }),
    signatureIndexForDocs: 0,
    descriptionKey: 'reporting-snowflake.function-definitions.WORKINGDAYS.description',
    examples: ['WORKINGDAYS("2018-01-01", "2017-12-31")', 'WORKINGDAYS([DEAL.createdate], NOW())'],
    arguments: [{
      name: 'value1',
      descriptionKey: 'reporting-snowflake.function-definitions.WORKINGDAYS.arg1.description',
      examples: ['"2021-01-01"', 1609459200, '[DEAL.createdate]', 'NOW()']
    }, {
      name: 'value2',
      descriptionKey: 'reporting-snowflake.function-definitions.WORKINGDAYS.arg2.description',
      examples: ['"2021-01-01"', 1609459200, '[DEAL.createdate]', 'NOW()']
    }]
  }),
  DISTINCT_COUNT: FunctionDefinition({
    name: 'DISTINCT_COUNT',
    type: createAggregationFunctionType({
      arguments: [createGenericType('T')],
      returns: NUMBER_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.DISTINCT_COUNT.description',
    examples: [],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.DISTINCT_COUNT.arg1.description',
      examples: []
    }]
  }),
  SUM: FunctionDefinition({
    name: 'SUM',
    type: createAggregationFunctionType({
      arguments: [NULLABLE_NUMBER_TYPE],
      returns: NUMBER_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.SUM.description',
    examples: [],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.SUM.arg1.description',
      examples: []
    }]
  }),
  AVERAGE: FunctionDefinition({
    name: 'AVERAGE',
    type: createAggregationFunctionType({
      arguments: [NULLABLE_NUMBER_TYPE],
      returns: NUMBER_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.AVERAGE.description',
    examples: [],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.AVERAGE.arg1.description',
      examples: []
    }]
  }),
  MIN: FunctionDefinition({
    name: 'MIN',
    type: createAggregationFunctionType({
      arguments: [NULLABLE_NUMBER_TYPE],
      returns: NUMBER_TYPE
    }, {
      arguments: [NULLABLE_STRING_TYPE],
      returns: STRING_TYPE
    }, {
      arguments: [NULLABLE_BOOLEAN_TYPE],
      returns: BOOLEAN_TYPE
    }, {
      arguments: [NULLABLE_DATE_TYPE],
      returns: DATE_TYPE
    }, {
      arguments: [NULLABLE_DATETIME_TYPE],
      returns: DATETIME_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.MIN.description',
    examples: [],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.MIN.arg1.description',
      examples: []
    }]
  }),
  MAX: FunctionDefinition({
    name: 'MAX',
    type: createAggregationFunctionType({
      arguments: [NULLABLE_NUMBER_TYPE],
      returns: NUMBER_TYPE
    }, {
      arguments: [NULLABLE_STRING_TYPE],
      returns: STRING_TYPE
    }, {
      arguments: [NULLABLE_BOOLEAN_TYPE],
      returns: BOOLEAN_TYPE
    }, {
      arguments: [NULLABLE_DATE_TYPE],
      returns: DATE_TYPE
    }, {
      arguments: [NULLABLE_DATETIME_TYPE],
      returns: DATETIME_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.MAX.description',
    examples: [],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.MAX.arg1.description',
      examples: []
    }]
  }),
  BETWEEN: FunctionDefinition({
    name: 'BETWEEN',
    type: createFunctionType({
      arguments: [createUnionType(NULLABLE_NUMBER_TYPE, NULLABLE_STRING_TYPE, NULLABLE_DATETIME_TYPE), createUnionType(NULLABLE_NUMBER_TYPE, NULLABLE_STRING_TYPE, NULLABLE_DATETIME_TYPE), createUnionType(NULLABLE_NUMBER_TYPE, NULLABLE_STRING_TYPE, NULLABLE_DATETIME_TYPE)],
      returns: BOOLEAN_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.BETWEEN.description',
    examples: ['BETWEEN([DEAL.amount], 100, 2000)'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.BETWEEN.arg1.description',
      examples: []
    }, {
      name: 'lower',
      descriptionKey: 'reporting-snowflake.function-definitions.BETWEEN.arg2.description',
      examples: []
    }, {
      name: 'upper',
      descriptionKey: 'reporting-snowflake.function-definitions.BETWEEN.arg3.description',
      examples: []
    }]
  }),
  LASTDAY: FunctionDefinition({
    name: 'LASTDAY',
    type: createFunctionType({
      arguments: [createUnionType(createStringType('YEAR'), createStringType('QUARTER'), createStringType('MONTH'), createStringType('WEEK')), createUnionType(NUMBER_TYPE, STRING_TYPE, DATE_TYPE, DATETIME_TYPE)],
      returns: DATE_TYPE
    }, {
      arguments: [createUnionType(createStringType('YEAR'), createStringType('QUARTER'), createStringType('MONTH'), createStringType('WEEK')), createUnionType(NULLABLE_NUMBER_TYPE, NULLABLE_STRING_TYPE, NULLABLE_DATE_TYPE, NULLABLE_DATETIME_TYPE, NULL_TYPE)],
      returns: NULLABLE_DATE_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.LASTDAY.description',
    examples: ['LASTDAY("QUARTER", [DEAL.closedate])'],
    arguments: [{
      name: 'period',
      descriptionKey: 'reporting-snowflake.function-definitions.LASTDAY.arg1.description',
      examples: ['WEEK', 'MONTH', 'QUARTER', 'YEAR']
    }, {
      name: 'date',
      descriptionKey: 'reporting-snowflake.function-definitions.LASTDAY.arg2.description',
      examples: []
    }]
  }),
  NOT: FunctionDefinition({
    name: 'NOT',
    type: createFunctionType({
      arguments: [NULLABLE_BOOLEAN_TYPE],
      returns: BOOLEAN_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.NOT.description',
    examples: ['NOT([DEAL.amount] > 1000)'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.NOT.arg1.description',
      examples: []
    }]
  }),
  ROUND: FunctionDefinition({
    name: 'ROUND',
    type: createFunctionType({
      arguments: [NULLABLE_NUMBER_TYPE],
      returns: NULLABLE_NUMBER_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.ROUND.description',
    examples: ['ROUND([CONTACT.hs_analytics_average_page_views] / 30)'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.ROUND.arg1.description',
      examples: []
    }]
  }),
  LOWER: FunctionDefinition({
    name: 'LOWER',
    type: createFunctionType({
      arguments: [STRING_TYPE],
      returns: STRING_TYPE
    }, {
      arguments: [NULLABLE_STRING_TYPE],
      returns: NULL_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.LOWER.description',
    examples: ['LOWER([CONTACT.lastname])'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.LOWER.arg1.description',
      examples: []
    }]
  }),
  UPPER: FunctionDefinition({
    name: 'UPPER',
    type: createFunctionType({
      arguments: [STRING_TYPE],
      returns: STRING_TYPE
    }, {
      arguments: [NULLABLE_STRING_TYPE],
      returns: NULL_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.UPPER.description',
    examples: ['UPPER([CONTACT.lastname])'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.UPPER.arg1.description',
      examples: []
    }]
  }),
  STARTSWITH: FunctionDefinition({
    name: 'STARTSWITH',
    type: createFunctionType({
      arguments: [NULLABLE_STRING_TYPE, NULLABLE_STRING_TYPE],
      returns: BOOLEAN_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.STARTSWITH.description',
    examples: ['STARTSWITH([DEAL.lead_source], "Marketing")'],
    arguments: [{
      name: 'string',
      descriptionKey: 'reporting-snowflake.function-definitions.STARTSWITH.arg1.description',
      examples: []
    }, {
      name: 'prefix',
      descriptionKey: 'reporting-snowflake.function-definitions.STARTSWITH.arg2.description',
      examples: []
    }]
  }),
  ENDSWITH: FunctionDefinition({
    name: 'ENDSWITH',
    type: createFunctionType({
      arguments: [NULLABLE_STRING_TYPE, NULLABLE_STRING_TYPE],
      returns: BOOLEAN_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.ENDSWITH.description',
    examples: ['ENDSWITH([DEAL.dealname], "Upsell")'],
    arguments: [{
      name: 'string',
      descriptionKey: 'reporting-snowflake.function-definitions.ENDSWITH.arg1.description',
      examples: []
    }, {
      name: 'suffix',
      descriptionKey: 'reporting-snowflake.function-definitions.ENDSWITH.arg2.description',
      examples: []
    }]
  }),
  CONTACT_LABEL: FunctionDefinition({
    name: 'CONTACT_LABEL',
    type: createFunctionType({
      arguments: [NULLABLE_STRING_TYPE, NULLABLE_STRING_TYPE, NULLABLE_STRING_TYPE],
      returns: NULLABLE_STRING_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.CONTACT_LABEL.description',
    examples: ['CONTACT_LABEL([CONTACT.firstname], [CONTACT.lastname], [CONTACT.email])'],
    arguments: [{
      name: 'first name',
      descriptionKey: 'reporting-snowflake.function-definitions.CONTACT_LABEL.arg1.description',
      examples: []
    }, {
      name: 'last name',
      descriptionKey: 'reporting-snowflake.function-definitions.CONTACT_LABEL.arg2.description',
      examples: []
    }, {
      name: 'email',
      descriptionKey: 'reporting-snowflake.function-definitions.CONTACT_LABEL.arg3.description',
      examples: []
    }]
  }),
  COALESCE: FunctionDefinition({
    name: 'COALESCE',
    type: createFunctionType({
      arguments: [createGenericType('T')],
      rest: createGenericType('T'),
      returns: createGenericType('T')
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.COALESCE.description',
    examples: [''],
    arguments: [{
      name: 'arg1',
      descriptionKey: 'reporting-snowflake.function-definitions.COALESCE.arg1.description',
      examples: []
    }, {
      name: '...args',
      descriptionKey: 'reporting-snowflake.function-definitions.COALESCE.args.description',
      examples: []
    }]
  }),
  MEDIAN: FunctionDefinition({
    name: 'MEDIAN',
    type: createAggregationFunctionType({
      arguments: [NULLABLE_NUMBER_TYPE],
      returns: NULLABLE_NUMBER_TYPE
    }, {
      arguments: [NULLABLE_DATE_TYPE],
      returns: NULLABLE_DATE_TYPE
    }, {
      arguments: [NULLABLE_DATETIME_TYPE],
      returns: NULLABLE_DATETIME_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.MEDIAN.description',
    examples: ['MEDIAN([DEAL.amount])'],
    arguments: [{
      name: 'property',
      descriptionKey: 'reporting-snowflake.function-definitions.MEDIAN.arg1.description',
      examples: []
    }]
  }),
  IN: FunctionDefinition({
    name: 'IN',
    type: createAggregationFunctionType({
      arguments: [NUMBER_TYPE, NULLABLE_NUMBER_TYPE],
      returns: BOOLEAN_TYPE
    }, {
      arguments: [STRING_TYPE, NULLABLE_STRING_TYPE],
      returns: BOOLEAN_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.IN.description',
    examples: ['IN(1, [CONTACT.days_to_close])'],
    arguments: [{
      name: 'value',
      descriptionKey: 'reporting-snowflake.function-definitions.IN.arg1.description',
      examples: []
    }, {
      name: 'property',
      descriptionKey: 'reporting-snowflake.function-definitions.IN.arg2.description',
      examples: []
    }]
  }),
  COUNT: FunctionDefinition({
    name: 'COUNT',
    type: createAggregationFunctionType({
      arguments: [createGenericType('T')],
      returns: NUMBER_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.COUNT.description',
    examples: ['COUNT([CONTACT.hs_object_id])'],
    arguments: [{
      name: 'exp',
      descriptionKey: 'reporting-snowflake.function-definitions.COUNT.arg1.description',
      examples: []
    }]
  }),
  ROW_NUMBER: FunctionDefinition({
    name: 'ROW_NUMBER',
    type: createFunctionType({
      arguments: [],
      returns: NUMBER_TYPE
    }),
    signatureIndexForDocs: 0,
    showBetaTag: true,
    descriptionKey: 'reporting-snowflake.function-definitions.ROW_NUMBER.description',
    examples: ['ROW_NUMBER()'],
    arguments: []
  })
};
export const FUNCTION_TYPES = Seq(FUNCTION_DEFINITIONS).map(({
  type
}) => type).toObject();
export const SCOPE = Scope({
  YEAR: Value({
    type: createStringType('YEAR'),
    value: 'YEAR'
  }),
  YEAROFWEEK: Value({
    type: createStringType('YEAROFWEEK'),
    value: 'YEAROFWEEK'
  }),
  YEAROFWEEKISO: Value({
    type: createStringType('YEAROFWEEKISO'),
    value: 'YEAROFWEEKISO'
  }),
  QUARTER: Value({
    type: createStringType('QUARTER'),
    value: 'QUARTER'
  }),
  MONTH: Value({
    type: createStringType('MONTH'),
    value: 'MONTH'
  }),
  WEEK: Value({
    type: createStringType('WEEK'),
    value: 'WEEK'
  }),
  WEEKISO: Value({
    type: createStringType('WEEKISO'),
    value: 'WEEKISO'
  }),
  DAY: Value({
    type: createStringType('DAY'),
    value: 'DAY'
  }),
  DAYOFWEEK: Value({
    type: createStringType('DAYOFWEEK'),
    value: 'DAYOFWEEK'
  }),
  DAYOFWEEKISO: Value({
    type: createStringType('DAYOFWEEKISO'),
    value: 'DAYOFWEEKISO'
  }),
  DAYOFYEAR: Value({
    type: createStringType('DAYOFYEAR'),
    value: 'DAYOFYEAR'
  }),
  HOUR: Value({
    type: createStringType('HOUR'),
    value: 'HOUR'
  }),
  MINUTE: Value({
    type: createStringType('MINUTE'),
    value: 'MINUTE'
  }),
  SECOND: Value({
    type: createStringType('SECOND'),
    value: 'SECOND'
  })
});
export const withStaticTypeDependencies = dependencies => Object.assign({
  operators: OPERATOR_TYPES,
  functions: FUNCTION_TYPES,
  scope: SCOPE
}, dependencies);