import { stableHash } from '../utils/stableHash';
import { createWorkerInstance } from './createWorkerInstance';
const emptyFunction = () => {};
export const runWorker = ({
  current,
  previous,
  extraProcessing
}) => new Promise((resolve, reject) => {
  const worker = createWorkerInstance(`
(() => {
  var stableHash = ${stableHash};
  var extraProcessing = ${extraProcessing || emptyFunction};

  self.onmessage = (e) => {
    try {
      var current = e.data.current;
      var previous = e.data.previous;

      var isEqual = stableHash(current) === stableHash(previous);
      var extraProcessingResult = extraProcessing(current);

      self.postMessage({
        type: "DATA",
        isEqual,
        extraProcessingResult
      });
    } catch (e) {
      self.postMessage({ type: "ERROR", error: e });
    }
  }
})();`);
  let didTerminate = false;
  worker.onmessage = function (e) {
    didTerminate = true;
    worker.terminate();
    if (e.data.type === 'ERROR') {
      reject(e.data.error);
    } else if (e.data.type === 'DATA') {
      resolve(e.data);
    }
  };
  setTimeout(() => {
    if (!didTerminate) {
      didTerminate = true;
      worker.terminate();
      reject(Error('Worker timeout'));
    }
  }, 60000);
  worker.postMessage({
    current,
    previous
  });
});