import { useSelector } from 'react-redux';
import { selectDashboardFilters, selectDashboardFrequency, selectDashboardFrequencyUseFiscalYear } from '../../../../../../ducks/applied-dashboard-filters';
import { track, EVENTS
// @ts-expect-error migrate upstream
} from '../../../../../../../tracking/DashboardUITracker';
import { useUpdateDashboardFilterSet } from '../../../../../../lib/savedDashboardFilters/hooks';
import { selectAppliedSavedFilterSet } from '../../../../../../ducks/savedDashboardFilters';
import { useSavedDashboardFiltersPanelContext } from '../context';
import { useReportDataSources } from './use-data-sources';
import { areFiltersSame } from '../../../../../../lib/dashboardFilters/types';
export const useSaveAppliedFilters = () => {
  const {
    dashboardId
  } = useSavedDashboardFiltersPanelContext();
  const appliedFilters = useSelector(selectDashboardFilters(dashboardId));
  const appliedFrequency = useSelector(selectDashboardFrequency(dashboardId));
  const appliedFrequencyUseFiscalYear = useSelector(selectDashboardFrequencyUseFiscalYear(dashboardId));
  const {
    dataSourcesProperties,
    dataSourcesMetadata
  } = useReportDataSources();
  const {
    isLoading,
    onUpdate,
    hasError,
    error
  } = useUpdateDashboardFilterSet(dataSourcesProperties, dataSourcesMetadata);
  const appliedFilterSet = useSelector(selectAppliedSavedFilterSet(dashboardId));
  const hasUnsavedFilters = (() => {
    if (!appliedFilterSet) {
      return false;
    }
    if (appliedFilterSet.filters.length !== appliedFilters.length) {
      return true;
    }
    if (appliedFilterSet.filters.some(filter => !appliedFilters.some(filterSetFilter => areFiltersSame(filterSetFilter, filter)))) {
      return true;
    }
    if (appliedFilterSet.frequency !== appliedFrequency) {
      return true;
    }
    if (appliedFilterSet.useFiscalYear !== appliedFrequencyUseFiscalYear) {
      return true;
    }
    return false;
  })();
  const handleSaveAppliedFilters = () => {
    if (!isLoading && appliedFilterSet) {
      onUpdate({
        id: appliedFilterSet.id,
        defaultFilter: appliedFilterSet.defaultFilter,
        name: appliedFilterSet.name,
        dashboardId: appliedFilterSet.dashboardId,
        filters: appliedFilters,
        frequency: appliedFrequency,
        useFiscalYear: appliedFrequencyUseFiscalYear
      }).then(() => {
        track(EVENTS.saveDashboardFilterSet, {
          interactionSource: 'save-applied',
          actionType: 'explore',
          assetType: 'dashboard'
        });
      }).catch(() => {});
    }
  };
  return {
    onSaveAppliedFilters: handleSaveAppliedFilters,
    isLoading,
    hasError,
    error,
    hasUnsavedFilters
  };
};