'use es6';

import { fromJS } from 'immutable';
import { TABLE } from 'reporting-data/constants/chartTypes';
import { isCustomWidgetReport } from 'reporting-data/tsTypes/reportTypes';
import { getDrilldownPropertiesForGrouping, updateSort, isUnifiedAnalyticsReport, getDataTypeName, isJourneyReport } from '../utils/salesAnalyticsHelpers';
import { getFilteredBreakdownKey } from '../utils/filteredBreakdownsHelpers';
import { applyMutators } from './mutators';
const TABLE_LIMIT = 10;
const getDimensionDrilldownMutator = updateContext => ({
  report,
  context
}) => {
  const {
    drilldowns,
    grouping
  } = context;
  const drilldownProperties = getDrilldownPropertiesForGrouping(report, grouping);
  const dimensionPropertyIndex = drilldowns.count();
  const canDrilldownFurther = dimensionPropertyIndex < drilldownProperties.length - 1;
  if (canDrilldownFurther) {
    const dimensionProperty = drilldownProperties[dimensionPropertyIndex];
    return report.setIn(['displayParams', 'columns', dimensionProperty, 'onClick'], ({
      field,
      metricLabel,
      value
    }) => {
      const newDrilldown = fromJS({
        property: field,
        value,
        label: metricLabel
      });
      updateContext({
        drilldowns: drilldowns.push(newDrilldown)
      }, report);
    });
  }
  return report;
};
const getRowsPerPageMutator = updateContext => ({
  report
}) => report.setIn(['displayParams', 'rowsPerPage', 'onChange'], ({
  target: {
    value
  }
}) => updateContext({
  limitTable: value
}, report));
const getToggleSeriesMutator = updateContext => ({
  report,
  context
}) => {
  if (!isUnifiedAnalyticsReport(report)) {
    return report;
  }
  const {
    filteredBreakdowns
  } = context;
  const toggleSeriesCallback = id => {
    const toggledBreakdownFilterKey = getFilteredBreakdownKey(getDataTypeName(report), String(id));
    const maybeBreakdownFilterIndex = filteredBreakdowns.findIndex(value => value === toggledBreakdownFilterKey);
    const updatedFilteredBreakdowns = maybeBreakdownFilterIndex === -1 ? filteredBreakdowns.push(toggledBreakdownFilterKey) : filteredBreakdowns.delete(maybeBreakdownFilterIndex);
    updateContext({
      filteredBreakdowns: updatedFilteredBreakdowns
    }, report);
  };
  return report.setIn(['displayParams', 'toggleSeries'], toggleSeriesCallback);
};
const generateTable = ({
  subAppKey,
  context,
  updateContext,
  reportTemplate,
  filteredGates,
  options
}) => {
  const {
    limitTable
  } = context;
  const additionalMutators = updateContext ? [getDimensionDrilldownMutator(updateContext), getRowsPerPageMutator(updateContext), getToggleSeriesMutator(updateContext)] : [];
  const generatedConfig = applyMutators({
    reportTemplate: reportTemplate.set('chartType', TABLE).setIn(['displayParams', 'showTotals'], true),
    subAppKey,
    context,
    filteredGates,
    additionalMutators,
    options
  });
  if (isJourneyReport(reportTemplate)) {
    return generatedConfig;
  }
  const rowLimit = limitTable || TABLE_LIMIT;
  if (isCustomWidgetReport(reportTemplate)) {
    return generatedConfig.setIn(['config', 'limit'], rowLimit);
  }
  if (generatedConfig.getIn(['config', 'dimensions']).size === 0) {
    return null;
  }
  return generatedConfig.update(updateSort).setIn(['config', 'limit'], rowLimit).updateIn(['config', 'processors'], processors => processors && processors.filter(processor => processor !== 'accumulate'));
};
export default generateTable;