'use es6';

import { Record } from 'immutable';
import always from 'transmute/always';
import get from 'transmute/get';
import toString from 'transmute/toString';
import { isFunction, isNil } from 'dashboard-lib/private/js-util';
const defaultChecker = always(false);
const Problem = Record({
  id: null,
  checker: defaultChecker
}, 'Problem');
export const getId = get('id');
export const getChecker = get('checker');
export const createProblem = (id, checker) => {
  if (isNil(id) || !isFunction(checker)) {
    console.warn('createProblem: invalid parameters');
  }
  const toReturn = Problem({
    id,
    checker
  });
  toReturn.toString = () => id;
  return toReturn;
};
export const equalsProblem = (problem, subject) => toString(problem) === toString(subject);