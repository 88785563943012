/* hs-eslint ignored failing-rules */

'use es6';

import { useEffect, useState } from 'react';
import { fromJS, Map as ImmutableMap } from 'immutable';
import http from 'hub-http/clients/apiClient';
const fetchReportTemplateByKey = templateKey => http.get(`reporting-discovery/v1/report-template/template-key/${templateKey}`);

//Recursively removes nested null values from an immutable map
//Necessary for potential breakages in the reporting platform
export const removeNulls = m => {
  if (!ImmutableMap.isMap(m)) {
    return m;
  }
  return m.reduce((memo, value, key) => {
    if (value === null) {
      return memo;
    } else if (ImmutableMap.isMap(value)) {
      const removedNulls = removeNulls(value);
      if (removedNulls.isEmpty()) {
        return memo;
      } else {
        return memo.set(key, removedNulls);
      }
    } else {
      return memo.set(key, value);
    }
  }, ImmutableMap());
};

//Normalize BE template to FE format
export const normalizeSingleTemplate = widget => {
  const {
    caption,
    chartType,
    config,
    editors,
    reportDefinition,
    description,
    displayParams,
    editableInBuilder,
    hiddenFromLibrary,
    name,
    tags = [],
    status
  } = widget;
  const report = removeNulls(fromJS({
    name,
    chartType,
    config,
    displayParams,
    editors,
    reportDefinition
  }));
  return fromJS({
    name,
    caption,
    description,
    editableInBuilder,
    hiddenFromLibrary,
    contentTags: tags,
    report,
    status
  });
};

//Same as above, but accepts a key
export const getWidgetByKeyAsync = key => key ? fetchReportTemplateByKey(key).then(reportTemplate => {
  return normalizeSingleTemplate(reportTemplate);
}, () => Promise.resolve(ImmutableMap())) : Promise.resolve(ImmutableMap());
export const useWidgetByKey = key => {
  const [widget, setWidget] = useState();
  useEffect(() => {
    getWidgetByKeyAsync(key).then(setWidget).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  }, [key]);
  return widget;
};