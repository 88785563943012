'use es6';

import formatName from 'I18n/utils/formatName';
import { getEmail, getFirstName, getLastName } from '../data/schemas/userSchema';
export const getUserLabel = ({
  user,
  showEmail = true
}) => {
  const email = getEmail(user);
  const fullName = formatName({
    firstName: getFirstName(user),
    lastName: getLastName(user)
  });
  if (fullName.length < 2) {
    return email;
  }
  return showEmail && email ? `${fullName} (${email})`.trim() : fullName;
};