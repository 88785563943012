const getScrollableContainer = () => {
  try {
    const navLayout = localStorage.getItem('NAVIGATION_PLACEHOLDER_VARIANT');
    const scrollableContainer = document.querySelector('.hubspot > .page');
    if (navLayout === 'vertical-nav' && scrollableContainer) {
      return scrollableContainer;
    }
  } catch (err) {
    // no-op
  }
  return window;
};
export default getScrollableContainer;