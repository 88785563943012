'use es6';

// Series metrics have a column number, a '__', a property name
// another '__' and a all-caps snake-case metricType
const reExtractor = /^(\d+)__(.*)__([A-Z][A-Z_]+)$/;
export default (metric => {
  if (reExtractor.test(metric)) {
    const [, column, property, metricType] = reExtractor.exec(metric);
    return {
      column,
      property,
      metricType
    };
  }
  return null;
});