'use es6';

import { FAILED_TO_FETCH_DASHBOARD, LOADING, PERMISSION_DENIED } from 'dashboard-lib/private/action-problem-solution/actions/clone-dashboard/problem';
import { indexBy } from 'dashboard-lib/private/js-util';
import { DASHBOARD_CLONE } from 'dashboard-lib/public/action/reporting-action';
import { NeedCreateAndOwnPermissionMessage } from '../common/common-messages';
import { createDisabledMessage } from '../common/disabled-message';
import { getProblemLang } from '../common/get-problem-lang';
import { createMessage, getId } from '../common/message';
import { CUSTOM_DASHBOARD_LIMIT_REACHED_OR_EXCEEDED } from 'dashboard-lib/private/action-problem-solution/common/asset-limit';
const MESSAGE_ROOT = getProblemLang(DASHBOARD_CLONE);
const createProblemMessage = createMessage(MESSAGE_ROOT);
const ProblemMessages = [createProblemMessage(FAILED_TO_FETCH_DASHBOARD), createProblemMessage(LOADING), NeedCreateAndOwnPermissionMessage(DASHBOARD_CLONE), createProblemMessage(PERMISSION_DENIED), createProblemMessage(CUSTOM_DASHBOARD_LIMIT_REACHED_OR_EXCEEDED)];
const _problemToMessage = indexBy(getId, ProblemMessages);
export function getDisabledProblemMessage(params, problem) {
  return createDisabledMessage(params, problem, _problemToMessage);
}