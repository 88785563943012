import { List as ImmutableList, Map as ImmutableMap } from 'immutable';
import { REPORT_TYPES as SALES_PERFORMANCE_TYPES } from 'reporting-data/constants/salesPerformanceConstants';
export const ORDERED_STAGES = ImmutableList(Object.keys(SALES_PERFORMANCE_TYPES));
export const setStagesOnReport = (report, stages) => {
  return report.setIn(['displayParams', 'customWidget', 'options', 'values'], ImmutableList(ORDERED_STAGES.filter(stage => stages.has(stage)).map(property => ImmutableMap({
    property
  }))));
};
export const getStagesOnReport = report => {
  return report.getIn(['displayParams', 'customWidget', 'options', 'values'], ImmutableList()).map(stage => stage.get('property')).toSet();
};