'use es6';

import { SUB_APPS } from '../sub-apps';
const CATEGORIES = {
  CHANNEL_PERFORMANCE: 'channelPerformance',
  WEB_TRAFFIC_ANALYSIS: 'webTrafficAnalysis',
  MULTI_TOUCH_ATTRIBUTION_REVENUE_IMPACT: 'multiTouchAttributionRevenueImpact',
  ATTRIBUTION: 'attribution',
  ADVANCED_MARKETING_REPORTING: 'advancedMarketingReporting',
  CRM_INSIGHTS: 'crmInsights'
};
export const CATEGORY_SUB_APPS = {
  [CATEGORIES.ATTRIBUTION]: [SUB_APPS.attribution],
  [CATEGORIES.CRM_INSIGHTS]: [SUB_APPS.contactInsights],
  [CATEGORIES.CHANNEL_PERFORMANCE]: [SUB_APPS.ads, SUB_APPS.leadCapture, SUB_APPS.marketingEmails, SUB_APPS.sms, SUB_APPS.cta],
  [CATEGORIES.WEB_TRAFFIC_ANALYSIS]: [SUB_APPS.sources, SUB_APPS.pages, SUB_APPS.geolocation, SUB_APPS.utmParameters, SUB_APPS.browsers, SUB_APPS.deviceTypes],
  [CATEGORIES.MULTI_TOUCH_ATTRIBUTION_REVENUE_IMPACT]: [SUB_APPS.marketingRevenueImpact, SUB_APPS.adImpact, SUB_APPS.blogImpact, SUB_APPS.formImpact, SUB_APPS.landingPageImpact, SUB_APPS.marketingSources],
  [CATEGORIES.ADVANCED_MARKETING_REPORTING]: [SUB_APPS.customerJourneyAnalytics]
};