import Raven from 'raven-js';
import PortalIdParser from 'PortalIdParser';
import get from 'transmute/get';
import isEmpty from 'transmute/isEmpty';
import isNull from 'transmute/isNull';
import { LoggingContext } from './logging-context';
import { DEBUG, debugSentryCapture } from './sentry-debug';
import { getEventId } from './sentry-event';
import { getEventById, hasEventById } from './sentry-event-data';
const EMPTY_CONTEXT = LoggingContext();
const createReason = (eventId, context = EMPTY_CONTEXT) => {
  const event = getEventById(eventId);
  const name = get('name')(event);
  const error = get('error')(context);
  return error || name || eventId;
};
const combineCaptureData = (event, context) => Object.assign({
  eventId: getEventId(event)
}, context.toObject(), event.toObject());
const createTags = captureData => {
  const {
    id,
    name,
    description,
    owner,
    tags
  } = captureData;
  return Object.assign({
    'report-management': id,
    name,
    description,
    owner
  }, tags);
};
const createMetadata = (eventId, context = EMPTY_CONTEXT) => {
  const event = getEventById(eventId);
  const captureData = combineCaptureData(event, context);
  const level = get('level', captureData);
  const extra = !isEmpty(get('extra', captureData)) ? get('extra', captureData) : {};
  const tags = createTags(captureData);
  const fingerprint = get('fingerprint', captureData);
  return Object.assign({
    level
  }, {
    extra: Object.assign({}, extra, {
      portalId: PortalIdParser.get()
    })
  }, {
    tags
  }, isNull(fingerprint) ? {} : {
    fingerprint
  });
};

/**
 * See https://docs.sentry.io/clients/javascript/usage/ to understand the params
 * in a Raven capture.
 *
 * @param {String} id - sentry event id from `sentry-event-id`
 * @param {Object} context - configurable per capture; used to create `LoggingContext`
 */
export function capture(id, context) {
  if (!hasEventById(id)) {
    console.warn(`Sentry: attempted to capture untracked event (id: ${id}). Capture failed; nothing is sent.`);
    return;
  }
  if (DEBUG) {
    debugSentryCapture(id);
  }
  const loggingContext = LoggingContext(context);
  const reason = createReason(id, loggingContext);
  const metadata = createMetadata(id, loggingContext);
  Raven.captureException(reason, metadata);
}