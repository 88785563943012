'use es6';

import memoize from 'transmute/memoize';
import { useUserInfo } from 'dashboard-components/context/user-info-context';
import { hasScope } from 'dashboard-lib/public/user-info/user-info-schema';
import { TEAMS_ADVANCED_MODELING_ACCESS } from 'dashboard-lib/public/scope/scope-name';
const getter = hasScope(TEAMS_ADVANCED_MODELING_ACCESS);
const memoizedGet = memoize(getter);
export const useTeamAdvancedModelingAccess = () => {
  const userInfo = useUserInfo();
  return memoizedGet(userInfo);
};