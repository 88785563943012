'use es6';

import http from 'hub-http/clients/apiClient';
import formatEvents from '../formatters/formatEvents';
const BASE_URI = 'reports/v1/events/simple';
export const createGetEvents = ({
  httpClient
}) => () => httpClient.get(BASE_URI).then(formatEvents);
export const getEvents = createGetEvents({
  httpClient: http
});