import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["defaultNullValue", "referencedObjectType", "reportingOverwrittenNumericType", "type", "scripted", "blocklistedForFiltering", "blocklistedForAggregation", "blocklistedForGrouping", "pipeline"];
import { Map as ImmutableMap, fromJS } from 'immutable';
import { INBOUND_DB_DATA_TYPES } from '../../../constants/dataTypes';
import * as http from '../../../request/http';
//@ts-expect-error Untyped dependency
import { ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS } from '../../../crmObjects/utils';
export const fetchPropertiesToValidate = () => http.get('reporting-metadata/v1/property/validtypes');
export const fetchAlreadyValidatedProperties = () => http.get('reporting-metadata/v1/property/rolled-out-crm-objects');
export const fetchPropertiesFromRaasApi = objectTypeId => http.get(`reporting-metadata/v1/property/${objectTypeId}`);
export const fetchPropertyGroupsFromRaasApi = objectTypeId => http.get(`reporting-metadata/v1/property/groups/${objectTypeId}`);
const formatPropertyGroupsForValidation = propertyGroups => propertyGroups.map(propertyGroup => {
  const propertyNames = propertyGroup.get('properties').map(property => property.get('name')).toList();
  return propertyGroup.set('propertyNames', propertyNames).delete('properties');
});
export const validatePropertyGroupsViaRaasAPI = (objectTypeId, dataType, fePropertyGroups, bePropertyGroups, locale, langEnabled) => http.post(`reporting-metadata/v1/property/validation/groups`, {
  data: {
    hardCodedPropertyMetadataGroups: formatPropertyGroupsForValidation(fePropertyGroups),
    metadataStorePropertyMetadataGroups: formatPropertyGroupsForValidation(bePropertyGroups),
    objectTypeId,
    dataType,
    locale,
    langEnabled
  }
}).then(response => response.get('totalInvalidPropertyGroups') === 0);
export const validatePropertiesViaRaasAPI = (objectTypeId, dataType, feProperties, beProperties, locale, langEnabled) => http.post(`reporting-metadata/v1/property/validation`, {
  data: {
    hardCodedPropertyMetadata: feProperties,
    metadataStorePropertyMetadata: beProperties,
    objectTypeId,
    dataType,
    locale,
    langEnabled
  }
}).then(response => response.get('totalInvalidProperties') === 0);
export const formatPropertiesForValidation = propertiesObject => propertiesObject.filter((_, key) => !!key).map(val => {
  const _val$toJS = val.toJS(),
    {
      defaultNullValue,
      referencedObjectType,
      reportingOverwrittenNumericType,
      type,
      scripted,
      blocklistedForFiltering,
      blocklistedForAggregation,
      blocklistedForGrouping,
      pipeline
    } = _val$toJS,
    standardPropValues = _objectWithoutPropertiesLoose(_val$toJS, _excluded);
  return ImmutableMap({
    property: fromJS(standardPropValues),
    defaultNullValue,
    referencedObjectType,
    reportingOverwrittenNumericType,
    type,
    scripted,
    blocklistedForFiltering,
    blocklistedForAggregation,
    blocklistedForGrouping,
    pipeline
  });
}).toMap();
export const isSupportedByReportingApi = dataType => INBOUND_DB_DATA_TYPES.includes(dataType) || ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS.includes(dataType);
export const __TESTABLE__ = {
  formatPropertyGroupsForValidation
};