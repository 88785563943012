import { List } from 'immutable';
import { DatePartTypes, Encoding, Transform, TransformTypes } from '../schema/column-records';
import { ChannelNames, VisualTypes } from '../schema/visual-records';
import { createColumnAlias, createDefaultDimension } from './column-utils';
import { decodeChannel, encodeChannel, getBreakdownChannel, supportsCompareChannel } from './visual-utils';
import { getExpressionFieldFromColumn } from './report-utils';
const defaultDimensionDatePartArgument = DatePartTypes.QUARTER;
export const RELATIVE_COMPARISON_UNITS = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR'
};
export const TIME_DIRECTIONS = {
  NEXT: 'NEXT',
  THIS: 'THIS',
  LAST: 'LAST',
  THIS_SO_FAR: 'THIS_SO_FAR'
};
export const FILTER_OPERATOR_TO_DIRECTION = {
  IN_THIS_TIME_UNIT: TIME_DIRECTIONS.THIS,
  IN_THIS_TIME_UNIT_SO_FAR: TIME_DIRECTIONS.THIS_SO_FAR,
  IN_LAST_TIME_UNIT: TIME_DIRECTIONS.LAST,
  IN_NEXT_TIME_UNIT: TIME_DIRECTIONS.NEXT
};
export const DEFAULT_RELATIVE_CONFIG = {
  timeUnit: RELATIVE_COMPARISON_UNITS.YEAR,
  firstTimeUnitCount: 1,
  firstTimeDirection: TIME_DIRECTIONS.THIS,
  secondTimeDirection: TIME_DIRECTIONS.LAST,
  secondTimeUnitCount: 1,
  useFiscalYear: false
};
export const reportHasDateComparisonFilter = report => report.dateComparisonFilter ? report.dateComparisonFilter.filters.size > 0 : false;
export const getDateComparisonField = report => {
  if (reportHasDateComparisonFilter(report)) {
    // @ts-expect-error reportHasDateComparisonFilter checks if filters is defined
    return report.dateComparisonFilter.filters.get(0).field;
  }
  return undefined;
};
const getDateComparisonUseFiscalYear = report => {
  if (reportHasDateComparisonFilter(report)) {
    // @ts-expect-error reportHasDateComparisonFilter checks if filters is defined
    return report.dateComparisonFilter.filters.get(0).filter.getIn(['operation', 'useFiscalYear'], undefined);
  }
  return false;
};
const getDateComparisonPeriod = report => {
  if (reportHasDateComparisonFilter(report)) {
    // @ts-expect-error reportHasDateComparisonFilter checks if filters is defined
    return report.dateComparisonFilter.filters.get(0).filter.getIn(['operation', 'timeUnit']);
  }
  return undefined;
};
const reportNeedsCompareColumn = report => {
  // Checks whether a visual needs a compare channel.
  // In case of text
  const {
    visual
  } = report;
  const hasDateComparisonFilter = reportHasDateComparisonFilter(report);
  if (!visual) {
    return false;
  }
  if (supportsCompareChannel(visual.type) && hasDateComparisonFilter) {
    if (visual.type === VisualTypes.GAUGE) {
      // If the compare channel is applied, the gauge visual needs the compare channel.
      return true;
    }

    // In case of text visual, report needs the compare channel if it is different from
    // the group channel
    const groupColumnAlias = visual.getIn(['encodings', ChannelNames.GROUP, 'column']);
    const groupColumn = report.getIn(['columns', groupColumnAlias]);
    const dateComparisonField = getDateComparisonField(report);
    const dateComparisonPeriod = getDateComparisonPeriod(report);
    if (groupColumn) {
      return !groupColumn.field.equals(dateComparisonField) || groupColumn.field.equals(dateComparisonField) && groupColumn.getIn(['transform', 'arguments', '0']) !== dateComparisonPeriod;
    }
  }
  return false;
};
const getOrCreateColumnAlias = (visual, channelName) => visual.getIn(['encodings', channelName, 'column']) || createColumnAlias();
const maybeSetDatePartTransform = (column, dateComparisonField, datePartArgument) => column.field.equals(dateComparisonField) ? column.update('transform', transform => !transform || transform.type !== TransformTypes.DATE_PART ? Transform({
  type: TransformTypes.DATE_PART,
  arguments: List([datePartArgument])
}) : transform) : column;
const maybeSetDimensionalEncoding = (report, channelName, datePartArgument = defaultDimensionDatePartArgument) => {
  const {
    visual
  } = report;
  if (!visual) {
    return report;
  }
  const dimensionColumnAlias = getOrCreateColumnAlias(visual, channelName);
  const dateComparisonField = getDateComparisonField(report);
  if (!dateComparisonField) {
    return report;
  }
  const useFiscalYear = getDateComparisonUseFiscalYear(report);
  const dimensionalColumn = maybeSetDatePartTransform(report.getIn(['columns', dimensionColumnAlias]) || createDefaultDimension(dateComparisonField).set('alias', dimensionColumnAlias), dateComparisonField, datePartArgument).update('useFiscalYear', nextUseFiscalYear => useFiscalYear != null ? useFiscalYear : nextUseFiscalYear);
  const expressionField = getExpressionFieldFromColumn(report, dimensionalColumn);
  return report.update('columns', nextColumns => nextColumns.set(dimensionColumnAlias, dimensionalColumn)).updateIn(['visual', 'encodings'], encodings => encodeChannel({
    encodings,
    channelName,
    column: dimensionalColumn,
    expressionField
  }));
};
const getBreakdownColumn = (report, breakdownChannelName, dateComparisonField) => {
  const {
    visual
  } = report;
  if (!visual) {
    return undefined;
  }
  const breakdownColumnAlias = getOrCreateColumnAlias(visual, breakdownChannelName);
  const useFiscalYear = getDateComparisonUseFiscalYear(report);
  const column = report.getIn(['columns', breakdownColumnAlias]) || createDefaultDimension(dateComparisonField);
  const maybeDatePartTransform = getDateComparisonPeriod(report);
  const nextColumn = column.set('field', dateComparisonField).set('alias', breakdownColumnAlias).update('useFiscalYear', nextUseFiscalYear => useFiscalYear != null ? useFiscalYear : nextUseFiscalYear);
  if (!maybeDatePartTransform) {
    return nextColumn;
  }
  return maybeSetDatePartTransform(nextColumn, dateComparisonField, maybeDatePartTransform);
};
const setBreakdownEncoding = (report, channelName) => {
  const dateComparisonField = getDateComparisonField(report);
  if (!dateComparisonField) {
    return report;
  }
  const breakdownColumn = getBreakdownColumn(report, channelName, dateComparisonField);
  if (!breakdownColumn) {
    return report;
  }
  const {
    alias: breakdownColumnAlias
  } = breakdownColumn;
  const maybeStagedBreakdownColumn = report.hasIn(['columns', breakdownColumnAlias]) && !report.getIn(['columns', breakdownColumnAlias, 'field']).equals(dateComparisonField) && report.getIn(['columns', breakdownColumnAlias]);
  const expressionField = getExpressionFieldFromColumn(report, breakdownColumn);
  return report.update('columns', nextColumns => nextColumns.set(breakdownColumnAlias, breakdownColumn)).updateIn(['visual', 'encodings'], nextEncodings => encodeChannel({
    encodings: nextEncodings,
    channelName,
    column: breakdownColumn,
    expressionField
  })).update('stagedColumns', stagedColumns => maybeStagedBreakdownColumn ? stagedColumns.push(maybeStagedBreakdownColumn) : stagedColumns).update('stagedEncodings', stagedEncodings => {
    return maybeStagedBreakdownColumn ? stagedEncodings.push(Encoding({
      column: maybeStagedBreakdownColumn.alias
    })) : stagedEncodings;
  });
};
const maybeSetDimensionalDateSettings = (report, channelName) => {
  const dateComparisonField = getDateComparisonField(report);
  const {
    visual
  } = report;
  if (!visual || !dateComparisonField) {
    return report;
  }
  const dimensionColumnAlias = visual.getIn(['encodings', channelName, 'column']);
  if (!dimensionColumnAlias) {
    return report;
  }
  const {
    columns
  } = report;
  if (!columns.has(dimensionColumnAlias)) {
    return report;
  }
  const dimensionColumn = columns.get(dimensionColumnAlias);
  if (!dimensionColumn.field.equals(dateComparisonField)) {
    return report;
  }
  let nextColumn = dimensionColumn;
  if (channelName === ChannelNames.GROUP || !(dimensionColumn.transform && dimensionColumn.transform.type)) {
    nextColumn = maybeSetDatePartTransform(nextColumn, dateComparisonField, defaultDimensionDatePartArgument);
  }
  const useFiscalYear = getDateComparisonUseFiscalYear(report);
  if (dimensionColumn.useFiscalYear !== useFiscalYear && useFiscalYear != null) {
    nextColumn = nextColumn.set('useFiscalYear', useFiscalYear);
  }
  return report.setIn(['columns', dimensionColumnAlias], nextColumn);
};
export const setInitialDateComparisonEncodings = report => {
  const {
    visual
  } = report;
  if (!visual) {
    return report;
  }
  const visualType = visual.type;
  const breakdownChannelName = getBreakdownChannel(visualType);
  if (visualType === VisualTypes.TEXT) {
    return maybeSetDimensionalEncoding(report, breakdownChannelName);
  } else if (visualType === VisualTypes.GAUGE) {
    return maybeSetDimensionalEncoding(report, breakdownChannelName, DEFAULT_RELATIVE_CONFIG.timeUnit);
  } else {
    const dateComparisonField = getDateComparisonField(report);
    if (!dateComparisonField) {
      return report;
    }
    const breakdownColumn = getBreakdownColumn(report, breakdownChannelName, dateComparisonField);
    if (!breakdownColumn) {
      return report;
    }
    const nextReport = setBreakdownEncoding(report, ChannelNames.COLOR);
    const dimensionalChannel = visualType === VisualTypes.HORIZONTAL_BAR ? ChannelNames.Y : ChannelNames.X;
    return maybeSetDimensionalEncoding(nextReport, dimensionalChannel);
  }
};
export const applyRequiredDateComparisonEncodings = report => {
  const hasDateComparisonFilter = reportHasDateComparisonFilter(report);
  if (hasDateComparisonFilter) {
    const visual = report.visual;
    if (!visual) {
      return report;
    }
    const visualType = visual.type;
    const breakdownChannelName = getBreakdownChannel(visualType);
    if (visualType === VisualTypes.TEXT || visualType === VisualTypes.GAUGE) {
      return maybeSetDimensionalDateSettings(report, breakdownChannelName);
    } else {
      const dimensionalChannel = visualType === VisualTypes.HORIZONTAL_BAR ? ChannelNames.Y : ChannelNames.X;
      const dateComparisonField = getDateComparisonField(report);
      if (!dateComparisonField) {
        return report;
      }
      const breakdownColumn = getBreakdownColumn(report, breakdownChannelName, dateComparisonField);
      if (!breakdownColumn) {
        return report;
      }
      const nextReport = maybeSetDimensionalDateSettings(report, dimensionalChannel);
      return setBreakdownEncoding(nextReport, ChannelNames.COLOR);
    }
  }
  return report;
};
export const applyCompareColumn = report => {
  const {
    visual
  } = report;
  if (!visual) {
    return report;
  }
  const hasCompareColumn = visual.hasIn(['encodings', ChannelNames.COMPARE, 'column']);
  const needsCompareColumn = reportNeedsCompareColumn(report);
  if (hasCompareColumn && !needsCompareColumn) {
    const compareColumnAlias = visual.getIn(['encodings', ChannelNames.COMPARE, 'column']);
    if (!compareColumnAlias) {
      return report;
    }
    return report.updateIn(['visual', 'encodings'], encodings => decodeChannel(encodings, ChannelNames.COMPARE)).update('columns', columns => columns.remove(compareColumnAlias));
  } else if (supportsCompareChannel(visual.type) && needsCompareColumn) {
    const dateComparisonField = getDateComparisonField(report);
    if (!dateComparisonField) {
      return report;
    }
    const nextReport = report.updateIn(['visual', 'options'], options => options.set('showPercentChangeComparison', true));
    return setBreakdownEncoding(nextReport, ChannelNames.COMPARE);
  }
  return report;
};
export const dateComparisonFilterToState = dateComparisonFilter => {
  if (!dateComparisonFilter || !dateComparisonFilter.filters || dateComparisonFilter.filters.isEmpty()) {
    return Object.assign({}, DEFAULT_RELATIVE_CONFIG);
  }
  const timeUnit = dateComparisonFilter.filters.getIn([0, 'filter', 'operation', 'timeUnit']);
  const firstTimeUnitCount = dateComparisonFilter.filters.getIn([0, 'filter', 'operation', 'timeUnitCount']);
  const firstTimeOperator = dateComparisonFilter.filters.getIn([0, 'filter', 'operation', 'operator']);

  // @ts-expect-error `filter` typed as any
  const firstTimeDirection = FILTER_OPERATOR_TO_DIRECTION[firstTimeOperator];
  const useFiscalYear = dateComparisonFilter.filters.getIn([0, 'filter', 'operation', 'useFiscalYear']);
  const secondTimeUnitCount = dateComparisonFilter.filters.getIn([1, 'filter', 'operation', 'timeUnitCount']);
  const secondTimeOperator = dateComparisonFilter.filters.getIn([1, 'filter', 'operation', 'operator']);

  // @ts-expect-error `filter` typed as any
  const secondTimeDirection = FILTER_OPERATOR_TO_DIRECTION[secondTimeOperator];
  return {
    timeUnit,
    useFiscalYear,
    firstTimeDirection,
    firstTimeUnitCount,
    secondTimeDirection,
    secondTimeUnitCount
  };
};