export const ANALYTICS_ALL_PAGES = 'ANALYTICS_ALL_PAGES';
export const ANALYTICS_ALL_PAGES_BY_COMPANY = 'ANALYTICS_ALL_PAGES_BY_COMPANY';
export const ANALYTICS_ALL_PAGES_SOURCES = 'ANALYTICS_ALL_PAGES_SOURCES';
export const ANALYTICS_BLOG_POSTS = 'ANALYTICS_BLOG_POSTS';
export const ANALYTICS_BOTS_CHATS = 'ANALYTICS_BOTS_CHATS';
export const ANALYTICS_BOTS_MODULES = 'ANALYTICS_BOTS_MODULES';
export const ANALYTICS_BROWSER_DETAILS = 'ANALYTICS_BROWSER_DETAILS';
export const ANALYTICS_CAMPAIGNS = 'ANALYTICS_CAMPAIGNS';
export const ANALYTICS_CONTENT_SEARCH = 'ANALYTICS_CONTENT_SEARCH';
export const ANALYTICS_CTA_PLACEMENTS = 'ANALYTICS_CTA_PLACEMENTS';
export const ANALYTICS_DEVICE_TYPES = 'ANALYTICS_DEVICE_TYPES';
export const ANALYTICS_DEVICE_TYPE_DETAILS = 'ANALYTICS_DEVICE_TYPE_DETAILS';
export const ANALYTICS_BEHAVIORAL_EVENTS = 'ANALYTICS_BEHAVIORAL_EVENTS';
export const ANALYTICS_EVENT_COMPLETIONS = 'ANALYTICS_EVENT_COMPLETIONS';
export const ANALYTICS_EVENT_PROPERTIES = 'ANALYTICS_EVENT_PROPERTIES';
export const ANALYTICS_FORMS = 'ANALYTICS_FORMS';
export const ANALYTICS_FORMS_SOURCES = 'ANALYTICS_FORMS_SOURCES';
export const ANALYTICS_FORMS_URLS = 'ANALYTICS_FORMS_URLS';
export const ANALYTICS_GEOLOCATION = 'ANALYTICS_GEOLOCATION';
export const ANALYTICS_KNOWLEDGE_ARTICLES = 'ANALYTICS_KNOWLEDGE_ARTICLES';
export const ANALYTICS_KNOWLEDGE_ARTICLES_SOURCES = 'ANALYTICS_KNOWLEDGE_ARTICLES_SOURCES';
export const ANALYTICS_LANDING_PAGES = 'ANALYTICS_LANDING_PAGES';
export const ANALYTICS_PATHS = 'ANALYTICS_PATHS';
export const ANALYTICS_SALES_DOCUMENTS = 'ANALYTICS_SALES_DOCUMENTS';
export const ANALYTICS_SALES_TEMPLATES = 'ANALYTICS_SALES_TEMPLATES';
export const ANALYTICS_SEQUENCE_ENROLLMENT_ACTIVITIES = 'ANALYTICS_SEQUENCE_ENROLLMENT_ACTIVITIES';
export const ANALYTICS_SOCIAL = 'ANALYTICS_SOCIAL';
export const ANALYTICS_SOCIAL_ENGAGEMENT = 'ANALYTICS_SOCIAL_ENGAGEMENT';
export const ANALYTICS_SOURCES = 'ANALYTICS_SOURCES';
export const ANALYTICS_STANDARD_PAGES = 'ANALYTICS_STANDARD_PAGES';
export const ANALYTICS_TARGETED_CONTENT = 'ANALYTICS_TARGETED_CONTENT';
export const ANALYTICS_TARGETED_CONTENT_DEFINITIONS = 'ANALYTICS_TARGETED_CONTENT_DEFINITIONS';
export const ANALYTICS_TOPICS = 'ANALYTICS_TOPICS';
export const ANALYTICS_UTM_CAMPAIGNS = 'ANALYTICS_UTM_CAMPAIGNS';
export const ANALYTICS_UTM_CONTENTS = 'ANALYTICS_UTM_CONTENTS';
export const ANALYTICS_UTM_MEDIUMS = 'ANALYTICS_UTM_MEDIUMS';
export const ANALYTICS_UTM_SOURCES = 'ANALYTICS_UTM_SOURCES';
export const ANALYTICS_UTM_TERMS = 'ANALYTICS_UTM_TERMS';
export const ANALYTICS_VIDEOS = 'ANALYTICS_VIDEOS';
export const ANALYTICS_VIDEOS_VIEWERSHIP = 'ANALYTICS_VIDEOS_VIEWERSHIP';
export const SOCIAL_CHANNELS = 'SOCIAL_CHANNELS';
export const UNIFIED_ADS_ACCOUNTS = 'UNIFIED_ADS_ACCOUNTS';
export const UNIFIED_ADS_CAMPAIGNS = 'UNIFIED_ADS_CAMPAIGNS';
export const UNIFIED_ADS_NETWORKS = 'UNIFIED_ADS_NETWORKS';
export const UNIFIED_FEEDBACK_EMAIL = 'UNIFIED_FEEDBACK_EMAIL';
export const UNIFIED_FEEDBACK_TRENDS = 'UNIFIED_FEEDBACK_TRENDS';
export const UNIFIED_FEEDBACK_WEB = 'UNIFIED_FEEDBACK_WEB';
export const UNIFIED_SEARCH_ANALYTICS_PAGES = 'UNIFIED_SEARCH_ANALYTICS_PAGES';
export const UNIFIED_SEARCH_ANALYTICS_QUERIES = 'UNIFIED_SEARCH_ANALYTICS_QUERIES';
export const SEQUENCE_SENDER_SCORE = 'SEQUENCE_SENDER_SCORE';

/**
 * IMPORTANT, make sure to also update this list when adding a new type in this file
 */
export const UNIFIED_DATA_TYPES = [ANALYTICS_ALL_PAGES, ANALYTICS_ALL_PAGES_BY_COMPANY, ANALYTICS_ALL_PAGES_SOURCES, ANALYTICS_BLOG_POSTS, ANALYTICS_BOTS_CHATS, ANALYTICS_BOTS_MODULES, ANALYTICS_BROWSER_DETAILS, ANALYTICS_CAMPAIGNS, ANALYTICS_CONTENT_SEARCH, ANALYTICS_CTA_PLACEMENTS, ANALYTICS_DEVICE_TYPES, ANALYTICS_DEVICE_TYPE_DETAILS, ANALYTICS_BEHAVIORAL_EVENTS, ANALYTICS_EVENT_COMPLETIONS, ANALYTICS_EVENT_PROPERTIES, ANALYTICS_FORMS, ANALYTICS_FORMS_SOURCES, ANALYTICS_FORMS_URLS, ANALYTICS_GEOLOCATION, ANALYTICS_KNOWLEDGE_ARTICLES, ANALYTICS_KNOWLEDGE_ARTICLES_SOURCES, ANALYTICS_KNOWLEDGE_ARTICLES_SOURCES, ANALYTICS_LANDING_PAGES, ANALYTICS_PATHS, ANALYTICS_SALES_DOCUMENTS, ANALYTICS_SALES_TEMPLATES, ANALYTICS_SEQUENCE_ENROLLMENT_ACTIVITIES, ANALYTICS_SEQUENCE_ENROLLMENT_ACTIVITIES, ANALYTICS_SOCIAL, ANALYTICS_SOCIAL_ENGAGEMENT, ANALYTICS_SOURCES, ANALYTICS_STANDARD_PAGES, ANALYTICS_TARGETED_CONTENT, ANALYTICS_TARGETED_CONTENT_DEFINITIONS, ANALYTICS_TARGETED_CONTENT_DEFINITIONS, ANALYTICS_TOPICS, ANALYTICS_UTM_CAMPAIGNS, ANALYTICS_UTM_CONTENTS, ANALYTICS_UTM_MEDIUMS, ANALYTICS_UTM_SOURCES, ANALYTICS_UTM_TERMS, ANALYTICS_VIDEOS, ANALYTICS_VIDEOS_VIEWERSHIP, SOCIAL_CHANNELS, UNIFIED_ADS_ACCOUNTS, UNIFIED_ADS_CAMPAIGNS, UNIFIED_ADS_NETWORKS, UNIFIED_FEEDBACK_EMAIL, UNIFIED_FEEDBACK_TRENDS, UNIFIED_FEEDBACK_WEB, UNIFIED_SEARCH_ANALYTICS_PAGES, UNIFIED_SEARCH_ANALYTICS_QUERIES, SEQUENCE_SENDER_SCORE];