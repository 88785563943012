// @ts-expect-error migrate upstream
import { hasSufficientDashboardEditPermission } from 'reporting-action-components/lib/dashboardLogic';
// @ts-expect-error migrate upstream
import { useHasReportingEditAccess } from 'reporting-action-components/access/scopes/hooks/useHasScope';
import { useSavedDashboardFiltersPanelContext } from '../context';
export const useCanEditFilterSets = () => {
  const {
    dashboard
  } = useSavedDashboardFiltersPanelContext();
  const canEditDashboard = hasSufficientDashboardEditPermission(dashboard);
  const canEditReport = useHasReportingEditAccess();
  return canEditDashboard && canEditReport;
};