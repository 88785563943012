/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */
/* eslint-disable prefer-promise-reject-errors */

const quickFetch = window.quickFetch;
if (!window.quickFetch) {
  console.warn('window.quickFetch is not included');
}
export function withQuickFetch(key, fetch, matcher = () => true) {
  return (...args) => {
    if (!matcher(...args)) {
      return fetch(...args);
    }
    if (!quickFetch) {
      return fetch(...args);
    }
    const earlyRequest = quickFetch.getRequestStateByName(key);
    if (!earlyRequest) {
      return fetch(...args);
    }
    return new Promise((resolve, reject) => {
      earlyRequest.whenFinished(request => {
        resolve(request);
        quickFetch.removeEarlyRequest(key);
      });
      earlyRequest.onError(xhr => {
        if (xhr.status !== 401) {
          fetch(...args).then(resolve, reject);
        } else {
          reject();
        }
        quickFetch.removeEarlyRequest(key);
      });
    });
  };
}