import I18n from 'I18n';
import Raven from 'raven-js';
import userInfo from 'hub-http/userInfo';
import lang from 'i2l?query=sporks!../lang/en.lyaml';
import ContentRoutes from 'ContentUtils/Routes';
import { initAppTranslations } from 'I18n/init/setup/AppInit';
import registerAllData from 'I18n/init/register/sync/registerAllData';
export function setupRaven(user) {
  Raven.setUserContext({
    id: user.user_id,
    email: user.email,
    username: user.username
  });
}
export function startMonitoring() {
  I18n.sendSentryOnMissingTranslations = true;
  I18n.sendSentryOnMissingValues = true;
}
export function initialize(i18nProvider) {
  const userInfoPromise = userInfo(); // can't convert Promise<UserInfo> to Promise<void>

  const langAvailablePromise = initAppTranslations([i18nProvider.register(lang), registerAllData(i18nProvider)]);
  const authenticationPromiseChain = userInfoPromise.then(({
    gates,
    user,
    portal
  }) => {
    i18nProvider.setLocale({
      locale: user.locale,
      langEnabled: user.lang_enabled,
      timezone: portal.timezone
    });
    setupRaven(user);
    ContentRoutes.init(gates);
  }).catch(error => {
    Raven.captureException(error, {
      extra: {
        reason: 'unhandled promise rejection GRI-304'
      }
    });
  });
  return Promise.all([authenticationPromiseChain, langAvailablePromise]);
}