'use es6';

import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { getAllSavedFilters, createGetAllSavedFilters } from 'reference-resolvers/api/SavedFiltersAPI';
import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
export const createCompaniesSavedFiltersReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.COMPANIES_SAVED_FILTERS,
  createFetchData: opts => createGetAllSavedFilters(opts)('companies'),
  fetchData: getAllSavedFilters('companies')
}, options));
export default createCompaniesSavedFiltersReferenceResolver();