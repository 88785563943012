'use es6';

import { createSelector } from 'reselect';
import { AssetPermission } from '../../permission/asset-permission';
import { selectHasCreateAndOwnScope, selectHasEditScope, selectHasReportingAccess, selectIsUserAdmin } from '../scopes';
export const selectUserMaxAssetPermission = createSelector(selectHasCreateAndOwnScope, selectHasEditScope, selectHasReportingAccess, selectIsUserAdmin, (canCreateAndOwn, canEdit, canAccessReporting, isAdmin) => {
  // Note: this order means admin takes precedence over "no reporting access"
  if (isAdmin) {
    return AssetPermission.COMPLETE;
  }
  if (!canAccessReporting) {
    return AssetPermission.ZERO;
  }
  if (canCreateAndOwn) {
    return AssetPermission.CREATE_AND_OWN;
  }
  if (canEdit) {
    return AssetPermission.EDIT;
  }
  return AssetPermission.VIEW;
});