'use es6';

import http from 'hub-http/clients/apiClient';
import { fromJS } from 'immutable';
const getUpdateQuickFilterGroupsUrl = dashboardId => `dashboard/v2/dashboard/${dashboardId}/pinned-properties-groups`;
const generateQuickFilterGroupsUrl = dashboardId => `dashboard/v2/dashboard/${dashboardId}/quick-filter-templates`;
export const updateQuickFilterGroups = (dashboardId, quickFiltersGroups) => {
  return http.put(getUpdateQuickFilterGroupsUrl(dashboardId), {
    data: quickFiltersGroups
  }).then(fromJS);
};
export const fetchGeneratedQuickFilterGroups = (dashboardId, generateQuickFilterGroupsOptions) => {
  return http.get(generateQuickFilterGroupsUrl(dashboardId), {
    query: {
      quickFilterTemplate: generateQuickFilterGroupsOptions
    }
  }).then(response => response);
};