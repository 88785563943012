'use es6';

import { ENGAGEMENT_PROPERTIES } from 'reference-resolvers/constants/CacheKeys';
import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
import { getEngagementProperties, createGetEngagementProperties } from 'reference-resolvers/api/EngagementPropertyAPI';
export const createEngagementPropertyReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: ENGAGEMENT_PROPERTIES,
  createFetchData: createGetEngagementProperties,
  fetchData: getEngagementProperties
}, options));
export default createEngagementPropertyReferenceResolver();