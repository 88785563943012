import get from 'transmute/get';
import { isList } from 'reporting-snowflake/shared/lib/utility-types';
import { buildUnitializedAsyncData } from 'reporting-data/asyncData/AsyncData';
export const getAppliedFiltersByReportFromDashboard = dashboard => {
  const hasAppliedFilters = !!get('appliedFilters', dashboard);
  if (hasAppliedFilters) {
    const appliedFilters = get('appliedFilters', dashboard);
    return isList(appliedFilters) ? appliedFilters.toJS() : appliedFilters;
  }
  return [];
};
export const setAsyncState = (state, dashboardId, nextAsyncState = buildUnitializedAsyncData()) => {
  if (!(dashboardId in state.dashboardReports)) {
    state.dashboardReports[dashboardId] = {
      reports: {},
      appliedFiltersByReport: [],
      asyncState: nextAsyncState
    };
  } else {
    state.dashboardReports[dashboardId].asyncState = nextAsyncState;
  }
};
export const resetAsyncStateOnFiltersChanged = (state, action) => {
  const {
    payload: {
      dashboardId
    }
  } = action;
  setAsyncState(state, dashboardId);
  state.validateFilterApplicationAsyncState[dashboardId] = buildUnitializedAsyncData();
};