import { mapValues } from '../../utils';
import { UNDEFINED_CONTEXT } from '../context/context';
import { MISSING_EXPRESSION } from '../expression/expression';
import { getExpressionFields } from '../expression/expression-utils';
import { UNDEFINED_TYPE } from '../type/type';
import { isField } from './environment';
import { FUNCTIONS, OPERATORS, SCOPE } from './environment-constants';
// Initializes a field from an input or existing field
export const initializeField = field => {
  if (!field.name) {
    throw new Error('Field name is required to initialize an environment field');
  }
  return {
    name: field.name,
    input: field.input,
    expression: MISSING_EXPRESSION,
    type: UNDEFINED_TYPE,
    context: UNDEFINED_CONTEXT,
    valid: false,
    circular: false,
    violations: {
      expression: [],
      type: [],
      context: []
    }
  };
};

// Initializes an environment, fresh will re-initialize all existing fields
export const initializeEnvironment = (uninitializedEnvironment, fresh = true) => {
  const initializedFields = mapValues(uninitializedEnvironment.fields, (draftField, draftFieldName) => {
    if (!draftField.name) {
      draftField.name = draftFieldName;
    }
    if (draftFieldName !== draftField.name) {
      throw new Error(`Indexed field name "${draftFieldName}" has mismatching name "${draftField.name}". Did you mean to remap the environment names instead?`);
    }
    return fresh || !isField(draftField) ? initializeField(draftField) : draftField;
  });
  return {
    scope: uninitializedEnvironment.scope || {},
    operators: uninitializedEnvironment.operators || {},
    functions: uninitializedEnvironment.functions || {},
    properties: uninitializedEnvironment.properties || {},
    fields: initializedFields,
    violations: fresh ? [] : uninitializedEnvironment.violations || []
  };
};
export const withStaticEnvironment = environment => Object.assign({
  scope: SCOPE,
  operators: OPERATORS,
  functions: FUNCTIONS,
  violations: []
}, environment);
export const environmentToGraph = environment => Object.values(environment.fields).reduce((graph, field) => Object.assign({}, graph, {
  [field.name]: field.expression ? getExpressionFields(field.expression).map(exp => exp.name).filter(name => environment.fields[name]) : []
}), {});