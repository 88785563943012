import { createExternalOptionsReferenceResolver } from '../createExternalOptionsReferenceResolver';
import fastStableStringify from 'fast-json-stable-stringify';
const cache = {};
const toCacheKey = (referenceType, objectTypeId, options) => `${referenceType}-${objectTypeId || 'default'}-${options.propertyName || 'default'}-${Boolean(options.byIdIncludeDeleted)}-${Boolean(options.searchIncludeDeleted)}-${fastStableStringify(options.externalOptionsMetaData)}`;

/**
 * Returns a cached reference resolver for the given referenceType, objectTypeId, optional property name, and optional
 * externalOptionsMetaData object.
 *
 * If externalOptionsMetaData is not passed, it will be looked up from framework-data-schema-resolvers based on the property name.
 *
 * If the same set of params have been used before, the existing resolver will be returned from the cache.
 *
 * @param {string} referenceType a `ReferenceTypes` constant
 * @param {string} objectTypeId (optional) objectTypeId to pass to the resolver
 * @param {Object} options.httpClient (optional) httpClient to forward to the resolver
 * @param {string} options.propertyName (optional) Property for which we're resolving options (if relevant)
 * @param {Object} options.externalOptionsMetaData (optional) Object (generally from the property definition) that modifies
 * the response from XO.
 *
 * NOTE: the `httpClient` option only applies the first time a resolver is requested for
 * a `objectTypeId/referenceType` pair. Subsequent calls will return the cached
 * resolver instead.
 *
 * @returns a `ReferenceResolver`
 */
export const createStandardCachedResolver = (referenceType, objectTypeId, options = {}) => {
  const {
    httpClient,
    byIdIncludeDeleted,
    searchIncludeDeleted,
    propertyName,
    externalOptionsMetaData
  } = options;
  const cacheKey = toCacheKey(referenceType, objectTypeId, options);
  if (!cache[cacheKey]) {
    cache[cacheKey] = createExternalOptionsReferenceResolver({
      objectTypeId,
      propertyName,
      referenceType,
      httpClient,
      byIdFetchOptions: typeof byIdIncludeDeleted === 'boolean' ? {
        includeDeleted: byIdIncludeDeleted
      } : undefined,
      searchFetchOptions: typeof searchIncludeDeleted === 'boolean' ? {
        includeDeleted: searchIncludeDeleted
      } : undefined,
      externalOptionsMetaData
    });
  }
  return cache[cacheKey];
};

// exported for unit test usage only
export const _resetCache = () => {
  for (const key in cache) {
    if (Object.prototype.hasOwnProperty.call(cache, key)) {
      delete cache[key];
    }
  }
};