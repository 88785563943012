/* eslint-disable no-console */
import { fromJS as _fromJS, List, Map as ImmutableMap, OrderedMap, Record, Set as ImmutableSet, Iterable } from 'immutable';
import fromJSOrdered from './fromJSOrdered';
import * as overrides from './overrides';
const isLoggingEnabled = () => {
  try {
    return overrides.checkedRecordLogging.enabled;
  } catch (e) {
    return false;
  }
};
const logger = {
  warn: (stack, title, ...messages) => {
    if (!isLoggingEnabled()) {
      return;
    }
    console.groupCollapsed(`Warning: ${title}`);
    console.warn(); // for test suite
    messages.forEach(message => typeof message === 'function' ? message() : console.log(message));
    console.log(`%c${stack}`, 'color: red');
    console.groupEnd();
  },
  error: (stack, title, ...messages) => {
    if (!isLoggingEnabled()) {
      return;
    }
    console.groupCollapsed(`Error: ${title}`);
    console.error(); // for test suite
    messages.forEach(message => typeof message === 'function' ? message() : console.log(message));
    console.log(`%c${stack}`, 'color: red');
    console.groupEnd();
  }
};
const captureStackTrace = () => {
  if (!isLoggingEnabled()) {
    return '';
  }
  return new Error('').stack || 'Stack only available in certain browsers';
};
const VALIDATE = 'validate';
const ANY = 'any';
const BOOLEAN = 'boolean';
const STRING = 'string';
const NUMBER = 'number';
const SYMBOL = 'symbol';
const FUNC = 'func';
const LIST = 'List';
const ITERABLE = 'Iterable';
const MAP = 'Map';
const RECORD = 'Record';
const POLY = 'Poly';
const type = (describe, evaluate) => {
  evaluate.describe = describe;
  return evaluate;
};
const identity = any => any;
const result = (expression, ...args) => typeof expression === 'function' ? expression(...args) : expression;
const always = (expression = identity, constant) => type(() => Object.assign({}, expression.describe(), {
  always: constant
}), (value, {
  origin = value,
  parent,
  path,
  stack = captureStackTrace()
} = {}) => {
  if (value !== constant) {
    logger.warn(stack, `Mismatching value provided to always constant {${path}}`, 'Name: always', `Path: ${path}`, 'Message: Expected constant value', () => console.log('Expected:', constant), () => console.log('Got:', value), () => console.log('Origin:', origin));
  }
  return result(expression, constant, {
    origin,
    parent,
    path,
    stack
  });
});
const required = (expression = identity) => type(() => {
  const description = expression.describe();
  delete description.optional;
  return description;
}, (value, {
  origin = value,
  parent,
  path,
  stack = captureStackTrace()
} = {}) => {
  if (value === undefined) {
    logger.warn(stack, `Missing required value at {${path}}`, 'Name: required', `Path: ${path}`, 'Message: Expected defined value', () => console.log('Value:', value), () => console.log('Origin:', origin));
  }
  return result(expression, value, {
    origin,
    parent,
    path,
    stack
  });
});
const optional = (expression = identity) => type(() => Object.assign({}, expression.describe(), {
  optional: true
}), (value, ...rest) => value === undefined ? value : result(expression, value, ...rest));
const defaultValue = (expression = identity, fallback) => type(() => Object.assign({}, expression.describe(), fallback ? {
  defaultValue: fallback
} : {}), (maybeValue = fallback, ...rest) => result(expression, maybeValue, ...rest));
const fromJS = (expression = identity) => (value, ...rest) => result(expression, _fromJS(value), ...rest);
const _fromJSOrdered = (expression = identity) => (value, ...rest) => result(expression, fromJSOrdered(value), ...rest);
const validate = (expression = identity, name = VALIDATE, message = '') => (value, {
  origin = value,
  parent,
  path = name,
  stack = captureStackTrace()
} = {}) => {
  try {
    if (!result(expression, value, {
      origin,
      parent,
      path,
      stack
    })) {
      logger.warn(stack, `Invalid value at {${path}}`, `Name: ${name}`, `Path: ${path}`, typeof message === 'function' ? message : `Message: ${message}`, () => console.log('Value:', value), () => console.log('Origin:', origin));
    }
    return value;
  } catch (validationError) {
    logger.error(stack, `Errored value at {${path}}`, `Name: ${name}`, `Path: ${path}`, typeof message === 'function' ? message : `Message: ${message}`, () => console.log('Value:', value), () => console.log('Origin:', origin), () => console.log('Validation Error:', validationError));
    throw validationError;
  }
};
const chainable = (fn, name) => {
  const pipe = next => (...args) => {
    const expression = next(...args);
    return type(expression.describe || fn.describe, chainable((value, ...rest) => expression(fn(value, ...rest), ...rest)));
  };
  const decorate = decorator => (...decorateArgs) => {
    const expression = decorator(fn, ...decorateArgs);
    return type(expression.describe || fn.describe, chainable((value, {
      origin = value,
      parent,
      path = name,
      stack = captureStackTrace()
    } = {}) => expression(value, {
      origin,
      parent,
      path,
      stack
    })));
  };
  const chainedFn = fn;
  chainedFn.validate = pipe(validate);
  chainedFn.always = decorate(always);
  chainedFn.required = decorate(required);
  chainedFn.optional = decorate(optional);
  chainedFn.defaultValue = decorate(defaultValue);
  chainedFn.fromJS = decorate(fromJS);
  chainedFn.fromJSOrdered = decorate(_fromJSOrdered);
  /* eslint-enable no-use-before-define */
  return chainedFn;
};
export const any = (name = ANY) => type(() => ({
  type: ANY,
  name,
  label: name
}), chainable(validate(true, name), name));
export const boolean = (name = BOOLEAN) => type(() => ({
  type: BOOLEAN,
  name,
  label: name
}), chainable(validate(value => typeof value === typeof true, name, 'Expected a boolean'), name).required());
export const number = (name = NUMBER) => type(() => ({
  type: NUMBER,
  name,
  label: name
}), chainable(validate(value => typeof value === 'number', name, 'Expected a number'), name).required());
export const string = (name = STRING) => type(() => ({
  type: STRING,
  name,
  label: name
}), chainable(validate(value => typeof value === 'string', name, 'Expected a string'), name).required());
export const symbol = (object, name = SYMBOL) => {
  const set = ImmutableSet(_fromJS(object).toList());
  return type(() => ({
    type: SYMBOL,
    name,
    label: name,
    set
  }), chainable(validate(value => set.contains(value), name, () => console.log('Message: Expected one of', set.toJS())), name).required());
};
export const func = (name = FUNC) => type(() => ({
  type: FUNC,
  name,
  label: name
}), chainable(validate(value => typeof value === 'function', name, 'Expected a function'), name).required());
export const list = (expression = any(), name = LIST) => type(() => {
  const valueType = expression.describe();
  return {
    type: LIST,
    name,
    label: `List<${valueType.label}>`,
    value: valueType
  };
}, chainable((values, {
  origin = values,
  parent,
  path = name,
  stack = captureStackTrace()
} = {}) => {
  try {
    return List(validate(arr => {
      if (Array.isArray(arr) || List.isList(arr)) {
        return true;
      }

      /* Validate will log it as an errored value & rethrow this error */
      throw new Error();
    }, name, 'Expected an Array or List')(values, {
      origin,
      parent,
      path,
      stack
    })).map((value, key) => result(expression, value, {
      origin,
      parent: values,
      path: `${path}[${key}]`,
      stack
    }));
  } catch (e) {
    /* Lets pass the value through, we already logged any possible errors */
    return values;
  }
}, name).required());
export const listOrIterableToList = (expression = any(), name = ITERABLE) => type(() => {
  const valueType = expression.describe();
  return {
    type: LIST,
    name,
    label: `List<${valueType.label}>`,
    value: valueType
  };
}, chainable((values, {
  origin = values,
  parent,
  path = name,
  stack = captureStackTrace()
} = {}) => {
  try {
    return List(validate(arr => {
      if (Array.isArray(arr) || Iterable.isIterable(arr)) {
        return true;
      }

      /* Validate will log it as an errored value & rethrow this error */
      throw new Error();
    }, name, 'Expected an Array or Iterable')(values, {
      origin,
      parent,
      path,
      stack
    })).map((value, key) => result(expression, value, {
      origin,
      parent: values,
      path: `${path}[${key}]`,
      stack
    }));
  } catch (e) {
    /* Lets pass the value through, we already logged any possible errors */
    return values;
  }
}, name).required());
export const kvmap = (keyExpression = any(), valueExpression = any(), name = MAP) => type(() => {
  const keyType = keyExpression.describe();
  const valueType = valueExpression.describe();
  return {
    type: MAP,
    name,
    label: `Map<${keyType.label}, ${valueType.label}>`,
    key: keyType,
    value: valueType
  };
}, chainable((object, {
  origin = object,
  parent,
  path = name,
  stack = captureStackTrace()
} = {}) => {
  try {
    return ImmutableMap(validate(obj => {
      if (obj === Object(obj) || ImmutableMap.isMap(obj)) {
        return true;
      }

      /* Validate will log it as an errored value & rethrow this error */
      throw new Error();
    }, name, 'Expected an Object or Map')(object, {
      origin,
      parent,
      path,
      stack
    })).mapKeys(key => result(keyExpression, key, {
      origin,
      parent: object,
      path: `${path}@${key}`,
      stack
    })).map((value, key) => result(valueExpression, value, {
      origin,
      parent: object,
      path: `${path}[${key}]`,
      stack
    }));
  } catch (e) {
    /* Lets pass the value through, we already logged any possible errors */
    return object;
  }
}, name).required());
export const map = (valueExpression = any(), name = MAP) => kvmap(string(), valueExpression, name);
export const record = (expressions, name = RECORD) => {
  const expressionMap = ImmutableMap(expressions);
  const defaultValues = expressionMap.map(() => undefined).toObject();
  const factory = Record(defaultValues, name);
  return type(() => ({
    type: RECORD,
    name,
    label: name,
    fields: OrderedMap(expressions).map((expression = any()) => expression.describe())
  }), chainable((object, {
    origin = object,
    parent,
    path = name,
    stack = captureStackTrace()
  } = {}) => {
    const values = ImmutableMap(validate(obj => ImmutableMap(obj), name, 'Expected an Object or Map')(object, {
      origin,
      parent,
      path,
      stack
    }));
    values.filter((_, key) => !expressionMap.has(key)).forEach((value, key) => {
      logger.warn(stack, `Unexpected key at {${path}}`, `Name: ${name}`, `Path: ${path}`, 'Message: Unexpected key for checked record', () => console.log('Key:', key), () => console.log('Value:', value), () => console.log('Origin:', origin));
    });
    return factory(expressionMap.map((expression = identity, key) => result(expression, values.get(key), {
      origin,
      parent: object,
      path: `${path}.${key}`,
      stack
    })));
  }, name).required());
};
export const recursive = producer => {
  const recursiveExpression = type(() => ({
    recursive: true
  }), chainable((...args) => recursive(producer)(...args)).optional());
  const rootExpression = producer(recursiveExpression);
  const {
    type: rootType,
    name: rootName,
    label: rootLabel
  } = rootExpression.describe();
  const recursiveDescription = Object.assign({
    type: rootType,
    name: rootName,
    label: rootLabel
  }, recursiveExpression.describe());
  recursiveExpression.describe = () => recursiveDescription;
  return rootExpression;
};
export const poly = (field, types, name = POLY) => {
  return type(() => ({
    type: POLY,
    name,
    label: name,
    field,
    types: OrderedMap(types).map((expression = any()) => expression.describe())
  }), chainable((object, {
    origin = object,
    parent,
    path,
    stack = captureStackTrace()
  } = {}) => {
    try {
      const typeName = ImmutableMap.isMap(object) ? object.get(field) : object[field];
      const typeConstructor = types[typeName];
      return typeConstructor(object, {
        origin,
        parent,
        path,
        stack
      });
    } catch (e) {
      try {
        validate(() => {
          throw e;
        }, name, 'Failed to construct poly type')(object, {
          origin,
          parent,
          path,
          stack
        });

        // eslint-disable-next-line no-empty
      } catch (ignored) {}
    }
    return object;
  }).required());
};