import I18n from 'I18n';
import { QUARTER, YEAR } from '../constants/frequency';
import { DEFAULT_FISCAL_YEAR_MONTH_NAME, MONTHS } from '../constants/dates';
import { FISCAL_YEAR_TO_STANDARD_DATE_RANGE, STANDARD_TO_FISCAL_YEAR_DATE_RANGE } from '../constants/dateRangeTypes';
import { HubsSettingClient } from 'frontend-preferences-client';
const getMonthIndex = monthName => Number(I18n.moment().locale('en').month(monthName).format('M')) - 1;
export const getFiscalYearBackwardsOffset = fiscalYearMonthName => {
  const monthIndex = getMonthIndex(fiscalYearMonthName);

  // January has no offset
  if (monthIndex === 0) return 0;
  return 12 - monthIndex;
};
const getFiscalQuarterOffset = fiscalYearMonthName => getMonthIndex(fiscalYearMonthName) % 3;
export const getUseFiscalYear = config => config && (config.getIn(['filters', 'dateRange', 'useFiscalYear']) || false);
export const getUseFiscalYearInAggregation = config => config && (config.get('useFiscalYearInAggregation') || false);
export const getFiscalPeriodOffset = (fiscalYearPeriod, fiscalYearMonthName = DEFAULT_FISCAL_YEAR_MONTH_NAME) => {
  if (fiscalYearPeriod === QUARTER) {
    return getFiscalQuarterOffset(fiscalYearMonthName);
  } else if (fiscalYearPeriod === YEAR) {
    return getMonthIndex(fiscalYearMonthName);
  }
  return 0;
};
const isMonthInNextStandardPeriod = (frequency, initialMonthName, fiscalYearMonthName) => {
  if (frequency === YEAR) {
    return getMonthIndex(initialMonthName) < getMonthIndex(fiscalYearMonthName);
  } else if (frequency === QUARTER) {
    return getFiscalQuarterOffset(initialMonthName) < getFiscalQuarterOffset(fiscalYearMonthName);
  }
  return false;
};
export const isFiscalYearSupportedFrequency = frequency => [QUARTER, YEAR].includes(frequency);
export const isFiscalYearSupportedPeriod = (frequency, fiscalYearMonthName = DEFAULT_FISCAL_YEAR_MONTH_NAME) => {
  return isFiscalYearSupportedFrequency(frequency) && fiscalYearMonthName !== MONTHS.JANUARY;
};
export const isFiscalYearSupportedRangeType = rangeType => rangeType in STANDARD_TO_FISCAL_YEAR_DATE_RANGE;
export const isFiscalYearRangeType = rangeType => rangeType in FISCAL_YEAR_TO_STANDARD_DATE_RANGE;
export const applyPeriodStartDateFiscalYearOffset = (startDate, fiscalYearMonthName, frequency, initialMonthName) => {
  if (!fiscalYearMonthName || !isFiscalYearSupportedPeriod(frequency, fiscalYearMonthName)) {
    return startDate;
  }
  const fiscalPeriodOffset = getFiscalPeriodOffset(frequency, fiscalYearMonthName);
  let offsetStartDate = startDate.add(fiscalPeriodOffset, 'months').startOf('month');
  if (isMonthInNextStandardPeriod(frequency, initialMonthName, fiscalYearMonthName)) {
    offsetStartDate = offsetStartDate.subtract(1, frequency);
  }
  return offsetStartDate;
};
export const applyPeriodEndDateFiscalYearOffset = (endDate, fiscalYearMonthName, frequency, initialMonthName, entireCurrentUnit = true) => {
  if (!fiscalYearMonthName || !isFiscalYearSupportedPeriod(frequency, fiscalYearMonthName)) {
    return endDate;
  }
  const fiscalPeriodOffset = getFiscalPeriodOffset(frequency, fiscalYearMonthName);
  let offsetEndDate = endDate.add(fiscalPeriodOffset, 'months');
  if (isMonthInNextStandardPeriod(frequency, initialMonthName, fiscalYearMonthName)) {
    offsetEndDate = offsetEndDate.subtract(1, frequency);
  }
  return entireCurrentUnit ? offsetEndDate.endOf('month') : offsetEndDate;
};
export const __TESTABLE__ = {
  isMonthInNextStandardPeriod
};
const hubsSettingClient = HubsSettingClient.forCaller('reporting-data');
export const fiscalYearFetcher = () => {
  return hubsSettingClient.fetch('hubspot:fiscalyear', DEFAULT_FISCAL_YEAR_MONTH_NAME);
};