'use es6';

import { getConfigId } from './get';
import { enterPhase, exitPhase, debug as debugAction } from './actions';
import connectActions from '../connectActions';

/**
 * A connected phase updates the redux store with enter and exit
 * actions.
 *
 * @param {string} name The name of the phase
 * @param {function} fn The phase implementation
 */
export const connectedPhase = (name, fn) => store => (config, runtimeOptions = {}) => (...input) => {
  const id = getConfigId(store.getState(), config);
  if (id == null) {
    throw new Error('Config not assigned id');
  }
  const [debug] = connectActions(store, [debugAction]);
  const debugFn = (key, value) => debug({
    config,
    key,
    value
  });
  store.dispatch(enterPhase({
    config,
    name,
    input
  }));
  return fn(...input, debugFn, runtimeOptions).then(output => {
    store.dispatch(exitPhase({
      config,
      name,
      output
    }));
    return output;
  });
};