'use es6';

import { useHasCustomReportingAccess } from '../scopes/hooks/useHasScope';
import { isSnowflake as _isSnowflake, isSnowflakeReportTemplateException as _isSnowflakeReportTemplateException } from '../../lib/report-logic';

// Portals that have been downgraded from pro/enterprise tiers lose access
// to standard Snowflake report templates (barring any exception templates).
export const useCheckSnowflakeTemplateAccess = report => {
  const hasCustomReportingAccess = useHasCustomReportingAccess();
  const isSnowflake = _isSnowflake(report);
  const isSnowflakeReportTemplateException = _isSnowflakeReportTemplateException(report);
  return isSnowflake && !isSnowflakeReportTemplateException && !hasCustomReportingAccess;
};