import get from 'transmute/get';
import { HS_OBJECT_ID } from 'reporting-snowflake/relational/metadata/links/links-mapping';
export const getLabel = get('label');
export const getDescription = get('description');
export const getType = get('type');
export const getHidden = get('hidden');
export const getName = get('name');
export const getReferencedObjectType = get('referencedObjectType');
export const getExternalOptionsReferenceType = get('externalOptionsReferenceType');
export const getAccessLevel = get('accessLevel');
export const getOptions = get('options');
export const isRecordIdProperty = property => getName(property) === HS_OBJECT_ID;
export const getPropertyFromDataSourceByPropertyRecord = (dataSourceProperties, dataSourceId, propertyName) => {
  if (dataSourceId in dataSourceProperties && propertyName in dataSourceProperties[dataSourceId]) {
    return dataSourceProperties[dataSourceId][propertyName];
  }
  return undefined;
};