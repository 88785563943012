'use es6';

const allowedProps = {
  ['data-selenium-test']: true,
  ['placeholder']: true,
  ['onChange']: true,
  ['className']: true,
  ['value']: true,
  ['disabled']: true,
  ['onKeyDown']: true,
  ['onKeyPress']: true,
  ['onKeyUp']: true
};

/**
 * Filter out props that are not supported by Canvas components.
 *
 * Canvas components pass all the props they receive to the native HTML elements
 * as {...rest}. That makes react complain about unrecognized props
 * e.g "Warning: React does not recognize the filterFamily prop on a DOM element."
 */
export const getTransferableProps = (props, customPropsToAllow = {}) => {
  return Object.keys(props).reduce((acc, key) => {
    if (allowedProps[key] || customPropsToAllow[key]) {
      acc[key] = props[key];
    }
    return acc;
  }, {});
};