'use es6';

import unescapedText from 'I18n/utils/unescapedText';
import { List } from 'immutable';
import { RAAS_FE_ONLY_CREATE_STAGE, LEGACY_HUBSPOT_DEFAULT_LIFECYCLE_STAGES } from './contacts-lifecyclestages';
import { crmScpContactsAccess, crmScpContactsBaseAccess } from '../../constants/scopes';
const buildDatePropertyUpdatesFromStage = (stage, defaultLifecyclestageIds, userInfo) => {
  const {
    user: {
      scopes = []
    }
  } = userInfo;
  const stageId = stage.get('value');
  const v1EnteredDateProperty = `hs_date_entered_${stageId}`;
  const maybeV0EnteredDateProperty = defaultLifecyclestageIds.includes(stageId) && `hs_lifecyclestage_${stageId}_date`;
  const label = unescapedText(`reporting-data.properties.contacts.hs_date_entered`, {
    stageLabel: stage.get('label')
  });
  const shouldHideV1Properties = scopes.includes(crmScpContactsAccess) || scopes.includes(crmScpContactsBaseAccess);
  return maybeV0EnteredDateProperty ? {
    [v1EnteredDateProperty]: {
      hidden: shouldHideV1Properties,
      label
    },
    [maybeV0EnteredDateProperty]: {
      hidden: true
    }
  } : {
    [v1EnteredDateProperty]: {
      hidden: shouldHideV1Properties,
      label
    }
  };
};
export const getEnteredDateLifecycleStagePropertyUpdates = (lifecyclestages, userInfo) => {
  const defaultLifecyclestageIds = LEGACY_HUBSPOT_DEFAULT_LIFECYCLE_STAGES().reduce((stageIds, stage) => stage.get('value') !== RAAS_FE_ONLY_CREATE_STAGE ? stageIds.push(stage.get('value')) : stageIds, List([]));
  const propertyUpdates = lifecyclestages.reduce((updates, stage) => {
    return stage.get('value') === RAAS_FE_ONLY_CREATE_STAGE ? updates : Object.assign({}, updates, buildDatePropertyUpdatesFromStage(stage, defaultLifecyclestageIds, userInfo));
  }, {});
  return propertyUpdates;
};