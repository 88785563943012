'use es6';

import { Record } from 'immutable';
import { lang } from 'dashboard-lib/private/js-util';
import curry from 'transmute/curry';
import get from 'transmute/get';
import toString from 'transmute/toString';
import { createFormattedMessage } from './create-formatted-message';
export const Message = Record({
  id: undefined,
  component: null
}, 'Message');
export const getId = get('id');
export const getComponent = get('component');
export const createMessage = curry((baseLangKey, object) => {
  const id = toString(object);
  const languageKey = lang(baseLangKey)(id);
  return Message({
    id,
    component: createFormattedMessage(languageKey)
  });
});
export const createReactMessage = curry((object, component) => {
  const id = toString(object);
  return Message({
    id,
    component
  });
});