import * as checked from 'reporting-data/lib/checked';
import { Encoding } from '../column-records';
import { AxisScaleType, ColorOption, Scale, legendPosition, legendWidth, showDataLabels, showMarkers, showRecordIds, showToday, stacking } from '../highcharts-settings';
import { VisualTypes } from '../constants/visual-constants';
export const ComboEncodings = checked.record({
  x: Encoding.optional(),
  y1: Encoding.optional(),
  y2: Encoding.optional(),
  breakdown: Encoding.optional()
}, 'ComboEncodings');
export const AxisModes = {
  DUAL: 'DUAL',
  BLEND: 'BLEND'
};
const AxisMode = checked.symbol(AxisModes, 'AxisMode');
export const MarkTypes = {
  BAR: 'BAR',
  LINE: 'LINE',
  AREA: 'AREA'
};
const MarkType = checked.symbol(MarkTypes, 'MarkType');
export const ComboVisualOptions = checked.record({
  axisMode: AxisMode.defaultValue(AxisModes.DUAL),
  y1MarkType: MarkType.defaultValue(MarkTypes.BAR),
  y2MarkType: MarkType.defaultValue(MarkTypes.LINE),
  y1MinMax: Scale.defaultValue({}),
  y2MinMax: Scale.defaultValue({}),
  y1AxisType: AxisScaleType,
  y2AxisType: AxisScaleType,
  y1ShowMarkers: showMarkers,
  y2ShowMarkers: showMarkers,
  stacking: checked.boolean().optional(),
  // make this non-optional after finishing the migration
  stackingType: checked.string().optional(),
  y1Stacking: stacking,
  y2Stacking: stacking,
  y1Accumulate: checked.boolean().defaultValue(false),
  y2Accumulate: checked.boolean().defaultValue(false),
  y1Color: ColorOption.defaultValue({}),
  y2Color: ColorOption.defaultValue({}),
  showDataLabels,
  showRecordIds,
  showToday,
  legendPosition,
  legendWidth
});
export const ComboVisual = checked.record({
  type: checked.string().always(VisualTypes.COMBO),
  encodings: ComboEncodings,
  options: ComboVisualOptions.defaultValue({})
}, 'ComboVisual');