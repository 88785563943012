import { IntegrationStatus } from '../api/model/app/IntegrationStatus';
export const get = integrationStatus => {
  let message;
  switch (integrationStatus) {
    case IntegrationStatus.THIRD_PARTY_MISSING_SCOPE_ERROR:
      message = 'shareWithThirdPartyComponentLibrary.appSelector.noPermissionsToShareError_jsx';
      break;
    case IntegrationStatus.AUTH_ERROR:
    case IntegrationStatus.DEPRECATED_AUTH_TOKEN:
    case IntegrationStatus.UNKNOWN_ERROR:
      message = 'shareWithThirdPartyComponentLibrary.appSelector.disconnectedError_jsx';
      break;
    default:
      message = 'shareWithThirdPartyComponentLibrary.appSelector.somethingWentWrongError_jsx';
      break;
  }
  return message;
};