'use es6';

import { VisualTypes } from '../../../schema/visual-records';
import { getReportVisualType, getReportYEncoding } from '../../../utils/report-utils';
import { combineConstraints, ConstraintTypes, createConstraint, InvalidSpecificationTypes } from '../../validate';
import { twoOrFewerValueChannels, xyLikeVisualIsDisplayable } from './common-constraints';
import { checkAllDateDimensionsHaveTransform } from './check-all-date-dimensions-have-transformation';
import { isDateLikeField } from '../../../utils/column-utils';
import { getEncodingColumnAlias } from '../../../utils/visual-utils';
import { checkIfSortIsAccumulateFriendly } from './check-sort-is-accumulate-friendly';
const getYColumnId = report => {
  const y = getReportYEncoding(report);
  return y && getEncodingColumnAlias(y);
};
const yAxisIsAccumulateFriendly = createConstraint('yAxisIsAccumulateFriendly', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (accumulate, maybeYEncoding) => {
  if (!accumulate) {
    return true;
  }
  const maybeColumn = maybeYEncoding && maybeYEncoding.column;
  return maybeColumn === undefined ? true : isDateLikeField(maybeColumn.field);
}, [report => report.visual.options.accumulate, report => getReportYEncoding(report)]);
export const sortIsAccumulateFriendly = createConstraint('sortIsAccumulateFriendly', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (isAccumulateSet, sorts, xId) => {
  if (!isAccumulateSet) {
    return true;
  }
  return checkIfSortIsAccumulateFriendly(sorts, xId);
}, [report => report.visual.options.accumulate, report => report.sorts, getYColumnId]);
const dateDimensionsHaveTransformation = createConstraint('dateDimensionsHaveTransformation', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, checkAllDateDimensionsHaveTransform, [report => report.columns]);
export const horizontalBar = combineConstraints('horizontalBar', [twoOrFewerValueChannels, xyLikeVisualIsDisplayable, dateDimensionsHaveTransformation, yAxisIsAccumulateFriendly, sortIsAccumulateFriendly], (constraint, report) => getReportVisualType(report) !== VisualTypes.HORIZONTAL_BAR || constraint.validate(report));