'use es6';

import { List } from 'immutable';
import { WrapWithRaven } from '../sentry/sales-analytics-sentry';
import { applyDefaultFrequency } from './apply-default-frequency';
import { syncDateRanges } from './context-change-hooks/sync-date-ranges';
import { validateChartType } from './context-change-hooks/validate-chart-types';
import { validateDateRange } from './context-change-hooks/validate-date-range';
import { validateFrequency } from './context-change-hooks/validate-frequency';
import { validateGrouping } from './context-change-hooks/validate-grouping';
import { validatePipeline } from './context-change-hooks/validate-pipeline';
import { validateStages } from './context-change-hooks/validate-stages';
import { validateBreakdown } from './context-change-hooks/validate-breakdown';
import { validateTableMetrics } from './context-change-hooks/validate-table-metrics';
import { validateChartMetrics } from './context-change-hooks/validate-chart-metrics';
import { validateFilteredBreakdowns } from './context-change-hooks/validate-filtered-breakdowns';
import { validateCompare } from './context-change-hooks/validate-compare';
import { validateAnalyticsView } from './context-change-hooks/validate-analytics-view';
import { applyQuestionInsight } from './context-change-hooks/apply-question-insight';
import { applySavedContext } from './context-change-hooks/apply-question-insight-saved-context';
import { updateChartTypeForGrouping } from './context-change-hooks/update-chart-type-for-grouping';
import { validateUnifiedEventDatatype } from './context-change-hooks/validate-unified-event-datatype';
const hooks = List([applySavedContext, applyQuestionInsight, validateDateRange, syncDateRanges, validateFrequency, applyDefaultFrequency, validateGrouping, updateChartTypeForGrouping, validateBreakdown, validatePipeline, validateStages, validateChartType, validateChartMetrics, validateTableMetrics, validateFilteredBreakdowns, validateCompare, validateAnalyticsView, validateUnifiedEventDatatype]);

// apply the context change
// validate the context, make it internally consistent and complete
export const applyContextChange = WrapWithRaven('applyContextChange', (context, contextChange, subAppKey, report, scopes, gates, defaultContext, isSettingInitialContext = false) => {
  const newContextChange = hooks.reduce((memo, hook) => hook(memo, context, report, subAppKey, defaultContext, scopes, gates, isSettingInitialContext), contextChange);
  return context.merge(newContextChange);
});