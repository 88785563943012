import PortalIdParser from 'PortalIdParser';
const BASE_URL = 'external-options/v3';
export const makeExternalOptionsByIdUrl = referenceType => `${BASE_URL}/fetch/${encodeURIComponent(referenceType)}`;
export const makeExternalOptionsSearchUrl = referenceType => `${BASE_URL}/pagedFetch/${encodeURIComponent(referenceType)}`;
export const getExternalOptionsFetchQueryOptions = () => {
  return {
    // NOTE: `portalId` is required both as a query param and in the body of the POST request:
    //
    // 1. Query param: for requests made from `SalesSidebar` (gmail plugin),
    //    without which requests fail with a 400 "HubId is not present in any
    //    form on the request. Please provide a path param or a query param.")
    // 2. Body of the POST request: without which requests fail with a 400
    //    "Invalid input JSON...some of required attributes are not set [portalId]"
    portalId: PortalIdParser.get()
  };
};
export const getExternalOptionsByIdFetchOptions = (ids, referenceType, fetchOptions, objectTypeId, externalOptionsMetaData) => Object.assign({
  referenceType,
  ids,
  includeDeleted: fetchOptions.includeDeleted,
  includeDisabled: false,
  portalId: PortalIdParser.get(),
  externalOptionsMetaData,
  formatLabel: true
}, objectTypeId && {
  objectType: objectTypeId
});
export const getExternalOptionSearchFetchOptions = (referenceType, searchQuery, fetchOptions, objectTypeId, externalOptionsMetaData) => Object.assign({
  referenceType,
  limit: searchQuery.count,
  searchQuery: searchQuery.query,
  offset: `${searchQuery.offset}`,
  portalId: PortalIdParser.get(),
  includeDeleted: fetchOptions.includeDeleted,
  useIndexOffset: false,
  externalOptionsMetaData,
  formatLabel: true
}, objectTypeId && {
  objectType: objectTypeId
});