/**
 * Terms and definitions:
 * Settled: the report components are not loading (either errored or success state)
 */

/**
 * Fire this when all reports in a page have settled.
 */
export const ALL_REPORTS_SETTLED = 'ALL_REPORTS_SETTLED';

/**
 * Fire this when the reports prioritized for render have settled.
 * For example, we can fire this action when the first 2 reports settle in the dashboard routes.
 */
export const PRIORITY_REPORTS_SETTLED = 'PRIORITY_REPORTS_SETTLED';