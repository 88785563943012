'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { Promise } from '../../lib/promise';
import prefix from '../../lib/prefix';
import { NOTES } from '../../constants/dataTypes';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import getCommonPropertyGroups from '../partial/engagement-common';
import countProperty from '../partial/count-property';
import engagementModule from '../../dataTypeDefinitions/inboundDb/engagement';
import overridePropertyTypes from '../../retrieve/inboundDb/common/overridePropertyTypes';
const translateNotes = prefix('reporting-data.properties.notes');
const translateGroup = prefix('reporting-data.groups.engagement');
export const getNotePropertyGroups = () => Promise.resolve(List.of(ImmutableMap({
  name: 'noteInfo',
  displayName: translateGroup('noteInfo'),
  displayOrder: 0,
  hubspotDefined: true,
  properties: List([ImmutableMap({
    name: 'note.body',
    label: translateNotes('body'),
    type: 'string'
  })])
})));
export const getPropertyGroups = () => Promise.all([getCommonPropertyGroups(), getNotePropertyGroups()]).then(([commonPropertyGroups, notePropertyGroups]) => List([...commonPropertyGroups, ...notePropertyGroups]));
export const getProperties = () => createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(NOTES)))().then(overridePropertyTypes(engagementModule.getInboundSpec()));