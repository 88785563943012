'use es6';

import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { getEmailsByIds, createGetEmailsByIds, createGetEmailsSearchPage, getEmailsSearchPage } from 'reference-resolvers/api/EmailAPI';
export const createEmailReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.EMAILS,
  createFetchByIds: createGetEmailsByIds,
  fetchByIds: getEmailsByIds,
  createFetchSearchPage: createGetEmailsSearchPage,
  fetchSearchPage: getEmailsSearchPage
}, options));
export default createEmailReferenceResolver();