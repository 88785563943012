'use es6';

import { has } from '../../../lib/has';
export const get = (spec, config) => {
  const {
    metadata = {}
  } = spec;
  const {
    dimensions
  } = config;
  if (dimensions.length === 1 && has(metadata, dimensions[0])) {
    return {
      groupby: `metadata-${dimensions[0]}`
    };
  }
  return {};
};