import I18n from 'I18n';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import { JoinTypes } from 'reporting-snowflake/relational/schema/table-records';
import { getJoins } from './table-utils';
import { TableGroupSections } from '../schema/source-records';
import { JoinEdge } from './association-utils';
import { addGroup } from '../modify/filters/filter-utils';
import { Group } from '../schema/filter-records';
import { HS_OBJECT_ID } from '../metadata/links/links-mapping';
import { FieldSources, FieldTypes } from '../schema/column-records';
export const CUSTOM_ASSOCIATION_PREFIX = '1-';
export const HUBSPOT_ASSOCIATION_PREFIX = '0-';
export const ASSOCIATION_DIVIDER = '_HS_SELF_ASSOCIATION_';
export const ASSOCIATION_ID_DIVIDER = '_HS_SOA_ID_';
const isCrossObjectJoinWithSelfAssociationTable = edge => {
  return edge.left !== edge.right && edge.left.includes(ASSOCIATION_DIVIDER);
};
export const getSelfJoin = tableDescription => getJoins(tableDescription).filter(join => join.target.name.includes(ASSOCIATION_DIVIDER)).find(join => !!join.definitions.find(definition => definition.type === JoinTypes.ASSOCIATION && !!definition.combinedAssociationTypeId && (definition.combinedAssociationTypeId.startsWith(CUSTOM_ASSOCIATION_PREFIX) || definition.combinedAssociationTypeId.startsWith(HUBSPOT_ASSOCIATION_PREFIX))));
export const getSelfJoinMetadataForObjectId = (allJoinMetadata, objectTypeId) => allJoinMetadata && allJoinMetadata.getIn([objectTypeId, objectTypeId]);
export const getCrossObjectJoinFromSelfAssociationEdge = edge => {
  if (isCrossObjectJoinWithSelfAssociationTable(edge)) {
    return JoinEdge({
      left: edge.left.split(ASSOCIATION_DIVIDER)[0],
      right: edge.right
    });
  }
  return edge;
};
export const getSelfAssociationLabelFromId = (joinMetadata, combinedAssociationTypeId) => {
  const selfJoinAssociation = joinMetadata.filter(meta => !!meta.getIn(['associationDefinition', 'label'])).find(meta => meta.get('combinedAssociationTypeId', '') === combinedAssociationTypeId);
  const label = selfJoinAssociation && selfJoinAssociation.getIn(['associationDefinition', 'label']);
  if (!label) {
    return '';
  }
  return label.replace(' ', '');
};
export const getSelfAssociationDisplayLabelFromId = (joinMetadata, combinedAssociationTypeId) => {
  const selfJoinAssociation = joinMetadata.filter(meta => !!meta.getIn(['associationDefinition', 'label'])).find(meta => meta.get('combinedAssociationTypeId', '') === combinedAssociationTypeId);
  const label = selfJoinAssociation && selfJoinAssociation.getIn(['associationDefinition', 'label']);
  if (!label) {
    return '';
  }
  return label;
};
export const formParentToChildCompanyAssociation = joinMetadata => {
  const joinMetaChildAssociationIndex = joinMetadata.indexOf(joinMetadata.find(meta => {
    return meta.getIn(['associationDefinition', 'name']) === 'PARENT_TO_CHILD_COMPANY';
  }));
  const newChildMeta = joinMetaChildAssociationIndex > -1 ? joinMetadata.find(meta => meta.getIn(['associationDefinition', 'name']) === 'PARENT_TO_CHILD_COMPANY').setIn(['associationDefinition', 'name'], 'CHILD_TO_PARENT_COMPANY') : undefined;
  return newChildMeta && joinMetaChildAssociationIndex > -1 ? joinMetadata.set(joinMetaChildAssociationIndex, newChildMeta) : joinMetadata;
};
export const getCorrespondingAssociationLabelFromId = (joinMetadata, combinedAssociationTypeId) => {
  const resolvedMetadata = formParentToChildCompanyAssociation(joinMetadata);
  const associationMeta = resolvedMetadata.find(meta => meta.get('combinedAssociationTypeId', '') === combinedAssociationTypeId);
  const associationName = associationMeta ? associationMeta.getIn(['associationDefinition', 'name']) : '';
  const correspdingAssociation = resolvedMetadata.find(meta => meta.get('combinedAssociationTypeId', '') !== combinedAssociationTypeId && meta.getIn(['associationDefinition', 'name']) === associationName);
  const associationId = correspdingAssociation && correspdingAssociation.get('combinedAssociationTypeId', '');
  if (!associationId) {
    return '';
  }
  return getSelfAssociationLabelFromId(joinMetadata, associationId);
};
export const getCorrespondingAssociationDisplayLabelFromId = (joinMetadata, combinedAssociationTypeId) => {
  const resolvedMetadata = formParentToChildCompanyAssociation(joinMetadata);
  const associationName = resolvedMetadata.find(meta => meta.get('combinedAssociationTypeId', '') === combinedAssociationTypeId).getIn(['associationDefinition', 'name']);
  const correspdingAssociation = resolvedMetadata.find(meta => meta.get('combinedAssociationTypeId', '') !== combinedAssociationTypeId && meta.getIn(['associationDefinition', 'name']) === associationName);
  const associationId = correspdingAssociation && correspdingAssociation.get('combinedAssociationTypeId', '');
  if (!associationId) {
    return '';
  }
  return getSelfAssociationDisplayLabelFromId(joinMetadata, associationId);
};
const getSelfJoinLabel = (selfJoin, allJoinMetadata) => {
  if (allJoinMetadata) {
    const joinMetadata = getSelfJoinMetadataForObjectId(allJoinMetadata, selfJoin.target.objectTypeId);
    const combinedAssociationTypeId = selfJoin.definitions.first().get('combinedAssociationTypeId', '');
    return getSelfAssociationLabelFromId(joinMetadata, combinedAssociationTypeId);
  }
  return null;
};
const createSelfJoinInternalLabel = (selfJoin, allJoinMetadata) => `${getSelfJoinLabel(selfJoin, allJoinMetadata)}${ASSOCIATION_ID_DIVIDER}${selfJoin.definitions.first().get('combinedAssociationTypeId', '').replace('-', '_')}`;
export const addSnowflakeTablesFromSelfAssociation = (snowflakeTables, tableDescription, allJoinMetadata) => {
  const selfJoin = getSelfJoin(tableDescription);
  if (!selfJoin || selfJoin.definitions.isEmpty()) {
    return snowflakeTables;
  }
  if (selfJoin && selfJoin.target && selfJoin.definitions && snowflakeTables) {
    const associationLabel = createSelfJoinInternalLabel(selfJoin, allJoinMetadata);
    const tableName = `${snowflakeTables.find(table => table.dataSourceId === selfJoin.target.objectTypeId).name}${ASSOCIATION_DIVIDER}${associationLabel}`;
    return snowflakeTables.set(tableName, snowflakeTables.find(table => table.dataSourceId === selfJoin.target.objectTypeId));
  }
  return snowflakeTables;
};
export const addSnowflakeTableGroupsFromSelfAssociation = (snowflakeTableGroups, snowflakeTables, tableDescription, allJoinMetadata) => {
  const selfJoin = getSelfJoin(tableDescription);
  if (!selfJoin || selfJoin.definitions.isEmpty()) {
    return snowflakeTableGroups;
  }
  if (selfJoin && snowflakeTables && snowflakeTableGroups) {
    const associationLabel = createSelfJoinInternalLabel(selfJoin, allJoinMetadata);
    const snowflakeTableName = snowflakeTables.find(table => table.dataSourceId === selfJoin.target.objectTypeId).name;
    const snowflakeTableGroupName = snowflakeTableGroups.find(value => value.root.first() === snowflakeTableName).name;
    const tableGroupName = `${snowflakeTableGroupName}${ASSOCIATION_DIVIDER}${associationLabel}`;
    const rootName = snowflakeTableGroups.find(value => value.root.first() === snowflakeTableName).root.first();
    const snowflakeTableGroupRootFirst = `${rootName}${ASSOCIATION_DIVIDER}${associationLabel}`;
    const snowflakeTableGroupRoot = snowflakeTableGroups.find(value => value.root.first() === snowflakeTableName).root.set(0, snowflakeTableGroupRootFirst);
    return snowflakeTableGroups.set(tableGroupName, snowflakeTableGroups.find(value => value.root.first() === snowflakeTableName).set('root', snowflakeTableGroupRoot).set('name', tableGroupName).set('section', TableGroupSections.SELF_ASSOCIATION));
  }
  return snowflakeTableGroups;
};
const getSelfAssociationFilter = selfJoin => Group({
  id: '1',
  condition: 'AND',
  filters: [{
    field: {
      name: HS_OBJECT_ID,
      table: selfJoin.target.name,
      source: FieldSources.TABLE,
      type: FieldTypes.NUMBER
    },
    filter: {
      filterType: 'PROPERTY',
      operation: {
        propertyType: 'alltypes',
        operator: 'IS_KNOWN'
      },
      property: HS_OBJECT_ID
    }
  }]
});
export const querifySelfAssociation = report => {
  const {
    table
  } = report;
  const selfJoin = getSelfJoin(table);
  if (selfJoin) {
    const hasSelfAssociationFilter = report.filtering && report.filtering.groups.find(group => group.condition === 'AND' && group.filters.get(0).equals(getSelfAssociationFilter(selfJoin).filters.get(0)));
    if (!hasSelfAssociationFilter) {
      return report.update('filtering', filtering => addGroup(filtering, getSelfAssociationFilter(selfJoin)));
    }
  }
  return report;
};
export const getSelfAssociationTableLabel = (tableName, selfJoin, snowflakeTable, tableDescription, allJoinMetadata) => {
  const joinMetadata = getSelfJoinMetadataForObjectId(allJoinMetadata, selfJoin.target.objectTypeId);
  const combinedAssociationTypeId = selfJoin.definitions.first().get('combinedAssociationTypeId', '');
  if (tableName.includes(ASSOCIATION_DIVIDER)) {
    const tableGroupNameArray = tableName.split(ASSOCIATION_DIVIDER);
    const tableAssociationLabel = getSelfAssociationDisplayLabelFromId(joinMetadata, combinedAssociationTypeId);
    return isValidI18nKey(`reporting-snowflake.source-labels.${tableGroupNameArray[0]}.other`) ? `${I18n.text(`reporting-snowflake.source-labels.${tableGroupNameArray[0]}.other`)} (${tableAssociationLabel})` : tableName;
  }
  const correspondingLabel = getCorrespondingAssociationDisplayLabelFromId(joinMetadata, combinedAssociationTypeId);
  if (correspondingLabel) {
    return isValidI18nKey(`reporting-snowflake.source-labels.${tableName}.other`) ? `${I18n.text(`reporting-snowflake.source-labels.${tableName}.other`)} (${correspondingLabel})` : tableName;
  }
  return tableName;
};