import { useEffect, useState } from 'react';
import { laboratoryClient } from '../data/laboratoryClient';
import { ONBU_038_PARAMETERS_KEY, ONBU_038_PARAM_KEY, ONBU_038_TREATMENT_KEY, ONBU_038_VARIANT } from '../constants/experimentConstants';

// ONBU-038
export const useSalesReportCreated = () => {
  const [isSalesReportCreated, setIsSalesReportCreated] = useState(false);
  useEffect(() => {
    const handler = () => setIsSalesReportCreated(true);
    document.addEventListener('postWizardDashboardTourExit', handler);
    return () => document.removeEventListener('postWizardDashboardTourExit', handler);
  }, []);
  return isSalesReportCreated;
};
export const useIsOnbu038Treatment = contactsCountTotal => {
  const [onbu038Treatment, setOnbu038Treatment] = useState();
  const isSalesReportCreatedViaOnboarding = useSalesReportCreated();
  useEffect(() => {
    if (contactsCountTotal <= 2 && isSalesReportCreatedViaOnboarding) {
      laboratoryClient.resolve(treatments => {
        const treatment = treatments[ONBU_038_TREATMENT_KEY][ONBU_038_PARAMETERS_KEY][ONBU_038_PARAM_KEY];
        setOnbu038Treatment(treatment);
        if (treatment) {
          laboratoryClient.logExposure(ONBU_038_TREATMENT_KEY);
        }
      });
    }
  }, [contactsCountTotal, isSalesReportCreatedViaOnboarding]);
  return onbu038Treatment === ONBU_038_VARIANT;
};