/**
 * Default reject function
 *
 * @param {Error} error Error object
 * @returns {object} Wrapped error
 * @private
 */
const defaultReject = error => ({
  error
});

/**
 * Failable decorator
 *
 * @param {function} callback Failable function
 * @param {?function} reject Rejection function
 * @returns {function} Safely wrapped function
 */

export const safe = (callback, reject = defaultReject) => (...args) => {
  try {
    return callback(...args);
  } catch (error) {
    return reject(error);
  }
};