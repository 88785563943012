'use es6';

import { AssetPermission } from '../../../../public/permission/asset-permission';
import { hasSufficientPermission } from '../../../../public/permission/compute-permission';
import { getAndCheck } from '../../common/get-and-check-param';
import { getAllProblems } from '../../common/get-problems-no-check';
import { createProblem } from '../../common/problem';
import { IS_READ_ONLY_DEMO, USER_DASHBOARD_PERMISSION } from '../../params/all-params';
import { NEED_CREATE_AND_OWN_PERMISSION } from '../../problems/permission-problem';
import { CUSTOM_DASHBOARD_LIMIT_EXCEEDED_AND_DASHBOARD_SHOULD_LOCKDOWN } from 'dashboard-lib/private/action-problem-solution/problems/lockdown-problem';
export const PERMISSION_DENIED = createProblem('PERMISSION_DENIED', params => {
  return !hasSufficientPermission(AssetPermission.CREATE_AND_OWN, getAndCheck(USER_DASHBOARD_PERMISSION, params));
});
export const DASHBOARD_IS_READ_ONLY_DEMO = createProblem('DASHBOARD_IS_READ_ONLY_DEMO', params => getAndCheck(IS_READ_ONLY_DEMO, params));
export const rankedProblems = [DASHBOARD_IS_READ_ONLY_DEMO, CUSTOM_DASHBOARD_LIMIT_EXCEEDED_AND_DASHBOARD_SHOULD_LOCKDOWN, NEED_CREATE_AND_OWN_PERMISSION, PERMISSION_DENIED];
export const getAll = params => getAllProblems(params, rankedProblems);