'use es6';

import createSimpleCachedReferenceResolver from '../lib/createSimpleCachedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { createGetAllSubdomainsById, getAllSubdomainsById } from '../api/SubdomainApi';
export const createSubdomainIdsReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.SUBDOMAIN_IDS,
  createFetchData: createGetAllSubdomainsById,
  fetchData: getAllSubdomainsById
}, options));
export default createSubdomainIdsReferenceResolver();