'use es6';

import usePollingQuery from './usePollingQuery';
import { REPORT_ACCESS_SETTINGS_FIELD_NAME, FETCH_REPORT_ACCESS_SETTINGS } from '../requests/report';
export const useReportAccessSettings = reportId => {
  const {
    data,
    error,
    loading
  } = usePollingQuery(FETCH_REPORT_ACCESS_SETTINGS, {
    variables: {
      reportId
    },
    fetchPolicy: 'cache-and-network'
  });
  return {
    data: data ? data[REPORT_ACCESS_SETTINGS_FIELD_NAME] : undefined,
    error,
    loading
  };
};