'use es6';

import http from 'hub-http/clients/apiClient';
import { formatByDomain, formatByDomainId } from '../formatters/formatSubdomains';
const BASE_URL = 'cos-domains/v1/domains';
const fetchAllSubdomains = ({
  httpClient
}) => httpClient.get(BASE_URL, {
  query: {
    skipdnscheck: true,
    limit: 1000
  }
});
export const createGetAllSubdomains = ({
  httpClient
}) => () => fetchAllSubdomains({
  httpClient
}).then(formatByDomain);
export const createGetAllSubdomainsById = ({
  httpClient
}) => () => fetchAllSubdomains({
  httpClient
}).then(formatByDomainId);
export const getAllSubdomains = createGetAllSubdomains({
  httpClient: http
});
export const getAllSubdomainsById = createGetAllSubdomainsById({
  httpClient: http
});