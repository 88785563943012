'use es6';

import { useEffect, useRef } from 'react';
import { useQuery } from 'data-fetching-client';
const usePollingQuery = (query, options = {}, pollIntervalMs = null) => {
  const {
    data,
    error,
    loading,
    startPolling,
    stopPolling,
    refetch
  } = useQuery(query, options);
  const prev = useRef(null);
  useEffect(() => {
    if (pollIntervalMs) {
      startPolling(pollIntervalMs);
    }
  });
  useEffect(() => {
    if (!pollIntervalMs) {
      return undefined;
    } else {
      const onVisibilityChange = () => {
        const {
          visibilityState
        } = document;
        const focused = typeof document === 'undefined' || visibilityState === undefined || visibilityState === 'visible' || visibilityState === 'prerender';
        if (prev && prev.current !== focused) {
          prev.current = focused;
          if (visibilityState !== 'hidden') {
            startPolling(pollIntervalMs);
          } else {
            stopPolling();
          }
        }
      };
      window.addEventListener('visibilitychange', onVisibilityChange);
      return () => {
        window.removeEventListener('visibilitychange', onVisibilityChange);
      };
    }
  }, [startPolling, stopPolling, pollIntervalMs]);
  return {
    data,
    error,
    loading,
    refetch
  };
};
export default usePollingQuery;