'use es6';

import { isDateLikeField } from '../../../utils/column-utils';
import { TransformTypes } from '../../../schema/column-records';
import { COLUMN_ROLES } from 'reporting-data/constants/relationalReports';
export const checkAllDateDimensionsHaveTransform = columns => {
  const isDimension = column => column.role === COLUMN_ROLES.DIMENSION;
  const isDateLikeColumn = column => isDateLikeField(column.field);
  const hasDateTransformation = column => {
    const transform = column.transform;
    return transform && [TransformTypes.DATE_TRUNC, TransformTypes.DATE_PART].includes(transform.type);
  };
  const isValidColumn = column => isDimension(column) && isDateLikeColumn(column) ? hasDateTransformation(column) : true;
  return columns.reduce((acc, c) => acc && isValidColumn(c), true);
};