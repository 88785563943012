'use es6';

import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import I18n from 'I18n';
import { DEALS, COMPANIES, CONTACTS } from '../../constants/dataTypes';
import { InboundDbModule } from '../../module';
import { hydrate } from '../../retrieve/inboundDb/dynamicHydrate';
import { adapt } from '../../references/adapt';
import listReferences from '../../references/list';
import pipeline from '../../references/pipeline';
import ownerReferences from '../../references/owner';
import teamReferences from '../../references/team';
import userReferences from '../../references/user';
import pipelineStageReferences from '../../references/pipelineStage';
import currencyReferences from '../../references/currency';
import Spec from '../../retrieve/inboundDb/common/specs/Spec';
import integrationsReferences from '../../references/integrations';
import emailReferences from '../../references/email';
import importReferences from '../../references/import';
import { combine } from '../../references/combine';
const idProperty = 'dealId';
const getInboundSpec = () => new Spec({
  dataType: DEALS,
  search: {
    url: `contacts/search/v1/search/deals`,
    objectsField: 'deals'
  },
  properties: {
    idProperty,
    responsePaths: {
      dealId: ['dealId']
    },
    references: {
      'associations.company': COMPANIES
    }
  },
  hydrate: {
    inputs: ImmutableSet([idProperty, 'dealname']),
    fn: obj => !obj ? I18n.text('reporting-data.references.deal.unknown', {
      id: obj[idProperty]
    }) : obj.dealname
  }
});
export default InboundDbModule({
  dataType: DEALS,
  references: ImmutableMap({
    pipeline: adapt(pipeline(DEALS)),
    'ilsListMemberships.listId': adapt(listReferences),
    hubspot_owner_id: adapt(ownerReferences),
    hubspot_team_id: adapt(teamReferences),
    dealstage: (...args) => adapt(pipelineStageReferences(DEALS, [{
      stageId: 'create',
      label: I18n.text('reporting-data.properties.deals.stage.create')
    }]))(...args),
    deal_currency_code: adapt(currencyReferences),
    currency: adapt(currencyReferences),
    hs_created_by_user_id: adapt(userReferences),
    hs_analytics_source_data_2: combine(adapt(integrationsReferences), adapt(emailReferences), adapt(importReferences))
  }),
  referenceProperties: ImmutableMap({
    'associations.company': COMPANIES,
    'associations.contact': CONTACTS,
    dealId: DEALS
  }),
  hydrate: (ids, config) => hydrate(DEALS, ids, config),
  getInboundSpec
});