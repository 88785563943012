export const TODAY = 'TODAY';
export const YESTERDAY = 'YESTERDAY';
export const TOMORROW = 'TOMORROW';
export const THIS_WEEK = 'THIS_WEEK';
export const THIS_WEEK_SO_FAR = 'THIS_WEEK_SO_FAR';
export const LAST_WEEK = 'LAST_WEEK';
export const NEXT_WEEK = 'NEXT_WEEK';
export const THIS_MONTH = 'THIS_MONTH';
export const THIS_MONTH_SO_FAR = 'THIS_MONTH_SO_FAR';
export const LAST_MONTH = 'LAST_MONTH';
export const NEXT_MONTH = 'NEXT_MONTH';
export const THIS_QUARTER = 'THIS_QUARTER';
export const THIS_FISCAL_QUARTER = 'THIS_FISCAL_QUARTER';
export const THIS_QUARTER_SO_FAR = 'THIS_QUARTER_SO_FAR';
export const THIS_FISCAL_QUARTER_SO_FAR = 'THIS_FISCAL_QUARTER_SO_FAR';
export const LAST_QUARTER = 'LAST_QUARTER';
export const LAST_FISCAL_QUARTER = 'LAST_FISCAL_QUARTER';
export const NEXT_QUARTER = 'NEXT_QUARTER';
export const NEXT_FISCAL_QUARTER = 'NEXT_FISCAL_QUARTER';
export const THIS_YEAR = 'THIS_YEAR';
export const THIS_FISCAL_YEAR = 'THIS_FISCAL_YEAR';
export const THIS_YEAR_SO_FAR = 'THIS_YEAR_SO_FAR';
export const THIS_FISCAL_YEAR_SO_FAR = 'THIS_FISCAL_YEAR_SO_FAR';
export const LAST_YEAR = 'LAST_YEAR';
export const LAST_FISCAL_YEAR = 'LAST_FISCAL_YEAR';
export const NEXT_YEAR = 'NEXT_YEAR';
export const NEXT_FISCAL_YEAR = 'NEXT_FISCAL_YEAR';
export const LAST_7_DAYS = 'LAST_7_DAYS';
export const LAST_14_DAYS = 'LAST_14_DAYS';
export const LAST_30_DAYS = 'LAST_30_DAYS';
export const LAST_60_DAYS = 'LAST_60_DAYS';
export const LAST_90_DAYS = 'LAST_90_DAYS';
export const LAST_180_DAYS = 'LAST_180_DAYS';
export const LAST_365_DAYS = 'LAST_365_DAYS';