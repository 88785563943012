'use es6';

import { Set as ImmutableSet, List } from 'immutable';
import both from 'transmute/both';
import compose from 'transmute/compose';
import count from 'transmute/count';
import curry from 'transmute/curry';
import filter from 'transmute/filter';
import map from 'transmute/map';
import isEmpty from 'transmute/isEmpty';
import { isJourneyReport } from 'reporting-data/tsTypes/reportTypes';
import { defaultTo, isNotNil, strictEquals, isString, isStringUrlFree } from '../../private/js-util';
import { isCustom as isCustomReport, isStaticWidget } from '../report/report-logic';
import { getId as getReportId } from '../report/report-schema';
import { isWidgetNearTheTop } from '../widget/widget-is-priority';
import { getReport, getReportId as getReportIdFromWidget } from '../widget/widget-schema';
import { getDemo, getOwnerId, getOwnerIdStr, getType, getWidgets } from './dashboard-schema';
import { isV2 as isSnowflakeReport } from '../report/report-logic';

/**
 * @param {String | Integer} userId
 * @param dashboard
 */
export const isUserOwner = curry((userId, dashboard) => {
  const ownerId = getOwnerIdStr(dashboard);
  return isNotNil(userId) && isNotNil(ownerId) && ownerId === String(userId);
});
const isType = curry((type, dashboard) => compose(strictEquals(type), getType)(dashboard));
export const isCustomDashboard = both(isNotNil, isType('CUSTOM'));
export const getWidgetsWithDefault = compose(defaultTo(List()), getWidgets);
export const countCustomReportsInDashboard = compose(count, filter(isCustomReport), map(getReport), getWidgetsWithDefault);
export const getReportIdSet = compose(ImmutableSet, map(compose(getReportId, getReport)), getWidgetsWithDefault);
export const getPriorityReportIdSet = compose(ImmutableSet, map(compose(getReportId, getReport)), filter(isWidgetNearTheTop), getWidgetsWithDefault);
export const isValidDashboardString = name => {
  const isNonEmpty = isString(name) && name.trim().length;
  return isNonEmpty && isStringUrlFree(name);
};
export const hasNoWidgets = compose(isEmpty, getWidgets);
export const isUserIdOwner = (userId, dashboard) => getOwnerId(dashboard) === userId;
export const isReadOnlyDemo = (canWriteDemoDashboard, dashboard) => !!getDemo(dashboard) && !canWriteDemoDashboard;

/**
 * @deprecated Utility can be removed once the old dashboard context filtering is deleted
 */
export const getHasNoFilterableReports = dashboard => {
  const widgets = getWidgets(dashboard);
  return widgets.every(widget => {
    const report = getReport(widget);
    return isSnowflakeReport(report) || isStaticWidget(report) || isJourneyReport(report);
  });
};
export const getDashboardReports = dashboard => {
  const widgets = getWidgets(dashboard);
  return widgets.reduce((reports, widget) => {
    const report = getReport(widget);
    return isStaticWidget(report) ? reports : reports.concat(report);
  }, []);
};
export const getWidgetWithReportId = (dashboard, reportId) => {
  return getWidgets(dashboard).find(widget => {
    return getReportIdFromWidget(widget) === reportId;
  });
};