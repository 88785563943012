import { createSelector } from 'reselect';
import { appliedDashboardFiltersNamespace } from './slice';
import { groupFiltersByDataSource } from './utils';
// @ts-expect-error migrate upstream
import { selectQuickFilterGroups } from '../quickFilters';
import { applySystemGeneratedAndQuickFilterGroupFlags } from '../../lib/dashboardFilters/groupQuickFilterHelpers';
function withState(selector) {
  return state => selector(state[appliedDashboardFiltersNamespace]);
}
const _selectDashboardFilters = dashboardId => withState(state => {
  return state.filters[dashboardId] || [];
});
export const selectDashboardFilters = dashboardId => createSelector([_selectDashboardFilters(dashboardId), selectQuickFilterGroups], applySystemGeneratedAndQuickFilterGroupFlags);
export const selectIsExistingProperty = (property, dashboardId) => createSelector(selectDashboardFilters(dashboardId), filters => {
  return filters.some(filter => filter.propertyName === property.name && filter.dataSource === property.dataSource);
});
export const selectIsDashboardFilterPanelOpen = withState(state => state.isPanelOpen);
export const selectDashboardFiltersGroupedByDataSource = dashboardId => createSelector(selectDashboardFilters(dashboardId), appliedDashboardFilters => {
  return groupFiltersByDataSource(appliedDashboardFilters);
});
export const selectDashboardFrequency = dashboardId => withState(state => dashboardId in state.frequency ? state.frequency[dashboardId].frequency : undefined);
export const selectDashboardFrequencyUseFiscalYear = dashboardId => withState(state => dashboardId in state.frequency ? state.frequency[dashboardId].useFiscalYear || false : false);