'use es6';

import { List } from 'immutable';
import { VisualTypes } from '../../schema/visual-records';
import { getMeasures } from '../../utils/column-utils';
import { applyPivotTotals } from './pivotTotals';
import { applyXTotals, applyYTotals } from './xyTotals';
import { applySlicedTotals } from './slicedTotals';
import { COLUMN_ROLES } from 'reporting-data/constants/relationalReports';
const TOTAL_ALIAS_PREFIX = 'total_';
export const SUBTOTAL_ALIAS_PREFIX = 'subtotal_';
const SUBTOTAL_ALIAS_DELIMETER = '-';
export const toTotalAlias = alias => `${TOTAL_ALIAS_PREFIX}${alias}`;
export const isTotalColumn = column => column.alias.startsWith(TOTAL_ALIAS_PREFIX);
export const toSubtotalAlias = (dimensionAlias, measureAlias) => `${SUBTOTAL_ALIAS_PREFIX}${dimensionAlias}${SUBTOTAL_ALIAS_DELIMETER}${measureAlias}`;
export const isSubtotalColumn = column => column.alias.startsWith(SUBTOTAL_ALIAS_PREFIX);
const toTotalColumn = column => column.update('alias', alias => toTotalAlias(alias)).set('fixedMeasure', true).set('fixed', List());
const applyTableTotals = report => {
  const {
    columns
  } = report;
  const nextColumns = columns.toList().flatMap(column => {
    return column.role === COLUMN_ROLES.DIMENSION ? List.of(column) : List.of(column, toTotalColumn(column));
  }).toMap().mapKeys((_, column) => column.alias);
  return report.set('columns', nextColumns);
};
export const applyTotals = report => {
  const {
    columns,
    visual: {
      type,
      options = {}
    } = {}
  } = report;
  const isSearchRequest = getMeasures(columns).isEmpty();
  if (isSearchRequest) {
    return report;
  }
  if (type === VisualTypes.HORIZONTAL_BAR) {
    return applyXTotals(report);
  }
  if (type === VisualTypes.VERTICAL_BAR || type === VisualTypes.AREA || type === VisualTypes.LINE) {
    return applyYTotals(report);
  }
  if (!options.showTotals) {
    return report;
  }
  if (type === VisualTypes.TABLE) {
    return applyTableTotals(report);
  }
  if (type === VisualTypes.PIVOT_TABLE) {
    return applyPivotTotals(report);
  }
  if (type === VisualTypes.PIE || type === VisualTypes.DONUT) {
    return applySlicedTotals(report);
  }
  return report;
};