'use es6';

import { useState, useEffect } from 'react';
import userInfo from 'hub-http/userInfo';
import { logError } from './logError';
import { UNINITIALIZED, PENDING, SUCCEEDED, FAILED } from '../constants/requestStatusType';
export function useGates(gatesToCheck) {
  const [gates, setGates] = useState([]);
  const [requestStatus, setRequestStatus] = useState(UNINITIALIZED);
  useEffect(() => {
    setRequestStatus(PENDING);
    userInfo().then(({
      gates: allGates
    }) => {
      if (gatesToCheck) {
        setGates(allGates.filter(gate => gatesToCheck.includes(gate)));
      } else {
        setGates(allGates);
      }
      setRequestStatus(SUCCEEDED);
    }).catch(err => {
      logError(err);
      setRequestStatus(FAILED);
    });
  }, [gatesToCheck]);
  return {
    gates,
    isGatesRequestPending: requestStatus === UNINITIALIZED || requestStatus === PENDING
  };
}