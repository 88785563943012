'use es6';

import * as PropertyTypes from '../../../constants/property-types';
import { Map as ImmutableMap } from 'immutable';
export default function overridePropertyTypes(spec) {
  return properties => {
    let overridden = properties.map(property => {
      const propertyKey = property.get('name');
      if (spec.hasIn(['properties', 'types', propertyKey])) {
        return property.set('type', spec.getIn(['properties', 'types', propertyKey]));
      }
      return property;
    });
    overridden = overridden.update(spec.getIn(['properties', 'idProperty']), ImmutableMap(), idPropDef => idPropDef.set('type', PropertyTypes.ENUMERATION));
    return overridden;
  };
}