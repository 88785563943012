'use es6';

import { List } from 'immutable';
import { Promise } from '../../lib/promise';
import { ENGAGEMENT } from '../../constants/dataTypes';
import getCommonPropertyGroups from '../partial/engagement-common';
import { getCallPropertyGroups } from './calls';
import { getConversationPropertyGroups } from './conversations';
import { getNotePropertyGroups } from './notes';
import { getTaskPropertyGroups } from './tasks';
import { getEngagementEmailPropertyGroups } from './engagement-emails';
import { getMeetingPropertyGroups } from './meetings';
import { getCommunicationPropertyGroups } from './communication';
import { getPostalMailPropertyGroups } from './postalMail';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import countProperty from '../partial/count-property';
import engagementModule from '../../dataTypeDefinitions/inboundDb/engagement';
import overridePropertyTypes from '../../retrieve/inboundDb/common/overridePropertyTypes';
export const getPropertyGroups = () => Promise.all([getCommonPropertyGroups(), getCallPropertyGroups(), getConversationPropertyGroups(), getCommunicationPropertyGroups(), getPostalMailPropertyGroups(), getNotePropertyGroups(), getEngagementEmailPropertyGroups(), getMeetingPropertyGroups(), getTaskPropertyGroups()]).then(([commonPropertyGroups, callPropertyGroups, conversationPropertyGroups, communicationPropertyGroups, postalMailPropertyGroups, notePropertyGroups, engagementEmailPropertyGroups, meetingPropertyGroups, taskPropertyGroups]) => List([...commonPropertyGroups, ...callPropertyGroups, ...conversationPropertyGroups, ...communicationPropertyGroups, ...postalMailPropertyGroups, ...notePropertyGroups, ...engagementEmailPropertyGroups, ...meetingPropertyGroups, ...taskPropertyGroups]));
export const getProperties = () => createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(ENGAGEMENT)))().then(overridePropertyTypes(engagementModule.getInboundSpec()));