'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import Superstore, { LocalStorage } from 'superstore';
import { LIST_PROPS, MAP_PROPS } from '../records/Context';
import { getAppName } from './urls';
import { NO_PIPELINE } from './salesAnalyticsHelpers';
const TRANSIENT_PROPS = ['chartMetrics', 'colorTheme', 'customFilters', 'relationalCustomFilters', 'drilldowns', 'metric', 'staticGoal', 'tab', 'question'];
const USER_SELECTION_KEY = 'user-selection';
const PIPELINE_PREFERENCE_KEY = 'pipeline-preference';
const store = new Superstore({
  backend: LocalStorage,
  namespace: getAppName(),
  async: false
});
const getJson = key => {
  try {
    const item = store.get(key);
    return JSON.parse(item);
  } catch (err) {
    console.warn(`storage: failed to get '${key}' from sessionStorage`);
    return null;
  }
};
const setJson = (key, value) => {
  try {
    const item = JSON.stringify(value);
    store.set(key, item);
  } catch (err) {
    console.warn(`storage: failed to set '${value}' as '${key}' in sessionStorage`);
  }
};
const shouldBeInUserSelection = prop => !TRANSIENT_PROPS.includes(prop);
export const getUserSelection = () => {
  const userSelection = getJson(USER_SELECTION_KEY) || {};
  return Object.keys(userSelection).reduce((collect, key) => {
    const val = userSelection[key];
    const parsedVal = LIST_PROPS.includes(key) ? List(val) : MAP_PROPS.includes(key) ? ImmutableMap(val) : val;
    return shouldBeInUserSelection(key) ? Object.assign({}, collect, {
      [key]: parsedVal
    }) : collect;
  }, {});
};
export const updateUserSelection = contextChange => {
  const userSelection = Object.assign({}, getUserSelection(), contextChange);
  const newUserSelection = Object.keys(userSelection).reduce((collect, key) => shouldBeInUserSelection(key) ? Object.assign({}, collect, {
    [key]: userSelection[key]
  }) : collect, {});
  setJson(USER_SELECTION_KEY, newUserSelection);
};
export const getPreferredPipeline = () => store.get(PIPELINE_PREFERENCE_KEY);
export const updatePreferredPipeline = ({
  pipeline
}) => {
  if (pipeline && pipeline !== NO_PIPELINE) {
    store.set(PIPELINE_PREFERENCE_KEY, pipeline);
  }
};