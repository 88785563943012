'use es6';

import http from 'hub-http/clients/apiClient';
import formatConversationInboxes from 'reference-resolvers/formatters/formatConversationInboxes';
export const createGetConversationInboxesById = ({
  httpClient
}) => idsToFetch => httpClient.post(`crm-objects/v1/objects/conversation_inbox/batch-read?properties=hs_inbox_name&properties=hs_deleted_at`, {
  data: {
    ids: idsToFetch
  }
}).then(formatConversationInboxes);
export const getConversationInboxesById = createGetConversationInboxesById({
  httpClient: http
});