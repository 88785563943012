export const __DASHBOARDS_RESET_STATE__ = '__DASHBOARDS_RESET_STATE__';
export const __OWNERS_RESET_STATE__ = '__OWNERS_RESET_STATE__';
export const __REFERENCE_OBJECT_RESET_STATE__ = '__REFERENCE_OBJECT_RESET_STATE__';
export const __OBJECT_DEFINITION_RESET_STATE__ = '__OBJECT_DEFINITION_RESET_STATE__';

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}