import { createSlice } from '@reduxjs/toolkit';
export let AppStatus;
(function (AppStatus) {
  AppStatus[AppStatus["UNINITIALIZE"] = 0] = "UNINITIALIZE";
  AppStatus[AppStatus["INITIALIZED"] = 1] = "INITIALIZED";
  AppStatus[AppStatus["CLOSED"] = 2] = "CLOSED";
})(AppStatus || (AppStatus = {}));
const initialState = {
  isReportViewerIsOpen: false,
  currentReportViewerReport: null,
  previewReport: null,
  appStatus: AppStatus.UNINITIALIZE,
  resourceId: null,
  appTracker: null,
  forceRenderCount: 0
};
const reportViewSlice = createSlice({
  name: 'reportView',
  initialState,
  reducers: {
    initializeApp(state, action) {
      const {
        resourceId
      } = action.payload;
      state.resourceId = resourceId;
    },
    open(state, action) {
      const {
        report,
        previewReport
      } = action.payload;
      state.isReportViewerIsOpen = true;
      state.currentReportViewerReport = report;
      state.previewReport = previewReport;
    },
    close(state) {
      state.isReportViewerIsOpen = false;
      state.currentReportViewerReport = null;
      state.previewReport = null;
    },
    incrementForceRenderCount(state) {
      state.forceRenderCount += 1;
    }
  }
});
export const selectAppState = state => state.reportView.appStatus;
export const selectAppTracker = state => state.reportView.appTracker;
export const selectIsReportViewerIsOpen = state => state.reportView.isReportViewerIsOpen;
export const selectCurrentReportViewerReport = state => state.reportView.currentReportViewerReport;
export const selectPreviewReport = state => state.reportView.previewReport;
export const selectResourceId = ({
  reportView
}) => {
  return reportView.resourceId;
};
export const selectForceRenderCount = ({
  reportView
}) => {
  return reportView.forceRenderCount;
};
export const {
  open,
  close,
  initializeApp,
  incrementForceRenderCount: increment
} = reportViewSlice.actions;
export default reportViewSlice.reducer;