import http from 'hub-http/clients/apiClient';
// @ts-expect-error ts-migrate(2307)
import { isJourney } from 'reporting-action-components/lib/report-logic';
import { refreshWithReportDefinition } from 'reporting-snowflake/relational/resolve/refresh';
import { refresh as refreshJourneyReport } from 'reporting-snowflake/funnel/resolve/refresh';
// @ts-expect-error ts-migrate(2307)
import { isSnowflake } from 'reporting-action-components/lib/report-logic';
// @ts-expect-error ts-migrate(2307)
import { getConfig } from 'reporting-action-components/data/schemas/reportSchema';
// @ts-expect-error ts-migrate(2307)
import { bustOne } from 'reporting-data/bust';
// @ts-expect-error ts-migrate(2307)
import { getReportDefinition } from 'reporting-action-components/data/schemas/reportSchema';
import { getJourneyQuery } from 'reporting-data/report/reportGetters';
const REPORT_URL = 'dashboard/v2/reports';
const REPORT_TEMPLATES_URL = 'reporting-discovery/v1/report-template';
const REPORT_BUILDER_URL = 'sql-reporting/v1/report-builder/cache';
export const deleteSnowflakeReportCache = reportId => {
  return http.delete(`${REPORT_BUILDER_URL}/${reportId}`);
};
export const deleteSnowflakeReportTemplateCache = () => {
  return http.delete(`${REPORT_BUILDER_URL}/custom`);
};
export const fetchReport = reportId => {
  return http.get(`${REPORT_URL}/${reportId}`);
};
export const fetchReportTemplate = reportId => {
  return http.get(`${REPORT_TEMPLATES_URL}/${reportId}`);
};
export const refreshSnowflakeReport = async reportId => {
  await deleteSnowflakeReportCache(reportId);
  const refreshedReport = await fetchReport(reportId);
  return refreshedReport;
};
export const refreshSnowflakeReportTemplate = async reportId => {
  await deleteSnowflakeReportTemplateCache();
  const refreshedReportTemplate = await fetchReportTemplate(reportId);
  return refreshedReportTemplate;
};
export const getReportRefreshPromise = (report, reportId, {
  useReportDefinitionToRefresh
} = {
  useReportDefinitionToRefresh: false
}) => {
  try {
    const isReportTemplate = report.get('templateKey');
    if (isJourney(report)) {
      // NOTE: we need to figure out how to handle journey report template
      const journeyQuery = getJourneyQuery(report);
      return refreshJourneyReport(journeyQuery, reportId);
    }
    if (isSnowflake(report)) {
      if (useReportDefinitionToRefresh) {
        const reportDefinition = getReportDefinition(report); // we could assert reportDefinition is defined, since isSnowflake is check the report definition is defined
        // can not really use this function since it returns a type can't fulfill refreshWithReportDefinition
        // import { getReportDefinition } from 'reporting-data/report/reportGetters';
        return refreshWithReportDefinition(reportDefinition, `${reportId}`);
      }
      return isReportTemplate ? refreshSnowflakeReportTemplate(reportId) : refreshSnowflakeReport(reportId);
    }

    // Single object report
    bustOne(getConfig(report));
    return isReportTemplate ? fetchReportTemplate(reportId) : fetchReport(reportId);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const __TESTABLE__ = {
  REPORT_URL,
  REPORT_TEMPLATES_URL,
  REPORT_BUILDER_URL
};