import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { UNDEFINED_TYPE } from '../../dataset/type-records';
import { Contexts } from '../../dataset/context-records';
import { createColumnAlias, isFieldExpressionBased } from './column-utils';
import { ExpressionField, Field, FieldSources } from '../schema/column-records';
import { checkField } from '../../dataset/dataset-utils';
import { withStaticTypeDependencies } from '../../dataset/expression-meta';
import { checkExpressionType, toPropertyBasicType } from '../../dataset/type-utils';
import { DatasetField } from '../../dataset/dataset-records';
import { SnowflakeProperty } from '../schema/source-records';
import { expressionToList, expressionUpdater, isFieldExpression, isFunctionCallExpression, isPropertyExpression } from '../../dataset/expression-utils';
import { FieldLevelPermissionViolation, NoFunctionAccessViolation, ViolationLevels } from '../../dataset/violation-records';
import { ExpressionTypes, Property } from '../../dataset/expression-records';
import { DATASET_TABLE } from '../schema/table-records';
const getEncodedExpressionFieldsSet = report => {
  const accumulateExpressionsInColumns = (nextExpressionFieldsSet, column) => {
    if (isFieldExpressionBased(column.field)) {
      return nextExpressionFieldsSet.add(column.field.name);
    }
    return nextExpressionFieldsSet;
  };
  const accumulateInFilterGroups = (expressionsAliasSet, filterGroup) => filterGroup.filters.reduce((nextExpressionFieldsSet, filter) => {
    if (isFieldExpressionBased(filter.field)) {
      return nextExpressionFieldsSet.add(filter.field.name);
    }
    return nextExpressionFieldsSet;
  }, expressionsAliasSet);
  const encodedExpressionsInColumns = report.columns.toList().reduce(accumulateExpressionsInColumns, ImmutableSet());
  const encodedExpressionsInStagedColumns = report.stagedColumns.reduce(accumulateExpressionsInColumns, encodedExpressionsInColumns);
  return report.filtering ? report.filtering.groups.reduce(accumulateInFilterGroups, encodedExpressionsInStagedColumns) : encodedExpressionsInStagedColumns;
};
export const reportHasExpressionEncoded = (report, expressionField) => {
  return getEncodedExpressionFieldsSet(report).has(expressionField.alias);
};
export const expressionIsADependency = (report, testExpressionField) => {
  return report.expressions.some(expressionField => {
    if (expressionField.alias !== testExpressionField.alias) {
      const expressionParts = expressionToList(expressionField.expression);
      return expressionParts.some(part => part.type === ExpressionTypes.FIELD && part.name === testExpressionField.alias);
    }
    return false;
  });
};
const getNextExpressionFieldLabel = expressionFields => {
  const derivedFields = expressionFields.filter(field => field.derived);
  const fields = derivedFields.map(field => field.label).toSet();
  let i = 1;
  for (; i < 10000; i++) {
    const newName = `Field ${i}`;
    if (fields.has(newName)) {
      continue;
    } else {
      return newName;
    }
  }
  return `Field ${i}`;
};
export const createExpressionFieldRecord = (expressionFields, label = getNextExpressionFieldLabel(expressionFields)) => {
  return ExpressionField({
    alias: createColumnAlias(),
    label,
    derived: true,
    hidden: false,
    valid: true,
    input: '',
    expression: {
      type: 'NULL_LITERAL',
      value: null
    },
    type: UNDEFINED_TYPE,
    context: Contexts.UNDEFINED
  });
};

/**
 * @param {List<ExpressionField>} expressionFields
 * @returns {ImmutableMap<String, ExpressionField>} - keyed by field.alias
 */
export const expressionFieldsListToMap = expressionFields => expressionFields.toMap().mapKeys((__, field) => field ? field.alias : '').toMap();
export const datasetFieldsListToMap = datasetFields => datasetFields.toMap().mapKeys((__, field) => field ? field.name : '').toMap();
export const expressionFieldToSnowflakeProperty = expressionField => SnowflakeProperty({
  name: expressionField.alias,
  label: expressionField.label,
  type: toPropertyBasicType(expressionField.type),
  groupName: 'EXPRESSION',
  metaDefinition: {},
  flpRestricted: false
});
export const expressionFieldToDatasetField = expressionField => {
  const {
    alias: name,
    label,
    derived,
    hidden,
    valid,
    input,
    expression,
    type,
    context,
    editor
  } = expressionField;
  return DatasetField({
    name,
    label,
    derived,
    hidden,
    valid,
    input,
    expression,
    type,
    context,
    editor
  });
};
export const datasetFieldToExpressionField = datasetField => {
  const {
    name: alias,
    label,
    derived,
    hidden,
    valid,
    input,
    expression,
    type,
    context,
    editor
  } = datasetField;
  return ExpressionField({
    alias,
    label,
    derived,
    hidden,
    valid,
    input,
    expression,
    type,
    context,
    editor
  });
};
export const checkExpressionFieldType = (expressionToCheck, expressionFields, meta) => {
  const {
    datasetDefinition,
    datasetProperties = ImmutableMap()
  } = meta || {};
  const expressionFieldMap = expressionFields.map(expressionFieldToDatasetField).reduce((nextExpressionMap, field) => field && field.name ? nextExpressionMap.set(field.name, field) : nextExpressionMap, ImmutableMap());
  return checkExpressionType(expressionToCheck, withStaticTypeDependencies({
    fields: datasetDefinition ? expressionFieldMap.merge(datasetDefinition.get('fields')) : expressionFieldMap,
    properties: datasetProperties
  }));
};
export const validateExpressionBasedFieldLevelPermissions = (expressionFieldToValidate, expressionFieldMap, meta) => {
  const {
    properties = ImmutableMap()
  } = meta || {};
  const listOfExpressions = expressionToList(expressionFieldToValidate.expression);

  // validate property expressions and field expressions
  const propertyExpressions = listOfExpressions.filter(expressionPart => isPropertyExpression(expressionPart));
  const fieldExpressions = listOfExpressions.filter(expressionPart => isFieldExpression(expressionPart));
  const flpRestrictedPropertyExpressions = propertyExpressions.filter(propertyExpression => properties.hasIn([propertyExpression.table, propertyExpression.name]) && properties.getIn([propertyExpression.table, propertyExpression.name, 'flpRestricted']) === true);
  const violations = flpRestrictedPropertyExpressions.map(propertyExpression => FieldLevelPermissionViolation({
    level: ViolationLevels.ERROR,
    expression: expressionFieldToValidate.expression,
    flpRestrictedFieldLabel: `[${propertyExpression.table}.${propertyExpression.name}]`,
    location: propertyExpression.location
  }));
  const fieldTypeFlpRestrictedExpressions = fieldExpressions.filter(fieldExpression => {
    const fieldName = fieldExpression.name;
    const expressionField = expressionFieldMap.get(fieldName);
    if (!expressionField) {
      // If the expression field does not exist, a reference violation error will be presented to the user
      return false;
    }
    const hasViolationsInChildExpressionField = validateExpressionBasedFieldLevelPermissions(expressionField, expressionFieldMap, meta).size > 0;
    return hasViolationsInChildExpressionField;
  });
  const fieldTypeFlpViolations = fieldTypeFlpRestrictedExpressions.map(restrictedFieldExpression => FieldLevelPermissionViolation({
    level: ViolationLevels.ERROR,
    expression: expressionFieldToValidate.expression,
    flpRestrictedFieldLabel: `[${expressionFieldMap.get(restrictedFieldExpression.name).label}]`,
    location: restrictedFieldExpression.location
  }));
  return violations.concat(fieldTypeFlpViolations);
};
export const checkExpressionField = (fieldToCheck, expressionFields, meta, useNoFunctionAccessValidation = false) => {
  const expressionFieldMap = expressionFields.map(expressionFieldToDatasetField).reduce((nextExpressionMap, field) => field && field.name ? nextExpressionMap.set(field.name, field) : nextExpressionMap, ImmutableMap());
  const {
    datasetDefinition,
    datasetProperties = ImmutableMap()
  } = meta || {};
  const {
    field: datasetField,
    violations
  } = checkField(expressionFieldToDatasetField(fieldToCheck), withStaticTypeDependencies({
    fields: datasetDefinition ? expressionFieldMap.merge(datasetDefinition.get('fields')) : expressionFieldMap,
    properties: datasetProperties
  }));
  const expressionField = datasetFieldToExpressionField(datasetField).update('expression', expression => datasetDefinition && datasetDefinition.has('fields') ? expressionUpdater(expression, expressionPart => {
    if (expressionPart.type === ExpressionTypes.FIELD && expressionPart.name && datasetDefinition.hasIn(['fields', expressionPart.name])) {
      const referencedDatasetField = datasetDefinition.fields.get(expressionPart.name);
      return Property({
        table: DATASET_TABLE,
        propertyType: toPropertyBasicType(referencedDatasetField.type),
        name: referencedDatasetField.name
      });
    }
    return expressionPart;
  }) : expression);
  const fieldLevelPermissionViolations = validateExpressionBasedFieldLevelPermissions(expressionField, expressionFieldsListToMap(expressionFields), meta);
  if (useNoFunctionAccessValidation) {
    const listOfExpressions = expressionToList(datasetField.expression);
    const functionCallExpression = listOfExpressions.find(expression => isFunctionCallExpression(expression));
    if (functionCallExpression) {
      return {
        field: expressionField,
        violations: violations.push(NoFunctionAccessViolation({
          level: ViolationLevels.ERROR,
          expression: expressionField.expression,
          location: functionCallExpression.location
        })).concat(fieldLevelPermissionViolations)
      };
    }
  }
  return {
    field: expressionField,
    violations: violations.concat(fieldLevelPermissionViolations)
  };
};

/**
 * Converts ExpressionField Record to a Field Record
 * @param {ExpressionField} expressionField
 * @returns Field
 */
export const buildFieldFromExpressionField = expressionField => {
  return Field({
    name: expressionField.alias,
    source: FieldSources.EXPRESSION,
    type: toPropertyBasicType(expressionField.type)
  });
};
export const doesFieldReferenceExpression = (field, expressionField) => field.source === FieldSources.EXPRESSION && field.name === expressionField.alias;