'use es6';

import { Map as ImmutableMap } from 'immutable';
import { DEFAULT_NULL_VALUES } from '../../constants/defaultNullValues';
import toJS from '../../lib/toJS';
import * as http from '../../request/http';
import { makeOption } from '../Option';
import { getReferenceLabeler } from '../../crmObjects/references';
import { SALESFORCE_CAMPAIGN } from 'reference-resolvers/constants/ReferenceObjectTypes';
const batch = ids => http.post(`sfdc/v1/campaigns/batch`, {
  data: ids.filter(id => id !== DEFAULT_NULL_VALUES.ENUMERATION)
}).then(toJS);
export default (ids => batch(ids).then(campaigns => campaigns.reduce((options, {
  id,
  name,
  label
}) => options.set(id, makeOption(id, getReferenceLabeler(SALESFORCE_CAMPAIGN)(ImmutableMap({
  name,
  label
}), id))), ImmutableMap())));