/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import I18n from 'I18n';
import { Map as ImmutableMap } from 'immutable';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
// @ts-expect-error Untyped import
import { NewRelicInteraction } from 'reporting-data/monitoring/newrelic';
import { EMAIL } from 'reporting-data/constants/dataTypes';
import { exportDatasetViaRaasApi } from 'reporting-data/public/export-dataset';
import { isUnifiedEventsReport } from 'reporting-data/tsTypes/reportTypes';
import { RelationalReport } from 'reporting-snowflake/relational/schema/report-records';
// @ts-expect-error Untyped import
import { exportSnowflake } from 'reporting-snowflake/relational/enhance/export';
// @ts-expect-error Untyped import
import { get as isUnifiedDataType } from 'reporting-data/retrieve/unified/supported';
// @ts-expect-error Untyped import
import { exportUnified } from 'reporting-data/public/export-unified';
// @ts-expect-error Untyped import
import { exportSalesAnalytics } from 'reporting-data/public/export-sales-analytics';

// @ts-expect-error Untyped import
import { exportEmail } from 'reporting-data/public/export-marketing-email';
import { exportJourney } from 'reporting-snowflake/funnel/enhance/export';
import { isImmutableJourneyReport } from 'reporting-snowflake/funnel/types/journeyReport';
import { getDrilldownFields, isExternalDataTypeReport, isInboundDbReport, isSalesAnalyticsWidget
// @ts-expect-error Untyped import
} from '../../../data/schemas/reportSchema';
import { EVENTS } from '../../../utils/usageTracker';
import { REPORT_EXPORT_FAILURE_TRACKING, REPORT_EXPORT_TRACKING } from '../../../constants/trackingMetrics';
// @ts-expect-error Untyped import
import { isSnowflake } from '../../../lib/report-logic';
import { Metrics } from '../../../monitoring/Metrics';
const EXPORT_PAGE_ACTION = 'ReportExport';
const EXPORT_ERROR_TITLE = 'ReportExportError';
const EXPORT_REPORT_ERROR_DETAILS = 'ReportExportErrorDetails';
const TOO_MANY_METRICS_REQUESTED_FOR_EXPORTS = 'ConfigValidationError.TOO_MANY_METRICS_REQUESTED_FOR_EXPORTS';
const newrelic = new NewRelicInteraction();
export const onSuccess = (report = ImmutableMap({}), usageTracker, trackingProperties) => () => {
  Metrics.counter(REPORT_EXPORT_TRACKING).increment();
  newrelic.addReportAttributes(report);
  newrelic.sendPageAction(EXPORT_PAGE_ACTION);
  if (usageTracker) {
    usageTracker.track(EVENTS.clickPreviewShareAsset, {
      actionType: 'view',
      action: 'export',
      object: 'report',
      assetType: 'report',
      interactionSource: trackingProperties.interactionSource
    });
  }
  FloatingAlertStore.addAlert({
    message: I18n.text('reporting-action-components.actions.exportReport.onSuccess'),
    sticky: true,
    type: 'success',
    'data-selenium-test': 'export-success-alert',
    'data-test-id': 'export-success-alert'
  });
};
export const onFailure = (report = ImmutableMap({})) => {
  return function (error, xhr) {
    Metrics.counter(REPORT_EXPORT_FAILURE_TRACKING).increment();
    const nrLogExportFailure = (newrelicInstance, pageAction) => {
      newrelicInstance.addAttributes({
        failureReason: error,
        reportErrorPhase: EXPORT_ERROR_TITLE,
        responseCode: xhr.status,
        response: xhr.response
      });
      newrelic.addReportAttributes(report);
      newrelicInstance.sendPageAction(pageAction);
    };
    nrLogExportFailure(newrelic, EXPORT_PAGE_ACTION);
    const expectedErrorCodes = [400, 403, 409];
    if (!expectedErrorCodes.includes(xhr.status)) {
      const unsampledNewRelic = new NewRelicInteraction(100);
      unsampledNewRelic.addReportAttributes(report);
      nrLogExportFailure(unsampledNewRelic, EXPORT_REPORT_ERROR_DETAILS);
    }
    let message = '';
    if (expectedErrorCodes.includes(xhr.status)) {
      if (xhr.status === 400) {
        let errorSubcategory = '';
        try {
          errorSubcategory = JSON.parse(xhr.response).subCategory;
        } catch (err) {
          console.warn(err);
        }
        if (errorSubcategory === TOO_MANY_METRICS_REQUESTED_FOR_EXPORTS) {
          message = I18n.text(`reporting-action-components.actions.exportReport.outcome.400.TOO_MANY_METRICS_REQUESTED_FOR_EXPORTS`);
        } else {
          message = I18n.text('reporting-action-components.actions.exportReport.outcome.error');
        }
      } else {
        message = I18n.text(`reporting-action-components.actions.exportReport.outcome.${xhr.status}`);
      }
    } else {
      message = I18n.text('reporting-action-components.actions.exportReport.outcome.error');
    }
    FloatingAlertStore.addAlert({
      message,
      sticky: true,
      type: 'danger'
    });
  };
};
export function doExport(report, exportOptions) {
  newrelic.addReportAttributes(report);
  newrelic.addAttributes(exportOptions);
  newrelic.sendPageAction('ReportingDataExport');
  if (isSnowflake(report)) {
    const reportWithCorrectType = report.update('reportDefinition', definition => RelationalReport(definition));
    return exportSnowflake(reportWithCorrectType, exportOptions);
  } else if (isUnifiedDataType(report.getIn(['config', 'dataType']))) {
    return exportUnified(report, exportOptions);
  } else if (isSalesAnalyticsWidget(report)) {
    return exportSalesAnalytics(report, Object.assign({}, exportOptions, {
      drilldownColumns: getDrilldownFields(report)
    }));
  } else if (isInboundDbReport(report) || isUnifiedEventsReport(report) || isExternalDataTypeReport(report)) {
    return exportDatasetViaRaasApi(report, exportOptions);
  } else if (report.getIn(['config', 'dataType']) === EMAIL) {
    return exportEmail(report, exportOptions);
  } else if (isImmutableJourneyReport(report)) {
    return exportJourney(report, exportOptions);
  }
  return Promise.resolve(false);
}