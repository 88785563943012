'use es6';

import I18n from 'I18n';
import { List } from 'immutable';
import { isFiscalYearSupportedFrequency } from 'reporting-data/lib/fiscalYearUtils';
import { RANGE_TYPES } from 'reporting-ui-components/components/shared/constants/DateRangeConstants';
function getBetweenLabel({
  dateRange,
  explicit
}) {
  const fromDate = I18n.moment(dateRange.get('startDate'));
  const toDate = I18n.moment(dateRange.get('endDate'));
  const numDays = toDate.diff(fromDate, 'days') + 1;
  const style = explicit ? 'long' : 'short';
  return I18n.text(`report.customSeries.customDays.${style}`, {
    numDays
  });
}
function getRollingLabel({
  dateRange,
  explicit
}) {
  const rollingDays = dateRange.get('rollingDays');
  const numDays = parseInt(rollingDays, 10);
  const style = explicit ? 'long' : 'short';
  return I18n.text(`report.customSeries.customDays.${style}`, {
    numDays
  });
}
const getLabelStyle = (explicit, useFiscalYear, dateUnit) => {
  const shouldUseFiscalYear = useFiscalYear && isFiscalYearSupportedFrequency(dateUnit);
  if (explicit) {
    return shouldUseFiscalYear ? 'long-fiscal-year' : 'long';
  }
  return shouldUseFiscalYear ? 'short-fiscal-year' : 'short';
};
function getPriorPeriodOption({
  dateRange,
  explicit,
  useFiscalYear
}) {
  const rangeType = dateRange.get('rangeType');
  const [, dateUnit] = rangeType.split('_');
  const style = getLabelStyle(explicit, useFiscalYear, dateUnit);
  switch (rangeType) {
    case RANGE_TYPES.IS_BEFORE_DATE:
    case RANGE_TYPES.ALL:
      return {
        value: 'PRIOR_PERIOD',
        text: I18n.text(`report.customSeries.priorPeriodComparison.lastPeriod.${style}`),
        disabled: true
      };
    case RANGE_TYPES.IS_EQUAL_TO:
      return {
        value: 'PRIOR_PERIOD',
        text: I18n.text(`report.customSeries.noComparison`),
        disabled: true
      };
    case RANGE_TYPES.CUSTOM:
      return {
        value: 'PRIOR_PERIOD',
        text: getBetweenLabel({
          dateRange,
          explicit
        }),
        disabled: false
      };
    case RANGE_TYPES.ROLLING:
      return {
        value: 'PRIOR_PERIOD',
        text: getRollingLabel({
          dateRange,
          explicit
        }),
        disabled: false
      };
    default:
      break;
  }
  return {
    value: 'PRIOR_PERIOD',
    text: I18n.text(`report.customSeries.timeUnit.${style}.${rangeType}`),
    disabled: false
  };
}
function getPriorYearOption({
  dateRange,
  explicit,
  useFiscalYear
}) {
  const rangeType = dateRange.get('rangeType');
  const [, dateUnit] = rangeType.split('_');
  const style = getLabelStyle(explicit, useFiscalYear, dateUnit);
  const periodType = rangeType === RANGE_TYPES.THIS_YEAR ? 'lastYear' : 'yearBefore';
  return {
    value: 'PRIOR_YEAR',
    text: I18n.text(`report.customSeries.priorYearComparison.${periodType}.${style}`),
    disabled: false
  };
}
export function getCompareOptions({
  dateRange,
  explicit = true,
  compare,
  useFiscalYear = false
}) {
  const rangeType = dateRange.get('rangeType');
  const functionallySame = rangeType === 'THIS_YEAR' || rangeType === 'LAST_YEAR';
  const priorPeriod = getPriorPeriodOption({
    explicit,
    dateRange,
    useFiscalYear
  });
  const priorYear = getPriorYearOption({
    explicit,
    dateRange,
    useFiscalYear
  });
  if (functionallySame) {
    return List.of(functionallySame && compare === 'PRIOR_PERIOD' ? priorPeriod : priorYear);
  }
  return List.of(priorPeriod, priorYear);
}
export function getCompareLabel({
  dateRange,
  explicit = true,
  value,
  useFiscalYear = false
}) {
  const rangeIsThisYear = dateRange.get('rangeType') === RANGE_TYPES.THIS_YEAR;
  const rangeIsLastYear = dateRange.get('rangeType') === RANGE_TYPES.LAST_YEAR;
  const compareIsPriorYear = value === 'PRIOR_YEAR';
  if ((compareIsPriorYear || rangeIsThisYear) && !rangeIsLastYear) {
    return getPriorYearOption({
      explicit,
      dateRange,
      useFiscalYear
    }).text;
  }
  return getPriorPeriodOption({
    explicit,
    dateRange,
    useFiscalYear
  }).text;
}
export const __TESTABLE__ = {
  getPriorPeriodOption,
  getPriorYearOption
};