'use es6';

import * as DSAssetFamilies from 'customer-data-filters/filterQueryFormat/DSAssetFamilies/DSAssetFamilies';
import invariant from 'react-utils/invariant';
export const SELF = 'SELF';
const dynamicFilterFamilyDefinitions = {
  [DSAssetFamilies.IN_LIST]: {
    filterFamily: DSAssetFamilies.IN_LIST,
    regex: RegExp(`${DSAssetFamilies.IN_LIST}-[0-9][-][0-9]+`),
    make: id => `${DSAssetFamilies.IN_LIST}-${id}`,
    parse: filterFamily => filterFamily.replace(`${DSAssetFamilies.IN_LIST}-`, '')
  },
  [DSAssetFamilies.INTEGRATION]: {
    filterFamily: DSAssetFamilies.INTEGRATION,
    regex: RegExp(`${DSAssetFamilies.INTEGRATION}-[0-9]+`),
    make: id => `${DSAssetFamilies.INTEGRATION}-${id}`,
    parse: filterFamily => filterFamily.replace(`${DSAssetFamilies.INTEGRATION}-`, '')
  },
  [DSAssetFamilies.IMPORT]: {
    filterFamily: DSAssetFamilies.IMPORT,
    regex: RegExp(`${DSAssetFamilies.IMPORT}-[0-9][-][0-9]+`),
    make: id => `${DSAssetFamilies.IMPORT}-${id}`,
    parse: filterFamily => filterFamily.replace(`${DSAssetFamilies.IMPORT}-`, '')
  },
  [DSAssetFamilies.UNIFIED_EVENT]: {
    filterFamily: DSAssetFamilies.UNIFIED_EVENT,
    regex: RegExp(`${DSAssetFamilies.UNIFIED_EVENT}-\\w+`),
    make: events_group_name => `${DSAssetFamilies.UNIFIED_EVENT}-${events_group_name}`,
    parse: filterFamily => filterFamily.replace(`${DSAssetFamilies.UNIFIED_EVENT}-`, '')
  },
  [SELF]: {
    filterFamily: SELF,
    regex: RegExp(`[0-9][-][0-9]+__SELF`),
    make: objectTypeId => `${objectTypeId}__SELF`,
    parse: filterFamily => filterFamily.replace(`__SELF`, '')
  }
};
const dynamicFilterFamilies = Object.values(dynamicFilterFamilyDefinitions);
const getDefinitionByFilterFamily = value => dynamicFilterFamilies.find(dff => dff.filterFamily === value);
const getDefinitionByRegexMatch = value => dynamicFilterFamilies.find(({
  regex
}) => regex.test(value));
const filterFamilySupportsDynamic = filterFamily => !!getDefinitionByFilterFamily(filterFamily);

/**
 * Given a filterFamily, attempts to derive the base filter family of the
 * filter family if it is in a dynamic filter family
 * ex) IN_LIST-0-2 -> IN_LIST
 */
const getDynamicFilterFamilyBasis = filterFamily => {
  const definition = getDefinitionByRegexMatch(filterFamily);
  return definition ? definition.filterFamily : undefined;
};
const makeDynamicFilterFamily = (filterFamilyBasis, filterFamily) => {
  const dynamicFilterFamily = getDefinitionByFilterFamily(filterFamilyBasis);
  invariant(dynamicFilterFamily, `Cannot create a dynamic filter family instance of non-dynamic filterFamily: "${filterFamilyBasis}".`);
  return dynamicFilterFamily.make(filterFamily);
};
const parseDynamicFilterFamilyId = (filterFamilyBasis, filterFamily) => {
  const dynamicFilterFamily = getDefinitionByFilterFamily(filterFamilyBasis);
  invariant(dynamicFilterFamily, `Cannot parse id from filter family instance of non-dynamic filterFamily: "${filterFamilyBasis}".`);
  if (!filterFamily) {
    return filterFamily;
  }
  return dynamicFilterFamily.parse(filterFamily);
};

/**
 * Returns true if the filterFamily passes the regex of the dynamic
 * filter family based on the filter family basis supplied
 */
const testDynamicFilterFamily = (filterFamilyBasis, filterFamily) => {
  const dynamicFilterFamily = getDefinitionByFilterFamily(filterFamilyBasis);
  invariant(dynamicFilterFamily, `Cannot test if filter family is instance of non-dynamic filterFamily: "${filterFamilyBasis}".`);
  return dynamicFilterFamily.regex.test(filterFamily);
};
export { filterFamilySupportsDynamic, getDynamicFilterFamilyBasis, makeDynamicFilterFamily, parseDynamicFilterFamilyId, testDynamicFilterFamily };