import isEqual from 'hs-lodash/isEqual';
import pick from 'transmute/pick';
import { getLayout, getReportId
// @ts-expect-error migrate upstream
} from 'dashboard-lib/public/widget/widget-schema';
import { GridParams } from '../constants/GridParams';
const normalizeLayout = layout => {
  if (!layout) return layout;
  const layoutWithRequiredKeys = layout.map(item => pick(['i', 'x', 'y', 'h', 'w', 'maxH', 'maxW', 'static'], item));
  const layoutSortedByI = layoutWithRequiredKeys.sort((a, b) => Number(a.i) - Number(b.i));
  return layoutSortedByI;
};
export const areLayoutsEqual = (layoutA, layoutB) => {
  if (!layoutA && !layoutB) return true;
  if (!layoutA || !layoutB) return false;
  const normalizedLayoutA = normalizeLayout(layoutA);
  const normalizedLayoutB = normalizeLayout(layoutB);
  if (layoutA.length !== layoutB.length) return false;
  return normalizedLayoutA.every((layoutItem, i) => isEqual(layoutItem, normalizedLayoutB[i]));
};
const getWidgetAsGridItem = (widget, canEditWidgets, isSmallScreen = false) => {
  const layout = getLayout(widget);
  const reportId = getReportId(widget);
  return {
    h: layout.get('height'),
    maxH: GridParams.MAX_HEIGHT,
    maxW: GridParams.MAX_WIDTH,
    minH: 1,
    minW: 1,
    w: isSmallScreen ? 1 : layout.get('width'),
    x: layout.get('x'),
    y: layout.get('y'),
    i: String(reportId),
    static: !canEditWidgets
  };
};
export const sortByCoords = widgets => {
  const pathToX = ['layout', 'x'];
  const pathToY = ['layout', 'y'];
  return widgets.sort((a, b) => {
    const aY = a.getIn(pathToY);
    const bY = b.getIn(pathToY);
    if (aY === bY) {
      const aX = a.getIn(pathToX);
      const bX = b.getIn(pathToX);
      return aX > bX ? 1 : aX < bX ? -1 : 0;
    } else {
      return aY > bY ? 1 : -1;
    }
  });
};
export const getLayoutFromWidgets = ({
  widgets,
  canEditWidgets,
  isSmallScreen
}) => !widgets ? null : widgets.map(widget => getWidgetAsGridItem(widget, canEditWidgets, isSmallScreen)).toJS();