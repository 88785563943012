import { List } from 'immutable';
import { getEncodingList, getVisualChannel } from './visual-utils';
import { ChannelNames, VisualTypes } from '../schema/visual-records';
import { getCurrencyNameForColumn } from './column-utils';
import { EncodingWithColumn } from '../schema/column-records';
import { isList } from '../../shared/lib/utility-types';
const createEncodingWithColumn = (encoding, column) => {
  return EncodingWithColumn({
    column,
    options: encoding.options
  });
};
const makeEncodingGetter = channelName => report => {
  const columns = report.columns;
  const visual = report.visual;
  if (!visual) {
    return undefined;
  }
  const maybeEncoding = getVisualChannel(visual, channelName);
  if (!maybeEncoding) {
    return undefined;
  }
  const encoding = maybeEncoding;
  if (!isList(encoding)) {
    const column = columns.get(encoding.column);
    return createEncodingWithColumn(encoding, column);
  }
  return encoding.map(e => {
    const column = columns.get(e.column);
    return createEncodingWithColumn(e, column);
  }).toList();
};
export const getReportColorEncoding = makeEncodingGetter(ChannelNames.COLOR);
export const getReportSizingEncoding = makeEncodingGetter(ChannelNames.SIZING);
export const getReportDetailEncoding = makeEncodingGetter(ChannelNames.DETAIL);
export const getReportColumnsEncodings = makeEncodingGetter(ChannelNames.COLUMNS);
export const getReportBreakdownEncoding = makeEncodingGetter(ChannelNames.BREAKDOWN);
export const getReportGroupEncoding = makeEncodingGetter(ChannelNames.GROUP);
export const getReportRowsEncodings = makeEncodingGetter(ChannelNames.ROWS);
export const getReportValuesEncodings = makeEncodingGetter(ChannelNames.VALUES);
export const getReportSingleValueEncoding = makeEncodingGetter(ChannelNames.SINGLE_VALUE);
export const getReportCompareEncoding = makeEncodingGetter(ChannelNames.COMPARE);
export const getReportXEncoding = makeEncodingGetter(ChannelNames.X);
export const getReportXMultiEncoding = makeEncodingGetter(ChannelNames.X_MULTI);
export const getReportYMultiEncoding = makeEncodingGetter(ChannelNames.Y_MULTI);
export const getReportYEncoding = makeEncodingGetter(ChannelNames.Y);
export const getReportY1Encoding = makeEncodingGetter(ChannelNames.Y_1);
export const getReportY2Encoding = makeEncodingGetter(ChannelNames.Y_2);
export const removeUnencodedColumns = (report, toVisualType) => {
  if (!report.visual) {
    return report;
  }
  const {
    visual: {
      type,
      encodings
    }
  } = report;
  const encodedColumnIds = getEncodingList(encodings).map(encoding => encoding.column);
  const isColumnEncoded = column => encodedColumnIds.includes(column.alias);
  const isRowFormattedVisual = !!toVisualType && toVisualType === VisualTypes.TABLE || !toVisualType && type === VisualTypes.TABLE;
  const currencyColumns = isRowFormattedVisual ? encodedColumnIds.map(alias => getCurrencyNameForColumn(alias)) : List();
  return report.update('columns', columns => columns.filter(column => isColumnEncoded(column) || isRowFormattedVisual && currencyColumns.includes(column.alias)));
};