import memoize from 'transmute/memoize';
// @ts-expect-error migrate upstream
import { fetchDashboard } from '../actions/DashboardActions';
// @ts-expect-error migrate upstream
import DashboardStore from '../stores/DashboardStore';
// TODO consolidate with DashboardsDependency

export const parseDashboardId = memoize(id => {
  const dashboardId = Number(id);
  return isNaN(dashboardId) ? 'default' : dashboardId;
});
export const findDashboardById = id => {
  const dashboardId = parseDashboardId(id);
  return DashboardStore.get(['dashboards', dashboardId]);
};
const getOrFetchDashboardById = (id, fallback = null) => {
  const store = DashboardStore.get();
  if (store.getIn(['dashboardFetched', id])) {
    return findDashboardById(id);
  } else if (!store.getIn(['dashboardFetching', id])) {
    const deferredStore = DashboardStore.get();
    if (!deferredStore.getIn(['dashboardFetching', id])) {
      fetchDashboard(id, {
        record: true
      });
    }
    return fallback;
  }
  return fallback;
};
export const getFetchedDashboardById = id => {
  const dashboardId = Number(id);
  if (isNaN(dashboardId)) {
    return null;
  }
  return getOrFetchDashboardById(dashboardId);
};