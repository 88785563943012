'use es6';

import I18n from 'I18n';
import { fromJS, List, Map as ImmutableMap } from 'immutable';
import { MEETINGS } from '../../constants/dataTypes';
import { ENUMERATION } from '../../constants/property-types';
import engagementModule from '../../dataTypeDefinitions/inboundDb/engagement';
import prefix from '../../lib/prefix';
import overridePropertyTypes from '../../retrieve/inboundDb/common/overridePropertyTypes';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import countProperty from '../partial/count-property';
import getCommonPropertyGroups from '../partial/engagement-common';
import getQuotasProperties from '../partial/quotas';
import getMeetingOutcomeOptions from '../partial/meeting-outcome-options';
const translateMeetingSources = prefix('reporting-data.properties.meetings.sources');
const translateMeetings = prefix('reporting-data.properties.meetings');
const translateGroup = prefix('reporting-data.groups.engagement');
export const getMeetingPropertyGroups = () => getMeetingOutcomeOptions().then(meetingOutcomeOptions => List.of(ImmutableMap({
  name: 'meetingInfo',
  displayName: translateGroup('meetingInfo'),
  displayOrder: 0,
  hubspotDefined: true,
  properties: List([ImmutableMap({
    name: 'meeting.body',
    label: translateMeetings('description'),
    type: 'string'
  }), ImmutableMap({
    name: 'meeting.title',
    label: translateMeetings('title'),
    type: 'string'
  }), ImmutableMap({
    name: 'meeting.source',
    label: translateMeetings('source'),
    type: 'enumeration',
    options: fromJS([{
      value: 'CRM_UI',
      label: translateMeetingSources('crmUI')
    }, {
      value: 'INTEGRATION',
      label: translateMeetingSources('integration')
    }, {
      value: 'BIDIRECTIONAL_API',
      label: translateMeetingSources('bidirectionalApi')
    }, {
      value: 'BIDIRECTIONAL_SYNC',
      label: translateMeetingSources('bidirectionalSync')
    }, {
      value: 'MEETINGS_EMBEDDED',
      label: translateMeetingSources('meetingsEmbedded')
    }, {
      value: 'MEETINGS_PUBLIC',
      label: translateMeetingSources('meetingsPublic')
    }])
  }), ImmutableMap({
    name: 'meeting.startTime',
    label: translateMeetings('startTime'),
    type: 'datetime'
  }), ImmutableMap({
    name: 'meeting.meetingOutcome',
    label: translateMeetings('outcome'),
    type: ENUMERATION,
    options: meetingOutcomeOptions
  }), ImmutableMap({
    name: 'hs_internal_meeting_notes',
    label: I18n.text('reporting-data.properties.meetings.internalNotes'),
    type: 'string'
  })])
})));
export const getPropertyGroups = () => Promise.all([getCommonPropertyGroups(), getMeetingPropertyGroups()]).then(([commonPropertyGroups, meetingPropertyGroups]) => List([...commonPropertyGroups, ...meetingPropertyGroups]));
export const getProperties = () => createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(MEETINGS)).merge(getQuotasProperties()))().then(overridePropertyTypes(engagementModule.getInboundSpec()));