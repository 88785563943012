export const LIST_FILTER_GROUP_ID = 1;
export const PROPERTY_FILTER_GROUP_ID = 2;
const getJourneyObjectTable = report => report.journeyQuery.objectQuery.table;
export const getJourneyObjectTypeId = report => getJourneyObjectTable(report).objectTypeId;
export const getJourneyDataTypeName = report => getJourneyObjectTable(report).name;
export const getObjectFiltering = report => report.journeyQuery.objectQuery.filtering;
const getFilterGroupById = (report, id) => {
  const objectFiltering = getObjectFiltering(report);
  const filterGroups = objectFiltering ? objectFiltering.groups : [];
  return filterGroups.find(group => group.id === id);
};
export const getListFilterGroup = report => getFilterGroupById(report, LIST_FILTER_GROUP_ID);
export const getObjectListFilterValue = report => {
  const filterGroup = getListFilterGroup(report);
  return filterGroup ? filterGroup.filters[0].filter.listId : undefined;
};
export const getPropertyFilterGroup = report => getFilterGroupById(report, PROPERTY_FILTER_GROUP_ID);
export const getReportEvents = report => {
  const allEvents = report.journeyQuery.stages.map(stage => stage.steps.map(step => step.query.table.name)).flat();
  return [...new Set(allEvents)];
};
export const getAllowAnonymousVisitors = report => report.journeyQuery.allowAnonymousVisitors;