'use es6';

import curry from 'transmute/curry';
import { AssetPermission, asNumber } from './asset-permission';
export const computeMaxUserPermission = (createAndOwnScope, editScope, reportingAccessScope, adminScope) => {
  // Note: this order means admin takes precedence over "no reporting access"
  if (adminScope) {
    return AssetPermission.COMPLETE;
  }
  if (!reportingAccessScope) {
    return AssetPermission.ZERO;
  }
  if (createAndOwnScope) {
    return AssetPermission.CREATE_AND_OWN;
  }
  if (editScope) {
    return AssetPermission.EDIT;
  }
  return AssetPermission.VIEW;
};
export const computeMinUserPermission = adminAccess => {
  return adminAccess ? AssetPermission.COMPLETE : AssetPermission.ZERO;
};
const lessThan = (left, right) => {
  return asNumber(left) < asNumber(right);
};
const lessThanOrEqual = (left, right) => {
  return asNumber(left) <= asNumber(right);
};
const moreThan = (left, right) => {
  return asNumber(left) > asNumber(right);
};
const moreThanOrEqual = (left, right) => {
  return asNumber(left) >= asNumber(right);
};
const betweenInclusive = (max, min, subject) => lessThanOrEqual(subject, max) && moreThanOrEqual(subject, min);
const max = (left, right) => lessThan(left, right) ? right : left;

/**
 * Determines whether the given permission is sufficient (equal to or higher)
 * compared to the required permission
 * @param minimumPermission: AssetPermission
 * @param subject: AssetPermission
 * @returns {boolean}
 */
export const hasSufficientPermission = curry((minimum, subject) => moreThanOrEqual(subject, minimum));

/**
 * Helper: checks if given permission is EDIT or above permission
 */
export const hasSufficientEditPermission = hasSufficientPermission(AssetPermission.EDIT);

/**
 * Helper: checks if given permission is CREATE_AND_OWN or above permission
 */
export const hasSufficientCreatePermission = hasSufficientPermission(AssetPermission.CREATE_AND_OWN);
export const computeFinalPermission = (userMin, userMax, asset) => {
  /**
   * For edge case when max permission is set lower than min:
   * 1. We will ignore the userMax permission
   * 2. Take the maximum between asset and userMin
   */
  if (lessThan(userMax, userMin)) {
    /*
    Graphically:
       ------------------ (maximum possible permission; most freedom)
       asset is higher than userMin => asset
       ------------------ (userMin)
       asset is lower than userMin => userMin
       ------------------ (userMax; ignored)
       asset is lower than userMin => userMin
       ------------------ (minimum possible permission; least freedom)
      */
    return max(userMin, asset);
  }

  /**
   * For when userMin is set equal to userMax:
   * 1. Return the userMin
   */
  if (userMin === userMax) {
    /*
    Graphically:
       ------------------ (maximum possible permission; most freedom)
       asset is higher than userMin => userMax (equal to userMin)
       ------------------ (userMin === userMax)
       asset is lower than userMin => userMin
       ------------------ (minimum possible permission; least freedom)
      */
    return userMin;
  }

  /*
    Graphically:
       ------------------ (maximum possible permission; most freedom)
       asset is higher than userMax => userMax
       ------------------ (userMax)
       asset is between (inclusive) userMax and userMin => asset
       ------------------ (userMin)
       asset is lower than userMin => userMin
       ------------------ (minimum possible permission; least freedom)
      */
  if (moreThan(asset, userMax)) {
    return userMax;
  } else if (betweenInclusive(userMax, userMin, asset)) {
    return asset;
  } else {
    return userMin;
  }
};