'use es6';

import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import { getConversationInboxesById, createGetConversationInboxesById } from 'reference-resolvers/api/ConversationInboxAPI';
import { fetchSearchPage, createFetchSearchPage } from 'reference-resolvers/api/CrmSearchAPI';
import { CONVERSATION_INBOX } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { getLabel, getArchived } from 'reference-resolvers/formatters/formatConversationInboxes';
const searchOptions = {};
const searchQueryParams = {};
const formatterOptions = {
  getters: {
    getLabel,
    getArchived
  }
};
export const createConversationInboxReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.CONVERSATION_INBOX,
  createFetchByIds: createGetConversationInboxesById,
  fetchByIds: getConversationInboxesById,
  createFetchSearchPage: opts => createFetchSearchPage(opts)(CONVERSATION_INBOX, {
    searchOptions,
    searchQueryParams,
    formatterOptions
  }),
  fetchSearchPage: fetchSearchPage(CONVERSATION_INBOX, {
    searchOptions,
    searchQueryParams,
    formatterOptions
  })
}, options));
export default createConversationInboxReferenceResolver();