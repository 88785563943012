'use es6';

export const ADS_PROPERTY = 'Ads';
export const COMPANY_PROPERTY = 'CompanyProperty';
export const COMPANY_PROPERTY_VALUE = 'CompanyPropertyValue';
export const CONTACT_PROPERTY = 'ContactProperty';
export const CTA = 'CTA';
export const DEAL_PROPERTY = 'DealProperty';
export const EMAIL_CAMPAIGN = 'Email';
export const EMAIL_SUBSCRIPTION = 'Subscriptions';
export const ENGAGEMENT_PROPERTY = 'Engagement';
export const EVENT = 'Event';
export const FORM = 'FormSubmission';
export const IMPORT = 'Import';
export const INTEGRATION = 'IntegrationsTimeline';
export const LINE_ITEM_PROPERTY = 'LINE_ITEM';
export const LIST_MEMBERSHIP = 'ListMembership';
export const PAGE_VIEW = 'PageView';
export const PRODUCT_PROPERTY = 'PRODUCT';
export const PROPERTY_VALUE = 'PropertyValue';
export const SURVEY_MONKEY = 'SurveyMonkey';
export const SURVEY_MONKEY_QUESTION = 'SurveyMonkeyQuestion';
export const WEBINAR = 'Webinar';
export const WORKFLOW = 'Workflow';