'use es6';

import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
import { fromJS, List } from 'immutable';
import indexBy from 'transmute/indexBy';
import get from 'transmute/get';
const formatEventReference = event => {
  return new ReferenceRecord({
    id: event.id,
    label: event.name || event.label,
    referencedObject: fromJS(event)
  });
};
const formatEvents = events => {
  return indexBy(get('id'), List(events).map(formatEventReference));
};
export default formatEvents;