import http from 'hub-http/clients/apiClient';
import { fromJS } from 'immutable';
import { stringify } from 'hub-http/helpers/params';
const isApplyAdhocFiltersParams = params => 'filters' in params;
export const getDashboardWithFiltersApplied = (dashboardId, params) => {
  const getDashboardWithOverriddenReportsPromise = (() => {
    if (isApplyAdhocFiltersParams(params)) {
      return http.post(`dashboard/v2/dashboard/${dashboardId}/apply-filter`, {
        data: {
          dashboardId,
          name: 'adhoc-filters',
          // Needed for api request to succeed
          filters: params.filters || [],
          frequency: params.frequency,
          useFiscalYear: params.useFiscalYear
        }
      });
    }
    const queryParams = stringify(params);
    return http.get(`dashboard/v2/dashboard/${dashboardId}?${queryParams}`);
  })();
  return getDashboardWithOverriddenReportsPromise.then(response => {
    return fromJS(response); // next dashboard object
  }).catch(error => {
    console.error(error);
    throw error;
  });
};
export const validateBEAppliedDashboardFilters = (dashboardId, filterSet, reportsWithFEAppliedFilters) => {
  return http.post(`dashboard-validation/v2/validate-filter/dashboard/${dashboardId}`, {
    data: {
      dashboardFiltersRequest: Object.assign({}, filterSet, {
        dashboardId,
        name: 'VALIDATION_FILTER_SET'
      }),
      filteredReports: reportsWithFEAppliedFilters
    }
  });
};