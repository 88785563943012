'use es6';

import { useDialogState } from './DialogProvider';
export const useShowDialog = () => {
  const {
    showDialog
  } = useDialogState();
  return showDialog;
};
export const useHideDialog = () => {
  const {
    hideDialog
  } = useDialogState();
  return hideDialog;
};