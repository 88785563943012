'use es6';

import memoize from 'transmute/memoize';
import * as ComputePermission from 'dashboard-lib/public/permission/compute-permission';
import { REPORTING_ACCESS, REPORTING_ADMIN, REPORTING_CREATE_AND_OWN, REPORTING_EDIT, SUPER_ADMIN, SUPER_USER } from 'dashboard-lib/public/scope/scope-name';
import { evaluateLogic } from 'dashboard-lib/public/access-language/eval';
import { thunkify } from 'dashboard-lib/private/js-util';
import { hasScope } from 'dashboard-lib/public/user-info/user-info-schema';
import { AssetPermission } from 'dashboard-lib/public/permission/asset-permission';
const lazyScopeChecker = thunkify(hasScope);
const getReportingAccessScope = hasScope(REPORTING_ACCESS);
const getReportingCreateAndOwnScope = hasScope(REPORTING_CREATE_AND_OWN);
const getReportingEditScope = hasScope(REPORTING_EDIT);
const adminAccess = userInfo => lazyScopeChecker(REPORTING_ADMIN, userInfo) || lazyScopeChecker(SUPER_USER, userInfo) || lazyScopeChecker(SUPER_ADMIN, userInfo);
export const hasAdminAccess = userInfo => evaluateLogic(adminAccess(userInfo));
const computeMaxUserPermission = userInfo => {
  if (userInfo) {
    return ComputePermission.computeMaxUserPermission(getReportingCreateAndOwnScope(userInfo), getReportingEditScope(userInfo), getReportingAccessScope(userInfo), hasAdminAccess(userInfo));
  }
  return AssetPermission.ZERO;
};
const memoizedGet = memoize(computeMaxUserPermission);

/**
 * @deprecated: use only with class components
 */
export const getMaxUserAssetPermissionFromUserInfo = memoizedGet;