export const getSavedFilterStateForDashboard = (state, dashboardId) => {
  return dashboardId in state ? state[dashboardId] : undefined;
};
export const updateSavedDashboardFiltersState = (state, dashboardId, updater) => {
  const maybeFilterState = getSavedFilterStateForDashboard(state, dashboardId);
  if (maybeFilterState) {
    updater(maybeFilterState);
  } else {
    state[dashboardId] = {
      dashboardId,
      filterSets: []
    };
    updater(state[dashboardId]);
  }
};