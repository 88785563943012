import { useEffect, useContext } from 'react';
import { CopilotChannelContext } from '../CopilotChannelContext';
export function useSubscribeToCopilotChannel(topic, onTopic) {
  const {
    channel
  } = useContext(CopilotChannelContext);
  useEffect(() => {
    if (!channel) {
      return () => {};
    }
    const unsubscribe = channel.subscribe(topic, onTopic);
    return () => {
      unsubscribe();
    };
  }, [channel, onTopic, topic]);
}