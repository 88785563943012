import { List, Map as ImmutableMap, Set as ImmutableSet, fromJS } from 'immutable';
// @ts-expect-error migrate upstream
import * as storage from 'dashboard-lib/private/js-util/storage';
import PortalIdParser from 'PortalIdParser';
// @ts-expect-error migrate upstream
import defaults from '../config/defaults';
/**
 * Public version identifier
 * @type {String}
 */
export const CONTEXT_VERSION = '1';
const getStorageKey = (version = CONTEXT_VERSION) => {
  return version ? `@@dashboard:context:${version}@@` : '@@dashboard:contexts@@';
};

/**
 * Max number of contexts to persist
 *
 * @constant
 * @private
 */
const MAX_ENTITIES = 10;

/**
 * Base persisted context object
 *
 * @constant
 * @private
 */
const Interface = {
  result: [],
  entities: {
    contexts: {}
  }
};

/**
 * Encode portal ID and dashboard ID to hash key
 */
const encode = (portalId, id) => {
  return [portalId, id].join(':');
};

/**
 * Decode portal ID and dashboard ID from hash key
 * @returns {object}       Portal ID and dashboard ID pair
 */
const decode = key => {
  const [portalId, id] = key.split(':');
  return {
    portalId: parseInt(portalId, 10),
    id: parseInt(id, 10) || id
  };
};

/**
 * Check if valid persisted context object
 *
 * @param   {object}  state Maybe persisted context object
 * @returns {boolean}       Whether valid persisted context object
 * @private
 */
const validate = state => {
  return state && Array.isArray(state.result) && typeof state.entities === 'object' && typeof state.entities.contexts === 'object';
};

/**
 * Get portal specific persisted contexts from local storage
 *
 * @returns {Interface} Portal specific persisted contexts
 * @throws
 * @private
 */
const raw = ({
  version = CONTEXT_VERSION
} = {}) => {
  const storageKey = getStorageKey(version);
  try {
    const state = JSON.parse(storage.get(storageKey));
    if (!validate(state)) {
      throw new Error('persisted contexts are not formatted as entities');
    }
    return state;
  } catch (err) {
    storage.remove(storageKey);
  }
  return Interface;
};

/**
 * Wipes current storage key
 */
export const wipeCurrentVersion = () => {
  storage.remove(getStorageKey());
};

/**
 * Set persisted context in local storage
 * Dont't allow setting with old version :)
 * (We can handle wiping the outdated/unused data later)
 *
 * @param   {number} id      Dashboard ID
 * @param   {object} context Dashboard context to persist
 * @returns {void}
 * @throws
 */
export const set = (id, context) => {
  const {
    result,
    entities: {
      contexts
    }
  } = raw();
  const key = encode(PortalIdParser.get(), id);
  if (result.includes(key)) {
    const index = result.indexOf(key);
    result.splice(index, 1);
    delete contexts[key];
  }
  while (result.length >= MAX_ENTITIES) {
    delete contexts[result.shift()];
  }
  storage.set(getStorageKey(), JSON.stringify({
    result: [...result, key],
    entities: {
      contexts: Object.assign({}, contexts, {
        [key]: context
      })
    }
  }));
};

/**
 * Get persisted context in local storage by portal ID
 *
 * @param   {Interface} state Persisted contexts
 * @returns {Interface}       Parsed persisted contexts
 */
export const get = ({
  version = CONTEXT_VERSION
} = {}) => {
  const {
    result,
    entities: {
      contexts
    }
  } = raw({
    version
  });
  return result.reduce((output, key) => {
    const {
      portalId,
      id
    } = decode(key);
    if (portalId === PortalIdParser.get()) {
      return {
        result: [...output.result, id],
        entities: {
          contexts: Object.assign({}, output.entities.contexts, {
            [id]: contexts[key]
          })
        }
      };
    }
    return output;
  }, Interface);
};
const upgradeOwner = context => {
  if (context.hasIn(['owner', 'owners'])) {
    return context.deleteIn(['owner', 'hubspot_owner_id']);
  }
  const owner = context.getIn(['owner', 'hubspot_owner_id'], null);
  if (owner !== null) {
    return context.setIn(['owner', 'owners'], List([owner])).deleteIn(['owner', 'hubspot_owner_id']);
  }
  return context.delete('owner');
};
const upgradeTeam = context => {
  if (context.hasIn(['team', 'teams'])) {
    return context.deleteIn(['team', 'teamId']);
  }
  const team = context.getIn(['team', 'teamId'], 'all');
  if (team !== 'all') {
    return context.setIn(['team', 'teams'], List([team])).deleteIn(['team', 'teamId']);
  }
  return context.delete('team');
};
const removeDefaults = context => {
  return context.filter((value, key) => !defaults.get(key).equals(value));
};
const upgradeContext = context => {
  const contextKeys = ImmutableSet(['context', 'pipeline', 'owner', 'team']);
  const filtered = context.filter((_, key) => contextKeys.has(key));
  const upgraded = upgradeOwner(upgradeTeam(filtered));
  return removeDefaults(upgraded);
};

/**
 * Upgrade old storage and persist in current version
 * @param  {obj} options.prevStorage Previous storage object
 */
export const upgrade = ({
  prevStorage = get({
    version: 'null'
  })
} = {}) => {
  const ids = prevStorage.result;
  const contexts = prevStorage.entities.contexts;
  ImmutableSet(ids).forEach(dashboardId => {
    const context = upgradeContext(fromJS(contexts[dashboardId])).toJS();
    set(dashboardId, context);
  });
};

/**
 * Gets the state of current storage as a map
 * @return {Immutable.Map} Map of current storage by dash id
 */
export const getContextMap = () => {
  if (!storage.has(getStorageKey()) && storage.has(getStorageKey('null'))) {
    upgrade();
  }
  const {
    result = [],
    entities: {
      contexts = {}
    }
  } = get();
  return ImmutableSet(result).reduce((configs, id) => configs.set(id, fromJS(contexts[id] || {})), ImmutableMap());
};

/**
 * Public interface for testable values/methods
 *
 * @constant
 * @private
 * @testable
 */
export const __TESTABLE__ = {
  MAX_ENTITIES,
  Interface,
  raw,
  encode,
  decode,
  validate,
  upgradeContext
};