'use es6';

import curry from 'transmute/curry';
import { AssetPermission } from '../../../public/permission/asset-permission';
import { hasSufficientPermission } from '../../../public/permission/compute-permission';
import { getAndCheck } from '../common/get-and-check-param';
import { createProblem } from '../common/problem';
import { MAX_USER_ASSET_PERMISSION } from '../params/all-params';
const _notEnoughPermission = curry((minimumPermission, params) => {
  return !hasSufficientPermission(minimumPermission, getAndCheck(MAX_USER_ASSET_PERMISSION, params));
});
export const NEED_EDIT_PERMISSION = createProblem('NEED_EDIT_PERMISSION', _notEnoughPermission(AssetPermission.EDIT));
export const NEED_CREATE_AND_OWN_PERMISSION = createProblem('NEED_WRITE_PERMISSION', _notEnoughPermission(AssetPermission.CREATE_AND_OWN));