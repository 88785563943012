'use es6';

import { VisualTypes } from '../../../schema/visual-records';
import { getReportColumnsEncodings, getReportRowsEncodings, getReportValuesEncodings, getReportVisualType } from '../../../utils/report-utils';
import { combineConstraints, ConstraintTypes, createConstraint, InvalidSpecificationTypes } from '../../validate';
import { columnChannelsAreDimensions, columnChannelsExpandedLength, columnChannelsLength, rowChannelsAreDimensions, rowChannelsExpandedLength, rowChannelsLength } from '../channels';
import { checkAllDateDimensionsHaveTransform } from './check-all-date-dimensions-have-transformation';
export const pivotTableVisualIsComplete = createConstraint('completionCriteria', ConstraintTypes.HARD, InvalidSpecificationTypes.INCOMPLETE, (columns, rows, values) => columns.size > 0 || rows.size > 0 || values.size > 0, [report => getReportColumnsEncodings(report), report => getReportRowsEncodings(report), report => getReportValuesEncodings(report)]);
const dateDimensionsHaveTransformation = createConstraint('dateDimensionsHaveTransformation', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, checkAllDateDimensionsHaveTransform, [report => report.columns]);
export const pivotTable = combineConstraints('pivotTable', [pivotTableVisualIsComplete, rowChannelsAreDimensions, rowChannelsLength, columnChannelsAreDimensions, columnChannelsLength, dateDimensionsHaveTransformation], (constraint, report, userInfo) => getReportVisualType(report) !== VisualTypes.PIVOT_TABLE || constraint.validate(report, userInfo));
export const pivotTableExpanded = combineConstraints('pivotTable', [pivotTableVisualIsComplete, rowChannelsAreDimensions, rowChannelsExpandedLength, columnChannelsAreDimensions, columnChannelsExpandedLength, dateDimensionsHaveTransformation], (constraint, report, userInfo) => getReportVisualType(report) !== VisualTypes.PIVOT_TABLE || constraint.validate(report, userInfo));