/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import quickFetch from 'quick-fetch';
import http from 'hub-http/clients/apiClient';
import { warn } from 'react-utils/devLogger';
import { getUserInfo } from './UserInfoAPI';
const BASE_OPTINS_URL = '/optins/v1/availability';
const ERROR = 'Error';
const USE_QUICK_FETCH_WARNING = {
  key: 'optin-quick-fetch',
  message: 'You are using ui-addon-opt without quick-fetch. Quick-fetching the optin state results in better performance and user experience.',
  url: 'https://git.hubteam.com/HubSpot/ui-addon-opt#quick-fetch-support'
};
const displayUseQuickFetchWarning = () => {
  warn(USE_QUICK_FETCH_WARNING);
};
const availabilityUrl = (optinName, hubOverride) => `${BASE_OPTINS_URL}/${optinName}${hubOverride ? '/hub-override' : ''}`;
const toError = error => {
  throw new Error(error && error.responseJSON && error.responseJSON.message || ERROR);
};
export const fetchOptinAvailability = optinName => http.get(availabilityUrl(optinName)).catch(toError);
const getQuickFetchedOptinAvailability = optinName => {
  const quickFetchKey = `optin-${optinName}`;
  const quickFetchedOptinAvailability = quickFetch.getRequestStateByName(quickFetchKey);
  if (quickFetchedOptinAvailability) {
    return new Promise((resolve, reject) => {
      quickFetchedOptinAvailability.whenFinished(availability => {
        resolve(availability);
        quickFetch.removeEarlyRequest(quickFetchKey);
      });
      quickFetchedOptinAvailability.onError(() => {
        fetchOptinAvailability(optinName).then(resolve, reject);
        quickFetch.removeEarlyRequest(quickFetchKey);
      });
    });
  }
  return null;
};
const getOptinAvailability = optinName => {
  const quickFetchedOptinAvailabilityPromise = getQuickFetchedOptinAvailability(optinName);
  if (quickFetchedOptinAvailabilityPromise) {
    return quickFetchedOptinAvailabilityPromise;
  } else {
    displayUseQuickFetchWarning();
  }
  return fetchOptinAvailability(optinName);
};
export const fetchUserOptSettings = optinName => Promise.all([getUserInfo().then(({
  user
}) => user), getOptinAvailability(optinName)]).then(([user, availability]) => ({
  availability,
  user
}));
export const updateOpt = ({
  availability: {
    stateActionAt
  },
  optinName,
  hubOverride,
  newOptinState
}) => http.put(`${availabilityUrl(optinName, hubOverride)}/${newOptinState}?currentActionAt=${stateActionAt}`).catch(toError);