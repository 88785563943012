import { checkForEmbedScripts } from './embed';
import { queryParamsDefaults, supportedMimeTypes, urlPatterns, EmbedFormats, lazyLoadingRegex } from '../constants/embed';
import htmlToEmbedData from '../utils/htmlToEmbedData';
import { LazyLoadingTypes } from '../constants/shared';
export const guessMime = url => {
  if (!url) {
    return '';
  }
  url = url.toLowerCase();
  const match = Object.keys(supportedMimeTypes).find(key => url.indexOf(`.${key}`) !== -1);
  return match ? supportedMimeTypes[match] : '';
};
function getEmbedDataFromUrl(url) {
  const urlPattern = urlPatterns.find(pattern => pattern.regex.test(url));
  if (!urlPattern) {
    return null;
  }
  const match = urlPattern.regex.exec(url);
  let src = urlPattern.url;
  if (match === null) {
    return null;
  }

  /* eslint no-loop-func: 0 */
  for (let i = 0; match[i]; i++) {
    src = src.replace(`$${i}`, () => {
      return match[i];
    });
  }
  if (queryParamsDefaults[urlPattern.service]) {
    const queryParams = Object.assign({}, queryParamsDefaults[urlPattern.service]);
    if (src.indexOf('?') >= 0 && src.length > src.indexOf('?') + 1) {
      const params = src.substring(src.indexOf('?') + 1).split('&');
      src = src.substring(0, src.indexOf('?'));
      for (let i = 0; params[i]; i++) {
        if (params[i].indexOf('=') >= 0) {
          const paramName = params[i].substring(0, params[i].indexOf('='));
          const paramValue = params[i].substring(params[i].indexOf('=') + 1);
          queryParams[paramName] = paramValue;
        }
      }
    }
    const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
    src = `${src}?${queryString}`;
  }
  const data = {
    attributes: [{
      key: 'allowFullScreen',
      value: urlPattern.allowFullscreen
    }, {
      key: 'height',
      value: urlPattern.h
    }, {
      key: 'width',
      value: urlPattern.w
    }, {
      key: 'originalUrl',
      value: url
    }, {
      key: 'service',
      value: urlPattern.service
    }, {
      key: 'src',
      value: src
    }],
    tag: urlPattern.type
  };
  return [data];
}
function getEmbedDataFromHtml(html) {
  if (!html) {
    return null;
  }
  return htmlToEmbedData(html);
}
export function getEmbedData(modalData) {
  if (!modalData) {
    return null;
  }
  if (modalData.url) {
    return getEmbedDataFromUrl(modalData.url);
  }
  return getEmbedDataFromHtml(modalData.embed);
}

// Matches: ['https://', 'http://', '//'] and cannot contain whitespace
const EmbedUrlRegex = /^(?:http(?:s)?:)?\/\/[^\s/$.?#].[^\s]*$/;

// Checks for valid opening and closing html tags:
// <tag ... /> || <tag>....</tag>
// Optionally start/end with <!-- comment --> tags
// Optionally start with <!--[if ...]> tag and end with <![endif]--> tags
const EmbedHTMLRegex = /^((<!--.+-->)|(<!(--)?\[if.+\]>))?\s*<([a-zA-Z1-6]+)[\w\W]+((\/>)|(<\/([a-zA-Z1-6]+)>)|(<!--.+-->)|(<!\[endif\](--)?>))$/;
// Checks for simple opening and closing html tags
const GenericEmbedHTMLRegex = /^<(\s|\S)+>$/;
const ScriptEmbedHublRegex = /^{{(\s)?script_embed\([\w\W]+\)(\s)?}}$/;

// Checks if the embed is a URL or an embed code
export function getEmbedType(embed) {
  const urlMatch = EmbedUrlRegex.exec(embed);
  if (urlMatch) {
    return EmbedFormats.URL;
  }
  let embedMatch = EmbedHTMLRegex.exec(embed);
  if (embedMatch) {
    return EmbedFormats.EMBED;
  }
  embedMatch = GenericEmbedHTMLRegex.exec(embed);
  if (embedMatch) {
    return EmbedFormats.GENERIC_EMBED;
  }
  return null;
}
export function validateEmbedUrl(url) {
  return EmbedUrlRegex.test(url);
}
export function validateEmbedHtml(html) {
  return EmbedHTMLRegex.test(html) || ScriptEmbedHublRegex.test(html);
}
export function defaultValidateEmbed({
  url,
  embed
}) {
  if (url) {
    return validateEmbedUrl(url);
  } else if (embed) {
    return validateEmbedHtml(embed);
  }
  return false;
}
export function generateIframeEmbed(data) {
  const allowFullscreen = data.allowFullscreen ? 'allowFullscreen="1"' : '';
  return `<iframe class="hs-responsive-embed-iframe" src="${data.url}" width="${data.width}" height="${data.height}" ${allowFullscreen} data-service="${data.service}"></iframe>`;
}
export const convertUrlToEmbedData = (pastedUrl = '') => {
  const url = pastedUrl.trim();
  return EmbedUrlRegex.exec(url) && {
    url
  };
};
const fancySingleQuotes = /[\u2018\u2019]/g;
const fancyDoubleQuotes = /[\u201C\u201D]/g;
export const sanitizeEmbedHtml = (html = '') => html.replace(fancySingleQuotes, "'").replace(fancyDoubleQuotes, '"').trim();
export const applyLazyLoadingToEmbed = (embedCode, lazyLoadingValue) => {
  let newEmbedCode = embedCode;
  const value = LazyLoadingTypes[lazyLoadingValue];
  const newLoading = value ? ` loading="${value}"` : '';
  const regexMatch = embedCode.match(lazyLoadingRegex);
  const oldLoading = regexMatch && regexMatch[1];
  if (oldLoading) {
    newEmbedCode = embedCode.replace(oldLoading, newLoading);
  } else {
    const index = embedCode.indexOf('<iframe') + 7;
    newEmbedCode = embedCode.slice(0, index) + newLoading + embedCode.slice(index);
  }
  return newEmbedCode;
};
export const extractLoadingValue = embedCode => {
  if (checkForEmbedScripts(embedCode) || !embedCode.includes('<iframe')) {
    return undefined;
  }
  const regexMatch = embedCode.match(lazyLoadingRegex);
  const lazyLoadingValue = regexMatch && regexMatch[2];
  return Object.keys(LazyLoadingTypes).find(key => LazyLoadingTypes[key] === lazyLoadingValue) || 'default';
};