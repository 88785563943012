export const ACCESS_DENIED = 'ACCESS_DENIED';
export const TOO_MANY_POINTS_REQUESTED = 'TOO_MANY_POINTS_REQUESTED';
export const TOO_MANY_BREAKDOWNS = 'TOO_MANY_BREAKDOWNS';
export const INVALID_PROPERTIES = 'INVALID_PROPERTIES';
export const INVALID_PIPELINE = 'INVALID_PIPELINE';
export const DEPRECATED_PROPERTY = 'DEPRECATED_PROPERTY';
export const FUNNEL_COUNT_PROPERTY_NOT_PRESENT = 'FUNNEL_COUNT_PROPERTY_NOT_PRESENT';
export const UNSUPPORTED = 'UNSUPPORTED';
export const TIMEOUT = 'TIMEOUT';
export const TOO_MANY_METRICS_REQUESTED = 'TOO_MANY_METRICS_REQUESTED';
export const TOO_MANY_DEAL_STAGES_REQUESTED = 'TOO_MANY_DEAL_STAGES_REQUESTED';
export const UNSUPPORTED_DATE_RANGE_REQUESTED = 'UNSUPPORTED_DATE_RANGE_REQUESTED';
export const MISSING_REQUIRED_DATA = 'MISSING_REQUIRED_DATA';
export const UNKNOWN = 'UNKNOWN';
export const REQUEST_ENTITY_TOO_LARGE = 'REQUEST_ENTITY_TOO_LARGE';
export const RESULT_POINT_LIMIT_EXCEEDED = 'RESULT_POINT_LIMIT_EXCEEDED';
export const NOT_CUSTOM_OBJECT_SUPPORTED = 'NOT_CUSTOM_OBJECT_SUPPORTED';
export const REQUEST_DATASET_TOO_LARGE = 'REQUEST_DATASET_TOO_LARGE';
export const PIPELINE_CADENCE_MISMATCH = 'PIPELINE_CADENCE_MISMATCH';
export const MISSING_DIMENSIONS_AND_METRICS = 'MISSING_DIMENSIONS_AND_METRICS';
export const DATA_REPROCESSING = 'DATA_REPROCESSING';
export const HIGHCHARTS_ERROR = 'HIGHCHARTS_ERROR';
export const UNAUTHORIZED_ENGAGEMENTS_REPORT_READ = 'UNAUTHORIZED_ENGAGEMENTS_REPORT_READ';
export const MISSING_INTEGRATION_ERROR = 'MISSING_INTEGRATION_ERROR';
export const MISSING_SERIES_ERROR = 'MISSING_SERIES_ERROR';
export const PIVOT_TOO_LARGE = 'PIVOT_TOO_LARGE';
export const TABLE_TOO_LARGE = 'TABLE_TOO_LARGE';
export const INVALID_TWO_DIMENSION_METRIC = 'INVALID_TWO_DIMENSION_METRIC';
export const MOBILE_UNSUPPORTED = 'MOBILE_UNSUPPORTED';
export const FUTURE_DATE_UNSUPPORTED = 'FUTURE_DATE_UNSUPPORTED';
export const FIELD_LEVEL_PERMISSIONS = 'FIELD_LEVEL_PERMISSIONS';
export const TOO_MANY_SEQUENTIAL_OPTIONAL_STAGES = 'TOO_MANY_SEQUENTIAL_OPTIONAL_STAGES';
export const UNFILTERED_PIPELINE_STAGE = 'UNFILTERED_PIPELINE_STAGE';

// These are not a type of error that can occur but rather an identifiers for
// the class of errors from the Reporting API
export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const PERMISSION_ERROR = 'MISSING_PERMISSIONS';