import * as checked from 'reporting-data/lib/checked';
export const NegativeNumberTypes = {
  AUTO: 'AUTO',
  PARENTHESES: 'parentheses',
  LEFT: 'left',
  RIGHT: 'right',
  RED: 'red'
};
const negativeSymbol = checked.symbol(NegativeNumberTypes, 'NegativeSymbol').defaultValue(NegativeNumberTypes.AUTO);
const customPrecision = checked.number().defaultValue(2);
const currencyCode = checked.string().optional();
const useThousandsSeparator = checked.boolean().defaultValue(true);
const duration = checked.string().optional();
const conditionalFormatting = checked.map().optional();
export const NumberFormattingType = 'number';
export const PercentFormattingType = 'percentage';
export const CurrencyFormattingType = 'currency';
export const AutoFormattingType = 'auto';
export const FormatTypes = {
  AUTO: AutoFormattingType,
  PERCENTAGE: PercentFormattingType,
  NUMBER: NumberFormattingType,
  CURRENCY: CurrencyFormattingType
};
export const FormatType = checked.symbol(FormatTypes, 'FormatType');
export const AutoFormatting = checked.record({
  type: FormatType.always(AutoFormattingType),
  negativeSymbol,
  customPrecision,
  useThousandsSeparator,
  duration,
  conditionalFormatting
}, 'AutoFormatting');
export const NumberFormatting = checked.record({
  type: FormatType.always(NumberFormattingType),
  negativeSymbol,
  customPrecision,
  useThousandsSeparator,
  duration,
  conditionalFormatting
}, 'NumberFormatting');
export const PercentFormatting = checked.record({
  type: FormatType.always(PercentFormattingType),
  negativeSymbol,
  customPrecision,
  useThousandsSeparator,
  duration,
  conditionalFormatting
}, 'PercentFormatting');
export const CurrencyFormatting = checked.record({
  type: FormatType.always(CurrencyFormattingType),
  negativeSymbol,
  customPrecision,
  currencyCode,
  useThousandsSeparator,
  duration,
  conditionalFormatting
}, 'CurrencyFormatting');