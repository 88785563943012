'use es6';

import * as DSAssetFamilies from 'customer-data-filters/filterQueryFormat/DSAssetFamilies/DSAssetFamilies';
import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import { parseDynamicFilterFamilyId } from '../../../filterQueryFormat/DynamicFilterFamilies';
import getIn from 'transmute/getIn';
import toValueComparison from './util/toValueComparison';
const _toIntegrationFilter = (operator, valueComparison, integrationId) => ({
  filterType: ObjectSegFilterTypes.INTEGRATION_EVENT,
  eventTypeId: integrationId,
  filterLines: [{
    property: getIn(['field', 'name'], operator),
    operation: valueComparison
  }]
});
const toIntegrationFilter = (operator, filterFamily, options) => {
  const valueComparison = toValueComparison(operator, options);
  const integrationId = parseDynamicFilterFamilyId(DSAssetFamilies.INTEGRATION, filterFamily);
  return _toIntegrationFilter(operator, valueComparison, integrationId);
};
export default toIntegrationFilter;