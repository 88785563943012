import { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import debounce from 'react-utils/debounce';
import { PulserOverlay } from '../pulser/PulserOverlay';
// Use the first popoverId if multiple popovers are attached to the target.
// This may not be the popoverId for the current tour step, but ensures the pulser
// element is inserted before/below all popovers/floating components.
const getPopoverId = target => {
  if (!target) {
    return '';
  }
  const popoverIdAttribute = target.getAttribute('data-popover-id');
  if (!popoverIdAttribute) {
    return '';
  }
  return popoverIdAttribute.split(' ')[0];
};
export const usePulserContainer = target => {
  const [pulserContainer, setPulserContainer] = useState();
  const popoverId = getPopoverId(target);
  useEffect(() => {
    // read popoverId again to avoid using a deprecated value (see https://git.hubteam.com/HubSpot/GrowthOnboardingPlanningCore/issues/2847)
    const currentPopoverId = popoverId || getPopoverId(target);
    if (!target || !currentPopoverId) {
      return undefined;
    }

    // Insert pulser dom before tour popover for making popover be above pulser overlay
    const popoverElement = document.getElementById(currentPopoverId);
    const pulserElement = document.createElement('div');
    pulserElement.id = `pulser-for-${currentPopoverId}`;
    if (popoverElement && popoverElement.parentNode) {
      popoverElement.parentNode.insertBefore(pulserElement, popoverElement);
    }
    setPulserContainer(pulserElement);
    return () => {
      if (pulserElement && pulserElement.parentNode) {
        pulserElement.parentNode.removeChild(pulserElement);
      }
    };
  }, [popoverId, target]);
  return pulserContainer;
};
export const usePulserOverlay = pulserRef => {
  const pulserOverlayRef = useRef(new PulserOverlay());
  const [overlayPath, setOverlayPath] = useState();
  const debouncedSetOverlayPath = useMemo(() => {
    return debounce(() => {
      setOverlayPath(pulserOverlayRef.current.overlayPath);
    }, 5);
  }, []);

  // expose addPulserPathToOverlay to pulser spotlight component
  // recalculate transparent holes in pulser overlay
  useImperativeHandle(pulserRef, () => ({
    repositionPulserOverlay: (pulserPath, oldPulserPath) => {
      pulserOverlayRef.current.generateOverlayPath(pulserPath, oldPulserPath);
      debouncedSetOverlayPath();
    }
  }), [debouncedSetOverlayPath]);
  return overlayPath;
};