'use es6';

import useUserInfo from '../../access/useUserInfo';
import { getUserId } from '../../data/schemas/userInfoSchema';
const isUserEmailOwner = {
  data: false,
  error: null,
  loading: true
};
export const useIsUserEmailOwner = ({
  schedule
}) => {
  const {
    data: userInfo,
    loading,
    error
  } = useUserInfo();
  if (error) {
    isUserEmailOwner.error = error;
    console.error(error);
    return isUserEmailOwner;
  }
  if (loading) {
    isUserEmailOwner.loading = loading;
    return isUserEmailOwner;
  }
  const emailOwnerId = schedule.get('ownerId');
  const userId = getUserId(userInfo);
  isUserEmailOwner.data = emailOwnerId === userId;
  return isUserEmailOwner;
};