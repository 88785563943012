'use es6';

import userInfo from 'hub-http/userInfo';
import toJS from 'reporting-data/lib/toJS';
import { getReportDefinition } from 'reporting-data/report/reportGetters';
import { post } from 'reporting-data/request/http';
import { exportRelationalReport } from './export';
import { resolveDeps } from '../../metadata/report-deps';
import { RELATIONAL } from '../../schema/report-records';
const RAAS_SNOWFLAKE_EXPORTS_GATE = 'RaaS:SnowflakeExports';
const requestSqlExport = exportRequest => userInfo().then(({
  gates
}) => {
  if (gates.includes(RAAS_SNOWFLAKE_EXPORTS_GATE)) {
    return post('reporting/v1/report/export', {
      data: exportRequest
    }).then(toJS);
  }
  return post('sql-reporting/v1/export', {
    data: exportRequest
  }).then(toJS);
});

/**
 * Invokes the export API for snowflake reports
 * @param report - full report object
 * @param exportOptions - options in the shape provided by r-ui-c's `ExportReport`
 * @returns {Promise}
 */
export const exportSnowflake = (report, exportOptions) => {
  const reportDefinition = getReportDefinition(report);
  switch (reportDefinition.type) {
    case RELATIONAL:
      return resolveDeps(reportDefinition).then(deps => exportRelationalReport(reportDefinition, deps, exportOptions)).then(requestBody => requestSqlExport(requestBody)).catch(error => {
        // TODO: log error
        console.error(error);
        throw error;
      });
    default:
      return Promise.resolve(false);
  }
};