'use es6';

import { LINE_ITEM_PROPERTIES } from 'reference-resolvers/constants/CacheKeys';
import { getLineItemProperties, createGetLineItemProperties } from 'reference-resolvers/api/PropertiesAPI';
import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
export const createLineItemPropertyReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: LINE_ITEM_PROPERTIES,
  createFetchData: createGetLineItemProperties,
  fetchData: getLineItemProperties
}, options));
export default createLineItemPropertyReferenceResolver();