'use es6';

import { makeOperator } from './Operator';
import { RegexString } from '../DisplayTypes';
import { isValidRegex } from './OperatorValidators';
export default makeOperator({
  name: 'PageViewMatchRegex',
  isRefinable: true,
  isRefinableExtra: true,
  values: [{
    name: 'value',
    isValid: isValidRegex
  }],
  displayType: RegexString
});