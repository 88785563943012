'use es6';

import PortalIdParser from 'PortalIdParser';
import { Map as ImmutableMap } from 'immutable';
import * as http from '../../request/http';
import makeDateRangeByType from '../../lib/makeDateRangeByType';
import { getFilterByProperty } from '../../config/filters/functions';
const TABLE_COLUMN_TO_EXPORT_COLUMN = {
  allInternalIds: 'ALL_CAMPAIGN_IDS',
  associatedWorkflows: 'WORKFLOW_NAMES',
  bounce: 'BOUNCE',
  bounceratio: 'BOUNCE_RATIO',
  campaign: 'CAMPAIGN_NAME',
  click: 'CLICK',
  reply: 'REPLY',
  clickratio: 'CLICK_RATIO',
  replyratio: 'REPLY_RATIO',
  clickthroughratio: 'CLICK_THROUGH_RATIO',
  contactslost: 'CONTACTS_LOST',
  contactslostratio: 'CONTACTS_LOST_RATIO',
  createdDate: 'CREATED',
  delivered: 'DELIVERED',
  deliveredratio: 'DELIVERED_RATIO',
  emailName: 'EMAIL_NAME',
  fromAddress: 'REPLY_TO',
  fromName: 'FROM_NAME',
  hardbounced: 'HARD_BOUNCED',
  hardbounceratio: 'HARD_BOUNCE_RATIO',
  internalHubSpotId: 'PRIMARY_EMAIL_CAMPAIGN_ID',
  lastEvent: 'CAMPAIGN_LAST_TOUCH',
  notsent: 'NOT_SENT',
  notsentratio: 'NOT_SENT_RATIO',
  open: 'OPEN',
  openratio: 'OPEN_RATIO',
  publishDate: 'PUBLISH_DATE',
  sent: 'SENT',
  softbounced: 'SOFT_BOUNCED',
  softbounceratio: 'SOFT_BOUNCE_RATIO',
  spamreport: 'SPAM_REPORT',
  spamreportratio: 'SPAM_REPORT_RATIO',
  state: 'STATE',
  subject: 'SUBJECT',
  subscriptionName: 'SUBSCRIPTION_NAME',
  suppressed: 'SUPPRESSED',
  unsubscribed: 'UNSUBSCRIBED',
  unsubscribedratio: 'UNSUBSCRIBED_RATIO',
  updatedDate: 'UPDATED'
};
const COSEMAIL_FILE_URL = 'cosemail/v1/export/dashboard';
function getEmailExportData(config, {
  email,
  format
}) {
  const columns = config.get('metrics').map(m => m.get('property'));
  const dateRange = makeDateRangeByType(config.getIn(['filters', 'dateRange', 'value']).toJS(), 'x' // format as unix timestamp millis
  );
  const emailTypeFilter = getFilterByProperty(config, 'emailType') || ImmutableMap();
  const emailBusinessUnitFilter = getFilterByProperty(config, 'businessUnitId') || ImmutableMap();
  const campaignFilter = getFilterByProperty(config, 'campaignId') || ImmutableMap();
  const teamFilter = getFilterByProperty(config, 'teamId') || ImmutableMap();
  return {
    campaign: campaignFilter.get('value'),
    exportColumns: columns.insert(0, 'emailName').map(col => TABLE_COLUMN_TO_EXPORT_COLUMN[col]).filter(column => column !== undefined),
    email,
    format,
    exportAdvancedStats: false,
    portalId: PortalIdParser.get(),
    type: 'emails',
    simpleStateType: emailTypeFilter.get('value') || null,
    businessUnitId: emailBusinessUnitFilter.get('value') || null,
    includeExportPeriod: true,
    startTimestamp: dateRange.startDate,
    endTimestamp: dateRange.endDate,
    state__in: [],
    subcategory__in: [],
    subcategory__nin: [],
    team_perms__in: teamFilter.has('value') ? [teamFilter.get('value')] : undefined
  };
}
export default ((report, exportConfig) => {
  const config = report.get('config');
  return http.post(COSEMAIL_FILE_URL, {
    data: getEmailExportData(config, exportConfig)
  });
});