import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useHandleIFrameError from './useHandleIFrameError';
import { TABS } from 'collaboration-sidebar/constants/tabs';

// TODO: https://git.hubteam.com/HubSpot/MarketingOrchestration/issues/3354

export default function useSidebarIframeHandlers({
  enabledFeatures,
  isOpen,
  onContentError,
  onContentReady,
  sendMessageRef,
  withoutSidebar
}) {
  // useRef will protect against infinite loops on un-memoized exposed callbacks
  const onContentReadyRef = useRef(onContentReady);
  const onContentErrorRef = useRef(onContentError);
  useEffect(() => {
    onContentReadyRef.current = onContentReady;
  }, [onContentReady]);
  useEffect(() => {
    onContentErrorRef.current = onContentError;
  }, [onContentError]);
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [preload, setPreload] = useState(isOpen || withoutSidebar || (enabledFeatures === null || enabledFeatures === void 0 ? void 0 : enabledFeatures.includes(TABS.COPILOT)) || false);
  const [iframeShouldRender, setIframeShouldRender] = useState(preload || false);
  const {
    IFrameError,
    handleSidebarInitError,
    handleSidebarReadyError
  } = useHandleIFrameError({
    onContentErrorRef
  });
  const handleReady = useCallback(({
    sendMessage
  }) => {
    sendMessageRef.current = sendMessage;
    setIsIframeReady(true);
    if (onContentReadyRef.current) {
      onContentReadyRef.current();
    }
  }, [sendMessageRef]);
  const preLoadIframe = useCallback(() => {
    setPreload(true);
  }, []);
  useEffect(() => {
    if (isOpen && !preload) {
      setPreload(true);
    }
  }, [isOpen, preload]);
  useEffect(() => {
    if (enabledFeatures !== null && enabledFeatures !== void 0 && enabledFeatures.includes(TABS.COPILOT) && !preload) {
      setPreload(true);
    }
  }, [enabledFeatures, preload]);
  useEffect(() => {
    // If either show or preload is true, we load the iframe.
    // show additionally means the iframe is visible
    if (!iframeShouldRender && (isOpen || preload)) {
      setIframeShouldRender(true);
    }
  }, [isOpen, preload, iframeShouldRender]);
  const iframeProps = useMemo(() => ({
    isReady: isIframeReady,
    preLoadIframe,
    handleReady,
    handleSidebarInitError,
    handleSidebarReadyError,
    preload,
    iframeShouldRender,
    IFrameError
  }), [isIframeReady, handleReady, handleSidebarInitError, handleSidebarReadyError, preload, iframeShouldRender, preLoadIframe, IFrameError]);
  return iframeProps;
}