'use es6';

import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { searchBlogAuthorOptions, createSearchBlogAuthorOptions, getBlogAuthorsById, createGetBlogAuthorsById } from 'reference-resolvers/api/BlogPostAPI';
export const createBlogAuthorOptionsReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.BLOG_AUTHOR,
  createFetchSearchPage: createSearchBlogAuthorOptions,
  fetchSearchPage: searchBlogAuthorOptions,
  createFetchByIds: createGetBlogAuthorsById,
  fetchByIds: getBlogAuthorsById
}, options));
export default createBlogAuthorOptionsReferenceResolver();