'use es6';

import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
import { fromJS, List } from 'immutable';
export const FORM_TYPES = {
  NPS: 'NPS',
  CES: 'CES',
  CSAT: 'CSAT',
  CUSTOM: 'CUSTOM',
  KNOWLEDGE: 'KNOWLEDGE'
};
const SURVEY_CONFIG_KEYS = {
  NPS: 'hasNpsSurvey',
  CES: 'hasCesSurvey',
  CSAT: 'hasCsatSurvey',
  CUSTOM: 'hasCustomSurvey'
};
const formatFormTypeReference = (id, type) => {
  return new ReferenceRecord({
    id,
    label: type,
    referencedObject: fromJS({
      type
    })
  });
};

// Returns a list of valid surveyTypes based on the input config
const formatFeedbackForms = response => {
  const npsRef = formatFormTypeReference(FORM_TYPES.NPS, FORM_TYPES.NPS);
  const cesRef = formatFormTypeReference(FORM_TYPES.CES, FORM_TYPES.CES);
  const csatRef = formatFormTypeReference(FORM_TYPES.CSAT, FORM_TYPES.CSAT);
  const customRef = formatFormTypeReference(FORM_TYPES.CUSTOM, FORM_TYPES.CUSTOM);
  const knowledgeRef = formatFormTypeReference(FORM_TYPES.KNOWLEDGE, FORM_TYPES.KNOWLEDGE);
  return List.of(npsRef, cesRef, csatRef, customRef, knowledgeRef).filter(referenceRecord => referenceRecord.get('id') === FORM_TYPES.KNOWLEDGE || response[SURVEY_CONFIG_KEYS[referenceRecord.get('id')]]);
};
export default formatFeedbackForms;