'use es6';

import http from 'hub-http/clients/apiClient';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import compose from 'transmute/compose';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import indexBy from 'transmute/indexBy';
export const createGetAllActivityTypes = ({
  httpClient
}) => () => httpClient.get('engagements/v1/activity-types').then(compose(indexBy(get('id')), formatToReferencesList({
  getId: getIn(['name']),
  getLabel: getIn(['name'])
})));
export const getAllActivityTypes = createGetAllActivityTypes({
  httpClient: http
});