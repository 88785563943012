'use es6';

import { useState, useEffect, useRef } from 'react';
import { isNotNil } from 'dashboard-lib/private/js-util';
const EMPTY_FUNCTION = () => {};

/**
 * Analogous to setInterval, but written as a hook.
 * Set delay to null to reset and stop the timer.
 * Set delay to a new value to reset and restart the timer.
 * @param callback -- function called on each interval
 * @param delay -- in milliseconds; changing delay resets the timer;
 * changing it to null stops it from running.
 */
export const useInterval = (callback, delay) => {
  const counterRef = useRef();
  const halt = () => {
    if (counterRef.current) {
      window.clearInterval(counterRef.current);
    }
    counterRef.current = null;
  };
  useEffect(() => {
    if (isNotNil(delay)) {
      counterRef.current = window.setInterval(callback, delay);
    }
    return halt;
  }, [delay, callback]);
};

/**
 * Uses useInterval to provide a customizable numerical counter.
 * This counter stops itself once it counts to 0 or bellow.
 * @param params settings for the useCounter
 * @param {number} params.initial  - the initial value
 * @param {number} params.delta - the delta to subtract from the current value on each interval
 * @param {number} params.delay - the delay interval in ms
 * @param {function} params.onInterval - fires on each interval
 * @param {function} params.onFinish - fires when then counter reaches 0 or lower.
 * @returns {{currentValue: number}} - current value
 */
export const useCounter = params => {
  const {
    initial = 0,
    delta = 1,
    delay = 1000,
    onInterval = EMPTY_FUNCTION,
    onFinish = EMPTY_FUNCTION
  } = params;
  const [currentValue, setValue] = useState(initial);
  const [delayValue, setDelay] = useState(delay);
  const halt = () => {
    setDelay(null);
  };
  useEffect(() => {
    if (delayValue == null) {
      onFinish();
    }
  }, [delayValue, onFinish]);
  useEffect(() => {
    onInterval(currentValue);
  }, [currentValue, onInterval]);
  const onEachInterval = () => {
    setValue(current => {
      const newValue = current - delta;
      if (newValue <= 0) {
        halt();
        return 0;
      }
      return newValue;
    });
  };
  useInterval(onEachInterval, delayValue);
  return {
    currentValue
  };
};
export const useTimeout = (callback, delay) => {
  const timeoutRef = useRef();
  const callbackRef = useRef();

  // Update callback function
  useEffect(() => {
    callbackRef.current = callback;
  });
  const halt = () => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = null;
  };
  useEffect(() => {
    if (isNotNil(delay)) {
      const callCallback = () => callbackRef.current();
      timeoutRef.current = window.setTimeout(callCallback, delay);
    }
    return halt;
  }, [delay]);
};