import http from 'hub-http/clients/apiClient';
import { getAssetKeyFromAsset } from '../utils/multipleAssets';
import COMMENT_TYPES from '../constants/CommentTypes';
import { MODULES_WITH_REPLIES } from '../constants/CollaborationSidebarStateProperties';
import { makeSimpleQuickFetchWrapper } from '../utils/ajax';
const BASE_URL = 'commenting/v3/comments';
export const updateCommentMetaDescriptionOptions = (id, updatedMetaDescription) => http.patch(`${BASE_URL}/${id}`, {
  data: [{
    op: 'replace',
    path: '/metaDescription',
    value: updatedMetaDescription
  }]
});
const fetchCommentsPromises = {};
export function fetchComments(params) {
  return http.get(BASE_URL, {
    query: Object.assign({
      // The default limit on the BE is 200,
      // We need this to be a high number so we can
      // make sure we fetch all top level comments and all replies.
      // 500 is the maximum limit we can set.
      limit: 500
    }, params)
  });
}
export const fetchCommentsCached = params => {
  const {
    objectType,
    objectId,
    refetch
  } = params;
  const key = getAssetKeyFromAsset({
    objectType,
    objectId
  });
  const hasKey = (key in fetchCommentsPromises);
  if (!hasKey || refetch) {
    fetchCommentsPromises[key] = fetchComments({
      objectType,
      objectId
    });
  }
  return fetchCommentsPromises[key];
};
export const updateCommentsCache = ({
  objectType,
  objectId,
  comments,
  updatedCommentId,
  modulesWithReplies,
  onUpdateCollaborationSidebarState
}) => {
  if (!objectType) {
    return;
  }
  const updatedComment = comments.find(comment => comment.id === updatedCommentId);
  if (updatedComment && updatedComment.parentId && updatedComment.type === COMMENT_TYPES.DEEP_COMMENT) {
    onUpdateCollaborationSidebarState({
      [MODULES_WITH_REPLIES]: Object.assign({}, modulesWithReplies, {
        [updatedComment.parentId]: updatedComment
      })
    });
  }
  const key = getAssetKeyFromAsset({
    objectType,
    objectId
  });
  if (key in fetchCommentsPromises) {
    fetchCommentsPromises[key] = Promise.resolve({
      results: comments,
      total: comments.length
    });
  }
};
export const fetchAssetLastUpdatedAt = ({
  objectType,
  objectId
}) => http.get(`commenting/v3/objects/${objectType}/${encodeURIComponent(objectId)}/last-updated-at`);
export const fetchCommentsWithTopLevelCommentsQuickFetch = ({
  objectType,
  objectId
}) => makeSimpleQuickFetchWrapper(`fetch-comments-v3-${objectType}-${objectId}`, fetchComments);
export const fetchCommentsWithMiniCommenterThreadQuickFetch = makeSimpleQuickFetchWrapper('fetch-comments-thread-v3', fetchComments);
export const createComment = ({
  atMentionsOwnerIds = [],
  ctaUrl,
  message,
  objectId,
  objectType,
  objectChildId,
  type,
  locationPath,
  metaDescription = {
    translationKey: '',
    locationPath: ''
  }
}) => {
  return http.post(BASE_URL, {
    data: {
      objectType,
      objectId,
      atMentionedOwnerIds: atMentionsOwnerIds,
      ctaUrl,
      message,
      parentId: objectChildId,
      type,
      metaDescription: {
        locationPath,
        translationKey: metaDescription ? metaDescription.translationKey : '',
        options: metaDescription && metaDescription.options ? metaDescription.options : {}
      }
    }
  });
};
export function updateComment({
  id,
  message,
  atMentionedUserIds
}) {
  return http.patch(`${BASE_URL}/${id}`, {
    data: [{
      op: 'replace',
      path: '/message',
      value: message
    }, {
      op: 'replace',
      path: '/atMentionedUserIds',
      value: atMentionedUserIds
    }]
  });
}
export function deleteComment(id) {
  return http.delete(`${BASE_URL}/${id}`);
}
export function resolveComment(id, ctaUrl) {
  return http.post(`${BASE_URL}/${id}/actions/resolve`, {
    data: {
      ctaUrl
    }
  });
}
export function unresolveComment(id, ctaUrl) {
  return http.post(`${BASE_URL}/${id}/actions/unresolve`, {
    data: {
      ctaUrl
    }
  });
}
export const markCommentAsReadAPI = ({
  commentIds = []
}) => {
  return http.post(`${BASE_URL}/actions/mark-as-read`, {
    data: {
      commentIds
    }
  });
};
export const markCommentAsUnreadAPI = ({
  commentIds = []
}) => {
  return http.post(`${BASE_URL}/actions/mark-as-unread`, {
    data: {
      commentIds
    }
  });
};
export const fetchNotificationSettings = ({
  objectType,
  objectId
}) => http.get(`commenting/v3/objects/${objectType}/${encodeURIComponent(objectId)}/notification-settings`);
export const updateUserNotificationSetting = async ({
  objectType,
  objectId,
  userSetting
}) => {
  await http.put(`commenting/v3/objects/${objectType}/${encodeURIComponent(objectId)}/notification-settings`, {
    data: {
      notificationSettings: userSetting
    }
  });
  return userSetting;
};
export const fetchUsersToNotify = ({
  objectType,
  objectId,
  selectedCommentThreadId
}) => {
  return http.get(`commenting/v3/objects/${objectType}/${encodeURIComponent(objectId)}/users-to-notify`, {
    query: {
      parentId: selectedCommentThreadId
    }
  });
};
export const fetchOwnersWithObjectViewAccess = makeSimpleQuickFetchWrapper('fetch-owners-v3', ({
  objectType,
  objectId
}) => http.get(`commenting/v3/objects/${objectType}/${encodeURIComponent(objectId)}/owners-with-object-view-access`));
export const fetchCommentRepliesCount = ({
  objectType,
  objectId,
  commentIds
}) => http.post('commenting/v3/reply-summaries/batch/read', {
  data: {
    objectType,
    objectId,
    inputs: commentIds.map(id => ({
      id
    }))
  }
}).then(response => {
  return response.results.reduce((acc, reply) => {
    acc[reply.id] = {
      replyCount: reply.replyCount,
      userIds: reply.userIds
    };
    return acc;
  }, {});
});