'use es6';

import I18n from 'I18n';
import { DEFAULT_FISCAL_YEAR_MONTH_NAME, DEFAULT_START } from '../../constants/dates';
import { RANGE_TYPES, ROLLING_DATE_TYPES } from '../../constants/dateRangeTypes';
import normalizeDateTime from '../normalizeDateTime';
import * as uses from './uses';
import * as units from './units';
import { startOf, endOf } from '../dateUtils';
import { isFiscalYearSupportedPeriod, isFiscalYearSupportedRangeType } from '../fiscalYearUtils';
const FORMAT = 'YYYYMMDD';

/*
 * interface DateRange {
 *   rangeType: RangeType;
 *   rollingDays?: RollingDateType;
 *   startDate?: string;
 *   endDate?: string;
 *   entireCurrentUnit?: boolean;
 * }
 */

/**
 * Make date range from range type
 *
 * @param {DateRange} filter Sparce date range filter
 * @param {string} format Output date format
 * @returns {DateRange} Complete date range filter
 */
export default (({
  rangeType = RANGE_TYPES.THIS_MONTH,
  rollingDays = ROLLING_DATE_TYPES['7'],
  entireCurrentUnit = false,
  startDate,
  endDate,
  date
} = {}, format = 'YYYY-MM-DD', dataType, useFiscalYear = false, fiscalYearMonthName = DEFAULT_FISCAL_YEAR_MONTH_NAME) => {
  const {
    utc,
    sunday
  } = uses.get(dataType);
  // NOTE: RA-1957 - normalize for locale agnostic logic
  const normalizedDateTime = normalizeDateTime(utc ? I18n.moment.utc : I18n.moment.portalTz);
  const [timeFrame, dateUnit] = rangeType.split('_');
  const {
    interval
  } = units.get(dateUnit, !sunday);
  const shouldUseFiscalYearOffset = useFiscalYear && isFiscalYearSupportedPeriod(dateUnit, fiscalYearMonthName) && isFiscalYearSupportedRangeType(rangeType);
  const isSingleDateType = [RANGE_TYPES.IS_EQUAL_TO, RANGE_TYPES.IS_BEFORE_DATE, RANGE_TYPES.IS_AFTER_DATE].includes(rangeType) || timeFrame === 'CUSTOM' && dateUnit;

  // NOTE: providing default value(s) for CUSTOM, IS_EQUAL_TO, IS_BEFORE_DATE, IS_AFTER_DATE
  if (rangeType === RANGE_TYPES.CUSTOM && startDate == null) {
    startDate = I18n.moment.portalTz().subtract(1, 'week').startOf('day').format('YYYY-MM-DD');
    endDate = I18n.moment.portalTz().endOf('day').format('YYYY-MM-DD');
  } else if (isSingleDateType && date == null) {
    date = I18n.moment.portalTz().startOf('day').format('YYYY-MM-DD');
  }
  if (rangeType === RANGE_TYPES.CUSTOM) {
    startDate = normalizedDateTime(startDate, FORMAT).startOf('day');
    endDate = normalizedDateTime(endDate, FORMAT).endOf('day');
  } else if (rangeType === RANGE_TYPES.ROLLING) {
    startDate = normalizedDateTime().subtract(rollingDays, 'days').startOf('day');
    endDate = normalizedDateTime().subtract(1, 'day').endOf('day');
  } else if (rangeType === RANGE_TYPES.ALL) {
    startDate = normalizedDateTime(DEFAULT_START, 'YYYY-MM-DD');
    endDate = normalizedDateTime().endOf('day');
  } else if (timeFrame === 'LAST') {
    entireCurrentUnit = true;
    startDate = startOf({
      date: normalizedDateTime(),
      dataType,
      frequency: dateUnit,
      useFiscalYear: shouldUseFiscalYearOffset,
      fiscalYearMonthName
    }).subtract(1, interval);
    endDate = endOf({
      date: normalizedDateTime().subtract(1, interval),
      frequency: dateUnit,
      dataType,
      useFiscalYear: shouldUseFiscalYearOffset,
      fiscalYearMonthName
    });
  } else if (timeFrame === 'NEXT') {
    startDate = startOf({
      date: normalizedDateTime(),
      dataType,
      frequency: dateUnit,
      useFiscalYear: shouldUseFiscalYearOffset,
      fiscalYearMonthName
    }).add(1, interval);
    endDate = endOf({
      date: normalizedDateTime().add(1, interval),
      frequency: dateUnit,
      dataType,
      useFiscalYear: shouldUseFiscalYearOffset,
      fiscalYearMonthName
    });
  } else if (timeFrame === 'CUSTOM') {
    startDate = startOf({
      date: normalizedDateTime(date),
      frequency: dateUnit,
      dataType
    });
    endDate = endOf({
      date: normalizedDateTime(date),
      frequency: dateUnit,
      dataType
    });
  } else if (rangeType === RANGE_TYPES.IS_AFTER_DATE) {
    startDate = normalizedDateTime(date).add(1, 'day'); // add a day since date itself not included
    endDate = entireCurrentUnit ? endOf({
      date: normalizedDateTime(),
      frequency: dateUnit,
      dataType
    }) : normalizedDateTime().endOf('day');
    if (endDate < startDate) {
      endDate = startDate;
    }
  } else if (rangeType === RANGE_TYPES.IS_BEFORE_DATE) {
    startDate = normalizedDateTime(DEFAULT_START, 'YYYY-MM-DD');
    endDate = normalizedDateTime(date);
  } else if (rangeType === RANGE_TYPES.IS_EQUAL_TO) {
    startDate = normalizedDateTime(date);
    endDate = startDate;
  } else {
    startDate = startOf({
      date: normalizedDateTime(),
      frequency: dateUnit,
      dataType,
      useFiscalYear: shouldUseFiscalYearOffset,
      fiscalYearMonthName
    });
    endDate = entireCurrentUnit ? endOf({
      date: normalizedDateTime(),
      frequency: dateUnit,
      dataType,
      useFiscalYear: shouldUseFiscalYearOffset,
      fiscalYearMonthName
    }) : normalizedDateTime().endOf('day');
  }
  return {
    rangeType,
    rollingDays: rangeType === RANGE_TYPES.ROLLING ? rollingDays : null,
    entireCurrentUnit,
    startDate: startDate.format(format),
    endDate: endDate.format(format),
    date: isSingleDateType ? date : null
  };
});