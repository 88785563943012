import { useState, useEffect } from 'react';
// @ts-expect-error migrate upstream
import useReportTypeProblem from 'reporting-action-components/reportingActions/shared/problemHooks/useReportTypeProblem';
import { is } from 'immutable';
import { normalizeReport } from 'dashboard-ui-components/lib/PlatformReportHelpers';
import { usePrevious } from './usePrevious';
const useNormalizedReport = report => {
  const [normalizedReport, setNormalizedReport] = useState(null);
  const previousReport = usePrevious(report);
  const reportTypeProblem = useReportTypeProblem({
    report
  });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let cancelNormalization = false;
    if (reportTypeProblem === 'LOADING') {
      // skip effect if we don't have everything we need to determine whether to perform normalization
      return () => {};
    }
    if (is(report, previousReport) && normalizedReport != null) {
      // skip effect; report value did not change
      return () => {};
    }

    // if a portal loses access to a type of report, it may fail to normalize, so avoid attempting it
    if (reportTypeProblem) {
      setIsLoading(false);
      setNormalizedReport(report);
      return () => {};
    }
    setNormalizedReport(null);
    setIsLoading(true);
    normalizeReport(report).then(updatedReport => {
      if (!cancelNormalization) {
        setNormalizedReport(updatedReport);
        setIsLoading(false);
      }
    }).catch(error => {
      if (!cancelNormalization) {
        console.error(error);
        setNormalizedReport(null);
        setIsLoading(false);
      }
    });
    return () => {
      cancelNormalization = true;
    };
  }, [report, normalizedReport, previousReport, reportTypeProblem]);
  return {
    normalizedReport,
    isLoading
  };
};
export default useNormalizedReport;