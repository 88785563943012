'use es6';

import curry from 'transmute/curry';
import get from 'transmute/get';
import { AND, OR, NOT } from './type-name';

/**
 * <Access Language> === <AL>
 *
 * <AL> ::= <Boolean>
 *        | <(*... ->  Boolean)>
 *        | Or(<AL>, ...)
 *        | And(<AL>, ...)
 *        | Not(<AL>)
 */

const TYPE_NAME_KEY = 'typeName';
export const initType = (typeName, rest) => Object.assign({
  [TYPE_NAME_KEY]: typeName
}, rest);
export const checkType = curry((typeName, type) => typeName === get(TYPE_NAME_KEY, type));
export const isOr = checkType(OR);
export const Or = (...args) => initType(OR, {
  args
});
export const isAnd = checkType(AND);
export const And = (...args) => initType(AND, {
  args
});
export const isNot = checkType(NOT);
export const Not = k => initType(NOT, {
  k
});