'use es6';

import { Map as ImmutableMap } from 'immutable';
export default ImmutableMap({
  operator: 'TIME_UNIT_TO_DATE',
  inclusive: true,
  property: '',
  timeUnitCount: 1,
  timeUnit: 'YEAR',
  rollForward: false
});