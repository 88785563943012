'use es6';

import I18n from 'I18n';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import { mutableDescriptionDefaults } from './mutableDescriptionDefaults';
const tryLookup = key => isValidI18nKey(key) ? I18n.text(key) : undefined;
export const propertyDescriptionTranslator = ({
  name,
  objectTypeId,
  description: loadedDescription
}) => {
  if (name === undefined || objectTypeId === undefined || loadedDescription === undefined) {
    throw new Error('Expected to be called with {name, objectTypeId, description}');
  }
  if (!(typeof objectTypeId === 'string' && objectTypeId.match(/^\d-\d+$/))) {
    throw new Error(`Expected objectTypeId to be a string in format "#-#" but got ${objectTypeId}`);
  }
  const mutableDescription = mutableDescriptionDefaults[objectTypeId] && mutableDescriptionDefaults[objectTypeId][name];
  if (mutableDescription && mutableDescription !== loadedDescription) {
    // we should only use the loadedDescription if the description
    //   is mutable (exists in the mutable description defaults object)
    //   and it does not match the existing description provided
    // this is not a fallback solution
    return loadedDescription;
  }
  if (objectTypeId.startsWith('2-')) {
    return tryLookup(`descriptions.CUSTOM.${name}`);
  }
  return tryLookup(`descriptions.${objectTypeId}.${name}`);
};