'use es6';

import { AssetPermission } from '../../../../public/permission/asset-permission';
import { hasSufficientPermission } from '../../../../public/permission/compute-permission';
import { limitExceeded } from '../../common/limits';
import { getAndCheck } from '../../common/get-and-check-param';
import { getAllProblems } from '../../common/get-problems-no-check';
import { createProblem } from '../../common/problem';
import { CUSTOM_REPORT_COUNT, CUSTOM_REPORT_LIMIT, REPORT_IS_CUSTOM, USER_REPORT_PERMISSION } from '../../params/all-params';
export const PERMISSION_DENIED = createProblem('PERMISSION_DENIED', params => {
  return !hasSufficientPermission(AssetPermission.CREATE_AND_OWN, getAndCheck(USER_REPORT_PERMISSION, params));
});
export const CUSTOM_REPORT_LIMIT_EXCEEDED = createProblem('CUSTOM_REPORT_LIMIT_EXCEEDED', params => {
  const reportIsCustom = getAndCheck(REPORT_IS_CUSTOM, params);
  const customReportCount = getAndCheck(CUSTOM_REPORT_COUNT, params);
  const customReportLimit = getAndCheck(CUSTOM_REPORT_LIMIT, params);
  return reportIsCustom && limitExceeded(customReportCount, customReportLimit);
});
export const rankedProblems = [PERMISSION_DENIED, CUSTOM_REPORT_LIMIT_EXCEEDED];
export const getAll = params => getAllProblems(params, rankedProblems);