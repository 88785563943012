import { createSlice } from '@reduxjs/toolkit';
export const getLayoutFromWidgets = ({
  widgets,
  editable
}) => widgets.map(widget => {
  return Object.assign({}, widget, {
    static: !editable
  });
});
const initialState = {};
const widgetLayoutSlice = createSlice({
  name: 'widgetLayout',
  initialState,
  reducers: {
    setWidgetLayout(state, action) {
      const {
        newLayout,
        resourceId
      } = action.payload;
      state[resourceId] = newLayout;
    }
  }
});
export const selectWidgetLayout = resourceId => ({
  widgetLayout
}) => {
  return widgetLayout[resourceId] || [];
};
export const selectGridLayout = resourceId => editable => state => {
  const widgetLayout = selectWidgetLayout(resourceId)(state);
  return getLayoutFromWidgets({
    widgets: widgetLayout,
    editable
  });
};
export const {
  setWidgetLayout
} = widgetLayoutSlice.actions;
export const removeWidgetLayout = ({
  widgetId
}) => (dispatch, getState) => {
  const resourceId = getState().reportView.resourceId;
  const state = getState().widgetLayout;
  const newLayout = state[resourceId].filter(({
    i
  }) => i !== widgetId);
  dispatch(setWidgetLayout({
    newLayout,
    resourceId
  }));
};
export const resetWidgetLayout = ({
  layout
}) => (dispatch, getState) => {
  const resourceId = getState().reportView.resourceId;
  dispatch(setWidgetLayout({
    newLayout: layout,
    resourceId
  }));
};
export default widgetLayoutSlice.reducer;