import { Map as ImmutableMap } from 'immutable';
import { FIELDS, HS_OBJECT_ID } from '../constants/fields';
import { reportHasDrilldownMeasure } from '../enhance/drilldown/drilldownMeasure';
export const JoinTypes = {
  ASSOCIATION: 'ASSOCIATION'
};
export const JOURNEY = 'JOURNEY';
export function isJourneyReport(maybeJourneyReport) {
  return !!maybeJourneyReport.journeyQuery;
}
export function isImmutableJourneyReport(maybeJourneyReport) {
  return ImmutableMap.isMap(maybeJourneyReport) && isJourneyReport(maybeJourneyReport.toJS());
}
export function isJourneyDrilldownReport(report) {
  return reportHasDrilldownMeasure(report);
}