'use es6';

import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as OperatorTypes from '../ObjectSegOperatorTypes';
import * as Operators from '../../../filterQueryFormat/operator/Operators';
import getIn from 'transmute/getIn';
import invariant from 'react-utils/invariant';
import protocol from 'transmute/protocol';
import toRefinementFilter from './toRefinementFilter';
const _toEventFilter = (operatorName, operator) => {
  const filter = {
    eventId: getIn(['field', 'name'], operator),
    filterType: ObjectSegFilterTypes.EVENT,
    operator: operatorName
  };
  const refinement = operator.get('refinement');
  if (refinement) {
    return Object.assign({}, filter, toRefinementFilter(refinement));
  }
  return filter;
};
const invalidOperator = operator => {
  invariant(false, 'cannot convert Operator "%s" to event ObjectSeg filter', operator.name);
};
const toEventFilter = protocol({
  name: 'toEventFilter',
  args: [protocol.TYPE],
  fallback: invalidOperator
});
toEventFilter.implement(Operators.EventCompleted, operator => _toEventFilter(OperatorTypes.HAS_EVENT, operator));
toEventFilter.implement(Operators.EventNotCompleted, operator => _toEventFilter(OperatorTypes.NOT_HAS_EVENT, operator));
export default toEventFilter;