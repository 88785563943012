import { convertTimePointToMomentDate, isAfterDateOperation, isAfterPropertyOperation, isBeforeDateOperation, isBeforePropertyOperation, isBetweenDatesOperation, isEqualToDateOperation, isLessThanXDaysAgoOperation, isLessThanXDaysFromNowOperation, isMoreThanXDaysAgoOperation, isMoreThanXDaysFromNowOperation, isNotBetweenDatesOperation, isNotUpdatedInLastXDaysOperation, isUpdatedAfterPropertyOperation, isUpdatedBeforePropertyOperation, isUpdatedInLastXDaysOperation } from '../timeFilters/timeFiltersUtils';
import 'I18n/init/data/allTimezones';
import { hasNewIlsTimeFiltersEnabled } from '../../../permissions/gates';
import invariant from 'react-utils/invariant';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../.... Remove this comment to see the full error message
import * as Operators from '../../../filterQueryFormat/operator/Operators';
import * as TimeOperationPropertyParserTypes from '../timeFilters/TimeOperationPropertyParserTypes';
const fromEqualToDateOperation = (field, operation) => {
  const momentDate = convertTimePointToMomentDate(operation.lowerBoundTimePoint);
  const hasTimezones = operation.propertyParser === TimeOperationPropertyParserTypes.VALUE;
  return Operators.EqualTimePoint.of(field, momentDate, hasTimezones);
};
const fromAfterDateOperation = (field, operation) => {
  const momentDate = convertTimePointToMomentDate(operation.timePoint);
  const hasTimezones = operation.propertyParser === TimeOperationPropertyParserTypes.VALUE;
  return Operators.AfterTimePoint.of(field, momentDate, hasTimezones);
};
const fromBeforeDateOperation = (field, operation) => {
  const momentDate = convertTimePointToMomentDate(operation.timePoint);
  const hasTimezones = operation.propertyParser === TimeOperationPropertyParserTypes.VALUE;
  return Operators.BeforeTimePoint.of(field, momentDate, hasTimezones);
};
const fromBetweenDatesOperation = (field, operation) => {
  const lowerBoundDateString = convertTimePointToMomentDate(operation.lowerBoundTimePoint);
  const upperBoundDateString = convertTimePointToMomentDate(operation.upperBoundTimePoint);
  const hasTimezones = operation.propertyParser === TimeOperationPropertyParserTypes.VALUE;
  return Operators.BetweenTimePoints.of(field, lowerBoundDateString, upperBoundDateString, hasTimezones);
};
const fromNotBetweenDatesOperation = (field, operation) => {
  const lowerBoundDateString = convertTimePointToMomentDate(operation.lowerBoundTimePoint);
  const upperBoundDateString = convertTimePointToMomentDate(operation.upperBoundTimePoint);
  const hasTimezones = operation.propertyParser === TimeOperationPropertyParserTypes.VALUE;
  return Operators.NotBetweenTimePoints.of(field, lowerBoundDateString, upperBoundDateString, hasTimezones);
};
const fromMoreThanXDaysAgoOperation = (field, operation) => {
  let days = operation.timePoint.offset.days;

  // Round up to nearest day when hours is set to 23 hours.
  // This is due to a bug in the FE code early on in releasing TimePoints.
  if (operation.timePoint.offset.hours === 23) {
    // Days are expected to be negative, but they can be positive
    days = days > 0 ? days + 1 : days - 1;
  }

  // The ILS format encodes direction into the number as the sign.
  // -X means backwards, X means forward
  // So 10 days forwards is -10 backwards
  // Since this operator forces the the units of "days" to be based in the backwards direction days has to be converted from "forward days" to "backwards days".
  // forward days = -backwards days
  days = -days;
  const hasTimezones = operation.propertyParser === TimeOperationPropertyParserTypes.VALUE;
  return Operators.GreaterRolling.of(field, days, 'backward', operation.timePoint.zoneId, hasTimezones);
};
const fromMoreThanXDaysFromNowOperation = (field, operation) => {
  let days = operation.timePoint.offset.days;

  // Round up to nearest day when hours is set to 23 hours.
  // This is due to a bug in the FE code early on in releasing TimePoints.
  if (operation.timePoint.offset.hours === 23) {
    // Days are expected to be positive, but they can be negative
    days = days > 0 ? days + 1 : days - 1;
  }
  const hasTimezones = operation.propertyParser === TimeOperationPropertyParserTypes.VALUE;
  return Operators.GreaterRolling.of(field, days, 'forward', operation.timePoint.zoneId, hasTimezones);
};
const fromLessThanXDaysAgoOperation = (field, operation) => {
  let days = Math.abs(operation.lowerBoundTimePoint.offset.days);
  if (operation.lowerBoundTimePoint.offset.hours === 23) {
    days++;
  }
  const hasTimezones = operation.propertyParser === TimeOperationPropertyParserTypes.VALUE;
  return Operators.LessRolling.of(field, days, 'backward', operation.lowerBoundTimePoint.zoneId, hasTimezones);
};
const fromLessThanXDaysFromNowOperation = (field, operation) => {
  let days = Math.abs(operation.upperBoundTimePoint.offset.days);
  if (operation.upperBoundTimePoint.offset.hours === 23) {
    days++;
  }
  const hasTimezones = operation.propertyParser === TimeOperationPropertyParserTypes.VALUE;
  return Operators.LessRolling.of(field, days, 'forward', operation.lowerBoundTimePoint.zoneId, hasTimezones);
};
const fromAfterPropertyOperation = (field, operation) => {
  return Operators.After.of(field, operation.timePoint.property);
};
const fromBeforePropertyOperation = (field, operation) => {
  return Operators.Before.of(field, operation.timePoint.property);
};
const fromUpdatedInLastXDaysAgoOperation = (field, operation) => {
  return Operators.UpdatedInLastXDays.of(field, Math.abs(operation.lowerBoundTimePoint.offset.days));
};
const fromNotUpdatedInLastXDaysAgoOperation = (field, operation) => {
  return Operators.NotUpdatedInLastXDays.of(field, Math.abs(operation.lowerBoundTimePoint.offset.days));
};
const fromUpdatedAfterAnotherPropertyOperation = (field, operation) => {
  return Operators.UpdatedAfter.of(field, operation.timePoint.property);
};
const fromUpdatedBeforeAnotherPropertyOperation = (field, operation) => {
  return Operators.UpdatedBefore.of(field, operation.timePoint.property);
};
export const fromTimeOperation = (__Operator, field, filter, options) => {
  const isUngatedForNewTimeFilters = hasNewIlsTimeFiltersEnabled(options.isUngated);
  invariant(isUngatedForNewTimeFilters, 'Not ungated for a new time filters format');
  invariant(!options.isNotUsingTimePoints, 'This app does not use time point filters');
  if (isEqualToDateOperation(filter.operation)) {
    return fromEqualToDateOperation(field, filter.operation);
  }
  if (isAfterDateOperation(filter.operation)) {
    return fromAfterDateOperation(field, filter.operation);
  }
  if (isBeforeDateOperation(filter.operation)) {
    return fromBeforeDateOperation(field, filter.operation);
  }
  if (isBetweenDatesOperation(filter.operation)) {
    return fromBetweenDatesOperation(field, filter.operation);
  }
  if (isNotBetweenDatesOperation(filter.operation)) {
    return fromNotBetweenDatesOperation(field, filter.operation);
  }
  if (isMoreThanXDaysAgoOperation(filter.operation)) {
    return fromMoreThanXDaysAgoOperation(field, filter.operation);
  }
  if (isMoreThanXDaysFromNowOperation(filter.operation)) {
    return fromMoreThanXDaysFromNowOperation(field, filter.operation);
  }
  if (isLessThanXDaysAgoOperation(filter.operation)) {
    return fromLessThanXDaysAgoOperation(field, filter.operation);
  }
  if (isLessThanXDaysFromNowOperation(filter.operation)) {
    return fromLessThanXDaysFromNowOperation(field, filter.operation);
  }
  if (isAfterPropertyOperation(filter.operation)) {
    return fromAfterPropertyOperation(field, filter.operation);
  }
  if (isBeforePropertyOperation(filter.operation)) {
    return fromBeforePropertyOperation(field, filter.operation);
  }
  if (isUpdatedInLastXDaysOperation(filter.operation)) {
    return fromUpdatedInLastXDaysAgoOperation(field, filter.operation);
  }
  if (isNotUpdatedInLastXDaysOperation(filter.operation)) {
    return fromNotUpdatedInLastXDaysAgoOperation(field, filter.operation);
  }
  if (isUpdatedAfterPropertyOperation(filter.operation)) {
    return fromUpdatedAfterAnotherPropertyOperation(field, filter.operation);
  }
  if (isUpdatedBeforePropertyOperation(filter.operation)) {
    return fromUpdatedBeforeAnotherPropertyOperation(field, filter.operation);
  }
  return undefined;
};