import { Map as ImmutableMap } from 'immutable';
import propertyNamespaces from './constants/propertyNamespaces';
export const getNamespace = dataType => {
  return propertyNamespaces[dataType];
};
export const getObjectId = dataType => {
  return `hs_${getNamespace(dataType)}_object_id`;
};
const objectIds = Object.keys(propertyNamespaces).map(getObjectId);
export const isObjectId = property => {
  return objectIds.includes(property);
};
export const namespaceProperty = (dataType, propertyName) => {
  if (isObjectId(propertyName)) {
    return propertyName;
  }
  const namespace = getNamespace(dataType);
  return `${namespace}_${propertyName}`;
};
const namespaceDataTypes = ImmutableMap(propertyNamespaces).flip();
const getDataTypeFromNamespacedProperty = namespacedProperty => namespaceDataTypes.find((_, namespace) => namespacedProperty.startsWith(namespace));
export const extractPropertyNamespace = namespacedProperty => {
  if (isObjectId(namespacedProperty)) {
    const dataType = getDataTypeFromNamespacedProperty(namespacedProperty.split('hs_')[1]);
    return {
      dataType,
      namespace: getNamespace(dataType),
      propertyName: namespacedProperty
    };
  }
  const dataType = getDataTypeFromNamespacedProperty(namespacedProperty);
  if (!dataType) {
    return {
      dataType: undefined,
      namespace: '',
      propertyName: namespacedProperty
    };
  }
  return {
    dataType,
    namespace: getNamespace(dataType),
    propertyName: namespacedProperty.slice(getNamespace(dataType).length + 1)
  };
};