import * as checked from 'reporting-data/lib/checked';
export const EditorTypes = {
  DEFAULT: 'DEFAULT',
  CONDITIONAL: 'CONDITIONAL',
  GUIDED: 'GUIDED',
  RELATIONAL_BUILDER: 'RELATIONAL_BUILDER'
};
export const EditorType = checked.symbol(EditorTypes, 'EditorType');
export const DefaultEditor = checked.record({
  editorType: EditorType.always(EditorTypes.DEFAULT).defaultValue(EditorTypes.DEFAULT),
  editorState: checked.record({})
}, 'DefaultEditor');
export const GuidedEditor = checked.record({
  editorType: EditorType.always(EditorTypes.GUIDED).defaultValue(EditorTypes.GUIDED),
  subEditorType: checked.string()
}, 'GuidedEditor');
export const ConditionalBlock = checked.record({
  conditionInput: checked.string().defaultValue(''),
  isAdvancedExpressionValue: checked.boolean().defaultValue(false),
  valueInput: checked.string().defaultValue('')
});
export const ConditionalEditor = checked.record({
  editorType: EditorType.always(EditorTypes.CONDITIONAL).defaultValue(EditorTypes.CONDITIONAL),
  editorState: checked.record({
    conditionalBlocks: checked.list(ConditionalBlock).defaultValue([ConditionalBlock({})]),
    defaultValue: checked.string().defaultValue('')
  })
}, 'ConditionalEditor');
export const DefaultRelationalEditor = checked.record({
  editorType: EditorType.always(EditorTypes.RELATIONAL_BUILDER).defaultValue(EditorTypes.RELATIONAL_BUILDER),
  editorState: checked.record({
    treatNullsAsZero: checked.boolean().defaultValue(false)
  })
});
export const Editor = checked.poly('editorType', {
  [EditorTypes.DEFAULT]: DefaultEditor,
  [EditorTypes.CONDITIONAL]: ConditionalEditor,
  [EditorTypes.RELATIONAL_BUILDER]: DefaultRelationalEditor,
  [EditorTypes.GUIDED]: GuidedEditor
}, 'Editor');