import { useEffect } from 'react';
import { useShowDialog, useHideDialog
// @ts-expect-error migrate upstream file
} from 'reporting-action-components/dialog/ducks';
import { useIsOnbu038Treatment } from '../../hooks/experimentHooks';
// @ts-expect-error migrate upstream file
import { track, EVENTS } from '../../../tracking/DashboardUITracker';
export const AddContactsModalContainer = ({
  contactsCountTotal
}) => {
  const isOnbu038Treatment = useIsOnbu038Treatment(contactsCountTotal);
  const showDialog = useShowDialog();
  const hideDialog = useHideDialog();
  const handleCTAClick = () => {
    track(EVENTS.onboardingInteraction, {
      action: 'clicked add contacts modal CTA button'
    });
    showDialog({
      dialogType: 'DATA_SYNC_MODAL'
    });
  };
  const handleDoThisLaterClick = () => {
    track(EVENTS.onboardingInteraction, {
      action: 'clicked add contacts modal do this later button'
    });
    hideDialog();
  };
  const handleCloseClick = () => {
    track(EVENTS.onboardingInteraction, {
      action: 'clicked close add contacts modal button'
    });
    hideDialog();
  };
  useEffect(() => {
    if (isOnbu038Treatment) {
      showDialog({
        dialogType: 'ADD_CONTACTS_MODAL',
        dialogProps: {
          onCtaClick: handleCTAClick,
          onDoThisLaterClick: handleDoThisLaterClick,
          onCloseModal: handleCloseClick
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnbu038Treatment]);
  return null;
};