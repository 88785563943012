'use es6';

import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { getCTASByIds, getCTAPage, createGetCTASByIds, createGetCTAPage } from 'reference-resolvers/api/CTAAPI';
export const createCTAReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.CTAS,
  createFetchByIds: createGetCTASByIds,
  createFetchSearchPage: createGetCTAPage,
  fetchByIds: getCTASByIds,
  fetchSearchPage: getCTAPage
}, options));
export default createCTAReferenceResolver();