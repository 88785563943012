'use es6';

import { useCustomReportCount } from '../../../data/hooks/useReportCounts';
import useCustomReportLimit from './useCustomReportLimit';
import { isCustom } from '../../../lib/report-logic';

// If the custom report limit is exceeded, we disable many actions on custom reports to encourage deletion
const useCheckCustomReportLimitExceededAndReportShouldLockdown = ({
  report
}) => {
  const customReportCount = useCustomReportCount();
  const customReportLimit = useCustomReportLimit();
  return isCustom(report) && customReportCount > customReportLimit;
};
export default useCheckCustomReportLimitExceededAndReportShouldLockdown;