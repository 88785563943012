'use es6';

import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as OperatorTypes from '../ObjectSegOperatorTypes';
import * as Operators from '../../../filterQueryFormat/operator/Operators';
import get from 'transmute/get';
import invariant from 'react-utils/invariant';
import protocol from 'transmute/protocol';
import toRefinementFilter from './toRefinementFilter';
const _toPageViewFilter = (operatorName, operator) => {
  const filter = {
    filterType: ObjectSegFilterTypes.PAGE_VIEW,
    operator: operatorName,
    pageUrl: get('value', operator)
  };
  const refinement = operator.get('refinement');
  if (refinement) {
    return Object.assign({}, filter, toRefinementFilter(refinement));
  }
  return filter;
};
const invalidOperator = operator => {
  invariant(false, 'cannot convert Operator "%s" to Page View filter', operator.name);
};
const toPageViewFilter = protocol({
  name: 'toPageViewFilter',
  args: [protocol.TYPE],
  fallback: invalidOperator
});
toPageViewFilter.implement(Operators.PageViewEqual, operator => _toPageViewFilter(OperatorTypes.HAS_PAGEVIEW_EQ, operator));
toPageViewFilter.implement(Operators.PageViewContain, operator => _toPageViewFilter(OperatorTypes.HAS_PAGEVIEW_CONTAINS, operator));
toPageViewFilter.implement(Operators.PageViewMatchRegex, operator => _toPageViewFilter(OperatorTypes.HAS_PAGEVIEW_MATCHES_REGEX, operator));
toPageViewFilter.implement(Operators.PageViewNotEqual, operator => _toPageViewFilter(OperatorTypes.NOT_HAS_PAGEVIEW_EQ, operator));
toPageViewFilter.implement(Operators.PageViewNotContain, operator => _toPageViewFilter(OperatorTypes.NOT_HAS_PAGEVIEW_CONTAINS, operator));
toPageViewFilter.implement(Operators.PageViewNotMatchRegex, operator => _toPageViewFilter(OperatorTypes.NOT_HAS_PAGEVIEW_MATCHES_REGEX, operator));
export default toPageViewFilter;