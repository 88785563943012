'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { Promise } from '../../lib/promise';
import prefix from '../../lib/prefix';
import { POSTAL_MAIL } from '../../constants/dataTypes';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import getCommonPropertyGroups from '../partial/engagement-common';
import countProperty from '../partial/count-property';
import engagementModule from '../../dataTypeDefinitions/inboundDb/engagement';
import overridePropertyTypes from '../../retrieve/inboundDb/common/overridePropertyTypes';
const translatePostalMail = prefix('reporting-data.properties.postalMail');
const translateGroup = prefix('reporting-data.groups.engagement');
export const getPostalMailPropertyGroups = () => Promise.resolve(List.of(ImmutableMap({
  name: 'postalMail',
  displayName: translateGroup('postalMail'),
  displayOrder: 0,
  hubspotDefined: true,
  properties: List([ImmutableMap({
    name: 'postalMail.body',
    label: translatePostalMail('body'),
    type: 'string'
  })])
})));
export const getPropertyGroups = () => Promise.resolve(getPostalMailPropertyGroups().then(postalMailPropertyGroups => List([...getCommonPropertyGroups(), ...postalMailPropertyGroups])));
export const getProperties = () => createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(POSTAL_MAIL)))().then(overridePropertyTypes(engagementModule.getInboundSpec()));