'use es6';

import { Map as ImmutableMap } from 'immutable';
import { InboundDbModule } from '../../module';
import { COMPANIES, CONTACTS, LINE_ITEMS } from '../../constants/dataTypes';
import { hydrate } from '../../retrieve/inboundDb/dynamicHydrate';
import { adapt } from '../../references/adapt';
import productReferences from '../../references/products';
import currencyReferences from '../../references/currency';
import Spec from '../../retrieve/inboundDb/common/specs/Spec';
const getInboundSpec = () => new Spec({
  dataType: LINE_ITEMS,
  properties: {
    idProperty: 'hs_object_id',
    responsePaths: {
      objectId: ['objectId']
    }
  },
  search: {
    url: 'contacts/search/v1/search/lineitems',
    objectsField: 'results'
  },
  hydrate: {
    fn: () => 'TODO_LINE_ITEM'
  }
});
export default InboundDbModule({
  dataType: LINE_ITEMS,
  references: ImmutableMap({
    hs_product_id: adapt(productReferences),
    hs_line_item_currency_code: adapt(currencyReferences)
  }),
  referenceProperties: ImmutableMap({
    'associations.company': COMPANIES,
    'associations.contact': CONTACTS,
    objectId: LINE_ITEMS
  }),
  hydrate: (ids, config) => hydrate(LINE_ITEMS, ids, config),
  getInboundSpec
});