import { MAX_VISIBLE_PAGES } from '../constants/paginationSettings';
import { fromJS } from 'immutable';
// @ts-expect-error NewRelic doesn't have types yet.
import { NewRelicInteraction } from '../monitoring/newrelic';
import { isSupportedUEReport } from './unifiedEvents/utils';
import { TABLE } from '../constants/chartTypes';
const UNIFIED_EVENTS_REPORT_WITHOUT_PAGINATION_LIMIT = 'ReportingDataUnifiedEventsWithoutPaginationLimit';
const UNIFIED_EVENTS_REPORT_WITH_PAGINATION_LIMIT_REDUCED_AT_RUNTIME = 'ReportingDataUnifiedEventsWithPaginationLimitReducedAtRuntime';
const UNIFIED_EVENTS_PAGINATION_TABLE_MAX_LIMIT = 100;
const UNIFIED_EVENTS_PAGINATION_NON_TABLE_MAX_LIMIT = 500;
const UNIFIED_EVENTS_PAGINATION_DEFAULT_API_LIMIT = 500;
const isTableChartType = report => {
  return report.get('chartType') === TABLE;
};
export const getPaginationClientLimit = report => {
  const configLimit = report.getIn(['config', 'limit']);
  if (isSupportedUEReport(report)) {
    const noConfigLimit = !configLimit;
    const isTableReport = isTableChartType(report);
    const maxLimit = isTableReport ? UNIFIED_EVENTS_PAGINATION_TABLE_MAX_LIMIT : UNIFIED_EVENTS_PAGINATION_NON_TABLE_MAX_LIMIT;
    const excessiveConfigLimit = configLimit > maxLimit;
    if (noConfigLimit || excessiveConfigLimit) {
      const newRelicInteraction = new NewRelicInteraction();
      newRelicInteraction.addReportAttributes(report);
      if (noConfigLimit) {
        newRelicInteraction.sendPageAction(UNIFIED_EVENTS_REPORT_WITHOUT_PAGINATION_LIMIT);
      } else if (excessiveConfigLimit) {
        newRelicInteraction.sendPageAction(UNIFIED_EVENTS_REPORT_WITH_PAGINATION_LIMIT_REDUCED_AT_RUNTIME);
      }
      return maxLimit;
    }
  }
  return configLimit;
};
export const getPaginationApiSettings = report => {
  if (isSupportedUEReport(report)) {
    const clientLimit = getPaginationClientLimit(report);
    const clientOffset = report.getIn(['config', 'offset']) || 0;
    if (isTableChartType(report)) {
      const apiLimit = Math.max(
      // subtracting the remainder below will ensure that we don't end up with a partial last page,
      // which causes the component rendering the pagination buttons to not render enough buttons
      // to continue requesting additional data from the API
      UNIFIED_EVENTS_PAGINATION_DEFAULT_API_LIMIT - UNIFIED_EVENTS_PAGINATION_DEFAULT_API_LIMIT % clientLimit,
      // The current UNIFIED_EVENTS_PAGINATION_TABLE_MAX_LIMIT and UNIFIED_EVENTS_PAGINATION_DEFAULT_API_LIMIT
      // constant values don't allow for the below (pages * clientLimit) to ever
      // be used here, but if someone were to change UNIFIED_EVENTS_PAGINATION_TABLE_MAX_LIMIT
      // to a value where UNIFIED_EVENTS_PAGINATION_TABLE_MAX_LIMIT * MAX_VISIBLE_PAGES
      // exceeds UNIFIED_EVENTS_PAGINATION_DEFAULT_API_LIMIT, the code below
      // will ensure we have at least enough data loaded for the page buttons being rendered
      MAX_VISIBLE_PAGES * clientLimit);
      return fromJS({
        limit: apiLimit,
        offset: Math.floor(clientOffset / apiLimit) * apiLimit
      });
    } else {
      return fromJS({
        limit: clientLimit,
        offset: clientOffset
      });
    }
  }
  return fromJS({
    limit: report.getIn(['config', 'limit']),
    offset: report.getIn(['config', 'offset'])
  });
};