import { retrieve } from './request';
const getQueryWithOptions = query => ({
  journeyQuery: query,
  reportOptions: {
    limit: 1000,
    offset: 0,
    fetchFromCache: false,
    updateCache: true // Bust the cache
  }
});
export const refresh = (immutableJourneyQuery, reportId) => {
  const journeyQuery = immutableJourneyQuery.toJS();
  return retrieve(getQueryWithOptions(journeyQuery), reportId);
};