'use es6';

import { SecondaryDateRangeControl } from '../../components/controls/SecondaryDateRangeControl';
export const syncDateRanges = (contextChange, __context, report) => {
  if (SecondaryDateRangeControl.isUsableForReport({
    report
  }) && contextChange.dateRange && !contextChange.secondaryDateRange) {
    contextChange.secondaryDateRange = contextChange.dateRange;
  }
  return contextChange;
};