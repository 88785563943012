'use es6';

import { VisualTypes } from '../../../schema/visual-records';
import { getReportVisualType, getReportXEncoding } from '../../../utils/report-utils';
import { combineConstraints, ConstraintTypes, createConstraint, InvalidSpecificationTypes } from '../../validate';
import { twoOrFewerValueChannels, xyLikeVisualIsDisplayable } from './common-constraints';
import { getEncodingColumnAlias } from '../../../utils/visual-utils';
import { checkIfSortIsAccumulateFriendly } from './check-sort-is-accumulate-friendly';
import { isDateLikeField } from '../../../utils/column-utils';
import { checkAllDateDimensionsHaveTransform } from './check-all-date-dimensions-have-transformation';
const getXColumnId = report => {
  const x = getReportXEncoding(report);
  return x && getEncodingColumnAlias(x);
};
export const sortIsAccumulateFriendly = createConstraint('sortIsAccumulateFriendly', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (isAccumulateSet, sorts, xId) => {
  if (!isAccumulateSet) {
    return true;
  }
  return checkIfSortIsAccumulateFriendly(sorts, xId);
}, [report => report.visual.options.accumulate, report => report.sorts, getXColumnId]);
const xAxisIsAccumulateFriendly = createConstraint('xAxisIsAccumulateFriendly', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (accumulate, maybeXEncoding) => {
  if (!accumulate) {
    return true;
  }
  const maybeColumn = maybeXEncoding && maybeXEncoding.column;
  return maybeColumn === undefined ? true : isDateLikeField(maybeColumn.field);
}, [report => report.visual.options.accumulate, report => getReportXEncoding(report)]);
const dateDimensionsHaveTransformation = createConstraint('dateDimensionsHaveTransformation', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, checkAllDateDimensionsHaveTransform, [report => report.columns]);
export const line = combineConstraints('line', [twoOrFewerValueChannels, xyLikeVisualIsDisplayable, sortIsAccumulateFriendly, xAxisIsAccumulateFriendly, dateDimensionsHaveTransformation], (constraint, report) => getReportVisualType(report) !== VisualTypes.LINE || constraint.validate(report));