'use es6';

import { makeOperator } from './Operator';
const isAnyValue = value => value != null;
export default makeOperator({
  name: 'NeverEqual',
  values: [{
    name: 'value',
    isValid: isAnyValue
  }]
});