// @ts-expect-error migrate upstream
import { useUserRoleForDashboard } from 'reporting-action-components/utils/trackingUtils';
import { trackFilterInteraction
// @ts-expect-error migrate upstream
} from 'dashboard-ui-components/tracking/DashboardUITracker';
import { addDashboardFilter, updateDashboardFilter } from '../../../../../../ducks/applied-dashboard-filters';
import { useSavedDashboardFiltersPanelContext } from '../context';
import { useDispatch } from 'react-redux';
export const useAddOrUpdateAppliedFilter = () => {
  const {
    editingFilterIdx,
    toManageTabbedView,
    dashboard,
    dashboardId
  } = useSavedDashboardFiltersPanelContext();
  const userRole = useUserRoleForDashboard(dashboard);
  const dispatch = useDispatch();
  const handleAddOrUpdateFilter = params => {
    const {
      dataSource,
      dataSourceName,
      operator,
      location,
      property
    } = params;
    const filterData = {
      dataSource,
      dataSourceName,
      operator,
      property
    };
    if (editingFilterIdx != null) {
      dispatch(updateDashboardFilter({
        dashboardId,
        index: editingFilterIdx,
        filterData
      }));
      trackFilterInteraction({
        action: 'edit-filter',
        filterData,
        userRole,
        additionalProps: {
          interactionSource: 'filters-panel',
          dashboardId
        }
      });
    } else {
      const payload = {
        dashboardId,
        filterData,
        dataSource,
        property
      };
      dispatch(addDashboardFilter(payload));
      trackFilterInteraction({
        action: 'add-filter',
        filterData,
        userRole,
        additionalProps: {
          category: location,
          interactionSource: 'filters-panel',
          dashboardId
        }
      });
    }
    toManageTabbedView();
  };
  return handleAddOrUpdateFilter;
};