import { Map as ImmutableMap, List, fromJS } from 'immutable';
// @ts-expect-error migrate upstream
import makeDateRangeByType from 'reporting-data/lib/makeDateRangeByType';
const hasSecondaryRangeRange = report => ['WATERFALL', 'CHANGE_HISTORY_DEAL_LIST'].includes(report.getIn(['displayParams', 'customWidget', 'type']));
export const isSalesQuota = report => report.getIn(['displayParams', 'customWidget', 'type']) === 'SALES_QUOTA';
export const applyDateRangeToSalesAnalytics = (dateRange, report) => {
  if (isSalesQuota(report)) {
    return report;
  }
  const reportDateRange = report.getIn(['config', 'filters', 'dateRange']);
  const property = reportDateRange.get('property');
  const newDateRange = makeDateRangeByType(dateRange.toJS(), 'YYYY-MM-DD');
  const {
    startDate,
    endDate
  } = newDateRange;
  return report.updateIn(['config', 'filters', 'custom'], (custom = List()) => hasSecondaryRangeRange(report) ? custom.filter(filter => filter.get('property') !== property).concat(fromJS([{
    property,
    operator: 'GT',
    value: startDate
  }, {
    property,
    operator: 'LT',
    value: endDate
  }])) : custom).setIn(['config', 'filters', 'dateRange', 'value'], ImmutableMap(newDateRange));
};