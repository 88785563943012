'use es6';

import { AD_OBJECT_AD } from 'reference-resolvers/constants/ReferenceObjectTypes';
import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import { ADS } from 'reference-resolvers/constants/CacheKeys';
import { createFetchAdsObjectSearchPage, createFetchAdsObjectsByIds, fetchAdsObjectsByIds, fetchAdsObjectSearchPage } from 'reference-resolvers/api/AdsEntityApi';
export const createAdsAdsReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: ADS,
  createFetchByIds: opts => createFetchAdsObjectsByIds(opts)(AD_OBJECT_AD),
  createFetchSearchPage: opts => createFetchAdsObjectSearchPage(opts)(AD_OBJECT_AD),
  fetchByIds: fetchAdsObjectsByIds(AD_OBJECT_AD),
  fetchSearchPage: fetchAdsObjectSearchPage(AD_OBJECT_AD)
}, options));
export default createAdsAdsReferenceResolver();