'use es6';

import { fromMoment, toMoment, toMomentPortalTz, toMomentUTC } from 'UIComponents/core/SimpleDate';
import I18n from 'I18n';
import { DATE_TIME } from 'customer-data-objects/property/PropertyTypes';
const dateFormat = 'Y-MM-DD';
export function toSimpleDate(fieldType, dateString) {
  if (!dateString) {
    return null;
  }
  if (fieldType === DATE_TIME) {
    return fromMoment(I18n.moment.utc(dateString));
  }
  return fromMoment(I18n.moment.portalTz(dateString));
}
export function fromSimpleDate(fieldType, simpleDate) {
  if (simpleDate == null) return null;
  const moment = fieldType === DATE_TIME ? toMomentUTC(simpleDate) : toMoment(simpleDate);
  return moment.format(dateFormat);
}
export const fromSimpleDateToMoment = (simpleDate, useUTC = false) => {
  if (!simpleDate) {
    return null;
  }
  return useUTC ? toMomentUTC(simpleDate) : toMomentPortalTz(simpleDate);
};
export const fromMomentToSimpleDate = momentDate => {
  if (!momentDate) {
    return null;
  }
  return fromMoment(momentDate);
};