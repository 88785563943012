'use es6';

import createSimpleCachedReferenceResolver from '../lib/createSimpleCachedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { getAllSubdomains, createGetAllSubdomains } from '../api/SubdomainApi';
export const createSubdomainsReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.SUBDOMAINS,
  createFetchData: createGetAllSubdomains,
  fetchData: getAllSubdomains
}, options));
export default createSubdomainsReferenceResolver();