'use es6';

const computeTotals = response => {
  const {
    breakdowns = [],
    totals
  } = response;
  const mobileBucket = breakdowns.find(({
    breakdown
  }) => breakdown === 'MOBILE');
  const mobileSessionRate = mobileBucket ? mobileBucket.visits / totals.visits : undefined;
  return Object.assign({}, totals, {
    mobileSessionRate
  });
};
export const preprocess = (spec, config) => response => {
  const {
    dimensions = []
  } = config;
  if (dimensions.length === 0) {
    return Object.assign({}, response, {
      totals: computeTotals(response)
    });
  }
  return response;
};