'use es6';

import { fromJS } from 'immutable';
import formatSequenceStepOrders from '../formatters/formatSequenceStepOrders';

// This is helping to transform a property which has values that are Zero Indexed to be One Indexed
export const createGetStepOrders = () => stepOrders => Promise.resolve(formatSequenceStepOrders(stepOrders.map(stepOrder => +stepOrder) // coerce to number
.map(stepOrder => ({
  id: stepOrder,
  stepOrder: stepOrder + 1
}))));
export const getStepOrders = createGetStepOrders();
export const createFetchStepOrdersSearchPage = () => props => {
  const {
    count,
    offset
  } = props.toJS();
  return Promise.resolve(fromJS({
    results: formatSequenceStepOrders(Array.from(new Array(count), (__, i) => i + offset).map(index => ({
      id: index,
      stepOrder: index + 1
    }))),
    count,
    offset: offset + count,
    hasMore: true
  }));
};
export const fetchStepOrdersSearchPage = createFetchStepOrdersSearchPage();