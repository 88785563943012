import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["propertyDefinitions"];
import { deepFreeze } from '../cache/deepFreeze';
import PortalIdParser from 'PortalIdParser';
import { mergeFrameworkTypesAndMetadata } from './mergeFrameworkTypesAndMetadata';
import { createInMemoryCache } from '../cache/createInMemoryCache';
import { mergePropertiesAndMetadata } from './mergePropertiesAndMetadata';
import { Metrics } from '../metrics';
import { makeLoadFailed } from '../utils/makeLoadFailed';
const defaultGraphOperationCache = createInMemoryCache({
  cacheName: 'graph-ops'
});
const defaultGetOperationCacheKey = ({
  operationName
}) => `${PortalIdParser.get()}-graph-${operationName}`;
export const makeGraphClient = ({
  propertiesClientPromise,
  frameworkTypesClientPromise,
  typeMetadataClientPromise,
  propertyMetadataClientPromise,
  operationCache = defaultGraphOperationCache,
  toOperationCacheKey = defaultGetOperationCacheKey
}) => {
  const waitForClients = async () => {
    const clients = await Promise.all([propertiesClientPromise, frameworkTypesClientPromise, typeMetadataClientPromise, propertyMetadataClientPromise]);
    return {
      propertiesClient: clients[0],
      typesClient: clients[1],
      typeMetadataClient: clients[2],
      propertyMetadataClient: clients[3]
    };
  };
  const makeGetFrameworkTypeFamilyWithMetadata = family => ({
    appSettingNames = [],
    __isComposed = false
  } = {}) => {
    if (!__isComposed) {
      Metrics.counter(`graph.frameworkTypes.${family === 'objects' ? 'getObjects' : 'getEvents'}`).increment();
    }
    const cachedValue = operationCache.readThrough({
      cacheKey: toOperationCacheKey({
        operationName: `getFrameworkTypesWithMetadata-${family}-${appSettingNames.slice().sort()}`
      }),
      loadValue: async () => {
        const {
          typesClient,
          typeMetadataClient
        } = await waitForClients();
        const [frameworkTypes, metadata] = await Promise.all([family === 'events' ? typesClient.getEvents({
          __isComposed: true
        }) : typesClient.getObjects({
          __isComposed: true
        }), appSettingNames.length ? typeMetadataClient.get({
          appSettingNames,
          __isComposed: true
        }) : Promise.resolve({})]);
        const typesWithMetadata = mergeFrameworkTypesAndMetadata({
          frameworkTypes,
          metadata
        });
        return deepFreeze(typesWithMetadata);
      }
    });
    return cachedValue === null ? makeLoadFailed() : cachedValue;
  };
  const graphClient = {
    /**
     * Prints debug info to the console.
     */
    debug: () => {
      operationCache.printDebug();
    },
    /**
     * Clears internal cache state
     *
     * @returns A promise which resolves when state is clear.
     */
    clearCache: async () => {
      await Promise.resolve(operationCache.clear());
    },
    frameworkTypes: {
      /**
       * Gets all object type definitions and merges the relevant type metadata entries
       * into them under the typeMetadata field.
       * @param options.appSettingNames The app settings to query for type metadata entries.
       * @returns A promise which resolves to all typeDefs with their metadata, or null if the data could not be found.
       */
      getObjects: makeGetFrameworkTypeFamilyWithMetadata('objects'),
      /**
       * Gets all event type definitions and merges the relevant type metadata entries
       * into them under the typeMetadata field.
       * @param options.appSettingNames The app settings to query for type metadata entries.
       * @returns A promise which resolves to all typeDefs with their metadata, or null if the data could not be found.
       */
      getEvents: makeGetFrameworkTypeFamilyWithMetadata('events')
    },
    properties: {
      /**
       * Gets all the property groups with their properties in the portal, and merges the relevant
       * property metadata entries into the property definition under the propertyMetadata field.
       * @param options.frameworkTypeIdentifier The framework type to fetch properties and groups for.
       * @param options.appSettingNames A list of app settings to query for property metadata entries.
       * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
       * @returns A promise which resolves to all groups and properties with metadata, or null if the data could not be found.
       */
      getGroups: async ({
        frameworkTypeIdentifier,
        appSettingNames,
        __isComposed = false
      }) => {
        if (!__isComposed) {
          Metrics.counter('graph.properties.getGroups').increment();
        }
        const cachedValue = await operationCache.readThrough({
          cacheKey: toOperationCacheKey({
            operationName: `getPropertyGroupsWithPropertyMetadata-${frameworkTypeIdentifier}-${appSettingNames.slice().sort()}`
          }),
          loadValue: async () => {
            const {
              propertiesClient,
              propertyMetadataClient
            } = await waitForClients();
            const [propertyGroups, ...allAppSettings] = await Promise.all([propertiesClient.getGroups({
              frameworkTypeIdentifier,
              __isComposed: true
            }), ...appSettingNames.map(appSettingName => propertyMetadataClient.get({
              frameworkTypeIdentifier,
              appSettingName,
              __isComposed: true
            }))]);
            const merged = propertyGroups.map(_ref => {
              let {
                  propertyDefinitions
                } = _ref,
                group = _objectWithoutPropertiesLoose(_ref, _excluded);
              return Object.assign({}, group, {
                propertyDefinitions: propertyDefinitions ? mergePropertiesAndMetadata({
                  propertyDefinitions,
                  appSettingNames,
                  appSettingsResponses: allAppSettings
                }) : null
              });
            });
            return deepFreeze(merged);
          }
        });
        return cachedValue === null ? makeLoadFailed() : cachedValue;
      },
      /**
       * Gets all the properties in the portal, and merges the relevant
       * property metadata entries into the property definition under the propertyMetadata field.
       * @param options.frameworkTypeIdentifier The framework type to fetch properties for.
       * @param options.appSettingNames A list of app settings to query for property metadata entries.
       * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
       * @returns A promise which resolves to all properties with metadata, or null if the data could not be found.
       */
      get: async ({
        frameworkTypeIdentifier,
        appSettingNames,
        __isComposed = false
      }) => {
        if (!__isComposed) {
          Metrics.counter('graph.properties.get').increment();
        }
        const cachedValue = await operationCache.readThrough({
          cacheKey: toOperationCacheKey({
            operationName: `getPropertiesWithMetadata-${frameworkTypeIdentifier}-${appSettingNames.slice().sort()}`
          }),
          loadValue: async () => {
            const {
              propertiesClient,
              propertyMetadataClient
            } = await waitForClients();
            const [propertyDefinitions, ...appSettingsResponses] = await Promise.all([propertiesClient.get({
              frameworkTypeIdentifier,
              __isComposed: true
            }), ...appSettingNames.map(appSettingName => propertyMetadataClient.get({
              frameworkTypeIdentifier,
              appSettingName,
              __isComposed: true
            }))]);
            const merged = mergePropertiesAndMetadata({
              propertyDefinitions,
              appSettingNames,
              appSettingsResponses
            });
            return deepFreeze(merged);
          }
        });
        return cachedValue === null ? makeLoadFailed() : cachedValue;
      },
      /**
       * Gets a specific property and merges metadata from the requested app settings into its definition
       * under the propertyMetadata field.
       * @param options.frameworkTypeIdentifier The framework type to fetch properties for.
       * @param options.propertyName The property to look for.
       * @param options.appSettingNames A list of app settings to query for property metadata entries.
       * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
       * @returns A promise which resolves to all properties with metadata, or null if the data could not be found.
       */
      getProperty: async ({
        frameworkTypeIdentifier,
        propertyName,
        appSettingNames,
        __isComposed = false
      }) => {
        if (!__isComposed) {
          Metrics.counter('graph.properties.getProperty').increment();
        }
        const cachedValue = await operationCache.readThrough({
          cacheKey: toOperationCacheKey({
            operationName: `getPropertyWithMetadata-${frameworkTypeIdentifier}-${appSettingNames.slice().sort()}-${propertyName}`
          }),
          loadValue: async () => {
            const {
              propertiesClient,
              propertyMetadataClient
            } = await waitForClients();
            const [property, ...appSettingsResponses] = await Promise.all([propertiesClient.getProperty({
              frameworkTypeIdentifier,
              propertyName,
              __isComposed: true
            }), ...appSettingNames.map(appSettingName => propertyMetadataClient.get({
              frameworkTypeIdentifier,
              appSettingName,
              __isComposed: true
            }))]);
            const [propertyWithMetadata] = mergePropertiesAndMetadata({
              propertyDefinitions: [property],
              appSettingNames,
              appSettingsResponses
            });
            return deepFreeze(propertyWithMetadata);
          }
        });
        return cachedValue === null ? makeLoadFailed() : cachedValue;
      }
    }
  };
  return Promise.resolve(graphClient);
};