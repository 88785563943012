export const Contexts = {
  // Undefined context is when we cannot reliably determine context
  UNDEFINED: 'UNDEFINED',
  // Constant context is allowed in both row and aggregate contexts
  CONSTANT: 'CONSTANT',
  // Row-level context is non-aggregate calculation on columns
  ROW_LEVEL: 'ROW_LEVEL',
  // Aggregate context is aggregate calculation on columns
  AGGREGATE: 'AGGREGATE'
};
export const UNDEFINED_CONTEXT = Contexts.UNDEFINED;