import * as checked from 'reporting-data/lib/checked';
import { Encoding } from '../column-records';
import { ColorOption, showDataLabels, showRecordIds, legendPosition, legendWidth } from '../highcharts-settings';
import { VisualTypes } from '../constants/visual-constants';
export const PieEncodings = checked.record({
  color: Encoding.optional(),
  values: checked.list(Encoding)
}, 'PieEncodings');
export const PieVisualOptions = checked.record({
  showDataLabels,
  showRecordIds,
  legendPosition,
  legendWidth,
  color: ColorOption.defaultValue({}),
  showTotals: checked.boolean().defaultValue(true)
});
export const PieVisual = checked.record({
  type: checked.string().always(VisualTypes.PIE),
  encodings: PieEncodings,
  options: PieVisualOptions.defaultValue({})
}, 'PieVisual');