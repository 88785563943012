import { AssociationDisplayType } from '../DisplayTypes';
import { AssociationInputType } from '../InputTypes';
import { makeOperator } from './Operator';
import isString from 'transmute/isString';
export default makeOperator({
  name: 'AssociatedTo',
  displayType: AssociationDisplayType,
  inputType: AssociationInputType,
  values: [{
    name: 'propertySuffix',
    isValid: isString
  }, {
    name: 'value',
    isValid: isString
  }]
});