'use es6';

import { getFetchedDashboardById } from '../lib/DashboardRetriever';
import { createSelector } from '../lib/ImmutableReselect';
import { getPathParams } from '../lib/routerUtils';
import DashboardStore from '../stores/DashboardStore';
import SettingsStore from '../stores/SettingsStore';
import { USER_ATTRIBUTES_KEYS } from '../data/UserAttributesDAO';
const dashboardSelector = ({
  match
}) => {
  const {
    dashboardId
  } = getPathParams(match);
  return getFetchedDashboardById(dashboardId);
};
const userAttributesSelector = () => SettingsStore.get('user-attributes');
export const DashboardDependency = {
  stores: [DashboardStore],
  deref: createSelector(dashboardSelector, dashboard => dashboard)
};
export const DefaultDashboardDependency = {
  stores: [SettingsStore],
  deref: createSelector(userAttributesSelector, userAttributes => userAttributes.get(USER_ATTRIBUTES_KEYS.PREFERRED_HOME))
};