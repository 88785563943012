import { fetchContactCount } from '../data/ContactCountApi';
import { fromJS } from 'immutable';
import RequestStatusType from '../constants/RequestStatusType';
export const contactCountNamespace = 'contactCount';
const {
  UNINITIALIZED,
  PENDING,
  SUCCESS,
  FAILURE
} = RequestStatusType;
// Action types
const FETCH_CONTACT_COUNT_PENDING = 'FETCH_CONTACT_COUNT_PENDING';
const FETCH_CONTACT_COUNT_FAILED = 'FETCH_CONTACT_COUNT_FAILED';
const FETCH_CONTACT_COUNT_SUCCEEDED = 'FETCH_CONTACT_COUNT_SUCCEEDED';
// Actions
export const getContactCount = () => dispatch => {
  dispatch({
    type: FETCH_CONTACT_COUNT_PENDING
  });
  return fetchContactCount().then(response => {
    dispatch({
      type: FETCH_CONTACT_COUNT_SUCCEEDED,
      payload: response
    });
  }, error => {
    console.error(error);
    dispatch({
      type: FETCH_CONTACT_COUNT_FAILED
    });
  });
};

// Reducer
const initialState = fromJS({
  status: {
    fetchContactCount: UNINITIALIZED
  },
  contactCount: undefined
});
export const contactCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTACT_COUNT_PENDING:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {
          fetchContactCount: PENDING
        })
      });
    case FETCH_CONTACT_COUNT_SUCCEEDED:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {
          fetchContactCount: SUCCESS
        }),
        [contactCountNamespace]: action.payload
      });
    case FETCH_CONTACT_COUNT_FAILED:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {
          fetchContactCount: FAILURE
        })
      });
    default:
      return state;
  }
};

// Selectors
export const selectContactCount = state => {
  var _state$contactCountNa;
  return (_state$contactCountNa = state[contactCountNamespace]) === null || _state$contactCountNa === void 0 || (_state$contactCountNa = _state$contactCountNa.contactCount) === null || _state$contactCountNa === void 0 ? void 0 : _state$contactCountNa.total;
};