'use es6';

import { createActions } from 'redux-actions';
import identity from 'transmute/identity';

/**
 * Why are the values lower case strings?
 * `redux-action/createActions` returns an object of actions with keys that are
 * converted to lowercase strings. I haven't found a way around this yet.
 *
 * To have consistency in referencing a request-action, the types are here are
 * all lowercase.
 */
export const RequestActionType = {
  PEND: 'pend',
  SUCCEED: 'succeed',
  FAIL: 'fail',
  CANCEL: 'cancel'
};
const createMetadata = (_, requestParams) => ({
  requestParams
});
export const createAsyncActions = (prefix, options = {}) => {
  const {
    createFailMetadata,
    createSucceedMetadata
  } = options;
  return createActions({
    [RequestActionType.PEND]: [identity, createMetadata],
    [RequestActionType.SUCCEED]: [identity, createSucceedMetadata || createMetadata],
    [RequestActionType.FAIL]: [identity, createFailMetadata || createMetadata],
    [RequestActionType.CANCEL]: [identity, createMetadata]
  }, {
    prefix
  });
};