import { NUMBER, STRING } from 'customer-data-objects/property/PropertyTypes';
import { hasExternalOptionsForAnyPropertyType } from '../permissions/gates';
import { resolveIsUngated } from './UserInfoHelper';

/**
 * When ungated, String and Number properties that are configured to use
 * external options should be treated as an Enumeration
 */
export const isExternalOptionsEnum = field => {
  if (!field || typeof field.get !== 'function') {
    return false;
  }
  const isUngatedForAnyPropertyExternalOptions = hasExternalOptionsForAnyPropertyType(resolveIsUngated());
  const isStringOrNumber = field.get('type') === STRING || field.get('type') === NUMBER;
  const configuredToUseExternalOptions = !!field.get('externalOptionsReferenceType');
  return isUngatedForAnyPropertyExternalOptions && isStringOrNumber && configuredToUseExternalOptions;
};