import { AsyncStatus } from './AsyncStatus';
export function isUninitialized(asyncData) {
  return asyncData.status === AsyncStatus.UNINITIALIZED;
}
export function isStarted(asyncData) {
  return asyncData.status === AsyncStatus.STARTED;
}
export function isFailed(asyncData) {
  return asyncData.status === AsyncStatus.FAILED;
}
export function isSucceeded(asyncData) {
  return asyncData.status === AsyncStatus.SUCCEEDED;
}
export function isLoading(asyncData) {
  return isUninitialized(asyncData) || isStarted(asyncData);
}
export function buildUnitializedAsyncData() {
  return {
    status: AsyncStatus.UNINITIALIZED
  };
}
export function buildStartedAsyncData() {
  return {
    status: AsyncStatus.STARTED
  };
}
export function buildSucceededAsyncData(data) {
  return {
    status: AsyncStatus.SUCCEEDED,
    data
  };
}
export function buildFailedAsyncData(error) {
  return {
    status: AsyncStatus.FAILED,
    error
  };
}