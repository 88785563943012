import { Field } from './expression-records';
import { expressionToString } from './expression-utils';

// see unit test for description of function
export const textRenameField = (expressionText = '', fromName, toName) => {
  const fromFieldText = expressionToString(Field({
    name: fromName
  }));
  const toFieldText = expressionToString(Field({
    name: toName
  }));
  return expressionText.replaceAll(fromFieldText, toFieldText);
};

// UTF-8 to Base64
export const encodeInput = input => window.btoa(unescape(encodeURIComponent(input)));

// Base64 to UTF-8
export const decodeInput = input => decodeURIComponent(escape(window.atob(input)));
export const tryDecodeInput = field => {
  try {
    return field.update('input', decodeInput);
  } catch (__e) {
    return field;
  }
};
export const tryEncodeInput = field => {
  try {
    return field.update('input', encodeInput);
  } catch (__e) {
    return field;
  }
};
export const encodeFieldInputs = datasetDefinition => datasetDefinition.update('fields', fields => fields.map(tryEncodeInput).toMap());