'use es6';

import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
import { createGetBusinessUnitDropdownOptions, getBusinessUnitDropdownOptions } from '../api/BusinessUnitDropdownAPI';
export const createBusinessUnitDropdownReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.BUSINESS_UNIT_DROPDOWN,
  createFetchData: createGetBusinessUnitDropdownOptions,
  fetchData: getBusinessUnitDropdownOptions
}, options));
export default createBusinessUnitDropdownReferenceResolver();