'use es6';

import { findContactFilters, findEngagementFilters, findSubmissionFilters, findTicketFilters } from '../../../configure/unified/feedback/common';
import * as DataTypes from '../../../constants/dataTypes';
const dataTypes = [DataTypes.UNIFIED_FEEDBACK_WEB, DataTypes.UNIFIED_FEEDBACK_EMAIL, DataTypes.UNIFIED_FEEDBACK_TRENDS];
export const get = (spec, config, supported = dataTypes) => {
  const {
    dataType,
    filters: {
      custom = []
    } = {}
  } = config;
  if (supported.includes(dataType)) {
    const contactFilters = findContactFilters(custom);
    const engagementFilters = findEngagementFilters(custom);
    const submissionFilters = findSubmissionFilters(custom);
    const ticketFilters = findTicketFilters(custom);
    return {
      method: 'post',
      data: {
        contactFilters,
        engagementFilters,
        submissionFilters,
        ticketFilters
      }
    };
  }
  return {
    method: 'get',
    data: {}
  };
};