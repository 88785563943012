'use es6';

import { AssetPermission } from '../../../../public/permission/asset-permission';
import { hasSufficientPermission } from '../../../../public/permission/compute-permission';
import { getAndCheck } from '../../common/get-and-check-param';
import { getAllProblemsCurried } from '../../common/get-problems-no-check';
import { createProblem } from '../../common/problem';
import { DASHBOARD_IS_LOADING, IS_READ_ONLY_DEMO, USER_DASHBOARD_PERMISSION, RESTRICTED_FIELD_LEVEL_PROPERTIES } from '../../params/all-params';
import { NEED_EDIT_PERMISSION } from '../../problems/permission-problem';
import { LACKS_SNOWFLAKE_TEMPLATE_ACCESS } from '../../problems/snowflake-template-problem';
import { LACKS_PAYMENTS_REPORT_ACCESS } from '../../problems/payment-reporting-problem';
export const LOADING = createProblem('LOADING', params => getAndCheck(DASHBOARD_IS_LOADING, params));
export const PERMISSION_DENIED = createProblem('PERMISSION_DENIED', params => {
  const dashboardPermission = getAndCheck(USER_DASHBOARD_PERMISSION, params);
  return !hasSufficientPermission(AssetPermission.EDIT, dashboardPermission);
});
export const IS_READ_ONLY_DEMO_PROBLEM = createProblem('IS_READ_ONLY_DEMO_PROBLEM', params => getAndCheck(IS_READ_ONLY_DEMO, params));
export const LACKS_FIELD_ACCESS = createProblem('LACKS_FIELD_ACCESS', params => {
  const restrictedFieldLevelProperties = getAndCheck(RESTRICTED_FIELD_LEVEL_PROPERTIES, params);
  return restrictedFieldLevelProperties && restrictedFieldLevelProperties.length > 0;
});
export const rankedProblems = [LOADING, NEED_EDIT_PERMISSION, PERMISSION_DENIED, LACKS_FIELD_ACCESS, IS_READ_ONLY_DEMO_PROBLEM, LACKS_SNOWFLAKE_TEMPLATE_ACCESS, LACKS_PAYMENTS_REPORT_ACCESS];
export const getAll = getAllProblemsCurried(rankedProblems);