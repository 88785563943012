'use es6';

import { List } from 'immutable';
import getInboundDbPropertyGroups from '../../retrieve/inboundDb/common/properties';
import { DEAL_CREATE_ATTRIBUTION } from '../../constants/dataTypes';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import countProperty from '../partial/count-property';
export const getPropertyGroups = () => getInboundDbPropertyGroups(DEAL_CREATE_ATTRIBUTION).then(groups => List([...groups]));

// TODO: Remove overrides once attribution property types are changed in the backend
export const getProperties = () => createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(DEAL_CREATE_ATTRIBUTION)))();