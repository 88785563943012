'use es6';

import { useIsProUpgradeEligible, useIsStarterUpgradeEligible } from '../../../access/scopes/hooks/useHasScope';
export const UPGRADE_POINT_LOCATIONS = {
  DASHBOARD_VIEW: 'DASHBOARD_VIEW',
  DASHBOARD_EDIT: 'DASHBOARD_EDIT',
  DASHBOARD_CREATE: 'DASHBOARD_CREATE',
  REPORT_LIST_VIEW: 'REPORT_LIST_VIEW',
  REPORT_LIST_EDIT: 'REPORT_LIST_EDIT',
  REPORT_LIST_CREATE: 'REPORT_LIST_CREATE',
  DASHBOARD_LIST_CREATE: 'DASHBOARD_LIST_CREATE',
  REPORT_LIBRARY_CREATE: 'REPORT_LIBRARY_CREATE',
  DASHBOARD_LIBRARY_CREATE: 'DASHBOARD_LIBRARY_CREATE',
  DISCOVER_TEMPLATES_CREATE: 'DISCOVER_TEMPLATES_CREATE',
  DISCOVER_BUILDERS_CREATE: 'DISCOVER_BUILDERS_CREATE'
};
export const UPGRADE_POINT_LOCATION_VALUES = Object.values(UPGRADE_POINT_LOCATIONS);
export const REPORT_BUILDER_UPGRADE_KEYS = {
  [UPGRADE_POINT_LOCATIONS.DASHBOARD_VIEW]: 'report-builder-reports-dashboard-view-report',
  [UPGRADE_POINT_LOCATIONS.DASHBOARD_EDIT]: 'report-builder-reports-dashboard-edit-report',
  [UPGRADE_POINT_LOCATIONS.DASHBOARD_CREATE]: 'report-builder-reports-dashboard-create-report',
  [UPGRADE_POINT_LOCATIONS.REPORT_LIST_VIEW]: 'report-builder-report-list-view-report',
  [UPGRADE_POINT_LOCATIONS.REPORT_LIST_EDIT]: 'report-builder-report-list-edit-report',
  [UPGRADE_POINT_LOCATIONS.REPORT_LIST_CREATE]: 'report-builder-report-list-create-report',
  [UPGRADE_POINT_LOCATIONS.REPORT_LIBRARY_CREATE]: 'report-builder-report-library-create-report',
  [UPGRADE_POINT_LOCATIONS.DISCOVER_TEMPLATES_CREATE]: 'report-builder-discover-templates-create-report',
  [UPGRADE_POINT_LOCATIONS.DISCOVER_BUILDERS_CREATE]: 'report-builder-discover-builders-create-report'
};
export const REPORT_LIMIT_UPGRADE_KEYS = {
  [UPGRADE_POINT_LOCATIONS.DASHBOARD_CREATE]: 'dashboard-custom-reports-reports-dashboard-create-report',
  [UPGRADE_POINT_LOCATIONS.REPORT_LIST_CREATE]: 'dashboard-custom-reports-report-list-create-report',
  [UPGRADE_POINT_LOCATIONS.REPORT_LIBRARY_CREATE]: 'dashboard-custom-reports-report-library-create-report',
  [UPGRADE_POINT_LOCATIONS.DISCOVER_TEMPLATES_CREATE]: 'dashboard-custom-reports-discover-templates-create-report',
  [UPGRADE_POINT_LOCATIONS.DISCOVER_BUILDERS_CREATE]: 'dashboard-custom-reports-discover-builders-create-report'
};
export const ATTRIBUTION_BUILDER_ENTERPRISE_UPGRADE_KEYS = {
  [UPGRADE_POINT_LOCATIONS.DASHBOARD_EDIT]: 'dashboard-attribution-enterprise',
  [UPGRADE_POINT_LOCATIONS.REPORT_LIST_EDIT]: 'reports-list-attribution-enterprise'
};
export const ATTRIBUTION_BUILDER_PRO_UPGRADE_KEYS = {
  [UPGRADE_POINT_LOCATIONS.DASHBOARD_EDIT]: 'dashboard-attribution-pro',
  [UPGRADE_POINT_LOCATIONS.REPORT_LIST_EDIT]: 'reports-list-attribution-pro'
};
const DASHBOARD_LIMIT_UPGRADE_KEYS = {
  [UPGRADE_POINT_LOCATIONS.DASHBOARD_CREATE]: {
    starterKey: 'dashboard-suite-starter-reports-dashboard-create-dashboard',
    proKey: 'dashboard-pro-reports-dashboard-create-dashboard',
    limitIncreaseKey: 'dashboard-custom-reports-reports-dashboard-create-dashboard'
  },
  [UPGRADE_POINT_LOCATIONS.DASHBOARD_LIST_CREATE]: {
    starterKey: 'dashboard-suite-starter-dashboard-list-create-dashboard',
    proKey: 'dashboard-pro-dashboard-list-create-dashboard',
    limitIncreaseKey: 'dashboard-custom-reports-dashboard-list-create-dashboard'
  },
  [UPGRADE_POINT_LOCATIONS.DASHBOARD_LIBRARY_CREATE]: {
    starterKey: 'dashboard-suite-starter-dashboard-library-create-dashboard',
    proKey: 'dashboard-pro-dashboard-library-create-dashboard',
    limitIncreaseKey: 'dashboard-custom-reports-dashboard-library-create-dashboard'
  },
  [UPGRADE_POINT_LOCATIONS.DISCOVER_TEMPLATES_CREATE]: {
    starterKey: 'dashboard-suite-starter-discover-create-dashboard',
    proKey: 'dashboard-pro-discover-create-dashboard',
    limitIncreaseKey: 'dashboard-custom-reports-discover-create-dashboard'
  }
};
export const useDashboardLimitUpgradeKey = upgradeLocation => {
  const isStarterUpgradeEligible = useIsStarterUpgradeEligible();
  const isProUpgradeEligible = useIsProUpgradeEligible();
  const {
    starterKey,
    proKey,
    limitIncreaseKey
  } = DASHBOARD_LIMIT_UPGRADE_KEYS[upgradeLocation] || {};
  return isStarterUpgradeEligible ? starterKey : isProUpgradeEligible ? proKey : limitIncreaseKey;
};