import { useEffect, useState } from 'react';
import { AsyncStatus } from './AsyncStatus';
/**
 * @description useAsyncFetcher fetch/store async data returned by a fetcher and allows the callers
 *  to defer fetching
 *
 * @param {Object} Options
 * @param {Function} options.fetcher - function that returns a Promise
 * @param {Object} options.requestArgs - optional request args that are passed to the fetcher
 * @param {Boolean} options.deferred - whether the fetcher should be called.  This is helpful
 *         in avoiding unnecessary API call while respecting the rule of hooks.  Defaults to false
 * @param {Function} - options.resultFormatter - Format the results from the fetcher
 * @returns {AsyncData}
 *
 * @example EnhanceReport
 *
 * interface Report {
 *  name: string;
 *  updatedAt: Date;
 * }
 *
 * interface EnhancedReport extends Report {
 *  isEnhanced: boolean
 * }
 *
 *  const fetcher = (requestArgs: Report) => http.post('url/enhanceReport', {requestArgs});
 *  const resultFormatter = (response: EnhancedReport): EnhancedReport => ({
 *      ...response, updatedAt: new Date()
 *    });
 *  const requestArgs: Report = {name: 'testReport', updatedAt: new Date() };
 *  const {data, error, status} = useAsyncFetcher<EnhancedReport>({fetcher, resultFormatter, requestArgs});
 *
 *
 *  @example Deferred
 *
 *  const {data, error, status} = useAsyncFetcher<EnhancedReport>({
 *    fetcher,
 *    deferred: SomeConditionThatReturnsTrue
 *  });
 *  // status === AsyncStatus.UNINITIALIZED
 *
 *  const {data, error, status} = useAsyncFetcher<EnhancedReport>({
 *    fetcher,
 *    deferred: SomeConditionThatReturnsFalse
 *  });
 *  // useAsyncFetcher will fetch data
 */

export const useAsyncFetcher = ({
  deferred = false,
  fetcher,
  requestArgs,
  resultFormatter
}) => {
  const [asyncState, setAsyncState] = useState(() => {
    return {
      status: AsyncStatus.UNINITIALIZED
    };
  });
  useEffect(() => {
    let expired = false;
    if (deferred) {
      return () => {
        expired = true;
      };
    }
    setAsyncState(existingValue => Object.assign({}, existingValue, {
      status: AsyncStatus.STARTED
    }));
    fetcher(requestArgs).then(response => {
      if (!expired) {
        const data = resultFormatter ? resultFormatter(response) : response;
        setAsyncState(existingValue => Object.assign({}, existingValue, {
          data,
          status: AsyncStatus.SUCCEEDED
        }));
      }
    }).catch(error => {
      if (!expired) {
        setAsyncState(existingState => Object.assign({}, existingState, {
          error,
          status: AsyncStatus.FAILED
        }));
      }
    });
    return () => {
      expired = true;
    };
  }, [resultFormatter, setAsyncState, fetcher, deferred, requestArgs]);
  return asyncState;
};