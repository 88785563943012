'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["property"];
const findFiltersByType = type => filters => filters.filter(({
  property
}) => property.indexOf(type) === 0).map(_ref => {
  let {
      property
    } = _ref,
    restFilter = _objectWithoutPropertiesLoose(_ref, _excluded);
  return Object.assign({}, restFilter, {
    property: property.replace(`${type}.`, '')
  });
});
export const findContactFilters = findFiltersByType('contactFilters');
export const findEngagementFilters = findFiltersByType('engagementFilters');
export const findSubmissionFilters = findFiltersByType('submissionFilters');
export const findTicketFilters = findFiltersByType('ticketFilters');