'use es6';

import { Map as ImmutableMap } from 'immutable';
import { GLOBAL_NULL } from '../../constants/defaultNullValues';
import { get } from '../../retrieve/inboundDb/pipelines';
import { makeOption } from '../Option';
export const generatePipelineStageLabel = (pipelineStageInfo, key, {
  isRelational
} = {}) => {
  const fallbackLabel = key === GLOBAL_NULL ? null : key;
  return pipelineStageInfo.get('pipelineLabel') && !isRelational ? `${pipelineStageInfo.get('label', fallbackLabel)} (${pipelineStageInfo.get('pipelineLabel')})` : pipelineStageInfo.get('label', fallbackLabel);
};
const stagesToMap = (stages, initialMap = ImmutableMap(), pipelineLabel) => stages.reduce((memo, {
  stageId,
  label
}) => {
  return memo.set(stageId, makeOption(stageId, generatePipelineStageLabel(ImmutableMap({
    label,
    pipelineLabel
  }))));
}, initialMap);
export default ((type, initialStages = []) => () => get(type).then(pipelines => {
  return pipelines.reduce((options, {
    stages,
    label
  }) => stagesToMap(stages, options, label), stagesToMap(initialStages, ImmutableMap()));
}));