'use es6';

import http from 'hub-http/clients/apiClient';
export default {
  fetchDashboardScheduleById({
    reportEmailId
  }) {
    return http.get(`dashboard/v1/report-emails/${reportEmailId}`);
  },
  unsubscribeEmailRecipient({
    reportEmailId,
    recipientUserId
  }) {
    return http.delete(`dashboard/v1/report-emails/subscriptions/${reportEmailId}`, {
      query: {
        recipientUserId
      }
    });
  }
};