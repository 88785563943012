'use es6';

import http from 'hub-http/clients/apiClient';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import get from 'transmute/get';
import { Map as ImmutableMap, fromJS } from 'immutable';
const PAGE_SIZE = 50;
const URL = 'blogs/v3/blog-posts';
const AUTHOR_URL = 'blogs/v3/blog-authors';
const BLOGS_URL = 'blog-settings/v1/blogs';
const BLOG_TAG_URL = 'blogs/v3/tags';
const DEFAULT_BLOGS_QUERY = {
  limit: PAGE_SIZE,
  offset: 0,
  order: 'name',
  property: ['absolute_url', 'id', 'name']
};
const DEFAULT_AUTHOR_QUERY = {
  limit: PAGE_SIZE,
  offset: 0,
  order: 'fullName',
  property: ['displayName', 'email', 'id', 'language']
};
const DEFAULT_TAG_QUERY = {
  limit: PAGE_SIZE,
  offset: 0,
  order: 'name',
  property: ['id', 'name', 'language']
};
export const createGetBlogPostsByIds = ({
  httpClient
}) => ids => httpClient.get(URL, {
  query: {
    id__in: ids.join(',')
  }
}).then(get('objects')).then(formatToReferencesList({
  getId: get('id'),
  getLabel: get('label')
}));
export const getBlogPostsByIds = createGetBlogPostsByIds({
  httpClient: http
});
export const createGetSearchBlogOptions = ({
  httpClient
}) => (args = {
  offset: 0,
  query: ''
}) => {
  const offset = get('offset', args);
  const query = get('query', args);
  return httpClient.get(BLOGS_URL, {
    query: Object.assign({}, DEFAULT_BLOGS_QUERY, {
      offset,
      name__icontains: query
    })
  }).then(fromJS).then(response => {
    const results = formatToReferencesList({
      getId: get('id'),
      getLabel: get('name')
    })(response.get('objects'));
    return ImmutableMap({
      count: results.length,
      offset: offset + results.length,
      hasMore: PAGE_SIZE + offset < response.get('total'),
      results,
      total: response.get('total')
    });
  });
};
export const getSearchBlogOptions = createGetSearchBlogOptions({
  httpClient: http
});
export const createGetBlogsById = ({
  httpClient
}) => ids => {
  return httpClient.get(BLOGS_URL, {
    query: Object.assign({}, DEFAULT_BLOGS_QUERY, {
      id__in: ids
    })
  }).then(fromJS).then(response => {
    return formatToReferencesList({
      getId: get('id'),
      getLabel: get('name')
    })(response.get('objects'));
  });
};
export const getBlogsById = createGetBlogsById({
  httpClient: http
});
export const createSearchBlogAuthorOptions = ({
  httpClient
}) => (args = {
  offset: 0,
  query: ''
}) => {
  const offset = get('offset', args);
  const queryString = get('query', args);
  return httpClient.get(AUTHOR_URL, {
    query: Object.assign({}, DEFAULT_AUTHOR_QUERY, {
      offset,
      name__icontains: queryString
    })
  }).then(fromJS).then(response => {
    const results = formatToReferencesList({
      getId: get('id'),
      getLabel: get('displayName'),
      getDescription: get('email')
    })(response.get('objects'));
    return ImmutableMap({
      count: results.length,
      offset: offset + results.length,
      hasMore: PAGE_SIZE + offset < response.get('total'),
      results,
      total: response.get('total')
    });
  });
};
export const searchBlogAuthorOptions = createSearchBlogAuthorOptions({
  httpClient: http
});
export const createGetBlogAuthorsById = ({
  httpClient
}) => ids => {
  return httpClient.get(AUTHOR_URL, {
    query: Object.assign({}, DEFAULT_AUTHOR_QUERY, {
      id__in: ids
    })
  }).then(fromJS).then(response => {
    return formatToReferencesList({
      getId: get('id'),
      getLabel: get('displayName'),
      getDescription: get('email')
    })(response.get('objects'));
  });
};
export const getBlogAuthorsById = createGetBlogAuthorsById({
  httpClient: http
});
export const createSearchBlogTagOptions = ({
  httpClient
}) => (args = {
  offset: 0,
  query: ''
}) => {
  const {
    offset,
    query: queryString
  } = args.toJS();
  return httpClient.get(BLOG_TAG_URL, {
    query: Object.assign({}, DEFAULT_TAG_QUERY, {
      offset,
      name__icontains: queryString
    })
  }).then(fromJS).then(response => {
    const results = formatToReferencesList({
      getId: get('id'),
      getLabel: get('name')
    })(response.get('objects'));
    return ImmutableMap({
      count: results.length,
      offset: offset + results.length,
      hasMore: PAGE_SIZE + offset < response.get('total'),
      results,
      total: response.get('total')
    });
  });
};
export const searchBlogTagOptions = createSearchBlogTagOptions({
  httpClient: http
});
export const createGetBlogTagsById = ({
  httpClient
}) => ids => {
  return httpClient.get(BLOG_TAG_URL, {
    query: Object.assign({}, DEFAULT_TAG_QUERY, {
      limit: ids.length,
      id__in: ids
    })
  }).then(fromJS).then(response => {
    return formatToReferencesList({
      getId: get('id'),
      getLabel: get('name')
    })(response.get('objects'));
  });
};
export const getBlogTagsById = createGetBlogTagsById({
  httpClient: http
});