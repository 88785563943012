'use es6';

import { updateSort } from '../utils/salesAnalyticsHelpers';
import { applyMutators } from './mutators';
const generateChart = ({
  subAppKey,
  context,
  reportTemplate,
  filteredGates,
  options
}) => {
  const generatedConfig = applyMutators({
    reportTemplate,
    subAppKey,
    context,
    filteredGates,
    options
  });
  return generatedConfig.update(updateSort);
};
export default generateChart;