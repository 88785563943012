'use es6';

import { Record } from 'immutable';
import get from 'transmute/get';
import { someIsNil } from '../../private/js-util';
const Action = Record({
  key: null
}, 'Action');
export const createAction = params => {
  const {
    key
  } = params;
  if (someIsNil([key])) {
    throw new Error('createAction is missing an required parameter.');
  }
  const toReturn = Action({
    key
  });
  toReturn.toString = () => key;
  return toReturn;
};
export const getKey = get('key');