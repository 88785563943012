import { gate } from 'hub-http/gates';
import withGateOverride from './withGateOverride';
export const NEW_ILS_TIME_FILTERS = 'new-ils-time-filters';
export const REMOVE_DEFAULT_VALUE_LOGIC = 'CRM:Filters:RemoveDefaultValue';
export const FILTERS_REMOVE_TIMEZONE_FOR_DATES = 'Filters:RemoveTimezoneForDateFilters';
export const hasNewIlsTimeFiltersEnabled = isUngated => {
  return isUngated(NEW_ILS_TIME_FILTERS);
};
export const hasRemoveTimezoneForDates = isUngated => {
  return isUngated(FILTERS_REMOVE_TIMEZONE_FOR_DATES);
};

// Properties usually have to be a ENUMERATION to use external options
// This gate is for allowing NUMBER and STRING properties to use
// External Options and to act like a ENUMERATION
export const EXTERNAL_OPTIONS_ANY_PROPERTY_TYPE = 'ExternalOptions:AnyPropertyType';
export const hasExternalOptionsForAnyPropertyType = isUngated => {
  return withGateOverride(EXTERNAL_OPTIONS_ANY_PROPERTY_TYPE, isUngated(EXTERNAL_OPTIONS_ANY_PROPERTY_TYPE));
};
export const REMOVED_PROPERTY_ASSOCIATION = 'InboundDBListSeg:DealToProductList';
export const hasRemovedPropertyAssociation = isUngated => {
  return isUngated(REMOVED_PROPERTY_ASSOCIATION);
};
export const ILS_NUMERIC_BETWEEN_OPERATOR = gate('Filters:ILSNumericBetweenOperator');
export const hasILSNumericBetweenOperator = isUngated => {
  return isUngated(ILS_NUMERIC_BETWEEN_OPERATOR);
};
export const CRM_SEARCH_X_DAYS_INCLUSIVE = 'Filters:XDaysWithExclusiveBounds';
export const hasXDaysWithExclusiveBounds = isUngated => {
  return isUngated(CRM_SEARCH_X_DAYS_INCLUSIVE);
};