'use es6';

import { AssetPermission } from '../../../../public/permission/asset-permission';
import { hasSufficientPermission } from '../../../../public/permission/compute-permission';
import { anyNumberIsLoading } from '../../common/any-number-is-loading';
import { getAndCheck } from '../../common/get-and-check-param';
import { getAllProblems } from '../../common/get-problems-no-check';
import { createProblem } from '../../common/problem';
import { CUSTOM_DASHBOARD_COUNT, CUSTOM_DASHBOARD_LIMIT, USER_DASHBOARD_PERMISSION } from '../../params/all-params';
import { NEED_EDIT_PERMISSION } from '../../problems/permission-problem';
import { CUSTOM_DASHBOARD_LIMIT_EXCEEDED_AND_DASHBOARD_SHOULD_LOCKDOWN } from 'dashboard-lib/private/action-problem-solution/problems/lockdown-problem';
export const LOADING = createProblem('LOADING', params => {
  const customDashboardCount = getAndCheck(CUSTOM_DASHBOARD_COUNT, params);
  const customDashboardLimit = getAndCheck(CUSTOM_DASHBOARD_LIMIT, params);
  return anyNumberIsLoading(customDashboardCount, customDashboardLimit);
});
export const PERMISSION_DENIED = createProblem('PERMISSION_DENIED', params => {
  return !hasSufficientPermission(AssetPermission.EDIT, getAndCheck(USER_DASHBOARD_PERMISSION, params));
});
export const rankedProblems = [LOADING, NEED_EDIT_PERMISSION, PERMISSION_DENIED, CUSTOM_DASHBOARD_LIMIT_EXCEEDED_AND_DASHBOARD_SHOULD_LOCKDOWN];
export const getAll = params => getAllProblems(params, rankedProblems);