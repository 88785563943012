/* hs-eslint ignored failing-rules */
/* eslint-disable compat/compat */

export const requestFullScreen = element => {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
};
export const exitFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen().catch(console.error);
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
};
export const setFullScreenHandler = onChange => {
  if ('onfullscreenchange' in document) {
    document['onfullscreenchange'] = onChange;
  } else if ('onwebkitfullscreenchange' in document) {
    document['onwebkitfullscreenchange'] = onChange;
  } else if ('onmsfullscreenchange' in document) {
    document['onmsfullscreenchange'] = onChange;
  } else if ('onmozfullscreenchange' in document) {
    document['onmozfullscreenchange'] = onChange;
  }
};
export const isFullScreen = () => {
  return document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
};
export const isFullScreenAvailable = () => {
  return 'fullscreenElement' in document || 'webkitFullscreenElement' in document || 'mozFullScreenElement' in document || 'msFullscreenElement' in document;
};