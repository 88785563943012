import { Record } from 'immutable';
import get from 'transmute/get';
import { SentryEventIds, SentryLevels, SentryOwners } from './constants';
export const SentryEvent = Record({
  id: SentryEventIds.DASHBOARD_ERROR_BOUNDARY,
  // TODO use the DEFAULT_DASHBOARD_APP_ERROR_BOUNDARY id

  name: '',
  // TODO use the DEFAULT_DASHBOARD_APP_ERROR_BOUNDARY name
  description: '',
  // TODO use the DEFAULT_DASHBOARD_APP_ERROR_BOUNDARY description
  owner: SentryOwners.DEFAULT_OWNER,
  level: SentryLevels.ERROR
}, 'SentryEvent');
export const getEventId = sentryEvent => get('id', sentryEvent);