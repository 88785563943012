'use es6';

import { Record, Map as ImmutableMap, Set as ImmutableSet, List } from 'immutable';
import fill from './fill';

// Replace with checked lib
const collectionWithNesting = (raw, constructor, nested) => constructor(raw).map(value => nested(value));
const constructWithNesting = (raw = {}, constructor, nested) => {
  const mutatedRaw = Object.assign({}, raw);
  for (const field in nested) {
    if (raw.hasOwnProperty(field)) {
      mutatedRaw[field] = nested[field](raw[field]);
    }
  }
  return constructor(mutatedRaw);
};
const ListOfString = raw => collectionWithNesting(raw, List, String);
const MapStringToListOfString = raw => collectionWithNesting(raw, ImmutableMap, ListOfString);
const SearchSpec = Record({
  url: null,
  objectsField: null,
  properties: List()
});
const _HydrateSpec = Record({
  inputs: ImmutableSet(),
  fn: () => null
});
function HydrateSpec(raw) {
  return constructWithNesting(raw, _HydrateSpec, {
    inputs: ImmutableSet
  });
}
const _PropertiesSpec = Record({
  idProperty: null,
  responsePaths: ImmutableMap(),
  types: ImmutableMap(),
  references: ImmutableMap(),
  extractors: ImmutableMap()
});
function PropertiesSpec(raw) {
  return constructWithNesting(raw, _PropertiesSpec, {
    responsePaths: MapStringToListOfString,
    types: ImmutableMap,
    references: ImmutableMap,
    extractors: ImmutableMap
  });
}
const _Spec = Record({
  dataType: null,
  objectTypeId: null,
  references: ImmutableMap(),
  search: new SearchSpec(),
  properties: new PropertiesSpec(),
  hydrate: new HydrateSpec(),
  associationPreviews: ImmutableMap()
}, 'inboundDb spec');
export default function Spec(raw) {
  return fill(constructWithNesting(raw, _Spec, {
    references: ImmutableMap,
    search: SearchSpec,
    properties: PropertiesSpec,
    hydrate: HydrateSpec
  }));
}