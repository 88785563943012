'use es6';

import { INTEGRATION } from '../../filterQueryFormat/DSAssetFamilies/DSAssetFamilies';
export const __ANY_CTA = '__ANY_CTA';
export const __ANY_FORM = '__ANY_FORM';
export const __ANY_LINK = '__ANY_LINK';
export const __ANY_PAGE = '__ANY_PAGE';
export const __ANY_WEBINAR = '__ANY_WEBINAR';
export const __PRIVACY_CONSENT = '__PRIVACY_CONSENT';

// This is a special case property that actually uses this value on the BE
export const __OCCURRED_INTEGRATION_PROPERTY = 'timestamp';
export const PRIVACY_CONSENT = 'PrivacyConsent';
export const ListSegClassicTimestampTypes = {
  NONE: 'NONE',
  PORTAL_TZ_ALIGNED: 'PORTAL_TZ_ALIGNED',
  UTC_TZ_ALIGNED: 'UTC_TZ_ALIGNED'
};
export const AdsEntityListTypes = {
  AD_TYPE: 'AD_TYPE',
  IDS: 'IDS',
  // classic
  ID: 'ID',
  // objseg
  NAMES: 'NAMES',
  // classic
  NAME: 'NAME',
  // objseg
  TIMESTAMP: 'TIMESTAMP'
};
export const AdsSearchTermTypes = {
  AD: 'AD',
  ADGROUP: 'ADGROUP',
  CAMPAIGN: 'CAMPAIGN',
  KEYWORD: 'KEYWORD'
};
export const AdsInteraction = 'INTERACTION';
export const WistiaIntegrationFamilies = [`${INTEGRATION}-14723`, `${INTEGRATION}-14724`, `${INTEGRATION}-14691`, `${INTEGRATION}-14722`, `${INTEGRATION}-14725`];