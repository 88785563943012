/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable promise/catch-or-return */

/* eslint no-restricted-imports: 0 */
import externalUserInfo from 'hub-http/userInfo';
// @ts-expect-error Untyped dependency
import store from '../../redux/store';
// @ts-expect-error Untyped dependency
import connectActions from '../../redux/connectActions';
// @ts-expect-error Untyped dependency
import { actions } from './store';

// This is only true for the first call to piggyback off early request user info
let useHubHttpCache = true;
const DEFAULT_OPTIONS = {
  // throw when trying to make an actual http request
  // (for debugging regression testing)
  cacheOnly: false
};
let currentOptions = DEFAULT_OPTIONS;
const setOptions = newOptions => currentOptions = Object.assign({}, currentOptions, newOptions);
const getOptions = () => currentOptions;
const resetOptions = () => currentOptions = DEFAULT_OPTIONS;

/* Here so that we can stub the real userInfo response in tests */
const __TESTABLE__ = {
  userInfo: externalUserInfo
};
const [setUserInfo, clearUserInfo] = connectActions(store, [actions.setUserInfo, actions.clearUserInfo]);
const userInfo = (options = {}) => {
  const {
    cacheOnly
  } = Object.assign({}, currentOptions, options);
  const userInfoPromise = store.getState().userInfo.promise;
  if (userInfoPromise) {
    return userInfoPromise;
  }
  if (cacheOnly) {
    throw new Error('User info not found in cache.');
  }
  const promise = __TESTABLE__.userInfo({
    cached: useHubHttpCache
  });
  setUserInfo({
    promise
  });
  if (useHubHttpCache) {
    useHubHttpCache = false;
  }
  promise.then(userInfoResult => {
    setUserInfo({
      promise,
      userInfo: userInfoResult
    });
  });
  return promise;
};
export { setOptions, getOptions, resetOptions, userInfo, clearUserInfo, __TESTABLE__ };