'use es6';

import { List } from 'immutable';
import * as storage from 'dashboard-lib/private/js-util/storage';
import { isNil } from 'dashboard-lib/private/js-util';
const RECENT_DASHBOARDS_KEY = 'RA:RecentDashboards';
export const getRecentDashboards = () => {
  if (!storage.has(RECENT_DASHBOARDS_KEY)) {
    return new List();
  }
  const stored = storage.get(RECENT_DASHBOARDS_KEY);
  try {
    return new List(JSON.parse(stored));
  } catch (e) {
    storage.remove(RECENT_DASHBOARDS_KEY);
    return new List();
  }
};

// Only show the top { displayCount } dashboards if the app is DashboardUI, otherwise show
// an indefinite amount of recently viewed dashboards. The display count param should be
// deleted once the IA version is the official app.
export const recordRecentDashboard = (dashboardId, displayCount) => {
  if (isNil(dashboardId)) {
    return;
  }
  const recentDashboards = getRecentDashboards();
  const updatedRecentDashboards = recentDashboards.filter(id => id !== dashboardId).unshift(dashboardId);
  const truncatedDashboardList = displayCount ? updatedRecentDashboards.take(displayCount) : updatedRecentDashboards;
  storage.set(RECENT_DASHBOARDS_KEY, JSON.stringify(truncatedDashboardList.toJS()));
};