import curry from 'transmute/curry';
import { AssetPermission, getAssetPermissionRank } from './assetPermission';
const moreThanOrEqual = (left, right) => {
  return getAssetPermissionRank(left) >= getAssetPermissionRank(right);
};

/**
 * Determines whether the given permission is sufficient (equal to or higher)
 * compared to the required permission
 * @param minimumPermission: AssetPermission
 * @param subject: AssetPermission
 * @returns {boolean}
 */
export const hasSufficientPermission = curry((minimum, subject) => moreThanOrEqual(subject, minimum));

/**
 * Helper: checks if given permission is View or above permission
 */
export const hasSufficientViewPermission = hasSufficientPermission(AssetPermission.VIEW);

/**
 * Helper: checks if given permission is EDIT or above permission
 */
export const hasSufficientEditPermission = hasSufficientPermission(AssetPermission.EDIT);

/**
 * Helper: checks if given permission is CREATE_AND_OWN or above permission
 */
export const hasSufficientCreatePermission = hasSufficientPermission(AssetPermission.CREATE_AND_OWN);