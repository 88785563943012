'use es6';

import { TABLE, DATA_WELL } from 'reporting-data/constants/chartTypes';
import { applyMutators } from './mutators';
import generateChart from './chart';
import generateTable from './table';
import { isSnowflakeReport } from '../utils/salesAnalyticsHelpers';
const generateSnowflake = ({
  reportTemplate,
  subAppKey,
  context,
  filteredGates
}) => {
  return applyMutators({
    reportTemplate,
    subAppKey,
    context,
    filteredGates
  });
};
export const getReportGenerator = reportTemplate => {
  if (isSnowflakeReport(reportTemplate)) {
    return generateSnowflake;
  }
  switch (reportTemplate.get('chartType')) {
    case TABLE:
      return generateTable;
    case DATA_WELL:
      return props => applyMutators(Object.assign({
        reportTemplate
      }, props));
    default:
      return generateChart;
  }
};