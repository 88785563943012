import I18n from 'I18n';
import { PROPERTIES_LIMIT, TIMESTAMP_PROPERTY } from 'reporting-snowflake/funnel/constants/properties';
import { fromJS, is } from 'immutable';
export const getPropertyOptions = (properties, {
  useLimit = true
} = {}) => {
  const optionList = Object.keys(properties).map(propKey => {
    const text = propKey === TIMESTAMP_PROPERTY ? I18n.text('reporting-snowflake.funnel.timestamp-property') : properties[propKey].propertyDefinition.property.label;
    return {
      text,
      value: propKey
    };
  }).sort(({
    text: text1
  }, {
    text: text2
  }) => {
    if (text1 && text2) return text1.localeCompare(text2);
    return text1 ? -1 : 1;
  });
  return useLimit ? optionList.slice(0, PROPERTIES_LIMIT) : optionList;
};
export const propertyFilterSetsAreIdentical = (set1, set2) => set1.filters.length === set2.filters.length && set1.filters.every((filterFromSet1, index) => {
  const filterFromSet2 = set2.filters[index];
  return filterFromSet1.filterType === filterFromSet2.filterType && filterFromSet1.property === filterFromSet2.property && filterFromSet1.propertySource === filterFromSet2.propertySource && is(fromJS(filterFromSet1.operation), fromJS(filterFromSet2.operation));
});