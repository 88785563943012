import { useState, useEffect } from 'react';
import { getSnowflakeJoins } from './joins';
import { getSnowflakeTables } from './tables';
import { getSnowflakeTableGroups } from './table-groups';
import { SnowflakeSourceData } from '../schema/source-records';
import userInfo from 'hub-http/userInfo';
import { AsyncStatus, isFailed, isLoading, isSucceeded } from '../utils/async-data-utils';
import { useSnowflakeJoinMetadata } from './joins-with-meta';
import { addSnowflakeTableGroupsFromSelfAssociation, addSnowflakeTablesFromSelfAssociation } from '../utils/self-association-utils';

// SnowflakeSourceData
let cache = undefined;
// Promise<SnowflakeSourceData>
let promiseCache = undefined;

// index everything by table name and convert to record
const toSnowflakeSourceData = ({
  snowflakeTables,
  snowflakeTableGroups,
  snowflakeJoins
}) => {
  const replaceWithName = id => id ? snowflakeTables.get(id).name : '';
  const replaceAllWithName = ids => ids && ids.map(replaceWithName).toList();
  return SnowflakeSourceData({
    snowflakeTables: snowflakeTables.mapKeys(replaceWithName).toMap(),
    snowflakeTableGroups: snowflakeTableGroups.map(tableGroup => tableGroup.update('root', root => replaceAllWithName(root).toList()).update('related', related => replaceAllWithName(related).toList())).toMap(),
    snowflakeJoins: snowflakeJoins.mapKeys(replaceWithName).map(replaceAllWithName).toMap()
  });
};

/**
 * Query for all snowflake source data, including tables, table groups, and joins
 * @returns {Promise<SnowflakeSourceData>}
 */
export const getSnowflakeSourceData = () => {
  const createRequest = () => Promise.all([getSnowflakeTables(), getSnowflakeTableGroups(), getSnowflakeJoins(), userInfo()]).then(([snowflakeTables, snowflakeTableGroups, snowflakeJoins]) => toSnowflakeSourceData({
    snowflakeTables,
    snowflakeTableGroups,
    snowflakeJoins
  }));
  if (!promiseCache) {
    promiseCache = createRequest();
  }
  return promiseCache.then(data => {
    cache = data;
    return data;
  }).catch(error => {
    promiseCache = undefined;
    console.error(error);
    throw error;
  });
};
/**
 * todo currently SnowflakeSourceData is keyed by "table name", but really it should be keyed by "dataSourceId"
 * * "table name" is named-spaced per report. A report definition defines the meaning of a "table name"
 * * this function is meant for browsing data sources, which should NOT be dependent on any specific report definition!
 *
 * A hook to query for snowflake tables, table groups, and joins in components state
 * @returns {{loading: boolean, data: SnowflakeSourceData, error: Error}}
 */
export const useSnowflakeSourceData = () => {
  const initialState = cache ? {
    status: AsyncStatus.SUCCEEDED,
    data: cache
  } : {
    status: AsyncStatus.UNINITIALIZED
  };
  const [requestState, setRequestState] = useState(initialState);
  useEffect(() => {
    let expired = false;

    // skip effect if already cached
    if (cache) {
      return () => {
        expired = true;
      };
    }
    getSnowflakeSourceData().then(data => {
      if (!expired) {
        setRequestState({
          status: AsyncStatus.SUCCEEDED,
          data
        });
      }
    }).catch(error => {
      if (!expired) {
        setRequestState({
          status: AsyncStatus.FAILED,
          error
        });
      }
    });
    return () => {
      expired = true;
    };
  }, []);
  return {
    loading: isLoading(requestState),
    data: isSucceeded(requestState) ? requestState.data : undefined,
    error: isFailed(requestState) ? requestState.error : undefined
  };
};
export const useSnowflakeSourceDataWithSelfJoins = (tableDescription, hasSelfJoinsAccess) => {
  const result = useSnowflakeSourceData();
  const {
    data: allJoinMetadata
  } = useSnowflakeJoinMetadata();
  const {
    loading,
    error,
    data: {
      snowflakeTables,
      snowflakeTableGroups,
      snowflakeJoins
    } = {}
  } = result;
  if (hasSelfJoinsAccess && snowflakeTables && snowflakeTableGroups) {
    return {
      loading,
      error,
      data: {
        snowflakeTables: addSnowflakeTablesFromSelfAssociation(snowflakeTables, tableDescription, allJoinMetadata),
        snowflakeTableGroups: addSnowflakeTableGroupsFromSelfAssociation(snowflakeTableGroups, snowflakeTables, tableDescription, allJoinMetadata),
        snowflakeJoins
      }
    };
  }
  return result;
};
export const __TESTABLE__ = {
  toSnowflakeSourceData
};