'use es6';

import { SUB_APPS } from '../sub-apps';
const CATEGORIES = {
  COACH_TEAMS_AND_REPS: 'coachRepsAndTeams',
  FORECAST_AND_PIPELINE: 'forecastAndPipeline',
  SALES_OUTCOMES: 'salesOutcomes'
};
export const CATEGORY_SUB_APPS = {
  [CATEGORIES.COACH_TEAMS_AND_REPS]: [SUB_APPS.activities, SUB_APPS.callOutcomes, SUB_APPS.taskOutcomes, SUB_APPS.conversations, SUB_APPS.meetingOutcomes, SUB_APPS.dealsCreatedByRep, SUB_APPS.timeInDealStage, SUB_APPS.activityStream, SUB_APPS.leadResponseTime, SUB_APPS.prospectEngagement, SUB_APPS.leadFunnel],
  [CATEGORIES.FORECAST_AND_PIPELINE]: [SUB_APPS.dealJourney, SUB_APPS.weightedPipelineForecast, SUB_APPS.waterfall, SUB_APPS.changeHistoryDealList, SUB_APPS.dealPushAnalysis, SUB_APPS.pipelineSnapshots, SUB_APPS.quotaAttainment, SUB_APPS.manualForecast, SUB_APPS.manualForecastTotal],
  [CATEGORIES.SALES_OUTCOMES]: [SUB_APPS.lossReasons, SUB_APPS.salesBySource, SUB_APPS.dealsWon, SUB_APPS.averageSalesPrice, SUB_APPS.dealVelocity, SUB_APPS.salesVelocity]
};