import { createSelector } from '@reduxjs/toolkit';
import { dataSourcePropertiesNamespace } from './slice';
import { getPropertyFromDataSourceByPropertyRecord } from '../../filter/utils/schema/propertySchema';

// TODO move all selectors pertaining to dataSourceProperties here
export const selectAllDataSourcesByPropertyRecords = state => {
  const dataSourcePropertiesState = state[dataSourcePropertiesNamespace];
  const dataSourceByPropertyRecord = Object.keys(dataSourcePropertiesState).reduce((next, objectId) => {
    var _dataSourceProperties;
    const properties = (_dataSourceProperties = dataSourcePropertiesState[objectId]) === null || _dataSourceProperties === void 0 ? void 0 : _dataSourceProperties.properties;
    if (properties != null) {
      next[objectId] = properties;
    }
    return next;
  }, {});
  return dataSourceByPropertyRecord;
};
export const selectDataSourcesByPropertyRecords = dataSourceIds => createSelector([selectAllDataSourcesByPropertyRecords], allDataSourcesByPropertyRecords => {
  return dataSourceIds.reduce((next, dataSourceId) => {
    if (dataSourceId in allDataSourcesByPropertyRecords) {
      next[dataSourceId] = allDataSourcesByPropertyRecords[dataSourceId];
    }
    return next;
  }, {});
});
export const selectPropertyDefinition = (dataSourceId, propertyName) => createSelector([selectAllDataSourcesByPropertyRecords], dataSourceProperties => {
  return getPropertyFromDataSourceByPropertyRecord(dataSourceProperties, dataSourceId, propertyName);
});