import getIn from 'transmute/getIn';
import { isListMembershipProperty } from 'platform-dashboard-ui/filter/utils/listMembershipFilterUtils';
import { STRING } from 'customer-data-objects/property/PropertyTypes';
import { DYNAMIC_DATE_RANGE_PROPERTY_NAME, DashboardFilterOperators, areFiltersSame, isDateCompareDashboardFilter, isHasPropertyDashboardFilter, isRangeDashboardFilter, isRollingDateRangeDashboardFilter, isScriptedPropertyDashboardFilter, isValuesDashboardFilter } from '../dashboardFilters/types';
import { SavedFilterOperators, isApiSavedHasPropertyDashboardFilter, isApiSavedRangeFilter, isApiSavedValueFilter, isApiSavedValuesFilter } from './types';
// @ts-expect-error migrate upstream
import { getType } from '../dashboardFilters/propertySchema';
// @ts-expect-error migrate upstream
import { getFullyQualifiedName } from '../dashboardFilters/dataSourceMetadataSchema';
import { isFrameworkDataSource } from '../dashboardFilters/dataSourceHelpers';
export const transformDashboardFilterToSavedFilter = dashboardFilters => {
  const transformed = dashboardFilters.map(filter => 'toJS' in filter // @ts-expect-error maybe immutable
  ? filter.toJS() : filter).map(dashboardFilter => {
    const {
      dataSource: dataSourceId,
      propertyName: name,
      propertyScripted: scripted
    } = dashboardFilter;

    // BE can only resolve propertypes for non-scripted, non list membership framework data sources
    const canBEHydratePropertyType = (() => {
      if (isFrameworkDataSource(dataSourceId)) {
        return !(isScriptedPropertyDashboardFilter(dashboardFilter) || isListMembershipProperty(name));
      }
      return false;
    })();

    // only persist propertyType for properties the BE cannot resolve
    const propertyType = canBEHydratePropertyType ? undefined : dashboardFilter.propertyType;
    const baseFilter = {
      dataSourceId,
      propertyType,
      name,
      scripted
    };
    if (isRollingDateRangeDashboardFilter(dashboardFilter)) {
      const {
        value: {
          isInclusive,
          direction,
          value
        }
      } = dashboardFilter;

      // TODO change when DashboardFilter rolling date range filter shape supports timeUnitOffset
      const timeUnitCount = (() => {
        if (isInclusive) {
          return 0;
        }
        if (direction === 'backward') {
          return value * -1;
        }
        return value;
      })();
      return Object.assign({
        operator: SavedFilterOperators.IN_ROLLING_DATE_RANGE,
        includeFutureDates: dashboardFilter.value.includeFutureDates,
        timeUnitCount,
        timeUnit: dashboardFilter.value.timeUnit,
        useFiscalYear: dashboardFilter.value.useFiscalYear
      }, baseFilter);
    }
    if (isValuesDashboardFilter(dashboardFilter)) {
      return Object.assign({
        operator: dashboardFilter.operator === DashboardFilterOperators.IN || dashboardFilter.operator === DashboardFilterOperators.CONTAIN_ANY ? SavedFilterOperators.IN : SavedFilterOperators.NOT_IN,
        values: dashboardFilter.value
      }, baseFilter);
    }
    if (isDateCompareDashboardFilter(dashboardFilter)) {
      return Object.assign({
        operator: dashboardFilter.operator === DashboardFilterOperators.AFTER_DATE ? SavedFilterOperators.GT : SavedFilterOperators.LT,
        value: dashboardFilter.value
      }, baseFilter);
    }
    if (isRangeDashboardFilter(dashboardFilter)) {
      return Object.assign({
        operator: SavedFilterOperators.BETWEEN,
        lowValue: dashboardFilter.value,
        highValue: dashboardFilter.highValue
      }, baseFilter);
    }
    if (isHasPropertyDashboardFilter(dashboardFilter)) {
      return Object.assign({
        operator: dashboardFilter.operator === DashboardFilterOperators.KNOWN ? SavedFilterOperators.KNOWN : SavedFilterOperators.UNKNOWN
      }, baseFilter);
    }
    const getValueOperator = () => {
      if (dashboardFilter.operator === DashboardFilterOperators.EQ) {
        return SavedFilterOperators.EQ;
      }
      if (dashboardFilter.operator === DashboardFilterOperators.NEQ) {
        return SavedFilterOperators.NEQ;
      }
      if (dashboardFilter.operator === DashboardFilterOperators.LT) {
        return SavedFilterOperators.LT;
      }
      if (dashboardFilter.operator === DashboardFilterOperators.LTE) {
        return SavedFilterOperators.LTE;
      }
      if (dashboardFilter.operator === DashboardFilterOperators.GT) {
        return SavedFilterOperators.GT;
      }
      return SavedFilterOperators.GTE;
    };
    return Object.assign({
      operator: getValueOperator(),
      value: dashboardFilter.value
    }, baseFilter);
  });
  return transformed;
};
const expandDynamicDateRangeApiFilter = (dynamicDateRangeFilter, properties) => {
  return properties.map(({
    name,
    scripted,
    dataSourceType,
    dataSourceId
  }) => {
    return Object.assign({}, dynamicDateRangeFilter, {
      name,
      scripted,
      dataSourceId,
      dataSourceType: dataSourceType
    });
  });
};
export const transformSavedFilterToDashboardFilter = (savedFilters, dataSourcesProperties, dataSourcesMetadata, dynamicDateRangeProperties = []) => {
  const apiSavedFilters = savedFilters.map(filter => 'toJS' in filter ?
  // @ts-expect-error maybe immutable
  filter.toJS() : filter);
  const maybeDynamicDateRangeFilter = apiSavedFilters.find(filter => filter.name === DYNAMIC_DATE_RANGE_PROPERTY_NAME);
  const expandedDateRangeFilters = maybeDynamicDateRangeFilter ? expandDynamicDateRangeApiFilter(maybeDynamicDateRangeFilter, dynamicDateRangeProperties) : [];
  const apiSavedFiltersMinusDateRangeFilter = apiSavedFilters.filter(filter => filter.name !== DYNAMIC_DATE_RANGE_PROPERTY_NAME);
  const transformFilter = savedFilter => {
    const dataSourceName = getFullyQualifiedName(getIn([savedFilter.dataSourceId], dataSourcesMetadata));
    const propertyType = getType(getIn([savedFilter.dataSourceId, savedFilter.name], dataSourcesProperties));
    const propertyScripted = savedFilter.scripted;
    const baseFilter = {
      propertyType,
      propertyScripted,
      dataSourceName,
      dataSource: savedFilter.dataSourceId,
      propertyName: savedFilter.name
    };
    if (isApiSavedValueFilter(savedFilter)) {
      const getValueFilterOperator = () => {
        if (savedFilter.operator === SavedFilterOperators.EQ) {
          return DashboardFilterOperators.EQ;
        }
        if (savedFilter.operator === SavedFilterOperators.NEQ) {
          return DashboardFilterOperators.NEQ;
        }
        if (savedFilter.operator === SavedFilterOperators.LT) {
          if (isNaN(Number(savedFilter.value))) {
            return DashboardFilterOperators.BEFORE_DATE;
          }
          return DashboardFilterOperators.LT;
        }
        if (savedFilter.operator === SavedFilterOperators.LTE) {
          return DashboardFilterOperators.LTE;
        }
        if (savedFilter.operator === SavedFilterOperators.GT) {
          if (isNaN(Number(savedFilter.value))) {
            return DashboardFilterOperators.AFTER_DATE;
          }
          return DashboardFilterOperators.GT;
        }
        return DashboardFilterOperators.GTE;
      };
      return Object.assign({
        value: savedFilter.value,
        operator: getValueFilterOperator()
      }, baseFilter);
    }
    if (isApiSavedValuesFilter(savedFilter)) {
      const getOperator = () => {
        const isContainsOperator = propertyType === STRING;
        if (isContainsOperator) {
          return savedFilter.operator === SavedFilterOperators.IN ? DashboardFilterOperators.CONTAIN_ANY : DashboardFilterOperators.NOT_CONTAIN_ANY;
        }
        return savedFilter.operator === SavedFilterOperators.IN ? DashboardFilterOperators.IN : DashboardFilterOperators.NOT_IN;
      };
      const operator = getOperator();
      return Object.assign({
        value: savedFilter.values,
        operator
      }, baseFilter);
    }
    if (isApiSavedHasPropertyDashboardFilter(savedFilter)) {
      return Object.assign({
        operator: savedFilter.operator === SavedFilterOperators.KNOWN ? DashboardFilterOperators.KNOWN : DashboardFilterOperators.UNKNOWN
      }, baseFilter);
    }
    if (isApiSavedRangeFilter(savedFilter)) {
      return Object.assign({
        value: savedFilter.lowValue,
        highValue: savedFilter.highValue,
        operator: DashboardFilterOperators.IN_RANGE
      }, baseFilter);
    }
    const {
      timeUnitCount
    } = savedFilter;
    const isThisTimeUnit = timeUnitCount === 0;
    const direction = timeUnitCount < 0 || isThisTimeUnit ? 'backward' : 'forward';
    const isInclusive = isThisTimeUnit;
    const value = isThisTimeUnit ? 1 : Math.abs(timeUnitCount);
    const dashboardFilter = Object.assign({
      operator: DashboardFilterOperators.IN_ROLLING_DATE_RANGE,
      value: {
        value,
        timeUnit: savedFilter.timeUnit,
        direction,
        isInclusive,
        includeFutureDates: savedFilter.includeFutureDates,
        useFiscalYear: savedFilter.useFiscalYear
      }
    }, baseFilter);
    return dashboardFilter;
  };
  const transformed = apiSavedFiltersMinusDateRangeFilter.map(transformFilter);
  return transformed.concat(expandedDateRangeFilters.map(transformFilter).map(dateRangeFilter => {
    return Object.assign({}, dateRangeFilter, {
      isDateRangeFilter: true
    });
  }));
};
export const getActivityLogFiltersMessageOptions = (entry, dataSourcesProperties, dataSourcesMetadata) => {
  const newValue = entry.get('newValue').toJS();
  const oldValue = entry.get('oldValue').toJS();
  const oldValueSize = oldValue.length;
  const newValueSize = newValue.length;
  if (oldValueSize === newValueSize) {
    const maybeRenamedFilterSet = newValue.find(newFilterSet => oldValue.find(oldFilterSet => oldFilterSet.id === newFilterSet.id && oldFilterSet.name !== newFilterSet.name));
    if (maybeRenamedFilterSet) {
      const key = 'renamed';
      const oldFilterSet = oldValue.find(filterSet => filterSet.id === maybeRenamedFilterSet.id);
      return {
        key,
        options: {
          oldFilterSetName: oldFilterSet ? oldFilterSet.name : '',
          newFilterSetName: maybeRenamedFilterSet.name
        }
      };
    }
    const maybeNewDefaultFilterSet = newValue.find(newFilterSet => newFilterSet.defaultFilter);
    const maybeOldDefaultFiltersSet = oldValue.find(oldFilterSet => oldFilterSet.defaultFilter);
    if (maybeNewDefaultFilterSet && (!maybeOldDefaultFiltersSet || maybeOldDefaultFiltersSet.id !== maybeNewDefaultFilterSet.id)) {
      const key = 'new-default';
      return {
        key,
        options: {
          filterSetName: maybeNewDefaultFilterSet.name
        }
      };
    }
    const key = 'updated';
    const newTransformed = newValue.map(newSet => Object.assign({}, newSet, {
      filters: transformSavedFilterToDashboardFilter(newSet.filters, dataSourcesProperties, dataSourcesMetadata)
    }));
    const oldTransformed = oldValue.map(oldSet => Object.assign({}, oldSet, {
      filters: transformSavedFilterToDashboardFilter(oldSet.filters, dataSourcesProperties, dataSourcesMetadata)
    }));
    const updatedFilterSet = newTransformed.find(newFilterSet => oldTransformed.find(oldFilterSet => oldFilterSet.id === newFilterSet.id && oldFilterSet.filters.length !== newFilterSet.filters.length || !newFilterSet.filters.every(filter => oldFilterSet.filters.some(oldFilter => areFiltersSame(filter, oldFilter)))));
    return {
      key,
      options: {
        filterSetName: updatedFilterSet ? updatedFilterSet.name : ''
      }
    };
  }
  if (oldValueSize > newValueSize) {
    const key = 'deleted';
    const deletedFilterSet = oldValue.find(oldFilterSet => !newValue.some(newSet => newSet.id === oldFilterSet.id));
    return {
      key,
      options: {
        filterSetName: deletedFilterSet ? deletedFilterSet.name : ''
      }
    };
  }
  const key = 'created';
  const createdFilterSet = newValue.find(newFilterSet => !oldValue.some(oldSet => oldSet.id === newFilterSet.id));
  return {
    key,
    options: {
      filterSetName: createdFilterSet ? createdFilterSet.name : ''
    }
  };
};
export const getFilterSetById = (filterSets, filterSetId) => filterSets.find(filterSet => filterSet.id === filterSetId);
export const getDefaultFilterSet = filterSets => filterSets.find(({
  defaultFilter
}) => defaultFilter);