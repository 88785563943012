'use es6';

import { getCustomWidgetType } from 'reporting-data/report/reportGetters';
import CustomWidgets from 'reporting-ui-components/components/customWidgets/constants/widgets';
import FirstDimensionControl from '../../components/controls/FirstDimensionControl';
import SecondDimensionControl from '../../components/controls/SecondDimensionControl';
import { getMutators } from '../../constants/sub-app/mutators';
import { DimensionsMutator } from './configMutators';
const applyDimensionMutation = (report, subAppKey, context, filteredGates) => {
  const mutatorProps = {
    report,
    subAppKey,
    context,
    filteredGates
  };
  if (FirstDimensionControl.isUsableForReport({
    report,
    subAppKey,
    filteredGates
  }) || getCustomWidgetType(report) === CustomWidgets.PIPELINE_SNAPSHOTS || SecondDimensionControl.isUsableForReport({
    subAppKey,
    filteredGates
  })) {
    return DimensionsMutator(mutatorProps);
  }
  return report;
};
export const applyMutators = ({
  reportTemplate,
  subAppKey,
  context,
  additionalMutators = [],
  filteredGates = [],
  options = {}
}) => {
  const reportWithDimensionMutation = applyDimensionMutation(reportTemplate, subAppKey, context, filteredGates);
  const mutators = [...getMutators(subAppKey, reportWithDimensionMutation, filteredGates, context), ...additionalMutators];
  return mutators.reduce((updatedReport, mutator) => mutator({
    report: updatedReport,
    subAppKey,
    context,
    filteredGates,
    options
  }), reportWithDimensionMutation);
};