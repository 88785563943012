'use es6';

import usePollingQuery from './usePollingQuery';
import { GET_CONNECTED_EMAIL_SENDING_DOMAINS, CONNECTED_EMAIL_SENDING_DOMAINS_FIELD_NAME } from '../requests/emails';
export const useDomains = () => {
  const {
    data,
    error,
    loading,
    refetch
  } = usePollingQuery(GET_CONNECTED_EMAIL_SENDING_DOMAINS);
  return {
    data: data ? data[CONNECTED_EMAIL_SENDING_DOMAINS_FIELD_NAME].toArray() : [],
    error,
    loading,
    refetch
  };
};