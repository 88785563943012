'use es6';

import { fill } from '../endpoints/fill';
const check = points => Boolean(points) && points.some(({
  score
}) => score != null);
const trailingSustain = (spec, config) => response => {
  let started = false;
  return fill(({
    previous
  }) => {
    started = started || check(previous);
    return started ? previous : [{
      score: null
    }];
  })(spec, config)(response);
};
export const preprocess = (spec, config) => response => {
  const {
    dimensions: [primary] = []
  } = config;
  return primary === 'sessionDate' ? trailingSustain(spec, config)(response) : response;
};