import { get } from '../../request/http';
const APP_NAME = 'raasExternalDataSystemMetadata';
const API_URL = `framework-builder/v1/read/metadata/types/${APP_NAME}`;
const fetchAllDataSystemMetadata = () => {
  return get(API_URL).then(response => response && response.toJS && response.toJS());
};
export const fetchDataSystemMetadata = dataType => {
  return fetchAllDataSystemMetadata().then(dataSystemMetadata => {
    if (dataType && dataSystemMetadata[dataType]) {
      return dataSystemMetadata[dataType].metadata;
    }
    return undefined;
  });
};