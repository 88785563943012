import { List } from 'immutable';
import { CUSTOM } from 'reporting-data/constants/chartTypes';
import { supportedDataTypes } from 'reporting-data/retrieve/inboundDb/common/dataTypes';
import { CROSS_OBJECT } from 'reporting-data/constants/dataTypes';
import { isSalesAnalyticsWidget } from '../components/customWidgets/lib/salesExplorer';
export default (report => {
  if (!report) return false;
  const isCustomWidget = report.get('chartType') === CUSTOM;
  const dataType = report.getIn(['config', 'dataType']);
  const hasSupportedDataType = supportedDataTypes.includes(dataType);
  const isCrossObject = dataType === CROSS_OBJECT;
  const hasQuotaMetric = report.getIn(['config', 'metrics'], List()).some(metric => metric.get('property') === 'QUOTAS.MONTHLY.REVENUE');
  const isSalesAnalytics = isSalesAnalyticsWidget(report);
  return !isCustomWidget && (hasSupportedDataType || isCrossObject) && !hasQuotaMetric && !isSalesAnalytics;
});