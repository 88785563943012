'use es6';

import { useState, useCallback } from 'react';
export const useOpenState = (initialState = false) => {
  const [isOpen, setOpenState] = useState(initialState);
  const close = useCallback(() => {
    setOpenState(false);
  }, [setOpenState]);
  const open = useCallback(() => {
    setOpenState(true);
  }, [setOpenState]);
  return {
    open,
    close,
    isOpen
  };
};