'use es6';

import http from 'hub-http/clients/apiClient';
import { registerQuery, registerMutation } from 'data-fetching-client';
import { fetchApps } from 'share-with-third-party-component-lib/api/ShareWithThirdPartyComponentApi';
export const SHARE_THIRD_PARTY_APP_FIELD_NAME = 'shareThirdPartyApp';
export const SHARE_THIRD_PARTY_APP_RECIPIENT_NAME = 'shareThirdPartyRecipientName';
const updateSlackRecipient = payload => {
  return http.post(`share-with-third-party/v1/recipients`, {
    data: Object.assign({}, payload)
  });
};

// @returns Object { [app.Name (e.g. Slack, Teams etc.)]: integer }
export const FETCH_SHARE_THIRD_PARTY_APPS = registerQuery({
  fieldName: SHARE_THIRD_PARTY_APP_FIELD_NAME,
  fetcher() {
    return fetchApps().then(apps => apps);
  }
});

// @returns Object of the Slack channel/user info
export const UPDATE_TO_FETCH_SLACK_NAME_FIELD = registerMutation({
  fieldName: SHARE_THIRD_PARTY_APP_RECIPIENT_NAME,
  args: ['appId', 'id', 'type'],
  fetcher(payload) {
    return updateSlackRecipient(payload);
  }
});