'use es6';

import FilterOperatorBoolInput from 'customer-data-filters/components/operator/FilterOperatorBoolInput';
import FilterOperatorDateInput from 'customer-data-filters/components/operator/FilterOperatorDateInput';
import FilterOperatorNumberInput from 'customer-data-filters/components/operator/FilterOperatorNumberInput';
import FilterOperatorPercentageInput from 'customer-data-filters/components/operator/FilterOperatorPercentageInput';
import FilterOperatorTextInput from 'customer-data-filters/components/operator/FilterOperatorTextInput';
import FilterOperatorMultiStringInput from 'customer-data-filters/components/operator/FilterOperatorMultiStringInput';
import { getObjectSegOperatorsForTypeStrict } from 'customer-data-filters/converters/objectSeg/ObjectSegTypeToOperator';
import FilterOperatorEnumInput from 'customer-data-filters/components/operator/FilterOperatorEnumInput';
import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import * as DisplayTypes from 'customer-data-filters/filterQueryFormat/DisplayTypes';
import { OBJECT_COORDINATES } from 'customer-data-filters/filterQueryFormat/UnifiedEventPropertyTypes';
import { CRM_OBJECT_META_TYPE } from 'reporting-data/constants/crmObjectMetaTypes';
import { getMetaTypeFromObjectTypeId } from 'reporting-data/crmObjects/tsUtils';
import * as ExternalOptionTypes from 'customer-data-objects/property/ExternalOptionTypes';
import FilterOperatorOwnerInput from 'customer-data-filters/components/operator/FilterOperatorOwnerInput';
import FilterOperatorTeamInput from 'customer-data-filters/components/operator/FilterOperatorTeamInput';
import { SNOWFLAKE_FILTER_FAMILY } from '../../relational/modify/filters/xo-filter-utils';
import { isNonExcludedReferenceType } from './overrides';
import { getReferenceType } from '../../relational/metadata/references/external/external-mapping';
import { toSnowflakeProperty } from '../../relational/metadata/properties';
import { overrideProperty } from './overrides';
import { getIsUngated } from '../../shared/lib/utils';
export function getInputComponent(referenceType, property, operator) {
  const propertyType = property.type;
  // HACK: This is for a special case in ListSegClassic and should not be
  // relied upon to remain the same.
  if (propertyType === DisplayTypes.StringDisplayType && operator.constructor.isIterableField('value') && !isNonExcludedReferenceType(referenceType, property.name)) {
    return FilterOperatorMultiStringInput;
  }
  if (isNonExcludedReferenceType(referenceType)) {
    if (referenceType === ExternalOptionTypes.TEAM) {
      return FilterOperatorTeamInput;
    } else if (referenceType === ExternalOptionTypes.OWNER) {
      return FilterOperatorOwnerInput;
    }
    return FilterOperatorEnumInput;
  }
  switch (propertyType) {
    case DisplayTypes.BooleanDisplayType:
      return FilterOperatorBoolInput;
    case DisplayTypes.DateDisplayType:
    case DisplayTypes.DatetimeDisplayType:
      return FilterOperatorDateInput;
    case DisplayTypes.EnumerationDisplayType:
      return FilterOperatorEnumInput;
    case DisplayTypes.NumberDisplayType:
      return FilterOperatorNumberInput;
    case DisplayTypes.PercentageDisplayType:
      return FilterOperatorPercentageInput;
    case OBJECT_COORDINATES:
      return FilterOperatorEnumInput;
    default:
      return FilterOperatorTextInput;
  }
}
const HIDDEN_OPERATORS = [Operators.After, Operators.Before, Operators.UpdatedAfter, Operators.UpdatedBefore];
const HIDDEN_EVENT_OPERATORS = [Operators.UpdatedInLastXDays, Operators.NotUpdatedInLastXDays, Operators.EverContained, Operators.EverContainedAll, Operators.EverContainedAny, Operators.NeverContained, Operators.NeverContainedAll, Operators.EverEqual, Operators.EverEqualAny, Operators.NeverEqual, Operators.NeverEqualAny, Operators.EverIn, Operators.NeverIn, Operators.EverEqualAll, Operators.NeverEqualAll];
const HIDDEN_REFERENCE_OPERATORS = [Operators.ContainAny, Operators.NotContainAny, Operators.StartsWithAny, Operators.EndsWithAny];
export function getOperators(property, objectTypeId, referenceType, userGates) {
  const operatorsParams = property.type === OBJECT_COORDINATES ? [property.type, objectTypeId] : [property.type, SNOWFLAKE_FILTER_FAMILY];
  const operators = getObjectSegOperatorsForTypeStrict(...operatorsParams, gate => getIsUngated(gate, userGates), true);
  const isEvent = [CRM_OBJECT_META_TYPE.HUBSPOT_EVENT, CRM_OBJECT_META_TYPE.PORTAL_SPECIFIC_EVENT].includes(getMetaTypeFromObjectTypeId(objectTypeId));
  const operatorsToHide = [...HIDDEN_OPERATORS, ...(isEvent ? HIDDEN_EVENT_OPERATORS : []), ...(isNonExcludedReferenceType(referenceType) ? HIDDEN_REFERENCE_OPERATORS : [])];
  return operators.filterNot(operator => operatorsToHide.includes(operator));
}
export const getFilterReferenceType = (entity, property) => {
  const normalizedProperty = overrideProperty(property);
  const snowflakeProperty = toSnowflakeProperty(normalizedProperty);
  return getReferenceType(entity, snowflakeProperty);
};