export const CONTACT_SALES_BUTTON = 'ContactSalesButton';
export const UI_ASSIGN_SEAT_BUTTON = 'UIAssignSeatButton';
export const UI_PRICING_PAGE_REDIRECT_BUTTON = 'UIPricingPageRedirectButton';
export const UI_PURCHASE_MOTION_BUTTON = 'UIPurchaseMotionButton';
export const UI_UPGRADE_BANNER = 'UIUpgradeBanner';
export const MC0653_USAGE_LIMIT_BANNER = 'MC0653UsageLimitBanner';
export const MC_0653_EMAIL_SENDS_COUNTER = 'MC0653EmailSendsCounter';
export const MC_0653_EMAIL_SENDS_WARNING = 'MC0653EmailSendsWarning';
export const MC_0653_EMAIL_SENDS_PROGRESS_BAR = 'MC0653EmailSendsProgressBar';
export const FEATURE_TABLE = 'FeatureTable';
export const MC_0663_PAYWALL = 'MC0663Paywall';
export const MC_0663_PAYWALL_FEATURE_TABLE = 'MC0663PaywallFeatureTable';