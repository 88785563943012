import invariant from 'react-utils/invariant';
export function curry(fn) {
  return (...xs) => {
    invariant(xs.length > 0, 'Empty Invocation');
    if (xs.length >= fn.length) {
      return fn(...xs);
    }
    return curry(fn.bind(null, ...xs));
  };
}
export const identity = value => value;
export function compose(...fns) {
  return fns.reduceRight((prevFn, nextFn) => (...args) => nextFn(prevFn(...args)), identity);
}
export const deepCompare = (valA, valB) => {
  if (typeof valA !== 'object' || typeof valB !== 'object' || valA === null || valB === null) {
    return valA === valB;
  }
  const aProps = Object.getOwnPropertyNames(valA);
  const bProps = Object.getOwnPropertyNames(valB);
  if (aProps.length !== bProps.length) {
    return false;
  }
  for (let index = 0; index < aProps.length; index++) {
    const propName = aProps[index];
    if (!deepCompare(valA[propName], valB[propName])) {
      return false;
    }
  }
  return true;
};

/**
 * Keep the whitelisted keys of the object
 *
 * @param {Array}  keys Array of keys to pick
 * @param {Object} obj  Object to filter
 *
 * @return {Object}
 */
const _pick = (keys, obj) => {
  invariant(Array.isArray(keys), 'keys must be an Array');
  const result = {};
  keys.forEach(key => {
    if (key in obj) {
      result[key] = obj[key];
    }
  });
  return result;
};
export const pick = curry(_pick);

/**
 * Filter out the blacklisted keys from the object
 *
 * @param {Array}  keys Array of keys to omit
 * @param {Object} obj  Object to filter
 *
 * @return {Object}
 */
const _omit = (keys, obj) => {
  invariant(Array.isArray(keys), 'keys must be an Array');
  const result = {};
  const index = {};
  keys.forEach(key => {
    index[key] = 1;
  });
  Object.keys(obj).forEach(key => {
    if (!index[key]) {
      result[key] = obj[key];
    }
  });
  return result;
};
export const omit = curry(_omit);
const _path = (paths, obj) => {
  let val = obj;
  let idx = 0;
  while (idx < paths.length) {
    if (typeof val === 'undefined' || val === null) {
      return undefined;
    }
    val = val[paths[idx]];
    idx += 1;
  }
  return val;
};
export const path = curry(_path);

/**
 * Map over an object and apply a function to each value
 *
 * @param {Function} iteratee The iterator func to apply to each (value, key) pair
 * @param {Object}   obj      The object to map over
 *
 * @return {Object}
 */
const _mapObject = (iteratee, obj = {}) => {
  return Object.keys(obj).reduce((acc, key) => {
    acc[key] = iteratee(obj[key], key);
    return acc;
  }, {});
};
export const mapObject = curry(_mapObject);

/**
 * Utils for escape and unescape
 */
const charRegex = /[&<>"'`]/g;
const charEscapeMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '`': '&#96;'
};
const escapeChar = char => charEscapeMap[char];

/**
 * Replaces the characters
 * '&', '<', '>', '"', "'", and '`' in the string
 * with their corresponding HTML entities
 *
 * @param {String} string Phrase to escape
 *
 * @return {String}
 */
export const escape = function escape(string) {
  return charRegex.test(string) ? string.replace(charRegex, escapeChar) : string;
};