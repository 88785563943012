'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { EditorState, Modifier, ContentBlock, BlockMapBuilder, genKey } from 'draft-js';
const getEmptyBlock = () => new ContentBlock({
  key: genKey(),
  type: 'unstyled',
  text: '',
  characterList: List()
});
export default function insertAtomicBlockWithData(editorState, blockData, preserveSelection = false, prependWithEmptyBlock = false, typeOverride) {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const afterRemoval = Modifier.removeRange(contentState, selectionState, 'backward');
  const targetSelection = afterRemoval.getSelectionAfter();
  const afterSplit = Modifier.splitBlock(afterRemoval, targetSelection);
  const insertionTarget = afterSplit.getSelectionAfter();
  const asType = Modifier.setBlockType(afterSplit, insertionTarget, 'atomic');
  const fragmentArray = [new ContentBlock({
    key: genKey(),
    type: typeOverride || 'atomic',
    text: '',
    characterList: List(),
    data: ImmutableMap(blockData)
  }), getEmptyBlock()];
  if (prependWithEmptyBlock) {
    fragmentArray.unshift(getEmptyBlock());
  }
  const fragment = BlockMapBuilder.createFromArray(fragmentArray);
  const withBlock = Modifier.replaceWithFragment(asType, insertionTarget, fragment);
  const newSelection = preserveSelection ? selectionState : withBlock.getSelectionAfter().set('hasFocus', true);
  const newContent = withBlock.merge({
    selectionBefore: selectionState,
    selectionAfter: newSelection
  });
  return EditorState.forceSelection(EditorState.push(editorState, newContent, 'insert-fragment'), newContent.getSelectionAfter());
}