'use es6';

import { Map as ImmutableMap, List, Set as ImmutableSet } from 'immutable';
import { applySorting } from '../../modify/sorts/report-sort-utils';
import { applyTotals } from '../../modify/totals/totals';
import { Encoding, UNDEFINED_BUCKET } from '../../schema/column-records';
import { ChannelNames, VisualTypes } from '../../schema/visual-records';
import { createMagicMeasure, dataSourceTypeSupportsMagicMeasure, getMeasures, isDateLikeField, updateUndefinedBucket, isFieldExpressionBased } from '../../utils/column-utils';
import { createDefaultEventDateInterval } from '../../utils/filter-utils';
import { applyLimits } from '../../utils/limit-utils';
import { applyResetFixedMeasures, getReportXEncoding, getReportY2Encoding, removeUnencodedColumns } from '../../utils/report-utils';
import { findEventLikeTable } from '../../utils/table-utils';
import { applyCompareColumn, applyRequiredDateComparisonEncodings } from '../../utils/date-comparison-filter-utils';
import { LegendPositions } from 'reporting-visualizations/visualizations/commonRecords';
import { buildFieldFromExpressionField, doesFieldReferenceExpression, expressionFieldsListToMap } from '../../utils/expression-field-utils';
import { getVisualTypeChannels, isSingleEncoding, decodeColumn, getEncodingList, createDefaultColumn, findEncodedChannel } from '../../utils/visual-utils';
import { findFiltersUsingField, removeFiltersWithPredicate, addExpressionFieldFilterToStage, isExpressionFieldFilter } from '../../modify/filters/filter-utils';
import { toPropertyBasicType } from '../../../dataset/type-utils';
import { channels as channelConstraints } from '../../validate/constraints/channels';
import { isValid } from '../../validate/validation-utils';
import { COLUMN_ROLES } from 'reporting-data/constants/relationalReports';
const CONTINUOUS_VISUAL_ENCODINGS = ImmutableMap({
  [VisualTypes.AREA]: ImmutableSet.of(ChannelNames.X),
  [VisualTypes.VERTICAL_BAR]: ImmutableSet.of(ChannelNames.X),
  [VisualTypes.HORIZONTAL_BAR]: ImmutableSet.of(ChannelNames.Y),
  [VisualTypes.COMBO]: ImmutableSet.of(ChannelNames.X),
  [VisualTypes.LINE]: ImmutableSet.of(ChannelNames.X)
});
export const isChannelContinuous = (visualType, channelName) => {
  const continuousChannelsForVisual = CONTINUOUS_VISUAL_ENCODINGS.get(visualType);
  return continuousChannelsForVisual && continuousChannelsForVisual.has(channelName);
};

/**
 * Returns a reducer that iterates through all encodings in a report.
 * @param {RelationalReport} report
 * @returns {((T, Encoding, Channel) => T, T) => T}
 */
export const makeEncodingsReducer = report => (reducerFn, initialValue) => {
  const {
    visual: {
      encodings,
      type: visualType
    }
  } = report;
  return getVisualTypeChannels(visualType).reduce((acc, channel) => {
    const {
      name: channelName
    } = channel;
    const maybeEncoding = encodings.get(channelName);
    if (!maybeEncoding) {
      return acc;
    }
    if (isSingleEncoding(channel)) {
      return reducerFn(acc, maybeEncoding, channel);
    }
    return maybeEncoding.reduce((acc2, encoding) => {
      return reducerFn(acc2, encoding, channel);
    }, acc);
  }, initialValue);
};

/**
 * For compatible visual types: enable undefined date buckets;
 * For incompatible visual types: disable undefined date buckets.
 * - Since we don't give users control over enabling/disabling undefined buckets, we disable all undefined date dimension buckets by default.
 * - However in certain visuals (ex tables), this default would hide those undefined data from customers
 * - Therefore, this mod converts these columns to include undefined values when the visual types support it
 */
export const applyEnableUndefinedDateBuckets = report => {
  const visualType = report.visual.type;
  const enableUndefinedDateBucket = (column, channelName) => {
    if (column.role === COLUMN_ROLES.MEASURE) {
      return updateUndefinedBucket(column);
    }
    const continuousChannel = isChannelContinuous(visualType, channelName);
    const dateDimensionOnAContinuousChannel = column.role === COLUMN_ROLES.DIMENSION && isDateLikeField(column.field) && continuousChannel;
    if (dateDimensionOnAContinuousChannel) {
      return updateUndefinedBucket(column);
    }
    return column.set('includeUndefinedBuckets', true).set('undefinedBucket', UNDEFINED_BUCKET);
  };
  const nextReport = report.update('columns', columns => {
    const reduceEncodings = makeEncodingsReducer(report);
    return reduceEncodings((nextColumns, encoding, channel) => {
      const {
        column: encodedColumnAlias
      } = encoding;
      return nextColumns.update(encodedColumnAlias, column => enableUndefinedDateBucket(column, channel.name));
    }, columns);
  });
  return nextReport.update('stagedColumns', columns => columns.map(updateUndefinedBucket));
};
const applyFixedY2Measure = report => {
  const {
    visual
  } = report;
  const {
    type,
    encodings
  } = visual;
  if (type === VisualTypes.COMBO && encodings.y2) {
    const y2 = getReportY2Encoding(report).column;
    const xLOD = encodings.x ? List.of(getReportXEncoding(report).column.alias) : List();
    const fixedMeasure = y2.role === COLUMN_ROLES.MEASURE ? y2.set('fixedMeasure', true).set('fixed', xLOD) : y2.set('fixedMeasure', false).set('fixed', List());
    return report.update('columns', columns => columns.set(y2.alias, fixedMeasure));
  }
  return report;
};
const GENERATED_MEASURE_PREFIX = 'generatedMeasure_';
export const getGeneratedMeasureColumnAlias = alias => `${GENERATED_MEASURE_PREFIX}${alias}`;
export const isGeneratedMeasureColumn = column => column.alias.startsWith(GENERATED_MEASURE_PREFIX);
const UNAGGREGATED_VISUALS = ImmutableSet([VisualTypes.TABLE, VisualTypes.SCATTER]);
const applyGeneratedMeasure = report => {
  const {
    table,
    columns,
    visual: {
      type
    } = {}
  } = report;
  if (!dataSourceTypeSupportsMagicMeasure(table.type)) {
    return report;
  }
  const isAggregation = !getMeasures(columns).isEmpty();
  if (UNAGGREGATED_VISUALS.includes(type) || isAggregation) {
    return report;
  }
  const maybeMagicMeasure = createMagicMeasure(table);
  if (!maybeMagicMeasure) {
    return report;
  }
  const alias = getGeneratedMeasureColumnAlias(maybeMagicMeasure.alias);
  const aliasedMeasure = maybeMagicMeasure.set('alias', alias);
  const nextColumns = columns.set(alias, aliasedMeasure);
  return report.set('columns', nextColumns);
};
const applyDefaultEventDateInterval = (report, deps) => {
  var _deps$meta;
  const {
    table
  } = report;
  const tableMeta = deps === null || deps === void 0 || (_deps$meta = deps.meta) === null || _deps$meta === void 0 ? void 0 : _deps$meta.tables;
  if (findEventLikeTable(table, tableMeta)) {
    return report.update('eventDateInterval', eventDateInterval => eventDateInterval || createDefaultEventDateInterval());
  }
  return report.delete('eventDateInterval');
};
const applyDefaultBubbleLegendPosition = report => {
  const isBubble = report.getIn(['visual', 'encodings', 'sizing']);
  const bubbleLegendEnabled = report.getIn(['visual', 'options', 'showBubbleLegend']);
  const legendPosition = report.getIn(['visual', 'options', 'legendPosition']);
  const bubbleLegendPositions = List([LegendPositions.LEFT, LegendPositions.RIGHT]);
  if (isBubble && bubbleLegendEnabled && !bubbleLegendPositions.includes(legendPosition)) {
    return report.setIn(['visual', 'options', 'legendPosition'], LegendPositions.LEFT);
  }
  return report;
};
export const removeUsedHiddenExpressions = report => {
  const hiddenExpressionFields = report.expressions.filter(e => e.hidden);
  const next = hiddenExpressionFields.reduce((nextReport, expressionField) => {
    const {
      columns,
      stagedColumns,
      filtering
    } = nextReport;

    // todo sort and limit references?

    const hasUsageInColumns = !!columns.filter(column => doesFieldReferenceExpression(column.field, expressionField)).size;
    if (hasUsageInColumns) {
      return nextReport;
    }
    const hasUsageInStagedColumns = !!stagedColumns.filter(column => doesFieldReferenceExpression(column.field, expressionField)).size;
    if (hasUsageInStagedColumns) {
      return nextReport;
    }
    const hasUsageInFiltering = findFiltersUsingField(filtering, buildFieldFromExpressionField(expressionField));
    if (hasUsageInFiltering) {
      return nextReport;
    }

    // todo we may need to check for expressions referencing each other

    const isSameExpression = (a, b) => a.alias === b.alias;
    return nextReport.update('expressions', expressions => expressions.filterNot(exp => isSameExpression(exp, expressionField)));
  }, report);
  return next;
};

// necessary to avoid "used before initialization" export error
const modApplyFixedY2Measure = applyFixedY2Measure;

/**
 * Verifies that field types of expression fields matches column and filter types.
 * If they do not match, moves the columns or filters to staging with correct type
 * @param {RelationalReport} report
 * @returns {RelationalReport}
 */
export const correctEncodedExpressionFieldTypes = (report, userInfo) => {
  const expressionsMap = expressionFieldsListToMap(report.expressions);
  const isFieldsTypeAccurate = field => {
    if (!expressionsMap.has(field.name)) {
      return false;
    }
    const expressionFieldType = expressionsMap.getIn([field.name, 'type']);
    return toPropertyBasicType(expressionFieldType) === field.type;
  };
  const updateField = field => field.set('type', toPropertyBasicType(expressionsMap.getIn([field.name, 'type'])));
  const filterHasInvalidExpressionFieldType = filter => isExpressionFieldFilter(filter) && !isFieldsTypeAccurate(filter.field);
  const correctExpressionFieldFilters = filtering => filtering.update('groups', groups => groups.map(group => group.update('filters', filters => filters.map(filter => {
    if (filterHasInvalidExpressionFieldType(filter) && expressionsMap.has(filter.field.name)) {
      const nextFilter = filter.set('field', buildFieldFromExpressionField(expressionsMap.get(filter.field.name)));
      const isValidFilter = ['IS_KNOWN', 'IS_UNKNOWN'].includes(filter.filter.get('operation').get('operator'));
      if (isValidFilter) {
        return nextFilter;
      }
    }
    return filter;
  }))));
  const moveInvalidExpressionFieldFiltersToStaged = filtering => {
    const invalidExpressionFieldsInFilters = filtering.groups.reduce((nextFieldsToStageSet, group) => group.filters.reduce((next, filter) => {
      if (filterHasInvalidExpressionFieldType(filter) && expressionsMap.has(filter.field.name)) {
        return next.add(filter.field.name);
      }
      return next;
    }, nextFieldsToStageSet), ImmutableSet());
    return invalidExpressionFieldsInFilters.reduce((nextFiltering, fieldName) => {
      const field = buildFieldFromExpressionField(expressionsMap.get(fieldName));
      const updatedFiltering = removeFiltersWithPredicate(nextFiltering, () => false, filterHasInvalidExpressionFieldType);
      return addExpressionFieldFilterToStage(updatedFiltering, field);
    }, filtering);
  };
  const correctColumnsWithInvalidExpressionFieldTypes = (nextReport, column) => {
    if (!isFieldExpressionBased(column.field) || isFieldsTypeAccurate(column.field)) {
      return nextReport;
    }
    const expressionField = expressionsMap.get(column.field.name);
    const encoding = getEncodingList(nextReport.visual.encodings).find(enc => enc.column === column.alias);

    // expression field is not encoded to viz
    // column will be added back in `modifiers()` with correct type
    if (!encoding) {
      return nextReport.update('columns', columns => columns.delete(column.alias));
    }
    const clonedColumn = createDefaultColumn(buildFieldFromExpressionField(expressionField), findEncodedChannel(nextReport.visual, encoding).name, nextReport.visual.type).set('alias', column.alias);
    const passesConstraints = isValid(nextReport.setIn(['columns', clonedColumn.alias], clonedColumn), userInfo, channelConstraints);
    if (passesConstraints) {
      return nextReport.setIn(['columns', clonedColumn.alias], clonedColumn);
    } else {
      return nextReport.update('columns', columns => columns.delete(column.alias)).update('visual', visual => decodeColumn(visual, column)).update('stagedColumns', stagedColumns => stagedColumns.push(clonedColumn)).update('stagedEncodings', stagedEncodings => stagedEncodings.push(Encoding({
        column: clonedColumn.alias
      })));
    }
  };
  return report.update('filtering', filtering => filtering.update(correctExpressionFieldFilters).update(moveInvalidExpressionFieldFiltersToStaged)).update(nextReport => {
    const stagedColumnIdsToUpdate = nextReport.stagedColumns.filter(column => isFieldExpressionBased(column.field) && !isFieldsTypeAccurate(column.field)).map(column => column.alias);
    return nextReport.update('stagedColumns', stagedColumns => stagedColumns.map(column => {
      if (stagedColumnIdsToUpdate.includes(column.alias)) {
        return column.set('field', updateField(column.field));
      }
      return column;
    })).update('stagedEncodings', stagedEncodings => stagedEncodings.map(encoding => stagedColumnIdsToUpdate.includes(encoding.column) ? Encoding({
      column: encoding.column
    }) : encoding));
  }).update(nextReport => nextReport.columns.reduce(correctColumnsWithInvalidExpressionFieldTypes, nextReport));
};
export const applyModifiers = (report, deps) => {
  const modifiers = [applyCompareColumn, applyRequiredDateComparisonEncodings, modApplyFixedY2Measure, applyResetFixedMeasures, applyTotals, (r, {
    meta
  }) => {
    return applySorting(r, meta);
  }, applyLimits, applyDefaultBubbleLegendPosition, applyGeneratedMeasure, applyEnableUndefinedDateBuckets, applyDefaultEventDateInterval, (r, {
    userInfo
  }) => {
    return correctEncodedExpressionFieldTypes(r, userInfo);
  }, removeUsedHiddenExpressions, removeUnencodedColumns // caution: this needs to be run first
  ];
  return modifiers.reduceRight((nextReport, mod) => {
    return mod(nextReport, deps);
  }, report);
};
export const __TESTABLE__ = {
  applyFixedY2Measure,
  applyGeneratedMeasure,
  modApplyFixedY2Measure,
  applyEnableUndefinedDateBuckets,
  applyDefaultEventDateInterval,
  applyLimits,
  applyDefaultBubbleLegendPosition,
  removeUnencodedColumns
};