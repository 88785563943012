import quickFetch from 'quick-fetch';
export function withQuickFetch({
  requestName,
  baseFetch
}) {
  const quickFetchedRequest = quickFetch.getRequestStateByName(requestName);
  if (quickFetchedRequest) {
    return new Promise((resolve, reject) => {
      quickFetchedRequest.whenFinished(result => {
        quickFetch.removeEarlyRequest(requestName);
        resolve(result);
      });
      quickFetchedRequest.onError(() => {
        quickFetch.removeEarlyRequest(requestName);
        baseFetch().then(resolve, reject).catch(err => {
          throw err;
        });
      });
    });
  }
  return baseFetch();
}