import * as checked from '../lib/checked';
export const DATASET_INSIGHT_DATASET_PREFIX = 'insights-dataset';
export const DATASET_INSIGHT_TYPES = {
  LINEAR_TRENDLINE: 'LINEAR_TRENDLINE',
  LOGARITHMIC_TRENDLINE: 'LOGARITHMIC_TRENDLINE',
  FORECAST_TRENDLINE: 'FORECAST_TRENDLINE',
  ANOMALY_DETECTION: 'ANOMALY_DETECTION'
};
export const DatasetInsightMeta = checked.record({
  metricName: checked.string(),
  breakdownValue: checked.string().optional(),
  rangeName: checked.string().optional()
}, 'DatasetInsightMeta');
export const DatasetInsightOption = checked.record({
  insightType: checked.symbol(DATASET_INSIGHT_TYPES, 'DATASET_INSIGHT_TYPES').required()
}, 'DatasetInsightOption');
export const DatasetInsightParams = checked.record({
  insightOptions: checked.list(DatasetInsightOption).required()
}, 'DatasetInsightParams');