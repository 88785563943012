'use es6';

import { List } from 'immutable';
import { getTotalMeasureAliasPivot, isTotalAlias } from 'reporting-visualizations/visualizations/crossTab/dataHelpers/totals';
export const isTotalMeasure = measure => isTotalAlias(measure.alias);
const toTotalMeasure = (measure, rows, columns, row, column) => {
  const rowDetail = row ? rows.takeUntil(r => r === row).concat(row) : List();
  const colDetail = column ? columns.takeUntil(c => c === column).concat(column) : List();
  const detail = rowDetail.concat(colDetail);
  return measure.set('alias', getTotalMeasureAliasPivot(measure.alias, row, column)).set('fixedMeasure', true).set('fixed', List(detail));
};

/**
 * Generates all totals and subtotals for a pivot table
 */
export const applyPivotTotals = report => {
  const {
    columns: reportColumns,
    visual: {
      encodings: {
        rows: rowsEncodings,
        columns: columnsEncodings,
        values: valuesEncodings
      },
      options: {
        showColumnSubtotals,
        showRowSubtotals
      } = {}
    }
  } = report;
  if (!columnsEncodings || !rowsEncodings || !valuesEncodings) {
    return report;
  }
  if (valuesEncodings.isEmpty()) {
    return report;
  }
  const rows = rowsEncodings.map(({
    column: alias
  }) => alias);
  const columns = columnsEncodings.map(({
    column: alias
  }) => alias);
  const values = valuesEncodings.map(({
    column: alias
  }) => alias);
  const detail = rows.concat(columns);

  // Where subtotals are not used, totals vary with only the last row/column
  const rowsWithUndefined = showRowSubtotals ? List([undefined, ...rows]) : List([undefined, ...rows.takeLast(1)]);
  const columnsWithUndefined = showColumnSubtotals ? List([undefined, ...columns]) : List([undefined, ...columns.takeLast(1)]);
  const totalColumns = rowsWithUndefined.flatMap(row => columnsWithUndefined.flatMap(column => values.map(value => {
    const measure = reportColumns.get(value);
    return toTotalMeasure(measure, rows, columns, row, column);
  }))).filterNot(column => (column.fixed || List()).equals(detail)); // this column is basically just the measure itself

  const newColumnsMap = totalColumns.toMap().mapKeys((_, column) => column.alias);
  const nextColumns = reportColumns.merge(newColumnsMap);
  return report.set('columns', nextColumns);
};