import get from 'transmute/get';
import http from 'hub-http/clients/apiClient';
// @ts-expect-error untyped module
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
// @ts-expect-error untyped module

import formatCtaGuids from 'reference-resolvers/formatters/formatCtaGuids';
export const createGetCTASByGuids = ({
  httpClient
}) => ids => httpClient.get(`ctas/v3/ctas/batch`, {
  query: {
    guids: ids
  }
}).then(resp => formatToReferencesList({
  getId: get('guid'),
  getLabel: get('name')
})(resp.ctas));
export const getCTASByGuids = createGetCTASByGuids({
  httpClient: http
});
export const createGetCTAGuidPage = ({
  httpClient
}) => query => {
  const parsedQuery = {
    name__like: query.get('query'),
    limit: query.get('count'),
    offset: query.get('offset')
  };
  return httpClient.get(`ctas/v3/ctas`, {
    query: parsedQuery
  }).then(formatCtaGuids);
};
export const getCTAGuidPage = createGetCTAGuidPage({
  httpClient: http
});