'use es6';

import get from 'transmute/get';
import { getType } from './param';

/**
 * This flag controls whether we error out when there are type mismatches
 * between the parameter's type and the given value.
 *
 * Turn this flag on before you merge into master.
 * @type {boolean}
 */
const PROD = true;
export const getAndCheck = (param, obj) => {
  const val = get(param, obj);
  if (!PROD) {
    const throwableChecker = getType(param);
    throwableChecker(param, val);
  }
  return val;
};