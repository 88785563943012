import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["additionalFields", "description"];
import { PERSONA, SYNC_ACCOUNT } from 'customer-data-objects/externalOptions/ExternalOptionsReferenceTypes';
// This file is only used with `createExternalOptionsReferenceResolver` and is
// therefore tested with that file to ensure it's wired up correctly.
const getIcon = (referenceType, __objectTypeId, reference) => {
  if (referenceType === PERSONA || referenceType === SYNC_ACCOUNT) {
    const {
      additionalFields
    } = reference;
    const iconSrc = additionalFields.iconSrc;
    return iconSrc ? {
      src: iconSrc
    } : undefined;
  }
  return undefined;
};
const formatExternalOptionsReference = (externalOptionReference, options) => {
  const {
      additionalFields,
      description
    } = externalOptionReference,
    rest = _objectWithoutPropertiesLoose(externalOptionReference, _excluded);
  const {
    objectTypeId,
    referenceType
  } = options;
  const icon = getIcon(referenceType, objectTypeId, externalOptionReference);
  return Object.assign({}, rest, {
    additionalProperties: additionalFields
  }, description != null && {
    description
  }, icon != null && {
    icon
  });
};
export const formatExternalOptionsByIdResponse = (externalOptionReferences, options) => externalOptionReferences.map(reference => formatExternalOptionsReference(reference, options));
export const formatExternalOptionsSearchResponse = (apiResult, options) => ({
  hasMore: apiResult.hasMore,
  offset: apiResult.offset,
  references: apiResult.results.map(reference => formatExternalOptionsReference(reference, options))
});