'use es6';

import formatName from 'I18n/utils/formatName';
//------------------------------------------------------------------------------
// Requirements
//------------------------------------------------------------------------------

import { List } from 'immutable';
import compose from 'transmute/compose';
import filter from 'transmute/filter';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import reduce from 'transmute/reduce';
import { createSelector, createStructuredSelector } from '../lib/ImmutableReselect';
import OwnersStore from '../stores/OwnersStore';

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

function textComparator(a, b) {
  return a.text.localeCompare(b.text);
}
export function formatOwnerLabel(owner) {
  const firstName = owner.get('firstName');
  const lastName = owner.get('lastName');
  const email = owner.get('email');
  const fullName = formatName({
    firstName,
    lastName
  });
  return email ? `${fullName} (${email})`.trim() : fullName;
}

//------------------------------------------------------------------------------
// Selectors
//------------------------------------------------------------------------------

const loadedSelector = () => OwnersStore.get('loaded');
const ownersSelector = () => OwnersStore.get('owners');
const currentOwnerIdSelector = () => OwnersStore.get('currentOwnerId');
const remotesSelector = () => OwnersStore.get('remotes');
const labelsByOwnerIdSelector = createSelector(ownersSelector, owners => {
  const labelsByOwnerId = owners.reduce((ownersList, owner) => {
    const id = owner.get('ownerId');
    ownersList.push({
      text: formatOwnerLabel(owner),
      value: String(id)
    });
    return ownersList;
  }, []).sort(textComparator);
  return List(labelsByOwnerId);
});
const labelsByUserIdSelector = createSelector(remotesSelector, remotes => {
  const labelsByUserId = remotes.reduce((ownersList, remote, key) => {
    if (remote.get('remoteType') === 'HUBSPOT') {
      ownersList.push({
        text: formatOwnerLabel(remote),
        value: key
      });
    }
    return ownersList;
  }, []).sort(textComparator);
  return List(labelsByUserId);
});
const ownersWithEmail = createSelector(ownersSelector, filter(owner => owner.has('email')));
const userIdEmailOptions = createSelector(ownersWithEmail, compose(list => list.sort(textComparator), reduce(List(), (ownersList, owner) => ownersList.push({
  text: formatOwnerLabel(owner),
  value: get('activeUserId', owner)
}))));
const currentUserIdSelector = createSelector(ownersSelector, currentOwnerIdSelector, (owners, currentOwnerId) => getIn([`${currentOwnerId}`, 'activeUserId'], owners));

//------------------------------------------------------------------------------
// Exports
//------------------------------------------------------------------------------

export default {
  stores: [OwnersStore],
  deref: createStructuredSelector({
    owners: ownersSelector,
    currentOwnerId: currentOwnerIdSelector,
    currentUserId: currentUserIdSelector,
    labelsByOwnerId: labelsByOwnerIdSelector,
    labelsByUserId: labelsByUserIdSelector,
    userIdEmailOptions,
    remotes: remotesSelector,
    loaded: loadedSelector
  })
};