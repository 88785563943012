'use es6';

import { Map as ImmutableMap } from 'immutable';
import prefix from '../../lib/prefix';
const translate = prefix('reporting-data.properties.common');
export default (() => ImmutableMap({
  total: ImmutableMap({
    name: 'total',
    type: 'number',
    label: translate('total')
  })
}));