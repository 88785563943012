'use es6';

import formatName from 'I18n/utils/formatName';
import I18n from 'I18n';
import { getEmail, getFirstName, getLastName } from 'dashboard-lib/public/users/users-schema';
const getFullName = user => formatName({
  firstName: getFirstName(user),
  lastName: getLastName(user)
});
export const getUserLabel = (user, showEmail = false) => {
  if (user) {
    if (user.get('removed')) {
      return I18n.text('users.removed');
    }
    if (user.get('gdprDeleted')) {
      return I18n.text('users.gdprDeleted');
    }
    const email = getEmail(user);
    const fullName = getFullName(user);
    if (fullName.length < 2) {
      return email;
    }
    return showEmail && email ? `${fullName} (${email})`.trim() : fullName;
  }
  return I18n.text('users.removed');
};
export const formatAsFullNameAndEmail = user => {
  const email = getEmail(user);
  const fullName = getFullName(user);
  return email ? `${fullName} (${email})`.trim() : fullName;
};
export const formatCreateAndOwnHubUserLabel = createAndOwnHubUser => {
  const firstName = createAndOwnHubUser.firstName;
  const lastName = createAndOwnHubUser.lastName;
  const email = createAndOwnHubUser.email;
  const fullName = formatName({
    firstName,
    lastName
  });
  return email ? `${fullName} (${email})`.trim() : fullName;
};