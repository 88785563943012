import { fetchFrameworkTypes } from './frameworkTypesApi';
import { deepFreeze } from '../cache/deepFreeze';
import { makeFrameworkTypesQuickFetchKey } from 'framework-data-schema-quick-fetch';
import { createPersistedAsyncCache } from '../cache/createPersistedAsyncCache';
import { Metrics } from '../metrics';
import { makeLoadFailed } from '../utils/makeLoadFailed';
const defaultAsyncFrameworkTypesRequestCache = createPersistedAsyncCache({
  cacheName: 'frameworkTypes',
  segmentKey: key => {
    const match = key.match(makeFrameworkTypesQuickFetchKey({
      portalId: '.*',
      family: '(.*)'
    }));
    return match && match[1] || null;
  }
});
export const makeFrameworkTypesClient = ({
  httpClient,
  requestCache = defaultAsyncFrameworkTypesRequestCache,
  toCacheKey = makeFrameworkTypesQuickFetchKey
}) => {
  const getForFamily = ({
    family
  }) => {
    const cachedValue = requestCache.readThrough({
      cacheKey: toCacheKey({
        family
      }),
      loadValue: () => fetchFrameworkTypes({
        family,
        httpClient
      }).then(deepFreeze)
    });
    return cachedValue === null ? makeLoadFailed() : cachedValue;
  };
  return Promise.resolve({
    /**
     * Prints debug info to the console.
     */
    debug: () => {
      requestCache.printDebug();
    },
    /**
     * Clears internal cache state
     *
     * @returns A promise which resolves when state is clear.
     */
    clearCache: async () => {
      await Promise.resolve(requestCache.clear());
    },
    /**
     * Gets all event type definitions in this portal.
     *
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to all event types, or null if the data could not be found.
     */
    getEvents: ({
      __isComposed = false
    } = {}) => {
      if (!__isComposed) {
        Metrics.counter('frameworkTypes.getEvents').increment();
      }
      return getForFamily({
        family: 'events'
      });
    },
    /**
     * Gets all object type definitions in this portal.
     *
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to all object types, or null if the data could not be found.
     */
    getObjects: ({
      __isComposed = false
    } = {}) => {
      if (!__isComposed) {
        Metrics.counter('frameworkTypes.getObjects').increment();
      }
      return getForFamily({
        family: 'objects'
      });
    }
  });
};