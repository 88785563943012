export const PROPERTIES_LIMIT = 30;
export const OBJECT_ID_PROPERTY = '__hs_event_native_object_id';
export const TIMESTAMP_PROPERTY = '__hs_event_native_timestamp';

// SMS
const SMS_CHANNEL_PROPERTY = 'hs_shortmessage_channel';
const SMS_LISTS_CONTAINING_PROPERTY = 'hs_shortmessage_lists_containing_recipient';
const SMS_LINK_TARGET_PROPERTY = 'hs_shortmessage_link_target_url';
const SMS_DELIVERED_PROPERTY = 'hs_shortmessage_delivered_id';
const SMS_DROP_REASON_PROPERTY = 'hs_shortmessage_drop_reason';
const SMS_DROPPED_ID_PROPERTY = 'hs_shortmessage_dropped_id';
const SMS_FAILURE_PROPERTY = 'hs_shortmessage_failure_reason';
const SMS_FAILED_ID_PROPERTY = 'hs_shortmessage_failed_id';
const SMS_SENT_ID = 'hs_shortmessage_sent_id';
export const FILTERED_SMS_PROPERTIES = [SMS_CHANNEL_PROPERTY, SMS_LISTS_CONTAINING_PROPERTY, SMS_LINK_TARGET_PROPERTY, SMS_DELIVERED_PROPERTY, SMS_DROP_REASON_PROPERTY, SMS_DROPPED_ID_PROPERTY, SMS_FAILURE_PROPERTY, SMS_FAILED_ID_PROPERTY, SMS_SENT_ID];
export const PROPERTY_BLOCKLIST = [OBJECT_ID_PROPERTY, ...FILTERED_SMS_PROPERTIES];