'use es6';

import { SERVICE_SUB_APPS } from '../sub-apps';
const CATEGORIES = {
  TEAM_PERFORMANCE: 'team_performance',
  CUSTOMER_SATISFACTION: 'customer_satisfaction',
  TICKET_REPLIES: 'ticket_replies'
};
export const CATEGORY_SUB_APPS = {
  [CATEGORIES.TEAM_PERFORMANCE]: [SERVICE_SUB_APPS.ticketTimeInPipeline, SERVICE_SUB_APPS.supportVolume, SERVICE_SUB_APPS.resolutionTime, SERVICE_SUB_APPS.queueTime],
  [CATEGORIES.CUSTOMER_SATISFACTION]: [SERVICE_SUB_APPS.postSupportCSAT],
  [CATEGORIES.TICKET_REPLIES]: [SERVICE_SUB_APPS.customerReplyTime, SERVICE_SUB_APPS.customerWaitTime, SERVICE_SUB_APPS.ownerReplyCount, SERVICE_SUB_APPS.ownerReplyTime, SERVICE_SUB_APPS.ticketReplyCount, SERVICE_SUB_APPS.timeOfDay]
};