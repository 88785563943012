'use es6';

import { Map as ImmutableMap } from 'immutable';
import http from 'hub-http/clients/apiClient';
import formatFeedback from 'reference-resolvers/formatters/formatFeedback';
const FEEDBACK_API = 'feedback/v1/submissions/';
export const createGetObjectsByIds = () => () => {
  // not implemented because formId needs to be provided
  // for this request, and the only input to this function
  // is an array of IDs
  throw new Error('Not implemented.');
};
export const getObjectsByIds = createGetObjectsByIds({
  httpClient: http
});
const mapResponse = ({
  hasMore,
  offset,
  total: count,
  results
}) => {
  return ImmutableMap({
    hasMore,
    offset: offset + results.length,
    count,
    results: formatFeedback(results)
  });
};
const fetchAllPaginated = (httpClient, query) => {
  return httpClient.post(`${FEEDBACK_API}all`, {
    data: query
  }).then(mapResponse);
};
const fetchBySurveyIdAndType = (httpClient, query) => {
  return httpClient.post(FEEDBACK_API, {
    data: query
  }).then(mapResponse);
};
export const createSearchObjects = ({
  httpClient
}) => query => {
  const specifiedIdAndType = query.has('surveyId') && query.has('surveyType');
  if (specifiedIdAndType) {
    return fetchBySurveyIdAndType(httpClient, query);
  }
  return fetchAllPaginated(httpClient, query);
};
export const searchObjects = createSearchObjects({
  httpClient: http
});