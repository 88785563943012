'use es6';

import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import { TEMPLATES } from 'reference-resolvers/constants/CacheKeys';
import { TEMPLATE } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { createFetchCrmObjectsSearchPage, fetchCrmObjectsSearchPage, createFetchCrmObjectsByIds, fetchCrmObjectsByIds } from 'reference-resolvers/api/CrmSearchByUniqueIdApi';
const TEMPLATE_ID_PROPERTY = 'hs_template_id';
const TEMPLATE_NAME_PROPERTY = 'hs_template_name';
export const createTemplatesReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: TEMPLATES,
  createFetchByIds: opts => createFetchCrmObjectsByIds(opts)(TEMPLATE, TEMPLATE_ID_PROPERTY, TEMPLATE_NAME_PROPERTY),
  createFetchSearchPage: opts => createFetchCrmObjectsSearchPage(opts)(TEMPLATE, TEMPLATE_ID_PROPERTY, TEMPLATE_NAME_PROPERTY),
  fetchByIds: fetchCrmObjectsByIds(TEMPLATE, TEMPLATE_ID_PROPERTY, TEMPLATE_NAME_PROPERTY),
  fetchSearchPage: fetchCrmObjectsSearchPage(TEMPLATE, TEMPLATE_ID_PROPERTY, TEMPLATE_NAME_PROPERTY)
}, options));
export default createTemplatesReferenceResolver();