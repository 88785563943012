'use es6';

import { OrderedSet } from 'immutable';
import { memoize } from './utils';

// low-cost memo, string -> short-array, not worried about memory implications
export const getClassNames = memoize(({
  className
}) => className && OrderedSet(className.split(' ')));
export const isNodeWithinClass = ({
  target,
  parentClasses
}) => {
  const targetSet = typeof classNames === 'string' ? OrderedSet.of(parentClasses) : OrderedSet(parentClasses);
  if (!target) {
    return false;
  }
  const classNameSet = getClassNames(target);
  return classNameSet && classNameSet.intersect(targetSet).isEmpty() === false ? true : isNodeWithinClass({
    target: target.parentElement,
    parentClasses
  });
};