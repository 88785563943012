/*eslint no-restricted-imports: 1 */

import { generatePath } from 'react-router-dom';
import { Failure, getValue, isSuccess, Success
// @ts-expect-error migrate upstream
} from 'dashboard-lib/private/monad/validation';
import { capture } from 'dashboard-lib/public/sentry/report-management-sentry';
import { CREATE_URL_FAILED } from 'dashboard-lib/public/sentry/sentry-event-id';
import { stringify } from 'hub-http/helpers/params';
import { Routes } from '../constants/route/DashboardRoute';
import { parseAsQueryMap } from './historyUtils';
import { APPLIED_ADHOC_FILTERS_PARAM, FILTER_SET_QUERY_PARAM, FREQUENCY_QUERY_PARAM, FREQUENCY_FISCAL_YEAR } from './dashboardFilters/dashboard-filter-query-params-util';
const safeGeneratePath = (path, params) => {
  try {
    return Success(generatePath(path, params));
  } catch (error) {
    capture(CREATE_URL_FAILED, {
      error,
      fingerprint: [CREATE_URL_FAILED]
    });
    return Failure(error);
  }
};

/**
 * @param  {string} path - the router path
 * @param  {object} [params={}]
 * @param  {object} [queryParams={}] key/value pairs of query params. Only strings supported (no arrays, objects, etc).
 */
export const route = (path, params = {}, queryParams = {}, appHistory = null) => {
  const maybePathname = safeGeneratePath(path, params);
  if (queryParams && typeof queryParams === 'object') {
    delete queryParams[APPLIED_ADHOC_FILTERS_PARAM];
    delete queryParams[FILTER_SET_QUERY_PARAM];
    delete queryParams[FREQUENCY_QUERY_PARAM];
    delete queryParams[FREQUENCY_FISCAL_YEAR];
  }
  if (appHistory) {
    if (isSuccess(maybePathname) && appHistory) {
      appHistory.push({
        pathname: getValue(maybePathname),
        search: stringify(queryParams)
      });
    }
  } else {
    console.error('dashboard-router.js: the history param is missing');
  }
};
export const routeToDashboard = (dashboardId, preserveQueryParams, appHistory = null) => {
  route(Routes.DASHBOARD, {
    dashboardId: Number(dashboardId)
  }, preserveQueryParams ? parseAsQueryMap(appHistory) : null, appHistory);
};