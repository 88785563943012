'use es6';

import { createAction } from 'redux-actions';
import always from 'transmute/always';
import pipe from 'transmute/pipe';
import { USER_ATTRIBUTE_FETCH_PENDING, USER_ATTRIBUTE_FETCH_SUCCESS, USER_ATTRIBUTE_FETCH_FAILURE, USER_ATTRIBUTE_UPDATE_PENDING, USER_ATTRIBUTE_UPDATE_SUCCESS, USER_ATTRIBUTE_UPDATE_FAILURE, USER_ATTRIBUTE_REMOVE_PENDING, USER_ATTRIBUTE_REMOVE_SUCCESS, USER_ATTRIBUTE_REMOVE_FAILURE, HUB_SETTINGS_UPDATE_PENDING, HUB_SETTINGS_UPDATE_SUCCESS, HUB_SETTINGS_UPDATE_FAILURE } from '../constants/SettingsActionTypes';
import * as HubSettingsDAO from '../data/HubSettingsDAO';
import * as UserAttributesDAO from '../data/UserAttributesDAO';
import createPromiseAction from '../lib/createPromiseAction';
import Raven from 'raven-js';
const logErrorPipe = func => pipe(error => {
  Raven.captureException(error);
}, func);
export const fetchUserAttributes = createPromiseAction(UserAttributesDAO.fetch, {
  pending: createAction(USER_ATTRIBUTE_FETCH_PENDING),
  success: createAction(USER_ATTRIBUTE_FETCH_SUCCESS, settings => {
    return {
      settings
    };
  }),
  failure: logErrorPipe(createAction(USER_ATTRIBUTE_FETCH_FAILURE))
});
export const setUserAttribute = (key, value = true) => createPromiseAction(UserAttributesDAO.save, {
  pending: createAction(USER_ATTRIBUTE_UPDATE_PENDING),
  success: createAction(USER_ATTRIBUTE_UPDATE_SUCCESS, always({
    key,
    value
  })),
  failure: logErrorPipe(createAction(USER_ATTRIBUTE_UPDATE_FAILURE))
})(key, value);
export const removeUserAttribute = key => createPromiseAction(UserAttributesDAO.remove, {
  pending: createAction(USER_ATTRIBUTE_REMOVE_PENDING),
  success: createAction(USER_ATTRIBUTE_REMOVE_SUCCESS, always(key)),
  failure: logErrorPipe(createAction(USER_ATTRIBUTE_REMOVE_FAILURE))
})(key);
export const setHubSetting = (key, value) => createPromiseAction(HubSettingsDAO.setSetting, {
  pending: createAction(HUB_SETTINGS_UPDATE_PENDING),
  success: createAction(HUB_SETTINGS_UPDATE_SUCCESS, always({
    key,
    value
  })),
  failure: logErrorPipe(createAction(HUB_SETTINGS_UPDATE_FAILURE))
});