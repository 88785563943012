import { useMemo, useState } from 'react';
import { MIN_SIDEBAR_WIDTH } from '../../../constants/sidebar';
import debounce from '../../../utils/debounce';
const POPOVER_WIDTH = 380;
const INDICATOR_HEIGHT = 30;
export function useEmbeddedCommentHandlers({
  commentableArea,
  popoverPlacement,
  verticalPositioning,
  embeddedCommentWrapper,
  onUpdateCollaborationSidebarState
}) {
  const [placement, setPlacement] = useState(popoverPlacement || 'top');
  const [topOffset, setTopOffset] = useState(0);
  const [leftOffset, setLeftOffset] = useState(0);
  const updateIndicatorPlacement = useMemo(() => debounce(() => {
    if (!(commentableArea instanceof HTMLElement)) {
      return;
    }
    // Get the height offset to position the comment indicator.
    let elementHeight = commentableArea.offsetHeight;
    if (commentableArea.children && commentableArea.children[0]) {
      const computedStyles = getComputedStyle(commentableArea.children[0]);
      elementHeight += parseInt(computedStyles.getPropertyValue('margin-bottom'), 10);
    }
    const defaultOffset = -INDICATOR_HEIGHT - 2;
    const horizontalTopOffset = elementHeight < 50 ? (elementHeight - INDICATOR_HEIGHT) / 2 : 10;
    setTopOffset(verticalPositioning ? defaultOffset : horizontalTopOffset);
    setLeftOffset(verticalPositioning ? 10 : defaultOffset);
    const {
      x
    } = commentableArea.getBoundingClientRect();

    // If popover will overflow, set placement
    if (x < POPOVER_WIDTH / 2 + MIN_SIDEBAR_WIDTH && !popoverPlacement) {
      setPlacement('top right');
    }
  }, 0), [commentableArea, verticalPositioning, popoverPlacement]);
  const updateEmbeddedCommentCoordinates = useMemo(() => debounce(() => {
    if (embeddedCommentWrapper) {
      const embeddedCommentRect = embeddedCommentWrapper.getBoundingClientRect();
      let top = Math.max(embeddedCommentRect.top, 0);
      let left = Math.max(embeddedCommentRect.left, 0);
      if (top > window.innerHeight) {
        top = window.innerHeight - embeddedCommentRect.height;
      }
      if (left > window.innerWidth) {
        left = window.innerWidth - embeddedCommentRect.width;
      }
      onUpdateCollaborationSidebarState({
        embeddedCommentCoordinates: {
          top,
          left,
          width: embeddedCommentRect.width,
          height: embeddedCommentRect.height
        }
      });
    }
  }, 50), [onUpdateCollaborationSidebarState, embeddedCommentWrapper]);
  return {
    updateIndicatorPlacement,
    updateEmbeddedCommentCoordinates,
    placement,
    topOffset,
    leftOffset
  };
}