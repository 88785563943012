'use es6';

import I18n from 'I18n';
import { Map as ImmutableMap } from 'immutable';
import chunk from '../../lib/async/chunk';
import toJS from '../../lib/toJS';
import * as http from '../../request/http';
import { validNumerical } from '../ids';
import { makeOption } from '../Option';
import { generateUserLabel } from '../user';
const batch = ids => chunk(group => http.post(`owners/v2/owners/batch-get/remotes/HUBSPOT`, {
  data: group
}).then(toJS), responses => responses.reduce((memo, response) => Object.assign({}, memo, response), {}), ids);
const remote = ids => batch(validNumerical(ids)).then(remotes => {
  const res = Object.keys(remotes).reduce((options, remoteId) => {
    const optionLabel = generateUserLabel(remotes[remoteId] && remotes[remoteId].isActive ? remotes[remoteId] : undefined, remoteId);
    return options.set(String(remoteId), makeOption(remoteId, optionLabel));
  }, ImmutableMap());
  return res;
});
export default remote;
export const getRemotes = ids => remote(ids).then(options => {
  return options.reduce((breakdowns, option) => Object.assign({}, breakdowns, {
    [option.get('value')]: option.get('label')
  }), {
    '-1': I18n.text('reporting-data.properties.sales-templates.rootUser')
  });
});