import invariant from 'react-utils/invariant';
import { NOT_STARTED, DONE } from '../constants/TourStates';
export default class ProgressStore {
  constructor() {
    this.subscribe = callback => {
      this.subscriptions.add(callback);
      return () => this.unsubscribe(callback);
    };
    this.unsubscribe = callback => {
      this.subscriptions.delete(callback);
    };
    this.notify = (step, tour, state) => {
      this.subscriptions.forEach(callback => {
        callback(step, tour, state);
      });
    };
    this.activateTour = tour => {
      this.subscriptions.forEach(callback => {
        callback(this.getStep(), tour);
      });
    };
    this.deactivate = () => {
      this.notify(null, null);
    };
    this.updateProgress = (step, tour) => {
      this.progress.push(step);
      this.notify(step, tour);
      if (step === DONE) {
        this.progress = [];
      }
      return step;
    };
    this.getStep = () => {
      return this.progress[this.progress.length - 1] || NOT_STARTED;
    };
    this.canGoBack = () => {
      return this.progress.length > 1;
    };
    this.goBack = tour => {
      const poppedPoint = this.progress.pop();
      invariant(typeof poppedPoint !== 'undefined', 'can not go back with no history');
      const lastStep = this.progress[this.progress.length - 1];
      this.notify(lastStep, tour);
      return lastStep;
    };
    this.subscriptions = new Set();
    this.progress = [];
  }
}