'use es6';

import { DASHBOARD_COUNT_FIELD_NAME, FETCH_DASHBOARD_COUNT } from '../requests/dashboards';
import usePollingQuery from './usePollingQuery';
export const useDashboardCount = () => {
  const {
    data,
    error,
    loading
  } = usePollingQuery(FETCH_DASHBOARD_COUNT);
  return {
    data: data ? data[DASHBOARD_COUNT_FIELD_NAME] : undefined,
    error,
    loading
  };
};