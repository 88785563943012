'use es6';

import http from 'hub-http/clients/apiClient';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import get from 'transmute/get';
import formatCtas from 'reference-resolvers/formatters/formatCtas';
const BASE_URL = '/ctas/v3/placements';
export const createGetCTASByIds = ({
  httpClient
}) => ids => httpClient.get(`${BASE_URL}/batch`, {
  query: {
    includeCtaInfo: true,
    guid: ids
  }
}).then(formatToReferencesList({
  getId: get('guid'),
  getLabel: get('name')
}));
export const getCTASByIds = createGetCTASByIds({
  httpClient: http
});
export const createGetCTAPage = ({
  httpClient
}) => query => {
  const parsedQuery = Object.assign({}, query.toJS(), {
    limit: query.get('count'),
    offset: query.get('offset'),
    name__contains: query.get('query'),
    includeCtaInfo: true
  });
  return httpClient.get(BASE_URL, {
    query: parsedQuery
  }).then(formatCtas);
};
export const getCTAPage = createGetCTAPage({
  httpClient: http
});