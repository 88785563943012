'use es6';

import { useCallback, useReducer } from 'react';
import { Set as ImmutableSet, OrderedSet } from 'immutable';
const SELECT_USERS = 'SELECT_USERS';
const DESELECT_USERS = 'DESELECT_USERS';
const SET_SELECTED_USERS = 'SET_SELECTED_USERS';
const userSelectReducer = (state, action) => {
  switch (action.type) {
    case SELECT_USERS:
      return state.union(action.users);
    case DESELECT_USERS:
      return state.subtract(action.users);
    case SET_SELECTED_USERS:
      return action.users;
    default:
      return state;
  }
};
export const useUserSelectState = initialUserSet => {
  const [selectedUserSet, dispatch] = useReducer(userSelectReducer, OrderedSet(initialUserSet));
  const selectUser = useCallback(userId => {
    dispatch({
      type: SELECT_USERS,
      users: ImmutableSet([userId])
    });
  }, []);
  const deselectUser = useCallback(userId => {
    dispatch({
      type: DESELECT_USERS,
      users: ImmutableSet([userId])
    });
  }, []);
  const setSelectedUsers = useCallback(userIds => {
    dispatch({
      type: SET_SELECTED_USERS,
      users: OrderedSet(userIds)
    });
  }, []);
  return {
    selectedUserSet,
    selectUser,
    deselectUser,
    setSelectedUsers
  };
};