'use es6';

import getIn from 'transmute/getIn';
import { AD_OBJECT_AD, AD_OBJECT_AD_ACCOUNT, AD_OBJECT_AD_CAMPAIGN, AD_OBJECT_AD_GROUP, AD_OBJECT_KEYWORD, ACTIVITY_TYPE, ANALYTICS_VIEW, BLOG_AUTHOR, BLOG_TAG, BLOGS, BUSINESS_UNIT, CALL_DISPOSITION, CAMPAIGN, CHATFLOW, COMPANIES_SAVED_FILTERS, COMPANY_PROPERTY, COMPANY, CONTACT_LIST, CONTACT_PROPERTY, CONTACT, CONTACTS_SAVED_FILTERS, CONTENT, CONVERSATION_INBOX, CTA, DEAL_PIPELINE_STAGE, DEAL_PIPELINE, DEAL_PROPERTY, DEAL, DEALS_SAVED_FILTERS, ENGAGEMENT_PROPERTY, ENGAGEMENT, EVENT, FEEDBACK_FORM, FEEDBACK, FORM, INBOUND_DB_COMPANY_IMPORT, INBOUND_DB_COMPANY_LIST, INBOUND_DB_CONTACT_IMPORT, INBOUND_DB_CONTACT_LIST, INBOUND_DB_DEAL_IMPORT, INBOUND_DB_DEAL_LIST, INBOUND_DB_TICKET_LIST, LINE_ITEM_PROPERTY, LINE_ITEM, MULTI_CURRENCY_CURRENCY_CODE, MULTI_CURRENCY_INFORMATION, MEETING_OUTCOME, OWNER, PERSONA, PRODUCT_PROPERTY, PRODUCT, SALESFORCE_CAMPAIGN, SEQUENCE, SEQUENCE_STEP_ORDER, SUBDOMAIN_ID, SUBDOMAIN, TEAM, TEMPLATE, TICKET_PIPELINE, TICKET_PROPERTY, TICKET_STAGE, TICKET, USER, WORKFLOW, TASK_QUEUE, MARKETING_EVENT, EMAIL, CTA_GUID, INBOUND_DB_LISTS, WEB_INTERACTIVE } from 'reference-resolvers/constants/ReferenceObjectTypes';
import AdsAdsReferenceResolver from 'reference-resolvers/resolvers/AdsAdsReferenceResolver';
import AdsAdAccountsReferenceResolver from 'reference-resolvers/resolvers/AdsAdAccountsReferenceResolver';
import AdsAdgroupsReferenceResolver from 'reference-resolvers/resolvers/AdsAdgroupsReferenceResolver';
import AdsCampaignsReferenceResolver from 'reference-resolvers/resolvers/AdsCampaignsReferenceResolver';
import AdsKeywordsReferenceResolver from 'reference-resolvers/resolvers/AdsKeywordsReferenceResolver';
import ActivityTypeReferenceResolver from 'reference-resolvers/resolvers/ActivityTypeReferenceResolver';
import AnalyticsViewsReferenceResolver from 'reference-resolvers/resolvers/AnalyticsViewsReferenceResolver';
import BlogAuthorReferenceResolver from 'reference-resolvers/resolvers/BlogAuthorReferenceResolver';
import BlogReferenceResolver from 'reference-resolvers/resolvers/BlogReferenceResolver';
import BlogTagReferenceResolver from 'reference-resolvers/resolvers/BlogTagReferenceResolver';
import BusinessUnitReferenceResolver from 'reference-resolvers/resolvers/BusinessUnitReferenceResolver';
import CallDispositionReferenceResolver from 'reference-resolvers/resolvers/CallDispositionReferenceResolver';
import CampaignReferenceResolver from 'reference-resolvers/resolvers/CampaignReferenceResolver';
import ChatflowsReferenceResolver from 'reference-resolvers/resolvers/ChatflowsReferenceResolver';
import CompaniesSavedFiltersReferenceResolver from 'reference-resolvers/resolvers/CompaniesSavedFiltersReferenceResolver';
import CompanyPropertyReferenceResolver from 'reference-resolvers/resolvers/CompanyPropertyReferenceResolver';
import CompanyReferenceResolver from 'reference-resolvers/resolvers/CompanyReferenceResolver';
import ContactListReferenceResolver from 'reference-resolvers/resolvers/ContactListReferenceResolver';
import ContactPropertyReferenceResolver from 'reference-resolvers/resolvers/ContactPropertyReferenceResolver';
import ContactReferenceResolver from 'reference-resolvers/resolvers/ContactReferenceResolver';
import ContactsSavedFiltersReferenceResolver from 'reference-resolvers/resolvers/ContactsSavedFiltersReferenceResolver';
import ContentReferenceResolver from 'reference-resolvers/resolvers/ContentReferenceResolver';
import ConversationInboxReferenceResolver from 'reference-resolvers/resolvers/ConversationInboxReferenceResolver';
import CTAReferenceResolver from 'reference-resolvers/resolvers/CTAReferenceResolver';
import CTAGuidReferenceResolver from 'reference-resolvers/resolvers/CTAGuidReferenceResolver';
import DealPropertyReferenceResolver from 'reference-resolvers/resolvers/DealPropertyReferenceResolver';
import DealReferenceResolver from 'reference-resolvers/resolvers/DealReferenceResolver';
import DealsSavedFiltersReferenceResolver from 'reference-resolvers/resolvers/DealsSavedFiltersReferenceResolver';
import EmailReferenceResolver from 'reference-resolvers/resolvers/EmailReferenceResolver';
import EngagementPropertyReferenceResolver from 'reference-resolvers/resolvers/EngagementPropertyReferenceResolver';
import EngagementReferenceResolver from 'reference-resolvers/resolvers/EngagementReferenceResolver';
import EventReferenceResolver from 'reference-resolvers/resolvers/EventReferenceResolver';
import FeedbackFormReferenceResolver from 'reference-resolvers/resolvers/FeedbackFormReferenceResolver';
import FeedbackReferenceResolver from 'reference-resolvers/resolvers/FeedbackReferenceResolver';
import FormReferenceResolver from 'reference-resolvers/resolvers/FormBatchedReferenceResolver';
import FormBatchedReferenceResolver from 'reference-resolvers/resolvers/FormBatchedReferenceResolver';
import HierarchicalTeamReferenceResolver from 'reference-resolvers/resolvers/HierarchicalTeamReferenceResolver';
import InboundDbCompanyImportReferenceResolver from 'reference-resolvers/resolvers/InboundDbCompanyImportReferenceResolver';
import InboundDbCompanyListReferenceResolver from 'reference-resolvers/resolvers/InboundDbCompanyListReferenceResolver';
import InboundDbContactImportReferenceResolver from 'reference-resolvers/resolvers/InboundDbContactImportReferenceResolver';
import InboundDbContactListReferenceResolver from 'reference-resolvers/resolvers/InboundDbContactListReferenceResolver';
import InboundDbDealImportReferenceResolver from 'reference-resolvers/resolvers/InboundDbDealImportReferenceResolver';
import InboundDbDealListReferenceResolver from 'reference-resolvers/resolvers/InboundDbDealListReferenceResolver';
import InboundDbTicketListReferenceResolver from 'reference-resolvers/resolvers/InboundDbTicketListReferenceResolver';
import InboundDbListsReferenceResolver from 'reference-resolvers/resolvers/InboundDbListsReferenceResolver';
import LineItemPropertyReferenceResolver from 'reference-resolvers/resolvers/LineItemPropertyReferenceResolver';
import LineItemReferenceResolver from 'reference-resolvers/resolvers/LineItemReferenceResolver';
import MultiCurrencyCurrencyCodeResolver from 'reference-resolvers/resolvers/MultiCurrencyCurrencyCodeResolver';
import MultiCurrencyInformationResolver from 'reference-resolvers/resolvers/MultiCurrencyInformationResolver';
import MeetingOutcomeReferenceResolver from 'reference-resolvers/resolvers/MeetingOutcomeReferenceResolver';
import OwnerPagedReferenceResolver from 'reference-resolvers/resolvers/OwnerPagedReferenceResolver';
import PersonaReferenceResolver from 'reference-resolvers/resolvers/PersonaReferenceResolver';
import PipelineReferenceResolver from 'reference-resolvers/resolvers/PipelineReferenceResolver';
import PipelineStageReferenceResolver from 'reference-resolvers/resolvers/PipelineStageReferenceResolver';
import ProductPropertyReferenceResolver from 'reference-resolvers/resolvers/ProductPropertyReferenceResolver';
import ProductReferenceResolver from 'reference-resolvers/resolvers/ProductReferenceResolver';
import SalesforceCampaignReferenceResolver from 'reference-resolvers/resolvers/SalesforceCampaignReferenceResolver';
import SequencesReferenceResolver from 'reference-resolvers/resolvers/SequencesReferenceResolver';
import SequenceStepOrderReferenceResolver from 'reference-resolvers/resolvers/SequenceStepOrderReferenceResolver';
import SubdomainIdReferenceResolver from 'reference-resolvers/resolvers/SubdomainIdReferenceResolver';
import SubdomainReferenceResolver from 'reference-resolvers/resolvers/SubdomainReferenceResolver';
import TaskQueuesReferenceResolver from 'reference-resolvers/resolvers/TaskQueuesReferenceResolver';
import TemplatesReferenceResolver from 'reference-resolvers/resolvers/TemplatesReferenceResolver';
import TicketPipelinesReferenceResolver from 'reference-resolvers/resolvers/TicketPipelinesReferenceResolver';
import TicketPropertyReferenceResolver from 'reference-resolvers/resolvers/TicketPropertyReferenceResolver';
import TicketReferenceResolver from 'reference-resolvers/resolvers/TicketReferenceResolver';
import TicketStagesReferenceResolver from 'reference-resolvers/resolvers/TicketStagesReferenceResolver';
import UserReferenceResolver from 'reference-resolvers/resolvers/UserReferenceResolver';
import WorkflowReferenceResolver from 'reference-resolvers/resolvers/WorkflowReferenceResolver';
import ObjectCoordinatesReferenceResolver from 'reference-resolvers/resolvers/ObjectCoordinatesReferenceResolver';
const objectTypeIdToOptions = {
  ['0-54']: {
    formatterOptions: {
      getters: {
        getLabel: getIn(['properties', 'hs_event_name', 'value'])
      }
    }
  },
  ['0-91']: {
    formatterOptions: {
      getters: {
        getLabel: getIn(['properties', 'hs_name', 'value'])
      }
    }
  }
};
const getResolverForObject = objectTypeId => {
  const options = objectTypeIdToOptions[objectTypeId] || {};
  return ObjectCoordinatesReferenceResolver(objectTypeId, options);
};
const BATCHED_FORM_RESOLVER = 'BATCHEDFORM';
export const reportingReferenceResolvers = {
  [AD_OBJECT_AD]: AdsAdsReferenceResolver,
  [AD_OBJECT_AD_ACCOUNT]: AdsAdAccountsReferenceResolver,
  [AD_OBJECT_AD_CAMPAIGN]: AdsCampaignsReferenceResolver,
  [AD_OBJECT_AD_GROUP]: AdsAdgroupsReferenceResolver,
  [AD_OBJECT_KEYWORD]: AdsKeywordsReferenceResolver,
  [ACTIVITY_TYPE]: ActivityTypeReferenceResolver,
  [ANALYTICS_VIEW]: AnalyticsViewsReferenceResolver,
  [BLOG_AUTHOR]: BlogAuthorReferenceResolver,
  [BLOG_TAG]: BlogTagReferenceResolver,
  [BUSINESS_UNIT]: BusinessUnitReferenceResolver,
  [BLOGS]: BlogReferenceResolver,
  [CALL_DISPOSITION]: CallDispositionReferenceResolver,
  [CAMPAIGN]: CampaignReferenceResolver,
  [CHATFLOW]: ChatflowsReferenceResolver,
  [COMPANIES_SAVED_FILTERS]: CompaniesSavedFiltersReferenceResolver,
  [COMPANY_PROPERTY]: CompanyPropertyReferenceResolver,
  [COMPANY]: CompanyReferenceResolver,
  [CONTACT_LIST]: ContactListReferenceResolver,
  [CONTACT_PROPERTY]: ContactPropertyReferenceResolver,
  [CONTACT]: ContactReferenceResolver,
  [CONTACTS_SAVED_FILTERS]: ContactsSavedFiltersReferenceResolver,
  [CONTENT]: ContentReferenceResolver,
  [CONVERSATION_INBOX]: ConversationInboxReferenceResolver,
  [CTA]: CTAReferenceResolver,
  [CTA_GUID]: CTAGuidReferenceResolver,
  [DEAL_PIPELINE_STAGE]: PipelineStageReferenceResolver,
  [DEAL_PIPELINE]: PipelineReferenceResolver,
  [DEAL_PROPERTY]: DealPropertyReferenceResolver,
  [DEAL]: DealReferenceResolver,
  [DEALS_SAVED_FILTERS]: DealsSavedFiltersReferenceResolver,
  [EMAIL]: EmailReferenceResolver,
  [ENGAGEMENT_PROPERTY]: EngagementPropertyReferenceResolver,
  [ENGAGEMENT]: EngagementReferenceResolver,
  [EVENT]: EventReferenceResolver,
  [FEEDBACK_FORM]: FeedbackFormReferenceResolver,
  [FEEDBACK]: FeedbackReferenceResolver,
  [FORM]: FormReferenceResolver,
  [BATCHED_FORM_RESOLVER]: FormBatchedReferenceResolver,
  [INBOUND_DB_COMPANY_IMPORT]: InboundDbCompanyImportReferenceResolver,
  [INBOUND_DB_COMPANY_LIST]: InboundDbCompanyListReferenceResolver,
  [INBOUND_DB_CONTACT_IMPORT]: InboundDbContactImportReferenceResolver,
  [INBOUND_DB_CONTACT_LIST]: InboundDbContactListReferenceResolver,
  [INBOUND_DB_DEAL_IMPORT]: InboundDbDealImportReferenceResolver,
  [INBOUND_DB_DEAL_LIST]: InboundDbDealListReferenceResolver,
  [INBOUND_DB_TICKET_LIST]: InboundDbTicketListReferenceResolver,
  [INBOUND_DB_LISTS]: InboundDbListsReferenceResolver,
  [LINE_ITEM_PROPERTY]: LineItemPropertyReferenceResolver,
  [LINE_ITEM]: LineItemReferenceResolver,
  [MULTI_CURRENCY_CURRENCY_CODE]: MultiCurrencyCurrencyCodeResolver,
  [MULTI_CURRENCY_INFORMATION]: MultiCurrencyInformationResolver,
  [MEETING_OUTCOME]: MeetingOutcomeReferenceResolver,
  [OWNER]: OwnerPagedReferenceResolver,
  [PERSONA]: PersonaReferenceResolver,
  [PRODUCT_PROPERTY]: ProductPropertyReferenceResolver,
  [PRODUCT]: ProductReferenceResolver,
  [SALESFORCE_CAMPAIGN]: SalesforceCampaignReferenceResolver,
  [SEQUENCE]: SequencesReferenceResolver,
  [SEQUENCE_STEP_ORDER]: SequenceStepOrderReferenceResolver,
  [SUBDOMAIN_ID]: SubdomainIdReferenceResolver,
  [SUBDOMAIN]: SubdomainReferenceResolver,
  [TEAM]: HierarchicalTeamReferenceResolver,
  [TEMPLATE]: TemplatesReferenceResolver,
  [TICKET_PIPELINE]: TicketPipelinesReferenceResolver,
  [TICKET_PROPERTY]: TicketPropertyReferenceResolver,
  [TICKET_STAGE]: TicketStagesReferenceResolver,
  [TICKET]: TicketReferenceResolver,
  [USER]: UserReferenceResolver,
  [WORKFLOW]: WorkflowReferenceResolver,
  [TASK_QUEUE]: TaskQueuesReferenceResolver,
  [MARKETING_EVENT]: getResolverForObject('0-54'),
  [WEB_INTERACTIVE]: getResolverForObject('0-91')
};