'use es6';

import { createAction } from 'redux-actions';
import compose from 'transmute/compose';
import curry from 'transmute/curry';
import get from 'transmute/get';
import { createAsyncActions } from './request-action';
const API_ACTION = 'API';
const API_ACTION_CACHED = 'API_CACHED';
export const isApiAction = action => {
  const type = get('type', action);
  return type === API_ACTION || type === API_ACTION_CACHED;
};
export const isCachedAction = action => {
  const type = get('type', action);
  return type === API_ACTION_CACHED;
};
export const getRequestParams = compose(get('requestParams'), get('meta'));
const createRequestActionHelper = curry((asyncActions, requestFunction) => {
  return createAction(API_ACTION, requestParams => ({
    requestFunction,
    requestParams
  }), () => ({
    asyncActions
  }));
});

/**
 * Create a request action without supplying a requestFunction
 * @param {string} prefix of the action type
 * @return {function} partially applied function (with .actions so the reducers can handle the callback actions).
 */
export const createRequestActionWithoutRequest = prefix => {
  const asyncActions = createAsyncActions(prefix);
  const intermediate = createRequestActionHelper(asyncActions);
  intermediate.actions = asyncActions;
  return intermediate;
};

/**
 * Creates a request action.
 * @param {string} prefix of the action type
 * @param {function} requestFunction
 * @param {object} options - optional parameters
 * @param {Function} [options.createFailMetadata] metadata creator for the fail action
 * @param {Function} [options.createSucceedMetadata] metadata creator for the succeed action
 * @return action creator
 */
export const createRequestAction = (prefix, requestFunction, options = {}) => {
  const asyncActions = createAsyncActions(prefix, options);
  const requestAction = createRequestActionHelper(asyncActions, requestFunction);
  requestAction.actions = asyncActions;
  return requestAction;
};
export const createRequestActionEnsure = (prefix, createResourceSelector, requestFunction) => {
  return createAction(API_ACTION_CACHED, requestParams => ({
    requestFunction,
    requestParams
  }), () => ({
    asyncActions: createAsyncActions(prefix),
    createResourceSelector
  }));
};