import { useSavedDashboardFiltersPanelContext } from '../context';
import { convertToDashboardFilter } from '../../../../../../lib/dashboardFilters/filterConversionHelpers';
// @ts-expect-error migrate upstream
import { useUserRoleForDashboard } from 'reporting-action-components/utils/trackingUtils';
import { trackFilterInteraction
// @ts-expect-error migrate upstream
} from 'dashboard-ui-components/tracking/DashboardUITracker';
export const useAddOrUpdateDraftFilter = () => {
  const {
    editingFilterIdx,
    toEditFilterSetFilterListView,
    draftFilterSetFilters,
    onDraftFilterSetFiltersChanged,
    dashboard,
    dashboardId
  } = useSavedDashboardFiltersPanelContext();
  const userRole = useUserRoleForDashboard(dashboard);
  const handleAddOrUpdateFilter = params => {
    const {
      dataSource,
      dataSourceName,
      operator,
      location,
      property
    } = params;
    const filterData = {
      dataSource,
      dataSourceName,
      operator,
      property
    };
    const nextFilter = convertToDashboardFilter(filterData);
    const draftFilterClone = [...draftFilterSetFilters];
    if (editingFilterIdx != null) {
      draftFilterClone[editingFilterIdx] = nextFilter;
      trackFilterInteraction({
        action: 'edit-filter',
        filterData,
        userRole,
        additionalProps: {
          interactionSource: 'edit-filter-set',
          dashboardId
        }
      });
    } else {
      draftFilterClone.push(nextFilter);
      trackFilterInteraction({
        action: 'add-filter',
        filterData,
        userRole,
        additionalProps: {
          category: location,
          interactionSource: 'edit-filter-set',
          dashboardId
        }
      });
    }
    onDraftFilterSetFiltersChanged(draftFilterClone);
    toEditFilterSetFilterListView();
  };
  return {
    onAddOrUpdateFilter: handleAddOrUpdateFilter
  };
};