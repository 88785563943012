'use es6';

import curry from 'transmute/curry';
import { AssetPermission } from '../../../../public/permission/asset-permission';
import { hasSufficientPermission } from '../../../../public/permission/compute-permission';
import { anyNumberIsLoading } from '../../common/any-number-is-loading';
import { getAndCheck } from '../../common/get-and-check-param';
import { getAllProblems } from '../../common/get-problems-no-check';
import { createProblem } from '../../common/problem';
import { CUSTOM_DASHBOARD_COUNT, CUSTOM_DASHBOARD_LIMIT, DASHBOARD_IS_LOADING, IS_READ_ONLY_DEMO, USER_DASHBOARD_PERMISSION } from '../../params/all-params';
import { NEED_CREATE_AND_OWN_PERMISSION } from '../../problems/permission-problem';
import { CUSTOM_DASHBOARD_LIMIT_EXCEEDED_AND_DASHBOARD_SHOULD_LOCKDOWN } from 'dashboard-lib/private/action-problem-solution/problems/lockdown-problem';
const _getAndCheck = curry(getAndCheck);
export const LOADING = createProblem('LOADING', params => {
  const customDashboardCount = getAndCheck(CUSTOM_DASHBOARD_COUNT, params);
  const customDashboardLimit = getAndCheck(CUSTOM_DASHBOARD_LIMIT, params);
  const dashboardLoading = getAndCheck(DASHBOARD_IS_LOADING, params);
  return dashboardLoading || anyNumberIsLoading(customDashboardCount, customDashboardLimit);
});
export const PERMISSION_DENIED = createProblem('PERMISSION_DENIED', params => {
  return !hasSufficientPermission(AssetPermission.CREATE_AND_OWN, getAndCheck(USER_DASHBOARD_PERMISSION, params));
});
export const DASHBOARD_IS_READ_ONLY_DEMO = createProblem('DASHBOARD_IS_READ_ONLY_DEMO', _getAndCheck(IS_READ_ONLY_DEMO));
export const rankedProblems = [LOADING, NEED_CREATE_AND_OWN_PERMISSION, PERMISSION_DENIED, DASHBOARD_IS_READ_ONLY_DEMO, CUSTOM_DASHBOARD_LIMIT_EXCEEDED_AND_DASHBOARD_SHOULD_LOCKDOWN];
export const getAll = params => getAllProblems(params, rankedProblems);