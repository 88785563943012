import { CURRENT_ENV } from './Env';

// Apps for testing
export const INTEGRATIONS_DEMO = {
  NETSUITE_DEMO: 'NETSUITE_DEMO'
  // DEVHUBTESTAPP: 'DEVHUBTESTAPP',
  // DEMO_APP: 'DEMO_APP',
};

// DataSync Apps
export const INTEGRATIONS_DATA_SYNC = {
  MSDYNAMICS: 'MSDYNAMICS',
  NETSUITE: 'NETSUITE',
  SALESLOFT: 'SALESLOFT',
  ZOHOCRM: 'ZOHOCRM',
  SHOPIFY_DATA_SYNC: 'SHOPIFY_DATA_SYNC',
  QUICKBOOKS_DATA_SYNC: 'QUICKBOOKS_DATA_SYNC',
  SALESFORCE_DATA_SYNC: 'SALESFORCE_DATA_SYNC'
};

// Apps
export const INTEGRATIONS = {
  SALESFORCE: 'SALESFORCE',
  SLACK: 'SLACK',
  MS_TEAMS: 'MS_TEAMS',
  SHOPIFY: 'SHOPIFY',
  QUICKBOOKS: 'QUICKBOOKS',
  DOCUSIGN: 'DOCUSIGN',
  ADOBE_SIGN: 'ADOBE_SIGN'
  // ACCEL_EVENTS: 'ACCEL_EVENTS',
  // AIRMEET: 'AIRMEET',
  // ASANA: 'ASANA',
  // AIRTABLE_INTEGRATION: 'AIRTABLE_INTEGRATION',
  // BEVY: 'BEVY',
  // CED_COMMERCE: 'CED_COMMERCE',
  // EVENT_REGIST: 'EVENT_REGIST',
  // EVENTBRITE: 'EVENTBRITE',
  // E_WEBINAR: 'E_WEBINAR',
  // FACEBOOK_WORKPLACE: 'FACEBOOK_WORKPLACE',
  // FACEBOOK_MESSENGER: 'FACEBOOK_MESSENGER',
  // GOOGLE_ASSISTANT: 'GOOGLE_ASSISTANT',
  // GOOGLE_CALENDAR: 'GOOGLE_CALENDAR',
  // GOOGLE_SEARCH_CONSOLE: 'GOOGLE_SEARCH_CONSOLE',
  // GOOGLE_MEET: 'GOOGLE_MEET',
  // GOOGLE_SHEETS: 'GOOGLE_SHEETS',
  // GO_TO_WEBINAR: 'GO_TO_WEBINAR',
  // HOPIN: 'HOPIN',
  // JIRA: 'JIRA',
  // LINKED_IN: 'LINKED_IN',
  // MAILCHIMP: 'MAILCHIMP',
  // MAKE_WEB_BETTER: 'MAKE_WEB_BETTER',
  // OFFICE365: 'OFFICE365',
  // ON_24: 'ON_24',
  // ONE_SIGNAL: 'ONE_SIGNAL',
  // PRESTASHOP: 'PRESTASHOP',
  // SANSAN: 'SANSAN',
  // STRIPE: 'STRIPE',
  // SURVEY_MONKEY: 'SURVEY_MONKEY',
  // TODOIST: 'TODOIST',
  // TEACHABLE: 'TEACHABLE',
  // TRELLO: 'TRELLO',
  // UBER_CONFERENCE: 'UBER_CONFERENCE',
  // UNIFIC: 'UNIFIC',
  // WORDPRESS: 'WORDPRESS',
  // ZOOM: 'ZOOM',
  // OKTA_SCIM: 'OKTA_SCIM',
  // INWINK: 'INWINK',
  // GOLDCAST_EVENTS: 'GOLDCAST_EVENTS',
  // HUMANITIX: 'HUMANITIX',
  // WEBINARGEEK: 'WEBINARGEEK',
  // ICAPTURE: 'ICAPTURE',
  // TWENTYTHREE: 'TWENTYTHREE',
  // CVENT: 'CVENT',
  // LIVEWEBINAR: 'LIVEWEBINAR',
};
export const INTEGRATIONS_APPS = Object.assign({}, INTEGRATIONS, INTEGRATIONS_DATA_SYNC, INTEGRATIONS_DEMO);
// TODO: add offering ID to IntegrationsAppsData model
export const INTEGRATIONS_APPS_DATA = {
  [INTEGRATIONS_APPS.MSDYNAMICS]: {
    displayName: 'Microsoft Dynamics',
    appIds: {
      QA: 1208252,
      PROD: 235999
    }
  },
  [INTEGRATIONS_APPS.NETSUITE]: {
    displayName: 'NetSuite',
    appIds: {
      QA: 1204058,
      PROD: 226318
    }
  },
  [INTEGRATIONS_APPS.SALESLOFT]: {
    displayName: 'SalesLoft',
    appIds: {
      QA: 1208255,
      PROD: 236003
    }
  },
  [INTEGRATIONS_APPS.ZOHOCRM]: {
    displayName: 'Zoho CRM',
    appIds: {
      QA: 1208260,
      PROD: 236009
    }
  },
  [INTEGRATIONS_APPS.SALESFORCE]: {
    displayName: 'Salesforce',
    appIds: {
      QA: 1160722,
      PROD: 38577
    }
  },
  [INTEGRATIONS_APPS.SALESFORCE_DATA_SYNC]: {
    displayName: 'Salesforce',
    appIds: {
      QA: 5840412,
      PROD: 2007421
    }
  },
  [INTEGRATIONS_APPS.SLACK]: {
    displayName: 'Slack',
    appIds: {
      QA: 1162214,
      PROD: 60729
    }
  },
  [INTEGRATIONS_APPS.MS_TEAMS]: {
    displayName: 'Microsoft Teams',
    appIds: {
      QA: 1202021,
      PROD: 221635
    }
  },
  [INTEGRATIONS_APPS.SHOPIFY]: {
    displayName: 'Shopify',
    appIds: {
      QA: 1161155,
      PROD: 51381
    }
  },
  [INTEGRATIONS_APPS.SHOPIFY_DATA_SYNC]: {
    displayName: 'Shopify',
    appIds: {
      QA: 1920453,
      PROD: 542084
    }
  },
  [INTEGRATIONS_APPS.QUICKBOOKS]: {
    displayName: 'QuickBooks Online',
    appIds: {
      QA: 1165378,
      PROD: 188574
    }
  },
  [INTEGRATIONS_APPS.QUICKBOOKS_DATA_SYNC]: {
    displayName: 'QuickBooks Online',
    appIds: {
      QA: 1920452,
      PROD: 542129
    }
  },
  [INTEGRATIONS_APPS.ADOBE_SIGN]: {
    displayName: 'Adobe Sign',
    appIds: {
      QA: 10021866,
      PROD: 3560422
    }
  },
  [INTEGRATIONS_APPS.DOCUSIGN]: {
    displayName: 'DocuSign',
    appIds: {
      QA: 1203058,
      PROD: 224321
    }
  },
  // Acceptance Testing Demo NetSute App
  [INTEGRATIONS_APPS.NETSUITE_DEMO]: {
    displayName: 'NetSuite (Demo)',
    appIds: {
      QA: 1205036,
      PROD: 362342
    }
  }
};
const appNameByAppIdMap = Object.keys(INTEGRATIONS_APPS_DATA).reduce((result, name) => Object.assign({}, result, {
  [INTEGRATIONS_APPS_DATA[name].appIds[CURRENT_ENV]]: name
}), {});
export const getAppIdByAppName = appName => INTEGRATIONS_APPS_DATA[appName].appIds[CURRENT_ENV];
export const getAppDataByAppName = appName => INTEGRATIONS_APPS_DATA[appName];
export const getAppDataByAppId = appId => INTEGRATIONS_APPS_DATA[appNameByAppIdMap[appId]];
export const getAppNameByAppId = appId => appNameByAppIdMap[appId];