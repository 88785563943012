import isEmpty from 'transmute/isEmpty';
import { List, Map as ImmutableMap, fromJS } from 'immutable';
import DSFieldRecord from 'customer-data-filters/filterQueryFormat/DSFieldRecord/DSFieldRecord';
// @ts-expect-error migrate upstream types
import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import RollingDateConfig from 'customer-data-filters/filterQueryFormat/rollingDates/RollingDateConfig';
// @ts-expect-error migrate upstream types
import { ENGAGEMENT_MAP_TO_SEARCH_PROPERTIES } from 'reporting-data/retrieve/inboundDb/common/mapToSearchProperties';
// @ts-expect-error migrate upstream types
import { getFullyQualifiedName } from './dataSourceMetadataSchema';
export const convertPropertyToDSFieldRecordWithScriptedField = property => {
  // Formats property in expected DSFieldRecord query format.
  const dSFieldRecordProperty = DSFieldRecord(property);
  // Explicitly set scripted field for DSFieldRecord translated properties.
  const dsFieldRecordPropertyWithScripted = Object.assign({}, dSFieldRecordProperty.toJS(), {
    scripted: Boolean(property.get('scripted'))
  });
  // Translate back to immutable JS object for consumption by filter related components.
  return fromJS(dsFieldRecordPropertyWithScripted);
};
// converts an operator in Filter Query Format to the format used in our redux store and local storage
export const convertToDashboardFilter = ({
  dataSource,
  dataSourceName,
  operator,
  systemGenerated = false,
  quickFilterGroupName = ''
}) => {
  const operatorName = operator.get('name');
  const value = operator.get('value');
  const property = operator.get('field');
  const dashboardFilter = ImmutableMap(Object.assign({
    dataSource,
    dataSourceName,
    propertyName: property.get('name'),
    propertyType: property.get('type'),
    propertyScripted: Boolean(property.getIn(['wrappedObject', 'scripted'])),
    operator: operatorName,
    value
  }, operatorName === Operators.InRange.toString() && {
    highValue: operator.get('highValue')
  }, quickFilterGroupName && {
    systemGenerated,
    quickFilterGroupName
  })).toJS();
  return dashboardFilter;
};
const ENGAGEMENT_SEARCH_TO_LEGACY_PROPERTIES = fromJS(ENGAGEMENT_MAP_TO_SEARCH_PROPERTIES).flip();

// converts stored dashboard filter data to the format (Filter Query Format) used by customer-data-filters components & conversion functions
export const getFilterQueryFormatOperator = (dashboardFilter, dataSourceProperties, isApplyingToCompletedActivities = false) => {
  const {
    operator,
    propertyName,
    value
  } = dashboardFilter;
  const Operator = Operators[operator];
  const propertyData = dataSourceProperties[propertyName];
  if (!propertyData) {
    return null;
  }
  const shouldModifyPropertyData = isApplyingToCompletedActivities && ENGAGEMENT_SEARCH_TO_LEGACY_PROPERTIES.get(propertyName) != null;
  const modifiedPropertyData = shouldModifyPropertyData ? propertyData.set('name', ENGAGEMENT_SEARCH_TO_LEGACY_PROPERTIES.get(propertyName), propertyName) : propertyData;
  const field = DSFieldRecord(modifiedPropertyData);
  const modifiedValue = Operator === Operators.InRollingDateRange ? RollingDateConfig(value) : Array.isArray(value) && !isEmpty(value) ? List(value) : value;
  const operatorRecord = Operator === Operators.InRange ? Operator.of(field, modifiedValue, dashboardFilter.highValue) : Operator.of(field, modifiedValue);
  return operatorRecord;
};
export const convertSingleOperatorToGroupOperators = ({
  singlePropertyOperator,
  quickFilterGroupProperties,
  dataSourcesMetadata
}) => {
  const operatorName = singlePropertyOperator.get('name');
  const value = singlePropertyOperator.get('value');
  const Operator = Operators[operatorName];
  const highValue = Operator === Operators.InRange ? singlePropertyOperator.get('highValue') : null;
  const quickFilterGroupOperators = quickFilterGroupProperties.filter(quickFilterProperty => !!quickFilterProperty.property).map(property => {
    const propertyRecord = property['property'];
    const dataSource = property['dataSource'];
    const dataSourceMetadata = dataSourcesMetadata[dataSource];
    const dataSourceName = getFullyQualifiedName(dataSourceMetadata);
    const propertyScripted = propertyRecord.get('scripted');

    // Set scripted field per property
    const field = propertyScripted ? convertPropertyToDSFieldRecordWithScriptedField(propertyRecord) : DSFieldRecord(propertyRecord);
    const modifiedValue = Operator === Operators.InRollingDateRange ? RollingDateConfig(value) : Array.isArray(value) && !isEmpty(value) ? List(value) : value;
    const operatorRecord = Operator === Operators.InRange ? Operator.of(field, modifiedValue, highValue) : Operator.of(field, modifiedValue);
    return {
      dataSource,
      dataSourceName,
      operator: operatorRecord
    };
  });
  return quickFilterGroupOperators;
};
export const convertGroupOperatorsToDashboardFilters = ({
  quickFilterGroupOperators,
  systemGenerated,
  quickFilterGroupName
}) => {
  const quickFilterGroupDashboardFilters = quickFilterGroupOperators.map(({
    dataSource,
    dataSourceName,
    operator
  }) => {
    const dashboardFilter = convertToDashboardFilter({
      dataSource,
      dataSourceName,
      operator,
      systemGenerated,
      quickFilterGroupName
    });
    return dashboardFilter;
  });
  return quickFilterGroupDashboardFilters;
};