export const VISITS = 'visits';
export const SUBMISSIONS = 'submissions';
export const LEADS = 'leads';
export const CUSTOMERS = 'customers';
export const CONTACTS = 'contacts';
export const LAST_TOUCH_CONTACTS = 'last-touch-contacts';
export const SUBSCRIBERS = 'subscribers';
export const MARKETING_QUALIFIED_LEADS = 'marketing-qualified-leads';
export const SALES_QUALIFIED_LEADS = 'sales-qualified-leads';
export const OPPORTUNITIES = 'opportunities';
export const OTHERS = 'others';
export const EVANGELISTS = 'evangelists';
export const CONTACTS_INFLUENCED = 'contacts-influenced';
export const INFLUENCED_CONTACTS = 'influenced-contacts';