import { generatePath } from 'react-router-dom';
// @ts-expect-error migrate upstream
import { isTruthy, append } from 'dashboard-lib/private/js-util';
import { stringify } from 'hub-http/helpers/params';
import compose from 'transmute/compose';
import ifThen from 'transmute/ifThen';
/**
 * @throws if pathParam does not complete template
 */
export const createUrlUnsafe = params => {
  const {
    basename = '',
    template = '',
    pathParam = {},
    queryParam = {}
  } = params;
  const path = generatePath(template, pathParam);
  const query = stringify(queryParam);
  const appendQuery = compose(append(query), append('?'));
  return compose(ifThen(() => isTruthy(query))(appendQuery), append(path))(basename);
};