'use es6';

import { useContext, useCallback, useMemo } from 'react';
import I18n from 'I18n';
import { UserInfoContext } from 'reporting-data/userInfo/userInfoContext';
import { useAsyncFetcher } from 'reporting-data/asyncData/useAsyncFetcher';
import { getSubdomainsForSources, getAnalyticsViews } from '../../../api/analyticsViews';
import { isSucceeded } from 'reporting-data/asyncData/AsyncData';
const ALL_SUBDOMAINS_GATE = 'Analytics:AllSubdomainProcessing';
const COS_ONLY_GATE = 'cos_only';
const useFetchAnalyticsViewOptions = (includeSubdomains = false) => {
  const {
    gates
  } = useContext(UserInfoContext);
  const [isUngatedToAllSubdomains, isUngatedToCOSOnly] = useMemo(() => {
    return [gates.includes(ALL_SUBDOMAINS_GATE), gates.includes(COS_ONLY_GATE)];
  }, [gates]);
  const fetcher = useCallback(() => Promise.all([includeSubdomains ? getSubdomainsForSources(isUngatedToAllSubdomains, isUngatedToCOSOnly) : null, getAnalyticsViews()]), [isUngatedToAllSubdomains, isUngatedToCOSOnly, includeSubdomains]);
  const resultFormatter = useCallback(response => {
    const [subdomains, views] = response;
    return [{
      text: I18n.text('reporting-enablement.reportEditors.analyticsViewEditor.optionGroups.all'),
      value: null
    }, ...(subdomains ? [{
      text: I18n.text('reporting-enablement.reportEditors.analyticsViewEditor.optionGroups.subdomains'),
      options: [...subdomains.map(option => ({
        text: option.domain,
        value: option.domain
      }))]
    }] : []), {
      text: I18n.text('reporting-enablement.reportEditors.analyticsViewEditor.optionGroups.views'),
      options: [...views.map(view => ({
        text: view.title,
        value: String(view.id)
      }))]
    }];
  }, []);
  const viewOptionsFetch = useAsyncFetcher({
    fetcher,
    resultFormatter
  });
  return isSucceeded(viewOptionsFetch) ? viewOptionsFetch.data : null;
};
export default useFetchAnalyticsViewOptions;