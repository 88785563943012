'use es6';

import { Record } from 'immutable';
import invariant from '../lib/invariant';
export class Option extends Record({
  value: null,
  label: null,
  url: null
}) {
  constructor({
    value,
    label = value,
    url
  }) {
    invariant(value != null, 'expected valid `key` to be supplied, but got none');
    super({
      value: String(value),
      label: String(label),
      url
    });
  }
}
export const makeOption = (value, label) => new Option({
  value,
  label
});