// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import * as DSAssetFamilies from 'customer-data-filters/filterQueryFormat/DSAssetFamilies/DSAssetFamilies';
import invariant from 'react-utils/invariant';
import { isOperator } from '../../filterQueryFormat/operator/Operator';
import { PrivacyConsentCompleted, PrivacyConsentNotCompleted
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../.... Remove this comment to see the full error message
} from '../../filterQueryFormat/operator/Operators';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './to... Remove this comment to see the full error message
import toAdsFilter from './toObjectSegFilter/toAdsFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './to... Remove this comment to see the full error message
import toCtaFilter from './toObjectSegFilter/toCtaFilter';
import toConstantFilter from './toObjectSegFilter/toConstantFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './to... Remove this comment to see the full error message
import toEmailEventFilter from './toObjectSegFilter/toEmailEventFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './to... Remove this comment to see the full error message
import toEmailSubscriptionFilter from './toObjectSegFilter/toEmailSubscriptionFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './to... Remove this comment to see the full error message
import toEventFilter from './toObjectSegFilter/toEventFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './to... Remove this comment to see the full error message
import toFormSubmissionFilter from './toObjectSegFilter/toFormSubmissionFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './to... Remove this comment to see the full error message
import toIntegrationFilter from './toObjectSegFilter/toIntegrationFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './to... Remove this comment to see the full error message
import toListMembershipFilter from './toObjectSegFilter/toListMembershipFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './to... Remove this comment to see the full error message
import toPageViewFilter from './toObjectSegFilter/toPageViewFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './to... Remove this comment to see the full error message
import toPrivacyConsentFilter from './toObjectSegFilter/toPrivacyConsentFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './to... Remove this comment to see the full error message
import toPropertyFilter from './toObjectSegFilter/toPropertyFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './to... Remove this comment to see the full error message
import toSurveyMonkeyQuestionFilter from './toObjectSegFilter/toSurveyMonkeyQuestionFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './to... Remove this comment to see the full error message
import toSurveyMonkeySurveyFilter from './toObjectSegFilter/toSurveyMonkeySurveyFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module './to... Remove this comment to see the full error message
import toWebinarFilter from './toObjectSegFilter/toWebinarFilter';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
import { getDynamicFilterFamilyBasis } from 'customer-data-filters/filterQueryFormat/DynamicFilterFamilies';
import DeprecatedFilter from 'customer-data-filters/filterQueryFormat/operator/DeprecatedFilter';
// @ts-expect-error untyped
import * as ObjectSegConstants from './constants/constants';
import MissingAssociationBranchFilter from '../../filterQueryFormat/operator/MissingAssociationBranchFilter';
import toMissingAssociationFilter from './toObjectSegFilter/toMissingAssociationFilter';
import { getConverterOptionsWithDefaults } from './converterOptions';
const filterFamilyConverters = {
  [DSAssetFamilies.ADS_PROPERTY]: toAdsFilter,
  [DSAssetFamilies.CTA]: toCtaFilter,
  [ObjectSegConstants.DEPRECATED_FILTER]: toConstantFilter,
  [ObjectSegConstants.MISSING_ASSOCIATION_BRANCH_FILTER]: toMissingAssociationFilter,
  [DSAssetFamilies.EMAIL_CAMPAIGN]: toEmailEventFilter,
  [DSAssetFamilies.EMAIL_SUBSCRIPTION]: toEmailSubscriptionFilter,
  [DSAssetFamilies.EVENT]: toEventFilter,
  [DSAssetFamilies.FORM]: toFormSubmissionFilter,
  [DSAssetFamilies.GOTO_WEBINAR_WEBINAR]: toWebinarFilter,
  [DSAssetFamilies.IMPORT]: toListMembershipFilter,
  [DSAssetFamilies.INTEGRATION]: toIntegrationFilter,
  [DSAssetFamilies.IN_LIST]: toListMembershipFilter,
  [DSAssetFamilies.PAGE_VIEW]: toPageViewFilter,
  [DSAssetFamilies.SURVEY_MONKEY_QUESTION]: toSurveyMonkeyQuestionFilter,
  [DSAssetFamilies.SURVEY_MONKEY_SURVEY]: toSurveyMonkeySurveyFilter,
  [DSAssetFamilies.WORKFLOW]: toListMembershipFilter,
  [DSAssetFamilies.PRIVACY_CONSENT]: toPrivacyConsentFilter
};
const isPrivacyConsent = condition => {
  return condition instanceof PrivacyConsentCompleted || condition instanceof PrivacyConsentNotCompleted;
};
const getFilterFamilyBasis = (operator, filterFamily) => {
  if (isPrivacyConsent(operator)) {
    return DSAssetFamilies.PRIVACY_CONSENT;
  }
  if (operator.constructor === DeprecatedFilter) {
    return ObjectSegConstants.DEPRECATED_FILTER;
  }
  if (operator.constructor === MissingAssociationBranchFilter) {
    return ObjectSegConstants.MISSING_ASSOCIATION_BRANCH_FILTER;
  }
  return getDynamicFilterFamilyBasis(filterFamily) || filterFamily;
};

/**
 * Convert {@link https://product.hubteam.com/docs/crm-filters/legacy-docs/FilterQueryFormat/Overview.html Filter Query Format} to InboundDbListSeg filter.
 */
export const toObjectSegFilterStrict = (operator, filterFamily, isUngated, isNotUsingTimePoints = false) => {
  invariant(isOperator(operator), `Expected ${operator} to be an operator`);
  invariant(typeof filterFamily === 'string', `Expected ${filterFamily} to be a string`);
  invariant(typeof isUngated === 'function', `Expected ${isUngated} to be a function`);
  const converterOptions = getConverterOptionsWithDefaults({
    isUngated,
    isNotUsingTimePoints
  });
  const filterFamilyBasis = getFilterFamilyBasis(operator, filterFamily);
  if (Object.prototype.hasOwnProperty.call(filterFamilyConverters, filterFamilyBasis)) {
    return filterFamilyConverters[filterFamilyBasis](operator, filterFamily, converterOptions);
  }
  return toPropertyFilter(operator, converterOptions);
};