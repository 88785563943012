import { Set as ImmutableSet, List } from 'immutable';
import { useEffect, useState } from 'react';
import { FieldRef } from '../schema/field-ref';
import { TableAndPropertyMeta, DataSourceTypes } from '../schema/source-records';
import { getTableDescriptionMeta } from './table-description-meta';
import { DatasetProperty } from '../../dataset/dataset-records';
import { Field, FieldSources } from '../schema/column-records';
import { DATASET_TABLE } from '../schema/table-records';
import { getPrimaryDisplayLabelPropertyName, getTableList } from '../utils/table-utils';
import { getReportFieldRefs } from '../utils/report-utils';
import { TypeNames } from '../../dataset/type-records';
import { ExpressionTypes } from '../../dataset/expression-records';

/**
 * Hydrate all of the the tables and properties used in a report
 */
export const resolveReportMeta = report => {
  const {
    table: tableDescription
  } = report;
  return getTableDescriptionMeta(tableDescription).then(tableDescriptionMeta => {
    const {
      tables: snowflakeTables,
      properties: snowflakeProperties,
      datasetDefinition
    } = tableDescriptionMeta;
    const primaryLabelFieldRefs = getPrimaryDisplayLabelPropertyName(snowflakeTables, tableDescription);
    const datasetPropertyRefs = datasetDefinition ? datasetDefinition.properties.reduce((result, properties, tableName) => {
      const fieldRefs = properties.map(property => FieldRef({
        table: tableName,
        property: property.name
      })).toList();
      return result.concat(fieldRefs).toList();
    }, List()) : List();
    const reportFieldRefs = List(getReportFieldRefs(report).concat(primaryLabelFieldRefs, datasetPropertyRefs));
    const mapOfTableNameToPropertyNames = reportFieldRefs.groupBy(fieldRef => fieldRef.table).map(fieldRefs => fieldRefs.map(fieldRef => fieldRef.property).toSet());

    // hydrated map of tables used in the report
    const tables = ((tableDescription.type === DataSourceTypes.HUBSPOT_DATASET || tableDescription.type === DataSourceTypes.HUBSPOT_GLOBAL_DATASET) && datasetDefinition ? getTableList(datasetDefinition.table) : getTableList(tableDescription)).toMap().mapKeys((_, table) => table ? table.name : '').filter((_, maybeTableName) => !!maybeTableName).map(table => snowflakeTables.get(table.name)).toMap();

    // hydrated properties used in the report, index by name, for all report tables
    const properties = mapOfTableNameToPropertyNames.map((propertyNames = ImmutableSet(), tableName) => propertyNames.toMap().map(propertyName => snowflakeProperties.getIn([tableName, propertyName])).filter(maybeProperty => !!maybeProperty).toMap()).filter(tableProperties => !tableProperties.isEmpty()).toMap();
    const datasetProperties = properties.map((tableProperties, tableName) => tableProperties.map(property => DatasetProperty({
      name: property.name,
      table: tableName,
      type: property.type
    })).toMap()).toMap();
    return TableAndPropertyMeta({
      tables,
      properties,
      datasetDefinition,
      datasetProperties
    });
  });
};
const fieldFromDatasetToPropertyField = (meta, field) => {
  const fieldFromDataset = meta.datasetDefinition && meta.datasetDefinition.fields.get(field.name);
  if (!fieldFromDataset) {
    console.error('Field does not exist in dataset');
    return undefined;
  }
  const {
    type,
    expression
  } = fieldFromDataset;
  if (type.type !== TypeNames.PROPERTY || expression.type !== ExpressionTypes.PROPERTY) {
    return undefined;
  }
  const {
    propertyType
  } = type;
  const {
    name,
    table
  } = expression;
  if (!name || !table) {
    console.error('Dataset property expression does not contain a valid name or table');
    return undefined;
  }
  return Field({
    name,
    table,
    source: FieldSources.TABLE,
    type: propertyType
  });
};
export const getSnowflakeProperty = (meta, field) => {
  const hsPropertyField = field.table === DATASET_TABLE ? fieldFromDatasetToPropertyField(meta, field) : field;
  if (hsPropertyField) {
    return meta.properties.getIn([hsPropertyField.table, hsPropertyField.name]);
  }
  return undefined;
};
export const getSnowflakeTable = (meta, tableName) => meta.tables.get(tableName);

/**
 * todo remove this when we no longer have "empty snowflake properties"
 * see https://git.hubteam.com/HubSpot/report-builder-issues/issues/348
 */
export const isEmptySnowflakeProperty = snowflakeProperty => {
  return snowflakeProperty === undefined || snowflakeProperty.name === undefined;
};

// todo think about unifying this with `useMeta` from reporting-ui-components
export const useReportMeta = report => {
  const [reportWithMeta, setReportWithMeta] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    let expired = false;
    setReportWithMeta(undefined);
    setError(undefined);
    resolveReportMeta(report).then(reportMeta => {
      if (!expired) {
        setReportWithMeta({
          report,
          reportMeta
        });
      }
    }).catch(e => {
      if (!expired) {
        setError(e);
      }
    });
    return () => {
      expired = true;
    };
  }, [report]);
  return {
    reportWithMeta,
    error
  };
};