'use es6';

import { Map as ImmutableMap } from 'immutable';
import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import compose from 'transmute/compose';
import get from 'transmute/get';
import update from 'transmute/update';
import { getRequestParams } from '../request/create-request-action';
import { handleResourceCancelAction, handleResourceFailAction, handleResourcePendAction, handleResourceSucceedAction, handleResourceSuccessMapOfValueAction } from '../request/redux-request';
import { RequestActionType } from '../request/request-action';
import { fetchDashboardSentenceMulti, fetchDashboardSentenceSingle, fetchDashboardConfigSingle, putDashboardConfigSingle, fetchReportSentenceMulti, fetchReportSentenceSingle, fetchReportConfigSingle, putReportConfigSingle } from './permission-action';
import { dashboardConfigNamespace, dashboardSentenceNamespace, reportConfigNamespace, reportSentenceNamespace } from 'dashboard-lib/public/permission/permission-action';
import { ResourceState } from 'dashboard-lib/public/request/resource-state';
const {
  PEND,
  SUCCEED,
  FAIL,
  CANCEL
} = RequestActionType;
const getDashboardId = compose(get('dashboardId'), getRequestParams);
const fetchDashboardConfigSingleActions = fetchDashboardConfigSingle.actions;
const putDashboardConfigSingleActions = putDashboardConfigSingle.actions;
export const permissionDashboardSettingsReducer = handleActions({
  [fetchDashboardConfigSingleActions[PEND]](state, action) {
    return handleResourcePendAction(action, state);
  },
  [fetchDashboardConfigSingleActions[SUCCEED]](state, action) {
    return handleResourceSucceedAction(action, state);
  },
  [fetchDashboardConfigSingleActions[FAIL]](state, action) {
    return handleResourceFailAction(action, state);
  },
  [fetchDashboardConfigSingleActions[CANCEL]](state, action) {
    return handleResourceCancelAction(action, state);
  },
  [putDashboardConfigSingleActions[PEND]](state, action) {
    return handleResourcePendAction(action, state);
  },
  [putDashboardConfigSingleActions[SUCCEED]](state, action) {
    return handleResourceSucceedAction(action, state);
  },
  [putDashboardConfigSingleActions[FAIL]](state, action) {
    return handleResourceFailAction(action, state);
  },
  [putDashboardConfigSingleActions[CANCEL]](state, action) {
    return handleResourceCancelAction(action, state);
  }
}, ResourceState());
const fetchDashboardSentenceSingleActions = fetchDashboardSentenceSingle.actions;
const fetchDashboardSentenceMultiActions = fetchDashboardSentenceMulti.actions;
export const dashboardSentenceReducer = handleActions({
  [fetchDashboardSentenceSingleActions[PEND]](state, action) {
    return update(getDashboardId(action), handleResourcePendAction(action), state);
  },
  [fetchDashboardSentenceSingleActions[SUCCEED]](state, action) {
    return update(getDashboardId(action), handleResourceSucceedAction(action), state);
  },
  [fetchDashboardSentenceSingleActions[FAIL]](state, action) {
    return update(getDashboardId(action), handleResourceFailAction(action), state);
  },
  [fetchDashboardSentenceSingleActions[CANCEL]](state, action) {
    return update(getDashboardId(action), handleResourceCancelAction(action), state);
  },
  [fetchDashboardSentenceMultiActions[PEND]](state) {
    return state;
  },
  [fetchDashboardSentenceMultiActions[SUCCEED]](state, action) {
    return handleResourceSuccessMapOfValueAction(action, state);
  },
  [fetchDashboardSentenceMultiActions[FAIL]](state) {
    return state;
  },
  [fetchDashboardSentenceMultiActions[CANCEL]](state) {
    return state;
  }
}, ImmutableMap());
const getReportId = compose(get('reportId'), getRequestParams);
const fetchReportConfigSingleActions = fetchReportConfigSingle.actions;
const putReportConfigSingleActions = putReportConfigSingle.actions;
export const permissionReportSettingsReducer = handleActions({
  [fetchReportConfigSingleActions[PEND]](state, action) {
    return handleResourcePendAction(action, state);
  },
  [fetchReportConfigSingleActions[SUCCEED]](state, action) {
    return handleResourceSucceedAction(action, state);
  },
  [fetchReportConfigSingleActions[FAIL]](state, action) {
    return handleResourceFailAction(action, state);
  },
  [fetchReportConfigSingleActions[CANCEL]](state, action) {
    return handleResourceCancelAction(action, state);
  },
  [putReportConfigSingleActions[PEND]](state, action) {
    return handleResourcePendAction(action, state);
  },
  [putReportConfigSingleActions[SUCCEED]](state, action) {
    return handleResourceSucceedAction(action, state);
  },
  [putReportConfigSingleActions[FAIL]](state, action) {
    return handleResourceFailAction(action, state);
  },
  [putReportConfigSingleActions[CANCEL]](state, action) {
    return handleResourceCancelAction(action, state);
  }
}, ResourceState());
const fetchReportSentenceSingleActions = fetchReportSentenceSingle.actions;
const fetchReportSentenceMultiActions = fetchReportSentenceMulti.actions;
export const reportSentenceReducer = handleActions({
  [fetchReportSentenceSingleActions[PEND]](state, action) {
    return update(getReportId(action), handleResourcePendAction(action), state);
  },
  [fetchReportSentenceSingleActions[SUCCEED]](state, action) {
    return update(getReportId(action), handleResourceSucceedAction(action), state);
  },
  [fetchReportSentenceSingleActions[FAIL]](state, action) {
    return update(getReportId(action), handleResourceFailAction(action), state);
  },
  [fetchReportSentenceSingleActions[CANCEL]](state, action) {
    return update(getReportId(action), handleResourceCancelAction(action), state);
  },
  [fetchReportSentenceMultiActions[PEND]](state) {
    return state;
  },
  [fetchReportSentenceMultiActions[SUCCEED]](state, action) {
    return handleResourceSuccessMapOfValueAction(action, state);
  },
  [fetchReportSentenceMultiActions[FAIL]](state) {
    return state;
  },
  [fetchReportSentenceMultiActions[CANCEL]](state) {
    return state;
  }
}, ImmutableMap());
export const permissionReducer = combineReducers({
  [dashboardConfigNamespace]: permissionDashboardSettingsReducer,
  [dashboardSentenceNamespace]: dashboardSentenceReducer,
  [reportConfigNamespace]: permissionReportSettingsReducer,
  [reportSentenceNamespace]: reportSentenceReducer
});