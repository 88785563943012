const HUBLET_REGEX = /^(local|app)-(.*).hubspot(qa)?.com/;
export const isHublet = (hostname = window.location.hostname) => {
  return HUBLET_REGEX.test(hostname);
};
export const getHublet = (hostname = window.location.hostname) => {
  if (isHublet(hostname)) {
    const hubletResult = HUBLET_REGEX.exec(hostname);
    return hubletResult ? hubletResult[2] : 'na1';
  }
  return 'na1';
};