export const MANAGE_SAVED_FILTER_TABS = {
  MANAGE_SAVED_FILTER_SETS: 'MANAGE_SAVED_FILTER_SETS',
  APPLIED_FILTERS_LIST: 'APPLIED_FILTERS_LIST',
  QUICK_FILTERS_EDIT_VIEW: 'QUICK_FILTERS_EDIT_VIEW'
};
// TODO: rename to something more generic to be inclusive of quick filters flow/ this
// is no longer specific to saved dashboard filters
export const SAVED_DASHBOARD_PANEL_VIEW = {
  MANAGE: 'MANAGE',
  ADD_APPLIED_FILTER: 'ADD_APPLIED_FILTER',
  APPLIED_FILTER_PROPERTY_SELECT: 'APPLIED_FILTER_PROPERTY_SELECT',
  EDIT_APPLIED_FILTER_OPERATOR: 'EDIT_APPLIED_FILTER_OPERATOR',
  EDIT_FILTER_SET: 'EDIT_FILTER_SET',
  ADD_QUICK_FILTER: 'ADD_QUICK_FILTER',
  QUICK_FILTER_FORM_VIEW: 'QUICK_FILTER_FORM_VIEW'
};
export const EDIT_FILTER_SET_VIEW = {
  ADD_FILTER: 'ADD_FILTER',
  PROPERTY_SELECT: 'PROPERTY_SELECT',
  EDIT_FILTER_OPERATOR: 'EDIT_FILTER_OPERATOR',
  FILTER_LIST: 'FILTER_LIST'
};