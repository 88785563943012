'use es6';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeamHierarchy } from 'dashboard-lib/public/team/team-hierarchy-action';
import { fetchAllTeam } from 'dashboard-lib/public/team/team-action';
import { fetchUsers } from 'dashboard-lib/public/users/users-action';
import { selectTeamValue } from 'dashboard-lib/public/team/team-selector';
import { selectTeamHierarchyValue } from 'dashboard-lib/public/team/team-hierarchy-selector';
import { selectUserSeq, selectUsersValue } from 'dashboard-lib/public/users/users-selector';
export const useFetchEntityDeps = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTeamHierarchy());
    dispatch(fetchAllTeam());
    dispatch(fetchUsers());
  }, [dispatch]);
};
export const useEntityDeps = () => {
  const teamMap = useSelector(selectTeamValue);
  const teamHierarchy = useSelector(selectTeamHierarchyValue);
  const userMap = useSelector(selectUsersValue);
  const userSeq = useSelector(selectUserSeq);
  return {
    teamMap,
    teamHierarchy,
    userMap,
    userSeq
  };
};