'use es6';

import { dataTypeSupportsAnalyticsViews, dataTypeSupportsSubdomains } from 'reporting-enablement/components/reportEditors/UnifiedAnalyticsViewFilterEditor/analyticsViewUtils';
import { getDataType } from 'reporting-data/report/configReportGetters';
export function validateAnalyticsView(contextChange, __context, report, __subAppKey, __defaultContext, __scopes) {
  const dataType = getDataType(report);
  const contextChangeWithoutAnalyticsViews = Object.assign({}, contextChange, {
    analyticsView: null,
    domain: null
  });
  const analyticsViewChange = contextChange.analyticsView;
  const subdomainChange = contextChange.domain;
  if (analyticsViewChange) {
    return dataTypeSupportsAnalyticsViews(dataType) ? Object.assign({}, contextChange, {
      domain: null
    }) : contextChangeWithoutAnalyticsViews;
  }
  if (subdomainChange) {
    return dataTypeSupportsSubdomains(dataType) ? Object.assign({}, contextChange, {
      analyticsView: null
    }) : contextChangeWithoutAnalyticsViews;
  }
  return contextChange;
}