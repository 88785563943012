'use es6';

import { getFilteredBreakdownsFieldsFromKey } from '../filteredBreakdownsHelpers';
export function validateFilteredBreakdowns(contextChange, context, report, __subAppKey, __defaultContext, __scopes, __gates) {
  const filteredBreakdowns = contextChange.filteredBreakdowns || context.filteredBreakdowns;
  const dataType = report.getIn(['config', 'dataType']);
  const dataTypeSpecificFilteredBreakdowns = filteredBreakdowns.filter(filteredBreakdownKey => getFilteredBreakdownsFieldsFromKey(filteredBreakdownKey).dataType === dataType);
  return Object.assign({}, contextChange, {
    filteredBreakdowns: dataTypeSpecificFilteredBreakdowns
  });
}