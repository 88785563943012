import { ReportWithOptions, ReportOptions } from './resolve-records';
import { request, retrieve } from './request';
const getReportWithOptions = reportDefinition => ReportWithOptions({
  reportDefinition,
  reportOptions: ReportOptions({
    limit: 1000,
    offset: 0,
    fetchFromCache: false,
    updateCache: true // Bust the cache
  })
});

/**
 * Refreshes the snowflake cache using a reportId
 *
 * The {updateCache: true} in reportOptions
 */
export const refresh = (reportDefinition, reportId) => retrieve(getReportWithOptions(reportDefinition), reportId);

/**
 * Refreshes the snowflake cache using the report definition
 *
 * This method is useful when refreshing a report that has runtime overrides (e.g. dashboard filters)
 */
export const refreshWithReportDefinition = (reportDefinition, reportId) => request(getReportWithOptions(reportDefinition), reportId);