import * as checked from 'reporting-data/lib/checked';
import { Dataset } from 'reporting-data/v2/dataset/datasetRecords';
import { XY } from '../../constants/visualizationTypes';
import { Order } from '../../field/order';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../.... Remove this comment to see the full error message
import { Transform } from '../../transforms/transformRecords';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../c... Remove this comment to see the full error message
import { DataLabelFormat, LegendPosition, LegendWidth } from '../commonRecords';
import { DATASET_INSIGHT_TYPES } from 'reporting-data/tsTypes/datasetInsightTypes';
export const Category = checked.record({
  key: checked.any(),
  name: checked.string()
}, 'Category');
export const Point = checked.record({
  row: checked.any().fromJS(),
  value: checked.any().fromJS(),
  hideInTooltip: checked.boolean().optional()
}, 'Point');
export const SeriesStyle = checked.record({
  zIndex: checked.number().optional(),
  color: checked.string().optional(),
  marker: checked.any().fromJS(),
  pointWidth: checked.number().optional(),
  dashStyle: checked.string().optional(),
  forceStacking: checked.boolean().optional(),
  showInLegend: checked.boolean().optional(),
  pattern: checked.string().optional()
}, 'SeriesStyle');
export const Series = checked.record({
  index: checked.number().optional(),
  id: checked.string(),
  layerId: checked.string(),
  property: checked.any().optional(),
  name: checked.string().optional(),
  type: checked.string().optional(),
  yAxis: checked.string().optional(),
  xAxis: checked.string().optional(),
  stacking: checked.boolean().optional(),
  stackingType: checked.string().optional(),
  stack: checked.string().optional(),
  points: checked.any().fromJS(),
  style: SeriesStyle.optional(),
  events: checked.any().fromJS(),
  levelOfDetail: checked.list(checked.string()).optional(),
  linkedTo: checked.string().optional(),
  metricName: checked.string().optional(),
  breakdownValue: checked.string().optional()
}, 'Series');
export const AxisTypes = {
  linear: 'linear',
  logarithmic: 'logarithmic',
  datetime: 'datetime',
  category: 'category',
  hidden: 'hidden',
  scaffold: 'scaffold'
};
export const AxisType = checked.symbol(AxisTypes, 'AxisType');
export const GridLineDashStyles = {
  solid: 'solid'
};
export const GridLineDashStyle = checked.symbol(GridLineDashStyles, 'GridLineDashStyle');
export const AxisRules = checked.record({
  plotLines: checked.any().fromJS(),
  plotBands: checked.any().fromJS()
}, 'AxisRules');
export const Axis = checked.record({
  id: checked.string().optional(),
  title: checked.string().optional(),
  type: AxisType.optional(),
  min: checked.number().optional(),
  max: checked.number().optional(),
  startOnTick: checked.boolean().optional(),
  rules: AxisRules.optional(),
  property: checked.any().optional(),
  categories: checked.list(Category).optional(),
  gridLineDashStyle: GridLineDashStyle.optional()
}, 'Axis');
export const Axes = checked.record({
  inverted: checked.boolean().optional(),
  xAxis: checked.list(Axis).fromJS(),
  yAxis: checked.list(Axis).fromJS()
}, 'Axes');
export const Options = checked.record({
  title: checked.string().optional(),
  type: checked.string().defaultValue('column'),
  colors: checked.list().optional(),
  dataLabels: checked.boolean().optional(),
  stackLabels: checked.boolean().optional(),
  showLegend: checked.boolean().optional(),
  showBubbleLegend: checked.boolean().defaultValue(false),
  bottomLegend: checked.boolean().optional(),
  tooltipFormatter: checked.func().optional(),
  tooltipDisabled: checked.boolean().optional(),
  showTotals: checked.boolean().optional(),
  legendPosition: LegendPosition.optional(),
  legendWidth: LegendWidth.optional(),
  dataLabelFormat: DataLabelFormat.optional(),
  disableSeriesHighlighting: checked.boolean().optional(),
  dateResolution: checked.string().optional(),
  disableZoom: checked.boolean().optional(),
  showZeroesInTooltip: checked.boolean().optional(),
  axisTotal: checked.string().optional()
}, 'Options');
export const Chart = checked.record({
  axes: Axes,
  series: checked.list(Series),
  options: Options
}, 'Chart');
export const MarkTypes = {
  arearange: 'arearange',
  bar: 'bar',
  line: 'line',
  area: 'area',
  scatter: 'scatter',
  bubble: 'bubble',
  tick: 'tick'
};
export const MarkType = checked.symbol(MarkTypes, 'MarkType');
export const MarkEncoding = checked.record({
  type: MarkType.optional(),
  stacking: checked.any().optional(),
  stack: checked.string().optional(),
  marker: checked.any().fromJS(),
  dashStyle: checked.string().optional(),
  zIndex: checked.number().optional()
}, 'MarkEncoding');
export const AxisEncoding = checked.record({
  type: AxisType,
  field: checked.string(),
  order: Order.optional(),
  blend: checked.string().optional(),
  formattingType: checked.string().optional(),
  //One of AutoFormatting, NumberFormatting, PercentFormatting, or CurrencyFormatting
  //{ type: string, customPrecision?: number, negativeSymbol?: string currencyCode?: string }
  formattingObject: checked.any().optional(),
  formattingObjectMap: checked.map(checked.any()).optional(),
  // For a linear axes, ensure a point exists for all other categories
  // Necessary for most (all?) breakdown charts to work properly
  // See https://git.hubteam.com/HubSpot/reporting/pull/8053 and its associated issue
  fill: checked.boolean().optional()
}, 'AxisEncoding');
export const SizingEncoding = checked.record({
  field: checked.string()
}, 'SizingEncoding');
export const DetailEncoding = checked.record({
  field: checked.string()
}, 'DetailEncoding');
export const ColorEncoding = checked.record({
  field: checked.string().optional(),
  order: Order.optional(),
  defined: checked.map(checked.string()).optional(),
  default: checked.string().optional(),
  defaultPattern: checked.string().optional(),
  definedPatterns: checked.map(checked.string()).optional(),
  definedDashStyles: checked.map(checked.string()).optional(),
  formattingType: checked.string().optional(),
  //One of AutoFormatting, NumberFormatting, PercentFormatting, or CurrencyFormatting
  //{ type: string, customPrecision?: number, negativeSymbol?: string currencyCode?: string }
  formattingObject: checked.any().optional(),
  formattingObjectMap: checked.map(checked.any()).optional()
}, 'ColorEncoding');
export const LayerLinkedTo = checked.record({
  dataset: checked.string(),
  metricName: checked.string(),
  breakdownValue: checked.string().optional(),
  colorCoordinate: checked.boolean().optional(),
  colorOpacity: checked.number().optional(),
  showInLegend: checked.boolean().optional()
});
export const ExcludePointsFromTooltipScopes = {
  all: 'all',
  range: 'range'
};
export const ExcludePointsFromTooltipScope = checked.symbol(ExcludePointsFromTooltipScopes, 'ExcludePointsFromTooltipScope');
export const ExcludePointsFromTooltip = checked.record({
  scope: ExcludePointsFromTooltipScope.required(),
  from: checked.number().optional(),
  to: checked.number().optional()
});
export const Layer = checked.record({
  id: checked.string().optional(),
  dataset: checked.string(),
  mark: MarkEncoding.optional(),
  x: AxisEncoding.optional(),
  y: AxisEncoding.optional(),
  sizing: SizingEncoding.optional(),
  detail: DetailEncoding.optional(),
  color: ColorEncoding.optional(),
  transform: checked.list(Transform).optional(),
  excludeFromLegacyTooltipTotals: checked.boolean().optional(),
  axisTotal: checked.string().optional(),
  linkedTo: LayerLinkedTo.optional(),
  excludePointsFromTooltip: ExcludePointsFromTooltip.optional(),
  hideDataLabels: checked.boolean().optional(),
  insightType: checked.symbol(DATASET_INSIGHT_TYPES).optional()
}, 'Layer');
export const Delta = checked.record({
  initial: checked.number().optional(),
  final: checked.number().optional()
}, 'Delta');
export const Style = checked.record({
  type: checked.string().optional(),
  // this is used to tell us which direction bars go if both axis are linear
  orient: checked.string().optional(),
  x: checked.map(checked.any().fromJS()).optional(),
  y: checked.map(checked.any().fromJS()).optional(),
  width: checked.number().optional(),
  height: checked.number().optional(),
  dataLabels: checked.boolean().optional(),
  // this is used to tell us if we should show delta value in chart
  delta: Delta.optional(),
  stackLabels: checked.boolean().optional(),
  showLegend: checked.boolean().optional(),
  showBubbleLegend: checked.boolean().optional(),
  colors: checked.list().optional(),
  bottomLegend: checked.boolean().optional(),
  tooltipFormatter: checked.func().optional(),
  tooltipDisabled: checked.boolean().optional(),
  showTotals: checked.boolean().optional(),
  legendPosition: LegendPosition.optional(),
  legendWidth: LegendWidth.optional(),
  isMobile: checked.boolean().optional(),
  opacity: checked.number().optional(),
  dataLabelFormat: DataLabelFormat.optional(),
  dateResolution: checked.string().optional(),
  disableZoom: checked.boolean().optional(),
  showZeroesInTooltip: checked.boolean().optional()
}, 'Style');
export const Events = checked.record({
  onPointClick: checked.func().optional()
}, 'Events');
export const XYVisualization = checked.record({
  type: checked.string().always(XY),
  data: checked.map(Dataset),
  layers: checked.list(Layer),
  style: Style.optional(),
  events: Events.optional()
}, 'XYVisualization');