import { dateDurations } from '../constants/dateDurations';
import { getDateDisplayHint, getPropertyType } from './propertyGetters';
import { DATE, DATE_TIME } from '../constants/property-types';
export const isDateOrDateTime = property => {
  const propertyType = getPropertyType(property);
  return [DATE, DATE_TIME].includes(propertyType);
};
export const isDurationDateProperty = property => {
  if (!isDateOrDateTime(property)) {
    return false;
  }
  const dateDisplayHint = getDateDisplayHint(property) || '';
  return dateDurations.includes(dateDisplayHint);
};