'use es6';

import memoize from 'transmute/memoize';
import http from 'hub-http/clients/apiClient';
import { AD_OBJECT_AD_ACCOUNT, AD_OBJECT_AD_CAMPAIGN, AD_OBJECT_AD_GROUP, AD_OBJECT_AD, AD_OBJECT_KEYWORD } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { createCustomObjectGetter } from 'reference-resolvers/formatters/formatCustomObjects';
import { createFetchSearchPage, createFetchByIds } from './CrmSearchAPI';
import get from 'transmute/get';
const createAdsObjectGetters = prop => {
  const idGetter = createCustomObjectGetter(prop);
  return {
    getId: idGetter,
    getDescription: idGetter
  };
};
const adsObjectsIdProps = {
  [AD_OBJECT_AD_ACCOUNT]: 'hs_ad_account_id',
  [AD_OBJECT_AD_CAMPAIGN]: 'hs_ad_campaign_id',
  [AD_OBJECT_AD_GROUP]: 'hs_ad_group_id',
  [AD_OBJECT_AD]: 'hs_ad_id',
  [AD_OBJECT_KEYWORD]: 'hs_keyword_id'
};
const INACTIVE_AD_CAMPAIGN_STATUSES = ['PENDING_REVIEW', 'ARCHIVED', 'DISAPPROVED', 'COMPLETED'];
const adsObjectGetters = {
  [AD_OBJECT_AD_ACCOUNT]: createAdsObjectGetters(adsObjectsIdProps[AD_OBJECT_AD_ACCOUNT]),
  [AD_OBJECT_AD_CAMPAIGN]: Object.assign({}, createAdsObjectGetters(adsObjectsIdProps[AD_OBJECT_AD_CAMPAIGN]), {
    getArchived: value => {
      const status = createCustomObjectGetter('hs_status')(value);
      return INACTIVE_AD_CAMPAIGN_STATUSES.includes(status);
    }
  }),
  [AD_OBJECT_AD_GROUP]: createAdsObjectGetters(adsObjectsIdProps[AD_OBJECT_AD_GROUP]),
  [AD_OBJECT_AD]: createAdsObjectGetters(adsObjectsIdProps[AD_OBJECT_AD]),
  [AD_OBJECT_KEYWORD]: createAdsObjectGetters(adsObjectsIdProps[AD_OBJECT_KEYWORD])
};
const adsSearchOptions = {
  requestOptions: {
    includeAllValues: true
  },
  sorts: [{
    property: 'hs_name',
    order: 'ASC'
  }]
};
const createAdsObjectFormatterOptions = memoize(objectType => ({
  getters: Object.assign({}, adsObjectGetters[objectType], {
    getLabel: createCustomObjectGetter('hs_name')
  })
}));
const createSearchOptionsForNetwork = memoize(adNetwork => Object.assign({}, adsSearchOptions, {
  filterGroups: [{
    filters: [{
      property: 'hs_ad_network',
      value: adNetwork,
      operator: 'EQ'
    }]
  }]
}));
export const createFetchAdsObjectsByIds = options => objectType => {
  const fetchByIds = createFetchByIds(options)(objectType, {
    searchOptions: adsSearchOptions,
    formatterOptions: createAdsObjectFormatterOptions(objectType)
  });
  return ids => fetchByIds({
    idPropName: adsObjectsIdProps[objectType]
  }, ids).then(get('results'));
};
export const fetchAdsObjectsByIds = createFetchAdsObjectsByIds({
  httpClient: http
});
export const createFetchAdsObjectsByIdsForNetwork = options => (adNetwork, objectType) => {
  const fetchByIds = createFetchByIds(options)(objectType, {
    searchOptions: createSearchOptionsForNetwork(adNetwork),
    formatterOptions: createAdsObjectFormatterOptions(objectType)
  });
  return ids => fetchByIds({
    idPropName: adsObjectsIdProps[objectType]
  }, ids).then(get('results'));
};
export const fetchAdsObjectsByIdsForNetwork = createFetchAdsObjectsByIdsForNetwork({
  httpClient: http
});
export const createFetchAdsObjectSearchPage = options => objectType => createFetchSearchPage(options)(objectType, {
  searchOptions: adsSearchOptions,
  formatterOptions: createAdsObjectFormatterOptions(objectType)
});
export const fetchAdsObjectSearchPage = createFetchAdsObjectSearchPage({
  httpClient: http
});
export const createFetchAdsObjectSearchPageForNetwork = options => (adNetwork, objectType) => createFetchSearchPage(options)(objectType, {
  searchOptions: createSearchOptionsForNetwork(adNetwork),
  formatterOptions: createAdsObjectFormatterOptions(objectType)
});
export const fetchAdsObjectSearchPageForNetwork = createFetchAdsObjectSearchPageForNetwork({
  httpClient: http
});