'use es6';

import { has } from '../../../lib/has';
import { pairs } from './metrics';
import { zero } from './fill';
import { chain } from './chain';
import { two } from './downgrade';
import * as frequency from './frequency';
export const parse = (spec, config) => response => {
  const {
    dimensions,
    metrics = []
  } = config;
  const keys = Object.keys(response);
  const subkeys = keys.reduce((points, key) => [...points, ...response[key]], []).reduce((memo, {
    breakdown
  }) => !memo.includes(breakdown) ? [...memo, breakdown] : memo, []);
  const remapped = keys.reduce((mapped, key) => Object.assign({}, mapped, {
    [key]: response[key].reduce((keyed, point) => Object.assign({}, keyed, {
      [point.breakdown]: point
    }), {})
  }), {});
  const matrix = {
    dimensions,
    metrics: pairs(config),
    keys: [keys, subkeys],
    data: keys.map(key => subkeys.map(subkey => metrics.map(({
      property
    }) => has(remapped, key) ? has(remapped[key], subkey) ? has(remapped[key][subkey], property) ? remapped[key][subkey][property] : 0 : 0 : 0))),
    total: [keys.length] // TODO: determine count vs. total
  };
  return {
    response,
    matrix
  };
};
export const get = (spec, config, runtimeOptions) => ({
  url: `${spec.url}/${frequency.get(config)}`,
  parse: chain(zero, parse)(spec, config, runtimeOptions),
  downgrade: two
});