'use es6';

import PortalIdParser from 'PortalIdParser';
import { getId, getTemplateKey } from '../../data/schemas/reportSchema';
import { isAttributionLike, isSnowflake, isJourney } from '../../lib/report-logic';
import { getAttributionBuilderPathEnding } from './attributionBuilderUtils';
export const encodeReport = report => {
  try {
    return btoa(encodeURIComponent(JSON.stringify(report)));
  } catch (err) {
    return null;
  }
};
const openReportInBuilder = ({
  dashboardId,
  loadConfig,
  report
}) => {
  const portalId = PortalIdParser.get();
  const reportId = getId(report);
  const template = getTemplateKey(report);
  let baseUrl = '';
  let remainingUrl = '';

  //attribution builder
  if (isAttributionLike(report)) {
    baseUrl = `/attribution-builder/${portalId}`;
    remainingUrl = reportId ? `/contact?reportId=${reportId}` : `/${getAttributionBuilderPathEnding(report)}`;
  }
  //snowflake builder
  else if (isSnowflake(report)) {
    baseUrl = `/advanced-builder/${portalId}`;
    remainingUrl = reportId ? `/report/${reportId}?` : `?template=${template}`;
  }

  // journey builder
  else if (isJourney(report)) {
    baseUrl = `/journey-builder/${portalId}`;
    remainingUrl = reportId ? `?reportId=${reportId}` : template ? `?templateKey=${template}` : `/`;
  }
  //single object builder
  else {
    baseUrl = `/report-builder/${portalId}`;
    remainingUrl = reportId ? `/report/${reportId}` : loadConfig ? `/load-config/${encodeReport(report)}` : `/template/${template}`;
  }
  const dashboardIdParam = dashboardId && `dashboardId=${dashboardId}`;
  const additionalQuery = dashboardIdParam ? remainingUrl.includes('?') ? `&${dashboardIdParam}` : `?${dashboardIdParam}` : '';
  window.location = `${baseUrl}${remainingUrl}${additionalQuery}`;
};
export default openReportInBuilder;