'use es6';

import { COLUMN_ROLES } from 'reporting-data/constants/relationalReports';
import { AggregationTypes, DomainTypes, FieldTypes, TransformTypes } from '../../schema/column-records';
import { SortOrders } from '../../schema/sort-records';
import { DataSourceTypes } from '../../schema/source-records';
import { VisualTypes } from '../../schema/visual-records';
import { getColumnAggregations, getColumnDomains, getColumnFields, getColumnRoles, getColumnTransforms } from '../../utils/column-utils';
import { getTableList } from '../../utils/table-utils';
import { HARD, ILL_FORMED, combineConstraints, createConstraint } from '../validate';
const enumExists = (enums, key) => item => Object.values(enums).includes(key ? item.get(key) : item);
const everyEnumExists = (enums, key) => list => list.every(enumExists(enums, key));
const getFromReportColumns = getter => report => getter(report.columns);
const getFromReportTable = getter => report => getter(report.table);
export const aggregationTypesAreValid = createConstraint('aggregationTypesAreValid', HARD, ILL_FORMED, everyEnumExists(AggregationTypes), getFromReportColumns(getColumnAggregations));
export const sourceTypesAreValid = createConstraint('sourceTypesAreValid', HARD, ILL_FORMED, everyEnumExists(DataSourceTypes, 'type'), getFromReportTable(getTableList));
export const domainsAreValid = createConstraint('domainsAreValid', HARD, ILL_FORMED, everyEnumExists(DomainTypes), getFromReportColumns(getColumnDomains));
export const fieldTypesAreValid = createConstraint('fieldTypesAreValid', HARD, ILL_FORMED, everyEnumExists(FieldTypes, 'type'), getFromReportColumns(getColumnFields));
export const rolesAreValid = createConstraint('rolesAreValid', HARD, ILL_FORMED, everyEnumExists(COLUMN_ROLES), getFromReportColumns(getColumnRoles));
export const visualTypeIsValid = createConstraint('visualTypeIsValid', HARD, ILL_FORMED, enumExists(VisualTypes), report => report.visual.type);
export const sortOrdersAreValid = createConstraint('sortOrdersAreValid', HARD, ILL_FORMED, everyEnumExists(SortOrders, 'order'), report => report.sorts);
export const transformTypesAreValid = createConstraint('transformTypesAreValid', HARD, ILL_FORMED, everyEnumExists(TransformTypes, 'type'), getFromReportColumns(getColumnTransforms));
export const enums = combineConstraints('enums', [aggregationTypesAreValid, sourceTypesAreValid, domainsAreValid, fieldTypesAreValid, rolesAreValid, visualTypeIsValid, sortOrdersAreValid, transformTypesAreValid]);