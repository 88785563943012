'use es6';

import I18n from 'I18n';
import { Map as ImmutableMap, OrderedSet } from 'immutable';
import { Field } from '../../schema/column-records';
import { EventDateInterval, Filter } from '../../schema/filter-records';
import { Equal, InRange, LessRolling, InRollingDateRange } from 'customer-data-filters/filterQueryFormat/operator/Operators';
import { IS_EQUAL_TO, IS_BETWEEN, IS_LESS_THAN_X_DAYS_AGO, IS_LESS_THAN_X_DAYS_FROM_NOW, IN_LAST_TIME_UNIT, IN_THIS_TIME_UNIT, IN_THIS_TIME_UNIT_SO_FAR, IN_NEXT_TIME_UNIT } from 'customer-data-filters/converters/objectSeg/ObjectSegOperatorTypes';
const maxDateValues = {
  DAY: 1,
  WEEK: 7,
  MONTH: 31,
  QUARTER: 92,
  YEAR: 365
};
export const EVENT_DATE_PROPERTY_NAME = '__PROPERTY__EVENT_DATE_FILTER__';
export const isEventDateFilter = filter => filter.field && filter.field.name === EVENT_DATE_PROPERTY_NAME;
export const checkEventDateIntervalLimit = (eventDateInterval, maxDays) => {
  const {
    propertyFilterOperation
  } = eventDateInterval;
  const operator = propertyFilterOperation.get('operator');

  // inclusive, a range from today to today is one day, not zero
  let numberOfDays;
  switch (operator) {
    case IS_LESS_THAN_X_DAYS_AGO:
    case IS_LESS_THAN_X_DAYS_FROM_NOW:
      numberOfDays = propertyFilterOperation.get('numberOfDays');
      break;
    case IS_BETWEEN:
      numberOfDays = I18n.moment(propertyFilterOperation.get('upperBoundTimestamp')).diff(I18n.moment(propertyFilterOperation.get('lowerBoundTimestamp')), 'days') + 1;
      break;
    case IS_EQUAL_TO:
      numberOfDays = 1;
      break;
    case IN_NEXT_TIME_UNIT:
      return false;
    case IN_THIS_TIME_UNIT:
      numberOfDays = maxDateValues[propertyFilterOperation.get('timeUnit')] * propertyFilterOperation.get('timeUnitCount');
      break;
    case IN_LAST_TIME_UNIT:
      numberOfDays = maxDateValues[propertyFilterOperation.get('timeUnit')] * propertyFilterOperation.get('timeUnitCount');
      break;
    case IN_THIS_TIME_UNIT_SO_FAR:
      numberOfDays = I18n.moment().diff(I18n.moment().startOf(propertyFilterOperation.get('timeUnit').toLowerCase()), 'days') + 1;
      break;
    default:
      numberOfDays = 1;
  }
  if (!numberOfDays) {
    return false;
  }
  return numberOfDays >= 1 && numberOfDays <= maxDays;
};
export const getEventDateTableLabel = () => I18n.text('reporting-snowflake.filter-editor.event-date-filter.table-label');
export const getOperators = () => OrderedSet([InRollingDateRange, Equal, InRange, LessRolling]);
export const toObjectSegFilterStrict = eventDateInterval => Filter({
  id: -1,
  field: Field({
    table: getEventDateTableLabel(),
    name: EVENT_DATE_PROPERTY_NAME,
    type: 'datetime'
  }),
  filter: ImmutableMap({
    filterType: 'PROPERTY',
    property: EVENT_DATE_PROPERTY_NAME,
    operation: eventDateInterval.propertyFilterOperation
  })
});
export const toEventDateInterval = value => EventDateInterval({
  propertyFilterOperation: value.filter.get('operation')
});