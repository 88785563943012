'use es6';

import { DEAL_PROPERTIES } from 'reference-resolvers/constants/CacheKeys';
import { getDealProperties, createGetDealProperties } from 'reference-resolvers/api/PropertiesAPI';
import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
export const createDealPropertyReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: DEAL_PROPERTIES,
  createFetchData: createGetDealProperties,
  fetchData: getDealProperties
}, options));
export default createDealPropertyReferenceResolver();