'use es6';

import { Map as ImmutableMap } from 'immutable';
import I18n from 'I18n';
import { SOCIAL_POSTS } from '../../constants/dataTypes';
const prefix = key => `reporting-data.properties.${key}.count`;
const specificLabels = ImmutableMap({
  [SOCIAL_POSTS]: prefix('socialPosts')
});
const getLabel = dataType => specificLabels.has(dataType) ? I18n.text(specificLabels.get(dataType)) : I18n.text(prefix('common'), {
  object: I18n.SafeString(I18n.text(`reporting-data.dataTypes.${dataType}`))
});
export default (dataType => ImmutableMap({
  count: ImmutableMap({
    name: 'count',
    label: getLabel(dataType),
    type: 'number'
  })
}));
export const customObjectCountProperty = pluralName => ImmutableMap({
  count: ImmutableMap({
    name: 'count',
    label: I18n.text('reporting-data.properties.common.count', {
      object: pluralName
    }),
    type: 'number'
  })
});