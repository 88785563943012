'use es6';

import PropTypes from 'prop-types';
export const DraftContextPropType = PropTypes.shape({
  getEditorState: PropTypes.func,
  getReadOnly: PropTypes.func,
  setReadOnly: PropTypes.func,
  onChange: PropTypes.func,
  focus: PropTypes.func,
  blur: PropTypes.func,
  editorRef: PropTypes.object
}).isRequired;