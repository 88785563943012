/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import { useCallback } from 'react';
import { updateReportWithContext } from './context-report-sync';
import { applyContextChange } from './apply-context-change';
import { flattenQueryParams } from './queryParams';
import { useScopes } from './use-scopes';
import { useGates } from './use-gates';
import { ACTIVE_CONTROL_GATES } from './access';
export const useUpdateContextInReportCallback = ({
  report,
  onChange,
  subAppKey,
  context,
  track,
  defaultContext
}) => {
  const scopes = useScopes();
  const {
    gates: filteredGates
  } = useGates(ACTIVE_CONTROL_GATES);
  return useCallback(contextChange => {
    const newContext = applyContextChange(context, contextChange, subAppKey, report, scopes, filteredGates, defaultContext);
    const updatedReport = updateReportWithContext(report, newContext, filteredGates, scopes);
    onChange(updatedReport);
    if (track) {
      const flatContextChange = flattenQueryParams(contextChange);
      Object.keys(flatContextChange).forEach(key => {
        track('updateSalesReportContext', {
          action: key,
          value: String(flatContextChange[key])
        });
      });
    }
  }, [report, onChange, track, subAppKey, context, scopes, defaultContext, filteredGates]);
};