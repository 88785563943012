export const NONE = 'NONE';
export const SUMMARY = 'SUMMARY';
export const PIE = 'PIE';
export const LINE = 'LINE';
export const BAR = 'BAR';
export const FUNNEL = 'FUNNEL';
export const STREAM = 'STREAM';
export const AREA = 'AREA';
export const TABLE = 'TABLE';
export const COLUMN = 'COLUMN';
export const DATA_WELL = 'DATA_WELL';
export const DONUT = 'DONUT';
export const COMBINATION = 'COMBINATION';
export const CUSTOM = 'CUSTOM';
export const SANKEY = 'SANKEY';
export const GAUGE = 'GAUGE';