module.exports = {
  "externalLinkClick": {
    "name": "external-link-click",
    "class": "interaction",
    "properties": {
      "trackingSource": {
        "type": "string",
        "isOptional": true
      },
      "name": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "communicationMethodsInteraction": {
    "name": "communication-methods",
    "class": "interaction",
    "properties": {
      "isRetail": {
        "type": "boolean",
        "isOptional": true
      },
      "meetingLink": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "contactSalesButtonInteraction": {
    "name": "contact-sales-button",
    "class": "interaction",
    "properties": {
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "termId": {
        "type": "number",
        "isOptional": true
      },
      "additionalUnitQuantity": {
        "type": "number",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "pricingPageRedirectButtonInteraction": {
    "name": "pricing-page-redirect-button",
    "class": "interaction",
    "properties": {
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "productActivationButtonInteraction": {
    "name": "product-activation-button",
    "class": "interaction",
    "namespace": "upgrades",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "productActivationInteraction": {
    "name": "product-activation",
    "class": "interaction",
    "namespace": "upgrades",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "trialActivationButtonInteraction": {
    "name": "trial-activation-button",
    "class": "interaction",
    "properties": {
      "trialId": {
        "type": "number",
        "isOptional": true
      },
      "isMultiTrial": {
        "type": "boolean",
        "isOptional": true
      },
      "activeTrials": {
        "type": "array",
        "isOptional": true
      },
      "expiredTrials": {
        "type": "array",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "trialActivationInteraction": {
    "name": "trial",
    "class": "interaction",
    "properties": {
      "trialId": {
        "type": "number",
        "isOptional": true
      },
      "isMultiTrial": {
        "type": "boolean",
        "isOptional": true
      },
      "activeTrials": {
        "type": "array",
        "isOptional": true
      },
      "expiredTrials": {
        "type": "array",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "features": {
        "type": "array",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "trialActionPromptInteraction": {
    "name": "trial-action-prompt",
    "class": "interaction",
    "properties": {
      "numUsers": {
        "type": "number",
        "isOptional": true
      },
      "numContacts": {
        "type": "number",
        "isOptional": true
      },
      "isMultiTrial": {
        "type": "boolean",
        "isOptional": true
      },
      "activeTrials": {
        "type": "array",
        "isOptional": true
      },
      "expiredTrials": {
        "type": "array",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "trialModalInteraction": {
    "name": "trial-modal",
    "class": "interaction",
    "properties": {
      "trialId": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "trialActivationRedirected": {
    "name": "trial-activation-redirected",
    "class": "interaction",
    "properties": {
      "trialId": {
        "type": "number",
        "isOptional": true
      },
      "redirectTarget": "string",
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "viewUpgradeAdminPrompt": {
    "name": "view-upgrade-admin-prompt",
    "class": "interaction",
    "properties": {
      "trialId": {
        "type": "number",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "modalType": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "checkoutButtonInteraction": {
    "name": "checkout-button",
    "class": "interaction",
    "properties": {
      "apiNames": {
        "isOptional": true,
        "type": "string"
      },
      "upgradeProducts": {
        "isOptional": true,
        "type": "string"
      },
      "currencyCode": {
        "isOptional": true,
        "type": "string"
      },
      "offerId": {
        "type": "number",
        "isOptional": true
      },
      "isQuantityUpgrade": {
        "type": "boolean",
        "isOptional": true
      },
      "productNames": {
        "type": "array",
        "isOptional": true
      },
      "offerIds": {
        "type": "array",
        "isOptional": true
      },
      "skuIds": {
        "type": "array",
        "isOptional": true
      },
      "productBasedOfferIds": {
        "type": "array",
        "isOptional": true
      },
      "checkoutType": [
        "buy-now",
        "full"
      ],
      "additionalUnitQuantity": {
        "type": "number",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "errorInteraction": {
    "name": "error",
    "class": "interaction",
    "properties": {
      "name": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "pqlInteraction": {
    "name": "pql",
    "class": "interaction",
    "properties": {
      "isAssignable": {
        "type": "boolean",
        "isOptional": true
      },
      "isRetail": {
        "type": "boolean",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "requestUpgradeInteraction": {
    "name": "request-upgrade",
    "class": "interaction",
    "properties": {
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "addAdditionalSeatsClick": {
    "name": "add-additional-seats-click",
    "class": "interaction",
    "properties": {
      "trackingSource": "string",
      "isValid": "boolean",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "interaction": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "isAssignable": {
        "type": "boolean",
        "isOptional": true
      },
      "limit": {
        "type": "number",
        "isOptional": true
      },
      "value": {
        "type": "number",
        "isOptional": true
      },
      "displayThreshold": {
        "type": "number",
        "isOptional": true
      },
      "hasReachedLimit": {
        "type": "boolean",
        "isOptional": true
      },
      "isPrimaryCta": {
        "type": "boolean",
        "isOptional": true
      },
      "isSecondaryCta": {
        "type": "boolean",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  },
  "featureTableInteraction": {
    "name": "feature-table-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-expand-all-sections",
        "clicked-expand-section",
        "clicked-expand-table",
        "clicked-collapse-all-sections",
        "clicked-collapse-section",
        "clicked-collapse-table"
      ],
      "productLine": {
        "type": "string",
        "isOptional": true
      },
      "products": {
        "type": "array",
        "isOptional": true
      },
      "currentPlan": {
        "type": "string",
        "isOptional": true
      },
      "recommendedPlan": {
        "type": "string",
        "isOptional": true
      },
      "showRecommended": {
        "type": "boolean",
        "isOptional": true
      },
      "subCategory": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "tab": {
        "type": [
          "starter",
          "enterprise"
        ],
        "isOptional": true
      },
      "trackingSource": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "upgradeProduct": {
        "type": "string",
        "isOptional": true
      },
      "apiName": {
        "type": "string",
        "isOptional": true
      },
      "upgradeSource": {
        "type": "string",
        "isOptional": true
      },
      "upgradeKey": {
        "type": "string",
        "isOptional": true
      },
      "uniqueId": {
        "type": "string",
        "isOptional": true
      },
      "pointType": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": "string",
        "isOptional": true
      },
      "hub": {
        "type": "string",
        "isOptional": true
      },
      "repName": {
        "type": "string",
        "isOptional": true
      },
      "feature": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "upgrades"
  }
};