'use es6';

import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { PRODUCT } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { searchObjects, createSearchObjects } from 'reference-resolvers/api/ContactSearchAPI';
import { getProductsByIds, createGetProductsByIds } from '../api/ProductsAPI';
export const createProductReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.PRODUCTS,
  createFetchByIds: createGetProductsByIds,
  createFetchSearchPage: opts => createSearchObjects(opts)(PRODUCT),
  fetchByIds: getProductsByIds,
  fetchSearchPage: searchObjects(PRODUCT),
  maxBatchSize: 100
}, options));
export default createProductReferenceResolver();