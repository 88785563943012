'use es6';

const objectIdSymbol = Symbol('objectId');
const addSymbolId = object => {
  if (object[objectIdSymbol] == null) {
    object[objectIdSymbol] = Symbol('id');
  }
};
const getSymbolId = object => {
  return object[objectIdSymbol];
};
class SymbolObjectSet {
  constructor() {
    this.set = {};
  }
  add(object) {
    addSymbolId(object);
    this.set[getSymbolId(object)] = object;
  }
  remove(object) {
    const symbol = getSymbolId(object);
    if (symbol != null) {
      delete this.set[symbol];
    }
  }
  has(object) {
    const symbol = getSymbolId(object);
    return symbol != null && this.set[symbol] != null;
  }
  forEach(operation) {
    const symbols = Object.getOwnPropertySymbols != null ? Object.getOwnPropertySymbols(this.set) : Object.keys(this.set);
    for (const symbol of symbols) {
      const value = this.set[symbol];
      if (getSymbolId(value) === symbol) {
        operation(value);
      }
    }
  }
}
export default SymbolObjectSet;