'use es6';

import { Map as ImmutableMap } from 'immutable';
import * as DataTypes from '../../../constants/dataTypes';
import * as UnifiedAnalyticsDataTypes from '../../../constants/dataTypes/unified';
export const LINKS_FIELD = '$links';
export const getIsAttributionType = dataType => [DataTypes.ATTRIBUTION_TOUCH_POINTS, DataTypes.CONTACT_CREATE_ATTRIBUTION, DataTypes.DEAL_CREATE_ATTRIBUTION].includes(dataType);
const idFieldMap = ImmutableMap({
  [DataTypes.CONTACTS]: 'vid',
  [DataTypes.COMPANIES]: 'company-id',
  [DataTypes.DEALS]: 'dealId',
  [DataTypes.ENGAGEMENT]: 'engagement.id',
  [DataTypes.ENGAGEMENTS]: 'hs_unique_id',
  [DataTypes.TICKETS]: 'hs_ticket_id',
  [DataTypes.LINE_ITEMS]: 'objectId',
  [DataTypes.EMAIL]: 'id'
});
export const objectTypeIdLinkableDatatypes = idFieldMap.keySeq().toList();
export const getIdColumnFromDataType = dataType => {
  if (UnifiedAnalyticsDataTypes[dataType]) {
    return 'breakdown';
  }
  return idFieldMap.get(dataType);
};
export const getIdColumnOrObjectTypeIdFromDataType = (dataType, dataRow) => {
  return dataRow && dataRow.has('hs_object_id') ? 'hs_object_id' : getIdColumnFromDataType(dataType);
};