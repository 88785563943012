'use es6';

import isEmpty from 'transmute/isEmpty';
import CustomWidgets from 'reporting-ui-components/components/customWidgets/constants/widgets';
import { getBreakdownPropertiesForReport, getCustomWidgetType } from '../salesAnalyticsHelpers';
export function validateBreakdown(contextChange, context, report, subAppKey, defaultContext) {
  const breakdown = contextChange.breakdown != null ? contextChange.breakdown : context.breakdown;
  const grouping = contextChange.grouping || context.grouping;
  const dimensionOptions = Object.keys(getBreakdownPropertiesForReport(subAppKey));
  const validDimensions = grouping ? dimensionOptions.filter(option => option !== grouping) : dimensionOptions;
  if (!validDimensions.includes(breakdown) || isEmpty(breakdown) && getCustomWidgetType(report) === CustomWidgets.PIPELINE_SNAPSHOTS) {
    const breakdownFallback = validDimensions.includes(defaultContext.breakdown) ? defaultContext.breakdown : null;
    return Object.assign({}, contextChange, {
      breakdown: breakdownFallback
    });
  }
  return contextChange;
}