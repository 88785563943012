'use es6';

import { Iterable } from 'immutable';
const transformDeep = (value, transform = a => a) => typeof value === 'object' && value != null ? Array.isArray(value) ? value.map(v => transformDeep(v, transform)) : Object.keys(value).reduce((o, key) => {
  const v = value[key];
  o[key] = transformDeep(v, transform);
  return o;
}, {}) : transform(value);
const sortDeep = value => typeof value === 'object' && value != null ? Array.isArray(value) ? value.map(sortDeep) : Object.keys(value).sort().reduce((o, key) => {
  const v = value[key];
  o[key] = sortDeep(v);
  return o;
}, {}) : value;
export const replaceFunctions = (obj, rename) => transformDeep(obj, value => typeof value === 'function' ? rename : value);

/**
 * @param {any?} subject - optional
 * @param {[]} args
 * @returns {string?} - optional
 */
export const stableStringify = (subject, ...args) => {
  const subjectJS = Iterable.isIterable(subject) ? subject.toJS() : subject;
  return JSON.stringify(sortDeep(subjectJS), ...args);
};