'use es6';

import http from 'hub-http/clients/apiClient';
import { fromJS, Map as ImmutableMap } from 'immutable';
import PortalIdParser from 'PortalIdParser';
import { registerMutation, registerQuery } from 'data-fetching-client';
import { addReportsToDashboard, createDashboard, fetchSingleDashboard } from './dashboards';
import { EXISTING_DASHBOARD, NEW_DASHBOARD, NONE } from '../../reportingActions/saveReport/dialog/destinationTypes';
import { getId as getDashboardId } from '../schemas/dashboardSchema';
import { getId as getReportId } from '../schemas/reportSchema';
import { toState } from '../../permissions/permission-payload';
import { stripNonUserAddedEditors } from '../../lib/report-logic';
const REPORT_HYDRATION_PARAMS = {
  USER_PERMISSION_LEVEL: 'USER_PERMISSION_LEVEL'
};

// TO DO: Lines 13-24 are copied over from dashboard-lib; Reorganize in follow up PRs
const setRequiredFields = report => report.update('id', id => id || null).update('portalId', portalId => portalId || PortalIdParser.get()).update('createdAt', createdAt => createdAt || null).update('active', active => active === undefined ? true : active);
export const SINGLE_REPORT_FIELD_NAME = 'report';
export const REPORT_ACCESS_SETTINGS_FIELD_NAME = 'reportAccessSettings';
export const ALL_REPORTS_FIELD_NAME = 'reports';
export const RESTORE_REPORTS_FIELD_NAME = 'restoreReports';
export const ALL_REPORT_TEMPLATES_FIELD_NAME = 'reportTemplates';
export const MOVE_REPORT_FIELD_NAME = 'moveReport';
const REPORT_URL = 'dashboard/v2/reports';
const REPORT_TEMPLATES_URL = 'reporting-discovery/v1/report-template';
const REPORT_BUILDER_URL = 'sql-reporting/v1/report-builder/cache';

//NOTE: When reportIds is empty, the action applies to all reports (filtered by the query string)
const bulkChangeReportOwner = (reportIds, ownerId, query) => http.put(`${REPORT_URL}/owner`, {
  data: {
    objectIds: reportIds,
    ownerId
  },
  query
});
const bulkRestoreReport = reportIds => http.post(`${REPORT_URL}/restore`, {
  data: reportIds
});
const createReport = report => http.post(REPORT_URL, {
  data: setRequiredFields(report).toJS(),
  query: {
    hydrate: [REPORT_HYDRATION_PARAMS.USER_PERMISSION_LEVEL]
  }
});

//NOTE: When reportIds is empty, the action applies to all reports (filtered by the query string)
const bulkDeleteReport = (reportIds, query, deleteAll) => http.post(`${REPORT_URL}/bulk-delete${deleteAll ? '?deleteAll=true' : ''}`, {
  data: reportIds,
  query
});
const fetchAllReports = () => http.get(REPORT_URL);
const updateReport = (reportId, report) => http.put(`${REPORT_URL}/${reportId}`, {
  data: setRequiredFields(report).toJS()
});
const fetchReportAccessSettings = reportId => http.get(`${REPORT_URL}/${reportId}/permission`);

//NOTE: When reportIds is empty, the action applies to all reports (filtered by the query string)
const bulkUpdateReportAccessSettings = (reportIds, accessConfig, query) => http.put(`${REPORT_URL}/permission`, {
  data: Object.assign({
    reportIds
  }, accessConfig),
  query
}).then(fromJS);
const fetchReportTemplates = () => http.get(REPORT_TEMPLATES_URL);
export const removeFromDashboard = (reportId, dashboardId) => http.delete(`dashboard/v2/dashboard/${dashboardId}/report/${reportId}`).then(fromJS);
export const moveReport = (oldDashboardId, newDashboardId, reportId) => {
  return removeFromDashboard(reportId, oldDashboardId).then(() => {
    return addReportsToDashboard({
      reportIds: [reportId],
      dashboardId: newDashboardId
    });
  });
};
export const deleteReportCache = reportId => {
  return http.delete(`${REPORT_BUILDER_URL}/${reportId}`).then(fromJS);
};
export const MOVE_REPORT = registerMutation({
  fieldName: MOVE_REPORT_FIELD_NAME,
  args: ['oldDashboardId', 'newDashboardId', 'reportId'],
  fetcher({
    oldDashboardId,
    newDashboardId,
    reportId
  }) {
    return moveReport(oldDashboardId, newDashboardId, reportId);
  }
});

// @reportId {Number}
// @ownerId {Number}
// @filters {Object} When no reportId is supplied, change owner applies to all reports, filtered by the
//   values supplied by the filters object (bulk actions)
export const CHANGE_REPORT_OWNER = registerMutation({
  fieldName: SINGLE_REPORT_FIELD_NAME,
  args: ['reportIds', 'ownerId', 'filters'],
  fetcher({
    reportIds,
    ownerId,
    filters = null
  }) {
    return bulkChangeReportOwner(reportIds, ownerId, filters).then(() => ImmutableMap({
      ownerId
    }));
  }
});
export const ADD_REPORTS_TO_DASHBOARD = registerMutation({
  fieldName: SINGLE_REPORT_FIELD_NAME,
  args: ['reportIds', 'dashboardId', 'filters'],
  fetcher({
    reportIds,
    dashboardId,
    filters = null
  }) {
    return addReportsToDashboard({
      reportIds,
      dashboardId,
      query: filters
    });
  }
});

// @reportId {Number}
export const DELETE_REPORT = registerMutation({
  fieldName: SINGLE_REPORT_FIELD_NAME,
  args: ['reportIds', 'filters'],
  fetcher({
    reportIds,
    filters = null,
    deleteAll = false
  }) {
    return bulkDeleteReport(reportIds, filters, deleteAll);
  }
});
export const FETCH_ALL_REPORTS = registerQuery({
  fieldName: ALL_REPORTS_FIELD_NAME,
  fetcher() {
    return fetchAllReports().then(response => {
      return fromJS(response.reports);
    });
  }
});
export const FETCH_ALL_REPORT_TEMPLATES = registerQuery({
  fieldName: ALL_REPORT_TEMPLATES_FIELD_NAME,
  fetcher() {
    return fetchReportTemplates().then(response => {
      return fromJS(response);
    });
  }
});

// @report {Object} The original report
// @reportId {Number}
// @newName {String} The new name of the report
// @returns {Map} The normalized report with the updated name
export const RENAME_REPORT = registerMutation({
  fieldName: SINGLE_REPORT_FIELD_NAME,
  args: ['reportId', 'renamedReport'],
  fetcher({
    reportId,
    renamedReport
  }) {
    return updateReport(reportId, renamedReport).then(fromJS);
  }
});
export const RESTORE_REPORT = registerMutation({
  fieldName: RESTORE_REPORTS_FIELD_NAME,
  args: ['reportIds'],
  fetcher({
    reportIds
  }) {
    return bulkRestoreReport(reportIds);
  }
});
export const FETCH_REPORT_ACCESS_SETTINGS = registerQuery({
  fieldName: REPORT_ACCESS_SETTINGS_FIELD_NAME,
  args: ['reportId'],
  fetcher({
    reportId
  }) {
    return reportId ? fetchReportAccessSettings(reportId).then(fromJS).then(toState) : {};
  }
});

// @newReport {Object} The updated report
// @returns {Map} The normalized updated report
export const UPDATE_REPORT = registerMutation({
  fieldName: SINGLE_REPORT_FIELD_NAME,
  args: ['newReport'],
  fetcher({
    newReport
  }) {
    return updateReport(getReportId(newReport), stripNonUserAddedEditors(newReport)).then(fromJS);
  }
});
export const UPDATE_REPORT_ACCESS_SETTINGS = registerMutation({
  fieldName: REPORT_ACCESS_SETTINGS_FIELD_NAME,
  args: ['reportIds', 'accessConfig', 'filters'],
  fetcher({
    reportIds,
    accessConfig,
    filters
  }) {
    return bulkUpdateReportAccessSettings(reportIds, accessConfig, filters).then(() => fromJS(accessConfig));
  }
});

// @dashboardName {String} The name of the new dashboard the user is creating
// @permissionConfig { {accessClassification: String, permissionGrants: Array, permissionLevel: String}}
// @destinationType {String} One of constants defined in destinationTypes file
// @report {Object}
// @targetDashboardId {Number} The id of the existing dashboard that the report should be added to
// @userId {Number}
// @returns {Map{
//   dashboard: Map(),
//   destinationType: String,
//   report: Map(),
// }}
export const SAVE_NEW_REPORT = registerMutation({
  fieldName: SINGLE_REPORT_FIELD_NAME,
  args: ['dashboardName', 'destinationType', 'permissionConfig', 'report', 'targetDashboardId', 'userId'],
  fetcher({
    dashboardName,
    destinationType,
    permissionConfig,
    report,
    targetDashboardId,
    userId
  }) {
    let dashboardRequest = Promise.resolve(null);
    const reportRequest = createReport(report).then(fromJS);
    if (destinationType === EXISTING_DASHBOARD) {
      dashboardRequest = fetchSingleDashboard(targetDashboardId, {
        hydrate: ''
      });
    } else if (destinationType === NEW_DASHBOARD) {
      dashboardRequest = createDashboard({
        dashboardName,
        userId,
        dashboardPermissionConfig: permissionConfig,
        widgets: []
      });
    }
    return Promise.all([reportRequest, dashboardRequest]).then(([newReport, dashboardResponse]) => {
      const dashboard = fromJS(dashboardResponse);
      if (destinationType === NONE) {
        return fromJS({
          report: newReport,
          dashboard,
          destinationType,
          permissionConfig
        });
      }
      return addReportsToDashboard({
        reportIds: [getReportId(newReport)],
        dashboardId: dashboard ? getDashboardId(dashboard) : null
      }).then(() => fromJS({
        report: newReport,
        dashboard,
        destinationType,
        permissionConfig
      }));
    });
  }
});