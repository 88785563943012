'use es6';

import get from 'transmute/get';
import { List } from 'immutable';
import useUserInfo from '../../useUserInfo';
const useScopes = () => {
  const {
    data
  } = useUserInfo();
  return get('scopes', data) || List();
};
export default useScopes;