export const CONTACT = '0-1';
export const COMPANY = '0-2';
export const DEAL = '0-3';
export const ENGAGEMENT = '0-4';
export const TICKET = '0-5';
export const LINE_ITEM = '0-8';
export const CONVERSATION = '0-11';
export const LANDING_PAGE = '0-25';
export const MARKETING_EMAIL = '0-29';
export const FORMS = '0-28';
export const FORM_SUBMISSION = '0-15';
export const AD_ACCOUNT = '0-30';
export const AD_CAMPAIGN = '0-31';
export const AD_GROUP = '0-32';
export const AD = '0-33';
export const KEYWORD = '0-34';
export const CAMPAIGN = '0-35';
export const SITE_PAGE = '0-38';
export const BLOG_POST = '0-39';
export const MEETING_EVENT = '0-47';
export const CALL = '0-48';
export const SEQUENCE = '0-58';
export const MEDIA = '0-57';
export const SUBSCRIPTION = '0-69';
export const DEAL_SPLIT = '0-72';
export const TASK = '0-27';
export const CART = '0-142';
export const ORDER = '0-123';
export const PARTNER_CLIENT = '0-145';
export const LISTS = '0-45';
export const USER = '0-115';
export const MEETING_BOOKED = '4-78877';
export const V2_CONTACT_REPLIED_SEQUENCE_EMAIL_QA = '4-83769';
export const V2_CONTACT_REPLIED_SEQUENCE_EMAIL_PROD = '4-675772';
export const V2_CONTACT_BOOKED_MEETING_THROUGH_SEQUENCE_QA = '4-83770';
export const V2_CONTACT_BOOKED_MEETING_THROUGH_SEQUENCE_PROD = '4-675773';
export const V2_CONTACT_UNSUBSCRIBED_SEQUENCE_EMAIL_QA = '4-83771';
export const V2_CONTACT_UNSUBSCRIBED_SEQUENCE_EMAIL_PROD = '4-675774';
export const V2_CONTACT_UNENROLLED_MANUALLY_FROM_SEQUENCE_QA = '4-83772';
export const V2_CONTACT_UNENROLLED_MANUALLY_FROM_SEQUENCE_PROD = '4-675775';
export const V2_CONTACT_UNENROLLED_FROM_SEQUENCE_QA = '4-83773';
export const V2_CONTACT_UNENROLLED_FROM_SEQUENCE_PROD = '4-675776';
export const V2_CONTACT_ENROLLED_IN_SEQUENCE_QA = '4-83774';
export const V2_CONTACT_ENROLLED_IN_SEQUENCE_PROD = '4-675777';
export const V2_CONTACT_FINISHED_SEQUENCE_QA = '4-83775';
export const V2_CONTACT_FINISHED_SEQUENCE_PROD = '4-675778';
export const V2_SEQUENCE_ERRORED_QA = '4-83776';
export const V2_SEQUENCE_ERRORED_PROD = '4-675779';
export const V2_SEQUENCE_STEP_EXECUTED_QA = '4-83777';
export const V2_SEQUENCE_STEP_EXECUTED_PROD = '4-675780';
export const V2_SEQUENCE_EMAIL_BOUNCED_QA = '4-83778';
export const V2_SEQUENCE_EMAIL_BOUNCED_PROD = '4-675781';
export const SENT_TRACKED_INBOX_EMAIL_V8_QA = '4-530144';
export const SENT_TRACKED_INBOX_EMAIL_V8_PROD = '4-1367006';
export const OPENED_TRACKED_INBOX_EMAIL_V8_QA = '4-530145';
export const OPENED_TRACKED_INBOX_EMAIL_V8_PROD = '4-1367314';
export const CLICKED_LINK_IN_TRACKED_INBOX_EMAIL_V8_QA = '4-530146';
export const CLICKED_LINK_IN_TRACKED_INBOX_EMAIL_V8_PROD = '4-1367771';
export const REPLIED_TO_TRACKED_INBOX_EMAIL_V8_QA = '4-530147';
export const REPLIED_TO_TRACKED_INBOX_EMAIL_V8_PROD = '4-1367930';
export const CALL_MENTIONED_KEYWORD = '4-534173';
export const MEDIA_PLAYED_QA = '4-83781';
export const MEDIA_PLAYED_PROD = '4-675783';
export const AD_INTERACTION_QA = '4-706542';
export const AD_INTERACTION_PROD = '4-1553675';
export const CLICKED_CTA_QA = '4-54520';
export const CLICKED_CTA_PROD = '4-100216';
export const VIEWED_CTA_QA = '4-54519';
export const VIEWED_CTA_PROD = '4-100215';
export const V4_MARKETING_CAMPAIGN_INFLUENCES_QA = '4-722622';
export const V4_MARKETING_CAMPAIGN_INFLUENCES_PROD = '4-1563645';