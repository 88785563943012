// @ts-expect-error migrate upstream
import Dispatcher from 'dispatcher/dispatcher';
/**
 * Creates a function that first creates an action, then dispatches that action.
 * Uses hubspot.flux.dispatcher.
 * @param actionCreator - a function that returns an action
 * @returns {Function} - a function that dispatches an action
 */
const createDispatcher = actionCreator => (...args) => {
  Dispatcher.dispatch(actionCreator(...args));
};
export default ((promise, stageActionCreators, shouldReturnPromise = false) => (...promiseArgs) => {
  const {
    pending,
    success,
    failure
  } = stageActionCreators;
  const dispatchPending = createDispatcher(pending);
  const dispatchSuccess = createDispatcher(success);
  const dispatchFailure = createDispatcher(failure);
  dispatchPending();
  const chain = promise(...promiseArgs).then(dispatchSuccess).catch(dispatchFailure);
  return shouldReturnPromise ? chain : chain.catch(console.error);
});