'use es6';

import { fromJS } from 'immutable';
import prefix from '../../lib/prefix';
import { Promise } from '../../lib/promise';
import sources from '../partial/contacts-sources';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
const translate = prefix('reporting-data.properties.attribution');
const translateGroup = prefix('reporting-data.groups.attribution');
const dimensions = () => [{
  name: 'REFERRER',
  type: 'string',
  label: translate('referrer')
}, {
  name: 'URL',
  type: 'string',
  label: translate('url')
}, {
  name: 'SOURCE',
  type: 'enumeration',
  label: translate('source'),
  options: sources().toJS()
}];
const metrics = () => [{
  name: 'contactsAssisted',
  type: 'number',
  label: translate('contactsAssisted')
}, {
  name: 'percentOfContacts',
  type: 'percent',
  label: translate('percentOfContacts')
}, {
  name: 'normalizedScore',
  type: 'number',
  label: translate('normalizedScore')
}];
export const getPropertyGroups = () => Promise.resolve(fromJS([{
  name: 'attributionInfo',
  displayName: translateGroup('attributionInfo'),
  displayOrder: 0,
  hubspotDefined: true,
  properties: [...dimensions(), ...metrics()]
}]));
export const getProperties = createPropertiesGetterFromGroups(getPropertyGroups, properties => properties);