import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { isUninitialized } from 'reporting-data/asyncData/AsyncData';
import { usePlatformDashboardSelector } from 'platform-dashboard-ui/store';
import { selectDataSourcesMetadataWithUniqueLabels } from 'platform-dashboard-ui/ducks/dataSourceMetadataSlice';
import { RequestState } from 'platform-dashboard-ui/filter/requests/request-state';

// @ts-expect-error migrate upstream file
import { selectHasBackendAppliedFiltersAccess } from 'dashboard-lib/public/userInfo/gates';
// @ts-expect-error migrate upstream file
import { selectAllGates } from 'dashboard-lib/public/userInfo';
import { getReportDataSourceUsages } from '../../lib/dashboardFilters/dataSourceHelpers';
import { transformDashboardFilterToSavedFilter, transformSavedFilterToDashboardFilter } from '../../lib/savedDashboardFilters';
import { selectDashboardFilters, selectDashboardFrequency, selectDashboardFrequencyUseFiscalYear } from '../applied-dashboard-filters';
import { selectBackendAppliedFiltersForReport, selectDashboardReportsAsyncStatus, selectOverriddenDashboardReport, selectValidateFilterApplicationAsyncState } from './selectors';
import { getOverriddenReportsFromAPIThunk, validateBEAppliedDashboardFiltersThunk } from './thunks';
import { selectDataSourcesProperties, selectDataSourcesPropertiesStatus } from 'platform-dashboard-ui/ducks/dataSourcePropertiesSlice';
import { applyDashboardFilters } from '../../lib/dashboardFilters/applyDashboardFilters';
// @ts-expect-error migrate upstream
import { selectQuickFilterGroups } from '../quickFilters';
import { checkIfQuickFilterGroupIsApplied, isDateRangeGeneratedQuickFilterGroup } from '../../lib/dashboardFilters/groupQuickFilterHelpers';
import { DYNAMIC_DATE_RANGE_PROPERTY_NAME } from '../../lib/dashboardFilters/types';

// if date range group is applied, simplify payload to only include the dyanmic date range filter
export const useBackendFilterPayload = dashboardId => {
  const appliedFilters = useSelector(selectDashboardFilters(dashboardId));
  const quickFilters = useSelector(selectQuickFilterGroups);
  const appliedFrequency = useSelector(selectDashboardFrequency(dashboardId));
  const appliedFrequencyFiscalYear = useSelector(selectDashboardFrequencyUseFiscalYear(dashboardId));
  const maybeDateRangeQuickFilter = quickFilters.find(isDateRangeGeneratedQuickFilterGroup);
  const isDateRangeQuickFilterApplied = maybeDateRangeQuickFilter ? checkIfQuickFilterGroupIsApplied(maybeDateRangeQuickFilter, appliedFilters) : false;
  const transformedFilters = (() => {
    const apiFilters = transformDashboardFilterToSavedFilter(appliedFilters);
    if (isDateRangeQuickFilterApplied && maybeDateRangeQuickFilter) {
      // replace filters with property in date range quick filter with a single date range filter

      const apiFiltersMinusDateRangeFilter = apiFilters.filter(filter => {
        return !maybeDateRangeQuickFilter.properties.some(property => filter.dataSourceId === property.dataSourceId && filter.name === property.name);
      });
      const dateRangeFilters = apiFilters.filter(filter => {
        return maybeDateRangeQuickFilter.properties.some(property => filter.dataSourceId === property.dataSourceId && filter.name === property.name);
      });
      const dateRangeFilter = Object.assign({}, dateRangeFilters[0], {
        dataSourceId: undefined,
        name: DYNAMIC_DATE_RANGE_PROPERTY_NAME
      });
      return [dateRangeFilter, ...apiFiltersMinusDateRangeFilter];
    }
    return apiFilters;
  })();
  return {
    // TODO add dyanamic date range filter as allowed type
    filters: transformedFilters,
    frequency: appliedFrequency,
    useFiscalYear: appliedFrequencyFiscalYear,
    dashboardId
  };
};
export const useValidateBEDashboardFilterApplication = (dashboardId, originalReports) => {
  const dispatch = useDispatch();
  const appliedDashboardFilters = useSelector(selectDashboardFilters(dashboardId));
  const gates = useSelector(selectAllGates);
  const frequency = useSelector(selectDashboardFrequency(dashboardId));
  const frequencyUseFiscalYear = useSelector(selectDashboardFrequencyUseFiscalYear(dashboardId));
  const reportDataSourceUsages = getReportDataSourceUsages({
    reports: originalReports
  });
  const validateFilterApplicationAsyncState = useSelector(selectValidateFilterApplicationAsyncState(dashboardId));
  const reportDataSourceIds = Object.keys(reportDataSourceUsages);
  const dataSourcesProperties = usePlatformDashboardSelector(selectDataSourcesProperties(reportDataSourceIds));
  const dataSourcesPropertiesStatus = usePlatformDashboardSelector(selectDataSourcesPropertiesStatus(reportDataSourceIds));
  const areDataSourcesPropertiesLoaded = dataSourcesPropertiesStatus === RequestState.SUCCEEDED;
  const currentAppliedFiltersBackendPayload = useBackendFilterPayload(dashboardId);
  const reportsWithFEAppliedFilters = useMemo(() => {
    try {
      if (!areDataSourcesPropertiesLoaded) {
        return [];
      }
      return originalReports.map(report => {
        return applyDashboardFilters({
          dashboardFilters: appliedDashboardFilters,
          dataSourcesProperties,
          report,
          gates,
          frequency,
          frequencyUseFiscalYear
        }).overriddenReport;
      });
    } catch (error) {
      console.error(error);
      return [];
    }
  }, [areDataSourcesPropertiesLoaded, appliedDashboardFilters, dataSourcesProperties, frequency, frequencyUseFiscalYear, gates, originalReports]);
  const hasAppliedFilters = currentAppliedFiltersBackendPayload.filters.length > 0 || currentAppliedFiltersBackendPayload.frequency != null && reportsWithFEAppliedFilters.length > 0;
  useEffect(() => {
    if (isUninitialized(validateFilterApplicationAsyncState) && hasAppliedFilters && areDataSourcesPropertiesLoaded) {
      dispatch(validateBEAppliedDashboardFiltersThunk({
        reportsWithFEAppliedFilters,
        filterSet: currentAppliedFiltersBackendPayload,
        dashboardId
      })).catch(error => {
        console.log(error);
      });
    }
  }, [areDataSourcesPropertiesLoaded, currentAppliedFiltersBackendPayload, dashboardId, dispatch, hasAppliedFilters, reportsWithFEAppliedFilters, validateFilterApplicationAsyncState]);
};
export const useDashboardReportsOverrides = dashboardId => {
  const overriddenDashboardReportsAsyncStatus = useSelector(selectDashboardReportsAsyncStatus(dashboardId));
  const hasBackendAppliedFiltersAccess = useSelector(selectHasBackendAppliedFiltersAccess);
  const dispatch = useDispatch();
  const currentAppliedFiltersBackendPayload = useBackendFilterPayload(dashboardId);
  useEffect(() => {
    if (hasBackendAppliedFiltersAccess && isUninitialized(overriddenDashboardReportsAsyncStatus)) {
      try {
        dispatch(getOverriddenReportsFromAPIThunk(currentAppliedFiltersBackendPayload)).catch(error => {
          console.error(error);
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [currentAppliedFiltersBackendPayload, dispatch, hasBackendAppliedFiltersAccess, overriddenDashboardReportsAsyncStatus]);
};
export const useGetBackendAppliedFiltersForReport = (dashboardId, reportId) => {
  const maybeOverriddenReport = useSelector(selectOverriddenDashboardReport(dashboardId, reportId));
  const maybeOverriddenFilters = useSelector(selectBackendAppliedFiltersForReport(dashboardId, reportId));
  const reportDataSourceUsages = getReportDataSourceUsages({
    reports: maybeOverriddenReport ? [maybeOverriddenReport] : []
  });
  const reportDataSourceIds = Object.keys(reportDataSourceUsages);
  const dataSourcesProperties = usePlatformDashboardSelector(selectDataSourcesProperties(reportDataSourceIds));
  const dataSourcesMetadata = usePlatformDashboardSelector(selectDataSourcesMetadataWithUniqueLabels(reportDataSourceIds));
  const transformedOverriddenFilters = (() => {
    try {
      return maybeOverriddenFilters && maybeOverriddenFilters.length > 0 ? transformSavedFilterToDashboardFilter(maybeOverriddenFilters, dataSourcesProperties, dataSourcesMetadata) : [];
    } catch (error) {
      return [];
    }
  })();
  return transformedOverriddenFilters;
};