import I18n from 'I18n';
import { daysAgo, endOfPrior, now, startOfPrior, startOfThis
// @ts-expect-error Type SimpleDate (#9771)
} from '../core/SimpleDate';
export const TODAY = {
  presetId: 'TODAY',
  getText: () => I18n.text('salesUI.UIDateRangePicker.rangeType.THIS_DAY'),
  getValue: tz => ({
    startDate: now(tz),
    endDate: now(tz)
  })
};
export const YESTERDAY = {
  presetId: 'YESTERDAY',
  getText: () => I18n.text('salesUI.UIDateRangePicker.rangeType.LAST_DAY'),
  getValue: tz => ({
    startDate: daysAgo(1, tz),
    endDate: daysAgo(1, tz)
  })
};
export const THIS_WEEK = {
  presetId: 'THIS_WEEK',
  getText: () => I18n.text('salesUI.UIDateRangePicker.rangeType.THIS_WEEK'),
  getValue: tz => ({
    startDate: startOfThis('week', tz),
    endDate: now(tz)
  })
};
export const LAST_WEEK = {
  presetId: 'LAST_WEEK',
  getText: () => I18n.text('salesUI.UIDateRangePicker.rangeType.LAST_WEEK'),
  getValue: tz => ({
    startDate: startOfPrior('week', 1, tz),
    endDate: endOfPrior('week')
  })
};
export const THIS_MONTH = {
  presetId: 'THIS_MONTH',
  getText: () => I18n.text('salesUI.UIDateRangePicker.rangeType.THIS_MONTH'),
  getValue: tz => ({
    startDate: startOfThis('month', tz),
    endDate: now(tz)
  })
};
export const LAST_MONTH = {
  presetId: 'LAST_MONTH',
  getText: () => I18n.text('salesUI.UIDateRangePicker.rangeType.LAST_MONTH'),
  getValue: tz => ({
    startDate: startOfPrior('month', 1, tz),
    endDate: endOfPrior('month')
  })
};
export const LAST_THIRTY_DAYS = {
  presetId: 'LAST_THIRTY_DAYS',
  getText: () => I18n.text('salesUI.UIDateRangePicker.rangeType.LAST_THIRTY_DAYS'),
  getValue: tz => ({
    startDate: daysAgo(29, tz),
    endDate: now(tz)
  })
};
export const LAST_THREE_MONTHS = {
  presetId: 'LAST_THREE_MONTHS',
  getText: () => I18n.text('salesUI.UIDateRangePicker.rangeType.LAST_THREE_MONTHS'),
  getValue: tz => ({
    startDate: startOfPrior('month', 2, tz),
    endDate: now(tz)
  })
};
export const THIS_QUARTER = {
  presetId: 'THIS_QUARTER',
  getText: () => I18n.text('salesUI.UIDateRangePicker.rangeType.THIS_QUARTER'),
  getValue: tz => ({
    startDate: startOfThis('quarter', tz),
    endDate: now(tz)
  })
};
export const LAST_QUARTER = {
  presetId: 'LAST_QUARTER',
  getText: () => I18n.text('salesUI.UIDateRangePicker.rangeType.LAST_QUARTER'),
  getValue: tz => ({
    startDate: startOfPrior('quarter', 1, tz),
    endDate: endOfPrior('quarter', 1, tz)
  })
};
export const LAST_YEAR = {
  presetId: 'LAST_YEAR',
  getText: () => I18n.text('salesUI.UIDateRangePicker.rangeType.LAST_YEAR'),
  getValue: tz => ({
    startDate: startOfPrior('year', 1, tz),
    endDate: endOfPrior('year', 1, tz)
  })
};
export const THIS_YEAR = {
  presetId: 'THIS_YEAR',
  getText: () => I18n.text('salesUI.UIDateRangePicker.rangeType.THIS_YEAR'),
  getValue: tz => ({
    startDate: startOfThis('year', tz),
    endDate: now(tz)
  })
};