'use es6';

import { fromJS, List, Map as ImmutableMap } from 'immutable';
import I18n from 'I18n';
import { Promise } from '../../lib/promise';
import toJS from '../../lib/toJS';
import * as http from '../../request/http';
import { makeOption } from '../Option';
import { getReferenceLabeler } from '../../crmObjects/references';
import { FORM } from 'reference-resolvers/constants/ReferenceObjectTypes';
const fetchForms = property => http.get('forms/v2/forms', {
  query: {
    property
  }
}).then(toJS);
const getForms = ids => {
  return ids.isEmpty() ? Promise.resolve(ImmutableMap()) : fetchForms(['guid', 'name']).then(forms => {
    return forms.reduce((memo, form) => {
      const id = form.guid;
      const formLabeler = getReferenceLabeler(FORM);
      const name = formLabeler(fromJS(form), id);
      return ids.includes(id) ? memo.push(makeOption(id, name)) : memo;
    }, List());
  });
};
export default getForms;
export const forms = ids => getForms(ids).then(options => options.reduce((breakdowns, option) => Object.assign({}, breakdowns, {
  [option.get('value')]: option.get('label')
}), {}));
const getVariantLabel = idx => I18n.text(`reporting-data.properties.forms.variants.${String.fromCharCode(97 + idx)}`);
export const variants = ids => Promise.resolve(ids.reduce((result, id, idx) => Object.assign({}, result, {
  [id]: getVariantLabel(idx)
}), {}));