'use es6';

import { makeOperator } from './Operator';
const zipcodePattern = /^\d{5}(-\d{4})?$/;

/**
 * A special operator for distance queries against zipcodes.
 */
export default makeOperator({
  name: 'Near',
  values: [{
    name: 'value',
    defaultValue: '',
    isValid: value => zipcodePattern.test(value)
  }]
});