import I18n from 'I18n';
const thresholds = {
  years: 12,
  days: 2.4,
  hours: 2.4,
  minutes: 2.4,
  seconds: 0,
  milliseconds: 0
};
const getRoundedUnitWithPrecision = (ms, unit, precisionModifier = 1) =>
// @ts-expect-error TS doesn't like math ops on Duration but it is legal
Math.round(ms / unit * precisionModifier) / precisionModifier;
const getUnits = precision => {
  const SECOND = I18n.moment.duration(1, 'second').valueOf();
  const MINUTE = I18n.moment.duration(1, 'minute').valueOf();
  const HOUR = I18n.moment.duration(1, 'hour').valueOf();
  const DAY = I18n.moment.duration(1, 'day').valueOf();
  const YEAR = I18n.moment.duration(1, 'year').valueOf();
  const precisionModifier = precision ? Math.pow(10, precision) : 1;
  return {
    years: ms => getRoundedUnitWithPrecision(ms, YEAR, precisionModifier),
    days: ms => getRoundedUnitWithPrecision(ms, DAY, precisionModifier),
    hours: ms => getRoundedUnitWithPrecision(ms, HOUR, precisionModifier),
    minutes: ms => getRoundedUnitWithPrecision(ms, MINUTE, precisionModifier),
    seconds: ms => getRoundedUnitWithPrecision(ms, SECOND),
    milliseconds: ms =>
    // @ts-expect-error TS doesn't like math ops on Duration but it is legal
    Math.round(ms)
  };
};
export const formatDuration = (ms, smallScale, precision, durationUnit) => Object.keys(getUnits(precision)).reduce((label, unit) => {
  const roundedValue = getUnits(precision)[unit](ms);
  return label || (Math.abs(roundedValue) > thresholds[unit] || roundedValue === 0 && durationUnit === unit ? unit !== 'milliseconds' ? I18n.text(`reporting-data.durations.${unit}`, {
    count: roundedValue
  }) : I18n.text(`reporting-data.durations.seconds`, {
    count: smallScale || roundedValue === 0 ? roundedValue / 1000 : I18n.SafeString(`< ${roundedValue > 0 ? '1' : '-1'}`)
  }) : 0);
}, 0);