'use es6';

import getIn from 'transmute/getIn';
import useUserInfo from '../../useUserInfo';
import { DASHBOARDS, LIMIT_ROOT } from '../limitTypes';
const useDashboardLimit = () => {
  const {
    data
  } = useUserInfo();
  return getIn([LIMIT_ROOT, DASHBOARDS], data);
};
export default useDashboardLimit;