'use es6';

import { getAll as getAllProblems } from '../../private/action-problem-solution/actions/view-report/problem';
import { getProblem } from '../../private/action-problem-solution/common/get-problem';
import { isEnabled } from '../../private/action-problem-solution/common/is-enabled';
export function canViewReport(params) {
  return isEnabled(params, getAllProblems);
}
export function getViewReportProblem(params) {
  return getProblem(params, getAllProblems);
}