'use es6';

import enviro from 'enviro';
import { fromJS } from 'immutable';
import { dataTypeToObjectType, objectTypeToDataType } from 'reporting-data/retrieve/inboundDb/common/dataTypes';
import { LEADS } from 'reporting-data/constants/dataTypes';
import { DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES } from 'reporting-data/constants/objectCoordinates';
import { createJourneyStepAlias, generateJourneyFilterId } from 'reporting-snowflake/funnel/utils/aliasing';
import { FUNNEL_CREATE_STAGE_ID, getCachedDataForStage, getObjectTypeFromStageValue, getOptionalStagesContextField, getStageContextField, STAGE_OBJECT_TYPES } from '../../utils/stageHelpers';
import { getDataTypeName } from '../../utils/salesAnalyticsHelpers';
const QA_PIPELINE_STAGE_EVENT_ID = '4-530531';
const PROD_PIPELINE_STAGE_EVENT_ID = '4-1463231';
const getObjectCreatedStep = (stageData, journeyDataType) => {
  const eventObjectTypeId = enviro.isQa() ? '4-530516' : '4-1463224';
  const objectObjectTypeId = DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(journeyDataType);
  return {
    alias: 'e_object_created_v2_0_0',
    name: stageData.get('label'),
    query: {
      table: {
        name: 'e_object_created_v2',
        objectObjectTypeIds: [objectObjectTypeId],
        objectTypeId: eventObjectTypeId,
        type: 'HUBSPOT_EVENT'
      }
    }
  };
};
const getJourneyStep = (stageData, stageIndex, stageObjectType, journeyDataType) => {
  const stepIndex = 0;
  const stageDataType = stageObjectType === STAGE_OBJECT_TYPES.LEAD ? LEADS : objectTypeToDataType.get(stageObjectType);
  const objectTypeId = DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(stageDataType);
  const eventObjectTypeId = enviro.isQa() ? QA_PIPELINE_STAGE_EVENT_ID : PROD_PIPELINE_STAGE_EVENT_ID;
  const journeyObjectType = dataTypeToObjectType.get(journeyDataType);
  const journeyObjectTypeId = DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(journeyDataType);
  const stepFilter = {
    field: {
      name: 'hs_pipeline_stage',
      table: 'e_pipeline_stage_updated_v2',
      source: 'TABLE',
      type: 'enumeration'
    },
    filter: {
      filterType: 'PROPERTY',
      operation: {
        defaultValue: null,
        includeObjectsWithNoValueSet: false,
        propertyType: 'enumeration',
        operator: 'IS_ANY_OF',
        values: [stageData.get('stageId')]
      },
      property: 'hs_pipeline_stage'
    },
    isIgnored: false
  };
  const filterId = generateJourneyFilterId(stepFilter, {
    stageIndex,
    stepIndex
  });
  const dataJoin = stageObjectType !== journeyObjectType ? {
    join: [{
      target: {
        name: stageObjectType,
        objectTypeId,
        type: 'HUBSPOT_OBJECT',
        join: [{
          target: {
            name: journeyObjectType,
            objectTypeId: journeyObjectTypeId,
            type: 'HUBSPOT_OBJECT'
          }
        }]
      }
    }]
  } : {};
  const query = {
    table: Object.assign({
      name: 'e_pipeline_stage_updated_v2',
      type: 'HUBSPOT_EVENT',
      objectTypeId: eventObjectTypeId,
      objectObjectTypeIds: [objectTypeId]
    }, dataJoin),
    filtering: {
      condition: 'AND',
      groups: [{
        id: filterId,
        condition: 'AND',
        filters: [Object.assign({
          id: filterId
        }, stepFilter)]
      }]
    }
  };
  return {
    alias: createJourneyStepAlias(stageIndex, stepIndex, query),
    name: stageData.get('label'),
    query
  };
};
export const JourneyStagesMutator = ({
  report,
  context
}) => {
  const stagesContextField = getStageContextField(report);
  const optionalStagesContextField = getOptionalStagesContextField(report);
  const optionalStages = context[optionalStagesContextField];
  const journeyDataType = getDataTypeName(report);
  const stages = context[stagesContextField].map((stageValue, stageIndex) => {
    const stageObjectType = getObjectTypeFromStageValue(stageValue);
    const optional = optionalStages.includes(stageValue);
    const stageData = getCachedDataForStage(stageValue, report);
    const step = stageData.get('value') === FUNNEL_CREATE_STAGE_ID ? getObjectCreatedStep(stageData, journeyDataType) : getJourneyStep(stageData, stageIndex, stageObjectType, journeyDataType);
    return fromJS({
      name: stageData.get('label'),
      orderNumber: stageIndex,
      optional,
      steps: [step]
    });
  });
  const updatedJourneyQuery = report.get('journeyQuery').set('stages', stages);
  return report.set('journeyQuery', updatedJourneyQuery);
};