import enviro from 'enviro';
import * as overrides from './overrides';

/**
 * Whether environment is running locally
 *
 * @returns {boolean} Is running locally
 * @private
 */
const isLocal = () => !enviro.deployed();

/**
 * Whether development mode is forced
 *
 * @returns {boolean} Development mode forced
 * @private
 */
const isForced = () => overrides.development.enabled;

/**
 * Whether environment is in development mode
 *
 * @returns {boolean} Is development mode
 */
export default (() => {
  try {
    return isLocal() || isForced();
  } catch (err) {
    console.error(`[reporting-data]:`, err);
    return false;
  }
});