'use es6';

import { AssetPermission } from '../../../../public/permission/asset-permission';
import { hasSufficientPermission } from '../../../../public/permission/compute-permission';
import { anyNumberIsLoading } from '../../common/any-number-is-loading';
import { getAndCheck } from '../../common/get-and-check-param';
import { getAllProblems } from '../../common/get-problems-no-check';
import { createProblem } from '../../common/problem';
import { CUSTOM_DASHBOARD_COUNT, CUSTOM_DASHBOARD_LIMIT, DASHBOARD_IS_LOADING, DASHBOARD_FETCH_FAILED, USER_DASHBOARD_PERMISSION } from '../../params/all-params';
import { NEED_CREATE_AND_OWN_PERMISSION } from '../../problems/permission-problem';
import { CUSTOM_DASHBOARD_LIMIT_REACHED_OR_EXCEEDED } from 'dashboard-lib/private/action-problem-solution/common/asset-limit';
export const PERMISSION_DENIED = createProblem('PERMISSION_DENIED', params => {
  const dashboardPermission = getAndCheck(USER_DASHBOARD_PERMISSION, params);
  return !hasSufficientPermission(AssetPermission.VIEW, dashboardPermission);
});
export const LOADING = createProblem('LOADING', params => {
  const dashboardIsLoading = getAndCheck(DASHBOARD_IS_LOADING, params);
  const customDashboardCount = getAndCheck(CUSTOM_DASHBOARD_COUNT, params);
  const customDashboardLimit = getAndCheck(CUSTOM_DASHBOARD_LIMIT, params);
  return dashboardIsLoading || anyNumberIsLoading(customDashboardLimit, customDashboardCount);
});
export const FAILED_TO_FETCH_DASHBOARD = createProblem('FAILED_TO_FETCH_DASHBOARD', params => getAndCheck(DASHBOARD_FETCH_FAILED, params));
export const rankedProblems = [FAILED_TO_FETCH_DASHBOARD, LOADING, NEED_CREATE_AND_OWN_PERMISSION, PERMISSION_DENIED, CUSTOM_DASHBOARD_LIMIT_REACHED_OR_EXCEEDED];
export const getAll = params => getAllProblems(params, rankedProblems);