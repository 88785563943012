import { createSlice } from '@reduxjs/toolkit';
import { convertToDashboardFilter } from '../filter/utils/filterConversionHelpers';
import { bulkUpdateFiltersOnAddFilter, bulkUpdateFiltersOnRemoveFilter } from './applied-dashboard-filters/utils';
export const initialState = {
  isPanelOpen: false
};
const dashboardFiltersSlice = createSlice({
  name: 'dashboardFilters',
  initialState,
  reducers: {
    initializeDashboardFilters(state, action) {
      const {
        dashboardId,
        filters
      } = action.payload;
      state.isPanelOpen = false;
      state[dashboardId] = filters || [];
    },
    addDashboardFilter: {
      reducer: (state, action) => {
        const {
          dashboardId,
          newFilter
        } = action.payload;
        const newFilterArray = state[dashboardId] || [];
        newFilterArray.push(newFilter);
        state[dashboardId] = newFilterArray;
      },
      prepare: ({
        dashboardId,
        filterData,
        dataSource,
        property
      }) => {
        const newFilter = convertToDashboardFilter(filterData);
        return {
          payload: {
            dashboardId,
            newFilter,
            dataSource,
            property
          }
        };
      }
    },
    addGroupDashboardFilter: (state, action) => {
      const {
        payload: {
          dashboardId,
          quickFilterGroupOperators,
          systemGenerated,
          quickFilterGroupName
        }
      } = action;

      // TODO update when platform-dashboard-ui is using the applied-dashboard-filters slice instead of this one
      const prevFilters = state[dashboardId] || [];
      state[dashboardId] = bulkUpdateFiltersOnAddFilter(prevFilters, quickFilterGroupOperators, systemGenerated, quickFilterGroupName);
    },
    removeGroupDashboardFilter: (state, action) => {
      const {
        payload: {
          dashboardId,
          properties
        }
      } = action;
      // TODO update when platform-dashboard-ui is using the applied-dashboard-filters slice instead of this one
      const currentFilters = state[dashboardId] || [];
      state[dashboardId] = bulkUpdateFiltersOnRemoveFilter(currentFilters, properties);
    },
    updateDashboardFilter: {
      reducer: (state, action) => {
        const {
          dashboardId,
          newFilter,
          index
        } = action.payload;
        state[dashboardId][index] = newFilter;
      },
      prepare: ({
        dashboardId,
        filterData,
        index
      }) => {
        const newFilter = convertToDashboardFilter(filterData);
        return {
          payload: {
            dashboardId,
            newFilter,
            index
          }
        };
      }
    },
    removeDashboardFilter(state, action) {
      const {
        dashboardId,
        index
      } = action.payload;
      const filters = state[dashboardId];
      if (filters && filters.length) {
        filters.splice(index, 1);
      }
    },
    removeDashboardFilterByName(state, action) {
      const {
        dashboardId,
        dataSource,
        propertyName
      } = action.payload;
      state[dashboardId] = (state[dashboardId] || []).filter(appliedFilter => !(appliedFilter.dataSource === dataSource && appliedFilter.propertyName === propertyName));
    },
    removeCurrentDashboardFilters(state, action) {
      const {
        dashboardId
      } = action.payload;
      state[dashboardId] = [];
    },
    removeAllDashboardFilters() {
      // const { dashboardId } = action.payload;
      // TODO: DELETE all dashboard filter key value pairs
    },
    openDashboardFiltersPanel(state) {
      state.isPanelOpen = true;
    },
    closeDashboardFiltersPanel(state) {
      state.isPanelOpen = false;
    },
    toggleDashboardFiltersPanel(state) {
      state.isPanelOpen = !state.isPanelOpen;
    }
  }
});

// Selectors
export const selectDashboardFilters = dashboardId => state => {
  const localState = state.dashboardFilters;
  return localState[dashboardId] || [];
};
export const selectIsExistingProperty = (property, dashboardId) => state => {
  const prevFilters = selectDashboardFilters(dashboardId)(state);
  return prevFilters.find(filter => filter.propertyName === property.name && filter.dataSource === property.dataSource);
};
export const selectIsDashboardFilterPanelOpen = state => {
  return state.dashboardFilters.isPanelOpen;
};

// TODO: add listener middleware

export const {
  initializeDashboardFilters,
  addDashboardFilter,
  updateDashboardFilter,
  removeDashboardFilter,
  removeDashboardFilterByName,
  removeCurrentDashboardFilters,
  removeAllDashboardFilters,
  openDashboardFiltersPanel,
  closeDashboardFiltersPanel,
  toggleDashboardFiltersPanel,
  addGroupDashboardFilter,
  removeGroupDashboardFilter
} = dashboardFiltersSlice.actions;
export default dashboardFiltersSlice.reducer;