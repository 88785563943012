import { useMemo } from 'react';
import { FEATURES } from 'collaboration-sidebar/constants/features';
import { maybeUpdateObjectTypeForV3 } from 'collaboration-sidebar/utils/commentingUtils';
import { convertEnabledFeatures } from '../utils';
import useFrameworkAppSettings from './useFrameworkAppSettings';
export default function useGetPropsFromFAS({
  appName,
  enabledFeatures
}) {
  const {
    getCollaborationSidebarAppSetting,
    getCommentingAppSetting,
    loading
  } = useFrameworkAppSettings();
  return useMemo(() => {
    var _collaborationSidebar, _getCommentingAppSett, _collaborationSidebar2, _collaborationSidebar3, _collaborationSidebar4, _collaborationSidebar5, _collaborationSidebar6, _collaborationSidebar7, _collaborationSidebar8, _collaborationSidebar9;
    const collaborationSidebarAppSettings = getCollaborationSidebarAppSetting(appName);
    if (!collaborationSidebarAppSettings) {
      return {
        loading,
        fasProps: undefined
      };
    }
    let objectTypeFromFAS = (_collaborationSidebar = collaborationSidebarAppSettings.objectType) === null || _collaborationSidebar === void 0 ? void 0 : _collaborationSidebar.value;
    if (objectTypeFromFAS) {
      objectTypeFromFAS = maybeUpdateObjectTypeForV3(objectTypeFromFAS);
    }
    const isCommentingAppSettingsEnabled = Boolean(objectTypeFromFAS && ((_getCommentingAppSett = getCommentingAppSetting(objectTypeFromFAS)) === null || _getCommentingAppSett === void 0 ? void 0 : _getCommentingAppSett.enabled.value));
    let fasEnabledFeatures = collaborationSidebarAppSettings.enabledFeatures;

    /**
     * Filter out COMMENTS and DEEP_COMMENTING from enabledFeatures when there is a missing
     * FAS config for commenting → https://tools.hubteamqa.com/puma/framework-app-settings/commenting/entries.
     */
    if (!isCommentingAppSettingsEnabled && fasEnabledFeatures) {
      fasEnabledFeatures = Object.assign({}, fasEnabledFeatures, {
        value: fasEnabledFeatures.value.filter(feature => feature.name !== FEATURES.COMMENTS && feature.name !== FEATURES.DEEP_COMMENTING)
      });
    }
    const appHub = collaborationSidebarAppSettings.appHubs;
    const props = {
      appHub: appHub === null || appHub === void 0 ? void 0 : appHub.value,
      defaultSidebarPlacement: (_collaborationSidebar2 = collaborationSidebarAppSettings.dragAndDropConfig) === null || _collaborationSidebar2 === void 0 || (_collaborationSidebar2 = _collaborationSidebar2.value) === null || _collaborationSidebar2 === void 0 ? void 0 : _collaborationSidebar2.defaultSidebarPlacement,
      dragAndDropAreas: (_collaborationSidebar3 = collaborationSidebarAppSettings.dragAndDropConfig) === null || _collaborationSidebar3 === void 0 || (_collaborationSidebar3 = _collaborationSidebar3.value) === null || _collaborationSidebar3 === void 0 ? void 0 : _collaborationSidebar3.dragAndDropAreas,
      enabledFeatures: convertEnabledFeatures({
        appHub,
        fasEnabledFeatures,
        propEnabledFeatures: enabledFeatures
      }),
      hideCommentModeAndToggle: (_collaborationSidebar4 = collaborationSidebarAppSettings.layoutConfig) === null || _collaborationSidebar4 === void 0 || (_collaborationSidebar4 = _collaborationSidebar4.value) === null || _collaborationSidebar4 === void 0 ? void 0 : _collaborationSidebar4.hideCommentModeAndToggle,
      isListingPage: (_collaborationSidebar5 = collaborationSidebarAppSettings.layoutConfig) === null || _collaborationSidebar5 === void 0 || (_collaborationSidebar5 = _collaborationSidebar5.value) === null || _collaborationSidebar5 === void 0 ? void 0 : _collaborationSidebar5.isListingPage,
      isOverlay: (_collaborationSidebar6 = collaborationSidebarAppSettings.layoutConfig) === null || _collaborationSidebar6 === void 0 || (_collaborationSidebar6 = _collaborationSidebar6.value) === null || _collaborationSidebar6 === void 0 ? void 0 : _collaborationSidebar6.isOverlay,
      objectType: objectTypeFromFAS,
      scrollablePage: (_collaborationSidebar7 = collaborationSidebarAppSettings.layoutConfig) === null || _collaborationSidebar7 === void 0 || (_collaborationSidebar7 = _collaborationSidebar7.value) === null || _collaborationSidebar7 === void 0 ? void 0 : _collaborationSidebar7.scrollablePage,
      showSidebarHeader: (_collaborationSidebar8 = collaborationSidebarAppSettings.layoutConfig) === null || _collaborationSidebar8 === void 0 || (_collaborationSidebar8 = _collaborationSidebar8.value) === null || _collaborationSidebar8 === void 0 ? void 0 : _collaborationSidebar8.showSidebarHeader,
      withoutSidebar: (_collaborationSidebar9 = collaborationSidebarAppSettings.layoutConfig) === null || _collaborationSidebar9 === void 0 || (_collaborationSidebar9 = _collaborationSidebar9.value) === null || _collaborationSidebar9 === void 0 ? void 0 : _collaborationSidebar9.withoutSidebar
    };
    return {
      loading,
      fasProps: props
    };
  }, [appName, enabledFeatures, getCollaborationSidebarAppSetting, getCommentingAppSetting, loading]);
}