'use es6';

import { Map as ImmutableMap } from 'immutable';
import Request from '../../request/Request';
import * as http from '../../request/http';
import { makeOption } from '../Option';
const URL = 'inbounddbproducts/v1/products/batch';
export const generateProductLabel = productInfo => productInfo.get('name');
export default (ids => http.retrieve(Request.get({
  url: URL,
  query: {
    id: ids,
    properties: 'name',
    includeDeletes: true
  }
})).then(response => ImmutableMap(response.map((product, id) => makeOption(id, product.getIn(['properties', 'name', 'value']))))));