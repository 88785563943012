import { generateJourneyFilterId } from 'reporting-snowflake/funnel/utils/aliasing';
import { getListFilterGroup, getPropertyFilterGroup } from '../common/journeyHelpers';
export const buildObjectPropertyFilters = (propertyFilterSet, objectProperties, currentObject) => {
  return propertyFilterSet.filters.map((propertyFilter, index) => {
    const filterWithoutId = {
      field: {
        name: propertyFilter.property,
        table: currentObject,
        source: 'TABLE',
        type: objectProperties[propertyFilter.property].propertyDefinition.property.type
      },
      filter: propertyFilter,
      isIgnored: false
    };
    return Object.assign({
      id: generateJourneyFilterId(filterWithoutId, {
        listIndex: index
      })
    }, filterWithoutId);
  });
};
export const getTransformedPropertyFilterSetFromQuery = journeyReport => {
  const savedPropertyFilterGroup = getPropertyFilterGroup(journeyReport);
  if (!savedPropertyFilterGroup) return {
    filters: []
  };
  const filters = savedPropertyFilterGroup.filters;
  return {
    filters: filters.reduce((accum, filterGroup) => {
      const {
        filter
      } = filterGroup;
      if (filter.filterType === 'PROPERTY') {
        accum.push({
          filterType: 'PROPERTY',
          operation: filter.operation,
          property: filter.property
        });
      }
      return accum;
    }, [])
  };
};
export const constructNewReportFiltering = (report, {
  properties,
  deleteProperties,
  objectList,
  deleteList
}) => {
  const existingPropertyFiltering = getPropertyFilterGroup(report);
  const existingListFiltering = getListFilterGroup(report);
  const newPropertyFilterGroup = properties || deleteProperties ? properties : existingPropertyFiltering;
  const newListFilterGroup = objectList || deleteList ? objectList : existingListFiltering;
  if (!newPropertyFilterGroup && !newListFilterGroup) {
    return undefined;
  } else if (newListFilterGroup && !newPropertyFilterGroup) {
    return {
      condition: 'OR',
      groups: [newListFilterGroup]
    };
  } else {
    return {
      condition: 'AND',
      groups: newListFilterGroup ? [newListFilterGroup, newPropertyFilterGroup] : [newPropertyFilterGroup]
    };
  }
};