import { useMemo } from 'react';
import { SUPPORTED_HUBS_REQUIRED_SCOPES } from '../constants';
import { hasScope } from '../utils/auth';
import { FEATURES } from 'collaboration-sidebar/constants/features';
export default function useHasSidebarPermission({
  appHub,
  enabledFeatures
}) {
  return useMemo(() => {
    if (!enabledFeatures) {
      return false;
    }
    const hasComments = enabledFeatures.includes(FEATURES.COMMENTS) || enabledFeatures.includes(FEATURES.DEEP_COMMENTING);
    if (!hasComments) {
      return true;
    }
    if (!appHub) {
      return false;
    }
    if (Array.isArray(appHub)) {
      return Boolean(appHub.find(hub => hasScope(SUPPORTED_HUBS_REQUIRED_SCOPES[hub])));
    }
    return hasScope(SUPPORTED_HUBS_REQUIRED_SCOPES[appHub]);
  }, [appHub, enabledFeatures]);
}