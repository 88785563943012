import { fromJS } from 'immutable';
import I18n from 'I18n';
//@ts-expect-error Untyped import
import { Objects as DataTypeObjects } from 'reporting-data/retrieve/inboundDb/pipelines';
import { PIPELINE_OBJECTS } from './pipelineStageTypes';
export const CREATE_STAGE_ID = 'create';
export const ObjectToDataType = fromJS(DataTypeObjects).flip().toJS();
export const getCreateStageLabel = objectType => {
  switch (objectType) {
    case PIPELINE_OBJECTS.DEAL:
      return I18n.text('reporting-data.properties.deals.stage.create');
    case PIPELINE_OBJECTS.CONTACT:
      return I18n.text('reporting-data.lifecyclestage.create');
    default:
      return I18n.text('settingLabels.pipelineStageOrder.defaultCreateStage');
  }
};
export const canBeOptional = (stage, selectedStages) => stage !== selectedStages.first() && stage !== selectedStages.last();
const STAGE_VALUE_DELIMITER = '|';
const getStageOptionValue = (objectType, stageId) => `${objectType}${STAGE_VALUE_DELIMITER}${stageId}`;
const getStageOption = (objectType, stage, pipelineName) => ({
  text: stage.label,
  value: getStageOptionValue(objectType, stage.stageId),
  pipelineName
});
export const getDataFromStageValue = optionValue => {
  const splitValue = optionValue.split(STAGE_VALUE_DELIMITER);
  return {
    objectType: splitValue[0],
    stageId: splitValue[1]
  };
};
export const getStageOptionValuesFromStageData = stagesData => stagesData.map(stage => getStageOptionValue(stage.pipelineObject, stage.stageId));
export const getAccessibleStageObjectTypes = (stageObjectTypes, {
  gates,
  scopes
}) => {
  const hasLeadObjectAccess = gates.includes('LeadTracking:GeneralAccess') && scopes.includes('prospecting-workspace-access');
  return stageObjectTypes.filter(objectType => objectType !== PIPELINE_OBJECTS.LEAD || hasLeadObjectAccess);
};
export const getStageData = (allPipelines, stageId) => allPipelines.reduce((memo, pipeline) => {
  if (!memo) {
    const {
      stages
    } = pipeline;
    return stages.find(stage => stage.stageId === stageId) || null;
  }
  return memo;
}, null);
export const getSelectedStageLabels = (allPipelines, selectedStages) => selectedStages.reduce((memo, selectedStage) => {
  const {
    stageId
  } = selectedStage;
  if (stageId === CREATE_STAGE_ID) {
    return memo.concat(getCreateStageLabel(selectedStage.pipelineObject) || selectedStage.stageId);
  }
  const stageData = getStageData(allPipelines, stageId);
  if (!stageData) {
    return memo;
  }
  const stageName = stageData.label;
  return memo.concat(selectedStage.optional ? I18n.text('settingLabels.pipelineStageOrder.optionalStageLabel', {
    stageName
  }) : stageName);
}, []);
export const getFilteredStageOptions = (pipelinesByObject, selectedPipelines, createStageObject, isValidStageOption) => {
  const allPipelines = Object.values(pipelinesByObject).reduce((memo, objectPipelines) => memo.concat(objectPipelines), []);
  const filteredPipelineStages = allPipelines.reduce((filteredStageOptions, pipeline) => {
    const objectType = pipeline.objectType;
    const pipelineId = pipeline.pipelineId;
    if (selectedPipelines.length > 0 && !selectedPipelines.includes(pipelineId)) {
      return filteredStageOptions;
    }
    const pipelineName = pipeline.label;
    return filteredStageOptions.concat({
      text: I18n.text(`settingLabels.pipelineStageOrder.optionGroups.${objectType}`, {
        pipeline: pipelineName
      }),
      options: pipeline.stages.filter(stage => isValidStageOption(objectType, stage)).map(stage => getStageOption(objectType, stage, pipelineName))
    });
  }, []);
  if (createStageObject) {
    filteredPipelineStages.unshift({
      text: getCreateStageLabel(createStageObject),
      value: getStageOptionValue(createStageObject, CREATE_STAGE_ID)
    });
  }
  return filteredPipelineStages;
};
export const getInitialStagePosition = stageOption => getDataFromStageValue(stageOption.value).stageId === CREATE_STAGE_ID ? 'first' : 'last';