'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import makeDateRangeByType from 'reporting-data/lib/makeDateRangeByType';
import { IN, GT, LT, BETWEEN, HAS_PROPERTY, NOT_HAS_PROPERTY } from 'reporting-data/constants/operators';
import { mapPropertyToSearchProperty } from 'reporting-data/retrieve/inboundDb/common/mapToSearchProperties';
import AnalyticsViewControl from '../../components/controls/AnalyticsViewControl';
import { constructContext } from '../../records/Context';
import { getSubAppTabs, isSubAppReportCollection } from '../../utils/report-collection-sub-app-data';
import { getHybridSRVSubAppTabs, isSubAppHybridSRVReport } from '../../utils/report-hybrid-srv-sub-app-data';
import { ACCESSIBLE_TEAM_PROPERTY, DIMENSIONS, NO_PIPELINE, TEAM_GROUPING_TYPE, getBreakdownPropertiesForReport, getDimensionPropertiesForReport, getTimestampProperty, isQuotaReport, getDataTypeName, getJourneyDateRangeFromReport, getDataType, getDimensionProperty } from '../../utils/salesAnalyticsHelpers';
import { getFilteredBreakdownKey } from '../../utils/filteredBreakdownsHelpers';
import { getSidebarControls } from '../../utils/controls';
import { getQuickFilterSchemasForReport } from '../../utils/editorSchemaHelpers';
import { getContextFieldForEditor } from '../../utils/editorSchemaHelpers';
import { checkHasUpgradeEngagements } from '../../utils/access';
import { DEFAULT_DATE_FORMAT } from '../date-format';
import { getMetricKey } from '../dataTypeMetricOptions';
import { strategy as journeyFilterStrategy } from 'reporting-enablement/components/reportEditors/QuickFilterEditor/JourneyQuickFilterEditor';
import { isJourneyReport, isConfigReport, isRelationalReport } from 'reporting-data/tsTypes/reportTypes';
const getFilterSchemas = (subAppKey, report, grouping, gates) => {
  const usableControls = getSidebarControls(subAppKey, report, gates);
  const customControlFilterSchemas = usableControls.filter(control => !!control.getFilterSchema).map(control => control.getFilterSchema({
    filteredGates: gates,
    grouping,
    report,
    subAppKey
  }));
  const quickFilterSchemas = getQuickFilterSchemasForReport(report, subAppKey, grouping, gates);
  return customControlFilterSchemas.concat(quickFilterSchemas);
};
const isHiddenFromCustomFiltersEditor = (report, filter, gates) => {
  const {
    property: filterProperty
  } = filter;
  const shouldHideAnalyticsViewFilter = AnalyticsViewControl.isUsableForReport({
    report,
    filteredGates: gates
  }) && ['subdomain', 'filterId'].includes(filterProperty);
  return shouldHideAnalyticsViewFilter;
};
const deriveContextFieldsFromCustomFilters = ({
  subAppKey,
  report,
  grouping,
  gates
}) => {
  const savedCustomFilters = report.getIn(['config', 'filters', 'custom'], List()).toJS();
  const filterSchemas = getFilterSchemas(subAppKey, report, grouping, gates);
  let filterContext = {};
  const remainingFilters = [];
  for (const filter of savedCustomFilters) {
    const {
      property: filterProperty,
      operator: filterOperator
    } = filter;

    // skip the custom filters used for secondary date range
    if (filterProperty === getTimestampProperty(report, gates, subAppKey) && (filterOperator === GT || filterOperator === LT || filterOperator === BETWEEN)) {
      continue;
    }
    const contextUpdate = filterSchemas.reduce((memo, filterSchema) => {
      const {
        property,
        operator
      } = filterSchema;
      if (filterProperty !== property) {
        return memo;
      }
      const contextField = getContextFieldForEditor(filterSchema);
      if (filterOperator === operator) {
        return {
          [contextField]: operator === IN ? filter.values : filter.value
        };
      }
      // handle special pipeline filter behavior for quota reports
      if (property === 'pipeline' && isQuotaReport(report) && [HAS_PROPERTY, NOT_HAS_PROPERTY].includes(filterOperator)) {
        return {
          [contextField]: filterOperator === NOT_HAS_PROPERTY ? NO_PIPELINE : null
        };
      }
      return memo;
    }, null);
    if (contextUpdate) {
      filterContext = Object.assign({}, filterContext, contextUpdate);
    } else if (!isHiddenFromCustomFiltersEditor(report, filter, gates)) {
      remainingFilters.push(filter);
    }
  }
  return {
    filterContext,
    remainingFilters
  };
};
export const deriveBreakdownFromReport = (report, subAppKey) => {
  const dimensions = report.getIn(['config', 'dimensions']);
  if (dimensions.size < 2) {
    return null;
  }
  const reportBreakdown = dimensions.get(1);
  const breakdownProperties = getBreakdownPropertiesForReport(subAppKey);
  return Object.keys(breakdownProperties).find(dimensionType => breakdownProperties[dimensionType] === reportBreakdown) || '';
};
export const deriveGroupingFromReport = (report, subAppKey, gates) => {
  const dimensions = report.getIn(['config', 'dimensions']);
  if (!dimensions) {
    return '';
  }
  const firstDimension = dimensions.get(0);
  const reportDimensionProperties = getDimensionPropertiesForReport(subAppKey, report, gates);
  if (!firstDimension) {
    return '';
  }
  const convertedFirstDimension = checkHasUpgradeEngagements(gates) ? mapPropertyToSearchProperty(firstDimension, getDataType(report)) : firstDimension;
  if (convertedFirstDimension === ACCESSIBLE_TEAM_PROPERTY) {
    return DIMENSIONS.TEAM;
  }
  return Object.keys(reportDimensionProperties).find(dimensionType => reportDimensionProperties[dimensionType] === convertedFirstDimension) || '';
};
export const deriveFilteredBreakdownsFromReport = report => {
  const dataType = getDataTypeName(report);
  // currently this only works on UA reports, not any other reports with
  // a non 'filters' property breakdown filter
  const customBreakdownsFilter = report.getIn(['config', 'filters', 'custom'], List()).find(filter => filter.get('property') === 'filters');
  const filteredBreakdowns = customBreakdownsFilter ? customBreakdownsFilter.get('values').map(value => getFilteredBreakdownKey(dataType, value)) : List();
  return filteredBreakdowns;
};
export const deriveChartMetricsFromReport = report => {
  const metrics = report.getIn(['config', 'metrics']);
  return metrics.map(metric => getMetricKey(metric.get('property'), metric.getIn(['metricTypes', 0])));
};
const getTab = ({
  report,
  subAppKey,
  scopes,
  gates,
  otherOptions: __otherOptions
}) => {
  const getTabs = isSubAppReportCollection(subAppKey) ? getSubAppTabs : isSubAppHybridSRVReport(subAppKey) ? getHybridSRVSubAppTabs : null;
  return getTabs && !report.get('id') ? getTabs(subAppKey, scopes, gates)[0] : null;
};
const deriveJourneyFilterByPropertyFromReport = (report, gates, subAppKey, dimension) => {
  const dimensionProperty = getDimensionProperty(report, subAppKey, dimension, gates);
  const filterValue = journeyFilterStrategy.getCurrentValue(report, {
    type: 'QUICK_FILTER',
    operator: 'IN',
    property: dimensionProperty
  });
  if (!filterValue) return List();
  return List(filterValue);
};
const CONFIG_REPORT_FIELD_DERIVE_FUNCTIONS = {
  breakdown: ({
    report,
    subAppKey
  }) => deriveBreakdownFromReport(report, subAppKey),
  chartMetrics: ({
    report
  }) => deriveChartMetricsFromReport(report),
  chartType: ({
    report
  }) => report.get('chartType'),
  dateRange: ({
    report
  }) => {
    const dateRange = report.getIn(['config', 'filters', 'dateRange']);
    const completeDateRange = ImmutableMap(makeDateRangeByType(dateRange.get('value').toJS(), DEFAULT_DATE_FORMAT));
    return completeDateRange.set('useFiscalYear', dateRange.get('useFiscalYear', false));
  },
  grouping: ({
    report,
    subAppKey,
    gates
  }) => deriveGroupingFromReport(report, subAppKey, gates),
  tab: getTab,
  teamGroupingType: ({
    report
  }) => {
    const firstDimension = report.getIn(['config', 'dimensions', 0], null);
    return firstDimension === ACCESSIBLE_TEAM_PROPERTY ? TEAM_GROUPING_TYPE.ACCESSIBLE_TEAM : TEAM_GROUPING_TYPE.PRIMARY_TEAM;
  },
  filteredBreakdowns: ({
    report
  }) => deriveFilteredBreakdownsFromReport(report)
};
const JOURNEY_REPORT_FIELD_DERIVE_FUNCTIONS = {
  chartType: ({
    report
  }) => report.get('chartType'),
  journeyDateRange: ({
    report
  }) => getJourneyDateRangeFromReport(report),
  tab: getTab,
  ownerIds: ({
    report,
    gates,
    subAppKey
  }) => deriveJourneyFilterByPropertyFromReport(report, gates, subAppKey, DIMENSIONS.OWNER),
  teams: ({
    report,
    gates,
    subAppKey
  }) => deriveJourneyFilterByPropertyFromReport(report, gates, subAppKey, DIMENSIONS.TEAM)
};
const SNOWFLAKE_REPORT_FIELD_DERIVE_FUNCTIONS = {
  relationalCustomFilters: ({
    report
  }) => {
    return ImmutableMap({
      filtering: report.getIn(['reportDefinition', 'filtering']),
      eventDateInterval: report.getIn(['reportDefinition', 'eventDateInterval'])
    });
  },
  tab: getTab
};
const getFieldDeriveFunctions = report => {
  if (isJourneyReport(report)) {
    return JOURNEY_REPORT_FIELD_DERIVE_FUNCTIONS;
  }
  if (isConfigReport(report)) {
    return CONFIG_REPORT_FIELD_DERIVE_FUNCTIONS;
  }
  if (isRelationalReport(report)) {
    return SNOWFLAKE_REPORT_FIELD_DERIVE_FUNCTIONS;
  }
  return {};
};
export const deriveContextFromReport = (report, subAppKey, scopes, gates, withFilters, otherOptions) => {
  const fieldDeriveFunctions = getFieldDeriveFunctions(report);
  const derivedContext = Object.entries(fieldDeriveFunctions).reduce((memo, [field, deriveFieldValue]) => {
    const fieldValue = deriveFieldValue({
      report,
      subAppKey,
      scopes,
      gates,
      otherOptions
    });
    if (fieldValue != null) {
      memo[field] = fieldValue;
    }
    return memo;
  }, {});

  // only derive filters for saved reports for now
  if (!withFilters || isJourneyReport(report)) {
    return derivedContext;
  }
  const grouping = deriveGroupingFromReport(report, subAppKey, gates);
  const {
    filterContext,
    remainingFilters
  } = deriveContextFieldsFromCustomFilters({
    subAppKey,
    report,
    grouping,
    gates
  });
  return Object.assign({}, derivedContext, filterContext, {
    customFilters: remainingFilters
  });
};
export const getDefaultContextFromTemplate = (reportTemplate, subAppKey, scopes, gates, otherOptions) => constructContext(deriveContextFromReport(reportTemplate, subAppKey, scopes, gates, false, otherOptions));