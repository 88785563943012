const SIDE_PANEL_HELP_WIDGET_IFRAME = 'growth-side-panel-help-widget-iframe';
const GROWTH_SIDE_PANEL_REOPENING = 'gsp-automatically-closed-flag';
export const flagGrowthSidePanelForReopening = () => {
  try {
    window.sessionStorage.setItem(GROWTH_SIDE_PANEL_REOPENING, 'true');
  } catch (e) {
    // do nothing
  }
};
export const isGrowthSidePanelFlaggedForReopening = () => {
  try {
    return window.sessionStorage.getItem(GROWTH_SIDE_PANEL_REOPENING) === 'true';
  } catch (e) {
    // do nothing
    return false;
  }
};
export const clearGrowthSidePanelFlagForReopening = () => {
  try {
    window.sessionStorage.removeItem(GROWTH_SIDE_PANEL_REOPENING);
  } catch (e) {
    // do nothing
  }
};
export const isGrowthSidePanelOpen = (rootWindow = window) => {
  const panelIframe = rootWindow.document.getElementById(SIDE_PANEL_HELP_WIDGET_IFRAME);
  if (!panelIframe) {
    return false;
  }
  const styles = window.getComputedStyle(panelIframe);
  return styles.display !== 'none';
};

// postMessage for zorse to open / restore the Help widget
// reference https://git.hubteam.com/HubSpot/Navigation/blob/master/unified-navigation-ui/static/js/components/Help/utils/inAppHelp.ts#L241
export const openGrowthSidePanel = (rootWindow = window) => {
  try {
    const hubspotWindow = rootWindow;
    if (isGrowthSidePanelOpen(hubspotWindow)) {
      return;
    }
    // open the GSP
    if (document.getElementById(SIDE_PANEL_HELP_WIDGET_IFRAME)) {
      hubspotWindow.postMessage({
        name: 'restore'
      }, hubspotWindow.origin);
      return;
    }
    hubspotWindow.hubspot.zorse.openHelpWidget();
  } catch (e) {
    // do nothing
  }
};

// minimize the Help widget
// reference https://git.hubteam.com/HubSpot/Navigation/blob/master/unified-navigation-ui/static/js/components/Help/utils/inAppHelp.ts#L236
export const closeGrowthSidePanel = (rootWindow = window) => {
  try {
    const hubspotWindow = rootWindow;
    if (!isGrowthSidePanelOpen(hubspotWindow)) {
      return false;
    }
    // close the GSP
    rootWindow.postMessage({
      name: 'minimize'
    }, hubspotWindow.origin);
    return true;
  } catch (e) {
    // do nothing
    return false;
  }
};