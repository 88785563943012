'use es6';

import { getAll as getAllProblems } from '../../private/action-problem-solution/actions/clone-dashboard/problem';
import { getProblem } from '../../private/action-problem-solution/common/get-problem';
import { isEnabled } from '../../private/action-problem-solution/common/is-enabled';
export function canCloneDashboard(params) {
  return isEnabled(params, getAllProblems);
}
export function getCloneDashboardProblem(params) {
  return getProblem(params, getAllProblems);
}