import { gate } from 'hub-http/gates';
import { MARKETING_ANALYTICS_PRIVATE_BETA } from 'reporting-ui-components/constants/gates';
export const SRV_PHASE_3_PART_2_GATE = 'ReportingEnablement:SRVPhase3Part2';
export const SRV_PSEUDO_STORY_REPORTS = 'ReportingEnablement:summaryv2';
export const CRM_SEARCH_FILTERS_V2 = 'ReportingEnablement:FiltersV2';
export const REPORTING_AI_INSIGHTS_SLIDER_GATE = 'reportingAI:insightSlider';
export const USE_VALID_INSIGHTS_API_FOR_TRENDLINES = 'RAAS:UseValidInsightsAPI';
export const JOURNEY_SRV_FILTERS_GATE = 'RA:JourneyDateRangeEditor';
export const ENABLE_OR_FILTERS = 'ReportingEnablement:EnableOrFilters';
export const JOURNEY_COLLECTION_GATE = 'RA:JourneyCollection';
export const DATASET_IN_SRV = 'RE:DatasetInSRV';
export const SRV_LITE_EDITTING_GATE = 'RE:SRVLiteEdittingGate';
export const AI_ACCESS_GATE = 'AIComponents:AISettings';
export const ENABLE_DATE_PROPERTY_FOR_ALL_CONFIG_REPORTS = gate('ReportingEnablement:EnableDatePropertyForAllConfigReports');
export const getIsUngatedForSummaryDatawellV2 = gates => (gates === null || gates === void 0 ? void 0 : gates.includes(SRV_PHASE_3_PART_2_GATE)) || (gates === null || gates === void 0 ? void 0 : gates.includes(MARKETING_ANALYTICS_PRIVATE_BETA));
export const getIsUngatedForSRVLiteEditting = (gates = []) => {
  return gates.includes(gate('RE:SRVLiteEdittingGate'));
};