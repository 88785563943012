import * as dataTypes from '../../constants/dataTypes';
export default {
  [dataTypes.COMPANIES]: 'company',
  [dataTypes.CONTACTS]: 'contact',
  [dataTypes.DEALS]: 'deal',
  [dataTypes.ENGAGEMENTS]: 'engagement',
  [dataTypes.LINE_ITEMS]: 'line_item',
  [dataTypes.PRODUCTS]: 'product',
  [dataTypes.TICKETS]: 'ticket',
  [dataTypes.FEEDBACK_SUBMISSIONS]: 'feedback_submission'
};