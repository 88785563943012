import quickFetch from 'quick-fetch';
export function makeSimpleQuickFetchWrapper(requestName, makeRequest) {
  return (...args) => {
    const earlyRequest = quickFetch.getRequestStateByName(requestName);
    let resultPromise;
    if (earlyRequest) {
      resultPromise = new Promise((resolve, reject) => {
        earlyRequest.whenFinished(result => {
          resolve(result);
          quickFetch.removeEarlyRequest(requestName);
        });
        earlyRequest.onError(() => {
          makeRequest(...args).then(resolve, reject).catch(reject);
          quickFetch.removeEarlyRequest(requestName);
        });
      });
    } else {
      resultPromise = makeRequest(...args);
    }
    return resultPromise;
  };
}