'use es6';

export const STATIC_INTEGRATION_DATA = {
  642: 'Zerys',
  1362: 'SnapApp',
  1552: 'Unbounce',
  24027: 'Scripted',
  25200: 'Zapier',
  25343: 'WordPress',
  25427: 'Wistia',
  25487: 'Magento',
  25573: 'ReadyTalk',
  25599: 'Scribe',
  25696: 'SnapEngage',
  25760: 'Klipfolio',
  25777: 'SalesforceIQ',
  25835: 'Bedrock Data',
  25881: 'LeadPages',
  25892: 'HubShop.ly for Shopify',
  25917: 'DataHero',
  25925: 'Verblio',
  26112: 'AppCues',
  26769: 'HubShop.ly for BigCommerce',
  26949: 'Base CRM',
  27036: 'Infer',
  27152: 'Seventh Sense',
  27301: 'HelloSign',
  27374: 'Uberflip',
  27496: 'App Data Room',
  27901: 'inboundli',
  27917: 'Perfect Audience',
  28280: 'CallRail',
  28285: 'Datanyze',
  28533: 'WorkflowMax',
  28536: 'Teamwork',
  28564: 'Eventbrite',
  28662: 'UberConference',
  28754: 'Unific (formerly Revenue Conduit)',
  28779: 'Smartling',
  28836: 'CData Software',
  28996: 'Grow',
  29107: 'Zendesk',
  29142: 'Aircall',
  29498: 'DataBox',
  29685: 'Privy',
  29707: 'Opensense',
  30238: 'Beacon eBooks',
  30430: 'LeadsBridge',
  30544: 'Conversica',
  30708: 'Cloudpipes',
  31318: 'Drift',
  31481: 'Azuqua',
  31765: 'AskNicely',
  31775: 'Dasheroo',
  31942: 'AdRoll',
  32259: 'HotJar',
  32363: 'AdEspresso by HootSuite',
  32450: 'PieSync',
  32460: 'Sigstr',
  32480: 'HubShop.ly for Magento',
  32532: 'Slemma',
  32725: 'Proposify',
  32941: 'Toky',
  33027: 'Automate.io',
  33205: 'Pure Chat',
  33232: 'Tray.io',
  33259: 'Front',
  33338: 'Wistia',
  33674: 'PandaDoc',
  33902: 'PhoneBurner',
  34212: 'Lucky Orange',
  35161: 'GoToWebinar',
  35186: 'SurveyMonkey',
  35224: 'Google Calendar',
  35569: 'Kixie',
  35774: 'Vidyard Pro',
  36196: 'Enthusem',
  36304: 'TwentyThree',
  36992: 'Olark',
  37115: 'LeadGnome',
  37204: 'Woopra',
  37409: 'Fomo',
  37454: 'Brightcove',
  37528: 'VOIQ',
  37845: 'Accelo',
  38080: 'CallPage',
  38273: 'Quuu',
  38341: 'briX',
  38577: 'Salesforce',
  38589: 'JustCall',
  38652: 'DepositFix',
  38659: 'Salesmsg',
  38741: 'Dossier',
  39247: 'TapClicks',
  39687: 'Instapage',
  39806: 'Stitch',
  40128: 'GetAccept',
  40568: 'Promo by Slidely',
  40901: 'iCapture',
  41074: 'Help Scout',
  41259: 'Justuno',
  41644: 'Map My Customers',
  41765: 'LiveChat',
  41985: 'BrightTALK',
  42082: 'LinkedIn Sales Navigator',
  43261: 'Cincopa',
  43817: 'Yext Review Generation',
  43994: 'Design Wizard',
  44044: 'Skyvia',
  48628: 'Yext Lead Generation',
  49055: 'Revoice',
  49078: 'Shakr',
  51340: 'Smile.io',
  51366: 'MagneticOne Mobile',
  51381: 'Shopify',
  51395: 'Belch.io',
  51665: 'Swoogo',
  51670: 'VidMob',
  51837: 'OrgChartHub',
  51841: 'Insycle',
  52136: 'Atomic Reach',
  52447: 'Integromat',
  52619: 'Attentive',
  52668: 'Needls.',
  52827: 'Powtoon',
  52964: 'Qwilr',
  53251: 'Viewbix',
  53446: 'Animoto',
  53656: 'Ceros',
  53904: 'G-Accon',
  54670: 'Survicate',
  54715: 'Postalytics',
  54736: 'Zoho Analytics',
  55021: 'Terminus',
  55554: 'Retreaver',
  55856: 'atEvent',
  56075: 'Rambl',
  56378: 'GetSiteControl',
  56440: 'MailChimp',
  57500: 'LeadDyno',
  58426: 'WooCommerce by MakeWebBetter',
  58713: 'XeroSync',
  58725: 'PhoneWagon',
  58827: 'PlusThis',
  59987: 'Data2CRM',
  60195: 'Import2 Wizard',
  60318: 'Visible',
  60729: 'Slack',
  60950: 'Power BI Integration by Bayard Bradford',
  61385: 'CircleLoop',
  61542: 'Paycove',
  62093: 'RingOver',
  62422: 'Typeform',
  62621: 'WorkCast',
  62823: 'Workplace by Facebook',
  62891: 'Wootric',
  62899: 'nDash.co',
  63044: 'Stripe',
  146082: 'Oktopost',
  168764: 'Leadfeeder',
  168891: 'RightMessage',
  169676: 'AdStage',
  169804: 'Intercom',
  176554: 'MAXG',
  178192: 'Zoom',
  178446: 'Google Search Console',
  179157: 'NeverBounce',
  180085: 'HubSpot Video powered by Vidyard',
  180421: 'RoboAuditor',
  182040: 'Outgrow',
  188672: 'Asana',
  GMAIL_ID: 'Gmail',
  OUTLOOK_ID: 'Outlook',
  FACEBOOK_ADS_ID: 'Facebook Ads'
};