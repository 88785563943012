'use es6';

import get from 'transmute/get';
import { Record, List, OrderedSet } from 'immutable';
import { ASSIGNMENT_MODES } from '../reportingActions/shared/permissionSettings/constants';
import { AssetPermission } from './assetPermission';
import { createPermissionGrant, UserSetType } from './permission-grant';
const {
  EDIT
} = AssetPermission;

// /**
//  * ACCESS_CLASS is a derived value sent from GET endpoints for convenience;
//  * It is not required for PUT / POST
//  */
const ACCESS_CLASS = 'accessClassification';
const PERM_LEVEL = 'permissionLevel';
const PERM_GRANTS = 'permissionGrants';

// Aka assignmentMode, conveys whether an asset is private, accessible to everyone, or partitioned
export const getAccessClassification = get(ACCESS_CLASS);

// Aka accessLevel, conveys the level of access (view & edit or view only) that those with access have to the asset
export const getPermissionLevel = get(PERM_LEVEL);

// Contains the set of users and teams that the asset was specifically assigned to
// See permission-grant file for the shape of these grants
export const getPermissionGrants = get(PERM_GRANTS);
export const PermissionConfigPayload = Record({
  [ACCESS_CLASS]: ASSIGNMENT_MODES.EVERYONE,
  [PERM_LEVEL]: EDIT,
  [PERM_GRANTS]: List([createPermissionGrant(UserSetType.API_ACCESS)])
});
export const PermissionLevel = {
  NONE: 'NONE',
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  CREATE_AND_OWN: 'CREATE_AND_OWN'
};
export const AssetPermissionToPermissionLevel = {
  [AssetPermission.ZERO]: PermissionLevel.NONE,
  [AssetPermission.VIEW]: PermissionLevel.VIEW,
  [AssetPermission.EDIT]: PermissionLevel.EDIT,
  [AssetPermission.CREATE_AND_OWN]: PermissionLevel.CREATE_AND_OWN
};
const PermissionLevelToAssetPermission = {
  [PermissionLevel.NONE]: AssetPermission.ZERO,
  [PermissionLevel.VIEW]: AssetPermission.VIEW,
  [PermissionLevel.EDIT]: AssetPermission.EDIT,
  [PermissionLevel.CREATE_AND_OWN]: AssetPermission.CREATE_AND_OWN
};

/**
 * @param UI state
 * @returns {PermissionConfigPayload}
 */
export const fromState = ({
  selectedUserSet,
  selectedTeamSet,
  assignmentMode,
  accessLevel
}) => {
  const createPermissionGrants = () => {
    if (assignmentMode === ASSIGNMENT_MODES.PRIVATE) {
      return List();
    }
    if (assignmentMode === ASSIGNMENT_MODES.EVERYONE) {
      /*
       Note: the API grant is always the same as permissionValue.
       For example, everyone who is an editor can edit.
       For example, everyone who is a viewer can view.
      */
      const apiGrant = createPermissionGrant(UserSetType.API_ACCESS);
      return List([apiGrant]);
    }
    const userPermissionGrants = List(selectedUserSet).map(userId => createPermissionGrant(UserSetType.SINGLE_USER, userId));
    const teamPermissionGrants = List(selectedTeamSet).map(teamId => createPermissionGrant(UserSetType.TEAM, teamId));
    return userPermissionGrants.concat(teamPermissionGrants);
  };
  const computePermissionLevel = () => {
    const value = assignmentMode === ASSIGNMENT_MODES.PRIVATE ? AssetPermission.ZERO : accessLevel;
    return AssetPermissionToPermissionLevel[value];
  };
  return PermissionConfigPayload({
    [ACCESS_CLASS]: assignmentMode,
    [PERM_LEVEL]: computePermissionLevel(),
    [PERM_GRANTS]: createPermissionGrants()
  });
};
const toEntitySet = type => grants => OrderedSet(grants.filter(grant => get('userSetType', grant) === type).map(get('userSetId')));

/**
 * Payload -> UI state
 * @param {PermissionConfigPayload} payload
 * @returns {object} state
 */
export const toState = payload => {
  const assignmentMode = getAccessClassification(payload);
  const permissionLevel = getPermissionLevel(payload);
  const permissionGrants = getPermissionGrants(payload);
  return {
    assignmentMode,
    accessLevel: PermissionLevelToAssetPermission[permissionLevel],
    selectedUserSet: toEntitySet(UserSetType.SINGLE_USER)(permissionGrants),
    selectedTeamSet: toEntitySet(UserSetType.TEAM)(permissionGrants)
  };
};