'use es6';

import { getAccessClassification, getPermissionGrants, getPermissionLevel } from './permission-payload';
import { UserSetType } from './permission-grant';
import { AssetPermission } from './assetPermission';
const getUserAndTeamGrantCounts = permissionGrants => {
  if (!permissionGrants || permissionGrants.size === 0) {
    return {};
  }
  const getCount = type => permissionGrants.filter(grant => grant.get('userSetType') === type).size;
  return {
    teamCount: getCount(UserSetType.TEAM),
    userCount: getCount(UserSetType.SINGLE_USER)
  };
};
export const getAccessSettingUsageProps = permissionConfig => {
  const accessLevel = getPermissionLevel(permissionConfig);
  return Object.assign({
    assignmentMode: getAccessClassification(permissionConfig).toLowerCase(),
    permissionValue: accessLevel === AssetPermission.NONE ? undefined : accessLevel.toLowerCase()
  }, getUserAndTeamGrantCounts(getPermissionGrants(permissionConfig)));
};