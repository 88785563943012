import { List as ImmutableList, fromJS } from 'immutable';
import { isBaseReport } from 'reporting-data/tsTypes/reportTypes';
import { NUMBER, PERCENT, CURRENCY, DURATION, ENUMERATION, STRING, DATE, DATE_TIME } from 'reporting-data/constants/property-types';
import { EDITOR_TYPES } from 'reporting-data/tsTypes/editorTypes';
export const getMetricEditorConfig = report => {
  const editorsFromConfig = isBaseReport(report) && report.get('editors');
  const editors = editorsFromConfig || ImmutableList([]);
  const metricEditor = editors.find(editor => editor.get('type') === EDITOR_TYPES.METRIC);
  if (!metricEditor) {
    return undefined;
  }
  const metrics = metricEditor.get('metrics') || ImmutableList([]);
  return fromJS({
    type: EDITOR_TYPES.METRIC,
    metrics
  });
};
export const getMetricsFromMetricsEditorReportConfig = report => {
  const metricEditor = getMetricEditorConfig(report);
  return metricEditor ? metricEditor.get('metrics') : ImmutableList([]);
};
export const isValidMetricProperty = (propertyData, isUnifiedAnalytics = false) => [NUMBER, PERCENT, CURRENCY, DURATION].includes(propertyData.type) || isUnifiedAnalytics && [ENUMERATION, STRING, DATE, DATE_TIME].includes(propertyData.type);