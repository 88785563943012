'use es6';

import { AD_OBJECT_AD_GROUP } from 'reference-resolvers/constants/ReferenceObjectTypes';
import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import { ADS_ADGROUPS } from 'reference-resolvers/constants/CacheKeys';
import { createFetchAdsObjectSearchPage, createFetchAdsObjectsByIds, fetchAdsObjectsByIds, fetchAdsObjectSearchPage } from 'reference-resolvers/api/AdsEntityApi';
export const createAdsAdgroupsReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: ADS_ADGROUPS,
  createFetchByIds: opts => createFetchAdsObjectsByIds(opts)(AD_OBJECT_AD_GROUP),
  createFetchSearchPage: opts => createFetchAdsObjectSearchPage(opts)(AD_OBJECT_AD_GROUP),
  fetchByIds: fetchAdsObjectsByIds(AD_OBJECT_AD_GROUP),
  fetchSearchPage: fetchAdsObjectSearchPage(AD_OBJECT_AD_GROUP)
}, options));
export default createAdsAdgroupsReferenceResolver();