'use es6';

import { fromJS, List } from 'immutable';
import http from 'hub-http/clients/apiClient';
const BASE_URL = 'pipelines/v2/pipelines';
let pipelines = null;
export const getPipelines = () => {
  if (!pipelines) {
    return http.get(BASE_URL, {
      query: {
        includeStageMetaPropertyNames: true
      }
    }).then(fromJS).then(response => {
      pipelines = response;
      return response;
    });
  }
  return Promise.resolve(pipelines);
};
export const getCachedPipelines = () => pipelines || List();
export const getDefaultDealPipelineId = (pipelineList = pipelines) => {
  const dealPipelines = pipelineList && pipelineList.filter(pipeline => pipeline.get('objectType') === 'DEAL');
  const defaultDealPipeline = dealPipelines && dealPipelines.find(pipeline => pipeline.get('default'));
  if (defaultDealPipeline) {
    return defaultDealPipeline.get('pipelineId');
  }
  return dealPipelines && dealPipelines.getIn([0, 'pipelineId']);
};
export default {
  getPipelines,
  getDefaultDealPipelineId
};