import { useEffect, useMemo, useState } from 'react';
import UICardGrid from 'UIComponents/card/UICardGrid';
import UIMultiColumnGroup from 'UIComponents/layout/UIMultiColumnGroup';
import UIMultiColumnItem from 'UIComponents/layout/UIMultiColumnItem';
const TBODY_ELEMENT_TYPE = 'TBODY';
const THEAD_ELEMENT_TYPE = 'THEAD';
const TR_ELEMENT_TYPE = 'TR';
export default function useCommentableAreaStyles({
  commentableArea,
  children
}) {
  const [isTableChild, setIsTableChild] = useState(false);
  const [isGridChild, setIsGridChild] = useState(false);
  useEffect(() => {
    // Checks if parent is UICardGrid so we can add extra styles usually passed by a > * combinator
    if (commentableArea && commentableArea.parentNode) {
      const parent = commentableArea.parentNode;
      if (parent instanceof HTMLElement) {
        setIsGridChild(parent.className.includes(UICardGrid.displayName));
      }
    }

    // Checks if parent is a UIMultiColumn and if the child is a UIMultiColumn group or item to apply border styles
    // that would usually collapse together
    if (commentableArea && commentableArea.parentNode && children && children instanceof Object && 'type' in children && children.type instanceof Object) {
      const isChildMultiColumn = children.type && (children.type.name === UIMultiColumnGroup.name || children.type.name === UIMultiColumnItem.name);
      if (isChildMultiColumn && commentableArea.parentNode instanceof HTMLElement) {
        commentableArea.parentNode.style.borderTop = '0';
      }
    }

    // Checks if parent is a table display to reapply table display to the
    // commentable area
    if (commentableArea && commentableArea.parentNode) {
      const parentNode = commentableArea.parentNode;
      if (!(parentNode instanceof HTMLElement)) {
        return;
      }
      const parentStyles = getComputedStyle(parentNode);
      const inlineParentStyles = parentNode.style;
      setIsTableChild(parentStyles.display === 'table' || inlineParentStyles && inlineParentStyles.display === 'table' || parentNode.nodeName === TBODY_ELEMENT_TYPE || parentNode.nodeName === THEAD_ELEMENT_TYPE);
    }
  }, [commentableArea, children]);
  const isTr = useMemo(() => {
    if (commentableArea) {
      return commentableArea.nodeName === TR_ELEMENT_TYPE;
    }
    return false;
  }, [commentableArea]);
  return {
    isTableChild,
    isGridChild,
    isTr
  };
}