import events from 'reporting-ui-components/events.yaml';
import once from 'transmute/once';
import { getPrimaryTracker } from 'usage-tracker-container';
import { Metrics } from './Metrics';
import { REPORTING_TRACKER, NO_PRIMARY_TRACKER } from 'reporting-data/constants/usageTracker';
import enviro from 'enviro';
import { emptyTracker } from 'reporting-data/tsTypes/usageTrackerTypes';
export const getUsageTracker = once(() => {
  try {
    const primaryTracker = getPrimaryTracker(REPORTING_TRACKER);
    if (enviro.isQa()) {
      // eslint-disable-next-line no-console
      console.info('reporting-ui-components: 🎉 Primary Usage Tracker Provided ✅');
    }
    return primaryTracker.clone({
      preserveTrackerProperties: false,
      preserveTrackerEvents: false,
      events
    });
  } catch (e) {
    Metrics.counter(NO_PRIMARY_TRACKER).increment();
    if (enviro.isQa()) {
      // eslint-disable-next-line no-console
      console.info('reporting-ui-components: 😢 No Primary Usage Tracker Provided ❌');
    }
    return emptyTracker;
  }
});