import { createSlice } from '@reduxjs/toolkit';
import { TEAMS_DATA_NAMESPACE } from './types';
import { fetchTeamsAsyncThunk } from './thunks';
import { RequestState } from 'dashboard-lib/public/request/request-state';
const initialState = {
  value: {},
  status: RequestState.UNINITIALIZED
};
const teamsDataSlice = createSlice({
  name: TEAMS_DATA_NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTeamsAsyncThunk.fulfilled, (state, action) => {
      const teams = action.payload;
      state.value = teams.reduce((acc, team) => {
        acc[team.id] = team;
        return acc;
      }, {});
      state.status = RequestState.SUCCEEDED;
    });
    builder.addCase(fetchTeamsAsyncThunk.pending, state => {
      state.status = RequestState.PENDING;
    });
    builder.addCase(fetchTeamsAsyncThunk.rejected, state => {
      state.status = RequestState.FAILED;
    });
  }
});
export const teamsDataReducer = teamsDataSlice.reducer;
export const __TESTABLE__ = {
  initialState
};