'use es6';

/**
 * Create + save a new report
 * Always shows the dashboard selection (except for attribution reports)
 */
export const CREATE_NEW_REPORT = 'CREATE_NEW_REPORT';

/**
 * Create + save a new report by cloning an existing report.
 * Always shows the dashboard selection (except for attribution reports)
 */
export const CLONE_EXISTING_REPORT = 'CLONE_EXISTING_REPORT';

/**
 * Edit an existing report
 * Never shows the dashboard selection.
 */
export const EDIT_EXISTING_REPORT = 'EDIT_EXISTING_REPORT';
export const SaveReportUseTypeList = [CREATE_NEW_REPORT, CLONE_EXISTING_REPORT, EDIT_EXISTING_REPORT];