import { List, fromJS } from 'immutable';
const ENGAGEMENT_COMPLETION_CONDITION = {
  CALL: {
    property: 'hs_call_status',
    operator: 'NEQ',
    value: 'IN_PROGRESS'
  },
  EMAIL: {
    property: 'hs_timestamp',
    operator: 'HAS_PROPERTY'
  },
  TASK: {
    property: 'hs_task_completion_date',
    operator: 'HAS_PROPERTY'
  },
  NOTE: {
    property: 'hs_timestamp',
    operator: 'HAS_PROPERTY'
  },
  CONVERSATION_SESSION: {
    property: 'hs_conversation_session_session_closed_at',
    operator: 'HAS_PROPERTY'
  },
  MEETING: {
    property: 'hs_meeting_outcome',
    operator: 'EQ',
    value: 'COMPLETED'
  }
};
export const getOnPlatformReport = report => {
  const getEngTypeFilter = engType => ({
    property: 'hs_engagement_type',
    operator: 'EQ',
    value: engType
  });
  return report.deleteIn(['displayParams', 'customWidget']).setIn(['config', 'filters', 'filterGroups'], fromJS(ENGAGEMENT_COMPLETION_CONDITION).reduce((acc, filter, engType) => acc.push(fromJS({
    filters: [filter, getEngTypeFilter(engType)]
  })), List()));
};