'use es6';

import { BACKWARD as FQF_BACKWARD, FORWARD as FQF_FORWARD } from '../../filterQueryFormat/rollingDates/RollingDateDirections';

// direction constants for date filters

export const BACKWARD = 'BACKWARD';
export const FORWARD = 'FORWARD';
export const FQF_TO_CONTACT_SEARCH_DIRECTION = {
  [FQF_BACKWARD]: BACKWARD,
  [FQF_FORWARD]: FORWARD
};
export const CONTACT_SEARCH_TO_FQF_DIRECTION = {
  [BACKWARD]: FQF_BACKWARD,
  [FORWARD]: FQF_FORWARD
};

// special terms for contact search

export const AND = 'AND';
export const NOT = 'NOT';
export const OR = 'OR';
export const RESERVE_WORDS = [AND, NOT, OR];
export const ESCAPED_RESERVE_WORDS = [`"${AND}"`, `"${NOT}"`, `"${OR}"`];