'use es6';

import * as ExternalOptionTypes from './ExternalOptionTypes';
import * as PropertyFieldTypes from './PropertyFieldTypes';
const getPropertyType = property => {
  const {
    externalOptions,
    fieldType,
    referencedObjectType
  } = property.toJS();
  const defaultFieldType = 'unknown';
  const isSelectWithExternalOptions = externalOptions && fieldType === PropertyFieldTypes.SELECT && referencedObjectType !== null && Object.prototype.hasOwnProperty.call(ExternalOptionTypes, referencedObjectType);
  return isSelectWithExternalOptions ? ExternalOptionTypes[referencedObjectType] : fieldType || defaultFieldType;
};
export default getPropertyType;