export const DRILLDOWN = 'drilldown';
export const DRILLDOWN_TYPES = {
  CRM_OBJECT: 'crm_object',
  UNIFIED_EVENTS: 'unified_events',
  UNIFIED_EVENTS_QL: 'unified_events_ql',
  EXTERNAL: 'external',
  FUNNEL: 'funnel',
  OTHER: 'other'
};
export const SELECT_TOP_STRING_TYPE_VALUES = 'select-top-resolved-with-string-type-values';
export const REPORTING_DATA_RESOLVE = 'reporting-data-resolve';
export const REPORTING_HTTP_REQUEST_INFO = 'reporting-http-request-info';
export const REPORTING_HTTP_REQUEST_ALERTING = 'reporting-http-request-alerting';
export const REPORTING_HTTP_REQUEST_DURATION = 'reporting-http-request-duration';