import * as chartTypes from '../../constants/chartTypes';
import { chartToTableReport } from '../../lib/convertToTableReport';
import { isConfigReport, isJourneyReport, isJourneyReportChartType } from '../../tsTypes/reportTypes';
import { getDimensions } from '../configReportGetters';
export const setReportChartType = (report, chartType) => {
  if (isConfigReport(report)) {
    return report.set('chartType', chartType);
  } else if (isJourneyReport(report) && isJourneyReportChartType(chartType)) {
    return report.set('chartType', chartType);
  }
  return null;
};
export const transformToSeriesChartType = chartType => report => {
  if (isConfigReport(report) && !getDimensions(report).size) {
    return null;
  }
  return setReportChartType(report, chartType);
};
export const transformToNonSeriesChartType = chartType => report => setReportChartType(report, chartType);
export const setChartTypeToTable = report => {
  if (isConfigReport(report)) {
    return chartToTableReport(report);
  } else if (isJourneyReport(report)) {
    return setReportChartType(report, chartTypes.TABLE);
  }
  return null;
};