'use es6';

import curry from 'transmute/curry';
import get from 'transmute/get';
import { regexTest } from '../../private/js-util';
export const getErrorName = get('name');
export const getErrorMessage = get('message');
export const createError = (name, message) => {
  const e = Error(message);
  e.name = name;
  return e;
};
export const matchErrorExact = curry((expectedError, givenError) => getErrorName(expectedError) === getErrorName(givenError) && getErrorMessage(expectedError) === getErrorMessage(givenError));
export const matchErrorMessageExact = curry((expectedMessage, givenError) => expectedMessage === getErrorMessage(givenError));
export const matchErrorMessageRegex = curry((regex, givenError) => regexTest(regex, getErrorMessage(givenError)));
export const throwPromise = error => {
  throw error;
};