// @ts-expect-error untyped dependency
import { isMismatchedDimensionDateFilterPropertyTimeSeries } from 'reporting-data/properties/isMismatchedDimensionDateFilterPropertyTimeSeries';
import { AREA, BAR, COLUMN, CUSTOM, DATA_WELL, LINE } from 'reporting-data/constants/chartTypes';
import { FUNNEL, PIPELINE, TIME_SERIES } from 'reporting-data/constants/configTypes';
export const canBeComparisonConfig = report => {
  const configType = report.getIn(['config', 'configType']);
  if ([FUNNEL, PIPELINE].includes(configType)) {
    return false;
  }
  const chartType = report.get('chartType');
  const dimensions = report.getIn(['config', 'dimensions']);
  const isMultiDimensional = dimensions && dimensions.size > 1;
  return [DATA_WELL, CUSTOM].includes(chartType) || [COLUMN, BAR] && !isMultiDimensional || [LINE, AREA].includes(chartType) && configType === TIME_SERIES && !isMultiDimensional;
};
export const shouldDisableCompareOption = report => {
  const isInvalidDateRangeAndDimension = isMismatchedDimensionDateFilterPropertyTimeSeries(report);
  return isInvalidDateRangeAndDimension || !canBeComparisonConfig(report);
};