import { Map as ImmutableMap } from 'immutable';
import { getDataType, getDimensions, getMetrics } from 'reporting-data/report/configReportGetters';
import { getMetricSortOptions, isSortableDimension } from '../utils';
import useFetchPropertyMetaData from 'reporting-data/hooks/useFetchPropertyMetaData';
import { useMemo } from 'react';
import { isSucceeded } from 'reporting-data/asyncData/AsyncData';
const useFetchSortablePropertyOptionsForDropdown = report => {
  const dataType = getDataType(report);
  const metrics = getMetrics(report);
  const dimensions = getDimensions(report);
  const propertyArgForMetaDataFetch = useMemo(() => {
    const metricProperties = metrics.map(metric => metric.get('property')).toList().toJS();
    const dimensionProperties = dimensions.toList().toJS();
    return [...metricProperties, ...dimensionProperties].map(property => ({
      dataType,
      property
    }));
  }, [dataType, dimensions, metrics]);
  const propertyMetaDataFetch = useFetchPropertyMetaData(propertyArgForMetaDataFetch);
  const dropdownOptions = useMemo(() => {
    if (!isSucceeded(propertyMetaDataFetch)) {
      return [];
    }
    const propertyMetaData = propertyMetaDataFetch.data[dataType];
    const metricOptions = metrics.flatMap(metric => getMetricSortOptions(propertyMetaData[metric.get('property')], metric)).toJS();
    const dimensionOptions = dimensions.map(dimension => propertyMetaData[dimension] || ImmutableMap()).filter(property => isSortableDimension(property, dataType)).map(property => ({
      value: property.name,
      text: property.label
    })).toList().toJS();
    return [...dimensionOptions, ...metricOptions];
  }, [dimensions, propertyMetaDataFetch, metrics, dataType]);
  return dropdownOptions;
};
export default useFetchSortablePropertyOptionsForDropdown;