import { useMemo, useEffect, useState } from 'react';
import { getEventsByProperty } from '../../../api/events';
import { getReportEvents } from '../common/journeyHelpers';
const getAnonymousVisitorEvents = () => getEventsByProperty(['journeyAnalytics'], 'allowAnonymousVisitors', true);
const isEnabledForEvents = (anonVisitorsEvents, eventsInReport) => anonVisitorsEvents.some(event => eventsInReport.includes(event));
export const useIsEnabledForReport = report => {
  const [anonVisitorsEvents, setAnonVisitorsEvents] = useState([]);
  const [needToRequestData, setNeedToRequestData] = useState(true);
  const eventsInReport = useMemo(() => getReportEvents(report), [report]);
  useEffect(() => {
    if (needToRequestData) {
      setNeedToRequestData(false);
      getAnonymousVisitorEvents().then(setAnonVisitorsEvents).catch(console.error);
    }
  }, [needToRequestData, setNeedToRequestData, setAnonVisitorsEvents]);
  return useMemo(() => isEnabledForEvents(anonVisitorsEvents, eventsInReport), [anonVisitorsEvents, eventsInReport]);
};