import { Record } from 'immutable';
export const FILTER_OPERATOR_ERROR_CODES = {
  VALUE_REQUIRED: 'VALUE_REQUIRED',
  INVALID_OPERATOR: 'INVALID_OPERATOR',
  MISSING_FIELD_ERROR: 'MISSING_FIELD_ERROR',
  INVALID_VALUE_OPTION: 'INVALID_VALUE_OPTION'
};
const FilterOperatorErrorRecord = Record({
  error: false,
  error_code: null,
  message: '',
  opts: {
    isRefinement: false,
    preventDefault: false
  }
}, 'FilterOperatorErrorRecord');
export default FilterOperatorErrorRecord;