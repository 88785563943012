'use es6';

import * as http from '../../request/http';
import toJS from '../../lib/toJS';
import precondition from '../../lib/precondition';
import { CONTACTS, DEALS, LEADS, TICKETS } from '../../constants/dataTypes';
import memoize from 'transmute/memoize';
const URL = 'pipelines/v2/pipelines';
export const Objects = {
  [CONTACTS]: 'CONTACT',
  [DEALS]: 'DEAL',
  [LEADS]: 'LEAD',
  [TICKETS]: 'TICKET'
};
const VALID_TYPES = Object.keys(Objects);
export const get = precondition(type => VALID_TYPES.includes(type), type => `expected one of ${VALID_TYPES}, but got ${type}`)(memoize(type => http.get(`${URL}/${Objects[type]}`, {
  query: {
    includeStageMetaPropertyNames: true
  }
}).then(toJS)));