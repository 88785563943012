import { createSlice } from '@reduxjs/toolkit';
import { buildFailedAsyncData, buildStartedAsyncData, buildSucceededAsyncData, buildUnitializedAsyncData } from 'reporting-data/asyncData/AsyncData';
import { getId as getDashboardId
// @ts-expect-error migrate upstream files
} from 'dashboard-lib/public/dashboard/dashboard-schema';
import { getId as getReportId
// @ts-expect-error migrate upstream files
} from 'dashboard-lib/public/report/report-schema';
import { getDashboardReports
// @ts-expect-error migrate upstream files
} from 'dashboard-lib/public/dashboard/dashboard-logic';
import { appliedDashboardFiltersActions } from '../applied-dashboard-filters/slice';
import { getOverriddenReportsFromAPIThunk, validateBEAppliedDashboardFiltersThunk } from './thunks';
import { getAppliedFiltersByReportFromDashboard, setAsyncState, resetAsyncStateOnFiltersChanged } from './helpers';
import { savedDashboardFiltersActions } from '../savedDashboardFilters';
export const dashboardReportsNamespace = 'dashboardReports';
export const initialState = {
  dashboardReports: {},
  validateFilterApplicationAsyncState: {}
};
const dashboardReportsSlice = createSlice({
  name: dashboardReportsNamespace,
  initialState,
  reducers: {
    initializeDashboardReports: (state, action) => {
      const {
        payload: {
          dashboard
        }
      } = action;
      const dashboardId = getDashboardId(dashboard);
      const dashboardReports = getDashboardReports(dashboard);
      const initialReportsConfig = dashboardReports.reduce((nextDashboardReports, reportConfig) => {
        const reportId = getReportId(reportConfig);
        nextDashboardReports[reportId] = reportConfig;
        return nextDashboardReports;
      }, {});
      state.dashboardReports[dashboardId] = {
        asyncState: buildUnitializedAsyncData(),
        reports: initialReportsConfig,
        appliedFiltersByReport: getAppliedFiltersByReportFromDashboard(dashboard)
      };
    }
  },
  extraReducers(builder) {
    builder.addCase(appliedDashboardFiltersActions.addDashboardFilter, (state, action) => {
      resetAsyncStateOnFiltersChanged(state, action);
    }).addCase(appliedDashboardFiltersActions.addGroupDashboardFilter, (state, action) => {
      resetAsyncStateOnFiltersChanged(state, action);
    }).addCase(appliedDashboardFiltersActions.updateDashboardFilter, (state, action) => {
      resetAsyncStateOnFiltersChanged(state, action);
    }).addCase(appliedDashboardFiltersActions.removeDashboardFilter, (state, action) => {
      resetAsyncStateOnFiltersChanged(state, action);
    }).addCase(appliedDashboardFiltersActions.removeCurrentDashboardFilters, (state, action) => {
      resetAsyncStateOnFiltersChanged(state, action);
    }).addCase(appliedDashboardFiltersActions.removeDashboardFilterByName, (state, action) => {
      resetAsyncStateOnFiltersChanged(state, action);
    }).addCase(appliedDashboardFiltersActions.removeGroupDashboardFilter, (state, action) => {
      resetAsyncStateOnFiltersChanged(state, action);
    }).addCase(appliedDashboardFiltersActions.initializeDashboardFilters, (state, action) => {
      resetAsyncStateOnFiltersChanged(state, action);
    }).addCase(savedDashboardFiltersActions.loadSavedDashboardFilterSet, (state, action) => {
      resetAsyncStateOnFiltersChanged(state, action);
    }).addCase(appliedDashboardFiltersActions.removeAllDashboardFilters, state => {
      Object.keys(state.dashboardReports).forEach(dashboardId => {
        resetAsyncStateOnFiltersChanged(state, {
          payload: {
            dashboardId: Number(dashboardId)
          }
        });
      });
    }).addCase(appliedDashboardFiltersActions.updateFrequency, (state, action) => {
      resetAsyncStateOnFiltersChanged(state, action);
    }).addCase(appliedDashboardFiltersActions.updateFrequencyUseFiscalYear, (state, action) => {
      resetAsyncStateOnFiltersChanged(state, action);
    });
    builder.addCase(getOverriddenReportsFromAPIThunk.pending, (state, action) => {
      const {
        arg: {
          dashboardId
        }
      } = action.meta;
      setAsyncState(state, dashboardId, buildStartedAsyncData());
    }).addCase(getOverriddenReportsFromAPIThunk.rejected, (state, action) => {
      const {
        meta: {
          arg: {
            dashboardId
          }
        },
        payload: error
      } = action;
      setAsyncState(state, dashboardId, buildFailedAsyncData(error));
    }).addCase(getOverriddenReportsFromAPIThunk.fulfilled, (state, action) => {
      const {
        meta: {
          arg: {
            dashboardId
          }
        },
        payload: dashboard
      } = action;
      const dashboardReports = getDashboardReports(dashboard);
      const overriddenReportsConfig = dashboardReports.reduce((nextDashboardReports, reportConfig) => {
        const reportId = getReportId(reportConfig);
        nextDashboardReports[reportId] = reportConfig;
        return nextDashboardReports;
      }, {});
      state.dashboardReports[dashboardId] = {
        asyncState: buildSucceededAsyncData(true),
        appliedFiltersByReport: getAppliedFiltersByReportFromDashboard(dashboard),
        reports: overriddenReportsConfig
      };
    });
    builder.addCase(validateBEAppliedDashboardFiltersThunk.pending, (state, action) => {
      const {
        meta: {
          arg: {
            dashboardId
          }
        }
      } = action;
      state.validateFilterApplicationAsyncState[dashboardId] = buildStartedAsyncData();
    }).addCase(validateBEAppliedDashboardFiltersThunk.rejected, (state, action) => {
      const {
        payload: error,
        meta: {
          arg: {
            dashboardId
          }
        }
      } = action;
      state.validateFilterApplicationAsyncState[dashboardId] = buildFailedAsyncData(error);
    }).addCase(validateBEAppliedDashboardFiltersThunk.fulfilled, (state, action) => {
      const {
        meta: {
          arg: {
            dashboardId
          }
        }
      } = action;
      state.validateFilterApplicationAsyncState[dashboardId] = buildSucceededAsyncData(undefined);
    });
  }
});
export const dashboardReportsActions = dashboardReportsSlice.actions;
export const dashboardReportsReducer = dashboardReportsSlice.reducer;