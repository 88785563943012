'use es6';

import { getAll } from '../../private/action-problem-solution/actions/move-report-to-existing-dashboard/problem';
import { getProblem } from '../../private/action-problem-solution/common/get-problem';
import { isEnabled } from '../../private/action-problem-solution/common/is-enabled';
export function canMoveReportToExistingDashboard(params) {
  return isEnabled(params, getAll);
}
export function getMoveReportToExistingDashboardProblem(params) {
  return getProblem(params, getAll);
}