export default {
  getOwners(context) {
    return context.getIn(['owner', 'owners'], null);
  },
  setOwners(context, newOwners) {
    return context.setIn(['owner', 'owners'], newOwners);
  },
  getTeams(context) {
    return context.getIn(['team', 'teams']);
  },
  setTeams(context, teams) {
    return context.setIn(['team', 'teams'], teams);
  },
  setFrequency(context, frequency) {
    return context.setIn(['frequency', 'histogram'], frequency);
  },
  getFrequency(context) {
    return context.getIn(['frequency', 'histogram']);
  },
  getDateRange(context) {
    return context.get('context');
  }
};