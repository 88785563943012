import * as checked from 'reporting-data/lib/checked';
export const SortOrders = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING'
};
export const SortOrder = checked.symbol(SortOrders, 'SortOrder');
export const SortTypes = {
  VALUE: 'VALUE',
  DISPLAY_ORDER: 'DISPLAY_ORDER',
  LABEL: 'LABEL'
};
export const SortType = checked.symbol(SortTypes, 'SortType');
export const Sort = checked.record({
  column: checked.string(),
  order: SortOrder,
  sortType: SortType.optional().defaultValue(SortTypes.VALUE)
}, 'Sort');
export const ColumnSort = checked.record({
  priority: checked.number(),
  type: SortType,
  order: SortOrder,
  by: checked.string().optional(),
  nested: checked.boolean().optional()
}, 'ColumnSort');