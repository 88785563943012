// @ts-expect-error migrate upstream
import { useRecurringShareAssets } from 'reporting-action-components/data/hooks/useRecurringShareAssets';
// @ts-expect-error migrate upstream
import { DASHBOARD_OBJECT_TYPE } from 'reporting-action-components/data/requests/emails';
import { useSavedDashboardFiltersPanelContext } from '../context';
export const useGetShareAssetsForFilterIds = filterSetIds => {
  const {
    dashboardId
  } = useSavedDashboardFiltersPanelContext();
  const {
    data: recurringShareAssets,
    loading
  } = useRecurringShareAssets({
    objectId: dashboardId,
    objectType: DASHBOARD_OBJECT_TYPE
  });
  const filteredSharedAssets = recurringShareAssets ? recurringShareAssets.toJS().filter(asset => asset.filterId ? filterSetIds.includes(asset.filterId) : false) : [];
  return {
    loading,
    shareAssets: filteredSharedAssets
  };
};