'use es6';

import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
import { fromJS, List } from 'immutable';
import indexBy from 'transmute/indexBy';
import get from 'transmute/get';
const formatCtaRecord = cta => {
  return new ReferenceRecord({
    id: cta.guid,
    label: cta.name,
    referencedObject: fromJS(cta)
  });
};
const formatCtas = response => {
  const results = indexBy(get('id'), List(response.results).map(formatCtaRecord));
  return {
    count: results.size,
    hasMore: response.hasMore,
    offset: response.offset,
    results
  };
};
export default formatCtas;