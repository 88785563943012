'use es6';

import { List } from 'immutable';
import { makeOperator } from './Operator';
export default makeOperator({
  name: 'In',
  values: [{
    name: 'value',
    defaultValue: List(),
    isValid: value => List.isList(value) && !value.isEmpty()
  }]
});