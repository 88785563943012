'use es6';

import { Promise } from '../../../lib/promise';
import { UNIFIED_FEEDBACK_TRENDS, UNIFIED_FEEDBACK_WEB, UNIFIED_FEEDBACK_EMAIL } from '../../../constants/dataTypes';
const dataTypes = [UNIFIED_FEEDBACK_TRENDS, UNIFIED_FEEDBACK_WEB, UNIFIED_FEEDBACK_EMAIL];
export const configure = config => {
  const dataType = config.get('dataType');
  if (dataTypes.includes(dataType)) {
    return Promise.resolve(config);
  }
  return Promise.resolve(config);
};