'use es6';

import PortalIdParser from 'PortalIdParser';
export const getRootDashboardRoute = () => `/reports-dashboard/${PortalIdParser.get()}`;
export const getDashboardBaseUrl = () => `/reports-dashboard/${PortalIdParser.get()}`;
export const getDashboardListUrl = () => `/dashboard-list/${PortalIdParser.get()}`;
export const getRecentlyViewedDisplayCount = () => 5;
export const getReportListUrl = dashboardId => {
  const additionalParams = dashboardId ? `?dashboardId=${dashboardId}` : '';
  return `/reports-list/${PortalIdParser.get()}${additionalParams}`;
};
export const getReportLibraryUrl = dashboardId => {
  return `/discover/${PortalIdParser.get()}/create/reports?dashboardId=${dashboardId}`;
};