// @ts-expect-error untyped module
import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
// @ts-expect-error untyped module
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { createGetCTAGuidPage, getCTAGuidPage, createGetCTASByGuids, getCTASByGuids } from 'reference-resolvers/api/CTAGuidAPI';
export const createCTAGuidReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.CTA_GUID,
  createFetchByIds: createGetCTASByGuids,
  createFetchSearchPage: createGetCTAGuidPage,
  fetchByIds: getCTASByGuids,
  fetchSearchPage: getCTAGuidPage
}, options));
export default createCTAGuidReferenceResolver();