'use es6';

import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { DEAL, REVENUE_DEAL_MERGE, EDITABLE_DEAL } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { getObjectsByIds, searchObjects, createGetObjectsByIds, createSearchObjects } from 'reference-resolvers/api/ContactSearchAPI';
export const createDealReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.DEALS,
  createFetchByIds: opts => createGetObjectsByIds(opts)(DEAL),
  createFetchSearchPage: opts => createSearchObjects(opts)(DEAL),
  fetchByIds: getObjectsByIds(DEAL),
  fetchSearchPage: searchObjects(DEAL)
}, options));
export const RevenueDealMergeReferenceResolver = createDealReferenceResolver({
  cacheKey: CacheKeys.REVENUE_DEAL_MERGES,
  createFetchByIds: opts => createGetObjectsByIds(opts)(REVENUE_DEAL_MERGE),
  createFetchSearchPage: opts => createSearchObjects(opts)(REVENUE_DEAL_MERGE),
  fetchByIds: getObjectsByIds(REVENUE_DEAL_MERGE),
  fetchSearchPage: searchObjects(REVENUE_DEAL_MERGE)
});
export const EditableDealReferenceResolver = createDealReferenceResolver({
  cacheKey: CacheKeys.EDITABLE_DEALS,
  createFetchByIds: opts => createGetObjectsByIds(opts)(EDITABLE_DEAL),
  createFetchSearchPage: opts => createSearchObjects(opts)(EDITABLE_DEAL),
  fetchByIds: getObjectsByIds(EDITABLE_DEAL),
  fetchSearchPage: searchObjects(EDITABLE_DEAL)
});
export default createDealReferenceResolver();