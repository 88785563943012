'use es6';

export const applySavedContext = (contextChange, context, __report, __subAppKey, __defaultContext, __scopes) => {
  const savedContext = context.savedContext;
  const activeQuestion = context.question;
  const newQuestion = contextChange.question;

  //case - first interaction with question Insights - save context
  if (!activeQuestion && newQuestion) {
    return Object.assign({}, contextChange, {
      savedContext: context
    });
  }
  //case - we had an active question, and are now clearing it - set context to saved context.
  if (activeQuestion && newQuestion === null) {
    return savedContext.toObject();
  }
  return contextChange;
};