import { buildUnitializedAsyncData } from 'reporting-data/asyncData/AsyncData';
import { dashboardReportsNamespace } from './slice';
function withState(selector) {
  return state => selector(state[dashboardReportsNamespace]);
}
export const selectDashboardReportsAsyncStatus = dashboardId => withState(state => {
  if (dashboardId in state.dashboardReports) {
    return state.dashboardReports[dashboardId].asyncState;
  }
  return buildUnitializedAsyncData();
});
export const selectOverriddenDashboardReport = (dashboardId, reportId) => withState(state => {
  if (dashboardId in state.dashboardReports && reportId in state.dashboardReports[dashboardId].reports) {
    return state.dashboardReports[dashboardId].reports[reportId];
  }
  return undefined;
});
export const selectBackendAppliedFiltersForReport = (dashboardId, reportId) => withState(state => {
  if (dashboardId in state.dashboardReports) {
    return state.dashboardReports[dashboardId].appliedFiltersByReport.filter(overridden => overridden.reportIds.includes(reportId)).map(overridden => overridden.dashboardFilter);
  }
  return [];
});
export const selectValidateFilterApplicationAsyncState = dashboardId => withState(state => {
  if (dashboardId in state.validateFilterApplicationAsyncState) {
    return state.validateFilterApplicationAsyncState[dashboardId];
  }
  return buildUnitializedAsyncData();
});