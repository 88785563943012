'use es6';

import formatName from 'I18n/utils/formatName';
import { Promise } from '../../../lib/promise';
import * as depth from '../depth';
import { get as getMetadata } from './metadata';
const formatNamesFromResponse = ({
  people = []
}) => people.reduce((references, {
  vid,
  firstname: firstName,
  lastname: lastName,
  email
}) => Object.assign({}, references, {
  [vid]: formatName({
    firstName,
    lastName,
    email
  })
}), {});
const formatDealsFromResponse = ({
  hydratedDeals = []
}) => hydratedDeals.reduce((refs, {
  dealId,
  dealName
}) => Object.assign({}, refs, {
  [dealId]: dealName
}), {});
export const fetchMetadataValues = (spec, config) => property => getMetadata(spec, config).then(response => Object.keys(response).reduce((references, breakdown) => Object.assign({}, references, {
  [breakdown]: response[breakdown][property]
}), {}));
const getReferencesFromMetadata = ({
  spec,
  config,
  data,
  primary,
  metadataProperty
}) => {
  const {
    response: {
      breakdowns = []
    } = {}
  } = data;
  if (primary === 'sessionDate') {
    return fetchMetadataValues(spec, config)(metadataProperty);
  }

  // totals reports already have metadata in response
  return breakdowns.filter(breakdown => breakdown.metadata && breakdown.metadata[metadataProperty] !== undefined).reduce((refs, {
    breakdown,
    metadata: meta = {}
  }) => Object.assign({}, refs, {
    [breakdown]: meta[metadataProperty]
  }), {});
};
const getReferencesFromModule = ({
  breakdowns,
  spec,
  config,
  ids,
  module
}) => {
  const reference = module.getIn(['references', breakdowns]);
  if (reference && typeof reference === 'function') {
    const getter = reference(spec, config);
    return getter(ids || []);
  }
  return {};
};
const getReferences = ({
  spec,
  config,
  module,
  data
}) => {
  const {
    dimensions: [primary]
  } = config;
  const {
    response,
    matrix: {
      keys = []
    } = {}
  } = data;
  const {
    dimensionality,
    drilldown: {
      metadata: metadataProperty,
      breakdowns
    } = {}
  } = depth.get(spec, config);
  const index = dimensionality - 1;
  const ids = keys[index] || [];
  const isMetadataReport = spec.metadata && spec.metadata[primary];
  const isSummaryReport = dimensionality === 1 && primary === 'sessionDate';
  if (isMetadataReport || isSummaryReport || dimensionality === 0) {
    return {};
  }
  if (primary === 'people') {
    return formatNamesFromResponse(response);
  }
  if (primary === 'deals-influenced') {
    return formatDealsFromResponse(response);
  }
  if (metadataProperty) {
    return Promise.resolve(getReferencesFromMetadata({
      spec,
      config,
      data,
      metadataProperty,
      primary,
      dimensionality
    })).then(references => {
      const missingIds = ids.filter(id => !references.hasOwnProperty(id));
      if (missingIds.length > 0) {
        return Promise.resolve(getReferencesFromModule({
          breakdowns,
          spec,
          config,
          data,
          ids: missingIds,
          module
        })).then(missingReferences => Object.assign({}, references, missingReferences));
      }
      return references;
    });
  }
  return getReferencesFromModule({
    breakdowns,
    spec,
    config,
    data,
    ids,
    module
  });
};
export const get = (spec, config, module) => data => {
  return Promise.resolve(getReferences({
    spec,
    config,
    module,
    data
  })).then(references => Object.assign({}, data, {
    references
  }));
};