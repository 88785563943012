import { userInfoSync } from 'hub-http/userInfo';
import { denormalizeTypeId, AUTOMATION_PLATFORM_FLOW_TYPE_ID, FORM_DASHBOARD_TYPE_ID, SITE_PAGE_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { AUTOMATION_PLATFORM_FLOW, FORM_DASHBOARD, SITE_PAGE } from 'customer-data-objects/constants/ObjectTypes';
import COMMENT_TYPES from 'collaboration-sidebar/constants/CommentTypes';
import { COMMENTABLE_AREA_IDENTIFIER, COMMENT_INDICATOR_IDENTIFIER } from 'collaboration-sidebar/constants/identifiers';
import CommentObjectType from 'collaboration-sidebar/constants/CommentObjectType';
// Required to stop propogation when clicking a comment
// TODO: Can be removed by adding tracking of if a single
// commentable area is active or not.
export const isTargetCommentElement = target => {
  if (!target) {
    return false;
  }

  // If the click is in the main commentable area
  if (target.className.includes(COMMENTABLE_AREA_IDENTIFIER)) {
    return true;
  }

  // If the click has no parent we can't check anything
  // else if it's a commentable area
  if (!target.parentElement) {
    return false;
  }

  // If the parent is the comment indicator icon, capture it as a click.
  return target.parentElement.className.includes(COMMENT_INDICATOR_IDENTIFIER) || target.parentElement.id === COMMENT_INDICATOR_IDENTIFIER;
};
export function isFromCurrentUser(comment, userInfoData) {
  const userId = userInfoData ? userInfoData.user.user_id : userInfoSync().user.user_id;
  return comment.userId === userId;
}
export function getTimeSincePreviousCommentIfFromDifferentUser(comments, userInfoData) {
  const previousComment = comments.slice().reverse().find(comment => comment.type !== COMMENT_TYPES.REPLY);
  if (!previousComment || isFromCurrentUser(previousComment, userInfoData)) {
    return null;
  }
  return Date.now() - Date.parse(previousComment.createdAt);
}
export function mapCommentResolveStatusToType(commentResolvedStatus) {
  if (commentResolvedStatus === true) {
    return 'resolved';
  }
  return 'unresolved';
}
export function createModuleRepliesMap(unreadComments) {
  return unreadComments.reduce((repliesMap, comment) => {
    const parentId = comment.parentId;
    const isUnreadReply = comment.type === COMMENT_TYPES.REPLY && !!parentId;
    if (!isUnreadReply) {
      return repliesMap;
    }
    if (repliesMap[parentId]) {
      repliesMap[parentId].push(comment);
    } else {
      repliesMap[parentId] = [comment];
    }
    return repliesMap;
  }, {});
}

/**
 * Returns the object type name used in commenting BE based on either the object type id or object name.
 *
 * Example:
 *  - getCommentingObjectType('0-35') => 'CAMPAIGN'
 *  - getCommentingObjectType(CAMPAIGN) => 'CAMPAIGN'
 */
export function getCommentingObjectType(objectTypeId) {
  switch (objectTypeId) {
    /**
     * The FORM object type (0-28) was created as FORM_DASHBOARD in CDO, which causes denormalizeTypeId
     * to return FORM_DASHBOARD (const) as opposed to FORM (object type name).
     */
    case FORM_DASHBOARD_TYPE_ID:
    case FORM_DASHBOARD:
      return 'FORM';

    /**
     * WEBSITE_PAGE is actually SITE_PAGE in CDO. We need to keep sending this value
     * until we migrate to commenting v3 → which will then become SITE_PAGE
     */
    case SITE_PAGE_TYPE_ID:
    case SITE_PAGE:
      return 'WEBSITE_PAGE';

    /**
     * WORKFLOW is actually AUTOMATION_PLATFORM_FLOW in CDO. We need to keep sending this value
     * until we migrate to commenting v3 → which will then become AUTOMATION_PLATFORM_FLOW
     */
    case AUTOMATION_PLATFORM_FLOW_TYPE_ID:
    case AUTOMATION_PLATFORM_FLOW:
      return 'WORKFLOW';
    default:
      return denormalizeTypeId(objectTypeId);
  }
}

/**
 * https://issues.hubspotcentral.com/browse/MO-602
 * This is a temporary hack until we fully release commenting v3.
 * The object type names used below are using different names than their CRM counterpart, making commenting v3 fail to resolve the object types.
 * We should be good to remove this once MO:CollaborationSidebar:FASCommenting is ungated to all
 */
export function maybeUpdateObjectTypeForV3(objectType) {
  if (objectType === CommentObjectType.WORKFLOW) {
    return 'AUTOMATION_PLATFORM_FLOW';
  } else if (objectType === CommentObjectType.WEBSITE_PAGE) {
    return 'SITE_PAGE';
  }
  return objectType;
}