'use es6';

import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
import { getAllActivityTypes, createGetAllActivityTypes } from 'reference-resolvers/api/ActivityTypeAPI';
export const createActivityTypeReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.ACTIVITY_TYPES,
  createFetchData: createGetAllActivityTypes,
  fetchData: getAllActivityTypes
}, options));
export default createActivityTypeReferenceResolver();