import http from 'hub-http/clients/apiClient';
import { Map as ImmutableMap } from 'immutable';
import { SnowflakeOption, SnowflakeProperty } from '../schema/source-records';
import { propertyLabelTranslator } from 'property-translator/propertyTranslator';
import { withQuickFetch } from './with-quick-fetch';
import { dateDurations } from 'reporting-data/constants/dateDurations';
const extendedReportableOptionsUrl = 'sql-reporting/v1/extended-reportable-options/data-sources/properties';
let propertyCache = ImmutableMap();
const toSnowflakeOption = option => {
  const {
    value,
    label,
    description,
    displayOrder
  } = option;
  return SnowflakeOption({
    value,
    label,
    description,
    displayOrder
  });
};
export function toSnowflakeProperty(propertyResponse) {
  const {
    propertyDefinition: {
      property
    },
    flpRestricted,
    hiddenForReportBuilder,
    rollupPropertyObjectTypeIds,
    rollupProperty,
    rollupPropertyErrorLevel,
    canBeResolvedWithLabelExpression
  } = propertyResponse;
  const {
    name,
    label,
    type,
    groupName,
    numberDisplayHint,
    dateDisplayHint,
    description,
    options
  } = property;

  /**
   * temporary solution while we wait for InboundDbProperties to provide the translations directly in the API response
   * see: https://docs.google.com/document/d/14RnC2Zs3-mIu7byk0WilOoMtRjO38xRELfm6o2_Wsxs/edit#
   */
  const translatedLabel = property.hubspotDefined ? propertyLabelTranslator(label) : label;
  return SnowflakeProperty({
    name,
    /**
     * Why do we have a default logic here for `label`? Isn't `label` required according to the backend model?
     * Answer: although `label` is required by the backend model, it is not guaranteed to be present in the response for older properties.
     * Slack: https://hubspot.slack.com/archives/C012YFD25PY/p1597782782031500
     */
    label: translatedLabel || name,
    type,
    groupName,
    description,
    hidden: hiddenForReportBuilder,
    numberDisplayHint,
    dateDisplayHint,
    rollupProperty,
    rollupPropertyObjectTypeIds,
    rollupPropertyErrorLevel,
    canBeResolvedWithLabelExpression,
    options: options ? options.map(option => toSnowflakeOption(option)) : undefined,
    metaDefinition: property,
    flpRestricted
  });
}
const _fetchProperties = dataSourceId => http.get(`${extendedReportableOptionsUrl}/${dataSourceId}`);
const fetchProperties = withQuickFetch('reporting-snowflake:contact-properties', _fetchProperties, dataSourceId => dataSourceId === '0-1');
const fetchAndTransformProperties = dataSourceId => fetchProperties(dataSourceId).then(properties => {
  const mapPropertyResponse = ([, propertyResponse]) => {
    const snowflakeProperty = toSnowflakeProperty(propertyResponse);
    return [snowflakeProperty.name, snowflakeProperty];
  };
  const snowflakeProperties = ImmutableMap(properties
  // @ts-expect-error untyped immutables
  ).mapEntries(mapPropertyResponse).filter(property => {
    const dateDisplayHint = property.dateDisplayHint;
    return !dateDisplayHint || !dateDurations.includes(dateDisplayHint);
  }).toMap();
  return snowflakeProperties;
});
export const getSnowflakeProperties = dataSourceId => {
  if (!propertyCache.has(dataSourceId)) {
    propertyCache = propertyCache.set(dataSourceId, fetchAndTransformProperties(dataSourceId));
  }
  return propertyCache.get(dataSourceId);
};