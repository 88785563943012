'use es6';

import { List } from 'immutable';
import { addExpressionFieldFilterToStage, addFieldFilterToStage, addPropertyFilterToStage, removeAggregateFilters, removeFiltersWithField } from '../../modify/filters/filter-utils';
import { AggregationTypes, FieldSources, defaultEncodingOptions } from '../../schema/column-records';
import { SortTypes } from '../../schema/sort-records';
import { DATASET_TABLE } from '../../schema/table-records';
import { VisualTypes } from '../../schema/visual-records';
import { createDefaultDimensionWithTransform, isMagicMeasure, applyDefaultAggregation, clearColumnAggregation, clearColumnTransform, setColumnTransform, getDefaultTransform, isFieldExpressionBased, getFieldKey } from '../../utils/column-utils';
import { getValidVisualTypes } from '../../utils/magic-wand-utils';
import { createDefaultReport, getReportVisualType, removeUnencodedColumns, removeTable, replaceMagicMeasure, getExpressionFieldFromColumn } from '../../utils/report-utils';
import { findEventTable, getTableList } from '../../utils/table-utils';
import { transformVisualToNewType } from '../../utils/transform-visual-type-util';
import { decodeChannel, decodeColumn, encodeChannel, findEncodedChannel, getChannel, getEncodingListInChannel, isSingleEncoding, getVisualEncodedColumnIds, getPreferredRoleInVisualChannel, dateComparisonAllowedForVisualType, buildEncodingFromColumn, findEncodingInStage, placeEncodingIntoChannel, replaceEncodingInChannel, getEncodingList, getChannelEncoding } from '../../utils/visual-utils';
import { createDefaultColumnUsingExpression } from '../../utils/visual-column-utils';
import { applyModifiers } from './modifiers';
import { setInitialDateComparisonEncodings } from '../../utils/date-comparison-filter-utils';
import { checkExpressionField, expressionFieldsListToMap, buildFieldFromExpressionField, doesFieldReferenceExpression } from '../../utils/expression-field-utils';
import { toFieldLabelExpression, toFieldNameExpression } from '../../../dataset/expression-utils';
import { textRenameField } from '../../../dataset/text-utils';
import { Contexts } from '../../../dataset/context-records';
import { getValidColumnSorts } from '../../modify/sorts/report-sort-utils';
import { COLUMN_ROLES } from 'reporting-data/constants/relationalReports';
const withModifiers = updater => (...args) => {
  const nextReport = updater(...args);
  const [__originalReport, {
    deps
  }] = args;
  return applyModifiers(nextReport, deps);
};
export const resetReport = () => createDefaultReport();
export const describeTable = withModifiers((report = resetReport(), {
  tableDescription
}) => {
  const switchingFromDatasetsToAutoJoin = report.table.name === DATASET_TABLE && tableDescription.name !== DATASET_TABLE;
  if (switchingFromDatasetsToAutoJoin) {
    report = resetReport();
  }
  const withEventMagicMeasuresReplaced = replaceMagicMeasure(report, findEventTable(report.table), findEventTable(tableDescription));
  const incomingTableList = getTableList(tableDescription);
  const tablesToRemove = getTableList(report.table).filterNot(table => incomingTableList.some(incoming => incoming.name === table.name));
  return tablesToRemove.reduce((resultReport, tableSourceToRemove) => removeTable(resultReport, tableSourceToRemove), withEventMagicMeasuresReplaced).set('table', tableDescription);
});
const transformColumnToCorrectRole = (report, column, channelName, snowflakeProperty) => {
  const visualType = getReportVisualType(report);
  const columnField = column.field;
  if (isMagicMeasure(column)) {
    return column;
  }
  if (column.aggregation === AggregationTypes.DELEGATE) {
    return column;
  }
  const preferredRoleForChannel = getPreferredRoleInVisualChannel(visualType, channelName, columnField);
  if (preferredRoleForChannel && column.role !== preferredRoleForChannel) {
    if (preferredRoleForChannel === COLUMN_ROLES.MEASURE) {
      return column.update(col => applyDefaultAggregation(col, snowflakeProperty)).update(col => clearColumnTransform(col));
    } else {
      return column.update(col => clearColumnAggregation(col)).update(col => setColumnTransform(col, getDefaultTransform(col.field)));
    }
  }
  return column;
};

// toIndex is optional, will append encoding if not provided
export const moveStagedEncodingToChannel = withModifiers((report, {
  encoding,
  toChannel,
  toIndex
}) => {
  const stagedColumn = report.stagedColumns.find(({
    alias
  }) => encoding.column === alias);
  if (!stagedColumn) {
    throw new Error('Expected to find a staged column for this encoding', encoding);
  }
  const encodings = report.visual.encodings;
  const nextEncodings = encodings.update(toChannel, currentEncoding => {
    if (isSingleEncoding(getChannel(toChannel))) {
      return encoding;
    }
    if (toIndex === undefined) {
      return (currentEncoding || List()).push(encoding);
    }
    return (currentEncoding || List()).insert(toIndex, encoding);
  });
  return report.update('stagedEncodings', stagedEncodings => stagedEncodings.filterNot(({
    column
  }) => column === encoding.column)).update('stagedColumns', stagedColumns => stagedColumns.filterNot(({
    alias
  }) => alias === encoding.column)).setIn(['visual', 'encodings'], nextEncodings).setIn(['columns', encoding.column], transformColumnToCorrectRole(report, stagedColumn, toChannel));
});

// toIndex is optional, will append encoding if not provided
export const moveEncoding = withModifiers((report, {
  encoding,
  toChannel,
  toIndex
}) => {
  const encodings = report.visual.encodings;
  const channel = findEncodedChannel(report.visual, encoding);
  const fromChannel = channel.name;
  const fromIndex = getEncodingListInChannel(encodings, channel.name).indexOf(encoding);
  const nextEncodings = decodeChannel(encodings, fromChannel, fromIndex).update(toChannel, currentEncoding => {
    if (isSingleEncoding(getChannel(toChannel))) {
      return encoding;
    }
    if (toIndex === undefined) {
      return (currentEncoding || List()).push(encoding);
    }
    return (currentEncoding || List()).insert(fromChannel === toChannel && toIndex > fromIndex ? toIndex - 1 : toIndex, encoding);
  });
  return report.setIn(['visual', 'encodings'], nextEncodings).setIn(['columns', encoding.column], transformColumnToCorrectRole(report, report.columns.get(encoding.column), toChannel));
});

// toIndex is optional, will append staged column if not provided
export const moveStagedEncodingToPosition = (report, {
  encoding,
  toIndex
}) => {
  let updatedStagedEncodings;
  return report.update('stagedEncodings', stagedEncodings => {
    const currentIndex = stagedEncodings.indexOf(encoding);
    updatedStagedEncodings = stagedEncodings.delete(currentIndex).insert(toIndex > currentIndex ? toIndex - 1 : toIndex, encoding);
    return updatedStagedEncodings;
  }).update('stagedColumns', stagedColumns => stagedColumns.sort((a, b) => updatedStagedEncodings.findIndex(entry => entry.get('column') === a.get('alias')) - updatedStagedEncodings.findIndex(entry => entry.get('column') === b.get('alias'))));
};

// toIndex is optional, will append column if not provided
const addColumnToChannelHelper = (report, {
  column,
  channelName,
  toIndex,
  snowflakeProperty,
  encodingOptions,
  deps
}) => {
  const {
    meta
  } = deps;
  const {
    preferred: preferredSort
  } = getValidColumnSorts(report, column.delete('sort'), meta);
  const sortedColumn = column.set('sort', preferredSort);
  const toReturn = report.update('columns', oldColumns => oldColumns.set(sortedColumn.alias, transformColumnToCorrectRole(report, sortedColumn, channelName, snowflakeProperty))).update('stagedColumns', stagedColumns => stagedColumns.filterNot(stagedColumn => stagedColumn.equals(column))).update('stagedEncodings', stagedEncodings => stagedEncodings.filterNot(stagedEncoding => stagedEncoding.column === column.alias)).updateIn(['visual', 'encodings'], encodings => {
    const stagedEncoding = findEncodingInStage(report.stagedEncodings, column);
    if (stagedEncoding) {
      return placeEncodingIntoChannel({
        encodings,
        channelName,
        toIndex,
        encoding: stagedEncoding
      });
    }
    const expressionField = getExpressionFieldFromColumn(report, column);
    return encodeChannel({
      encodings,
      channelName,
      column,
      expressionField,
      snowflakeProperty,
      toIndex,
      encodingOptions
    });
  }).update('filtering', filtering => {
    const {
      field
    } = column;
    const {
      name,
      source
    } = field;
    if (source === FieldSources.EXPRESSION) {
      const expressionsMap = expressionFieldsListToMap(report.expressions);
      const expression = expressionsMap.get(name);
      if (!expression) {
        console.error(`Expected to find expression with given \`field.name\`: ${name}`);
        return filtering;
      }

      // Aggregate context expression filters are not supported at the moment. Can be removed later.
      if (expression.context === Contexts.AGGREGATE) {
        return filtering;
      }
      return addExpressionFieldFilterToStage(filtering, field);
    }
    if (column.field.table === DATASET_TABLE) {
      // Same as above. Aggregate context expression filters are not supported at the moment. Can be removed later.
      if (column.role === COLUMN_ROLES.MEASURE) {
        return filtering;
      }
      return addFieldFilterToStage(filtering, column.field);
    }
    return addPropertyFilterToStage(filtering, column.field);
  });
  return toReturn;
};
const getExpressionFieldsMap = report => {
  return report.expressions.toMap().mapKeys((__, field) => field.alias);
};
const addExpressionFieldToChannelHelper = (report, {
  field,
  channelName,
  toIndex,
  hasValidCurrencyProperty,
  deps
}) => {
  const expressionField = getExpressionFieldsMap(report).get(field.name);
  const visualType = report.visual.type;
  const column = createDefaultColumnUsingExpression(expressionField, channelName, visualType, report.expressions);
  return addColumnToChannelHelper(report, {
    column,
    channelName,
    toIndex,
    snowflakeProperty: undefined,
    hasValidCurrencyProperty,
    deps
  });
};
export const addExpressionFieldToChannel = withModifiers(addExpressionFieldToChannelHelper);
export const addColumnToChannel = withModifiers((...args) => addColumnToChannelHelper(...args));
export const moveStagedEncodingToMagicWand = withModifiers((report, {
  encoding,
  index,
  deps
}) => {
  const {
    userInfo
  } = deps;
  const stagedColumn = report.stagedColumns.find(({
    alias
  }) => encoding.column === alias);
  if (!stagedColumn) {
    throw new Error('Expected to find a staged column for this encoding', encoding);
  }
  const column = stagedColumn;
  const channelName = 'columns';
  const toIndex = index;
  const originalVisualType = report.getIn(['visual', 'type']);
  const magicVisual = report.get('columns').size > 0 ? transformVisualToNewType(report.get('visual'), VisualTypes.TABLE, removeUnencodedColumns(report).columns, userInfo) : report.get('visual');
  const magicReport = report.set('visual', magicVisual).update('columns', oldColumns => oldColumns.set(column.alias, transformColumnToCorrectRole(report, column, channelName))).update('stagedColumns', stagedColumns => stagedColumns.filterNot(_stagedColumn => _stagedColumn.equals(column))).update('stagedEncodings', stagedEncodings => stagedEncodings.filterNot(stagedEncoding => stagedEncoding.equals(encoding))).updateIn(['visual', 'encodings'], encodings => placeEncodingIntoChannel({
    encodings,
    channelName,
    toIndex,
    encoding
  })).update('filtering', filtering => column.field.table === DATASET_TABLE ? addFieldFilterToStage(filtering, column.field) : addPropertyFilterToStage(filtering, column.field));
  const validVisuals = getValidVisualTypes(removeUnencodedColumns(magicReport).columns);
  if (validVisuals.includes(originalVisualType)) {
    return magicReport.set('visual', transformVisualToNewType(magicReport.get('visual'), originalVisualType, removeUnencodedColumns(magicReport).columns, userInfo));
  }
  return magicReport;
});
export const addColumnToMagicWand = withModifiers((report, {
  column,
  channelName,
  toIndex,
  snowflakeProperty,
  deps
}) => {
  const {
    userInfo
  } = deps;
  const originalVisualType = report.getIn(['visual', 'type']);
  const magicVisual = report.get('columns').size > 0 ? transformVisualToNewType(report.get('visual'), VisualTypes.TABLE, removeUnencodedColumns(report).columns, userInfo) : report.get('visual');
  const magicReport = report.set('visual', magicVisual).update('columns', oldColumns => oldColumns.set(column.alias, transformColumnToCorrectRole(report, column, channelName))).update('stagedColumns', stagedColumns => stagedColumns.filterNot(stagedColumn => stagedColumn.equals(column))).updateIn(['visual', 'encodings'], encodings => {
    const stagedEncoding = findEncodingInStage(report.stagedEncodings, column);
    if (stagedEncoding) {
      return placeEncodingIntoChannel({
        encodings,
        channelName,
        toIndex,
        encoding: stagedEncoding
      });
    }
    const expressionField = getExpressionFieldFromColumn(report, column);
    return encodeChannel({
      encodings,
      channelName,
      column,
      expressionField,
      toIndex,
      snowflakeProperty
    });
  }).update('filtering', filtering => column.field.source === FieldSources.EXPRESSION ? addExpressionFieldFilterToStage(filtering, column.field) : column.field.table === DATASET_TABLE ? addFieldFilterToStage(filtering, column.field) : addPropertyFilterToStage(filtering, column.field));
  const validVisuals = getValidVisualTypes(removeUnencodedColumns(magicReport).columns);
  if (validVisuals.includes(originalVisualType)) {
    return magicReport.set('visual', transformVisualToNewType(magicReport.get('visual'), originalVisualType, removeUnencodedColumns(magicReport).columns, userInfo));
  }
  return magicReport;
});
// toIndex is optional, will append field if not provided
export const addFieldToStage = (report, {
  field,
  toIndex
}) => {
  const isExpression = isFieldExpressionBased(field);
  let column;
  let expressionField;
  if (isExpression) {
    expressionField = expressionFieldsListToMap(report.expressions).get(field.name);
    if (!expressionField) {
      console.error(`Expected to find expression with given \`field.name\`: ${field.name}`);
      return report;
    }
    column = createDefaultColumnUsingExpression(expressionField, undefined, VisualTypes.TABLE, report.expressions);
  } else {
    column = createDefaultDimensionWithTransform(field);
  }
  const encoding = buildEncodingFromColumn(column, expressionField);
  return report.update('stagedColumns', stagedColumns => toIndex === undefined ? stagedColumns.push(column) : stagedColumns.insert(toIndex, column)).update('stagedEncodings', stagedEncodings => toIndex === undefined ? stagedEncodings.push(encoding) : stagedEncodings.insert(toIndex, encoding));
};

// toIndex is optional, will append column if not provided
// snowflakeProperty is optional
export const addColumnToStage = (report, {
  column,
  toIndex
}) => {
  const expressionField = getExpressionFieldFromColumn(report, column);
  const encoding = buildEncodingFromColumn(column, expressionField);
  return report.update('stagedColumns', stagedColumns => toIndex === undefined ? stagedColumns.push(column) : stagedColumns.insert(toIndex, column)).update('stagedEncodings', stagedEncodings => toIndex === undefined ? stagedEncodings.push(encoding) : stagedEncodings.insert(toIndex, encoding));
};

// toIndex is optional, will append encoding if not provided
export const moveEncodingToStagedEncodings = withModifiers((report, {
  encoding,
  toIndex
}) => {
  const {
    column: encodedColumn
  } = encoding;
  const column = report.getIn(['columns', encodedColumn]);
  return report.update('columns', columns => columns.delete(encodedColumn)).update('visual', visual => decodeColumn(visual, column)).update('stagedColumns', stagedColumns => toIndex === undefined ? stagedColumns.push(column) : stagedColumns.insert(toIndex, column)).update('stagedEncodings', stagedEncodings => toIndex === undefined ? stagedEncodings.push(encoding) : stagedEncodings.insert(toIndex, encoding));
});
export const removeEncodingFromChannel = withModifiers((report, {
  channelName,
  index
}) => report.updateIn(['visual', 'encodings'], encodings => decodeChannel(encodings, channelName, index)));
export const removeEncodingFromMagicWand = withModifiers((report, {
  channelName,
  index,
  deps
}) => {
  const {
    userInfo
  } = deps;
  const originalVisualType = report.getIn(['visual', 'type']);
  const magicVisual = transformVisualToNewType(report.get('visual'), VisualTypes.TABLE, removeUnencodedColumns(report).columns, userInfo);
  report = report.set('visual', magicVisual).updateIn(['visual', 'encodings'], encodings => decodeChannel(encodings, channelName, index));
  const validVisuals = getValidVisualTypes(removeUnencodedColumns(report).columns);
  if (validVisuals.includes(originalVisualType)) {
    return report.set('visual', transformVisualToNewType(report.get('visual'), originalVisualType, removeUnencodedColumns(report).columns, userInfo));
  }
  return report;
});
export const removeEncodingFromStage = withModifiers((report, {
  index
}) => {
  const next = report.update('stagedEncodings', stagedEncodings => stagedEncodings.delete(index));
  const stagedColumnsReferencedByStagedEncodings = next.stagedEncodings.map(encoding => encoding.column).toSet();
  return next.update('stagedColumns', stagedColumns => stagedColumns.filter(column => stagedColumnsReferencedByStagedEncodings.has(column.alias)));
});
export const updateSorts = withModifiers((report, {
  sorts
}) => report.set('sorts', sorts));
export const updateEncoding = withModifiers((report, {
  encoding,
  channelName,
  toIndex
}) => {
  return report.updateIn(['visual', 'encodings'], encodings => replaceEncodingInChannel({
    encodings,
    channelName,
    encoding,
    toIndex
  }));
});
export const updateMagicWandEncoding = withModifiers((report, {
  encoding,
  toIndex
}) => {
  const channelName = report.getIn(['visual', 'type']) === VisualTypes.TABLE ? 'columns' : report.getIn(['visual', 'encodings']).findKey(value => value && value.column.alias === encoding.column.alias);
  return report.updateIn(['visual', 'encodings'], encodings => replaceEncodingInChannel({
    encodings,
    channelName,
    encoding,
    toIndex
  }));
});
const isReplacementColumnSameField = (report, {
  alias,
  column
}) => report.getIn(['columns', alias, 'field', 'name']) === column.getIn(['field', 'name']) && report.getIn(['columns', alias, 'field', 'table']) === column.getIn(['field', 'table']);
const getReplacementColumnWithSorting = (report, {
  alias,
  column,
  deps,
  isReplace
}) => {
  if (isReplace) {
    if (isReplacementColumnSameField(report, {
      alias,
      column
    })) {
      const previouslyAppliedSort = report.getIn(['columns', alias, 'sort']);
      return column.set('sort', previouslyAppliedSort);
    } else {
      const {
        preferred: preferredSort
      } = getValidColumnSorts(report, column.delete('sort'), deps.meta);
      return column.set('sort', preferredSort);
    }
  }
  return column;
};
const replaceEncodingWithDefaultOptions = (report, {
  alias,
  column,
  oldReport
}) => {
  if (isReplacementColumnSameField(oldReport, {
    alias,
    column
  })) {
    return report;
  }
  const encoding = getEncodingList(report.visual.encodings).find(e => e.column === alias);
  if (!encoding) {
    return report;
  }
  const updatedEncoding = encoding.set('options', defaultEncodingOptions);
  const channelName = report.getIn(['visual', 'type']) === VisualTypes.TABLE ? 'columns' : report.getIn(['visual', 'encodings']).findKey(value => value && (List.isList(value) ? !!value.find(multiEncoding => multiEncoding.column === alias) : value.column === alias));
  const previousEncoding = getChannelEncoding(channelName, oldReport.getIn(['visual', 'encodings']));
  const toIndex = List.isList(previousEncoding) ? previousEncoding.findIndex(e => e.column === alias) : -1;
  return report.updateIn(['visual', 'encodings'], encodings => replaceEncodingInChannel({
    encodings,
    channelName,
    encoding: updatedEncoding,
    toIndex
  }));
};
export const updateEncodedColumn = withModifiers((report, {
  alias,
  column,
  deps,
  isReplace
}) => {
  const sortedColumn = getReplacementColumnWithSorting(report, {
    alias,
    column,
    deps,
    isReplace
  });
  const result = report.update('columns', columns => columns.set(alias, sortedColumn)).update(nextReport => {
    if (nextReport.visual.type === VisualTypes.TABLE && !nextReport.sorts.isEmpty()) {
      const activeSort = nextReport.sorts.get(0);
      if (activeSort.column === alias && column.role === COLUMN_ROLES.MEASURE && activeSort.sortType === SortTypes.DISPLAY_ORDER) {
        return updateSorts(nextReport, {
          sorts: List([activeSort.set('sortType', SortTypes.VALUE)]),
          deps
        });
      }
    }
    return nextReport;
  });
  return replaceEncodingWithDefaultOptions(result, {
    alias,
    column,
    oldReport: report
  });
});
export const updateStagedColumn = (report, {
  alias,
  column
}) => report.update('stagedColumns', stagedColumns => stagedColumns.map(stagedColumn => stagedColumn.alias === alias ? column : stagedColumn));
export const updateStagedEncoding = (report, {
  encoding,
  index
}) => report.update('stagedEncodings', stagedEncodings => stagedEncodings.set(index, encoding));
export const updateEventDateInterval = (report, {
  eventDateInterval
}) => report.set('eventDateInterval', eventDateInterval);
export const updateFiltering = withModifiers((report, {
  filtering
}) => report.set('filtering', filtering));
const stageEncodingsAndColumns = (previousReport, nextReport) => {
  const previousColumns = previousReport.columns;
  const previousEncodings = getEncodingList(previousReport.visual.encodings);
  const nextEncodedColumns = getVisualEncodedColumnIds(nextReport.visual).map(alias => nextReport.columns.get(alias));
  const isSameColumn = (columnA, columnB) => columnA.alias === columnB.alias;
  const decodedColumns = previousColumns.filterNot(column => nextEncodedColumns.some(encodedColumn => isSameColumn(column, encodedColumn)) || nextReport.stagedColumns.some(stagedColumn => isSameColumn(column, stagedColumn))).toList();
  return nextReport.update('stagedEncodings', stagedEncodings => {
    const decodedEncodings = previousEncodings.filterNot(encoding => {
      return nextEncodedColumns.some(column => {
        return column.alias === encoding.column;
      });
    });
    return stagedEncodings.concat(decodedEncodings);
  }).update('stagedColumns', stagedColumns => {
    return stagedColumns.concat(decodedColumns);
  });
};
export const updateVisualTypeWithMagicWand = withModifiers((report, {
  type,
  deps
}) => {
  const {
    userInfo
  } = deps;
  const previousReport = removeUnencodedColumns(report, type);
  const columns = previousReport.columns;
  const magicWandIntermediateVisual = transformVisualToNewType(report.visual, VisualTypes.TABLE, columns, userInfo);
  const transformedVisual = transformVisualToNewType(magicWandIntermediateVisual, type, columns, userInfo);
  return stageEncodingsAndColumns(previousReport, report.set('visual', transformedVisual));
});
export const updateVisualType = withModifiers((report, {
  type,
  deps
}) => {
  const {
    userInfo
  } = deps;
  const previousReport = removeUnencodedColumns(report, type);
  const columns = previousReport.columns;
  const transformedVisual = transformVisualToNewType(report.visual, type, columns, userInfo);
  return stageEncodingsAndColumns(previousReport, report.set('visual', transformedVisual)).update('dateComparisonFilter', dateComparisonFilter => dateComparisonAllowedForVisualType(type) ? dateComparisonFilter : undefined);
});
export const updateVisualOptions = withModifiers((report, {
  options
}) => report.setIn(['visual', 'options'], options));
export const updateVisualShowDataLabels = (report, {
  showDataLabels
}) => report.setIn(['visual', 'options', 'showDataLabels'], showDataLabels);
export const updateVisualShowRecordIds = (report, {
  showRecordIds
}) => report.setIn(['visual', 'options', 'showRecordIds'], showRecordIds);
export const updateVisualShowMarkers = (report, {
  showMarkers
}) => report.setIn(['visual', 'options', 'showMarkers'], showMarkers);
export const updateVisualStacking = (report, {
  stacking
}) => {
  if (stacking === 'percent') {
    return report.withMutations(mutatedReport => mutatedReport.setIn(['visual', 'options', 'stacking'], true).setIn(['visual', 'options', 'stackingType'], 'percent'));
  } else {
    return report.withMutations(mutatedReport => mutatedReport.setIn(['visual', 'options', 'stacking'], stacking).setIn(['visual', 'options', 'stackingType'], undefined));
  }
};
export const addDateComparisonFilter = withModifiers((report, {
  dateComparisonFilter,
  deps
}) => {
  const {
    meta
  } = deps;
  if (!dateComparisonFilter || !dateComparisonAllowedForVisualType(report.visual.type)) {
    return report;
  }
  const field = dateComparisonFilter.getIn(['filters', 0, 'field']);
  return report.set('dateComparisonFilter', dateComparisonFilter).update('filtering', filtering => removeFiltersWithField(filtering, field)).update(nextReport => setInitialDateComparisonEncodings(nextReport, meta));
});
export const removeDateComparisonFilter = withModifiers(report => report.remove('dateComparisonFilter'));
export const removeExpressionField = withModifiers((report, {
  alias
}) => {
  const expressionField = expressionFieldsListToMap(report.expressions).get(alias);
  const isRemovedExpressionField = field => doesFieldReferenceExpression(field, expressionField);
  const getColumnsToDecode = columns => columns.filter(column => isRemovedExpressionField(column.field));
  return report.update('expressions', expressions => expressions.filter(field => field.alias !== alias)).update('filtering', filtering => removeFiltersWithField(filtering, buildFieldFromExpressionField(expressionField))).update(nextReport => getColumnsToDecode(nextReport.stagedColumns).reduce((reducedReport, column) => reducedReport.update('stagedColumns', stagedColumns => stagedColumns.filter(stagedColumn => column.alias !== stagedColumn.alias)).update('stagedEncodings', stagedEncodings => stagedEncodings.filter(encoding => encoding.column !== column.alias)), nextReport)).update(nextReport => getColumnsToDecode(nextReport.columns.toList()).reduce((reducedReport, column) => reducedReport.update('columns', columns => columns.delete(column.alias)).update('visual', visual => decodeColumn(visual, column)), nextReport));
});
const addExpressionFieldHelper = (report, {
  expressionField
}) => {
  return report.update('expressions', expressions => {
    const alreadyAdded = expressions.find(expression => expression.alias === expressionField.alias);
    if (alreadyAdded) {
      return expressions;
    }
    return expressions.push(expressionField);
  });
};
export const addExpressionTemplateToChannel = withModifiers((report, {
  expressionField,
  channelName,
  index,
  deps
}) => {
  const next = addExpressionFieldHelper(report, {
    expressionField
  });
  const field = buildFieldFromExpressionField(expressionField);
  return addExpressionFieldToChannelHelper(next, {
    field,
    channelName,
    toIndex: index,
    hasValidCurrencyProperty: false,
    deps
  });
});
export const addExpressionTemplateToStage = withModifiers((report, {
  expressionField
}) => {
  const next = addExpressionFieldHelper(report, {
    expressionField
  });
  const field = buildFieldFromExpressionField(expressionField);
  return addFieldToStage(next, {
    id: getFieldKey(field),
    field,
    snowflakeProperty: undefined
  });
});
export const addExpressionField = withModifiers(addExpressionFieldHelper);
export const updateExpressionField = withModifiers((report, {
  expressionField,
  deps
}) => {
  const {
    meta
  } = deps;
  const idx = report.expressions.findIndex(field => field.alias === expressionField.alias);
  if (idx === -1) {
    return report;
  }
  const isLabelChange = report.expressions.get(idx).label !== expressionField.label;
  const {
    field: nextField
  } = checkExpressionField(expressionField, report.expressions, meta);
  return report.update('expressions', expressions => {
    if (isLabelChange) {
      const originalLabel = expressions.get(idx).label;
      const nextExpressionFields = expressions.set(idx, nextField);
      const nextExpressionFieldsMap = expressionFieldsListToMap(nextExpressionFields);
      return nextExpressionFields.map(({
        alias
      }) => {
        const field = nextExpressionFieldsMap.get(alias);
        const nextExpression = toFieldNameExpression(toFieldLabelExpression(field.expression, nextExpressionFieldsMap), nextExpressionFieldsMap);
        const nextInput = textRenameField(field.input, originalLabel, nextField.label);
        return field.set('expression', nextExpression).set('input', nextInput);
      });
    }
    return expressions.set(idx, nextField);
  }).update(r => removeAggregateFilters(r));
});