import * as PropertyTypes from 'customer-data-objects/property/PropertyTypes';
export const AssociationDisplayType = 'ASSOCIATION';
export const BooleanDisplayType = PropertyTypes.BOOLEAN;
export const CountDisplayType = 'COUNT';
export const CtaDisplayType = 'CTA';
export const DateDisplayType = PropertyTypes.DATE;
export const DatetimeDisplayType = PropertyTypes.DATE_TIME;
export const DefaultDisplayType = 'DEFAULT';
export const EmailLinkDisplayType = 'EMAIL_LINK';
export const EmailSubscriptionDisplayType = 'EMAIL_SUBSCRIPTION';
export const EnumerationDisplayType = PropertyTypes.ENUMERATION;
export const NoDisplayType = 'NONE';
export const NumberDisplayType = PropertyTypes.NUMBER;
export const PercentageDisplayType = 'percent';
export const PropertyDisplayType = 'PROPERTY';
export const RollingDateDisplayType = 'ROLLING_DATE';
export const RollingInequalityDisplayType = 'ROLLING_INEQUALITY';
export const RollingPropertyUpdatedDisplayType = 'ROLLING_PROPERTY_UPDATED';
export const RollingWithUnitDisplayType = 'ROLLING_WITH_UNIT';
export const StringDisplayType = PropertyTypes.STRING;
export const RegexString = 'REGEX_STRING';
export const MomentDate = 'MOMENT_DATE';