'use es6';

import { fromJS, List, Map as ImmutableMap, OrderedMap } from 'immutable';
import I18n from 'I18n';
import chunk from '../../lib/async/chunk';
import { Promise } from '../../lib/promise';
import * as http from '../../request/http';
import { makeOption } from '../Option';
import { DEFAULT_NULL_VALUES, GLOBAL_NULL } from '../../constants/defaultNullValues';
import { DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES } from '../../constants/objectCoordinates';
import { COMPANIES } from '../../constants/dataTypes';
const getCompanies = (companies = List()) => chunk(group => http.post('inbounddb-objects/v1/preview', {
  data: {
    [DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(COMPANIES)]: group.toArray()
  }
}).then(fromJS), responses => responses.reduce((references, response) => {
  const chunkReferences = response.getIn([DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(COMPANIES)]);
  return references.concat(chunkReferences);
}, OrderedMap({})), companies);
export const generateCompanyLabel = (companyInfo, key) => companyInfo.get('label', key === GLOBAL_NULL ? null : I18n.text('reporting-data.references.company.unknown', {
  id: key
}));
export default (ids => {
  if (ids.isEmpty()) {
    return Promise.resolve(ImmutableMap());
  }
  const sanitized = ids.reduce((memo, id) => {
    const parsed = Number(id);
    return parsed ? memo.set(String(parsed), id) : memo;
  }, ImmutableMap());
  return getCompanies(sanitized.keySeq().toList()).then(companies => {
    return fromJS(companies).reduce((options, companyInfo = ImmutableMap(), companyId) => {
      return options.set(String(companyId), makeOption(sanitized.get(companyId, companyId), companyInfo.getIn(['properties', 'name', 'value'], null)));
    }, ids.includes(String(DEFAULT_NULL_VALUES.NUMBER)) ? ImmutableMap({
      [DEFAULT_NULL_VALUES.NUMBER]: makeOption(DEFAULT_NULL_VALUES.NUMBER, I18n.text('reporting-data.missing.value'))
    }) : ImmutableMap());
  });
});