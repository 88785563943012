'use es6';

const getNormalizedLinkData = (data, {
  showNoFollow = false,
  showTarget = false
} = {}) => {
  // if data.target === "_self" (or other self referencing values) this will be false even with a true showTarget value
  const targetFallbackValue = typeof data.target !== 'undefined' && data.target !== null ? data.target === '_blank' : showTarget;
  return Object.assign({}, data, {
    isTargetBlank: typeof data.isTargetBlank !== 'undefined' ? data.isTargetBlank : targetFallbackValue,
    isNoFollow: typeof data.isNoFollow !== 'undefined' ? data.isNoFollow : data.rel === 'nofollow' || showNoFollow
  });
};
export default getNormalizedLinkData;