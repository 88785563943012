'use es6';

import { VisualTypes } from '../../../schema/visual-records';
import { getReportVisualType, getReportXEncoding, getReportY1Encoding, getReportY2Encoding, getReportBreakdownEncoding } from '../../../utils/report-utils';
import { combineConstraints, ConstraintTypes, createConstraint, InvalidSpecificationTypes } from '../../validate';
import { checkIfSortIsAccumulateFriendly } from './check-sort-is-accumulate-friendly';
import { getEncodingColumnAlias } from '../../../utils/visual-utils';
import { isDateLikeField } from '../../../utils/column-utils';
import { checkAllDateDimensionsHaveTransform } from './check-all-date-dimensions-have-transformation';
const getXColumnId = report => {
  const x = getReportXEncoding(report);
  return x && getEncodingColumnAlias(x);
};
const sortIsAccumulateFriendly = createConstraint('sortIsAccumulateFriendly', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (y1Accumulate, y2Accumulate, sorts, xId) => {
  if (!y1Accumulate && !y2Accumulate) {
    return true;
  }
  return checkIfSortIsAccumulateFriendly(sorts, xId);
}, [report => report.visual.options.y1Accumulate, report => report.visual.options.y2Accumulate, report => report.sorts, getXColumnId]);
const xAxisIsAccumulateFriendly = createConstraint('xAxisIsAccumulateFriendly', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (y1Accumulate, y2Accumulate, maybeXEncoding) => {
  if (!y1Accumulate && !y2Accumulate) {
    return true;
  }
  const maybeColumn = maybeXEncoding && maybeXEncoding.column;
  return maybeColumn === undefined ? true : isDateLikeField(maybeColumn.field);
}, [report => report.visual.options.y1Accumulate, report => report.visual.options.y2Accumulate, report => getReportXEncoding(report)]);
const comboVisualHasCompleteYLayer = createConstraint('completeYLayer', ConstraintTypes.HARD, InvalidSpecificationTypes.INCOMPLETE, (y1, y2, breakdown) => {
  return !(y2 && breakdown && !y1);
}, [getReportY1Encoding, getReportY2Encoding, getReportBreakdownEncoding]);
const comboVisualIsComplete = createConstraint('completionCriteria', ConstraintTypes.HARD, InvalidSpecificationTypes.INCOMPLETE, (x, y1, y2) => {
  return x || y1 || y2;
}, [getReportXEncoding, getReportY1Encoding, getReportY2Encoding]);
const dateDimensionsHaveTransformation = createConstraint('dateDimensionsHaveTransformation', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, checkAllDateDimensionsHaveTransform, [report => report.columns]);
export const combo = combineConstraints('combo', [comboVisualIsComplete, comboVisualHasCompleteYLayer, sortIsAccumulateFriendly, xAxisIsAccumulateFriendly, dateDimensionsHaveTransformation], (constraint, report) => getReportVisualType(report) !== VisualTypes.COMBO || constraint.validate(report));