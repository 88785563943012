import { useMemo } from 'react';
import { usePlatformDashboardSelector } from 'platform-dashboard-ui/store';
import { selectDataSourcesMetadataWithUniqueLabels } from 'platform-dashboard-ui/ducks/dataSourceMetadataSlice';
// @ts-expect-error migrate upstream dependency
import { getLabel as getDataSourceLabel } from '../../../../../../lib/dashboardFilters/dataSourceMetadataSchema';
import { groupFiltersByDataSource } from '../../../../../../ducks/applied-dashboard-filters/utils';
export const useSortAndGroupFilters = filters => {
  const groupedFiltersByDataSource = groupFiltersByDataSource(filters);
  const dataSourceKeys = Object.keys(groupedFiltersByDataSource);
  const dataSourcesMetadata = usePlatformDashboardSelector(selectDataSourcesMetadataWithUniqueLabels(dataSourceKeys)); // TODO: update this type to match platform

  const sortedGroupedFilters = useMemo(() => {
    if (!dataSourcesMetadata) {
      return groupedFiltersByDataSource;
    }
    if (dataSourcesMetadata && Object.keys(dataSourcesMetadata).length === 0) {
      return groupedFiltersByDataSource;
    }
    return dataSourceKeys.sort((dataSourceKeyA, dataSourceKeyB) => {
      const dataSourceALabel = getDataSourceLabel(dataSourcesMetadata[dataSourceKeyA]);
      const dataSourceBLabel = getDataSourceLabel(dataSourcesMetadata[dataSourceKeyB]);
      if (!dataSourceALabel) {
        return 0;
      }
      return dataSourceALabel.localeCompare(dataSourceBLabel);
    }).reduce((memoAppliedFiltersGroupedByDataSourceAlphabetized, dataSource) => {
      return Object.assign({}, memoAppliedFiltersGroupedByDataSourceAlphabetized, {
        [dataSource]: groupedFiltersByDataSource[dataSource]
      });
    }, {});
  }, [groupedFiltersByDataSource, dataSourcesMetadata, dataSourceKeys]);
  return sortedGroupedFilters;
};