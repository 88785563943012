import { AREA, BAR, COLUMN, LINE } from 'reporting-data/constants/chartTypes';
import { ACCUMULATE } from 'reporting-data/constants/processorTypes';
import { getReportProcessors, isTimeSeriesReport } from 'reporting-data/report/configReportGetters';
import { setProcessorType } from 'reporting-data/report/configReportSetters';
import { getReportChartType } from 'reporting-data/report/reportGetters';
import { isConfigReport } from 'reporting-data/tsTypes/reportTypes';
export const getIsReportValidForCumulativeCheckbox = ({
  report
}) => {
  return isConfigReport(report) && [LINE, AREA, BAR, COLUMN].includes(getReportChartType(report) || '') && isTimeSeriesReport(report);
};
export const getIsCumulativeConfigReport = report => {
  return getReportProcessors(report).includes(ACCUMULATE);
};
export const cumulativeSetterForConfigReportCheckbox = report => {
  return setProcessorType(report, ACCUMULATE);
};