'use es6';

export const DEFAULT_EXPORT_TYPE = {
  NONE: 'NONE'
};
// delete after merge- leaving this since it will be a breaking change for the dashboard
export const EXPORT_TYPES = Object.assign({}, DEFAULT_EXPORT_TYPE, {
  PDF: 'PDF',
  PPTX: 'PPTX',
  ZIP: 'ZIP'
});
export const IMAGE_EXPORT_TYPES = {
  PDF: 'PDF',
  PPTX: 'PPTX',
  ZIP: 'ZIP'
};
export const DATA_EXPORT_TYPES = {
  CSV: 'CSV',
  XLS: 'XLS',
  XLSX: 'XLSX'
};
export const EXPORT_TYPES_FOR_DASHBOARD = Object.assign({}, DEFAULT_EXPORT_TYPE, IMAGE_EXPORT_TYPES, DATA_EXPORT_TYPES);
export const EXPORT_TYPES_FOR_REPORT = Object.keys(Object.assign({}, DEFAULT_EXPORT_TYPE, DATA_EXPORT_TYPES));
export const isUnsupportedDataExportType = exportType => Object.keys(DATA_EXPORT_TYPES).includes(exportType);