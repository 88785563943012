'use es6';

import Raven from 'raven-js';
import get from 'transmute/get';
import isEmpty from 'transmute/isEmpty';
import isNull from 'transmute/isNull';
import { getEventById, hasEventById } from './sentry-events';
import SentryContext from './sentry-context';
const getErrorReason = (eventId, sentryContext) => {
  const event = getEventById(eventId);
  return event.get('name') || sentryContext.get('error') || eventId;
};
const createTags = captureData => {
  const {
    id,
    name,
    description,
    owner,
    tags
  } = captureData;
  return Object.assign({
    'sales-analytics': id,
    name,
    description,
    owner
  }, tags);
};
const createMetaData = (eventId, context = SentryContext()) => {
  const event = getEventById(eventId);
  const totalMetaData = Object.assign({
    eventId
  }, context.toJS(), event.toJS());
  const level = get('name', totalMetaData);
  const tags = createTags(totalMetaData);
  const extra = get('extra', totalMetaData);
  const fingerprint = get('fingerprint', totalMetaData);
  return Object.assign({
    level
  }, isEmpty(extra) ? {} : {
    extra
  }, {
    tags
  }, isNull(fingerprint) ? {} : {
    fingerprint
  });
};
export const capture = (eventId, eventContext) => {
  if (!hasEventById(eventId)) {
    console.warn(`Sentry: attempted to capture untracked event (id: ${eventId}). Capture failed; nothing is sent.`);
    return;
  }
  const sentryContext = SentryContext(eventContext);
  const errorReason = getErrorReason(eventId, sentryContext);
  const metaInfo = createMetaData(eventId, sentryContext);
  Raven.captureException(errorReason, metaInfo);
};
export const WrapWithRaven = (funcName, wrappedFn) => Raven.wrap({
  extra: {
    funcName
  }
}, wrappedFn);