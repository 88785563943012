/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

'use es6';

import { ENTIRE_TO_PARTIAL, getRangeType } from '../range';
import { getRangeOptionsForReport, isValidRangeForReport } from '../dateRangeHelpers';
import { getDateRange } from '../salesAnalyticsHelpers';
export function validateDateRange(contextChange, context, report, __subAppKey, defaultContext, __scopes, gates) {
  const dateRangeToTest = contextChange.dateRange || context.dateRange;
  const range = getRangeType(dateRangeToTest.toJS());
  const grouping = contextChange.grouping != null ? contextChange.grouping : context.grouping;
  const intraperiod = contextChange.intraperiod != null ? contextChange.intraperiod : context.intraperiod;
  if (!isValidRangeForReport(range, report, grouping, intraperiod, gates)) {
    const partialRange = ENTIRE_TO_PARTIAL.get(range);
    if (partialRange && isValidRangeForReport(partialRange, report, grouping, intraperiod, gates)) {
      return Object.assign({}, contextChange, {
        dateRange: dateRangeToTest.set('entireCurrentUnit', false)
      });
    }
    const defaultDateRange = defaultContext.dateRange;
    const defaultRange = getRangeType(defaultDateRange);
    if (isValidRangeForReport(defaultRange, report, grouping, intraperiod, gates)) {
      return Object.assign({}, contextChange, {
        dateRange: defaultDateRange
      });
    }
    const validRanges = getRangeOptionsForReport(report, grouping, intraperiod, gates);
    return Object.assign({}, contextChange, {
      dateRange: getDateRange(validRanges.get(0))
    });
  } else {
    return contextChange;
  }
}