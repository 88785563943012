'use es6';

import { getAndCheck } from '../common/get-and-check-param';
import { createProblem } from '../common/problem';
import { REPORT_IS_PAYMENTS, HAS_PAYMENTS_REPORTING_ACCESS } from '../params/all-params';

// Portals require the commerce-payments-read scope to be able to view
// the gross payments dashboard snowflake template reports.
export const LACKS_PAYMENTS_REPORT_ACCESS = createProblem('LACKS_PAYMENTS_REPORT_ACCESS', params => {
  return getAndCheck(REPORT_IS_PAYMENTS, params) && !getAndCheck(HAS_PAYMENTS_REPORTING_ACCESS, params);
});