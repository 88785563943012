import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestState } from '../filter/requests/request-state';
import { selectDataSourcesInfo, selectDataSourcesInfoStatus } from '../filter/utils/dataSourceInfoLogic';
import { addToRecentlyUsedProperties, getRecentlyUsedProperties, isDuplicateProperty } from '../filter/utils/recentlyUsedProperties';
import { addDashboardFilter } from './dashboardFiltersSlice';
export const fetchDataSourcesRecentlyUsedPropertiesAsyncThunk = createAsyncThunk('dataSourcesRecentlyUsedProperties/fetch', async dataSource => {
  try {
    const recentlyUsedProperties = await getRecentlyUsedProperties({
      dataSource
    });
    return recentlyUsedProperties;
  } catch (error) {
    return error;
  }
});
export const dataSourceRecentlyUsedPropertiesSlice = createSlice({
  name: 'dataSourceTopProperties',
  initialState: {},
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDataSourcesRecentlyUsedPropertiesAsyncThunk.pending, (state, action) => {
      const {
        arg: dataSource
      } = action.meta;
      state[dataSource] = {
        status: RequestState.PENDING
      };
    });
    builder.addCase(fetchDataSourcesRecentlyUsedPropertiesAsyncThunk.fulfilled, (state, action) => {
      const {
        arg: dataSource
      } = action.meta;
      const recentlyUsedProperties = action.payload;
      state[dataSource] = {
        status: RequestState.SUCCEEDED,
        recentlyUsedProperties
      };
    });
    builder.addCase(fetchDataSourcesRecentlyUsedPropertiesAsyncThunk.rejected, (state, action) => {
      const {
        arg: dataSource
      } = action.meta;
      state[dataSource] = {
        status: RequestState.FAILED
      };
    });
    builder.addCase(addDashboardFilter, (state, action) => {
      const {
        dataSource,
        property
      } = action.payload;
      if (dataSource && property) {
        const recentlyUsedProperties = state[dataSource] ? state[dataSource]['recentlyUsedProperties'] || [] : [];
        const propertyName = property.name;
        if (!isDuplicateProperty(recentlyUsedProperties, propertyName)) {
          state[dataSource] = {
            status: RequestState.SUCCEEDED,
            recentlyUsedProperties: addToRecentlyUsedProperties(recentlyUsedProperties, propertyName)
          };
        }
      }
    });
  }
});

// Actions
export const fetchDataSourcesRecentlyUsedProperties = dataSources => (dispatch, getState) => {
  const state = getState().dataSourceRecentlyUsedProperties;
  for (const dataSource of dataSources) {
    if (!state[dataSource]) {
      dispatch(fetchDataSourcesRecentlyUsedPropertiesAsyncThunk(dataSource));
    }
  }
};

// Selectors
export const selectDataSourcesRecentlyUsedProperties = dataSources => state => selectDataSourcesInfo({
  dataSources,
  valueName: 'recentlyUsedProperties'
})(state.dataSourceRecentlyUsedProperties);
export const selectDataSourcesRecentlyUsedPropertiesStatus = dataSources => state => selectDataSourcesInfoStatus({
  dataSources
})(state.dataSourceRecentlyUsedProperties);
export default dataSourceRecentlyUsedPropertiesSlice.reducer;