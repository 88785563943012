'use es6';

import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as OperatorTypes from '../ObjectSegOperatorTypes';
import * as Operators from '../../../filterQueryFormat/operator/Operators';
import getIn from 'transmute/getIn';
import invariant from 'react-utils/invariant';
import protocol from 'transmute/protocol';
import toRefinementFilter from './toRefinementFilter';
const _toPrivacyConsentFilter = (operatorName, operator) => {
  const filter = {
    filterType: ObjectSegFilterTypes.PRIVACY_CONSENT,
    operator: operatorName,
    privacyName: getIn(['value'], operator)
  };
  const refinement = operator.get('refinement');
  if (refinement) {
    return Object.assign({}, filter, toRefinementFilter(refinement));
  }
  return filter;
};
const invalidOperator = operator => {
  invariant(false, 'cannot convert Operator "%s" to privacy consent ObjectSeg filter', operator.name);
};
const toPrivacyConsentFilter = protocol({
  name: 'toPrivacyConsentFilter',
  args: [protocol.TYPE],
  fallback: invalidOperator
});
toPrivacyConsentFilter.implement(Operators.PrivacyConsentCompleted, operator => _toPrivacyConsentFilter(OperatorTypes.PRIVACY_CONSENT_GRANTED, operator));
toPrivacyConsentFilter.implement(Operators.PrivacyConsentNotCompleted, operator => _toPrivacyConsentFilter(OperatorTypes.PRIVACY_CONSENT_NOT_GRANTED, operator));
export default toPrivacyConsentFilter;