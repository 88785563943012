import { isConfigReport, isFunnelReport } from 'reporting-data/tsTypes/reportTypes';
import { AREA, BAR, COLUMN, DATA_WELL, LINE } from 'reporting-data/constants/chartTypes';
import { getReportChartType } from 'reporting-data/report/reportGetters';
import { getConfigType, getDimensions, getFilters, getReportConfig, isTimeSeriesReport } from 'reporting-data/report/configReportGetters';
import { TIME_SERIES } from 'reporting-data/constants/configTypes';
export const getIsMismatchedDimensionDateFilterPropertyTimeSeries = report => {
  const dimensions = getDimensions(report);
  return getConfigType(report) === TIME_SERIES && (dimensions === null || dimensions === void 0 ? void 0 : dimensions.size) === 1 && dimensions.get(0) !== getFilters(getReportConfig(report)).getIn(['dateRange', 'property']);
};
export const getIsReportValidForComparisonDropdown = ({
  report
}) => {
  if (isConfigReport(report)) {
    const chartType = getReportChartType(report) || '';
    const dimensions = getDimensions(report);
    const isMismatchedDimensionDateFilterPropertyTimeSeries = getIsMismatchedDimensionDateFilterPropertyTimeSeries(report);
    const isChartTypeValid = [LINE, AREA].includes(chartType) && isTimeSeriesReport(report) && (dimensions === null || dimensions === void 0 ? void 0 : dimensions.size) < 2 || [BAR, COLUMN].includes(chartType) && (dimensions === null || dimensions === void 0 ? void 0 : dimensions.size) < 2 || chartType === DATA_WELL;
    return isChartTypeValid && !isFunnelReport(report) && !isMismatchedDimensionDateFilterPropertyTimeSeries;
  }
  return false;
};