'use es6';

import { fromJS, List, Map as ImmutableMap } from 'immutable';
import { CONVERSATIONS } from '../../constants/dataTypes';
import engagementModule from '../../dataTypeDefinitions/inboundDb/engagement';
import prefix from '../../lib/prefix';
import { Promise } from '../../lib/promise';
import overridePropertyTypes from '../../retrieve/inboundDb/common/overridePropertyTypes';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import getCountProperty from '../partial/count-property';
import getCommonPropertyGroups from '../partial/engagement-common';
const translate = prefix('reporting-data.properties.conversations');
const translateGroup = prefix('reporting-data.groups.conversations');
const withNamespace = (namespace, properties) => fromJS(properties.map(property => Object.assign({}, property, {
  name: `${namespace}.${property.name}`
})));
export const getConversationPropertyGroups = () => Promise.resolve(List.of(ImmutableMap({
  name: 'conversationInfo',
  displayName: translateGroup('conversationInfo'),
  displayOrder: 0,
  hubspotDefined: true,
  properties: withNamespace('conversationSession', [{
    name: 'visitorStartTime',
    label: translate('visitorStartTime'),
    type: 'datetime'
  }, {
    name: 'visitorEndTime',
    label: translate('visitorEndTime'),
    type: 'datetime'
  }, {
    name: 'visitorWaitTimeMilliseconds',
    label: translate('visitorWaitTimeMilliseconds'),
    type: 'duration'
  }, {
    name: 'sessionDurationMilliseconds',
    label: translate('sessionDurationMilliseconds'),
    type: 'duration'
  }, {
    name: 'agentJoinTime',
    label: translate('agentJoinTime'),
    type: 'datetime'
  }, {
    name: 'numVisitorMessages',
    label: translate('numVisitorMessages'),
    type: 'number'
  }, {
    name: 'numAgentMessages',
    label: translate('numAgentMessages'),
    type: 'number'
  }, {
    name: 'online',
    label: translate('online'),
    type: 'bool',
    hidden: true
  }, {
    name: 'isBot',
    label: translate('isBot'),
    type: 'bool'
  }, {
    name: 'fullUrl',
    label: translate('fullUrl'),
    type: 'string'
  }, {
    name: 'conversationSource',
    label: translate('conversationSource'),
    type: 'enumeration',
    options: [{
      value: 'LIVE_CHAT',
      label: translate('conversationSources.liveChat')
    }, {
      value: 'FB_MESSENGER',
      label: translate('conversationSources.facebookMessenger')
    }]
  }, {
    name: 'threadId',
    label: translate('threadId'),
    type: 'number',
    numberDisplayHint: 'unformatted',
    hidden: true
  }, {
    name: 'sessionClosedAt',
    label: translate('sessionClosedAt'),
    type: 'datetime'
  }, {
    name: 'agentResponseTimeMilliseconds',
    label: translate('agentResponseTimeMilliseconds'),
    type: 'duration'
  }])
})));
export const getPropertyGroups = () => Promise.all([getCommonPropertyGroups(), getConversationPropertyGroups()]).then(([commonPropertyGroups, conversationPropertyGroups]) => List([...commonPropertyGroups, ...conversationPropertyGroups]));
export const getProperties = () => createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(getCountProperty(CONVERSATIONS)))().then(overridePropertyTypes(engagementModule.getInboundSpec()));