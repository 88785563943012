import { createAsyncThunk } from '@reduxjs/toolkit';
export const createQuickFilterGroupAsyncThunk = createAsyncThunk('quickFilters/createGroup', async ({
  dashboardId,
  quickFilterGroup
}) => {
  // TODO: remove this when we need to persist quick filter on the go.
  return await Promise.resolve({
    dashboardId,
    quickFilterGroup
  });
});
export const updateQuickFilterGroupAsyncThunk = createAsyncThunk('quickFilters/updateGroup', async ({
  dashboardId,
  quickFilterGroupIdx,
  quickFilterGroup
}) => {
  // TODO: remove this when we need to persist quick filter on the go.
  return await Promise.resolve({
    dashboardId,
    quickFilterGroupIdx,
    quickFilterGroup
  });
});
export const removeQuickFilterGroupAsyncThunk = createAsyncThunk('quickFilters/removeGroup', async ({
  dashboardId,
  quickFilterGroupIdx
}) => {
  // TODO: remove this when we need to persist quick filter on the go.
  return await Promise.resolve({
    dashboardId,
    quickFilterGroupIdx
  });
});
export const removeAllQuickFilterGroupsAsyncThunk = createAsyncThunk('quickFilters/removeAllGroups', async ({
  dashboardId
}) => {
  // TODO: remove this when we need to persist quick filter on the go.
  return await Promise.resolve(dashboardId);
});