import { trackFilterInteraction
// @ts-expect-error migrate upstream
} from 'dashboard-ui-components/tracking/DashboardUITracker';

// @ts-expect-error migrate upstream
import { useUserRoleForDashboard } from 'reporting-action-components/utils/trackingUtils';
import { useSavedDashboardFiltersPanelContext } from '../context';
export const useDeleteDraftFilter = () => {
  const {
    draftFilterSetFilters,
    onDraftFilterSetFiltersChanged,
    dashboard,
    dashboardId
  } = useSavedDashboardFiltersPanelContext();
  const userRole = useUserRoleForDashboard(dashboard);
  const handleDeleteFilter = filterIdxToDelete => {
    const filterToDelete = draftFilterSetFilters[filterIdxToDelete];
    onDraftFilterSetFiltersChanged(draftFilterSetFilters.filter((_, idx) => idx !== filterIdxToDelete));
    if (filterToDelete && filterToDelete.operator) {
      const {
        operator,
        dataSource,
        propertyName,
        propertyType
      } = filterToDelete;
      trackFilterInteraction({
        action: 'delete-filter',
        filterData: {
          dataSource,
          operator,
          property: {
            type: propertyType,
            name: propertyName
          }
        },
        userRole,
        additionalProps: {
          interactionSource: 'edit-filter-set',
          dashboardId
        }
      });
    }
  };
  return {
    onDeleteFilter: handleDeleteFilter
  };
};