'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import I18n from 'I18n';
import Request from '../../request/Request';
import * as http from '../../request/http';
import { Promise } from '../../lib/promise';
import { makeOption } from '../Option';
import { validNumerical } from '../ids';
const URL = '/cosemail/v1/emails/by-email-campaign-id/minimal/multi';
const EMAIL_CONTENT_URL = 'cosemail/v1/emails/minimal/multi';
const PREVIEW_ID = '2';
const initial = () => ImmutableMap({
  [PREVIEW_ID]: makeOption(PREVIEW_ID, I18n.text('reporting-data.references.email.preview'))
});
const getEmails = ids => {
  const emailCampaignIds = validNumerical(ids);
  return emailCampaignIds.isEmpty() ? Promise.resolve(ImmutableMap()) : http.retrieve(Request.get({
    url: URL,
    query: {
      emailCampaignId: validNumerical(ids)
    }
  })).then(emails =>
  // emails.map((email, id) => makeOption(id, email.get('name')))
  emails.reduce((options, email, id) => options.set(id, makeOption(id, email.get('name'))), initial()));
};
export default getEmails;
export const emails = ids => getEmails(List(ids)).then(options => options.reduce((breakdowns, option) => Object.assign({}, breakdowns, {
  [option.get('value')]: option.get('label')
}), {}));
export const getEmailsByContentIds = ids => {
  const emailContentIds = validNumerical(List(ids));
  return emailContentIds.isEmpty() ? Promise.resolve(ImmutableMap()) : http.retrieve(Request.get({
    url: EMAIL_CONTENT_URL,
    query: {
      emailId: validNumerical(ids)
    }
  })).then(emailsByContentId => emailsByContentId.reduce((options, email, id) => options.set(id, makeOption(id, email.get('name'))), initial()).reduce((breakdowns, option) => Object.assign({}, breakdowns, {
    [option.get('value')]: option.get('label')
  }), {}));
};