import { useCallback, useEffect } from 'react';
function useWindowScrollListener({
  listenToScroll,
  onScroll,
  useCapture
}) {
  const handleOnScroll = useCallback(() => {
    requestAnimationFrame(() => onScroll());
  }, [onScroll]);
  useEffect(() => {
    if (!listenToScroll) {
      return () => {
        window.removeEventListener('scroll', handleOnScroll, useCapture);
      };
    }
    window.addEventListener('scroll', handleOnScroll, useCapture);
    return () => {
      window.removeEventListener('scroll', handleOnScroll, useCapture);
    };
  }, [listenToScroll, handleOnScroll, useCapture]);
}
export default useWindowScrollListener;