'use es6';

import * as Colors from './index';

// TODO I18n these labels
const COLOR_SERIES = {
  DEFAULT: {
    label: 'Default',
    type: 'ordered',
    colors: [Colors.ORANGE, Colors.AQUA, Colors.PURPLE, Colors.YELLOW, Colors.RED, Colors.BLUE, Colors.GREEN, Colors.DARK_ORANGE, Colors.DARK_AQUA, Colors.DARK_PURPLE, Colors.DARK_YELLOW, Colors.DARK_PINK, Colors.DARK_BLUE, Colors.DARK_GREEN]
  },
  SEQUENTIAL: {
    label: 'Sequential',
    type: 'gradient',
    colors: Colors.SEQUENTIAL_7
  },
  DIVERGENT: {
    label: 'Divergent',
    type: 'gradient',
    colors: Colors.DIVERGENT_12
  },
  SEMANTIC: {
    label: 'Semantic',
    type: 'gradient',
    colors: Colors.SEMANTIC_1
  },
  ORANGES: {
    label: 'orange',
    type: 'gradient',
    colors: Colors.ORANGES
  },
  AQUAS: {
    label: 'aqua',
    type: 'gradient',
    colors: Colors.AQUAS
  },
  PURPLES: {
    label: 'purple',
    type: 'gradient',
    colors: Colors.PURPLES
  },
  YELLOWS: {
    label: 'yellow',
    type: 'gradient',
    colors: Colors.YELLOWS
  },
  PINKS: {
    label: 'pink',
    type: 'gradient',
    colors: Colors.PINKS
  },
  BLUES: {
    label: 'blue',
    type: 'gradient',
    colors: Colors.BLUES
  },
  GREENS: {
    label: 'green',
    type: 'gradient',
    colors: Colors.GREENS
  }
};
export function getColorSeries(colorSeriesType) {
  if (colorSeriesType) {
    return COLOR_SERIES[colorSeriesType];
  }
  return COLOR_SERIES;
}
export function getColors(series = 'DEFAULT', dataSeries) {
  let colorSeriesType = 'DEFAULT';
  if ({}.hasOwnProperty.call(COLOR_SERIES, series)) {
    colorSeriesType = series;
  }
  const colorSeries = getColorSeries(colorSeriesType);
  let {
    colors
  } = colorSeries;
  if (dataSeries && colorSeries.type === 'gradient') {
    // If the number of series is less than half the number of colors,
    // space out series colors and center them in the color list
    const seriesLength = Array.isArray(dataSeries) ? dataSeries.length : Object.keys(dataSeries).length;
    if (seriesLength <= Math.ceil(colors.length / 2)) {
      const colorMiddle = Math.floor(colors.length / 2);
      const centeringAdjustment = colorMiddle - seriesLength + 1;
      const selectedColors = [];
      for (let i = 0; i < seriesLength; ++i) {
        const colorIndex = i * 2 + centeringAdjustment;
        selectedColors[i] = colors[colorIndex];
      }
      colors = selectedColors;
    }
  }
  return colors;
}