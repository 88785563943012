'use es6';

import { ATTRIBUTION_TOUCH_POINTS, CONTACT_CREATE_ATTRIBUTION } from 'reporting-data/constants/dataTypes';
import { RANGE_TYPES } from 'reporting-data/constants/dateRangeTypes';
import { is, Map as ImmutableMap, List } from 'immutable';
import { stringify } from 'hub-http/helpers/params';
import { CRM_OBJECT } from 'reporting-data/constants/dataTypes/inboundDb';
const COMMA = `,`;
const DEAL_CREATE_ATTRIBUTION = '0-63';
const ATTRIBUTION_BUILDER_TYPES = ImmutableMap([[ATTRIBUTION_TOUCH_POINTS, 'revenue'], [CONTACT_CREATE_ATTRIBUTION, 'contact'], [DEAL_CREATE_ATTRIBUTION, 'deal-create']]);
const getAttributionBuilderPathPrefix = reportDataType => {
  return ATTRIBUTION_BUILDER_TYPES.get(reportDataType);
};
const combineParamArrays = configArray => {
  if (!configArray) {
    return '';
  }
  return configArray.join(COMMA);
};
const getSortParams = sortArray => {
  const sortWithDirectionAsString = sortArray.map(sort => {
    const sortOrder = sort.get('order');
    const sortProperty = sort.get('property');
    if (!sortOrder || !sortProperty) {
      return '';
    }
    const direction = sortOrder === 'DESC' ? '-' : '';
    return direction + sortProperty;
  });
  return combineParamArrays(sortWithDirectionAsString);
};
const getMetricsParams = metricArray => {
  const metricProperties = metricArray.map(metric => metric.get('property'));
  return combineParamArrays(metricProperties);
};
const processCustomFilters = customFilters => {
  const contentFilter = customFilters.filter(filter => filter.get('property') === 'hs_asset_type');
  return contentFilter ? contentFilter.get('values', List()).toJS() : [];
};
const getContentTypeGroup = filters => {
  const customFilters = filters.get('custom');
  if (!customFilters) {
    return '';
  }
  return processCustomFilters(customFilters);
};
const getDateRangeParams = dateRangeFilter => {
  if (!dateRangeFilter || !dateRangeFilter.get('property')) {
    return null;
  }
  const dateRangeValue = dateRangeFilter.get('value');
  const rangeType = dateRangeValue.get('rangeType');
  if (is(rangeType, RANGE_TYPES.ROLLING)) {
    return `${rangeType}_${dateRangeValue.get('rollingDays')}`;
  } else if (is(rangeType, RANGE_TYPES.CUSTOM)) {
    return `${rangeType}_${dateRangeValue.get('startDate')}_${dateRangeValue.get('endDate')}`;
  }
  return rangeType;
};
export const getAttributionBuilderPathEnding = report => {
  const dataType = report.getIn(['config', 'dataType']);
  const objectType = dataType !== CRM_OBJECT ? dataType : report.getIn(['config', 'objectTypeId']);
  if (!ATTRIBUTION_BUILDER_TYPES.has(objectType)) {
    return false;
  }
  const chartType = report.getIn('chartType');
  const configType = report.getIn(['config', 'configType']);
  const frequency = report.getIn(['config', 'frequency']);
  const dimensions = combineParamArrays(report.getIn(['config', 'dimensions']));
  const sort = getSortParams(report.getIn(['config', 'sort']));
  const metrics = getMetricsParams(report.getIn(['config', 'metrics']));
  const contentTypes = getContentTypeGroup(report.getIn(['config', 'filters']));
  const dateRange = getDateRangeParams(report.getIn(['config', 'filters', 'dateRange'], ImmutableMap()));
  return `${getAttributionBuilderPathPrefix(objectType)}/?${stringify({
    chartType,
    configType,
    frequency,
    dimensions,
    sort,
    metrics,
    contentTypes,
    dateRange
  })}`;
};