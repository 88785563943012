'use es6';

import { List, fromJS } from 'immutable';
import makeDateRangeByType from 'reporting-data/lib/makeDateRangeByType';
import { IN, GT, LT, BETWEEN } from 'reporting-data/constants/operators';
import { RANGE_TYPES } from 'reporting-data/constants/dateRangeTypes';
import { strategy as journeyFilterStrategy } from 'reporting-enablement/components/reportEditors/QuickFilterEditor/JourneyQuickFilterEditor';
import { strategy as analyticsViewFilterStrategy } from 'reporting-enablement/components/reportEditors/UnifiedAnalyticsViewFilterEditor/UnifiedAnalyticsViewFilterEditor';
import { getDataTypeName, getTimestampProperty, isJourneyReport, isQuotaReport, NO_PIPELINE } from '../../utils/salesAnalyticsHelpers';
import { CONTACTS } from 'reporting-data/constants/dataTypes';
import { SUB_APPS } from '../../constants/sub-apps';
import { DEAL_CHANGE_HISTORY_RM_GATE } from '../../constants/gates';
const addCustomFilters = (report, additionalFilters) => report.updateIn(['config', 'filters', 'custom'], (filters = List()) => filters.concat(fromJS(additionalFilters)));
const getJourneyDateRange = dateRange => {
  if (dateRange.get('rangeType') === RANGE_TYPES.ROLLING) {
    const propertyFilterOperation = {
      propertyType: 'calendar-date',
      operator: 'IN_LAST_TIME_UNIT',
      timeUnit: 'DAY',
      timeUnitCount: dateRange.get('timeUnitCount')
    };
    return fromJS({
      propertyFilterOperation
    });
  }
  return fromJS({
    dateFrom: dateRange.get('dateFrom'),
    dateTo: dateRange.get('dateTo')
  });
};
export const JourneyDateRangeMutator = ({
  report,
  context: {
    journeyDateRange
  }
}) => {
  const eventDateInterval = getJourneyDateRange(journeyDateRange);
  const updatedJourneyQuery = report.get('journeyQuery').set('eventDateInterval', eventDateInterval);
  return report.set('journeyQuery', updatedJourneyQuery);
};
export const DateRangeMutator = ({
  report,
  context: {
    dateRange
  }
}) => {
  const useFiscalYear = dateRange.get('useFiscalYear', false);
  const dateRangeValue = dateRange.remove('useFiscalYear');
  return report.setIn(['config', 'filters', 'dateRange', 'value'], dateRangeValue).setIn(['config', 'filters', 'dateRange', 'useFiscalYear'], useFiscalYear);
};
export const ShowDropoffMutator = ({
  report,
  context: {
    showDropoff
  }
}) => report.setIn(['journeyQuery', 'includeDropoffs'], showDropoff);
export const RelationalCustomFiltersMutator = ({
  report,
  context: {
    relationalCustomFilters
  }
}) => {
  let mutatedReport = report;
  const filtering = relationalCustomFilters.get('filtering');
  const eventDateInterval = relationalCustomFilters.get('eventDateInterval');
  if (filtering) {
    mutatedReport = mutatedReport.setIn(['reportDefinition', 'filtering'], filtering);
  }
  if (eventDateInterval) {
    mutatedReport = mutatedReport.setIn(['reportDefinition', 'eventDateInterval'], eventDateInterval);
  }
  return mutatedReport;
};
export const JourneyFilterMutator = ({
  report,
  context: {
    contactObjectFiltering
  }
}) => {
  const dataType = getDataTypeName(report);
  if (dataType === CONTACTS) {
    return report.setIn(['journeyQuery', 'objectQuery', 'filtering'], !contactObjectFiltering || contactObjectFiltering.isEmpty() ? null : contactObjectFiltering);
  }
  return null;
};
export const JourneyAnonVisitorMutator = ({
  report,
  context: {
    includeAnonymousVisitors
  }
}) => report.setIn(['journeyQuery', 'allowAnonymousVisitors'], includeAnonymousVisitors).updateIn(['journeyQuery', 'objectQuery', 'filtering'], filtering => includeAnonymousVisitors ? undefined : filtering);
export const SecondDateRangeMutator = ({
  report,
  context: {
    secondaryDateRange
  },
  filteredGates,
  subAppKey
}) => {
  const timestampProperty = getTimestampProperty(report, filteredGates, subAppKey);
  const {
    startDate,
    endDate
  } = makeDateRangeByType(secondaryDateRange.toJS());
  const isMigratedDealChangeHistoryReport = [SUB_APPS.changeHistoryDealList].includes(subAppKey) && filteredGates.includes(DEAL_CHANGE_HISTORY_RM_GATE);
  const filters = isMigratedDealChangeHistoryReport ? [{
    operator: BETWEEN,
    property: timestampProperty,
    value: startDate,
    highValue: endDate,
    dateTimeFormat: 'DATE'
  }] : [{
    operator: GT,
    property: timestampProperty,
    value: startDate,
    dateTimeFormat: 'DATE'
  }, {
    operator: LT,
    property: timestampProperty,
    value: endDate,
    dateTimeFormat: 'DATE'
  }];
  return addCustomFilters(report, filters).setIn(['displayParams', 'salesAnalytics', 'context', 'secondaryDateRange'], secondaryDateRange);
};
export const CustomFiltersMutator = ({
  report,
  context: {
    customFilters
  }
}) => {
  if (customFilters) {
    return addCustomFilters(report, customFilters);
  }
  return report;
};
export const SalesQuotaPipelineMutator = ({
  report,
  context: {
    pipeline
  }
}) => {
  if (!isQuotaReport(report)) {
    return report;
  }
  const filter = {
    property: 'pipeline',
    operator: 'EQ'
  };
  if (pipeline === NO_PIPELINE) {
    filter.operator = 'NOT_HAS_PROPERTY';
  } else if (!pipeline) {
    filter.operator = 'HAS_PROPERTY';
  } else {
    filter.value = pipeline;
  }
  return addCustomFilters(report, [filter]);
};
export const AnalyticsViewFilterMutator = ({
  report,
  context: {
    analyticsView,
    domain
  }
}) => {
  const newView = analyticsView || domain;
  return analyticsViewFilterStrategy.updateReport(report, newView);
};
const updateCustomFilters = (report, newFilter) => {
  const {
    property: newFilterProperty
  } = newFilter;
  return report.updateIn(['config', 'filters', 'custom'], (filters = List()) => {
    const nonConflictingFilters = filters.filter(filter => newFilterProperty !== filter.get('property'));
    return nonConflictingFilters.concat(fromJS([newFilter]));
  });
};
export const getFilterMutator = ({
  filteredGates,
  getContextField,
  filterSchema
}) => ({
  report,
  context,
  subAppKey
}) => {
  const {
    property,
    operator
  } = filterSchema;
  const filterValue = context[getContextField({
    filteredGates,
    grouping: context.grouping,
    report,
    subAppKey
  })];
  if (isJourneyReport(report)) {
    return journeyFilterStrategy.updateReport(report, filterSchema, filterValue.toJS());
  }
  if (!filterValue || operator === IN && List.isList(filterValue) && filterValue.isEmpty()) {
    return report;
  }
  const value = operator === IN ? {
    values: List.isList(filterValue) ? filterValue.toJS() : [filterValue]
  } : {
    value: filterValue
  };
  const newFilter = Object.assign({
    property,
    operator
  }, value);
  return updateCustomFilters(report, newFilter);
};