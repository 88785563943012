'use es6';

import extractor from '../../../config/extractor';
import invariant from '../../../lib/invariant';
import { EQ } from '../../../constants/operators';
import { getFilterByProperty } from '../../../config/filters/functions';
export const emailTypes = ['AB', 'AUTOMATED_AB', 'AUTOMATED', 'BATCH', 'BLOG', 'AUTOMATED_FOR_FORM', 'RSS', 'SINGLE_SEND', 'TIMEZONE'];
const getEmailType = (config, missing) => {
  const filter = getFilterByProperty(config, 'emailType');
  if (filter && emailTypes.includes(filter.get('value'))) {
    const operator = filter.get('operator');
    invariant(operator === EQ, 'expected operator to be EQ, but got %s', operator);
    return filter.get('value');
  }
  return missing;
};
export default (() => extractor(getEmailType, null));