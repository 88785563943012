import { AGGREGATION, PIPELINE, TIME_SERIES } from 'reporting-data/constants/configTypes';
import { getConfigType, getDataType } from 'reporting-data/report/configReportGetters';
import { isConfigReport } from 'reporting-data/tsTypes/reportTypes';
// @ts-expect-error Untyped import
import { isMismatchedDimensionDateFilterPropertyTimeSeries } from 'reporting-data/properties/isMismatchedDimensionDateFilterPropertyTimeSeries';
import { AREA, COLUMN, CUSTOM, DATA_WELL, FUNNEL, LINE } from 'reporting-data/constants/chartTypes';
import { CROSS_OBJECT } from 'reporting-data/constants/dataTypes';
export const getIsReportValidForDimensionEditorDropdown = ({
  report
}) => {
  return isConfigReport(report) && [AGGREGATION, TIME_SERIES].includes(getConfigType(report));
};
const isCrossObject = report => getDataType(report) === CROSS_OBJECT;
const canBeComparisonConfig = report => {
  const configType = report.getIn(['config', 'configType']);
  if (isCrossObject(report) || [FUNNEL, PIPELINE].includes(configType)) {
    return false;
  }
  const chartType = report.get('chartType');
  const dimensions = report.getIn(['config', 'dimensions']);
  const isMultiDimensional = dimensions && dimensions.size > 1;
  return [DATA_WELL, CUSTOM].includes(chartType) || chartType === COLUMN && !isMultiDimensional || [LINE, AREA].includes(chartType) && configType === TIME_SERIES && !isMultiDimensional;
};
export const isCompareValid = report => canBeComparisonConfig(report) && !isMismatchedDimensionDateFilterPropertyTimeSeries(report);