export const CONTAINS = 'CONTAINS';
export const DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN';
export const ENDS_WITH = 'ENDS_WITH';
export const HAS_EVER_BEEN_ANY_OF = 'HAS_EVER_BEEN_ANY_OF';
export const HAS_EVER_BEEN_EQUAL_TO = 'HAS_EVER_BEEN_EQUAL_TO';
export const HAS_EVER_CONTAINED = 'HAS_EVER_CONTAINED';
export const HAS_NEVER_BEEN_ANY_OF = 'HAS_NEVER_BEEN_ANY_OF';
export const HAS_NEVER_BEEN_EQUAL_TO = 'HAS_NEVER_BEEN_EQUAL_TO';
export const HAS_NEVER_CONTAINED = 'HAS_NEVER_CONTAINED';
export const IS_AFTER = 'IS_AFTER';
export const IS_AFTER_DATE = 'IS_AFTER_DATE';
export const IS_ANY_OF = 'IS_ANY_OF';
export const IS_BEFORE = 'IS_BEFORE';
export const IS_BEFORE_DATE = 'IS_BEFORE_DATE';
export const IS_BETWEEN = 'IS_BETWEEN';
export const IS_EQUAL_TO = 'IS_EQUAL_TO';
export const IS_GREATER_THAN = 'IS_GREATER_THAN';
export const IS_GREATER_THAN_OR_EQUAL_TO = 'IS_GREATER_THAN_OR_EQUAL_TO';
export const IS_KNOWN = 'IS_KNOWN';
export const IS_LESS_THAN = 'IS_LESS_THAN';
export const IN_LAST_TIME_UNIT = 'IN_LAST_TIME_UNIT';
export const IN_THIS_TIME_UNIT = 'IN_THIS_TIME_UNIT';
export const IN_THIS_TIME_UNIT_SO_FAR = 'IN_THIS_TIME_UNIT_SO_FAR';
export const IN_NEXT_TIME_UNIT = 'IN_NEXT_TIME_UNIT';
export const IS_LESS_THAN_OR_EQUAL_TO = 'IS_LESS_THAN_OR_EQUAL_TO';
export const IS_LESS_THAN_X_DAYS_AGO = 'IS_LESS_THAN_X_DAYS_AGO';
export const IS_LESS_THAN_X_DAYS_FROM_NOW = 'IS_LESS_THAN_X_DAYS_FROM_NOW';
export const IS_MORE_THAN_X_DAYS_AGO = 'IS_MORE_THAN_X_DAYS_AGO';
export const IS_MORE_THAN_X_DAYS_FROM_NOW = 'IS_MORE_THAN_X_DAYS_FROM_NOW';
export const IS_NONE_OF = 'IS_NONE_OF';
export const IS_NOT_BETWEEN = 'IS_NOT_BETWEEN';
export const IS_NOT_EQUAL_TO = 'IS_NOT_EQUAL_TO';
export const IS_UNKNOWN = 'IS_UNKNOWN';
export const NOT_UPDATED_IN_LAST_X_DAYS = 'NOT_UPDATED_IN_LAST_X_DAYS';
export const STARTS_WITH = 'STARTS_WITH';
export const UPDATED_IN_LAST_X_DAYS = 'UPDATED_IN_LAST_X_DAYS';
export const IS_EXACTLY = 'IS_EXACTLY';
export const IS_NOT_EXACTLY = 'IS_NOT_EXACTLY';
export const CONTAINS_ALL = 'CONTAINS_ALL';
export const DOES_NOT_CONTAIN_ALL = 'DOES_NOT_CONTAIN_ALL';
export const HAS_EVER_BEEN_EXACTLY = 'HAS_EVER_BEEN_EXACTLY';
export const HAS_NEVER_BEEN_EXACTLY = 'HAS_NEVER_BEEN_EXACTLY';
export const HAS_EVER_CONTAINED_ALL = 'HAS_EVER_CONTAINED_ALL';
export const HAS_NEVER_CONTAINED_ALL = 'HAS_NEVER_CONTAINED_ALL';

// Other
export const CONSTANT = 'CONSTANT';

// ADS
export const LESS_THAN_X_AGO_OR_FROM_NOW = 'LESS_THAN_X_AGO_OR_FROM_NOW';
export const MORE_THAN_X_AGO_OR_FROM_NOW = 'MORE_THAN_X_AGO_OR_FROM_NOW';

// CTA
export const HAS_CLICKED_CTA = 'HAS_CLICKED_CTA';
export const HAS_NOT_CLICKED_CTA = 'HAS_NOT_CLICKED_CTA';
export const HAS_OPENED_CTA = 'HAS_OPENED_CTA';
export const HAS_NOT_OPENED_CTA = 'HAS_NOT_OPENED_CTA';

// CTA Placement
export const HAS_CLICKED_CTA_PLACEMENT = 'HAS_CLICKED_CTA_PLACEMENT';
export const HAS_NOT_CLICKED_CTA_PLACEMENT = 'HAS_NOT_CLICKED_CTA_PLACEMENT';
export const HAS_OPENED_CTA_PLACEMENT = 'HAS_OPENED_CTA_PLACEMENT';
export const HAS_NOT_OPENED_CTA_PLACEMENT = 'HAS_NOT_OPENED_CTA_PLACEMENT';

// Events
export const HAS_EVENT = 'HAS_EVENT';
export const NOT_HAS_EVENT = 'NOT_HAS_EVENT';

// PRIVACY CONSENT (Subtype of contact property in the UI)
export const PRIVACY_CONSENT_NOT_GRANTED = 'PRIVACY_CONSENT_NOT_GRANTED';
export const PRIVACY_CONSENT_GRANTED = 'PRIVACY_CONSENT_GRANTED';

// Email campaigns
export const EMAIL_BOUNCED = 'EMAIL_BOUNCED';
export const EMAIL_LINK_CLICKED = 'EMAIL_LINK_CLICKED';
export const EMAIL_MARKED = 'EMAIL_MARKED';
export const EMAIL_OPENED = 'EMAIL_OPENED';
export const EMAIL_OPENED_BUT_LINK_NOT_CLICKED = 'EMAIL_OPENED_BUT_LINK_NOT_CLICKED';
export const EMAIL_OPENED_BUT_NOT_REPLIED = 'EMAIL_OPENED_BUT_NOT_REPLIED';
export const EMAIL_RECEIVED = 'EMAIL_RECEIVED';
export const EMAIL_RECEIVED_BUT_NOT_OPENED = 'EMAIL_RECEIVED_BUT_NOT_OPENED';
export const EMAIL_REPLIED = 'EMAIL_REPLIED';
export const EMAIL_SENT = 'EMAIL_SENT';
export const EMAIL_SENT_BUT_LINK_NOT_CLICKED = 'EMAIL_SENT_BUT_LINK_NOT_CLICKED';
export const EMAIL_SENT_BUT_NOT_RECEIVED = 'EMAIL_SENT_BUT_NOT_RECEIVED';
export const EMAIL_UNSUBSCRIBED = 'EMAIL_UNSUBSCRIBED';

// Email subscriptions
export const HAS_OPTED_IN = 'OPT_IN';
export const HAS_OPTED_OUT = 'OPT_OUT';
export const HAS_NOT_OPTED = 'NOT_OPTED';

// Form submissions
export const FORM_SUBMISSION = 'FORM_SUBMISSION';
export const FORM_SUBMISSION_ON_PAGE = 'FORM_SUBMISSION_ON_PAGE';

// In list
export const IS_IN_LIST = 'IN_LIST';
export const IS_NOT_IN_LIST = 'NOT_IN_LIST';

// Imports
export const IS_IN_IMPORT = 'IN_IMPORT';
export const IS_NOT_IN_IMPORT = 'NOT_IN_IMPORT';

// Page Views
export const HAS_PAGEVIEW_CONTAINS = 'HAS_PAGEVIEW_CONTAINS';
export const HAS_PAGEVIEW_EQ = 'HAS_PAGEVIEW_EQ';
export const HAS_PAGEVIEW_MATCHES_REGEX = 'HAS_PAGEVIEW_MATCHES_REGEX';
export const NOT_HAS_PAGEVIEW_CONTAINS = 'NOT_HAS_PAGEVIEW_CONTAINS';
export const NOT_HAS_PAGEVIEW_EQ = 'NOT_HAS_PAGEVIEW_EQ';
export const NOT_HAS_PAGEVIEW_MATCHES_REGEX = 'NOT_HAS_PAGEVIEW_MATCHES_REGEX';

// Survey Monkey surveys
export const HAS_NOT_RESPONDED_TO_SURVEY = 'HAS_NOT_RESPONDED_TO_SURVEY';
export const HAS_RESPONDED_TO_SURVEY = 'HAS_RESPONDED_TO_SURVEY';
export const HAS_ANSWERED_SURVEY_QUESTION_WITH_VALUE = 'HAS_ANSWERED_SURVEY_QUESTION_WITH_VALUE';

// Webinars
export const HAS_WEBINAR_ATTENDANCE = 'HAS_WEBINAR_ATTENDANCE';
export const HAS_WEBINAR_REGISTRATION = 'HAS_WEBINAR_REGISTRATION';
export const NOT_HAS_WEBINAR_ATTENDANCE = 'NOT_HAS_WEBINAR_ATTENDANCE';
export const NOT_HAS_WEBINAR_REGISTRATION = 'NOT_HAS_WEBINAR_REGISTRATION';

// WORKFLOW
export const WORKFLOW_ACTIVE = 'ACTIVE_IN_WORKFLOW';
export const WORKFLOW_COMPLETED = 'COMPLETED_WORKFLOW';
export const WORKFLOW_ENROLLED = 'ENROLLED_IN_WORKFLOW';
export const WORKFLOW_MET_GOAL = 'MET_WORKFLOW_GOAL';
export const WORKFLOW_NOT_ACTIVE = 'NEVER_ACTIVE_IN_WORKFLOW';
export const WORKFLOW_NOT_COMPLETED = 'NEVER_COMPLETED_WORKFLOW';
export const WORKFLOW_NOT_ENROLLED = 'NEVER_ENROLLED_IN_WORKFLOW';
export const WORKFLOW_NOT_MET_GOAL = 'NEVER_MET_WORKFLOW_GOAL';

// ADS

// CTA

// CTA Placement

// Events

// PRIVACY CONSENT (Subtype of contact property in the UI)

// Email campaigns

// Email subscriptions

// Form submissions

// In list

// Imports

// Page Views

// Survey Monkey surveys

// Webinars

// WORKFLOW