'use es6';

import { useCallback, useMemo, useReducer } from 'react';
import set from 'transmute/set';
import get from 'transmute/get';
import { Map as ImmutableMap, Set as ImmutableSet, OrderedSet } from 'immutable';
import { useTeams } from '../../../../data/hooks/useTeams';
import { useTeamHierarchy } from '../../../../data/hooks/useTeamHierarchy';
import { getChildTeams, getId, getParentTeamId } from '../../../../data/schemas/teamSchema';
import { traverseStack } from '../../../../lib/traverse';
const SELECT_TEAM = 'SELECT_TEAM';
const DESELECT_TEAM = 'DESELECT_TEAM';
const SET_SELECTED_TEAMS = 'SET_SELECTED_TEAM';
const teamSelectReducer = (state, action) => {
  switch (action.type) {
    case SELECT_TEAM:
      return state.union(action.teams);
    case DESELECT_TEAM:
      return state.subtract(action.teams);
    case SET_SELECTED_TEAMS:
      return OrderedSet(action.teams);
    default:
      return state;
  }
};
const getParentTeamIdSet = (allTeams, teamId) => {
  const targetTeam = allTeams.get(teamId);
  const loop = (acc, next) => {
    const parentTeamId = getParentTeamId(next);
    if (parentTeamId == null) {
      return acc;
    }
    return loop(acc.add(parentTeamId), allTeams.get(parentTeamId));
  };
  return loop(ImmutableSet(), targetTeam);
};
const traverseHierarchy = (accumulateFn, initial, subject) => traverseStack(accumulateFn, team => getChildTeams(team) || [], initial, subject);

/**
 * Selects teams with children derived from team hierarchy
 * team: { children: [team, team] }
 */
const getTeamsHydratedWithChildren = teamHierarchy => {
  return traverseHierarchy((acc, team) => {
    const teamId = getId(team);
    return set(teamId, team, acc);
  }, ImmutableMap(), teamHierarchy);
};
const getChildTeamIdSet = (teamsHydratedWithChildren, teamId) => {
  const targetTeamWithChildren = get(teamId, teamsHydratedWithChildren);
  return traverseHierarchy((acc, team) => acc.add(getId(team)), ImmutableSet(), [targetTeamWithChildren]);
};
export const useTeamSelectState = initialState => {
  const {
    data: allTeams
  } = useTeams();
  const {
    data: teamHierarchy
  } = useTeamHierarchy();
  const [selectedTeamSet, dispatch] = useReducer(teamSelectReducer, OrderedSet(initialState));
  const teamsHydratedWithChildren = useMemo(() => getTeamsHydratedWithChildren(teamHierarchy), [teamHierarchy]);
  const selectTeam = useCallback(teamId => {
    const parents = getParentTeamIdSet(allTeams, teamId);
    dispatch({
      type: SELECT_TEAM,
      teams: parents.add(teamId)
    });
  }, [allTeams]);
  const deselectTeam = useCallback(teamId => {
    const children = getChildTeamIdSet(teamsHydratedWithChildren, teamId);
    dispatch({
      type: DESELECT_TEAM,
      teams: children.add(teamId)
    });
  }, [teamsHydratedWithChildren]);
  const setSelectedTeams = useCallback(teamIds => {
    dispatch({
      type: SET_SELECTED_TEAMS,
      teams: teamIds
    });
  }, []);
  return {
    selectedTeamSet,
    selectTeam,
    deselectTeam,
    setSelectedTeams
  };
};