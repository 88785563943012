'use es6';

import { Record } from 'immutable';
export const types = {
  SET_USER_INFO: '@@reporting/SET_USER_INFO',
  CLEAR_USER_INFO: '@@reporting/CLEAR_USER_INFO'
};
export const UserInfoState = Record({
  promise: null,
  data: null
}, 'UserInfoState');
export const initialState = new UserInfoState();
export const reducer = (state = initialState, action) => {
  const {
    type,
    data = {}
  } = action;
  switch (type) {
    case types.SET_USER_INFO:
      {
        const {
          promise = state.promise,
          userInfo = state.data
        } = data;
        return state.set('promise', promise).set('data', userInfo);
      }
    case types.CLEAR_USER_INFO:
      {
        return initialState;
      }
    default:
      {
        return state;
      }
  }
};
export const actions = {
  setUserInfo: ({
    promise,
    userInfo
  }) => ({
    type: types.SET_USER_INFO,
    data: {
      promise,
      userInfo
    }
  }),
  clearUserInfo: () => ({
    type: types.CLEAR_USER_INFO
  })
};