'use es6';

const FILTERED_BREAKDOWN_KEY_DELIMITER = '|';
export const getFilteredBreakdownKey = (dataType, filterValue) => `${dataType}${FILTERED_BREAKDOWN_KEY_DELIMITER}${filterValue}`;
export const getFilteredBreakdownsFieldsFromKey = filteredBreakdownKey => {
  const splitKey = filteredBreakdownKey.split(FILTERED_BREAKDOWN_KEY_DELIMITER);
  return {
    dataType: splitKey[0],
    filterValue: splitKey[1]
  };
};
export const getFilteredBreakdownValuesFromKeys = filteredBreakdowns => {
  return filteredBreakdowns.map(filteredBreakdownKey => getFilteredBreakdownsFieldsFromKey(filteredBreakdownKey).filterValue);
};