import I18n from 'I18n';
import set from 'transmute/set';
// @ts-expect-error migrate file to typescript
import { RequestState } from 'dashboard-lib/public/request/request-state';
// @ts-expect-error migrate file to typescript
import { getLabel } from './dataSourceMetadataSchema';
import { isFrameworkDataSource } from './dataSourceHelpers';
export const getDataSourceMetadataWithUniqueLabel = (dataSource, dataSourceMetadata) => {
  const newLabel = I18n.text(isFrameworkDataSource(dataSource) ? 'dashboardHeader.dashboardFilters.allFilters.panel.uniqueDataSourcesLabel.builder' : 'dashboardHeader.dashboardFilters.allFilters.panel.uniqueDataSourcesLabel.other', {
    dataSourceLabel: getLabel(dataSourceMetadata)
  });
  return set('label', newLabel, dataSourceMetadata);
};
export const selectDataSourcesInfo = ({
  dataNamespace,
  dataSources,
  valueName
}) => state => dataSources.reduce((memo, dataSource) => {
  // TODO: we might want to consider to use getIn([dataNamespace, dataSource], DEFAULT_VALUE)
  const storedValue = state[dataNamespace][dataSource];
  memo[dataSource] = storedValue ? storedValue[valueName] : [];
  return memo;
}, {});
export const selectDataSourcesInfoStatus = ({
  dataNamespace,
  dataSources
}) => state => dataSources.reduce((memo, dataSource) => {
  if (memo === RequestState.FAILED) {
    return memo;
  }

  // TODO: we might want to consider to use getIn([dataNamespace, dataSource], RequestState.PENDING)
  const storedValue = state[dataNamespace][dataSource];
  if (!storedValue || storedValue.status === RequestState.PENDING) {
    return RequestState.PENDING;
  }
  if (storedValue.status === RequestState.FAILED) {
    return RequestState.FAILED;
  }
  return memo;
}, RequestState.SUCCEEDED);