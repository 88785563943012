import { ExpressionTypes, isAggregationContextExpression, isBinaryExpression, isBooleanLiteralExpression, isFieldExpression, isFunctionCallExpression, isIdentifierExpression, isNullLiteralExpression, isNumericLiteralExpression, isPropertyExpression, isRecoveryExpression, isStringLiteralExpression, isUnaryExpression } from '../expression/expression';
import { createBooleanLiteralType, createNumericLiteralType, createStringLiteralType, createUnionType, isGenericType, isUndefinedType, NULL_TYPE, UNDEFINED_TYPE } from '../type/type';
import { check, expandFunctionSignature, flattenType, typeToMap, typeToString, widenType } from '../type/type-utils';
import { isArgumentViolation, TypeViolationTypes } from './type-violation';
const withViolations = (type, ...violations) => ({
  type,
  violations
});
const checkIdentifierExpressionType = (expression, environment) => {
  const {
    scope
  } = environment;
  const scopedValue = scope[expression.name];
  if (!scopedValue) {
    return withViolations(UNDEFINED_TYPE, {
      type: TypeViolationTypes.REFERENCE,
      expression
    });
  }
  return withViolations(scopedValue.type);
};
const checkFieldExpressionType = (expression, environment) => {
  const referencedField = environment.fields[expression.name];
  const referencedType = referencedField && referencedField.type;
  if (!referencedField || isUndefinedType(referencedType)) {
    return withViolations(UNDEFINED_TYPE, {
      type: TypeViolationTypes.REFERENCE,
      expression
    });
  }
  return withViolations(referencedType);
};
const checkPropertyExpressionType = (expression, environment) => {
  const referencedProperty = environment.properties[`${expression.table}.${expression.name}`];
  const referencedType = referencedProperty && referencedProperty.type;
  if (!referencedType) {
    return withViolations(UNDEFINED_TYPE, {
      type: TypeViolationTypes.REFERENCE,
      expression
    });
  }
  return withViolations(referencedType);
};
const checkSignature = (expression, signature, providedTypes) => {
  const {
    arguments: argumentTypes,
    returns: returnType
  } = signature;
  const argumentDetails = argumentTypes.map((argumentType, index) => {
    const argument = expression.arguments[index];
    const providedType = providedTypes[index] || UNDEFINED_TYPE;
    const valid = check(providedType, argumentType);
    const generic = Array.from(typeToMap(argumentType).values()).find(isGenericType);
    return {
      index,
      argument,
      argumentType,
      providedType,
      valid,
      generic
    };
  });
  const argumentViolations = argumentDetails.filter(({
    valid
  }) => !valid).filter(({
    generic,
    providedType
  }) => !generic || generic && isUndefinedType(providedType)).map(({
    index,
    argument,
    argumentType,
    providedType
  }) => ({
    type: TypeViolationTypes.ARGUMENT,
    expression: argument,
    parentExpression: expression,
    argumentIndex: index,
    expected: argumentType,
    provided: providedType
  }));
  const genericCandidates = {};
  argumentDetails.filter(({
    valid,
    generic
  }) => !valid && generic).forEach(argumentData => {
    if (argumentData.generic) {
      const key = typeToString(argumentData.generic);
      if (!genericCandidates[key]) {
        genericCandidates[key] = {
          generic: argumentData.generic,
          candidates: [argumentData]
        };
        return;
      }
      genericCandidates[key].candidates.push(argumentData);
    }
  });
  const checkedGenerics = Object.values(genericCandidates).map(({
    generic,
    candidates
  }) => {
    const types = candidates.map(({
      providedType: t
    }) => t);

    // valid generic literal type
    const literalType = flattenType(createUnionType(...types));
    const literalMap = typeToMap(literalType);
    literalMap.delete(typeToString(NULL_TYPE));
    if (literalMap.size < 2) {
      return Object.assign({
        generic
      }, withViolations(literalType));
    }

    // valid generic primitive type
    const primitiveType = widenType(literalType);
    const primitiveMap = typeToMap(primitiveType);
    primitiveMap.delete(typeToString(NULL_TYPE));
    if (primitiveMap.size < 2) {
      return Object.assign({
        generic
      }, withViolations(primitiveType));
    }

    // Technically we still have a generic type, the union of provided types
    // Our type system only considers Nullable literals/primitives as valid generic types

    return Object.assign({
      generic
    }, withViolations(UNDEFINED_TYPE, {
      type: TypeViolationTypes.GENERICS,
      expression,
      argumentIndices: candidates.map(({
        index
      }) => index),
      genericType: generic,
      providedTypes: candidates.map(({
        providedType
      }) => providedType)
    }));
  });
  const genericViolations = checkedGenerics.flatMap(({
    violations
  }) => violations);
  if (isGenericType(returnType)) {
    const returnTypeKey = typeToString(returnType);
    const solvedReturnType = checkedGenerics.find(({
      generic
    }) => typeToString(generic) === returnTypeKey);
    if (!solvedReturnType) {
      throw new Error(`Could not solve generic type ${returnTypeKey}`);
    }
    return withViolations(solvedReturnType.type, ...argumentViolations, ...genericViolations);
  }
  return withViolations(returnType, ...argumentViolations, ...genericViolations);
};
const checkFunctionCallExpressionType = (expression, environment) => {
  const checkedArguments = expression.arguments.map(e =>
  // eslint-disable-next-line
  checkExpressionType(e, environment));
  const providedTypes = checkedArguments.map(({
    type
  }) => type);
  const providedViolations = checkedArguments.flatMap(({
    violations
  }) => violations);
  const {
    functions,
    operators
  } = environment;
  const functionType = functions[expression.name] || operators[expression.name];
  if (!functionType) {
    return withViolations(UNDEFINED_TYPE, ...providedViolations, {
      type: TypeViolationTypes.REFERENCE,
      expression
    });
  }
  let result;
  for (const signature of functionType.signatures) {
    const expandedSignature = expandFunctionSignature(signature, providedTypes.length);
    const {
      type,
      violations
    } = checkSignature(expression, expandedSignature, providedTypes);

    // If we have a zero length signature, go ahead and use it
    if (violations.length === 0) {
      return withViolations(type, ...providedViolations, ...violations);
    }
    if (!result || violations.length < result.violations.length) {
      result = withViolations(type, ...violations);
    }
  }
  if (!result || functionType.signatures.length === 0) {
    throw new Error(`Could not find a signature for ${expression.name}`);
  }
  return withViolations(result.type, ...providedViolations, ...result.violations);
};
const checkAggregationContextExpressionType = (expression, environment) => {
  const {
    dimensions,
    expression: lodExpression
  } = expression;
  const checkedDimensions = dimensions.map(e =>
  // eslint-disable-next-line
  checkExpressionType(e, environment));
  const providedViolations = checkedDimensions.flatMap(({
    violations
  }) => violations);

  // eslint-disable-next-line
  const {
    type,
    violations
  } = checkExpressionType(lodExpression, environment);
  return withViolations(type, ...providedViolations, ...violations);
};
const checkTypeWithRemappedExpression = mapToFunctionCallExpression => (expression, environment) => {
  const functionExpression = mapToFunctionCallExpression(expression);
  const {
    type,
    violations
  } = checkFunctionCallExpressionType(functionExpression, environment);
  const remapViolation = violation => {
    violation.expression = functionExpression === violation.expression ? expression : violation.expression;
    if (isArgumentViolation(violation)) {
      violation.parentExpression = functionExpression === violation.parentExpression ? expression : violation.parentExpression;
    }
    return violation;
  };
  const remappedViolations = violations.map(remapViolation);
  return withViolations(type, ...remappedViolations);
};
const checkUnaryExpressionType = checkTypeWithRemappedExpression(unaryExpression => ({
  type: ExpressionTypes.FUNCTION_CALL,
  name: unaryExpression.type,
  arguments: [unaryExpression.argument]
}));
const checkBinaryExpressionType = checkTypeWithRemappedExpression(binaryExpression => ({
  type: ExpressionTypes.FUNCTION_CALL,
  name: binaryExpression.type,
  arguments: [binaryExpression.left, binaryExpression.right]
}));
export const checkExpressionType = (expression, environment) => {
  // Recovery
  if (isRecoveryExpression(expression)) return withViolations(UNDEFINED_TYPE);

  // Literals
  if (isNullLiteralExpression(expression)) return withViolations(NULL_TYPE);
  if (isBooleanLiteralExpression(expression)) return withViolations(createBooleanLiteralType(expression.value));
  if (isNumericLiteralExpression(expression)) return withViolations(createNumericLiteralType(expression.value));
  if (isStringLiteralExpression(expression)) return withViolations(createStringLiteralType(expression.value));

  // Reference
  if (isIdentifierExpression(expression)) return checkIdentifierExpressionType(expression, environment);
  if (isFieldExpression(expression)) return checkFieldExpressionType(expression, environment);
  if (isPropertyExpression(expression)) return checkPropertyExpressionType(expression, environment);

  // Language
  if (isFunctionCallExpression(expression)) return checkFunctionCallExpressionType(expression, environment);
  if (isAggregationContextExpression(expression)) return checkAggregationContextExpressionType(expression, environment);

  // Unary
  if (isUnaryExpression(expression)) return checkUnaryExpressionType(expression, environment);

  // Binary
  if (isBinaryExpression(expression)) return checkBinaryExpressionType(expression, environment);
  throw new Error(`Unsupported expression: ${expression}`);
};