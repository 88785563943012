import { List, Map as ImmutableMap } from 'immutable';
import { isUnifiedEventsReport } from '../../tsTypes/reportTypes';
import { AGGREGATION, TIME_SERIES } from '../../constants/configTypes';
const CRM_OBJECT_REFERENCE_PROPERTY_PREFIX = 'object';
const filterCrmObjectReferenceFilters = property => {
  const [maybePrefix, maybeRealPropertyName] = property.split('.');
  return !(maybeRealPropertyName && maybePrefix === CRM_OBJECT_REFERENCE_PROPERTY_PREFIX);
};
export const getUEReportType = report => report.getIn(['config', 'unifiedEvent', 'reportType']);
export const getUESchemaVersion = report => report.getIn(['config', 'unifiedEvent', 'schemaVersion']);
export const parseCustomFilters = customFilters => customFilters.reduce((filtersByType, filter) => {
  if (filterCrmObjectReferenceFilters(filter.get('property'))) {
    return filtersByType.update('UEFilters', UEfilters => UEfilters.push(filter));
  }
  return filtersByType.update('crmObjectFilters', CrmObjectfilters => CrmObjectfilters.push(filter));
}, ImmutableMap({
  UEFilters: List([]),
  crmObjectFilters: List([])
}));
export const applyPrefixToCrmObjectFilter = filter => {
  return filter.update('property', property => `${CRM_OBJECT_REFERENCE_PROPERTY_PREFIX}.${property}`);
};
export const normalizePrefixedCrmObjectFilter = filter => {
  const [maybePrefix, maybeRealPropertyName] = filter.get('property').split('.');
  return maybeRealPropertyName && maybePrefix === CRM_OBJECT_REFERENCE_PROPERTY_PREFIX ? filter.set('property', maybeRealPropertyName) : filter;
};
const getNodeViaSubaggregationOrder = (schema, report) => {
  const currentDimension = report.getIn(['config', 'dimensions', 0]);
  const filterProperties = report.getIn(['config', 'filters', 'custom']).map(filter => filter.get('property')).toSet(); // ensure unique set of filter properties (https://hubspot.slack.com/archives/C5WR5PBFT/p1663102668608009?thread_ts=1662734878.589779&cid=C5WR5PBFT)
  const subaggregationOrder = filterProperties.filter(property => property !== currentDimension && filterCrmObjectReferenceFilters(property));
  return subaggregationOrder.reduce((currentNode, property) => currentNode.children[property] || schema.aggregationHierarchy, schema.aggregationHierarchy);
};
export const getSiblingDrilldownOptions = (schema, report) => {
  const nodeFromSubAggOrder = getNodeViaSubaggregationOrder(schema, report);
  return Object.keys(nodeFromSubAggOrder.children).map(property => ({
    text: nodeFromSubAggOrder.children[property].label || property,
    value: property
  }));
};
export const detemineNodeBasedOnReport = (schema, report) => {
  const nodeFromSubAggOrder = getNodeViaSubaggregationOrder(schema, report);
  const currentDimension = report.getIn(['config', 'dimensions', 0]);
  return currentDimension ? nodeFromSubAggOrder.children[currentDimension] : nodeFromSubAggOrder;
};
export const SUPPORTED_UE_CONFIG_TYPES = [AGGREGATION, TIME_SERIES];
export const isSupportedUEReport = report => {
  const configType = report.getIn(['config', 'configType']);
  return isUnifiedEventsReport(report) && SUPPORTED_UE_CONFIG_TYPES.includes(configType);
};
export const __TESTABLE__ = {
  filterCrmObjectReferenceFilters
};