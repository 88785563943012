import * as checked from 'reporting-data/lib/checked';
export const Directions = {
  ascending: 'ascending',
  descending: 'descending'
};
export const Direction = checked.symbol(Directions, 'Direction');
export const Aggregations = {
  count: 'count',
  sum: 'sum',
  average: 'average',
  min: 'min',
  max: 'max',
  distinct: 'distinct',
  attribute: 'attribute'
};
export const Aggregation = checked.symbol(Aggregations, 'Aggregation');
export const Order = checked.record({
  defined: checked.list(checked.string()).optional(),
  direction: Direction.optional(),
  field: checked.string().optional(),
  aggregation: Aggregation.optional()
}, 'Order');