import { AD_CAMPAIGN, APPOINTMENT, ATTRIBUTION, AUTOMATION_PLATFORM_FLOW, BLOG_LISTING_PAGE, BLOG_POST, CAMPAIGN, COMMUNICATION, COMPANY, CONTACT, CONTACT_CREATE_ATTRIBUTION, CONVERSATION, COURSE, CTA, DATA_PRIVACY_REQUEST, DEAL, DISCOUNT, ENGAGEMENT, FEEDBACK_SUBMISSION, FEEDBACK_SURVEY, FORM, FORM_DASHBOARD, KNOWLEDGE_ARTICLE, LANDING_PAGE, LINE_ITEM, LISTING, MARKETING_EMAIL, MARKETING_SMS, OBJECT_LIST, PRODUCT, QUOTE, QUOTE_MODULE, QUOTE_MODULE_FIELD, QUOTE_TEMPLATE, SEQUENCE_ENROLLMENT, SOCIAL_BROADCAST, TASK, TICKET, MARKETING_EVENT, GOAL_TARGET, USER, LEAD, DATA_PRIVACY_CONSENT, SERVICE, SITE_PAGE, WEB_INTERACTIVE, MEETING_EVENT, PARTNER_CLIENT, PARTNER_ACCOUNT } from './ObjectTypes';
// values taken from https://git.hubteam.com/HubSpot/IdentityBase/blob/d231c0fb530b36463ef8bbd5603514799fec6221/src/main/protobuf/contacts.proto#L21-L79
export const CONTACT_TYPE_ID = '0-1';
export const COMPANY_TYPE_ID = '0-2';
export const DEAL_TYPE_ID = '0-3';
export const ENGAGEMENT_TYPE_ID = '0-4';
export const TICKET_TYPE_ID = '0-5';
export const PRODUCT_TYPE_ID = '0-7';
export const LINE_ITEM_TYPE_ID = '0-8';
export const CONTENT_TYPE_ID = '0-10';
export const CONVERSATION_TYPE_ID = '0-11';
export const QUOTE_TYPE_ID = '0-14';
export const FORM_TYPE_ID = '0-15';
export const COMMUNICATION_TYPE_ID = '0-18';
export const FEEDBACK_SUBMISSION_TYPE_ID = '0-19';
export const ATTRIBUTION_TYPE_ID = '0-20';
export const LANDING_PAGE_TYPE_ID = '0-25';
export const TASK_TYPE_ID = '0-27';
export const FORM_DASHBOARD_TYPE_ID = '0-28';
export const MARKETING_EMAIL_TYPE_ID = '0-29';
export const AD_CAMPAIGN_TYPE_ID = '0-31';
export const CAMPAIGN_TYPE_ID = '0-35';
export const CTA_TYPE_ID = '0-42';
export const SITE_PAGE_TYPE_ID = '0-38';
export const BLOG_POST_TYPE_ID = '0-39';
export const AUTOMATION_PLATFORM_FLOW_TYPE_ID = '0-44';
export const OBJECT_LIST_TYPE_ID = '0-45';
export const NOTE_TYPE_ID = '0-46';
export const MEETING_EVENT_TYPE_ID = '0-47';
export const CALL_TYPE_ID = '0-48';
export const EMAIL_TYPE_ID = '0-49';
export const PUBLISHING_TASK_TYPE_ID = '0-50';
export const CONVERSATION_SESSION_TYPE_ID = '0-51';
export const CONTACT_CREATE_ATTRIBUTION_TYPE_ID = '0-52';
export const INVOICE_TYPE_ID = '0-53';
export const MARKETING_EVENT_TYPE_ID = '0-54';
export const QUOTE_TEMPLATE_TYPE_ID = '0-64';
export const QUOTE_MODULE_TYPE_ID = '0-65';
export const QUOTE_MODULE_FIELD_TYPE_ID = '0-66';
export const SEQUENCE_ENROLLMENT_TYPE_ID = '0-68';
export const SUBSCRIPTION_TYPE_ID = '0-69';
export const SOCIAL_BROADCAST_TYPE_ID = '0-71';
export const DEAL_REGISTRATION_ID = '0-73';
export const GOAL_TARGET_ID = '0-74';
export const SALES_DOCUMENT_TYPE_ID = '0-83';
export const PLAYBOOK_TYPE_ID = '0-96';
export const DISCOUNT_TYPE_ID = '0-84';
export const FEE_TYPE_ID = '0-85';
export const TAX_TYPE_ID = '0-86';
export const WEB_INTERACTIVE_TYPE_ID = '0-91';
export const COMMERCE_PAYMENT_TYPE_ID = '0-101';
export const BLOG_LISTING_PAGE_TYPE_ID = '0-104';
export const DISCOUNT_TEMPLATE_TYPE_ID = '0-109';
export const FEEDBACK_SURVEY_ID = '0-111';
export const SALES_TASK_TYPE_ID = '0-113';
export const USER_TYPE_ID = '0-115';
export const POSTAL_MAIL_TYPE_ID = '0-116';
export const PAYMENT_LINK_TYPE_ID = '0-118';
export const ORDER_TYPE_ID = '0-123';
export const MARKETING_SMS_TYPE_ID = '0-124';
export const PARTNER_ACCOUNT_TYPE_ID = '0-125';
export const LEAD_TYPE_ID = '0-136';
export const CART_TYPE_ID = '0-142';
export const PARTNER_CLIENT_TYPE_ID = '0-145';
export const KNOWLEDGE_ARTICLE_TYPE_ID = '0-151';
export const DATA_PRIVACY_CONSENT_TYPE_ID = '0-153';
export const SERVICE_TYPE_ID = '0-162';
export const PARTNER_SERVICE_TYPE_ID = '0-164';
export const APPOINTMENT_TYPE_ID = '0-421';
export const COURSE_TYPE_ID = '0-410';
export const LISTING_TYPE_ID = '0-420';
export const DATA_PRIVACY_REQUEST_TYPE_ID = '0-422';

/**
 * Object type ids are formatted as "<meta type id>-<inner id>"
 *
 * We don't want to hard-code the complete list of HubSpot-defined
 * object type ids, as new ones can be created dynamically using
 * the Framework Builder. Similarly, it's not possible to hard-code
 * portal-specific object type ids such as "2-123", as they differ
 * for each portal.
 *
 * TypeScript also has no way to define a type that matches a regex.
 * Otherwise we would limit this to /\d+-\d+/ (possibly further
 * restricted to only known meta type ids).
 *
 * See https://github.com/microsoft/TypeScript/issues/41160
 * See https://github.com/microsoft/TypeScript/issues/6579
 */

export const ObjectTypesToIds = {
  [AD_CAMPAIGN]: AD_CAMPAIGN_TYPE_ID,
  [APPOINTMENT]: APPOINTMENT_TYPE_ID,
  [ATTRIBUTION]: ATTRIBUTION_TYPE_ID,
  [AUTOMATION_PLATFORM_FLOW]: AUTOMATION_PLATFORM_FLOW_TYPE_ID,
  [BLOG_LISTING_PAGE]: BLOG_LISTING_PAGE_TYPE_ID,
  [BLOG_POST]: BLOG_POST_TYPE_ID,
  [CAMPAIGN]: CAMPAIGN_TYPE_ID,
  [COMMUNICATION]: COMMUNICATION_TYPE_ID,
  [CONTACT]: CONTACT_TYPE_ID,
  [CONTACT_CREATE_ATTRIBUTION]: CONTACT_CREATE_ATTRIBUTION_TYPE_ID,
  [COMPANY]: COMPANY_TYPE_ID,
  [COURSE]: COURSE_TYPE_ID,
  [CTA]: CTA_TYPE_ID,
  [DATA_PRIVACY_REQUEST]: DATA_PRIVACY_REQUEST_TYPE_ID,
  [DEAL]: DEAL_TYPE_ID,
  [DISCOUNT]: DISCOUNT_TYPE_ID,
  [ENGAGEMENT]: ENGAGEMENT_TYPE_ID,
  [KNOWLEDGE_ARTICLE]: KNOWLEDGE_ARTICLE_TYPE_ID,
  [TICKET]: TICKET_TYPE_ID,
  [PRODUCT]: PRODUCT_TYPE_ID,
  [LANDING_PAGE]: LANDING_PAGE_TYPE_ID,
  [LINE_ITEM]: LINE_ITEM_TYPE_ID,
  [LISTING]: LISTING_TYPE_ID,
  [MARKETING_EMAIL]: MARKETING_EMAIL_TYPE_ID,
  [MARKETING_SMS]: MARKETING_SMS_TYPE_ID,
  [CONVERSATION]: CONVERSATION_TYPE_ID,
  [QUOTE]: QUOTE_TYPE_ID,
  [FORM]: FORM_TYPE_ID,
  [FORM_DASHBOARD]: FORM_DASHBOARD_TYPE_ID,
  [FEEDBACK_SUBMISSION]: FEEDBACK_SUBMISSION_TYPE_ID,
  [FEEDBACK_SURVEY]: FEEDBACK_SURVEY_ID,
  [TASK]: TASK_TYPE_ID,
  [OBJECT_LIST]: OBJECT_LIST_TYPE_ID,
  [DATA_PRIVACY_CONSENT]: DATA_PRIVACY_CONSENT_TYPE_ID,
  [QUOTE_MODULE]: QUOTE_MODULE_TYPE_ID,
  [QUOTE_MODULE_FIELD]: QUOTE_MODULE_FIELD_TYPE_ID,
  [QUOTE_TEMPLATE]: QUOTE_TEMPLATE_TYPE_ID,
  [SEQUENCE_ENROLLMENT]: SEQUENCE_ENROLLMENT_TYPE_ID,
  [SOCIAL_BROADCAST]: SOCIAL_BROADCAST_TYPE_ID,
  [MARKETING_EVENT]: MARKETING_EVENT_TYPE_ID,
  [GOAL_TARGET]: GOAL_TARGET_ID,
  [USER]: USER_TYPE_ID,
  [LEAD]: LEAD_TYPE_ID,
  [SERVICE]: SERVICE_TYPE_ID,
  [SITE_PAGE]: SITE_PAGE_TYPE_ID,
  [WEB_INTERACTIVE]: WEB_INTERACTIVE_TYPE_ID,
  [MEETING_EVENT]: MEETING_EVENT_TYPE_ID,
  [PARTNER_CLIENT]: PARTNER_CLIENT_TYPE_ID,
  [PARTNER_ACCOUNT]: PARTNER_ACCOUNT_TYPE_ID
};

/**
 * Object type ids are formatted as "<meta type id>-<inner id>". Meta type id is from a
 * known list of meta types and inner type is numeric. The backend also understands
 * object type id aliases (DEAL/DEALS/deal/deals) but those are not considered here.
 *
 * See https://git.hubteam.com/HubSpot/InboundDbMeta/blob/master/InboundDbCore/src/main/java/com/hubspot/inbounddb/base/ObjectTypeId.java
 * See git.hubteam.com/HubSpot/InboundDbMeta/blob/master/InboundDbCore/src/main/java/com/hubspot/inbounddb/base/MetaType.java
 */
export const isObjectTypeId = objectTypeId => /^\d+-\d+$/.test(objectTypeId);
function invert(obj) {
  const map = {};
  Object.keys(obj).forEach(key => map[obj[key]] = key);
  return map;
}
export const ObjectTypeFromIds = invert(ObjectTypesToIds);
export const denormalizeTypeId = objectTypeOrId => Object.prototype.hasOwnProperty.call(ObjectTypeFromIds, objectTypeOrId) ? ObjectTypeFromIds[objectTypeOrId] : objectTypeOrId;
export const normalizeTypeId = objectTypeOrId => Object.prototype.hasOwnProperty.call(ObjectTypesToIds, objectTypeOrId) ? ObjectTypesToIds[objectTypeOrId] : objectTypeOrId;