'use es6';

import { ANALYTICS_CAMPAIGNS } from '../../constants/dataTypes';
import { List } from 'immutable';
export const configure = config => {
  const dataType = config.get('dataType');
  if (dataType !== ANALYTICS_CAMPAIGNS || ['breakdown', 'people', 'deals-influenced'].includes(config.getIn(['dimensions', 0]))) {
    return Promise.resolve(config);
  }
  let configured = config;
  configured = configured.has('dimensions') ? configured.update('dimensions', dimensions => dimensions.map(dimension => dimension === 'sessionDate' ? dimension : 'breakdown')) : configured.set('dimensions', List());
  if (configured.hasIn(['filters', 'custom'])) {
    configured = configured.updateIn(['filters', 'custom'], filters => filters && filters.map(filter => {
      switch (filter.get('property')) {
        case 'campaignId':
          return filter.set('property', configured.getIn(['dimensions', 0]) === 'breakdown' ? 'd1' : 'filters');
        default:
          return filter;
      }
    }));
  }
  return Promise.resolve(configured);
};