'use es6';

import { List } from 'immutable';
import getInboundDbPropertyGroups from '../../retrieve/inboundDb/common/properties';
import { CONTACT_CREATE_ATTRIBUTION } from '../../constants/dataTypes';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import countProperty from '../partial/count-property';
import { mergeProperties } from '../mergeProperties';
export const getPropertyGroups = () => getInboundDbPropertyGroups(CONTACT_CREATE_ATTRIBUTION).then(groups => List([...groups])).then(groups => mergeProperties(groups, 'attributioncampaigninformation', {
  hs_campaign_guid: {
    type: 'enumeration'
  },
  hs_cta_id: {
    type: 'enumeration',
    externalOptions: true,
    referencedObjectType: 'CTA'
  },
  hs_created_by_user_id: {
    type: 'enumeration',
    externalOptions: true,
    referencedObjectType: 'USER'
  },
  hs_updated_by_user_id: {
    type: 'enumeration',
    externalOptions: true,
    referencedObjectType: 'USER'
  }
}));

// TODO: Remove overrides once attribution property types are changed in the backend
export const getProperties = () => createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(CONTACT_CREATE_ATTRIBUTION)))();