'use es6';

export const ADD_CONFIG = '@@reporting/ADD_CONFIG';
export const addConfig = ({
  config
}) => ({
  type: ADD_CONFIG,
  data: {
    config
  }
});
export const BUST_ALL_RESOLVES = '@@reporting/BUST_ALL_RESOLVES';
export const bustResolves = () => ({
  type: BUST_ALL_RESOLVES
});
export const BUST_ONE_RESOLVE = '@@reporting/BUST_ONE_RESOLVE';
export const bustOneResolve = ({
  config
}) => ({
  type: BUST_ONE_RESOLVE,
  data: {
    config
  }
});
export const SET_CONFIGURED = '@@reporting/SET_CONFIGURED';
export const setConfigured = ({
  config,
  configured
}) => ({
  type: SET_CONFIGURED,
  data: {
    config,
    configured
  }
});
export const SET_PROMISE = '@@reporting/SET_PROMISE';
export const setPromise = ({
  config,
  promise
}) => ({
  type: SET_PROMISE,
  data: {
    config,
    promise
  }
});
export const SET_STATUS = '@@reporting/SET_STATUS';
export const setStatus = ({
  config,
  status,
  error
}) => ({
  type: SET_STATUS,
  data: {
    config,
    status,
    error
  }
});
export const SET_DATA = '@@reporting/SET_DATA';
export const setData = ({
  config,
  data
}) => ({
  type: SET_DATA,
  data: {
    config,
    data
  }
});
export const baseEnter = '@@reporting/ENTER_';
export const enterPhase = ({
  config,
  name,
  input
}) => ({
  type: `${baseEnter}${name}`,
  data: {
    config,
    name,
    input
  }
});
export const baseExit = '@@reporting/EXIT_';
export const exitPhase = ({
  config,
  name,
  output
}) => ({
  type: `${baseExit}${name}`,
  data: {
    config,
    name,
    output
  }
});
export const DEBUG = '@@reporting/DEBUG';
export const debug = ({
  config,
  key,
  value
}) => ({
  type: DEBUG,
  data: {
    config,
    key,
    value
  }
});