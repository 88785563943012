'use es6';

import { getAndCheck } from '../common/get-and-check-param';
import { createProblem } from '../common/problem';
import { REPORT_IS_SNOWFLAKE_TEMPLATE_EXCEPTION, HAS_CUSTOM_REPORTING_ACCESS, REPORT_IS_SNOWFLAKE } from '../params/all-params';

// Portals that have been downgraded from pro/enterprise tiers lose access
// to standard Snowflake report templates (barring any exception templates).
export const LACKS_SNOWFLAKE_TEMPLATE_ACCESS = createProblem('LACKS_SNOWFLAKE_TEMPLATE_ACCESS', params => {
  const isSnowflakeReport = getAndCheck(REPORT_IS_SNOWFLAKE, params);
  if (!isSnowflakeReport) {
    return false;
  }
  const isSnowflakeReportTemplateException = getAndCheck(REPORT_IS_SNOWFLAKE_TEMPLATE_EXCEPTION, params);
  if (!isSnowflakeReportTemplateException) {
    return !getAndCheck(HAS_CUSTOM_REPORTING_ACCESS, params);
  }
  return false;
});