import { BOOLEAN_TYPE, createUnionType, isBooleanLiteralType, isFunctionType, isGenericType, isLiteralType, isNullType, isNumericLiteralType, isPrimitiveType, isPropertyType, isStringLiteralType, isUndefinedType, isUnionType, NULLABLE_BOOLEAN_TYPE, NULLABLE_DATETIME_TYPE, NULLABLE_DATE_TYPE, NULLABLE_NUMBER_TYPE, NULLABLE_STRING_TYPE, NUMBER_TYPE, PropertyTypeNames, STRING_TYPE } from './type';
const getTypeOrder = type => {
  if (isUndefinedType(type)) return 5;
  if (isNullType(type)) return 4;
  if (isPropertyType(type)) return 3;
  if (isPrimitiveType(type)) return 2;
  if (isLiteralType(type)) return 1;
  return 6;
};
function typeComparator(typeA, typeB) {
  const orderA = getTypeOrder(typeA);
  const orderB = getTypeOrder(typeB);
  if (orderA < orderB) return -1;
  if (orderA > orderB) return 1;
  if (typeToString(typeA) < typeToString(typeB)) return -1;
  if (typeToString(typeA) > typeToString(typeB)) return 1;
  return 0;
}
export function typeToString(type) {
  if (isUndefinedType(type)) return type.type;
  if (isNullType(type)) return type.type;
  if (isBooleanLiteralType(type)) return type.value.toString();
  if (isNumericLiteralType(type)) return type.value.toString();
  if (isStringLiteralType(type)) return `"${type.value}"`;
  if (isPrimitiveType(type)) return type.type;
  if (isUnionType(type)) {
    return `(${type.types.sort(typeComparator).map(typeToString).join(' | ')})`;
  }
  if (isPropertyType(type)) return `Property<${type.propertyType}>`;
  if (isGenericType(type)) return `Generic<${type.name}>`;
  if (isFunctionType(type)) return `Function<${type.name}>`;
  throw new Error(`Unknown type: ${type}`);
}
export const equal = (typeA, typeB) => typeToString(typeA) === typeToString(typeB);
export const typeToMap = type => {
  const typeToArray = t => {
    if (isUnionType(t)) {
      return t.types.flatMap(typeToArray);
    }
    return [t];
  };
  const map = new Map();
  typeToArray(type).forEach(t => map.set(typeToString(t), t));
  return map;
};
export const flattenType = type => {
  const types = Array.from(typeToMap(type).values());
  return types.length === 1 ? types[0] : createUnionType(...types);
};
export const normalizeType = type => {
  const flattenedType = flattenType(type);
  return isUnionType(flattenedType) ? createUnionType(...flattenedType.types.sort(typeComparator)) : flattenedType;
};
export const widenType = type => {
  if (isUnionType(type)) {
    const typeMap = typeToMap(type);
    const types = Array.from(typeMap.values()).map(widenType);
    return flattenType(createUnionType(...types));
  }

  // Literal types widen to primitive types
  if (isBooleanLiteralType(type)) return BOOLEAN_TYPE;
  if (isNumericLiteralType(type)) return NUMBER_TYPE;
  if (isStringLiteralType(type)) return STRING_TYPE;

  // Property types widen to primitive types
  if (isPropertyType(type)) {
    switch (type.propertyType) {
      case PropertyTypeNames.number:
      case PropertyTypeNames.currency_number:
        return NULLABLE_NUMBER_TYPE;
      case PropertyTypeNames.string:
      case PropertyTypeNames.enumeration:
      case PropertyTypeNames.phone_number:
      case PropertyTypeNames.json:
      case PropertyTypeNames.object_coordinates:
        return NULLABLE_STRING_TYPE;
      case PropertyTypeNames.bool:
        return NULLABLE_BOOLEAN_TYPE;
      case PropertyTypeNames.date:
        return NULLABLE_DATE_TYPE;
      case PropertyTypeNames.datetime:
        return NULLABLE_DATETIME_TYPE;
      default:
        throw new Error(`Unknown property type: ${type.propertyType}`);
    }
  }
  return type;
};
export const check = (providedType, requiredType) => {
  const providedTypeMap = typeToMap(providedType);
  const requiredTypeMap = typeToMap(requiredType);
  return Array.from(providedTypeMap.entries()).every(([key, type]) => requiredTypeMap.has(key) || Array.from(typeToMap(widenType(type)).keys()).every(k => requiredTypeMap.has(k)));
};
export const expandFunctionSignature = (functionSignature, count) => {
  if (functionSignature.rest) {
    return Object.assign({}, functionSignature, {
      arguments: [...functionSignature.arguments, ...Array(Math.max(0, count - functionSignature.arguments.length)).fill(functionSignature.rest)]
    });
  }
  return functionSignature;
};