'use es6';

import { createProblem } from 'dashboard-lib/private/action-problem-solution/common/problem';
import { getAndCheck } from 'dashboard-lib/private/action-problem-solution/common/get-and-check-param';
import { CUSTOM_DASHBOARD_COUNT, CUSTOM_DASHBOARD_LIMIT } from 'dashboard-lib/private/action-problem-solution/params/all-params';
import { limitReachedOrExceeded } from 'dashboard-lib/private/action-problem-solution/common/limits';
export const CUSTOM_DASHBOARD_LIMIT_REACHED_OR_EXCEEDED = createProblem('CUSTOM_DASHBOARD_LIMIT_REACHED_OR_EXCEEDED', params => {
  const customDashboardCount = getAndCheck(CUSTOM_DASHBOARD_COUNT, params);
  const customDashboardLimit = getAndCheck(CUSTOM_DASHBOARD_LIMIT, params);
  return limitReachedOrExceeded(customDashboardCount, customDashboardLimit);
});