'use es6';

import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import { getChatflowsById, createGetChatflowsById } from 'reference-resolvers/api/ChatflowsAPI';
import { fetchSearchPage, createFetchSearchPage } from 'reference-resolvers/api/CrmSearchAPI';
import { CHATFLOW } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { getLabel, getArchived } from 'reference-resolvers/formatters/formatChatflows';
const searchOptions = {};
const searchQueryParams = {};
const formatterOptions = {
  getters: {
    getLabel,
    getArchived
  }
};
export const createChatflowsReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.CHATFLOW,
  createFetchByIds: createGetChatflowsById,
  fetchByIds: getChatflowsById,
  createFetchSearchPage: opts => createFetchSearchPage(opts)(CHATFLOW, {
    searchOptions,
    searchQueryParams,
    formatterOptions
  }),
  fetchSearchPage: fetchSearchPage(CHATFLOW, {
    searchOptions,
    searchQueryParams,
    formatterOptions
  })
}, options));
export default createChatflowsReferenceResolver();