'use es6';

import { Record } from 'immutable';
const DefaultNullValueRecord = Record({
  defaultComparisonValue: null,
  defaultValue: null,
  includeObjectsWithNoValueSet: undefined
}, 'DefaultNullValueRecord');
DefaultNullValueRecord.isDefined = record => record.defaultValue !== null || record.defaultComparisonValue !== null;
DefaultNullValueRecord.isDefaultNullValueRecord = record => record instanceof DefaultNullValueRecord;
export default DefaultNullValueRecord;