import { Metrics } from '../metrics';
import enviro from 'enviro';
export function createInMemoryCache({
  cacheName = 'unknown'
} = {}) {
  const __cache = new Map();
  const cache = {
    printDebug: () => {
      if (!enviro.deployed()) {
        console.log(cacheName, __cache);
      }
    },
    // Basic cache ops
    has: cacheKey => __cache.has(cacheKey),
    get: cacheKey => {
      const cacheValue = __cache.get(cacheKey);
      if (!cacheValue) {
        return null;
      }
      Metrics.counter('cache-hit', {
        cacheName
      }).increment();
      return cacheValue.value;
    },
    set: (cacheKey, value) => {
      __cache.set(cacheKey, {
        value
      });
      return true;
    },
    delete: cacheKey => __cache.delete(cacheKey),
    clear: () => __cache.clear(),
    // Complex ops composed from basic ones
    readThrough: ({
      cacheKey,
      refresh,
      loadValue
    }) => {
      if (!refresh && cache.has(cacheKey)) {
        return cache.get(cacheKey);
      }
      const value = loadValue();
      cache.set(cacheKey, value);
      return value;
    }
  };
  return cache;
}