import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
const SAVED_DASHBOARD_FILTERS_BASE_URL = 'dashboard/v2/filters'; // TODO may change to /v2/filters

export const createSavedDashboardFilterSet = data => {
  return http.post(SAVED_DASHBOARD_FILTERS_BASE_URL, {
    data: Object.assign({}, data)
  });
};
export const deleteSavedDashboardFilterSet = filterSetId => {
  return http.delete(`${SAVED_DASHBOARD_FILTERS_BASE_URL}/${filterSetId}`);
};
export const updateSavedDashboardFilterSet = (filterSetId, data) => {
  return http.put(`${SAVED_DASHBOARD_FILTERS_BASE_URL}/${filterSetId}`, {
    data: Object.assign({}, data, {
      portalId: PortalIdParser.get()
    })
  });
};
export const fetchSavedFilterById = filterSetId => {
  return http.get(`${SAVED_DASHBOARD_FILTERS_BASE_URL}/${filterSetId}`);
};
export const getGeneratedFilterSetFromLegacy = (dashboardId, queryString) => {
  return http.post(`${SAVED_DASHBOARD_FILTERS_BASE_URL}/generate`, {
    data: {
      dashboardId,
      queryString
    }
  });
};