'use es6';

import { List, is } from 'immutable';
import { Promise } from '../../lib/promise';
import { SUM } from '../../constants/metricTypes';
const PROPERTY = 'count';
const METRIC_TYPES = List.of(SUM);
export const configure = config => {
  const metrics = config.get('metrics') || List();
  const index = metrics.findIndex(metric => metric.get('property') === PROPERTY && !is(metric.get('metricTypes'), METRIC_TYPES));
  return Promise.resolve(index >= 0 ? config.updateIn(['metrics', index], metric => metric.set('metricTypes', METRIC_TYPES)) : config);
};