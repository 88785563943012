import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
export const filterEventsByProperty = (response, property, value) => Object.keys(response).reduce((accum, eventName) => {
  const eventMetadata = response[eventName].journeyAnalytics.metadata;
  const propertyExistsOnEvent = !!eventMetadata[property];
  if (propertyExistsOnEvent && eventMetadata[property].value === value) {
    return [...accum, eventName];
  }
  return accum;
}, []);
export const fetchEvents = appSettingNames => getFrameworkDataSchemasClient().then(client => client.typeMetadata.get({
  appSettingNames
})).catch(err => {
  console.error(err);
});
export const getEventsByProperty = (appSettingNames, property, value) => fetchEvents(appSettingNames).then(response => filterEventsByProperty(response, property, value));