'use es6';

import * as DSAssetFamilies from 'customer-data-filters/filterQueryFormat/DSAssetFamilies/DSAssetFamilies';
export const ASSET_TYPE_TOKENS = {
  [DSAssetFamilies.CTA]: 'CTA',
  [DSAssetFamilies.FORM]: 'FORM',
  [DSAssetFamilies.EMAIL_CAMPAIGN]: 'EMAIL_CAMPAIGN',
  [DSAssetFamilies.EVENT]: 'EVENT',
  [DSAssetFamilies.IMPORT]: 'IMPORT',
  [DSAssetFamilies.IN_LIST]: 'IN_LIST',
  [DSAssetFamilies.WORKFLOW]: 'WORKFLOW'
};