import { Set as ImmutableSet } from 'immutable';
import * as checked from 'reporting-data/lib/checked';
export const TypeNames = {
  UNDEFINED: 'UNDEFINED',
  NULL: 'NULL',
  // Literals
  NUMERIC_LITERAL: 'NUMERIC_LITERAL',
  STRING_LITERAL: 'STRING_LITERAL',
  BOOLEAN_LITERAL: 'BOOLEAN_LITERAL',
  // Primitives
  NUMBER: 'NUMBER',
  STRING: 'STRING',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  DATETIME: 'DATETIME',
  // Property
  PROPERTY: 'PROPERTY',
  // Others
  UNION: 'UNION',
  GENERIC: 'GENERIC',
  FUNCTION: 'FUNCTION'
};
export const PropertyTypeNames = {
  number: 'number',
  string: 'string',
  bool: 'bool',
  enumeration: 'enumeration',
  datetime: 'datetime',
  date: 'date',
  phone_number: 'phone_number',
  currency_number: 'currency_number',
  json: 'json',
  object_coordinates: 'object_coordinates'
};
export const TypeName = checked.symbol(TypeNames, 'TypeName');
const createLiteralType = (type, name, valueType) => checked.record({
  type: TypeName.always(type).defaultValue(type),
  value: valueType
}, name);
const createPrimitiveType = (type, name) => checked.record({
  type: TypeName.always(type).defaultValue(type)
}, name);
export const UndefinedType = createLiteralType(TypeNames.UNDEFINED, 'UndefinedType', checked.any().always(undefined));
export const NullType = createLiteralType(TypeNames.NULL, 'NullType', checked.any().always(null).defaultValue(null));
export const NumericLiteralType = createLiteralType(TypeNames.NUMERIC_LITERAL, 'NumericLiteralType', checked.number());
export const StringLiteralType = createLiteralType(TypeNames.STRING_LITERAL, 'StringLiteralType', checked.string());
export const BooleanLiteralType = createLiteralType(TypeNames.BOOLEAN_LITERAL, 'BooleanLiteralType', checked.boolean());
export const NumberType = createPrimitiveType(TypeNames.NUMBER, 'NumberType');
export const StringType = createPrimitiveType(TypeNames.STRING, 'StringType');
export const BooleanType = createPrimitiveType(TypeNames.BOOLEAN, 'BooleanType');
export const DateType = createPrimitiveType(TypeNames.DATE, 'DateType');
export const DatetimeType = createPrimitiveType(TypeNames.DATETIME, 'DatetimeType');
export const PropertyType = checked.record({
  type: TypeName.always(TypeNames.PROPERTY).defaultValue(TypeNames.PROPERTY),
  propertyType: checked.symbol(PropertyTypeNames)
}, 'PropertyType');
export const GenericType = checked.record({
  type: TypeName.always(TypeNames.GENERIC).defaultValue(TypeNames.GENERIC),
  name: checked.string()
}, 'GenericType');
const createRecursiveTypes = Type => {
  const UnionType = checked.record({
    type: TypeName.always(TypeNames.UNION).defaultValue(TypeNames.UNION),
    types: checked.list(Type)
  }, 'UnionType');
  const FunctionType = checked.record({
    type: TypeName.always(TypeNames.FUNCTION).defaultValue(TypeNames.FUNCTION),
    signatures: checked.list(checked.record({
      arguments: checked.list(Type).defaultValue([]),
      rest: Type.optional(),
      returns: Type
    }, 'FunctionSignature')),
    isAggregation: checked.boolean().defaultValue(false)
  }, 'FunctionType');
  return {
    UnionType,
    FunctionType
  };
};
const _Type = checked.recursive(__Type => {
  const {
    UnionType,
    FunctionType
  } = createRecursiveTypes(__Type);
  return checked.poly('type', {
    [TypeNames.UNDEFINED]: UndefinedType,
    [TypeNames.NULL]: NullType,
    [TypeNames.NUMERIC_LITERAL]: NumericLiteralType,
    [TypeNames.STRING_LITERAL]: StringLiteralType,
    [TypeNames.BOOLEAN_LITERAL]: BooleanLiteralType,
    [TypeNames.NUMBER]: NumberType,
    [TypeNames.STRING]: StringType,
    [TypeNames.BOOLEAN]: BooleanType,
    [TypeNames.DATE]: DateType,
    [TypeNames.DATETIME]: DatetimeType,
    [TypeNames.PROPERTY]: PropertyType,
    [TypeNames.UNION]: UnionType,
    [TypeNames.GENERIC]: GenericType,
    [TypeNames.FUNCTION]: FunctionType
  }, 'Type');
});
const {
  UnionType: _UnionType,
  FunctionType: _FunctionType
} = createRecursiveTypes(_Type);
export const UnionType = _UnionType;
export const FunctionType = _FunctionType;
export const Type = _Type;
export const Value = checked.record({
  type: Type,
  value: checked.any()
}, 'Value');

// Scope is defined as a named map of identifier names to a value
export const Scope = checked.map(Value, 'Scope');
export const UNDEFINED_TYPE = UndefinedType({});
export const NULL_TYPE = NullType({});
export const createNumericType = value => NumericLiteralType({
  value
});
export const createStringType = value => StringLiteralType({
  value
});
export const createBooleanType = value => BooleanLiteralType({
  value
});
export const createPropertyType = propertyType => PropertyType({
  propertyType
});
export const createUnionType = (...types) => UnionType({
  types: ImmutableSet(types).toList()
});
export const createGenericType = name => GenericType({
  name
});
export const createFunctionType = (...signatures) => FunctionType({
  signatures
});
export const createAggregationFunctionType = (...signatures) => createFunctionType(...signatures).set('isAggregation', true);
export const toOptionalType = type => createUnionType(type, UNDEFINED_TYPE);
export const toNullableType = type => createUnionType(type, NULL_TYPE);
export const isUndefinedType = type => type.type === TypeNames.UNDEFINED;
export const isNullType = type => type.type === TypeNames.NULL;
export const isLiteralType = type => {
  switch (type.type) {
    case TypeNames.NUMERIC_LITERAL:
    case TypeNames.STRING_LITERAL:
    case TypeNames.BOOLEAN_LITERAL:
      return true;
    default:
      return false;
  }
};
export const isPrimitiveType = type => {
  switch (type.type) {
    case TypeNames.NUMBER:
    case TypeNames.STRING:
    case TypeNames.BOOLEAN:
    case TypeNames.DATE:
    case TypeNames.DATETIME:
      return true;
    default:
      return false;
  }
};
export const isPropertyType = type => type.type === TypeNames.PROPERTY;
export const isUnionType = type => type.type === TypeNames.UNION;
export const isGenericType = type => type.type === TypeNames.GENERIC;
export const isFunctionType = type => type.type === TypeNames.FUNCTION;
export const NUMBER_TYPE = NumberType({});
export const STRING_TYPE = StringType({});
export const BOOLEAN_TYPE = BooleanType({});
export const DATE_TYPE = DateType({});
export const DATETIME_TYPE = DatetimeType({});
export const NULLABLE_NUMBER_TYPE = toNullableType(NUMBER_TYPE);
export const NULLABLE_STRING_TYPE = toNullableType(STRING_TYPE);
export const NULLABLE_BOOLEAN_TYPE = toNullableType(BOOLEAN_TYPE);
export const NULLABLE_DATE_TYPE = toNullableType(DATE_TYPE);
export const NULLABLE_DATETIME_TYPE = toNullableType(DATETIME_TYPE);
export const PROPERTY_TYPES = {
  NUMBER: createPropertyType(PropertyTypeNames.number),
  STRING: createPropertyType(PropertyTypeNames.string),
  BOOL: createPropertyType(PropertyTypeNames.bool),
  ENUMERATION: createPropertyType(PropertyTypeNames.enumeration),
  DATE: createPropertyType(PropertyTypeNames.date),
  DATETIME: createPropertyType(PropertyTypeNames.datetime),
  PHONE_NUMBER: createPropertyType(PropertyTypeNames.phone_number),
  CURRENCY_NUMBER: createPropertyType(PropertyTypeNames.currency_number),
  JSON: createPropertyType(PropertyTypeNames.json),
  OBJECT_COORDINATES: createPropertyType(PropertyTypeNames.object_coordinates)
};