'use es6';

import { Record } from 'immutable';
import get from 'transmute/get';
import { isNil, isFunction } from '../../js-util';
const Param = Record({
  id: null,
  type: null
}, 'Param');
export const getId = get('id');
export const getType = get('type');
export const createParam = (id, type) => {
  if (isNil(id) || !isFunction(type)) {
    console.warn('createParam: invalid parameters');
  }
  const toReturn = Param({
    id,
    type
  });
  toReturn.toString = () => id;
  return toReturn;
};