import { Record } from 'immutable';

// TODO: Shadow type, real type is defined in snowflake-report that'd create a circular dependency

const defaultValues = {
  id: undefined,
  label: undefined,
  link: undefined
};
export const Reference = Record(defaultValues);
export class AsyncQueryError extends Error {}
export class DeprecatedDataSourcesError extends Error {}
export class MaximumColumnsCountExceededError extends Error {}

// error subclasses
export class AsyncQueryCancelled extends AsyncQueryError {}
export class AsyncQueryFailed extends AsyncQueryError {}
export class AsyncQueryTimeout extends AsyncQueryError {}