'use es6';

const calculated = (breakdown = {}) => {
  const {
    submissions = 0,
    formViews = 0,
    visibles = 0,
    submissionsPerFormView
  } = breakdown;
  return Object.assign({}, breakdown, {
    submissionsPerFormView: submissionsPerFormView <= 1 ? submissionsPerFormView : undefined,
    conversionRate: submissions && formViews ? submissions / formViews : 0,
    clickThroughRate: visibles && formViews ? visibles / formViews : 0,
    formConversionRate: submissions && visibles ? submissions / visibles : 0
  });
};
const breakdown = response => Object.assign({}, response, {
  totals: calculated(response.totals || {}),
  breakdowns: (response.breakdowns || []).map(calculated)
});
const points = response => Object.keys(response).reduce((recalculated, date) => Object.assign({}, recalculated, {
  [date]: response[date].map(calculated)
}), {});
export const preprocess = (spec, config) => response => {
  const {
    dimensions: [primary] = []
  } = config;
  return primary === 'sessionDate' ? points(response) : breakdown(response);
};