import * as checked from 'reporting-data/lib/checked';
export const ExpressionTypes = {
  // Literals
  NULL_LITERAL: 'NULL_LITERAL',
  BOOLEAN_LITERAL: 'BOOLEAN_LITERAL',
  NUMERIC_LITERAL: 'NUMERIC_LITERAL',
  STRING_LITERAL: 'STRING_LITERAL',
  // References
  IDENTIFIER: 'IDENTIFIER',
  PROPERTY: 'PROPERTY',
  FIELD: 'FIELD',
  // Functions
  FUNCTION_CALL: 'FUNCTION_CALL',
  // Context
  AGGREGATION_CONTEXT: 'AGGREGATION_CONTEXT',
  // Unary Operators
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
  NOT: 'NOT',
  // Binary Operators
  ADD: 'ADD',
  SUBTRACT: 'SUBTRACT',
  MULTIPLY: 'MULTIPLY',
  DIVIDE: 'DIVIDE',
  MODULUS: 'MODULUS',
  GREATER: 'GREATER',
  GREATER_OR_EQUAL: 'GREATER_OR_EQUAL',
  LESS: 'LESS',
  LESS_OR_EQUAL: 'LESS_OR_EQUAL',
  EQUAL: 'EQUAL',
  NOT_EQUAL: 'NOT_EQUAL',
  AND: 'AND',
  OR: 'OR'
};
export const EXPRESSION_OPERATORS = [ExpressionTypes.POSITIVE, ExpressionTypes.NEGATIVE, ExpressionTypes.NOT, ExpressionTypes.ADD, ExpressionTypes.SUBTRACT, ExpressionTypes.MULTIPLY, ExpressionTypes.DIVIDE, ExpressionTypes.MODULUS, ExpressionTypes.GREATER, ExpressionTypes.GREATER_OR_EQUAL, ExpressionTypes.LESS, ExpressionTypes.LESS_OR_EQUAL, ExpressionTypes.EQUAL, ExpressionTypes.NOT_EQUAL, ExpressionTypes.AND, ExpressionTypes.OR];
export const Location = checked.record({
  start: checked.record({
    offset: checked.number(),
    line: checked.number(),
    // 1-indexed
    column: checked.number() // 1-indexed
  }, 'Start'),
  end: checked.record({
    offset: checked.number(),
    line: checked.number(),
    // 1-indexed
    column: checked.number() // 1-indexed
  }, 'End')
}, 'Location');
export const ExpressionType = checked.symbol(ExpressionTypes, 'ExpressionType');
export const NullLiteral = checked.record({
  type: ExpressionType.always(ExpressionTypes.NULL_LITERAL).defaultValue(ExpressionTypes.NULL_LITERAL),
  value: checked.any().always(null).defaultValue(null),
  location: Location.optional()
}, 'NullLiteral');
export const BooleanLiteral = checked.record({
  type: ExpressionType.always(ExpressionTypes.BOOLEAN_LITERAL).defaultValue(ExpressionTypes.BOOLEAN_LITERAL),
  value: checked.boolean(),
  location: Location.optional()
}, 'BooleanLiteral');
export const StringLiteral = checked.record({
  type: ExpressionType.always(ExpressionTypes.STRING_LITERAL).defaultValue(ExpressionTypes.STRING_LITERAL),
  value: checked.string(),
  location: Location.optional()
}, 'StringLiteral');
export const NumericLiteral = checked.record({
  type: ExpressionType.always(ExpressionTypes.NUMERIC_LITERAL).defaultValue(ExpressionTypes.NUMERIC_LITERAL),
  value: checked.number(),
  location: Location.optional()
}, 'NumericLiteral');
export const Identifier = checked.record({
  type: ExpressionType.always(ExpressionTypes.IDENTIFIER).defaultValue(ExpressionTypes.IDENTIFIER),
  name: checked.string(),
  location: Location.optional()
}, 'Identifier');
export const Property = checked.record({
  type: ExpressionType.always(ExpressionTypes.PROPERTY).defaultValue(ExpressionTypes.PROPERTY),
  table: checked.string(),
  propertyType: checked.string().optional(),
  name: checked.string(),
  location: Location.optional()
}, 'Property');
export const Field = checked.record({
  type: ExpressionType.always(ExpressionTypes.FIELD).defaultValue(ExpressionTypes.FIELD),
  name: checked.string(),
  location: Location.optional()
}, 'Field');
const createFunctionCallRecord = expressionRecord => checked.record({
  type: ExpressionType.always(ExpressionTypes.FUNCTION_CALL).defaultValue(ExpressionTypes.FUNCTION_CALL),
  name: checked.string(),
  arguments: checked.list(expressionRecord),
  location: Location.optional(),
  identifierLocation: Location.optional()
}, 'FunctionCall');
const createAggregationContextTypeRecord = expressionRecord => checked.record({
  type: ExpressionType.always(ExpressionTypes.AGGREGATION_CONTEXT).defaultValue(ExpressionTypes.AGGREGATION_CONTEXT),
  dimensions: checked.list(expressionRecord),
  expression: expressionRecord,
  location: Location.optional()
}, 'AggregationContext');
const createUnaryRecord = (expressionRecord, name, type, operator) => checked.record({
  type: ExpressionType.always(type).defaultValue(type),
  operator: checked.string().always(operator).defaultValue(operator),
  argument: expressionRecord,
  location: Location.optional()
}, name);
const createBinaryRecord = (expressionRecord, name, type, operator) => checked.record({
  type: ExpressionType.always(type).defaultValue(type),
  operator: checked.string().always(operator).defaultValue(operator),
  left: expressionRecord,
  right: expressionRecord,
  location: Location.optional()
}, name);
const createRecursiveTypes = r => ({
  FunctionCall: createFunctionCallRecord(r),
  AggregationContext: createAggregationContextTypeRecord(r),
  Positive: createUnaryRecord(r, 'Positive', ExpressionTypes.POSITIVE, '+'),
  Negative: createUnaryRecord(r, 'Negative', ExpressionTypes.NEGATIVE, '-'),
  Not: createUnaryRecord(r, 'Not', ExpressionTypes.NOT, '!'),
  Add: createBinaryRecord(r, 'Add', ExpressionTypes.ADD, '+'),
  Subtract: createBinaryRecord(r, 'Subtract', ExpressionTypes.SUBTRACT, '-'),
  Multiply: createBinaryRecord(r, 'Multiply', ExpressionTypes.MULTIPLY, '*'),
  Divide: createBinaryRecord(r, 'Divide', ExpressionTypes.DIVIDE, '/'),
  Modulus: createBinaryRecord(r, 'Modulus', ExpressionTypes.MODULUS, '%'),
  Greater: createBinaryRecord(r, 'Greater', ExpressionTypes.GREATER, '>'),
  GreaterOrEqual: createBinaryRecord(r, 'GreaterOrEqual', ExpressionTypes.GREATER_OR_EQUAL, '>='),
  Less: createBinaryRecord(r, 'Less', ExpressionTypes.LESS, '<'),
  LessOrEqual: createBinaryRecord(r, 'LessOrEqual', ExpressionTypes.LESS_OR_EQUAL, '<='),
  Equal: createBinaryRecord(r, 'Equal', ExpressionTypes.EQUAL, '=='),
  NotEqual: createBinaryRecord(r, 'NotEqual', ExpressionTypes.NOT_EQUAL, '!='),
  And: createBinaryRecord(r, 'And', ExpressionTypes.AND, '&&'),
  Or: createBinaryRecord(r, 'Or', ExpressionTypes.OR, '||')
});
const _Expression = checked.recursive(e => {
  const recursiveTypes = createRecursiveTypes(e);
  return checked.poly('type', {
    // Literals
    [ExpressionTypes.NULL_LITERAL]: NullLiteral,
    [ExpressionTypes.BOOLEAN_LITERAL]: BooleanLiteral,
    [ExpressionTypes.STRING_LITERAL]: StringLiteral,
    [ExpressionTypes.NUMERIC_LITERAL]: NumericLiteral,
    // References
    [ExpressionTypes.IDENTIFIER]: Identifier,
    [ExpressionTypes.PROPERTY]: Property,
    [ExpressionTypes.FIELD]: Field,
    // Functions
    [ExpressionTypes.FUNCTION_CALL]: recursiveTypes.FunctionCall,
    // Context
    [ExpressionTypes.AGGREGATION_CONTEXT]: recursiveTypes.AggregationContext,
    // Unary Operators
    [ExpressionTypes.POSITIVE]: recursiveTypes.Positive,
    [ExpressionTypes.NEGATIVE]: recursiveTypes.Negative,
    [ExpressionTypes.NOT]: recursiveTypes.Not,
    // Binary Operators
    [ExpressionTypes.ADD]: recursiveTypes.Add,
    [ExpressionTypes.SUBTRACT]: recursiveTypes.Subtract,
    [ExpressionTypes.MULTIPLY]: recursiveTypes.Multiply,
    [ExpressionTypes.DIVIDE]: recursiveTypes.Divide,
    [ExpressionTypes.MODULUS]: recursiveTypes.Modulus,
    [ExpressionTypes.GREATER]: recursiveTypes.Greater,
    [ExpressionTypes.GREATER_OR_EQUAL]: recursiveTypes.GreaterOrEqual,
    [ExpressionTypes.LESS]: recursiveTypes.Less,
    [ExpressionTypes.LESS_OR_EQUAL]: recursiveTypes.LessOrEqual,
    [ExpressionTypes.EQUAL]: recursiveTypes.Equal,
    [ExpressionTypes.NOT_EQUAL]: recursiveTypes.NotEqual,
    [ExpressionTypes.AND]: recursiveTypes.And,
    [ExpressionTypes.OR]: recursiveTypes.Or
  }, 'Expression');
});
const {
  FunctionCall: _FunctionCall,
  AggregationContext: _AggregationContext,
  Positive: _Positive,
  Negative: _Negative,
  Not: _Not,
  Add: _Add,
  Subtract: _Subtract,
  Multiply: _Multiply,
  Divide: _Divide,
  Modulus: _Modulus,
  Greater: _Greater,
  GreaterOrEqual: _GreaterOrEqual,
  Less: _Less,
  LessOrEqual: _LessOrEqual,
  Equal: _Equal,
  NotEqual: _NotEqual,
  And: _And,
  Or: _Or
} = createRecursiveTypes(_Expression);
export const FunctionCall = _FunctionCall;
export const AggregationContext = _AggregationContext;
export const Positive = _Positive;
export const Negative = _Negative;
export const Not = _Not;
export const Add = _Add;
export const Subtract = _Subtract;
export const Multiply = _Multiply;
export const Divide = _Divide;
export const Modulus = _Modulus;
export const Greater = _Greater;
export const GreaterOrEqual = _GreaterOrEqual;
export const Less = _Less;
export const LessOrEqual = _LessOrEqual;
export const Equal = _Equal;
export const NotEqual = _NotEqual;
export const And = _And;
export const Or = _Or;
export const Expression = _Expression;