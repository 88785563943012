'use es6';

import SlackAPI from './SlackAPI';
class IntegrationConnectionResolver {
  static isConnected() {
    return SlackAPI.getIntegrationConnectionStatus().then(integrationStatusResponse => {
      const connectionStatus = integrationStatusResponse.integrationStatus;
      return this.CONNECTED_STATUSES.includes(connectionStatus);
    }).catch(error => {
      console.error(error);
      return false;
    });
  }
}
IntegrationConnectionResolver.CONNECTED_STATUSES = ['CONNECTED', 'THIRD_PARTY_MISSING_SCOPE_ERROR'];
export default IntegrationConnectionResolver;