const chunk = (list, size) => {
  const chunks = [];
  let start = 0;
  let chunkIdx = 0;
  do {
    chunks[chunkIdx++] = list.slice(start, start + size);
    start += size;
  } while (start < list.length);
  return chunks;
};
export default chunk;