'use es6';

import { anyNumberIsLoading } from './any-number-is-loading';
export const FAVORITE_ASSET_LIMIT = 10;

/**
 * This is optimistic.
 * If any value is unknown, the fallback is: **have not reached the limit**.
 */
export function limitReached(count, limit) {
  return !anyNumberIsLoading(count, limit) && count === limit;
}

/**
 * This is optimistic.
 * If any value is unknown, the fallback is: **have not exceeded the limit**.
 */
export function limitExceeded(count, limit) {
  return !anyNumberIsLoading(count, limit) && count > limit;
}

/**
 * This is optimistic.
 * If any value is unknown, the fallback is: **have not reached or exceeded the limit**.
 */
export function limitReachedOrExceeded(count, limit) {
  return limitReached(count, limit) || limitExceeded(count, limit);
}