import * as checked from 'reporting-data/lib/checked';
import { Encoding } from '../column-records';
import { showRecordIds } from '../highcharts-settings';
import { VisualTypes } from '../constants/visual-constants';
export const TableEncodings = checked.record({
  columns: checked.list(Encoding).optional()
}, 'TableEncodings');
export const TableVisualOptions = checked.record({
  showTotals: checked.boolean().defaultValue(true),
  showRecordIds
}, 'TableVisualOptions');
export const TableVisual = checked.record({
  type: checked.string().always(VisualTypes.TABLE),
  encodings: TableEncodings,
  options: TableVisualOptions.defaultValue({})
}, 'TableVisual');