'use es6';

import http from 'hub-http/clients/apiClient';
import curry from 'transmute/curry';
import get from 'transmute/get';
import map from 'transmute/map';
import indexBy from 'transmute/indexBy';
import memoize from 'transmute/memoize';
import { formatToReferencesList } from 'reference-resolvers/lib/formatReferences';
import formatProperties from '../formatters/formatProperties';
import formatPropertyGroups from '../formatters/formatPropertyGroups';
const BASE_URI = 'properties/v4';
const propertyGetters = {
  getId: get('name'),
  getLabel: get('label'),
  getDescription: get('description')
};
const indexById = indexBy(get('id'));
const getResults = get('results');
const getProperty = get('property');
const mapToProperties = map(getProperty);
const toAllPropertiesUrl = memoize(objectTypeId => `${BASE_URI}/${objectTypeId}`);
const toMultiGetPropertiesUrl = memoize(objectTypeId => `${BASE_URI}/${objectTypeId}/multi-get`);
const toMultiGetResultsList = curry((names, response) => names.map(name => response[name]));
const makeFetcher = (httpClient, objectType) => () => httpClient.get(`${BASE_URI}/groups/${objectType}?includeFieldLevelPermission=true&includeProperties=true`).then(getResults).then(formatProperties);
export const createGetContactProperties = ({
  httpClient
}) => makeFetcher(httpClient, 'CONTACT');
export const getContactProperties = createGetContactProperties({
  httpClient: http
});
export const createGetConversationProperties = ({
  httpClient
}) => makeFetcher(httpClient, 'CONVERSATION');
export const getConversationProperties = createGetConversationProperties({
  httpClient: http
});
export const createGetCompanyProperties = ({
  httpClient
}) => makeFetcher(httpClient, 'COMPANY');
export const getCompanyProperties = createGetCompanyProperties({
  httpClient: http
});
export const createGetDealProperties = ({
  httpClient
}) => makeFetcher(httpClient, 'DEAL');
export const getDealProperties = createGetDealProperties({
  httpClient: http
});
export const createGetInvoiceProperties = ({
  httpClient
}) => makeFetcher(httpClient, 'INVOICE');
export const getInvoiceProperties = createGetInvoiceProperties({
  httpClient: http
});
export const createGetTicketProperties = ({
  httpClient
}) => makeFetcher(httpClient, 'TICKET');
export const getTicketProperties = createGetTicketProperties({
  httpClient: http
});
export const createGetLineItemProperties = ({
  httpClient
}) => makeFetcher(httpClient, 'LINE_ITEM');
export const getLineItemProperties = createGetLineItemProperties({
  httpClient: http
});
export const createGetProductProperties = ({
  httpClient
}) => makeFetcher(httpClient, 'PRODUCT');
export const getProductProperties = createGetProductProperties({
  httpClient: http
});
export const createGetQuoteProperties = ({
  httpClient
}) => makeFetcher(httpClient, 'QUOTE');
export const getQuoteProperties = createGetQuoteProperties({
  httpClient: http
});
export const createGetPropertyGroupsByObjectType = ({
  httpClient
}) => ({
  queryParams
} = {}) => objectTypes => {
  const promises = objectTypes.map(objectType => httpClient.get(`${BASE_URI}/groups/${objectType}`, {
    query: queryParams
  }).then(getResults).then(results => ({
    objectType: objectType.toUpperCase(),
    results
  })));
  return Promise.all(promises).then(formatPropertyGroups);
};
export const getPropertyGroupsByObjectType = createGetPropertyGroupsByObjectType({
  httpClient: http
})();
export const createGetPropertyGroupsByObjectTypeId = ({
  httpClient
}) => (objectTypeId, {
  queryParams
} = {}) => {
  const getFn = createGetPropertyGroupsByObjectType({
    httpClient
  })({
    queryParams: Object.assign({
      includeProperties: true
    }, queryParams)
  });
  return () => getFn([objectTypeId]);
};
export const getPropertyGroupsByObjectTypeId = createGetPropertyGroupsByObjectTypeId({
  httpClient: http
});
export const createGetAllProperties = ({
  httpClient
}) => (objectTypeId, {
  queryParams
} = {}) => () => httpClient.get(toAllPropertiesUrl(objectTypeId), {
  query: queryParams
}).then(mapToProperties).then(formatToReferencesList(propertyGetters)).then(indexById);
export const getAllProperties = createGetAllProperties({
  httpClient: http
});
export const createGetProperties = ({
  httpClient
}) => curry((objectTypeId, {
  queryParams
}, names) => httpClient.post(toMultiGetPropertiesUrl(objectTypeId), {
  query: queryParams,
  data: names
}).then(toMultiGetResultsList(names)).then(mapToProperties).then(formatToReferencesList(propertyGetters)));
export const getProperties = createGetProperties({
  httpClient: http
});