import { stringify } from 'hub-http/helpers/params';
import compose from 'transmute/compose';
import curry from 'transmute/curry';
import getIn from 'transmute/getIn';
import { parseQueryString } from './queryUtils';

/**
 * @param history {object} - history object (react-router v4)
 * @returns {string} - query string
 */
export const getQueryString = getIn(['location', 'search']);

/**
 * @param history {object} - history object (react-router v4)
 * @returns {object} - a key-value map of the query
 */
export const parseAsQueryMap = compose(parseQueryString, getQueryString);
export const setQuery = curry((newQuery, history) => {
  history.replace({
    search: stringify(newQuery)
  });
});

/**
 * @param {Object} - history object from react router
 * @returns the relative path name from the given history object
 * @example
 *
 *      getPathname(history);       //=> "/view/2613682"
 */
export const getPathname = getIn(['location', 'pathname']);