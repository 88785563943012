'use es6';

import I18n from 'I18n';
import { useDashboards } from './useDashboards';
import { useRecentlyViewedDashboards } from './useRecentlyViewedDashboards';
import useUserInfo from '../../access/useUserInfo';
import { MARIGOLD } from 'HubStyleTokens/colors';
const TRANSPARENT = 'rgba(0,0,0,0)';
const sortOptions = optionsArray => {
  return optionsArray.sort((a, b) => {
    if (a.text.toLowerCase() < b.text.toLowerCase()) {
      return -1;
    }
    if (a.text.toLowerCase() > b.text.toLowerCase()) {
      return 1;
    }
    return 0;
  });
};
const dashboardOptions = {
  data: [],
  error: null,
  loading: true
};
const ENCODING_KEY = '_dashboard_group_';
const DASHBOARD_OPTION_GROUPS = {
  RECENTS: 'recentlyViewed',
  MY_DASHBOARDS: 'myDashboards',
  OTHER_DASHBOARDS: 'otherDashboards'
};
export const getEncodedOptionValue = (group, dashboardId) => `${group}${ENCODING_KEY}${dashboardId}`;
const getDashboardOptionObject = ({
  group,
  dashboard
}) => {
  const dashboardId = group === DASHBOARD_OPTION_GROUPS.RECENTS ? dashboard.objectId : dashboard.id;
  return {
    text: dashboard.name,
    // Encode the value with the group name because a recently viewed dashboard
    // can exist in both the recently viewed array and one of "My dashboards" or "Other dashboards"
    // The encoding ensures that each value in the select list is unique, and hover behaviors are maintained
    value: getEncodedOptionValue(group, dashboardId),
    icon: 'favorite',
    iconColor: dashboard.favorite ? MARIGOLD : TRANSPARENT
  };
};
export const useDashboardSearchOptions = () => {
  const dashboards = useDashboards();
  const recentlyViewedDashboards = useRecentlyViewedDashboards();
  const userInfo = useUserInfo();
  const errorArr = [dashboards.error, recentlyViewedDashboards.error];
  const errorState = errorArr.find(query => query);
  dashboardOptions.loading = dashboards.loading || userInfo.loading || recentlyViewedDashboards.loading;
  if (errorState) {
    dashboardOptions.error = errorState;
    console.error(errorState);
    return dashboardOptions;
  }
  if (dashboardOptions.loading) {
    return dashboardOptions;
  }
  const currentUserId = userInfo.data.getIn(['user', 'user_id']);
  const dashboardsArr = Object.values(dashboards.data.get('dashboards').toJS());
  const recentlyViewedDashboardsArr = recentlyViewedDashboards.data.toJS().map(dashboard => getDashboardOptionObject({
    group: DASHBOARD_OPTION_GROUPS.RECENTS,
    dashboard
  }));
  const myDashboardsArr = dashboardsArr.filter(dashboard => dashboard.ownerId === currentUserId).map(dashboard => getDashboardOptionObject({
    group: DASHBOARD_OPTION_GROUPS.MY_DASHBOARDS,
    dashboard
  }));
  const otherDashboardsArr = dashboardsArr.filter(dashboard => dashboard.ownerId !== currentUserId).map(dashboard => getDashboardOptionObject({
    group: DASHBOARD_OPTION_GROUPS.OTHER_DASHBOARDS,
    dashboard
  }));
  dashboardOptions.data = [{
    text: I18n.text('reporting-action-components.dashboards.search.categories.recentlyViewed'),
    options: sortOptions(recentlyViewedDashboardsArr)
  }, {
    text: I18n.text('reporting-action-components.dashboards.search.categories.myDashboards'),
    options: sortOptions(myDashboardsArr)
  }, {
    text: I18n.text('reporting-action-components.dashboards.search.categories.otherDashboards'),
    options: sortOptions(otherDashboardsArr)
  }];
  return dashboardOptions;
};

// Derives the dashboard id from the grouped dashboard value
// e.g. given "recentlyViewed_dashboard_group_123", returns 123
export const decodeSelectGroupedDashboardValue = selectedValue => {
  const dashboardId = selectedValue.split(ENCODING_KEY)[1];
  if (!isNaN(dashboardId)) {
    return Number(dashboardId);
  } else {
    return undefined;
  }
};