'use es6';

// gets a set of keys in which two immutable objects have different values
export const getImmutableDifferences = (a, b) => {
  if (!b || !b.toJS) {
    return a.keySeq().toSet();
  }
  const missingKeys = b.keySeq().toSet().filter(key => !a.has(key));
  return a.reduce((acc, val, key) => {
    const bVal = b && b.get && b.get(key);
    if (val && val.toJS) {
      return acc.concat(getImmutableDifferences(val, bVal).map(subkey => `${key}.${subkey}`));
    }
    return val === bVal ? acc : acc.add(key);
  }, missingKeys);
};