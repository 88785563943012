import { ENUMERATION } from 'reporting-data/constants/property-types';
import { FIELDS, FILTERED_ENTITIES, HS_OBJECT_ID } from '../../constants/fields';
const generateField = table => ({
  name: 'objectid',
  table,
  source: 'TABLE',
  type: ENUMERATION
});
export const generateDrilldownMeasure = (row, selectedField = FIELDS.COUNT) => {
  const basicDrilldownMeasure = {
    alias: HS_OBJECT_ID,
    domain: 'DISCRETE',
    field: generateField(row.to),
    role: 'MEASURE'
  };

  // TODO: fix this if/when 1st stages get multiple steps or isOptional functionality
  if (selectedField === FIELDS.COUNT && row.from && row.from !== FILTERED_ENTITIES) {
    return Object.assign({}, basicDrilldownMeasure, {
      fromField: generateField(row.from)
    });
  }
  return basicDrilldownMeasure;
};
export const reportHasDrilldownMeasure = report => !!report.journeyQuery.measure && report.journeyQuery.measure.alias === HS_OBJECT_ID;