'use es6';

import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { InboundDbModule } from '../../module';
import { SOCIAL_POSTS } from '../../constants/dataTypes';
import { hydrate } from '../../retrieve/inboundDb/dynamicHydrate';
import { adapt } from '../../references/adapt';
import campaign from '../../references/campaign';
import channelKeyReferences from '../../references/channelKey';
import Spec from '../../retrieve/inboundDb/common/specs/Spec';
const idProperty = 'id';
const getInboundSpec = () => new Spec({
  dataType: SOCIAL_POSTS,
  properties: {
    idProperty,
    responsePaths: {
      id: ['id'],
      'stats.clicks': ['stats', 'clicks'],
      'stats.impressions': ['stats', 'impressions'],
      'stats.shares': ['stats', 'shares']
    },
    extractors: {
      __default: (obj, property) => obj.get(property),
      'stats.clicks': result => result.getIn(['stats', 'clicks']),
      'stats.impressions': result => result.getIn(['stats', 'impressions']),
      'stats.shares': result => result.getIn(['stats', 'shares'])
    }
  },
  search: {
    url: 'social-reporting/v1/search/posts',
    objectsField: 'results'
  },
  hydrate: {
    inputs: ImmutableSet([idProperty, 'channelType']),
    // TODO: update to use real hydrator
    fn: ({
      id,
      channelType
    }) => `${id} (${channelType})`
  }
});
export default InboundDbModule({
  dataType: SOCIAL_POSTS,
  references: ImmutableMap({
    channelId: adapt(() => channelKeyReferences({
      useChannelId: true
    })),
    campaignGuid: adapt(campaign)
  }),
  referenceProperties: ImmutableMap({
    id: SOCIAL_POSTS
  }),
  hydrate: (ids, config) => hydrate(SOCIAL_POSTS, ids, config),
  getInboundSpec
});