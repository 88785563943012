'use es6';

import { List } from 'immutable';
import { getMetricOptions, getTableOnlyMetricOptions } from '../../constants/dataTypeMetricOptions';
import { getReportTemplateTableMetrics } from '../../constants/dataTypeMetricOptions';
export function validateTableMetrics(contextChange, context, report, subAppKey, __defaultContext, __scopes, __gates) {
  const tableMetrics = contextChange.tableMetrics || context.tableMetrics;
  const staticMetrics = contextChange.metric ? List([contextChange.metric]) : getReportTemplateTableMetrics(report);
  const validTableMetrics = getMetricOptions(report, subAppKey).reduce((validOptions, metricOption) => {
    if (!staticMetrics.includes(metricOption.value)) {
      validOptions.push(metricOption);
    }
    return validOptions;
  }, []).concat(getTableOnlyMetricOptions(report, subAppKey)).map(metricObject => metricObject.value);
  const filteredTableMetrics = tableMetrics.filter(metric => validTableMetrics.includes(metric));
  return Object.assign({}, contextChange, {
    tableMetrics: filteredTableMetrics
  });
}