'use es6';

import isString from 'transmute/isString';
import { RollingInequalityDisplayType } from '../DisplayTypes';
import { RollingInequalityInputType } from '../InputTypes';
import { makeOperator } from './Operator';
import isNumber from 'transmute/isNumber';
import { isBoolean } from './OperatorValidators';
const isValidDirection = direction => {
  return direction === 'forward' || direction === 'backward';
};
export default makeOperator({
  displayType: RollingInequalityDisplayType,
  inputType: RollingInequalityInputType,
  name: 'LessRolling',
  values: [{
    name: 'numberOfDays',
    isValid: isNumber,
    defaultValue: 1
  }, {
    name: 'direction',
    isValid: isValidDirection,
    defaultValue: 'backward'
  }, {
    name: 'zoneId',
    isValid: value => typeof value === 'undefined' || isString(value)
  }, {
    name: 'hasTimezones',
    isValid: isBoolean,
    defaultValue: false
  }]
});