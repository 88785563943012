'use es6';

import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as Operators from '../../../filterQueryFormat/operator/Operators';
import { __ANY_LINK } from 'customer-data-filters/converters/listSegClassic/ListSegConstants';
import getIn from 'transmute/getIn';
import protocol from 'transmute/protocol';
import toRefinementFilter from './toRefinementFilter';
const EMAIL_EVENT_TYPE = {
  BOUNCE: 'BOUNCE',
  CLICK: 'CLICK',
  DELIVERED: 'DELIVERED',
  OPEN: 'OPEN',
  PRINT: 'PRINT',
  REPLIED: 'REPLIED',
  SENT: 'SENT',
  SPAMREPORT: 'SPAMREPORT',
  UNSUBSCRIBED: 'UNSUBSCRIBED'
};
const getFilterLine = (emailEventType, addProps = {}) => {
  return Object.assign({
    negated: false,
    eventType: emailEventType,
    eventTypes: []
  }, addProps);
};
const getFilterLineNegated = emailEventType => {
  return getFilterLine(emailEventType, {
    negated: true
  });
};
const getFilterLineWithValue = (emailEventType, operator) => {
  const clickUrlSubstring = getIn(['value'], operator);
  if (clickUrlSubstring && clickUrlSubstring !== __ANY_LINK) {
    return getFilterLine(emailEventType, {
      clickUrlSubstring
    });
  }
  return getFilterLine(emailEventType);
};
const getEmailEventFilter = (filterLines, operator) => {
  return {
    filterType: ObjectSegFilterTypes.EMAIL_EVENT,
    level: getIn(['field', 'metadata', 'level'], operator),
    emailId: getIn(['field', 'name'], operator),
    appId: getIn(['field', 'metadata', 'appId'], operator),
    emailFilterLines: filterLines
  };
};
const getFilterLinesForOperator = protocol({
  name: 'getFilterLinesForOperator',
  args: [protocol.TYPE],
  fallback: []
});
getFilterLinesForOperator.implement(Operators.EmailBounced, () => [getFilterLine(EMAIL_EVENT_TYPE.BOUNCE)]);
getFilterLinesForOperator.implement(Operators.EmailLinkClicked, operator => [getFilterLineWithValue(EMAIL_EVENT_TYPE.CLICK, operator)]);
getFilterLinesForOperator.implement(Operators.EmailMarked, () => [getFilterLine(EMAIL_EVENT_TYPE.SPAMREPORT)]);
getFilterLinesForOperator.implement(Operators.EmailOpened, () => [getFilterLine(EMAIL_EVENT_TYPE.OPEN)]);
getFilterLinesForOperator.implement(Operators.EmailOpenedButLinkNotClicked, () => [getFilterLine(EMAIL_EVENT_TYPE.OPEN), getFilterLineNegated(EMAIL_EVENT_TYPE.CLICK)]);
getFilterLinesForOperator.implement(Operators.EmailOpenedButNotReplied, () => [getFilterLine(EMAIL_EVENT_TYPE.OPEN), getFilterLineNegated(EMAIL_EVENT_TYPE.REPLIED)]);
getFilterLinesForOperator.implement(Operators.EmailReceived, () => [getFilterLine(EMAIL_EVENT_TYPE.DELIVERED)]);
getFilterLinesForOperator.implement(Operators.EmailReceivedButNotOpened, () => [getFilterLine(EMAIL_EVENT_TYPE.DELIVERED), getFilterLineNegated(EMAIL_EVENT_TYPE.OPEN)]);
getFilterLinesForOperator.implement(Operators.EmailReplied, () => [getFilterLine(EMAIL_EVENT_TYPE.REPLIED)]);
getFilterLinesForOperator.implement(Operators.EmailSent, () => [getFilterLine(EMAIL_EVENT_TYPE.SENT)]);
getFilterLinesForOperator.implement(Operators.EmailSentButLinkNotClicked, () => [getFilterLine(EMAIL_EVENT_TYPE.SENT), getFilterLineNegated(EMAIL_EVENT_TYPE.CLICK)]);
getFilterLinesForOperator.implement(Operators.EmailSentButNotReceived, () => [getFilterLine(EMAIL_EVENT_TYPE.SENT), getFilterLineNegated(EMAIL_EVENT_TYPE.DELIVERED)]);
getFilterLinesForOperator.implement(Operators.EmailUnsubscribed, () => [getFilterLine(EMAIL_EVENT_TYPE.UNSUBSCRIBED)]);
const toEmailEventFilter = operator => {
  let filterLines = getFilterLinesForOperator(operator);
  const refinement = operator.get('refinement');
  if (refinement) {
    const refinementFilter = toRefinementFilter(refinement);
    filterLines = filterLines.map(filterLine => Object.assign({}, filterLine, refinementFilter));
  }
  return getEmailEventFilter(filterLines, operator);
};
export default toEmailEventFilter;