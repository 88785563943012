'use es6';

// NOTE: Implement InvariantViolation exception with frame popping

/**
 * Development environment error
 *
 * @param {string} format Message format string
 * @param {...string} args Format tokens
 * @returns {Error} Invariant error
 * @private
 */
const getDevelopmentError = (format, ...args) => {
  let index = 0;
  const error = new Error(format.replace(/%s/g, () => String(args[index++])));
  error.name = 'Invariant Violation';
  return error;
};

/**
 * Invariant violation check
 *
 * @param {boolean} condition Condition to check
 * @param {string} format Message format string
 * @param {...string} args Format tokens
 * @returns {void}
 * @throws Invariant Violation
 */
export default ((condition, format, ...args) => {
  if (!condition) {
    const error = typeof format === 'string' ? getDevelopmentError(format, ...args) : new Error('invariant(...): Second argument must be a string.');
    throw error;
  }
});