'use es6';

import * as OperatorTypes from '../ObjectSegOperatorTypes';
import { __ANY_CTA } from 'customer-data-filters/converters/listSegClassic/ListSegConstants';
import fromRefinementFilter from './fromRefinementFilter';
const ctaOperators = [OperatorTypes.HAS_CLICKED_CTA, OperatorTypes.HAS_NOT_CLICKED_CTA, OperatorTypes.HAS_OPENED_CTA, OperatorTypes.HAS_NOT_OPENED_CTA];
const tryAddRefinement = (operator, field, filter) => {
  const refinement = fromRefinementFilter(field, filter);
  return refinement ? operator.set('refinement', refinement) : operator;
};
export const isNonPlacementCta = operatorName => ctaOperators.includes(operatorName);
export const fromCtaValueOperation = (Operator, field, filter) => {
  const {
    ctaName,
    operator: operatorName
  } = filter;
  const cta = isNonPlacementCta(operatorName) ? ctaName : __ANY_CTA;
  const operator = Operator.of(field, cta);
  return tryAddRefinement(operator, field, filter);
};