'use es6';

import { isNil } from '../../../private/js-util';
export function anyNumberIsLoading(...params) {
  for (const param in params) {
    if (isNil(params[param])) {
      return true;
    }
  }
  return false;
}