import { set, remove, has } from './storage';
import * as global from './global';

/**
 * Global access to override managers
 *
 * @constant {object}
 */
global.set('overrides', {});

/**
 * Create normalized reporting data override key
 *
 * @param {string} override Name of the override
 * @returns {string} Localstorage key
 */
const key = override => `@@reporting-data:override:${override}`;

/**
 * Create override
 *
 * @param {string} override Name of the override
 * @param {?string} description Optional description
 * @returns {object} Override manager
 */
const create = (override, description) => {
  const manager = Object.assign({
    on: () => set(key(override), true),
    off: () => remove(key(override)),
    get enabled() {
      return has(key(override));
    }
  }, description ? {
    description
  } : {});
  global.update('overrides', overrides => Object.assign({}, overrides, {
    [override]: manager
  }));
  return manager;
};
export const development = create('development', 'force development mode in the platform');
export const unified = create('unified', 'use unified implementation of data types if avaialble');
export const newrelic = create('newrelic', 'enable platform level debug new relic tracking');
export const batchRequests = create('batchRequests', 'Enable batching of contacts-search requests');
export const unifiedMetricFilters = create('unifiedMetricFilters', 'Enables metric level (post-aggregation) filtering for analytics reports');
export const disableHighchartsAnimation = create('disableHighchartsAnimation', 'Disables Highcharts animations (for automated testing only)');
export const checkedRecordLogging = create('checkedRecordLogging', 'Enables logging for type issues for checked.record');