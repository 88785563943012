'use es6';

import isEqual from 'hs-lodash/isEqual';
import { defineFactory } from 'general-store';
import { Map as ImmutableMap } from 'immutable';
import { defer } from 'dashboard-lib/private/js-util';
import * as FREQUENCY_TYPES from 'reporting-data/constants/frequency';
import { RANGE_TYPES } from 'reporting-ui-components/components/shared/constants/DateRangeConstants';
import compose from 'transmute/compose';
import fromJS from 'transmute/fromJS';
import { DASHBOARD_MOUNTED, DASHBOARD_CONTEXT_UPDATE } from '../constants/DashboardActionTypes';
import { OWNERS_FETCH_SUCCESS, TEAMS_FETCH_SUCCESS } from '../constants/SystemDataActionTypes';
import { parseContext } from '../lib/context/parse-context';
import { toQuery } from '../lib/context/stringify-context';
import { convertToDateRange } from '../lib/DateConversionUtils';
import { setQuery, parseAsQueryMap, getQueryString } from '../lib/historyUtils';
import * as persistedContext from '../lib/persistedContext';
import OwnersStore from './OwnersStore';
import TeamsStore from './TeamsStore';
const persistContext = (dashboardId, context, history) => {
  const currentParams = parseAsQueryMap(history);
  const newParams = toQuery(context, currentParams);
  if (!isEqual(currentParams, newParams)) {
    defer(() => setQuery(newParams, history));
  }
  persistedContext.set(dashboardId, context);
  return context;
};
const validateOwners = (context, allValidOwners) => {
  const ownerList = context.getIn(['owner', 'owners']);
  if (!ownerList || ownerList.size === 0) {
    return context;
  }
  const validOwners = ownerList.filter(ownerId => allValidOwners.get(ownerId) || allValidOwners.get(String(ownerId)) || ownerId === '__hs__ME');
  if (validOwners.size === 0) {
    return context.delete('owner');
  }
  return context.setIn(['owner', 'owners'], validOwners);
};
const validateTeams = (context, allValidTeams) => {
  const teamList = context.getIn(['team', 'teams']);
  if (!teamList || teamList.size === 0) {
    return context;
  }
  const validTeams = teamList.filter(teamId => allValidTeams.get(teamId) || allValidTeams.get(Number(teamId)) || allValidTeams.get(String(teamId)) || teamId === '__hs__MY_TEAM');
  if (validTeams.size === 0) {
    return context.delete('team');
  }
  return context.setIn(['team', 'teams'], validTeams);
};
const validateRangeType = context => {
  if (!context.get('context')) {
    return context;
  }
  const dateRange = convertToDateRange(context.get('context'));
  if (dateRange && !RANGE_TYPES[dateRange.get('rangeType')]) {
    return context.delete('context');
  }
  return context;
};
const validateFrequency = context => {
  const frequency = context.getIn(['frequency', 'histogram']);
  if (!frequency || FREQUENCY_TYPES[frequency]) {
    return context;
  }
  return context.delete('frequency');
};
const validateOptions = compose(validateRangeType, validateFrequency, context => OwnersStore.get('loaded') ? validateOwners(context, OwnersStore.get('owners')) : context, context => TeamsStore.get('loaded') ? validateTeams(context, TeamsStore) : context);
export default defineFactory().defineName('DashboardContextStore').defineGetInitialState(() => ImmutableMap()).defineGet((state, id) => {
  const context = state.get(id);
  return context || ImmutableMap();
}).defineResponseTo(DASHBOARD_MOUNTED, (state, {
  dashboardId,
  skipLocalStorage,
  history,
  hasImprovedDBFiltersAccess
}) => {
  let context = validateOptions(parseContext(getQueryString(history)));
  skipLocalStorage = skipLocalStorage || hasImprovedDBFiltersAccess;
  if (!skipLocalStorage) {
    if (context.isEmpty()) {
      context = validateOptions(persistedContext.getContextMap().get(dashboardId) || ImmutableMap({}));
    }
    persistContext(dashboardId, context, history);
  }
  return state.set(dashboardId, context);
}).defineResponseTo(OWNERS_FETCH_SUCCESS, (state, {
  owners
}) => {
  return state.map(context => {
    return validateOwners(context, fromJS(owners));
  });
}).defineResponseTo(TEAMS_FETCH_SUCCESS, (state, teams) => {
  return state.map(context => {
    return validateTeams(context, fromJS(teams));
  });
}).defineResponseTo(DASHBOARD_CONTEXT_UPDATE, (state, {
  context,
  dashboardId,
  history
}) => {
  persistContext(dashboardId, context, history);
  return state.set(dashboardId, context);
}).register();