// TODO: add proper ILS filters types

export const isILSFilter = thing => {
  if (typeof thing === 'object' && thing !== null && typeof thing.filterType === 'string') {
    return true;
  }
  return false;
};
export const isILSFilterBranch = thing => {
  if (typeof thing === 'object' && thing !== null && Array.isArray(thing.filterBranches) && Array.isArray(thing.filters) && typeof thing.filterBranchOperator === 'string' && typeof thing.filterBranchType === 'string') {
    return true;
  }
  return false;
};