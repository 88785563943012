import { Map as ImmutableMap } from 'immutable';
import curry from 'transmute/curry';
import get from 'transmute/get';
import keySeq from 'transmute/keySeq';
import reduce from 'transmute/reduce';
const isTypeof = curry((jsTypeName, k) => typeof k === jsTypeName);
export const strictEquals = curry((left, right) => left === right);
export const isNil = k => k == null;
export const isNotNil = k => k != null;
export const isString = isTypeof('string');

/**
 * Creates an object given an array of pairs
 * @param subject (vanilla array)
 * @returns {object} js object
 */
export const fromPairs = reduce(
// TODO: test this one
{}, (acc, [key, value]) => Object.assign({}, acc, {
  [key]: value
}), ImmutableMap({}));

/**
 * @param subject (immutable maps or vanilla maps)
 */
export const toPairs = subject => {
  const keys = ImmutableMap.isMap(subject) ? keySeq(subject) : Object.keys(subject);
  return keys.map(key => [key, get(key, subject)]);
};

// Values

export const lang = curry((baseLangKey, restOfTheKey) => `${baseLangKey}.${restOfTheKey}`);

/** Default is returned if the given value is nil (see `isNil`)*/
export const defaultTo = curry((defaultValue, given) => isNil(given) ? defaultValue : given);