'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import * as http from '../../request/http';
import Request from '../../request/Request';
import prefix from '../../lib/prefix';
import { stageLabelTranslator } from 'property-translator/propertyTranslator';
const translate = prefix('reporting-data.lifecyclestage');
export const RAAS_FE_ONLY_CREATE_STAGE = 'create';
export const LEGACY_HUBSPOT_DEFAULT_LIFECYCLE_STAGES = () => List([ImmutableMap({
  value: RAAS_FE_ONLY_CREATE_STAGE,
  type: 'string',
  label: translate(RAAS_FE_ONLY_CREATE_STAGE),
  displayOrder: -1
}), ImmutableMap({
  value: 'subscriber',
  type: 'string',
  label: translate('subscriber'),
  displayOrder: 0
}), ImmutableMap({
  value: 'lead',
  type: 'string',
  label: translate('lead'),
  displayOrder: 1
}), ImmutableMap({
  value: 'marketingqualifiedlead',
  type: 'string',
  label: translate('marketingqualifiedlead'),
  displayOrder: 2
}), ImmutableMap({
  value: 'salesqualifiedlead',
  type: 'string',
  label: translate('salesqualifiedlead'),
  displayOrder: 3
}), ImmutableMap({
  value: 'opportunity',
  type: 'string',
  label: translate('opportunity'),
  displayOrder: 4
}), ImmutableMap({
  value: 'customer',
  type: 'string',
  label: translate('customer'),
  displayOrder: 5
}), ImmutableMap({
  value: 'evangelist',
  type: 'string',
  label: translate('evangelist'),
  displayOrder: 6
}), ImmutableMap({
  value: 'other',
  type: 'string',
  label: translate('other'),
  displayOrder: 7
})]);
const getLifecycleStages = () => http.retrieve(Request.get({
  url: 'pipelines/v2/pipelines/0-1/contacts-lifecycle-pipeline'
}));
export const getLifecycleStagePropertyOptions = () => getLifecycleStages().then(pipeline => pipeline.get('stages').map(stage => ImmutableMap({
  type: 'string',
  value: stage.get('stageId'),
  label: stageLabelTranslator({
    objectType: pipeline.get('objectType'),
    stageId: stage.get('stageId'),
    pipelineId: pipeline.get('pipelineId'),
    label: stage.get('label')
  }),
  displayOrder: stage.get('displayOrder')
})).unshift(ImmutableMap({
  value: 'create',
  type: 'string',
  label: translate('create'),
  displayOrder: -1
})));
export default getLifecycleStagePropertyOptions;