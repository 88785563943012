import { DAY, WEEK, MONTH, QUARTER, YEAR } from 'customer-data-filters/filterQueryFormat/rollingDates/TimeUnits';
import { AfterDate, BeforeDate, Equal, InRange, InRollingDateRange
// @ts-expect-error migrate file to typescript
} from 'customer-data-filters/filterQueryFormat/operator/Operators';
import { FORWARD } from 'customer-data-filters/filterQueryFormat/rollingDates/RollingDateDirections';
// @ts-expect-error migrate file to typescript
import makeDateRangeByType from 'reporting-data/lib/makeDateRangeByType';
import { RANGE_TYPES } from 'reporting-data/constants/dateRangeTypes';
export const GENERIC_DATE_RANGE_PROPERTY_NAME = '__DATE_RANGE__';
export const isGenericDateRangeProperty = propertyName => propertyName === GENERIC_DATE_RANGE_PROPERTY_NAME;
export const DateRangeOperatorToSORangeType = {
  [Equal]: RANGE_TYPES.IS_EQUAL_TO,
  [AfterDate]: RANGE_TYPES.IS_AFTER_DATE,
  [BeforeDate]: RANGE_TYPES.IS_BEFORE_DATE,
  [InRange]: RANGE_TYPES.CUSTOM
};
const THIS_UNIT = 'THIS_UNIT';
const NEXT_UNIT = 'NEXT_UNIT';
const LAST_UNIT = 'LAST_UNIT';
export const TimeUnitToRangeType = {
  [DAY]: {
    [THIS_UNIT]: RANGE_TYPES.THIS_DAY,
    [NEXT_UNIT]: RANGE_TYPES.NEXT_DAY,
    [LAST_UNIT]: RANGE_TYPES.LAST_DAY
  },
  [WEEK]: {
    [THIS_UNIT]: RANGE_TYPES.THIS_WEEK,
    [NEXT_UNIT]: RANGE_TYPES.NEXT_WEEK,
    [LAST_UNIT]: RANGE_TYPES.LAST_WEEK
  },
  [MONTH]: {
    [THIS_UNIT]: RANGE_TYPES.THIS_MONTH,
    [NEXT_UNIT]: RANGE_TYPES.NEXT_MONTH,
    [LAST_UNIT]: RANGE_TYPES.LAST_MONTH
  },
  [QUARTER]: {
    [THIS_UNIT]: RANGE_TYPES.THIS_QUARTER,
    [NEXT_UNIT]: RANGE_TYPES.NEXT_QUARTER,
    [LAST_UNIT]: RANGE_TYPES.LAST_QUARTER
  },
  [YEAR]: {
    [THIS_UNIT]: RANGE_TYPES.THIS_YEAR,
    [NEXT_UNIT]: RANGE_TYPES.NEXT_YEAR,
    [LAST_UNIT]: RANGE_TYPES.LAST_YEAR
  }
};
export const getDateRangeFiscalYearOverride = dashboardFilter => {
  const {
    operator: operatorName,
    value: operatorValue
  } = dashboardFilter;
  if (operatorName === InRollingDateRange.toString()) {
    return operatorValue.useFiscalYear;
  }
  return null;
};
export const getInRollingDateRangeFilterOverride = operatorValue => {
  const {
    direction,
    includeFutureDates,
    isInclusive,
    timeUnit,
    value: timeUnitCount
  } = operatorValue;

  // Handles rolling date range.
  if (timeUnit === DAY && timeUnitCount > 1) {
    return {
      rangeType: RANGE_TYPES.ROLLING,
      otherDateInfo: {
        rollingDays: timeUnitCount
      }
    };
  }
  const rangeTypeKey = direction === FORWARD ? NEXT_UNIT : isInclusive ? THIS_UNIT : LAST_UNIT;
  return {
    // @ts-expect-error could access undefined
    rangeType: TimeUnitToRangeType[timeUnit][rangeTypeKey],
    otherDateInfo: {
      entireCurrentUnit: includeFutureDates
    }
  };
};

// converts operator in Filter Query Format to format used by date range editor
export const getDateRangeFilterOverride = dashboardFilter => {
  const {
    operator: operatorName,
    value: operatorValue,
    highValue: operatorHighValue
  } = dashboardFilter;
  if (operatorName === InRollingDateRange.toString()) {
    const {
      rangeType,
      otherDateInfo
    } = getInRollingDateRangeFilterOverride(operatorValue);
    return makeDateRangeByType(Object.assign({
      rangeType
    }, otherDateInfo));
  }
  const rangeType = DateRangeOperatorToSORangeType[operatorName];
  if ([RANGE_TYPES.IS_EQUAL_TO, RANGE_TYPES.IS_AFTER_DATE, RANGE_TYPES.IS_BEFORE_DATE].includes(rangeType)) {
    return makeDateRangeByType({
      rangeType,
      date: operatorValue
    });
  }

  //custom date range
  return makeDateRangeByType({
    rangeType,
    startDate: operatorValue,
    endDate: operatorHighValue
  });
};