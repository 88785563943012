'use es6';

import { getTableList } from 'reporting-snowflake/relational/utils/table-utils';
const getTableListNames = reportDefinition => getTableList(reportDefinition.get('table')).map(table => table.name);
// TODO replace `reportDefinition` with error code from sql-reporting api to determine which data sources are deprecated
export const getDeprecatedDataSources = reportDefinition => {
  const deprecatedDataSources = [];
  const reportTableList = getTableListNames(reportDefinition);
  return reportTableList.filter(name => deprecatedDataSources.includes(name));
};
export const reportHasDeprecatedDataSources = reportDefinition => {
  const deprecatedDataSources = getDeprecatedDataSources(reportDefinition);
  return !deprecatedDataSources.isEmpty();
};
export const reportHadBeenMigratedFromXOR = reportDefinition => {
  return !!reportDefinition.get('sourceCrossObjectReportId', false);
};