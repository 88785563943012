// @ts-expect-error untyped
import { isValidMomentDate, isBoolean } from './OperatorValidators';
import { makeOperator } from './Operator';
import { RangeInputType } from '../InputTypes';
import { MomentDate } from '../DisplayTypes';
export default makeOperator({
  inputType: RangeInputType,
  displayType: MomentDate,
  name: 'BetweenTimePoints',
  values: [{
    name: 'value',
    isValid: isValidMomentDate
  }, {
    name: 'highValue',
    isValid: isValidMomentDate
  }, {
    name: 'hasTimezones',
    isValid: isBoolean,
    defaultValue: false
  }]
});