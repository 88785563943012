'use es6';

import { Map as ImmutableMap } from 'immutable';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
export const formatByDomainId = response => response.objects.reduce((acc, domainObject) => acc.set(String(domainObject.id), ReferenceRecord({
  id: String(domainObject.id),
  label: domainObject.domain,
  referencedObject: domainObject
})), ImmutableMap());
export const formatByDomain = response => response.objects.reduce((acc, domainObject) => acc.set(domainObject.domain, ReferenceRecord({
  id: domainObject.domain,
  label: domainObject.domain,
  referencedObject: domainObject
})), ImmutableMap());