'use es6';

import { List } from 'immutable';
import { Promise } from '../../lib/promise';
import { CONTACT_SEARCH_AGGREGATION_MAX_SIZE } from '../../constants/limits';
export const SCRIPTED = 'BUCKET_dealProgress';
export const PROPERTY = 'dealstage.probability';
export const configure = config => {
  const dimensions = config.get('dimensions') || List();
  const index = dimensions.indexOf(SCRIPTED);
  return Promise.resolve(index >= 0 ? config.set('limit', CONTACT_SEARCH_AGGREGATION_MAX_SIZE) : config);
};