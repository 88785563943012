'use es6';

import memoize from 'transmute/memoize';
import compose from 'transmute/compose';
import { defaultTo } from 'dashboard-lib/private/js-util';
import { getPortalLimit } from 'dashboard-lib/public/user-info/user-info-schema';
import { useUserInfo } from 'dashboard-components/context/user-info-context';
const getDashboardLimit = userInfo => {
  return getPortalLimit('dashboards', userInfo);
};
const getter = compose(defaultTo(0), getDashboardLimit);
const memoizedGet = memoize(getter);

/**
 * @deprecated: use only with class components
 */
export const getCustomDashboardLimitFromUserInfo = memoizedGet;
export const useCustomDashboardLimit = () => {
  const userInfo = useUserInfo();
  return memoizedGet(userInfo);
};