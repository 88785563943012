/* hs-eslint ignored failing-rules */
/* eslint-disable no-bitwise */

'use es6';

import { getPortalLimit } from 'dashboard-lib/public/user-info/user-info-schema';
import { useUserInfo } from 'dashboard-components/context/user-info-context';
export const useReportingIncreaseCount = () => {
  const userInfo = useUserInfo();
  return getPortalLimit('reporting-increase-count', userInfo) | 0;
};