import { fromJS } from 'immutable';
import enviro from 'enviro';
import { DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES, HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE } from 'reporting-data/constants/objectCoordinates';
//@ts-expect-error Untyped import
import { Objects as DataTypeToObject } from 'reporting-data/retrieve/inboundDb/pipelines';
import { createJourneyStepAlias, generateJourneyFilterId } from 'reporting-snowflake/funnel/utils/aliasing';
import { AND } from 'reporting-snowflake/funnel/types/filterTypes';
import { JOURNEY_SOURCE_TYPE } from 'reporting-snowflake/funnel/constants/sourceTypes';
import { FieldTypes } from 'reporting-snowflake/relational/schema/constants/column-constants';
import { CREATE_STAGE_ID, ObjectToDataType, getCreateStageLabel } from './pipelineStageOrderEditorUtils';
export const JOURNEY_STAGE_LIMIT = 15;
const QA_PIPELINE_STAGE_EVENT_ID = '4-530531';
const PROD_PIPELINE_STAGE_EVENT_ID = '4-1463231';
export const STAGE_CHANGE_EVENT_NAME = 'e_pipeline_stage_updated_v2';
export const OBJECT_CREATED_EVENT_NAME = 'e_object_created_v2';
export const getObjectType = report => {
  return report.journeyQuery.objectQuery.table.name;
};
const getObjectTypeId = report => {
  return report.journeyQuery.objectQuery.table.objectTypeId;
};
const getObjectCreatedStep = report => {
  const eventObjectTypeId = enviro.isQa() ? '4-530516' : '4-1463224';
  const objectObjectTypeId = getObjectTypeId(report);
  const label = getCreateStageLabel(getObjectType(report));
  return {
    alias: 'e_object_created_v2_0_0',
    name: label,
    query: {
      table: {
        name: OBJECT_CREATED_EVENT_NAME,
        objectObjectTypeIds: [objectObjectTypeId],
        objectTypeId: eventObjectTypeId,
        type: JOURNEY_SOURCE_TYPE.HUBSPOT_EVENT
      }
    }
  };
};
const getJourneyStep = (stageData, stageIndex, stageObjectType, report) => {
  const stepIndex = 0;
  const stageDataType = ObjectToDataType[stageObjectType];
  const stageObjectTypeId = DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(stageDataType);
  const eventObjectTypeId = enviro.isQa() ? QA_PIPELINE_STAGE_EVENT_ID : PROD_PIPELINE_STAGE_EVENT_ID;
  const journeyObjectType = getObjectType(report);
  const journeyObjectTypeId = getObjectTypeId(report);
  const stepFilter = {
    field: {
      name: 'hs_pipeline_stage',
      table: STAGE_CHANGE_EVENT_NAME,
      source: 'TABLE',
      type: FieldTypes.ENUMERATION
    },
    filter: {
      filterType: 'PROPERTY',
      operation: {
        propertyType: 'enumeration',
        operator: 'IS_ANY_OF',
        values: [stageData.stageId]
      },
      property: 'hs_pipeline_stage'
    },
    isIgnored: false
  };
  const filterId = generateJourneyFilterId(stepFilter, {
    stageIndex,
    stepIndex
  });
  const dataJoin = stageObjectType !== journeyObjectType ? {
    join: [{
      target: {
        name: stageObjectType,
        objectTypeId: stageObjectTypeId,
        type: JOURNEY_SOURCE_TYPE.HUBSPOT_OBJECT,
        join: [{
          target: {
            name: journeyObjectType,
            objectTypeId: journeyObjectTypeId,
            type: JOURNEY_SOURCE_TYPE.HUBSPOT_OBJECT
          }
        }]
      }
    }]
  } : {};
  const query = {
    table: Object.assign({
      name: STAGE_CHANGE_EVENT_NAME,
      type: JOURNEY_SOURCE_TYPE.HUBSPOT_EVENT,
      objectTypeId: eventObjectTypeId,
      objectObjectTypeIds: [stageObjectTypeId]
    }, dataJoin),
    filtering: {
      condition: AND,
      groups: [{
        id: filterId,
        condition: AND,
        filters: [Object.assign({
          id: filterId
        }, stepFilter)]
      }]
    }
  };
  return {
    alias: createJourneyStepAlias(stageIndex, stepIndex, query),
    name: stageData.label,
    query
  };
};
export const getUpdatedStages = (report, selectedStages, stageDataById) => selectedStages.map(({
  pipelineObject,
  stageId,
  optional
}, stageIndex) => {
  if (stageId === CREATE_STAGE_ID) {
    return fromJS({
      name: getCreateStageLabel(getObjectType(report)),
      orderNumber: stageIndex,
      optional,
      steps: [getObjectCreatedStep(report)]
    });
  }
  const stageData = stageDataById[stageId];
  const step = getJourneyStep(stageData, stageIndex, pipelineObject, report);
  return fromJS({
    name: stageData.label,
    orderNumber: stageIndex,
    optional,
    steps: [step]
  });
});
export const getCurrentStages = report => {
  const stages = report.journeyQuery.stages;
  return stages.map(stage => {
    const optional = stage.optional;
    const step = stage.steps[0];
    const stepQuery = step.query;
    const stepQueryTable = stepQuery.table;
    if (stepQueryTable.name === OBJECT_CREATED_EVENT_NAME) {
      const objectType = getObjectType(report);
      return {
        pipelineObject: objectType,
        stageId: CREATE_STAGE_ID,
        optional
      };
    }
    const stageObjectTypeId = stepQueryTable.objectObjectTypeIds[0];
    const stageDataType = HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE.get(stageObjectTypeId);
    const stageObjectType = DataTypeToObject[stageDataType];
    const stageId = stepQuery.filtering.groups[0].filters[0].filter.operation.values[0];
    return {
      pipelineObject: stageObjectType,
      stageId,
      optional
    };
  });
};