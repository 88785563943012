import { List } from 'immutable';
import { FieldSources } from '../../schema/column-records';
import { isFieldExpressionBased } from '../../utils/column-utils';
import { getExpressionProperties } from '../../../dataset/expression-utils';
import { Conditions } from '../../schema/filter-records';
import { DATE_PART_EXPRESSION_FIELD_ALIAS_PREFIX } from './constants';
export const isColumnFlpRestricted = (reportDefinition, meta, column) => {
  const expressionFieldsList = reportDefinition.expressions;
  if (isFieldExpressionBased(column.field)) {
    const expressionFieldToCheck = expressionFieldsList.find(expressionField => expressionField.alias === column.field.name);
    const properties = getExpressionProperties(expressionFieldToCheck.expression, meta);
    const flpRestrictedProperties = properties.filter(property => {
      const propertyTable = meta.properties.get(property.table);
      const snowflakeProperty = propertyTable && propertyTable.get(property.name);
      return snowflakeProperty.get('flpRestricted');
    });
    return !flpRestrictedProperties.isEmpty();
  }
  if (column.field.source === FieldSources.TABLE) {
    const {
      field: {
        table,
        name
      }
    } = column;
    if (!table) {
      return false;
    }
    const propertyTable = meta.properties.get(table);
    const property = propertyTable && propertyTable.get(name);
    if (!property) {
      return false;
    }
    return property.get('flpRestricted');
  }
  return false;
};
export const removeInvalidFiltersFromReport = reportDefinition => {
  var _reportDefinition$fil, _reportDefinition$fil2;
  if ((reportDefinition === null || reportDefinition === void 0 || (_reportDefinition$fil = reportDefinition.filtering) === null || _reportDefinition$fil === void 0 ? void 0 : _reportDefinition$fil.condition) === Conditions.CUSTOM && (reportDefinition === null || reportDefinition === void 0 || (_reportDefinition$fil2 = reportDefinition.filtering) === null || _reportDefinition$fil2 === void 0 ? void 0 : _reportDefinition$fil2.logic) === '') {
    return reportDefinition.setIn(['filtering', 'groups'], List([]));
  }
  return reportDefinition;
};
export const getIsReportFlpRestricted = (reportDefinition, meta) => {
  return !reportDefinition.stagedColumns.filter(column => isColumnFlpRestricted(reportDefinition, meta, column)).isEmpty();
};
export const isDatePartExpressionFieldAlias = alias => alias.includes(DATE_PART_EXPRESSION_FIELD_ALIAS_PREFIX);