'use es6';

import { AssetPermission } from '../../../../public/permission/asset-permission';
import { hasSufficientPermission } from '../../../../public/permission/compute-permission';
import { anyNumberIsLoading } from '../../common/any-number-is-loading';
import { getAndCheck } from '../../common/get-and-check-param';
import { getAllProblems } from '../../common/get-problems-no-check';
import { limitReachedOrExceeded } from '../../common/limits';
import { createProblem } from '../../common/problem';
import { DASHBOARD_IS_LOADING, DASHBOARD_REPORT_LIMIT, MAX_USER_ASSET_PERMISSION, TARGET_DASHBOARD_REPORT_COUNT, TARGET_IS_READ_ONLY_DEMO, USER_DASHBOARD_PERMISSION } from '../../params/all-params';
export function _isLoading(params) {
  const targetDashboardReportCount = getAndCheck(TARGET_DASHBOARD_REPORT_COUNT, params);
  const dashboardReportLimit = getAndCheck(DASHBOARD_REPORT_LIMIT, params);
  const isDashboardLoading = getAndCheck(DASHBOARD_IS_LOADING, params);
  return anyNumberIsLoading(targetDashboardReportCount, dashboardReportLimit) || isDashboardLoading;
}
export const _dashboardPermissionDenied = params => {
  const dashboardPermission = getAndCheck(USER_DASHBOARD_PERMISSION, params);
  const correctDashboardPermission = hasSufficientPermission(AssetPermission.EDIT, dashboardPermission);
  return !correctDashboardPermission;
};
export const _dashboardReportLimitReachedOrExceeded = params => {
  const targetDashboardReportCount = getAndCheck(TARGET_DASHBOARD_REPORT_COUNT, params);
  const dashboardReportLimit = getAndCheck(DASHBOARD_REPORT_LIMIT, params);
  return limitReachedOrExceeded(targetDashboardReportCount, dashboardReportLimit);
};
export const LOADING = createProblem('LOADING', _isLoading);
export const DASHBOARD_PERMISSION_DENIED = createProblem('DASHBOARD_PERMISSION_DENIED', _dashboardPermissionDenied);
export const DASHBOARD_REPORT_LIMIT_REACHED_OR_EXCEEDED = createProblem('DASHBOARD_REPORT_LIMIT_REACHED_OR_EXCEEDED', _dashboardReportLimitReachedOrExceeded);
export const TARGET_IS_READ_ONLY_DEMO_PROBLEM = createProblem('TARGET_IS_READ_ONLY_DEMO_PROBLEM', params => getAndCheck(TARGET_IS_READ_ONLY_DEMO, params));
export const USER_SETTINGS_LACK_EDIT = createProblem('USER_SETTINGS_LACK_EDIT', params => {
  const maximumPermission = getAndCheck(MAX_USER_ASSET_PERMISSION, params);
  return !hasSufficientPermission(AssetPermission.EDIT, maximumPermission);
});

// TODO: update problems once note & iframes are no longer considered reports
// add-static-widget-to-dashboard can probably be grouped w/ static-widget-action at that point
export const rankedProblems = [LOADING, USER_SETTINGS_LACK_EDIT, DASHBOARD_PERMISSION_DENIED, TARGET_IS_READ_ONLY_DEMO_PROBLEM, DASHBOARD_REPORT_LIMIT_REACHED_OR_EXCEEDED];
export const getAll = params => getAllProblems(params, rankedProblems);