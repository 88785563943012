import { List, Map as ImmutableMap, Set as ImmutableSet, Stack } from 'immutable';
import { toDatasetProperty } from './dataset-property-utils';
import { parse } from './expression-parser';
import { Expression, ExpressionTypes } from './expression-records';
export const parseExpression = expression => Expression(parse(expression));
export const parseExpressionFromString = expressionString => {
  let parsedExpression;
  try {
    parsedExpression = parseExpression(expressionString);
  } catch (error) {
    parsedExpression = parseExpression('null');
  }
  return parsedExpression;
};
export const isLiteralExpression = expression => {
  switch (expression.type) {
    case ExpressionTypes.NULL_LITERAL:
    case ExpressionTypes.NUMERIC_LITERAL:
    case ExpressionTypes.STRING_LITERAL:
    case ExpressionTypes.BOOLEAN_LITERAL:
      return true;
    default:
      return false;
  }
};
export const isIdentifierExpression = expression => expression.type === ExpressionTypes.IDENTIFIER;
export const isPropertyExpression = expression => expression.type === ExpressionTypes.PROPERTY;
export const isFieldExpression = expression => expression.type === ExpressionTypes.FIELD;
export const isFunctionCallExpression = expression => expression.type === ExpressionTypes.FUNCTION_CALL;
export const isAggregationContextExpression = expression => expression.type === ExpressionTypes.AGGREGATION_CONTEXT;
export const isUnaryExpression = expression => {
  switch (expression.type) {
    case ExpressionTypes.POSITIVE:
    case ExpressionTypes.NEGATIVE:
    case ExpressionTypes.NOT:
      return true;
    default:
      return false;
  }
};
export const isBinaryExpression = expression => {
  switch (expression.type) {
    case ExpressionTypes.ADD:
    case ExpressionTypes.SUBTRACT:
    case ExpressionTypes.MULTIPLY:
    case ExpressionTypes.DIVIDE:
    case ExpressionTypes.MODULUS:
    case ExpressionTypes.GREATER:
    case ExpressionTypes.GREATER_OR_EQUAL:
    case ExpressionTypes.LESS:
    case ExpressionTypes.LESS_OR_EQUAL:
    case ExpressionTypes.EQUAL:
    case ExpressionTypes.NOT_EQUAL:
    case ExpressionTypes.AND:
    case ExpressionTypes.OR:
      return true;
    default:
      return false;
  }
};
export const isEqualityExpression = expression => {
  switch (expression.type) {
    case ExpressionTypes.EQUAL:
    case ExpressionTypes.NOT_EQUAL:
      return true;
    default:
      return false;
  }
};
export const expressionToString = expression => {
  const type = expression.type;
  if (type === ExpressionTypes.NULL_LITERAL) {
    return '';
  } else if (isLiteralExpression(expression)) {
    return String(expression.value);
  } else if (isIdentifierExpression(expression)) {
    return expression.name;
  } else if (isPropertyExpression(expression)) {
    return `[${expression.table}.${expression.name}]`;
  } else if (isFieldExpression(expression)) {
    return `[${expression.name}]`;
  } else if (isFunctionCallExpression(expression)) {
    return `${expression.name}(${expression.arguments.map(expressionToString).join(',')})`;
  } else if (isAggregationContextExpression(expression)) {
    const strExp = expressionToString(expression.expression);
    if (expression.dimensions.isEmpty()) {
      return `{ ${strExp} }`;
    }
    const strCtx = expression.dimensions.map(expressionToString).join(', ');
    return `{ ${strCtx}: ${strExp} }`;
  } else if (isUnaryExpression(expression)) {
    return `(${expression.operator}${expressionToString(expression.argument)})`;
  } else if (isBinaryExpression(expression)) {
    return `(${expressionToString(expression.left)}${expression.operator}${expressionToString(expression.right)})`;
  }
  return '';
};
export const expressionToList = expression => {
  let left;
  let right;
  let args = List();
  let dimensions = List();
  let argument;
  if (isBinaryExpression(expression)) {
    left = expression.left;
    right = expression.right;
  } else if (isFunctionCallExpression(expression)) {
    args = expression.arguments;
  } else if (isAggregationContextExpression(expression)) {
    dimensions = expression.dimensions;
    argument = expression.expression;
  } else if (isUnaryExpression(expression)) {
    argument = expression.argument;
  }
  return List([expression, ...List([...args.toArray(), ...dimensions.toArray(), argument, left, right]).filter(e => !!e).flatMap(e => expressionToList(e)).toArray()]);
};
export const expressionUpdater = (expression, updater) => {
  const applyUpdater = exp => {
    if (isFunctionCallExpression(exp)) {
      return exp
      // @ts-expect-error TS doesn't know about one-argument use of update()
      .update(updater).update('arguments', args => args.map(arg => applyUpdater(arg)));
    }
    if (isAggregationContextExpression(exp)) {
      return exp
      // @ts-expect-error TS doesn't know about one-argument use of update()
      .update(updater).update('dimensions', dimensions => dimensions.map(e => applyUpdater(e))).update('expression', e => applyUpdater(e));
    }
    if (isUnaryExpression(exp)) {
      return exp
      // @ts-expect-error TS doesn't know about one-argument use of update()
      .update(updater).update('argument', argument => applyUpdater(argument));
    }
    if (isBinaryExpression(exp)) {
      return exp
      // @ts-expect-error TS doesn't know about one-argument use of update()
      .update(updater).update('left', left => applyUpdater(left)).update('right', right => applyUpdater(right));
    }
    // @ts-expect-error TS doesn't know about one-argument use of update()
    return exp.update(updater);
  };
  return applyUpdater(expression);
};
export const getExpressionProperties = (expression, meta) => expressionToList(expression).filter(exp => isPropertyExpression(exp)).toSet().map(exp => {
  const maybeProperty = meta.properties.getIn([exp.table, exp.name]);
  return maybeProperty ? toDatasetProperty(exp.table, meta.properties.getIn([exp.table, exp.name])) : undefined;
}).filter(property => !!property);
export const getExpressionFields = expression => expressionToList(expression).filter(exp => isFieldExpression(exp)).toSet();

// @ts-expect-error annotate params
export const getExpressionFunctions = (expression, meta) => expressionToList(expression).filter(exp => isFunctionCallExpression(exp)).toSet().map(exp =>
// @ts-expect-error annotate params
meta.find(functionMeta => functionMeta.function === exp.name));
const mapExpressionFieldNames = (expression, lookup) => expressionUpdater(expression, exp => {
  if (isFieldExpression(exp)) {
    const previous = exp.name;
    const next = lookup.has(previous) ? lookup.get(previous) : previous;
    return exp.set('name', next);
  }
  return exp;
});

// @returns an Expression record where all references to a field names are replaced
// by the field ids to ensure that we can still uniquely identify the field, even when the
// field name is changed

export const toFieldNameExpression = (expression, fields) => {
  const labelToNameMap = fields.map(f => f.label).flip();
  return mapExpressionFieldNames(expression, labelToNameMap);
};
export const toFieldLabelExpression = (expression, fields) => {
  const nameToLabelMap = fields.map(f => f.label);
  return mapExpressionFieldNames(expression, nameToLabelMap);
};
export const fieldsToGraph = fields => fields.reduce((graph, field, id) => graph.set(id, field.expression ? getExpressionFields(field.expression).toList().map(exp => {
  return exp.name;
}) : List()), ImmutableMap());
export const transposeGraph = graph => {
  const edges = graph.map((adjacent, vertex) => adjacent.map(a => ({
    from: a,
    to: vertex
  }))).toList().flatten(1);
  const emptyGraph = graph.map(() => List());
  return emptyGraph.merge(edges.groupBy(edge => edge.from).toMap().map(adj => adj.map(a => a.to).toSet().toList()));
};

/**
 * Check for cycles in a directed graph
 * If `maybeIdToCheck` is defined, we will only
 * validate if that vertex is part of a cycle.
 */
export const isCyclic = (graph, maybeIdToCheck) => {
  let color = ImmutableMap();
  const white = id => color = color.set(id, 0);
  const gray = id => color = color.set(id, 1);
  const black = id => color = color.set(id, 2);
  const isWhite = id => color.get(id) === 0;
  const isGray = id => color.get(id) === 1;
  graph.forEach((adj, id) => white(id));
  const checkVertex = id => {
    gray(id);
    const adjacent = graph.get(id);
    // @ts-expect-error TS doesn't know that for-of works on List
    for (const aId of adjacent) {
      if (isGray(aId)) return true;
      if (isWhite(aId) && checkVertex(aId)) return true;
    }
    black(id);
    return false;
  };
  if (maybeIdToCheck) {
    return checkVertex(maybeIdToCheck);
  }
  const ids = graph.keySeq().toArray();
  for (const id of ids) {
    if (isWhite(id) && checkVertex(id)) return true;
  }
  return false;
};

/**
 * In scenarios where we want to type check several fields in a graph,
 * this order determines an order to use and will omit vertices that are
 * in a cycle and therefore unknown types.
 */
export const getVertexOrder = (graph, includeCycleVertices = false) => {
  const vertex = graph.findKey(v => v.isEmpty());
  if (!vertex) {
    return includeCycleVertices ? graph.keySeq().toList() : List();
  }
  const nextGraph = graph.delete(vertex).map(adjacent => adjacent.filter(v => v !== vertex));
  return List([vertex, ...getVertexOrder(nextGraph, includeCycleVertices).toArray()]);
};
export const getVertexDependencies = (graph, vertex) => {
  const dependencies = [];
  const visited = {
    [vertex]: true
  };
  let stack = Stack([vertex]);
  while (stack.size !== 0) {
    const next = stack.peek();
    stack = stack.pop();
    const adjacent = graph.get(next);
    // @ts-expect-error TS doesn't know that for-of works on List
    for (const v of adjacent) {
      if (!visited[v]) {
        dependencies.push(v);
        visited[v] = true;
        stack = stack.push(v);
      }
    }
  }
  return List(dependencies);
};
export const flattenFieldExpressions = fields => getVertexOrder(fieldsToGraph(fields), false).reduce((nextFields, vertex) => nextFields.update(vertex, field => field.update('expression', expression => expressionUpdater(expression, exp => isFieldExpression(exp) ? nextFields.get(exp.name).expression : exp))), fields);
export const getExpressionDataSources = (expression, meta) => {
  const properties = getExpressionProperties(expression, meta);
  const dataSources = properties.reduce((dataSourceSet, property) => {
    const {
      table
    } = property;
    return dataSourceSet.add(table);
  }, ImmutableSet());
  return dataSources;
};