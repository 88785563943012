'use es6';

import I18n from 'I18n';
import { GLOBAL_NULL } from '../../constants/defaultNullValues';
export const generateSequenceStepOrderLabel = (__identifiers, value) => {
  let label;
  if (value === GLOBAL_NULL) {
    label = I18n.text('reporting-data.references.sequencestep.unknown');
  } else {
    const stepOrder = +value;
    label = I18n.text('reporting-data.references.sequencestep.label', {
      stepNumber: stepOrder + 1 // stepOrder is 0-indexed
    });
  }
  return label;
};