import { fetchGroupedProperties } from './propertiesApi';
import { deepFreeze } from '../cache/deepFreeze';
import { makeGroupsWithPropertiesQuickFetchKey } from 'framework-data-schema-quick-fetch';
import { stringCollator } from '../utils/stringCollator';
import { createInMemoryCache } from '../cache/createInMemoryCache';
import { createPersistedAsyncCache } from '../cache/createPersistedAsyncCache';
import { Metrics } from '../metrics';
import { makeLoadFailed } from '../utils/makeLoadFailed';
import { keyBy } from '../utils/keyBy';
import { handleExtraProcessingResult, processExtraPropertyMetrics } from './extraPropertyMetrics';
import { normalizeCustomIdentifier } from '../utils/normalizeCustomIdentifier';
const defaultAsyncPropertiesRequestCache = createPersistedAsyncCache({
  cacheName: 'properties',
  segmentKey: key => {
    const match = key.match(makeGroupsWithPropertiesQuickFetchKey({
      portalId: '.*',
      frameworkTypeIdentifier: '(.*)'
    }));
    if (!match || !match[1]) {
      return null;
    }
    return normalizeCustomIdentifier(match[1]);
  },
  metrics: {
    extraMetricsProcessing: processExtraPropertyMetrics,
    handleExtraProcessingResult
  }
});
const defaultPropertiesOperationCache = createInMemoryCache({
  cacheName: 'properties-ops'
});
const makeOperationCacheKey = ({
  operationName,
  frameworkTypeIdentifier,
  query,
  portalId
}) => `${makeGroupsWithPropertiesQuickFetchKey({
  portalId,
  frameworkTypeIdentifier,
  query
})}-${operationName}`;
export const makePropertiesClient = ({
  httpClient,
  requestCache = defaultAsyncPropertiesRequestCache,
  operationCache = defaultPropertiesOperationCache,
  toOperationCacheKey = makeOperationCacheKey,
  toRequestCacheKey = makeGroupsWithPropertiesQuickFetchKey
}) => {
  const client = {
    /**
     * Prints debug info to the console.
     */
    debug: () => {
      requestCache.printDebug();
      operationCache.printDebug();
    },
    /**
     * Clears internal cache state.
     *
     * @returns A promise which resolves when state is clear.
     */
    clearCache: async () => {
      await Promise.all([requestCache.clear(), operationCache.clear()]);
    },
    /**
     * Gets all property groups and their properties for a framework type.
     *
     * @param options.frameworkTypeIdentifier A unique id for the framework type, such as `objectTypeId` or `fullyQualifiedName`.
     * @param options.query.showHighlySensitiveProperties Whether highly-sensitive properties should be visible.
     * @param options.refetch Bypasses the cache and triggers a fresh network request, writing the result to the cache.
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to an array of all property groups in this portal, or null if the data could not be found.
     */
    getGroups: ({
      frameworkTypeIdentifier,
      query,
      refetch = false,
      __isComposed = false
    }) => {
      if (!__isComposed) {
        Metrics.counter('properties.getGroups').increment();
      }
      const cacheKey = toRequestCacheKey({
        frameworkTypeIdentifier,
        query
      });
      const cachedValue = requestCache.readThrough({
        cacheKey,
        refresh: refetch,
        loadValue: () => fetchGroupedProperties({
          httpClient,
          frameworkTypeIdentifier,
          query
        }).then(deepFreeze)
      });
      return cachedValue === null ? makeLoadFailed() : cachedValue;
    },
    /**
     * Gets all properties for a framework type (derived from the request for all property groups).
     *
     * Properties are sorted by label || name.
     *
     * @param options.frameworkTypeIdentifier A unique id for the framework type, such as `objectTypeId` or `fullyQualifiedName`.
     * @param options.query.showHighlySensitiveProperties Whether highly-sensitive properties should be visible.
     * @param options.refetch Bypasses the cache and triggers a fresh network request, writing the result to the cache.
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to an array of all properties in this portal, or null if the data could not be found.
     */
    get: ({
      frameworkTypeIdentifier,
      query,
      refetch = false,
      __isComposed = false
    }) => {
      if (!__isComposed) {
        Metrics.counter('properties.get').increment();
      }
      const operationCacheKey = toOperationCacheKey({
        operationName: 'getForType',
        frameworkTypeIdentifier,
        query
      });
      const cachedValue = operationCache.readThrough({
        cacheKey: operationCacheKey,
        refresh: refetch,
        loadValue: () => client.getGroups({
          frameworkTypeIdentifier,
          query,
          refetch,
          __isComposed: true
        }).then(groups => deepFreeze(groups.map(({
          propertyDefinitions
        }) => propertyDefinitions || []).flat(1).sort((a, b) => stringCollator.compare(a.property.label || a.property.name, b.property.label || b.property.name))))
      });
      return cachedValue === null ? makeLoadFailed() : cachedValue;
    },
    /**
     * Gets one property for a framework type. This can be used instead of manually filtering down the response from `.get()`.
     *
     * @param options.frameworkTypeIdentifier A unique id for the framework type, such as `objectTypeId` or `fullyQualifiedName`.
     * @param options.propertyName The property's internal name.
     * @param options.query.showHighlySensitiveProperties Whether highly-sensitive properties should be visible.
     * @param options.refetch Bypasses the cache and triggers a fresh network request, writing the result to the cache.
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to this property, or null if the data could not be found.
     */
    getProperty: ({
      frameworkTypeIdentifier,
      propertyName,
      query,
      refetch = false,
      __isComposed = false
    }) => {
      if (!__isComposed) {
        Metrics.counter('properties.getProperty').increment();
      }
      const propertyMapCacheKey = toOperationCacheKey({
        operationName: 'getForTypeAndName-propertyMap',
        frameworkTypeIdentifier,
        query
      });
      const operationCacheKey = toOperationCacheKey({
        operationName: `getForTypeAndName-${propertyName}`,
        frameworkTypeIdentifier,
        query
      });
      const propertyMapPromise = operationCache.readThrough({
        cacheKey: propertyMapCacheKey,
        refresh: refetch,
        loadValue: () => client.get({
          frameworkTypeIdentifier,
          query,
          refetch,
          __isComposed: true
        }).then(properties => keyBy(properties, ({
          property: {
            name
          }
        }) => name))
      });
      if (propertyMapPromise === null) {
        return makeLoadFailed();
      }
      const cachedValue = operationCache.readThrough({
        cacheKey: operationCacheKey,
        refresh: refetch,
        loadValue: () => propertyMapPromise.then(properties => {
          const property = properties[propertyName];
          if (!property) {
            throw new Error(`Property "${propertyName}" on type "${frameworkTypeIdentifier}" does not exist`);
          }
          return deepFreeze(property);
        })
      });
      return cachedValue === null ? makeLoadFailed() : cachedValue;
    }
  };
  return Promise.resolve(client);
};