'use es6';

import usePollingQuery from './usePollingQuery';
import { CREATE_AND_OWN_USERS_FIELD_NAME, FETCH_CREATE_AND_OWN_USERS } from '../requests/users';
export const useCreateAndOwnUsers = () => {
  const {
    data,
    error,
    loading
  } = usePollingQuery(FETCH_CREATE_AND_OWN_USERS);
  return {
    data: data ? data[CREATE_AND_OWN_USERS_FIELD_NAME] : undefined,
    error,
    loading
  };
};