import { useMemo } from 'react';
import { ANALYTICS_FORMS } from 'reporting-data/constants/dataTypes/unified';
import { BETWEEN, GT, LT } from 'reporting-data/constants/operators';
import { getDataType, getDateRangeFilterProperty, isTimeSeriesReport } from 'reporting-data/report/configReportGetters';
import { getCustomWidgetType } from 'reporting-data/report/reportGetters';
import { CUSTOM_WIDGET_TYPES } from 'reporting-data/tsTypes/customWidgetTypes';
import { isCustomWidgetReport, isUnifiedAnalyticsReport } from 'reporting-data/tsTypes/reportTypes';
import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
import { customWidgetTypeIs } from 'reporting-ui-components/components/customWidgets/utils';
import { DEFAULT_RANGE_TYPES, RANGE_TYPES
// @ts-expect-error Untyped dependency
} from 'reporting-ui-components/components/shared/constants/DateRangeConstants';
const FUTURE_RANGE_TYPES = [RANGE_TYPES.NEXT_DAY, RANGE_TYPES.NEXT_WEEK, RANGE_TYPES.NEXT_MONTH, RANGE_TYPES.NEXT_QUARTER, RANGE_TYPES.NEXT_YEAR];
const UA_DATA_TYPES_COMPATABILE_WITH_RELATIVE_RANGES = [ANALYTICS_FORMS];
export const hasRelativeRangeTypeSupport = report => !isCustomWidgetReport(report) && !isUnifiedAnalyticsReport(report) || UA_DATA_TYPES_COMPATABILE_WITH_RELATIVE_RANGES.includes(getDataType(report));
const hasSecondaryDateRangeSubApp = displayParams => {
  const subAppKey = displayParams === null || displayParams === void 0 ? void 0 : displayParams.getIn(['salesAnalytics', 'subAppKey'], '');
  return [SUB_APPS.changeHistoryDealList, SUB_APPS.waterfall].includes(subAppKey);
};
const hasSecondaryDateRangeCustomWidget = customWidgetType => customWidgetType && [CUSTOM_WIDGET_TYPES.WATERFALL, CUSTOM_WIDGET_TYPES.CHANGE_HISTORY_DEAL_LIST].includes(customWidgetType);
export const hasSecondaryDateRangeEditor = (customWidgetType, displayParams) => hasSecondaryDateRangeCustomWidget(customWidgetType) || hasSecondaryDateRangeSubApp(displayParams);
export const hasFutureRangeTypeSupport = report => customWidgetTypeIs(report, CUSTOM_WIDGET_TYPES.PIPELINE_SNAPSHOTS);
export const useLimitedDateRangeOptions = report => {
  const customWidgetType = getCustomWidgetType(report);
  const intraperiod = report.getIn(['displayParams', 'customWidget', 'options', 'intraperiod']);
  const isTimeSeries = isTimeSeriesReport(report);
  return useMemo(() => {
    if (customWidgetType === CUSTOM_WIDGET_TYPES.PIPELINE_SNAPSHOTS) {
      return Object.values(DEFAULT_RANGE_TYPES).filter(rangeType => !FUTURE_RANGE_TYPES.includes(rangeType));
    }
    if (customWidgetType === CUSTOM_WIDGET_TYPES.SALES_QUOTA) {
      if (isTimeSeries) {
        return intraperiod ? [RANGE_TYPES.CUSTOM_MONTH] : [RANGE_TYPES.CUSTOM_QUARTER, RANGE_TYPES.CUSTOM_YEAR];
      }
      return [RANGE_TYPES.CUSTOM_MONTH, RANGE_TYPES.CUSTOM_QUARTER, RANGE_TYPES.CUSTOM_YEAR];
    }
    return undefined;
  }, [customWidgetType, intraperiod, isTimeSeries]);
};
export const getIsSecondaryDateRangeCustomFilter = (filter, config, customWidgetType, displayParams) => {
  const dateProperty = getDateRangeFilterProperty(config);
  return hasSecondaryDateRangeEditor(customWidgetType, displayParams) && filter.get('property') === dateProperty && [GT, LT, BETWEEN].includes(filter.get('operator'));
};