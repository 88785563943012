'use es6';

import http from 'hub-http/clients/apiClient';
const ANALYTICS_VIEW_URL = '/activities/v2/filters';
const ALL_SUBDOMAIN_URL = '/reports/v1/domains/sources';
const BASIC_SUBDOMAIN_URL = '/cos-domains/v1/domains?casing=snake&offset=0&limit=9999&skipdnscheck=true';

// see https://issues.hubspotcentral.com/browse/RL-223 for original implementation(s)
export const getAnalyticsViews = () => {
  return http.get(ANALYTICS_VIEW_URL).then(views => views.filter(view => {
    return !view.deleteAt;
  }));
};
export const getSubdomainsForSources = (ungatedToAllSubdomains, ungatedToCOSOnly) => {
  const url = ungatedToAllSubdomains ? ALL_SUBDOMAIN_URL : BASIC_SUBDOMAIN_URL;
  return http.get(url).then(response => {
    if (response.objects) {
      return response.objects.filter(domain => domain.is_any_primary && (!ungatedToCOSOnly || !domain.isLegacy));
    }
    return response.filter(domain => domain.showInSources);
  });
};