'use es6';

export const BLOG_AUTHOR_ID = 'BLOG_AUTHOR_ID';
export const CONTENT_GROUP_ID = 'CONTENT_GROUP_ID';
export const CONTENT_TYPE_CODE = 'CONTENT_TYPE_CODE';
export const CREATED_TIMESTAMP = 'CREATED_TIMESTAMP';
export const DOMAIN_ID = 'DOMAIN_ID';
export const DOMAIN = 'DOMAIN';
export const LANGUAGE = 'LANGUAGE';
export const HTML_TITLE = 'HTML_TITLE';
export const NAME = 'NAME';
export const PUBLISH_STATUS_ID = 'PUBLISH_STATUS_ID';
export const PUBLISHED_TIMESTAMP = 'PUBLISHED_TIMESTAMP';
export const TAG_IDS = 'TAG_IDS';
export const URL = 'URL';
export const SCP_CONTENT_TYPE = 'SCP_CONTENT_TYPE';