'use es6';

import { fromJS, List, Map as ImmutableMap } from 'immutable';
import { defaultTo, isNil } from 'dashboard-lib/private/js-util';
import { stringify } from 'hub-http/helpers/params';
import compose from 'transmute/compose';
import filter from 'transmute/filter';
import filterNot from 'transmute/filterNot';
import isEmpty from 'transmute/isEmpty';
import merge from 'transmute/merge';
import toJS from 'transmute/toJS';
import { convertToDateRange, isSearchFilterDateRange } from '../DateConversionUtils';
import { PIPELINE_TYPES, getPipelineFilterFromContext } from '../pipelineContext';
import { FREQUENCY_HISTOGRAM_PATH, CONTEXT_QUERY_KEYS, HS_ANY, OWNERS_PATH, TEAMS_PATH, CONTEXT_PATH, pipelineTypeToQueryParam } from './context-data';
const formatListParam = param => {
  if (isNil(param)) {
    return null;
  }
  if (isEmpty(param)) {
    return HS_ANY;
  }
  if (List.isList(param)) {
    return toJS(param);
  }
  return null;
};
const removeEmptyQueryValues = filter(value => value || value === '');
const getNonContextQueryMap = compose(filterNot((value, key) => CONTEXT_QUERY_KEYS.includes(key)), fromJS, defaultTo({}));
export const toQuery = (context = ImmutableMap(), query = ImmutableMap()) => {
  const nonContextQueryMap = getNonContextQueryMap(query);
  if (isEmpty(context)) {
    return toJS(removeEmptyQueryValues(nonContextQueryMap));
  }
  const dataRangeContext = context.getIn(CONTEXT_PATH);
  const dateRangeQuery = isSearchFilterDateRange(dataRangeContext) ? convertToDateRange(dataRangeContext) : ImmutableMap();
  const owners = formatListParam(context.getIn(OWNERS_PATH));
  const teams = formatListParam(context.getIn(TEAMS_PATH));
  const frequency = context.getIn(FREQUENCY_HISTOGRAM_PATH);
  const dealPipeline = getPipelineFilterFromContext(PIPELINE_TYPES.DEAL, context);
  const ticketPipeline = getPipelineFilterFromContext(PIPELINE_TYPES.TICKET, context);
  const contextQueryMap = ImmutableMap({
    owners,
    teams,
    frequency,
    [pipelineTypeToQueryParam[PIPELINE_TYPES.DEAL]]: dealPipeline,
    [pipelineTypeToQueryParam[PIPELINE_TYPES.TICKET]]: ticketPipeline
  }).merge(dateRangeQuery);
  return compose(toJS, removeEmptyQueryValues, merge(contextQueryMap), merge(nonContextQueryMap))(ImmutableMap());
};
export const stringifyContext = (context = ImmutableMap()) => {
  return stringify(toQuery(context));
};