import isString from 'transmute/isString';
import * as ElasticSearchConstants from 'customer-data-objects/search/ElasticSearchConstants';
// @ts-expect-error Not typed, remove when it is
import CjkCharacterRegExp from 'I18n/constants/regex/CJK';
import { MIN_SEARCH_LENGTH } from 'customer-data-objects/search/ElasticSearchConstants';
// @ts-expect-error Not typed, remove when it is
import KatakanaCharacterRegExp from 'I18n/constants/regex/Katakana';
const invalidESQueries = [/\s+(AND|OR)\s+(AND|OR)\s+/, /^\s*(AND|OR)\s+/, /\s+(AND|OR)\s*$/];
const quotes = /"/g;
function hasUnmatchedQuotes(str) {
  const matches = str.match(quotes);
  if (!matches) {
    return false;
  }
  return matches.length % 2 === 1;
}

/**
 * Returns `true` if `value` _might_ be a valid Elastic Search query string.
 *
 * This is not an exhaustive validation.
 *
 * @example
 * isValidQuery('testing OR wow') === true
 * isValidQuery('omg OR OR') === false
 *
 * @param  {any} value
 * @return {boolean}
 */
export function isValidQuery(value) {
  return isString(value) && value !== '' && !invalidESQueries.some(pattern => pattern.test(value)) && !hasUnmatchedQuotes(value);
}
export const isCjkCharacterContained = (str = '') => Boolean(str) && (CjkCharacterRegExp.test(str) || KatakanaCharacterRegExp.test(str));
export const isOfMinSearchLength = query => !!query && (query.length >= MIN_SEARCH_LENGTH || isCjkCharacterContained(query));
export const getTruncatedQuery = (query = '') => {
  if (query) {
    const splitQuery = query.split(' ');
    const hasTooManyCharacters = query.length > ElasticSearchConstants.SEARCH_LENGTH_LIMIT;
    const hasTooManyWords = splitQuery.length > ElasticSearchConstants.SEARCH_WORD_LIMIT;
    if (hasTooManyCharacters || hasTooManyWords) {
      return splitQuery.splice(0, ElasticSearchConstants.SEARCH_WORD_LIMIT).join(' ').slice(0, ElasticSearchConstants.SEARCH_LENGTH_LIMIT);
    }
  }
  return query;
};