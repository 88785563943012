'use es6';

import { Modifier } from 'draft-js';
export const applyStyles = (contentState, selection, styles) => {
  return styles.reduce((updatedContentState, style) => {
    return Modifier.applyInlineStyle(updatedContentState, selection, style);
  }, contentState);
};
export const removeStyles = (contentState, selection, allInlineStyles) => {
  return allInlineStyles.reduce((updatedContentState, style) => {
    return Modifier.removeInlineStyle(updatedContentState, selection, style);
  }, contentState);
};