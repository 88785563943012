'use es6';

export const get = (spec, config) => {
  const {
    metadata = {}
  } = spec;
  const {
    sort: [{
      property,
      order: sortDir
    } = {}] = []
  } = config;
  const properties = Object.keys(metadata);
  const sort = properties.includes(property) ? `metadata-${property}` : property;
  return {
    sort,
    sortDir
  };
};