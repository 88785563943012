import { STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from './Status';
export const useFetchResolver = (resolver, id) => {
  const {
    useFetchQuery
  } = resolver;
  const results = useFetchQuery(resolver, id === undefined ? undefined : [id]);
  if (results.status === STATUS_SUCCESS) {
    const {
      references
    } = results;
    return {
      status: results.status,
      error: undefined,
      reference: references[0]
    };
  } else if (results.status === STATUS_IDLE) {
    return {
      status: results.status,
      error: results.error,
      reference: undefined
    };
  } else if (results.status === STATUS_LOADING) {
    return {
      status: results.status,
      error: results.error,
      reference: undefined
    };
  }
  return {
    status: results.status,
    error: results.error,
    reference: undefined
  };
};
export const useFetchBatchResolver = (resolver, ids) => {
  const {
    useFetchQuery
  } = resolver;
  return useFetchQuery(resolver, ids);
};
export const usePagedSearchResolver = (resolver, query) => {
  const {
    useSearchQuery
  } = resolver;
  return useSearchQuery(resolver, query);
};