'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { Promise } from '../../lib/promise';
import prefix from '../../lib/prefix';
import { CONTACTS } from '../../constants/dataTypes';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
const translateDataType = prefix('reporting-data.dataTypes');
const translateCommon = prefix('reporting-data.properties.common');
const translate = prefix('reporting-data.properties.events');
const translateGroup = prefix('reporting-data.groups.events');
export const getPropertyGroups = () => Promise.resolve(List([ImmutableMap({
  name: 'eventInfo',
  displayName: translateGroup('eventInfo'),
  displayOrder: 0,
  hubspotDefined: true,
  properties: List([ImmutableMap({
    name: 'event',
    type: 'enumeration',
    label: translate('event')
  }), ImmutableMap({
    name: 'count',
    type: 'number',
    label: translateCommon('count', {
      object: translateDataType(CONTACTS)
    })
  }), ImmutableMap({
    name: 'funnel.conversion',
    type: 'percent',
    label: translateCommon('conversion')
  })])
})]));
export const getProperties = createPropertiesGetterFromGroups(getPropertyGroups);