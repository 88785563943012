import { createTracker } from 'usage-tracker';
import events from 'collaboration-sidebar/events.yaml';
export const UsageTracker = createTracker({
  events,
  properties: {
    namespace: 'collab-sidebar'
  },
  onError: () => {
    // TODO: Add error handling
  }
});