import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["dashboardId"];
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDashboardWithFiltersApplied, validateBEAppliedDashboardFilters } from '../../lib/backend-applied-filters';
export const getOverriddenReportsFromAPIThunk = createAsyncThunk('getOverriddenReportsFromAPI/fetch', action => {
  try {
    const {
        dashboardId
      } = action,
      params = _objectWithoutPropertiesLoose(action, _excluded);
    return getDashboardWithFiltersApplied(dashboardId, params);
  } catch (error) {
    return Promise.reject(error);
  }
});
export const validateBEAppliedDashboardFiltersThunk = createAsyncThunk('validateBEAppliedDashboardFiltersThunk/fetch', action => {
  try {
    const {
      filterSet,
      reportsWithFEAppliedFilters,
      dashboardId
    } = action;
    return validateBEAppliedDashboardFilters(dashboardId, Object.assign({}, filterSet), reportsWithFEAppliedFilters);
  } catch (error) {
    return Promise.reject(error);
  }
});