'use es6';

import { AREA, BAR, COLUMN, LINE } from 'reporting-data/constants/chartTypes';
import { DIMENSIONS } from '../salesAnalyticsHelpers';
const getNewChartType = (currentChartType, newGrouping) => {
  if (newGrouping === DIMENSIONS.DATE) {
    return currentChartType === BAR ? COLUMN : currentChartType;
  }
  return [AREA, LINE].includes(currentChartType) ? BAR : currentChartType;
};
export function updateChartTypeForGrouping(contextChange, context, __report, __subAppKey, __defaultContext, __scopes, __gates, isSettingInitialContext) {
  const newGrouping = contextChange.grouping;
  const newChartType = contextChange.chartType;
  const currentChartType = context.chartType;
  if (!newGrouping || !!newChartType || isSettingInitialContext) {
    return contextChange;
  }
  return Object.assign({}, contextChange, {
    chartType: getNewChartType(currentChartType, newGrouping)
  });
}