'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { Promise } from '../../lib/promise';
import prefix from '../../lib/prefix';
import { TASKS } from '../../constants/dataTypes';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import getCommonPropertyGroups from '../partial/engagement-common';
import countProperty from '../partial/count-property';
import { getOptions } from '../partial/status-options';
const translateGroup = prefix('reporting-data.groups.engagement');
const translateProperty = prefix('reporting-data.properties.tasks');
export const getTaskPropertyGroups = () => Promise.resolve(List.of(ImmutableMap({
  name: 'taskInfo',
  displayName: translateGroup('taskInfo'),
  displayOrder: 0,
  hubspotDefined: true,
  properties: List([ImmutableMap({
    name: 'task.status',
    label: translateProperty('taskStatus.label'),
    type: 'enumeration',
    options: getOptions()
  }), ImmutableMap({
    name: 'task.subject',
    label: translateProperty('taskSubject.label'),
    type: 'string'
  }), ImmutableMap({
    name: 'task.body',
    label: translateProperty('taskBody.label'),
    type: 'string'
  }), ImmutableMap({
    name: 'task.completionDate',
    label: translateProperty('taskCompletionDate.label'),
    type: 'datetime'
  }), ImmutableMap({
    name: 'task.taskType',
    label: translateProperty('taskType.label'),
    type: 'enumeration',
    options: List([ImmutableMap({
      value: 'EMAIL',
      label: translateProperty('taskType.options.EMAIL'),
      displayOrder: -1
    }), ImmutableMap({
      value: 'CALL',
      label: translateProperty('taskType.options.CALL'),
      displayOrder: -1
    }), ImmutableMap({
      value: 'TODO',
      label: translateProperty('taskType.options.TODO'),
      displayOrder: -1
    })])
  })])
})));
export const getPropertyGroups = () => Promise.all([getCommonPropertyGroups(), getTaskPropertyGroups()]).then(([commonPropertyGroups, propertyGroups]) => List([...commonPropertyGroups, ...propertyGroups]));
export const getProperties = createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(TASKS)));