'use es6';

import { createTracker as createPublicTracker } from 'usage-tracker';
import draftEvents from 'draft-plugins/events.yaml';
export function createTracker(config = {}) {
  return createPublicTracker(Object.assign({
    trackerName: 'draftjs-tracker',
    events: draftEvents,
    properties: {
      textLibrary: 'draft.js'
    }
  }, config));
}