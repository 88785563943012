import { useEffect, useMemo } from 'react';
import useCollaborationSidebarState from '../../../hooks/useCollaborationSidebarState';
export function useEmbeddedCommentPopover({
  iframeContainerID,
  popoverContentWrapperRef,
  closeEmbeddedCommentPopover
}) {
  const {
    commentableAreaClickCoordinates,
    isEmbeddedPopoverOpen,
    embeddedCommentCoordinates
  } = useCollaborationSidebarState();
  const dimensionsAndCoordinates = useMemo(() => {
    const iframeArea = iframeContainerID ? document.getElementById(iframeContainerID) : null;
    const iframeRect = iframeArea === null || iframeArea === void 0 ? void 0 : iframeArea.getBoundingClientRect();
    if (isEmbeddedPopoverOpen) {
      return {
        top: iframeRect ? embeddedCommentCoordinates.top + iframeRect.top : embeddedCommentCoordinates.top,
        left: iframeRect ? embeddedCommentCoordinates.left + iframeRect.left : embeddedCommentCoordinates.left,
        width: embeddedCommentCoordinates.width,
        height: embeddedCommentCoordinates.height
      };
    } else {
      return {
        top: 0,
        left: 0,
        width: 0,
        height: 0
      };
    }
  }, [embeddedCommentCoordinates, isEmbeddedPopoverOpen, iframeContainerID]);
  useEffect(() => {
    var _popoverContentWrappe;
    if (!isEmbeddedPopoverOpen || !commentableAreaClickCoordinates) {
      return;
    }
    const popoverRect = (_popoverContentWrappe = popoverContentWrapperRef.current) === null || _popoverContentWrappe === void 0 ? void 0 : _popoverContentWrappe.getBoundingClientRect();
    const iframeArea = iframeContainerID ? document.getElementById(iframeContainerID) : null;
    const iframeAreaRect = iframeArea === null || iframeArea === void 0 ? void 0 : iframeArea.getBoundingClientRect();
    const absoulteCoordinates = {
      x: commentableAreaClickCoordinates.x + ((iframeAreaRect === null || iframeAreaRect === void 0 ? void 0 : iframeAreaRect.left) || 0),
      y: commentableAreaClickCoordinates.y + ((iframeAreaRect === null || iframeAreaRect === void 0 ? void 0 : iframeAreaRect.top) || 0)
    };
    if (popoverRect && commentableAreaClickCoordinates) {
      if (absoulteCoordinates.x < popoverRect.left || absoulteCoordinates.x > popoverRect.right || absoulteCoordinates.y < popoverRect.top || absoulteCoordinates.y > popoverRect.bottom) {
        closeEmbeddedCommentPopover();
      }
    }
  }, [isEmbeddedPopoverOpen, commentableAreaClickCoordinates, closeEmbeddedCommentPopover, iframeContainerID, popoverContentWrapperRef]);
  return {
    dimensionsAndCoordinates
  };
}