'use es6';

import chunk from '../../lib/async/chunk';
import toJS from '../../lib/toJS';
import * as http from '../../request/http';
export const get = () => http.get('owners/v2/owners').then(toJS);

/**
 * Temporary & partial fix for this JIRA: https://issues.hubspotcentral.com/browse/RA-14148The ids are coming from a response to a crm-search request, however, the crm-search endpoint currently does notvalidate the hubspot_owner_id and there's some (very edge)cases where the report shows the owner label as"unknown owner".
 * The reason for this constant and its value is because we want to filter out invalid Nums from the list of owner ids since they don't get validated by the crm (for now)
 * CRM issue to validate hubspot_owner_id: https://git.hubteam.com/HubSpot/crm-data-backend-team/issues/56
 */
const MAX_INT = 2147483647;
export const batch = ids => {
  const filteredIds = ids.filter(id => isNaN(id) || id <= MAX_INT);
  return chunk(group => http.get('owners/v2/owners/batch-v2', {
    query: {
      ownerId: group.toArray(),
      includeSignature: false
    }
  }).then(toJS), responses => responses.reduce((memo, response) => Object.assign({}, memo, response), {}), filteredIds);
};