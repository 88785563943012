'use es6';

export const SECONDS = 'seconds';
export const MINUTES = 'minutes';
export const HOURS = 'hours';
export const DAYS = 'days';
export const getDefaultUnit = duration => {
  if (duration % (24 * 60 * 60 * 1000) === 0) {
    return DAYS;
  }
  if (duration % (60 * 60 * 1000) === 0) {
    return HOURS;
  }
  if (duration % (60 * 1000) === 0) {
    return MINUTES;
  }
  return SECONDS;
};
export const formatDurationToUnit = (duration, unit) => {
  if (duration === undefined) {
    return undefined;
  }
  switch (unit) {
    case DAYS:
      return duration / 1000 / 60 / 60 / 24;
    case HOURS:
      return duration / 1000 / 60 / 60;
    case MINUTES:
      return duration / 1000 / 60;
    case SECONDS:
    default:
      return duration / 1000;
  }
};
export const formatDurationToMilliseconds = (duration, unit) => {
  switch (unit) {
    case DAYS:
      return duration * 1000 * 60 * 60 * 24;
    case HOURS:
      return duration * 1000 * 60 * 60;
    case MINUTES:
      return duration * 1000 * 60;
    case SECONDS:
    default:
      return duration * 1000;
  }
};