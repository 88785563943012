import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// @ts-expect-error migrate upstream
import { selectHasCustomReportingAcccess } from 'dashboard-lib/public/userInfo/scopes';
// @ts-expect-error migrate upstream
import { selectAllScopes } from 'dashboard-lib/public/userInfo';
import { usePlatformDashboardSelector, usePlatformDashboardDispatch } from 'platform-dashboard-ui/store';
import { RequestState } from 'platform-dashboard-ui/filter/requests/request-state';
import { fetchDataSourcesProperties, selectDataSourcesPropertiesStatus } from 'platform-dashboard-ui/ducks/dataSourcePropertiesSlice';
import { selectDataSourcesMetadataStatus, fetchDataSourcesMetadata } from 'platform-dashboard-ui/ducks/dataSourceMetadataSlice';
const useFetchDataSources = dataSourceKeys => {
  const hasCustomReportingAccess = useSelector(selectHasCustomReportingAcccess);
  const scopes = useSelector(selectAllScopes);
  const platformDispatch = usePlatformDashboardDispatch();
  const dataSourcesPropertiesStatus = usePlatformDashboardSelector(selectDataSourcesPropertiesStatus(dataSourceKeys));
  const dataSourcesMetadataStatus = usePlatformDashboardSelector(selectDataSourcesMetadataStatus(dataSourceKeys));
  useEffect(() => {
    platformDispatch(fetchDataSourcesProperties(dataSourceKeys, hasCustomReportingAccess, scopes));
    platformDispatch(fetchDataSourcesMetadata(dataSourceKeys));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceKeys, hasCustomReportingAccess, platformDispatch, scopes]);
  if (dataSourcesMetadataStatus === RequestState.FAILED || dataSourcesPropertiesStatus === RequestState.FAILED) {
    return RequestState.FAILED;
  }
  if (dataSourcesMetadataStatus === RequestState.SUCCEEDED && dataSourcesPropertiesStatus === RequestState.SUCCEEDED) {
    return RequestState.SUCCEEDED;
  }
  return RequestState.PENDING;
};
export default useFetchDataSources;