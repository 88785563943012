'use es6';

import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as OperatorTypes from '../ObjectSegOperatorTypes';
import * as Operators from '../../../filterQueryFormat/operator/Operators';
import getIn from 'transmute/getIn';
import invariant from 'react-utils/invariant';
import protocol from 'transmute/protocol';
const _toSurveyMonkeySurveyFilter = (operatorName, operator) => ({
  filterType: ObjectSegFilterTypes.SURVEY_MONKEY,
  operator: operatorName,
  surveyId: getIn(['field', 'name'], operator)
});
const invalidOperator = operator => {
  invariant(false, 'cannot convert Operator "%s" to Survey Monkey Survey ObjectSeg filter', operator.name);
};
const toSurveyMonkeySurveyFilter = protocol({
  name: 'toWebinarFilter',
  args: [protocol.TYPE],
  fallback: invalidOperator
});
toSurveyMonkeySurveyFilter.implement(Operators.FormFilledOut, _toSurveyMonkeySurveyFilter);
toSurveyMonkeySurveyFilter.implement(Operators.SurveyMonkeyHasResponded, operator => _toSurveyMonkeySurveyFilter(OperatorTypes.HAS_RESPONDED_TO_SURVEY, operator));
toSurveyMonkeySurveyFilter.implement(Operators.SurveyMonkeyNotHasResponded, operator => _toSurveyMonkeySurveyFilter(OperatorTypes.HAS_NOT_RESPONDED_TO_SURVEY, operator));
export default toSurveyMonkeySurveyFilter;