import { convertGroupOperatorsToDashboardFilters, convertToDashboardFilter } from '../../filter/utils/filterConversionHelpers';
import { getName } from '../../filter/utils/schema/propertySchema';
import { areFiltersDataSourceAndPropertyNameSame } from '../dashboardFilters/types';

// Helper functions to manipulate the filters object in the store.
export const getPropertyObj = filterData => {
  return {
    name: getName(filterData.operator.get('field')),
    dataSource: filterData.dataSource
  };
};
export const updateFiltersOnAddFilter = (filters, filterData) => {
  const newFilter = convertToDashboardFilter(filterData);
  const updatedFilters = filters.concat(newFilter);
  return updatedFilters;
};
export const updateFiltersOnUpdateFilter = (filters, index, filterData) => {
  const updatedFilters = Array.from(filters);
  updatedFilters[index] = convertToDashboardFilter(filterData);
  return updatedFilters;
};
export const bulkUpdateFiltersOnAddFilter = (filters, quickFilterGroupOperators, systemGenerated, quickFilterGroupName) => {
  const quickFilterGroupDashboardFiltersToApply = convertGroupOperatorsToDashboardFilters({
    quickFilterGroupOperators,
    systemGenerated,
    quickFilterGroupName
  });
  const updatedFilters = filters.filter(currentAppliedFilter => !quickFilterGroupDashboardFiltersToApply.some(nextGroupedFilter => areFiltersDataSourceAndPropertyNameSame(nextGroupedFilter, currentAppliedFilter))).concat(quickFilterGroupDashboardFiltersToApply);
  return updatedFilters;
};
export const updateFiltersOnRemoveFilter = (filters, index) => {
  const updatedFilters = Array.from(filters);
  updatedFilters.splice(index, 1);
  return updatedFilters;
};
export const updateFilterOnRemoveFilterByName = (filters, dataSource, propertyName) => filters.filter(appliedFilter => !(appliedFilter.dataSource === dataSource && appliedFilter.propertyName === propertyName));
export const bulkUpdateFiltersOnRemoveFilter = (filters, filtersToRemove) => {
  const updatedFilters = filtersToRemove.reduce((acc, filterToRemove) => {
    const {
      dataSourceId,
      name
    } = filterToRemove;
    return acc.filter(appliedFilter => !(appliedFilter.dataSource === dataSourceId && appliedFilter.propertyName === name));
  }, filters);
  return updatedFilters;
};
export const checkIfPropertyExists = (filters, property) => {
  return filters.some(filter => filter.propertyName === property.name && filter.dataSource === property.dataSource);
};
export const groupFiltersByDataSource = filters => {
  const filtersGroupedByDataSource = filters.reduce((nextGroupedFilters, filter, originalIndex) => {
    const dataSource = filter.dataSource;
    if (nextGroupedFilters[dataSource]) {
      nextGroupedFilters[dataSource].push({
        filter,
        originalIndex
      });
    } else {
      nextGroupedFilters[dataSource] = [{
        filter,
        originalIndex
      }];
    }
    return nextGroupedFilters;
  }, {});
  return filtersGroupedByDataSource;
};
export const getDataSourceKeysForFilters = filters => {
  return Object.keys(groupFiltersByDataSource(filters));
};