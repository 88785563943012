// @ts-expect-error untyped dependency
import { indexAndMap } from 'dashboard-lib/private/js-util';
import keyMirror from 'react-utils/keyMirror';
import get from 'transmute/get';
import { createUrlUnsafe } from '../../lib/pathUtils';
// @ts-expect-error untyped dependency
import { getRootDashboardRoute } from 'dashboard-components/lib/urlHelpers';
// ********** PUBLIC EVENT **********
// Public Events help teams across HubSpot automate work and customize experiences based on user actions.
// Speak with #product-insight and your PM before any shipping any changes to this event incl. event name, properties, values, and when it occurs.
export const RouteKeys = keyMirror({
  DASHBOARD: null,
  EXPORT: null,
  PERMISSION_REQUIRED: null,
  EMAIL_UNSUBSCRIBE: null
});
export const RouteConfigs = [{
  key: RouteKeys.DASHBOARD,
  name: 'dashboard',
  // DO NOT CHANGE- This is used for tracking a public event
  path: '/view/:dashboardId?/:reportId?'
}, {
  key: RouteKeys.EXPORT,
  name: 'export-dashboard',
  path: '/export/dashboard/:dashboardId'
}, {
  key: RouteKeys.PERMISSION_REQUIRED,
  name: 'permission-required',
  path: '/permission-required'
}, {
  key: RouteKeys.EMAIL_UNSUBSCRIBE,
  name: 'email-unsubscribe',
  path: '/email-unsubscribe'
}];
const getKey = get('key');
const getPath = get('path');
const getName = get('name');
export const Routes = indexAndMap(getKey, getPath, RouteConfigs);
export const PathToName = indexAndMap(getPath, getName, RouteConfigs);
export const getRoutePath = routeKey => get(routeKey, Routes);
export const createDashboardUrl = param => createUrlUnsafe(Object.assign({}, param, {
  basename: getRootDashboardRoute()
}));