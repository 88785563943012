export default {
  buckets: 'add',
  date: 'date',
  datetime: 'date',
  timestamp: 'date',
  enumeration: 'add',
  string: 'edit',
  DYNAMIC_LIST: 'refresh',
  STATIC_LIST: 'bulletList',
  '@@@NONE@@@': 'remove',
  NONE: 'remove',
  DELETE: 'remove',
  ADD: 'add',
  viewable: 'view',
  private: 'locked',
  editable: 'contacts',
  owner: 'contacts',
  actions: 'verticalMenu',
  rename: 'edit',
  edit: 'edit',
  clone: 'duplicate',
  remove: 'delete',
  editGoals: 'edit',
  goToView: 'externalLink',
  goToList: 'externalLink',
  info: 'info',
  valid: 'success',
  invalid: 'remove',
  settings: 'settings',
  favorite: 'favorite',
  addedToDashboard: 'success',
  completed: 'success',
  back: 'left',
  left: 'left',
  right: 'right',
  drillDownCancel: 'left',
  drillDownExport: 'download',
  up: 'up',
  down: 'down',
  lock: 'locked',
  docs: 'documents',
  help: 'info',
  backToDashboard: 'left',
  addReport: 'add',
  email: 'email',
  sendEmail: 'email',
  manageSchedule: 'inbox',
  export: 'upload',
  undo: 'undo',
  close: 'remove',
  editFilter: 'edit',
  deleteFilter: 'remove',
  addFilter: 'add',
  DEAL: 'deals',
  ENGAGEMENT: 'email',
  CONTACT: 'contacts',
  COMPANY: 'companies',
  EMAIL: 'email',
  CALL: 'calling',
  MEETING: 'time',
  EMAIL_SUMMARY: 'emailOpen',
  SOURCE_PERFORMANCE: 'contacts',
  EMAIL_PERFORMANCE: 'emailOpen'
};