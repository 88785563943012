'use es6';

import I18n from 'I18n';
import { Map as ImmutableMap } from 'immutable';
import { GLOBAL_NULL } from '../constants/defaultNullValues';
import extractUniqueValues from '../dataset/extract-unique-values';
import { Promise } from '../lib/promise';
import { formatForScientificNotation } from '../v2/dataset/utils';
import { makeOption } from './Option';
export const adapt = get => (config, property, data, response, maybeExactIds, {
  excludeNull = false,
  overwrittenNullLabel
} = {}) => {
  const ids = (maybeExactIds || extractUniqueValues(property, data)).map(formatForScientificNotation);
  if (ids.isEmpty()) {
    return Promise.resolve(ImmutableMap({
      key: property,
      value: ImmutableMap()
    }));
  }
  if (excludeNull) {
    return get(ids.filter(id => id !== GLOBAL_NULL)).then(options => {
      return ImmutableMap({
        key: property,
        value: options.set(GLOBAL_NULL, makeOption(GLOBAL_NULL, overwrittenNullLabel || I18n.text('reporting-data.missing.value')))
      });
    });
  }
  return get(ids).then(options => ImmutableMap({
    key: property,
    value: options
  }));
};