'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["resolverOptions"];
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import * as CrmObjectsAPI from 'reference-resolvers/api/CrmObjectsAPI';
import { createFetchSearchPage, fetchSearchPage } from 'reference-resolvers/api/CrmSearchAPI';
import { OBJECT_COORDINATES } from 'reference-resolvers/constants/ReferenceObjectTypes';
import { getDefaultGettersByObjectTypeId } from 'reference-resolvers/formatters/formatCustomObjects';
import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import PortalIdParser from 'PortalIdParser';
const createCustomObjectKey = (key, objectTypeId) => `${key}_${objectTypeId}`;
export const toCustomReferenceObjectType = objectTypeId => createCustomObjectKey(OBJECT_COORDINATES, objectTypeId);
export const getFormatterOptions = (objectTypeId, formatterOptions = {}) => Object.assign({}, formatterOptions, {
  getters: Object.assign({}, getDefaultGettersByObjectTypeId(objectTypeId), formatterOptions.getters)
});
const objectCoordinatesToObjectIds = objectCoordinates => {
  return objectCoordinates.map(id => {
    const parts = id.split('-');
    return parts[parts.length - 1];
  });
};
const makeObjectIdToObjectCoordinateConverterForObjectType = objectTypeId => {
  return ({
    objectId
  }) => `${PortalIdParser.get()}-${objectTypeId}-${objectId}`;
};
export const createObjectCoordinatesReferenceResolver = (objectTypeId, {
  resolverOptions,
  fetchQueryParams,
  searchOptions,
  searchQueryParams,
  formatterOptions: options
} = {}) => {
  options = options || {};
  options.getters = options.getters || {};
  options.getters.getId = makeObjectIdToObjectCoordinateConverterForObjectType(objectTypeId);
  const formatterOptions = getFormatterOptions(objectTypeId, options);
  return createBatchedReferenceResolver(Object.assign({
    cacheKey: createCustomObjectKey(CacheKeys.OBJECT_COORDINATES, objectTypeId),
    maxBatchSize: 200,
    createFetchByIds: opts => {
      const fetchByIdsFn = CrmObjectsAPI.createFetchByIds(Object.assign({}, opts))(objectTypeId, {
        fetchQueryParams,
        formatterOptions
      });
      return objectCoordinates => {
        const objectIds = objectCoordinatesToObjectIds(objectCoordinates);
        return fetchByIdsFn(objectIds);
      };
    },
    createFetchSearchPage: opts => createFetchSearchPage(opts)(objectTypeId, {
      searchOptions,
      searchQueryParams,
      formatterOptions
    }),
    fetchByIds: (() => {
      const fetchByIdsFn = CrmObjectsAPI.fetchByIds(objectTypeId, {
        fetchQueryParams,
        formatterOptions
      });
      return objectCoordinates => {
        const objectIds = objectCoordinatesToObjectIds(objectCoordinates);
        return fetchByIdsFn(objectIds);
      };
    })(),
    fetchSearchPage: fetchSearchPage(objectTypeId, {
      searchOptions,
      searchQueryParams,
      formatterOptions
    })
  }, resolverOptions));
};
export default ((objectTypeId, options) => {
  const opts = _objectWithoutPropertiesLoose(options, _excluded);
  return createObjectCoordinatesReferenceResolver(objectTypeId, opts);
});