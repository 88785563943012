'use es6';

import { ENGAGEMENT } from '../../constants/dataTypes/inboundDb';
import * as http from '../../request/http';
import pick from 'transmute/pick';
const REQUEST_URL = `/properties/v4/${ENGAGEMENT}/named/hs_engagement_type`;
export const getEngagementTypeOptions = () => http.get(REQUEST_URL).then(response => {
  const options = response.getIn(['property', 'options']).filter(option => option.get('value') !== 'PUBLISHING_TASK');
  return options.map(pick(['value', 'label', 'displayOrder']));
});
export const __TESTABLE__ = {
  REQUEST_URL
};