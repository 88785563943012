export const ALL = 'alltypes';
export const STRING = 'string';
export const MULTI_STRING = 'multistring';
export const NUMBER = 'number';
export const BOOLEAN = 'bool';
export const ENUMERATION = 'enumeration';
export const DATE_TIME = 'datetime';
export const DATE_TIME_COMPARATIVE = 'datetime-comparative';
export const NUMBER_RANGED = 'number-ranged';
export const DATE_TIME_RANGED = 'datetime-ranged';
export const DATE_TIME_ROLLING = 'datetime-rolling';
export const PROPERTY_UPDATED_COMPARATIVE = 'property-updated-comparative';
export const ROLLING_PROPERTY_UPDATED = 'rolling-property-updated';
export const CALENDAR_DATE = 'calendar-date';

// ObjectSeg time operation types
export const RANGED_TIME = 'rangedtime';
export const TIME_POINT = 'timepoint';