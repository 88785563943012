'use es6';

import { sunday } from './makeDateRangeByType/uses';
import { WEEK } from '../constants/frequency';
import { get as getMomentUnit } from './makeDateRangeByType/units';
import { applyPeriodEndDateFiscalYearOffset, applyPeriodStartDateFiscalYearOffset } from './fiscalYearUtils';
import { DEFAULT_FISCAL_YEAR_MONTH_NAME } from '../constants/dates';
export function startOf({
  date: initialDate,
  dataType,
  frequency,
  useFiscalYear = false,
  fiscalYearMonthName = DEFAULT_FISCAL_YEAR_MONTH_NAME
}) {
  const date = initialDate.clone();
  const initialMonth = date.locale('en').format('MMMM');
  const {
    period
  } = getMomentUnit(frequency, false);
  if (!dataType || frequency !== WEEK) {
    return useFiscalYear ? applyPeriodStartDateFiscalYearOffset(date.startOf(period), fiscalYearMonthName, frequency, initialMonth) : date.startOf(period);
  }
  if (!sunday.includes(dataType)) {
    return date.startOf('isoWeek');
  }

  // isoWeekday() returns a 1-index day-of-the-week count for monday-based-weeks
  // isoWeekday() modulo 7 thus returns the 0-index-based day-of-the-week count for sunday-based-weeks
  const dayInSundayWeek = date.isoWeekday() % 7;
  date.subtract(dayInSundayWeek, 'days');
  return date.startOf('day');
}
export function endOf({
  date: initialDate,
  dataType,
  frequency,
  useFiscalYear = false,
  fiscalYearMonthName = DEFAULT_FISCAL_YEAR_MONTH_NAME
}) {
  const date = initialDate.clone();
  const initialMonth = date.locale('en').format('MMMM');
  const {
    period
  } = getMomentUnit(frequency, false);
  if (!dataType || frequency !== WEEK) {
    return useFiscalYear ? applyPeriodEndDateFiscalYearOffset(date.endOf(period), fiscalYearMonthName, frequency, initialMonth, true) : date.endOf(period);
  }
  if (!sunday.includes(dataType)) {
    return date.endOf('isoWeek');
  }

  // isoWeekday() returns a 1-index day-of-the-week count for monday-based-weeks
  // isoWeekday() modulo 7 thus returns the 0-index-based day-of-the-week count for sunday-based-weeks
  const dayInSundayWeek = date.isoWeekday() % 7;
  date.add(6 - dayInSundayWeek, 'days'); // diff between saturday and current day
  return date.endOf('day');
}