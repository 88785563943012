'use es6';

import * as DSAssetFamilies from 'customer-data-filters/filterQueryFormat/DSAssetFamilies/DSAssetFamilies';
import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import { Map as ImmutableMap } from 'immutable';
import * as ObjectSegConstants from './constants/constants';
const filterTypesToAssetsFamilies = ImmutableMap({
  [ObjectSegFilterTypes.ADS_SEARCH]: DSAssetFamilies.ADS_PROPERTY,
  [ObjectSegFilterTypes.ADS_TIME]: DSAssetFamilies.ADS_PROPERTY,
  [ObjectSegFilterTypes.CTA]: DSAssetFamilies.CTA,
  [ObjectSegFilterTypes.CONSTANT]: ObjectSegConstants.DEPRECATED_FILTER,
  [ObjectSegFilterTypes.PRIVACY_CONSENT]: DSAssetFamilies.PRIVACY_CONSENT,
  [ObjectSegFilterTypes.EVENT]: DSAssetFamilies.EVENT,
  [ObjectSegFilterTypes.EMAIL_EVENT]: DSAssetFamilies.EMAIL_CAMPAIGN,
  [ObjectSegFilterTypes.EMAIL_SUBSCRIPTION]: DSAssetFamilies.EMAIL_SUBSCRIPTION,
  [ObjectSegFilterTypes.FORM_SUBMISSION]: DSAssetFamilies.FORM,
  [ObjectSegFilterTypes.FORM_SUBMISSION_ON_PAGE]: DSAssetFamilies.FORM,
  [ObjectSegFilterTypes.PAGE_VIEW]: DSAssetFamilies.PAGE_VIEW,
  [ObjectSegFilterTypes.SURVEY_MONKEY]: DSAssetFamilies.SURVEY_MONKEY_SURVEY,
  [ObjectSegFilterTypes.WEBINAR]: DSAssetFamilies.GOTO_WEBINAR_WEBINAR,
  [DSAssetFamilies.CONTACT]: DSAssetFamilies.CONTACT_PROPERTY,
  [ObjectSegFilterTypes.IN_LIST]: DSAssetFamilies.IN_LIST,
  [ObjectSegFilterTypes.INTEGRATION_EVENT]: DSAssetFamilies.INTEGRATION,
  [ObjectSegFilterTypes.IN_IMPORT]: DSAssetFamilies.IMPORT,
  [ObjectSegFilterTypes.WORKFLOW]: DSAssetFamilies.WORKFLOW,
  [ObjectSegFilterTypes.SURVEY_MONKEY_VALUE]: DSAssetFamilies.SURVEY_MONKEY_QUESTION,
  [ObjectSegFilterTypes.ASSOCIATION]: ObjectSegConstants.MISSING_ASSOCIATION_BRANCH_FILTER
});
export const getDSAssetFamilyForType = filterType => filterTypesToAssetsFamilies.get(filterType);