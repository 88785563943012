import { List } from 'immutable';
import { DataSourceTypes } from '../schema/source-records';
import * as ObjectTypes from './objectTypes';
import { getEventLikeTables, getTableList, isTableEventLike } from '../utils/table-utils';
export const MAXIMUM_NUMBER_OF_TABLES = 5;
export const MAXIMUM_NUMBER_OF_EVENT_TABLES = 1;
const ObjectTypesExcludedFromLimit = List.of(ObjectTypes.CAMPAIGN, ObjectTypes.AD_CAMPAIGN, ObjectTypes.AD_GROUP, ObjectTypes.AD_ACCOUNT, ObjectTypes.KEYWORD);
export const getNumberOfTablesIncludedInLimit = tableDescription => getTableList(tableDescription).filterNot(table => {
  if ((isTableEventLike(table) || table.type === DataSourceTypes.HUBSPOT_OBJECT) && table.objectTypeId) {
    return ObjectTypesExcludedFromLimit.includes(table.objectTypeId);
  }
  return false;
}).size;
export const atTableLimit = tableDescription => getNumberOfTablesIncludedInLimit(tableDescription) === MAXIMUM_NUMBER_OF_TABLES;
export const withinTableLimit = tableDescription => getNumberOfTablesIncludedInLimit(tableDescription) <= MAXIMUM_NUMBER_OF_TABLES;
export const withinEventLimit = tableDescription => getEventLikeTables(tableDescription).size <= MAXIMUM_NUMBER_OF_EVENT_TABLES;