'use es6';

import usePollingQuery from './usePollingQuery';
import { FETCH_RECENT_DASHBOARDS, RECENT_DASHBOARDS_FIELD_NAME } from '../requests/recentlyViewedAssets';
export const useRecentlyViewedDashboards = () => {
  const {
    data,
    error,
    loading
  } = usePollingQuery(FETCH_RECENT_DASHBOARDS);
  return {
    data: data ? data[RECENT_DASHBOARDS_FIELD_NAME] : undefined,
    error,
    loading
  };
};