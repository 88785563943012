'use es6';

import { ContentState, EditorState, SelectionState } from 'draft-js';
export default ((blockKey, editorState) => {
  const currentContent = editorState.getCurrentContent();
  if (!currentContent.getBlockForKey(blockKey)) {
    return editorState;
  }
  const updatedBlockMap = currentContent.getBlockMap().delete(blockKey);
  const updatedContent = ContentState.createFromBlockArray(updatedBlockMap.toArray());
  let updatedSelection = SelectionState.createEmpty();
  const precedingBlockKey = currentContent.getKeyBefore(blockKey);
  if (precedingBlockKey) {
    const precedingBlock = currentContent.getBlockForKey(precedingBlockKey);
    const offset = precedingBlock ? precedingBlock.getLength() : 0;
    updatedSelection = SelectionState.createEmpty(precedingBlockKey).merge({
      anchorOffset: offset,
      focusOffset: offset
    });
  }
  const updatedEditorState = EditorState.push(editorState, updatedContent, 'remove-range');
  return EditorState.forceSelection(updatedEditorState, updatedSelection);
});