import { BOOLEAN, CURRENCY, DATE, DATE_TIME, DURATION, ENUMERATION, NUMBER, PERCENT, STRING } from 'reporting-data/constants/property-types';
import { getPropertyType } from 'reporting-data/properties/propertyGetters';
import { isDateOrDateTime, isDurationDateProperty } from 'reporting-data/properties/propertyUtils';
import { isUnifiedAnalyticsReport } from 'reporting-data/tsTypes/reportTypes';
export const getIsValidDimensionProperty = property => {
  if (isDateOrDateTime(property)) {
    return !isDurationDateProperty(property);
  }
  const propertyType = getPropertyType(property);
  return [STRING, ENUMERATION, BOOLEAN, CURRENCY].includes(propertyType);
};
export const getIsValidConfigMetricProperty = (property, report) => {
  const propertyType = getPropertyType(property);
  const isUnifiedAnalytics = isUnifiedAnalyticsReport(report);
  if (isUnifiedAnalytics) {
    return [ENUMERATION, STRING, DATE, DATE_TIME].includes(propertyType);
  }
  if (isDateOrDateTime(property)) {
    return isDurationDateProperty(property);
  }
  return [NUMBER, PERCENT, CURRENCY, DURATION].includes(propertyType);
};