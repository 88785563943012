import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  properties: {}
};
export const platformDataSourceMetadataSliceNamespace = 'dataSourceMetadataView';

/** exposes a complete view of all successfully resolved properties within the platform-dashboard-ui store */
const platformDataSourceMetadataSlice = createSlice({
  name: platformDataSourceMetadataSliceNamespace,
  initialState,
  reducers: {
    updateProperties: (state, action) => {
      const {
        payload: {
          properties
        }
      } = action;
      state.properties = properties;
    }
  }
});
export const selectDataSourcePropertiesView = state => state[platformDataSourceMetadataSliceNamespace].properties;
export const platformDataSourceMetadataActions = platformDataSourceMetadataSlice.actions;
export const platformDataSourceMetadataReducer = platformDataSourceMetadataSlice.reducer;