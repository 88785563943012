import { registerQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
import I18n from 'I18n';

/** Indexed by dataSourceId */

// legacy Requests
export const getDataSourceMetadata = objectTypeId => http.get(`inbounddb-meta/v1/object-types/${objectTypeId}`).then(metadata => {
  const metadataWithLabel = Object.assign({
    label: metadata.pluralForm || metadata.singularForm || I18n.text(`reporting-snowflake.source-labels.${metadata.fullyQualifiedName}.other`)
  }, metadata);
  return metadataWithLabel;
});

// TODO: ideally we will start using DFC intead of the function above
export const GET_DATASOURCE_META = registerQuery({
  fieldName: 'dataSourceMetadata',
  args: ['objectTypeId'],
  fetcher({
    objectTypeId
  }) {
    return http.get('inbounddb-meta/v1/object-types/', {
      query: {
        objectTypeId
      }
    }).then(metadata => {
      const metadataWithLabel = Object.assign({
        label: metadata.pluralForm || metadata.singularForm || I18n.text(`reporting-snowflake.source-labels.${metadata.fullyQualifiedName}.other`)
      }, metadata);
      return metadataWithLabel;
    });
  }
});