export const keyEntriesByAppSettings = entries => {
  return Object.entries(entries).reduce((mappedEntries, [fullyQualifiedName, appSettings]) => {
    Object.entries(appSettings).forEach(([appSettingName, metadata]) => {
      if (!mappedEntries[appSettingName]) {
        mappedEntries[appSettingName] = {};
      }
      if (!mappedEntries[appSettingName][fullyQualifiedName]) {
        mappedEntries[appSettingName][fullyQualifiedName] = metadata;
      }
    });
    return mappedEntries;
  }, {});
};
export const keyEntriesByFQN = entries => {
  return Object.entries(entries).reduce((mappedEntries, [appSettingName, typeEntries]) => {
    Object.entries(typeEntries).forEach(([fullyQualifiedName, metadata]) => {
      if (!mappedEntries[fullyQualifiedName]) {
        mappedEntries[fullyQualifiedName] = {};
      }
      if (!mappedEntries[fullyQualifiedName][appSettingName]) {
        mappedEntries[fullyQualifiedName][appSettingName] = metadata;
      }
    });
    return mappedEntries;
  }, {});
};