import * as checked from 'reporting-data/lib/checked';
import { Column, Encoding, ExpressionField } from './column-records';
import { FieldRef } from './field-ref';
import { DateComparisonFilter, EventDateInterval, Filtering } from './filter-records';
import { Sort } from './sort-records';
import { TableDescription } from './table-records';
import { Visual } from './visual-records';
import { LimitForQuery } from './limit-records';
export const RELATIONAL = 'RELATIONAL';
export const RelationalReport = checked.record({
  type: checked.string().always(RELATIONAL),
  table: TableDescription,
  filtering: Filtering.optional(),
  columns: checked.map(Column),
  sorts: checked.list(Sort).defaultValue([]),
  visual: Visual.optional(),
  eventDateInterval: EventDateInterval.optional(),
  dateComparisonFilter: DateComparisonFilter.optional(),
  stagedEncodings: checked.list(Encoding).defaultValue([]),
  // TODO: remove when ready
  stagedFields: checked.list(FieldRef).defaultValue([]),
  stagedColumns: checked.list(Column).defaultValue([]),
  limitsForQuery: checked.list(LimitForQuery).defaultValue([]),
  sourceCrossObjectReportId: checked.number().optional(),
  migratedAt: checked.string().optional(),
  expressions: checked.list(ExpressionField).defaultValue([]),
  flags: checked.list(checked.string())
}, 'RelationalReport');