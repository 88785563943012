import * as checked from 'reporting-data/lib/checked';
import { Encoding } from '../column-records';
import { AxisScaleType, ColorOption, Scale, goalFrequency, goalLineValue, legendPosition, legendWidth, showDataLabels, showMarkers, showRecordIds, showToday, stacking, stackingType } from '../highcharts-settings';
import { VisualTypes } from '../constants/visual-constants';
export const AreaEncodings = checked.record({
  x: Encoding.optional(),
  y: Encoding.optional(),
  y_multi: checked.list(Encoding),
  color: Encoding.optional()
}, 'AreaEncodings');
export const AreaVisualOptions = checked.record({
  showDataLabels,
  showRecordIds,
  showMarkers,
  stacking,
  stackingType,
  accumulate: checked.boolean().defaultValue(false),
  showToday,
  goalLineValue,
  goalFrequency,
  yMinMax: Scale.defaultValue({}),
  color: ColorOption.defaultValue({}),
  yAxisType: AxisScaleType,
  yAxisLabel: checked.string().optional(),
  legendPosition,
  legendWidth
});
export const AreaVisual = checked.record({
  type: checked.string().always(VisualTypes.AREA),
  encodings: AreaEncodings,
  options: AreaVisualOptions.defaultValue({})
}, 'AreaVisual');