'use es6';

import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { getObjectsByIds, searchObjects, createGetObjectsByIds, createSearchObjects } from 'reference-resolvers/api/FeedbackAPI';
export const createFeedbackReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.FEEDBACK,
  createFetchByIds: createGetObjectsByIds,
  createFetchSearchPage: createSearchObjects,
  fetchByIds: getObjectsByIds,
  fetchSearchPage: searchObjects
}, options));
export default createFeedbackReferenceResolver();