'use es6';

import Request from '../../request/Request';
import * as http from '../../request/http';
import { makeOption } from '../Option';
import { Map as ImmutableMap, List } from 'immutable';
const URL = 'inbounddb-io/imports/get-names';
const BATCH_SIZE = 100;
function fetch(keys) {
  return http.retrieve(Request.put({
    url: URL,
    data: [...keys]
  })).then(names => names.get('hits', ImmutableMap()).map((name, key) => makeOption(key, name)));
}
const getImports = keys => {
  const batches = [];
  for (let offset = 0; offset < keys.count(); offset += BATCH_SIZE) {
    batches.push(fetch(keys.slice(offset, offset + BATCH_SIZE)));
  }
  return Promise.all(batches).then(responses => responses.reduce((final, response) => final.merge(response), ImmutableMap()));
};
export default getImports;
export const imports = ids => getImports(List(ids)).then(options => options.reduce((breakdowns, option) => Object.assign({}, breakdowns, {
  [option.get('value')]: option.get('label')
}), {}));