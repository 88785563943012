import { Map as ImmutableMap } from 'immutable';
const waitForPromise = (promise, setStateFunc) => {
  promise.then(result => {
    setStateFunc({
      status: 'resolved',
      value: result
    });
  }).catch(error => setStateFunc({
    status: 'rejected',
    error
  }));
};
export const waitForPromises = promiseMap => {
  ImmutableMap(promiseMap).forEach(promiseWithUpdater => {
    waitForPromise(promiseWithUpdater.promise, promiseWithUpdater.setStateFunction);
  });
};