'use es6';

import curryN from 'transmute/curryN';
import { isFunction, isNil } from './index';

/* `R.invoker` from https://github.com/ramda/ramda/blob/v0.26.1/source/invoker.js */
export default ((arity, functionName) => curryN(arity + 1)((...args) => {
  const subject = args[arity];
  if (isNil(subject) || !isFunction(subject[functionName])) {
    throw new TypeError(`${subject} does not have a method named "${functionName}".`);
  }
  return subject[functionName].apply(subject, Array.prototype.slice.call(args, 0, arity));
}));