'use es6';

import Loadable from 'UIComponents/decorators/Loadable';
const DELAY_TIMEOUT = 5000;
const _delayUntilIdle = () => {
  const delay = window.requestIdleCallback || window.setTimeout;
  const delayOptions = delay === window.requestIdleCallback ? {
    timeout: DELAY_TIMEOUT
  } : 0;
  return new Promise(resolve => {
    delay(() => resolve(), delayOptions);
  });
};
const loadUILock = () => import( /* webpackChunkName: "growth" */'ui-addon-upgrades/icons/UILock');
export const AsyncUILock = Loadable({
  loader: loadUILock,
  LoadingComponent: () => null
});
const loadUIUpgradeBanner = () => import( /* webpackChunkName: "growth" */'ui-addon-upgrades/ums/UIUpgradeBanner');
export const AsyncUIUpgradeBanner = Loadable({
  loader: loadUIUpgradeBanner,
  LoadingComponent: () => null
});
const loadGrowthContainer = () => _delayUntilIdle().then(() => import( /* webpackChunkName: "growth" */'trial-components-library/modals/GrowthDashboardModalContainer'));
export const AsyncGrowthModalContainer = Loadable({
  loader: loadGrowthContainer,
  LoadingComponent: () => null
});
const loadHSFSAlerts = () => _delayUntilIdle().then(() => import( /* webpackChunkName: "growth" */'dashboard-ui-components/components/pql/HSFSAlert'));
export const AsyncHSFSAlerts = Loadable({
  loader: loadHSFSAlerts,
  LoadingComponent: () => null
});