import { createSearchReferenceResolver } from './createSearchReferenceResolver';
import { makeExternalOptionsByIdUrl, makeExternalOptionsSearchUrl, getExternalOptionsFetchQueryOptions, getExternalOptionsByIdFetchOptions, getExternalOptionSearchFetchOptions } from './internals/externalOptionsResolver/api';
import { formatExternalOptionsByIdResponse, formatExternalOptionsSearchResponse } from './internals/externalOptionsResolver/formatters';
import http from 'hub-http/clients/apiClient';
import { maybeGetExternalOptionsMetaData } from './frameworkData/maybeGetExternalOptionsMetaData';
export const createExternalOptionsReferenceResolver = ({
  // We enable deleted results by default in by id requests because it's
  // possible for deleted references to be referenced in the UI and we still
  // want to be able to resolve them. By Id requests are generally used in
  // reference to existing data that we want thte user to be able to view, they
  // are rarely, if ever, used to create new references so we don't have to
  // worry about filtering out deleted data.
  byIdFetchOptions = {
    includeDeleted: true
  },
  debouncePeriod,
  enableCaching,
  httpClient = http,
  maxBatchSize,
  objectTypeId,
  propertyName,
  referenceType,
  // We don't enable deleted results by default in search requests because most
  // of the time users don't want to search for deleted records. Search is
  // commonly used to set new values for an external option property and
  // therefore we generally want to hide deleted references to avoid confusing
  // users
  searchFetchOptions = {
    includeDeleted: false
  },
  externalOptionsMetaData
}) => {
  const byIdUrl = makeExternalOptionsByIdUrl(referenceType);
  const searchUrl = makeExternalOptionsSearchUrl(referenceType);
  const fetchByIds = async ids => httpClient.post(byIdUrl, {
    query: getExternalOptionsFetchQueryOptions(),
    data: getExternalOptionsByIdFetchOptions(ids, referenceType, byIdFetchOptions, objectTypeId, externalOptionsMetaData || (await maybeGetExternalOptionsMetaData(objectTypeId, propertyName, httpClient)))
  });
  const fetchBySearch = async searchQuery => httpClient.post(searchUrl, {
    query: getExternalOptionsFetchQueryOptions(),
    data: getExternalOptionSearchFetchOptions(referenceType, searchQuery, searchFetchOptions, objectTypeId, externalOptionsMetaData || (await maybeGetExternalOptionsMetaData(objectTypeId, propertyName, httpClient)))
  });
  const formatByIdResponse = byIdResponse => formatExternalOptionsByIdResponse(byIdResponse, {
    objectTypeId,
    referenceType
  });
  const formatSearchResponse = searchResponse => formatExternalOptionsSearchResponse(searchResponse, {
    objectTypeId,
    referenceType
  });
  return createSearchReferenceResolver({
    debouncePeriod,
    enableCaching,
    fetchByIds,
    fetchBySearch,
    formatByIdResponse,
    formatSearchResponse,
    maxBatchSize,
    referenceType
  });
};