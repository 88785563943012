'use es6';

import { define } from 'general-store';
import Immutable, { fromJS } from 'immutable';
import { PORTAL_SETTINGS_FETCH_SUCCESS } from '../constants/SystemDataActionTypes';
let data = Immutable.Map();
let loaded = false;
export default define().defineName('PortalSettingsStore').defineGet(key => {
  if (key === 'loaded') {
    return loaded;
  }
  return key ? data.get(key) : data;
}).defineResponseTo(PORTAL_SETTINGS_FETCH_SUCCESS, ({
  settings
}) => {
  loaded = true;
  data = fromJS(settings);
}).register();