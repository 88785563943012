'use es6';

import * as ObjectTypeIds from 'customer-data-filters/data/objectTypeIds';
import { LegacyFilterFamilyObjectNameTranslator } from 'customer-data-filters/components/FilterFamilyTranslator';
import { getDefaultTranslationKey, getTranslationOptions } from 'customer-data-filters/utilities/filterFamilyTranslation';
const ObjectHasCustomLabels = [ObjectTypeIds.ENGAGEMENT_TYPE_ID, ObjectTypeIds.LINE_ITEM_TYPE_ID, ObjectTypeIds.SUBSCRIPTION_TYPE_ID];
export const objectHasCustomLabels = filterFamily => ObjectHasCustomLabels.includes(filterFamily);
export const getEditorFilterFamilyNameTranslation = (filterFamily, {
  getFilterFamilyEntityName = LegacyFilterFamilyObjectNameTranslator,
  getFilterFamilyTranslationKey = getDefaultTranslationKey
} = {}) => {
  const filterFamilyKey = getFilterFamilyTranslationKey(filterFamily);
  const options = getTranslationOptions({
    filterFamily,
    getFilterFamilyEntityName
  });
  return {
    key: `customerDataFilters.FilterFamilyName.${filterFamilyKey}`,
    options
  };
};
export const getFilterFamilyGroupHeadingTranslation = (filterFamily, {
  getFilterFamilyEntityName = LegacyFilterFamilyObjectNameTranslator,
  getFilterFamilyTranslationKey = getDefaultTranslationKey,
  isEditor = true
} = {}) => {
  const filterFamilyKey = getFilterFamilyTranslationKey(filterFamily);
  const editorModeKey = isEditor ? 'editor' : 'viewer';
  const options = getTranslationOptions({
    filterFamily,
    getFilterFamilyEntityName
  });
  return {
    key: `customerDataFilters.FilterFamilyGroupHeading.${editorModeKey}.${filterFamilyKey}`,
    options
  };
};