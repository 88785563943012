'use es6';

import { SEQUENCE_STEP_ORDER } from 'reference-resolvers/constants/CacheKeys';
import { getStepOrders, createGetStepOrders, fetchStepOrdersSearchPage, createFetchStepOrdersSearchPage } from 'reference-resolvers/api/SequenceStepOrderAPI';
import createBatchedReferenceResolver from 'reference-resolvers/lib/createBatchedReferenceResolver';
export const createSequenceStepOrderReferenceResolver = options => createBatchedReferenceResolver(Object.assign({
  cacheKey: SEQUENCE_STEP_ORDER,
  createFetchSearchPage: createFetchStepOrdersSearchPage,
  createFetchByIds: createGetStepOrders,
  fetchByIds: getStepOrders,
  fetchSearchPage: fetchStepOrdersSearchPage
}, options));
export default createSequenceStepOrderReferenceResolver();