import { OrderedMap } from 'immutable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../.... Remove this comment to see the full error message
import { WistiaIntegrationFamilies } from '../../converters/listSegClassic/ListSegConstants';
import { addCondition, getConditionKey, isLogicGroup, makeLogicGroup } from './LogicGroup';
import getIn from 'transmute/getIn';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../D... Remove this comment to see the full error message
import { INTEGRATION } from '../DSAssetFamilies/DSAssetFamilies';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '../D... Remove this comment to see the full error message
import { testDynamicFilterFamily } from '../DynamicFilterFamilies';
const And = makeLogicGroup('And', OrderedMap());
const shouldAllowMultipleAndGroupsForFilterFamily = filterFamily => {
  return (
    // HACK: Workaround to allow multiple And groups for the Wistia Integration
    // See: https://git.hubteam.com/HubSpot/customer-data-filters/pull/261
    // and for other integrations, see https://git.hubteam.com/HubSpot/crm-filters/issues/798
    // DO NOT replicate this pattern without talking to #crm-filters
    WistiaIntegrationFamilies.includes(filterFamily) || testDynamicFilterFamily(INTEGRATION, filterFamily)
  );
};
const getAndConditionKey = (condition, group) => {
  const conditionFilterFamily = getIn(['filterFamily'], condition);
  if (isLogicGroup(condition) && shouldAllowMultipleAndGroupsForFilterFamily(conditionFilterFamily)) {
    return [conditionFilterFamily, getIn(['conditions'], group).size].join(' ');
  }
  return getConditionKey(condition, group);
};
addCondition.implement(And,
// @ts-expect-error this implementation can only be called with AndInstance
(condition, group, filterFamily = group.filterFamily) => {
  const emptyGroup = And.of().set('filterFamily', filterFamily);
  const setInGroup = (g = emptyGroup) => {
    const conditionKey = getAndConditionKey(condition, g);
    return g.setIn(['conditions', conditionKey], condition);
  };
  const newGroupKey = shouldAllowMultipleAndGroupsForFilterFamily(filterFamily) ? [filterFamily, getIn(['conditions'], group).size].join(' ') : filterFamily;
  return filterFamily === group.filterFamily ? setInGroup(group) :
  // @ts-expect-error setInGroup will always add And group here
  group.updateIn(['conditions', newGroupKey], setInGroup);
});
export default And;