import { useCallback } from 'react';
import { isSucceeded } from 'reporting-data/asyncData/AsyncData';
import { useAsyncFetcher } from 'reporting-data/asyncData/useAsyncFetcher';
// @ts-expect-error Untyped import
import getProperties from 'reporting-data/properties';
const useFetchPropertiesForDataType = dataType => {
  const resultFormatter = useCallback(response => {
    return response.get(dataType);
  }, [dataType]);
  const fetcher = useCallback(() => {
    return getProperties(dataType);
  }, [dataType]);
  const fetch = useAsyncFetcher({
    fetcher,
    resultFormatter
  });
  if (isSucceeded(fetch)) {
    return fetch.data;
  }
  return [];
};
export default useFetchPropertiesForDataType;