'use es6';

import { Map as ImmutableMap } from 'immutable';
import Request from '../../request/Request';
import * as http from '../../request/http';
import { makeOption } from '../Option';
import { GLOBAL_NULL } from '../../constants/defaultNullValues';
import I18n from 'I18n';
const LABEL_KEY = 'hs_template_name';
const url = 'sales-templates/v1/reporting/templates';
export const generateTemplateLabel = (template, key) => {
  const fallback = key !== GLOBAL_NULL ? I18n.text('reporting-data.references.template.unknown', {
    id: key
  }) : undefined;
  return template.get(LABEL_KEY) || fallback;
};
export default (ids => http.retrieve(Request.get({
  url,
  query: {
    id: ids,
    includeDeleted: true
  }
})).then(templates => templates.get('hits', ImmutableMap()).reduce((options, template) => options.set(template.get('id'), makeOption(template.get('id'), template.get('name'))), ImmutableMap())));