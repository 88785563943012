import emptyFunction from 'react-utils/emptyFunction';
import TourHandler from './TourHandler';
import ProgressStore from '../stores/ProgressStore';
import { NOT_STARTED, DONE } from '../constants/TourStates';
export default class MultiTourHandler {
  constructor() {
    this.tours = {};
    this.activeTourKey = null;
  }
  registerTour(key, config, progressStore) {
    if (typeof this.tours[key] === 'undefined') {
      const handler = new TourHandler({
        key,
        config,
        progressStore: progressStore || new ProgressStore()
      });
      this.tours[key] = handler;
    }
    return this.tours[key];
  }
  deregisterTour(key) {
    this.deactivateTour(key, () => {
      const tour = this.tours[key];
      // Reset store and subscriptions when tour is unmounted
      // For preventing updating on unmounted component
      if (tour && tour.progressStore.constructor === ProgressStore) {
        tour.progressStore = new ProgressStore();
      }
    });
  }
  activateTour(key, callback = emptyFunction) {
    const next = this.tours[key];
    const activateNext = () => {
      this.getInactiveTourKeys().forEach(inactiveTourKey => {
        const handler = this.tours[inactiveTourKey];
        handler._broadcastActiveTour(key);
      });
      this.activeTourKey = key;
      next._activate();
      callback();
    };
    if (this.activeTourKey) {
      const current = this.getActiveTour();
      current._deactivate(activateNext);
    } else {
      activateNext();
    }
  }
  deactivateTour(key, callback) {
    const handler = this.tours[key];
    if (this.activeTourKey === key) {
      handler.deactivate(callback);
      this.activeTourKey = null;
    }
  }
  deactivate(callback) {
    const currentTourKey = this.getActiveTourKey();
    if (currentTourKey) {
      this.deactivateTour(currentTourKey, callback);
    }
  }
  getTour(key) {
    return this.tours[key];
  }
  getActiveTourKey() {
    if (!this.activeTourKey) {
      return null;
    }
    return this.activeTourKey;
  }
  getActiveTour() {
    const activeTourKey = this.getActiveTourKey();
    return this.tours[activeTourKey];
  }
  getRunningTour() {
    const activeTourHandler = this.getActiveTour();
    if (!activeTourHandler) {
      return null;
    }
    const stepKey = activeTourHandler.getStepKey();
    const isTourRunning = stepKey && stepKey !== NOT_STARTED && stepKey !== DONE;
    return isTourRunning ? activeTourHandler : null;
  }
  getRunningTourKey() {
    const runningTourHandler = this.getRunningTour();
    return runningTourHandler ? runningTourHandler.tour : null;
  }
  getInactiveTourKeys() {
    const activeTourKey = this.getActiveTourKey();
    const tourKeys = Object.keys(this.tours);
    return tourKeys.filter(key => key !== activeTourKey);
  }
}