import { SUB_APPS } from '../constants/sub-apps';
const REPORT_HYBRID_SRV_SUB_APP_DATA = {
  [SUB_APPS.ads]: {
    adNetworks: {
      tabKey: 'adNetworks'
    },
    adCampaigns: {
      tabKey: 'adCampaigns'
    }
  },
  [SUB_APPS.leadCapture]: {
    forms: {
      tabKey: 'forms'
    }
  },
  [SUB_APPS.marketingEmails]: {
    marketingEmails: {
      tabKey: 'marketingEmails'
    }
  },
  [SUB_APPS.sms]: {
    sms: {
      tabKey: 'sms'
    }
  },
  [SUB_APPS.cta]: {
    cta: {
      tabKey: 'cta'
    }
  },
  [SUB_APPS.customerJourneyAnalytics]: {
    // dealStageProgression: {
    //   tabKey: 'dealStageProgression',
    // },
    lifecycleStageProgression: {
      tabKey: 'lifecycleStageProgression'
    },
    pageVisitsToFormSubmissions: {
      tabKey: 'pageVisitsToFormSubmissions'
    },
    leadCreationBrokenDownByAdNetwork: {
      tabKey: 'leadCreationBrokenDownByAdNetwork'
    },
    meetingsThatGeneratedDeals: {
      tabKey: 'meetingsThatGeneratedDeals'
    }
  },
  [SUB_APPS.sources]: {
    sources: {
      tabKey: 'sources'
    }
  },
  [SUB_APPS.pages]: {
    pages: {
      tabKey: 'pages'
    }
  },
  [SUB_APPS.utmParameters]: {
    utmCampaign: {
      tabKey: 'utmCampaign'
    },
    utmSource: {
      tabKey: 'utmSource'
    },
    utmMedium: {
      tabKey: 'utmMedium'
    },
    utmContent: {
      tabKey: 'utmContent'
    },
    utmTerm: {
      tabKey: 'utmTerm'
    }
  },
  [SUB_APPS.deviceTypes]: {
    deviceTypes: {
      tabKey: 'deviceTypes'
    }
  },
  [SUB_APPS.geolocation]: {
    geolocation: {
      tabKey: 'geolocation'
    }
  },
  [SUB_APPS.browsers]: {
    browsers: {
      tabKey: 'browsers'
    }
  },
  [SUB_APPS.contactInsights]: {
    contactInsights: {
      tabKey: 'contactInsights'
    }
  },
  [SUB_APPS.attribution]: {
    revenueAttribution: {
      tabKey: 'attribution'
    }
  },
  [SUB_APPS.customerReplyTime]: {
    averageCustomerReplyTime: {
      tabKey: 'averageCustomerReplyTime'
    },
    customersWithHighestAverageReplyTime: {
      tabKey: 'customersWithHighestAverageReplyTime'
    }
  },
  [SUB_APPS.customerWaitTime]: {
    customerWaitTime: {
      tabKey: 'customerWaitTime'
    }
  },
  [SUB_APPS.ownerReplyCount]: {
    ownerReplyCount: {
      tabKey: 'ownerReplyCount'
    }
  },
  [SUB_APPS.ownerReplyTime]: {
    averageReplyTime: {
      tabKey: 'averageReplyTime'
    },
    averageReplyTimeByOwner: {
      tabKey: 'averageReplyTimeByOwner'
    }
  },
  [SUB_APPS.ticketReplyCount]: {
    ticketsWithMostMessagesSent: {
      tabKey: 'ticketsWithMostMessagesSent'
    },
    ticketsWithMostMessagesReceived: {
      tabKey: 'ticketsWithMostMessagesReceived'
    }
  },
  [SUB_APPS.timeOfDay]: {
    messagesSentByTimeOfDay: {
      tabKey: 'messagesSentByTimeOfDay'
    },
    messagesReceivedByTimeOfDay: {
      tabKey: 'messagesReceivedByTimeOfDay'
    }
  }
};
export const getHybridSRVSubAppData = subAppKey => REPORT_HYBRID_SRV_SUB_APP_DATA[subAppKey];
export const isSubAppHybridSRVReport = subAppKey => !!getHybridSRVSubAppData(subAppKey);
export const getHybridSRVSubAppTabs = (subAppKey, scopes, gates) => {
  const subAppData = getHybridSRVSubAppData(subAppKey) || {};
  return Object.keys(subAppData).filter(tabKey => {
    const tabData = subAppData[tabKey];
    const requiredScope = tabData.scope;
    const requiredGate = tabData.gate;
    return (!requiredScope || scopes.includes(requiredScope)) && (!requiredGate || gates.includes(requiredGate));
  });
};