import enviro from 'enviro';
const hubletSubdomainPostfix = hubletOverride => {
  if (hubletOverride && hubletOverride !== 'na1') {
    return `-${hubletOverride}`;
  }
  const currentHublet = enviro.getHublet();
  if (currentHublet === 'na1' || hubletOverride === 'na1') {
    return '';
  }
  return `-${currentHublet}`;
};
export const getAppUrl = (name = 'app', domainPrefix = 'hubspot', hubletOverride) => {
  const targetHublet = hubletSubdomainPostfix(hubletOverride);
  const domainSuffix = enviro.isProd() ? '' : 'qa';
  return `https://${name}${targetHublet}.${domainPrefix}${domainSuffix}.com`;
};