import { Map as ImmutableMap } from 'immutable';
import * as checked from 'reporting-data/lib/checked';
import { AreaVisual, AreaVisualOptions } from './visuals/area-visual-records';
import { ComboVisual, ComboVisualOptions } from './visuals/combo-visual-records';
import { DonutVisual, DonutVisualOptions } from './visuals/donut-visual-records';
import { HorizontalBarVisual, HorizontalBarVisualOptions } from './visuals/horizontal-bar-visual-records';
import { LineVisual, LineVisualOptions } from './visuals/line-visual-records';
import { PieVisual, PieVisualOptions } from './visuals/pie-visual-records';
import { PivotTableVisual, PivotTableVisualOptions } from './visuals/pivot-table-visual-records';
import { ScatterVisual, ScatterVisualOptions } from './visuals/scatter-visual-records';
import { TableVisual, TableVisualOptions } from './visuals/table-visual-records';
import { TextVisual, TextVisualOptions } from './visuals/text-visual-records';
import { VerticalBarVisual, VerticalBarVisualOptions } from './visuals/vertical-bar-visual-records';
import { MagicWandVisual } from './visuals/magic-wand-visual-records';
import { GaugeVisual, GaugeVisualOptions } from './visuals/gauge-visual-records';
import { getChannelHelpText } from '../utils/channel-utils';
import { TimeSeriesLikeVisualTypes as TimeSeriesLikeVisualTypesConstant, VisualTypes as VisualTypesConstant, ChannelNames as ChannelNamesConstant } from './constants/visual-constants';
import { COLUMN_ROLES } from 'reporting-data/constants/relationalReports';

/**
 * From "How can I use Immutable maps with TypeScript?" in
 * https://product.hubteam.com/docs/frontend/docs/typescript-faqs.html
 * A type that can wrap a TypeScript interface and provide hinting for an immutable Map of the same structure
 */

// A utility function to build an immutable Map of a given structure, ensuring that initial keys and values conform to the given type
export const buildTypedMap = data => ImmutableMap(data);
export const VisualTypes = VisualTypesConstant;
export const TimeSeriesLikeVisualTypes = TimeSeriesLikeVisualTypesConstant;
export const VisualType = checked.symbol(VisualTypes, 'VisualType');
const _Visual = checked.poly('type', {
  [VisualTypes.VERTICAL_BAR]: VerticalBarVisual,
  [VisualTypes.HORIZONTAL_BAR]: HorizontalBarVisual,
  [VisualTypes.LINE]: LineVisual,
  [VisualTypes.AREA]: AreaVisual,
  [VisualTypes.SCATTER]: ScatterVisual,
  [VisualTypes.PIE]: PieVisual,
  [VisualTypes.DONUT]: DonutVisual,
  [VisualTypes.TEXT]: TextVisual,
  [VisualTypes.TABLE]: TableVisual,
  [VisualTypes.PIVOT_TABLE]: PivotTableVisual,
  [VisualTypes.COMBO]: ComboVisual,
  [VisualTypes.MAGIC_WAND]: MagicWandVisual,
  [VisualTypes.GAUGE]: GaugeVisual
}, 'Visual');
export const Visual = _Visual;
export const ChannelNames = ChannelNamesConstant;
export const ChannelTypes = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
  STATIC: 'STATIC'
};
export const ChannelType = checked.symbol(ChannelTypes, 'ChannelTypes');
export const Channel = checked.record({
  name: checked.symbol(ChannelNames, 'ChannelNames'),
  label: checked.string(),
  type: ChannelType,
  helpText: checked.any().optional(),
  displayOrder: checked.number().optional()
}, 'Channel');
const setDisplayOrder = (order, channel) => channel.set('displayOrder', order);

// TODO: remove "-channel" suffix when we fix x and y channels
export const colorChannel = Channel({
  name: ChannelNames.COLOR,
  label: 'reporting-snowflake.channels.color-channel',
  type: ChannelTypes.SINGLE
});
export const sizingChannel = Channel({
  name: ChannelNames.SIZING,
  label: 'reporting-snowflake.channels.sizing-channel',
  type: ChannelTypes.SINGLE,
  helpText: getChannelHelpText(ChannelNames.SIZING)
});
export const detailChannel = Channel({
  name: ChannelNames.DETAIL,
  label: 'reporting-snowflake.channels.detail-channel',
  type: ChannelTypes.SINGLE,
  helpText: getChannelHelpText(ChannelNames.DETAIL)
});
export const valuesChannel = Channel({
  name: ChannelNames.VALUES,
  label: 'reporting-snowflake.channels.values-channel',
  type: ChannelTypes.MULTIPLE
});
export const singleValueChannel = Channel({
  name: ChannelNames.SINGLE_VALUE,
  label: 'reporting-snowflake.channels.single-value-channel',
  type: ChannelTypes.SINGLE
});
export const groupChannel = Channel({
  name: ChannelNames.GROUP,
  label: 'reporting-snowflake.channels.group-channel',
  type: ChannelTypes.SINGLE
});
export const rowsChannel = Channel({
  name: ChannelNames.ROWS,
  label: 'reporting-snowflake.channels.rows-channel',
  type: ChannelTypes.MULTIPLE
});
export const columnsChannel = Channel({
  name: ChannelNames.COLUMNS,
  label: 'reporting-snowflake.channels.columns-channel',
  type: ChannelTypes.MULTIPLE
});
export const xChannel = Channel({
  name: ChannelNames.X,
  label: 'reporting-snowflake.channels.x-channel',
  type: ChannelTypes.SINGLE
});
export const xMultiChannel = Channel({
  name: ChannelNames.X_MULTI,
  label: 'reporting-snowflake.channels.x-multi-channel',
  type: ChannelTypes.MULTIPLE
});
export const compareChannel = Channel({
  name: ChannelNames.COMPARE,
  label: 'reporting-snowflake.channels.x-channel',
  type: ChannelTypes.SINGLE
});
export const yChannel = Channel({
  name: ChannelNames.Y,
  label: 'reporting-snowflake.channels.y-channel',
  type: ChannelTypes.SINGLE
});
export const yMultiChannel = Channel({
  name: ChannelNames.Y_MULTI,
  label: 'reporting-snowflake.channels.y-multi-channel',
  type: ChannelTypes.MULTIPLE
});
export const y1Channel = Channel({
  name: ChannelNames.Y_1,
  label: 'reporting-snowflake.channels.y1-channel',
  type: ChannelTypes.SINGLE
});
export const y2Channel = Channel({
  name: ChannelNames.Y_2,
  label: 'reporting-snowflake.channels.y2-channel',
  type: ChannelTypes.SINGLE
});
export const breakdownFirstMeasureChannel = Channel({
  name: ChannelNames.BREAKDOWN,
  label: 'reporting-snowflake.channels.breakdown-first-measure-channel',
  type: ChannelTypes.SINGLE
});
export const verticalBarChannels = buildTypedMap({
  [ChannelNames.X]: setDisplayOrder(0, xChannel),
  [ChannelNames.Y_MULTI]: setDisplayOrder(1, yMultiChannel),
  [ChannelNames.Y]: setDisplayOrder(2, yChannel),
  [ChannelNames.COLOR]: setDisplayOrder(3, colorChannel)
});
export const horizontalBarChannels = buildTypedMap({
  [ChannelNames.X_MULTI]: setDisplayOrder(0, xMultiChannel),
  [ChannelNames.X]: setDisplayOrder(1, xChannel),
  [ChannelNames.Y]: setDisplayOrder(2, yChannel),
  [ChannelNames.COLOR]: setDisplayOrder(3, colorChannel)
});
export const lineChannels = buildTypedMap({
  [ChannelNames.X]: setDisplayOrder(0, xChannel),
  [ChannelNames.Y_MULTI]: setDisplayOrder(1, yMultiChannel),
  [ChannelNames.Y]: setDisplayOrder(2, yChannel),
  [ChannelNames.COLOR]: setDisplayOrder(3, colorChannel)
});
export const areaChannels = buildTypedMap({
  [ChannelNames.X]: setDisplayOrder(0, xChannel),
  [ChannelNames.Y_MULTI]: setDisplayOrder(1, yMultiChannel),
  [ChannelNames.Y]: setDisplayOrder(2, yChannel),
  [ChannelNames.COLOR]: setDisplayOrder(3, colorChannel)
});
export const scatterChannels = buildTypedMap({
  [ChannelNames.X]: setDisplayOrder(0, xChannel),
  [ChannelNames.Y]: setDisplayOrder(1, yChannel),
  [ChannelNames.COLOR]: setDisplayOrder(2, colorChannel),
  [ChannelNames.DETAIL]: setDisplayOrder(3, detailChannel),
  [ChannelNames.SIZING]: setDisplayOrder(4, sizingChannel)
});
export const pieChannels = buildTypedMap({
  [ChannelNames.COLOR]: setDisplayOrder(0, colorChannel),
  [ChannelNames.VALUES]: setDisplayOrder(1, valuesChannel)
});
export const donutChannels = buildTypedMap({
  [ChannelNames.COLOR]: setDisplayOrder(0, colorChannel),
  [ChannelNames.VALUES]: setDisplayOrder(1, valuesChannel)
});
export const textChannels = buildTypedMap({
  [ChannelNames.GROUP]: setDisplayOrder(0, groupChannel),
  [ChannelNames.COMPARE]: setDisplayOrder(1, compareChannel),
  [ChannelNames.VALUES]: setDisplayOrder(2, valuesChannel)
});
export const tableChannels = buildTypedMap({
  [ChannelNames.COLUMNS]: setDisplayOrder(0, columnsChannel)
});
export const magicWandChannels = buildTypedMap({
  [ChannelNames.COLUMNS]: setDisplayOrder(0, columnsChannel)
});
export const pivotTableChannels = buildTypedMap({
  [ChannelNames.ROWS]: setDisplayOrder(0, rowsChannel),
  [ChannelNames.COLUMNS]: setDisplayOrder(1, columnsChannel),
  [ChannelNames.VALUES]: setDisplayOrder(2, valuesChannel)
});
export const comboChannels = buildTypedMap({
  [ChannelNames.X]: setDisplayOrder(0, xChannel),
  [ChannelNames.Y_1]: setDisplayOrder(1, y1Channel),
  [ChannelNames.Y_2]: setDisplayOrder(2, y2Channel),
  [ChannelNames.BREAKDOWN]: setDisplayOrder(3, breakdownFirstMeasureChannel)
});
export const gaugeChannels = buildTypedMap({
  [ChannelNames.SINGLE_VALUE]: setDisplayOrder(0, singleValueChannel),
  [ChannelNames.COMPARE]: setDisplayOrder(1, compareChannel)
});
export const channelMetadata = buildTypedMap({
  [ChannelNames.COLOR]: colorChannel,
  [ChannelNames.SIZING]: sizingChannel,
  [ChannelNames.DETAIL]: detailChannel,
  [ChannelNames.VALUES]: valuesChannel,
  [ChannelNames.SINGLE_VALUE]: singleValueChannel,
  [ChannelNames.GROUP]: groupChannel,
  [ChannelNames.ROWS]: rowsChannel,
  [ChannelNames.COLUMNS]: columnsChannel,
  [ChannelNames.X]: xChannel,
  [ChannelNames.X_MULTI]: xMultiChannel,
  [ChannelNames.Y]: yChannel,
  [ChannelNames.Y_MULTI]: yMultiChannel,
  [ChannelNames.Y_1]: yChannel,
  [ChannelNames.Y_2]: yChannel,
  [ChannelNames.BREAKDOWN]: breakdownFirstMeasureChannel,
  [ChannelNames.COMPARE]: compareChannel
});
export const relationalReportChannels = buildTypedMap({
  [VisualTypes.VERTICAL_BAR]: verticalBarChannels,
  [VisualTypes.HORIZONTAL_BAR]: horizontalBarChannels,
  [VisualTypes.LINE]: lineChannels,
  [VisualTypes.AREA]: areaChannels,
  [VisualTypes.SCATTER]: scatterChannels,
  [VisualTypes.PIE]: pieChannels,
  [VisualTypes.DONUT]: donutChannels,
  [VisualTypes.TEXT]: textChannels,
  [VisualTypes.TABLE]: tableChannels,
  [VisualTypes.PIVOT_TABLE]: pivotTableChannels,
  [VisualTypes.COMBO]: comboChannels,
  [VisualTypes.MAGIC_WAND]: magicWandChannels,
  [VisualTypes.GAUGE]: gaugeChannels
});
export const visualOptionsByType = buildTypedMap({
  [VisualTypes.VERTICAL_BAR]: VerticalBarVisualOptions,
  [VisualTypes.HORIZONTAL_BAR]: HorizontalBarVisualOptions,
  [VisualTypes.LINE]: LineVisualOptions,
  [VisualTypes.AREA]: AreaVisualOptions,
  [VisualTypes.SCATTER]: ScatterVisualOptions,
  [VisualTypes.PIE]: PieVisualOptions,
  [VisualTypes.DONUT]: DonutVisualOptions,
  [VisualTypes.TEXT]: TextVisualOptions,
  [VisualTypes.TABLE]: TableVisualOptions,
  [VisualTypes.PIVOT_TABLE]: PivotTableVisualOptions,
  [VisualTypes.COMBO]: ComboVisualOptions,
  [VisualTypes.GAUGE]: GaugeVisualOptions
});
export const preferredChannelRoleByVisualType = buildTypedMap({
  [VisualTypes.VERTICAL_BAR]: buildTypedMap({
    [ChannelNames.X]: COLUMN_ROLES.DIMENSION,
    [ChannelNames.Y]: COLUMN_ROLES.MEASURE,
    [ChannelNames.Y_MULTI]: COLUMN_ROLES.MEASURE,
    [ChannelNames.COLOR]: COLUMN_ROLES.DIMENSION
  }),
  [VisualTypes.HORIZONTAL_BAR]: buildTypedMap({
    [ChannelNames.X]: COLUMN_ROLES.MEASURE,
    [ChannelNames.X_MULTI]: COLUMN_ROLES.MEASURE,
    [ChannelNames.Y]: COLUMN_ROLES.DIMENSION,
    [ChannelNames.COLOR]: COLUMN_ROLES.DIMENSION
  }),
  [VisualTypes.LINE]: buildTypedMap({
    [ChannelNames.X]: COLUMN_ROLES.DIMENSION,
    [ChannelNames.Y]: COLUMN_ROLES.MEASURE,
    [ChannelNames.Y_MULTI]: COLUMN_ROLES.MEASURE,
    [ChannelNames.COLOR]: COLUMN_ROLES.DIMENSION
  }),
  [VisualTypes.AREA]: buildTypedMap({
    [ChannelNames.X]: COLUMN_ROLES.DIMENSION,
    [ChannelNames.Y]: COLUMN_ROLES.MEASURE,
    [ChannelNames.Y_MULTI]: COLUMN_ROLES.MEASURE,
    [ChannelNames.COLOR]: COLUMN_ROLES.DIMENSION
  }),
  [VisualTypes.SCATTER]: buildTypedMap({
    [ChannelNames.X]: COLUMN_ROLES.MEASURE,
    [ChannelNames.Y]: COLUMN_ROLES.MEASURE,
    [ChannelNames.COLOR]: COLUMN_ROLES.DIMENSION,
    [ChannelNames.DETAIL]: COLUMN_ROLES.DIMENSION,
    [ChannelNames.SIZING]: COLUMN_ROLES.MEASURE
  }),
  [VisualTypes.PIE]: buildTypedMap({
    [ChannelNames.COLOR]: COLUMN_ROLES.DIMENSION,
    [ChannelNames.VALUES]: COLUMN_ROLES.MEASURE
  }),
  [VisualTypes.DONUT]: buildTypedMap({
    [ChannelNames.COLOR]: COLUMN_ROLES.DIMENSION,
    [ChannelNames.VALUES]: COLUMN_ROLES.MEASURE
  }),
  [VisualTypes.TEXT]: buildTypedMap({
    [ChannelNames.GROUP]: COLUMN_ROLES.DIMENSION,
    [ChannelNames.VALUES]: COLUMN_ROLES.MEASURE
  }),
  [VisualTypes.TABLE]: buildTypedMap({
    [ChannelNames.COLUMNS]: null
  }),
  [VisualTypes.PIVOT_TABLE]: buildTypedMap({
    [ChannelNames.COLUMNS]: COLUMN_ROLES.DIMENSION,
    [ChannelNames.VALUES]: COLUMN_ROLES.MEASURE,
    [ChannelNames.ROWS]: COLUMN_ROLES.DIMENSION
  }),
  [VisualTypes.COMBO]: buildTypedMap({
    [ChannelNames.X]: COLUMN_ROLES.DIMENSION,
    [ChannelNames.Y_1]: COLUMN_ROLES.MEASURE,
    [ChannelNames.Y_2]: COLUMN_ROLES.MEASURE
  }),
  [VisualTypes.GAUGE]: buildTypedMap({
    [ChannelNames.SINGLE_VALUE]: COLUMN_ROLES.MEASURE
  })
});