'use es6';

import PropTypes from 'prop-types';
import { Component } from 'react';
import { track as dashboardTracker, EVENTS } from 'dashboard-ui-components/tracking/DashboardUITracker';
import getScrollableContainer from '../../lib/getScrollableContainer';
const FIVE_SECOND_TIMER = 5000;
class EngagementTracker extends Component {
  constructor(props) {
    super(props);
    this.onScrollAfterFiveSeconds = () => {
      this.scrollEle.removeEventListener('scroll', this.onScrollAfterFiveSeconds);
    };
    this.onFirstScroll = () => {
      this.scrollEle.removeEventListener('scroll', this.onFirstScroll);
      dashboardTracker(EVENTS.dashboardDayZero, Object.assign({}, this.props.data));
      dashboardTracker(EVENTS.scrollDashboard, Object.assign({}, this.props.data));
    };
    this.scrollEle = getScrollableContainer();
  }
  componentDidMount() {
    this.scrollEle.addEventListener('scroll', this.onFirstScroll);
    setTimeout(() => {
      this.scrollEle.addEventListener('scroll', this.onScrollAfterFiveSeconds);
    }, FIVE_SECOND_TIMER);
  }
  componentWillUnmount() {
    this.scrollEle.removeEventListener('scroll', this.onFirstScroll);
    this.scrollEle.removeEventListener('scroll', this.onScrollAfterFiveSeconds);
  }
  render() {
    return this.props.children;
  }
}
EngagementTracker.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object
};
EngagementTracker.defaultProps = {
  children: null,
  data: {}
};
export default EngagementTracker;