import { useEffect } from 'react';
export function useEmbeddedCommentChangeHandlers({
  isCommentModeActive,
  prevIsCommentModeActive,
  toggleOpenPopover
}) {
  // Make sure we close popovers on comment mode ending
  useEffect(() => {
    // Because there is a single popover state,
    // we need to observe the previous state for this module ID
    if (!isCommentModeActive && prevIsCommentModeActive) {
      toggleOpenPopover(false);
    }
  }, [isCommentModeActive, prevIsCommentModeActive, toggleOpenPopover]);
}