export const ERROR_ID_INVALID = 'Invalid ID';
export const ERROR_OBJECT_NOT_FOUND = 'Object not found';
export const ERROR_FETCH_FAILED = 'Fetch request failed';
export const ERROR_SEARCH_FAILED = 'Search request failed';
export const ERROR_BATCH_OBJECT_NOT_FOUND = 'Objects not found';
export const isInvalidIdError = error => error.type === ERROR_ID_INVALID;
export const toInvalidIdError = id => ({
  type: ERROR_ID_INVALID,
  reason: `${ERROR_ID_INVALID}: ${id}`
});
export const isObjectNotFoundError = error => error.type === ERROR_OBJECT_NOT_FOUND;
export const toObjectNotFoundError = id => ({
  type: ERROR_OBJECT_NOT_FOUND,
  reason: `${ERROR_OBJECT_NOT_FOUND} for ID: ${id}`
});
export const isBatchObjectsNotFoundError = error => error.type === ERROR_BATCH_OBJECT_NOT_FOUND;
export const toBatchObjectsNotFoundError = ids => ({
  type: ERROR_BATCH_OBJECT_NOT_FOUND,
  reason: `${ERROR_BATCH_OBJECT_NOT_FOUND} for IDs: ${ids.join(',')}`
});
export const isFetchFailedError = error => error.type === ERROR_FETCH_FAILED;
export const toFetchFailedError = reason => ({
  type: ERROR_FETCH_FAILED,
  reason
});
export const isSearchFailedError = error => error.type === ERROR_SEARCH_FAILED;
export const toSearchFailedError = reason => ({
  type: ERROR_SEARCH_FAILED,
  reason
});