'use es6';

import http from 'hub-http/clients/apiClient';
import { RequestState } from 'dashboard-lib/public/request/request-state';
import { selectHasCustomReportingAcccess } from 'dashboard-lib/public/userInfo/scopes';
import { isFrameworkDataSource } from '../lib/dashboardFilters/dataSourceHelpers';
import { selectDataSourcesInfo, selectDataSourcesInfoStatus } from '../lib/dashboardFilters/dataSourceInfoLogic';
export const dataSourceTopPropertiesNamespace = 'dataSourceTopProperties';

// Requests
export const getDataSourceTopProperties = objectTypeId => http.get(`sql-reporting/v1/extended-reportable-options/top-properties/${objectTypeId}`).then(response => {
  return response.portalTopProperties.length > 0 ? response.portalTopProperties : response.defaultTopProperties;
});

// Action types
const FETCH_DATA_SOURCE_TOP_PROPERTIES_PENDING = 'FETCH_DATA_SOURCE_TOP_PROPERTIES_PENDING';
const FETCH_DATA_SOURCE_TOP_PROPERTIES_SUCCEEDED = 'FETCH_DATA_SOURCE_TOP_PROPERTIES_SUCCEEDED';
const FETCH_DATA_SOURCE_TOP_PROPERTIES_FAILED = 'FETCH_DATA_SOURCE_TOP_PROPERTIES_FAILED';

// Actions
export const fetchDataSourcesTopProperties = dataSources => (dispatch, getState) => {
  const state = getState();
  const hasCustomReportingAccess = selectHasCustomReportingAcccess(state);
  for (const dataSource of dataSources) {
    if (!state[dataSourceTopPropertiesNamespace][dataSource]) {
      dispatch({
        type: FETCH_DATA_SOURCE_TOP_PROPERTIES_PENDING,
        payload: {
          dataSource
        }
      });
      const topPropertiesPromise = isFrameworkDataSource(dataSource) && hasCustomReportingAccess ? getDataSourceTopProperties(dataSource) : Promise.resolve([]);
      topPropertiesPromise.then(topProperties => {
        dispatch({
          type: FETCH_DATA_SOURCE_TOP_PROPERTIES_SUCCEEDED,
          payload: {
            dataSource,
            topProperties
          }
        });
      }).catch(() => {
        dispatch({
          type: FETCH_DATA_SOURCE_TOP_PROPERTIES_FAILED,
          payload: {
            dataSource
          }
        });
      });
    }
  }
};

// Reducers
export const dataSourceTopPropertiesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_SOURCE_TOP_PROPERTIES_PENDING:
      {
        const {
          dataSource
        } = action.payload;
        return Object.assign({}, state, {
          [dataSource]: {
            status: RequestState.PENDING
          }
        });
      }
    case FETCH_DATA_SOURCE_TOP_PROPERTIES_SUCCEEDED:
      {
        const {
          dataSource,
          topProperties
        } = action.payload;
        return Object.assign({}, state, {
          [dataSource]: {
            status: RequestState.SUCCEEDED,
            topProperties
          }
        });
      }
    case FETCH_DATA_SOURCE_TOP_PROPERTIES_FAILED:
      {
        const {
          dataSource
        } = action.payload;
        return Object.assign({}, state, {
          [dataSource]: {
            status: RequestState.FAILED
          }
        });
      }
    default:
      return state;
  }
};

// Selectors
export const selectDataSourcesTopProperties = dataSources => state => selectDataSourcesInfo({
  dataNamespace: dataSourceTopPropertiesNamespace,
  dataSources,
  valueName: 'topProperties'
})(state);
export const selectDataSourcesTopPropertiesStatus = dataSources => state => selectDataSourcesInfoStatus({
  dataNamespace: dataSourceTopPropertiesNamespace,
  dataSources
})(state);