import { useContext } from 'react';
import CollaborationSidebarPropsContext from '../contexts/CollaborationSidebarPropsContext';
import useGetPropsFromFAS from '../features/fas/hooks/useGetPropsFromFAS';
export default function useCollaborationSidebarProps() {
  const props = useContext(CollaborationSidebarPropsContext);
  const {
    fasProps,
    loading: isLoadingFAS
  } = useGetPropsFromFAS({
    appName: (props === null || props === void 0 ? void 0 : props.appName) || '',
    enabledFeatures: props === null || props === void 0 ? void 0 : props.enabledFeatures
  });
  if (!props) {
    throw new Error('CollaborationSidebarPropsContext not provided');
  }
  if (fasProps) {
    return Object.assign({}, props, fasProps, {
      isLoadingFAS
    });
  }
  return Object.assign({}, props, {
    isLoadingFAS
  });
}