import { isConfigReport } from 'reporting-data/tsTypes/reportTypes';
import { getCompare, getReportConfig, getUseFiscalYearInAggregation } from 'reporting-data/report/configReportGetters';
import { setUseFiscalYearInDateRangeFilter } from 'reporting-data/report/configReportSetters';
export const getIsReportValidForFiscalYearCheckbox = ({
  report
}) => {
  return isConfigReport(report);
};
export const updateConfigReportFiscalYearForCheckbox = (report, checked) => {
  let updatedReport = report;
  updatedReport = report.setIn(['config', 'useFiscalYearInAggregation'], checked);
  if (getCompare(getReportConfig(report))) {
    updatedReport = setUseFiscalYearInDateRangeFilter(updatedReport, checked);
  }
  return updatedReport;
};
export const getFiscalYearFromCheckboxForConfigReport = report => {
  return getUseFiscalYearInAggregation(getReportConfig(report));
};