'use es6';

import I18n from 'I18n';
import { Promise } from '../../lib/promise';
import { toUpperCase } from '../../lib/string';
import { makeOption } from '../Option';
export const generateCurrencyLabel = (_, code) => {
  const sanitized = toUpperCase(code);
  const {
    name_plural
  } = I18n.currencySymbols[sanitized] || {};
  return name_plural ? `${name_plural} (${sanitized})` : sanitized;
};
export default (currencyCodes => {
  return Promise.resolve(currencyCodes.map((code = '') => {
    return makeOption(code, generateCurrencyLabel(undefined, code));
  }));
});