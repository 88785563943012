'use es6';

import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as Operators from '../../../filterQueryFormat/operator/Operators';
import getIn from 'transmute/getIn';
import getObjectWithOmittedProps from './util/getObjectWithOmittedProps';
import toValueComparison from './util/toValueComparison';
const getFilterWithRefinements = (filter, refinements) => {
  return Object.assign({}, filter, {
    operation: Object.assign({}, filter.operation, refinements)
  });
};
const _toPropertyFilter = (operation, operator) => getObjectWithOmittedProps({
  filterType: ObjectSegFilterTypes.PROPERTY,
  operation,
  property: getIn(['field', 'name'], operator)
});
const toPropertyFilter = (operator, options) => {
  const valueComparison = toValueComparison(operator, options);
  const filter = _toPropertyFilter(valueComparison, operator);

  // todo: clean up refinements as part of crm-filters issue #64
  if (operator.constructor === Operators.EverEqualAny || operator.constructor === Operators.NeverEqualAny) {
    return getFilterWithRefinements(filter, {
      pruningRefineBy: {
        type: 'AllHistoryRefineBy'
      }
    });
  }
  return filter;
};
export default toPropertyFilter;