/**
 * @deprecated Translation of values in a data fetching library is bad practice
 *
 * Use DealPipelinesReferenceResolver
 *
 */

'use es6';

import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { getAllPipelines, createGetAllPipelines } from 'reference-resolvers/api/PipelinesAPI';
import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
import get from 'transmute/get';
import set from 'transmute/set';
import { propertyLabelTranslator } from 'property-translator/propertyTranslator';
const translateStageLabel = stage => set('label', propertyLabelTranslator(get('label', stage)), stage);
const translateReferencedStages = referencedObject => set('stages', get('stages', referencedObject).map(translateStageLabel), referencedObject);

/**
 * @deprecated Translation of values in a data fetching library is bad practice
 *
 * Due to some expectations set by other apps, we have to show translated
 * values for deal stages. However, running translations on plain english
 * strings and translating data inside of a data resolver are both bad
 * practices that we should avoid. Please do not copy this pattern to other
 * resolvers.
 */

export const createPipelineReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.DEAL_PIPELINES,
  createFetchData: createGetAllPipelines,
  fetchData: getAllPipelines,
  selectReferences: response => {
    return get('pipelines', response).map(pipeline => set('referencedObject', translateReferencedStages(get('referencedObject', pipeline)), pipeline));
  }
}, options));
export default createPipelineReferenceResolver();