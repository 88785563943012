'use es6';

import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import { getAllSavedFilters, createGetAllSavedFilters } from 'reference-resolvers/api/SavedFiltersAPI';
import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
export const createContactsSavedFiltersReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.CONTACTS_SAVED_FILTERS,
  createFetchData: opts => createGetAllSavedFilters(opts)('contacts'),
  fetchData: getAllSavedFilters('contacts')
}, options));
export default createContactsSavedFiltersReferenceResolver();