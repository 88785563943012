'use es6';

import { Map as ImmutableMap } from 'immutable';
import { reduce } from '../lib/async';
import { CONFIGURE } from '../constants/phases';
import { connectedPhase } from '../redux/resolve/connected';
import filters from './filters';
import bucket from './bucket';
import implicit from './implicit';
import dealstage from './dealstage';
import unified from './unified';
import conversion from './conversion';
import amount from './amount';
const mutators = [filters, bucket, implicit, dealstage, conversion, unified, amount];
export const configure = config => {
  return reduce(mutators, (mutated, mutator) => mutator(mutated), config);
};
export const configureReport = report => {
  return configure(report.get('config')).then(configuredConfig => report.set('config', configuredConfig));
};
export const connectedConfigure = connectedPhase(CONFIGURE, configure);
export const configFilters = report => {
  const configuration = ImmutableMap({
    filters: report.getIn(['config', 'filters'])
  });
  return configure(configuration).then(newConfig => {
    return report.setIn(['config', 'filters'], newConfig.get('filters'));
  });
};