'use es6';

import I18n from 'I18n';
import * as colors from 'HubStyleTokens/colors';
export const configureDateLine = (dateLine, categories) => {
  const {
    date,
    key,
    label: initialLabel,
    color,
    dashStyle,
    width
  } = dateLine.toJS();
  let datetime = date || key;
  let label = initialLabel;
  if (datetime === 'today') {
    datetime = I18n.moment.portalTz();
    label = I18n.text('display.dateline.today');
  } else {
    datetime = I18n.moment.portalTz(datetime);
  }
  let closestCategory = null;
  categories.forEach((category, index) => {
    const prevCategory = categories[index - 1];
    const categoryDatetime = I18n.moment.portalTz(category.get('key'));
    if (prevCategory) {
      const prevDatetime = I18n.moment.portalTz(prevCategory.get('key'));
      if (datetime.isBetween(prevDatetime, categoryDatetime, 'day', '[)')) {
        closestCategory = prevCategory;
        return;
      } else if (datetime.isSame(categoryDatetime, 'day')) {
        closestCategory = category;
        return;
      }
    } else if (datetime.isSame(categoryDatetime, 'day')) {
      closestCategory = category;
      return;
    }
  });
  const index = categories.indexOf(closestCategory);
  const dateLineColor = color || colors.EERIE;
  const dateLineDashStyle = dashStyle || 'Dash';
  const dateLineWidth = width || 1;
  return {
    id: 'includeDateLine',
    color: dateLineColor,
    dashStyle: dateLineDashStyle,
    width: dateLineWidth,
    label: {
      text: label,
      style: {
        color: dateLineColor
      }
    },
    value: index,
    zIndex: 1
  };
};
export const getDateLine = ({
  report,
  categories
}) => {
  const dateLine = report.getIn(['displayParams', 'includeDateLine']);
  return dateLine ? {
    xAxis: {
      plotLines: [configureDateLine(dateLine, categories)]
    }
  } : {};
};