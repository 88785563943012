import get from 'transmute/get';
import { List } from 'immutable';
// @ts-expect-error: no types
import useUserInfo from '../../useUserInfo';
import { PUBLIC_APPS_SHARE_WITH_COMPONENT } from '../gates';
import { gate } from 'hub-http/gates';
export const useHasGate = gateName => {
  const {
    data,
    loading
  } = useUserInfo();
  if (loading) {
    return null;
  }
  const gates = get('gates', data) || List();
  return gates.includes(gateName);
};
export const useIsUngatedForPublicAppsShareWithComponent = () => useHasGate(PUBLIC_APPS_SHARE_WITH_COMPONENT);
export const useIsUngatedForBreezeAIBranding = () => useHasGate(gate('BreezeAI:Rebranding'));
export const useIsUngatedForAISettings = () => useHasGate('AIComponents:AISettings');