import { Map as ImmutableMap } from 'immutable';
export const REPORTING_PERF_START_KEY = 'reportingDataPerf_start';
export const REPORTING_PERF_END_KEY = 'reportingDataPerf_end';
const mark = (marks, key) => {
  if (marks.has(key)) {
    const previousValue = marks.get(key);
    // eslint-disable-next-line no-console
    console.info('ReportingPerf', `${key} was already marked with \`${previousValue}\`!`);
  }
  return marks.set(key, window.performance.now());
};
export class ReportingPerf {
  constructor() {
    this.markStart = () => {
      this.marks = mark(this.marks, REPORTING_PERF_START_KEY);
    };
    this.markEnd = () => {
      this.marks = mark(this.marks, REPORTING_PERF_END_KEY);
      return this.measure();
    };
    this.measure = () => {
      const maybeStartTime = this.marks.get(REPORTING_PERF_START_KEY);
      const maybeEndTime = this.marks.get(REPORTING_PERF_END_KEY);
      if (maybeStartTime == null || maybeEndTime == null) {
        return NaN;
      }
      return maybeEndTime - maybeStartTime;
    };
    this.getMarks = () => this.marks;
    this.marks = ImmutableMap();
  }

  /**
   * @returns {number} the measured time (in ms) elapsed between start and end
   */

  /**
   * @returns {number} measure time (in ms) elapsed between start and end
   * NaN if a key has a nil value in `marks`
   */
}