import { clearPersistedDashboardFilters, persistDashboardFilters } from '../../lib/dashboardFilters/persistedDashboardFilters';
import { clearDashboardFiltersQueryParams, removeLegacyFilterContextQueryParams, setAdhocFiltersParam, setFrequencyFiscalYearQueryParam, setFrequencyQueryParam, setSavedFilterSetIdQueryParam } from '../../lib/dashboardFilters/dashboard-filter-query-params-util';
import { setRecentlyUsedProperties } from '../../lib/dashboardFilters/recentlyUsedProperties';
import { updateFilterOnRemoveFilterByName, updateFiltersOnAddFilter, updateFiltersOnRemoveFilter, updateFiltersOnUpdateFilter, bulkUpdateFiltersOnRemoveFilter, bulkUpdateFiltersOnAddFilter } from './utils';
import { selectDashboardFilters } from './selectors';
import { appliedDashboardFiltersActions } from './slice';
import { savedDashboardFiltersActions, selectAppliedSavedFilterSet } from '../savedDashboardFilters';
import { areFiltersSame } from '../../lib/dashboardFilters/types';
import { getDefaultFilterSet, getFilterSetById } from '../../lib/savedDashboardFilters';
import { wipeCurrentVersion } from '../../lib/persistedContext';
export const filterQueryParamsMiddleware = history => ({
  getState
}) => next => action => {
  const getAppliedFilterSet = dashboardId => selectAppliedSavedFilterSet(dashboardId)(getState());
  const persistNonSavedAppliedFilters = (dashboardId, nextAppliedFilters) => {
    const maybeAppliedFilterSet = getAppliedFilterSet(dashboardId);
    if (!maybeAppliedFilterSet) {
      persistDashboardFilters(dashboardId, nextAppliedFilters);
      setAdhocFiltersParam({
        history,
        dashboardFilters: nextAppliedFilters,
        dashboardId
      });
    } else {
      const filtersLessSavedFilters = nextAppliedFilters.filter(filter => !maybeAppliedFilterSet.filters.some(filterSetFilter => areFiltersSame(filterSetFilter, filter)));
      persistDashboardFilters(dashboardId, filtersLessSavedFilters);
      setAdhocFiltersParam({
        history,
        dashboardFilters: filtersLessSavedFilters,
        dashboardId
      });
    }
  };
  if (appliedDashboardFiltersActions.initializeDashboardFilters.match(action)) {
    const {
      dashboardId,
      filters,
      filterSetId,
      filterSets,
      frequency,
      useFiscalYear
    } = action.payload;
    const maybeAppliedFilterSet = filterSets ? filterSetId != null ? getFilterSetById(filterSets, filterSetId) : getDefaultFilterSet(filterSets) : undefined;

    // Adhoc filters to be used in url param override
    const filtersLessSavedFilters = maybeAppliedFilterSet ? filters.filter(filter => !maybeAppliedFilterSet.filters.some(filterSetFilter => areFiltersSame(filterSetFilter, filter))) : filters;
    const nextFilterSetIdQueryParam = maybeAppliedFilterSet ? maybeAppliedFilterSet.defaultFilter ? undefined : maybeAppliedFilterSet.id : undefined;
    const maybeAppliedFilterSetFrequency = maybeAppliedFilterSet ? maybeAppliedFilterSet.frequency : undefined;
    const nextFrequencyParam = maybeAppliedFilterSetFrequency !== frequency ? frequency : undefined;
    persistDashboardFilters(dashboardId, filters);
    setFrequencyQueryParam({
      history,
      frequency: nextFrequencyParam
    });
    const maybeAppliedFilterSetFiscalYear = maybeAppliedFilterSet ? maybeAppliedFilterSet.useFiscalYear : undefined;
    const nextFrequencyFiscalYearParam = maybeAppliedFilterSetFiscalYear !== useFiscalYear ? useFiscalYear : undefined;
    setFrequencyFiscalYearQueryParam({
      history,
      useFiscalYear: nextFrequencyFiscalYearParam
    });
    setSavedFilterSetIdQueryParam({
      history,
      filterSetId: nextFilterSetIdQueryParam
    });
    setAdhocFiltersParam({
      history,
      dashboardFilters: filtersLessSavedFilters,
      dashboardId
    });
  }
  if (appliedDashboardFiltersActions.loadMigratedLegacyFilters.match(action)) {
    const {
      dashboardFilters,
      dashboardId,
      frequency
    } = action.payload;
    removeLegacyFilterContextQueryParams({
      history
    });
    wipeCurrentVersion();
    setFrequencyQueryParam({
      history,
      frequency
    });
    setAdhocFiltersParam({
      history,
      dashboardFilters,
      dashboardId
    });
    persistDashboardFilters(dashboardId, dashboardFilters);
  }

  // when a new saved dashboard filter set is added all filters and settings in the new set override the current applied filters
  if (savedDashboardFiltersActions.addNewSavedDashboardFilterSet.match(action)) {
    const {
      payload: {
        dashboardId,
        id,
        defaultFilter,
        frequency,
        useFiscalYear
      }
    } = action;
    if (frequency) {
      // frequency is derived from the filterId param - remove the frequency query param
      setFrequencyQueryParam({
        history,
        frequency: undefined
      });
    }
    if (useFiscalYear != null) {
      // frequency is derived from the filterId param - remove the frequency query param
      setFrequencyFiscalYearQueryParam({
        history
      });
    }
    setSavedFilterSetIdQueryParam({
      history,
      filterSetId: !defaultFilter ? id : undefined
    });
    setAdhocFiltersParam({
      history,
      dashboardFilters: [],
      dashboardId
    });
    persistDashboardFilters(dashboardId, []);
  }

  // if the applied filter set was updated - update query params
  if (savedDashboardFiltersActions.updateSavedDashboardFilterSet.match(action)) {
    const {
      payload: {
        dashboardId,
        id,
        defaultFilter,
        frequency,
        useFiscalYear
      }
    } = action;
    const appliedFilterSet = getAppliedFilterSet(dashboardId);
    if (appliedFilterSet && appliedFilterSet.id === id) {
      if (frequency) {
        // frequency is derived from the filterId param - remove the frequency query param
        setFrequencyQueryParam({
          history,
          frequency: undefined
        });
      }
      if (useFiscalYear != null) {
        // frequency is derived from the filterId param - remove the frequency query param
        setFrequencyFiscalYearQueryParam({
          history
        });
      }
      setAdhocFiltersParam({
        history,
        dashboardFilters: [],
        dashboardId
      });
      setSavedFilterSetIdQueryParam({
        history,
        filterSetId: !defaultFilter ? id : undefined
      });
      persistDashboardFilters(dashboardId, []);
    }
  }
  if (savedDashboardFiltersActions.loadSavedDashboardFilterSet.match(action)) {
    const {
      payload: {
        dashboardId,
        nextAppliedFilterSet
      }
    } = action;
    setAdhocFiltersParam({
      history,
      dashboardFilters: [],
      dashboardId
    });
    if (nextAppliedFilterSet) {
      const {
        defaultFilter,
        id,
        frequency,
        useFiscalYear
      } = nextAppliedFilterSet;
      if (frequency) {
        // frequency is derived from the filterId param - remove the frequency query param
        setFrequencyQueryParam({
          history
        });
      }
      if (useFiscalYear != null) {
        // frequency is derived from the filterId param - remove the frequency query param
        setFrequencyFiscalYearQueryParam({
          history
        });
      }
      setSavedFilterSetIdQueryParam({
        history,
        filterSetId: !defaultFilter ? id : undefined
      });
    } else {
      setSavedFilterSetIdQueryParam({
        history,
        filterSetId: undefined
      });
    }
    persistDashboardFilters(dashboardId, []);
  }
  if (appliedDashboardFiltersActions.addDashboardFilter.match(action)) {
    const {
      dashboardId,
      filterData,
      dataSource,
      property
    } = action.payload;
    const prevFilters = selectDashboardFilters(dashboardId)(getState());
    const updatedFilters = updateFiltersOnAddFilter(prevFilters, filterData);
    persistNonSavedAppliedFilters(dashboardId, updatedFilters);
    if (dataSource && property) {
      setRecentlyUsedProperties({
        dataSource,
        property
      }).catch(console.error);
    }
  }
  if (appliedDashboardFiltersActions.updateDashboardFilter.match(action)) {
    const {
      dashboardId,
      index,
      filterData
    } = action.payload;
    const prevFilters = selectDashboardFilters(dashboardId)(getState());
    const updatedFilters = updateFiltersOnUpdateFilter(prevFilters, index, filterData);
    persistNonSavedAppliedFilters(dashboardId, updatedFilters);
  }
  if (appliedDashboardFiltersActions.addGroupDashboardFilter.match(action)) {
    const {
      dashboardId,
      quickFilterGroupOperators,
      systemGenerated,
      quickFilterGroupName
    } = action.payload;
    const prevFilters = selectDashboardFilters(dashboardId)(getState());
    const updatedFilters = bulkUpdateFiltersOnAddFilter(prevFilters, quickFilterGroupOperators, systemGenerated, quickFilterGroupName);
    persistNonSavedAppliedFilters(dashboardId, updatedFilters);
  }
  if (appliedDashboardFiltersActions.removeDashboardFilter.match(action)) {
    const {
      dashboardId,
      index
    } = action.payload;
    const prevFilters = selectDashboardFilters(dashboardId)(getState());
    const updatedFilters = updateFiltersOnRemoveFilter(prevFilters, index);
    persistNonSavedAppliedFilters(dashboardId, updatedFilters);
  }
  if (appliedDashboardFiltersActions.removeDashboardFilterByName.match(action)) {
    const {
      dashboardId,
      dataSource,
      propertyName
    } = action.payload;
    const prevFilters = selectDashboardFilters(dashboardId)(getState());
    const updatedFilters = updateFilterOnRemoveFilterByName(prevFilters, dataSource, propertyName);
    persistNonSavedAppliedFilters(dashboardId, updatedFilters);
  }
  if (appliedDashboardFiltersActions.removeGroupDashboardFilter.match(action)) {
    const {
      dashboardId,
      properties
    } = action.payload;
    const prevFilters = selectDashboardFilters(dashboardId)(getState());
    const updatedFilters = bulkUpdateFiltersOnRemoveFilter(prevFilters, properties);
    persistNonSavedAppliedFilters(dashboardId, updatedFilters);
  }
  if (appliedDashboardFiltersActions.removeCurrentDashboardFilters.match(action)) {
    const {
      dashboardId
    } = action.payload;
    persistDashboardFilters(dashboardId, []);
    clearDashboardFiltersQueryParams({
      history
    });
  }
  if (appliedDashboardFiltersActions.removeAllDashboardFilters.match(action)) {
    clearPersistedDashboardFilters();
    removeLegacyFilterContextQueryParams({
      history
    });
    clearDashboardFiltersQueryParams({
      history
    });
  }

  // only add frequency param if applied frequency is not the same as the applied filters sets frequency
  if (appliedDashboardFiltersActions.updateFrequency.match(action)) {
    const {
      dashboardId,
      frequency
    } = action.payload;
    const appliedFilterSet = getAppliedFilterSet(dashboardId);
    const frequencyFromAppliedFilterSet = appliedFilterSet ? appliedFilterSet.frequency : undefined;
    setFrequencyQueryParam({
      history,
      frequency: frequencyFromAppliedFilterSet !== frequency ? frequency : undefined
    });
  }

  // only add frequency param if applied frequency is not the same as the applied filters sets frequency
  if (appliedDashboardFiltersActions.updateFrequencyUseFiscalYear.match(action)) {
    const {
      dashboardId,
      useFiscalYear
    } = action.payload;
    const appliedFilterSet = getAppliedFilterSet(dashboardId);
    const frequencyFiscalYearFromAppliedFilterSet = appliedFilterSet ? appliedFilterSet.useFiscalYear : undefined;
    setFrequencyFiscalYearQueryParam({
      history,
      useFiscalYear: frequencyFiscalYearFromAppliedFilterSet !== useFiscalYear ? useFiscalYear : undefined
    });
  }
  next(action);
};