'use es6';

import { Map as ImmutableMap } from 'immutable';
import defaultCompareTo from './defaultCompareTo';
import defaultDateFilter from './defaultDateFilter';
import defaultFrequency from './defaultFrequency';
import defaultOwnerContext from './defaultOwnerContext';
import defaultPipelineContext from './defaultPipelineContext';
import defaultTeamContext from './defaultTeamContext';
export default ImmutableMap({
  context: defaultDateFilter,
  compareTo: defaultCompareTo,
  frequency: defaultFrequency,
  owner: defaultOwnerContext,
  pipeline: defaultPipelineContext,
  team: defaultTeamContext
});