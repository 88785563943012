import { hasScope, isUngated } from 'collaboration-sidebar/utils/auth';
import { FEATURES } from 'collaboration-sidebar/constants/features';
import { CALENDAR_ACCESS_SCOPE } from 'collaboration-sidebar/constants/scopes';
import { SUPPORTED_HUBS_REQUIRED_SCOPES } from 'collaboration-sidebar/constants/supportedHubs';
/**
 * CALENDAR should only be enabled when the user has the CALENDAR_ACCESS_SCOPE scope.
 */
function checkCalendarAccess({
  enabledFeaturesFromAppSettings
}) {
  if (enabledFeaturesFromAppSettings.includes(FEATURES.CALENDAR) && !hasScope(CALENDAR_ACCESS_SCOPE)) {
    return enabledFeaturesFromAppSettings.filter(feature => feature !== FEATURES.CALENDAR);
  }
  return enabledFeaturesFromAppSettings;
}

/**
 * TASKS should only be enabled when the user has the CALENDAR_ACCESS_SCOPE scope.
 */
function checkTasksAccess({
  enabledFeaturesFromAppSettings
}) {
  if (enabledFeaturesFromAppSettings.includes(FEATURES.TASKS) && !hasScope(CALENDAR_ACCESS_SCOPE)) {
    return enabledFeaturesFromAppSettings.filter(feature => feature !== FEATURES.TASKS);
  }
  return enabledFeaturesFromAppSettings;
}

/**
 * COMMENT is a hub feature and it should only be present if the user has the correct scopes for the
 * hubs used in FAS
 */
function checkCommentAccess({
  appHub,
  enabledFeaturesFromAppSettings
}) {
  if (!enabledFeaturesFromAppSettings.includes(FEATURES.COMMENTS) && !enabledFeaturesFromAppSettings.includes(FEATURES.COMMENTS)) {
    return enabledFeaturesFromAppSettings;
  }
  if (!appHub || appHub.value.length === 0) {
    return enabledFeaturesFromAppSettings.filter(feature => feature !== FEATURES.COMMENTS && feature !== FEATURES.DEEP_COMMENTING);
  }
  const hasCommentScopes = appHub.value.some(hub => hasScope(SUPPORTED_HUBS_REQUIRED_SCOPES[hub]));
  if (!hasCommentScopes) {
    return enabledFeaturesFromAppSettings.filter(feature => feature !== FEATURES.COMMENTS && feature !== FEATURES.DEEP_COMMENTING);
  }
  return enabledFeaturesFromAppSettings;
}

/**
 * COPILOT should only be enabled when the portal is ungated to Chatspot:Widget:Beta.
 * Since this will be common to all apps using collab-sidebar, we're going to keep this check in code to avoid having to update several app setting entries later on when this gate is rolled out to all.
 */
function checkCopilotAccess({
  enabledFeaturesFromAppSettings
}) {
  if (enabledFeaturesFromAppSettings.includes(FEATURES.COPILOT) && !isUngated('Chatspot:Widget:Beta')) {
    return enabledFeaturesFromAppSettings.filter(feature => feature !== FEATURES.COPILOT);
  }
  return enabledFeaturesFromAppSettings;
}

/**
 * APPROVALS could depend on external factors other than gates and scopes such as
 * calling an external endpoint to check if the feature is enabled.
 * In that case, we need to check if APPROVALS is present in the propEnabledFeatures and manually add it to the final result.
 */
function checkApprovalsAccess({
  enabledFeaturesFromAppSettings,
  propEnabledFeatures
}) {
  if (!enabledFeaturesFromAppSettings.includes(FEATURES.APPROVALS) && propEnabledFeatures !== null && propEnabledFeatures !== void 0 && propEnabledFeatures.includes(FEATURES.APPROVALS)) {
    return [...enabledFeaturesFromAppSettings, FEATURES.APPROVALS];
  }
  return enabledFeaturesFromAppSettings;
}
export function convertEnabledFeatures({
  appHub,
  fasEnabledFeatures,
  propEnabledFeatures
}) {
  if (!fasEnabledFeatures || !fasEnabledFeatures.value) {
    return [];
  }
  let enabledFeaturesFromAppSettings = fasEnabledFeatures.value.map(feature => {
    /**
     * No gates or scopes to check: feature is enabled
     */
    if (!feature.scopes && !feature.gates) {
      return feature.name;
    }

    /**
     * feature might have scopes and or gates.
     * In that case we need to check if the user has all the scopes and gates required before enabling the feature
     */

    const hasAllScopes = feature.scopes ? feature.scopes.every(scope => hasScope(scope)) : true;
    const isUngatedToAll = feature.gates ? feature.gates.every(gate => isUngated(gate)) : true;
    return hasAllScopes && isUngatedToAll ? feature.name : null;
  }).filter(Boolean);
  enabledFeaturesFromAppSettings = checkApprovalsAccess({
    enabledFeaturesFromAppSettings,
    propEnabledFeatures
  });
  enabledFeaturesFromAppSettings = checkCopilotAccess({
    enabledFeaturesFromAppSettings
  });
  enabledFeaturesFromAppSettings = checkCalendarAccess({
    enabledFeaturesFromAppSettings
  });
  enabledFeaturesFromAppSettings = checkTasksAccess({
    enabledFeaturesFromAppSettings
  });
  enabledFeaturesFromAppSettings = checkCommentAccess({
    appHub,
    enabledFeaturesFromAppSettings
  });
  return enabledFeaturesFromAppSettings;
}