/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import { List } from 'immutable';
import { propertyLabelTranslator, propertyLabelTranslatorWithIsHubSpotDefined } from 'property-translator/propertyTranslator';
import { ATTRIBUTION_TOUCH_POINTS, COMPANIES, CONTACTS, CONTACT_CREATE_ATTRIBUTION, DEALS, DEAL_CREATE_ATTRIBUTION, ENGAGEMENTS, FEEDBACK_SUBMISSIONS, LINE_ITEMS, PRODUCTS, QUOTAS, SOCIAL_POSTS, TICKETS } from '../../../../constants/dataTypes';
import { DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES } from '../../../../constants/objectCoordinates';
import { fetchCrmObjectProperties } from '../../../../crmObjects/tsUtils';
import { MissingPropertiesException } from '../../../../exceptions';
import { Promise } from '../../../../lib/promise';
import * as http from '../../../../request/http';
const STANDARD_URL_DATA_TYPES = List([ATTRIBUTION_TOUCH_POINTS, CONTACT_CREATE_ATTRIBUTION, CONTACTS, COMPANIES, DEAL_CREATE_ATTRIBUTION, DEALS, ENGAGEMENTS, FEEDBACK_SUBMISSIONS, LINE_ITEMS, PRODUCTS, QUOTAS, TICKETS]);
const NON_STANDARD_URLS = {
  [SOCIAL_POSTS]: 'social-reporting/v1/report/posts/groups'
};
const fetchNonStandardProperties = dataType => http.get(NON_STANDARD_URLS[dataType], {
  query: {
    includeProperties: true
  }
});
const propertyTranslatorNamespaces = {
  [ATTRIBUTION_TOUCH_POINTS]: 'attributionProperties',
  [CONTACT_CREATE_ATTRIBUTION]: 'contactCreateAttributionProperties',
  [DEAL_CREATE_ATTRIBUTION]: 'dealCreateAttributionProperties'
};
const translateProperties = (propertyGroups, dataType) => propertyGroups.map(group => group.update('displayName', propertyLabelTranslator).update('properties', properties => {
  return properties.map(property => {
    const translated = property.update('label', label => propertyLabelTranslatorWithIsHubSpotDefined({
      label,
      nameSpaceLookupKey: propertyTranslatorNamespaces[dataType],
      isHubSpotDefined: property.get('hubspotDefined')
    }));
    return property.has('options') ? translated.update('options', options => options.map(option => option.update('label', label => propertyLabelTranslatorWithIsHubSpotDefined({
      label,
      nameSpaceLookupKey: propertyTranslatorNamespaces[dataType],
      isHubSpotDefined: property.get('hubspotDefined')
    })))) : translated;
  });
}));
export default (dataType => {
  if (NON_STANDARD_URLS.hasOwnProperty(dataType)) {
    fetchNonStandardProperties(dataType).then(propertyGroups => translateProperties(propertyGroups, dataType));
  }
  return STANDARD_URL_DATA_TYPES.includes(dataType) ? fetchCrmObjectProperties(DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(dataType, dataType)) : Promise.reject(new MissingPropertiesException(dataType));
});