'use es6';

import { Set as ImmutableSet } from 'immutable';
import { NO_ID, MY_OWNER_ID } from '../../constants/magicTypes';
import { Promise } from '../../lib/promise';
import { userInfo } from '../../request/user-info';
import * as owners from '../../references/owner/owners';
const findOwner = ([{
  user: {
    user_id: userId
  }
}, response]) => response.reduce((memo, {
  remoteList
}) => [...memo, ...remoteList], []).find(({
  remoteId
}) => String(userId) === remoteId);
export default ((config, paths = ImmutableSet()) => Promise.all([userInfo(), owners.get()]).then(findOwner).then(({
  ownerId = NO_ID
} = {}) => paths.reduce((memo, path) => memo.updateIn(['filters', ...path], value => value === MY_OWNER_ID && ownerId != null ? ownerId : NO_ID), config)));