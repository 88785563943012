'use es6';

import { WrapWithRaven } from '../sentry/sales-analytics-sentry';
import { DIMENSIONS } from './salesAnalyticsHelpers';
import { getDefaultFrequencyForRangeType } from './dateRangeHelpers';
export const applyDefaultFrequency = WrapWithRaven('applyDefaultFrequency', (contextChange, context = {}, report) => {
  const shouldApplyDefaultFrequency = contextChange.dateRange || contextChange.grouping === DIMENSIONS.DATE;
  if (contextChange.frequency == null && shouldApplyDefaultFrequency) {
    const dateRange = contextChange.dateRange || context.dateRange;
    const intraperiod = contextChange.intraperiod != null ? contextChange.intraperiod : context.intraperiod;
    return Object.assign({}, contextChange, {
      frequency: getDefaultFrequencyForRangeType(dateRange, report, intraperiod)
    });
  } else {
    return contextChange;
  }
});