export const SINGLE = 350;
export const DOUBLE = 550;
export const TRIPLE = 700;
export function getMenuWidth(size) {
  switch (size) {
    case 1:
      return SINGLE;
    case 2:
      return DOUBLE;
    case 3:
      return TRIPLE;
    default:
      throw new Error(`MenuWidths: '${size}' is not a supported menu size`);
  }
}