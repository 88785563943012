'use es6';

import { getAll as getAllProblems } from '../../private/action-problem-solution/actions/static-widget-action/problem';
import { getProblem } from '../../private/action-problem-solution/common/get-problem';
import { isEnabled } from '../../private/action-problem-solution/common/is-enabled';
export function canTakeStaticWidgetAction(params) {
  return isEnabled(params, getAllProblems);
}
export function getStaticWidgetActionProblem(params) {
  return getProblem(params, getAllProblems);
}