'use es6';

import { Map as ImmutableMap, fromJS } from 'immutable';
import I18n from 'I18n';
import { batch } from './owners';
import { validNumerical } from '../ids';
import { makeOption } from '../Option';
import { DEFAULT_NULL_VALUES } from '../../constants/defaultNullValues';
import formatName from 'I18n/utils/formatName';
export const generateOwnerLabel = (owner, key, {
  isRelational
} = {}) => {
  owner = fromJS(owner);
  const ownerDataMissing = !owner || !owner.has('firstName') && !owner.has('lastName') && !owner.has('email') && !owner.has('label');
  const missingDataKey = key === DEFAULT_NULL_VALUES.ENUMERATION || key === '' || Number(key) === 0;
  if (ownerDataMissing || missingDataKey) {
    return key && key !== DEFAULT_NULL_VALUES.ENUMERATION && Number(key) !== 0 ? I18n.text('reporting-data.missing.unknown.owner', {
      id: key
    }) : isRelational ? I18n.text('reporting-data.missing.value') : I18n.text('reporting-data.missing.unassigned');
  }
  if (owner.has('label')) {
    return owner.get('label');
  } else {
    /* This case gets hit by some FE resolve reports
     *  such as Sales Analytics reports. Once these
     *  reports are moved to backend resolve, this
     *  portion will be unused and can be cleaned up
     *  since the BE resolve provides this labeling.
     */

    return formatName({
      firstName: owner.get('firstName'),
      lastName: owner.get('lastName'),
      email: owner.get('email')
    });
  }
};
export default (ids => batch(validNumerical(ids)).then(owners => Object.keys(owners).reduce((options, ownerId) => options.set(String(ownerId), makeOption(ownerId, generateOwnerLabel(owners[ownerId]))), ids.includes(DEFAULT_NULL_VALUES.ENUMERATION) ? ImmutableMap({
  [DEFAULT_NULL_VALUES.ENUMERATION]: makeOption(DEFAULT_NULL_VALUES.ENUMERATION, I18n.text('reporting-data.missing.unassigned'))
}) : ImmutableMap())));