'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { DEALS, LINE_ITEMS } from '../../constants/dataTypes';
import compose from '../../lib/compose';
import { configureMetrics, configureFilters } from '../common';
const mapping = ImmutableMap({
  [DEALS]: {
    amount_in_deal_currency: 'amount',
    closed_amount_in_deal_currency: 'hs_closed_amount',
    projected_amount_in_deal_currency: 'hs_projected_amount'
  },
  [LINE_ITEMS]: {
    amount: 'amount',
    price: 'price'
  }
});
const shouldConfigure = config => {
  const dataType = config.get('dataType');
  if (!mapping.has(dataType)) {
    return false;
  }
  return Boolean(config.get('metrics', List()).find(metric => mapping.hasIn([dataType, metric.get('property')])));
};
const configure = compose(configureFilters(mapping), configureMetrics(mapping));
export default (config => shouldConfigure(config) ? configure(config) : config);