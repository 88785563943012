'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { Promise } from '../../lib/promise';
import prefix from '../../lib/prefix';
import { COMMUNICATION } from '../../constants/dataTypes';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import getCommonPropertyGroups from '../partial/engagement-common';
import countProperty from '../partial/count-property';
import engagementModule from '../../dataTypeDefinitions/inboundDb/engagement';
import overridePropertyTypes from '../../retrieve/inboundDb/common/overridePropertyTypes';
const translateCommunication = prefix('reporting-data.properties.communication');
const translateGroup = prefix('reporting-data.groups.engagement');
export const getCommunicationPropertyGroups = () => Promise.resolve(List.of(ImmutableMap({
  name: 'communicationInfo',
  displayName: translateGroup('communicationInfo'),
  displayOrder: 0,
  hubspotDefined: true,
  properties: List([ImmutableMap({
    name: 'communication.body',
    label: translateCommunication('body'),
    type: 'string'
  })])
})));
export const getPropertyGroups = () => Promise.resolve(getCommunicationPropertyGroups().then(communicationPropertyGroups => List([...getCommonPropertyGroups(), ...communicationPropertyGroups])));
export const getProperties = () => createPropertiesGetterFromGroups(getPropertyGroups, properties => properties.merge(countProperty(COMMUNICATION)))().then(overridePropertyTypes(engagementModule.getInboundSpec()));