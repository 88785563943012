'use es6';

import { CONTACT_PROPERTIES } from 'reference-resolvers/constants/CacheKeys';
import { getContactProperties, createGetContactProperties } from 'reference-resolvers/api/PropertiesAPI';
import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
export const createContactPropertyReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CONTACT_PROPERTIES,
  createFetchData: createGetContactProperties,
  fetchData: getContactProperties
}, options));
export default createContactPropertyReferenceResolver();