'use es6';

import { createSelector } from 'reselect';
import * as reportsApi from './api';
import fromJS from 'transmute/fromJS';
import { RequestState } from '../request/request-state';
const {
  FAILED,
  PENDING,
  SUCCEEDED,
  UNINITIALIZED
} = RequestState;
export const reportsNamespace = 'reports';

// Action types
export const FETCH_REPORTS_PENDING = 'FETCH_REPORTS_PENDING';
export const FETCH_REPORTS_SUCCEEDED = 'FETCH_REPORTS_SUCCEEDED';
export const FETCH_REPORTS_FAILED = 'FETCH_REPORTS_FAILED';
export const FETCH_REPORTS_COUNT_PENDING = 'FETCH_REPORTS_COUNT_PENDING';
export const FETCH_REPORTS_COUNT_SUCCEEDED = 'FETCH_REPORTS_COUNT_SUCCEEDED';
export const FETCH_REPORTS_COUNT_FAILED = 'FETCH_REPORTS_COUNT_FAILED';

// Actions
export const fetchReports = () => dispatch => {
  dispatch({
    type: FETCH_REPORTS_PENDING
  });
  return reportsApi.fetchReports().then(reportsMappedById => {
    dispatch({
      type: FETCH_REPORTS_SUCCEEDED,
      payload: reportsMappedById
    });
  }, error => {
    console.error(error);
    dispatch({
      type: FETCH_REPORTS_FAILED,
      payload: error
    });
  });
};
export const fetchReportsCount = () => dispatch => {
  dispatch({
    type: FETCH_REPORTS_COUNT_PENDING
  });
  return reportsApi.fetchReportsCount().then(reportsCountList => {
    return dispatch({
      type: FETCH_REPORTS_COUNT_SUCCEEDED,
      payload: reportsCountList
    });
  }, error => {
    console.error(error);
    dispatch({
      type: FETCH_REPORTS_COUNT_FAILED,
      payload: error
    });
  });
};

// Reducers
const initialState = fromJS({
  reports: [],
  reportsCount: {},
  errorMsg: {
    fetchReports: '',
    fetchReportsCount: ''
  },
  status: {
    fetchReports: UNINITIALIZED,
    fetchReportsCount: UNINITIALIZED
  }
});
export const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPORTS_PENDING:
      return state.setIn(['status', 'fetchReports'], PENDING).setIn(['errorMsg', 'fetchReports'], '');
    case FETCH_REPORTS_SUCCEEDED:
      return state.setIn(['status', 'fetchReports'], SUCCEEDED).setIn(['errorMsg', 'fetchReports'], '').set('reports', action.payload);
    case FETCH_REPORTS_FAILED:
      return state.setIn(['status', 'fetchReports'], FAILED).setIn(['errorMsg', 'fetchReports'], action.payload);
    case FETCH_REPORTS_COUNT_PENDING:
      return state.setIn(['status', 'fetchReportsCount'], PENDING).setIn(['errorMsg', 'fetchReportsCount'], '');
    case FETCH_REPORTS_COUNT_SUCCEEDED:
      return state.setIn(['status', 'fetchReportsCount'], SUCCEEDED).setIn(['errorMsg', 'fetchReportsCount'], '').set('reportsCount', action.payload);
    case FETCH_REPORTS_COUNT_FAILED:
      return state.setIn(['status', 'fetchReportsCount'], FAILED).setIn(['errorMsg', 'fetchReportsCount'], action.payload);
    default:
      return state;
  }
};

// General selectors
export const selectReportsRoot = state => state[reportsNamespace];
const selectRequestErrorMsg = requestName => createSelector(selectReportsRoot, reportsRoot => reportsRoot.getIn(['errorMsg', requestName]));
const selectRequestStatus = requestName => createSelector(selectReportsRoot, reportsRoot => reportsRoot.getIn(['status', requestName]));

// Report asset selectors
export const selectFetchReportsError = selectRequestErrorMsg('fetchReports');
export const selectFetchReportsStatus = selectRequestStatus('fetchReports');
export const selectReports = createSelector(selectReportsRoot, reportsRoot => reportsRoot.get('reports'));

// Report count selectors
export const selectFetchReportsCountError = selectRequestErrorMsg('fetchReportsCount');
export const selectFetchReportsCountStatus = selectRequestStatus('fetchReportsCount');
export const selectSpecificReportsCount = reportType => createSelector(selectReportsRoot, reportsRoot => reportsRoot.getIn(['reportCounts', reportType]));
export const selectAllReportsCount = selectSpecificReportsCount('allReports');
export const selectCustomReportsCount = selectSpecificReportsCount('customReports');
export const selectReportsInDashboardsCount = selectSpecificReportsCount('inDashboards');
export const selectReportsLimitCount = selectSpecificReportsCount('reportsLimits');
export const selectReportsNotInDashboardsCount = selectSpecificReportsCount('notInDashboards');