'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import { propertyLabelTranslator } from 'property-translator/propertyTranslator';
import toJS from '../../lib/toJS';
import * as http from '../../request/http';
export default (() => http.get('twilio/v1/custom-dispositions', {
  query: {
    includeDeleted: true
  }
}).then(toJS).then(({
  callDispositions
}) => List(callDispositions.map(({
  uid,
  label,
  displayOrder
}) => ImmutableMap({
  value: uid,
  label: propertyLabelTranslator(label, 'reporting-data.properties.call.dispositionTypes'),
  displayOrder
})))));