'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { getTotalMeasureAlias, isTotalAlias } from 'reporting-visualizations/visualizations/crossTab/dataHelpers/totals';
export const isTotalMeasure = measure => isTotalAlias(measure.alias);
const toGrandTotalMeasure = measure => {
  return measure.set('alias', getTotalMeasureAlias(measure.alias, [])).set('fixedMeasure', true).set('fixed', List([]));
};
export const applySlicedTotals = report => {
  const {
    columns: reportColumns,
    visual: {
      encodings: {
        color: colorEncodings,
        values: valuesEncodings
      },
      options: {
        showTotals
      } = {}
    }
  } = report;
  if (!showTotals) {
    return report;
  }
  if (!colorEncodings || !valuesEncodings) {
    return report;
  }
  if (valuesEncodings.isEmpty()) {
    return report;
  }
  const values = valuesEncodings.map(({
    column: alias
  }) => alias);
  const firstValueColumn = reportColumns.get(values.first());
  const totalColumn = toGrandTotalMeasure(firstValueColumn);
  const newColumnsMap = ImmutableMap({
    [totalColumn.alias]: totalColumn
  });
  const nextColumns = reportColumns.merge(newColumnsMap);
  return report.set('columns', nextColumns);
};