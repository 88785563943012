'use es6';

import * as CacheKeys from 'reference-resolvers/constants/CacheKeys';
import createSimpleCachedReferenceResolver from 'reference-resolvers/lib/createSimpleCachedReferenceResolver';
import { getAllMeetingOutcomes, createGetAllMeetingOutcomes } from 'reference-resolvers/api/MeetingOutcomesAPI';
export const createMeetingOutcomeReferenceResolver = options => createSimpleCachedReferenceResolver(Object.assign({
  cacheKey: CacheKeys.MEETING_OUTCOME,
  createFetchData: createGetAllMeetingOutcomes,
  fetchData: getAllMeetingOutcomes
}, options));
export default createMeetingOutcomeReferenceResolver();