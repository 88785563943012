'use es6';

import { handleActions } from 'redux-actions';
import compose from 'transmute/compose';
import curry from 'transmute/curry';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import reduce from 'transmute/reduce';
import update from 'transmute/update';
import { defaultTo } from '../../private/js-util';
import { RequestState } from './request-state';
import { AvailableState } from './resource-availability-state';
import { clearPromise, getValue, ResourceState, setAvailability, setPromise, setStatus, setValue } from './resource-state';
import { setError } from 'dashboard-lib/public/request/resource-state';
const {
  UNINITIALIZED,
  SUCCEEDED,
  PENDING,
  FAILED,
  CANCELED
} = RequestState;
const getPayload = get('payload');
const getPromise = getIn(['payload', 'promise']);
export const selectResource = curry((namespace, state) => get(namespace, state));
export const handleResourcePend = curry((promise, state) => compose(setStatus(PENDING), setPromise(promise), defaultTo(ResourceState()))(state));
export const handleResourcePendAction = curry((action, state) => handleResourcePend(getPromise(action), state));
export const handleResourceFail = curry((error, state) => compose(setStatus(FAILED), setError(error), clearPromise, defaultTo(ResourceState()))(state));
export const handleResourceFailAction = curry((action, state) => handleResourceFail(getPayload(action), state));
export const handleResourceSucceed = curry((value, state) => compose(setAvailability(AvailableState.INITIALIZED), setStatus(SUCCEEDED), setValue(value), clearPromise, defaultTo(ResourceState()))(state));
export const handleResourceSucceedAction = curry((action, state) => handleResourceSucceed(getPayload(action), state));
export const handleResourceCancel = curry((_, state) => {
  const nextStatus = getValue(state) === undefined ? UNINITIALIZED : CANCELED;
  return compose(setStatus(nextStatus), clearPromise, defaultTo(ResourceState()))(state);
});
export const handleResourceCancelAction = curry((action, state) => handleResourceCancel(action, state));

/**
 * Merges a map (newMap) into another (oldMap) using the updater function
 * @param updater - (newValue, oldValue) -> updatedValue
 * @param {Map|Object} newMap - used to update
 * @param {Map|Object} oldMap - to be updated
 * @returns updated map
 */
const mergeWith = curry((updater, newMap, oldMap) => reduce(oldMap, (acc, value, key) => update(key, updater(value), acc), newMap));
export const handleResourcePendMapOfValue = mergeWith(handleResourcePend);
export const handleResourceSuccessMapOfValue = mergeWith(handleResourceSucceed);
export const handleResourceFailMapOfValue = mergeWith(handleResourceFail);
export const handleResourceCancelMapOfValue = mergeWith(handleResourceCancel);

/**
 * A version of {handleResourceSuccessMapOfValue} that expects an action with a
 * payload containing a map of values.
 */
export const handleResourceSuccessMapOfValueAction = (action, mapOfResource) => {
  return handleResourceSuccessMapOfValue(getPayload(action), mapOfResource);
};
export const createReducer = curry((actions, fallbackValue = null) => {
  const {
    pend,
    succeed,
    fail,
    cancel
  } = actions;
  return handleActions({
    [pend](state, action) {
      return handleResourcePendAction(action, state);
    },
    [succeed](state, action) {
      return handleResourceSucceedAction(action, state);
    },
    [fail](state, action) {
      return handleResourceFailAction(action, state);
    },
    [cancel](state, action) {
      return handleResourceCancelAction(action, state);
    }
  }, ResourceState({
    value: fallbackValue
  }));
});