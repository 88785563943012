'use es6';

import { reduce } from '../../lib/async';
import * as overrides from '../../lib/overrides';
import { configure as feedback } from './feedback';
import { configure as campaigns } from './analytics-campaigns';
import { configure as configureFilters } from './configure-filters';
const optional = [];
const required = [feedback, campaigns, configureFilters];
export default (config => {
  const mutators = overrides.unified.enabled ? [...optional, ...required] : required;
  return reduce(mutators, (mutated, mutator) => mutator(mutated), config);
});