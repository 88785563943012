'use es6';

import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as OperationTypes from '../OperationTypes';
import { AdsEntityListTypes, AdsSearchTermTypes } from '../../listSegClassic/ListSegConstants';
import getIn from 'transmute/getIn';
import toRefinementFilter from './toRefinementFilter';
import toValueComparison from './util/toValueComparison';
const SuffixToSearchTermType = {
  id: AdsEntityListTypes.ID,
  name: AdsEntityListTypes.NAME,
  timestamp: AdsEntityListTypes.TIMESTAMP,
  type: AdsEntityListTypes.AD_TYPE
};
const EntityToEntityType = {
  keyword: AdsSearchTermTypes.KEYWORD,
  adgroup: AdsSearchTermTypes.ADGROUP,
  ad: AdsSearchTermTypes.AD,
  campaign: AdsSearchTermTypes.CAMPAIGN
};
const getAdNetwork = operator => getIn(['field', 'wrappedObject', 'adNetwork'], operator);
const _toAddsSearchFilter = (operator, valueComparison) => {
  const [__network, entity, suffix] = operator.field.name.split('_');
  return {
    adNetwork: (getAdNetwork(operator) || '').toUpperCase(),
    entityType: EntityToEntityType[entity],
    filterType: ObjectSegFilterTypes.ADS_SEARCH,
    operator: valueComparison.operator,
    searchTerms: valueComparison.values || [],
    searchTermType: SuffixToSearchTermType[suffix]
  };
};
const _toAdsTimeFilter = operator => {
  const refinement = toRefinementFilter(operator);
  if (refinement) {
    return Object.assign({
      filterType: ObjectSegFilterTypes.ADS_TIME
    }, refinement);
  }
  return undefined;
};
const SUPPORTED_TIME_BASED_OPERATIONS = [OperationTypes.DATE_TIME, OperationTypes.DATE_TIME_RANGED, OperationTypes.DATE_TIME_ROLLING];
const toAdsFilter = (operator, __filterFamily, options) => {
  const valueComparison = toValueComparison(operator, options);
  const isTimeBasedFilter = SUPPORTED_TIME_BASED_OPERATIONS.includes(valueComparison.propertyType);
  return isTimeBasedFilter ? _toAdsTimeFilter(operator) : _toAddsSearchFilter(operator, valueComparison);
};
export default toAdsFilter;