'use es6';

import { has } from '../../../lib/has';
import { pairs } from './metrics';
import { one } from './downgrade';
export const getPeopleType = ({
  filters: {
    custom = []
  } = {}
}) => {
  const filter = custom.find(({
    property
  }) => property === 'peopleType');
  return filter ? filter.value : 'visits';
};
const parse = (spec, config) => response => {
  const {
    dimensions,
    metrics = []
  } = config;
  const {
    total,
    people
  } = response;
  const keys = people.map(({
    vid
  }) => vid);
  const remapped = people.reduce((mapped, person) => Object.assign({}, mapped, {
    [person.vid]: person
  }), {});
  const matrix = {
    dimensions,
    metrics: pairs(config),
    keys: [keys],
    data: keys.map(key => metrics.map(({
      property
    }) => has(remapped, key) ? has(remapped[key], property) ? remapped[key][property] : has(remapped[key].metadata, property) ? remapped[key].metadata[property] : null : null)),
    total: [total]
  };
  return {
    response,
    matrix
  };
};
export const get = (spec, config) => ({
  url: `${spec.url}/people/${getPeopleType(config)}`,
  parse: parse(spec, config),
  downgrade: one
});