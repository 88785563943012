'use es6';

import formatName from 'I18n/utils/formatName';
import { fromJS } from 'immutable';
import * as ObjectTypes from '../../objectTypes';
import * as EventDigestTypes from '../../eventDigestTypes';
const HS_OBJECT_ID = 'hs_object_id';

/**
 * Map<DataSourceId, PropertyName>
 */
export const ID_PROPERTIES = fromJS({
  [ObjectTypes.CONTACT]: HS_OBJECT_ID,
  [ObjectTypes.COMPANY]: HS_OBJECT_ID,
  [ObjectTypes.DEAL]: HS_OBJECT_ID,
  [ObjectTypes.TICKET]: 'hs_ticket_id',
  [ObjectTypes.CAMPAIGN]: HS_OBJECT_ID,
  [ObjectTypes.CALL]: HS_OBJECT_ID,
  [ObjectTypes.FORMS]: 'hs_form_id',
  [EventDigestTypes.EMAIL_EVENTS]: 'hs_email_scheduled_id',
  [EventDigestTypes.MARKETING_EVENT_ACTIVITIES]: 'hs_marketing_event'
});
export const isIdProperty = (name, objectTypeId) => {
  const isHubSpotObjectId = name === HS_OBJECT_ID;
  const isLegacyObjectId = name === ID_PROPERTIES.get(objectTypeId);
  return isHubSpotObjectId || isLegacyObjectId;
};
export const getDefaultDisplayLabel = (id, primaryDisplayLabelPropertyName, secondaryDisplayLabelPropertyNames, properties) => `${properties[primaryDisplayLabelPropertyName]} (${id})`;
export const getContactDisplayLabel = (id, primaryDisplayLabelPropertyName, secondaryDisplayLabelPropertyNames, {
  firstname,
  lastname,
  email
}) => formatName({
  firstName: firstname,
  lastName: lastname,
  email
});
export const OVERRIDES = fromJS({
  [ObjectTypes.CONTACT]: getContactDisplayLabel
});