'use es6';

//------------------------------------------------------------------------------
// Requirements
//------------------------------------------------------------------------------
import Immutable, { Map as ImmutableMap } from 'immutable';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import invariant from 'react-utils/invariant';

//------------------------------------------------------------------------------
// Public Interface
//------------------------------------------------------------------------------

/**
 * Immutable selector creator
 *
 * @param   {...function} args Same arguments are reselect#createSelector
 * @returns {function}         Selector function with immutable equality check
 */
export const createSelector = createSelectorCreator(defaultMemoize, Immutable.is);

/**
 * Immutable structured selector creator
 * Adapted from reselect#createStructuredSelector
 *
 * @param   {object}   selectors       Object mapping of name and selector
 * @param   {?object}  selectorCreator Optional custom selector creator
 * @returns {function}                 Structured selector returning Map
 */
export function createStructuredSelector(selectors, selectorCreator = createSelector) {
  invariant(typeof selectors === 'object', 'createStructuredSelector expects first argument to be an object ' + `where each property is a selector, instead received a ${typeof selectors}`);
  const keys = Object.keys(selectors);
  return selectorCreator(keys.map(key => selectors[key]), (...values) => {
    return values.reduce((composition, value, key) => {
      return composition.set(keys[key], value);
    }, ImmutableMap());
  });
}