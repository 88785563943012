'use es6';

import { List, fromJS } from 'immutable';
import indexBy from 'transmute/indexBy';
import get from 'transmute/get';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
const formatMeetingOutcomeReference = meetingOutcome => {
  const id = meetingOutcome.name;
  const label = meetingOutcome.label;
  return new ReferenceRecord({
    id,
    label,
    referencedObject: fromJS(meetingOutcome)
  });
};
const formatMeetingOutcomes = ({
  meetingOutcomes
}) => {
  const result = indexBy(get('id'), List(meetingOutcomes).map(formatMeetingOutcomeReference));
  return result;
};
export default formatMeetingOutcomes;