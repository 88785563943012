'use es6';

const OPT = 'HS:opt';
const OPT_MINIMIZED = `${OPT}:minimized`;
const toNamedSetting = (name, setting) => `${setting}:${name}`;
const getLocalSetting = setting => {
  try {
    return localStorage.getItem(setting);
  } catch (err) {
    return undefined;
  }
};
const setLocalSetting = (setting, value) => {
  try {
    localStorage.setItem(setting, value);
    // eslint-disable-next-line no-empty
  } catch (err) {}
};
export const isOptMinimized = optinName => getLocalSetting(toNamedSetting(optinName, OPT_MINIMIZED)) === 'true';
export const setOptMinimized = (optinName, minimized) => setLocalSetting(toNamedSetting(optinName, OPT_MINIMIZED), String(minimized));