'use es6';

import { Map as ImmutableMap } from 'immutable';
import * as DataTypes from '../../../constants/dataTypes';
export const dataTypeToEngagementType = ImmutableMap({
  [DataTypes.CALLS]: 'CALL',
  [DataTypes.CONVERSATIONS]: 'CONVERSATION_SESSION',
  [DataTypes.ENGAGEMENT_EMAILS]: 'EMAIL',
  [DataTypes.MEETINGS]: 'MEETING',
  [DataTypes.NOTES]: 'NOTE',
  [DataTypes.TASKS]: 'TASK',
  [DataTypes.FEEDBACK]: 'FEEDBACK_SUBMISSION'
});