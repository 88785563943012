'use es6';

import * as PropertyTypes from 'customer-data-objects/property/PropertyTypes';
import { EMAIL_CAMPAIGN } from 'reference-resolvers/constants/ReferenceObjectTypes';
const EMAIL_CAMPAIGN_ID = 'hs_email_campaign_id';
const PROPERTY_OVERRIDES = {
  [EMAIL_CAMPAIGN_ID]: {
    type: 'string',
    fieldType: 'text',
    excludedReferenceType: EMAIL_CAMPAIGN
  }
};
export const isNonExcludedReferenceType = (referenceType, propertyName) => referenceType && !(PROPERTY_OVERRIDES[propertyName] && referenceType === PROPERTY_OVERRIDES[propertyName].excludedReferenceType);
export const overrideProperty = property => {
  const overrideInfo = PROPERTY_OVERRIDES[property.propertyDefinition.property.name];
  if (!overrideInfo) {
    return property;
  }
  const {
    type,
    fieldType
  } = overrideInfo;
  const eventSpecificMetadata = property.eventSpecificMetadata ? {
    eventSpecificMetadata: Object.assign({}, property.eventSpecificMetadata, {
      type
    })
  } : {};
  return Object.assign({}, property, eventSpecificMetadata, {
    propertyDefinition: Object.assign({}, property.propertyDefinition, {
      property: Object.assign({}, property.propertyDefinition.property, {
        type,
        fieldType
      })
    })
  });
};
export const coerceOperatorForLabelDisplay = (operator, referenceType) => isNonExcludedReferenceType(referenceType, operator.getIn(['field', 'name'])) && operator.getIn(['field', 'type']) === PropertyTypes.NUMBER ? operator.setIn(['field', 'displayType'], PropertyTypes.ENUMERATION) : operator;