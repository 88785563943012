'use es6';

import { fromJS, Map as ImmutableMap } from 'immutable';
import { GLOBAL_NULL } from '../../constants/defaultNullValues';
import chunk from '../../lib/async/chunk';
import prefix from '../../lib/prefix';
import toJS from '../../lib/toJS';
import * as http from '../../request/http';
import { validNumerical } from '../ids';
import { makeOption } from '../Option';
const translate = prefix('reporting-data.properties.contacts');
export const generateListMembershipIdLabel = (list, key) => {
  const immutableList = fromJS(list);
  if (immutableList.has('label')) {
    return immutableList.get('label');
  }
  return immutableList.get('name', key === GLOBAL_NULL ? null : translate('deletedList', {
    listId: immutableList.get('listId') || key
  }));
};
const batch = ids => chunk(group => http.get('contacts/v1/lists/batch', {
  query: {
    property: ['listId', 'name'],
    listId: validNumerical(group).toArray()
  }
}).then(toJS), responses => responses.reduce((memo, {
  lists
}) => Object.assign({}, memo, lists.reduce((mapped, list) => Object.assign({}, mapped, {
  [list.listId]: generateListMembershipIdLabel(list)
}), {})), {}), ids);
export default (ids => batch(ids).then(lists => ids.reduce((options, listId) => options.set(String(listId), makeOption(listId, lists[listId])), ImmutableMap())));