import { Contexts } from '../../dataset/context-records';
import { buildFieldFromExpressionField, expressionFieldsListToMap } from './expression-field-utils';
import { createDefaultColumn } from './visual-utils';
import { AggregationTypes, Column, DomainTypes } from '../schema/column-records';
import { DEFAULT_COLUMN_SORT } from '../modify/sorts/sort-utils';
import { createColumnAlias } from './column-utils';
import { expressionToList, isFieldExpression } from '../../dataset/expression-utils';
import { List } from 'immutable';
import { COLUMN_ROLES } from 'reporting-data/constants/relationalReports';
const createAggregateExpressionColumn = field => Column({
  role: COLUMN_ROLES.MEASURE,
  domain: DomainTypes.CONTINUOUS,
  field,
  sort: DEFAULT_COLUMN_SORT,
  alias: createColumnAlias(),
  aggregation: AggregationTypes.DELEGATE
});
export const createDefaultColumnUsingExpression = (expressionField, maybeChannelName, visualType, expressionFields = List()) => {
  const expressionFieldsMap = expressionFieldsListToMap(expressionFields);
  const field = buildFieldFromExpressionField(expressionField);
  const isAggregateContextExpression = expression => expression.type === 'AGGREGATION_CONTEXT';
  const isAggregateExpressionField = testExpressionField => {
    return testExpressionField.context === Contexts.AGGREGATE || expressionToList(testExpressionField.expression).some(expression => isAggregateContextExpression(expression) || (isFieldExpression(expression) ? isAggregateExpressionField(expressionFieldsMap.get(expression.name)) : false));
  };
  if (isAggregateExpressionField(expressionField)) {
    return createAggregateExpressionColumn(field);
  }
  return createDefaultColumn(field, maybeChannelName, visualType);
};