'use es6';

import usePollingQuery from './usePollingQuery';
import { FETCH_SHARE_THIRD_PARTY_APPS, SHARE_THIRD_PARTY_APP_FIELD_NAME } from '../requests/thirdPartyApps';
const findApp = (data, appId) => data[SHARE_THIRD_PARTY_APP_FIELD_NAME].find(({
  id
}) => id === appId);
export const useShareThirdPartyApp = appId => {
  const {
    data,
    loading
  } = usePollingQuery(FETCH_SHARE_THIRD_PARTY_APPS);
  return {
    data: data && findApp(data, appId),
    loading
  };
};