export const SavedDashboardFilterSetFrequency = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR'
};

// from https://git.hubteam.com/HubSpot/Dashboard/blob/master/DashboardBase/src/main/java/com/hubspot/dashboard/v2/dashboards/filters/DashboardFilterIF.java

export let SavedFilterOperators;
(function (SavedFilterOperators) {
  SavedFilterOperators["EQ"] = "EQ";
  SavedFilterOperators["NEQ"] = "NEQ";
  SavedFilterOperators["LT"] = "LT";
  SavedFilterOperators["LTE"] = "LTE";
  SavedFilterOperators["GT"] = "GT";
  SavedFilterOperators["GTE"] = "GTE";
  SavedFilterOperators["IN"] = "IN";
  SavedFilterOperators["NOT_IN"] = "NOT_IN";
  SavedFilterOperators["BETWEEN"] = "BETWEEN";
  SavedFilterOperators["KNOWN"] = "KNOWN";
  SavedFilterOperators["UNKNOWN"] = "UNKNOWN";
  SavedFilterOperators["IN_ROLLING_DATE_RANGE"] = "IN_ROLLING_DATE_RANGE";
})(SavedFilterOperators || (SavedFilterOperators = {}));
export function isApiSavedValueFilter(savedFilter) {
  return savedFilter.operator === SavedFilterOperators.EQ || savedFilter.operator === SavedFilterOperators.NEQ || savedFilter.operator === SavedFilterOperators.LT || savedFilter.operator === SavedFilterOperators.LTE || savedFilter.operator === SavedFilterOperators.GT || savedFilter.operator === SavedFilterOperators.GTE;
}
export function isApiSavedValuesFilter(savedFilter) {
  return savedFilter.operator === SavedFilterOperators.IN || savedFilter.operator === SavedFilterOperators.NOT_IN;
}
export function isApiSavedRangeFilter(savedFilter) {
  return savedFilter.operator === SavedFilterOperators.BETWEEN;
}
export function isApiSavedHasPropertyDashboardFilter(savedFilter) {
  return savedFilter.operator === SavedFilterOperators.KNOWN || savedFilter.operator === SavedFilterOperators.UNKNOWN;
}
export function isApiSavedRollingDateRangeFilter(savedFilter) {
  return savedFilter.operator === SavedFilterOperators.IN_ROLLING_DATE_RANGE;
}

// Also can be mapped to the Pinned Property IF

// export const isApiDynamicDateRangeFilter = (
//   filter: ApiSavedDashboardFilter
// ): filter is ApiDynamicDateRangeFilter =>
//   filter.name === DYNAMIC_DATE_RANGE_PROPERTY_NAME;

// type ApiDynamicDateRangeFilter =
//   | (Omit<ApiSavedRollingDateRangeFilter, keyof ApiBaseFilter> &
//       DynamicDateRangeFilterBase)
//   | (Omit<ApiSavedRangeFilter, keyof ApiBaseFilter> &
//       DynamicDateRangeFilterBase)
//   | (Omit<ApiSavedHasPropertyDashboardFilter, keyof ApiBaseFilter> &
//       DynamicDateRangeFilterBase)
//   | (Omit<ApiSavedValueFilter, keyof ApiBaseFilter> &
//       DynamicDateRangeFilterBase);

// // FE will always expand this filter type into a ApiBaseFilter when ingesting from the API
// type DynamicDateRangeFilterBase = {
//   name: typeof DYNAMIC_DATE_RANGE_PROPERTY_NAME; // __DYNAMIC_DATE_RANGE__
//   dataSourceId: undefined;
//   propertyType: undefined; // needed to resolve property types for WEB_ANALYTICS data source types
//   dataSourceType: undefined; // BE can derive this value
//   /** denotes that the property is FE defined and sohuld skip puma validation */
//   scripted: undefined;
// };