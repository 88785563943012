'use es6';

import { Map as ImmutableMap } from 'immutable';
import http from 'hub-http/clients/apiClient';
import { userInfo as fetchUserInfo } from '../request/user-info';
import makeDateRangeByType from '../lib/makeDateRangeByType';
import { exportDatasetViaRaasApi } from './export-dataset';
import { getOnPlatformReport } from './transform-completed-activities';
const EXPORT_URL = 'sales-reporting/export';
const EXPORT_TYPES = {
  WATERFALL: 'waterfall',
  CHANGE_HISTORY_DEAL_LIST: 'deal-change-history',
  DEAL_PUSH_ANALYSIS: 'push-analysis',
  PIPELINE_SNAPSHOTS: 'historical-snapshots',
  SALES_QUOTA: 'attainment'
};
const APPS = [{
  path: 'reports-dashboard',
  app: 'DashboardUI'
}, {
  path: 'reports-list',
  app: 'reports-list'
}, {
  path: 'sales-reports',
  app: 'sales-analytics-ui'
}, {
  path: 'reporting',
  app: 'IA'
}];
export const exportSalesAnalytics = (report, exportOptions) => fetchUserInfo().then(userInfo => {
  const {
    portal: {
      portal_id
    },
    user: {
      email,
      user_id
    }
  } = userInfo;
  const {
    format,
    title = '',
    drilldownColumns = []
  } = exportOptions;
  const pathParts = window.location.pathname.substring(1).split('/');
  const appPath = APPS.find(({
    path
  }) => pathParts.includes(path));
  const reportUrl = report.has('id') ? `${window.location.origin}/reports-list/${portal_id}/${report.get('id')}` : null;
  const exportableReport = report.updateIn(['config', 'filters', 'dateRange', 'value'], ImmutableMap(), dateRange => ImmutableMap(makeDateRangeByType(dateRange.toJS())));
  if (report.getIn(['displayParams', 'customWidget', 'type']) === 'COMPLETED_ACTIVITIES') {
    return exportDatasetViaRaasApi(getOnPlatformReport(report), exportOptions);
  }
  const data = {
    portalId: portal_id,
    userId: user_id,
    email,
    type: EXPORT_TYPES[exportableReport.getIn(['displayParams', 'customWidget', 'type'])],
    format,
    exportName: title,
    referrerUrl: window.location.href,
    app: appPath ? appPath.app : pathParts[0],
    report: exportableReport,
    drilldownColumns,
    reportUrl
  };
  return http.post(EXPORT_URL, {
    data
  });
});