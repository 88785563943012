export const DATE_FORMATS = {
  DAY: 'l',
  WEEK: 'l',
  MONTH: 'short-m-y',
  QUARTER: 'short-q-y',
  YEAR: 'YYYY'
};
export const ACCESSIBILITY_DATE_FORMATS = {
  DAY: 'LL',
  WEEK: 'LL',
  MONTH: 'medium-m-y',
  QUARTER: 'short-q-y',
  YEAR: 'YYYY'
};