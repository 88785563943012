'use es6';

import { getReportColorEncoding, getReportValuesEncodings, getReportXEncoding, getReportXMultiEncoding, getReportYEncoding, getReportYMultiEncoding } from '../../../utils/report-utils';
import { logicalXOR } from '../../../utils/validation-utils';
import { ConstraintTypes, createConstraint, InvalidSpecificationTypes } from '../../validate';

// used for XY-like visuals (vertical/horizontal bar, line, area)
// NOT used for scatter
export const twoOrFewerValueChannels = createConstraint('twoOrFewerValueChannels', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, values => values === undefined || values.size <= 2, report => getReportValuesEncodings(report));
export const xyLikeVisualIsDisplayable = createConstraint('completionCriteria', ConstraintTypes.HARD, InvalidSpecificationTypes.INCOMPLETE, (x, xMulti, y, yMulti) => {
  return x || y || xMulti && xMulti.length > 0 || yMulti && yMulti.length > 0;
}, [getReportXEncoding, getReportXMultiEncoding, getReportYEncoding, getReportYMultiEncoding]);

// used for sliced-like visuals (pie and donut)
export const manyValuesXORColor = createConstraint('manyValuesXORColor', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (color, values) => logicalXOR(color !== undefined && values.size <= 1, color === undefined && values.size >= 0), [report => getReportColorEncoding(report), report => getReportValuesEncodings(report)]);
export const slicedLikeVisualIsComplete = createConstraint('completionCriteria', ConstraintTypes.HARD, InvalidSpecificationTypes.INCOMPLETE, values => values.size > 0, report => getReportValuesEncodings(report));