'use es6';

import { List, Map as ImmutableMap } from 'immutable';
const count = series => series.count();
const sum = series => series.reduce((result, v) => result + v, 0);
const average = series => sum(series) / count(series);
const min = series => series.min();
const max = series => series.max();
const distinct = series => series.toSet().count();
const valid = series => series.filter(v => v != null && v !== undefined).count();
const missing = series => series.filter(v => v == null || v === undefined).count();
const attribute = series => series.first();
const METRICS = {
  count,
  sum,
  average,
  min,
  max,
  distinct,
  valid,
  missing,
  attribute
};
export const AggregateTypes = Object.keys(METRICS).reduce((acc, key) => acc.set(key, key), ImmutableMap()).toJS();
function aggregate(data, {
  groupby = [],
  aggregate: aggregations
}) {
  if (groupby.length === 0) {
    return List.of(ImmutableMap(aggregations.map(({
      op = 'count',
      field,
      as
    }) => {
      const fn = METRICS[op];
      if (!fn) {
        throw new Error(`No aggregation function found for operator: ${op}, expected one of [${Object.keys(METRICS)}]`);
      }
      return [as || (op === 'count' ? 'count' : `${op}_${field}`), fn(op === 'count' ? data : data.map(r => r.get(field)))];
    })));
  }
  const [field, ...rest] = groupby;
  return data.groupBy(r => r.get(field)).map((group, key) => aggregate(group, {
    groupby: rest,
    aggregate: aggregations
  }).map(r => r.set(field, key))).toList().flatten(1);
}

// https://vega.github.io/vega-lite/docs/aggregate.html#transform
export default ((dataset, {
  groupby = [],
  aggregate: aggregations
}) => {
  if (!aggregations) {
    throw new Error('Missing aggregations in aggregate transform');
  }
  return dataset.update('data', data => aggregate(data, {
    groupby,
    aggregate: aggregations
  }));
});