import { CALL_TYPE_ID, CAMPAIGN_TYPE_ID, COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, ENGAGEMENT_TYPE_ID, LINE_ITEM_TYPE_ID, TASK_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import * as ReferenceTypes from 'customer-data-objects/externalOptions/ExternalOptionsReferenceTypes';
/**
 * Commented out properties are those that we know have
 * externalOptions but the corresponding lite resolver
 * has not yet been built out
 */
const PropertyNameToReferenceType = {
  [CALL_TYPE_ID]: {
    hs_call_disposition: ReferenceTypes.CALL_DISPOSITION
  },
  [CAMPAIGN_TYPE_ID]: {
    hs_owner: ReferenceTypes.OWNER
  },
  [COMPANY_TYPE_ID]: {
    lifecyclestage: ReferenceTypes.PIPELINE_STAGE,
    hs_analytics_first_touch_converting_campaign: ReferenceTypes.CAMPAIGN,
    hs_analytics_last_touch_converting_campaign: ReferenceTypes.CAMPAIGN
  },
  [CONTACT_TYPE_ID]: {
    hs_persona: ReferenceTypes.PERSONA,
    lifecyclestage: ReferenceTypes.PIPELINE_STAGE,
    // 'formSubmissions.formId': ReferenceTypes.FORM,
    // 'listMemberships.listId': ReferenceTypes.LIST,
    hs_analytics_first_touch_converting_campaign: ReferenceTypes.CAMPAIGN,
    hs_analytics_last_touch_converting_campaign: ReferenceTypes.CAMPAIGN
    // salesforcecampaignids: ReferenceTypes.SALESFORCE_CAMPAIGN,
  },
  [DEAL_TYPE_ID]: {
    dealstage: ReferenceTypes.PIPELINE_STAGE,
    pipeline: ReferenceTypes.PIPELINE,
    deal_currency_code: ReferenceTypes.MULTI_CURRENCY_CURRENCY_CODE,
    'engagement.ownerId': ReferenceTypes.OWNER
  },
  [ENGAGEMENT_TYPE_ID]: {
    // hs_activity_type: ReferenceTypes.ACTIVITY_TYPE,
    hs_attendee_owner_ids: ReferenceTypes.OWNER,
    hs_at_mentioned_owner_ids: ReferenceTypes.OWNER,
    hs_created_by: ReferenceTypes.USER,
    hs_call_disposition: ReferenceTypes.CALL_DISPOSITION
  },
  [LINE_ITEM_TYPE_ID]: {
    hs_line_item_currency_code: ReferenceTypes.MULTI_CURRENCY_CURRENCY_CODE
  },
  [TASK_TYPE_ID]: {
    hs_queue_membership_ids: ReferenceTypes.TASK_QUEUE
  },
  [TICKET_TYPE_ID]: {}
};
const CommonPropertiesToReferenceType = {
  // '_inbounddbio.importid_': <fill in reference type>,
  hs_persona: ReferenceTypes.PERSONA,
  // hs_all_accessible_team_ids: <fill in reference type>,
  hubspot_owner_id: ReferenceTypes.OWNER,
  hubspot_team_id: ReferenceTypes.TEAM,
  hs_pipeline: ReferenceTypes.PIPELINE,
  hs_pipeline_stage: ReferenceTypes.PIPELINE_STAGE,
  // 'ilsListMemberships.listId': <fill in reference type>,
  hs_created_by: ReferenceTypes.USER,
  hs_created_by_user_id: ReferenceTypes.USER,
  hs_updated_by_user_id: ReferenceTypes.USER
  // hs_event_status: <fill in reference type>,
  // hs_app_id: <fill in reference type>,
};

/**
 * Preference order (highest to lowest):
 *
 * 1. BE property definition (given by the `externalOptionsReferenceType` field)
 * 2. ObjectType-specific property name override
 * 3. Common property name override (for properties shared by all types)
 *
 * @param propertyOrPropertyDefinition  CRM property definition object or property definition object from backend
 * @param objectTypeId                  Object Type ID in the form `metaTypeId-typeId` (i.e. 0-1)
 * @returns                             `ReferenceType` constant for creating a
 * resolver from this library, or `null` if the given property
 * does not map to any reference type
 */
export const getReferenceTypeFromProperty = (propertyOrPropertyDefinition, objectTypeId) => {
  const property = propertyOrPropertyDefinition && 'property' in propertyOrPropertyDefinition ? propertyOrPropertyDefinition.property : propertyOrPropertyDefinition;
  if (property && property.externalOptionsReferenceType) {
    return property.externalOptionsReferenceType;
  }
  const propertyName = property ? property.name : null;
  const propertyIsHubspotDefined = property ? property.hubspotDefined : false;
  const overrideReferenceTypeFields = PropertyNameToReferenceType[objectTypeId] || {};
  const overrideReferenceType = propertyName ? overrideReferenceTypeFields[propertyName] : null;
  if (overrideReferenceType && propertyIsHubspotDefined) {
    return overrideReferenceType;
  }
  const commonOverrideReferenceType = propertyName ? CommonPropertiesToReferenceType[propertyName] : null;
  if (commonOverrideReferenceType && propertyIsHubspotDefined) {
    return commonOverrideReferenceType;
  }
  return null;
};