import * as checked from 'reporting-data/lib/checked';
export const LimitDirections = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING'
};
const LimitDirection = checked.symbol(LimitDirections, 'LimitDirections');
export const Limit = checked.record({
  direction: LimitDirection,
  count: checked.number(),
  byMeasure: checked.string(),
  forDimension: checked.string(),
  autoApplied: checked.boolean().optional()
}, 'Limit');
export const LimitForQuery = checked.record({
  direction: LimitDirection,
  count: checked.number(),
  // references a dependency column
  byMeasure: checked.string(),
  forDimension: checked.string()
}, 'LimitForQuery');