'use es6';

import fromJS from 'transmute/fromJS';
import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { getUserLabel } from '../../lib/user-logic';
import { useUsers, useDeactivatedUsers } from './useUsers';
import { getId } from '../schemas/userSchema';
import { useCurrentUser } from '../../access/useUserInfo';
const getUserOption = user => ImmutableMap({
  text: getUserLabel({
    user: fromJS(user)
  }),
  value: getId(user)
});
export const useDeactivatedUserOptions = () => {
  const {
    data: deactivatedUsers,
    error,
    loading
  } = useDeactivatedUsers();
  return {
    data: loading ? new Set() : deactivatedUsers,
    error,
    loading
  };
};
export const useUserOptions = () => {
  const {
    data: allUsers,
    error,
    loading
  } = useUsers();
  const {
    data: deactivatedUsers
  } = useDeactivatedUserOptions();
  const currentUser = useCurrentUser();
  return {
    data: loading ? ImmutableSet() : allUsers.filter(user => {
      return !deactivatedUsers.has(getId(user));
    }).reduce((userOptions, user) => userOptions.add(getUserOption(user)), ImmutableSet()).add(getUserOption(currentUser)).sort(),
    error,
    loading
  };
};