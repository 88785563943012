module.exports = {
  "record-shared-to-slack": {
    "name": "slack-component-usage",
    "class": "usage",
    "properties": {
      "recordId": "number",
      "recordType": "string",
      "slackChannelId": "string",
      "placeIdentifier": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "slack-ui-library",
    "version": "1"
  }
};