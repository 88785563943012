import * as http from '../request/http';
import { UNIFIED_EVENTS_QL } from '../constants/dataTypes';
const UE_QL_DATA_TYPE_REGEX = /^(pe(\d*)|e)_.+$/;
export const isSupportedUnifiedEventQLDataType = dataType => {
  if (!dataType) {
    return false;
  }
  if (dataType === UNIFIED_EVENTS_QL) {
    return true;
  }
  return UE_QL_DATA_TYPE_REGEX.test(dataType);
};
export const fetchUnifiedEventQLMetadata = eventName => {
  return http.get(`inbounddb-meta/v1/object-types/batch-get-by-names`, {
    query: {
      name: eventName,
      includeDeleted: false
    }
  }, {
    disableTtl: true
  });
};
export const getUnifiedEventQLPluralName = eventName => fetchUnifiedEventQLMetadata(eventName).then(eventMetadata => eventMetadata.getIn(['0', 'pluralForm'], eventName));
export const getUnifiedEventQLDefaultDrilldownColumns = eventName => {
  return fetchUnifiedEventQLMetadata(eventName).then(response => {
    var _response$toJS;
    const obj = (_response$toJS = response.toJS()) === null || _response$toJS === void 0 ? void 0 : _response$toJS[0];
    const primaryDisplayLabelPropertyName = obj.primaryDisplayLabelPropertyName;
    const secondaryDisplayLabelPropertyNames = obj.secondaryDisplayLabelPropertyNames;
    return [primaryDisplayLabelPropertyName, ...secondaryDisplayLabelPropertyNames];
  });
};