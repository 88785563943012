import { sources } from 'ui-addon-upgrades/_core/common/data/upgradeData/properties/sources';
/**
 * @param {Object} upgradeData
 * @param {String} upgradeSource
 * @return {Object}
 */

export const getSource = ({
  app,
  screen,
  uniqueId
}, source) => {
  return `${app}-${screen}-${sources[source]}-${uniqueId}`;
};