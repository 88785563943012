'use es6';

import { Set as ImmutableSet } from 'immutable';
import { userInfo } from '../../request/user-info';
import { NO_ID, MY_TEAM_ID } from '../../constants/magicTypes';
export default ((config, paths = ImmutableSet()) => userInfo().then(({
  user: {
    teams
  }
}) => {
  const {
    id: myTeamId
  } = teams.find(({
    my_team
  }) => my_team) || {};
  return paths.reduce((memo, path) => memo.updateIn(['filters', ...path], value => value === MY_TEAM_ID && myTeamId || NO_ID), config);
}));