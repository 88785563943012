'use es6';

const calculated = breakdown => {
  const {
    searchResultsFounds = 0
  } = breakdown;
  return Object.assign({}, breakdown, {
    hasResults: searchResultsFounds > 0
  });
};
export const preprocess = () => response => {
  const {
    breakdowns,
    totals
  } = response;
  if (breakdowns && totals) {
    return Object.assign({}, response, {
      breakdowns: breakdowns.map(calculated),
      totals: calculated(totals)
    });
  }
  return response;
};