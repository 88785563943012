'use es6';

import * as ObjectSegFilterTypes from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import * as OperatorTypes from '../ObjectSegOperatorTypes';
import * as Operators from '../../../filterQueryFormat/operator/Operators';
import { IN_LIST_TYPE } from '../constants/constants';
import getIn from 'transmute/getIn';
import invariant from 'react-utils/invariant';
import protocol from 'transmute/protocol';
const _toListMembershipFilter = (operatorName, operator) => ({
  filterType: ObjectSegFilterTypes.IN_LIST,
  operator: operatorName,
  listId: getIn(['field', 'name'], operator)
});
const _toImportFilter = (operatorName, operator) => ({
  filterType: ObjectSegFilterTypes.IN_LIST,
  operator: operatorName,
  metadata: {
    inListType: IN_LIST_TYPE.IMPORT,
    id: getIn(['field', 'name'], operator)
  }
});
const _toWorkflowFilter = (operatorName, inListType, operator) => ({
  filterType: ObjectSegFilterTypes.IN_LIST,
  operator: operatorName,
  metadata: {
    inListType,
    id: getIn(['field', 'name'], operator)
  }
});
const invalidOperator = operator => {
  invariant(false, 'cannot convert Operator "%s" to List Membership filter', operator.name);
};
const toListMembershipFilter = protocol({
  name: 'toListMembershipFilter',
  args: [protocol.TYPE],
  fallback: invalidOperator
});
toListMembershipFilter.implement(Operators.ListIsMember, operator => _toListMembershipFilter(OperatorTypes.IS_IN_LIST, operator));
toListMembershipFilter.implement(Operators.ListIsNotMember, operator => _toListMembershipFilter(OperatorTypes.IS_NOT_IN_LIST, operator));
toListMembershipFilter.implement(Operators.InImport, operator => _toImportFilter(OperatorTypes.IS_IN_LIST, operator));
toListMembershipFilter.implement(Operators.NotInImport, operator => _toImportFilter(OperatorTypes.IS_NOT_IN_LIST, operator));
toListMembershipFilter.implement(Operators.WorkflowActive, operator => _toWorkflowFilter(OperatorTypes.IS_IN_LIST, IN_LIST_TYPE.WORKFLOWS_ACTIVE, operator));
toListMembershipFilter.implement(Operators.WorkflowNotActive, operator => _toWorkflowFilter(OperatorTypes.IS_NOT_IN_LIST, IN_LIST_TYPE.WORKFLOWS_ACTIVE, operator));
toListMembershipFilter.implement(Operators.WorkflowCompleted, operator => _toWorkflowFilter(OperatorTypes.IS_IN_LIST, IN_LIST_TYPE.WORKFLOWS_COMPLETED, operator));
toListMembershipFilter.implement(Operators.WorkflowNotCompleted, operator => _toWorkflowFilter(OperatorTypes.IS_NOT_IN_LIST, IN_LIST_TYPE.WORKFLOWS_COMPLETED, operator));
toListMembershipFilter.implement(Operators.WorkflowEnrolled, operator => _toWorkflowFilter(OperatorTypes.IS_IN_LIST, IN_LIST_TYPE.WORKFLOWS_ENROLLMENT, operator));
toListMembershipFilter.implement(Operators.WorkflowNotEnrolled, operator => _toWorkflowFilter(OperatorTypes.IS_NOT_IN_LIST, IN_LIST_TYPE.WORKFLOWS_ENROLLMENT, operator));
toListMembershipFilter.implement(Operators.WorkflowMetGoal, operator => _toWorkflowFilter(OperatorTypes.IS_IN_LIST, IN_LIST_TYPE.WORKFLOWS_GOAL, operator));
toListMembershipFilter.implement(Operators.WorkflowNotMetGoal, operator => _toWorkflowFilter(OperatorTypes.IS_NOT_IN_LIST, IN_LIST_TYPE.WORKFLOWS_GOAL, operator));
export default toListMembershipFilter;