import { useEffect } from 'react';
import { normalizeTypeId, SOCIAL_BROADCAST_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { CommentObjectType } from '../constants';
import { setTourStepContentOverrides } from 'onboarding-tours/util/tourStorage';
import { COMMENT_ONBOARDING_TOUR_ID } from '../constants/tourIDs';
import I18n from 'I18n';
export default function useOverrideTourContent({
  objectType
}) {
  useEffect(() => {
    let tourTextOverrideKey = 'default';
    const isSocialBroadcast = Boolean(objectType && normalizeTypeId(objectType) === SOCIAL_BROADCAST_TYPE_ID);
    if (isSocialBroadcast) {
      tourTextOverrideKey = 'text-social';
    }
    if (isSocialBroadcast || objectType === CommentObjectType.COMMENTING_SANDBOX) {
      setTourStepContentOverrides({
        tourId: COMMENT_ONBOARDING_TOUR_ID,
        stepId: 'step 1',
        overrides: {
          textOptions: {
            text: I18n.text(`collaborationSidebar.commentOnboardingText.${tourTextOverrideKey}`)
          }
        }
      });
    }
  }, [objectType]);
}