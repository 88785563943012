import { useSelector } from 'react-redux';
import { NOTE_WIDGET_EDIT, IFRAME_WIDGET_EDIT } from '../../../constants/dialogTypes';
// @ts-expect-error untyped dependency
import { getId } from 'dashboard-lib/public/dashboard/dashboard-schema';
// @ts-expect-error untyped dependency
import { isReadOnlyDemo } from 'dashboard-lib/public/dashboard/dashboard-logic';
// @ts-expect-error untyped dependency
import { useShowDialog } from 'reporting-action-components/dialog/ducks';
// @ts-expect-error untyped dependency
import { getStaticWidgetActionProblem } from 'dashboard-lib/public/enable-action/static-widget-action';
import { DASHBOARD_IS_LOADING, IS_READ_ONLY_DEMO, MAX_USER_ASSET_PERMISSION, USER_DASHBOARD_PERMISSION
// @ts-expect-error untyped dependency
} from 'dashboard-lib/private/action-problem-solution/params/all-params';
// @ts-expect-error untyped dependency
import { selectUserMaxAssetPermission } from 'dashboard-lib/public/userInfo/permissions';
// @ts-expect-error untyped dependency
import { selectHasDashboardDemoWriteScope } from 'dashboard-lib/public/userInfo/scopes';
// @ts-expect-error untyped dependency
import { useDashboardPermission } from 'dashboard-components/permission/useDashboardPermission';
// @ts-expect-error untyped dependency
import { getEditDisabledProblemMessage } from 'dashboard-components/disabled-message/actions/static-widget-action';
import { useWidgetContext } from '../WidgetContext';
import { track as dashboardTracker, EVENTS
// @ts-expect-error untyped dependency
} from 'dashboard-ui-components/tracking/DashboardUITracker';
export const BaseEditTrackingObject = {
  actionType: 'edit',
  action: 'edit',
  assetType: 'dashboard',
  interactionSource: 'widget',
  interactionWith: 'asset-action'
};
export const useEditStaticWidgetAction = ({
  widgetType
}) => {
  const {
    dashboard,
    report
  } = useWidgetContext();
  const showDialog = useShowDialog();
  const dashboardPermission = useDashboardPermission(getId(dashboard));
  const maxUserAssetPermission = useSelector(selectUserMaxAssetPermission);
  const canWriteDemoDashboard = useSelector(selectHasDashboardDemoWriteScope);
  const problem = getStaticWidgetActionProblem({
    [DASHBOARD_IS_LOADING]: !dashboard,
    [IS_READ_ONLY_DEMO]: isReadOnlyDemo(canWriteDemoDashboard, dashboard),
    [MAX_USER_ASSET_PERMISSION]: maxUserAssetPermission,
    [USER_DASHBOARD_PERMISSION]: dashboardPermission
  });
  const Message = getEditDisabledProblemMessage({}, problem);
  const dialogType = widgetType === 'note' ? NOTE_WIDGET_EDIT : IFRAME_WIDGET_EDIT;
  const trackEventType = widgetType === 'note' ? EVENTS.clickNoteAction : EVENTS.clickIframeAction;
  const handleEdit = () => {
    dashboardTracker(trackEventType, Object.assign({}, BaseEditTrackingObject, {
      object: widgetType
    }));
    showDialog({
      dialogType,
      dialogProps: {
        dashboardId: getId(dashboard),
        report
      }
    });
  };
  return {
    problem,
    Message,
    handleEdit
  };
};