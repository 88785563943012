import * as ObjectTypeIds from 'customer-data-objects/constants/ObjectTypeIds';
import { Map as ImmutableMap } from 'immutable';
// @ts-expect-error untyped
import { getDefaultGettersByObjectTypeId } from 'reference-resolvers/formatters/formatCustomObjects';
// @ts-expect-error untyped
import { getWildcardAssociation } from '../components/strategies/ObjectSegStrategyUtils';
import getIn from 'transmute/getIn';
const ASSOCIATED_OBJECT_TYPE_ID_ALLOWLIST = [ObjectTypeIds.CONTACT_TYPE_ID, ObjectTypeIds.COMPANY_TYPE_ID, ObjectTypeIds.DEAL_TYPE_ID, ObjectTypeIds.TICKET_TYPE_ID, ObjectTypeIds.SUBSCRIPTION_TYPE_ID, ObjectTypeIds.COMMERCE_PAYMENT_TYPE_ID, ObjectTypeIds.PAYMENT_LINK_TYPE_ID];
const cachedCustomObjectReferenceResolvers = {};
export const getAssociatedRecordsUtils = (data, filterFamily) => {
  const {
    ASSOCIATION_DEFINITION = ImmutableMap(),
    CUSTOM_OBJECT_DEFINITION = ImmutableMap(),
    STANDARD_OBJECT_DEFINITION = ImmutableMap()
  } = data.toObject();
  const associationDefinitions = ASSOCIATION_DEFINITION.get(filterFamily);
  const possibleAssociatedObjectTypeIds = associationDefinitions ? Object.keys(associationDefinitions.toObject()) : [];
  const getAssociationDefinition = objectTypeId => {
    const possibleAssociationDefinitions = associationDefinitions && associationDefinitions.get(objectTypeId);
    if (!possibleAssociationDefinitions) {
      return undefined;
    }
    return getWildcardAssociation(Object.values(possibleAssociationDefinitions.toObject()));
  };
  const getObjectDefinition = objectTypeId => {
    return STANDARD_OBJECT_DEFINITION.get(objectTypeId) || CUSTOM_OBJECT_DEFINITION.get(objectTypeId);
  };
  const getObjectTypeIdFromPropertySuffix = propertySuffix => {
    if (!propertySuffix) {
      return undefined;
    }
    const id = propertySuffix.split('-').pop();
    return possibleAssociatedObjectTypeIds.find(objectTypeId => {
      const associationDefinition = getAssociationDefinition(objectTypeId);
      return associationDefinition && `${associationDefinition.id}` === id;
    });
  };
  const getObjectTypeOptions = () => {
    const allowedAssociatedObjectTypeIds = [...ASSOCIATED_OBJECT_TYPE_ID_ALLOWLIST, ...Object.keys(CUSTOM_OBJECT_DEFINITION.toObject())];
    const associatedObjectTypeIds = allowedAssociatedObjectTypeIds.filter(objectTypeId => objectTypeId !== filterFamily && possibleAssociatedObjectTypeIds.includes(objectTypeId));
    return associatedObjectTypeIds.reduce((acc, objectTypeId) => {
      const objectDefinition = getObjectDefinition(objectTypeId);
      const singularForm = objectDefinition && objectDefinition.get('singularForm');
      if (!singularForm) {
        return acc;
      }
      return [...acc, {
        text: singularForm,
        value: objectTypeId
      }];
    }, []).sort(({
      text: text1
    }, {
      text: text2
    }) => text1.localeCompare(text2));
  };
  const getCustomObjectReferenceResolver = (resolver, objectTypeId) => {
    if (!objectTypeId) {
      return undefined;
    }
    const cachedCustomObjectReferenceResolver = cachedCustomObjectReferenceResolvers[objectTypeId];
    if (cachedCustomObjectReferenceResolver) {
      return cachedCustomObjectReferenceResolver;
    }
    const objectDefinition = getObjectDefinition(objectTypeId);
    const primaryDisplayLabelPropertyName = objectDefinition && objectDefinition.primaryDisplayLabelPropertyName ? objectDefinition.primaryDisplayLabelPropertyName : undefined;
    const {
      resolverFactory,
      resolversCache
    } = resolver();
    const formatterOptions = {
      getters: Object.assign({
        getLabel: ({
          objectId,
          properties
        }) => {
          return getIn([primaryDisplayLabelPropertyName, 'value'], properties) || `${objectId}`;
        }
      }, getDefaultGettersByObjectTypeId(objectTypeId))
    };
    const CustomObjectReferenceResolver = resolverFactory(objectTypeId, {
      formatterOptions
    });
    const customObjectReferenceResolver = CustomObjectReferenceResolver(resolversCache);
    cachedCustomObjectReferenceResolvers[objectTypeId] = customObjectReferenceResolver;
    return customObjectReferenceResolver;
  };
  return {
    getAssociationDefinition,
    getObjectDefinition,
    getObjectTypeIdFromPropertySuffix,
    getObjectTypeOptions,
    getCustomObjectReferenceResolver
  };
};