'use es6';

/**
 * Returns true if `value` is a String.
 * 
 * @param {any} value
 * @return {boolean}
 */
export default function isString(value) {
  return typeof value === 'string';
}