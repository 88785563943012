import { useMemo, useRef } from 'react';
import debounce from '../../../utils/debounce';
import usePrevious from '../../../hooks/usePrevious';
import isEqual from 'hs-lodash/isEqual';
export function useToggleOpenPopover({
  embeddedCommentWrapper,
  onUpdateCollaborationSidebarState,
  moduleId,
  metaDescription,
  placement
}) {
  const prevEmbeddedCommentWrapper = usePrevious(embeddedCommentWrapper);
  const prevModuleId = usePrevious(moduleId);
  const prevPlacement = usePrevious(placement);
  const prevOnUpdateCollaborationSidebarState = usePrevious(onUpdateCollaborationSidebarState);
  const toggleCallback = useRef(undefined);
  const toggleOpenPopover = useMemo(() => {
    // metaDescription can contain React elements so we can't memoize it with useMemoizedObjFromProps,
    // and we should not update the toggleCallback if the metaDescription changes.
    const stablePropsChanged = !isEqual(embeddedCommentWrapper, prevEmbeddedCommentWrapper) || !isEqual(moduleId, prevModuleId) || !isEqual(placement, prevPlacement) || !isEqual(onUpdateCollaborationSidebarState, prevOnUpdateCollaborationSidebarState);
    if (stablePropsChanged || !toggleCallback.current) {
      // The toggleOpenPopover needs to be debounced
      // to make sure we always dispatch the latest update.
      toggleCallback.current = debounce((open, isOpenFromCommentMode = false) => {
        if (!embeddedCommentWrapper) {
          return;
        }
        if (open) {
          const embeddedCommentRect = embeddedCommentWrapper.getBoundingClientRect();
          onUpdateCollaborationSidebarState({
            isEmbeddedPopoverOpen: true,
            embeddedPopoverPlacement: placement,
            embeddedCommentCoordinates: {
              top: embeddedCommentRect.top,
              left: embeddedCommentRect.left,
              width: embeddedCommentRect.width,
              height: embeddedCommentRect.height
            },
            embeddedModuleID: moduleId,
            embeddedMetaDescription: metaDescription,
            isEmbeddedOpenFromCommentMode: isOpenFromCommentMode
          });
        } else {
          onUpdateCollaborationSidebarState({
            isEmbeddedPopoverOpen: false
          });
        }
      }, 100);
    }
    return toggleCallback.current;
  }, [metaDescription, embeddedCommentWrapper, prevEmbeddedCommentWrapper, onUpdateCollaborationSidebarState, moduleId, placement, prevModuleId, prevOnUpdateCollaborationSidebarState, prevPlacement]);
  return toggleOpenPopover;
}