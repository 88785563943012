import { useState, useEffect } from 'react';
import userInfo from 'hub-http/userInfo';
import { Map as ImmutableMap, OrderedMap } from 'immutable';

// Same functionality as mapKeys()
export const indexBy = (iter, keyMapper) => {
  return iter.reduce((acc, value, key) => {
    return acc.set(keyMapper(key, value), value);
  }, ImmutableMap());
};

/**
 * Similar to OrderedMap.mapEntries, but better typed.
 */
export const mapOrderedEntries = (map, mapper) => {
  let index = 0;
  return map.reduce((acc, v, k) => {
    const [toK, toV] = mapper([k, v], index);
    index += 1;
    return acc.set(toK, toV);
  }, OrderedMap());
};
export const useFilterRelevantUserInfo = () => {
  const [userId, setUserId] = useState(null);
  const [userGates, setUserGates] = useState([]);
  useEffect(() => {
    if (!userId) {
      userInfo().then(user => {
        setUserId(user.user.user_id.toString());
        setUserGates(user.gates);
      }).catch(e => console.error(e));
    }
  }, [userId]);
  return {
    userId,
    userGates
  };
};
export const getIsUngated = (gate, userGates) => !!userGates && userGates.includes(gate);