'use es6';

import prefix from '../../../lib/prefix';
import * as depth from '../depth';
import { createUnifiedTranslationKey } from '../../../properties/data-type/unified';
const noop = () => null;
const translate = prefix('reporting-data.properties.unified.people');
const person = ({
  filters: {
    custom = []
  } = {}
}) => {
  const filter = custom.find(({
    property
  }) => property === 'peopleType');
  return translate(filter ? filter.value : 'default');
};
export const get = (spec, config) => ({
  matrix,
  references
}) => {
  const {
    keys
  } = matrix;
  const {
    dimensionality,
    drilldown
  } = depth.get(spec, config);
  if (!drilldown) {
    return matrix;
  }
  const {
    dimensions: [primary],
    dataType
  } = config;
  const {
    metadata = {},
    breakdowns = {}
  } = drilldown;
  const translateProperty = prop => prefix(`reporting-data.properties.${createUnifiedTranslationKey(dataType)}`)(prop);
  const dimension = primary === 'people' ? person(config) : primary === 'deals-influenced' ? translate('deals-influenced') : spec.metadata && spec.metadata[primary] ? translateProperty(primary) : primary === 'sessionDate' ? null : drilldown.default;
  const lookup = key => typeof breakdowns === 'object' ? breakdowns[key] || references[key] : references[key];
  const label = metadata || breakdowns ? lookup : noop;
  return Object.assign({}, matrix, {
    labels: {
      dimensions: [dimension],
      keys: [dimensionality === 1 ? keys[0].map(label) : [], dimensionality === 2 ? keys[1].map(label) : []]
    }
  });
};