'use es6';

import { isBoolean, isString, isInteger, isObject } from '../../private/js-util';
import { isSet } from 'immutable';
import { instanceOf } from 'dashboard-lib/private/js-util';
const throwIfFalse = (checker, typeName) => (propName, subject) => {
  if (checker(subject) === false) {
    throw new Error(`Expected ${propName} to be a (non-nil) ${typeName}! Got ${subject}`);
  }
};
export const record = ExpectedRecord => throwIfFalse(instanceOf(ExpectedRecord), 'record');
export const integer = throwIfFalse(isInteger, 'integer');
export const string = throwIfFalse(isString, 'string');
export const boolean = throwIfFalse(isBoolean, 'boolean');
export const object = throwIfFalse(isObject, 'object');
export const enumeration = options => (propName, value) => {
  for (const option of options) {
    if (value === option) {
      return;
    }
  }
  throw new Error(`${propName} must be one of ${options}`);
};
export const set = throwIfFalse(isSet, 'set');
export const nullable = checker => (propName, value) => {
  if (value === undefined) {
    throw new Error(`${propName} is undefined.`);
  }
  if (value == null) {
    return;
  }
  checker(propName, value);
};