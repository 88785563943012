'use es6';

import * as checked from 'reporting-data/lib/checked';
export const LegendPositions = {
  TOP: 'TOP',
  LEFT: 'LEFT',
  BOTTOM: 'BOTTOM',
  RIGHT: 'RIGHT'
};
export const LegendPosition = checked.symbol(LegendPositions, 'LegendPosition');
export const LegendWidthTypes = {
  PIXEL: 'PIXEL',
  PERCENT: 'PERCENT',
  AUTO: 'AUTO'
};
export const LegendWidth = checked.record({
  value: checked.number(),
  type: checked.symbol(LegendWidthTypes, 'LegendWidthType').defaultValue(LegendWidthTypes.PERCENT)
}, 'LegendWidth');
export const DataLabelFormats = {
  X: 'X',
  Y: 'Y',
  SIZE: 'SIZE',
  X_Y: 'X_Y',
  X_Y_SIZE: 'X_Y_SIZE'
};
export const DataLabelFormat = checked.symbol(DataLabelFormats, 'DataLabelFormat');