export const DashboardFilterOperators = {
  EQ: 'Equal',
  NEQ: 'NotEqual',
  KNOWN: 'Known',
  UNKNOWN: 'NotKnown',
  LT: 'Less',
  LTE: 'LessOrEqual',
  GT: 'Greater',
  GTE: 'GreaterOrEqual',
  IN: 'In',
  NOT_IN: 'NotIn',
  CONTAIN_ANY: 'ContainAny',
  NOT_CONTAIN_ANY: 'NotContainAny',
  IN_RANGE: 'InRange',
  AFTER_DATE: 'AfterDate',
  BEFORE_DATE: 'BeforeDate',
  IN_ROLLING_DATE_RANGE: 'InRollingDateRange'
};
export const DYNAMIC_DATE_RANGE_PROPERTY_NAME = '__DYNAMIC_DATE_RANGE__';
export const isDynamicDateRangeFilterType = filter => {
  return filter.isDateRangeFilter === true;
};
export const isScriptedPropertyDashboardFilter = dashboardFilter => dashboardFilter.propertyScripted === true;
export function isRollingDateRangeDashboardFilter(filter) {
  return filter.operator === DashboardFilterOperators.IN_ROLLING_DATE_RANGE;
}
export function isValueDashboardFilter(filter) {
  return filter.operator === DashboardFilterOperators.LT || filter.operator === DashboardFilterOperators.LTE || filter.operator === DashboardFilterOperators.GT || filter.operator === DashboardFilterOperators.GTE || filter.operator === DashboardFilterOperators.EQ || filter.operator === DashboardFilterOperators.NEQ;
}
export function isDateCompareDashboardFilter(filter) {
  return filter.operator === DashboardFilterOperators.AFTER_DATE || filter.operator === DashboardFilterOperators.BEFORE_DATE;
}
export function isHasPropertyDashboardFilter(filter) {
  return filter.operator === DashboardFilterOperators.KNOWN || filter.operator === DashboardFilterOperators.UNKNOWN;
}
export function isRangeDashboardFilter(filter) {
  return filter.operator === DashboardFilterOperators.IN_RANGE;
}
export function isValuesDashboardFilter(filter) {
  return filter.operator === DashboardFilterOperators.IN || filter.operator === DashboardFilterOperators.NOT_IN || filter.operator === DashboardFilterOperators.CONTAIN_ANY || filter.operator === DashboardFilterOperators.NOT_CONTAIN_ANY;
}
export const areFilterOperatorsAndValuesSame = (filterA, filterB) => {
  if (filterA.operator === filterB.operator) {
    if (isValueDashboardFilter(filterA) && isValueDashboardFilter(filterB)) {
      return filterA.value === filterB.value;
    }
    if (isValuesDashboardFilter(filterA) && isValuesDashboardFilter(filterB)) {
      return filterA.value.length === filterB.value.length && filterA.value.every(value => filterB.value.includes(value));
    }
    if (isRangeDashboardFilter(filterA) && isRangeDashboardFilter(filterB)) {
      return filterA.value === filterB.value && filterA.highValue === filterB.highValue;
    }
    if (isHasPropertyDashboardFilter(filterA) && isHasPropertyDashboardFilter(filterB)) {
      return true;
    }
    if (isDateCompareDashboardFilter(filterA) && isDateCompareDashboardFilter(filterB)) {
      return filterA.value === filterB.value;
    }
    if (isRollingDateRangeDashboardFilter(filterA) && isRollingDateRangeDashboardFilter(filterB)) {
      return filterA.value.value === filterB.value.value && filterA.value.direction === filterB.value.direction && filterA.value.timeUnit === filterB.value.timeUnit && filterA.value.isInclusive === filterB.value.isInclusive && filterA.value.includeFutureDates === filterB.value.includeFutureDates && filterA.value.useFiscalYear === filterB.value.useFiscalYear;
    }
  }
  return false;
};
export const areFiltersDataSourceAndPropertyNameSame = (filterA, filterB) => filterA.propertyName === filterB.propertyName && filterA.dataSource === filterB.dataSource;
export const areFiltersSame = (filterA, filterB) => {
  if (areFiltersDataSourceAndPropertyNameSame(filterA, filterB)) {
    return areFilterOperatorsAndValuesSame(filterA, filterB);
  }
  return false;
};