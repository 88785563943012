'use es6';

import { createTracker } from 'usage-tracker';
import events from 'dashboard-ui-components/events.yaml';
import { getName, getType } from '../js/lib/dashboardFilters/propertySchema';
import { setPrimaryTracker } from 'usage-tracker-container';
import { REPORTING_TRACKER } from 'reporting-data/constants/usageTracker';
import get from 'transmute/get';
const trackerInstance = createTracker({
  events
});
setPrimaryTracker(trackerInstance, REPORTING_TRACKER);
let currentScreen = 'dashboard';
let currentSubscreen = 'dashboard';
const actionsWithSubscreen = ['deleteDashboard', 'renameDashboard', 'changeDashboardOwner', 'setDefaultDashboard', 'removeDefaultDashboard'];
const actionsThatActivate = ['addFilter', 'updateFilter', 'deleteFilter', 'updateLayout', 'createDashboard', 'cloneDashboard', 'renameDashboard', 'changeDashboardOwner', 'scheduleEmail', 'exportDashboard'];
export const DashboardUITracker = createTracker({
  events,
  properties: {
    namespace: 'reporting'
  },
  onError: err => {
    console.error(err);
  }
});
export const EVENTS = Object.keys(events).reduce((map, event) => Object.assign({}, map, {
  [event]: event
}), {});
export const setProperties = properties => {
  DashboardUITracker.setProperties(properties);
};
export const track = (action, extras = {}) => {
  const {
    screen,
    subscreen
  } = extras;
  currentScreen = screen || currentScreen;
  currentSubscreen = subscreen || currentSubscreen;

  // this is temporary - should be removed when the old tracking is deprecated
  if (currentScreen === 'dashboard') {
    currentScreen = 'custom-dashboard';
  }
  if (actionsThatActivate.includes(action)) {
    DashboardUITracker.track(EVENTS.dashboardActivation, Object.assign({
      action
    }, extras, {
      screen: currentScreen
    }));
  }
  DashboardUITracker.track(action, Object.assign({
    action
  }, extras, {
    screen: currentScreen
  }, actionsWithSubscreen.includes(action) ? {
    subscreen: currentSubscreen
  } : {}));
};
export const trackBeforeUnload = (eventKey, eventProperties = {}) => DashboardUITracker.clone({}).track(eventKey, eventProperties);
export const trackFilterInteraction = ({
  action,
  filterData,
  userRole,
  additionalProps = {}
}) => {
  const {
    dataSource,
    operator,
    property
  } = filterData;
  const _property = operator && !!get(operator, 'field') ? get(operator, 'field') : property;
  const operatorName = (() => {
    if (!operator) {
      return '';
    }
    if (typeof operator === 'string') {
      return operator;
    }
    return get(operator, 'name');
  })();
  track(EVENTS.dashboardFilterInteraction, Object.assign({
    actionType: 'explore',
    action,
    assetType: 'dashboard',
    dataTypes: [dataSource],
    property: getName(_property) || '',
    type: getType(_property) || '',
    operator: operatorName,
    role: userRole
  }, additionalProps));
};
export const trackQuickFilterGroupInteraction = ({
  action,
  quickFilterGroupData,
  userRole,
  additionalProps = {}
}) => {
  const {
    quickFilterGroup,
    operator
  } = quickFilterGroupData;
  const {
    name: quickFilterGroupName,
    systemGenerated,
    properties
  } = quickFilterGroup;
  track(EVENTS.dashboardQuickFilterGroupInteraction, Object.assign({
    actionType: 'explore',
    action,
    assetType: 'dashboard',
    quickFilterGroupName: quickFilterGroupName || '',
    type: operator ? operator.getIn(['field', 'type']) : '',
    operator: operator ? operator.get('name') : '',
    role: userRole,
    systemGenerated: !!systemGenerated,
    size: properties ? properties.length : 0
  }, additionalProps));
};