'use es6';

import { List, fromJS } from 'immutable';
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
const formatFeedbackReference = feedback => {
  return new ReferenceRecord({
    id: String(feedback.submissionId),
    label: feedback.responseContent,
    description: String(feedback.value),
    referencedObject: fromJS(feedback)
  });
};
const formatFeedback = allFeedback => {
  return List(allFeedback).map(formatFeedbackReference);
};
export default formatFeedback;