export const CONFIGURE = 'CONFIGURE';
export const PROPERTIES = 'PROPERTIES';
export const GENERATE = 'GENERATE';
export const REQUEST = 'REQUEST';
export const TRANSFORM = 'TRANSFORM';
export const PROCESS = 'PROCESS';
export const REFERENCES = 'REFERENCES';
export const HYDRATE = 'HYDRATE';
export const RETRIEVE = 'RETRIEVE';
export const ALL = [CONFIGURE, PROPERTIES, GENERATE, REQUEST, TRANSFORM, PROCESS, REFERENCES, HYDRATE];

// One-off phases
export const SPLIT = 'SPLIT';
export const RESOLVE = 'RESOLVE';
export const COMBINE = 'COMBINE';