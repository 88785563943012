'use es6';

import { NONE, PORTAL_TZ_ALIGNED, UTC_TZ_ALIGNED } from './TimestampTypes';
import I18n from 'I18n';
import invariant from 'react-utils/invariant';
export const convertTimestampToUtc = timestamp => {
  const localMoment = I18n.moment.portalTz(timestamp);
  const timezoneOffset = localMoment.utcOffset();
  const shiftedMoment = I18n.moment(localMoment);
  shiftedMoment.utc().add(timezoneOffset, 'minutes');
  return shiftedMoment.valueOf();
};
export const convertTimestampToSimpleDate = (timestamp, type) => {
  const parsedTimestamp = parseInt(timestamp, 10);
  if (type === PORTAL_TZ_ALIGNED) {
    return I18n.moment.portalTz(parsedTimestamp).format('YYYY-MM-DD');
  } else if (type === UTC_TZ_ALIGNED) {
    return I18n.moment.utc(parsedTimestamp).startOf('day').format('YYYY-MM-DD');
  } else if (type === NONE) {
    return timestamp;
  }
  invariant(false, 'Date conversions must use one of the types specified in TimestampTypes');
  return I18n.moment.portalTz(parsedTimestamp).format('YYYY-MM-DD');
};
export const convertSimpleDateToTimestamp = (date, type, isEndOfDayAligned) => {
  if (type === PORTAL_TZ_ALIGNED) {
    const portalMoment = I18n.moment.portalTz(date);
    return isEndOfDayAligned ? portalMoment.endOf('day').valueOf() : portalMoment.valueOf();
  } else if (type === UTC_TZ_ALIGNED) {
    const localMoment = I18n.moment.portalTz(date);
    const timezoneOffset = localMoment.utcOffset();
    const shiftedMoment = I18n.moment(localMoment);
    shiftedMoment.utc().add(timezoneOffset, 'minutes');
    if (isEndOfDayAligned) {
      shiftedMoment.endOf('day');
    }
    return shiftedMoment.valueOf();
  } else if (type === NONE) {
    return date;
  }
  invariant(false, 'Date conversions must use one of the types specified in TimestampTypes');
  return I18n.moment.portalTz(date).valueOf();
};