'use es6';

import EmailConfirmBarPrebuilt from 'email-confirm-ui/prebuilts/HubHttpPrebuilt';

// DashboardUI requires access to EmailConfirmBar's methods to be able to hide
// the element within certain views (ie Presentation mode).
//
// EmailConfirmBar is usually initialized after app initialization
// because it requires I18n to be fully set up. However, creating a new instance
// of the EmailConfirmBar will render the element immediately. By exporting
// initializeEmailConfirmBar, we are able to delay the initialization of EmailConfirmBar
// until the the function is run.
//
// Simlarly, the instance of EmailConfirmBar is stored in EmailConfirmBarObject, which
// enables us to access its methods later on (ie removeEmailConfirmBar). Exporting the object
// itself will return undefined, which is the value of EmailConfirmBar during module
// definition.

let EmailConfirmBarObject;
export const initializeEmailConfirmBar = () => {
  EmailConfirmBarObject = new EmailConfirmBarPrebuilt();
};
export const removeEmailConfirmBar = () => {
  if (EmailConfirmBarObject) {
    EmailConfirmBarObject.remove();
  }
};