import { createSlice } from '@reduxjs/toolkit';
import { USERS_NAMESPACE } from './types';
import { fetchUsersAsyncThunk } from './thunks';
import { RequestState } from 'dashboard-lib/public/request/request-state';
const initialState = {
  value: {},
  status: RequestState.UNINITIALIZED
};
const usersDataSlice = createSlice({
  name: USERS_NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchUsersAsyncThunk.fulfilled, (state, action) => {
      const users = action.payload;
      state.value = users.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {});
      state.status = RequestState.SUCCEEDED;
    });
    builder.addCase(fetchUsersAsyncThunk.pending, state => {
      state.status = RequestState.PENDING;
    });
    builder.addCase(fetchUsersAsyncThunk.rejected, state => {
      state.status = RequestState.FAILED;
    });
  }
});
export const usersDataReducer = usersDataSlice.reducer;
export const __TESTABLE__ = {
  initialState
};