import { Map as ImmutableMap } from 'immutable';
//@ts-expect-error untyped dependency
import { stableStringify } from 'reporting-data/lib/stringify';
let REQUEST_CACHE = ImmutableMap();
const CACHE_THRESHOLD_IN_MILLIS = 10000; // 10 secs

const getCacheIdForQuery = query => stableStringify(query);
export const setRequestCache = (query, promise) => {
  const cachedRecord = {
    promise,
    timestamp: new Date().getTime()
  };
  REQUEST_CACHE = REQUEST_CACHE.set(getCacheIdForQuery(query), cachedRecord);
};
export const isValidRequestInCache = query => {
  const cacheId = getCacheIdForQuery(query);
  const now = new Date().getTime();
  const upperBound = REQUEST_CACHE.has(cacheId) ? REQUEST_CACHE.get(cacheId).timestamp + CACHE_THRESHOLD_IN_MILLIS : -1;
  return now <= upperBound;
};
export const invalidateRequestInCache = query => {
  const cacheId = getCacheIdForQuery(query);
  if (REQUEST_CACHE.has(cacheId)) {
    REQUEST_CACHE = REQUEST_CACHE.delete(cacheId);
  }
};
export const getResponseFromRequestCache = query => REQUEST_CACHE.get(getCacheIdForQuery(query)).promise;