'use es6';

import { Map as ImmutableMap, fromJS } from 'immutable';
import I18n from 'I18n';
import { Config } from './config';
import makeDateRangeByType from './lib/makeDateRangeByType';
import { RANGE_TYPES } from './constants/dateRangeTypes';
import { TIME_SERIES, AGGREGATION } from './constants/configTypes';
import { endOf } from './lib/dateUtils';
import { NewRelicInteraction } from './monitoring/newrelic';
import { YEAR } from './constants/frequency';
import { DEFAULT_FISCAL_YEAR_MONTH_NAME } from './constants/dates';
import { getUseFiscalYear, isFiscalYearSupportedFrequency } from './lib/fiscalYearUtils';
const DATE_FORMAT = 'YYYY-MM-DD';
const DURATION_ROLLBACK_RANGE_TYPES = [RANGE_TYPES.ALL, RANGE_TYPES.IS_AFTER_DATE, RANGE_TYPES.ROLLING, RANGE_TYPES.CUSTOM];
function createCustomDateRange(start, end) {
  return ImmutableMap({
    rangeType: 'CUSTOM',
    endDate: end.format(DATE_FORMAT),
    startDate: start.format(DATE_FORMAT),
    entireCurrentUnit: true
  });
}
export function getLastYearFilter(filter) {
  const start = I18n.moment.portalTz(filter.get('startDate'));
  const end = I18n.moment.portalTz(filter.get('endDate'));
  const highValue = end.subtract(1, 'years').format(DATE_FORMAT);
  const value = start.subtract(1, 'years').format(DATE_FORMAT);
  return filter.set('endDate', highValue).set('startDate', value).set('rangeType', 'CUSTOM');
}
export function getPriorPeriodFilter(filter, dataType, useFiscalYear = false) {
  const entireCurrentUnit = filter.get('entireCurrentUnit');
  const rangeType = filter.get('rangeType');
  const start = I18n.moment.portalTz(filter.get('startDate'));
  const end = I18n.moment.portalTz(filter.get('endDate'));
  if (DURATION_ROLLBACK_RANGE_TYPES.includes(rangeType)) {
    const duration = end.diff(start, 'days');
    const priorEnd = start.clone().subtract(1, 'days');
    const priorStart = start.clone().subtract(duration + 1, 'days');
    return createCustomDateRange(priorStart, priorEnd);
  }
  const [, dateUnit] = rangeType.split('_');
  const priorStart = start.subtract(1, dateUnit);
  let priorEnd = end.subtract(1, dateUnit);
  if (entireCurrentUnit && !useFiscalYear) {
    priorEnd = endOf({
      date: priorEnd,
      frequency: dateUnit,
      dataType
    });
  } else if (useFiscalYear && entireCurrentUnit && isFiscalYearSupportedFrequency(dateUnit)) {
    priorEnd = priorEnd.endOf('month');
  }
  return createCustomDateRange(priorStart, priorEnd);
}
export const isComparisonConfig = config => config.get('compare') && (config.get('configType') === TIME_SERIES || config.get('configType') === AGGREGATION) && config.getIn(['filters', 'dateRange']);
const REPORTING_DATA_COMPARELABEL_CALL = 'ReportingDataCompareLabelCall';
const REPORTING_DATA_COMPARELABEL_COMPARE_PARAM = 'ReportingDataCompareLabelCompareParam';
const REPORTING_DATA_COMPARELABEL_METRICLABEL_PARAM = 'ReportingDataCompareLabelMetricLabelParam';
const compareLabelWithLog = (report, compare, metricLabel) => {
  const newRelicInteraction = new NewRelicInteraction();
  if (report) {
    newRelicInteraction.addReportAttributes(report);
  }
  newRelicInteraction.addAttribute(REPORTING_DATA_COMPARELABEL_COMPARE_PARAM, compare);
  newRelicInteraction.addAttribute(REPORTING_DATA_COMPARELABEL_METRICLABEL_PARAM, metricLabel);
  newRelicInteraction.sendPageAction(REPORTING_DATA_COMPARELABEL_CALL);
  if (!metricLabel) {
    return compare === 'PRIOR_YEAR' ? I18n.text('reporting-data.compare.priorYear') : I18n.text('reporting-data.compare.priorPeriod');
  }
  return compare === 'PRIOR_YEAR' ? I18n.text('reporting-data.compare.priorYearAndLabel', {
    metricLabel
  }) : I18n.text('reporting-data.compare.priorPeriodAndLabel', {
    metricLabel
  });
};
export const compareLabelFactory = report => {
  return (compare, metricLabel) => {
    return compareLabelWithLog(report, compare, metricLabel);
  };
};
export const splitConfig = (config, fiscalYearMonthName = DEFAULT_FISCAL_YEAR_MONTH_NAME) => {
  const dataType = config.get('dataType');
  const compare = config.get('compare');
  const base = config.delete('compare');
  const filter = config.getIn(['filters', 'dateRange']);
  const baseDateRange = filter.get('value');
  const rangeType = baseDateRange.get('rangeType', '');
  const useFiscalYear = getUseFiscalYear(config);
  const [, dateUnit] = rangeType.split('_');
  const fullDateRange = fromJS(makeDateRangeByType(baseDateRange.toJS(), DATE_FORMAT, dataType, useFiscalYear, fiscalYearMonthName));
  const compareFilter = compare === 'PRIOR_YEAR' || dateUnit === YEAR ? getLastYearFilter(fullDateRange) : getPriorPeriodFilter(fullDateRange, config.get('dataType'), useFiscalYear);
  const compareConfig = Config(base.setIn(['filters', 'dateRange', 'value'], compareFilter));
  return {
    config: base,
    compareConfig,
    compareLabelFactory: report => compareLabelWithLog(report, compare)
  };
};