'use es6';

import { Set as ImmutableSet } from 'immutable';
import * as dataTypes from 'reporting-data/constants/dataTypes';
const USES_SAVED_FILTERS = ImmutableSet([dataTypes.DEALS, dataTypes.COMPANIES, dataTypes.CONTACTS]);
export function checkCanApplySavedFilters(report) {
  if (report.getIn(['config', 'template']) === 'hubspotSalesPipeline') {
    return false;
  }
  const dataType = report.getIn(['config', 'dataType']);
  return USES_SAVED_FILTERS.has(dataType);
}