'use es6';

import { has } from '../../../lib/has';
import { pairs } from './metrics';
import { zero } from './downgrade';
const parse = (spec, config) => response => {
  const {
    metrics = []
  } = config;
  const {
    total,
    totals = {}
  } = response;
  const matrix = {
    dimensions: [],
    metrics: pairs(config),
    keys: [],
    data: metrics.map(({
      property
    }) => has(totals, property) ? totals[property] : 0),
    total: [total]
  };
  return {
    response,
    matrix
  };
};
export const get = (spec, config) => ({
  url: `${spec.url}/total`,
  parse: parse(spec, config),
  downgrade: zero
});