'use es6';

import { UnifiedEventGroups } from 'report-customization-components/components/unified-event-dropdown/utils';
import { isUnifiedEventsQLReport } from 'reporting-data/tsTypes/reportTypes';
export const validateUnifiedEventDatatype = (contextChange, _context, report, subAppKey) => {
  const isUESearch = isUnifiedEventsQLReport(report);
  const eventsAllowed = UnifiedEventGroups[subAppKey];
  if (isUESearch && !eventsAllowed.includes(contextChange.event)) {
    return Object.assign({}, contextChange, {
      event: eventsAllowed[0]
    });
  }
  return contextChange;
};