import { Component } from 'react';
import { HAS_UNRECOVERABLE_ERROR } from '../../constants/CollaborationSidebarStateProperties';
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }
  componentDidCatch() {
    const {
      onUpdateCollaborationSidebarState
    } = this.props;
    onUpdateCollaborationSidebarState({
      [HAS_UNRECOVERABLE_ERROR]: true
    });
  }
  render() {
    if (this.state.hasError) {
      return this.props.renderFallback();
    }
    return this.props.children;
  }
}