'use es6';

import RollingDateConfig from 'customer-data-filters/filterQueryFormat/rollingDates/RollingDateConfig';
import { RANGE_OPERATOR_TYPES } from '../constants/rollingDateRange';
const fromRollingDateRangeOperation = (Operator, field, {
  operation
}) => {
  const {
    direction,
    isInclusive,
    includeFutureDates
  } = RANGE_OPERATOR_TYPES[operation.operator];
  return Operator.of(field, RollingDateConfig({
    direction,
    includeFutureDates,
    useFiscalYear: operation.useFiscalYear === true,
    isInclusive,
    timeUnit: operation.timeUnit,
    value: operation.timeUnitCount
  }), 0);
};
export { fromRollingDateRangeOperation };