'use es6';

export const ORANGE = '#fea58e';
export const DARK_PURPLE = '#9784c2';
export const YELLOW = '#f5c78e';
export const GREEN = '#a4d398';
export const AQUA = '#51d3d9';
export const BLUE = '#81c1fd';
export const PURPLE = '#bda9ea';
export const RED = '#ea90b1';
export const NEARWHITE = '#f9f9f9';
export const DARK_ORANGE = '#c3705c';
export const DARK_AQUA = '#009ca2';
export const DARK_YELLOW = '#bb915b';
export const DARK_PINK = '#b05c7d';
export const DARK_BLUE = '#468cc4';
export const DARK_GREEN = '#6b9a5b';
export const SEQUENTIAL_1 = [ORANGE];
export const SEQUENTIAL_2 = [ORANGE, DARK_PURPLE];
export const SEQUENTIAL_3 = [ORANGE, '#ce94a9', DARK_PURPLE];
export const SEQUENTIAL_4 = [ORANGE, '#df9aa0', '#be8eb1', DARK_PURPLE];
export const SEQUENTIAL_5 = [ORANGE, '#e79d9c', '#ce94a9', '#b48cb6', DARK_PURPLE];
export const SEQUENTIAL_6 = [ORANGE, '#ec9e99', '#d997a3', '#c491ae', '#b08ab8', DARK_PURPLE];
export const SEQUENTIAL_7 = [ORANGE, '#f09f97', '#df9aa0', '#ce94a9', '#be8eb1', '#ac89b9', DARK_PURPLE];
export const FUNNEL = ['#fea58e', '#fb9d95', '#f7969c', '#ef91a4', '#e68cab', '#da89b2', '#cc87b8', '#bc85bd', '#aa85c0', '#9784c2'];
export const DIVERGENT_2 = [ORANGE, NEARWHITE, AQUA];
export const DIVERGENT_4 = [ORANGE, '#ffcfc3', NEARWHITE, '#b0e6e9', AQUA];
export const DIVERGENT_6 = [ORANGE, '#ffc2b1', '#feded5', NEARWHITE, '#c9edee', '#96e0e4', AQUA];
export const DIVERGENT_8 = [ORANGE, '#ffbba8', '#ffcfc3', '#fde5de', NEARWHITE, '#d5f0f1', '#b0e6e9', '#86dde1', AQUA];
export const DIVERGENT_10 = [ORANGE, '#ffb6a3', '#ffc7b9', '#ffd7cd', '#fde9e3', NEARWHITE, '#ddf2f2', '#c0eaec', '#9fe3e6', '#7edbdf', AQUA];
export const DIVERGENT_12 = [ORANGE, '#ffb4a0', '#ffc2b1', '#ffcfc3', '#feded5', '#fcebe7', NEARWHITE, '#e2f3f4', '#c9edee', '#b0e6e9', '#96e0e4', '#76d9de', AQUA];
export const SEMANTIC_1 = [GREEN, RED];
export const SEMANTIC_2 = [RED, NEARWHITE, GREEN];
export const SEMANTIC_4 = [RED, '#f4c5d4', NEARWHITE, '#d0e6c9', GREEN];
export const SEMANTIC_6 = [RED, '#f1b4c8', '#f6d7e1', NEARWHITE, '#ddecd8', '#c0e0b8', GREEN];
export const SEMANTIC_8 = [RED, '#f0abc2', '#f4c5d4', '#f7dfe7', NEARWHITE, '#e4efe0', '#d0e6c9', '#b9dcaf', GREEN];
export const SEMANTIC_10 = [RED, '#efa5bf', '#f2bacd', '#f5d0dc', '#f8e4ea', NEARWHITE, '#e8f2e5', '#d8ead2', '#c7e3be', '#b5dbaa', GREEN];
export const SEMANTIC_12 = [RED, '#eea2bc', '#f1b4c8', '#f4c5d4', '#f6d7e1', '#f8e8ed', NEARWHITE, '#ebf3e8', '#ddecd8', '#d0e6c9', '#c0e0b8', '#b3d9a8', GREEN];
export const ORANGES = ['#904233', '#a75645', '#b86554', '#cf7a67', '#e78f7b', '#ffa490', '#ffc2ac', '#ffdec9', '#fffbe6'];
export const AQUAS = ['#066469', '#00747a', '#008a90', '#00a1a7', '#29b7bd', '#49cdd3', '#64e4ea', '#7efbff', '#a7ffff'];
export const PURPLES = ['#5b4b85', '#6f5f9a', '#8472af', '#9a87c5', '#af9cdc', '#c6b1f2', '#ddc8ff', '#f6e1ff', '#fffcff'];
export const YELLOWS = ['#cb8007', '#df9419', '#eca531', '#f5b44b', '#fec76f', '#ffd28a', '#ffdca3', '#ffe5bb', '#fff1da'];
export const PINKS = ['#8f3e5f', '#9f4d6e', '#b66182', '#cd7696', '#e48bac', '#fca1c2', '#ffb7d8', '#ffcdee', '#ffe4ff'];
export const BLUES = ['#005a8e', '#186da3', '#3981b9', '#5296cf', '#6aabe6', '#81c1fd', '#99d7ff', '#b0eeff', '#c7ffff'];
export const GREENS = ['#315f24', '#457337', '#59884a', '#6e9d5d', '#83b271', '#98c886', '#aedf9b', '#c4f6b1', '#dbffc7'];