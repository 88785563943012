'use es6';

import prefix from '../../../lib/prefix';
const translate = prefix('reporting-data.offlineSources');
export default (() => ({
  IMPORT: translate('import'),
  API: translate('api'),
  FORM: translate('form'),
  ANALYTICS: translate('analytics'),
  MIGRATION: translate('migration'),
  SALESFORCE: translate('salesforce'),
  INTEGRATION: translate('integration'),
  CONTACTS_WEB: translate('contactsWeb'),
  WAL_INCREMENTAL: translate('walIncremental'),
  TASK: translate('task'),
  EMAIL: translate('email'),
  WORKFLOWS: translate('workflows'),
  CALCULATED: translate('calculated'),
  SOCIAL: translate('social'),
  BATCH_UPDATE: translate('batchUpdate'),
  SIGNALS: translate('signals'),
  COMPANY_INSIGHTS: translate('companyInsights'),
  COMPANIES: translate('companies'),
  DEALS: translate('deals'),
  ASSISTS: translate('assists'),
  PRESENTATIONS: translate('presentations'),
  TALLY: translate('tally'),
  SIDEKICK: translate('sidekick'),
  CRM_UI: translate('crmUI'),
  MERGE_CONTACTS: translate('mergeContacts'),
  PORTAL_USER_ASSOCIATOR: translate('portalUserAssociator'),
  INTEGRATIONS_PLATFORM: translate('integrationsPlatform'),
  BCC_TO_CRM: translate('bccToCrm'),
  FORWARD_TO_CRM: translate('forwardToCrm'),
  ENGAGEMENTS: translate('engagements'),
  SALES: translate('sales'),
  HEISENBERG: translate('heisenberg'),
  LEADIN: translate('leadin'),
  GMAIL_INTEGRATION: translate('gmailIntegration'),
  ACADEMY: translate('academy'),
  SALES_MESSAGES: translate('salesMessages'),
  AVATARS_SERVICE: translate('avatarsService'),
  MERGE_COMPANIES: translate('mergeCompanies'),
  SEQUENCES: translate('sequences'),
  COMPANY_FAMILIES: translate('companyFamilies'),
  MOBILE_IOS: translate('mobileIOS'),
  MOBILE_ANDROID: translate('mobileAndroid'),
  CONTACTS: translate('contacts'),
  ASSOCIATIONS: translate('associations'),
  EXTENSION: translate('extension'),
  SUCCESS: translate('success'),
  BOT: translate('bot'),
  INTEGRATIONS_SYNC: translate('integrationsSync'),
  AUTOMATION_PLATFORM: translate('automationPlatform'),
  CONVERSATIONS: translate('conversations'),
  EMAIL_INTEGRATION: translate('emailIntegration'),
  CONTENT_MEMBERSHIP: translate('contentMembership'),
  QUOTES: translate('quotes'),
  BET_ASSIGNMENT: translate('betAssignment'),
  QUOTAS: translate('quotas'),
  BET_CRM_CONNECTOR: translate('betCrmConnector'),
  MEETINGS: translate('meetings'),
  MERGE_OBJECTS: translate('mergeObjects'),
  PLAYBOOKS: translate('playbooks')
}));