import { useEffect, useState } from 'react';
import events from '../../events.yaml';
import { emptyTracker, isEmptyTracker } from 'reporting-data/tsTypes/usageTrackerTypes';
import { getUsageTracker } from '../monitoring/usageTracker';
const useCloneTracker = appUsageTracker => {
  const [usageTracker, setUsageTracker] = useState(emptyTracker);
  const primaryTracker = getUsageTracker();
  useEffect(() => {
    if (!isEmptyTracker(primaryTracker)) {
      setUsageTracker(primaryTracker);
    } else if (appUsageTracker) {
      setUsageTracker(appUsageTracker.clone({
        events,
        preserveTrackerProperties: false
      }) || emptyTracker);
    }
  }, [appUsageTracker, primaryTracker]);
  return usageTracker;
};
export default useCloneTracker;