'use es6';

import { IS_READ_ONLY_DEMO_PROBLEM, PERMISSION_DENIED } from 'dashboard-lib/private/action-problem-solution/actions/remove-report-from-dashboard/problem';
import { indexBy } from 'dashboard-lib/private/js-util';
import { DASHBOARD_REMOVE_REPORT } from 'dashboard-lib/public/action/reporting-action';
import { NeedEditPermissionMessage } from '../common/common-messages';
import { createDisabledMessage } from '../common/disabled-message';
import { getProblemLang } from '../common/get-problem-lang';
import { createMessage, getId } from '../common/message';
const MESSAGE_ROOT = getProblemLang(DASHBOARD_REMOVE_REPORT);
const createProblemMessage = createMessage(MESSAGE_ROOT);
const ProblemMessages = [NeedEditPermissionMessage(DASHBOARD_REMOVE_REPORT), createProblemMessage(PERMISSION_DENIED), createProblemMessage(IS_READ_ONLY_DEMO_PROBLEM)];
const _problemToMessage = indexBy(getId, ProblemMessages);
export function getDisabledProblemMessage(params, problem) {
  return createDisabledMessage(params, problem, _problemToMessage);
}