'use es6';

import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
import { createCustomObjectGetter } from 'reference-resolvers/formatters/formatCustomObjects';
import { fromJS, List } from 'immutable';
import indexBy from 'transmute/indexBy';
import get from 'transmute/get';
export const getLabel = createCustomObjectGetter('hs_chatflow_name');
export const getArchived = chatflow => !!chatflow.properties.hs_deleted_at;
const formatChatflowReference = chatflow => {
  return new ReferenceRecord({
    id: String(chatflow.objectId),
    label: getLabel(chatflow),
    referencedObject: fromJS(chatflow),
    archived: getArchived(chatflow)
  });
};
export const formatChatflows = chatflows => {
  return indexBy(get('id'), List(Object.values(chatflows)).map(formatChatflowReference));
};
export default formatChatflows;