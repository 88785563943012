import * as ObjectTypes from 'customer-data-objects/constants/ObjectTypes';
import { List, OrderedMap, Record, is as defaultIs } from 'immutable';
import { isOperator } from '../operator/Operator';
import either from 'transmute/either';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import isNull from 'transmute/isNull';
import isRecord from 'transmute/isRecord';
import isString from 'transmute/isString';
import isUndefined from 'transmute/isUndefined';
import protocol from 'transmute/protocol';
export function isLogicGroup(group) {
  return isRecord(group) && (OrderedMap.isOrderedMap(group.conditions) || List.isList(group.conditions));
}
export const addCondition = protocol({
  name: 'addCondition',
  args: [either(isLogicGroup, isOperator), protocol.TYPE, either(isUndefined, isString)]
});
export const getRealPath = keyPath => {
  return keyPath.flatMap(key => List(['conditions', key]));
};
export function getKeyPath(keyPath, group) {
  return group.getIn(getRealPath(keyPath), group);
}
export function getExactKeyPath(keyPath, group) {
  return group.getIn(getRealPath(keyPath));
}
const getKeyPathPrefix = condition => `${getIn(['field', 'name'], condition)} ${get('name', condition)}`;
export const getConditionKey = (condition, group) => {
  const conditionIsLogicGroup = isLogicGroup(condition);
  const conditionFilterFamily = getIn(['filterFamily'], condition);
  const conditionKey = conditionIsLogicGroup ? `${conditionFilterFamily}` : [getKeyPathPrefix(condition), getIn(['conditions'], group).size].join(' ');
  return conditionKey;
};
export const findConditionKeyPath = (condition, group) => {
  if (isLogicGroup(condition)) {
    return null;
  }
  const prefix = getKeyPathPrefix(condition);
  return get('conditions', group).keySeq().find(key => key.indexOf(prefix) === 0) || null;
};

// @ts-expect-error ts-migrate(7023) FIXME: 'findDeepConditionKeyPath' implicitly has return t... Remove this comment to see the full error message
export const findDeepConditionKeyPath = (condition, group, conditionPath = List()) => {
  const groupConditions = group.conditions.entrySeq().toJS();
  for (let i = 0; i < groupConditions.length; i++) {
    const [currentKey, currentCondition] = groupConditions[i];
    if (condition.equals(currentCondition)) {
      return conditionPath.push(currentKey);
    }
    if (currentCondition.conditions) {
      // @ts-expect-error ts-migrate(7022) FIXME: 'conditionId' implicitly has type 'any' because it... Remove this comment to see the full error message
      const conditionId = findDeepConditionKeyPath(condition, currentCondition, conditionPath.push(currentKey));
      if (conditionId !== undefined) {
        return conditionId;
      }
    }
  }
  return undefined;
};
export function addToKeyPath(keyPath, condition, group, filterFamily) {
  return group.updateIn(getRealPath(keyPath), subgroup => {
    return addCondition(condition, subgroup, filterFamily);
  });
}

// @ts-expect-error ts-migrate(7023) FIXME: 'removeFilter' implicitly has return type 'any' be... Remove this comment to see the full error message
function removeFilter(keyPath, group, removeEmptyParentGroup = true) {
  if (keyPath.size > 0) {
    // @ts-expect-error ts-migrate(7022) FIXME: 'recursed' implicitly has type 'any' because it do... Remove this comment to see the full error message
    const recursed = removeFilter(keyPath.slice(2), group.getIn(keyPath.take(2)), removeEmptyParentGroup);
    if (isNull(recursed)) {
      return group.conditions.size > 1 || !removeEmptyParentGroup ? group.removeIn(keyPath.take(2)) : null;
    }
    return group.setIn(keyPath.take(2), recursed);
  }
  return null;
}
export function removeKeyPath(keyPath, group, removeEmptyParentGroup = true) {
  const realPath = getRealPath(keyPath);
  const recursed = removeFilter(realPath, group, removeEmptyParentGroup);
  return isNull(recursed) ? group.removeIn(realPath.take(2)) : recursed;
}
export function setKeyPath(keyPath, condition, group) {
  return group.setIn(getRealPath(keyPath), condition);
}
export const removeEmptyChildGroups = logicGroup => {
  return logicGroup.set('conditions', logicGroup.conditions.filter(condition => {
    if (isLogicGroup(condition)) {
      return condition.conditions.size > 0;
    }
    return true;
  }));
};
function isGroupsOwnPropertiesEqual(groupA, groupB) {
  const groupProperties = ['associationTypeId', 'associationCategory', 'name', 'includeObjectsWithNoAssociatedObjects', 'hasNotCompletedUnifiedEvent'];
  return groupProperties.every(prop => groupA.get(prop) === groupB.get(prop));
}
export function is(groupA, groupB) {
  if (!isLogicGroup(groupA) || !isLogicGroup(groupB)) {
    return defaultIs(groupA, groupB);
  }
  if (!isGroupsOwnPropertiesEqual(groupA, groupB)) {
    return false;
  }
  const aConditions = groupA.conditions.valueSeq();
  const bConditions = groupB.conditions.valueSeq();
  return aConditions.every((condition, index) => is(condition, bConditions.get(index))) && aConditions.size === bConditions.size;
}
export function makeLogicGroup(name, conditionsValue = OrderedMap(), includeObjectsWithNoAssociatedObjects = false) {
  // Filter Family can be any DSFilterFamily (A subset of DSAssetFamilies)
  // or (temporarily) ObjectType.
  const recordShape = {
    includeObjectsWithNoAssociatedObjects,
    conditions: conditionsValue,
    filterFamily: ObjectTypes.CONTACT,
    associationTypeId: undefined,
    associationCategory: undefined,
    hasNotCompletedUnifiedEvent: undefined,
    name
  };
  const Group = Record(recordShape, name);

  // @ts-expect-error how to type this?
  Group[`is${name}`] = thing => thing instanceof Group;
  Group.of = (...conditions) => conditions.reduce((group, condition) => addCondition(condition, group), Group());
  return Group;
}