'use es6';

import * as checked from 'reporting-data/lib/checked';
import { Direction, Directions } from '../field/order';
import { AggregateTypes } from './aggregate';

/**
 * Each of these transforms are meant to operate over a layer of a visualization
 * to alter the dataset. These are roughly based off of vega-lite's transform
 * operations (https://vega.github.io/vega-lite/docs/window.html#window-field-definition).
 *
 * There are a few reoccuring parts of transformation definitions:
 *  field - The column of the dataset which the transformation will operate over
 *  groupBy - (optional) When a transformation should operate over groups of
 *    data from discrete breakdowns, this indicates which column/columns
 *    indicate the breakdown.
 *  as - (optional) The name of the column that the result of the operation will
 *    be put into
 */

export const TransformTypes = {
  AGGREGATE: 'AGGREGATE',
  ACCUMULATE: 'ACCUMULATE'
};
export const TransformType = checked.symbol(TransformTypes, 'TransformType');
export const AggregateDetail = checked.record({
  op: checked.symbol(AggregateTypes, 'AggregateType'),
  field: checked.string(),
  as: checked.string().optional()
}, 'AggregateDetail');
export const AggregateTransform = checked.record({
  type: TransformType.always(TransformTypes.AGGREGATE).defaultValue(TransformTypes.AGGREGATE),
  aggregations: checked.list(AggregateDetail),
  groupBy: checked.list(checked.string()).optional()
}, 'AggregateTransform');
export const AccumulateAlong = checked.record({
  field: checked.string(),
  direction: Direction.optional().defaultValue(Directions.ascending)
}, 'AccumulateAlong');
export const AccumulateTransform = checked.record({
  type: TransformType.always(TransformTypes.ACCUMULATE).defaultValue(TransformTypes.ACCUMULATE),
  groupBy: checked.list(checked.string()).optional(),
  field: checked.string(),
  as: checked.string().optional(),
  along: AccumulateAlong.optional()
}, 'AccumulateTransform');
export const Transform = checked.poly('type', {
  [TransformTypes.AGGREGATE]: AggregateTransform,
  [TransformTypes.ACCUMULATE]: AccumulateTransform
}, 'Transform');