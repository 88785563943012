/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */
/* eslint-disable prefer-promise-reject-errors */

'use es6';

import quickFetch from 'quick-fetch';
import { fromJS } from 'immutable';
import http from 'hub-http/clients/apiClient';
import { fromResponse, toRequest } from './DashboardDAOHelper';
import { stringify } from 'hub-http/helpers/params';
const DASHBOARDS_ENDPOINT = `dashboard/v2`;
const DASHBOARD_URL = `${DASHBOARDS_ENDPOINT}/dashboard`;
const REPORT_COUNTS_URL = `${DASHBOARDS_ENDPOINT}/reports/report-counts`;
const REPORTS_URL = `${DASHBOARDS_ENDPOINT}/reports`;
const getDashboardUrl = dashboardId => `${DASHBOARDS_ENDPOINT}/dashboard/${dashboardId}`;
const getReportUrl = reportId => `${REPORTS_URL}/${reportId}`;
const getWidgetUrl = dashboardId => `${getDashboardUrl(dashboardId)}/widgets`;
const fixLimit = report => {
  return report.getIn(['config', 'limit']) === null ? report.deleteIn(['config', 'limit']) : report;
};
export const updateReport = (reportId, report) => {
  return http.put(getReportUrl(reportId), {
    data: report.toJS()
  }).then(fromJS).then(fixLimit);
};
export const deleteReport = (reportId, options = {}) => {
  return http.delete(getReportUrl(reportId), options).then(fromJS);
};
export const deleteWidget = (dashboardId, reportId, options = {
  query: {
    keepReport: true
  }
}) => {
  return http.delete(`${getDashboardUrl(dashboardId)}/report/${reportId}`, options).then(fromJS);
};
export const fetchDashboards = (options = {}) => {
  const get = () => http.get(DASHBOARD_URL, {
    query: Object.assign({}, options)
  }).then(fromJS);
  const earlyRequest = quickFetch.getRequestStateByName('dashboards');
  if (earlyRequest) {
    return new Promise((resolve, reject) => {
      earlyRequest.whenFinished(request => {
        resolve(fromJS(request));
        quickFetch.removeEarlyRequest('dashboards');
      });
      earlyRequest.onError(xhr => {
        if (xhr.status !== 401) {
          get().then(resolve, reject);
        } else {
          reject(xhr);
        }
        quickFetch.removeEarlyRequest('dashboards');
      });
    });
  } else {
    return get();
  }
};
export const fetchDashboard = (dashboardId, params = {}) => {
  const get = () => http.get(`${getDashboardUrl(dashboardId)}?${stringify(params)}`).then(fromJS).then(fromResponse);
  const earlyRequest = quickFetch.getRequestStateByName('dashboard');
  if (earlyRequest) {
    return new Promise((resolve, reject) => {
      earlyRequest.whenFinished(request => {
        resolve(fromJS(request));
        quickFetch.removeEarlyRequest('dashboard');
      });
      earlyRequest.onError(xhr => {
        if (xhr.status !== 401) {
          get().then(resolve, reject);
        } else {
          reject(xhr);
        }
        quickFetch.removeEarlyRequest('dashboard');
      });
    });
  } else {
    return get();
  }
};
export const deleteDashboard = (dashboardId, options = {}) => {
  return http.delete(getDashboardUrl(dashboardId), {
    query: options
  }).then(fromJS);
};
export const cloneDashboard = ({
  dashboardId,
  name,
  dashboardPermissionConfig,
  shouldCloneReports
}) => {
  return http.post(`${DASHBOARD_URL}/clone`, {
    data: {
      dashboardId,
      name,
      dashboardPermissionConfig
    },
    query: {
      cloneReports: shouldCloneReports
    }
  }).then(fromJS).then(fromResponse);
};
export const updateDashboardMeta = (dashboardId, dashboard) => {
  const data = toRequest(dashboard).toJS();
  return http.put(getDashboardUrl(dashboardId), {
    data
  }).then(fromJS);
};
export const updateDashboardLayout = (dashboardId, widgets) => {
  const url = `${getDashboardUrl(dashboardId)}/layout`;
  return http.post(url, {
    data: widgets.toJS()
  }).then(fromJS);
};
export const getReportCounts = () => {
  const get = () => http.get(REPORT_COUNTS_URL).then(fromJS);
  const earlyRequest = quickFetch.getRequestStateByName('reportCounts');
  if (earlyRequest) {
    return new Promise((resolve, reject) => {
      earlyRequest.whenFinished(request => {
        resolve(fromJS(request));
        quickFetch.removeEarlyRequest('reportCounts');
      });
      earlyRequest.onError(xhr => {
        if (xhr.status !== 401) {
          get().then(resolve, reject);
        } else {
          reject(xhr);
        }
        quickFetch.removeEarlyRequest('reportCounts');
      });
    });
  } else {
    return get();
  }
};
export const createWidget = (dashboardId, report) => {
  return http.post(getWidgetUrl(dashboardId), {
    data: [report.toJS()]
  }).then(fromJS);
};