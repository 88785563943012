'use es6';

import I18n from 'I18n';
import { Seq, Set as ImmutableSet } from 'immutable';
const TOKEN = {
  LP: '(',
  RP: ')',
  AND: 'AND',
  OR: 'OR',
  NOT: 'NOT'
};
const PRECEDENCE = {
  [TOKEN.LP]: 3,
  [TOKEN.NOT]: 2,
  [TOKEN.AND]: 1,
  [TOKEN.OR]: 0
};

/**
 * This should really keep data about
 * the tokens character indices.
 * For production, will probably use ANTLR :)
 */
const tokenize = input => {
  input = input.replace(/\(/g, ' ( ');
  input = input.replace(/\)/g, ' ) ');
  input = input.replace(/AND|and/g, ' AND ');
  input = input.replace(/OR|or/g, ' OR ');
  input = input.replace(/&&|&/g, ' AND ');
  input = input.replace(/\|\||\|/g, ' OR ');
  input = input.replace(/NOT|not/g, ' NOT ');
  // Disable NOT tokenization if/until BE supports it
  // input = input.replace(/!/g, ' NOT ');

  input = input.trim();
  return input.split(/\s+/).filter(s => s !== '');
};
const isNumber = str => /^(0|[1-9]\d*)$/.test(str);
const parser = validIds => {
  const parse = tokens => {
    if (tokens.length === 0) {
      return undefined;
    }
    if (tokens.length === 1) {
      const first = tokens[0];
      if (isNumber(first)) {
        if (!validIds || validIds.contains(first)) {
          return first;
        }
        throw new Error(I18n.text('reporting-snowflake.filter-editor.logic-editor.errors.invalid-id'));
      } else {
        throw new Error(I18n.text('reporting-snowflake.filter-editor.logic-editor.errors.invalid-input', {
          first
        }));
      }
    }
    const operators = [];
    let parenthesisDepth = 0;
    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];
      if (token === TOKEN.LP) {
        parenthesisDepth++;
        continue;
      }
      if (token === TOKEN.RP) {
        if (--parenthesisDepth === 0) {
          operators.push([TOKEN.LP]);
        }
        continue;
      }
      if (parenthesisDepth !== 0) {
        continue;
      }
      if (token === TOKEN.NOT) {
        operators.push([TOKEN.NOT]);
        continue;
      }
      if (token === TOKEN.AND) {
        operators.push([token, i]);
        continue;
      }
      if (token === TOKEN.OR) {
        operators.push([token, i]);
        break; // OR has the lowest precedence, dont bother looking forward
      }
      if (!isNumber(token)) {
        throw new Error(I18n.text('reporting-snowflake.filter-editor.logic-editor.errors.unrecognized-input', {
          token
        }));
      }
    }
    if (parenthesisDepth !== 0) {
      throw new Error(I18n.text('reporting-snowflake.filter-editor.logic-editor.errors.mismatching-parentheses'));
    }
    if (operators.length === 0) {
      throw new Error(I18n.text('reporting-snowflake.filter-editor.logic-editor.errors.missing-operator'));
    }
    const [type, maybeIndex] = Seq(operators).sortBy(([operator]) => PRECEDENCE[operator]).first();
    if (type === TOKEN.LP) {
      if (tokens[0] !== TOKEN.LP) {
        throw new Error(I18n.text('reporting-snowflake.filter-editor.logic-editor.errors.missing-operator'));
      }
      return parse(tokens.slice(1, -1));
    }
    if (type === TOKEN.NOT) {
      if (tokens[0] !== TOKEN.NOT) {
        throw new Error(I18n.text('reporting-snowflake.filter-editor.logic-editor.errors.missing-operator'));
      }
      return {
        operator: type,
        value: parse(tokens.slice(1))
      };
    }
    if (type === TOKEN.AND || type === TOKEN.OR) {
      const left = tokens.slice(0, maybeIndex);
      const right = tokens.slice(maybeIndex + 1);
      if (left.length === 0 || right.length === 0) {
        throw new Error(I18n.text('reporting-snowflake.filter-editor.logic-editor.errors.operator-incomplete'));
      }
      return {
        operator: type,
        left: parse(left),
        right: parse(right)
      };
    }
    throw new Error(I18n.text('reporting-snowflake.filter-editor.logic-editor.errors.parse-error'));
  };
  return parse;
};
export const parseFilterLogic = (expressionText, maybeValidIds) => {
  const validIds = maybeValidIds && ImmutableSet(maybeValidIds);
  const parse = parser(validIds);
  return parse(tokenize(expressionText));
};
export const stringify = (expression, parentOperator) => {
  if (!expression) {
    return '';
  }
  if (typeof expression === 'string') {
    return expression;
  }
  const {
    operator,
    value,
    left,
    right
  } = expression;
  const parenthesis = result => parentOperator === operator || !parentOperator ? result : `(${result})`;
  if (operator === 'NOT') {
    return parenthesis(`NOT ${stringify(value, operator)}`);
  }
  if (operator === 'AND') {
    return parenthesis(`${stringify(left, operator)} AND ${stringify(right, operator)}`);
  }
  if (operator === 'OR') {
    return parenthesis(`${stringify(left, operator)} OR ${stringify(right, operator)}`);
  }
  throw new Error(`Unexpected operator ${operator}`);
};
export const replaceId = (expression, lookup) => {
  if (typeof expression === 'string') {
    return lookup.get(expression) || expression;
  }
  const {
    operator,
    value,
    left,
    right
  } = expression;
  if (operator === 'NOT') {
    return {
      operator,
      value: replaceId(value, lookup)
    };
  }
  if (operator === 'AND' || operator === 'OR') {
    return {
      operator,
      left: replaceId(left, lookup),
      right: replaceId(right, lookup)
    };
  }
  throw new Error(`Unexpected operator ${operator}`);
};
export const and = (left, right) => ({
  operator: TOKEN.AND,
  left,
  right
});
export const removeId = (expression, id) => {
  if (typeof expression === 'string') {
    return expression === id ? undefined : expression;
  }
  const {
    operator,
    value,
    left,
    right
  } = expression;
  if (operator === 'NOT') {
    const childExpression = removeId(value, id);
    if (childExpression) {
      return {
        operator,
        value: childExpression
      };
    }
    return undefined;
  }
  if (operator === 'AND' || operator === 'OR') {
    const leftChildExpression = removeId(left, id);
    const rightChildExpression = removeId(right, id);
    if (leftChildExpression && rightChildExpression) {
      return {
        operator,
        left: leftChildExpression,
        right: rightChildExpression
      };
    }
    return leftChildExpression || rightChildExpression || undefined;
  }
  throw new Error(`Unexpected operator ${operator}`);
};