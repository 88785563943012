import { getSnowflakeProperty } from '../../metadata/report-meta';
const canPropertyBeResolvedWithLabelExpression = property => {
  return property.canBeResolvedWithLabelExpression;
};
export const shouldPropertyUseBackendLabelSort = snowflakeProperty => {
  if (snowflakeProperty.options && !snowflakeProperty.options.isEmpty()) {
    return false;
  }
  return canPropertyBeResolvedWithLabelExpression(snowflakeProperty);
};
export const shouldFieldUseBackendLabelSort = (field, reportMeta) => {
  const property = reportMeta && getSnowflakeProperty(reportMeta, field);
  if (!property) {
    return false;
  }
  return shouldPropertyUseBackendLabelSort(property);
};
const LABEL_ALIAS_PREFIX = 'label_';
export const toLabelDependencyAlias = dimensionAlias => `${LABEL_ALIAS_PREFIX}${dimensionAlias}`;