'use es6';

import { canBeOptional, FUNNEL_CREATE_STAGE_ID, getCachedDataForStage, getOptionalStagesContextField, getStageContextField, getStageIdFromStageValue, JOURNEY_STAGE_LIMIT } from '../stageHelpers';
export function validateStages(contextChange, context, report) {
  const stagesField = getStageContextField(report);
  const optionalStagesField = getOptionalStagesContextField(report);
  const selectedStages = contextChange[stagesField] || context[stagesField];
  const optionalStages = contextChange[optionalStagesField] || context[optionalStagesField];
  const validStages = selectedStages.filter(stageValue => {
    if (getStageIdFromStageValue(stageValue) === FUNNEL_CREATE_STAGE_ID) {
      return true;
    }
    return !!getCachedDataForStage(stageValue, report, undefined);
  });
  const limitedStages = validStages.take(JOURNEY_STAGE_LIMIT);
  return Object.assign({}, contextChange, {
    dealStages: limitedStages,
    optionalDealStages: optionalStages.filter(stage => limitedStages.includes(stage) && canBeOptional(stage, limitedStages))
  });
}