import I18n from 'I18n';
import set from 'transmute/set';
import { RequestState } from '../requests/request-state';
import { getLabel } from './schema/dataSourceMetadataSchema';
import { isFrameworkDataSource } from './dataSourceHelpers';
export const getDataSourceMetadataWithUniqueLabel = (dataSource, dataSourceMetadata) => {
  const newLabel = I18n.text(isFrameworkDataSource(dataSource) ? 'platformDashboard.dashboardFilters.allFilters.panel.uniqueDataSourcesLabel.builder' : 'platformDashboard.dashboardFilters.allFilters.panel.uniqueDataSourcesLabel.other', {
    dataSourceLabel: getLabel(dataSourceMetadata)
  });
  return set('label', newLabel, dataSourceMetadata);
};
export const selectDataSourcesInfo = ({
  dataSources,
  valueName
}) => state => dataSources.reduce((memo, dataSource) => {
  const storedValue = state[dataSource]; // TODO: update type here
  memo[dataSource] = storedValue ? storedValue[valueName] : [];
  return memo;
}, {});
export const selectDataSourcesInfoStatus = ({
  dataSources
}) => state => dataSources.reduce((memo, dataSource) => {
  if (memo === RequestState.FAILED) {
    return memo;
  }
  const storedValue = state[dataSource];
  if (!storedValue) {
    return RequestState.UNINITIALIZED;
  }
  if (storedValue.status === RequestState.PENDING) {
    return RequestState.PENDING;
  }
  if (storedValue.status === RequestState.FAILED) {
    return RequestState.FAILED;
  }
  return memo;
}, RequestState.SUCCEEDED);