'use es6';

import curry from 'transmute/curry';
import compose from 'transmute/compose';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import { defaultTo, includes } from 'dashboard-lib/private/js-util';
export const getPortalLimits = getIn(['portal', 'limits']);
export const getUserScopes = getIn(['user', 'scopes']);
export const getUserId = getIn(['user', 'user_id']);
export const hasScope = curry((key, userInfo) => compose(includes(key), defaultTo([]), getUserScopes)(userInfo));
export const hasGate = curry((key, userInfo) => compose(includes(key), defaultTo([]), get('gates'))(userInfo));
export const getPortalLimit = curry((key, userInfo) => compose(get(key), getPortalLimits)(userInfo));