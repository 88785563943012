'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { TooMuchDataException } from '../../../../exceptions';
export const DEFAULT_LIMIT = 500;
const precondition = (response, limits = [DEFAULT_LIMIT]) => {
  const check = data => data.get('aggregations', ImmutableMap()).forEach(aggregation => {
    const [limit = DEFAULT_LIMIT, ...rest] = limits;
    if (aggregation.count() > limit) {
      throw new TooMuchDataException();
    }
    precondition(aggregation, rest);
  });
  if (List.isList(response)) {
    response.forEach(check);
  } else {
    check(response);
  }
};
export default precondition;