'use es6';

import I18n from 'I18n';
import { DEFAULT_FISCAL_YEAR_MONTH_NAME } from 'reporting-data/constants/dates';
import * as Frequency from 'reporting-data/constants/frequency';
import makeDateRangeByType from 'reporting-data/lib/makeDateRangeByType';
const dateFormat = 'YYYYMMDD';
const getDefaultFrequency = (dateRange, useFiscalYear = false, fiscalYearMonthName = DEFAULT_FISCAL_YEAR_MONTH_NAME) => {
  const {
    startDate,
    endDate
  } = makeDateRangeByType(dateRange, dateFormat, undefined, useFiscalYear, fiscalYearMonthName);
  const start = I18n.moment(startDate, dateFormat);
  const end = I18n.moment(endDate, dateFormat);
  const elapsedDays = end.diff(start, 'DAY');
  if (elapsedDays < 30) {
    return Frequency.DAY;
  } else if (elapsedDays < 90) {
    return Frequency.WEEK;
  }
  return Frequency.MONTH;
};
export default getDefaultFrequency;