// *****PROTECTED*******
// DO NOT USE OUTSIDE OF FILE MANAGER CODE

export const VISIBLE_IN_APP_PUBLIC_TO_ALL_INDEXABLE = 'VISIBLE_IN_APP_PUBLIC_TO_ALL_INDEXABLE';
export const VISIBLE_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE = 'VISIBLE_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE';
export const VISIBLE_IN_APP_PRIVATE_NOT_INDEXABLE = 'VISIBLE_IN_APP_PRIVATE_NOT_INDEXABLE';
export const HIDDEN_IN_APP_PRIVATE_NOT_INDEXABLE = 'HIDDEN_IN_APP_PRIVATE_NOT_INDEXABLE';
export const HIDDEN_IN_APP_PUBLIC_TO_ALL_INDEXABLE = 'HIDDEN_IN_APP_PUBLIC_TO_ALL_INDEXABLE';
export const HIDDEN_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE = 'HIDDEN_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE';
export const HIDDEN_IN_APP_SENSITIVE = 'HIDDEN_IN_APP_SENSITIVE';
export const FileAccessValues = {
  [VISIBLE_IN_APP_PUBLIC_TO_ALL_INDEXABLE]: {
    allowsAnonymousAccess: true,
    isIndexable: true,
    hidden: false,
    isSensitive: false
  },
  [VISIBLE_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE]: {
    allowsAnonymousAccess: true,
    isIndexable: false,
    hidden: false,
    isSensitive: false
  },
  [VISIBLE_IN_APP_PRIVATE_NOT_INDEXABLE]: {
    allowsAnonymousAccess: false,
    isIndexable: false,
    hidden: false,
    isSensitive: false
  },
  [HIDDEN_IN_APP_PRIVATE_NOT_INDEXABLE]: {
    allowsAnonymousAccess: false,
    isIndexable: false,
    hidden: true,
    isSensitive: false
  },
  [HIDDEN_IN_APP_PUBLIC_TO_ALL_INDEXABLE]: {
    allowsAnonymousAccess: true,
    isIndexable: true,
    hidden: true,
    isSensitive: false
  },
  [HIDDEN_IN_APP_PUBLIC_TO_ALL_NOT_INDEXABLE]: {
    allowsAnonymousAccess: true,
    isIndexable: false,
    hidden: true,
    isSensitive: false
  },
  [HIDDEN_IN_APP_SENSITIVE]: {
    allowsAnonymousAccess: false,
    isIndexable: false,
    hidden: true,
    isSensitive: true
  }
};