'use es6';

import usePollingQuery from './usePollingQuery';
import { ALL_DASHBOARDS_FIELD_NAME, FETCH_ALL_DASHBOARDS } from '../requests/dashboards';
export const useDashboards = () => {
  const {
    data,
    error,
    loading
  } = usePollingQuery(FETCH_ALL_DASHBOARDS);
  return {
    data: data ? data[ALL_DASHBOARDS_FIELD_NAME] : undefined,
    error,
    loading
  };
};