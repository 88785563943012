import { ApolloClient, InMemoryCache } from '@apollo/client';
import { hubHttpLink } from './hubHttpLink';
import possibleTypes from './possibleTypes.json';
export const client = new ApolloClient({
  link: hubHttpLink({
    uri: 'graphql/crm'
  }),
  cache: new InMemoryCache({
    possibleTypes,
    typePolicies: {
      // merge policies, keyField policies, and query policies
    }
  }),
  connectToDevTools: true,
  name: 'DashboardUI-apollo-client'
});