'use es6';

// list membership types
export const IN_LIST_TYPE = {
  IMPORT: 'IMPORT',
  WORKFLOWS_ACTIVE: 'WORKFLOWS_ACTIVE',
  WORKFLOWS_COMPLETED: 'WORKFLOWS_COMPLETED',
  WORKFLOWS_ENROLLMENT: 'WORKFLOWS_ENROLLMENT',
  WORKFLOWS_GOAL: 'WORKFLOWS_GOAL'
};

// refinements

export const ALL_HISTORY_REFINE_BY = 'AllHistoryRefineBy';
export const PRUNING_REFINE_BY = 'pruningRefineBy';
export const COALESCING_REFINE_BY = 'coalescingRefineBy';
export const COALESCING_REFINE_BY_TYPE = {
  NUM_OCCURRENCES: 'NumOccurrencesRefineBy',
  SET_OCCURRENCES: 'SetOccurrencesRefineBy'
};
export const COALESCING_REFINE_BY_NUM_TYPE = {
  LESS: 'LESS',
  MORE: 'MORE',
  EQUAL: 'EQUAL'
};
export const COALESCING_REFINE_BY_SET_TYPE = {
  ALL: 'ALL',
  ALL_INCLUDE_EMPTY: 'ALL_INCLUDE_EMPTY',
  ANY: 'ANY',
  NONE: 'NONE',
  NONE_EXCLUDE_EMPTY: 'NONE_EXCLUDE_EMPTY'
};
export const PRUNING_REFINE_BY_TYPE = {
  ABSOLUTE: 'AbsoluteComparativeTimestampRefineBy',
  ABSOLUTE_RANGE: 'AbsoluteRangedTimestampRefineBy',
  RELATIVE: 'RelativeComparativeTimestampRefineBy',
  RELATIVE_RANGE: 'RelativeRangedTimestampRefineBy'
};
export const TIME_DIRECTION = {
  PAST: 'PAST',
  FUTURE: 'FUTURE'
};
export const TIME_UNIT = {
  DAYS: 'DAYS',
  WEEKS: 'WEEKS'
};
export const TIME_COMPARISON = {
  AFTER: 'AFTER',
  BEFORE: 'BEFORE',
  BETWEEN: 'BETWEEN',
  NOT_BETWEEN: 'NOT_BETWEEN'
};
export const DEPRECATED_FILTER = 'DEPRECATED_FILTER';
export const MISSING_ASSOCIATION_BRANCH_FILTER = 'MISSING_ASSOCIATION_BRANCH_FILTER';