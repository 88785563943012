import I18n from 'I18n';
import once from 'transmute/once';
import { ALL_OWNER_VALUE, ME_OWNER_VALUE, NONE_OWNER_VALUE } from './OwnerIdValues';
import PropertyOptionRecord from 'customer-data-objects/property/PropertyOptionRecord';
export const getAllOption = once(() => PropertyOptionRecord({
  label: I18n.text('customerDataObjects.OwnerIdSelect.all'),
  value: ALL_OWNER_VALUE
}));
export const getMeOption = once(() => PropertyOptionRecord({
  icon: 'dynamicFilter',
  label: I18n.text('customerDataObjects.OwnerIdSelect.me'),
  value: ME_OWNER_VALUE,
  description: I18n.text('customerDataObjects.OwnerIdSelect.meDescription')
}));
export const getNoneOption = once(() => PropertyOptionRecord({
  label: I18n.text('customerDataObjects.OwnerIdSelect.none'),
  value: NONE_OWNER_VALUE
}));