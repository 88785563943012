import { useState, useEffect } from 'react';
import getIn from 'transmute/getIn';
// @ts-expect-error migrate upstream types
import { getCurrencyInformation } from 'reporting-data/public/currency';
// TODO: migrate to asyncThunk pattern
export const useCurrencyCode = () => {
  const [currencyCode, setCurrencyCode] = useState(null);
  useEffect(() => {
    getCurrencyInformation().then(currencyResponse => {
      setCurrencyCode(getIn(['homeCurrency', 'currencyCode'], currencyResponse));
    }).catch(console.error);
  }, []);
  return currencyCode;
};