import { List, Map as ImmutableMap } from 'immutable';
import getIn from 'transmute/getIn';
import updateIn from 'transmute/updateIn';
import * as DataTypes from 'reporting-data/constants/dataTypes';
// @ts-expect-error migrate upstream
import { getOverrideProperty } from 'reporting-ui-components/lib/getOverrideProperty';
import { FUNNEL, PIPELINE } from 'reporting-data/constants/configTypes';
import { isSalesAnalyticsWidget } from 'reporting-ui-components/components/customWidgets/lib/salesExplorer';
import { isSalesQuota, applyDateRangeToSalesAnalytics } from './salesAnalyticsOverrides';
import { isSinglePipelineReport } from 'sales-analytics-lib/utils/salesAnalyticsHelpers';
import { getOverrideFrequency, getContextOverrideFilters } from './PlatformReportHelpers';
const getDataType = getIn(['config', 'dataType']);
const STATIC_DATATYPES = [DataTypes.ANALYTICS_FUNNEL, DataTypes.ATTRIBUTION];
const updateCustomFilter = updateIn(['config', 'filters', 'custom']);
const shouldClearFilter = filterValue => filterValue === null;
const isSinglePipelineSalesAnalyticsWidget = report => isSinglePipelineReport(report.getIn(['displayParams', 'salesAnalytics', 'subAppKey']), report);
const replaceCustomFilter = (newFilter, report) => updateCustomFilter((custom = List()) => custom.filter(filter => filter.get('property') !== newFilter.get('property')).push(newFilter), report);
const removeFilterProperty = (property, report) => updateCustomFilter((custom = List()) => custom.filter(filter => filter.get('property') !== property), report);
const APPLY_OVERRIDE_FILTER = {
  dateRange: (dateRange, report) => {
    const reportDateRange = report.getIn(['config', 'filters', 'dateRange']);
    if (isSalesAnalyticsWidget(report)) {
      return applyDateRangeToSalesAnalytics(dateRange, report);
    }
    if (reportDateRange != null && !STATIC_DATATYPES.includes(report.getIn(['config', 'dataType']))) {
      return report.setIn(['config', 'filters', 'dateRange', 'value'], dateRange);
    }
    return report;
  },
  owners: (values, report) => {
    const dataType = getDataType(report);
    const property = getOverrideProperty('owners', dataType);
    return property ? shouldClearFilter(values) ? removeFilterProperty(property, report) : replaceCustomFilter(ImmutableMap({
      property,
      operator: 'IN',
      values
    }), report) : report;
  },
  teams: (values, report) => {
    const dataType = getDataType(report);
    const property = getOverrideProperty('teams', dataType);
    return property ? shouldClearFilter(values) ? removeFilterProperty(property, report) : replaceCustomFilter(ImmutableMap({
      property,
      operator: 'IN',
      values
    }), report) : report;
  },
  dealPipeline: (pipeline, report) => {
    if (getDataType(report) !== DataTypes.DEALS) {
      return report;
    }
    const property = getOverrideProperty('pipeline', getDataType(report));
    if (shouldClearFilter(pipeline) && !isSinglePipelineSalesAnalyticsWidget(report)) {
      return removeFilterProperty(property, report);
    }
    if ([FUNNEL, PIPELINE].includes(report.getIn(['config', 'configType'])) || isSalesAnalyticsWidget(report)) {
      return report.setIn(['config', 'pipeline', 'id'], pipeline);
    }
    const values = List([pipeline]);
    return property ? replaceCustomFilter(ImmutableMap({
      property,
      operator: 'IN',
      values
    }), report) : report;
  },
  ticketPipeline: (pipeline, report) => {
    if (getDataType(report) !== DataTypes.TICKETS) {
      return report;
    }
    return shouldClearFilter(pipeline) ? removeFilterProperty('hs_pipeline', report) : replaceCustomFilter(ImmutableMap({
      property: 'hs_pipeline',
      operator: 'EQ',
      value: pipeline
    }), report);
  }
};
const applyOverrides = (dashboardContext, report) => {
  const overrideFilters = getContextOverrideFilters(dashboardContext);
  const overrideFrequency = getOverrideFrequency(dashboardContext);
  const dataType = report.getIn(['config', 'dataType']);
  report = overrideFilters.entrySeq().reduce((memo, [key, override]) => {
    if ((override || shouldClearFilter(override)) && !STATIC_DATATYPES.includes(dataType)) {
      return APPLY_OVERRIDE_FILTER[key](override, memo);
    }
    return memo;
  }, report);
  const reportFrequency = report.getIn(['config', 'frequency']);
  if (reportFrequency != null && overrideFrequency != null && !isSalesQuota(report)) {
    report = report.setIn(['config', 'frequency'], overrideFrequency);
  }
  return report;
};
export default applyOverrides;