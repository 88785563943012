import { createSlice } from '@reduxjs/toolkit';
import { getDefaultFilterSet, getFilterSetById } from '../../lib/savedDashboardFilters';
import { updateSavedDashboardFiltersState } from './helpers';
import { appliedDashboardFiltersActions } from '../applied-dashboard-filters/slice';
export const savedDashboardFiltersNamespace = 'savedDashboardFilters';
export const initialState = {};

// reducer actions

const savedDashboardFiltersSlice = createSlice({
  name: savedDashboardFiltersNamespace,
  initialState,
  reducers: {
    loadSavedDashboardFilterSet: (state, action) => {
      const {
        payload: {
          dashboardId,
          nextAppliedFilterSet
        }
      } = action;
      updateSavedDashboardFiltersState(state, dashboardId, nextFilterState => {
        nextFilterState.appliedFilterSetId = nextAppliedFilterSet ? nextAppliedFilterSet.id : undefined;
      });
    },
    addNewSavedDashboardFilterSet: (state, action) => {
      const {
        payload: newFilterSet
      } = action;
      const {
        dashboardId
      } = newFilterSet;
      updateSavedDashboardFiltersState(state, dashboardId, filterState => {
        const newDefault = newFilterSet.defaultFilter;
        if (newDefault) {
          filterState.filterSets = filterState.filterSets.map(filterSet => Object.assign({}, filterSet, {
            defaultFilter: false
          }));
        }
        filterState.appliedFilterSetId = newFilterSet.id;
        filterState.filterSets.push(newFilterSet);
      });
    },
    updateSavedDashboardFilterSet: (state, action) => {
      const {
        payload: {
          dashboardId,
          filters: nextFilters,
          id: filterSetId,
          name: nextName,
          defaultFilter: nextDefaultFilter,
          frequency: nextFrequency,
          useFiscalYear: nextUseFiscalYear
        }
      } = action;
      const maybeOverwriteDefault = (filterSet, defaultFilter) => {
        if (defaultFilter === true) {
          filterSet.defaultFilter = false;
        }
        return filterSet;
      };
      const updateFilterSet = filterSet => Object.assign({}, filterSet, {
        filters: nextFilters || filterSet.filters,
        name: nextName || filterSet.name,
        frequency: nextFrequency,
        useFiscalYear: nextUseFiscalYear,
        defaultFilter: nextDefaultFilter !== undefined ? nextDefaultFilter : filterSet.defaultFilter
      });
      updateSavedDashboardFiltersState(state, dashboardId, nextFilterState => {
        const nextFilterSets = nextFilterState.filterSets.map(filterSet => filterSet.id === filterSetId ? updateFilterSet(filterSet) : maybeOverwriteDefault(filterSet, nextDefaultFilter));
        nextFilterState.filterSets = nextFilterSets;
      });
    },
    removeSavedDashboardFilterSet: (state, action) => {
      const {
        payload: {
          dashboardId,
          filterSetId
        }
      } = action;
      updateSavedDashboardFiltersState(state, dashboardId, nextFilterState => {
        const nextFilterSets = nextFilterState.filterSets.filter(filterSet => filterSet.id !== filterSetId);
        nextFilterState.filterSets = nextFilterSets;
      });
    },
    removeAllSavedDashboardFilterSets: (state, action) => {
      const {
        payload: {
          dashboardId
        }
      } = action;
      updateSavedDashboardFiltersState(state, dashboardId, nextFilterState => {
        nextFilterState.appliedFilterSetId = undefined;
        nextFilterState.filterSets = [];
      });
    }
  },
  extraReducers: builder => {
    builder.addCase(appliedDashboardFiltersActions.removeCurrentDashboardFilters, (state, action) => {
      const {
        payload: {
          dashboardId
        }
      } = action;
      if (state[dashboardId]) {
        state[dashboardId].appliedFilterSetId = undefined;
      }
    });
    builder.addCase(appliedDashboardFiltersActions.initializeDashboardFilters, (state, action) => {
      const {
        payload: {
          dashboardId,
          filterSetId: maybeAppliedFilterSetOverrideId,
          filterSets = []
        }
      } = action;
      const maybeDefaultFilterSet = getDefaultFilterSet(filterSets);
      const maybeOverrideFilterSet = maybeAppliedFilterSetOverrideId && getFilterSetById(filterSets, maybeAppliedFilterSetOverrideId);
      const maybeAppliedFilterSet = maybeOverrideFilterSet || maybeDefaultFilterSet;
      const appliedFilterSetId = maybeAppliedFilterSet ? maybeAppliedFilterSet.id : undefined;
      state[dashboardId] = {
        dashboardId,
        appliedFilterSetId,
        filterSets
      };
    });
  }
});
export const savedDashboardFiltersActions = savedDashboardFiltersSlice.actions;
export const savedDashboardFiltersReducer = savedDashboardFiltersSlice.reducer;