'use es6';

const exponentFormat = /^[0-9]\.?[0-9]*[eE][0-9]+/;
const redundantDecimalFormat = /\.0$/;
const isInvalidNumberFormat = num => {
  const numStr = String(num);
  return exponentFormat.exec(numStr) || redundantDecimalFormat.exec(numStr);
};
export const formatForScientificNotation = value => {
  if (isNaN(value) || value === null || value === '' || !isInvalidNumberFormat(value)) {
    return value;
  }
  return typeof value === 'string' ? String(Number(value)) : Number(value);
};