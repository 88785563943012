import { REPORT_CUSTOMIZATION_COMPONENT_CONFIG } from '../constants/report-customization-components/componentConfig';
export const getIsReportValidForCustomizationComponentId = (componentId, report, userInfo) => {
  var _REPORT_CUSTOMIZATION;
  return (_REPORT_CUSTOMIZATION = REPORT_CUSTOMIZATION_COMPONENT_CONFIG[componentId]) === null || _REPORT_CUSTOMIZATION === void 0 ? void 0 : _REPORT_CUSTOMIZATION.isValidForReport({
    report,
    userInfo
  });
};
export const getComponentForReportCustomizationComponentId = (componentId, report, userInfo) => {
  var _REPORT_CUSTOMIZATION2, _REPORT_CUSTOMIZATION3;
  if (!((_REPORT_CUSTOMIZATION2 = REPORT_CUSTOMIZATION_COMPONENT_CONFIG[componentId]) !== null && _REPORT_CUSTOMIZATION2 !== void 0 && _REPORT_CUSTOMIZATION2.isValidForReport({
    report,
    userInfo
  }))) {
    return;
  }
  return (_REPORT_CUSTOMIZATION3 = REPORT_CUSTOMIZATION_COMPONENT_CONFIG[componentId]) === null || _REPORT_CUSTOMIZATION3 === void 0 ? void 0 : _REPORT_CUSTOMIZATION3.component;
};