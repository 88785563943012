// Reporting Permission
export const REPORTING_ACCESS = 'reporting-access';
export const REPORTING_EDIT = 'reporting-edit';
export const REPORTING_CREATE_AND_OWN = 'reporting-create-and-own';
export const REPORTING_ADMIN = 'reporting-admin';
export const JITA_USER = 'jita-user';
export const CUSTOM_REPORTING_ACCESS = 'custom-reporting-access';
export const CUSTOM_OBJECT_ACCESS = 'custom-object-access';
export const REVENUE_ATTRIBUTION_ACCESS = 'revenue-attribution-read';
export const CONTACT_CREATE_ATTRIBUTION_ACCESS = 'contact-create-date-attribution-read';
export const DEAL_CREATE_ATTRIBUTION_ACCESS = 'deal-create-attribution-read';
export const REPORTING_DATASETS_ACCESS = 'reporting-datasets-access';
export const REPORTING_DATASETS_READ = 'reporting-datasets-read';
export const REPORTING_DATASETS_WRITE = 'reporting-datasets-write';
export const JOURNEY_ANALYTICS_READ = 'journey-analytics-read';
export const DEAL_JOURNEY_ANALYTICS_READ = 'deal-journey-analytics-read';
export const FUNNEL_JOURNEY_ANALYTICS_READ = 'funnel-journey-analytics-read';
export const CRM_EXPORT_SCOPE = 'crm-export';
export const DASHBOARD_SPECIFIC_PERMISSION_GRANTS = 'dashboard-specific-permission-grants';
export const DASHBOARD_DEMO_WRITE = 'dashboard-demo-write';
export const REPORTS_SALES_PRO_ACCESS = 'reports-sales-pro-access';
export const MARKETING_STARTER_PRODUCT = 'marketing-starter-product';
export const HUB_MARKETING_PRODUCT = 'hub-marketing-product';
export const REPORTS_PAID_ACCESS = 'reports-paid-access';
export const COMMENTING_SUPPORTED_HUBS_REQUIRED_SCOPES = ['commenting-access-marketing-hub', 'commenting-access-service-hub', 'commenting-access-cms-hub', 'commenting-access-ops-hub', 'commenting-access-sales-hub'];
export const REPORTING_EMAIL_EXTERNAL_RECIPIENTS_ACCESS = 'reporting-email-external-recipients-access';
export const BUSINESS_UNITS_ACCESS = 'business-units-access';
export const PAYMENTS_REPORTING_ACCESS = 'commerce-payments-read';
export const SUPER_ADMIN = 'super-admin';