import { ApolloLink, Observable } from '@apollo/client';
import { print } from 'graphql/language/printer';
import promiseClient from 'hub-http/adapters/promiseClient';
import { getGqlEarlyRequest } from './quickFetch';
import enviro from 'enviro';
import { Metrics } from './internal/Metrics';
import { stableStringify } from './internal/stableStringify';
const earlyRequestLink = new ApolloLink((operation, forward) => {
  const earlyRequest = getGqlEarlyRequest(operation);
  if (earlyRequest) {
    operation.setContext({
      earlyRequest
    });
  }
  return forward(operation);
});
const toRequestPayload = (operation, experimentalPersistedQueries) => {
  const {
    operationName,
    variables,
    query
  } = operation;
  const executionInput = {
    operationName,
    variables
  };
  if (experimentalPersistedQueries && enviro.deployed()) {
    executionInput.id = query.id;
  } else {
    executionInput.query = print(query);
  }
  return executionInput;
};
const createHubHttpLinkForClientHelper = (client, uri, experimentalPersistedQueries) => {
  const httpLink = new ApolloLink(operation => {
    const {
      hubHttpOptions,
      earlyRequest
    } = operation.getContext();
    const chosenURI = typeof uri === 'function' ? uri(operation) : uri;
    return new Observable(observer => {
      let currentXhr;
      const makeRequest = () => client.post(chosenURI, Object.assign({}, hubHttpOptions, {
        withXhr(xhr) {
          currentXhr = xhr;
        },
        data: toRequestPayload(operation, experimentalPersistedQueries)
      }));
      const request = earlyRequest ? earlyRequest.catch(makeRequest) : Metrics.timer('query-duration').time(makeRequest);
      const metricDimensions = {
        earlyRequest: String(!!earlyRequest)
      };
      request.then(data => {
        if (data.errors && data.errors.length > 0) {
          Metrics.counter('queries-with-errors', metricDimensions).increment();
        } else {
          Metrics.counter('successful-queries', metricDimensions).increment();
        }
        observer.next(data);
        observer.complete();
      }).catch(err => {
        if (err.errorCode === 'ABORT') {
          return;
        }
        Metrics.counter('failed-queries', metricDimensions).increment();
        observer.error(err);
      });
      return () => {
        if (currentXhr && currentXhr.readyState !== 4) {
          currentXhr.abort();
        }
      };
    });
  });
  return httpLink;
};
export const createHubHttpLinkForClient = client => ({
  uri = '',
  experimentalPersistedQueries = false
} = {}) => {
  const httpLink = createHubHttpLinkForClientHelper(client, uri, experimentalPersistedQueries);
  return ApolloLink.from([earlyRequestLink, httpLink]);
};
/**
 * We allow consumers to pass hub-http options as a context value to any
 * query or mutation. Handling this for a single operation is roughly trivial
 * (see {@link createHubHttpLinkForClientHelper}), but for a batched operation
 * this has some complexity because multiple items in the batch may have
 * different options set. Merging all of those options is undesired, as it
 * means a header set by a query in a library could override headers set by an
 * app, for example, dependning on how the merge code is written.
 *
 * Instead, we iterate through a batch, partitioning the operations by options.
 * In local testing, the performance impact of this iteration, even on large
 * batches, was not observable.
 *
 * In theory, this should have no impact on request performance as the requests
 * are multiplexed (we don't want for a request to complete before dispatching
 * the next). Event loop conflicts could arise, but in local testing that was
 * not the case.
 */
function partitionOperationsByHeaders(queue) {
  const operationsByStringifiedOptions = new Map();
  for (const queuedReq of queue) {
    const {
      hubHttpOptions
    } = queuedReq.operation.getContext();
    const stringOptions = stableStringify(hubHttpOptions);
    if (operationsByStringifiedOptions.has(stringOptions)) {
      operationsByStringifiedOptions.get(stringOptions).requests.push(queuedReq);
    } else {
      operationsByStringifiedOptions.set(stringOptions, {
        options: hubHttpOptions,
        requests: [queuedReq]
      });
    }
  }
  return Array.from(operationsByStringifiedOptions.values());
}
export const createBatchedHubHttpLinkForClient = client => ({
  uri = '',
  batchInterval = 10,
  batchMax = 10,
  experimentalPersistedQueries = false
} = {}) => {
  let queue = [];
  let dispatchTimer;
  function dispatchQueue() {
    const batch = queue;
    queue = [];
    const partitionedBatches = partitionOperationsByHeaders(batch);
    partitionedBatches.forEach(({
      options,
      requests
    }) => {
      client.post(uri, Object.assign({}, options, {
        data: requests.map(({
          operation
        }) => toRequestPayload(operation, experimentalPersistedQueries))
      })).then(data => {
        requests.forEach(({
          observer
        }, i) => {
          observer.next(data[i]);
          observer.complete();
        });
      }).catch(err => {
        requests.forEach(({
          observer
        }) => {
          observer.error(err);
        });
      });
    });
  }
  const batchedHttpLink = new ApolloLink(operation => {
    return new Observable(observer => {
      queue.push({
        operation,
        observer
      });
      if (queue.length === batchMax) {
        clearTimeout(dispatchTimer);
        dispatchQueue();
      } else if (queue.length === 1) {
        dispatchTimer = setTimeout(() => {
          dispatchQueue();
        }, batchInterval);
      }
      // notice there is no cleanup function so requests won't be aborted when query is unwatched.
    });
  });
  const httpLink = createHubHttpLinkForClientHelper(client, uri, experimentalPersistedQueries);
  return ApolloLink.from([earlyRequestLink, ApolloLink.split(operation => {
    const context = operation.getContext();
    // There are some scenarios where we want to fallback to not batching. We could
    // maintain separate queues like the official Apollo batching link but seems overkill.
    return typeof uri === 'string' && !context.noBatch && !context.earlyRequest;
  }, batchedHttpLink, httpLink)]);
};
export const createHubHttpLink = clientStack => createHubHttpLinkForClient(promiseClient(clientStack));
export const createBatchedHubHttpLink = clientStack => createBatchedHubHttpLinkForClient(promiseClient(clientStack));