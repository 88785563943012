'use es6';

// Create & save a new report from scratch or from a template
export const CREATE_NEW_REPORT = 'CREATE_NEW_REPORT';

// Create & save a new report by cloning an existing report.
export const CLONE_EXISTING_REPORT = 'CLONE_EXISTING_REPORT';

// Create + save a new report by saving updates to an existing one as new
export const SAVE_UPDATES_AS_NEW = 'SAVE_UPDATES_AS_NEW';

// Save updates to an existing report
export const EDIT_EXISTING_REPORT = 'EDIT_EXISTING_REPORT';
export const SaveReportUseTypeList = [CREATE_NEW_REPORT, CLONE_EXISTING_REPORT, SAVE_UPDATES_AS_NEW, EDIT_EXISTING_REPORT];

//These flow names are used in event tracking for the whichFlow property
export const getSaveReportFlowName = ({
  saveUse,
  template,
  isDrilldown,
  shouldBecomeCustom,
  trackingProperties = {}
}) => {
  if (trackingProperties.applicationName === 'ai-report-builder') {
    return 'ai-builder-generated-report';
  }
  switch (saveUse) {
    case CLONE_EXISTING_REPORT:
      return 'clone';
    case SAVE_UPDATES_AS_NEW:
      return 'save-updates-as-new';
    default:
      if (isDrilldown) {
        return 'save-from-drilldown';
      } else if (trackingProperties.whichFlow) {
        return trackingProperties.whichFlow;
      } else if (template || !shouldBecomeCustom) {
        //includes library reports & those found outside reporting
        return 'save-from-template';
      }
      return 'save-from-scratch';
  }
};