'use es6';

import { EditorState, SelectionState, Modifier } from 'draft-js';
import { replaceSelectionChangeTypes } from 'draft-plugins/lib/constants';
const {
  PUSH,
  SET
} = replaceSelectionChangeTypes;
export default (({
  editorState,
  text,
  start,
  end,
  entityKey,
  inlineStyles = null,
  blockKey,
  changeType = PUSH
}) => {
  const userSelection = editorState.getSelection();
  const selectedBlockKey = blockKey || userSelection.getStartKey();
  const replaceSelection = SelectionState.createEmpty(selectedBlockKey).merge({
    anchorOffset: start,
    focusOffset: end,
    isBackward: false,
    hasFocus: true
  });
  const contentState = Modifier.replaceText(editorState.getCurrentContent(), replaceSelection, text, inlineStyles, entityKey);
  let resultEditorState = editorState;
  switch (changeType) {
    case PUSH:
      resultEditorState = EditorState.push(editorState, contentState, 'insert-characters');
      break;
    case SET:
      resultEditorState = EditorState.set(editorState, {
        currentContent: contentState
      });
      break;
    default:
      resultEditorState = editorState;
      break;
  }
  const needToUpdateSelection = userSelection.getStartKey() === selectedBlockKey && userSelection.isCollapsed() && userSelection.getStartOffset() >= end;
  if (needToUpdateSelection) {
    const delta = text.length - (end - start);
    if (delta !== 0) {
      const newUserSelection = userSelection.update('anchorOffset', anchorOffset => anchorOffset + delta).update('focusOffset', focusOffset => focusOffset + delta);
      resultEditorState = EditorState.acceptSelection(resultEditorState, newUserSelection);
    }
  }
  return resultEditorState;
});